<template>
  <div
    class="select-container"
    style="width: 100%; position: relative"
    ref="target"
  >
    <div
      class="d-flex justify-content-end align-items-center"
      style="
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 0 14px;
        height: 40px;
      "
      @click="autoShow = !autoShow"
    >
      <span class="flex-fill text-start" v-if="selectValue != ''">
        {{ selectValue }}
      </span>
      <span class="flex-fill text-start" style="color: #575757" v-else>
        {{ holder }}
      </span>
      <img src="../assets/selection-arrow.png" alt="searchImg" />
    </div>
    <div
      class="auto-complicate-div"
      style="width: 100%"
      :style="`max-height:${mxHeight}px;top:${topSide}px`"
      v-show="autoShow"
      @mouseenter="autoShow = true"
    >
      <p
        class="m-0 text-start p-2"
        style="position: relative; border-bottom: 1px solid #c4c4c4"
      >
        <input
          type="text"
          class="search-input-customRFM-checkbox"
          placeholder="搜尋名稱"
          v-model="searchInput"
        />
        <img
          src="../assets/search-black.png"
          alt=""
          style="position: absolute; left: 10px; top: 12px"
        />
      </p>
      <div style="min-height: 146px;max-height: 146px; overflow-y: auto">
        <p
          v-for="(value, index) in searchedValueArr"
          :key="index"
          class="
            text-start
            m-0
            auto-complicate-p
            d-flex
            justify-content-end
            align-items-center
          "
          style="padding: 5px 14px"
          @click="childClick(value, index)"
        >
          <span
            class="flex-fill"
            v-if="
              Object.prototype.toString.call(valueArr) === '[object Object]'
            "
          >
            {{ index }}
          </span>
          <span class="flex-fill" v-else>
            {{ value }}
          </span>
          <span style="width: 10px">&nbsp;</span>
        </p>
        <p v-if="searchedValueArr.length===0">
            查無資料
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "DropdownAll",
  components: {},
  props: {
    topSide: Number,
    mxHeight: Number,
    valueArr: [Array, Object],
    selectValue: String,
    changingSelected: String,
    holder: String,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return {
      target,
      autoShow,
    };
  },
  data() {
    return {
      searchInput: "",
    };
  },
  created() {},
  computed: {
    searchedValueArr: function () {
      if (this.searchInput === "") {
        return this.$props.valueArr;
        // this.searchedValueArr = this.originValueArr;
      } else {
        const arr = this.$props.valueArr.filter((d) => {
          return d.includes(this.searchInput);
        });
        return arr;
      }
    },
  },
  methods: {
    childClick(value, index) {
      this.$emit(
        "selectValueByDrop",
        value,
        this.$props.changingSelected,
        index
      );
      this.autoShow = false;
    },
  },
};
</script>

<style scoped>
/* DropdownAll */
.auto-complicate-div {
  position: absolute;
  box-shadow: none;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background-color: white;
  z-index: 99;
}
.select-container {
  color: #575757;
  text-align: center;
  cursor: default;
}
.auto-complicate-p {
  color: #575757;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.auto-complicate-p:hover:last-child {
  background-color: #eaf3f4;
  color: #575757;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>