/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

var apigClientFactory = {};
apigClientFactory.newClient = function (config) {
    var apigClient = { };
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: 'ap-northeast-1',
apiKey: 'Rx1MmkMDROayqGCtfXCcQ7FqpeXWSshs7TPHRx0B',
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }

    
    // extract endpoint and path from url
    var invokeUrl = 'https://lyfed2ygdc.execute-api.ap-northeast-1.amazonaws.com/cdp';
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);
    
    
    
    apigClient.behaviorOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOfflineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineDayGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOfflineDayGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/day').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineDayGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineDayOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOfflineDayOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/day').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineDayOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineMemberGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOfflineMemberGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineMemberGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineMemberOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOfflineMemberOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineMemberOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflinePieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOfflinePieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflinePieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflinePieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOfflinePieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflinePieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineTimeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOfflineTimeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineTimeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOfflineTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineWeekGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOfflineWeekGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/week').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineWeekGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOfflineWeekOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOfflineWeekOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/offline/week').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOfflineWeekOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineBuyBehaGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOnlineBuyBehaGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/buy_beha').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineBuyBehaGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineBuyBehaOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineBuyBehaOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/buy_beha').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineBuyBehaOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineBuyJourGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOnlineBuyJourGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/buy_jour').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineBuyJourGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineBuyJourOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineBuyJourOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/buy_jour').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineBuyJourOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineDayGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOnlineDayGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/day').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineDayGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineDayOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineDayOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/day').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineDayOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineRaterateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOnlineRaterateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/raterate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineRaterateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineRaterateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineRaterateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/raterate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineRaterateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineTimeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOnlineTimeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineTimeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineWeekGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOnlineWeekGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/week').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineWeekGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOnlineWeekOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOnlineWeekOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/online/week').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOnlineWeekOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallAllTimeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOverallAllTimeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/all_time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallAllTimeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallAllTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallAllTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/all_time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallAllTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallCompareGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOverallCompareGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/compare').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallCompareGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallCompareOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallCompareOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/compare').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallCompareOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallPieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOverallPieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallPieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallPieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallPieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallPieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOverallRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallRate2Get = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOverallRate2GetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/rate2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallRate2GetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallRate2Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallRate2OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/rate2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallRate2OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallSaleTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var behaviorOverallSaleTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/sale_trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallSaleTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.behaviorOverallSaleTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var behaviorOverallSaleTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/behavior/overall/sale_trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(behaviorOverallSaleTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicDiscountGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicDiscountGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/discount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicDiscountGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicDiscountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicDiscountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/discount').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicDiscountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabelGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicLabelGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabelGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicLabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabel1Get = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicLabel1GetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label1').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabel1GetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabel1Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicLabel1OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label1').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabel1OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabel2Get = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'member_id'], ['body']);
        
        var memberBasicLabel2GetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabel2GetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabel2Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicLabel2OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabel2OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabel3Get = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'member_id'], ['body']);
        
        var memberBasicLabel3GetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label3').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabel3GetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicLabel3Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicLabel3OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/label3').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicLabel3OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicPreferGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicPreferGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/prefer').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicPreferGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicPreferOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicPreferOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/prefer').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicPreferOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicRecommendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicRecommendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/recommend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicRecommendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicRecommendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicRecommendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/recommend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicRecommendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicRfmGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberBasicRfmGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/rfm').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicRfmGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberBasicRfmOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberBasicRfmOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/basic/rfm').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberBasicRfmOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOnlineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineLfgepieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOnlineLfgepieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/lfgepie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineLfgepieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineLfgepieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOnlineLfgepieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/lfgepie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineLfgepieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineListsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOnlineListsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/lists').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineListsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineListsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOnlineListsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/lists').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineListsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineResponseGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOnlineResponseGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/response').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineResponseGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineResponseOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOnlineResponseOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/response').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineResponseOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineTrackGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOnlineTrackGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/track').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineTrackGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineTrackOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOnlineTrackOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/track').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineTrackOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineViewGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOnlineViewGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/view').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineViewGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOnlineViewOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOnlineViewOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/online/view').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOnlineViewOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallHourGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOverallHourGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/hour').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallHourGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallHourOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallHourOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/hour').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallHourOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallLinechartGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOverallLinechartGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/linechart').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallLinechartGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallLinechartOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallLinechartOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/linechart').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallLinechartOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallPurchaseGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOverallPurchaseGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallPurchaseGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallPurchaseOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallPurchaseOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallPurchaseOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallShopieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOverallShopieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/shopie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallShopieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallShopieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallShopieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/shopie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallShopieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallThreeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOverallThreeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/three').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallThreeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallThreeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallThreeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/three').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallThreeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallWeekGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['member_id', 'Authorization'], ['body']);
        
        var memberOverallWeekGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/week').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['member_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallWeekGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberOverallWeekOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberOverallWeekOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/overall/week').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberOverallWeekOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberSearchPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberSearchPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member/search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberSearchPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberSearchOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberSearchOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberSearchOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberSearchUpdatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberSearchUpdatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member/search_update').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberSearchUpdatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberSearchUpdateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberSearchUpdateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member/search_update').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberSearchUpdateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorDeviceGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorDeviceGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/device').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorDeviceGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorDeviceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorDeviceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/device').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorDeviceOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorMemberGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorMemberGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorMemberGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorMemberOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorMemberOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorMemberOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorOtherGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorOtherGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/other').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorOtherGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorOtherOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorOtherOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/other').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorOtherOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorProductGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization', 'item'], ['body']);
        
        var onlineBehaviorProductGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/product').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', 'item']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorProductGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorProductOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorProductOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/product').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorProductOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorProductSearchGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorProductSearchGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/product_search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorProductSearchGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorProductSearchOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorProductSearchOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/product_search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorProductSearchOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorRegisterGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorRegisterGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/register').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorRegisterGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorRegisterOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorRegisterOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/register').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorRegisterOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorRegisterLineGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorRegisterLineGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/register_line').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorRegisterLineGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorRegisterLineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorRegisterLineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/register_line').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorRegisterLineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorSessionGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorSessionGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/session').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorSessionGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorSessionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorSessionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/session').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorSessionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorSessionLineGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorSessionLineGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/session_line').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorSessionLineGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorSessionLineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorSessionLineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/session_line').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorSessionLineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorWordGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineBehaviorWordGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/word').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorWordGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineBehaviorWordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineBehaviorWordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/behavior/word').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineBehaviorWordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineCfOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfClick10Get = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineCfClick10GetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/click10').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfClick10GetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfClick10Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineCfClick10OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/click10').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfClick10OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfClickBubbleGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineCfClickBubbleGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/click_bubble').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfClickBubbleGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfClickBubbleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineCfClickBubbleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/click_bubble').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfClickBubbleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfKeywordsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineCfKeywordsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/keywords').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfKeywordsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfKeywordsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineCfKeywordsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/keywords').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfKeywordsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfLabelCatesGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineCfLabelCatesGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/label_cates').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfLabelCatesGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfLabelCatesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineCfLabelCatesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/label_cates').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfLabelCatesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfLabelValueGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var onlineCfLabelValueGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/label_value').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfLabelValueGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.onlineCfLabelValueOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var onlineCfLabelValueOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/online/cf/label_value').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(onlineCfLabelValueOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationChannelHotsaletimeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationChannelHotsaletimeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/channel/hotsaletime').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationChannelHotsaletimeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationChannelHotsaletimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationChannelHotsaletimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/channel/hotsaletime').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationChannelHotsaletimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationChannelMemberateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationChannelMemberateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/channel/memberate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationChannelMemberateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationChannelMemberateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationChannelMemberateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/channel/memberate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationChannelMemberateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationChannelStoreperformanceGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationChannelStoreperformanceGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/channel/storeperformance').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationChannelStoreperformanceGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationChannelStoreperformanceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationChannelStoreperformanceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/channel/storeperformance').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationChannelStoreperformanceOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberBuyFreqGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'Authorization', 'end_date'], ['body']);
        
        var operationMemberBuyFreqGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/buy_freq').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberBuyFreqGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberBuyFreqOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMemberBuyFreqOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/buy_freq').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberBuyFreqOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberMemberGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var operationMemberMemberGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberMemberGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberMemberOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMemberMemberOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberMemberOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberRegionGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMemberRegionGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/region').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberRegionGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberRegionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMemberRegionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/region').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberRegionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberSalesGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMemberSalesGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/sales').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberSalesGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberSalesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMemberSalesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/sales').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberSalesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberStructureGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMemberStructureGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberStructureGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberStructureOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMemberStructureOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberStructureOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMemberTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMemberTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMemberTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/member/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMemberTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsChannelrateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMetricsChannelrateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/channelrate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsChannelrateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsChannelrateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMetricsChannelrateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/channelrate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsChannelrateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsIndexGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMetricsIndexGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/index').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsIndexGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsIndexOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMetricsIndexOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/index').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsIndexOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsOnlinetrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMetricsOnlinetrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/onlinetrend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsOnlinetrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsOnlinetrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMetricsOnlinetrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/onlinetrend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsOnlinetrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsSalesxordersGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationMetricsSalesxordersGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/salesxorders').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsSalesxordersGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationMetricsSalesxordersOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationMetricsSalesxordersOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/metrics/salesxorders').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationMetricsSalesxordersOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'start_date', 'end_date'], ['body']);
        
        var operationProductAssociationGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationCountGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductAssociationCountGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_count').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationCountGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationCountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationCountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_count').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationCountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationCountsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductAssociationCountsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_counts').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationCountsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationCountsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationCountsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_counts').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationCountsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationProdCateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var operationProductAssociationProdCateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_prod_cate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationProdCateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationProdCateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationProdCateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_prod_cate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationProdCateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationProductGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var operationProductAssociationProductGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_product').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationProductGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationProductOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationProductOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_product').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationProductOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationSearchPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductAssociationSearchPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationSearchPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationSearchOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationSearchOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationSearchOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationSearchesPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductAssociationSearchesPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_searches').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationSearchesPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductAssociationSearchesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductAssociationSearchesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/association_searches').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductAssociationSearchesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductClusterGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'start_date', 'end_date'], ['body']);
        
        var operationProductClusterGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/cluster').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductClusterGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductClusterOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductClusterOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/cluster').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductClusterOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductRank5growGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductRank5growGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/rank5grow').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductRank5growGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductRank5growOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductRank5growOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/rank5grow').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductRank5growOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductRank5productGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductRank5productGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/rank5product').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductRank5productGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductRank5productOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductRank5productOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/rank5product').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductRank5productOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductScatterGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductScatterGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/scatter').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductScatterGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductScatterOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductScatterOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/scatter').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductScatterOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductTop5cateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductTop5cateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/top5cate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductTop5cateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductTop5cateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductTop5cateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/top5cate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductTop5cateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductTop5revorderGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var operationProductTop5revorderGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/top5revorder').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductTop5revorderGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.operationProductTop5revorderOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var operationProductTop5revorderOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/operation/product/top5revorder').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(operationProductTop5revorderOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var timeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeMemberGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var timeMemberGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/time/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeMemberGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeMemberOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var timeMemberOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/time/member').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeMemberOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeOnlineGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var timeOnlineGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/time/online').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeOnlineGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeOnlineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var timeOnlineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/time/online').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeOnlineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeSalesGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var timeSalesGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/time/sales').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeSalesGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.timeSalesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var timeSalesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/time/sales').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(timeSalesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    return apigClient;
};
export var allapi = apigClientFactory.newClient();