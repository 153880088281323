<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              is-expanded
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div class="title-span fs-medium">域動站外標籤</div>

        <div class="d-flex justify-content-end">
          <div class="title-button-div">
            <button
              class="button-nofocus"
              :class="whichWeb === web ? 'button-focus' : ''"
              @click="changeWeb(web)"
              v-for="web in websites"
              :key="web"
            >
              {{ web }}
            </button>
          </div>
        </div>
      </div>
      <div class="dropdown" style="border-radius:10px;border:1px solid #c4c4c4;width: 20.8vw;">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span> {{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <!-- <li class="change-date-li">
                        <button class="change-date-button" @click="getPass14Days">
                            最近14天
                        </button>
                    </li> -->
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>

          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-12">
        <div class="clickforce-1row-div p-3">
          <div class="d-flex align-items-center">
            <span class="number-label">站外熱門標籤 TOP10</span>
            <Dropdown
              style="width: 30%; margin-left: 15px"
              :valueArr="this.mainDropdownKey"
              :selectValue="DropdownSelected"
              :changingSelected="'top10PageSelector'"
              v-on:selectValueByDrop="selectValueByDrop"
            />
          </div>
          <div style="position: relative">
            <div
              v-if="this.topBarData.every(d=>d==null)"
              style="position: absolute; top: 38%; left: 50%;color:#c4c4c4"
              class="text-center"
            >
              <p>此選項無資料</p>
            </div>
            <apexchart
              type="bar"
              :options="topBarOptions"
              :series="topBarSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-12">
        <div
          class="
            clickforce-1row-div-wordcloud
            d-flex
            flex-column
            justify-content-center
          "
          style="padding: 25px 13px 25px 40px; height: 600px"
          v-if="cloudLoading"
        >
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div
          class="clickforce-1row-div-wordcloud p-3"
          style="position: relative"
        >
          <div class="d-flex align-items-center">
            <span class="number-label">站外熱門關鍵字</span>
          </div>

          <div v-if="this.defaultWords[0] != undefined">
            <div style="width: 100%">
              <WordCloudOwn
                :data="defaultWords"
                nameKey="name"
                valueKey="value"
                :fontScale="myFontScale"
                :fontSize="myFontSize"
                :color="myColors"
                :showTooltip="false"
                :wordClick="wordClickHandler"
              >
              </WordCloudOwn>
            </div>
          </div>
          <div
            v-else
            style="position: absolute; top: 30%; left: 40%;color:#c4c4c4"
            class="text-center"
          >
            <img src="../assets/missData.svg" alt="" style="width: 200px" />
            <p>此時間段無資料</p>
          </div>
          <!-- <WordCloudOwn :data="defaultWords" nameKey="name" valueKey="value" :color="myColors"
                            :showTooltip="false" :wordClick="wordClickHandler">
                        </WordCloudOwn> -->
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-12">
        <div class="clickforce-1row-div p-3">
          <div class="d-flex">
            <Dropdown
              style="width: 30%"
              :valueArr="this.mainDropdownKey"
              :selectValue="DropdownSelected2"
              :changingSelected="'top10PageSelector'"
              v-on:selectValueByDrop="selectValueByDrop2"
            />
            <Dropdown
              style="width: 30%; margin-left: 15px"
              :valueArr="this.secondaryDropdownKey"
              :selectValue="secondarySelected"
              :changingSelected="'top10PageSelector'"
              v-on:selectValueByDrop="selectSecondaryByDrop"
            />
          </div>

          <div class="d-flex mt-3">
            <div class="col-6 pe-2">
              <div class="chart-border-freeheight" style="position: relative">
                <span class="number-label ms-3 mt-3"
                  >站外與瀏覽行為分析TOP10</span
                >
                <div
                  v-if="
                    this.mediumBarData1.length === 0 ||
                    this.mediumBarData1[0] == null
                  "
                  style="position: absolute; top: 30%; left: 30%;color:#c4c4c4"
                  class="text-center"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <p>此選項無資料</p>
                </div>
                <div v-else>
                  <apexchart
                    type="bar"
                    height="420"
                    :options="mediumBarOptions1"
                    :series="mediumBarSeries1"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
            <div class="col-6 ps-2">
              <div class="chart-border-freeheight" style="position: relative">
                <span class="number-label ms-3 mt-3"
                  >站外與購買行為分析TOP10</span
                >
                <div
                  v-if="
                    this.mediumBarData2.length === 0 ||
                    this.mediumBarData2[0] == null
                  "
                  style="position: absolute; top: 30%; left: 30%;color:#c4c4c4"
                  class="text-center"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <p>此選項無資料</p>
                </div>
                <div v-else>
                  <apexchart
                    type="bar"
                    height="420"
                    :options="mediumBarOptions2"
                    :series="mediumBarSeries2"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-12">
        <div class="clickforce-1row-div p-3">
          <Dropdown
            style="width: 30%"
            :valueArr="this.mainDropdownKey"
            :selectValue="DropdownSelected3"
            :changingSelected="'top10PageSelector'"
            v-on:selectValueByDrop="selectValueByDrop3"
          />
          <div class="d-flex mt-3">
            <div class="col-6 pe-2">
              <div class="chart-border" style="position: relative">
                <span class="number-label ms-3 mt-3">站外與瀏覽表現分析</span>
                <div
                  v-if="this.bubbleData1[0].price_item_cid[0] === undefined"
                  style="position: absolute; top: 30%; left: 30%;color:#c4c4c4"
                  class="text-center"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <p>此選項無資料</p>
                </div>
                <div v-else>
                  <highcharts :options="highchartsOptions"></highcharts>
                </div>
              </div>
            </div>
            <div class="col-6 ps-2">
              <div class="chart-border" style="position: relative">
                <span class="number-label ms-3 mt-3">站外與購買表現分析</span>
                <div
                  v-if="this.bubbleData2[0].price_item_cid[0] === undefined"
                  style="position: absolute; top: 30%; left: 30%;color:#c4c4c4"
                  class="text-center"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <p>此選項無資料</p>
                </div>
                <div v-else>
                  <highcharts :options="highchartsOptions2"></highcharts>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi } from "../../public/static/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import Dropdown from "../components/Dropdown.vue";
import wordcloud from "vue-wordcloud";
import WordCloudOwn from "../views/WordCloud.vue";

import { Chart } from "highcharts-vue";

export default {
  components: {
    WordCloudOwn,
    wordcloud,
    apexchart: VueApexCharts,
    Dropdown,
    Calendar,
    DatePicker,
    highcharts: Chart,
  },

  data() {
    return {
      websites: [],
      whichWeb: "",
      vue3Date: {
        start: "",
        end: "",
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      dateSelector: "全部",
      startDate: "2021-11-01",
      endDate: "2021-12-22",
      hasDataFirst: "",
      hasDataEnd: "",
      endYear: 2022,
      endMonth: 6,
      loading: false,
      // 上面的dropdown的state
      DropdownSelected: "",
      // 中間第一個dropdown的state
      DropdownSelected2: "",
      // 下面bubble chart 第一個dropdown的state
      DropdownSelected3: "",
      secondarySelected: "",
      mainDropdownKey: [],
      secondaryDropdownKey: [],

      // 上部barchart series and options
      topBarSeries: [],
      topBarOptions: {},
      // 上部barchartr res data
      topBarData: [],
      topBarLabel: [],
      //中間barchart series and options
      mediumBarSeries1: [
        {
          name: "",
          data: [],
        },
      ],
      mediumBarOptions1: {},
      // 中間barchartr res data
      mediumBarData1: [],
      mediumBarLabel1: [],
      // 中間barchart series and options
      mediumBarSeries2: [
        {
          name: "",
          data: [],
        },
      ],
      mediumBarOptions2: {},
      // 中間barchartr res data
      mediumBarData2: [],
      mediumBarLabel2: [],

      bubbleSeries: [],
      bubbleOptions: {},

      bubbleSeries2: [],
      bubbleOptions2: {},

      bubbleData1: [{ price_item_cid: [] }],
      bubbleData2: [{ price_item_cid: [] }],

      resDataDropdown: "",
      resDataTopBar: "",
      resDataMediumBar: "",
      myColors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
      myFontSize: [15, 80],
      myFontScale: "log",
      defaultWords: [],
      resDataWords: "",
      resDataBubble: "",
      cloudLoading: false,
      highchartsOptions: {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: "xy",
        },
        title: { text: "" },
        xAxis: {
          categories: [
            "18-24歲",
            "25-29歲",
            "30-34歲",
            "35-39歲",
            "40-44歲",
            "45-49歲",
            "50-54歲",
            "55歲+",
          ],
        },
        yAxis: {
          title: { text: "人數" },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        series: [
          {
            name: "",
            data: [
              { x: 95, y: 95, z: 13.8, name: "馬來西亞", country: "Malaysia" },
              { x: 86.5, y: 102.9, z: 14.7, name: "台灣", country: "taiwan" },
              { x: 80.8, y: 91.5, z: 15.8, name: "中國", country: "china" },
              { x: 80.4, y: 102.5, z: 12, name: "越南", country: "vietnam" },
              { x: 80.3, y: 86.1, z: 11.8, name: "瑞典", country: "Sweden" },
              {
                x: 78.4,
                y: 70.1,
                z: 16.6,
                name: "荷蘭",
                country: "Nerthelands",
              },
              {
                x: 74.2,
                y: 68.5,
                z: 14.5,
                name: "新加坡",
                country: "Singapore",
              },
            ],
          },
        ],
      },
      highchartsOptions2: {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: "xy",
        },
        title: { text: "" },
        xAxis: {
          categories: [
            "18-24歲",
            "25-29歲",
            "30-34歲",
            "35-39歲",
            "40-44歲",
            "45-49歲",
            "50-54歲",
            "55歲+",
          ],
        },
        yAxis: {
          title: { text: "人數" },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        colors: ["#71AFB6", "#92BB92", "D197CC"],
        series: [
          {
            name: "",
            data: [
              { x: 95, y: 95, z: 13.8, name: "馬來西亞", country: "Malaysia" },
              { x: 86.5, y: 102.9, z: 14.7, name: "台灣", country: "taiwan" },
              { x: 80.8, y: 91.5, z: 15.8, name: "中國", country: "china" },
              { x: 80.4, y: 102.5, z: 12, name: "越南", country: "vietnam" },
              { x: 80.3, y: 86.1, z: 11.8, name: "瑞典", country: "Sweden" },
              {
                x: 78.4,
                y: 70.1,
                z: 16.6,
                name: "荷蘭",
                country: "Nerthelands",
              },
              {
                x: 74.2,
                y: 68.5,
                z: 14.5,
                name: "新加坡",
                country: "Singapore",
              },
            ],
          },
          {
            data: [
              { x: 25, y: 95, z: 13.8, name: "德國", country: "Malaysia" },
              { x: 36.5, y: 102.9, z: 14.7, name: "南非", country: "taiwan" },
              { x: 30.8, y: 91.5, z: 15.8, name: "西班牙", country: "china" },
              { x: 60.4, y: 102.5, z: 12, name: "葡萄牙", country: "vietnam" },
              { x: 100.3, y: 86.1, z: 11.8, name: "英國", country: "Sweden" },
              {
                x: 576.4,
                y: 70.1,
                z: 16.6,
                name: "文萊",
                country: "Nerthelands",
              },
              {
                x: 224.2,
                y: 68.5,
                z: 14.5,
                name: "香港",
                country: "Singapore",
              },
            ],
          },
        ],
      },
    };
  },
  watch: {},

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getOnlineCfLabelCatesGet(); //firstDropdown
    // await //topBarchart

    this.whichWeb = this.websites[0];

    await Promise.all([
      this.getOnlineCfLabelValueGet(),
      this.onlineCfKeywordsGet(),
      this.getonlineCfClick10Get(),
      this.getonlineCfClickBubbleGet(),
    ]);
    this.TopFirstBarchart();
    this.MediumFirstBarchart();
    this.MediumSecondBarchart();
    this.HighchartsBubble();
    this.HighchartsBubble2();
    this.loading = false;
    // console.log('array',this.mediumBarData2)
    console.log("window", window.pageWidth);
    console.log("creted whic", this.whichWeb);
  },

  methods: {
    changeWeb: async function (web) {
      if (web != undefined) {
        this.whichWeb = web;
      }
      // 上面bar chart
      this.topBarData =
        this.resDataTopBar[this.whichWeb][this.DropdownSelected].counts;
      this.topBarLabel =
        this.resDataTopBar[this.whichWeb][this.DropdownSelected].value;

      this.secondaryDropdownKey =
        this.resDataDropdown[this.whichWeb][this.DropdownSelected2];
      this.secondarySelected = this.secondaryDropdownKey[0];

      // 中閒bar chart
      this.mediumBarData1 =
        this.resDataMediumBar[this.whichWeb].瀏覽商品[this.DropdownSelected2][
          this.secondarySelected
        ].count;
      this.mediumBarLabel1 =
        this.resDataMediumBar[this.whichWeb].瀏覽商品[this.DropdownSelected2][
          this.secondarySelected
        ].product;
      if (this.resDataMediumBar[this.whichWeb].購買商品 != undefined) {
        this.mediumBarData2 =
          this.resDataMediumBar[this.whichWeb].購買商品[this.DropdownSelected2][
            this.secondarySelected
          ].count;
        this.mediumBarLabel2 =
          this.resDataMediumBar[this.whichWeb].購買商品[this.DropdownSelected2][
            this.secondarySelected
          ].product;
      } else {
        this.mediumBarData2 = [];
        this.mediumBarLabel2 = [];
      }
      if (this.resDataBubble[this.whichWeb] != undefined) {
        this.bubbleData1 =
          this.resDataBubble[this.whichWeb].瀏覽商品[this.DropdownSelected3];
        this.bubbleData2 =
          this.resDataBubble[this.whichWeb].購買商品[this.DropdownSelected3];
        console.log("this.bubbleData1", this.bubbleData1);
      } else {
        this.bubbleData1 = [{ price_item_cid: [] }];
        this.bubbleData2 = [{ price_item_cid: [] }];
        console.log("hehe,webchange");
      }

      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.TopFirstBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
      await this.onlineCfKeywordsGet();
    },
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    // 上面第一個barchart dropdown onchange 做的事
    selectValueByDrop(index) {
      this.DropdownSelected = index;
      this.topBarData =
        this.resDataTopBar[this.whichWeb][this.DropdownSelected].counts;
      this.topBarLabel =
        this.resDataTopBar[this.whichWeb][this.DropdownSelected].value;
      this.TopFirstBarchart();
    },
    // 中間第一個barchart  dropdown onchange 做的事
    selectValueByDrop2(index) {
      this.DropdownSelected2 = index;
      this.secondaryDropdownKey =
        this.resDataDropdown[this.whichWeb][this.DropdownSelected2];
      this.secondarySelected = this.secondaryDropdownKey[0];
      this.mediumBarData1 =
        this.resDataMediumBar[this.whichWeb].瀏覽商品[this.DropdownSelected2][
          this.secondarySelected
        ].count;
      this.mediumBarLabel1 =
        this.resDataMediumBar[this.whichWeb].瀏覽商品[this.DropdownSelected2][
          this.secondarySelected
        ].product;
      if (this.resDataMediumBar[this.whichWeb].購買商品 != undefined) {
        this.mediumBarData2 =
          this.resDataMediumBar[this.whichWeb].購買商品[this.DropdownSelected2][
            this.secondarySelected
          ].count;
        this.mediumBarLabel2 =
          this.resDataMediumBar[this.whichWeb].購買商品[this.DropdownSelected2][
            this.secondarySelected
          ].product;
      } else {
        this.mediumBarData2 = [];
        this.mediumBarLabel2 = [];
      }

      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
    },
    // 中間第二個barchart  dropdown onchange 做的事
    selectSecondaryByDrop(index) {
      this.secondarySelected = index;
      this.mediumBarData1 =
        this.resDataMediumBar[this.whichWeb].瀏覽商品[this.DropdownSelected2][
          this.secondarySelected
        ].count;
      this.mediumBarLabel1 =
        this.resDataMediumBar[this.whichWeb].瀏覽商品[this.DropdownSelected2][
          this.secondarySelected
        ].product;

      if (this.resDataMediumBar[this.whichWeb].購買商品 != undefined) {
        if (
          this.resDataMediumBar[this.whichWeb].購買商品[this.DropdownSelected2][
            this.secondarySelected
          ] != undefined
        ) {
          this.mediumBarData2 =
            this.resDataMediumBar[this.whichWeb].購買商品[
              this.DropdownSelected2
            ][this.secondarySelected].count;
          this.mediumBarLabel2 =
            this.resDataMediumBar[this.whichWeb].購買商品[
              this.DropdownSelected2
            ][this.secondarySelected].product;
        } else {
          this.mediumBarData2 = [];
          this.mediumBarLabel2 = [];
        }
      } else {
        this.mediumBarData2 = [];
        this.mediumBarLabel2 = [];
      }

      console.log("aa", this.resDataMediumBar[this.whichWeb].購買商品);

      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
    },
    // 下面bubble chart 的dropdown onchange
    selectValueByDrop3(index) {
      this.DropdownSelected3 = index;
      this.bubbleData1 =
        this.resDataBubble[this.whichWeb].瀏覽商品[this.DropdownSelected3];
      this.bubbleData2 =
        this.resDataBubble[this.whichWeb].購買商品[this.DropdownSelected3];

      if (this.resDataBubble[this.whichWeb] != undefined) {
        this.bubbleData1 =
          this.resDataBubble[this.whichWeb].瀏覽商品[this.DropdownSelected3];
        this.bubbleData2 =
          this.resDataBubble[this.whichWeb].購買商品[this.DropdownSelected3];
      } else {
        this.bubbleData1 = [{ price_item_cid: [] }];
        this.bubbleData2 = [{ price_item_cid: [] }];
      }

      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
    },

    // wordcloud 文字云
    wordClickHandler(name, value, vm) {
      console.log("wordClickHandler", name, value, vm);
    },

    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近1天";
      await this.getOnlineCfLabelCatesGet();
      await Promise.all([
        //firstDropdown
        this.getOnlineCfLabelValueGet(),
        this.onlineCfKeywordsGet(),
        this.getonlineCfClick10Get(),
        this.getonlineCfClickBubbleGet(),
      ]);
      this.TopFirstBarchart();
      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
      this.changeWeb();
      this.loading = false;
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近7天";

      await this.getOnlineCfLabelCatesGet();
      await Promise.all([
        //firstDropdown
        this.getOnlineCfLabelValueGet(),
        this.onlineCfKeywordsGet(),
        this.getonlineCfClick10Get(),
        this.getonlineCfClickBubbleGet(),
      ]);
      this.loading = false;
      this.TopFirstBarchart();
      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
      this.changeWeb();
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      console.log("start", this.startDate);
      console.log("end", this.endDate);
      this.dateSelector = "最近30天";

      await this.getOnlineCfLabelCatesGet(),
        await Promise.all([
          //firstDropdown
          this.getOnlineCfLabelValueGet(),
          this.onlineCfKeywordsGet(),
          this.getonlineCfClick10Get(),
          this.getonlineCfClickBubbleGet(),
        ]);
      this.loading = false;
      this.TopFirstBarchart();
      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
      this.changeWeb();
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近一年";
      console.log("start", this.startDate);
      console.log("end", this.endDate);
      this.changeWeb();
      await this.getOnlineCfLabelCatesGet();
      await Promise.all([
        this.getOnlineCfLabelValueGet(),
        this.onlineCfKeywordsGet(),
        this.getonlineCfClick10Get(),
        this.getonlineCfClickBubbleGet(),
      ]);
      this.loading = false;
      this.TopFirstBarchart();
      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      this.dateSelector = "全部";
      this.changeWeb();
      this.changeWeb();
      await this.getOnlineCfLabelCatesGet();
      await Promise.all([
        this.getOnlineCfLabelValueGet(),
        this.onlineCfKeywordsGet(),
        this.getonlineCfClick10Get(),
        this.getonlineCfClickBubbleGet(),
      ]);
      this.loading = false;
      this.TopFirstBarchart();
      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
    },

    customDatePicker: async function () {
      this.loading = true;
      this.startDate = this.vue3Date.start;
      this.endDate = this.vue3Date.end;
      this.dateSelector = "自訂";
      await this.getOnlineCfLabelCatesGet();
      await Promise.all([
        //firstDropdown
        this.getOnlineCfLabelValueGet(),
        this.onlineCfKeywordsGet(),
        this.getonlineCfClick10Get(),
        this.getonlineCfClickBubbleGet(),
      ]);
      this.loading = true;
      this.TopFirstBarchart();
      this.MediumFirstBarchart();
      this.MediumSecondBarchart();
      this.BubbleChart1();
      this.BubbleChart2();
      this.HighchartsBubble();
      this.HighchartsBubble2();
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeOnlineGet(params)
        .then((res) => {
          console.log(res.data.Data);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
          //   if (res.data.Data.start_date === null) {
          //     this.laurel = false;
          //   } else {
          //     this.laurel = true;
          //   }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    //Dropdown 資料
    getOnlineCfLabelCatesGet: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };
      await allapi
        .onlineCfLabelCatesGet(params)
        .then((res) => {
          this.resDataDropdown = res.data.Data;
          this.websites = Object.keys(res.data.Data);
          console.log("dropdown", res);
          if (this.whichWeb === "") {
            this.whichWeb = this.websites[0];
          }
          this.mainDropdownKey = Object.keys(res.data.Data[this.whichWeb]);
          this.DropdownSelected = this.mainDropdownKey[0];
          this.DropdownSelected2 = this.mainDropdownKey[0];
          this.DropdownSelected3 = this.mainDropdownKey[0];
          if (
            Object.keys(this.resDataDropdown[this.whichWeb])[0] != undefined
          ) {
            this.secondaryDropdownKey =
              this.resDataDropdown[this.whichWeb][this.DropdownSelected2];
            this.secondarySelected = this.secondaryDropdownKey[0];
          }
          console.log("this.mainDropdownKey", this.mainDropdownKey);
          console.log("whichWeb", this.whichWeb);
          console.log("secondarykey", this.secondaryDropdownKey);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    // top barchart
    getOnlineCfLabelValueGet: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };
      await allapi
        .onlineCfLabelValueGet(params)
        .then((res) => {
          console.log("getOnlineCfLabelValueGet", res);
          this.resDataTopBar = res.data.Data;
          if (this.resDataTopBar[this.whichWeb] != undefined) {
            this.topBarData =
              this.resDataTopBar[this.whichWeb][this.DropdownSelected].counts;
            this.topBarLabel =
              this.resDataTopBar[this.whichWeb][this.DropdownSelected].value;
          } else {
            this.topBarData = [];
          }
          console.log(" this.topBarData", this.topBarData);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    // wordcloud api
    onlineCfKeywordsGet: async function () {
      this.cloudLoading = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };
      await allapi
        .onlineCfKeywordsGet(params)
        .then((res) => {
          console.log("word", res);
          this.resDataWords = res.data.Data;

          if (this.resDataWords[this.whichWeb] === undefined) {
            this.defaultWords = [];
          } else {
            this.defaultWords = this.resDataWords[this.whichWeb];
          }
          console.log(" this.defaultWords", this.defaultWords);
          this.cloudLoading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          this.cloudLoading = false;
          alert("連線有誤");
        });
    },
    // mediumBar api
    getonlineCfClick10Get: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineCfClick10Get(params)
        .then((res) => {
          console.log("getonlineCfClick10Get", res);
          this.resDataMediumBar = res.data.Data;
          if (
            this.resDataMediumBar[this.whichWeb] != undefined &&
            this.resDataMediumBar[this.whichWeb].瀏覽商品 != undefined
          ) {
            this.mediumBarData1 =
              this.resDataMediumBar[this.whichWeb].瀏覽商品[
                this.DropdownSelected2
              ][this.secondarySelected].count;
            this.mediumBarLabel1 =
              this.resDataMediumBar[this.whichWeb].瀏覽商品[
                this.DropdownSelected2
              ][this.secondarySelected].product;
          } else {
            this.mediumBarData1 = [];
            this.mediumBarLabel1 = [];
          }
          if (
            this.resDataMediumBar[this.whichWeb] != undefined &&
            this.resDataMediumBar[this.whichWeb].購買商品 != undefined
          ) {
            this.mediumBarData2 =
              this.resDataMediumBar[this.whichWeb].購買商品[
                this.DropdownSelected2
              ][this.secondarySelected].count;
            this.mediumBarLabel2 =
              this.resDataMediumBar[this.whichWeb].購買商品[
                this.DropdownSelected2
              ][this.secondarySelected].product;
          } else {
            this.mediumBarData2 = [];
            this.mediumBarLabel2 = [];
          }

          console.log("this.mediumBardata1", this.mediumBarData2);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    //bubble api
    getonlineCfClickBubbleGet: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };
      await allapi
        .onlineCfClickBubbleGet(params)
        .then((res) => {
          console.log("bubbleget", res);
          this.resDataBubble = res.data.Data;
          // this.bubbleData1 = this.resDataBubble[this.whichWeb]
          if (this.resDataBubble[this.whichWeb] != undefined) {
            this.bubbleData1 =
              this.resDataBubble[this.whichWeb].瀏覽商品[
                this.DropdownSelected3
              ];
            this.bubbleData2 =
              this.resDataBubble[this.whichWeb].購買商品[
                this.DropdownSelected3
              ];
            console.log("this.bubbleData1", this.bubbleData1);
          } else {
            this.bubbleData1 = [{ price_item_cid: [] }];
            this.bubbleData2 = [{ price_item_cid: [] }];
            console.log("hehe");
          }
          console.log("this.bubbleData1", this.bubbleData1);
          console.log("this.bubbleData2", this.bubbleData2);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    // 第一個,最上面的barchart
    TopFirstBarchart() {
      (this.topBarSeries = [
        {
          name: "人數",
          data: this.topBarData,
        },
      ]),
        (this.topBarOptions = {
          chart: {
            type: "bar",
            height: 420,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          colors: ["#71AFB6"],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: this.topBarLabel,
            title: {
              text: "人數",
            },
          },
          yaxis: {
            title: {
              text: "",
            },
            labels: {
              minWidth: 90,
              style: {
                fontSize: "12px",
              },
            },
          },
        });
    },
    // 中間,第一個的barchart
    MediumFirstBarchart() {
      const vm = this;
      console.log("this.mediumBarData1", this.mediumBarData1);
      (this.mediumBarSeries1 = [
        {
          name: "瀏覽次數",
          data: this.mediumBarData1,
          // data: [],
        },
      ]),
        (this.mediumBarOptions1 = {
          chart: {
            type: "bar",
            height: 420,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          colors: ["#71AFB6"],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            x: {
              formatter: function (value, { dataPointIndex, w }) {
                return vm.mediumBarLabel1[dataPointIndex];
              },
            },
          },
          xaxis: {
            type: "category",
            categories: this.mediumBarLabel1,

            title: {
              text: "",
            },
            labels: {
              show: true,
              rotateAlways: false,
              hideOverlappingLabels: false,
              formatter: function (x) {
                if (x != undefined && x != null) {
                  const a = x;
                  let c = "";
                  if (x.length > 10) {
                    c = a.slice(0, 7) + "...";
                  } else {
                    c = a;
                  }
                  return c;
                } else {
                  return x;
                }
              },
            },
          },
          yaxis: {
            title: {
              text: "瀏覽次數",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        });
    },
    // 中間,第二個的barchart
    MediumSecondBarchart() {
      const vm = this;
      this.mediumBarSeries2 = [
        {
          name: "",
          data: this.mediumBarData2,
          // data: [],
        },
      ];
      this.mediumBarOptions2 = {
        chart: {
          type: "bar",
          height: 420,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            formatter: function (value, { dataPointIndex, w }) {
              return vm.mediumBarLabel2[dataPointIndex];
            },
          },
        },
        xaxis: {
          categories: this.mediumBarLabel2,
          title: {
            text: "",
          },
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: false,
            formatter: function (x) {
              if (x != undefined && x != null) {
                const a = x;
                let c = "";
                if (x.length > 10) {
                  c = a.slice(0, 7) + "...";
                } else {
                  c = a;
                }
                return c;
              } else {
                return x;
              }
            },
          },
        },
        yaxis: {
          title: {
            text: "瀏覽次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      };
    },
    // apex-bubble
    BubbleChart1() {
      let series = [
        {
          name: "",
          data: [],
        },
      ];
      let bubbleobj = {};

      for (let index = 0; index < this.bubbleData1.length; index++) {
        bubbleobj = {
          name: this.bubbleData1[index].value,
          data: [this.bubbleData1[index].price_item_cid],
        };
        series.push(bubbleobj);
      }
      // console
      console.log("arr", series);

      (this.bubbleSeries = series),
        (this.bubbleOptions = {
          chart: {
            height: 350,
            type: "bubble",
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.8,
          },
          title: {
            text: "",
          },
          legend: {
            show: false,
          },
          xaxis: {
            tickAmount: 12,
            type: "category",
          },
          yaxis: {
            labels: {
              formatter: function (y) {
                return y.toFixed(2);
              },
            },
          },
        });
    },
    // apex-bubble
    BubbleChart2() {
      let series = [];
      let bubbleobj = {};

      for (let index = 0; index < this.bubbleData2.length; index++) {
        bubbleobj = {
          name: this.bubbleData2[index].value,
          data: [this.bubbleData2[index].price_item_cid],
        };
        series.push(bubbleobj);
      }
      // console
      console.log("arr", series);

      (this.bubbleSeries2 = series),
        (this.bubbleOptions2 = {
          chart: {
            height: 350,
            type: "bubble",
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.8,
          },
          title: {
            text: "",
          },
          xaxis: {
            tickAmount: 12,
            type: "category",
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              formatter: function (y) {
                return y.toFixed(2);
              },
            },
          },
        });
    },
    // highcharts-bubble
    HighchartsBubble() {
      let series = [
        {
          name: "",
          data: [],
        },
      ];
      let bubbleobj = {};

      for (let index = 0; index < this.bubbleData1.length; index++) {
        if (this.bubbleData1[index].price_item_cid[0] != undefined) {
          bubbleobj = {
            data: [
              {
                x: Number(this.bubbleData1[index].price_item_cid[0].toFixed(2)),
                y: Number(this.bubbleData1[index].price_item_cid[1].toFixed(2)),
                z: Number(this.bubbleData1[index].price_item_cid[2].toFixed(2)),
                name: this.bubbleData1[index].value,
              },
            ],
          };
          series.push(bubbleobj);
        }
      }

      // console
      console.log("arr", series);
      console.log("bubbleobj", bubbleobj);
      this.highchartsOptions = {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: "xy",
        },
        colors: [
          "#71AFB6",
          "#92BB92",
          "#F9C357",
          "#7072D1",
          "#DD8EA5",
          "#FC9F66",
          "#D197CC",
        ],
        legend: {
          enabled: false,
        },
        title: { text: "" },
        xAxis: {
          title: { text: "瀏覽商品金額(NT$)" },
          categories: [
            "18-24歲",
            "25-29歲",
            "30-34歲",
            "35-39歲",
            "40-44歲",
            "45-49歲",
            "50-54歲",
            "55歲+",
          ],
        },
        yAxis: {
          title: { text: "瀏覽次數" },
        },

        tooltip: {
          useHTML: true,
          headerFormat: "<table>",
          pointFormat:
            '<tr><th colspan="2"><h5>{point.name}</h5></th></tr>' +
            "<tr><th>平均瀏覽金額:</th><td>{point.x}</td></tr>" +
            "<tr><th>平均瀏覽次數:</th><td>{point.y}</td></tr>" +
            "<tr><th>人數:</th><td>{point.z}</td></tr>",
          footerFormat: "</table>",
          followPointer: true,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        series: series,
      };
    },
    HighchartsBubble2() {
      let series = [];
      let bubbleobj = {};

      for (let index = 0; index < this.bubbleData2.length; index++) {
        if (this.bubbleData2[index].price_item_cid[0] != undefined) {
          bubbleobj = {
            data: [
              {
                x: Number(this.bubbleData2[index].price_item_cid[0].toFixed(2)),
                y: Number(this.bubbleData2[index].price_item_cid[1].toFixed(2)),
                z: Number(this.bubbleData2[index].price_item_cid[2].toFixed(2)),
                name: this.bubbleData2[index].value,
              },
            ],
          };
          series.push(bubbleobj);
        }
      }
      this.highchartsOptions2 = {
        chart: {
          type: "bubble",
          plotBorderWidth: 1,
          zoomType: "xy",
        },
        colors: [
          "#71AFB6",
          "#92BB92",
          "#F9C357",
          "#7072D1",
          "#DD8EA5",
          "#FC9F66",
          "#D197CC",
        ],
        legend: {
          enabled: false,
        },
        title: { text: "" },
        xAxis: {
          title: { text: "購買商品金額(NT$)" },
          categories: [
            "18-24歲",
            "25-29歲",
            "30-34歲",
            "35-39歲",
            "40-44歲",
            "45-49歲",
            "50-54歲",
            "55歲+",
          ],
        },
        yAxis: {
          title: { text: "購買次數" },
        },

        tooltip: {
          useHTML: true,
          headerFormat: "<table>",
          pointFormat:
            '<tr><th colspan="2"><h5>{point.name}</h5></th></tr>' +
            "<tr><th>平均購買金額:</th><td>{point.x}</td></tr>" +
            "<tr><th>平均購買次數:</th><td>{point.y}</td></tr>" +
            "<tr><th>人數:</th><td>{point.z}</td></tr>",
          footerFormat: "</table>",
          followPointer: true,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        },
        series: series,
      };
    },
  },
};
</script>


<style scoped>
.clickforce-1row-div {
  padding: 10px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.clickforce-1row-div-wordcloud {
  padding: 10px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 470px;
  /* overflow-y: scroll; */
}

.number-label {
  color: #71afb6;
  font-size: 18px;
}

.chart-border {
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  padding: 10px;
  height: 480px;
}

.chart-border-freeheight {
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  padding: 10px;
  height: 500px;
}
:deep(.highcharts-credits) {
  display: none;
}
</style>