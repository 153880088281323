<template>
  <div class="container-fluid home">
    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- alert變數Modal -->
    <div
      class="modal fade"
      id="varnameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @click="autoShow = false">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body" style="text-align: left">
            <div class="d-flex justify-content-start align-items-center mb-2">
              <div class="col-4 p-0" style="color: #71afb6">插入個人化變數</div>
              <div
                class="search-container col-6 p-0"
                style="position: relative"
              >
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                    padding: 0 14px;
                    height: 35px;
                  "
                  @click.stop="autoShow = !autoShow"
                >
                  {{ triggerType }}
                  <img src="../assets/selection-arrow.png" alt="searchImg" />
                </div>
                <div
                  class="auto-complicate-div"
                  style="width: 100%"
                  v-show="autoShow"
                  @mouseenter="autoShow = true"
                >
                  <p
                    class="m-0 p-1 px-3 auto-complicate-p"
                    @click.stop="
                      triggerType = '顧客姓名';
                      varName = '{name}';
                      autoShow = false;
                    "
                  >
                    顧客姓名
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <p class="mb-4" style="color: #8a8a8a">
                複製下方變數並插入文字中，使信件客製化；可讓信件中加入訂閱者的姓名，增加親切感。(英文皆以全名呈現)
              </p>
              <div class="d-flex align-items-center mb-4">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客姓名</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  "
                  v-clipboard:copy="varName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
              <div class="d-flex align-items-center mb-4">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客姓</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varLastName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  "
                  v-clipboard:copy="varLastName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
              <div class="d-flex align-items-center">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客名</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varFirstName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  "
                  v-clipboard:copy="varFirstName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sms modal -->
    <SmsPreview :countText="countText" :resSubsec="expSubsec"></SmsPreview>
    
    <!-- alert修改成功Modal -->
    <div
      class="modal fade"
      id="alertModal3"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">修改成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert添加成功Modal -->
    <div
      class="modal fade"
      id="alertModal4"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">添加成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert刪除成功Modal -->
    <div
      class="modal fade"
      id="deleteSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- main -->
    <div class="title-span" style="padding-left: 10px">
      簡訊模板
      <!-- <img
        src="../assets/peopleNumbers.svg"
        alt="leftArrow"
        class="ml-5 p-0 m-0"
      />
      <span v-if="group != null">{{ group }}{{ number }}人 </span>
      <span v-else> 目前沒選擇分群</span> -->
    </div>
    <div class="container-fluid">
      <div class="p-4 form-container2" style="margin-top: 36px">
        <ProductSmsList
          v-bind:todos="todos"
          ref="RefChilde"
          @toggle-value="selectValue"
          @copy-todo="copytodo"
          @refresh-todos="refreshtodo"
          @clearall="clearall"
          @showDeleteSuccess="showDeleteSuccess"
          v-on:changeLoading="changeLoading"
          v-bind:selectedID="selectedID"
          v-on:search="search"
        />
      </div>
    </div>

    <div class="container-fluid">
      <div class="p-4 mt-5 form-container">
        <div class="row justify-content-between align-items-center">
          <div class="col-3 section-title">模板內容</div>
          <div class="col-3">
            <button
              class="basic-btn highlight-SmsTemp"
              style="cursor: pointer"
              @click="showVar"
            >
              <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/插-icon.svg" alt="" class="pe-2 pt-1" />

                <span>插入個人化變數</span>
              </div>
            </button>
          </div>
        </div>

        <div class="row pt-5">
          <label for="" class="section-title" style="width: 13%"
            >模板名稱</label
          >
          <div style="width: 87%">
            <input
              v-bind:class="{ 'is-invalid': titleError }"
              ref="titleInput"
              name="add-input"
              class="form-control"
              v-model="newTodoTitle"
              @click="titleChange()"
            />
            <div class="invalid-feedback">
              {{ titleErrMsg }}
            </div>
            <!-- {{newTodoTitleSession}} -->
          </div>
        </div>

        <div class="mb-3 row mt-4">
          <div style="width: 13%">
            <label for="exampleFormControlTextarea1" class="form-label"
              >簡訊內容</label
            >
          </div>
          <div style="width: 87%">
            <textarea
              v-bind:class="{ 'is-invalid': textError }"
              ref="textInput"
              class="form-control"
              id="exampleFormControlTextarea1"
              v-model="newSubsec"
              :todo="todos"
              style="height: 130px"
              @click="textChange()"
            ></textarea>
            <div class="invalid-feedback">
              {{ textErrMsg }}
            </div>
            <div>中英數簡訊一封70字元 (縮址佔37字元)，若超過則會分為兩則。</div>
          </div>
        </div>

        <!-- <div class="mb-3 row">
          <div class="col-2">
            <label for="exampleFormControlTextarea1" class="form-label"
              >點擊追蹤</label
            >
          </div>
          <div class="col-8 col-lg-7 col-xl-8">
            <input
              ref="titleInput"
              name="add-input"
              class="form-control"
              v-model="fullurl"
            />
             <p style="color:#71AFB6">原始追蹤網址，請輸入完整的URL，包含http://或://。短網址可自行移動到想放置的地方。</p> 
          </div>
          <div class="col-2 d-flex justify-content-end">
            <button class="btn button-color" @click="addShortUrl">
              產生短網址
            </button>
          </div>
        </div> -->
        <div class="row mb-3">
          <div style="width: 13%">
            <span class="banner-span" style="line-height: 56px">點擊追蹤</span>
          </div>
          <div style="width: 87%">
            <p class="upload-sub mt-3" style="color: #71afb6">
              請輸入原始網址連結，系統將會自動轉為縮址以便追蹤，建議點擊預覽模板即可測試並觀看縮址後的結果。
            </p>
            <!-- <p>
              點右側連結測試短網址效果
              <a :href="shortUrl" target="_blank">{{ shortUrl }}</a>
            </p> -->
          </div>
        </div>
        <div class="row mt-3 pb-3">
          <div class="col-12 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-primary button-color"
              style="width: 40%"
              @click="expshow"
            >
              <img src="../assets/eye.svg" style="width: 1.5rem" />
              預覽簡訊模板
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container-fluid" v-if="!notshow">
      <div class="mt-5 p-4 form-container">
        <div class="">
          <div class="row">
            <span style="color: #71afb6"
              >投放金額
              <button
                class="btn btn-default button px-0 mx-0 pt-0"
                @click="showMoneyPop()"
              >
                <span>
                  <img src="../assets/question-img.svg" style="width: 1.5rem"
                /></span>
                <div class="parent-popout">
                  <div v-show="isShowMoney" class="child-popout9">
                    &nbsp;
                    每封金額為0.05(NT$),試算投放金額僅依造預估數量來做計算,最後金額會以實際寄出的信件數量為準。
                    收費方式會以月結制來計算,每月累計來進行收費,帳單資訊與明細將會以正式管道額外提供。<br />
                    若單日超過五萬封,或對於收費有任何疑問,請聯絡專人協助02-2785-3858。
                    <div class="container__arrow"></div>
                  </div>
                </div>
              </button>
            </span>
          </div>
          <div class="mb-3 mt-3 row">
            <div class="col-sm-5">
              <input
                type=""
                class="form-control"
                id="inputPassword"
                placeholder="NT"
              />
            </div>
            <div class="col-sm-5">
              <button type="button" class="btn button-color">進行試算</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row mt-4 mx-5 pb-5 justify-content-between">
      <div class="col-5" v-if="this.selectedID != ''">
        <button
          class="btn btn-primary add-template-button-color"
          style="width: 100%; border-radius: 10px"
          @click="updateNewTodo"
        >
          <img
            src="../assets/save-icon-blue.svg"
            alt="save"
            style="position: relative; top: -2px"
          />
          <span style="color: #71afb6"> &nbsp;&nbsp;儲存 </span>
        </button>
      </div>
      <div class="col-5" v-else>
        <button
          type="submit"
          class="btn btn-primary add-template-button-color"
          style="color: #71afb6; width: 100%; border-radius: 10px"
          @click="addNewTodo"
        >
          <img
            src="../assets/save-icon-blue.svg"
            alt="save"
            style="position: relative; top: -2px"
          />
          &nbsp;&nbsp;建立
        </button>
      </div>
      <div class="col-5">
        <button
          type="button"
          class="btn btn-primary button-color"
          style="width: 100%"
          @click="goSetting"
        >
          儲存及下一步
          <img
            src="../assets/gonext-arrow.svg"
            alt="save"
            style="position: relative; top: -2px"
          />
        </button>
      </div>
      <!-- <button @click="test">test</button> -->
    </div>
  </div>
</template>

<script>
import ProductSmsList from "../components/ProductSmsTemplate/ProductSmsList.vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import SmsPreview from "../components/SmsPreview.vue";

export default {
  name: "ProductSmsTemplate",
  components: {
    ProductSmsList,
    SmsPreview,
  },

  data() {
    return {
      varok: false,
      realLength: "",
      random_id: "",
      notshow: true,
      loading: false,
      groupId: "",
      group: "",
      number: "",
      i: 1,

      todos: [],
      titleError: false,
      titleErrMsg: "",
      subError: false,
      subErrMsg: "",
      textError: false,
      textErrMsg: "",
      noBanner: false,
      selectedID: "",
      eventName: "",
      eventNameError: false,
      eventNameErrMsg: "",

      newTodoTitle: "",
      newSubsec: "",
      fullurl: "",
      shortUrl: "",

      searchQuery: "",
      searchTodos: [],
      schedules: [],
      childtodo: [],
      editinput: false,
      editfile: false,
      editimage: false,

      beClick: true,
      enableABtest: false,
      enableBtemplate: true,
      beClick4: true,

      isShow: false,
      isShow2: false,
      isShow3: false,
      isShowMoney: false,

      fullurlArr: [],
      shortUrlArr: [],
      urlok: true,
      expSubsec: "",
      goSet: false,

      newText: "",
      testarr: [],

      alertModal3: {},
      alertModal4: {},
      varnameModal: {},

      varName: "{name}",
      varLastName: "{lastname}",
      varFirstName: "{firstname}",
      triggerType: "顧客姓名",
      autoShow: false,

      countText: "",
    };
  },

  created: async function () {
    this.group = sessionStorage.getItem("getlabelTitle");
    this.groupId = sessionStorage.getItem("getlabelId");
    this.number = sessionStorage.getItem("getlabelNumber");
    this.random_id = "T_" + this.randomString(8);
    await this.get();
    console.log("random_id", this.random_id);
    this.todos.map((d) => {
      if (d.template_id === this.random_id) {
        this.random_id = "T_" + this.randomString(8);
      }
    });
  },
  watch: {
    newTodoTitle: function (val) {
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      if (isText.test(this.newTodoTitle)) {
        this.titleError = true;
        this.titleErrMsg = "名稱不能含有是有特殊字元";
      } else if (val === "") {
        this.titleError = true;
        this.titleErrMsg = "必須輸入模板名稱";
      } else if (val != "") {
        this.titleError = false;
      }
    },
    newSubsec: function (val) {
      if (val != "") {
        this.textError = false;
      } else if (val === "") {
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      }
    },
  },
  methods: {
    onCopy: function (e) {
      console.log("You just copied: " + e.text);
      this.varnameModal.hide();
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
    showVar() {
      this.varnameModal = new bootstrap.Modal(
        document.getElementById("varnameModal"),
        {
          keyboard: false,
        }
      );
      this.varnameModal.show();
    },
    test() {
      const realLength = function (str) {
        var realLength = 0,
          len = str.length,
          arr = [],
          charCode = -1;
        for (var i = 0; i < len; i++) {
          charCode = str.charCodeAt(i);
          if (charCode > 0 && charCode <= 128) realLength += 1;
          else realLength += 2;
          if (realLength === 28) {
            arr.push(i + 1);
          }
          if (realLength === 56) {
            arr.push(i + 1);
          }
          if (realLength === 84) {
            arr.push(i + 1);
          }
        }
        return arr;
      };
      console.log(realLength(this.newSubsec));
      // this.newSubsec.
      // if(realLength(this.newSubsec)[0]){
      //   this.newSubsec=val+'\n'
      // }
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },

    show() {
      this.isShow = !this.isShow;
    },
    show2() {
      this.isShow2 = !this.isShow2;
    },
    show3() {
      this.isShow3 = !this.isShow3;
    },
    showDeleteSuccess() {
      const deleteSuccessModal = new bootstrap.Modal(
        document.getElementById("deleteSuccessModal"),
        {
          keyboard: false,
        }
      );
      deleteSuccessModal.show();
    },

    titleChange() {
      this.editinput = true;
    },
    textChange() {
      this.editinput = true;
    },

    search(val) {
      const searched = this.searchTodos.filter((d) => d.name.includes(val));
      this.todos = searched;
    },
    clearall() {
      this.clearInputs();
      this.selectedID = "";
    },

    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .smsTemplateSmsTempInfoGet(params)
        .then((res) => {
          console.log("GET", res);
          this.loading = false;
          this.todos = res.data;
          this.todos.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });

          this.searchTodos = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    post: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        name: this.newTodoTitle,
        temp: { text: this.newSubsec, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTempInfoPost(params, body)
        .then((res) => {
          console.log(res);
          // this.alertModal4 = new bootstrap.Modal(
          //   document.getElementById("alertModal4"),
          //   {
          //     keyboard: false,
          //   }
          // );
          // this.alertModal4.show();
          // $("#alertModal4").modal("show");
          this.random_id = "T_" + this.randomString(8);
        })
        .catch((err) => {
          this.goSet = false;
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });

      await this.get();
      await this.selectValue(this.todos[0]);
      this.$refs.RefChilde.changePage();
    },
    patch: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.selectedID,
        name: this.newTodoTitle,
        temp: { text: this.newSubsec, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTempInfoPatch(params, body)
        .then((res) => {
          console.log(res);
          // this.alertModal3 = new bootstrap.Modal(
          //   document.getElementById("alertModal3"),
          //   {
          //     keyboard: false,
          //   }
          // );
          // this.alertModal3.show();
        })
        .catch((err) => {
          this.goSet = false;
          console.log("連線有誤");
          console.log(err);
          alert("patch連線有誤" + err);
          this.loading = false;
        });

      await this.get();
    },
    getShortUrl: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");

      let temp_id = this.random_id;

      if (this.selectedID != "") {
        temp_id = this.selectedID;
      }

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        template_id: temp_id,
      };
      await allapi2
        .smsScheduleShortenUrlPost(params, body)
        .then((res) => {
          console.log("GETUrl", res);

          this.newText = this.newText.replace(
            fullurl,
            res.data.url + "?i={id}"
          );
          this.expSubsec = this.expSubsec
            .replace(
              fullurl,
              "<a href='" +
                res.data.url +
                // "?i=" +
                // 'uid000' +
                "' target='_blank'>" +
                res.data.url +
                "?i=" +
                "uid000" +
                "</a>"
            )
            .replace(/{lastname}/g, "王")
            .replace(/{firstname}/g, "◯◯")
            .replace(/{name}/g, "王◯◯");

          console.log("this.newText", this.newText);
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.urlok = false;
          this.textError = true;
          this.textErrMsg = "短網址生成失敗。";
          this.loading = false;
          console.log(this.shortUrlArr);
        });
    },
    getAllShortHttp: async function () {
      this.urlok = true;
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrl(this.fullurlArr[index]);
      }
    },
    findAllURl: async function () {
      this.newText = this.newSubsec;
      this.expSubsec = this.newSubsec;
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newSubsec);
      console.log(this.fullurlArr);
      if (this.fullurlArr != null) {
        await this.getAllShortHttp();
      } else {
        this.textError = false;
        this.urlok = true;
      }

      this.expSubsec = this.expSubsec
        .replace(/{id}/g, "uid000")
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
    },
    checkVar() {
      function isValidURL(string) {
        var res = string.match(/\{{*.*?\}/g);
        return res;
      }
      const matchList = ["{name}", "{lastname}", "{firstname}", "{tracking}", "{id}"];
      const varArr = isValidURL(this.newSubsec);
      console.log(varArr);
      var boolean = [];
      if (varArr != null) {
        boolean = varArr.map((d) => {
          for (let index = 0; index < matchList.length; index++) {
            if (d === matchList[index]) {
              return true;
            }
          }
        });
      }
      console.log(boolean);
      this.varok = boolean.every((d) => d === true);
      console.log(this.varok);
    },

    expshow: async function () {
      await this.findAllURl();
      this.countText = this.newText
        .replace(/{id}/g, "uid000")
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      console.log("this.countText", this.countText);
      const exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
      this.checkVar();
      if (this.varok) {
        console.log("變數都符合要求");
      } else {
        this.loading = false;
        return alert("個人化變數不符合格式");
      }
    },
    clearInputs() {
      this.newTodoTitle = "";
      this.newSubsec = "";
      this.selectedID = "";
      this.titleError = false;
      this.textError = false;
    },
    refreshtodo: async function () {
      await this.get();
      // await this.selectValue(this.todos[0]);
    },
    changeLoading() {
      this.loading = !this.loading;
    },
    copytodo: async function (todo) {
      await this.selectValue(todo);
      this.loading = true;
      console.log(todo);
      this.childtodo = todo;
      while (
        this.todos.findIndex(
          (d) => d.name === this.childtodo.name + "(" + this.i + ")"
        ) != "-1"
      ) {
        this.i++;
      }
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        name: this.newTodoTitle + "(" + this.i + ")",
        temp: { text: this.newSubsec },
      };
      await allapi2
        .smsTemplateSmsTempInfoPost(params, body)
        .then((res) => {
          console.log(res);
          this.alertModal4 = new bootstrap.Modal(
            document.getElementById("alertModal4"),
            {
              keyboard: false,
            }
          );
          this.alertModal4.show();
          this.random_id = "T_" + this.randomString(8);
          // $("#alertModal4").modal("show");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
      await this.get();
      await this.selectValue(this.todos[0]);
      this.$refs.RefChilde.changePage();
    },
    addNewTodo: async function () {
      await this.findAllURl();
      this.checkVar();
      if (this.varok) {
        console.log("變數都符合要求");
      } else {
        this.loading = false;
        return alert("個人化變數不符合格式");
      }
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;

      if (this.todos.findIndex((d) => d.name === this.newTodoTitle) != "-1") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "您要使用的模板名稱已有其他模板使用";
      } else if (this.newTodoTitle === null || this.newTodoTitle === "") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "必須輸入標題";
      } else if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else if (isText.test(this.newTodoTitle)) {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "名稱不能含有是有特殊字元";
      } else {
        this.loading = true;
        // 上傳圖片API

        await this.post();
        // setTimeout(() => {
        //   this.alertModal4.hide();
        if (this.goSet) {
          sessionStorage.setItem("template_name", this.newTodoTitle);
          this.$router.push({
            name: "SmsSetting",
            params: {
              title: this.newTodoTitle,
              groupId: this.groupId,
              group: this.group,
            },
          });
        }
        // }, 500);
      }
    },
    updateNewTodo: async function () {
      await this.findAllURl();
      this.checkVar();
      if (this.varok) {
        console.log("變數都符合要求");
      } else {
        this.loading = false;
        return alert("個人化變數不符合格式");
      }
      console.log("?????");
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      if (
        this.todos.findIndex(
          (d) =>
            d.name === this.newTodoTitle &&
            this.newTodoTitle != this.childtodo.name
        ) != "-1"
      ) {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "您要使用的模板名稱已有其他模板使用";
      } else if (this.newTodoTitle === null || this.newTodoTitle === "") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "必須輸入標題";
      } else if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (isText.test(this.newTodoTitle)) {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "名稱不能含有是有特殊字元";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;

        await this.patch();
        // setTimeout(() => {
        //   this.alertModal3.hide();
        if (this.goSet) {
          sessionStorage.setItem("template_name", this.newTodoTitle);
          this.$router.push({
            name: "SmsSetting",
            params: {
              title: this.newTodoTitle,
              groupId: this.groupId,
              group: this.group,
            },
          });
        }
        // }, 500);

        // this.$refs.RefChilde.changePage();
        // window, scrollTo(0, 0);
        this.editinput = false;
      }
    },
    getTemplateById: async function (todo) {
      console.log(todo.key);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: todo.key,
      };

      await allapi2
        .smsTemplateSmsTempKeyGet(params)
        .then((res) => {
          console.log(res.data);
          this.newSubsec = res.data.data.text;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    selectValue: async function (todo) {
      console.log(todo);
      await this.getTemplateById(todo);
      console.log(this.fullurl);
      this.selectedID = todo.key;
      this.childtodo = todo;
      this.newTodoTitle = todo.name;
      this.titleError = false;
      this.textError = false;
      this.editinput = false;
      console.log("template_key", this.selectedID);
    },
    goSetting: async function () {
      console.log("this.selectedID", this.selectedID);
      if (this.selectedID === "") {
        this.goSet = true;
        sessionStorage.setItem("template_key", this.random_id);
        await this.addNewTodo();
      } else {
        this.goSet = true;
        console.log("template_key", this.selectedID);
        sessionStorage.setItem("template_key", this.selectedID);
        await this.updateNewTodo();
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-size: 24px;
  font-weight: bold;
  margin: 30px;
}

.home {
  /* padding: 50px; */
  padding-left: 67px;
  padding-top: 53px;
}
.form-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}

.form-container2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}

.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.section-title {
  color: #71afb6;
}

.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}

.add-template-button-color {
  border: none;
  background-color: #eaf3f4;
}

/* modal css */
.highlight-SmsTemp {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.highlight-SmsTemp-var {
  background-color: #71afb6;
  color: white;
}
/* .sms-bubble:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 4%;
  left: -0.8%;
  height: 15px;
  width: 20px;
  background: #e6e6e6;
  border-top-left-radius: 105px;
  border-bottom-right-radius: 55px;
} */
</style>