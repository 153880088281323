<template>
    <!-- product sales dropdown -->
    <Teleport to="#teleport-modal">
        <div v-if="selectTimeModal" class="modal-bg">
            <div class="modal-limit" ref="modal">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="selectTimeModal = false">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <p class="success-text">報表時間設定</p>

                    <div class="mt-3 d-flex align-items-center">
                        <div class="col-2">
                            <span>從</span>
                        </div>
                        <div class="col-10">
                            <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                @click="startDateModal()">
                                <span class="col-9 d-flex">
                                    {{ this.startDropdownSelect }}
                                </span>
                                <span class="col-3 d-flex justify-content-end">
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 d-flex align-items-center">
                        <div class="col-2">
                            <span>至</span>
                        </div>
                        <div class="col-10">
                            <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                @click="endDateModal()">
                                <span class="col-9 d-flex">
                                    {{ this.endDropdownSelect }}
                                </span>
                                <span class="col-3 d-flex justify-content-end">
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 d-flex justify-content-center">
                        <div class="col-6 d-flex justify-content-center">
                            <button class="dismiss-button" @click="selectTimeModal = false">
                                取消
                            </button>
                        </div>
                        <div class="col-6 6 d-flex justify-content-center">
                            <button class="download-button" @click="reportProductReportPost()"
                             :style="this.startDropdownSelect ==='選擇月份'||this.endDropdownSelect ==='選擇月份' 
                              ?'pointer-events:none; opacity:0.5' :''">
                                下載
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
    <!-- product month teleport start -->
    <Teleport to="#date-modal">
        <div v-if="monthModal" class="modal-bg">
            <div class="modal-limit" ref="modalDate">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownProduct()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
        
                    <!-- <month-picker @change="showDate" lang="zh" :min-date="cdpStartDate" :max-date="cdpEndDate">
                    </month-picker> -->
                                    <Datepicker
                                   v-model="monthData"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   monthPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.cdpStartDateMonth"
                                   :maxDate="this.cdpEndDateMonth"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>
    <!-- product month teleport end -->
    <Teleport to="#date-modal">
        <div v-if="monthModal2" class="modal-bg">
            <div class="modal-limit" ref="modalDate2">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownProduct()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                 
                    <!-- <month-picker @change="showDate2" lang="zh" :min-date="onlineStartDate" :max-date="onlineEndDate">
                    </month-picker> -->
                             <Datepicker
                                   v-model="monthData2"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   monthPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.cdpStartDateMonth"
                                   :maxDate="this.cdpEndDateMonth"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>

    <!-- sales dropdown -->
    <Teleport to="#teleport-modal">
        <div v-if="selectTimeModal2" class="modal-bg">
            <div class="modal-limit" ref="modal2">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="selectTimeModal2 = false">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <div class="d-flex align-items-center">
                        <span class="success-text pe-3">報表時間設定</span>
                        <div class="title-button-div-reportExport fs-small">
                            <button class="button-nofocus-reportExport"
                                :class="this.buttonSelected === 'month' ? 'button-focus' : ''" @click="clickMonth">
                                月
                            </button>
                            <button class="button-nofocus-reportExport"
                                :class="this.buttonSelected === 'year' ? 'button-focus' : ''" @click="clickYear">
                                年
                            </button>
                        </div>
                    </div>
                    <div class="month" v-if="this.buttonSelected === 'month'">
                        <div class="mt-3 d-flex align-items-center">
                            <div class="col-2">
                                <span>從</span>
                            </div>
                            <div class="col-10">
                                <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                    @click="salesModalMonthStart()">
                                    <span class="col-9 d-flex">
                                        {{ this.salesDropdownMonthStart }}
                                    </span>
                                    <span class="col-3 d-flex justify-content-end">
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 d-flex align-items-center">
                            <div class="col-2">
                                <span>至</span>
                            </div>
                            <div class="col-10">
                                <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                    @click="salesModalMonthEnd()">
                                    <span class="col-9 d-flex">
                                        {{ this.salesDropdownMonthEnd }}
                                    </span>
                                    <span class="col-3 d-flex justify-content-end">
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="year" v-else>
                        <div class="mt-3 d-flex align-items-center">
                            <div class="col-2">
                                <span>從</span>
                            </div>
                            <div class="col-10">
                                <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                    @click="salesModalYearStart()">
                                    <span class="col-9 d-flex">
                                        {{ this.salesDropdownYearStart }}
                                    </span>
                                    <span class="col-3 d-flex justify-content-end">
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 d-flex align-items-center">
                            <div class="col-2">
                                <span>至</span>
                            </div>
                            <div class="col-10">
                                <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                    @click="salesModalYearEnd()">
                                    <span class="col-9 d-flex">
                                        {{ this.salesDropdownYearEnd }}
                                    </span>
                                    <span class="col-3 d-flex justify-content-end">
                                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 d-flex justify-content-center">
                        <div class="col-6 d-flex justify-content-center">
                            <button class="dismiss-button" @click="selectTimeModal2 = false">
                                取消
                            </button>
                        </div>
                        <div class="col-6 6 d-flex justify-content-center">
                            <button class="download-button" @click="reportSalesExport()"
                          
                            >
                                下載
                            </button>
                               <!-- :style="this.salesDropdownMonthStart ==='選擇月份'||this.salesDropdownMonthEnd ==='選擇月份'
                              
                              ?'pointer-events:none; opacity:0.5' :''" -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
    <!-- sales month teleport start -->
    <Teleport to="#date-modal">
        <div v-if="salesMonthModal" class="modal-bg">
            <div class="modal-limit" ref="salesMonthModalRef">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownSales()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
             
                                 <!-- <month-picker @change="showDate3" lang="zh" :min-date="onlineStartDate" :max-date="onlineEndDate">
                    </month-picker> -->
                             <Datepicker
                                   v-model="monthData3"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   monthPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.cdpStartDateMonth"
                                   :maxDate="this.cdpEndDateMonth"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>

    <!-- sales month teleport end -->
    <Teleport to="#date-modal">
        <div v-if="salesMonthModal2" class="modal-bg">
            <div class="modal-limit" ref="salesMonthModalRef2">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownSales()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <!-- <Calendar v-model="date12" :inline="true" view="month" dateFormat="mm/yy" :minDate="onlineStartDate"
                        :maxDate="onlineEndDate" /> -->
                   <!-- <month-picker @change="showDate4" lang="zh"  :min-date="onlineStartDate" :max-date="onlineEndDate">
                    </month-picker> -->
                        <Datepicker
                                   v-model="monthData4"
                                   
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   monthPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.cdpStartDateMonth"
                                   :maxDate="this.cdpEndDateMonth"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>

    <!-- sales year teleport start -->
    <Teleport to="#date-modal">
        <div v-if="salesYearModal" class="modal-bg">
            <div class="modal-limit" ref="salesYearModalRef">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownSales()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <!-- <Calendar v-model="date13" :inline="true" view="year" dateFormat="mm/yy" :minDate="onlineStartDate"
                        :maxDate="onlineEndDate" /> -->
                                <Datepicker
                                  
                                   :modelValue="yearData"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   yearPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.cdpStartDate"
                                   :maxDate="this.cdpEndDate"
                                   @update:modelValue="setYearData"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                           <!-- <month-picker @change="showDate4" lang="zh" year-only="true"   :min-date="onlineStartDate" :max-date="onlineEndDate">
                          </month-picker> -->
                </div>
            </div>
        </div>
    </Teleport>

    <!-- sales year teleport end -->
    <Teleport to="#date-modal">
        <div v-if="salesYearModal2" class="modal-bg">
            <div class="modal-limit" ref="salesYearModalRef2">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownSales()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <!-- <Calendar v-model="date14" :inline="true" view="year" dateFormat="mm/yy" :minDate="onlineStartDate"
                        :maxDate="onlineEndDate" /> -->
                                   <Datepicker
                               
                                   :modelValue="yearData2"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   yearPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.cdpStartDate"
                                   :maxDate="this.cdpEndDate"
                                   @update:modelValue="setYearData2"
                                    >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>

    <!-- webBehavior dropdown -->
    <Teleport to="#teleport-modal">
        <div v-if="selectTimeModal3" class="modal-bg">
            <div class="modal-limit" ref="modal3">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="selectTimeModal3 = false">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <p class="success-text">報表時間設定</p>

                    <div class="mt-3 d-flex align-items-center">
                        <div class="col-2">
                            <span>從</span>
                        </div>
                        <div class="col-10">
                            <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                @click="webBehaviorMonthStart()">
                                <span class="col-9 d-flex">
                                    {{ this.webBehaviorDropdownYearStart }}
                                </span>
                                <span class="col-3 d-flex justify-content-end">
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 d-flex align-items-center">
                        <div class="col-2">
                            <span>至</span>
                        </div>
                        <div class="col-10">
                            <div class="selector d-flex justify-content-between align-items-center ps-3 pe-3"
                                @click="webBehaviorMonthEnd()">
                                <span class="col-9 d-flex">
                                    {{ this.webBehaviorDropdownYearEnd }}
                                </span>
                                <span class="col-3 d-flex justify-content-end">
                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#71AFB6" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 d-flex justify-content-center">
                        <div class="col-6 d-flex justify-content-center">
                            <button class="dismiss-button" @click="selectTimeModal3 = false">
                                取消
                            </button>
                        </div>
                        <div class="col-6 6 d-flex justify-content-center">
                            <button class="download-button" @click="reportOnlineExport()"
                            :style="this.webBehaviorDropdownYearStart ==='選擇月份'||this.webBehaviorDropdownYearEnd ==='選擇月份' 
                              ?'pointer-events:none; opacity:0.5' :''"
                            >
                                下載
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
    <!-- webBehavior month start -->
    <Teleport to="#date-modal">
        <div v-if="webBehaviormonthModal" class="modal-bg">
            <div class="modal-limit" ref="webBehaviormodalDateRef">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownOnline()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <!-- <Calendar v-model="date15" :inline="true" view="month" dateFormat="mm/yy" :minDate="cdpStartDate"
                        :maxDate="cdpEndDate" /> -->
                              <!-- <month-picker @change="showDate5" lang="zh"  :min-date="onlineStartDate" :max-date="onlineEndDate">
                    </month-picker> -->
                   <Datepicker
                                   v-model="monthData5"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   monthPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.onlineStartDate"
                                   :maxDate="this.onlineEndDate"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>
    <!-- webBehavior month end -->
    <Teleport to="#date-modal">
        <div v-if="webBehaviormonthModal2" class="modal-bg">
            <div class="modal-limit" ref="webBehaviormodalDate2Ref">
                <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                        @click="backDropdownOnline()">
                        <path
                            d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                            fill="white" />
                    </svg>
                </div>
                <div class="modal-body-limit d-flex flex-column justify-content-center">
                    <!-- <Calendar v-model="date16" :inline="true" view="month" dateFormat="mm/yy" />
                     -->
                                 <!-- <month-picker @change="showDate6" lang="zh"  :min-date="onlineStartDate" :max-date="onlineEndDate">
                    </month-picker> -->
                             <Datepicker
                                   v-model="monthData6"
                                   locale="tw"
                                   autoApply
                                    inline
                                   :clearable="false"
                                   monthPicker
                                   inputClassName="dp-custom-input"
                                    :minDate="this.onlineStartDate"
                                   :maxDate="this.onlineEndDate"
                                 >
                                   <template #input-icon>
                                     <img
                                       src="../assets/date.svg"
                                       alt=""
                                       style="position: relative; right: -10px; top: -3px"
                                     />
                                   </template>

                                 </Datepicker>
                </div>
            </div>
        </div>
    </Teleport>


    <div class="container home">
          <!-- <iframe
              src="http://localhost:8081/Upload"
              height="800px"
              width="100%"
              frameborder="0"
            ></iframe> -->
        <div class="title-span fs-medium" style="padding-left: 2px">
            報表資料管理
        </div>

        <div class="d-flex mt-4">
            <div class="col-12">
                <div class="section-div-reportexport">
                    <p style="color: #71afb6">下載報表</p>
                    <div class="d-flex">
                        <div class="col-4">
                            <p>商品銷售月報表</p>
                            <button class="button-style p-2" @click="productReport()">
                                <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.5 1.15625C12.5 1.35516 12.421 1.54593 12.2803 1.68658C12.1397 1.82723 11.9489 1.90625 11.75 1.90625L0.75 1.90625C0.551087 1.90625 0.360322 1.82723 0.219669 1.68658C0.0790166 1.54593 -4.14783e-08 1.35516 -3.27835e-08 1.15625C-2.40888e-08 0.957337 0.0790167 0.766572 0.219669 0.625919C0.360322 0.485267 0.551087 0.406249 0.75 0.406249L11.75 0.40625C11.9489 0.40625 12.1397 0.485268 12.2803 0.62592C12.421 0.766572 12.5 0.957338 12.5 1.15625ZM6.25 3.40625C6.44891 3.40625 6.63968 3.48527 6.78033 3.62592C6.92098 3.76657 7 3.95734 7 4.15625L7 14.3462L9.72 11.6263C9.86217 11.4938 10.0502 11.4216 10.2445 11.4251C10.4388 11.4285 10.6242 11.5072 10.7616 11.6446C10.899 11.782 10.9777 11.9674 10.9812 12.1617C10.9846 12.356 10.9125 12.5441 10.78 12.6862L6.784 16.6833C6.71945 16.748 6.64395 16.8008 6.561 16.8393C6.42122 16.9034 6.26506 16.9228 6.11382 16.8949C5.96259 16.867 5.82366 16.7931 5.716 16.6833L1.72 12.6862C1.64631 12.6176 1.58721 12.5348 1.54622 12.4428C1.50523 12.3508 1.48318 12.2515 1.48141 12.1508C1.47963 12.0501 1.49816 11.95 1.53588 11.8567C1.5736 11.7633 1.62974 11.6784 1.70096 11.6072C1.77218 11.536 1.85701 11.4798 1.9504 11.4421C2.04379 11.4044 2.14382 11.3859 2.24452 11.3877C2.34522 11.3894 2.44454 11.4115 2.53654 11.4525C2.62854 11.4935 2.71134 11.5526 2.78 11.6262L5.5 14.3462L5.5 4.15625C5.5 3.95734 5.57902 3.76657 5.71967 3.62592C5.86032 3.48527 6.05109 3.40625 6.25 3.40625Z"
                                        fill="white" />
                                </svg>

                                <span class="ps-3">匯出報表</span>
                            </button>
                        </div>
                        <div class="col-4 ps-4">
                            <p>營業銷售月/年報表</p>
                            <button class="button-style p-2" @click="productReport2()">
                                <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.5 1.15625C12.5 1.35516 12.421 1.54593 12.2803 1.68658C12.1397 1.82723 11.9489 1.90625 11.75 1.90625L0.75 1.90625C0.551087 1.90625 0.360322 1.82723 0.219669 1.68658C0.0790166 1.54593 -4.14783e-08 1.35516 -3.27835e-08 1.15625C-2.40888e-08 0.957337 0.0790167 0.766572 0.219669 0.625919C0.360322 0.485267 0.551087 0.406249 0.75 0.406249L11.75 0.40625C11.9489 0.40625 12.1397 0.485268 12.2803 0.62592C12.421 0.766572 12.5 0.957338 12.5 1.15625ZM6.25 3.40625C6.44891 3.40625 6.63968 3.48527 6.78033 3.62592C6.92098 3.76657 7 3.95734 7 4.15625L7 14.3462L9.72 11.6263C9.86217 11.4938 10.0502 11.4216 10.2445 11.4251C10.4388 11.4285 10.6242 11.5072 10.7616 11.6446C10.899 11.782 10.9777 11.9674 10.9812 12.1617C10.9846 12.356 10.9125 12.5441 10.78 12.6862L6.784 16.6833C6.71945 16.748 6.64395 16.8008 6.561 16.8393C6.42122 16.9034 6.26506 16.9228 6.11382 16.8949C5.96259 16.867 5.82366 16.7931 5.716 16.6833L1.72 12.6862C1.64631 12.6176 1.58721 12.5348 1.54622 12.4428C1.50523 12.3508 1.48318 12.2515 1.48141 12.1508C1.47963 12.0501 1.49816 11.95 1.53588 11.8567C1.5736 11.7633 1.62974 11.6784 1.70096 11.6072C1.77218 11.536 1.85701 11.4798 1.9504 11.4421C2.04379 11.4044 2.14382 11.3859 2.24452 11.3877C2.34522 11.3894 2.44454 11.4115 2.53654 11.4525C2.62854 11.4935 2.71134 11.5526 2.78 11.6262L5.5 14.3462L5.5 4.15625C5.5 3.95734 5.57902 3.76657 5.71967 3.62592C5.86032 3.48527 6.05109 3.40625 6.25 3.40625Z"
                                        fill="white" />
                                </svg>
                                <span class="ps-3">匯出報表</span>
                            </button>
                        </div>
                        <div class="col-4 ps-4">
                            <p>網站表現月報表</p>
                            <button class="button-style p-2" @click="productReport3()">
                                <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.5 1.15625C12.5 1.35516 12.421 1.54593 12.2803 1.68658C12.1397 1.82723 11.9489 1.90625 11.75 1.90625L0.75 1.90625C0.551087 1.90625 0.360322 1.82723 0.219669 1.68658C0.0790166 1.54593 -4.14783e-08 1.35516 -3.27835e-08 1.15625C-2.40888e-08 0.957337 0.0790167 0.766572 0.219669 0.625919C0.360322 0.485267 0.551087 0.406249 0.75 0.406249L11.75 0.40625C11.9489 0.40625 12.1397 0.485268 12.2803 0.62592C12.421 0.766572 12.5 0.957338 12.5 1.15625ZM6.25 3.40625C6.44891 3.40625 6.63968 3.48527 6.78033 3.62592C6.92098 3.76657 7 3.95734 7 4.15625L7 14.3462L9.72 11.6263C9.86217 11.4938 10.0502 11.4216 10.2445 11.4251C10.4388 11.4285 10.6242 11.5072 10.7616 11.6446C10.899 11.782 10.9777 11.9674 10.9812 12.1617C10.9846 12.356 10.9125 12.5441 10.78 12.6862L6.784 16.6833C6.71945 16.748 6.64395 16.8008 6.561 16.8393C6.42122 16.9034 6.26506 16.9228 6.11382 16.8949C5.96259 16.867 5.82366 16.7931 5.716 16.6833L1.72 12.6862C1.64631 12.6176 1.58721 12.5348 1.54622 12.4428C1.50523 12.3508 1.48318 12.2515 1.48141 12.1508C1.47963 12.0501 1.49816 11.95 1.53588 11.8567C1.5736 11.7633 1.62974 11.6784 1.70096 11.6072C1.77218 11.536 1.85701 11.4798 1.9504 11.4421C2.04379 11.4044 2.14382 11.3859 2.24452 11.3877C2.34522 11.3894 2.44454 11.4115 2.53654 11.4525C2.62854 11.4935 2.71134 11.5526 2.78 11.6262L5.5 14.3462L5.5 4.15625C5.5 3.95734 5.57902 3.76657 5.71967 3.62592C5.86032 3.48527 6.05109 3.40625 6.25 3.40625Z"
                                        fill="white" />
                                </svg>
                                <span class="ps-3">匯出報表</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-4">
            <div class="col-12">
                <div class="section-div-reportexport d-flex">
                    <span class="col-1" style="color: #71afb6">上傳資料</span>
                    <span class="col-5">
                        可上傳各資料源，包括會員、交易、商品與標籤資料。</span>
                    <span class="col-6">
                        <button class="button-style p-2" @click="goUpload()">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M-3.27835e-08 16.1562C-4.14783e-08 15.9573 0.0790175 15.7666 0.21967 15.6259C0.360322 15.4853 0.551088 15.4062 0.75 15.4062L11.75 15.4062C11.9489 15.4062 12.1397 15.4853 12.2803 15.6259C12.421 15.7666 12.5 15.9573 12.5 16.1562C12.5 16.3552 12.421 16.5459 12.2803 16.6866C12.1397 16.8272 11.9489 16.9062 11.75 16.9062L0.75 16.9062C0.551088 16.9062 0.360322 16.8272 0.21967 16.6866C0.0790175 16.5459 -2.40888e-08 16.3552 -3.27835e-08 16.1562ZM6.25 13.9062C6.05109 13.9062 5.86032 13.8272 5.71967 13.6866C5.57902 13.5459 5.5 13.3552 5.5 13.1562L5.5 2.96625L2.78 5.68625C2.63782 5.81873 2.44978 5.89085 2.25548 5.88743C2.06118 5.884 1.87579 5.80528 1.73838 5.66787C1.60096 5.53046 1.52225 5.34507 1.51882 5.15077C1.5154 4.95647 1.58752 4.76842 1.72 4.62625L5.716 0.629249C5.78055 0.564502 5.85605 0.511688 5.939 0.473249C6.07877 0.409107 6.23494 0.389669 6.38618 0.417589C6.53741 0.445509 6.67634 0.519426 6.784 0.629249L10.78 4.62625C10.8537 4.69491 10.9128 4.77771 10.9538 4.86971C10.9948 4.96171 11.0168 5.06102 11.0186 5.16173C11.0204 5.26243 11.0018 5.36246 10.9641 5.45585C10.9264 5.54924 10.8703 5.63407 10.799 5.70529C10.7278 5.77651 10.643 5.83265 10.5496 5.87037C10.4562 5.90809 10.3562 5.92662 10.2555 5.92484C10.1548 5.92306 10.0555 5.90102 9.96346 5.86003C9.87146 5.81904 9.78866 5.75994 9.72 5.68625L7 2.96625L7 13.1562C7 13.3552 6.92098 13.5459 6.78033 13.6866C6.63968 13.8272 6.44891 13.9062 6.25 13.9062Z"
                                    fill="white" />
                            </svg>
                            <span class="ps-3" >匯入資料</span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from "primevue/calendar";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import moment from "moment";
import { allapi } from "../../public/static/api/apigClient";
import { allapi2 } from "../../public/static2/api/apigClient";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { MonthPickerInput } from 'vue-month-picker'
import { MonthPicker } from 'vue-month-picker'
export default {
    components: {
        Calendar,
        MonthPickerInput,
        MonthPicker,
        Datepicker
    },

    setup() {
        const selectTimeModal = ref(false);
        const modal = ref(null);

        const monthModal = ref(false);
        const modalDate = ref(null);
        const monthModal2 = ref(false);
        const modalDate2 = ref(null);

        const selectTimeModal2 = ref(false);
        const modal2 = ref(null);

        const selectTimeModal3 = ref(false);
        const modal3 = ref(null);

        const salesMonthModal = ref(false);
        const salesMonthModalRef = ref(null);
        const salesMonthModal2 = ref(false);
        const salesMonthModalRef2 = ref(null);
        const salesYearModal = ref(false);
        const salesYearModalRef = ref(null);
        const salesYearModal2 = ref(false);
        const salesYearModalRef2 = ref(null);

        const webBehaviormonthModal = ref(false);
        const webBehaviormodalDateRef = ref(null);
        const webBehaviormonthModal2 = ref(false);
        const webBehaviormodalDateRef2 = ref(null);

        const yearData = ref(new Date().getFullYear());

        const yearData2 = ref(new Date().getFullYear());

           const monthData = ref({
           month: 6,
           year: 2022,
         });

   


        const monthData2 = ref({
           month: 6,
           year: 2022,
         }); 

        const monthData3 = ref({
           month: 6,
           year: 2022,
         }); 

        const monthData4 = ref({
           month: 6,
           year: 2022,
         }); 

        const monthData5 = ref({
           month: 6,
           year: 2022,
         }); 

        const monthData6 = ref({
           month: 6,
           year: 2022,
         });

        const salesDropdownYearStart = ref("選擇年份");

        const salesDropdownYearEnd = ref("選擇年份");

        onClickOutside(modal, () => {
            selectTimeModal.value = false;
        });
        onClickOutside(modalDate, () => {
            monthModal.value = false;
        });

        onClickOutside(modal2, () => {
            selectTimeModal2.value = false;
        });

        onClickOutside(modal3, () => {
            selectTimeModal3.value = false;
        });

        const setYearData = (value) => {
            yearData.value = value
            salesDropdownYearStart.value = yearData.value
            salesYearModal.value = false
            selectTimeModal2.value = true;
        }

        const setYearData2 = (value) => {
            yearData2.value= value
            salesDropdownYearEnd.value  = yearData2.value
             salesYearModal2.value = false
             selectTimeModal2.value = true;
        }

        return {
            selectTimeModal,
            modal,
            selectTimeModal2,
            modal2,
            selectTimeModal3,
            modal3,
            monthModal,
            modalDate,
            monthModal2,
            modalDate2,

            salesMonthModal,
            salesMonthModalRef,
            salesMonthModal2,
            salesMonthModalRef2,
            salesYearModal,
            salesYearModalRef,
            salesYearModal2,
            salesYearModalRef2,

            webBehaviormonthModal,
            webBehaviormodalDateRef,
            webBehaviormonthModal2,
            webBehaviormodalDateRef2,
            yearData,
            yearData2,

            monthData,
            // formatmonthData,
            monthData2,
            monthData3,
            monthData4,
              monthData5,
            monthData6,

            setYearData2,
            setYearData,

            salesDropdownYearStart,
            salesDropdownYearEnd
        };
    },
    data() {
        return {


            testMinDate: new Date('2022-03-01'),
            startDropdownSelect: "選擇月份",
            endDropdownSelect: "選擇月份",

            salesDropdownMonthStart: "選擇月份",
            salesDropdownMonthEnd: "選擇月份",
            // salesDropdownYearStart: "選擇年份",
            // salesDropdownYearEnd: "選擇年份",
            webBehaviorDropdownYearStart: "選擇月份",
            webBehaviorDropdownYearEnd: "選擇月份",
       
            cdpStartDateMonth: "",
            cdpEndDateMonth: "",
            cdpStartDate: "",
            cdpEndDate: "",
            onlineStartDate: "",
            onlineEndDate: "",
            memberStartDate:'',
            memberEndDate:'',
            buttonSelected: "month",

            selectedLabels: [],

            json_data: [],
            webs: [],
            sheet1Date:'',
            json_data2: [],
            webs2: [],
            sheet2Date:'',
            json_data3: [],
            sheet3Date:'',
            webs3: [],
            cdptimeMonthEndValue:0
        };
    },
    created: async function () {
        await this.getTimeCDP();
        this.gettimeOnlineGet();
        this.monthData = {
            month:this.cdptimeMonthEndValue,
            year:2022
        }
        this.monthData2 = {
            month:this.cdptimeMonthEndValue,
            year:2022
        }
        this.monthData3 = {
            month:this.cdptimeMonthEndValue,
            year:2022
        }
        this.monthData4 = {
            month:this.cdptimeMonthEndValue,
            year:2022
        }
        this.monthData5 = {
            month:this.cdptimeMonthEndValue,
            year:2022
        }
        this.monthData6 = {
            month:this.cdptimeMonthEndValue,
            year:2022
        }
        
        console.log('mounted monthData',this.monthData)
    },
    mounted(){
       
    },

    watch: {

        monthData(){
            console.log('monthData',this.monthData)
          
            let a = this.monthData.year
            let b =  this.monthData.month + 1 
          
            this.startDropdownSelect = a + '-' + b
            this.monthModal = false,
            this.selectTimeModal = true
            if(this.monthData.month === this.cdptimeMonthEndValue ){
                this.selectTimeModal = false
            }
     
            console.log('aa',this.startDropdownSelect)
            console.log('er',a)
        },
            monthData2(){
            console.log('monthData',this.monthData2)
            // this.DropdownSelect = this.monthData.year
            let a = this.monthData2.year
            let b =  this.monthData2.month + 1 
            this.endDropdownSelect = a + '-' + b
            this.monthModal2 = false,
            this.selectTimeModal = true
            if(this.monthData2.month === this.cdptimeMonthEndValue ){
                this.selectTimeModal = false
            }
       
            console.log('aa',this.endDropdownSelect)
            console.log('er',a)
        },
        monthData3(){
             let a = this.monthData3.year
            let b =  this.monthData3.month + 1 
            this.salesDropdownMonthStart = a + '-' + b
            this.salesMonthModal = false
            this.selectTimeModal2 = true
            if(this.monthData3.month === this.cdptimeMonthEndValue ){
                this.selectTimeModal2 = false
            }
        },
           monthData4(){
             let a = this.monthData4.year
            let b =  this.monthData4.month + 1 
            this.salesDropdownMonthEnd = a + '-' + b
            this.salesMonthModal2 = false
            this.selectTimeModal2 = true
            if(this.monthData4.month === this.cdptimeMonthEndValue ){
                this.selectTimeModal2 = false
            }
        }, 
        monthData5(){
            let a = this.monthData5.year
            let b =  this.monthData5.month + 1 
            this.webBehaviorDropdownYearStart = a + '-' + b
            this.webBehaviormonthModal = false
            this.selectTimeModal3 = true
            if(this.monthData5.month === this.cdptimeMonthEndValue ){
                this.selectTimeModal3 = false
            }

        },
            monthData6(){
            let a = this.monthData6.year
            let b =  this.monthData6.month + 1 
            this.webBehaviorDropdownYearEnd = a + '-' + b
            this.webBehaviormonthModal2 = false
            this.selectTimeModal3 = true
            if(this.monthData6.month === this.cdptimeMonthEndValue ){
                this.selectTimeModal3 = false
            }
        },  

        // yearData(){
        //     console.log('yearDate',this.yearData)
        //     this.salesDropdownYearStart  =this.yearData
        //     this.salesYearModal = false
        //     this.selectTimeModal2 = true;
        // },
        // yearData2(){
        //     console.log('yearDate',this.yearData2)
        //      this.salesDropdownYearEnd  = this.yearData2
        //      this.salesYearModal2 = false
        //      this.selectTimeModal2 = true;
        //      if(  this.salesDropdownYearEnd  === this.yearData2){
        //         this.salesYearModal2 = false
        //      }
        // }
    },

    methods: {
        //   allowedMonths: val => parseInt(val.split('-')[1], 10) % 2 === 0,
        showDate(date) {
            console.log('vuedate', date)
            this.date = date
            let convertedDate = moment(date.from).format("YYYY-MM-DD");
            this.monthModal = false;
            this.selectTimeModal = true;
            this.startDropdownSelect = convertedDate;

        },
          showDate2(date) {
            console.log('vuedate', date)
            this.date2 = date
            let convertedDate = moment(date.from).format("YYYY-MM-DD");
            this.monthModal2 = false;
            this.selectTimeModal = true;
            this.endDropdownSelect = convertedDate;

        },
        showDate3(date) {
            console.log('vuedate', date.from)
            this.date3 = date
            let convertedDate = moment(date.from).format("YYYY-MM-DD");
            this.salesMonthModal = false;
            this.selectTimeModal2 = true;
            this.salesDropdownMonthStart = convertedDate;

        },
         showDate4(date) {
            console.log('vuedate', date.from)
            this.date4 = date
            let convertedDate = moment(date.from).format("YYYY-MM-DD");
            this.salesMonthModal2 = false;
            this.selectTimeModal2 = true;
            this.salesDropdownMonthEnd = convertedDate;

        },
            showDate5(date) {
            console.log('vuedate', date.from)
            this.date4 = date
            let convertedDate = moment(date.from).format("YYYY-MM-DD");
            this.webBehaviormonthModal = false;
            this.selectTimeModal3 = true;
            this.webBehaviorDropdownYearStart = convertedDate;

        },
            showDate6(date) {
            console.log('vuedate', date.from)
            this.date4 = date
            let convertedDate = moment(date.from).format("YYYY-MM-DD");
            this.webBehaviormonthModal2 = false;
            this.selectTimeModal3 = true;
            this.webBehaviorDropdownYearEnd = convertedDate;

        },
        productReport() {
            this.selectTimeModal = true;
        },
        productReport2() {
            this.selectTimeModal2 = true;
        },
        productReport3() {
            this.selectTimeModal3 = true;
        },
        startDateModal() {
            this.selectTimeModal = false;
            this.monthModal = true;
        },
        endDateModal() {
            this.selectTimeModal = false;
            this.monthModal2 = true;
        },
        clickMonth() {
            this.buttonSelected = "month";
        },
        clickYear() {
            this.buttonSelected = "year";
        },

        salesModalMonthStart() {
            this.selectTimeModal2 = false;
            this.salesMonthModal = true;
        },
        salesModalMonthEnd() {
            this.selectTimeModal2 = false;
            this.salesMonthModal2 = true;
        },
        salesModalYearStart() {
            this.selectTimeModal2 = false;
            this.salesYearModal = true;
        },
        salesModalYearEnd() {
            this.selectTimeModal2 = false;
            this.salesYearModal2 = true;
        },

        webBehaviorMonthStart() {
            this.webBehaviormonthModal = true;
            this.selectTimeModal3 = false;
        },
        webBehaviorMonthEnd() {
            this.webBehaviormonthModal2 = true;
            this.selectTimeModal3 = false;
        },

        backDropdownProduct() {
            this.selectTimeModal = true;
            this.monthModal = false
            this.monthModal2 = false
        },
        backDropdownSales() {
            this.selectTimeModal2 = true,
                this.salesMonthModal = false,
                this.salesMonthModal2 = false,
                this.salesYearModal = false,
                this.salesYearModal2 = false
        },
        backDropdownOnline() {
            this.selectTimeModal3 = true
            this.webBehaviormonthModal = false
            this.webBehaviormonthModal2 = false
        },

        getTimeCDP:async function () {
            const token = sessionStorage.getItem("access_token");
            const params = {
                Authorization: "Bearer " + token,
            };

            await allapi
                .timeSalesGet(params)
                .then((res) => {
                    console.log("timecdp", res);
                    let convertedDateEnd = res.data.Data.end_date;
                    let convertedDateStart = res.data.Data.start_date;
                    // let a = moment(convertedDateEnd);
                    // let b = moment(convertedDateStart);
                    // this.cdpEndDate = a._d;
                    // this.cdpStartDate = b._d;
                    let a = convertedDateEnd.slice(0,4)
                    let b = convertedDateStart.slice(0,4)
                    let c = convertedDateStart.slice(0,7)
                    let d = convertedDateEnd.slice(0,7)
                    let MonthDataEndValue = convertedDateEnd.slice(6,7)

                    console.log('MonthDataEndValue',MonthDataEndValue)

                    this.cdptimeMonthEndValue = MonthDataEndValue - 1
                    console.log('this.cdptimeMonthEndValue', this.cdptimeMonthEndValue)
                    this.cdpEndDate = moment(a).endOf('year').toISOString();
                   
                    this.cdpStartDate = b;
                    this.cdpStartDateMonth = c
                    this.cdpEndDateMonth = moment(d).add(28,'days').toISOString()
                    
                    console.log("cdpend", this.cdpEndDate);
                    console.log("cdpstart", this.cdpStartDate);
                    console.log("cdpstartMonth", this.cdpStartDateMonth);
                    console.log("cdpendMonth", this.cdpEndDateMonth);
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    alert("連線有誤");
                });
        },
        gettimeOnlineGet:async function() {
            const token = sessionStorage.getItem("access_token");
            const params = {
                Authorization: "Bearer " + token,
            };

           await  allapi
                .timeOnlineGet(params)
                .then((res) => {
                    console.log("onlinetime", res);
                    let convertedDateEnd = res.data.Data.end_date;
                    let convertedDateStart = res.data.Data.start_date;
                    // let a = moment(convertedDateEnd);
                    // let b = moment(convertedDateStart);
                    // this.onlineStartDate = b._d;
                    // this.onlineEndDate = a._d;
                    let a = convertedDateEnd.slice(0,7)
                    let b = convertedDateStart.slice(0,7)
                   this.onlineStartDate = b;
                    this.onlineEndDate = moment(a).add(28,'days').toISOString();

                    console.log('this.onlineStartDate', this.onlineStartDate)
                    console.log('this.onlineEndDate', this.onlineEndDate)
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    alert("連線有誤");
                });
        },
        gettimeMemberGet(){
                 const token = sessionStorage.getItem("access_token");
            const params = {
                Authorization: "Bearer " + token,
            };
            allapi
            .timeMemberGet(params)
            .then((res) =>{
                console.log("membertime", res);
            // this.memberStartDate = res.data.Da
            })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    alert("連線有誤");
                });
    
        },  

        download() {
            const buildData = (data) => {
                return new Promise((resolve, reject) => {
                    // 最後所有的資料會存在這
                    let arrayData = [];

                    // 取 data 的第一個 Object 的 key 當表頭
                    let arrayTitle = Object.keys(data[0]);
                    arrayData.push(arrayTitle);

                    // 取出每一個 Object 裡的 value，push 進新的 Array 裡
                    Array.prototype.forEach.call(data, (d) => {
                        let items = [];
                        Array.prototype.forEach.call(arrayTitle, (title) => {
                            let item = d[title] || "無";
                            items.push(item);
                        });
                        arrayData.push(items);
                    });

                    resolve(arrayData);
                });
            };
            const selectNames = this.selectedLabels.map((d) => d.label);
            const downloadCSV = (data) => {
                let csvContent = "";
                Array.prototype.forEach.call(data, (d) => {
                    let dataString = d.join(",") + "\n";
                    csvContent += dataString;
                });

                // 下載的檔案名稱
                let fileName =
                    "下載" +
                    "[" +
                    selectNames +
                    "]" +
                    "資料" +
                    new Date().toISOString() +
                    ".csv";

                // 建立一個 a，並點擊它
                let link = document.createElement("a");
                link.setAttribute(
                    "href",
                    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
                );
                link.setAttribute("download", fileName);
                link.click();
            };
            const data = this.json_data;
            buildData(data).then((data) => downloadCSV(data));
        },

        downloadOnlineReport(webs) {

            /* create a new blank workbook */
            var wb = XLSX.utils.book_new();

            var wsBooks = ''

            for (let index = 0; index < webs.length; index++) {
                wsBooks = XLSX.utils.json_to_sheet(this.json_data[webs[index]]);

                XLSX.utils.book_append_sheet(wb, wsBooks, webs[index]);

            }

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data1, "網站表現月報表" + this.sheet3Date);

        },


        reportOnlineExport: async function () {
            this.selectTimeModal3 = false;

            const token = sessionStorage.getItem("access_token");
            const params = {
                Authorization: "Bearer " + token,
            };
            const body = {
                start_date: this.webBehaviorDropdownYearStart,
                end_date: this.webBehaviorDropdownYearEnd,
            };
            await allapi2
                .reportOnlineReportPost(params, body)
                .then((res) => {
                    console.log("res1", res);
                   const webs = Object.keys(res.data.sheet)
                    for(let index = 0; index < webs.length; index++){
                        res.data.sheet[webs[index]] = res.data.sheet[webs[index]].map((d)=>{
                            return{
                                日期:d.time,
                                網站瀏覽量:d.網站瀏覽量,
                                不重複訪客:d.不重複訪客,
                                瀏覽商品數量的人數:d.瀏覽商品數量的人數,
                                加入購物車數量:d.加入購物車數量,
                                加入購物車比例:d.加入購物車比例,
                                購買轉換率:d.購買轉換率,
                                // "上期同比-網站瀏覽量test":d,
                                "上期同比-網站瀏覽量":d["上期同比-網站瀏覽量"],
                                "上期同比-不重複訪客":d["上期同比-不重複訪客"],
                                "上期同比-瀏覽商品數量的人數":d["上期同比-瀏覽商品數量的人數"],
                                "上期同比-加入購物車數量":d["上期同比-加入購物車數量"],
                                "上期同比-加入購物車比例":d["上期同比-加入購物車比例"],
                                "上期同比-購買轉換率":d["上期同比-購買轉換率"]

                            }
                        });
                      
                    }


                    this.sheet3Date = res.data.date
                    this.json_data = res.data.sheet;
                    this.webs = Object.keys(res.data.sheet)
                    this.downloadOnlineReport(this.webs);
                })
                .catch((err) => {
                    console.log("連線有誤");
                    console.log(err);
                    this.loading = false;
                    if(err.status === 500){
                        alert('查無資料')
                    }
                    // alert("連線有誤");
                });

        },


        downloadSalesIncomeReport(webs) {

            /* create a new blank workbook */
            var wb = XLSX.utils.book_new();

            var wsBooks = ''

            for (let index = 0; index < webs.length; index++) {
                wsBooks = XLSX.utils.json_to_sheet(this.json_data2[webs[index]]);

                XLSX.utils.book_append_sheet(wb, wsBooks, webs[index]);

            }

            /* create a worksheet for books */
            // var wsBooks = XLSX.utils.json_to_sheet(data);

            /* Add the worksheet to the workbook */
            // XLSX.utils.book_append_sheet(wb, wsBooks, "Books");

            /* create a worksheet for person details */
            // var wsPersonDetails = XLSX.utils.json_to_sheet(data2);

            /* Add the worksheet to the workbook */
            // XLSX.utils.book_append_sheet(wb, wsPersonDetails, "PersonDetails");


            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            if(this.buttonSelected === 'month'){
                   FileSaver.saveAs(data1, "營業銷售月報表"+this.sheet2Date);

            }else{
                 FileSaver.saveAs(data1, "營業銷售年報表"+this.sheet2Date);
            }
         
        },
        reportSalesExport: async function () {
            this.selectTimeModal2 = false;

            const token = sessionStorage.getItem("access_token");
            const params = {
                Authorization: "Bearer " + token,
            };
            let body = {}

            if (this.buttonSelected === 'month') {
                body = {
                    start_date: this.salesDropdownMonthStart,
                    end_date: this.salesDropdownMonthEnd,
                    time: 'month'
                }
            } else if (this.buttonSelected === 'year') {
                body = {
                    start_date: this.salesDropdownYearStart,
                    end_date: this.salesDropdownYearEnd,
                    time: 'year'
                }
            }
            await allapi2
                .reportSaleReportPost(params, body)
                .then((res) => {
                    console.log("res2", res);
                    const webs = Object.keys(res.data.sheet)
                    for(let index = 0; index < webs.length; index++){
                        res.data.sheet[webs[index]] = res.data.sheet[webs[index]].map((d)=>{
                            return{
                                日期:d.日期,
                                整體營業額:d.營業額,
                                整體訂單數:d.訂單數,
                                整體平均客單價:d.客單價,
                                新加入會員數:d.新加入會員數,
                                顧客首購數:d.顧客首購數,
                                顧客回購數:d.顧客回購數,
                                "整體營業額-與上月同比":d["上期同比-營業額"],
                                "整體訂單數-與上月同比":d["上期同比-訂單數"],
                                "整體平均客單價-與上月同比":d["上期同比-客單價"],
                                "新加入會員數-與上月同比":d["上期同比-新加入會員數"],
                                "顧客首購數-與上月同比":d["上期同比-顧客首購數"],
                                "顧客回購數-與上月同比":d["上期同比-顧客回購數"]
                            }
                        });
                      
                    }


                    this.json_data2 = res.data.sheet;
                      this.webs2 = Object.keys(res.data.sheet)
                    
                    this.sheet2Date = res.data.date
                    this.downloadSalesIncomeReport(this.webs2)
                })
                .catch((err) => {
                    console.log("連線有誤");
                    console.log(err);
                      if(err.status === 500){
                        alert('查無資料')
                    }
                    this.loading = false;
                    // alert("連線有誤");
                });


        },
        downloadProductReport(webs) {
            var wb = XLSX.utils.book_new();

            var wsBooks = ''

            for (let index = 0; index < webs.length; index++) {
                wsBooks = XLSX.utils.json_to_sheet(this.json_data3[webs[index]]);

                XLSX.utils.book_append_sheet(wb, wsBooks, webs[index]);

            }

            /* create a worksheet for books */
            // var wsBooks = XLSX.utils.json_to_sheet(data);

            /* Add the worksheet to the workbook */
            // XLSX.utils.book_append_sheet(wb, wsBooks, "Books");

            /* create a worksheet for person details */
            // var wsPersonDetails = XLSX.utils.json_to_sheet(data2);

            /* Add the worksheet to the workbook */
            // XLSX.utils.book_append_sheet(wb, wsPersonDetails, "PersonDetails");


            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data1, "商品銷售月報表" + this.sheet1Date);
        },
        goUpload(){
            const from = 'cdp'
            const token = sessionStorage.getItem('access_token')
            const idToken =  sessionStorage.getItem('id_token')
            if(window.location.origin === 'http://localhost:8080'){
                window.location.href = `http://localhost:8081/?from=${from}&token=${token}&idtoken=${idToken}`;
            }else if(window.location.origin === 'https://firstcdp-test.retailing-data.net'|| window.location.origin === 'https://firstcdp-dev.retailing-data.net'||window.location.origin === 'https://firstcdp.retailing-data.net' ){
                    window.location.href = `https://firstcdp-dataupload.retailing-data.net/?from=${from}&token=${token}&idtoken=${idToken}`
            }
        },  

        reportProductReportPost: async function () {
            this.selectTimeModal = false;

            const token = sessionStorage.getItem("access_token");
            const params = {
                Authorization: "Bearer " + token,
            };
            const body = {
                start_date: this.startDropdownSelect,
                end_date: this.endDropdownSelect,
            };

            await allapi2
                .reportProductReportPost(params, body)
                .then((res) => {
                    console.log('res3', res)
                    const webs = Object.keys(res.data.sheet)
                    for (let index = 0; index < webs.length; index++) {
                        res.data.sheet[webs[index]] = res.data.sheet[webs[index]].map((o) => {
                        return{
                            品牌:o.品牌,
                            商品名稱:o.商品名稱,
                            商品營業額:o.商品營業額,
                            商品訂單數:o.商品訂單數,
                            商品購買人數:o.商品購買人數,
                            商品購買數量:o.商品購買數量,
                            營業額排名:o.營業額排名,
                            產品類別:o.產品類別,
                           "上個時段同比-營業額成長率":o["上期同比-營業額成長率"],
                           "上個時段同比-購買數量成長率":o["上期同比-購買數量成長率"]

                        }
                    });
                    }
                    this.json_data3 = res.data.sheet
                    this.sheet1Date = res.data.date 
                    console.log('this.json_data3', this.json_data3)
                    
                    this.webs3 = Object.keys(res.data.sheet)
                    this.downloadProductReport(this.webs3)
                })
                .catch((err) => {
                    console.log("連線有誤");
                    console.log(err);
                      if(err.status === 500){
                        alert('查無資料')
                    }
                    this.loading = false;
                    // alert("連線有誤");
                });

        },
    },
};
</script>

<style scoped>
:deep(.month-picker__container) {
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 10%);
    position: relative;
    width: 335px;
}

:deep(.p-monthpicker) {
    margin: 1rem 0.5rem;
}

:deep(.p-monthpicker-month) {
    padding: 10px;
}

:deep(.p-yearpicker) {
    margin: 1rem 0.5rem;
}

:deep(.p-yearpicker-year) {
    padding: 10px;
}
:deep(.dp__flex_display){
    display: flex;
    justify-content: center;
}

.section-div-reportexport {
    padding: 30px 30px 30px 30px;
    /* margin-top: 5px; */
    /* margin-bottom: 2rem; */
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.button-style {
    width: 100%;
    background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
    border-radius: 10px;
    border: none;
    color: #fff;
}

.modal-header-limit {
    background: linear-gradient(90deg, #b5dbe0 0%, #71aeb5 100%);
    border-radius: 10px 10px 0px 0px;
    height: 5%;
    padding: 5px 10px;
}

.modal-body-limit {
    height: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 0px 0px 10px 10px;
}

.modal-limit {
    position: fixed;
    z-index: 999;
    top: 35%;
    left: 48%;
    width: 20%;
    /* margin-left: -150px; */
    border-radius: 20px;
    background-color: aliceblue;
}

.success-text {
    text-align: start;
    color: #71afb6;
    font-weight: 600;
}

.download-button {
    border-radius: 10px;
    padding: 10px 5px;
    color: #fff;

    width: 80%;
    border: none;
    background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
}

.dismiss-button {
    border-radius: 10px;
    padding: 10px 5px;
    color: #575757;
    width: 80%;
    border: none;
    /* background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%); */
    background-color: #eaf3f4;
}

.button-nofocus-reportExport {
    width: 5rem;
    height: 33px;
    background-color: #eaf3f4;
    border: none;
    border-radius: 8px;
}

.title-button-div-reportExport {
    background-color: #eaf3f4;
    border-radius: 8px;
    height: 33px;
}
</style>
