<template>
  <div>
    <div class="container-fluid login-page p-0">
      <div class="d-flex">
        <div class="leftNav col-2 sidebar">
          <ul class="list-unstyled components">
            <li style="margin-bottom: 30px">
              <div>
                <router-link to="/AccountSetting" active-class="active">
                  <img src="../assets/setting-gray.png" v-show="!settingPage" />
                  <img src="../assets/setting-white.png" v-show="settingPage" />
                  帳號設定
                </router-link>
                <router-link
                  to="/TermPage"
                  active-class="active"
                  @click="this.termpage = !this.termpage"
                >
                  <img src="../assets/service-gray.png" v-show="!termPage" />
                  <img src="../assets/service-white.png" v-show="termPage" />
                  服務條款
                </router-link>
                <router-link to="/PrivacyPage" active-class="active">
                  <img src="../assets/privacy-icon.svg" v-show="privacypage" />
                  <img
                    src="../assets/privacy-white.png"
                    v-show="!privacypage"
                  />
                  隱私權政策
                </router-link>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-10 main-section">
          <h3>
            <img
              src="../assets/back-arrow.png"
              @click="$router.go(-1)"
              style="cursor: pointer"
            />
            &nbsp; &nbsp; First CDP 隱私權政策
          </h3>
          <p class="mt-4 fs">
            歡迎您加入 First CDP顧客數據平台
            （以下簡稱本平台），為了讓您能夠安心的使用本平台的各項服務與資訊，特此向您說明本平台的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
          </p>
          <p class="mt-4 fs">一、 隱私權保護政策的適用範圍</p>
          <ul>
            <li>
              隱私權保護政策內容，包括本平台如何處理在您使用平台服務時收集到的個人識別資料。隱私權保護政策不適用於本平台以外的相關連結網站。
            </li>
          </ul>
          <p class="mt-4 fs">二、 個人資料的蒐集、處理及利用方式</p>
          <ul>
            <li>
              本平台個人資料蒐集之目的是要對於會員管理與服務、統計與研究分析、行銷業務上，提供更好的服務。當您登入本平台時，我們將視活動性質請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料。
            </li>
            <li>
              本平台蒐集足以識別使用者身分的個人資料、消費與交易資料，或日後經您同意而提供之其他個人資料，都僅供本平台於其內部、依照蒐集之目的進行處理和利用、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權政府機關之命令或要求，否則本平台不會將足以識別使用者身分的個人資料提供給第三人（包括境內及境外）、或移作蒐集目的以外之使用。
            </li>
          </ul>
          <p class="mt-4 fs">三、 資料之保護</p>
          <ul>
            <li>
              本公司採用適當之安全措施，防止您的個人資料被竊取、竄改、洩露或毀損，包括對資料的收集、儲存、處理和安全措施，以及實體上的安全措施進行內部檢查，以防杜本公司儲存您個人資料的系統遭受入侵。
            </li>
            <li>
              如因業務需要有必要委託本平台相關單位提供服務時，本平台亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
            </li>
          </ul>

          <p class="mt-4 fs">四、 平台對外的相關連結</p>
          <p>
            本平台提供其他網站或其他平台的網路連結，您也可經由本平台所提供的連結，點選進入其他網站或其他平台。但該連結不適用本平台的隱私權保護政策，您必須參考該連結中的隱私權保護政策。
          </p>
          <p class="mt-4 fs">五、 與第三人共用個人資料之政策</p>
          <p>
            本平台絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
          </p>
          <ul>
            <li>
              前項但書之情形包括不限於：
              <ol>
                <li>經由您同意。</li>
                <li>法律明文規定。</li>
                <li>為維護國家安全或增進公共利益。</li>
                <li>為免除您生命、身體、自由或財產上之危險。</li>
                <li>
                  當您在本平台的行為，違反本平台的服務條款或可能損害或妨礙本網站權益或導致任何人遭受損害時，經本平台揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                </li>
              </ol>
            </li>
          </ul>
          <p class="mt-4 fs">六、 隱私權保護政策之修正</p>
          <p>
            本平台隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於平台上。
          </p>

          <p class="mt-4 fs">
            若您對本公司隱私權政策或資料處理方式有任何問題或疑慮，敬請透過客服電話或信箱與我們聯絡。
          </p>
          <p class="mt-5">
            Copyright © 2022 聚典資訊股份有限公司 All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPage",
  data() {
    return {
      privacypage: false,
      termPage:false,
      settingPage: false,
    };
  },
  watch: {
    $route: function () {
      this.privacypage = this.$route.path === "/PrivacyPage";
    },
  },
};
</script>

<style scoped>
.container-fluid {
  height: 100%;
}
.login-page {
  position: absolute;
  left: 0;
  /* top: 0; */
  /* background-color: #fff; */
  /* overflow: hidden; */
}

li {
  cursor: pointer;
}
img {
  position: relative;
  top: -2px;
}
.leftNav {
  background-color: #eaf3f4;
  height: inherit;
  min-width: 216px;
  /* min-height: calc(80vh); */
  padding-top: 50px;
  height: 200vh;
}
.highlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #63a7c8;
}
.nohighlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #8a8a8a;
}
.inside-highlight {
  /* background-color: #63a7c8; */
  color: #63a7c8;
  border-radius: 8px;
  font-size: 18px;
  padding-left: 35px;
  height: 40px;
  line-height: 40px;
}
.inside-nohighlight {
  color: #8a8a8a;
  font-size: 18px;
}
.show {
  display: block;
}
.noshow {
  display: none;
}
a {
  display: block;
  color: #8a8a8a;
  text-decoration: none;
  border-radius: 8px;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
}
.active {
  display: block;
  background-color: #63a7c8;
  color: white;
}
.inside-active {
  color: #71afb6;
}
.main-section {
  padding: 50px;
}
.fs {
  font-size: 18px;
}
</style>