import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import OperationalOverview from '../views/OperationalOverview.vue'
import MembershipSales from '../views/MembershipSales.vue'
import CommoditySales from '../views/CommoditySales.vue'
// import CombinatorialAnalysis from '../views/CombinatorialAnalysis.vue'
import StoreChannelSales from '../views/StoreChannelSales.vue'
import ValueGrouping from '../views/ValueGrouping.vue'
import CustomLabel from '../views/CustomLabel.vue'
import OfflineBehavior from '../views/OfflineBehavior.vue'
import BasicInfo from '../views/BasicInfo.vue'
import PurchaseRecord from '../views/PurchaseRecord'
import OnlineBehavior from '../views/OnlineBehavior.vue'
import RFMpage from '../views/RFMpage.vue'
import RFMCustomPage from '../views/RFMCustomPage.vue'
import ProductTextTemplate from '../views/ProductTextTemplate.vue'
import MarketingSchedule from '../views/MarketingSchedule.vue'
import TriggerScript from '../views/TriggerScript.vue'
import AfterTriggerScript from '../views/AfterTriggerScript.vue'
import MarketingScript from '../views/MarketingScript.vue'
import AllAdvEffect from '../views/AllAdvEffect.vue'
import EmailMarketingTable from '../views/EmailMarketingTable.vue'
import FbMarketingTable from '../views/FbMarketingTable.vue'
import GoogleMarketingTable from '../views/GoogleMarketingTable.vue'
import LineMarketingTable from '../views/LineMarketingTable.vue'
import EmailMarketingTrend from '../views/EmailMarketingTrend.vue'
// import FbMarketingTrend from '../views/FbMarketingTrend.vue'
import GoogleMarketingTrend from '../views/GoogleMarketingTrend.vue'
import LineMarketingTrend from '../views/LineMarketingTrend.vue'
import ResultPerformance from '../views/ResultPerformance.vue'
import LatestResult from '../views/LatestResult'
import EventPerformance from '../views/EventPerformance'
import TermPage from '../views/TermPage'
import PrivacyPage from '../views/PrivacyPage'
import AccountSetting from '../views/AccountSetting'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import ProductSmsTemplate from '../views/ProductSmsTemplate'
import SmsSchedule from '../views/SmsSchedule'
import SmsMarketingTable from '../views/SmsMarketingTable.vue'
import SmsResultPerformance from '../views/SmsResultPerformance'
import SmsEventPerformance from '../views/SmsEventPerformance'
import SmsLatestResult from '../views/SmsLatestResult'
import LineAdsPackage from '../views/LineAdsPackage'
import LineShortUrl from '../views/LineShortUrl'
import FacebookAdsPackage from '../views/FacebookAdsPackage'
import FbShortUrl from '../views/FbShortUrl'
import FbadsTable from '../views/FbadsTable'
import FbadsTableByAdv from '../views/FbadsTableByAdv'
import FbTrendAnal from '../views/FbTrendAnal'
import FbThemeAnal from '../views/FbThemeAnal'
import testdragpage from '../views/testdragpage'
import ChannelMarketing from '../views/ChannelMarketing'
import EmailSetting from '../views/EmailSetting'
import SmsSetting from '../views/SmsSetting'
import ScriptTable  from '../views/ScriptTable'
import MemberStatus  from '../views/MemberStatus'
import MemberUploadDel  from '../views/MemberUploadDel'
import TriggerScriptPerformance from '../views/TriggerScriptPerformance'
import FullCalendar from '../views/FullCalendar'
import WebsiteAnalysis from '../views/WebsiteAnalysis.vue'
// import LabelsReport from '../views/LabelsReport.vue'
import TagReport from '../views/TagReport.vue'
// import NewRFMCustomPage from '../views/NewRFMCustomPage.vue'
import MemberLimit from '../views/MemberLimit.vue'
import MemberData from '../views/MemberData.vue'
import New360Page from '../views/New360Page.vue'
import reportExport from '../views/reportExport.vue'
import ClickForceTag from '../views/ClickForceTag.vue'
import WordCloud from '../views/WordCloud.vue'
import TryNewTrigger from '../views/TryNewTrigger.vue'
import LabelManage from '../views/LabelManage.vue'

// import CommoditySales_NW from '../views/CommoditySales_NW.vue'

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/OperationalOverview',
    name: 'OperationalOverview',
    component: OperationalOverview
  },
  {
    path: '/MembershipSales',
    name: 'MembershipSales',
    component: MembershipSales
  },
  {
    path: '/CommoditySales',
    name: 'CommoditySales',
    component: CommoditySales
  },
  // {
  //   path: '/CombinatorialAnalysis',
  //   name: 'CombinatorialAnalysis',
  //   component: CombinatorialAnalysis
  // },
  {
    path: '/StoreChannelSales',
    name: 'StoreChannelSales',
    component: StoreChannelSales
  },
  {
    path: '/ValueGrouping',
    name: 'ValueGrouping',
    component: ValueGrouping
  },
  {
    path: '/CustomLabel',
    name: 'CustomLabel',
    component: CustomLabel
  },
  {
    path: '/OfflineBehavior',
    name: 'OfflineBehavior',
    component: OfflineBehavior
  },
  {
    path: '/BasicInfo',
    name: 'BasicInfo',
    component: BasicInfo
  },
  {
    path: '/PurchaseRecord',
    name: 'PurchaseRecord',
    component: PurchaseRecord
  },
  {
    path: '/OnlineBehavior',
    name: 'OnlineBehavior',
    component: OnlineBehavior
  },
  {
    path: '/RFMpage',
    name: 'RFMpage',
    component: RFMpage
  },
  {
    path: '/RFMCustomPage',
    name: 'RFMCustomPage',
    component: RFMCustomPage
  },
  {
    path: '/ProductTextTemplate',
    name: 'ProductTextTemplate',
    component: ProductTextTemplate
  },
  {
    path: '/MarketingSchedule',
    name: 'MarketingSchedule',
    component: MarketingSchedule
  },
  {
    path: '/TriggerScript',
    name: 'TriggerScript',
    component: TriggerScript
  },
  {
    path: '/AfterTriggerScript',
    name: 'AfterTriggerScript',
    component: AfterTriggerScript
  },
  {
    path: '/MarketingScript',
    name: 'MarketingScript',
    component: MarketingScript
  },
  {
    path: '/AllAdvEffect',
    name: 'AllAdvEffect',
    component: AllAdvEffect
  },
  {
    path: '/EmailMarketingTable',
    name: 'EmailMarketingTable',
    component: EmailMarketingTable
  },
  {
    path: '/FbMarketingTable',
    name: 'FbMarketingTable',
    component: FbMarketingTable
  },
  {
    path: '/GoogleMarketingTable',
    name: 'GoogleMarketingTable',
    component: GoogleMarketingTable
  },
  {
    path: '/LineMarketingTable',
    name: 'LineMarketingTable',
    component: LineMarketingTable
  },
  {
    path: '/EmailMarketingTrend',
    name: 'EmailMarketingTrend',
    component: EmailMarketingTrend
  },
  // {
  //   path: '/FbMarketingTrend',
  //   name: 'FbMarketingTrend',
  //   component: FbMarketingTrend
  // },
  {
    path: '/GoogleMarketingTrend',
    name: 'GoogleMarketingTrend',
    component: GoogleMarketingTrend
  },
  {
    path: '/LineMarketingTrend',
    name: 'LineMarketingTrend',
    component: LineMarketingTrend
  },
  {
    path: '/ResultPerformance',
    name: 'ResultPerformance',
    component: ResultPerformance
  },
  {
    path: '/LatestResult',
    name: 'LatestResult',
    component: LatestResult
  },
  {
    path: '/EventPerformance',
    name: 'EventPerformance',
    component: EventPerformance
  },
  {
    path: '/TermPage',
    name: 'TermPage',
    component: TermPage
  },
  {
    path: '/PrivacyPage',
    name: 'PrivacyPage',
    component: PrivacyPage
  },
  {
    path: '/AccountSetting',
    name: 'AccountSetting',
    component: AccountSetting
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/ProductSmsTemplate',
    name: 'ProductSmsTemplate',
    component: ProductSmsTemplate
  },
  {
    path: '/SmsSchedule',
    name: 'SmsSchedule',
    component: SmsSchedule
  },
  {
    path: '/SmsMarketingTable',
    name: 'SmsMarketingTable',
    component: SmsMarketingTable
  },
  {
    path: '/SmsResultPerformance',
    name: 'SmsResultPerformance',
    component: SmsResultPerformance
  },
  {
    path: '/SmsEventPerformance',
    name: 'SmsEventPerformance',
    component: SmsEventPerformance
  },
  {
    path: '/SmsLatestResult',
    name: 'SmsLatestResult',
    component: SmsLatestResult
  },
  {
    path: '/LineAdsPackage',
    name: 'LineAdsPackage',
    component: LineAdsPackage
  },
  {
    path: '/LineShortUrl',
    name: 'LineShortUrl',
    component: LineShortUrl
  },
  {
    path: '/FacebookAdsPackage',
    name: 'FacebookAdsPackage',
    component: FacebookAdsPackage
  },
  {
    path: '/FbShortUrl',
    name: 'FbShortUrl',
    component: FbShortUrl
  },
  {
    path: '/FbadsTable',
    name: 'FbadsTable',
    component: FbadsTable
  },
  {
    path: '/FbadsTableByAdv',
    name: 'FbadsTableByAdv',
    component: FbadsTableByAdv
  },
  
  {
    path: '/FbTrendAnal',
    name: 'FbTrendAnal',
    component: FbTrendAnal
  },
  {
    path: '/FbThemeAnal',
    name: 'FbThemeAnal',
    component: FbThemeAnal
  },
  {
    path: '/testdragpage',
    name: 'testdragpage',
    component: testdragpage
  },
  {
    path: '/ChannelMarketing',
    name: 'ChannelMarketing',
    component: ChannelMarketing
  },
  {
    path: '/EmailSetting',
    name: 'EmailSetting',
    component: EmailSetting
  },
  {
    path: '/SmsSetting',
    name: 'SmsSetting',
    component: SmsSetting
  },
  {
    path: '/ScriptTable',
    name: 'ScriptTable',
    component: ScriptTable
  },
  {
    path: '/MemberStatus',
    name: 'MemberStatus',
    component: MemberStatus
  },
  {
    path: '/MemberUploadDel',
    name: 'MemberUploadDel',
    component: MemberUploadDel
  },
  {
    path: '/TriggerScriptPerformance',
    name: 'TriggerScriptPerformance',
    component: TriggerScriptPerformance
  },
  {
    path: '/FullCalendar',
    name: 'FullCalendar',
    component: FullCalendar
  },
  {
    path: '/WebsiteAnalysis',
    name: 'WebsiteAnalysis',
    component: WebsiteAnalysis
  },
  // {
  //   path: '/LabelsReport',
  //   name: 'LabelsReport',
  //   component: LabelsReport
  // },
  {
    path: '/TagReport',
    name: 'TagReport',
    component: TagReport
  },
  // {
  //   path: '/NewRFMCustomPage',
  //   name: 'NewRFMCustomPage',
  //   component: NewRFMCustomPage
  // },
  // {
  //   path: '/NewRFMpage',
  //   name: 'NewRFMpage',
  //   component: NewRFMpage
  // },
  {
    path: '/MemberLimit',
    name: 'MemberLimit',
    component: MemberLimit
  },
  {
    path: '/MemberData',
    name: 'MemberData',
    component: MemberData
  },
  {
    path: '/New360Page',
    name: 'New360Page',
    component: New360Page
  },
  {
    path: '/reportExport',
    name: 'reportExport',
    component: reportExport
  },
  {
    path: '/ClickForceTag',
    name: 'ClickForceTag',
    component: ClickForceTag
  },
  {
    path: '/WordCloud',
    name: 'WordCloud',
    component: WordCloud
  },
  {
    path: '/TryNewTrigger',
    name: 'TryNewTrigger',
    component: TryNewTrigger
  },
  {
    path: '/LabelManage',
    name: 'LabelManage',
    component: LabelManage
  },
  // {
  //   path: '/CommoditySales_NW',
  //   name: 'CommoditySales_NW',
  //   component: CommoditySales_NW
  // },

  
  
  {
    path: '/',
    redirect: { name: 'OperationalOverview' }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
