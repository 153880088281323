<template>
  <div>
    <div class="container-fluid login-page p-0">
      <div class="d-flex">
        <div class="leftNav col-2 sidebar">
          <ul class="list-unstyled components">
            <li style="margin-bottom: 30px">
              <div>
                <router-link to="/AccountSetting" active-class="active">
                  <img src="../assets/setting-gray.png" v-show="settingPage" />
                  <img
                    src="../assets/setting-white.png"
                    v-show="!settingPage"
                  />
                  帳號設定
                </router-link>
                <router-link
                  to="/TermPage"
                  active-class="active"
                  @click="this.termpage = !this.termpage"
                >
                  <img src="../assets/service-gray.png" v-show="!termPage" />
                  <img src="../assets/service-white.png" v-show="termPage" />
                  服務條款
                </router-link>
                <router-link to="/PrivacyPage" active-class="active">
                  <img src="../assets/privacy-icon.svg" v-show="!privacypage" />
                  <img src="../assets/privacy-white.png" v-show="privacypage" />
                  隱私權政策
                </router-link>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-10 main-section">
          <h3>
            <img
              src="../assets/back-arrow.png"
              @click="$router.go(-1)"
              style="cursor: pointer"
            />
            &nbsp; &nbsp; 帳號設定
          </h3>
          <div class="col-6">
            <div class="personal-info-div">
              <p style="color: #71afb6" class="fs">個人資料帳戶</p>

              <label for="exampleInputEmail1" class="input-title"
                >用戶名稱</label
              >
              <input
                type="text"
                class="form-control mt-2"
                aria-describedby="emailHelp"
                placeholder=""
                v-model="signupUser"
                disabled
              />
              <label for="exampleInputEmail1" class="input-title mt-2">用戶帳號</label>
              <input
                type="text"
                class="form-control mt-2"
                aria-describedby="emailHelp"
                placeholder=""
                v-model="signupUserName"
                disabled
              />
              <label for="exampleInputEmail1" class="input-title mt-2">Email</label>
              <input
                type="text"
                class="form-control mt-2"
                aria-describedby="emailHelp"
                placeholder=""
                v-model="signupEmail"
                disabled
              />
            </div>

            <div class="personal-info-div2">
              <p style="color: #71afb6" class="fs">更改密碼</p>

              <label for="exampleInputEmail1" class="input-title"
                >當前密碼</label
              >
              <input
                type="password"
                class="form-control mt-2"
                aria-describedby="emailHelp"
                placeholder=""
                v-model="oldpassword"
                disabled
              />

              <label for="exampleInputEmail1" class="input-title mt-2">新密碼</label>
              <input
                type="password"
                class="form-control mt-2"
                id="exampleInputUsername"
                aria-describedby="emailHelp"
                placeholder="確保它至少有 6 個字符"
                v-model="newpassword1"
                disabled
              />

              <label for="exampleInputEmail1" class="input-title mt-2"
                >確認密碼</label
              >
              <input
                type="password"
                class="form-control mt-2"
                aria-describedby="emailHelp"
                placeholder="確保它至少有 6 個字符"
                v-model="newpassword2"
                disabled
              />
            </div>

            <button
              type="button"
              class="btn btn-primary button-color"
              @click="signup()"
            >
              更新
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSetting",
  data() {
    return {
      signupUser: "",
      signupUserName: "",
      signupEmail: "",
      oldpassword:'',
      newpassword1:'',
      newpassword2:'',
      settingPage: false,
      termPage: false,
      privacypage: false,
    };
  },
  watch: {
    $route: function () {
      this.settingPage = this.$route.path === "/AccountSetting";
    },
  },
  created() {
    this.signupUser = sessionStorage.getItem('name');
    this.signupUserName = sessionStorage.getItem('username');
    this.signupEmail = sessionStorage.getItem('email');
  },
};
</script>

<style scoped>
/* AccountSetting */
.container-fluid {
  height: 100%;
}
.login-page {
  position: absolute;
  left: 0;
  /* top: 0; */
  /* background-color: #fff; */
  /* overflow: hidden; */
}

li {
  cursor: pointer;
}
img {
  position: relative;
  top: -2px;
}
.leftNav {
  background-color: #eaf3f4;
  height: inherit;
  min-width: 216px;
  /* min-height: calc(80vh); */
  padding-top: 50px;
  height: 200vh;
}
.highlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #63a7c8;
}
.nohighlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #8a8a8a;
}
.inside-highlight {
  /* background-color: #63a7c8; */
  color: #63a7c8;
  border-radius: 8px;
  font-size: 18px;
  padding-left: 35px;
  height: 40px;
  line-height: 40px;
}
.inside-nohighlight {
  color: #8a8a8a;
  font-size: 18px;
}
.show {
  display: block;
}
.noshow {
  display: none;
}
a {
  display: block;
  color: #8a8a8a;
  text-decoration: none;
  border-radius: 8px;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
}
.active {
  display: block;
  background-color: #63a7c8;
  color: white;
}
.inside-active {
  color: #71afb6;
}
.main-section {
  padding: 50px;
}
.fs {
  font-size: 18px;
}
.personal-info-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
}
.personal-info-div2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
}

.button-color {
  /*background-color:#71AFB6;*/
  margin-top: 30px;
  border: none;
  width: 37vw;
  padding: 10px;
  background-image: linear-gradient(to top left, #71afb6, #b5dce0);
  color: #fff;
  border-radius: 10px;
}
</style>