<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium">購買紀錄</span>
        <div class="title-button-div">
          <button
            class="button-nofocus"
            :class="all ? 'button-focus' : ''"
            @click="getall"
          >
            全部
          </button>
          <button
            class="button-nofocus"
            :class="online ? 'button-focus' : ''"
            @click="getonline"
          >
            線上
          </button>
          <button
            class="button-nofocus"
            :class="offline ? 'button-focus' : ''"
            @click="getoffline"
          >
            線下
          </button>
        </div>
        <!-- <button @click="test">test</button> -->
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div style="width: 23.5%">
          <div class="customerNumber-div">
            <p class="fs-small p-3 pb-0 mb-0" style="color: #71afb6">
              顧客編號
            </p>
            <div>
              <div class="search-div">
                <img
                  class="searchImg-basic"
                  src="../assets/search.png"
                  alt="searchImg"
                />
                <input
                  id="input360"
                  class="search-input-360"
                  v-model="searching"
                  v-on:keyup.enter="enterinput()"
                  placeholder="可輸入ID.姓名.電話.信箱搜尋"
                  v-tooltip.right="{
                    value: '請按Enter搜尋',
                    class: 'tipInBasic',
                  }"
                />
                <img
                  class="xImg-basic"
                  src="../assets/greenX.svg"
                  alt=""
                  @click="this.searchClear()"
                  style="cursor: pointer"
                />
              </div>
              <hr
                style="
                  width: 100%;
                  margin: 0px;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div class="d-flex flex-wrap sorting-button-div">
                <button
                  :class="cost ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickCost"
                >
                  消費金額
                  <img class="sorting-img" :src="upimg" alt="searchImg" />
                </button>
                <button
                  :class="count ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickCount"
                >
                  消費次數
                  <img class="sorting-img" :src="upimg2" alt="searchImg" />
                </button>
                <button
                  :class="consumption ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickConsumption"
                >
                  最近消費
                  <img class="sorting-img" :src="upimg3" alt="searchImg" />
                </button>
                <button
                  :class="addtime ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickTime"
                >
                  加入時間
                  <img class="sorting-img" :src="upimg4" alt="clickTime" />
                </button>
              </div>
              <hr
                style="
                  width: 100%;
                  margin-top: 0px;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div
                style="height: 450px"
                class="d-flex flex-column justify-content-end"
                v-if="this.wholeSearchloading"
              >
                <div class="d-flex justify-content-center my-auto">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="customerID-div" v-if="this.member_id.length === 0">
                  查無此資料
                </div>
                <div
                  class="customerID-div"
                  @scroll="this.scroll($event)"
                  v-else
                >
                  <div
                    class="loop-div"
                    :key="item"
                    v-for="(item, index) in member_id"
                  >
                    <input
                      type="radio"
                      id="vehicle1"
                      name="vehicle1"
                      :value="item"
                      v-model="checkbox"
                      @click="check($event, customerID[index])"
                    />
                    {{ customerID[index] }}
                  </div>
                  <div class="d-flex justify-content-center" v-if="searchload">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <!-- <p>{{this.customerID}}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 76.5%">
          <div class="row ps-3">
            <div class="pe-0" style="padding-left: 12px">
              <div
                class="table-pie-div d-flex align-items-center"
                style="position: relative; height: 90px; padding: 20px"
              >
                <p class="m-0">
                  <span style="color: #c4c4c4" class="me-4">姓名</span>
                  <span class="me-5">{{ member_info.姓名 }}</span>
                  <span style="color: #c4c4c4" class="me-4">手機</span>
                  <span class="me-5">{{ member_info.手機 }}</span>
                  <span style="color: #c4c4c4" class="me-4">信箱</span>
                  <span class="me-5">{{ member_info.信箱 }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="row ps-3">
            <div class="col-6" :class="all ? 'col-6' : 'col-12'">
              <div class="table-pie-div" style="position: relative">
                <p class="fs-small mb-0" style="color: #71afb6">最近購買商品</p>
                <span
                  style="
                    position: absolute;
                    top: 70%;
                    left: 40%;
                    color: #c4c4c4;
                  "
                  v-if="this.selectedTableData[0] === undefined"
                  >無資料</span
                >
                <DataTable
                  :value="selectedTableData"
                  class="bottom-table"
                  :scrollable="true"
                  scrollHeight="220px"
                >
                  <Column
                    field="商品編號"
                    header="商品名稱"
                    :sortable="true"
                    style="min-width: 120px"
                  ></Column>
                  <Column
                    field="銷售額"
                    header="消費金額"
                    :sortable="true"
                    style="min-width: 120px"
                  ></Column>
                  <Column
                    field="線上/線下"
                    header="線上/線下"
                    :sortable="true"
                    style="min-width: 150px"
                  ></Column>
                </DataTable>
              </div>
            </div>
            <div v-show="all" class="col-6 pe-0">
              <div class="table-pie-div">
                <span class="fs-small" style="color: #71afb6"
                  >線上線下消費金額佔比</span
                >
                <div id="donut" style="position: relative">
                  <span
                    class="text-center"
                    style="
                      position: absolute;
                      top: 10%;
                      left: 20%;
                      color: #c4c4c4;
                    "
                    v-if="this.series2.every((d) => d === 0)"
                  >
                    <img
                      src="../assets/missData.svg"
                      alt=""
                      style="width: 200px"
                    />
                    <br />
                    無資料</span
                  >
                  <apexchart
                    class="bottom-table"
                    type="donut"
                    height="260"
                    :options="chartOptions2"
                    :series="series2"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="row ps-3">
            <div class="col-4">
              <div
                class="
                  number-div
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="fs-small" style="color: #71afb6">總消費筆數</span>
                <div
                  class="d-flex justify-content-between align-items-center mt-3"
                >
                  <p class="data-number" v-if="selectState.總消費筆數 === 0">
                    - -
                  </p>
                  <p class="data-number" v-else>
                    {{ selectState.總消費筆數 }}&nbsp;
                  </p>
                  <p class="data-unit">(筆)</p>
                </div>
              </div>
              <div
                class="
                  number-div
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="fs-small" style="color: #71afb6">平均客單價</span>
                <div
                  class="d-flex justify-content-between align-items-center mt-3"
                >
                  <p class="data-number" v-if="selectState.平均客單價 === ''">
                    - -
                  </p>
                  <p class="data-number" v-else>
                    {{
                      Number(
                        selectState.平均客單價.toFixed(0)
                      ).toLocaleString()
                    }}&nbsp;
                  </p>
                  <p class="data-unit">($)</p>
                </div>
              </div>
              <div
                class="
                  number-div
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <span class="fs-small" style="color: #71afb6"
                  >平均購買間隔時長</span
                >
                <div
                  class="d-flex justify-content-between align-items-center mt-3"
                >
                  <p
                    class="data-number"
                    v-if="selectState.平均購買間隔時長 === null"
                  >
                    - -
                  </p>
                  <p class="data-number" v-else>
                    {{ selectState.平均購買間隔時長.toFixed(0) }}&nbsp;
                  </p>
                  <p class="data-unit">(天)</p>
                </div>
              </div>
            </div>
            <div class="col-8 pe-0">
              <div class="line-div" style="position: relative">
                <span class="fs-small" style="color: #71afb6"
                  >購買時序分析</span
                >
                <span
                  class="fs-medium"
                  style="
                    position: absolute;
                    top: 40%;
                    left: 48%;
                    color: #c4c4c4;
                  "
                  v-if="this.series3[0].data.every((d) => d === 0)"
                  >無資料</span
                >
                <apexchart
                  class="columm-div-scroll"
                  style="height: 210px;min-height:150px"
                  type="bar"
                  height="210"
                  :options="chartOptions3"
                  :series="series3"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div style="width: 49%">
          <div class="columm-div" style="position: relative">
            <span class="fs-small" style="color: #71afb6"
              >購買時間點(星期)</span
            >
            <span
              class="fs-medium"
              style="position: absolute; top: 45%; left: 45%; color: #c4c4c4"
              v-if="this.seriesColumm[0].data.every((d) => d === 0)"
              >無資料</span
            >
            <apexchart
              type="bar"
              height="300"
              :options="chartOptionsColumm"
              :series="seriesColumm"
            ></apexchart>
          </div>
        </div>
        <div style="width: 49%">
          <div class="columm-div" style="position: relative">
            <span class="fs-small" style="color: #71afb6"
              >購買時間點(小時)</span
            >
            <span
              class="fs-medium"
              style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
              v-if="this.seriesColumm2[0].data.every((d) => d === 0)"
              >無資料</span
            >
            <apexchart
              class="columm-div-scroll"
              type="bar"
              width="1000"
              height="280"
              :options="chartOptionsColumm2"
              :series="seriesColumm2"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";

import updownimage from "../assets/sorting-button.png";
import upimage from "../assets/up-arrow.png";
import downimage from "../assets/down-arrow.png";

import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "PurchaseRecord",

  components: {
    DataTable,
    Column,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: false,
      wholeSearchloading: false,
      searching: "",
      rank: "",
      ascending: false,
      checkbox: "",
      selectId: "19",
      customerID: [],
      member_id: [],

      allThree: {},
      selectState: {
        平均客單價: "",
        平均購買間隔時長: 0,
        總消費筆數: 0,
      },
      member_info: {},
      memberWeek: {},
      memberHour: {},
      lineData: {},
      tableData: {},
      selectedTableData: [],

      cost: false,
      count: false,
      consumption: false,
      addtime: false,

      upimg: updownimage,
      upimg2: updownimage,
      upimg3: updownimage,
      upimg4: updownimage,

      // dount
      series2: [],
      chartOptions2: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#63A7C8", "#F9C357"],
        labels: ["線上", "線下"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      // bar
      series3: [
        {
          name: "金額",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          type: "bar",
          height: 210,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          title: {
            text: "日期",
            offsetY:-30
          },
        },
        yaxis: {
          title: {
            text: "消費金額",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },

      //columm

      seriesColumm: [
        {
          name: "消費金額",
          data: [],
        },
      ],
      chartOptionsColumm: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
          title: {
            text: "星期",
          },
        },
        yaxis: {
          title: {
            text: "消費金額",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      //columm

      seriesColumm2: [
        {
          name: "消費金額",
          data: [],
        },
      ],
      chartOptionsColumm2: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
          title: {
            text: "小時",
          },
        },
        yaxis: {
          title: {
            text: "消費金額",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },

      online: false,
      offline: false,
      all: true,

      ids: 0,
      notEnd: true,
      bottom: false,
      searchload: false,
    };
  },
  beforeRouteLeave: function (to, from) {
    sessionStorage.removeItem("template_key");
    if (
      to.path === "/BasicInfo" ||
      to.path === "/PurchaseRecord" ||
      to.path === "/OnlineBehavior"
    ) {
      return;
    } else {
      console.log("remove checkboxID");

      sessionStorage.removeItem("checkboxID");
    }
  },
  watch: {
    searching: function (val) {
      if (val === "") {
        this.searchClear();
      }
    },
  },
  created: async function () {
    console.log(sessionStorage.getItem("checkboxID"));
    this.searching = sessionStorage.getItem("checkboxID");
    this.selectId = sessionStorage.getItem("checkboxMemberID");
    await this.search();
    // await Promise.all([
    //   this.getMemberShopie(),
    //   this.getMemberThree(),
    //   this.getMemberWeek(),
    //   this.getMemberHour(),
    //   this.getMemberLinechart(),
    //   this.getMemberTable(),
    // ]);

    // this.getall();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  methods: {
    beforeunloadcall(e) {
      e = e || window.event;
      // e.returnValue = '确定要关闭窗口吗？'
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        sessionStorage.removeItem("checkboxID");
        sessionStorage.removeItem("checkboxMemberID");
        console.log("123");
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "whatever";
    },
    scroll: async function (e) {
      const { target } = e;
      if (
        Math.ceil(target.scrollTop) >=
        target.scrollHeight - target.offsetHeight
      ) {
        if (this.searchload === false) {
          await this.searchMore();
          this.searchload = false;
        }
        this.bottom = true;
      }
      console.log("this.bottom", this.bottom);
    },
    clickCost() {
      this.rank = "消費金額";
      this.cost = true;
      (this.count = false), (this.consumption = false), (this.addtime = false);
      if (this.upimg === downimage) {
        this.ascending = true;
        this.upimg = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg = downimage;
        this.search();
      }

      this.upimg2 = updownimage;
      this.upimg3 = updownimage;
      this.upimg4 = updownimage;
    },
    clickCount() {
      this.rank = "消費次數";
      (this.cost = false), (this.count = true);
      (this.consumption = false), (this.addtime = false);
      if (this.upimg2 === downimage) {
        this.ascending = true;
        this.upimg2 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg2 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg3 = updownimage;
      this.upimg4 = updownimage;
    },
    clickConsumption() {
      this.rank = "最近消費";
      (this.cost = false), (this.count = false);
      (this.consumption = true), (this.addtime = false);
      if (this.upimg3 === downimage) {
        this.ascending = true;
        this.upimg3 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg3 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg2 = updownimage;
      this.upimg4 = updownimage;
    },
    clickTime() {
      this.rank = "加入時間";
      (this.cost = false), (this.count = false);
      (this.consumption = false), (this.addtime = true);
      if (this.upimg4 === downimage) {
        this.ascending = true;
        this.upimg4 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg4 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg2 = updownimage;
      this.upimg3 = updownimage;
    },
    enterinput() {
      this.search();
    },
    search: async function () {
      this.ids = 0;
      this.loading = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "overall",
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log("start search", res);
          this.customerID = res.data.Data.ID;
          this.member_id = res.data.Data.member_id;
          if (this.customerID[0] != undefined) {
            this.checkbox = this.member_id[0];
            this.selectId = this.member_id[0];
            sessionStorage.setItem("checkboxID", this.customerID[0]);
            sessionStorage.setItem("checkboxMemberID", this.member_id[0]);
          }
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });

      await Promise.all([
        this.getMemberInfo(),
        this.getMemberThree(),
        this.getMemberTable(),
        this.getMemberShopie(),
        this.getMemberWeek(),
        this.getMemberHour(),
        this.getMemberLinechart(),
      ]);
      // await Promise.all([
      //   this.getMemberWeek(),
      //   this.getMemberHour(),
      //   this.getMemberLinechart(),
      // ]);

      if (this.all === true) {
        this.getall();
      } else if (this.online === true) {
        this.getonline();
      } else {
        this.getoffline();
      }
      this.loading = false;
    },
    searchMore: async function () {
      this.searchload = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "overall",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log(res);

          this.customerID = this.customerID.concat(res.data.Data.ID);
          this.member_id = this.member_id.concat(res.data.Data.member_id);
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    searchClear: async function () {
      this.wholeSearchloading = true;
      this.ids = 0;
      this.searching = "";
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "overall",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log(res);
          this.customerID = res.data.Data.ID;
          this.member_id = res.data.Data.member_id;
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
          this.wholeSearchloading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.wholeSearchloading = false;
          alert("連線有誤");
        });
    },
    getMemberInfo: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicInfoGet(params)
        .then((res) => {
          console.log("MemberInfo", res);
          const values = Object.values(res.data.Data);
          const keys = Object.keys(res.data.Data);
          console.log(values);
          for (let index = 0; index < values.length; index++) {
            if (values[index] === null) {
              res.data.Data[keys[index]] = "- -";
            }
          }
          this.member_info = res.data.Data;
          console.log(this.member_info);

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberShopie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOverallShopieGet(params)
        .then((res) => {
          console.log("pie", res);
          this.series2 = Object.values(res.data.Data).map((d) => {
            return parseInt(d);
          });
          this.chartOptions2 = {
            labels: Object.keys(res.data.Data),
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberThree: async function () {
      let time = 0;
      const conut = setInterval(() => {
        time++;
      }, 1000);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOverallThreeGet(params)
        .then((res) => {
          clearInterval(conut);
          console.log("時間", time);
          console.log("3筆資料", res);
          this.allThree = res.data.Data;
          if (res.data.Data.整體 === undefined) {
            this.allThree.整體 = {
              平均客單價: "",
              平均購買間隔時長: 0,
              總消費筆數: 0,
            };
            this.allThree.線上 = {
              平均客單價: "",
              平均購買間隔時長: 0,
              總消費筆數: 0,
            };
            this.allThree.線下 = {
              平均客單價: "",
              平均購買間隔時長: 0,
              總消費筆數: 0,
            };
          } else {
            // const dotIndex = res.data.Data.整體.平均客單價
            //   .toLocaleString("en-US")
            //   .indexOf(".");
            // const dotIndex2 = res.data.Data.線上.平均客單價
            //   .toLocaleString("en-US")
            //   .indexOf(".");
            // const dotIndex3 = res.data.Data.線下.平均客單價
            //   .toLocaleString("en-US")
            //   .indexOf(".");
            // res.data.Data.整體.平均客單價 = res.data.Data.整體.平均客單價
            //   .toLocaleString("en-US")
            //   .slice(0, dotIndex);
            // res.data.Data.線上.平均客單價 = res.data.Data.線上.平均客單價
            //   .toLocaleString("en-US")
            //   .slice(0, dotIndex2);
            // res.data.Data.線下.平均客單價 = res.data.Data.線下.平均客單價
            //   .toLocaleString("en-US")
            //   .slice(0, dotIndex3);

            this.selectState = this.allThree.整體;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberWeek: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOverallWeekGet(params)
        .then((res) => {
          console.log("bar week", res);
          this.memberWeek = res.data.Data;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberHour: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOverallHourGet(params)
        .then((res) => {
          console.log("bar hour", res);
          this.memberHour = res.data.Data;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberLinechart: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOverallLinechartGet(params)
        .then((res) => {
          console.log("bar 時序", res);
          if (res.data.Data.整體 === undefined) {
            this.lineData = {
              整體: { date: [], sales: [] },
              線上: { date: [], sales: [] },
              線下: { date: [], sales: [] },
            };
          } else {
            this.lineData = res.data.Data;
          }
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOverallPurchaseGet(params)
        .then((res) => {
          console.log("table", res);
          this.tableData = res.data.Data;
          this.selectedTableData = this.tableData.整體;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getall() {
      console.log("開始畫時柱狀圖囉");
      this.all = true;
      this.online = false;
      this.offline = false;
      this.selectState = this.allThree.整體;
      this.seriesColumm = [{ data: this.memberWeek.整體.sales }];
      this.seriesColumm2 = [{ data: this.memberHour.整體.sales }];
      console.log("開始畫時序圖囉");
      this.series3 = [{ data: this.lineData.整體.sales }];
      if (this.series3[0].data.length > 12) {
        this.chartOptions3 = {
          chart: {
            width: 1000,
          },
          xaxis: { categories: this.lineData.整體.date, tickAmount: 15 },
        };
      } else {
        this.chartOptions3 = {
          xaxis: { categories: this.lineData.整體.date, tickAmount: 7 },
        };
      }

      this.selectedTableData = this.tableData.整體;
    },
    getonline() {
      console.log("開始畫時柱狀圖囉");
      this.all = false;
      this.online = true;
      this.offline = false;
      this.selectState = this.allThree.線上;
      this.seriesColumm = [{ data: this.memberWeek.線上.sales }];
      this.seriesColumm2 = [{ data: this.memberHour.線上.sales }];
      console.log("開始畫時序圖囉");

      if (this.series3[0].data.length > 12) {
        this.chartOptions3 = {
          chart: {
            width: 1000,
          },
          xaxis: { categories: this.lineData.整體.date, tickAmount: 15 },
        };
      } else {
        this.chartOptions3 = {
          xaxis: { categories: this.lineData.整體.date, tickAmount: 7 },
        };
      }
      this.selectedTableData = this.tableData.線上;
    },
    getoffline() {
      this.all = false;
      this.online = false;
      this.offline = true;
      this.selectState = this.allThree.線下;
      this.seriesColumm = [{ data: this.memberWeek.線下.sales }];
      this.seriesColumm2 = [{ data: this.memberHour.線下.sales }];
      if (this.series3[0].data.length > 12) {
        this.chartOptions3 = {
          chart: {
            width: 1000,
          },
          xaxis: { categories: this.lineData.整體.date, tickAmount: 15 },
        };
      } else {
        this.chartOptions3 = {
          xaxis: { categories: this.lineData.整體.date, tickAmount: 7 },
        };
      }
      this.selectedTableData = this.tableData.線下;
    },
    check: async function (e, id) {
      this.loading = true;
      console.log(e.target.value);
      this.selectId = e.target.value;
      sessionStorage.setItem("checkboxID", id);
      sessionStorage.setItem("checkboxMemberID", this.selectId);
      await Promise.all([
        this.getMemberInfo(),
        this.getMemberShopie(),
        this.getMemberThree(),
        this.getMemberWeek(),
        this.getMemberHour(),
        this.getMemberLinechart(),
        this.getMemberTable(),
      ]);

      if (this.all === true) {
        this.getall();
      } else if (this.online === true) {
        this.getonline();
      } else {
        this.getoffline();
      }
      this.loading = false;
    },

    test() {
      this.getMemberThree();
      this.getMemberTable();
      this.getMemberShopie();
    },
  },
};
</script>
<style scoped>
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.searchImg {
  z-index: 2;
  position: relative;
  top: 10px;
  left: 10px;
}
.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}

.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}

.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}

.search-div {
  padding-top: 8px;
  height: 40px;
}
/* .search-input-360 {
  vertical-align: bottom;
  width: 75%;
  border: none;
  outline: none;
  text-indent: 15px;
} */
#input360::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-size: 14px;
}
#input360 input::placeholder {
  font-size: 14px;
}

.customerNumber-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  margin-top: 30px;
  height: 706px;
}

.customerID-div {
  margin: 20px;
  padding: 15px;
  height: 480px;
  overflow-y: scroll;
}

.sorting-button-div {
  padding: 8px;
}

.sorting-button {
  background-color: transparent;
  border: none;
  /* margin-top: 10px; */
  padding: 10px;
  color: #c4c4c4;
}

.button-highlight {
  background-color: #71afb6;
  border: none;
  /* margin-top: 10px; */
  padding: 10px;
  color: #fff;
  border-radius: 8px;
}

.sorting-img {
  padding-left: 10px;
}

.table-pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 30px;
  height: 295px;
}

.number-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 27.2px;
  height: 70px;
}

.line-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 27.2px;
  height: 264px;
}

.bottom-table {
  margin-top: 15px;
}

.data-unit {
  font-size: 16px;
  color: #ababab;
}

.data-number {
  font-size: 24px;
}

.columm-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 30px;
  height: 348px;
}

.columm-div-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}

@media screen and (max-width: 1590px) {
}
</style>