<template>
  <div>
    <div class="container-fluid login-page p-0">
      <div class="d-flex">
        <div class="leftNav col-2 sidebar">
          <ul class="list-unstyled components">
            <li style="margin-bottom: 30px">
              <div>
                <router-link to="/AccountSetting" active-class="active">
                    <img src="../assets/setting-gray.png" v-show="!settingPage" />
                  <img src="../assets/setting-white.png" v-show="settingPage" />
                  帳號設定
                </router-link>
                <router-link to="/TermPage" active-class="active" >
                  <img src="../assets/service-gray.png" v-show="termpage" />
                  <img src="../assets/service-white.png" v-show="!termpage" />
                  服務條款
                </router-link>
                <router-link to="/PrivacyPage" active-class="active">
                 <img src="../assets/privacy-icon.svg" v-show="!privacypage" />
                  <img src="../assets/privacy-white.png" v-show="privacypage" />
                  隱私權政策
                </router-link>
              
              </div>
            </li>
          </ul>
        </div>
        <div class="col-10 main-section">
        <div>
         
        
          
          <h3>  <img src="../assets/back-arrow.png"  @click="$router.go(-1)" style="cursor:pointer"/>   &nbsp; &nbsp; First CDP 服務條款</h3>
        </div>
          <p class="mt-4 fs">
            歡迎使用First CDP顧客數據平台，只要您完成First
            CDP顧客數據平台(以下簡稱「本平台」)之會員註冊手續、或開始使用本平台時，即表示您已閱讀、瞭解並同意聚典資訊股份有限公司(以下簡稱「本公司」)所制定的服務條款，並完全接受本服務現有與未來衍生的服務項目及內容。故請詳閱服務條款內容。
          </p>
          <p  class="mt-4 fs">1. 規範內容</p>
          <ul>
            <li>
              本公司會持續變更並改進本平台，可能會在未事前通知您的情況下新增或移除功能或特性，也可能完全暫停或停止某項服務。
            </li>
            <li>
              您隨時都可以停止使用本平台，如您繼續使用，即表示您接受這些變更。
            </li>
            <li>
              您使用本平台時，同意對於您的服務內容與行為，以及由此產生的任何後果承擔完全的責任。
            </li>
            <li>
              本公司僅以現狀提供本平台服務，不會就本平台中的內容、服務之特定功能及其可靠性、可用性和符合您的需求的能力，做出任何承諾。
            </li>
            <li>本公司可以蒐集您使用本平台時輸入、上載或傳送的資料。</li>
            <li>
              您所上載、傳送、輸入或提供予本平台之任何資料，其權利仍為您或您的授權人所有；但任何資料一經您上載、傳送、輸入或提供予本平台時，即表示您同意：由本公司及其關係企業儲存或管理該資料，並由本公司及其關係企業之搜尋工具進行索引及抓取，並公開刊登、使用於本服務及其關係企業的相關系統網路上，包括本平台所聯盟或合作的第三人網站上；及本平台之目的，進行使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯該等資料，不致侵害任何第三人之智慧財產權，否則應對本公司及其關係企業負損害賠償責任。
            </li>
            <li>
              您在使用本平台時，同意遵守所有中華民國的法律、法規和規章制度。您不得從事以下活動，也不得同意、授權或指示任何第三人從事以下活動：
              <ol>
                <li>
                  使用本平台上載、傳輸或採用其他方式分發任何非法的、誹謗性的、侵擾他人的、侮辱性的、詐欺性的、猥褻的、包含病毒的或根據本公司的合理判斷會在其他方面遭異議的任何內容
                </li>
                <li>
                  上載、傳輸或採用其他方式分發侵犯他方的智慧財產權或者其他專有、契約或信用權利或義務的內容
                </li>
                <li>竄改或盜用他人的商標、資料、標籤等內容</li>
                <li>阻止其他人使用本平台</li>
                <li>將本平台用於任何非法行為或不當目的</li>
                <li>
                  使用任何瀏覽器、抓取工具、其他自動設備或手動過程對本平台的任何內容進行監控、複製或破壞或違反本使用條款
                </li>
              </ol>
            </li>
          </ul>
          <p class="mt-4 fs">2. 會員註冊</p>
          <ul>
            <li>
              會員註冊程序完成後，會員維持密碼及帳號之機密安全，是會員的責任。任何依照規定方法輸入會員帳號及密碼與登入資料一致時，無論是否由本人親自輸入，均將推定為會員本人所使用，利用該密碼及帳號所進行的一切行動，會員本人應負完全責任。
            </li>
            <li>
              會員應妥善保管帳號及密碼，並為利用該密碼及帳號進入平台後所從事之所有行為負完全的責任，為維護您自身之權益，請勿將帳號及密碼出借或轉讓他人使用。
            </li>
            <li>會員資格不得轉讓、轉借或與他人合用，且不得重複申請。</li>
            <li>
              會員的帳號或密碼遭到盜用或發生任何安全問題時，會員應立即通知本公司。
            </li>
          </ul>
          <p class="mt-4 fs">3. 使用者的守法義務及承諾</p>
          <p>
            您同意不為任何非法目的或以任何非法方式使用本平台，並承諾遵守本服務條款之約定，中華民國相關法規及一切使用網際網路之國際慣例。會員若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
          </p>
          <ol>
            <li>
              傳輸或散佈電腦病毒或惡意程式、或以任何方式阻止或妨礙其他使用者正常使用本服務、或有其他任何可能干擾或破壞本公司平台及系統之行為。
            </li>
            <li>違反依據契約或法令所應負之義務，包括保密義務。</li>
            <li>冒用任何人或團體之名義使用本平台。</li>
            <li>
              侵害他人的智慧財產權或其他權利，包括複製、擷取或修改非經他人開放或授權之資訊等。
            </li>
            <li>
              從事未經本公司事前授權的商業行為、或有其他不法交易行為或有任何不當、不合常理之使用行為。
            </li>
            <li>
              其他不符合本服務所提供的使用目的或本公司有正當理由認為不適當之行為。
            </li>
          </ol>
          <p class="mt-4 fs">4. 智慧財產權</p>
          <ul>
            <li>
              本平台上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計、會員內容等，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、公開傳輸、公開演出、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。
            </li>
            <li>
              若您欲引用或轉載前述軟體、程式W或網站內容，除明確為法律所許可者外，必須依法取得本公司或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任。
            </li>
          </ul>
          <p  class="mt-4 fs">5. 平台之服務暫停或中斷</p>
          <ul>
            <li>
              本公司將依一般合理之技術及方式，維持本平台及系統之正常運作，但有下列情形之一者，您同意本公司不須因此負任何賠償或補償責任：
              <ol>
                <li>
                  對本平台相關軟硬體設備進行搬遷、更換、升級、保養或維修時。
                </li>
                <li>使用者有任何違反政府法令或本服務條款之情形時。</li>
                <li>
                  因天災等不可抗力或其他不可歸責於本公司之因素，致使本公司無法提供本平台時。
                </li>
                <li>
                  因維護、改置或變動所發生之本平台暫停或中斷，本公司將於該暫停或中斷前以電子郵件、公告或其他適當之方式告知會員。
                </li>
              </ol>
            </li>
          </ul>
          <p  class="mt-4 fs">6. 終止平台之服務</p>
          <ul>
            <li>
              基於本公司營運情形，本平台有可能停止提供本平台之部分或全部服務，或對本平台附加或設定新的限制，本公司不負任何賠償或補償責任。
            </li>
            <li>
              會員違反本服務條款時，本公司保留隨時暫停提供本平台或終止本平台之權利，對於因此所生之一切損害或損失，本公司不負任何賠償或補償責任。
            </li>
          </ul>
          <p  class="mt-4 fs">7.	準據法</p>
          <ul>
            <li>本服務條款若有疑義，依中華民國法律解釋，因本平台有關的任何爭議若無法協商解決，雙方同意以台灣台北地方法院為第一審管轄法院。</li>
          </ul>
          <p class="mt-5">Copyright © 2022 聚典資訊股份有限公司 All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"TermPage",
  data(){
    return{
      termpage:false,
      settingPage: false,
      privacypage: false,
    }
  },
  watch:{
    $route: function(){
      this.termpage =
      this.$route.path === "/TermPage";
    }
  }
};
</script>

<style scoped>
.container-fluid {
  height: 100%;
}
.login-page {
  position: absolute;
  left: 0;
  /* top: 0; */
  /* background-color: #fff; */
  /* overflow: hidden; */
}

li {
  cursor: pointer;
}
img {
  position: relative;
  top: -2px;
}
.leftNav {
  background-color: #eaf3f4;
  height: inherit;
  min-width: 216px;
  /* min-height: calc(80vh); */
  padding-top: 50px;
  height: 200vh;
}
.highlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #63a7c8;
}
.nohighlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #8a8a8a;
}
.inside-highlight {
  /* background-color: #63a7c8; */
  color: #63a7c8;
  border-radius: 8px;
  font-size: 18px;
  padding-left: 35px;
  height: 40px;
  line-height: 40px;
}
.inside-nohighlight {
  color: #8a8a8a;
  font-size: 18px;
}
.show {
  display: block;
}
.noshow {
  display: none;
}
a {
  display: block;
  color: #8a8a8a;
  text-decoration: none;
  border-radius: 8px;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
}
.active {
  display: block;
  background-color: #63a7c8;
  color: white;
}
.inside-active {
  color: #71afb6;
}
.main-section {
  padding: 50px;
}
.fs {
  font-size: 18px;
}
</style>