<template>
  <div class="modal-body alert-modal-body text-start py-3 px-5">
    <div class="d-flex align-items-center mb-3">
      <div style="width: 20%;line-height:40px">開始時間</div>
      <div class="cycletime-setting-block">
        {{ this.$props.cycleTime_setting.time_start }}
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div style="width: 20%">發送方式</div>
      <div class="cycletime-setting-block">定期投放</div>
    </div>
    <div class="d-flex flex-wrap mb-3">
      <div style="width: 20%;line-height:40px">周期條件</div>
      <div class="cycletime-setting-block mb-3">月</div>
      <div style="width: 20%"></div>
      <div
        style="width: 80%" class="d-flex"
        v-if="
          this.$props.cycleTime_setting.time_setting.happenMonth_moreoption ===
          false
        "
      >
        <div style="width: 30%;line-height:40px">投放於每月</div>
        <div class="cycletime-setting-block mb-3" style="width:70%">
          {{ this.$props.cycleTime_setting.time_setting.happenMonth }}
        </div>
      </div>
      <div
        class="d-flex flex-wrap"
        style="width: 80%"
        v-else-if="
          this.$props.cycleTime_setting.time_setting.happenMonth_moreoption ===
            true && this.$props.cycleTime_setting.time_setting.picked2 === 'One'
        "
      >
        <div style="width: 25%;line-height:40px">投放於每</div>
        <div class="cycletime-setting-block mb-3" style="width:75%">
          {{ this.$props.cycleTime_setting.time_setting.happenMonth2 }}
        </div>
        <div class="cycletime-setting-block mb-3" style="width: 100%">
          {{ this.$props.cycleTime_setting.time_setting.happenMonth3 }}
        </div>
      </div>
      <div
        class="d-flex flex-wrap"
        style="width: 80%"
        v-else-if="
          this.$props.cycleTime_setting.time_setting.happenMonth_moreoption ===
            true && this.$props.cycleTime_setting.time_setting.picked2 === 'Two'
        "
      >
        <div style="width: 25%;line-height:40px">投放於每</div>
        <div class="cycletime-setting-block mb-3" style="width: 75%">
          {{ this.$props.cycleTime_setting.time_setting.happenMonth2 }}
        </div>
        <div style="width: 49%;margin-right:2%" class="cycletime-setting-block mb-3">
          {{ this.$props.cycleTime_setting.time_setting.happenMonth4 }}
        </div>
        <div style="width: 49%" class="cycletime-setting-block mb-3">
          {{ this.$props.cycleTime_setting.time_setting.happenMonth5 }}
        </div>
      </div>
      <div style="width: 20%"></div>
      <div class="cycletime-setting-block">
        {{ this.$props.cycleTime_setting.time_setting.daytime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSettingModalMonth",
  components: {
  },
  props: {
    cycleTime_setting: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.cycletime-setting-block {
  width: 80%;
  border: 1px solid #c4c4c4;
  line-height: 40px;
  border-radius: 10px;
  color: #c4c4c4;
  text-align: center;
}
</style>