<template>
  <div class="container-fluid home">
    <!-- loading screen -->
    <div class="loading-background" v-show="loading">
      <p>建立排程中，請耐心等候</p>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- sms exp modal -->
    <SmsPreview :countText="countText" :resSubsec="expSubsec"></SmsPreview>
 
    <!-- sms editormodal -->
    <div
      class="modal expEmailModal2-background"
      id="smsEditor"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 100%">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <SmsTemplateEditor
            :template_key="template_key"
            :random_key="random_key"
            :key="refreshEditor"
            @close-editor="closeModal"
            v-if="
              this.$route.params.key != undefined && this.refreshEditor != 0
            "
          />
        </div>
      </div>
    </div>
    <!-- shorturl loading screen -->
    <div class="loading-background" v-show="urlloading">
      <p>資料處理中，請稍候</p>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- changedateModal -->
    <div
      class="modal fade"
      id="changedateModal"
      tabindex="-1"
      aria-labelledby="changedateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 410px; font-size: 18px">
        <div class="modal-content" style="width: 410px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body">
            <p>活動名稱</p>
            <input
              type="text"
              class="date-input mb-3 ps-3"
              style="width: 100%"
              placeholder="請輸入活動名稱"
              v-model="renameEvent"
            />
            <p>活動時間</p>
            <div class="row align-items-center justify-content-between">
              <span class="col-1 set-time-title">
                <img class="mb-1" src="../assets/date.svg" alt=""
              /></span>
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  :max-date="calentime"
                  v-model="calenday"
                  is24hr
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
              ~
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  :min-date="calenday"
                  v-model="calentime"
                  is24hr
                  is-expanded
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="basic-btn-calen"
              style="width:130px;background-color"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              data-bs-dismiss="modal"
              @click="addCalen"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- router push alert -->
    <div
      class="modal fade"
      id="alertModalroutergo"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">預約成功 !</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="routergoScript()"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- testMemberModal -->
    <div
      class="modal fade"
      id="testMemberModal"
      tabindex="-1"
      aria-labelledby="testMemberModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        style="width: 770px; max-width: 770px; font-size: 18px"
      >
        <div class="modal-content" style="width: 770px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body px-5">
            <p>測試名單</p>
            <p style="color: #c4c4c4">
              輸入最多五筆電子信箱與五筆電話號碼，並建立測試名單，每筆信箱請以換行方式做區隔，此名單不會匯入系統會員名單裡。您亦可依造正常流程進行排程與投放。
            </p>
            <p style="color: #71afb6">收件人電子信箱</p>
            <textarea
              class="mb-3 p-3"
              style="width: 100%; height: 200px; border-radius: 10px"
              v-model="emailtext"
            />
            <p style="color: #71afb6">收件人號碼</p>

            <textarea
              class="mb-3 p-3"
              style="width: 100%; height: 200px; border-radius: 10px"
              v-model="phonetext"
            />
            <p style="color: #c4c4c4">
              注意透過此名單寄出後一樣會算在寄信與簡訊成本裡。
            </p>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="basic-btn-calen"
              style="width:130px;background-color"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              @click="saveTestMember"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 確認發送測試信alert -->
    <div
      class="modal fade"
      id="postTestModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <div>
              一鍵發送測試信給你自己，瀏覽郵件在收件匣呈現的樣子<br />此功能不是郵件到達率的測試。
            </div>
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close2"
              data-bs-dismiss="modal"
              style="color: #000; background-color: #eaf3f4"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              @click="postTestApi()"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert schedule -->
    <div
      class="modal fade"
      id="alertModal5"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="width: 410px">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <div class="text-start" style="padding: 0 50px">
              確認後仍可編輯投放設定，但寄出前10分鐘內將無法進行任何更改
            </div>
          </div>
          <div class="d-flex justify-content-evenly mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close2"
              data-bs-dismiss="modal"
              style="color: #000; background-color: #eaf3f4; width: 150px"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              style="width: 150px"
              data-bs-dismiss="modal"
              @click="successPatchSchedule()"
              v-if="this.schedule_id != ''"
            >
              確認
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="successBookSchedule()"
              v-else
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- router push alert -->
    <div
      class="modal fade"
      id="alertModalroutergo"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">預約成功 !</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="routergoScript()"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container-fluid">
      <div class="title-span ms-2">
        <span>
          <img
            src="../assets/back-arrow.png"
            @click="$router.go(-1)"
            class="me-2 mb-1"
            style="cursor: pointer"
          />
          簡訊投放設定
        </span>
      </div>
      <div class="p-4 mt-4 form-container2" style="padding-left: 24px">
        <p style="color: #71afb6" class="">投放設定</p>
        <div class="d-flex pt-3">
          <label
            for=""
            class="col-1 col-form-label section-title p-0"
            style="color: #71afb6"
            >模板名稱</label
          >
          <div class="flex-fill">
            <input
              type="text"
              v-bind:class="{ 'is-invalid': titleError2 }"
              ref="titleInput2"
              name="add-input"
              class="form-control"
              v-model="template_name"
              disabled
            />
            <div class="invalid-feedback">
              {{ titleErrMsg2 }}
            </div>
          </div>
          <div class="d-flex justify-content-between" style="width: 250px">
            <button
              class="basic-btn highlight ms-4"
              style="width: 100px"
              :style="
                this.$route.params.key === undefined
                  ? 'opacity:0.5;cursor:not-allowed'
                  : ''
              "
              @click="updateSmsTemp"
            >
              <img
                src="../assets/edit.svg"
                alt=""
                style="width: 14px; position: relative; top: -1px; left: -3px"
              />
              編輯
            </button>
            <button
              class="basic-btn"
              style="width: 100px"
              @click="this.expshow()"
            >
              <img
                src="../assets/green-eye.svg"
                alt=""
                style="width: 16px; position: relative; top: -1px; left: -3px"
              />
              預覽
            </button>
          </div>
        </div>
        <div class="d-flex pt-3">
          <label
            for=""
            class="col-1 col-form-label section-title p-0"
            style="color: #71afb6"
            >活動名稱</label
          >
          <div
            class="search-container col-11"
            style="position: relative"
            ref="target"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                width: 100%;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow = !autoShow"
            >
              {{ calendar_event }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>

            <div
              class="auto-complicate-div-setting"
              style="width: 100%"
              v-show="autoShow"
              @mouseenter="autoShow = true"
            >
              <img
                src="../assets/search-black.png"
                alt="searchImg"
                class="ms-3 my-2 mt-1"
                style="width: 18px"
              />
              <input
                class="ms-1 p-1 ps-2 my-2"
                style="border: none; width: 90%"
                placeholder="搜尋活動名稱"
                v-model="searchInput2"
              />
              <hr
                class="m-0"
                style="
                  width: 100%;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div style="max-height: 150px; overflow: auto">
                <p
                  class="m-0 p-1 ps-3 auto-complicate-p"
                  @click="selectCalendar_event(event)"
                  v-for="event in searchEvents"
                  :key="event.id"
                >
                  <span>{{ event.title }}</span>
                  <span style="font-size: 12px; color: #c4c4c4; display: block"
                    >{{ event.start }} ~ {{ event.end }}</span
                  >
                </p>
              </div>
              <hr
                class="m-0"
                style="
                  width: 100%;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div
                class="ms-3 my-1"
                style="cursor: pointer"
                @click="wantaddCalen"
              >
                <img class="mb-1 me-2" src="../assets/blackPlus.svg" alt="" />
                建立新活動名稱
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 form-container2">
        <div class="p-4">
          <p style="color: #71afb6" class="">選擇客群</p>
          <div class="row">
            <div
              class="
                col-12
                mb-3
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex align-items-center col-3">
                <div class="search-container col-4" ref="target2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="
                      width: 270px;
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                      padding: 0 14px;
                      height: 35px;
                    "
                    @click="autoShow2 = !autoShow2"
                  >
                    {{ selectGroup }}
                    <img src="../assets/selection-arrow.png" alt="searchImg" />
                  </div>
                  <div
                    class="auto-complicate-div"
                    style="width: 270px"
                    v-show="autoShow2"
                    @mouseenter="autoShow2 = true"
                  >
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '全部';
                        autoShow2 = false;
                      "
                    >
                      全部
                    </p>
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '價值分群';
                        autoShow2 = false;
                      "
                    >
                      價值分群
                    </p>
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '個性化分群';
                        autoShow2 = false;
                      "
                    >
                      個性化分群
                    </p>
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '自訂分群';
                        autoShow2 = false;
                      "
                    >
                      自訂分群
                    </p>
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '測試人員';
                        autoShow2 = false;
                      "
                    >
                      測試人員
                    </p>
                    <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
                  </div>
                </div>
              </div>
              <div
                class="p-0 search-container col-5"
                v-if="
                  this.selectGroup === '價值分群' ||
                  this.selectGroup === '個性化分群' ||
                  this.selectGroup === '渠道行銷分群'||
                  this.selectGroup === '自訂分群' ||
                  this.selectGroup === '測試人員'
                "
              >
                <img
                  class="searchImg"
                  src="../assets/search.png"
                  alt="searchImg"
                />
                <input class="search-input" v-model="searchInput" />
              </div>
              <div
                class="col-3 d-flex justify-content-end"
                style="height: 40px; position: relative; min-width: 110px"
              >
                <Paginator
                  :rows="this.perPage"
                  :pageLinkSize="3"
                  template="PrevPageLink PageLinks NextPageLink"
                  :totalRecords="searchList.length"
                  @page="onPage($event)"
                  class="m-0"
                ></Paginator>
                <div
                  style="
                    position: absolute;
                    right: 5px;
                    bottom: -25px;
                    font-size: 14px;
                    color: #8a8a8a;
                  "
                >
                  目前 {{ this.page }} ，共
                  {{ Math.ceil(this.todos.length / this.perPage) }} 頁
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap" style="height: 112px">
            <div
              class="mt-3 px-2"
              style="width: 25%; position: relative"
              :key="index"
              v-for="(todo, index) in displayedRecords"
            >
              <button
                class="basic-btn px-0 mx-0"
                :class="
                  usedID.includes(todo.label_id) ||
                  usedID.includes(todo.labelId)
                    ? 'disable'
                    : ''
                "
                @click="popout = index"
                ref="target4"
                v-tooltip.bottom="
                  todo.label.length > 7
                    ? {
                        value: todo.label,
                        class: 'tip-LabelManage',
                      }
                    : {
                        value: '',
                        class: '',
                      }
                "
              >
                <div class="todo-span d-flex justify-content-between px-4">
                  <span class="word-overflow" style="line-height: 40px">
                    {{ todo.label }}
                  </span>
                  <img
                    src="../assets/template-plus-icon.svg"
                    style="cursor: pointer"
                    v-if="
                      !usedID.includes(todo.label_id) ||
                      usedID.includes(todo.labelId)
                    "
                  />
                </div>
              </button>
              <div
                style="
                  position: absolute;
                  top: 35px;
                  right: -65px;
                  background-color: white;
                  width: 150px;
                  z-index: 99;
                "
                class="form-container2"
                v-if="popout === index && !usedID.includes(todo.label_id)"
              >
                <p
                  class="m-0 popout-hover"
                  style="padding: 7px 15px; cursor: default"
                  @click="
                    addLabelsToIncludes(todo);
                    popout = '';
                  "
                >
                  <img
                    src="../assets/click-to-add-label.svg"
                    alt="add-label"
                    class="me-2"
                  />
                  <span> 選擇發送 </span>
                </p>
                <p
                  class="m-0 popout-hover"
                  style="padding: 7px 15px; cursor: default"
                  @click="
                    addLabelsToExcludes(todo);
                    popout = '';
                  "
                >
                  <img
                    src="../assets/click-to-exclude-label.svg"
                    alt="exclude-label"
                    class="me-2"
                  />
                  <span> 進行排除 </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="m-0"
          style="
            width: 100%;
            opacity: 1;
            height: 0;
            border: 0;
            border-bottom: 1px solid #c4c4c4;
          "
        />
        <div class="px-4 py-3 d-flex flex-wrap justify-content-between">
          <div style="width: 49%">
            <p style="color: #71afb6">選擇發送</p>
            <div
              class="d-flex flex-wrap py-3"
              style="
                height: 130px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                overflow: auto;
              "
            >
              <div
                class="mb-3 px-3"
                style="width: 50%"
                :key="index"
                v-tooltip.bottom="
                  todo.label.length > 7
                    ? {
                        value: todo.label,
                        class: 'tip-LabelManage',
                      }
                    : {
                        value: '',
                        class: '',
                      }
                "
                v-for="(todo, index) in includeLabels"
              >
                <button
                  class="basic-btn px-0 mx-0"
                  style="background-color: #71afb6; color: white"
                  @click="deleteLabelsFromIncludes(todo)"
                >
                  <div class="todo-span d-flex justify-content-between px-4">
                    <span class="word-overflow" style="line-height: 40px">
                      {{ todo.label }}
                    </span>
                    <img
                      src="../assets/whiteX.svg"
                      alt=""
                      style="cursor: pointer; margin-top: 1px; margin-left: 5px"
                    />
                  </div>
                </button>
              </div>
            </div>
            <p
              style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
              class="ms-1 mt-1 mb-0"
            >
              選擇您要發送的名單。
            </p>
          </div>
          <div style="width: 49%">
            <p style="color: #71afb6">進行排除</p>
            <div
              class="d-flex flex-wrap py-3"
              style="
                height: 130px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                overflow: auto;
              "
            >
              <div
                class="mb-3 px-3"
                style="width: 50%"
                :key="index"
                v-for="(todo, index) in excludeLabels"
              >
                <button
                  class="basic-btn px-0 mx-0"
                  style="background-color: #71afb6; color: white"
                  @click="deleteLabelsFromExcludes(todo)"
                >
                  <div class="todo-span d-flex justify-content-between px-4">
                    <span class="word-overflow" style="line-height: 40px">
                      {{ todo.label }}
                    </span>
                    <img
                      src="../assets/whiteX.svg"
                      alt=""
                      style="cursor: pointer; margin-top: 1px; margin-left: 5px"
                    />
                  </div>
                </button>
              </div>
            </div>
            <p
              style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
              class="ms-1 mt-1 mb-0"
            >
              讓您將特定聯絡人從選定的名單中排除。
            </p>
          </div>
        </div>
        <hr
          class="m-0"
          style="
            width: 100%;
            opacity: 1;
            height: 0;
            border: 0;
            border-bottom: 1px solid #c4c4c4;
          "
        />
        <div class="p-4">
          <p style="color: #71afb6">實際的投放人數</p>
          <div class="d-flex align-items-center mt-4 justify-content-between">
            <div class="d-flex align-items-center">
              <img src="../assets/peopleOrange.svg" style="z-index: 10" />
              <span class="progress-span"
                >{{ numbers }}人({{ Number(percentBars).toFixed(2) }}%)</span
              >
              <progress
                class="top-progress"
                :value="percentBars"
                max="100"
              ></progress>
            </div>
            <span style="font-size: 20px"
              >會員總數&nbsp;&nbsp;{{ members }}&nbsp;&nbsp;人</span
            >
          </div>
        </div>
      </div>
      <div class="contain-top mt-4" style="height: 260px">
        <div  class="mb-3" style="color: #71afb6">
          寄件人資料設定
          <BtnInfoBox content="請先設定寄件人資訊，用於此排程以及自動回應的寄件人Email帳號與電話號碼。" direction="right"></BtnInfoBox>
        </div>
        <div class="d-flex align-items-center ">
          <span style=" width: 140px"
            >電話號碼</span
          >
          <div
            class="search-container flex-fill"
            style="position: relative"
            ref="target3"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow4 = !autoShow4"
            >
              {{ selectSms }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>
            <div
              class="auto-complicate-div"
              style="width: 100%"
              v-show="autoShow4"
              @mouseenter="autoShow4 = true"
            >
              <p
                class="m-0 p-1 ps-3 auto-complicate-p"
                v-for="(item, index) in smsList"
                :key="index"
                @click="
                  this.selectSms = index;
                  this.realselectSms = item;
                  autoShow4 = false;
                "
              >
                {{ index }}
              </p>

              <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <span style="width: 140px"
            >Email</span
          >
          <div class="search-container flex-fill" ref="target3">
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow3 = !autoShow3"
            >
              {{ selectEmail }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>

            <div
              class="auto-complicate-div"
              style="width: 100%; cursor: default"
              v-show="autoShow3"
              @mouseenter="autoShow3 = true"
            >
              <p
                class="m-0 p-1 ps-3 auto-complicate-p"
                :value="item"
                v-for="item in emailList"
                :key="item"
                @click="
                  selectEmail = item;
                  autoShow3 = false;
                "
              >
                {{ item }}
              </p>

              <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
            </div>
          </div>
          <button
            class="ms-3 basic-btn highlight"
            style="width: 200px"
            @click="sendTest"
          >
            <img src="../assets/testSche.svg" alt="" class="me-2" /> 測試投放
          </button>
        </div>
        <div class="d-flex align-items-center mt-3" >
          <span style="width: 140px"
            >寄件人名稱
            <BtnInfoBox content="運用容易辨識的名稱，例如您的公司名。" direction="right"></BtnInfoBox>
            </span
          >
          <div class="search-container flex-fill" >
            <input
              type="text"
              style="border-radius: 10px"
              name="add-input"
              class="form-control"
              v-model="sender_name"
            />
          </div>
        </div>
        
        <div class="d-flex align-items-center mt-1" style="margin-left: 140px">
          若有需要新增手機號碼，請洽詢專案服務人員。
        </div>
      </div>
      <div class="p-4 ps-5 mt-5 form-container2">
        <div class="row align-items-center">
          <span class="col-2 set-time-title">選擇發送時間</span>
          <span class="col-5">
            <DatePicker
              class="inline-block h-full"
              :min-date="new Date()"
              v-model="day"
              is24hr
              @click="dayChange"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="d-flex align-items-center" @click="togglePopover()">
                  <input
                    :value="inputValue"
                    class="col-12 date-input"
                    readonly
                  />
                  <img
                    src="../assets/date.svg"
                    alt=""
                    style="position: relative; left: -30px"
                  />
                </div>
              </template>
            </DatePicker>
            <div class="invalid-feedback">
              {{ dayErrMsg }}
            </div>
          </span>
          <span class="col-5">
            <DatePicker
              class="inline-block h-full"
              v-model="time"
              mode="time"
              is24hr
              is-expanded
              @click="timeChange"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="d-flex align-items-center" @click="togglePopover()">
                  <input
                    :value="inputValue"
                    class="col-12 date-input"
                    readonly
                  />
                  <img
                    src="../assets/time.svg"
                    alt=""
                    style="position: relative; left: -30px"
                  />
                </div>
              </template>
            </DatePicker>
            <div
              style="
                color: red;
                font-size: 12px;
                position: absolute;
                top: 41px;
                left: 20px;
              "
              v-if="this.timeAlertShow"
            >
              請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)
            </div>
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button
          class="
            btn btn-lg btn-primary
            button-color
            d-flex
            align-items-center
            justify-content-center
          "
          data-bs-toggle="modal"
          data-bs-target="#alertModal5"
          v-if="this.schedule_id != ''"
        >
          <img
            src="../assets/check.svg"
            style="width: 18px; position: relative; top: 2px"
            class="me-2"
          />
          修改預約
        </button>
        <button
          class="
            btn btn-lg btn-primary
            button-color
            d-flex
            align-items-center
            justify-content-center
          "
          data-bs-toggle="modal"
          data-bs-target="#alertModal5"
          v-else
        >
          <img
            src="../assets/check.svg"
            style="width: 18px; position: relative; top: 2px"
            class="me-2"
          />
          確認預約
        </button>
        <!-- <button @click="test">test</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { Calendar, DatePicker } from "v-calendar";
import Paginator from "primevue/paginator";
import moment from "moment";
import SmsTemplateEditor from "../components/SmsSetting/SmsTemplateEditor.vue";
import SmsPreview from "../components/SmsPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "SmsSetting",
  components: {
    Paginator,
    Calendar,
    DatePicker,
    SmsTemplateEditor,
    SmsPreview,
    BtnInfoBox,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);
    const autoShow2 = ref(false);
    const target2 = ref(null);
    const autoShow3 = ref(false);
    const target3 = ref(null);
    const autoShow4 = ref(false);
    const target4 = ref(null);
    const popout = ref(null);

    onClickOutside(target, () => (autoShow.value = false));
    onClickOutside(target2, () => (autoShow2.value = false));
    onClickOutside(target3, () => (autoShow3.value = false));
    onClickOutside(target4, () => (autoShow4.value = false));
    onClickOutside(target3, () => (popout.value = false));

    return {
      target,
      autoShow,
      target2,
      autoShow2,
      target3,
      autoShow3,
      target4,
      autoShow4,
      popout,
    };
  },
  data() {
    return {
      sender_name:'',
      has_event: "",
      urlloading: false,
      timeAlertShow: false,

      percentBars: "",
      numbers: "- -",
      members: "- -",
      loading: false,
      group: null,
      searchInput: "",
      searchList: [],
      usedID: [],
      includeLabels: [],
      excludeLabels: [],
      combineLabelID: "",

      selectGroup: "--",
      selectedID: "",
      selectSms: "",
      realselectSms: "",

      smsList: [],
      emailList: [],

      selectEmail: "",

      newText: "",
      fullurlArr: [],
      shortUrlArr: [],
      urlok: true,
      smstodos: "",
      random_id: "",

      todotext: "",
      todourl: "",

      page: 1,
      perPage: 8,
      records: [],
      todos: [],
      RFMtodos: [],
      customtodos: [],
      channeltodos: [],
      originUploadLabels:[],
      ALLtodos: [],
      abSetting: false,
      abSend: false,

      template_name: "",
      schedule_name: "",
      title_name: "",
      title_nameB: null,
      scheduleError: false,
      scheduleErrMsg: "",
      labelError2: false,
      labelErrMsg2: "",
      titleError: false,
      titleErrMsg: "",
      titleError2: false,
      titleErrMsg2: "",

      percent: "",
      day: null,
      dayErrMsg: "",
      time: new Date(new Date().setHours(0, 0, 0, 0)),
      calenday: "",
      calentime: "",
      renameEvent: "",

      schedule_id: "",
      script_id: "",
      label_id: "",
      AB_proportion: "",
      template_key: "",
      selectedName: "",

      calendar_event: "請選擇活動",
      searchEvents: [],
      events: [],
      searchInput2: "",

      emailtext: "",
      phonetext: "",
      emailArr: [],
      phoneArr: [],
      testMemberModal: "",

      smsEditor: {},
      refreshEditor: 0,
      noCbox: true,
      countText: "",
      expSubsec: "",
    };
  },

  watch: {
    selectGroup: function (val) {
      console.log(val);
      if (val === "價值分群") {
        this.todos = this.RFMtodos;
        this.searchList = this.todos;
      } else if (val === "個性化分群") {
        this.todos = this.customtodos;
        this.searchList = this.todos;
      } else if (val === "渠道行銷分群") {
        this.todos = this.channeltodos;
        this.searchList = this.todos;
      } else if (val === "自訂分群") {
        this.todos = this.originUploadLabels;
        this.searchList = this.todos;
      }else if (val === "測試人員") {
        this.todos = [
          {
            label_id: "123456798",
            label: "測試人員",
          },
        ];
        this.searchList = [
          {
            label_id: "123456798",
            label: "測試人員",
          },
        ];
        // this.newtodos = [];
      } else if (val === "全部") {
        this.todos = this.ALLtodos;
        console.log("tods", this.todos);
        this.searchList = this.todos;
        this.newtodos = this.searchList;
        // this.newtodos = this.searchList.slice(0, this.perPage);
      }
    },
    schedule_name: function (val) {
      if (val != "") {
        this.titleError = false;
        this.titleErrMsg = "必須輸入活動名稱";
      } else if (val === "") {
        this.titleError = true;
      }
    },
    searchInput: function (val) {
      if (val === "") {
        this.searchList = this.todos;
      } else {
        console.log(val);
        this.searchList = this.todos.filter((d) => d.label.includes(val));
      }
    },
    searchInput2: function (val) {
      if (val != "") {
        this.searchEvents = this.events.filter((d) => {
          return d.title.includes(val);
        });
      } else {
        this.searchEvents = this.events;
      }
    },
    day: function () {
      var moment = require("moment");
      if (
        this.time < moment(new Date()) &&
        this.day.toDateString() === new Date().toDateString()
      ) {
        console.log("early");
        setTimeout(() => {
          this.time = moment(new Date()).add(2, "m")._d;
        }, 500);
        console.log("this.time", this.time);
      } else {
        return;
      }
    },
    time: function () {
      var moment = require("moment");
      if (
        this.time < moment(new Date()) &&
        this.day.toDateString() === new Date().toDateString()
      ) {
        console.log("early");
        setTimeout(() => {
          this.time = moment(new Date()).add(2, "m")._d;
        }, 500);
        console.log("this.time", this.time);
      } else {
        return;
      }
    },
  },

  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.searchList.slice(startIndex, endIndex);
    },
    selectedLabels() {
      return this.includeLabels.concat(this.excludeLabels);
    },
    // time() {
    //    if(this.time<new Date()&&this.day.toDateString()===new Date().toDateString()){
    //      console.log('early')
    //      return new Date()
    //    }
    // },
  },
  created: async function () {
    this.urlloading = true;
    await this.getCalenEvents();

    this.random_id = "T_" + this.randomString(8);

    await this.getSmstodosApi();
    this.getSmsList();
    this.getEmailList();
    this.template_name = sessionStorage.getItem("template_name");

    console.log(this.$route);
    const fromSche = this.$route.params.key;
    await Promise.all([
      this.getRFM(),
      this.getRFMcustom(),
      this.getcustomlabelMarketingAllGet(),
      this.getUploadLabelList(),
    ]);
    if (fromSche === undefined) {
      this.template_key = sessionStorage.getItem("template_key");
      // this.selectedID = sessionStorage.getItem("getlabelId");
      // this.group = sessionStorage.getItem("getlabelTitle");
      this.getTemplateById();
    } else {
      this.random_key = "key_for_Cbox" + new Date().getTime();

      this.template_key = this.$route.params.key;
      this.schedule_id = this.$route.params.schedule_name;
      this.schedule_name = this.$route.params.script_name;
      this.script_id = this.$route.params.script_id;
      this.selectedID = this.$route.params.label_id;
      this.selectedName = JSON.parse(this.$route.params.label_name);
      this.realselectSms = this.$route.params.sender;
      if (this.$route.params.email_sender.includes('_')) {
        this.selectEmail = this.$route.params.email_sender.split('_')[1]
        this.sender_name = this.$route.params.email_sender.split('_')[0]
      } else {
        this.selectEmail = this.$route.params.email_sender
        this.sender_name = ''
      }
      this.has_event = this.$route.params.has_event;

      const index = Object.values(this.smsList).findIndex(
        (d) => d === this.realselectSms
      );
      this.selectSms = Object.keys(this.smsList)[index];

      const event = this.events.filter((d) => {
        return d.id === this.schedule_name;
      });
      console.log(this.selectSms);
      if (event[0] != undefined) {
        this.calendar_event = event[0].title;
      }
      this.day = new Date(this.$route.params.ScheduleExpression);
      const hour = new Date(this.$route.params.ScheduleExpression).getHours();
      const min = new Date(this.$route.params.ScheduleExpression).getMinutes();
      this.time = moment(new Date().toISOString().slice(0, 10))
        .add(hour, "h")
        .add(min, "m")._d;

      await this.getInfoByCombineLabelID(this.selectedID);

      this.checkCombineLabelMemberCount();
    }
    this.urlloading = false;

    console.log("this.selectedName", this.selectedName);
    // for (let index = 0; index < this.selectedID.length; index++) {
    //   this.selectedLabels.push({
    //     label_id: this.selectedID[index],
    //     label: this.selectedName[index],
    //   });
    // }
  },

  methods: {
    // 拿寄件人Email
    getEmailList() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      allapi2
        .userEmaillistGet(params)
        .then((res) => {
          console.log("寄件人Email的可選Email", res);
          this.emailList = res.data.maillist;
          this.selectEmail = this.emailList[0];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿所有自訂分群labels
    getUploadLabelList: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .uploadLabelGet(params)
        .then((res) => {
          console.log("uploadLabelList", res);

          res.data.map((d) => {
            d.create_time = d.time;
            d.time = new Date(d.time).toLocaleString();
            d.label_type = "自訂分群";
          });
          this.originUploadLabels = res.data.filter(d=>!d.status.includes('處理中')||!d.status.includes('上傳失敗_'));
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    closeModal() {
      this.smsEditor.hide();
    },

    getInfoByCombineLabelID: async function (id) {
      console.log("this.group", this.selectedName);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        label_id: id,
      };
      await allapi2
        .customlabelMarketingLabelIdGet(params)
        .then((res) => {
          console.log("根據合成後的label_id拿包含不包含", res);
          const includeArr = res.data.message.Data.content.include;
          if (includeArr[0] != undefined) {
            for (let index = 0; index < includeArr.length; index++) {
              const include_label = {};
              include_label.label_id = includeArr[index];
              include_label.label = this.selectedName.includeLabelNames[index];
              this.includeLabels.push(include_label);
              this.usedID.push(includeArr[index]);
            }
          }
          const excludeArr = res.data.message.Data.content.exclude;
          if (excludeArr[0] != undefined) {
            for (let index = 0; index < excludeArr.length; index++) {
              const exclude_label = {};
              exclude_label.label_id = excludeArr[index];
              exclude_label.label = this.selectedName.excludeLabelNames[index];
              this.excludeLabels.push(exclude_label);
              this.usedID.push(excludeArr[index]);
            }
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    checkCombineLabelMemberCount: async function () {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      console.log(include_ids, exclude_ids);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
        type: "sms",
      };

      await allapi2
        .customlabelMarketingRatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的人數", res);
          this.percentBars = res.data.message.Data.ratio;
          this.numbers = res.data.message.Data.count;
          this.members = res.data.message.Data.total;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getCombineLabelID: async function (date, isPatch) {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
      };

      await allapi2
        .customlabelMarketingCreatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的label_id", res);
          this.combineLabelID = res.data.message.Data.label_id;
          if (isPatch) {
            this.patch(date);
          } else {
            this.post(date);
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("combinID有誤");
        });
    },
    callalert() {
      alert("功能暫未開放");
    },
    opentestMemberModal() {
      this.testMemberModal = new bootstrap.Modal(
        document.getElementById("testMemberModal"),
        {
          keyboard: false,
        }
      );
      this.testMemberModal.show();
    },
    saveTestMember() {
      console.log(this.emailtext);
      function isValidEmail(string) {
        var res = string.match(
          /\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+/g
        );
        return res;
      }
      function isValidPhone(string) {
        var res = string.match(/09[0-9]{8}/g);
        return res;
      }
      let emailok = false;
      let phoneok = false;

      this.emailArr = isValidEmail(this.emailtext);
      if (this.emailArr === null) {
        emailok = true;
      } else if (this.emailArr[0] != undefined) {
        const newemailtext = this.emailtext
          .replace(this.emailArr[0], "")
          .replace(this.emailArr[1], "")
          .replace(this.emailArr[2], "")
          .replace(this.emailArr[3], "")
          .replace(this.emailArr[4], "");
        if (newemailtext.match(/./) != null) {
          alert("信箱格式有誤");
        } else if (this.emailArr.length > 5) {
          alert("最多只能放5個測試信箱");
        } else {
          emailok = true;
        }
      } else {
        alert("連線有誤");
      }
      this.phoneArr = isValidPhone(this.phonetext);
      if (this.phoneArr === null) {
        alert("電話不能為空");
      } else if (this.phoneArr[0] != undefined) {
        const newphonetext = this.phonetext
          .replace(this.phoneArr[0], "")
          .replace(this.phoneArr[1], "")
          .replace(this.phoneArr[2], "")
          .replace(this.phoneArr[3], "")
          .replace(this.phoneArr[4], "");
        if (newphonetext.match(/./) != null) {
          alert("電話格式有誤");
        } else if (this.phoneArr.length > 5) {
          alert("最多只能放5個測試電話");
        } else {
          phoneok = true;
        }
      } else {
        alert("連線有誤");
      }
      if (emailok && phoneok) {
        this.postTestMember();
      }
    },
    postTestMember() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        email: this.emailArr,
        phone: this.phoneArr,
      };

      allapi2
        .testLabelPost(params, body)
        .then((res) => {
          console.log(res);
          const testMember = {};
          testMember.label_id = res.data.Data;
          testMember.label = "測試人員";

          this.includeLabels.push(testMember);
          this.usedID.push("123456798");

          this.testMemberModal.hide();
          this.checkCombineLabelMemberCount();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getTestMember() {
      this.opentestMemberModal();
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        email: this.emailArr,
        phone: this.phoneArr,
      };

      allapi2
        .testLabelGet(params, body)
        .then((res) => {
          console.log(res);
          const reg = /,/g;
          this.emailtext = res.data.Data.email.toString().replace(reg, "\n");
          this.phonetext = res.data.Data.phone.toString().replace(reg, "\n");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    test() {
      this.time = moment(new Date()).add(1, "h")._d;
      console.log("this.time", this.time);
    },
    onPage(event) {
      this.page = event.page + 1;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log("可用活動", res);
          res.data.Data.reverse();
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
          this.searchEvents = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    selectCalendar_event(e) {
      this.schedule_name = e.id;
      this.calendar_event = e.title;
      this.autoShow = false;
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    dayChange() {
      this.editinput = true;
      console.log(this.day);
    },
    timeChange() {
      this.editinput = true;
    },
    addLabelsToIncludes(todo) {
      if (this.selectGroup === "測試人員") {
        this.getTestMember();
      } else {
        const ids = this.includeLabels.map((d) => {
          return d.labelId || d.label_id;
        });
        const hasid = ids.findIndex((d) => {
          return d === todo.label_id || d === todo.labelId;
        });

        console.log(ids);
        console.log(hasid);
        if (hasid != -1) {
          return;
        }
        this.includeLabels.push(todo);
        if (todo.label_id != undefined) {
          this.usedID.push(todo.label_id);
        } else if (todo.labelId != undefined) {
          this.usedID.push(todo.labelId);
        }

        console.log("this.usedID", this.usedID);
        console.log(this.includeLabels);
        this.checkCombineLabelMemberCount();
      }
    },
    addLabelsToExcludes(todo) {
      const ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const hasid = ids.findIndex((d) => {
        return d === todo.label_id || d === todo.labelId;
      });

      console.log(ids);
      console.log(hasid);
      if (hasid != -1) {
        return;
      }
      this.excludeLabels.push(todo);
      if (todo.label_id != undefined) {
        this.usedID.push(todo.label_id);
      } else if (todo.labelId != undefined) {
        this.usedID.push(todo.labelId);
      }

      console.log(this.excludeLabels);
      this.checkCombineLabelMemberCount();
    },
    deleteLabelsFromIncludes(todo) {
      console.log(todo);
      if (todo.label === "測試人員") {
        this.usedID = this.usedID.filter((d) => {
          return d != "123456798";
        });
      }
      this.includeLabels = this.includeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      this.checkCombineLabelMemberCount();
    },

    deleteLabelsFromExcludes(todo) {
      console.log(todo);
      if (todo.label === "測試人員") {
        this.usedID = this.usedID.filter((d) => {
          return d != "123456798";
        });
      }
      this.excludeLabels = this.excludeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      this.checkCombineLabelMemberCount();
    },
    getSmstodosApi: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .smsTemplateSmsScheduleInfoGet(params)
        .then((res) => {
          console.log(res);
          this.smstodos = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getTemplateById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.template_key,
      };

      await allapi2
        .smsTemplateSmsTempKeyGet(params)
        .then((res) => {
          console.log("拿A箱子", res);
          this.todotext = res.data.data.text;
          this.newText = res.data.data.newText;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料缺失，將返回排程");
          this.$router.push({
            name: "SmsSchedule",
          });
          console.log(err);
        });
    },
    getRFM: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelAilabelGet(params)
        .then((res) => {
          console.log(res);
          this.RFMtodos = res.data.message.Data;
          this.todos = this.RFMtodos;
          // this.searchList = this.todos;

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getRFMcustom: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelListGet(params)
        .then((res) => {
          console.log(res);
          this.customtodos = res.data.message.Data;
          this.customtodos.sort((a, b) => {
            return b.time - a.time;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getcustomlabelMarketingAllGet: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .customlabelMarketingAllGet(params)
        .then((res) => {
          console.log("ALLresres", res);
          this.ALLtodos = res.data.message.Data;
          // this.newtodos = this.ALLtodos;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getSmsList: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .userSmslistGet(params)
        .then((res) => {
          console.log(res);
          this.smsList = res.data.smslist;
          console.log(Object.keys(this.smsList)[0]);
          this.selectSms = Object.keys(this.smsList)[0];
          this.realselectSms = Object.values(this.smsList)[0];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    // 開啟編輯模板
    updateSmsTemp: async function () {
      if (this.$route.params.key === undefined) {
        return;
      } else {
        this.smsEditor = new bootstrap.Modal(
          document.getElementById("smsEditor"),
          {
            keyboard: false,
          }
        );
        this.smsEditor.show();
        this.refreshEditor = this.refreshEditor + 1;
      }
    },
    // 顯示模擬SMS
    expshow: async function () {
      if(this.$route.params.key==undefined){
        console.log('從A箱子預覽')
      }else{
        await this.getCboxById();
        if (this.noCbox) {
          // 如果沒改過C箱子 則從B箱子預覽
          await this.getBboxById();
        } else {
          // 如果改過C箱子 則拿C箱子預覽
          console.log("接續使用C箱子預覽");
        }
      }
      await this.findAllURl();
      this.countText = this.newText
        .replace(/{id}/g, "uid000")
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      console.log("this.countText", this.countText);
      const exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    // 獲得所有正常網址 並將所有正常網址 轉成短網址
    findAllURl: async function () {
      this.expSubsec = this.todotext;
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.todotext);
      console.log(this.fullurlArr);
      if (this.fullurlArr != null) {
        await this.getAllShortHttp();
      } else {
        this.textError = false;
        this.urlok = true;
      }
    },
    // 根據所有正常網址 for循環呼叫轉短網址
    getAllShortHttp: async function () {
      this.urlok = true;
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrl(this.fullurlArr[index]);
      }
    },
    // 呼叫轉短網址的api 並產生this.newText 用來放轉好短網址的text 和 this.expSubsec用來顯示模擬SMS(加了a標籤)
    getShortUrl: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        template_id: this.template_key,
      };
      await allapi2
        .smsScheduleShortenUrlPost(params, body)
        .then((res) => {
          console.log("GETUrl", res);

          this.newText = this.newText.replace(
            fullurl,
            res.data.url + "?i={id}"
          );
          this.expSubsec = this.expSubsec
            .replace(
              fullurl,
              "<a href='" +
                res.data.url +
                // "?i=" +
                // 'uid000' +
                "' target='_blank'>" +
                res.data.url +
                "?i=" +
                "uid000" +
                "</a>"
            )
            .replace(/{lastname}/g, "王")
            .replace(/{firstname}/g, "◯◯")
            .replace(/{name}/g, "王◯◯");

          console.log("this.newText", this.newText);
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.urlok = false;
          this.textError = true;
          this.textErrMsg = "短網址生成失敗。";
          this.loading = false;
          console.log(this.shortUrlArr);
        });
    },

    // 拿Bbox的api
    getBboxById: async function () {
      console.log(this.templatekey);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.template_key,
      };

      await allapi2
        .smsTemplateSmsTransitKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.template_key拿B箱子  現在的this.template_key是${this.template_key}`,
            res
          );
          this.Btext = res.data.temp.newText;
          this.Btext = res.data.temp.text;
          this.todotext = res.data.temp.text;
          this.newText = res.data.temp.newText;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿Cbox的api
    getCboxById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.random_key,
      };

      await allapi2
        .smsTemplateSmsCacheKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.random_key拿C箱子  現在的this.random_key是${this.random_key}`,
            res
          );
          if (res.data == "not found") {
            this.noCbox = true;
          } else {
            this.noCbox = false;
            this.Ctext = res.data.temp.newText;
            this.Ctext = res.data.temp.text;
            this.todotext = res.data.temp.text;
            this.newText = res.data.temp.newText;
            this.template_name = res.data.name;
          }
        })
        .catch((err) => {
          this.noCbox = true;
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 上傳B箱子 this.todotext是最原始的用戶輸入的簡訊內容 this.newText是轉好短網址的簡訊內容
    postBbox: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.template_key,
        name: this.template_name,
        temp: { text: this.todotext, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTransitInfoPost(params, body)
        .then((res) => {
          console.log("上傳B箱子", res);
        })
        .catch((err) => {
          this.goSet = false;
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    // 更新B箱子
    patchBbox: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.template_key,
        name: this.template_name,
        temp: { text: this.todotext, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTransitInfoPatch(params, body)
        .then((res) => {
          console.log("更新B箱子", res);
        })
        .catch((err) => {
          this.goSet = false;
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    // 上傳排程用模板 D箱子
    postScheSmsTemp: async function (date) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.schedule_name,
        key: this.template_key,
        schedule: this.newText,
      };

      await allapi2
        .smsTemplateSmsScheduleInfoPost(params, body)
        .then(async (res) => {
          const isPatch = false;
          console.log("上傳D箱子", res);
          this.getCombineLabelID(date, isPatch);

          await this.delete();
          await this.createNew();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    // 更新排程用模板 D箱子
    patchScheSmsTemp: async function (date) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.schedule_name,
        key: this.template_key,
        schedule: this.newText,
      };

      await allapi2
        .smsTemplateSmsScheduleInfoPatch(params, body)
        .then(async (res) => {
          const isPatch = true;
          console.log("更新D箱子", res);
          this.getCombineLabelID(date, isPatch);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    // 上傳排程
    post: async function (date) {
      const include_names = this.includeLabels.map((d) => {
        return d.label;
      });
      const exclude_names = this.excludeLabels.map((d) => {
        return d.label;
      });
      console.log("run1");
      let sender_email =''
       if (this.sender_name=='') {
        sender_email = this.selectEmail
      } else {
        sender_email =`${this.sender_name}_${this.selectEmail}`
      }

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        label_id: this.combineLabelID,
        label_name: {
          includeLabelNames: include_names,
          excludeLabelNames: exclude_names,
        },
        sender: this.realselectSms,
        email_sender: sender_email,
        expression_time: date,
        template_name: this.template_name,
        script_name: this.schedule_name,
        template_id: this.template_key,
      };

      await allapi2
        .smsScheduleCreatePost(params, body)
        .then((res) => {
          console.log("上傳新的預約排程了", res);
          this.loading = false;

          const alertModalroutergo = new bootstrap.Modal(
            document.getElementById("alertModalroutergo"),
            {
              keyboard: false,
            }
          );
          alertModalroutergo.show();
          setTimeout(() => {
            alertModalroutergo.hide();
            this.routergoScript();
          }, 2000);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    // 修改排程
    patch: async function (date) {
      const include_names = this.includeLabels.map((d) => {
        return d.label;
      });
      const exclude_names = this.excludeLabels.map((d) => {
        return d.label;
      });

      console.log("run1");
      let sender_email =''
       if (this.sender_name=='') {
        sender_email = this.selectEmail
      } else {
        sender_email =`${this.sender_name}_${this.selectEmail}`
      }

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        scheduleName: this.schedule_id,
      };
      const body = {
        label_id: this.combineLabelID,
        label_name: {
          includeLabelNames: include_names,
          excludeLabelNames: exclude_names,
        },
        expression_time: date,
        sender: this.realselectSms,
        email_sender:sender_email,
        template_name: this.template_name,
        script_name: this.schedule_name,
        script_id: this.script_id,
        template_id: this.template_key,
      };

      await allapi2
        .smsScheduleScheduleNamePatch(params, body)
        .then(async (res) => {
          console.log("更新的預約排程了", res);
          this.loading = false;
          await this.patchRunScriptApi();

          const alertModalroutergo = new bootstrap.Modal(
            document.getElementById("alertModalroutergo"),
            {
              keyboard: false,
            }
          );
          alertModalroutergo.show();
          setTimeout(() => {
            alertModalroutergo.hide();
            this.routergoScript();
          }, 2000);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("post連線有誤" + err);
          this.loading = false;
        });
    },

    // 確認預約 存B D箱子 並上傳預約
    successBookSchedule: async function () {
      if (this.schedule_name === "") {
        alert("活動名稱不能是空的");
        console.log(this.titleError);
      } else if (this.includeLabels[0] === undefined) {
        alert("客群不能是空的");
      } else if (this.realselectSms === "") {
        alert("寄件人不能是空的");
      } else if (this.day === null) {
        alert("發送時間不能為空");
      } else if (this.urlok != true) {
        alert("有錯誤網址");
      } else {
        this.loading = true;
        var moment = require("moment");
        const day = moment(this.day)
          .add(+8, "h")
          .toISOString()
          .substr(0, 10);
        const time = this.time.toString().substr(16, 5);

        var UTC = moment(day + " " + time).add(-8, "h");
        console.log(UTC);

        const date =
          "cron(" +
          UTC.format("mm") +
          " " +
          UTC.format("HH") +
          " " +
          UTC.format("D") +
          " " +
          UTC.format("M") +
          " " +
          "?" +
          " " +
          UTC.format("Y") +
          ")";

        console.log("POST DATE", date);
        // await this.MigrateAll();
        if (
          this.time < moment(new Date()).add(1, "m") &&
          this.day.toDateString() === new Date().toDateString()
        ) {
          alert("請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)");
          this.timeAlertShow = true;
        this.loading=false
          return;
        } else {
          this.timeAlertShow = false;
        }
        await this.postBbox();
        await this.postScheSmsTemp(date);
      }
    },
    // 修改預約 如果有改過C箱子 取C箱子給B箱子 patch B箱子 沒有則直接patchB箱子 並用B箱子的內容 patch D箱子
    successPatchSchedule: async function () {
      this.loading = true;

      if (this.schedule_name === "") {
        alert("活動名稱不能是空的");
      } else if (this.includeLabels[0] === undefined) {
        alert("發送客群不能是空的");
      } else if (this.realselectSms === "") {
        alert("寄件人不能是空的");
      } else if (this.day === null) {
        alert("發送時間不能為空");
      } else if (this.urlok != true) {
        alert("有錯誤網址");
      } else {
        var moment = require("moment");
        const day = moment(this.day)
          .add(+8, "h")
          .toISOString()
          .substr(0, 10);
        const time = this.time.toString().substr(16, 5);

        var UTC = moment(day + " " + time).add(-8, "h");
        console.log(UTC);

        const date =
          "cron(" +
          UTC.format("mm") +
          " " +
          UTC.format("HH") +
          " " +
          UTC.format("D") +
          " " +
          UTC.format("M") +
          " " +
          "?" +
          " " +
          UTC.format("Y") +
          ")";

        console.log("POST DATE", date);
        if (
          this.time < moment(new Date()).add(1, "m") &&
          this.day.toDateString() === new Date().toDateString()
        ) {
          alert("請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)");
          this.timeAlertShow = true;
        this.loading=false
          return;
        } else {
          this.timeAlertShow = false;
        }
        await this.getCboxById();
        if (this.noCbox) {
          // 如果沒改過C箱子 則不用重改B箱子 直接patch排程
          console.log("如果沒改過C箱子 則不用重改B箱子 直接patch排程");
          const isPatch = true;
          this.getCombineLabelID(date, isPatch);
        } else {
          // 如果改過C箱子 則把B箱子的內容改成跟C箱子的內容一樣 D箱子也是
          console.log("把B箱子的內容改成跟C箱子的內容一樣");
          await this.patchBbox();
          await this.patchScheSmsTemp(date);
        }
      }
    },
    // 修改預約後 必須設定劇本狀態 讓他跟更新新的劇本狀態一致 避免劇本狀態回到0
    patchRunScriptApi: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: this.script_id,
      };
      const body = {
        state: this.has_event,
      };

      await allapi2
        .smsScheduleUpdateHasEventIdPost(params, body)
        .then((res) => {
          console.log("成功更改劇本狀態為" + this.has_event, res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("patch連線有誤" + err);
        });
    },

    // 打開 要添加新的活動 的彈出視窗
    wantaddCalen() {
      this.changedateModal = new bootstrap.Modal(
        document.getElementById("changedateModal"),
        {
          keyboard: false,
        }
      );
      this.changedateModal.show();
    },
    // 確定添加新的活動
    addCalen: async function () {
      if (this.select_calen_title === "") {
        alert("必須輸入活動名稱");
        return;
      } else if (this.calenday === "" || this.calentime === "") {
        alert("必須輸入活動時間");
        return;
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        activity_id: Date.now(),
        name: this.renameEvent,
        start: this.calenday,
        end: this.calentime,
      };

      await allapi2
        .activityPost(params, body)
        .then(async (res) => {
          console.log(res);
          await this.getCalenEvents();
          const newEvent = this.events.find(
            (d) => d.title === this.renameEvent
          );
          this.selectCalendar_event(newEvent);
          this.renameEvent = "";
          this.calenday = "";
          this.calentime = "";
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    createNew: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        name: this.template_name,
        temp: { text: this.todotext, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTempInfoPost(params, body)
        .then((res) => {
          console.log("建立新的A箱子", res);
          // const alertModal4 = new bootstrap.Modal(
          //   document.getElementById("alertModal4"),
          //   {
          //     keyboard: false,
          //   }
          // );
          // alertModal4.show();
          // $("#alertModal4").modal("show");
          // this.random_id = "T_" + this.randomString(8);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    delete: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.template_key,
      };

      await allapi2
        .smsTemplateSmsTempInfoDelete(params, body)
        .then((res) => {
          console.log("刪除舊的A箱子", res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("delete連線有誤" + err);
          this.loading = false;
        });
    },
    routergoScript() {
      this.$router.push({
        name: "SmsSchedule",
      });
    },
  },
};
</script>

<style scoped>
.home {
  padding: 50px;
}

.contain-top {
  padding: 20px 40px 25px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  height: 198px;
}

.form-container2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}

.form-control,
.form-control2 {
  border: none;
  background-color: #eaf3f4;
  overflow: none;
}

.input-style {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
}

.input-style-2 {
  width: 50%;
  height: 40px;

  border-radius: 10px;
  background-color: #fff;
}

.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}

.basic-btn {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}

.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 30px;
  width: 18rem;
  height: 37px;
}

.search-container {
  position: relative;
}

.searchImg {
  position: absolute;
  /* width: 3%; */
  height: 20px;
  top: 10px;
  left: 8px;
}

.button-group-div {
  background-color: #eaf3f4;
  width: 200px;
  border-radius: 10px;
}

.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}

.button-color2 {
  width: 100px;
  background-color: #eaf3f4;
  border-radius: 10px;
}

.B-text-input-container {
  width: 94%;
}

.B-text-input {
  background-color: #eaf3f4;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 37px;
}

.B-text-input:focus {
  border: none;
}

.modal-btn-close-cancel {
  width: 10vw;
}

input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: left;
}

.ab-select {
  width: 14vw;
  height: 40px;
  border-radius: 10px;
}

.auto-complicate-div-setting {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 98%;
  background-color: white;
  z-index: 99;
}

input::-webkit-input-placeholder {
  text-align: left;
}
.popout-hover:hover {
  background-color: #eaf3f4;
}
.popout-hover:hover:first-child {
  background-color: #eaf3f4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popout-hover:hover:last-child {
  background-color: #eaf3f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.disable {
  background-color: #eeeeee;
  color: #c4c4c4;
}
:deep(.vc-time-date) {
  display: none !important;
}
</style>
