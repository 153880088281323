<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium">整體顧客行為</span>
      </div>

      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              is-expanded
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-4" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div class="col-3 d-flex" style="flex-wrap: wrap">
          <div class="col-12 mb-4">
            <div class="number-div-valueGroup">
              <p class="fs-small" style="color: #71afb6">整體會員總數</p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p>{{ behaviorAll.會員總數 }}</p>
                <p>(人)</p>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="number-div-valueGroup">
              <p class="fs-small" style="color: #71afb6">新註冊會員</p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p>{{ behaviorAll.新註冊會員 }}</p>
                <p>(人)</p>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="number-div-valueGroup">
              <p class="fs-small" style="color: #71afb6; height: 27px">
                整體回購率
                <BtnInfoBox content="根據所選時段內，有購買(線上線下)的會員且曾經有消費過兩次及以上的比例。" direction="right"></BtnInfoBox>

              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p>{{ Number(behaviorAll2.回購率).toFixed(2) }}</p>
                <p>(%)</p>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="number-div-valueGroup">
              <p class="fs-small" style="color: #71afb6; height: 27px">
                平均回購間隔
                <BtnInfoBox content="根據所選時段內，有購買(線上線下)的會員，距離上次購買間隔的總平均。" direction="right"></BtnInfoBox>

              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p>{{ Number(comebackBuy).toFixed(2) }}</p>
                <p>(天)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9 d-flex flex-wrap justify-content-end">
          <div style="width: 61%">
            <div class="line-div" style="position: relative">
              <span class="fs-small" style="color: #71afb6"
                >線上/線下新註冊會員趨勢圖</span
              >
              <span
                style="position: absolute; top: 50%; left: 40%; color: #c4c4c4"
                v-if="this.series3[0] === undefined"
                >此時間段無資料</span
              >
              <apexchart
                type="line"
                height="260"
                :options="chartOptions3"
                :series="series3"
              ></apexchart>
            </div>
          </div>
          <div class="col-4 ms-4">
            <div class="pie-div66" style="position: relative">
              <span class="fs-small" style="color: #71afb6"
                >線上/線下會員用戶佔比</span
              >
              <span
                class="text-center"
                style="position: absolute; top: 30%; left: 13%; color: #c4c4c4"
                v-if="this.series2[0] === undefined"
              >
                <img src="../assets/missData.svg" alt="" style="width: 200px" />
                <br />
                此時間段無資料</span
              >
              <apexchart
                type="donut"
                height="290"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </div>
          </div>
          <div style="width: 97%">
            <div class="line-div" style="position: relative">
              <span class="fs-small" style="color: #71afb6"
                >線上/線下會員消費趨勢圖</span
              >
              <span
                style="position: absolute; top: 45%; left: 40%; color: #c4c4c4"
                v-if="this.series4[0] === undefined"
                >此時間段無資料</span
              >
              <apexchart
                type="line"
                height="260"
                :options="chartOptions4"
                :series="series4"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "ValueGrouping",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    BtnInfoBox,
  },
  data() {
    return {
      loading: false,
      memberShow: false,
      memberShow2: false,
      comebackBuy: "",
      behaviorAll: "",
      behaviorAll2: "",
      vue3Date: {
        start: "",
        end: "",
        //  start: new Date("2021-12-10").toISOString().slice(0,10),
        // end: new Date("2021-12-20").toISOString().slice(0,10),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      startDate: "2021-12-05",
      endDate: "2022-01-10",
      hasDataFirst: "",
      hasDataEnd: "",

      endYear: 2022,
      endMonth: 6,

      dateSelector: "全部",

      series2: [],
      chartOptions2: {
        chart: {
          height: 290,
          type: "donut",
        },
        colors: ["#63A7C8", "#F9C357"],
        labels: [],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      // line
      series3: [
        {
          name: "線上",
          data: [],
        },
        {
          name: "線下",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          height: 260,
          type: "line",
          dropShadow: {
            enabled: false,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        colors: ["#71AFB6", "#F9C357"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "straight",
        },
        title: {
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "medium",
            color: "#71AFB6",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            // text:"日期"
          },
        },
        yaxis: {
          title: {
            text: "人數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      series4: [],
      chartOptions4: {
        chart: {
          height: 260,
          type: "line",
          dropShadow: {
            enabled: false,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        colors: ["#71AFB6", "#F9C357"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "straight",
        },
        title: {
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "medium",
            color: "#71AFB6",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            // text:"日期"
          },
        },
        yaxis: {
          title: {
            text: "消費次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
      },
    };
  },

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();
    this.highlightVue3Date();
    this.loading = false;

    this.data2 = "改改";
  },
  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },

    show() {
      // a=!a
      // console.log("a",a)
      this.memberShow = !this.memberShow;
      // this.ABShow = !this.ABShow
    },
    show2() {
      // a=!a
      // console.log("a",a)
      this.memberShow2 = !this.memberShow2;
      // this.ABShow = !this.ABShow
    },

    getoverallall_time: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallAllTimeGet(params)
        .then((res) => {
          console.log("回購間隔", res);
          this.comebackBuy = res.data.Data.整體;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorAll: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallRateGet(params)
        .then((res) => {
          console.log("整體.會員總數", res);
          res.data.Data.整體.會員總數 =
            res.data.Data.整體.會員總數.toLocaleString();
          this.behaviorAll = res.data.Data.整體;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorAll2: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallRate2Get(params)
        .then((res) => {
          console.log("回購率", res);
          this.behaviorAll2 = res.data.Data.整體;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorAllSellTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallSaleTrendGet(params)
        .then((res) => {
          console.log("SellTrend", res);
          if (res.data.Data.線上 === undefined) {
            this.series4 = [];
            this.chartOptions4 = {
              xaxis: {
                categories: [],
                tickAmount: 6,
              },
            };
          } else {
            this.series4 = [
              {
                name: "線上",
                data: res.data.Data.線上.sum,
              },
              {
                name: "線下",
                data: res.data.Data.線下.sum,
              },
            ];
            this.chartOptions4 = {
              xaxis: {
                categories: res.data.Data.線上.date,
                tickAmount: 6,
              },
            };
          }

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorAllPie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallPieGet(params)
        .then((res) => {
          console.log("pie data", res);
          console.log(res.data.Data.整體.用戶數);
          this.series2 = res.data.Data.整體.用戶數.reverse();
          this.chartOptions2 = {
            labels: res.data.Data.整體.標題.reverse(),
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorLinechart: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallCompareGet(params)
        .then((res) => {
          console.log("註冊趨勢 data", res);
          if (res.data.Data.線上.會員數 === undefined) {
            this.series3 = [];
          } else {
            this.series3 = [
              {
                name: "線上",
                data: res.data.Data.線上.會員數,
              },
              {
                name: "線下",
                data: res.data.Data.線下.會員數,
              },
            ];
            this.chartOptions3 = {
              xaxis: {
                categories: res.data.Data.線上.日期,
                tickAmount: 6,
              },
            };
          }
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeMemberGet(params)
        .then((res) => {
          console.log('最大跟最小的時間',res);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.getoverallall_time(),
        this.getBehaviorAll(),
        this.getBehaviorAll2(),
        this.getBehaviorAllPie(),
        this.getBehaviorLinechart(),
        this.getBehaviorAllSellTrend(),
      ]);

      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "全部";
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近一年";
      await Promise.all([
        this.getoverallall_time(),
        this.getBehaviorAll(),
        this.getBehaviorAll2(),
        this.getBehaviorAllPie(),
        this.getBehaviorLinechart(),
        this.getBehaviorAllSellTrend(),
      ]);

      this.highlightVue3Date();
      this.loading = false;
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近30天";
      await Promise.all([
        this.getoverallall_time(),
        this.getBehaviorAll(),
        this.getBehaviorAll2(),
        this.getBehaviorAllPie(),
        this.getBehaviorLinechart(),
        this.getBehaviorAllSellTrend(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近7天";
      await Promise.all([
        this.getoverallall_time(),
        this.getBehaviorAll(),
        this.getBehaviorAll2(),
        this.getBehaviorAllPie(),
        this.getBehaviorLinechart(),
        this.getBehaviorAllSellTrend(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近1天";
      await Promise.all([
        this.getoverallall_time(),
        this.getBehaviorAll(),
        this.getBehaviorAll2(),
        this.getBehaviorAllPie(),
        this.getBehaviorLinechart(),
        this.getBehaviorAllSellTrend(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    customDatePicker: async function () {
      this.loading = true;
      this.startDate = this.vue3Date.start;
      this.endDate = this.vue3Date.end;
      this.dateSelector = "自訂";
      await Promise.all([
        this.getoverallall_time(),
        this.getBehaviorAll(),
        this.getBehaviorAll2(),
        this.getBehaviorAllPie(),
        this.getBehaviorLinechart(),
        this.getBehaviorAllSellTrend(),
      ]);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.home {
  padding: 50px;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.money-number {
  margin-top: 40px;
}

.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}

.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}

.number-div-valueGroup {
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 145px;
}

.pie-div66 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  height: 315px;
}

.line-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  height: 315px;
}

.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}

.change-date-li:hover {
  background-color: #eaf3f4;
}

.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
.child-popout-inValueGrouping-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -330px;
  text-align: left;
  top: -42px;
  width: 320px;
  z-index: 99;
}
.child-popout-inValueGrouping-second {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -335px;
  text-align: left;
  top: -42px;
  width: 320px;
  z-index: 99;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>
