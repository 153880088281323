<template>
    <div>
        <Teleport to="#teleport-modal">
            <div v-if="saveModal" class="modal-bg">
                <div class="modal-limit" ref="modal">
                    <div class="modal-header-limit">
                    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg" @click="saveModal = false">
                        <path d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z" fill="white"/>
                    </svg>

                    </div>
                    <div class="modal-body-limit d-flex flex-column justify-content-center" >
                       <p class="success-text">儲存成功</p>
                    <div class="mt-3 d-flex justify-content-center">
                       <button class=" dismiss-button" @click="saveModal = false">
                        確認
                       </button>
                       </div>
                    </div>
                    
                </div>

            </div>
        </Teleport>



        <div class="container home">




            <span class="title-span fs-medium">
                <img src="../assets/back-arrow.png" @click="$router.go(-1)" style="cursor: pointer" />
                &nbsp; &nbsp; 行銷溝通上限</span>


            <div class="tab-container-2 ">
                <div class="reset-set GrayC4 font-18" @click="onResetSetting()">
                    <span>還原預設</span>
                </div>

                <div class=" inner-padding">

                    <p class="label-font">Email</p>
                    <div class="d-flex align-items-center input-div">
                        <span class='col-3'>每日 &nbsp; <input type="number" v-model="daysEmail" class="input" /> &nbsp;
                            次</span>
                        <span class='col-3'>每週 &nbsp; <input type="number" v-model="weekEmail" class="input" /> &nbsp;
                            次</span>
                        <span class='col-3'>每月 &nbsp; <input type="number" v-model="monthEmail" class="input" /> &nbsp;
                            次</span>
                        <span class='col-3'>每年 &nbsp; <input type="number" v-model="yearEmail" class="input" /> &nbsp;
                            次</span>
                    </div>
                </div>
                <hr>
                <div class=" inner-padding">

                    <p class="label-font">SMS</p>
                    <div class="d-flex align-items-center input-div">
                        <span class='col-3'>每日 &nbsp; <input type="number" v-model="daysSMS" class="input" /> &nbsp;
                            次</span>
                        <span class='col-3'>每週 &nbsp; <input type="number" v-model="weekSMS" class="input" /> &nbsp;
                            次</span>
                        <span class='col-3'>每月 &nbsp; <input type="number" v-model="monthSMS" class="input" /> &nbsp;
                            次</span>
                        <span class='col-3'>每年 &nbsp; <input type="number" v-model="yearSMS" class="input" /> &nbsp;
                            次</span>
                    </div>
                </div>

            </div>
            <div class="container-fluid  d-flex justify-content-center  pe-4 mt-5">
                <button class="btn btn-lg btn-download2" @click="postQuota()">
                    儲存
                </button>
                <!-- <button class="btn btn-lg btn-download2" @click="saveModal = true">
                    modal
                </button> -->
            </div>
        </div>
    </div>

</template>



<script>
import { ref, watch, onBeforeMount } from "vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { onClickOutside } from "@vueuse/core";

export default {

    setup() {

        const saveModal = ref(false)
        const modal = ref(null)

        const daysEmail = ref(null)
        const weekEmail = ref(null)
        const monthEmail = ref(null)
        const yearEmail = ref(null)

        const daysSMS = ref(null)
        const weekSMS = ref(null)
        const monthSMS = ref(null)
        const yearSMS = ref(null)

        onClickOutside(modal, () => {
            saveModal.value = false
        })

        watch(daysEmail, () => {
            changeMinMax(daysEmail)
        })
        watch(weekEmail, () => {
            changeMinMax(weekEmail)
        })
        watch(monthEmail, () => {
            changeMinMax(monthEmail)
        })
        watch(yearEmail, () => {
            changeMinMax(yearEmail)
        })
        watch(daysSMS, () => {
            changeMinMax(daysSMS)
        })
        watch(weekSMS, () => {
            changeMinMax(weekSMS)
        })
        watch(monthSMS, () => {
            changeMinMax(monthSMS)
        })
        watch(yearSMS, () => {
            changeMinMax(yearSMS)
        })

        // watch([daysEmail, weekEmail, monthEmail, yearEmail], () => {
        //     if (daysEmail.value > weekEmail.value) {
        //         // daysEmail.value = null
        //         alert('不能大於每週次數')
        //     } else if (weekEmail.value > monthEmail.value) {
        //         // weekEmail.value = null
        //         alert('不能大於每月次數')
        //     } else if (monthEmail.value > yearEmail.value) {
        //         // monthEmail.value = null
        //         alert('不能大於每年次數')

        //     }
        // })
        // watch([daysSMS, weekSMS, monthSMS, yearSMS], () => {
        //     if (daysSMS.value > weekSMS.value) {
        //         // daysSMS.value = null
        //         alert('不能大於每週次數')
        //     } else if (weekSMS.value > monthSMS.value) {
        //         // weekSMS.value = null
        //         alert('不能大於每月次數')
        //     } else if (monthSMS.value > yearSMS.value) {
        //         // monthSMS.value = null
        //         alert('不能大於每年次數')

        //     }
        // })

        const getQuota = async () => {
            const token = sessionStorage.getItem("access_token");

            const params = {
                Authorization: "Bearer " + token,
            };
            await allapi2
                .memberStatusQuotaGet(params)
                .then((res) => {
                    console.log(res)
                    daysEmail.value = res.data.Data.Email.daily
                    weekEmail.value = res.data.Data.Email.weekly
                    monthEmail.value = res.data.Data.Email.monthly
                    yearEmail.value = res.data.Data.Email.yearly
                    daysSMS.value = res.data.Data.SMS.daily
                    weekSMS.value = res.data.Data.SMS.weekly
                    monthSMS.value = res.data.Data.SMS.monthly
                    yearSMS.value = res.data.Data.SMS.yearly

                })
                .catch((err) => {
                    console.log("連線有誤");
                    console.log(err);
                    // this.loading = false;
                });
        }

        const postQuota = async () => {
            const token = sessionStorage.getItem("access_token");

            const body = {
                Email: {
                    daily: daysEmail.value,
                    weekly: weekEmail.value,
                    monthly: monthEmail.value,
                    yearly: yearEmail.value
                },
                SMS: {
                    daily: daysSMS.value,
                    weekly: weekSMS.value,
                    monthly: monthSMS.value,
                    yearly: yearSMS.value
                }
            }

            const params = {
                Authorization: "Bearer " + token,
            };
            await allapi2
                .memberStatusQuotaPost(params, body)
                .then((res) => {
                    console.log('postgood', res)
                    saveModal.value = true
                })

                .catch((err) => {
                    console.log("連線有誤");
                    console.log(err);
                    // this.loading = false;
                })

            await getQuota();
        }

        const onResetSetting = () => {
            daysEmail.value = 9999
            weekEmail.value = 9999
            monthEmail.value = 9999
            yearEmail.value = 9999
            daysSMS.value = 9999
            weekSMS.value = 9999
            monthSMS.value = 9999 
            yearSMS.value = 9999

        }

        const changeMinMax = (data) => {
            if (data.value < 0) {
                data.value = 0
            }
            if (data.value > 9999) {
                data.value = 9999
            }
        }




        onBeforeMount(async () => {
            await getQuota();
        })

        return {
            saveModal,
            modal,
            daysEmail,
            weekEmail,
            monthEmail,
            yearEmail,
            daysSMS,
            weekSMS,
            monthSMS,
            yearSMS,

            getQuota,
            postQuota,
            onResetSetting,
            changeMinMax,
        }
    }


}


</script>



<style scoped>
.input {
    background-color: #EAF3F4;
    border: none;
    border-radius: 10px;
    height: 35px;
    text-align: center;
    width: 55%;
}

.input:focus {
    outline: none;
}

.btn-download2 {
    background-image: linear-gradient(270deg, #71afb6, #b5dce0);
    border: none;
    color: #fff;
    font-size: 18px;
}

/* .modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

} */

.modal-header-limit {
    background: linear-gradient(90deg, #B5DBE0 0%, #71AEB5 100%);
    border-radius: 10px 10px 0px 0px;
    height: 5%;
     padding:5px 10px
}

.modal-body-limit {
    height: 90%;
    background-color: #fff;
    padding:20px;
     border-radius: 0px 0px 10px 10px;
}

.modal-limit {
    position: fixed;
    z-index: 999;
    top: 35%;
    left: 48%;
    width: 20%;
    /* margin-left: -150px; */
    border-radius: 20px;
    background-color: aliceblue;
}
 .dismiss-button{
    border-radius: 10px;
    padding: 10px 5px  ;
    color:#fff;
  
    width: 30%;
     border: none;
   background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
 } 
 .success-text{
    text-align: center;
    color: #575757;
    font-weight: 600;
 } 

 .reset-set {
    position: absolute;
    right: 5vw;
    box-shadow: 0 1px;
    line-height: 19px;
}

</style>