<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div v-else>
    <!-- downloading -->
    <div class="loading-background" v-show="downloading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div>下載中...</div>
    </div>
    <!-- DownloadBillModal  帳單明細彈出視窗 id:downloadBillModal -->
    <DownloadBillModal @downloadBill="downloadBill" />
    <!-- 其他彈出視窗 -->
    <Teleport to="#teleport-modal">
      <div v-if="saveModal" class="modal-bg">
        <div class="modal-limit" ref="modal">
          <div class="modal-header-limit">
            <svg
              width="9"
              height="12"
              viewBox="0 0 9 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="saveModal = false"
            >
              <path
                d="M8.20019 10.59L3.60872 6L8.2002 1.41L6.78666 -6.17875e-08L0.771625 6L6.78666 12L8.20019 10.59Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            class="modal-body-limit d-flex flex-column justify-content-center"
          >
            <p class="success-text">儲存成功</p>
            <div class="mt-3 d-flex justify-content-center">
              <button class="dismiss-button" @click="saveModal = false">
                確認
              </button>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-expanded
              is-range
              :model-config="modelConfig"
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <!-- :max-date="hasDataEnd" -->
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container home">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title-span fs-medium" style="padding-left: 2px">
          行銷投放紀錄
        </div>

        <div class="dropdown">
          <a
            class="
              btn
              dropdown-toggle
              datepicker
              d-flex
              justify-content-between
              align-items-center
            "
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="font-size: 14px"
          >
            <span class="d-flex align-items-center">
              <img
                class="me-2"
                src="../assets/black-calendar.svg"
                alt="calendar"
              />
              <span>{{ dateSelector }}</span>
            </span>
            <span v-if="startDate !== 'all'">
              {{ startDate }}~{{ endDate }}
            </span>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li class="change-date-li">
              <button class="change-date-button" @click="getPass1Days">
                最近1天
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getPass7Days">
                最近7天
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getRecentMonth">
                最近30天
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getRecentYear">
                最近一年
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getLastMonth">
                上個月
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getCurrentMonth">
                這個月
              </button>
            </li>
            <li class="change-date-li">
              <button
                type="button"
                class="change-date-button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                自訂
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getAll">全部</button>
            </li>
          </ul>
          <p
            class="ms-2 mb-5"
            style="
              position: absolute;
              right: 5px;
              color: #c4c4c4;
              font-size: 14px;
            "
          >
            {{ hasDataEnd }} 為最近一筆資料
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div class="col-3">
          <div class="number-div-member">
            <p class="number-label-member">Email投放總數</p>
            <div class="d-flex justify-content-between">
              <span class="number-amount-member">{{ emailTotal }} </span>
              <span class="number-amount-member">(次)</span>
            </div>
          </div>
          <div class="number-div-member">
            <p class="number-label-member">SMS投放總數</p>
            <div class="d-flex justify-content-between">
              <span class="number-amount-member">{{ smsTotal }} </span>
              <span class="number-amount-member">(次)</span>
            </div>
          </div>
        </div>
        <div class="col-9 ps-4">
          <div class="chart-div-member">
            <p class="number-label-member m-0">投放數量分布</p>
            <apexchart
              type="line"
              height="225"
              :options="lineOptions"
              :series="lineSeries"
            ></apexchart>
          </div>
        </div>
      </div>
      <div
        class="
          shadow-box
          d-flex
          align-items-center
          justify-content-between
          mt-4
        "
        style="padding: 20px"
      >
        <div style="color: #71afb6">帳單費用明細</div>
        <button
          class="basic-btn highlight-btn"
          style="width: 250px"
          @click="openDownloadBillModal"
        >
          <img
            src="../assets/white-download.svg"
            alt=""
            style="position: relative; top: -1px"
            class="me-3"
          />
          下載明細
        </button>
      </div>
      <div class="d-flex">
        <div class="col-12">
          <div class="input-div-member2">
            <span class="number-label-member m-0"
              >設定每月預計配額總數 &nbsp;</span
            >
            <span class="number-label-member2 m-0"
              >&nbsp; &nbsp;若超過設定總數，系統即會寄 Email
              信提醒到下方所填入之電子郵件。</span
            >

            <div class="d-flex align-items-center mt-5">
              <div class="col-12 d-flex">
                <span class="col-6"
                  >Email &nbsp;
                  <input type="number" v-model="emailInput" class="input" />
                  &nbsp; 封</span
                >
                <span class="col-6"
                  >SMS &nbsp;
                  <input type="number" v-model="smsInput" class="input" />
                  &nbsp; 封</span
                >
              </div>
            </div>
            <div class="number-label-member mb-2 mt-4">
              設定被提醒信箱 &nbsp;
            </div>
            <div class="number-label-member2 mb-2 mt-4">
              請輸入最多五筆電子郵件，每筆信箱請以換行方式做區隔；當配額超過時，系統會自動寄出提醒信到下方電子郵件名單。
            </div>
            <div class="d-flex">
              <div class="col-12">
                <textarea
                  class="textarea-css"
                  v-model="emailTextarea"
                ></textarea>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <div class="col-8">
                <button
                  class="btn btn-download2"
                  :class="disableButton ? '' : 'disabled'"
                  :aria-disbled="disableButton ? 'false' : 'true'"
                  @click="pushEmailer()"
                >
                  儲存
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="d-flex">
      <div class="col-12">
        <div class="input-div-member2">
       
          <div class=" ps-5 col-2 d-flex flex-column justify-content-end"  style="padding-bottom: 0.6rem;" >
            <button class="btn btn-download2" :class="disableButton ? '' : 'disabled'"
                :aria-disbled="disableButton ? 'false' : 'true'"
               
                @click="pushEmailer()">
                儲存
              </button>
          </div>
          </div>
        </div> -->
        </div>
      </div>
      <!-- <button class="btn btn-download2" @click="getCurrentMonth()">mtoh</button> -->
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, onMounted, watch } from "vue";
import VueApexCharts from "vue3-apexcharts";
import { onClickOutside } from "@vueuse/core";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import DownloadBillModal from "../components/MemberData/DownloadBillModal.vue";

export default {
  name: "MemberData",

  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    DownloadBillModal,
  },

  setup() {
    const downloading = ref(false);
    const notshow = ref(false);
    const vue3Date = ref({
      start: "",
      end: "",
    });

    const modelConfig = ref({
      type: "string",
      mask: "YYYY-MM-DD",
    });

    const hasDataFirst = ref("");
    const hasDataEnd = ref("");

    const endYear = ref(2022);
    const endMonth = ref(6);

    const disableButton = ref(false);

    const dateSelector = ref("全部");

    const saveModal = ref(false);
    const modal = ref(null);

    const emailTotal = ref(0);
    const smsTotal = ref(0);
    const lineSeries = ref([]);
    const lineOptions = ref({});

    const emailLineData = ref([]);
    const smsLineData = ref([]);
    const lineLabel = ref([]);

    const emailInput = ref(null);
    const emailInputApi = ref(null);
    const smsInput = ref(null);
    const smsInputApi = ref(null);
    const emailTextareaApi = ref([]);
    const emailTextareaApiForDisable = ref([]);
    const emailTextarea = ref([]);

    const startDate = ref("2021-11-21");
    const endDate = ref("2021-12-22");

    onClickOutside(modal, () => {
      saveModal.value = false;
    });

    watch([emailInput, smsInput, emailTextarea], () => {
      if (
        emailInput.value !== emailInputApi.value ||
        smsInput.value !== smsInputApi.value ||
        emailTextarea.value !== emailTextareaApiForDisable.value
      ) {
        disableButton.value = true;
      }
    });

    const openDownloadBillModal = async () => {
      const downloadBillModal = new bootstrap.Modal(
        document.getElementById("downloadBillModal"),
        {
          keyboard: false,
        }
      );
      downloadBillModal.show();
    };

    const downloadBill = async (bill_date) => {
      downloading.value = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        date: bill_date,
      };

      await allapi2
        .memberStatusExportBillGet(params)
        .then((res) => {
          console.log("downloadBill", res);
          window.location.assign(res.data.Data);
          downloading.value = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          downloading.value = false;
        });
    };

    const onDayClick = (day) => {
      endYear.value = day.year;
      endMonth.value = day.month;
    };

    const highlightVue3Date = async () => {
      endMonth.value = Number(endDate.value.slice(5, 7));
      endYear.value = Number(endDate.value.slice(0, 4));

      vue3Date.value = {
        start: new Date(startDate.value).toISOString().slice(0, 10),
        end: new Date(endDate.value).toISOString().slice(0, 10),
      };
    };

    const getRecentMonth = async () => {
      startDate.value = moment().subtract(1, "M").format("YYYY-MM-DD");
      endDate.value = moment().subtract(1, "days").format("YYYY-MM-DD");

      console.log("start", startDate.value);
      console.log("end", endDate.value);

      await highlightVue3Date();
      await getData();
      lineChart();
      dateSelector.value = "最近30天";
    };

    const getPass7Days = async () => {
      startDate.value = moment().subtract(7, "days").format("YYYY-MM-DD");
      endDate.value = moment().subtract(1, "days").format("YYYY-MM-DD");

      await highlightVue3Date();
      await getData();
      lineChart();
      dateSelector.value = "最近7天";
    };

    const getPass1Days = async () => {
      startDate.value = moment().subtract(2, "days").format("YYYY-MM-DD");
      endDate.value = moment().subtract(1, "days").format("YYYY-MM-DD");

      await highlightVue3Date();
      await getData();
      lineChart();
      dateSelector.value = "最近1天";
    };

    const getRecentYear = async () => {
      startDate.value = moment().subtract(1, "Y").format("YYYY-MM-DD");
      endDate.value = moment().subtract(1, "days").format("YYYY-MM-DD");

      await highlightVue3Date();
      await getData();
      lineChart();
      dateSelector.value = "最近1天";
    };

    const test = async () => {
      console.log(vue3Date.value);
      // startDate.value = vue3Date.s
    };

    const getLastMonth = async () => {
      let getFirstday = moment()
        .subtract(1, "M")
        .startOf("months")
        .format("YYYY-MM-DD");
      let getLastday = moment()
        .subtract(1, "M")
        .endOf("months")
        .format("YYYY-MM-DD");
      startDate.value = moment()
        .subtract(1, "M")
        .startOf("months")
        .format("YYYY-MM-DD");
      endDate.value = moment()
        .subtract(1, "M")
        .endOf("months")
        .format("YYYY-MM-DD");

      await highlightVue3Date();
      await getData();
      lineChart();

      dateSelector.value = "上個月";
      console.log("getFirstday", getFirstday);
      console.log("getLastday", getLastday);
    };

    const getCurrentMonth = async () => {
      startDate.value = moment().startOf("months").format("YYYY-MM-DD");
      endDate.value = moment().subtract(1, "days").format("YYYY-MM-DD");
      console.log("getFirstday", startDate.value);
      console.log("getLastday", endDate.value);

      await highlightVue3Date();
      await getData();
      lineChart();

      dateSelector.value = "這個月";
    };

    const getAll = async () => {
      startDate.value = "all";
      endDate.value = "all";

      await getData();
      lineChart();
      dateSelector.value = "全部";
    };

    const customDatePicker = async () => {
      startDate.value = vue3Date.value.start.slice(0, 10);
      endDate.value = vue3Date.value.end.slice(0, 10);

      await highlightVue3Date();
      await getData();
      lineChart();
      dateSelector.value = "自訂";
    };

    const lineChart = () => {
      (lineSeries.value = [
        {
          name: "Email",
          data: emailLineData.value,
        },
        {
          name: "SMS",
          data: smsLineData.value,
        },
      ]),
        (lineOptions.value = {
          chart: {
            height: 225,
            type: "line",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          colors: ["#63A7C8", "#F9C357"],

          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: "category",
            categories: lineLabel.value,
            tickAmount: 5,
          },
          yaxis: {
            title: {
              text: "次數",
              style: {
                fontSize: "12px",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
          },
        });
    };

    const getData = async () => {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: startDate.value,
        end_date: endDate.value,
      };

      await allapi2
        .memberStatusGetEdmSmsGet(params)
        .then((res) => {
          console.log("gwtUpperdata", res);
          smsTotal.value = res.data.Data.SMS_toal;
          emailTotal.value = res.data.Data.EDM_toal;
          lineLabel.value = res.data.Data.send_time;
          emailLineData.value = res.data.Data.EDM;
          smsLineData.value = res.data.Data.SMS;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // this.loading = false;
        });
    };

    const pushEmailer = () => {
      function isValidEmail(string) {
        var res = string.match(
          /\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+/g
        );
        return res;
      }
      let emailok = false;

      emailTextareaApi.value = isValidEmail(emailTextarea.value);
      if (emailTextareaApi.value != null) {
        const newemailtext = emailTextarea.value
          .replace(emailTextareaApi.value[0], "")
          .replace(emailTextareaApi.value[1], "")
          .replace(emailTextareaApi.value[2], "")
          .replace(emailTextareaApi.value[3], "")
          .replace(emailTextareaApi.value[4], "");
        if (newemailtext.match(/./) != null) {
          alert("信箱格式有誤");
        } else if (emailTextareaApi.value.length > 5) {
          alert("最多只能放5個信箱");
        } else {
          emailok = true;
        }
      } else {
        alert("不可留空白");
      }
      if (emailok) {
        postInput();
        disableButton.value = false;
      }

      console.log("emailTextareaApi.value.push", emailTextareaApi.value);
    };

    const postInput = async () => {
      const token = sessionStorage.getItem("access_token");

      const body = {
        Email: emailInput.value,
        SMS: smsInput.value,
        Emailer: emailTextareaApi.value,
      };
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .memberStatusWarnPost(params, body)
        .then((res) => {
          console.log("post", res);
          saveModal.value = true;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // this.loading = false;
        });

      await getInput();
    };

    const getInput = async () => {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .memberStatusWarnGet(params)
        .then((res) => {
          console.log("inputdata", res);
          emailInputApi.value = res.data.Data.email;
          emailInput.value = emailInputApi.value;
          smsInputApi.value = res.data.Data.sms;
          smsInput.value = smsInputApi.value;
          const reg = /,/g;
          emailTextareaApi.value = res.data.Data.emailer
            .toString()
            .replace(reg, "\n");
          emailTextareaApiForDisable.value = emailTextareaApi.value;
          emailTextarea.value = emailTextareaApiForDisable.value;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // this.loading = false;
        });
    };

    onBeforeMount(async () => {
      hasDataFirst.value = moment("20220101", "YYYYMMDD").format("YYYY-MM-DD");
      hasDataEnd.value = moment().subtract(1, "days").format("YYYY-MM-DD");

      // console.log('hasDataFirst.value',hasDataFirst.value)
      // console.log('hasDataEnd.value',hasDataEnd.value)

      getRecentMonth();
      await getData();
      await getInput();
      highlightVue3Date();
      lineChart();
    });

    return {
      notshow,
      downloading,
      disableButton,
      hasDataFirst,
      hasDataEnd,

      vue3Date,
      modelConfig,

      endYear,
      endMonth,

      dateSelector,

      startDate,
      endDate,

      saveModal,
      modal,

      emailLineData,
      smsLineData,
      lineLabel,

      emailTotal,
      smsTotal,

      emailInput,
      emailInputApi,
      smsInput,
      smsInputApi,
      emailTextareaApi,
      emailTextareaApiForDisable,
      emailTextarea,

      lineOptions,
      lineSeries,

      lineChart,
      getData,
      postInput,
      onDayClick,
      highlightVue3Date,

      getRecentMonth,
      getPass7Days,
      getPass1Days,
      getRecentYear,
      customDatePicker,
      getAll,
      getLastMonth,
      getCurrentMonth,
      pushEmailer,
      test,
      openDownloadBillModal,
      downloadBill,
    };
  },
};
</script>

<style scoped>
.input {
  background-color: #eaf3f4;
  border: none;
  border-radius: 10px;
  height: 35px;
  text-align: center;
  width: 70%;
  /* color:#bcb8b8 */
}

.input:focus {
  outline: none;
}

.number-div-member {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding:10px; */
}

.number-label-member {
  color: #71afb6;
  /* font-size: 22px; */
}

.number-label-member2 {
  color: #c4c4c4;
  /* font-size: 1vw; */
  /* font-size: 22px; */
}

.number-amount-member {
  /* font-size: 1.1vw; */
  /* font-weight: bold; */
  font-size: 22px;
}

.chart-div-member {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 285px;
}

.input-div-member {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 195px;
}

.input-div-member2 {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 520px;
}

.btn-download2 {
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
  border: none;
  color: #fff;
  font-size: 18px;
  width: 100%;
  border-radius: 10px;
}

.modal-header-limit {
  background: linear-gradient(90deg, #b5dbe0 0%, #71aeb5 100%);
  border-radius: 10px 10px 0px 0px;
  height: 5%;
  padding: 5px 10px;
}

.modal-body-limit {
  height: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
}

.modal-limit {
  position: fixed;
  z-index: 999;
  top: 35%;
  left: 48%;
  width: 20%;
  /* margin-left: -150px; */
  border-radius: 20px;
  background-color: aliceblue;
}

.dismiss-button {
  border-radius: 10px;
  padding: 10px 5px;
  color: #fff;

  width: 30%;
  border: none;
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
}

.success-text {
  text-align: center;
  color: #575757;
  font-weight: 600;
}

.textarea-css {
  resize: none;
  width: 100%;
  height: 150px;
  border-color: #c4c4c4;
  border-radius: 10px;
  padding: 15px;
}
</style>
