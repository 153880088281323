<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <!-- load -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- datpicker modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              is-expanded
              :model-config="modelConfig"
              :max-date="hasDataEnd"
              :min-date="hasDataFirst"
            />
            <p>{{ vue3Date.start }}~{{ vue3Date.end }}</p>
            <button
              class="btn confrim-btn"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <span class="title-span fs-medium">
        趨勢比較分析(FB Ads)
        <BtnInfoBox content="針對成效重要指標以不同時間區段 (右上角切換)來查看FB廣告投放表現，點擊按鈕亦可同時查看上個區間。" direction="right"></BtnInfoBox>

      </span>

      <div class="d-flex justify-content-end">
        <div class="title-button-div">
          <button
            class="button-nofocus"
            :class="openMail ? 'button-focus' : ''"
            @click="openMailbtn()"
          >
            流量
          </button>
          <button
            class="button-nofocus"
            :class="clickMail ? 'button-focus' : ''"
            @click="clickMailbtn()"
          >
            支出
          </button>
        </div>
      </div>

      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink"  v-if="this.hasDataEnd != null">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass14Days">
              最近14天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>

          <li class="change-date-li">
            <button class="change-date-button" @click="getLastMonth">
              上個月
            </button>
          </li>
          <li>
            <button class="change-date-button" @click="getThisMonth">
              這個月
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            color: #c4c4c4;
            font-size: 14px;
            right: 5px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>
    <div class="top-table" v-if="this.openMail" style="margin-top: 30px">
      <div
        class="
          row1
          d-flex
          justify-content-between
          align-items-center
          text-center
        "
      >
        <div class="col1"></div>
        <div class="col1">廣告曝光次數</div>
        <div class="col1">廣告點擊次數</div>
        <div class="col1">CTR(全部)</div>
        <div class="col1">廣告轉換率</div>
      </div>
      <div
        class="
          row2
          d-flex
          justify-content-between
          align-items-center
          text-center
        "
      >
        <div class="col1" style="border-right: 1.5px solid #80808059">
          同比增減
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          style="border-right: 1.5px solid #80808059"
          :style="
            this.actionsApi.同比增減.廣告曝光次數 > 0
              ? 'color:red'
              : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.廣告曝光次數 }}%
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          style="border-right: 1.5px solid #80808059"
          :style="
            this.actionsApi.同比增減.廣告點擊次數 > 0
              ? 'color:red'
              : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.廣告點擊次數 }}%
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          style="border-right: 1.5px solid #80808059"
          :style="
            this.actionsApi.同比增減.CTR連結點擊率 > 0
              ? 'color:red'
              : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.CTR連結點擊率 }}%
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          :style="
            this.actionsApi.同比增減.廣告轉換率 > 0
              ? 'color:red'
              : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.廣告轉換率 }}%
        </div>
      </div>
    </div>
    <div class="top-table mt-5" v-if="this.clickMail">
      <div
        class="
          row1
          d-flex
          justify-content-between
          align-items-center
          text-center
        "
      >
        <div class="col1"></div>
        <div class="col1">CPC</div>
        <div class="col1">CPM</div>
        <div class="col1">花費金額</div>
        <div class="col1">廣告轉換成本</div>
      </div>
      <div
        class="
          row2
          d-flex
          justify-content-between
          align-items-center
          text-center
        "
      >
        <div class="col1" style="border-right: 1.5px solid #80808059">
          同比增減
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          style="border-right: 1.5px solid #80808059"
          :style="
            this.actionsApi.同比增減.CPC > 0 ? 'color:red' : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.CPC }}%
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          style="border-right: 1.5px solid #80808059"
          :style="
            this.actionsApi.同比增減.CPM > 0 ? 'color:red' : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.CPM }}%
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          style="border-right: 1.5px solid #80808059"
          :style="
            this.actionsApi.同比增減.花費金額 > 0
              ? 'color:red'
              : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.花費金額 }}%
        </div>
        <div class="col1" v-if="this.actionsApi.同比增減 === undefined">
          - -
        </div>
        <div
          class="col1"
          :style="
            this.actionsApi.同比增減.廣告轉換成本 > 0
              ? 'color:red'
              : 'color:#71AFB6'
          "
          v-else
        >
          {{ this.actionsApi.同比增減.廣告轉換成本 }}%
        </div>
      </div>
    </div>
    <div class="mt-4 d-flex justify-content-end align-items-center">
      <div>同時查看上個區間</div>
      <div class="ms-4 group-btn-div">
        <button
          class="basic-btn"
          :style="
            showbefore
              ? 'background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);color:white'
              : ''
          "
          @click="openShowBefore"
        >
          開啟
        </button>
        <button
          class="basic-btn"
          :style="
            showbefore
              ? ''
              : 'background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);color:white'
          "
          @click="closeShowBefore"
        >
          關閉
        </button>
      </div>
    </div>
    <div class="chart-container mt-4">
      <div class="text-start label-title" v-show="this.firstTitleA">
        廣告曝光次數
      </div>
      <div class="text-start label-title" v-show="this.firstTitleB">
        CPC(NT$)
      </div>
      <div style="position: relative">
        <span
          style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
          v-if="this.actionsApi.同比增減 === undefined"
          >此時間段無資料</span
        >
        <apexchart
          class="mt-2"
          type="line"
          height="280"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div class="chart-container mt-4">
      <div class="text-start label-title" v-show="this.firstTitleA">
        廣告連結點擊數
      </div>
      <div class="text-start label-title" v-show="this.firstTitleB">
        CPM(NT$)
      </div>
      <div style="position: relative">
        <span
          style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
          v-if="this.actionsApi.同比增減 === undefined"
          >此時間段無資料</span
        >
        <apexchart
          class="mt-2"
          type="line"
          height="280"
          :options="chartOptions2"
          :series="series2"
        ></apexchart>
      </div>
    </div>
    <div class="chart-container mt-4">
      <div class="text-start label-title" v-show="this.firstTitleA">
        CTR(全部)(%)
      </div>
      <div class="text-start label-title" v-show="this.firstTitleB">
        花費金額(NT$)
      </div>
      <div style="position: relative">
        <span
          style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
          v-if="this.actionsApi.同比增減 === undefined"
          >此時間段無資料</span
        >
        <apexchart
          class="mt-2"
          type="line"
          height="280"
          :options="chartOptions3"
          :series="series3"
        ></apexchart>
      </div>
    </div>
    <div class="chart-container mt-4">
      <div class="text-start label-title" v-show="this.firstTitleA">
        廣告轉換率(%)
      </div>
      <div class="text-start label-title" v-show="this.firstTitleB">
        廣告轉換成本(NT$)
      </div>
      <div style="position: relative">
        <span
          style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
          v-if="this.actionsApi.同比增減 === undefined"
          >此時間段無資料</span
        >
        <apexchart
          class="mt-2"
          type="line"
          height="280"
          :options="chartOptions4"
          :series="series4"
        ></apexchart>
      </div>
    </div>

    <!-- <div class="d-flex justify-content-evenly mt-4">
      <button class="btn btn-lg btn-primary upload-button">
        <span> 一鍵上傳</span>
      </button>
    </div> -->
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import { allapi } from "../../public/static/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "FbTrendAnal",

  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    BtnInfoBox,
  },

  data() {
    return {
      loading: false,
      isShowTrend: false,
      showbefore: false,
      notshow: false,
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",

          toolbar: {
            show: false,
          },
        },

        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        // grid: {
        //   borderColor: "#e7e7e7",
        //   row: {
        //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        //     opacity: 0.5,
        //   },
        // },
        markers: {
          size: 0,
        },
        xaxis: {
          tickAmount: 9,
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            // text: 'Month'
          },
        },
        yaxis: {
          title: {
            // text: 'Temperature'
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      series2: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: "line",

          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          tickAmount: 9,
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            // text: 'Month'
          },
        },
        yaxis: {
          title: {
            // text: 'Temperature'
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      series3: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          height: 350,
          type: "line",

          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          tickAmount: 9,
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            // text: 'Month'
          },
        },
        yaxis: {
          title: {
            // text: 'Temperature'
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },

      series4: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions4: {
        chart: {
          height: 350,
          type: "line",

          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        title: {
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          tickAmount: 9,
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            // text: 'Month'
          },
        },
        yaxis: {
          title: {
            // text: 'Temperature'
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },

      vue3Date: {
        start: "",
        end: "",
      },

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      endYear: 2022,
      endMonth: 6,
      dateSelector: "全部",
      startDate: "2022-02-01",
      endDate: "2022-02-28",
      hasDataFirst: "",
      hasDataEnd: "",

      actionsApi: {},
      noNullactionsApi: "",
      selectedActions: "",
      notallday: "",
      allday: "",

      openMail: true,
      clickMail: false,
      firstTitleA: true,
      firstTitleB: false,
    };
  },
  created: async function () {
    // http://localhost:8080 test
    if (sessionStorage.getItem("lv") === "test") {
      this.notshow = true;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "laurel") {
      this.notshow = true;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "dev") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "bd") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    }
    if (!this.notshow) {
      this.loading = true;
      await this.getTime();
      if (this.hasDataEnd === null) {
        this.loading = false;
        return;
      }
      await this.getRecentMonth();
      await this.getChart();
      this.highlightVue3Date();
    }
  },

  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    getChart: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
        // labelId: this.childtodo.labelId,
      };
      await allapi3
        .facebookTrendGet(params)
        .then((res) => {
          console.log("chartfb", res);
          this.noNullactionsApi = res.data.Data;
          this.actionsApi = res.data.Data;

          this.allday = this.actionsApi.流量.過去.日期.concat(
            this.actionsApi.流量.現在.日期
          );
          console.log("this.noNullactionsApi", this.noNullactionsApi);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
      console.log("this.actionsApi", this.actionsApi);
      for (let i = 0; i < this.actionsApi.流量.過去.日期.length; i++) {
        this.actionsApi.流量.現在.廣告曝光次數.unshift(null);
        this.actionsApi.流量.現在.廣告轉換率.unshift(null);
        this.actionsApi.流量.現在.廣告連結點擊數.unshift(null);
        this.actionsApi.流量.現在.廣告連結點擊率.unshift(null);
      }
      for (let i = 0; i < this.actionsApi.流量.現在.日期.length; i++) {
        this.actionsApi.流量.過去.廣告曝光次數.push(null);
        this.actionsApi.流量.過去.廣告轉換率.push(null);
        this.actionsApi.流量.過去.廣告連結點擊數.push(null);
        this.actionsApi.流量.過去.廣告連結點擊率.push(null);
      }
      for (let i = 0; i < this.actionsApi.支出.過去.日期.length; i++) {
        this.actionsApi.支出.現在.CPC.unshift(null);
        this.actionsApi.支出.現在.CPM.unshift(null);
        this.actionsApi.支出.現在.廣告轉換成本.unshift(null);
        this.actionsApi.支出.現在.花費.unshift(null);
      }
      for (let i = 0; i < this.actionsApi.支出.現在.日期.length; i++) {
        this.actionsApi.支出.過去.CPC.push(null);
        this.actionsApi.支出.過去.CPM.push(null);
        this.actionsApi.支出.過去.廣告轉換成本.push(null);
        this.actionsApi.支出.過去.花費.push(null);
      }
      console.log("this.actionsApi", this.actionsApi);
    },

    openMailbtn() {
      if (this.showbefore) {
        this.clickOpenMail();
      } else {
        this.clickOpenMailnow();
      }
    },
    clickMailbtn() {
      if (this.showbefore) {
        this.clickClickMail();
      } else {
        this.clickClickMailnow();
      }
    },

    clickOpenMail() {
      if (this.actionsApi.同比增減 != undefined) {
        const date = this.allday;
        this.selectedActions = this.actionsApi.流量;

        console.log("this.selectedActions", this.selectedActions);

        this.series = [
          {
            name: "現在-廣告曝光次數",
            data: this.selectedActions.現在.廣告曝光次數,
          },
          {
            name: "過去-廣告曝光次數",
            data: this.selectedActions.過去.廣告曝光次數,
          },
        ];
        this.chartOptions = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
        console.log(
          "new time",
          this.selectedActions.過去.日期.concat(this.selectedActions.現在.日期)
        );
        this.series2 = [
          {
            name: "現在-廣告轉換率",
            data: this.selectedActions.現在.廣告連結點擊數,
          },
          {
            name: "過去-廣告轉換率",
            data: this.selectedActions.過去.廣告連結點擊數,
          },
        ];
        this.chartOptions2 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };

        this.series3 = [
          {
            name: "現在-廣告連結點擊率",
            data: this.selectedActions.現在.廣告連結點擊率,
          },
          {
            name: "過去-廣告連結點擊率",
            data: this.selectedActions.過去.廣告連結點擊率,
          },
        ];

        this.chartOptions3 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };

        this.series4 = [
          {
            name: "現在-廣告轉換率",
            data: this.selectedActions.現在.廣告轉換率,
          },
          {
            name: "過去-廣告轉換率",
            data: this.selectedActions.過去.廣告轉換率,
          },
        ];

        this.chartOptions4 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
      }
      this.openMail = true;
      this.clickMail = false;
      this.firstTitleA = true;
      this.firstTitleB = false;
    },
    clickClickMail() {
      if (this.actionsApi.同比增減 != undefined) {
        const date = this.allday;
        this.selectedActions = this.actionsApi.支出;

        console.log("this.selectedActions", this.selectedActions);

        this.series = [
          {
            name: "現在-CPC",
            data: this.selectedActions.現在.CPC,
          },
          {
            name: "過去-CPC",
            data: this.selectedActions.過去.CPC,
          },
        ];

        this.chartOptions = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };

        this.series2 = [
          {
            name: "現在-Cpm",
            data: this.selectedActions.現在.CPM,
          },
          {
            name: "過去-Cpm",
            data: this.selectedActions.過去.CPM,
          },
        ];
        this.chartOptions2 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
        this.series3 = [
          {
            name: "現在-花費",
            data: this.selectedActions.現在.花費,
          },
          {
            name: "過去-花費",
            data: this.selectedActions.過去.花費,
          },
        ];
        this.chartOptions3 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
        this.series4 = [
          {
            name: "現在-廣告轉換率",
            data: this.selectedActions.現在.廣告轉換成本,
          },
          {
            name: "過去-廣告轉換率",
            data: this.selectedActions.過去.廣告轉換成本,
          },
        ];
        this.chartOptions4 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
      }
      this.openMail = false;
      this.clickMail = true;
      this.firstTitleA = false;
      this.firstTitleB = true;
    },
    clickOpenMailnow() {
      if (this.actionsApi.同比增減 != undefined) {
        this.selectedActions = this.noNullactionsApi.流量;
        const date = this.selectedActions.現在.日期;

        console.log(
          "this.selectedActions",
          this.selectedActions.現在.廣告曝光次數.slice(date.length)
        );
        console.log("date", date);

        this.series = [
          {
            name: "現在-廣告曝光次數",
            data: this.selectedActions.現在.廣告曝光次數.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];
        this.chartOptions = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
        this.series2 = [
          {
            name: "現在-廣告轉換率",
            data: this.selectedActions.現在.廣告連結點擊數.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];
        this.chartOptions2 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };

        this.series3 = [
          {
            name: "現在-廣告連結點擊率",
            data: this.selectedActions.現在.廣告連結點擊率.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];

        this.chartOptions3 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };

        this.series4 = [
          {
            name: "現在-廣告轉換率",
            data: this.selectedActions.現在.廣告轉換率.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];

        this.chartOptions4 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
      }

      this.openMail = true;
      this.clickMail = false;
      this.firstTitleA = true;
      this.firstTitleB = false;
    },
    clickClickMailnow() {
      if (this.actionsApi.同比增減 != undefined) {
        this.selectedActions = this.noNullactionsApi.支出;
        const date = this.selectedActions.現在.日期;

        console.log("this.selectedActions", this.selectedActions);

        this.series = [
          {
            name: "現在-CPC",
            data: this.selectedActions.現在.CPC.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];

        this.chartOptions = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };

        this.series2 = [
          {
            name: "現在-Cpm",
            data: this.selectedActions.現在.CPM.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];
        this.chartOptions2 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
        this.series3 = [
          {
            name: "現在-花費",
            data: this.selectedActions.現在.花費.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];
        this.chartOptions3 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
        this.series4 = [
          {
            name: "現在-廣告轉換率",
            data: this.selectedActions.現在.廣告轉換成本.slice(
              this.selectedActions.過去.日期.length
            ),
          },
        ];
        this.chartOptions4 = {
          xaxis: {
            tickAmount: 9,
            categories: date,
            title: {
              // text: 'Month'
            },
          },
        };
      }
      this.openMail = false;
      this.clickMail = true;
      this.firstTitleA = false;
      this.firstTitleB = true;
    },
    openShowBefore() {
      this.showbefore = true;
      if (this.openMail === true) {
        this.clickOpenMail();
      } else {
        this.clickClickMail();
      }
    },
    closeShowBefore() {
      this.showbefore = false;
      if (this.openMail === true) {
        this.clickOpenMailnow();
      } else {
        this.clickClickMailnow();
      }
    },

    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .facebookTimeGet(params)
        .then((res) => {
          console.log(res);
          this.hasDataFirst = res.data.Data.start_time;
          this.hasDataEnd = res.data.Data.end_time;
          this.startDate = res.data.Data.start_time;
          this.endDate = res.data.Data.end_time;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近7天";
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近1天";
    },
    getPass14Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-14, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近14天";
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近30天";
    },
    getLastMonth: async function () {
      const start = moment(this.hasDataEnd).startOf("month").add(-1, "months");
      const end = moment(this.hasDataEnd).endOf("month").add(-1, "months");
      const strStart = start._d.toISOString().slice(0, 10);
      const strEnd = end._d.toISOString().slice(0, 10);
      this.startDate = strStart;
      this.endDate = strEnd;
      console.log("start", strStart);
      console.log("end", strEnd);
      //  this.startDate = strStart;
      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "上個月";
    },

    getThisMonth: async function () {
      const start = moment(this.hasDataEnd)
        .startOf("month")
        .add(+1, "day");
      const end = moment(this.hasDataEnd);
      const strStart = start._d.toISOString().slice(0, 10);
      const strEnd = end._d.toISOString().slice(0, 10);
      this.startDate = strStart;
      this.endDate = strEnd;
      console.log("thisstart", strStart);
      console.log("thisend", strEnd);

      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "這個月";
    },
    customDatePicker: async function () {
      this.startDate = this.vue3Date.start.slice(0, 10);
      this.endDate = this.vue3Date.end.slice(0, 10);

      console.log("customstart", this.startDate);
      console.log("customend", this.endDate);

      // await Promise.all([this.getChart(), this.clickOpenMail()]);
      await this.getChart();
      if (this.showbefore === true) {
        this.openShowBefore();
      } else {
        this.closeShowBefore();
      }
      this.loading = false;
      this.dateSelector = "自訂";
    },
  },
};
</script>

<style scoped>
/* FbTrendAnal */
.top-table {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  border-radius: 10px;
  height: 127px;
}
.row1 {
  box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 63px;
}
.row2 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 63px;
}
.col1 {
  width: 20%;
  line-height: 63px;
}
.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}
.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}
.chart-container {
  width: 100%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  margin-top: 15px;
}
.label-title {
  color: #71afb6;
  font-size: 18px;
  font-weight: 400;
}
.upload-button {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
}
.btn-lg {
  min-width: 34.5vw;
  font-size: 20px;
  border-radius: 10px;
}
.group-btn-div {
  background-color: #eaf3f4;
  border-radius: 10px;
}
.basic-btn {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}
.child-popout9 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -45px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
</style>
