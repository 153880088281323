<template>
  <div
    v-if="notshow==0"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能為正式付費使用 </span>
  </div>
  <div class="container home" v-else>
    <div
      class="modal fade"
      id="alertModalSuccess"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">上傳成功!</div>
          <div
            class="modal-footer alert-modal-footer"
            style="background-color: "
          >
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <span class="title-span fs-medium">
      受眾包預覽 (FB Ads)
      <BtnInfoBox content="僅呈現該受眾包對於FB廣告的行銷回應程度，實際人數會以 FB 後台為主，可點擊上傳至 FB 廣告後台。" direction="right"></BtnInfoBox>

    </span>
    <div>
      <div class="mt-5 form-container2">
        <div class="p-4">
          <p style="color: #71afb6" class="px-3">選擇客群</p>
          <div class="row">
            <div
              class="
                col-12
                mb-3
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex align-items-center col-3">
                <div class="search-container col-4" ref="target2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="
                      width: 270px;
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                      padding: 0 14px;
                      height: 35px;
                    "
                    @click="autoShow2 = !autoShow2"
                  >
                    {{ selectGroup }}
                    <img src="../assets/selection-arrow.png" alt="searchImg" />
                  </div>
                  <div
                    class="auto-complicate-div"
                    style="width: 270px"
                    v-show="autoShow2"
                    @mouseenter="autoShow2 = true"
                  >
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '全部';
                        autoShow2 = false;
                      "
                    >
                      全部
                    </p>
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '價值分群';
                        autoShow2 = false;
                      "
                    >
                      價值分群
                    </p>
                    <p
                      class="m-0 p-1 ps-3 auto-complicate-p"
                      @click="
                        selectGroup = '個性化分群';
                        autoShow2 = false;
                      "
                    >
                      個性化分群
                    </p>
                   
                    <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
                  </div>
                </div>
              </div>
              <div
                class="p-0 search-container col-5"
                v-if="
                  this.selectGroup === '價值分群' ||
                  this.selectGroup === '個性化分群' ||
                  this.selectGroup === '渠道行銷分群' 
                "
              >
                <img
                  class="searchImg"
                  src="../assets/search.png"
                  alt="searchImg"
                />
                <input
                  class="search-input"
                  style="border: 1px solid #c4c4c4"
                  v-model="searchInput"
                />
              </div>
              <div
                class="col-3 d-flex justify-content-end"
                style="height: 40px; position: relative; min-width: 110px"
              >
                <Paginator
                  :rows="this.perPage"
                  :pageLinkSize="3"
                  template="PrevPageLink PageLinks NextPageLink"
                  :totalRecords="searchList.length"
                  @page="onPage($event)"
                  class="m-0"
                ></Paginator>
                <div
                  style="
                    position: absolute;
                    right: 5px;
                    bottom: -25px;
                    font-size: 14px;
                    color: #8a8a8a;
                  "
                >
                  目前 {{ this.page }} ，共
                  {{ Math.ceil(this.todos.length / this.perPage) }} 頁
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap" style="height: 112px">
            <div
              class="mt-3 px-2"
              style="width: 25%; position: relative"
              :key="index"
              v-for="(todo, index) in newtodos"
            >
              <button
                class="basic-btn px-0 mx-0"
                :class="
                  usedID.includes(todo.label_id) ||
                  usedID.includes(todo.labelId)
                    ? 'disable'
                    : ''
                "
                @click="popout = index"
                ref="target4"
              >
                <div class="todo-span d-flex justify-content-between px-4">
                  {{ todo.label }}
                  <img
                    src="../assets/template-plus-icon.svg"
                    style="cursor: pointer"
                    v-if="
                      !usedID.includes(todo.label_id) ||
                      usedID.includes(todo.labelId)
                    "
                  />
                </div>
              </button>
              <div
                style="
                  position: absolute;
                  top: 32px;
                  right: -130px;
                  background-color: white;
                  width: 180px;
                  z-index: 99;
                "
                class="form-container2"
                v-if="popout === index && !usedID.includes(todo.label_id)"
              >
                <p
                  class="m-0 popout-hover"
                  style="padding: 7px 15px; cursor: default"
                  @click="
                    addLabelsToIncludes(todo);
                    popout = '';
                  "
                >
                  <img
                    src="../assets/click-to-add-label.svg"
                    alt="add-label"
                    class="me-2"
                  />
                  <span> 選擇名單 </span>
                </p>
                <p
                  class="m-0 popout-hover"
                  style="padding: 7px 15px; cursor: default"
                  @click="
                    addLabelsToExcludes(todo);
                    popout = '';
                  "
                >
                  <img
                    src="../assets/click-to-exclude-label.svg"
                    alt="exclude-label"
                    class="me-2"
                  />
                  <span> 進行排除 </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="m-0"
          style="
            width: 100%;
            opacity: 1;
            height: 0;
            border: 0;
            border-bottom: 1px solid #c4c4c4;
          "
        />
        <div class="px-4 py-3 d-flex flex-wrap justify-content-between">
          <div style="width: 49%">
            <p style="color: #71afb6">選擇名單</p>
            <div
              class="d-flex flex-wrap py-3"
              style="
                height: 130px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                overflow: auto;
              "
            >
              <div
                class="mb-3 px-3"
                style="width: 50%"
                :key="index"
                v-for="(todo, index) in includeLabels"
              >
                <button
                  class="basic-btn px-0 mx-0"
                  style="background-color: #71afb6; color: white"
                  @click="deleteLabelsFromIncludes(todo)"
                >
                  <div class="todo-span d-flex justify-content-between px-4">
                    {{ todo.label }}
                    <img
                      src="../assets/whiteX.svg"
                      alt=""
                      style="cursor: pointer; margin-top: 1px"
                    />
                  </div>
                </button>
              </div>
            </div>
            <p
              style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
              class="ms-1 mt-1 mb-0"
            >
              選擇您要上傳的名單。
            </p>
          </div>
          <div style="width: 49%">
            <p style="color: #71afb6">進行排除</p>
            <div
              class="d-flex flex-wrap py-3"
              style="
                height: 130px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                overflow: auto;
              "
            >
              <div
                class="mb-3 px-3"
                style="width: 50%"
                :key="index"
                v-for="(todo, index) in excludeLabels"
              >
                <button
                  class="basic-btn px-0 mx-0"
                  style="background-color: #71afb6; color: white"
                  @click="deleteLabelsFromExcludes(todo)"
                >
                  <div class="todo-span d-flex justify-content-between px-4">
                    {{ todo.label }}
                    <img
                      src="../assets/whiteX.svg"
                      alt=""
                      style="cursor: pointer; margin-top: 1px"
                    />
                  </div>
                </button>
              </div>
            </div>
            <p
              style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
              class="ms-1 mt-1 mb-0"
            >
              讓您將特定聯絡人從選定的名單中排除。
            </p>
          </div>
        </div>
        <hr
          class="m-0"
          style="
            width: 100%;membersBar
            opacity: 1;
            height: 0;
            border: 0;
            border-bottom: 1px solid #c4c4c4;
          "
        />
        <div class="p-4">
          <p style="color: #71afb6">實際的投放人數</p>
          <div class="d-flex align-items-center mt-4 justify-content-between">
            <div class="d-flex align-items-center">
              <img src="../assets/peopleOrange.svg" style="z-index: 10" />
              <span class="progress-span"
                >{{ numbersBars }}人({{
                  Number(percentBars).toFixed(2)
                }}%)</span
              >
              <progress
                class="top-progress"
                :value="percentBars"
                max="100"
              ></progress>
            </div>
            <span style="font-size: 20px"
              >會員總數&nbsp;&nbsp;{{ membersBars }}&nbsp;&nbsp;人</span
            >
          </div>
        </div>
      </div>
      <div v-if="!loading">
        <div class="row justify-content-center">
          <div class="fb-chart-container mt-5" style="width: 53%">
            <div class="text-start label-title">FB 行銷回應</div>
            <apexchart
              class="mt-2 me-5"
              type="donut"
              height="200"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <div
            style="width: 47%"
            class="d-flex flex-column justify-content-between"
          >
            <div class="col-12 label-container mt-5 d-flex align-items-center">
              <div class="mt-1">
                <span class="text-start label-title">廣告帳號編號 :</span>
                <span> {{ fbChartApi.fb_id }}</span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 label-container2 d-flex align-items-center">
                <div class="mt-1 d-flex py-1">
                  <span class="text-start label-title col-2">提示</span>
                  <span class="col-10">
                    投放廣告時，使用
                    <router-link to="FbShortUrl"
                      >FB 點擊追蹤功能 (強烈推薦)</router-link
                    >，讓您TA更精準更有效，並且優化廣告組合與投放策略。</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-evenly mt-4">
          <a
            href="https://www.facebook.com/adsmanager/audiences"
            target="_blank"
          >
            <button class="btn btn-lg btn-primary fb-button">
              <img src="../assets/fb-icon.svg" class="text-start fb-icon" />
              <span>查看 FB 廣告受眾</span>
            </button>
          </a>
          <button
            class="btn btn-lg btn-primary upload-button"
            @click="audienceFbCreatePost()"
          >
            <img src="../assets/upload-icon.svg" alt="" class="pe-2 pb-1" />
            <span> 一鍵上傳受眾包</span>
          </button>
        </div>
      </div>
      <div class="p-5 d-flex" v-else>
        <div class="mx-auto">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import Paginator from "primevue/paginator";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import VueApexCharts from "vue3-apexcharts";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "FacebookAdsPackage",
  components: {
    apexchart: VueApexCharts,
    Paginator,
    BtnInfoBox,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);
    const autoShow2 = ref(false);
    const target2 = ref(null);
    const autoShow3 = ref(false);
    const target3 = ref(null);
    const popout = ref(null);
    const target4 = ref(null);

    onClickOutside(target, () => (autoShow.value = false));
    onClickOutside(target2, () => (autoShow2.value = false));
    onClickOutside(target3, () => (autoShow3.value = false));
    onClickOutside(target3, () => (popout.value = false));

    return {
      target,
      autoShow,
      target2,
      autoShow2,
      target3,
      autoShow3,
      popout,
      target4,
    };
  },
  data() {
    return {
      percentBars: "",
      numbersBars: "- -",
      membersBars: "- -",
      notshow: false,
      needload: 0,
      loading: false,
      searchInput: "",
      selectGroup: "--",
      page: 1,
      perPage: 8,

      RFMtodos: [],
      customtodos: [],
      channeltodos: [],
      ALLtodos: [],
      todos: [],
      newtodos: [],
      searchList: [],
      selectedLabels: [],

      selectIDs: [],
      usedID: [],
      selectedID: "",

      RFMShow: false,
      label_id: "",
      members: [],
      label: "",

      fbChartApi: "",

      series: [],
      emailtext: "",
      phonetext: "",
      emailArr: [],
      phoneArr: [],
      testMemberModal: "",
      includeLabels: [],
      excludeLabels: [],

      chartOptions: {
        chart: {
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        colors: ["#96C5D7", "#FC9F66", "#F9C357"],
        labels: ["高", "中", "低"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  watch: {
    selectGroup: function (val) {
      console.log(val);
      if (val === "價值分群") {
        this.todos = this.RFMtodos;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "個性化分群") {
        this.todos = this.customtodos;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "渠道行銷分群") {
        this.todos = this.channeltodos;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "全部") {
        this.todos = this.ALLtodos;
        console.log("tods", this.todos);
        this.searchList = this.todos;
        this.newtodos = this.searchList;
        // this.newtodos = this.searchList.slice(0, this.perPage);
      }
    },
    searchInput: function (val) {
      if (val === "") {
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else {
        console.log(val);
        this.searchList = this.todos.filter((d) => d.label.includes(val));
        this.newtodos = this.searchList.slice(0, this.perPage);
      }
    },
  },
  created: async function () {
    if (sessionStorage.getItem("lv") == "0") {
      this.notshow = 0;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "1") {
      this.notshow = 1;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "2") {
      this.notshow = 2;
      sessionStorage.removeItem("lv");
    } 
    this.getRFM();
    this.getRFMcustom();
    this.getRFMchannel();
    this.getcustomlabelMarketingAllGet();
    // if (this.label_id != null&&this.notshow === false) {
    //   await this.getMemberFromCsv();
    //   await this.PostAud();
    // }
  },
  methods: {
    getInfoByCombineLabelID: async function (id) {
      console.log("this.group", this.group);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        label_id: id,
      };
      await allapi2
        .customlabelMarketingLabelIdGet(params)
        .then((res) => {
          console.log("根據合成後的label_id拿包含不包含", res);
          const includeArr = res.data.message.Data.content.include;
          if (includeArr[0] != undefined) {
            for (let index = 0; index < includeArr.length; index++) {
              const include_label = {};
              include_label.label_id = includeArr[index];
              include_label.label = this.group.includeLabelNames[index];
              this.includeLabels.push(include_label);
              this.usedID.push(includeArr[index]);
            }
          }
          const excludeArr = res.data.message.Data.content.exclude;
          if (excludeArr[0] != undefined) {
            for (let index = 0; index < excludeArr.length; index++) {
              const exclude_label = {};
              exclude_label.label_id = excludeArr[index];
              exclude_label.label = this.group.excludeLabelNames[index];
              this.excludeLabels.push(exclude_label);
              this.usedID.push(excludeArr[index]);
            }
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    addLabelsToIncludes(todo) {
      if (this.selectGroup === "測試人員") {
        this.getTestMember();
      } else {
        const ids = this.includeLabels.map((d) => {
          return d.labelId || d.label_id;
        });
        const hasid = ids.findIndex((d) => {
          return d === todo.label_id || d === todo.labelId;
        });

        console.log(ids);
        console.log(hasid);
        if (hasid != -1) {
          return;
        }
        this.includeLabels.push(todo);
        if (todo.label_id != undefined) {
          this.usedID.push(todo.label_id);
        } else if (todo.labelId != undefined) {
          this.usedID.push(todo.labelId);
        }

        console.log("this.usedID", this.usedID);
        console.log(this.includeLabels);
        this.checkCombineLabelMemberCount();
        this.PostAud();
      }
    },
    addLabelsToExcludes(todo) {
      const ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const hasid = ids.findIndex((d) => {
        return d === todo.label_id || d === todo.labelId;
      });

      console.log(ids);
      console.log(hasid);
      if (hasid != -1) {
        return;
      }
      this.excludeLabels.push(todo);
      if (todo.label_id != undefined) {
        this.usedID.push(todo.label_id);
      } else if (todo.labelId != undefined) {
        this.usedID.push(todo.labelId);
      }

      console.log(this.excludeLabels);
      this.checkCombineLabelMemberCount();
      this.PostAud();
    },
    deleteLabelsFromIncludes(todo) {
      console.log(todo);
      this.includeLabels = this.includeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);

      if (this.includeLabels[0] === undefined) {
        this.percentBars = "";
        this.numbersBars = "";
        this.membersBars = "- -";
      } else {
        this.checkCombineLabelMemberCount();
        this.PostAud();
      }
    },
    deleteLabelsFromExcludes(todo) {
      console.log(todo);
      this.excludeLabels = this.excludeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      if (this.includeLabels[0] === undefined) {
        this.percentBars = "";
        this.numbersBars = "";
        this.membersBars = "- -";
      } else {
        this.checkCombineLabelMemberCount();
        this.PostAud();
      }
    },
    checkCombineLabelMemberCount: async function () {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      console.log(include_ids, exclude_ids);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
        type: "email",
      };

      await allapi2
        .customlabelMarketingRatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的人數", res);
          this.percentBars = res.data.message.Data.ratio;
          this.numbersBars = res.data.message.Data.count;
          this.membersBars = res.data.message.Data.total;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getTestMember() {
      this.opentestMemberModal();
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        email: this.emailArr,
        phone: this.phoneArr,
      };

      allapi2
        .testLabelGet(params, body)
        .then((res) => {
          console.log(res);
          const reg = /,/g;
          this.emailtext = res.data.Data.email.toString().replace(reg, "\n");
          this.phonetext = res.data.Data.phone.toString().replace(reg, "\n");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getCombineLabelID: async function (isPatch) {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
      };

      await allapi2
        .customlabelMarketingCreatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的label_id", res);
          this.combineLabelID = res.data.message.Data.label_id;
          if (isPatch) {
            console.log("going to patch");
          } else {
            this.postHtmlApiSuccess();
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("combinID有誤");
        });
    },

    getRFM: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .customlabelAilabelGet(params)
        .then((res) => {
          console.log(res);
          this.RFMtodos = res.data.message.Data;
          this.todos = this.RFMtodos;
          // this.searchList = this.todos;
          // this.newtodos = this.searchList.slice(0, this.perPage);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getRFMcustom: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .customlabelListGet(params)
        .then((res) => {
          console.log(res);
          this.customtodos = res.data.message.Data;
          this.customtodos.sort((a, b) => {
            return b.time - a.time;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getRFMchannel: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .remarketinglabelGet(params)
        .then((res) => {
          console.log("channellabel", res);
          this.channeltodos = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getcustomlabelMarketingAllGet: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .customlabelMarketingAllGet(params)
        .then((res) => {
          console.log("ALLresres", res);
          this.ALLtodos = res.data.message.Data;
          // this.newtodos = this.ALLtodos
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    addLabels: async function (todo) {
      this.needload = this.needload + 1;

      const ids = this.selectedLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const hasid = ids.findIndex((d) => {
        return d === todo.label_id || d === todo.labelId;
      });

      console.log(ids);
      console.log(hasid);
      if (hasid != -1) {
        return;
      }
      this.selectedLabels.push(todo);
      await this.getMemberFromCsv();
      await this.PostAud();
      console.log(this.selectedLabels);
    },
    deleteLabels: async function (todo) {
      this.needload = this.needload + 1;
      this.selectedLabels = this.selectedLabels.filter((d) => {
        return d != todo;
      });

      await this.getMemberFromCsv();
      await this.PostAud();
      if (this.selectedLabels[0] === undefined) {
        this.fbChartApi = {};
        this.series = [];
      }
    },

    onPage(event) {
      const startIndex = event.rows * event.page;
      const endIndex = startIndex + event.rows;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
      this.newtodos = this.searchList.slice(startIndex, endIndex);
      console.log(this.newtodos);
    },

    show() {
      // a=!a
      // console.log("a",a)
      this.RFMShow = !this.RFMShow;
      // this.ABShow = !this.ABShow
    },
    getMemberFromCsv: async function () {
      if (this.selectedLabels[0] != undefined) {
        this.loading = true;
        this.selectIDs = this.selectedLabels.map(
          (d) => d.label_id || d.labelId
        );
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
        };
        const body = {
          label_ids: this.selectIDs,
        };
        await allapi2
          .multipleLabelMemberPost(params, body)
          .then((res) => {
            console.log(res);
            this.members = res.data.message.Data.member;
            // this.members = this.json_data.map((d) => {
            //   return d.member;
            // });
            console.log(this.needload);

            console.log(this.members);
          })
          .catch((err) => {
            console.log(err);
            alert("連線有誤");
            this.loading = false;
          });
      }
    },
    PostAud: async function () {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
        type: "email",
      };
      await allapi2
        .audienceFbPost(params, body)
        .then((res) => {
          console.log("fbpost", res);
          this.fbChartApi = res.data.Data;
          this.series = [
            +this.fbChartApi.persentage.高,
            +this.fbChartApi.persentage.中,
            +this.fbChartApi.persentage.低,
          ];
          this.needload = this.needload - 1;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      if (this.needload === 0) {
        this.loading = false;
      }
    },
    audienceFbCreatePost: async function () {
      // if (this.members[0] === undefined) {
      //   alert("請先選擇分群");
      //   return;
      // }
      const alertModal = new bootstrap.Modal(
        document.getElementById("alertModalSuccess"),
        {
          keyboard: false,
        }
      );
      alertModal.show();

      // const token = sessionStorage.getItem("access_token");

      // const params = {
      //   Authorization: "Bearer " + token,
      // };
      // const body = {
      //   name: this.label + " Facebook受眾包",
      //   audience: this.members,
      //   description: "測試上傳FB受眾包",
      // };
      // await allapi2
      //   .audienceFbCreatePost(params, body)
      //   .then((res) => {
      //     console.log("fbcreate", res);

      //     const alertModal = new bootstrap.Modal(
      //       document.getElementById("alertModalSuccess"),
      //       {
      //         keyboard: false,
      //       }
      //     );
      //     alertModal.show();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.loading = false;
      //     alert("連線有誤");
      //   });
    },
  },
};
</script>

<style scoped>
/* FacebookAdsPackage */
.form-container2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}
.fb-chart-container {
  flex: 0 0 auto;
  height: 228px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  margin-top: 30px;
}
.child-popout3 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -50px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.home {
  padding-top: 55px;
}

.label-container {
  flex: 0 0 auto;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 25px 20px 25px;
  margin-top: 15px;
}
.label-container2 {
  flex: 0 0 auto;
  height: 100%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  /* margin-top: 15px; */
}
.label-title {
  color: #71afb6;
  font-size: 18px;
  font-weight: 400;
}
.fb-all {
  justify-content: center;
  flex-direction: column;
}
.fb-button {
  position: relative;
  background-color: #395185;
  border: none;
}
.upload-button {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
}
.btn-lg {
  min-width: 34.5vw;
  font-size: 18px;
  border-radius: 10px;
}
.fb-icon {
  position: absolute;
  left: 30px;
}
.popout-hover:hover {
  background-color: #eaf3f4;
}
.popout-hover:hover:first-child {
  background-color: #eaf3f4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popout-hover:hover:last-child {
  background-color: #eaf3f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.disable {
  background-color: #eeeeee;
  color: #c4c4c4;
}
.search-container {
  position: relative;
}
.searchImg {
  position: absolute;
  /* width: 3%; */
  height: 20px;
  top: 10px;
  left: 8px;
}
.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 30px;
  width: 100%;
  height: 37px;
}
</style>