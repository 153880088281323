<template>
  <!-- loading -->
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="header d-flex justify-content-between">
      <div class="d-flex" style="position: relative">
        <!-- loading -->
        <div class="loading-background" v-show="loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <!-- main -->
        <span class="title-span fs-medium">營運總覽</span>
        <!-- <button @click="test">test</button> -->
        <div class="title-button-div fs-small">
          <button
            class="button-nofocus"
            :class="all ? 'button-focus' : ''"
            @click="getall"
          >
            全部
          </button>
          <button
            class="button-nofocus"
            :class="online ? 'button-focus' : ''"
            @click="getonline"
          >
            線上
          </button>
          <button
            class="button-nofocus"
            :class="offline ? 'button-focus' : ''"
            @click="getoffline"
          >
            線下
          </button>
        </div>
      </div>

      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <DatePicker
                :from-page="{ month: this.endMonth, year: this.endYear }"
                @dayclick="onDayClick"
                v-model="vue3Date"
                is-range
                is-expanded
                :model-config="modelConfig"
                :min-date="hasDataFirst"
                :max-date="hasDataEnd"
              />
              <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
                {{ vue3Date.start }}~{{ vue3Date.end }}
              </p>
              <button
                class="btn confrim-btn mt-3"
                @click="customDatePicker"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                選取
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4" style="padding: 0px">
      <div class="d-flex flex-wrap">
        <div
          :class="all ? 'col-5 pe-4' : 'col-12 d-flex justify-content-between'"
        >
          <div
            style="margin-bottom: 24px"
            class="number-div d-flex"
            :class="
              all
                ? 'justify-content-between align-items-center'
                : 'online-width'
            "
          >
            <span class="fs-small" style="color: #71afb6">營業額</span>
            <span class="fs-medium" v-if="selectedSumNew.營業額 === undefined"
              >- -&nbsp;($)</span
            >
            <span class="fs-medium" v-else
              >{{ selectedSumNew.營業額 }}&nbsp;($)</span
            >
          </div>
          <div
            style="margin-bottom: 24px"
            class="number-div d-flex"
            :class="
              all
                ? 'justify-content-between align-items-center'
                : 'online-width'
            "
          >
            <span class="fs-small" style="color: #71afb6">客單價</span>
            <span class="fs-medium" v-if="selectedSumNew.客單價 === undefined"
              >- -&nbsp;($)</span
            >
            <span class="fs-medium" v-else
              >{{ selectedSumNew.客單價 }}&nbsp;($)</span
            >
          </div>
          <div
            class="number-div d-flex"
            :class="
              all
                ? 'justify-content-between align-items-center'
                : 'online-width'
            "
          >
            <span class="fs-small" style="color: #71afb6">訂單數</span>
            <span class="fs-medium" v-if="selectedSumNew.訂單數 === undefined"
              >- -&nbsp;($)</span
            >
            <span class="fs-medium" v-else
              >{{ selectedSumNew.訂單數 }}&nbsp;(次)</span
            >
          </div>
        </div>
        <div class="line-bar-div" :class="all ? 'col-7' : 'col-12'">
          <span class="fs-small" style="color: #71afb6">營業額 X 訂單數</span>
          <div id="lineBar" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="this.selectedMixedLine.日期[0] === undefined"
              >此時間段無資料</span
            >
            <apexchart
              type="line"
              height="330"
              :options="mixOptions"
              :series="mixSeries"
            ></apexchart>
          </div>
        </div>
      </div>
      <div v-show="all">
        <div class="d-flex">
          <div class="col-5">
            <div class="pie-div-OperationalOverview">
              <span class="fs-small" style="color: #71afb6"
                >通路線上/線下佔比</span
              >
              <div id="donut" style="position: relative">
                <span
                  class="text-center"
                  style="
                    position: absolute;
                    top: 20%;
                    left: 24%;
                    color: #c4c4c4;
                  "
                  v-if="this.series2.every((d) => d === 0)"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <br />
                  此時間段無資料</span
                >
                <apexchart
                  type="donut"
                  height="300"
                  :options="chartOptions2"
                  :series="series2"
                ></apexchart>
              </div>
            </div>
          </div>
          <div class="col-7 line-div">
            <span class="fs-small" style="color: #71afb6"
              >線上線下消費走勢</span
            >
            <div id="line" style="position: relative">
              <span
                style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
                v-if="this.series3[0].data[0] === undefined"
                >此時間段無資料</span
              >
              <apexchart
                type="line"
                height="260"
                :options="chartOptions3"
                :series="series3"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";

import VueApexCharts from "vue3-apexcharts";
export default {
  name: "OperationalOverview",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
  },
  data() {
    return {
      loading: false,

      vue3Date: {
        start: "",
        end: "",
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },

      dateSelector: "最近30天",

      // dount
      series2: [0, 0],
      chartOptions2: {},
      // line
      series3: [
        {
          data: [],
        },
      ],
      chartOptions3: {},

      sumOFBussAll: null,

      apiChannelPie: [],
      apiNumber: [],
      apiMixLine: [],
      apiMixColumm: [],
      apiLine: [],

      online: false,
      offline: false,
      all: true,

      selectedSumNew: [],
      selectedMixedLine: {
        日期: [],
        營業額: [],
        訂單數: [],
      },
      selectedPieNew: [],
      selectedLineNew: [],

      mixSeries: [],
      mixOptions: {},

      onlineAndOffline: "全部",

      startDate: "2021-11-01",
      endDate: "2021-12-22",
      hasDataFirst: "",
      hasDataEnd: "",

      behaviors: "",
      models: {
        b0: "",
        b1: "",
        b2: "",
        b3: "",
        b4: "",
        b5: "",
        b6: "",
        b7: "",
        b8: "",
        b9: "",
        b10: "",
      },
      signupUser: "",
      signupEmail: "",

      endYear: 2022,
      endMonth: 6,
    };
  },
  beforeMount() {},
  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();
    this.highlightVue3Date();
    this.getall();
  },

//   accessKey: '',
//   secretKey: '',
//   sessionToken: '',
//   region: 'ap-northeast-1',
//   apiKey: 'Rx1MmkMDROayqGCtfXCcQ7FqpeXWSshs7TPHRx0B',
//   defaultContentType: 'application/json',
//   defaultAcceptType: 'application/json'

// export var allapi = apigClientFactory.newClient();

  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeSalesGet(params)
        .then((res) => {
          console.log(res);
          // this.vue3Date.start = moment(res.data.Data.end_date).add(-1,"days");
          // this.vue3Date.end = res.data.Data.end_date;
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    postNumberData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMetricsIndexGet(params)
        .then((res) => {
          console.log("number", res);
          if (res.data.Data.整體.營業額 === undefined) {
            res.data.Data.整體 = {};
          } else {
            res.data.Data.整體.營業額 =
              res.data.Data.整體.營業額.toLocaleString();
            res.data.Data.整體.客單價 =
              res.data.Data.整體.客單價.toLocaleString();
            res.data.Data.整體.訂單數 =
              res.data.Data.整體.訂單數.toLocaleString();
          }

          if (res.data.Data.線上.營業額 === undefined) {
            res.data.Data.線上 = {};
          } else {
            res.data.Data.線上.營業額 =
              res.data.Data.線上.營業額.toLocaleString();
            res.data.Data.線上.客單價 =
              res.data.Data.線上.客單價.toLocaleString();
            res.data.Data.線上.訂單數 =
              res.data.Data.線上.訂單數.toLocaleString();
          }
          if (res.data.Data.線下.營業額 === undefined) {
            res.data.Data.線下 = {};
          } else {
            res.data.Data.線下.營業額 =
              res.data.Data.線下.營業額.toLocaleString();
            res.data.Data.線下.客單價 =
              res.data.Data.線下.客單價.toLocaleString();
            res.data.Data.線下.訂單數 =
              res.data.Data.線下.訂單數.toLocaleString();
          }

          this.apiNumber = res.data.Data;
          // this.getall();
          // this.apiNumber = this.apiNumber.toLocaleString()
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);

          this.loading = false;
        });
    },
    postMixedChartData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMetricsSalesxordersGet(params)
        .then((res) => {
          console.log("mixed", res);
          this.apiMixLine = res.data.Data;
        })
        .catch((err) => {
          // alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    postPieData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationMetricsChannelrateGet(params)
        .then((res) => {
          console.log("chanelpie", res.data.Data[0]);

          this.apiChannelPie = res.data.Data[0];
        })
        .catch((err) => {
          // alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    postLineData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMetricsOnlinetrendGet(params)
        .then((res) => {
          console.log("lineapi", res);
          this.apiLine = res.data.Data;
        })
        .catch((err) => {
          // alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    pieChart() {
      (this.series2 = [this.selectedPieNew.線上, this.selectedPieNew.線下]),
        (this.chartOptions2 = {
          chart: {
            height: 330,
            type: "donut",
          },
          colors: ["#63A7C8", "#F9C357"],
          labels: ["線上", "線下"],
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              // breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        });
    },
    lineChart() {
      this.series3 = [
        {
          name: "線上",
          data: this.selectedLineNew.線上,
        },
        {
          name: "線下",
          data: this.selectedLineNew.線下,
        },
      ];
      this.chartOptions3 = {
        chart: {
          height: 280,
          type: "line",
          dropShadow: {
            enabled: false,
            color: "#575757",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        colors: ["#63A7C8", "#F9C357"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "medium",
            color: "#71AFB6",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: this.selectedLineNew.日期,
          tickAmount: 7,
        },
        yaxis: {
          title: {
            text: "營業額",
            style: {
              fontSize: "12px",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
          // min: 5,
          // max: 40,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          offsetY: 6,
          offsetX: -5,
        },
      };
    },
    mixedChart() {
      (this.mixSeries = [
        {
          name: "營業額",
          type: "column",
          data: this.selectedMixedLine.營業額,
        },
        {
          name: "訂單數",
          type: "line",
          data: this.selectedMixedLine.訂單數,
        },
      ]),
        (this.mixOptions = {
          chart: {
            // width:820,
            height: 320,
            type: "line",
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#F9C357"],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: "smooth",
            width: 2,
          },
          title: {
            align: "left",
            style: {
              fontSize: "20px",
              fontWeight: "medium",
              color: "#71AFB6",
            },
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            categories: this.selectedMixedLine.日期,
            tickAmount: 7,
            labels: {
              show: true,
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: [
            {
              title: {
                text: "營業額",
                style: {
                  fontSize: "12px",
                },
              },
              labels: {
                style: {
                  fontSize: "12px",
                },
                formatter: function (y) {
                  return y.toLocaleString();
                },
              },
            },
            {
              opposite: true,
              title: {
                text: "訂單數",
                style: {
                  fontSize: "12px",
                },
              },
              labels: {
                style: {
                  fontSize: "12px",
                },
                formatter: function (y) {
                  return y.toLocaleString();
                },
              },
            },
          ],

          responsive: [
            {
              //  breakpoint: 1000,
              options: {
                width: 820,
              },
            },
          ],

          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            offsetX: -5,
          },
        });
    },

    getall: async function () {
      this.all = true;
      this.online = false;
      this.offline = false;
      this.onlineAndOffline = "整體";
      this.onlineOrOffline = "all";

      this.selectedSumNew = this.apiNumber.整體;
      this.selectedMixedLine = this.apiMixLine.整體;
      this.selectedPieNew = this.apiChannelPie;
      this.selectedLineNew = this.apiLine;
      this.pieChart();
      this.lineChart();
      setTimeout(() => {
        this.mixedChart();
      }, 0);
    },
    getonline() {
      this.all = false;
      this.online = true;
      this.offline = false;
      this.onlineAndOffline = "線上";
      this.onlineOrOffline = "onlineAction";

      this.selectedSumNew = this.apiNumber.線上;
      this.selectedMixedLine = this.apiMixLine.線上;
      setTimeout(() => {
        this.mixedChart();
      }, 0);
    },
    getoffline() {
      this.all = false;
      this.online = false;
      this.offline = true;
      this.onlineAndOffline = "線下";
      this.onlineOrOffline = "offlineAction";

      this.selectedSumNew = this.apiNumber.線下;
      this.selectedMixedLine = this.apiMixLine.線下;
      setTimeout(() => {
        this.mixedChart();
      }, 0);
    },
    getallTime() {
      this.timeSwitch = "allTime";
      this.selectedLineData = this.mixChartData.allTime[this.onlineOrOffline];
      this.selectedcolummData = this.mixChartData.allTime[this.onlineOrOffline];

      this.mixedChart();
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);

      if (this.all) {
        this.getall();
      }
      if (this.online) {
        this.getonline();
      }
      if (this.offline) {
        this.getoffline();
      }
      this.loading = false;
      this.dateSelector = "全部";
    },
    getRecentYear: async function () {
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.loading = true;

      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);

      if (this.all) {
        this.getall();
      }
      if (this.online) {
        this.getonline();
      }
      if (this.offline) {
        this.getoffline();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近一年";
    },
    getRecentMonth: async function () {
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.loading = true;

      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);

      if (this.all) {
        this.getall();
      }
      if (this.online) {
        this.getonline();
      }
      if (this.offline) {
        this.getoffline();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近30天";
    },
    getPass7Days: async function () {
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.loading = true;

      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);

      if (this.all) {
        this.getall();
      }
      if (this.online) {
        this.getonline();
      }
      if (this.offline) {
        this.getoffline();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近7天";
    },
    getPass1Days: async function () {
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.loading = true;

      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);

      if (this.all) {
        this.getall();
      }
      if (this.online) {
        this.getonline();
      }
      if (this.offline) {
        this.getoffline();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近1天";
    },

    customDatePicker: async function () {
      this.startDate = this.vue3Date.start;
      this.endDate = this.vue3Date.end;
      this.loading = true;

      await Promise.all([
        this.postPieData(),
        this.postNumberData(),
        this.postMixedChartData(),
        this.postLineData(),
      ]);

      if (this.all) {
        this.getall();
      }
      if (this.online) {
        this.getonline();
      }
      if (this.offline) {
        this.getoffline();
      }
      this.loading = false;
      this.dateSelector = "自訂";
    },
  },
};
</script>

<style scoped>
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  padding: 50px;
}
.title-span {
  /* font-size: 24px; */
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}
.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}
.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}
.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}
.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}
.number-div {
  padding: 0 15px;
  margin-top: 5px;
  /* margin-bottom: 2rem; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 114px;
}
.online-width {
  width: 24.3vw;
  align-items: center;
  justify-content: space-between;
}
.line-bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 5px;
  height: 390px;
}
.pie-div-OperationalOverview {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 1.5rem;
  height: 356px;
}
.line-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 356px;
}
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}
.change-date-li:hover {
  background-color: #eaf3f4;
}
.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
.parent-popout {
  position: relative;
}

.child-popout {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -30px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
@media (max-width: 1200px) {
  .online-width {
    width: 22vw;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  /* .fs-small {
    font-size: 16px;
  }
  .fs-medium {
    font-size: 20px;
  } */
}
</style>
