<template>
  <div
    v-if="notshow == 0"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能為正式付費使用 </span>
  </div>

  <div class="container home" v-else>
    <!-- loading screen -->

    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->

    <div class="d-flex" style="align-items: center">
      <span class="title-span fs-medium d-flex align-items-center">
        <img
          src="../assets/back-arrow.png"
          @click="gotable"
          style="cursor: pointer"
        />
        &nbsp; &nbsp; 自動化劇本
      </span>
      <BtnInfoBox
        style="position:relative;top:5px"
        content="此模組主要提供行銷人員設計自動化的訊息溝通，根據觸發事件像是註冊、購物車未結、購買等行為判斷，來讓人員來進行廣告訊息上的安排。請先選擇目標名單再進行下一步。"
        direction="right"
      ></BtnInfoBox>
    </div>
    <div class="contain-top mt-4">
      <div class="d-flex align-items-center">
        <div style="width: 140px; margin-right: 20px">
          <span style="color: #71afb6">選擇名單</span>
          <button
            class="btn btn-default button p-0"
            style="width: 48px"
            @click="show()"
          >
            <span class="d-flex justify-content-center">
              <img
                class="align-self-center"
                src="../assets/question-img.png"
                @mouseover="list_explain = true"
                @mouseleave="list_explain = false"
            /></span>
            <div class="parent-popout">
              <div v-show="list_explain" class="child-popout-inTriggerScript2">
                現有顧客: 僅針對您目前的整體會員。<br />
                未來新加入:針對您建立的時間點，後續加入名單的會員。<br />
                自建名單:您在系統上自己建立的分群名單。
                <div class="container__arrow"></div>
              </div>
            </div>
          </button>
          <!-- <BtnInfoBox content="現有顧客: 僅針對您目前的整體會員。
                未來新加入:針對您建立的時間點，後續加入名單的會員。
                自建名單:您在系統上自己建立的分群名單。" direction="right"></BtnInfoBox> -->
        </div>
        <div
          class="search-container flex-fill"
          style="position: relative"
          ref="target4"
        >
          <div
            class="d-flex justify-content-end align-items-center"
            style="
              border: 1px solid #c4c4c4;
              border-radius: 10px;
              padding: 0 14px;
              height: 35px;
            "
            @click="autoShow4 = !autoShow4"
          >
            <span class="flex-fill">
              {{ selectGroup }}
            </span>
            <img src="../assets/selection-arrow.png" alt="searchImg" />
          </div>
          <div
            class="auto-complicate-div"
            style="width: 100%; cursor: default"
            v-show="autoShow4"
            @mouseenter="autoShow4 = true"
          >
            <p
              class="m-0 p-1 ps-3 auto-complicate-p"
              @click="
                selectGroup = '現有顧客+未來新加入名單';
                autoShow4 = false;
              "
            >
              現有顧客+未來新加入名單(全部)
            </p>
            <p
              class="m-0 p-1 ps-3 auto-complicate-p"
              @click="
                selectGroup = '針對現有顧客名單';
                autoShow4 = false;
              "
            >
              針對現有顧客名單(僅取現有資料庫名單)
            </p>
            <p
              class="m-0 p-1 ps-3 auto-complicate-p"
              @click="
                selectGroup = '針對未來新加入名單';
                autoShow4 = false;
              "
            >
              針對未來新加入名單(新註冊使用)
            </p>
            <p
              class="m-0 p-1 ps-3 auto-complicate-p"
              @click="
                selectGroup = '自建名單';
                autoShow4 = false;
              "
            >
              自建名單(貼標分群)
            </p>
            <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 form-container2" v-if="this.selectGroup === '自建名單'">
      <div class="p-4">
        <p style="color: #71afb6" class="px-3">選擇客群</p>
        <div class="row">
          <div
            class="
              col-12
              mb-3
              d-flex
              align-items-center
              justify-content-between
              px-4
            "
          >
            <div class="d-flex align-items-center col-3">
              <div class="search-container col-4" ref="target2">
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="
                    width: 270px;
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                    padding: 0 14px;
                    height: 35px;
                  "
                  @click="autoShow2 = !autoShow2"
                >
                  {{ selectCustomGroup }}
                  <img src="../assets/selection-arrow.png" alt="searchImg" />
                </div>
                <div
                  class="auto-complicate-div"
                  style="width: 270px"
                  v-show="autoShow2"
                  @mouseenter="autoShow2 = true"
                >
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectCustomGroup = '價值分群';
                      autoShow2 = false;
                    "
                  >
                    價值分群
                  </p>
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectCustomGroup = '個性化分群';
                      autoShow2 = false;
                    "
                  >
                    個性化分群
                  </p>
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectCustomGroup = '自訂分群';
                      autoShow2 = false;
                    "
                  >
                    自訂分群
                  </p>

                  <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
                </div>
              </div>
            </div>
            <div
              class="p-0 search-container col-5"
              v-if="
                this.selectCustomGroup === '價值分群' ||
                this.selectCustomGroup === '個性化分群' ||
                this.selectCustomGroup === '渠道行銷分群' ||
                this.selectCustomGroup === '自訂分群'
              "
            >
              <img
                class="searchImg"
                src="../assets/search.png"
                alt="searchImg"
              />
              <input
                style="
                  border-radius: 10px;
                  border: 1px solid #c4c4c4;
                  height: 35px;
                  padding-left: 30px;
                "
                placeholder="搜尋分群名稱"
                v-model="searchInput"
              />
            </div>
            <div
              class="col-3 d-flex justify-content-end"
              style="height: 40px; position: relative; min-width: 110px"
            >
              <Paginator
                :rows="this.perPage"
                :pageLinkSize="3"
                template="PrevPageLink PageLinks NextPageLink"
                :totalRecords="this.todos.length"
                @page="onPage($event)"
                class="m-0"
              ></Paginator>
              <div
                style="
                  position: absolute;
                  right: 5px;
                  bottom: -25px;
                  font-size: 14px;
                  color: #8a8a8a;
                "
              >
                目前 {{ this.page }} ，共
                {{ Math.ceil(this.todos.length / this.perPage) }} 頁
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap" style="height: 112px" ref="target5">
          <div
            class="mt-3 px-2"
            style="width: 25%; position: relative"
            :key="index"
            v-for="(todo, index) in displayedRecords"
          >
            <button
              class="basic-btn px-0 mx-0"
              :class="
                usedID.includes(todo.label_id) || usedID.includes(todo.labelId)
                  ? 'disable'
                  : ''
              "
              @click="popout = index"
            >
              <div class="d-flex justify-content-between px-4">
                <span
                  class="todo-span-inTriggerScript"
                  style="line-height: 40px"
                >
                  {{ todo.label }}
                </span>
                <img
                  src="../assets/template-plus-icon.svg"
                  style="cursor: pointer"
                  v-if="
                    !usedID.includes(todo.label_id) ||
                    usedID.includes(todo.labelId)
                  "
                />
              </div>
            </button>
            <div
              style="
                position: absolute;
                top: 35px;
                right: -65px;
                background-color: white;
                width: 150px;
                z-index: 99;
              "
              class="form-container2"
              v-if="popout === index && !usedID.includes(todo.label_id)"
            >
              <p
                class="m-0 popout-hover"
                style="padding: 7px 15px; cursor: default"
                @click="
                  addLabelsToIncludes(todo);
                  popout = '';
                "
              >
                <img
                  class="me-2"
                  src="../assets/click-to-add-label.svg"
                  alt="add-label"
                />
                <span> 選擇發送 </span>
              </p>
              <p
                class="m-0 popout-hover"
                style="padding: 7px 15px; cursor: default"
                @click="
                  addLabelsToExcludes(todo);
                  popout = '';
                "
              >
                <img
                  class="me-2"
                  src="../assets/click-to-exclude-label.svg"
                  alt="exclude-label"
                />
                <span> 進行排除 </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="m-0"
        style="
          width: 100%;
          opacity: 1;
          height: 0;
          border: 0;
          border-bottom: 1px solid #c4c4c4;
        "
      />
      <div class="px-4 py-3 d-flex flex-wrap justify-content-between">
        <div style="width: 49%">
          <p style="color: #71afb6">選擇發送</p>
          <div
            class="d-flex flex-wrap py-3"
            style="
              height: 130px;
              border: 1px solid #c4c4c4;
              border-radius: 10px;
              overflow: auto;
            "
          >
            <div
              class="mb-3 px-3"
              style="width: 50%"
              :key="index"
              v-for="(todo, index) in includeLabels"
            >
              <button
                class="basic-btn px-0 mx-0"
                style="background-color: #71afb6; color: white"
                @click="deleteLabelsFromIncludes(todo)"
              >
                <div
                  class="
                    todo-span-inTriggerScript
                    d-flex
                    justify-content-between
                    px-4
                  "
                >
                  {{ todo.label }}
                  <img
                    src="../assets/whiteX.svg"
                    alt=""
                    style="cursor: pointer; margin-top: 1px"
                  />
                </div>
              </button>
            </div>
          </div>
          <p
            style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
            class="ms-1 mt-1 mb-0"
          >
            選擇您要發送的名單。
          </p>
        </div>
        <div style="width: 49%">
          <p style="color: #71afb6">進行排除</p>
          <div
            class="d-flex flex-wrap py-3"
            style="
              height: 130px;
              border: 1px solid #c4c4c4;
              border-radius: 10px;
              overflow: auto;
            "
          >
            <div
              class="mb-3 px-3"
              style="width: 50%"
              :key="index"
              v-for="(todo, index) in excludeLabels"
            >
              <button
                class="basic-btn px-0 mx-0"
                style="background-color: #71afb6; color: white"
                @click="deleteLabelsFromExcludes(todo)"
              >
                <div
                  class="
                    todo-span-inTriggerScript
                    d-flex
                    justify-content-between
                    px-4
                  "
                >
                  {{ todo.label }}
                  <img
                    src="../assets/whiteX.svg"
                    alt=""
                    style="cursor: pointer; margin-top: 1px"
                  />
                </div>
              </button>
            </div>
          </div>
          <p
            style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
            class="ms-1 mt-1 mb-0"
          >
            讓您將特定聯絡人從選定的名單中排除。
          </p>
        </div>
      </div>
      <hr
        class="m-0"
        style="
          width: 100%;
          opacity: 1;
          height: 0;
          border: 0;
          border-bottom: 1px solid #c4c4c4;
        "
      />
      <div class="p-4">
        <p style="color: #71afb6">實際的投放人數</p>
        <div class="d-flex align-items-center mt-4 justify-content-between">
          <div class="d-flex align-items-center">
            <img src="../assets/peopleOrange.svg" style="z-index: 10" />
            <span class="progress-span"
              >{{ numbers }}人({{ Number(percentBars).toFixed(2) }}%)</span
            >
            <progress
              class="top-progress"
              :value="percentBars"
              max="100"
            ></progress>
          </div>
          <span style="font-size: 20px"
            >會員總數&nbsp;&nbsp;{{ members }}&nbsp;&nbsp;人</span
          >
        </div>
      </div>
    </div>

    <div class="contain-top mt-4">
      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="mb-3" style="color: #71afb6">
          寄件人資料設定

          <BtnInfoBox
            content="請先設定寄件人資訊，用於自動化劇本的寄件人Email帳號與電話號碼。"
            direction="right"
          ></BtnInfoBox>
        </div>
        <div class="d-flex align-items-center col-12 mb-3">
          <span style="margin-right: 20px; width: 140px"
            >寄件人名稱
            <BtnInfoBox
              content="運用容易辨識的名稱，例如您的公司名。"
              direction="right"
            ></BtnInfoBox>
          </span>
          <div class="search-container flex-fill">
            <input
              type="text"
              style="border-radius: 10px"
              name="add-input"
              class="form-control"
              v-model="sender_name"
            />
          </div>
        </div>
        <div style="margin-right: 20px; width: 140px">寄件人Email</div>
        <div
          class="search-container flex-fill"
          style="position: relative"
          ref="target"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            style="
              border: 1px solid #c4c4c4;
              border-radius: 10px;
              padding: 0 14px;
              height: 35px;
            "
            @click="autoShow = !autoShow"
          >
            {{ selectEmail }}
            <img src="../assets/selection-arrow.png" alt="searchImg" />
          </div>
          <div
            class="auto-complicate-div"
            style="width: 100%"
            v-show="autoShow"
            @mouseenter="autoShow = true"
          >
            <p
              class="m-0 p-1 ps-3 auto-complicate-p"
              :value="item"
              v-for="item in emailList"
              :key="item"
              @click="
                selectEmail = item;
                autoShow = false;
              "
            >
              {{ item }}
            </p>

            <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div style="margin-right: 20px; width: 140px">寄件人手機號碼</div>
        <div
          class="search-container flex-fill"
          style="position: relative"
          ref="target2"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            style="
              border: 1px solid #c4c4c4;
              border-radius: 10px;
              padding: 0 14px;
              height: 35px;
            "
            @click="autoShow2 = !autoShow2"
          >
            {{ selectSms }}
            <img src="../assets/selection-arrow.png" alt="searchImg" />
          </div>
          <div
            class="auto-complicate-div"
            style="width: 100%"
            v-show="autoShow2"
            @mouseenter="autoShow2 = true"
          >
            <p
              class="m-0 p-1 ps-3 auto-complicate-p"
              v-for="(item, index) in smsList"
              :key="index"
              @click="
                this.selectSms = index;
                this.realselectSms = item;
                autoShow2 = false;
              "
            >
              {{ index }}
            </p>

            <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mt-3" style="margin-left: 170px">
        若有需要新增寄件人Email或手機號碼，請洽詢專案服務人員。
      </div>
    </div>
    <div class="text-center">
      <button
        class="mt-4 basic-btn"
        style="
          width: 30%;
          background-image: linear-gradient(to bottom right, #71afb6, #b5dce0);
          color: white;
        "
        @click="goScript"
      >
        下一步
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { Calendar, DatePicker } from "v-calendar";
import Paginator from "primevue/paginator";
import BtnInfoBox from "../components/BtnInfoBox.vue";

export default {
  name: "TriggerScript",
  components: {
    Paginator,
    DatePicker,
    BtnInfoBox,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);
    const autoShow2 = ref(false);
    const target2 = ref(null);
    const autoShow3 = ref(false);
    const target3 = ref(null);
    const autoShow4 = ref(false);
    const target4 = ref(null);
    const target5 = ref(null);
    const popout = ref(null);

    onClickOutside(target, () => (autoShow.value = false));
    onClickOutside(target2, () => (autoShow2.value = false));
    onClickOutside(target3, () => (autoShow3.value = false));
    onClickOutside(target4, () => (autoShow4.value = false));
    onClickOutside(target5, () => (popout.value = false));

    return {
      target,
      autoShow,
      target2,
      autoShow2,
      target3,
      autoShow3,
      target4,
      autoShow4,
      target5,
      popout,
    };
  },
  data() {
    return {
      sender_name: "",
      percentBars: "",
      numbers: "- -",
      members: "- -",
      group: null,
      searchList: [],
      usedID: [],
      includeLabels: [],
      excludeLabels: [],
      combineLabelID: "",

      loading: false,
      notshow: false,

      trigger_explain: false,
      list_explain: false,
      page: 1,
      perPage: 8,
      records: [],
      // searchTodos: "",
      searchInput: "",
      selectedID: "",
      todos: [],
      RFMtodos: [],
      customtodos: [],
      originUploadLabels: [],
      emailList: [],
      smsList: [],

      title: "",
      selectGroup: "",
      selectCustomGroup: "價值分群",
      random_id: "",
      scriptID: "",
      selectEmail: "",
      selectSms: "",
      realselectSms: "",
      groupName: "",

      day: null,
    };
  },
  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.todos.slice(startIndex, endIndex);
    },
    selectedLabels() {
      return this.includeLabels.concat(this.excludeLabels);
    },
  },
  watch: {
    selectGroup: function (val) {
      console.log(val);
      if (val === "現有顧客+未來新加入名單") {
        this.getlabelID("all");
        this.groupName = "現有顧客+未來新加入名單";
      } else if (val === "針對現有顧客名單") {
        this.getlabelID("now");
        this.groupName = "針對現有顧客名單";
      } else if (val === "針對未來新加入名單") {
        this.getlabelID("new");
        this.groupName = "針對未來新加入名單";
      }
    },
    selectCustomGroup: function (val) {
      console.log(val);
      if (val === "價值分群") {
        this.todos = this.RFMtodos;
      } else if (val === "個性化分群") {
        this.todos = this.customtodos;
      } else if (val === "自訂分群") {
        this.todos = this.originUploadLabels;
      }
    },
    searchInput: function (val) {
      if (this.selectCustomGroup === "價值分群") {
        if (val === "") {
          this.selectCustomGroup = this.RFMtodos;
        } else {
          this.todos = this.todos.filter((d) => d.label.includes(val));
        }
      } else if (this.selectCustomGroup === "個性化分群") {
        if (val === "") {
          this.todos = this.customtodos;
        } else {
          console.log(val);
          this.todos = this.todos.filter((d) => d.label.includes(val));
        }
      } else if (val === "自訂分群") {
        if (val === "") {
          this.todos = this.originUploadLabels;
        } else {
          console.log(val);
          this.todos = this.todos.filter((d) => d.label.includes(val));
        }
      }
    },
  },
  created: async function () {
    if (sessionStorage.getItem("lv") == "0") {
      this.notshow = 0;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "1") {
      this.notshow = 1;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "2") {
      this.notshow = 2;
      sessionStorage.removeItem("lv");
    }
    this.loading = true;
    // this.random_id = "ReMA_Trigger_" + this.randomString(8);
    this.getRFM();
    this.getRFMcustom();
    this.getUploadLabelList();
    await this.getSmsList();
    await this.getEmailList();
    if (sessionStorage.getItem("fromScript") === "true") {
      this.scriptID = sessionStorage.getItem("setResScriptId");
      this.getScriptById();
    } else {
      this.getscriptID();
    }
  },
  methods: {
    // 拿所有自訂分群labels
    getUploadLabelList: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .uploadLabelGet(params)
        .then((res) => {
          console.log("uploadLabelList", res);

          res.data.map((d) => {
            d.create_time = d.time;
            d.time = new Date(d.time).toLocaleString();
            d.label_type = "自訂分群";
          });
          this.originUploadLabels = res.data.filter(
            (d) =>
              !d.status.includes("處理中") || !d.status.includes("上傳失敗_")
          );
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    sayabc() {
      console.log("abc");
    },
    onPage(event) {
      this.page = event.page + 1;
    },
    getInfoByCombineLabelID: async function (id) {
      console.log("this.group", this.selectedName);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        label_id: id,
      };
      await allapi2
        .customlabelMarketingLabelIdGet(params)
        .then((res) => {
          console.log("根據合成後的label_id拿包含不包含", res);
          const includeArr = res.data.message.Data.content.include;
          if (includeArr[0] != undefined) {
            for (let index = 0; index < includeArr.length; index++) {
              const include_label = {};
              include_label.label_id = includeArr[index];
              include_label.label = this.selectedName.includeLabelNames[index];
              this.includeLabels.push(include_label);
              this.usedID.push(includeArr[index]);
            }
          }
          const excludeArr = res.data.message.Data.content.exclude;
          if (excludeArr[0] != undefined) {
            for (let index = 0; index < excludeArr.length; index++) {
              const exclude_label = {};
              exclude_label.label_id = excludeArr[index];
              exclude_label.label = this.selectedName.excludeLabelNames[index];
              this.excludeLabels.push(exclude_label);
              this.usedID.push(excludeArr[index]);
            }
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    checkCombineLabelMemberCount: async function () {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      console.log(include_ids, exclude_ids);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
        type: "email",
      };

      await allapi2
        .customlabelMarketingRatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的人數", res);
          this.percentBars = res.data.message.Data.ratio;
          this.numbers = res.data.message.Data.count;
          this.members = res.data.message.Data.total;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getCombineLabelID: async function () {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      var body = {};
      if (this.selectGroup === "自建名單") {
        body = {
          content: { include: include_ids, exclude: exclude_ids },
        };
      } else {
        body = {
          content: { include: [this.selectedID], exclude: exclude_ids },
        };
      }

      await allapi2
        .customlabelMarketingCreatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的label_id", res);
          this.combineLabelID = res.data.message.Data.label_id;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("combinID有誤");
        });
    },
    gotable() {
      this.$router.push({
        name: "ScriptTable",
      });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    getRFM: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelAilabelGet(params)
        .then((res) => {
          console.log(res);
          this.RFMtodos = res.data.message.Data;
          this.todos = this.RFMtodos;
          this.searchList = this.todos;

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getRFMcustom: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelListGet(params)
        .then((res) => {
          console.log(res);
          this.customtodos = res.data.message.Data;
          this.customtodos.sort((a, b) => {
            return b.time - a.time;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getEmailList: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .userEmaillistGet(params)
        .then((res) => {
          console.log(res);
          this.emailList = res.data.maillist;
          this.selectEmail = this.emailList[0];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getSmsList: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .userSmslistGet(params)
        .then((res) => {
          console.log(res);
          this.smsList = res.data.smslist;
          console.log(Object.keys(this.smsList)[0]);
          this.selectSms = Object.keys(this.smsList)[0];
          this.realselectSms = Object.values(this.smsList)[0];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getscriptID() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      allapi2
        .triggerScriptScriptIdGet(params)
        .then((res) => {
          console.log(res);
          this.scriptID = res.data.script_id;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getlabelID(state) {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        state: state,
      };

      allapi2
        .triggerScriptCreateLabelPost(params, body)
        .then((res) => {
          console.log(res);
          this.selectedID = res.data.label_id;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getScriptById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.scriptID,
      };

      await allapi2
        .triggerScriptScriptIdGet_1(params)
        .then(async (res) => {
          console.log("成功根據排成獲得劇本內容", res);
          this.title = res.data.message.script_name;

          if (res.data.message.email_sender.includes("_")) {
            this.selectEmail = res.data.message.email_sender.split("_")[1];
            this.sender_name = res.data.message.email_sender.split("_")[0];
          } else {
            this.selectEmail = res.data.message.email_sender;
            this.sender_name = "";
          }

          this.realselectSms = res.data.message.sms_sender;
          const index = Object.values(this.smsList).findIndex(
            (d) => d === this.realselectSms
          );
          this.selectSms = Object.keys(this.smsList)[index];

          if (
            res.data.message.label_name.includeLabelNames[0] ===
            "現有顧客+未來新加入名單"
          ) {
            this.selectGroup = "現有顧客+未來新加入名單";
          } else if (
            res.data.message.label_name.includeLabelNames[0] ===
            "針對現有顧客名單"
          ) {
            this.selectGroup = "針對現有顧客名單";
          } else if (
            res.data.message.label_name.includeLabelNames[0] ===
            "針對未來新加入名單"
          ) {
            this.selectGroup = "針對未來新加入名單";
          } else {
            this.selectGroup = "自建名單";
            this.selectedName = res.data.message.label_name;
            await this.getInfoByCombineLabelID(res.data.message.label_id);
            this.checkCombineLabelMemberCount();
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    addLabelsToIncludes(todo) {
      if (this.selectGroup === "測試人員") {
        this.getTestMember();
      } else {
        const ids = this.includeLabels.map((d) => {
          return d.labelId || d.label_id;
        });
        const hasid = ids.findIndex((d) => {
          return d === todo.label_id || d === todo.labelId;
        });

        console.log(ids);
        console.log(hasid);
        if (hasid != -1) {
          return;
        }
        this.includeLabels.push(todo);
        if (todo.label_id != undefined) {
          this.usedID.push(todo.label_id);
        } else if (todo.labelId != undefined) {
          this.usedID.push(todo.labelId);
        }

        console.log("this.usedID", this.usedID);
        console.log(this.includeLabels);
        this.checkCombineLabelMemberCount();
      }
    },
    addLabelsToExcludes(todo) {
      const ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const hasid = ids.findIndex((d) => {
        return d === todo.label_id || d === todo.labelId;
      });

      console.log(ids);
      console.log(hasid);
      if (hasid != -1) {
        return;
      }
      this.excludeLabels.push(todo);
      if (todo.label_id != undefined) {
        this.usedID.push(todo.label_id);
      } else if (todo.labelId != undefined) {
        this.usedID.push(todo.labelId);
      }

      console.log(this.excludeLabels);
      this.checkCombineLabelMemberCount();
    },
    deleteLabelsFromIncludes(todo) {
      console.log(todo);
      this.includeLabels = this.includeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      this.checkCombineLabelMemberCount();
    },
    deleteLabelsFromExcludes(todo) {
      console.log(todo);
      this.excludeLabels = this.excludeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      this.checkCombineLabelMemberCount();
    },
    goScript: async function () {
      const include_names = this.includeLabels.map((d) => {
        return d.label;
      });
      const exclude_names = this.excludeLabels.map((d) => {
        return d.label;
      });

      if (this.selectGroup === "自建名單") {
        await this.getCombineLabelID();

        const labelsName = {
          includeLabelNames: include_names,
          excludeLabelNames: exclude_names,
        };
        sessionStorage.setItem("triggerLabel", this.combineLabelID);
        sessionStorage.setItem("triggerGroupName", JSON.stringify(labelsName));
      } else {
        await this.getCombineLabelID();
        sessionStorage.setItem("triggerLabel", this.combineLabelID);
        sessionStorage.setItem(
          "triggerGroupName",
          JSON.stringify({
            includeLabelNames: [this.groupName],
            excludeLabelNames: exclude_names,
          })
        );
      }

      if (
        this.selectGroup === "自建名單" &&
        this.includeLabels[0] === undefined
      ) {
        return alert("不能沒選擇分群名單");
      } else if (this.selectGroup === "") {
        return alert("不能沒選擇分群名單");
      } else if (this.selectedID === "" && this.selectGroup != "自建名單") {
        return alert("分群ID資料有誤");
      }

      let sender_email = "";

      if (this.sender_name == "") {
        sender_email = this.selectEmail;
      } else {
        sender_email = `${this.sender_name}_${this.selectEmail}`;
      }

      sessionStorage.setItem("triggerEndtime", this.day);
      sessionStorage.setItem("triggerTitle", this.title);
      sessionStorage.setItem("triggerGroup", this.selectGroup);
      sessionStorage.setItem("setResScheduleId", this.scriptID);
      sessionStorage.setItem("setResScriptId", this.scriptID);
      sessionStorage.setItem("triggerEmail", sender_email);
      sessionStorage.setItem("triggerSms", this.realselectSms);
      this.$router.push({
        name: "AfterTriggerScript",
      });
    },
  },
};
</script>

<style scoped>
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.group-div-triggerScript {
  background-color: #eaf3f4;
  width: 200px;
  border-radius: 20px;
}
.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.form-container2 {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 20px;
}
.basic-btn {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}
.title-span {
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
  align-self: center;
}
.contain-top {
  padding: 20px 40px 25px 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
}
.input-style {
  width: 88%;
  height: 35px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
}
.child-popout-inTriggerScript1 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 430px;
  right: -445px;
  top: -53px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}
.child-popout-inTriggerScript2 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  max-width: 220px;
  right: -220px;
  top: -80px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}
.contain-bottom {
  padding: 20px 40px 25px 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 188px;
}
.todo-span-inTriggerScript {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search-input {
  border-radius: 5px;
  background-color: #eaf3f4;
  border: none;
  text-indent: 30px;
  width: 17rem;
  height: 37px;
}
.searchImg {
  position: relative;
  top: -2px;
  left: 24px;
}
.popout-hover:hover {
  background-color: #eaf3f4;
}
.popout-hover:hover:first-child {
  background-color: #eaf3f4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popout-hover:hover:last-child {
  background-color: #eaf3f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.disable {
  background-color: #eeeeee;
  color: #c4c4c4;
}
</style>
