<template>
  <div class="col-3">
    <button
      class="btn btn-default button col-11 col-xxl-11 px-0 mx-0"
      @click="emitToggleEvent(todo)"
      v-tooltip.bottom="todo.name.length>7?{
        value: todo.name,
        class: 'tip-LabelManage',
      }:{
        value: '',
        class: '',
      }"
      :class="{ highlight: todo.key === selectedID }"
      @mouseleave="mouseLeave"
    >
      <div class="todo-span">{{ todo.name }}</div>
      <button class="del-btn" v-on:click.stop="show()">
        
        <img src="../../assets/white3spot.svg" v-if="todo.key === selectedID" />
        <img src="../../assets/spot.svg" v-else />
        <div class="popout" v-show="isShow">
          <p class="p-hover-up m-0 py-1" @click.stop="rename(todo)">
            <img src="../../assets/rename.svg" />
            重新命名
          </p>
          <p class="p-hover-mid m-0 py-1" @click.stop="copy(todo)">
            <img src="../../assets/copy.svg" />
            複製
          </p>
          <p class="p-hover-bot m-0 py-1" v-on:click.stop="removeTodo(todo)">
            <img src="../../assets/delete-black.svg" />
            刪除
          </p>
        </div>
      </button>
    </button>
  </div>
</template>

<script>
export default {
  props: ["todo", "selectedID"],
  name: "ProductFavNameList",

  data() {
    return {
      isShow: false,
      // highlight:false,
      beClicked: "",
    };
  },
  // watch: {
  //   beClick : this.todo.id,
  // },

  methods: {
    mouseLeave() {
      if (this.isShow) {
        this.isShow = !this.isShow;
      }
    },
    show() {
      this.isShow = !this.isShow;
    },
    rename(todo) {
      console.log(todo);
      this.$emit("showrename-todo", todo);

      // this.patchTemplateApi(todo)
    },
    patchTemplateApi: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
        name: todo.name,
        json: todo.json,
        html: todo.html,
      };

      await allapi2
        .templatev2TempInfoPatch(params, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
      await this.getTemplate();
      const template = this.templates.find((d) => {
        return d.name === this.name;
      });
      this.key = template.key;
      console.log("this.key", this.key);
      console.log("this.goSet", this.goSet);
      if (this.goSet === true && this.key != "undefined") {
        window.location.href = `http://localhost:8080/EmailSetting?key=${this.key}`;
      } else {
        alert("key出錯了");
      }
    },
    copy(todo) {
      this.$emit("copy-todo", todo);
    },
    removeTodo(todo) {
      this.$emit("remove-todo", todo);
    },
    emitToggleEvent(todo) {
      this.$emit("toggle-value", todo);
    },
  },
};
</script>


<style scoped>
.todo-span {
  display: inline-block;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.container-fluid {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 30px;
  height: 17rem;
}
.section-title {
  color: #71afb6;
}
.form-control {
  border: none;
  background-color: #eaf3f4;
}
.favorite-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.button {
  /* width: 14rem; */
  /* padding: 10px 70px; */
  height: 40px;
  margin: 12.5px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
  position: relative;
}
.del-btn {
  background-color: inherit;
  background: transparent;
  border: none;
  height: 40px;
  position: absolute;
  right: 10px;
  color: #c4c4c4;
}
.del-btn img {
  position: relative;
  top: -10px;
}
.popout {
  background-color: #fff;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 150px;
  right: -110px;
  top: 25px;
  color: #575757;
  text-align: left;
}
.popout img {
  position: relative;
  width: 13px;
  top: -2px;
  margin: 0px 13px 0px 20px;
}
.p-hover-up:hover {
  border-radius: 10px 10px 0 0;
  background-color: #eaf3f4;
  color: #575757;
}
.p-hover-mid:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.p-hover-bot:hover {
  border-radius: 0 0 10px 10px;
  background-color: #eaf3f4;
  color: #575757;
}
.tip {
  background-color: #ababab;
  border: 1px solid #ababab;
  border-radius: 10px;
  color: white;
  width: 300px;
}
.tip:after {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 100%;
  width: 0;
}
.tip:after,
.tip:before {
  opacity: 1;
  pointer-events: none;
  border: 6px solid transparent;
  border-top-color: #ababab;
  bottom: -35%;
  left: 50%;
  position: absolute;
  height: 0;
  width: 0;
  z-index: 5000;
}
</style>