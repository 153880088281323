<template>
  <div v-if="notshow" class="d-flex justify-content-center align-items-center" style="height:100%">
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <!-- upload sucess modal -->
    <div
      class="modal fade"
      id="alertModalSuccess"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">上傳成功!</div>
          <div
            class="modal-footer alert-modal-footer"
            style="background-color: "
          >
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <span class="title-span fs-medium">
        活動主題比較 (FB Ads)
        <BtnInfoBox content="此功能提供您投放過後的活動並進行比較，按下「+」按鈕即可針對同一主題活動 (例如:聖誕節) 快速判斷其表現。" direction="right"></BtnInfoBox>

      </span>
      <div class="search-container">
        <img class="searchImg" src="../assets/search.svg" alt="searchImg" />
        <input
          type="text"
          class="search-input"
          placeholder="搜尋名稱"
          v-model="searchTemName"
        />
      </div>
    </div>
    <div class="row">
      <div class="template-container">
        <p style="color: #71afb6; margin-left: 1rem">篩選結果</p>

        <div style="margin-left: 1rem" v-if="campaignTemplate.length === 0">
          <p>查無搜尋結果。</p>
        </div>

        <div class="container-flexdriection" v-else>
          <button
            style="border: none"
            class="template-item-div d-flex"
            v-for="(item, index) in searchtemplateData"
            :key="index"
            @click="addToTable(item.campaign_id)"
          >
            {{ item.campaign_name }}
            <img
              src="../assets/template-plus-icon.svg"
              style="cursor: pointer"
            />
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 p-0">
        <div class="view">
          <div class="wrapper">
            <table class="table" id="table">
              <thead>
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <th
                    @mouseenter="this.underline = header"
                    @mouseleave="this.underline = ''"
                    :class="this.underline === header ? 'addUnderline' : ''"
                    style="cursor: grab"
                    :style="grabbing ? 'cursor: grabbing' : ''"
                    v-for="header in headers"
                    :key="header"
                    scope="col"
                    :draggable="true"
                    @dragenter="this.underline = header"
                    @dragover="allowDrop"
                    @dragstart="dragStart($event)"
                    @dragleave="removeclass($event, header)"
                    @drop="dropEvent($event)"
                    @mousedown="this.grabbing = true"
                    @mouseup="this.grabbing = false"
                  >
                    <div
                      class="d-flex align-items-center"
                      style="
                        min-width: 40px;
                        border-bottom: none;
                        padding: 0px;
                        display: flex;
                        align-items: center;
                      "
                      v-if="header != '刪除'"
                    >
                      <span
                        style="min-width: 36px; border-bottom: none"
                        :style="
                          header === this.currentKey ? 'color:#4197B9' : ''
                        "
                      >
                        {{ header }}
                      </span>
                      <img
                        :draggable="false"
                        class="sort-img ms-1"
                        style="
                          cursor: pointer;
                          height: 20px;
                          border-bottom: none;
                          margin-right: 5px;
                        "
                        src="../assets/green-up-arrow.png"
                        alt=""
                        @click="sortingButton($event)"
                        v-if="
                          header === this.currentKey && this.issort === false
                        "
                      />
                      <img
                        :draggable="false"
                        class="sort-img ms-1"
                        style="
                          cursor: pointer;
                          height: 20px;
                          border-bottom: none;
                          margin-right: 5px;
                        "
                        src="../assets/green-down-arrow.png"
                        alt=""
                        @click="sortingButton($event)"
                        v-if="
                          header === this.currentKey && this.issort === true
                        "
                      />
                      <img
                        :draggable="false"
                        class="sort-img ms-1"
                        style="
                          cursor: pointer;
                          height: 20px;
                          border-bottom: none;
                          margin-left: 5px;
                        "
                        src="../assets/sort-black.png"
                        alt=""
                        @click="sortingButton($event)"
                        v-if="header != this.currentKey"
                      />
                    </div>
                  </th>
                </transition-group>
              </thead>
              <tbody>
                <tr v-for="(item, index1) in searchlist" :key="index1">
                  <td v-for="(header, index) in headers" :key="index">
                    <div
                      class="d-flex flex-column cost-div align-items-end"
                      v-if="header === '成果與成果次數'"
                    >
                      <div v-if="item[item.目標] != undefined">
                        {{ item[item.目標] }}
                      </div>
                      <div
                        v-else-if="
                          item[item.目標] === undefined &&
                          item.成果與成果次數[item.成果名稱] != undefined
                        "
                      >
                        {{ item.成果與成果次數[item.成果名稱] }}
                      </div>
                      <div v-else>{{ item.觸及人數 }}</div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                        style="min-width: 140px"
                      >
                        <img
                          src="../assets/show-result.png"
                          alt=""
                          @mouseover="(item.觸及人數 != '-' && item[header] != {}) ? show(index1) : ''"
                          @mouseout="this.rowindex = ''"
                        />
                        <div class="parent-popout font-14">
                          <div
                            v-show="this.rowindex === index1"
                            class="child-popout8"
                          >
                            <div>
                              <div
                                class="d-flex flex-column align-items-end"
                                v-for="(val, index) in Object.values(
                                  item[header]
                                )"
                                :key="index"
                              >
                                {{ val }}
                                <div style="font-size: 14px">
                                  {{ Object.keys(item[header])[index] }}
                                </div>
                              </div>
                            </div>
                            <div class="container__arrow"></div>
                          </div>
                        </div>
                        <div
                          style="font-size: 14px; color: gray"
                          v-if="item[item.目標] != undefined"
                        >
                          {{ item.目標 }}
                        </div>
                        <div
                          style="font-size: 14px; color: gray"
                          v-else-if="
                            item[item.目標] === undefined &&
                            item.成果與成果次數[item.成果名稱] != undefined
                          "
                        >
                          {{ item.成果名稱 }}
                        </div>
                        <div style="font-size: 14px; color: gray" v-else>
                          觸及人數
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column align-items-end"
                      v-else-if="header === '刪除'"
                    >
                      <button
                        style="border: none; background-color: white"
                        @click="deleteList(index1, item)"
                      >
                        <img src="../assets/green-delete.svg" alt="" />
                      </button>
                    </div>
                    <div
                      class="d-flex flex-column cost-div align-items-end"
                      v-else-if="header === '每次成果成本'"
                    >
                      <div>NT${{ item[header] }}</div>
                      <div style="font-size: 14px; color: gray">
                        {{ item.成果名稱 }}
                      </div>
                    </div>
                    <span v-else>
                      {{ item[header] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <button @click="shuffle()">te4st</button> -->

        <div class="row justify-content-between">
          <div class="chart-container">
            <p style="color: #71afb6">曝光</p>
            <apexchart
              class="mt-2"
              type="bar"
              height="280"
              :options="chartOptions"
              :series="seriesBar"
            ></apexchart>
          </div>

          <div class="chart-container">
            <p style="color: #71afb6">點擊</p>
            <apexchart
              class="mt-2"
              type="bar"
              height="280"
              :options="chartOptions2"
              :series="seriesBar2"
            ></apexchart>
          </div>

          <div class="chart-container">
            <p style="color: #71afb6">CTR</p>
            <apexchart
              class="mt-2"
              type="bar"
              height="280"
              :options="chartOptions3"
              :series="seriesBar3"
            ></apexchart>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="chart-container">
            <p style="color: #71afb6">CPC</p>
            <apexchart
              class="mt-2"
              type="bar"
              height="280"
              :options="chartOptions4"
              :series="seriesBar4"
            ></apexchart>
          </div>

          <div class="chart-container">
            <p style="color: #71afb6">CPM</p>
            <apexchart
              class="mt-2"
              type="bar"
              height="280"
              :options="chartOptions5"
              :series="seriesBar5"
            ></apexchart>
          </div>

          <div class="chart-container">
            <p style="color: #71afb6">花費</p>
            <apexchart
              class="mt-2"
              type="bar"
              height="280"
              :options="chartOptions6"
              :series="seriesBar6"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import DropdownAll from "../components/DropdownAll.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "FbThemeAnal",
  display: "Table Column",
  order: 9,
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    BtnInfoBox,
  },
  data() {
    return {
      isShowTrend: false,
      underline: false,
      drag: false,
      notshow: false,
      vue3Date: {
        start: new Date("2022-01-06").toISOString().slice(0, 10),
        end: new Date("2022-01-10").toISOString().slice(0, 10),
      },

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      campaignTemplate: [],

      // region: 'ap-northeast-1',
      //             apiKey: 'ble92Y0z8E8PjdDEvHjhQ3KIt05khYnpajVVaKEO',
      searchtemplateData: [],

      headers: [
        "活動名稱",
        "目標",
        "曝光次數",
        "點擊",
        "CTR(全部)",
        "CPM",
        "CPC",
        "花費",
        "成果與成果次數",
        "每次成果成本",
        "刪除",
      ],
      list: [
        {
          CPC: 0.1,
          CPM: 1.2,
          "CTR(全部)": 0.2,
          目標: "連結點擊",
          廣告組合: "電器買五送一加碼大優惠",
          成果與成果次數: { ssss: 1230 },
          每次成果成本: "0.88",
          排程: "Sun, 13 Feb 2022 00:00:00 GMT",
          曝光次數: 897,
          活動名稱: "黑五特賣會會會會",
          狀態: "進行中",
          花費: 875,
          觸及人數: 230,
          預算: 23,
          頻率: 1.52,
          點擊: 54,
        },
      ],

      seriesBar: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 10,
            // dataLabels: {
            //   position: 'bottom', // top, center, bottom
            // },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (y) {
              return y + "(次)";
            },
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      seriesBar2: [
        {
          name: "點擊",
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            width: "50%",
            borderRadius: 10,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,

          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (y) {
              return y + "(次)";
            },
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      seriesBar3: [
        {
          name: "CTR",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            width: "50%",
            borderRadius: 10,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,

          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (y) {
              return y.toFixed(2) + "%";
            },
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      seriesBar4: [
        {
          name: "CTR",
          data: [],
        },
      ],
      chartOptions4: {
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            width: "50%",
            borderRadius: 10,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,

          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (y) {
              return "NT$" + y;
            },
            style: {
              fontSize: "12px",
            },
          },
        },
        title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
      seriesBar5: [
        {
          name: "CTR",
          data: [],
        },
      ],
      chartOptions5: {
        chart: {
          height: 350,
          type: "bar",
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            width: "50%",
            borderRadius: 10,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,

          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (y) {
              return "NT$" + y;
            },
            style: {
              fontSize: "12px",
            },
          },
        },
        title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
      seriesBar6: [
        {
          name: "CTR",
          data: [],
        },
      ],
      chartOptions6: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            width: "50%",
            borderRadius: 10,
            dataLabels: {
              position: "bottom", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,

          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (y) {
              return "NT$" + y;
            },
            style: {
              fontSize: "12px",
            },
          },
        },
        title: {
          text: "",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },

      searchTemName: [],
      selectedData: "",

      searchlist: [],
      issort: false,
      oldNum: "",
      newNum: "",

      currentKey: "",
      rowindex: "",
      grabbing: false,
    };
  },
  watch: {
    searchTemName: function () {
      console.log("temtem", this.campaignTemplate);
      if (this.searchTemNam === "") {
        this.searchtemplateData = this.campaignTemplate;
      } else {
        this.searchTemplate(this.searchTemName);
      }
    },
  },
  created() {
    // http://localhost:8080 test
    if (sessionStorage.getItem("lv") === "test") {
      this.notshow = true;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "laurel") {
      this.notshow = true;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "dev") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "bd") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    }
    // console.log("v-ifseac", this.searchtemplateData);
    if(!this.notshow){
      this.getCampaignName();
    }
    // this.getCampaignData()
  },
  methods: {
    selectValueByDrop(selectValue, changingSelected) {
      this[changingSelected] = selectValue;
    },

    show(index1) {
      this.rowindex = index1;
    },
    getCampaignName() {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        // labelId: this.childtodo.labelId,
      };
      allapi3
        .facebookCompareGet(params)
        .then((res) => {
          console.log("fb1", res);
          this.campaignTemplate = res.data.Data;
          this.searchtemplateData = res.data.Data;
          console.log("fb", this.campaignTemplate);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    addToTable: async function (e) {
      // this.getCampaignData(e)
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        campaign_id: e,
      };
      await allapi3
        .facebookCompareCampaignIdGet(params)
        .then((res) => {
          console.log("fbdata", res);
          this.selectedData = res.data.Data;
          //  console.log('try', this.selectedData[0].clicks)
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });

      this.searchlist.push(this.selectedData[0]);
      this.seriesBar[0].data.push(this.selectedData[0].曝光次數);
      this.seriesBar2[0].data.push(this.selectedData[0].點擊);
      this.seriesBar3[0].data.push(this.selectedData[0]["CTR(全部)"]);
      this.seriesBar4[0].data.push(this.selectedData[0].CPC);
      this.seriesBar5[0].data.push(this.selectedData[0].CPM);
      this.seriesBar6[0].data.push(this.selectedData[0].花費);
      this.chartOptions.xaxis.categories.push(this.selectedData[0].活動名稱);
      this.chartOptions2.xaxis.categories.push(this.selectedData[0].活動名稱);
      this.chartOptions3.xaxis.categories.push(this.selectedData[0].活動名稱);
      this.chartOptions4.xaxis.categories.push(this.selectedData[0].活動名稱);
      this.chartOptions5.xaxis.categories.push(this.selectedData[0].活動名稱);
      this.chartOptions6.xaxis.categories.push(this.selectedData[0].活動名稱);

      // console.log("list", this.searchlist);
      // console.log("aa",this.searchlist[0])
      // // console.log("list", Object.keys(e.jan));
      // console.log("opiton", this.chartOptions.xaxis.categories);
      // console.log(e);
      // console.log("kan", e.jan);
    },

    sortingButton(e) {
      this.issort = !this.issort;
      console.log(e.target.parentNode.innerText.trim());
      const key = e.target.parentNode.innerText.trim();
      if (this.currentKey != key) {
        this.issort = true;
      }
      this.currentKey = key;

      if (key === "活動名稱") {
        if (this.issort) {
          this.searchlist.sort((a, b) => {
            return b.活動名稱.length - a.活動名稱.length;
          });
        } else {
          this.searchlist.sort((a, b) => {
            return a.活動名稱.length - b.活動名稱.length;
          });
        }
      } else if (key === "目標") {
        if (this.issort) {
          this.searchlist.sort((a, b) => {
            return b.活動名稱.length - a.活動名稱.length;
          });
        } else {
          this.searchlist.sort((a, b) => {
            return a.活動名稱.length - b.活動名稱.length;
          });
        }
      } else {
        if (this.issort) {
          this.searchlist.sort((a, b) => {
            return b[key] - a[key];
          });
        } else {
          this.searchlist.sort((a, b) => {
            return a[key] - b[key];
          });
        }
      }
    },
    shuffle() {
      this.list = _.shuffle(this.list);
    },

    allowDrop(e) {
      e.preventDefault();
    },
    dragStart(e) {
      let tar = e.target.innerText;
      console.log("dragststrty", e);
      console.log(tar);
      e.dataTransfer.setData("Text", tar);
      e.target.className = "dragclass";
    },
    removeclass(e, header) {
      e.target.className = "addUnderline";
      this.underline = header;
      console.log("e.target", e.target.innerText === "");
      this.grabbing = false;
    },

    dropEvent(e) {
      console.log("drop", e);

      this.allowDrop(e);

      const fixDragText = e.dataTransfer.getData("Text").trim();

      const dragText = fixDragText;

      const newheaders = this.headers.slice(2, 20);

      const haveheader = newheaders.some((d) => {
        return d === dragText;
      });
      const not12 = newheaders.some((d) => {
        return d === e.target.innerText.trim();
      });

      if (haveheader && not12) {
        const dropindex = this.headers.findIndex((d) => {
          return d === e.target.innerText.trim();
        });
        const dragindex = this.headers.findIndex((d) => {
          return d === dragText;
        });

        this.headers[dropindex] = dragText;
        // table.rows.style.color = "white";
        this.headers[dragindex] = e.target.innerText.trim();
      }
      // if(this.oldNum != this.newNum){
      //   let oldIndex = this.list.indexOf(this.oldNum);
      //   let newIndex = this.list.indexOf(this.newNum);
      //   let newItems =[...this.list];

      //   newItems.splice(oldIndex,1);
      //   newItems.splice(newIndex, 0, this.oldNum);
      //   this.list = [...newItems]
      // }
      this.grabbing = false;
    },
    searchTemplate(val) {
      console.log("1", this.campaignTemplate);
      console.log("sesrcvhtest", val);
      const searched = this.campaignTemplate.filter((d) =>
        d.campaign_name.includes(val)
      );
      this.searchtemplateData = searched;
      console.log("this.searchtemplateData", this.searchtemplateData);
    },
    deleteList(index1, item) {
      console.log(index1);
      this.searchlist.splice(index1, 1);

      this.seriesBar[0].data.splice(index1, 1);
      this.seriesBar2[0].data.splice(index1, 1);
      this.seriesBar3[0].data.splice(index1, 1);
      this.seriesBar4[0].data.splice(index1, 1);
      this.seriesBar5[0].data.splice(index1, 1);
      this.seriesBar6[0].data.splice(index1, 1);
      this.chartOptions.xaxis.categories.splice(index1, 1);
      this.chartOptions2.xaxis.categories.splice(index1, 1);
      this.chartOptions3.xaxis.categories.splice(index1, 1);
      this.chartOptions4.xaxis.categories.splice(index1, 1);
      this.chartOptions5.xaxis.categories.splice(index1, 1);
      this.chartOptions6.xaxis.categories.splice(index1, 1);
    },
  },
};
</script>
<style scoped>
/* FbThemeAnal */
.flip-list-move {
  transition: transform 0.5s;
}
.buttons {
  margin-top: 35px;
}
.home {
  padding-top: 55px;
}
.view {
  padding: 15px;
  margin: auto;
  width: 100%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.wrapper {
  position: relative;
  overflow: auto;
  /* border: 1px solid black; */
  white-space: nowrap;
  height: 400px;
  /* box-shadow: 10px 0 5px -2px #888; */
  transition: transform 1s;
}
.table {
  text-align: center;
  transition: transform 1s;
  vertical-align: middle;
}
td:nth-last-child(1) {
  position: -webkit-sticky;
  position: sticky;
  right: 0px;
  min-width: 50px;
  max-width: 50px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 99;
  white-space: normal;
}
th:nth-last-child(1) {
  position: -webkit-sticky;
  position: sticky;
  right: 0px;
  min-width: 50px;
  max-width: 50px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 99;
  white-space: normal;
}
td:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  min-width: 120px;
  max-width: 120px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 99;
  white-space: normal;
}
th:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  min-width: 120px;
  max-width: 120px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 99;
}

th {
  border-bottom: 2px solid rgb(235, 230, 230);
  padding: 5px 15px;
  cursor: grab;
  transition: transform 1s;
  vertical-align: middle;
}
th,
td {
  border-right: 2px solid rgb(235, 230, 230);
  transition: transform 1s;
}
.table-header-custom:nth-child(1):hover,
.table-header-custom:nth-child(2):hover {
  border-bottom: 2px solid rgb(235, 230, 230);
}

.table-header-custom:hover {
  border-bottom: 2px solid #63a7c8;
  /* background-color: #63A7C8;*/
}
.table-header-custom.over {
  /* border-bottom: 5px solid #63A7C8; */
  color: red;
}
.addUnderline {
  border-bottom: 2px solid #63a7c8;
}
.dragclass {
  background-color: #eaf3f4;
}
.enddragclass {
  background-color: #fff;
}
.upload-button {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
}
.btn-lg {
  min-width: 34.5vw;
  font-size: 20px;
  border-radius: 10px;
}
.search-container {
  position: relative;
  right: 0;
  /* height:50px ; */
}
.template-container {
  /* flex-direction: row; */
  margin-top: 1rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  width: 100%;
}
.container-flexdriection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.template-item-div {
  display: flex;
  justify-content: space-between;
  width: 31%;
  padding: 10px;
  margin-top: 1rem;
  margin-left: 1rem;
  background-color: #eaf3f4;
  color: #71afb6;
  border-radius: 10px;
}
.search-input {
  background-color: #eaf3f4;
  border: none;
  border-radius: 10px;
  text-indent: 30px;
  height: 35px;
}
.searchImg {
  position: absolute;
  top: 8px;
  left: 15px;
}
.chart-container {
  margin-top: 2rem;
  width: 32%;
  height: 351px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: -23px;
  left: -20px;
  /* bottom: 0; */
  width: 120px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  top: 7px;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
.container__arrow1 {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  top: 20px;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
.child-popout9 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -45px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
</style>
