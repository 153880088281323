<template>
	<div class="info-pop" @mouseover="InfoShow = true" @mouseout="InfoShow = false">
		<button class="btn btn-default button px-0 mx-0 pt-0 ms-1 btn-info-pop">
			<div class="parent-popout">
				<div v-show="InfoShow"
					:class="'child-popout-' + direction"
					:style="
						'--l:' + (this.getLength(content) * -7) + 'px;' +
						'--l2:' + (this.getLength(content) * -18 - 40) + 'px;'"
				>
					<span class="font-14">{{ content }}</span>
					<div :class="{
						'container__arrow': direction == 'right',
						'container__arrow-inCustomLabel-trd': direction == 'top'
					}"></div>
				</div>
			</div>
		</button>
	</div>
</template>

<script>
export default {
  name: "BtnInfoBox",
  props: {
	content: String,
	direction: String,
  },
  data() {
	return {
		InfoShow: false,
	}
  },
  methods: {
	getLength(data) {
		data = data.toString()
		// console.log(data?.replace(/[^\x00-\xff]/g,"OO").length);
		return parseInt((data?.replace(/[^\x00-\xff]/g,"OO").length - 1) / 28);
	}
  }
};
</script>

<style scoped>
.parent-popout {
    height: 40px;
    left: -5px;
    top: -10px;
    width: 30px;
}
</style>