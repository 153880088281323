<template>
	<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 5000">
		<div class="modal-dialog email-box modal-dialog-centered">
			<div class="modal-content" style="height: 600px; width: 100%">
				<div class="modal-header alert-modal-header d-flex justify-content-end">
					<img src="../assets/whiteX.svg" alt="arrow" style="cursor: pointer" data-bs-dismiss="modal"/>
				</div>

				<div class="m-25">
					<div class="mb-3 ms-3">{{ subject || '(無主旨)' }}</div>
	
					<hr class="m-0 content-hr" />
	
				</div>
				<iframe class="content-data" height="560px" :srcdoc="html" frameborder="0" ></iframe>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "EmailPreview",
  props: {
	subject: String,
	html: null
  },
};
</script>

<style scoped>
.email-box {
	max-width: 1067px;
}

.modal-content {
  border-radius: 15px;
}

.alert-modal-header {
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  padding: 0.5rem 1rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.content-hr {
	width: 100%;
	opacity: 1;
	height: 0;
	border: 0;
	border-bottom: 1px solid #c4c4c4;
}

.m-25 {
	margin: 25px;
}

.content-data {
	margin: 0px 35px 25px;
}
</style>
