<template>
  <div class="container-fluid home">
    <!-- loading screen -->
    <div class="loading-background" v-show="loading">
      <p>建立排程中，請耐心等候</p>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- expEmailModal -->
    <EmailPreview :subject="title_name" :html="html"></EmailPreview>
    
    <!-- unlayer Modal -->
    <div
      class="modal fade"
      id="unlayerModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 100%">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body" style="height: 90vh">
            <iframe
              name="myFrame"
              :src="this.url8081"
              height="100%"
              width="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- shorturl loading screen -->
    <div class="loading-background" v-show="urlloading">
      <p>資料處理中，請稍候</p>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- changedateModal -->
    <div
      class="modal fade"
      id="changedateModal"
      tabindex="-1"
      aria-labelledby="changedateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 410px; font-size: 18px">
        <div class="modal-content" style="width: 410px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body">
            <p>活動名稱</p>
            <input
              type="text"
              class="mb-3 ps-3"
              style="
                width: 100%;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                height: 40px;
              "
              placeholder="請輸入活動名稱"
              v-model="renameEvent"
            />
            <p>活動時間</p>
            <div class="row align-items-center justify-content-between">
              <span class="col-1 set-time-title">
                <img class="mb-1" src="../assets/date.svg" alt=""
              /></span>
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  :max-date="calentime"
                  v-model="calenday"
                  is24hr
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
              ~
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  :min-date="calenday"
                  v-model="calentime"
                  is24hr
                  is-expanded
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="basic-btn-calen"
              style="width:130px;background-color"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              data-bs-dismiss="modal"
              @click="addCalen"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- testMemberModal -->
    <div
      class="modal fade"
      id="testMemberModal"
      tabindex="-1"
      aria-labelledby="testMemberModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        style="width: 770px; max-width: 770px; font-size: 18px"
      >
        <div class="modal-content" style="width: 770px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body px-5">
            <p>測試名單</p>
            <p style="color: #c4c4c4">
              輸入最多五筆電子信箱與五筆電話號碼，並建立測試名單，每筆信箱請以換行方式做區隔，此名單不會匯入系統會員名單裡。您亦可依造正常流程進行排程與投放。
            </p>
            <p style="color: #71afb6">收件人電子信箱</p>
            <textarea
              class="mb-3 p-3"
              style="
                width: 100%;
                height: 200px;
                border-radius: 10px;
                line-height: 1.5;
              "
              v-model="emailtext"
            />
            <p style="color: #71afb6">收件人號碼</p>

            <textarea
              class="mb-3 p-3"
              style="
                width: 100%;
                height: 200px;
                border-radius: 10px;
                line-height: 1.5;
              "
              v-model="phonetext"
            />
            <p style="color: #c4c4c4">
              注意透過此名單寄出後一樣會算在寄信與簡訊成本裡。
            </p>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="basic-btn-calen"
              style="width:130px;background-color"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              @click="saveTestMember"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- router push alert -->
    <div
      class="modal fade"
      id="alertModalroutergo"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">預約成功 !</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="routergoScript()"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- router push alert -->
    <div
      class="modal fade"
      id="alertModalroutergo2"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">修改預約成功 !</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="routergoScript()"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 確認發送測試信alert -->
    <div
      class="modal fade"
      id="postTestSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <div>發送成功！</div>
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 確認發送測試信alert -->
    <div
      class="modal fade"
      id="postTestModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <div style="padding: 10px 5px" class="text-start">
              一鍵發送測試信給你自己，瀏覽郵件在收件匣呈現的樣子<br />此功能不是郵件到達率的測試。
            </div>
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close2 me-3"
              data-bs-dismiss="modal"
              style="color: #575757; background-color: #eaf3f4; width: 125px"
            >
              取消
            </button>
            <button
              type="button"
              style="width: 125px"
              class="btn btn-secondary modal-btn-close"
              @click="postTestApi()"
              data-bs-dismiss="modal"
            >
              傳送
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 確認預約模板alert -->
    <div
      class="modal fade"
      id="postScheduleModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="width: 410px">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <div class="text-start" style="padding: 0 50px">
              確認後仍可編輯投放設定，但寄出前10分鐘內將無法進行任何更改
            </div>
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close2"
              data-bs-dismiss="modal"
              style="color: #000; background-color: #eaf3f4; width: 125px"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              style="width: 125px"
              @click="patchScheduleApi()"
              data-bs-dismiss="modal"
              v-if="this.schedule_id != ''"
            >
              確認
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              style="width: 125px"
              @click="postScheduleApi()"
              data-bs-dismiss="modal"
              v-else
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container-fluid">
      <div class="title-span ms-2">
        <span>
          <img
            src="../assets/back-arrow.png"
            @click="goUnlayer()"
            class="me-2 mb-1"
            style="cursor: pointer"
            v-if="this.$route.params.key === undefined"
          />
          郵件投放設定
        </span>
        <!-- <button @click="getCboxById">test</button> -->
      </div>
      <div class="p-4 mt-4 form-container2" style="padding-left: 24px">
        <p style="color: #71afb6" class="">投放設定</p>
        <div class="d-flex pt-3">
          <label
            for=""
            class="col-1 col-form-label section-title p-0"
            style="line-height: 35px"
            >模板名稱</label
          >
          <div class="flex-fill">
            <input
              type="text"
              style="border-radius: 10px"
              name="add-input"
              class="form-control"
              v-model="template_name"
              disabled
            />
          </div>
          <div class="d-flex justify-content-between" style="width: 250px">
            <button
              class="basic-btn highlight ms-4"
              style="width: 100px"
              :style="
                this.$route.params.key === undefined
                  ? 'opacity:0.5;cursor:not-allowed'
                  : ''
              "
              @click="showUnlayer"
            >
              <img
                src="../assets/edit.svg"
                alt=""
                style="width: 14px; position: relative; top: -1px; left: -3px"
              />
              編輯
            </button>
            <button
              class="basic-btn"
              style="width: 100px"
              @click="this.showDetailsModal()"
            >
              <img
                src="../assets/green-eye.svg"
                alt=""
                style="width: 16px; position: relative; top: -1px; left: -3px"
              />
              預覽
            </button>
          </div>
        </div>
        <div class="d-flex pt-3">
          <label
            for=""
            class="col-1 col-form-label section-title p-0"
            style="line-height: 35px"
            >活動名稱</label
          >
          <div
            class="search-container col-11"
            style="position: relative"
            ref="target"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                width: 100%;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow = !autoShow"
            >
              {{ calendar_event }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>

            <div
              class="auto-complicate-div-setting"
              style="width: 100%"
              v-show="autoShow"
              @mouseenter="autoShow = true"
            >
              <img
                src="../assets/search-black.png"
                alt="searchImg"
                class="ms-3 my-2 mt-1"
                style="width: 18px"
              />
              <input
                class="ms-1 p-1 ps-2 my-2"
                style="border: none; width: 90%"
                placeholder="搜尋活動名稱"
                v-model="searchInput2"
              />
              <hr
                class="m-0"
                style="
                  width: 100%;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div style="max-height: 150px; overflow: auto">
                <p
                  class="m-0 p-1 ps-3 auto-complicate-p"
                  style="cursor: default"
                  @click="selectCalendar_event(event)"
                  v-for="event in searchEvents"
                  :key="event.id"
                >
                  <span>{{ event.title }}</span>
                  <span style="font-size: 12px; color: #c4c4c4; display: block"
                    >{{ event.start }} ~ {{ event.end }}</span
                  >
                </p>
              </div>
              <hr
                class="m-0"
                style="
                  width: 100%;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div
                class="ms-3 my-1"
                style="cursor: pointer"
                @click="wantaddCalen"
              >
                <img class="mb-1 me-2" src="../assets/blackPlus.svg" alt="" />
                建立新活動名稱
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex pt-3">
          <label
            for=""
            class="col-1 col-form-label section-title p-0"
            style="line-height: 35px"
            >主旨</label
          >
          <div class="col-11">
            <input
              type="text"
              style="border-radius: 10px"
              v-bind:class="{ 'is-invalid': titleError2 }"
              ref="titleInput2"
              name="add-input"
              class="form-control"
              v-model="title_name"
              placeholder="請輸入主旨"
            />
            <div class="invalid-feedback">
              {{ titleErrMsg2 }}
            </div>
          </div>
        </div>
      </div>
      <!-- 選擇客群 -->
      <div class="mt-5 form-container2">
        <div class="p-4">
          <p style="color: #71afb6" class="">選擇客群</p>
          <div
            class="
              col-12
              mb-3
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="d-flex align-items-center col-3">
              <div class="search-container col-4" ref="target2">
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="
                    width: 270px;
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                    padding: 0 14px;
                    height: 35px;
                  "
                  @click="autoShow2 = !autoShow2"
                >
                  {{ selectGroup }}
                  <img src="../assets/selection-arrow.png" alt="searchImg" />
                </div>
                <div
                  class="auto-complicate-div"
                  style="width: 270px"
                  v-show="autoShow2"
                  @mouseenter="autoShow2 = true"
                >
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectGroup = '全部';
                      autoShow2 = false;
                    "
                  >
                    全部
                  </p>
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectGroup = '價值分群';
                      autoShow2 = false;
                    "
                  >
                    價值分群
                  </p>
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectGroup = '個性化分群';
                      autoShow2 = false;
                    "
                  >
                    個性化分群
                  </p>
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectGroup = '自訂分群';
                      autoShow2 = false;
                    "
                  >
                    自訂分群
                  </p>
                  <p
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    @click="
                      selectGroup = '測試人員';
                      autoShow2 = false;
                    "
                  >
                    測試人員
                  </p>
                  <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
                </div>
              </div>
            </div>
            <div
              class="p-0 search-container col-5"
              v-if="
                this.selectGroup === '價值分群' ||
                this.selectGroup === '個性化分群' ||
                this.selectGroup === '渠道行銷分群' ||
                this.selectGroup === '自訂分群' ||
                this.selectGroup === '測試人員'
              "
            >
              <img
                class="searchImg"
                src="../assets/search.png"
                alt="searchImg"
              />
              <input class="search-input" v-model="searchInput" />
            </div>
            <div
              class="col-3 d-flex justify-content-end"
              style="height: 40px; position: relative; min-width: 110px"
            >
              <Paginator
                :rows="this.perPage"
                :pageLinkSize="3"
                template="PrevPageLink PageLinks NextPageLink"
                :totalRecords="searchList.length"
                @page="onPage($event)"
                class="m-0"
              ></Paginator>
              <div
                style="
                  position: absolute;
                  right: 5px;
                  bottom: -25px;
                  font-size: 14px;
                  color: #8a8a8a;
                "
              >
                目前 {{ this.page }} ，共
                {{ Math.ceil(this.todos.length / this.perPage) }} 頁
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap" style="height: 112px">
            <div
              class="mt-3 px-2"
              style="width: 25%; position: relative"
              :key="index"
              v-for="(todo, index) in newtodos"
            >
              <button
                class="basic-btn px-0 mx-0"
                :class="
                  usedID.includes(todo.label_id) ||
                  usedID.includes(todo.labelId)
                    ? 'disable'
                    : ''
                "
                @click="popout = index"
                ref="target4"
                v-tooltip.bottom="
                  todo.label.length > 7
                    ? {
                        value: todo.label,
                        class: 'tip-LabelManage',
                      }
                    : {
                        value: '',
                        class: '',
                      }
                "
              >
                <div class="todo-span d-flex justify-content-between px-4">
                  <span class="word-overflow" style="line-height: 40px">
                    {{ todo.label }}
                  </span>
                  <img
                    src="../assets/template-plus-icon.svg"
                    style="cursor: pointer"
                    v-if="
                      !usedID.includes(todo.label_id) ||
                      usedID.includes(todo.labelId)
                    "
                  />
                </div>
              </button>
              <div
                style="
                  position: absolute;
                  top: 35px;
                  right: -65px;
                  background-color: white;
                  width: 150px;
                  z-index: 99;
                "
                class="form-container2"
                v-if="popout === index && !usedID.includes(todo.label_id)"
              >
                <p
                  class="m-0 popout-hover"
                  style="padding: 7px 15px; cursor: default"
                  @click="
                    addLabelsToIncludes(todo);
                    popout = '';
                  "
                >
                  <img
                    src="../assets/click-to-add-label.svg"
                    alt="add-label"
                    class="me-2"
                  />
                  <span> 選擇發送 </span>
                </p>
                <p
                  class="m-0 popout-hover"
                  style="padding: 7px 15px; cursor: default"
                  @click="
                    addLabelsToExcludes(todo);
                    popout = '';
                  "
                >
                  <img
                    src="../assets/click-to-exclude-label.svg"
                    alt="exclude-label"
                    class="me-2"
                  />
                  <span> 進行排除 </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr
          class="m-0"
          style="
            width: 100%;
            opacity: 1;
            height: 0;
            border: 0;
            border-bottom: 1px solid #c4c4c4;
          "
        />
        <div class="px-4 py-3 d-flex flex-wrap justify-content-between">
          <div style="width: 49%">
            <p style="color: #71afb6">選擇發送</p>
            <div
              class="d-flex flex-wrap py-3"
              style="
                height: 130px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                overflow: auto;
              "
            >
              <div
                class="mb-3 px-3"
                style="width: 50%"
                :key="index"
                v-tooltip.bottom="
                  todo.label.length > 7
                    ? {
                        value: todo.label,
                        class: 'tip-LabelManage',
                      }
                    : {
                        value: '',
                        class: '',
                      }
                "
                v-for="(todo, index) in includeLabels"
              >
                <button
                  class="basic-btn px-0 mx-0"
                  style="background-color: #71afb6; color: white"
                  @click="deleteLabelsFromIncludes(todo)"
                >
                  <div class="todo-span d-flex justify-content-between px-4">
                    <span class="word-overflow" style="line-height: 40px">
                      {{ todo.label }}
                    </span>
                    <img
                      src="../assets/whiteX.svg"
                      alt=""
                      style="cursor: pointer; margin-top: 1px; margin-left: 5px"
                    />
                  </div>
                </button>
              </div>
            </div>
            <p
              style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
              class="ms-1 mt-1 mb-0"
            >
              選擇您要上傳的名單。
            </p>
          </div>
          <div style="width: 49%">
            <p style="color: #71afb6">進行排除</p>
            <div
              class="d-flex flex-wrap py-3"
              style="
                height: 130px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                overflow: auto;
              "
            >
              <div
                class="mb-3 px-3"
                style="width: 50%"
                :key="index"
                v-for="(todo, index) in excludeLabels"
              >
                <button
                  class="basic-btn px-0 mx-0"
                  style="background-color: #71afb6; color: white"
                  @click="deleteLabelsFromExcludes(todo)"
                >
                  <div class="todo-span d-flex justify-content-between px-4">
                    <span class="word-overflow" style="line-height: 40px">
                      {{ todo.label }}
                    </span>
                    <img
                      src="../assets/whiteX.svg"
                      alt=""
                      style="cursor: pointer; margin-top: 1px; margin-left: 5px"
                    />
                  </div>
                </button>
              </div>
            </div>
            <p
              style="color: #c4c4c4; font-size: 12px; font-family: sans-serif"
              class="ms-1 mt-1 mb-0"
            >
              讓您將特定聯絡人從選定的名單中排除。
            </p>
          </div>
        </div>
        <hr
          class="m-0"
          style="
            width: 100%;
            opacity: 1;
            height: 0;
            border: 0;
            border-bottom: 1px solid #c4c4c4;
          "
        />
        <div class="p-4">
          <p style="color: #71afb6">實際的投放人數</p>
          <div class="d-flex align-items-center mt-4 justify-content-between">
            <div class="d-flex align-items-center">
              <img src="../assets/peopleOrange.svg" style="z-index: 10" />
              <span class="progress-span"
                >{{ numbers }}人({{ Number(percentBars).toFixed(2) }}%)</span
              >
              <progress
                class="top-progress"
                :value="percentBars"
                max="100"
              ></progress>
            </div>
            <span style="font-size: 20px"
              >會員總數&nbsp;&nbsp;{{ members }}&nbsp;&nbsp;人</span
            >
          </div>
        </div>
      </div>
      <!-- 寄件人 -->
      <div class="contain-top mt-4" style="height:260px">
        <div  class=" mb-3" style="color: #71afb6">
          寄件人資料設定
          <BtnInfoBox content="請先設定寄件人資訊，用於此排程以及自動回應的寄件人Email帳號與電話號碼。" direction="right"></BtnInfoBox>
        </div>
        <div class="d-flex align-items-center">
          <span  style="width: 140px"
            >寄件人名稱
            <BtnInfoBox content="運用容易辨識的名稱，例如您的公司名。" direction="right"></BtnInfoBox>
            </span
          >
          <div class="search-container flex-fill" >
            <input
              type="text"
              style="border-radius: 10px"
              name="add-input"
              class="form-control"
              v-model="sender_name"
            />
          </div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <span style="width: 140px"
            >Email</span
          >
          <div class="search-container flex-fill" ref="target3">
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow3 = !autoShow3"
            >
              {{ selectEmail }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>

            <div
              class="auto-complicate-div"
              style="width: 100%; cursor: default"
              v-show="autoShow3"
              @mouseenter="autoShow3 = true"
            >
              <p
                class="m-0 p-1 ps-3 auto-complicate-p"
                :value="item"
                v-for="item in emailList"
                :key="item"
                @click="
                  selectEmail = item;
                  autoShow3 = false;
                "
              >
                {{ item }}
              </p>

              <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
            </div>
          </div>
          <button
            class="ms-3 basic-btn highlight"
            style="width: 200px"
            @click="sendTest"
          >
            <img src="../assets/testSche.svg" alt="" class="me-2" /> 測試投放
          </button>
        </div>
        <div class="d-flex align-items-center mt-3" >
          <span style="width: 140px"
            >電話號碼</span
          >
          <div
            class="search-container flex-fill"
            style="position: relative"
            ref="target4"
          >
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow4 = !autoShow4"
            >
              {{ selectSms }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>
            <div
              class="auto-complicate-div"
              style="width: 100%"
              v-show="autoShow4"
              @mouseenter="autoshow4 = true"
            >
              <p
                class="m-0 p-1 ps-3 auto-complicate-p"
                v-for="(item, index) in smsList"
                :key="index"
                @click="
                  this.selectSms = index;
                  this.realselectSms = item;
                  autoShow3 = false;
                "
              >
                {{ index }}
              </p>

              <!-- <p class="m-0 p-1 ps-3 auto-complicate-p" @click="selectGroup='渠道行銷分群分群'">渠道行銷分群分群</p> -->
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2" style="margin-left: 140px">
          若有需要新增寄件人Email，請洽詢專案服務人員。
        </div>
      </div>
      <!-- AB test -->
      <div class="mt-5 form-container2" style="padding: 24px 40px">
        <div class="d-flex align-items-center">
          <p style="color: #71afb6" class="m-0">AB Test 設定</p>
          <div class="button-group-div ms-5 d-flex" style="width: 200px">
            <button
              class="btn button-color2"
              style="width: 200px"
              :class="abSetting === false ? 'button-color' : ''"
              @click="this.abSetting = false"
            >
              關閉
            </button>
            <button
              class="btn button-color2"
              style="width: 200px"
              :class="abSetting === true ? 'button-color' : ''"
              @click="this.abSetting = true"
            >
              啟用
            </button>
          </div>
        </div>
        <div v-if="this.abSetting === true">
          <div class="d-flex align-items-center">
            <div class="col-6 mt-4 d-flex align-items-center">
              <span class="me-4">A組占總人數中百分之</span>
              <Dropdown
                style="width: 50%"
                :valueArr="this.percentDropdown"
                :selectValue="percent_index"
                :changingSelected="'percent'"
                v-on:selectValueByDrop="selectValueByPercentDrop"
              />
            </div>
            <div
              class="
                col-6
                d-flex
                mt-4
                justify-content-between
                align-items-center
              "
            >
              <span class="col-3 d-flex align-items-center"
                >設定選項
                <BtnInfoBox content="目前提供「主旨調整」設定，B組即是同客群、同排程但不同信件主旨，可依此比較兩組的郵件成效表現。" direction="right"></BtnInfoBox>
                
              </span>

              <Dropdown
                style="width: 70%"
                :valueArr="['主旨調整', '內容調整']"
                :selectValue="abOption"
                :changingSelected="'abOption'"
                v-on:selectValueByDrop="selectValueByDrop"
              />
            </div>
          </div>
          <div
            class="d-flex mt-4 align-items-center"
            v-if="this.abOption == '主旨調整'"
          >
            <span class="col-1">B組主旨</span>
            <span class="B-text-input-container">
              <input
                type="text"
                class="B-text-input ms-3 ps-4"
                placeholder="請在此輸入B組主旨"
                v-model="title_nameB"
              />
            </span>
          </div>
          <div
            class="d-flex mt-4 align-items-center"
            v-else-if="this.abOption == '內容調整'"
          >
            <span class="col-1">B組內容</span>
            <span class="d-flex justify-content-between" style="width: 250px">
              <button
                class="basic-btn highlight ms-4"
                style="width: 100px"
                @click="showUnlayer_b"
              >
                <img
                  src="../assets/edit.svg"
                  alt=""
                  style="width: 14px; position: relative; top: -1px; left: -3px"
                />
                編輯
              </button>
              <button
                class="basic-btn"
                style="width: 100px"
                @click="this.showDetailsModal_b()"
              >
                <img
                  src="../assets/green-eye.svg"
                  alt=""
                  style="width: 16px; position: relative; top: -1px; left: -3px"
                />
                預覽
              </button>
            </span>
          </div>
        </div>
      </div>

      <div class="p-4 ps-5 mt-5 form-container2">
        <div class="row align-items-center">
          <span class="col-2 set-time-title">選擇發送時間</span>
          <span class="col-5">
            <DatePicker
              class="inline-block h-full"
              v-model="day"
              :min-date="new Date()"
              is24hr
              @click="dayChange"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="d-flex align-items-center" @click="togglePopover()">
                  <input
                    :value="inputValue"
                    class="col-12 date-input"
                    readonly
                  />
                  <img
                    src="../assets/date.svg"
                    alt=""
                    style="position: relative; left: -30px"
                  />
                </div>
              </template>
            </DatePicker>
            <div class="invalid-feedback">
              {{ dayErrMsg }}
            </div>
          </span>
          <span class="col-5" style="position: relative">
            <DatePicker
              class="inline-block h-full"
              v-model="time"
              mode="time"
              :min-date="new Date()"
              is24hr
              is-expanded
              @click="timeChange"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="d-flex align-items-center" @click="togglePopover()">
                  <input
                    :value="inputValue"
                    class="col-12 date-input"
                    readonly
                  />
                  <img
                    src="../assets/time.svg"
                    alt=""
                    style="position: relative; left: -30px"
                  />
                </div>
              </template>
            </DatePicker>
            <div
              style="
                color: red;
                font-size: 12px;
                position: absolute;
                top: 41px;
                left: 20px;
              "
              v-if="this.timeAlertShow"
            >
              請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)
            </div>
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button
          class="
            btn btn-lg
            d-flex
            align-items-center
            justify-content-center
            me-5
          "
          style="
            height: 40px;
            background-color: #eaf3f4;
            font-size: 18px;
            width: 29rem;
            color: #71afb6;
          "
          v-if="this.$route.params.key != undefined"
          @click="this.goUnlayer()"
        >
          <img
            src="../assets/green-left-arrow.svg"
            alt=""
            style="position: relative; top: 1px; left: -5px"
          />
          &nbsp;返回排程
        </button>
        <button
          class="
            btn btn-lg btn-primary
            button-color
            d-flex
            align-items-center
            justify-content-center
          "
          style="width: 29rem"
          v-if="this.schedule_id != ''"
          @click="successPatchSchedule"
        >
          <img
            src="../assets/check.svg"
            style="width: 18px; position: relative; top: 2px"
            class="me-2"
          />
          修改預約
        </button>
        <button
          class="
            btn btn-lg btn-primary
            button-color
            d-flex
            align-items-center
            justify-content-center
          "
          style="width: 29rem"
          @click="successBookSchedule"
          v-else
        >
          <img
            src="../assets/check.svg"
            style="width: 18px; position: relative; top: 2px"
            class="me-2"
          />
          確認預約
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../components/Dropdown.vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import Paginator from "primevue/paginator";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { Calendar, DatePicker } from "v-calendar";
import moment from "moment";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "EmailSetting",
  components: {
    Paginator,
    Calendar,
    DatePicker,
    Dropdown,
    EmailPreview,
    BtnInfoBox,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);
    const autoShow2 = ref(false);
    const target2 = ref(null);
    const autoShow3 = ref(false);
    const target3 = ref(null);
    const autoShow4 = ref(false);
    const target4 = ref(null);
    const popout = ref(null);

    onClickOutside(target, () => (autoShow.value = false));
    onClickOutside(target2, () => (autoShow2.value = false));
    onClickOutside(target3, () => (autoShow3.value = false));
    onClickOutside(target4, () => (autoShow4.value = false));
    onClickOutside(target3, () => (popout.value = false));

    return {
      target,
      autoShow,
      target2,
      autoShow2,
      target3,
      autoShow3,
      target4,
      autoShow4,
      popout,
    };
  },
  data() {
    return {
      sender_name:'',
      originUploadLabels: [],
      testRandomkey: "",
      hasBbox: false,
      hasBbox_b: false,
      postBboxError: false,
      from: "",
      url8081: "",
      html: "",

      has_event: "",
      urlloading: false,
      timeAlertShow: false,

      percentBars: "",
      numbers: "- -",
      members: "- -",
      isShow2: false,
      loading: false,
      templatekey: "",
      group: null,
      searchInput: "",
      searchList: [],
      usedID: [],
      includeLabels: [],
      excludeLabels: [],
      combineLabelID: "",

      selectGroup: "--",
      selectedID: "",
      selectEmail: "",

      originHtml: "",
      AboxHtml: "",
      AboxJson: {},
      BboxHtml: "",
      BboxJson: {},
      CboxHtml: "",
      CboxJson: {},
      BboxHtml_b: "",
      BboxJson_b: {},
      CboxHtml_b: "",
      CboxJson_b: {},

      newHtml: "",
      Parr: [],
      fullurlArr: [],
      shortUrlArr: [],
      htmlok: true,
      htmltodos: "",
      random_id: "",
      random_id_b: null,

      page: 1,
      perPage: 8,
      records: [],
      todos: [],
      RFMtodos: [],
      ALLtodos: [],
      customtodos: [],
      channeltodos: [],
      abSetting: false,
      abSend: true,
      abOption: "",

      template_name: "",
      schedule_name: "",
      title_name: "",
      title_nameB: null,
      scheduleError: false,
      scheduleErrMsg: "",
      labelError2: false,
      labelErrMsg2: "",
      titleError: false,
      titleErrMsg: "",
      titleError2: false,
      titleErrMsg2: "",

      percentDropdown: {
        "10%": "10",
        "20%": "20",
        "30%": "30",
        "40%": "40",
        "50%": "50",
        "60%": "60",
        "70%": "70",
        "80%": "80",
        "90%": "90",
      },
      percent: "",
      percent_index: "",
      day: null,
      dayErrMsg: "",
      time: new Date(new Date().setHours(0, 0, 0, 0)),
      selectdate: "",
      calenday: "",
      calentime: "",
      renameEvent: "",

      schedule_id: "",
      script_id: "",
      label_id: "",
      AB_proportion: "",

      selectedName: "",

      emailList: [],
      smsList: [],
      selectSms: '',
      realselectSms: '',

      calendar_event: "請選擇活動",
      searchEvents: [],
      events: [],
      searchInput2: "",
      newtodos: [],

      emailtext: "",
      phonetext: "",
      emailArr: [],
      phoneArr: [],
      testMemberModal: "",
      postScheduleModal: "",

      random_Cbox_key: "",
      noCbox: true,
      noCbox_b: true,
    };
  },

  watch: {
    selectGroup: function (val) {
      console.log(val);
      if (val === "價值分群") {
        this.todos = this.RFMtodos;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "個性化分群") {
        this.todos = this.customtodos;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "渠道行銷分群") {
        this.todos = this.channeltodos;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "自訂分群") {
        this.todos = this.originUploadLabels;
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else if (val === "測試人員") {
        this.todos = [
          {
            label_id: "123456798",
            label: "測試人員",
          },
        ];
        this.searchList = [
          {
            label_id: "123456798",
            label: "測試人員",
          },
        ];
        this.newtodos = [
          {
            label_id: "123456798",
            label: "測試人員",
          },
        ];
      } else if (val === "全部") {
        this.todos = this.ALLtodos;
        console.log("tods", this.todos);
        this.searchList = this.todos;
        this.newtodos = this.searchList;
        // this.newtodos = this.searchList.slice(0, this.perPage);
      }
    },
    searchInput: function (val) {
      if (val === "") {
        this.searchList = this.todos;
        this.newtodos = this.searchList.slice(0, this.perPage);
      } else {
        console.log(val);
        this.searchList = this.todos.filter((d) => d.label.includes(val));
        this.newtodos = this.searchList.slice(0, this.perPage);
      }
    },
    schedule_name: function () {
      if (this.newSub === "") {
        this.titleError = true;
        this.titleErrMsg = "活動名稱不能是空的";
      } else {
        this.titleError = false;
      }
      //  sessionStorage.setItem("主子", this.newSub)
    },
    title_name: function () {
      if (this.title_name === "") {
        this.titleError2 = true;
        this.titleErrMsg2 = "主旨不能是空的";
      } else {
        this.titleError2 = false;
      }
      // sessionStorage.setItem("訊息", this.newSubsec);
    },
    searchInput2: function (val) {
      if (val != "") {
        this.searchEvents = this.events.filter((d) => {
          return d.title.includes(val);
        });
      } else {
        this.searchEvents = this.events;
      }
    },
    day: function () {
      console.log("this.day", this.day);
      var moment = require("moment");
      if (
        this.time < moment(new Date()) &&
        this.day.toDateString() === new Date().toDateString()
      ) {
        console.log("early");
        setTimeout(() => {
          this.time = moment(new Date()).add(2, "m")._d;
        }, 500);
        console.log("this.time", this.time);
      } else {
        return;
      }
    },
    time: function () {
      console.log("this.time", this.time);
      var moment = require("moment");
      if (
        this.time < moment(new Date()) &&
        this.day.toDateString() === new Date().toDateString()
      ) {
        console.log("early");
        setTimeout(() => {
          this.time = moment(new Date()).add(2, "m")._d;
        }, 500);
        console.log("this.time", this.time);
      } else {
        return;
      }
    },
  },

  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.searchList.slice(startIndex, endIndex);
    },
    selectedLabels() {
      return this.includeLabels.concat(this.excludeLabels);
    },
  },
  created: async function () {
    await this.getCalenEvents();
   this.getSmsList();
    this.getEmailList();

    this.urlloading = true;
    await this.gethtmltodosApi();
    this.random_id = "T_" + this.randomString(8);
    this.random_Cbox_key = "T_" + new Date().getTime();
    this.htmltodos.map((d) => {
      if (d.key === this.random_id) {
        this.random_id = "T_" + this.randomString(8);
      }
    });
    console.log(this.$route.params.key);
    const fromSche = this.$route.params.key;
    await Promise.all([
      this.getRFM(),
      this.getRFMcustom(),
      this.getcustomlabelMarketingAllGet(),
      this.getUploadLabelList(),
    ]);
    const index = window.location.href.indexOf("key=");
    this.template_name = sessionStorage.getItem("template_name");
    await this.getCboxById_b();
    if (fromSche === undefined) {
      this.templatekey = window.location.href.slice(index + 4);
      sessionStorage.setItem("template_key",this.templatekey);
      // this.selectedID = JSON.parse( sessionStorage.getItem("getlabelId"));
      // this.group = JSON.parse(sessionStorage.getItem("getlabelTitle"));

      this.getTemplateById();
    } else {
      this.random_id = fromSche;
      await this.getCboxById();
      this.template_name = this.$route.params.template_name;
      this.schedule_id = this.$route.params.ScheduleName;
      this.schedule_name = this.$route.params.script_name;
      this.script_id = this.$route.params.script_id;
      this.selectedID = this.$route.params.label_id;
      this.group = JSON.parse(this.$route.params.label_name);
      this.title_name = this.$route.params.subject;
      this.title_nameB = this.$route.params.subject_b;
      this.AB_proportion = this.$route.params.AB_proportion;
      console.log(this.$route.params)
      if (this.$route.params.sender.includes('_')) {
        this.selectEmail = this.$route.params.sender.split('_')[1]
        this.sender_name = this.$route.params.sender.split('_')[0]
      } else {
        this.selectEmail = this.$route.params.sender
        this.sender_name = ''
      }
      this.has_event = this.$route.params.has_event;
      this.abOption = this.$route.params.AB_type;
      this.realselectSms = this.$route.params.sms_sender;

    const smsListindex = Object.values(this.smsList).findIndex(
        (d) => d === this.realselectSms
      );
      this.selectSms = Object.keys(this.smsList)[smsListindex];
      console.log(this.$route.params);
      const event = this.events.filter((d) => {
        return d.id === this.schedule_name;
      });
      console.log(this.schedule_name);
      if (event[0] != undefined) {
        this.calendar_event = event[0].title;
      }
      this.day = new Date(this.$route.params.ScheduleExpression);
      const hour = new Date(this.$route.params.ScheduleExpression).getHours();
      const min = new Date(this.$route.params.ScheduleExpression).getMinutes();
      this.time = moment(new Date().toISOString().slice(0, 10))
        .add(hour, "h")
        .add(min, "m")._d;

      await this.getInfoByCombineLabelID(this.selectedID);

      // for (let index = 0; index < this.group.length; index++) {
      //   this.selectedLabels.push({
      //     label_id: this.selectedID[index],
      //     label: this.group[index],
      //   });
      // }
      this.checkCombineLabelMemberCount();
    }
    if (this.AB_proportion != "1" && this.AB_proportion != "") {
      this.abSetting = true;
      this.percent = this.AB_proportion * 100;
      console.log("this.percent", this.percent);
      this.percent_index = this.AB_proportion * 100 + "%";
      console.log("this.percent_index", this.percent_index);
    }
    this.urlloading = false;
  },

  methods: {
    // 拿寄件人電話
    getSmsList: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .userSmslistGet(params)
        .then((res) => {
          console.log(res);
          this.smsList = res.data.smslist;
          console.log(Object.keys(this.smsList)[0]);
          this.selectSms = Object.keys(this.smsList)[0];
          this.realselectSms = Object.values(this.smsList)[0];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿所有自訂分群labels
    getUploadLabelList: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .uploadLabelGet(params)
        .then((res) => {
          console.log("uploadLabelList", res);

          res.data.map((d) => {
            d.create_time = d.time;
            d.time = new Date(d.time).toLocaleString();
            d.label_type = "自訂分群";
          });
         this.originUploadLabels = res.data.filter(d=>!d.status.includes('處理中')||!d.status.includes('上傳失敗_'));
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.html);
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.html = this.html.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },
    showDetailsModal: async function (todo) {
      if (this.$route.params.key == undefined) {
        await this.getTemplateById();
      } else {
        await this.getCboxById();
        if (this.noCbox == false) {
          console.log("Cbox");
        } else {
          console.log("Bbox");
          await this.getBboxById();
        }
      }

      this.changeAllUrl();
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    showUnlayer: async function () {
      if (this.$route.params.key === undefined) {
        return;
      }
      this.url8081 = "";
      // this.from = "template";
      await this.getCboxById();
      if (this.noCbox == false) {
        this.from = "emailsettingCbox";
        this.key = this.random_Cbox_key;
      } else {
        this.from = "emailsettingBbox";
        this.key = this.random_id + this.random_Cbox_key;
      }
      // this.key = this.templatekey;
      const token = sessionStorage.getItem("access_token");

      if (window.location.origin === "http://localhost:8080") {
        this.url8081 = `http://localhost:8081/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      } else if (
        window.location.origin === "https://firstcdp-test.retailing-data.net"
      ) {
        this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      } else if (
        window.location.origin === "https://firstcdp-dev.retailing-data.net"
      ) {
        this.url8081 = `https://firstcdp-dev2.retailing-data.net/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      } else {
        this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      }

      this.unlayerModal = new bootstrap.Modal(
        document.getElementById("unlayerModal"),
        {
          keyboard: false,
        }
      );
      this.unlayerModal.show();
    },
    showDetailsModal_b: async function (todo) {
      await this.getCboxById_b();
      if (this.noCbox_b == false) {
        console.log("Cbox");
      } else {
        await this.getBboxById_b();
        if (this.hasBbox_b) {
          console.log("有B箱子了");
        } else {
          await this.postBboxApi_b(this.AboxJson, this.AboxHtml);
          await this.getBboxById_b();
        }
      }

      this.changeAllUrl();
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    showUnlayer_b: async function () {
      this.url8081 = "";
      await this.getCboxById_b();
      if (this.noCbox_b == false) {
        console.log("Cbox_b");
        this.from = "emailsettingCbox_b";
        this.key = this.random_Cbox_key;
      } else {
        await this.getBboxById_b();
        if (this.hasBbox_b) {
          console.log("有B箱子了");
        } else {
          await this.postBboxApi_b(this.AboxJson, this.AboxHtml);
        }
        this.from = "emailsettingBbox_b";
        this.key = this.random_id + this.random_Cbox_key;
      }
      const token = sessionStorage.getItem("access_token");

      if (window.location.origin === "http://localhost:8080") {
        this.url8081 = `http://localhost:8081/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      } else if (
        window.location.origin === "https://firstcdp-test.retailing-data.net"
      ) {
        this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      } else if (
        window.location.origin === "https://firstcdp-dev.retailing-data.net"
      ) {
        this.url8081 = `https://firstcdp-dev2.retailing-data.net/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      } else {
        this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.template_name}&key=${this.key}&token=${token}&group=${this.group}&from=${this.from}`;
      }

      this.unlayerModal = new bootstrap.Modal(
        document.getElementById("unlayerModal"),
        {
          keyboard: false,
        }
      );
      this.unlayerModal.show();
    },

    selectValueByDrop(selectValue, changingSelected) {
      if (selectValue === "主旨調整") {
        this[changingSelected] = "主旨調整";
      } else {
        this[changingSelected] = "內容調整";
      }
    },
    selectValueByPercentDrop(selectValue, changingSelected, index) {
      console.log(selectValue, changingSelected, index);
      this.percent_index = index;
      this[changingSelected] = selectValue;
    },
    getInfoByCombineLabelID: async function (id) {
      console.log("this.group", this.group);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        label_id: id,
      };
      await allapi2
        .customlabelMarketingLabelIdGet(params)
        .then((res) => {
          console.log("根據合成後的label_id拿包含不包含", res);
          const includeArr = res.data.message.Data.content.include;
          if (includeArr[0] != undefined) {
            for (let index = 0; index < includeArr.length; index++) {
              const include_label = {};
              include_label.label_id = includeArr[index];
              include_label.label = this.group.includeLabelNames[index];
              this.includeLabels.push(include_label);
              this.usedID.push(includeArr[index]);
            }
          }
          const excludeArr = res.data.message.Data.content.exclude;
          if (excludeArr[0] != undefined) {
            for (let index = 0; index < excludeArr.length; index++) {
              const exclude_label = {};
              exclude_label.label_id = excludeArr[index];
              exclude_label.label = this.group.excludeLabelNames[index];
              this.excludeLabels.push(exclude_label);
              this.usedID.push(excludeArr[index]);
            }
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    checkCombineLabelMemberCount: async function () {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      console.log(include_ids, exclude_ids);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
        type: "email",
      };

      await allapi2
        .customlabelMarketingRatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的人數", res);
          this.percentBars = res.data.message.Data.ratio;
          this.numbers = res.data.message.Data.count;
          this.members = res.data.message.Data.total;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getCombineLabelID: async function (isPatch) {
      const include_ids = this.includeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const exclude_ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
      };

      await allapi2
        .customlabelMarketingCreatePost(params, body)
        .then((res) => {
          console.log("根據包含不包含拿合成後的label_id", res);
          this.combineLabelID = res.data.message.Data.label_id;
          if (isPatch) {
            console.log("nothing");
          } else {
            this.postHtmlApiSuccess();
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("combinID有誤");
        });
    },
    callalert() {
      alert("功能暫未開放");
    },
    goUnlayer() {
      if (sessionStorage.getItem("template_key") != undefined) {
        const name = sessionStorage.getItem("template_name");
        const key = sessionStorage.getItem("template_key");
        const from = "template";
        const group = sessionStorage.getItem("getlabelTitle");
        const token = sessionStorage.getItem("access_token");
        console.log("群", group);
        console.log(token);
        if (window.location.origin === "http://localhost:8080") {
          window.location.href = `http://localhost:8081/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          window.location.href = `https://firstcdp-test2.retailing-data.net/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        } else if (
          window.location.origin === "https://firstcdp-dev.retailing-data.net"
        ) {
          window.location.href = `https://firstcdp-dev2.retailing-data.net/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        } else {
          window.location.href = `https://firstcdp2.retailing-data.net/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        }
      } else {
        this.$router.push({
          name: "MarketingSchedule",
        });
      }
    },
    opentestMemberModal() {
      this.testMemberModal = new bootstrap.Modal(
        document.getElementById("testMemberModal"),
        {
          keyboard: false,
        }
      );
      this.testMemberModal.show();
    },
    saveTestMember() {
      console.log(this.emailtext);
      function isValidEmail(string) {
        var res = string.match(
          /\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+/g
        );
        return res;
      }
      function isValidPhone(string) {
        var res = string.match(/09[0-9]{8}/g);
        return res;
      }
      let emailok = false;
      let phoneok = false;

      this.emailArr = isValidEmail(this.emailtext);
      console.log(this.emailArr);
      if (this.emailArr === null) {
        alert("信箱不能為空");
      } else if (this.emailArr[0] != undefined) {
        const newemailtext = this.emailtext
          .replace(this.emailArr[0], "")
          .replace(this.emailArr[1], "")
          .replace(this.emailArr[2], "")
          .replace(this.emailArr[3], "")
          .replace(this.emailArr[4], "");
        if (newemailtext.match(/./) != null) {
          alert("信箱格式有誤");
        } else if (this.emailArr.length > 5) {
          alert("最多只能放5個測試信箱");
        } else {
          emailok = true;
        }
      } else {
        alert("連線有誤");
      }
      this.phoneArr = isValidPhone(this.phonetext);
      if (this.phoneArr === null) {
        phoneok = true;
      } else if (this.phoneArr[0] != undefined) {
        const newphonetext = this.phonetext
          .replace(this.phoneArr[0], "")
          .replace(this.phoneArr[1], "")
          .replace(this.phoneArr[2], "")
          .replace(this.phoneArr[3], "")
          .replace(this.phoneArr[4], "");
        if (newphonetext.match(/./) != null) {
          alert("電話格式有誤");
        } else if (this.phoneArr.length > 5) {
          alert("最多只能放5個測試電話");
        } else {
          phoneok = true;
        }
      } else {
        alert("連線有誤");
      }
      if (emailok && phoneok) {
        this.postTestMember();
      }
    },
    postTestMember() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        email: this.emailArr,
        phone: this.phoneArr,
      };

      allapi2
        .testLabelPost(params, body)
        .then((res) => {
          console.log(res);
          const testMember = {};
          testMember.label_id = res.data.Data;
          testMember.label = "測試人員";

          this.includeLabels.push(testMember);
          this.usedID.push("123456798");

          this.testMemberModal.hide();
          this.checkCombineLabelMemberCount();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getTestMember() {
      this.opentestMemberModal();
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        email: this.emailArr,
        phone: this.phoneArr,
      };

      allapi2
        .testLabelGet(params, body)
        .then((res) => {
          console.log(res);
          const reg = /,/g;
          this.emailtext = res.data.Data.email.toString().replace(reg, "\n");
          this.phonetext = res.data.Data.phone.toString().replace(reg, "\n");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    sendTest: async function () {
      if (this.title_name === "") {
        alert("主旨不能為空");
        return;
      }

      await this.getCboxById();
      if (this.noCbox == false) {
        console.log("Cbox");
        this.originHtml = this.CboxHtml;
      } else {
        console.log("Bbox");
        await this.getBboxById();
        if (this.hasBbox) {
          console.log("有B箱子了");
          this.originHtml = this.BboxHtml;
        } else {
          await this.getTemplateById();
          this.originHtml = this.AboxHtml;
        }
      }

      this.sliceStyle();
      this.changeCustomTool();
      await this.getallhttp();
      await this.getAllShortHttp();
      this.changeHtmlhttp();
      if (this.htmlok === true) {
        const postTestModal = new bootstrap.Modal(
          document.getElementById("postTestModal"),
          {
            keyboard: false,
          }
        );
        postTestModal.show();
      } else {
        alert("轉html出錯了");
      }
    },
    postTestApi: async function () {
      await this.postHtmlApi(true);

      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        email: this.selectEmail,
        template: this.testRandomkey + "_test",
        subject: this.title_name,
      };

      allapi2
        .testLabelSendEmailPost(params, body)
        .then((res) => {
          console.log(res);
          const postTestSuccessModal = new bootstrap.Modal(
            document.getElementById("postTestSuccessModal"),
            {
              keyboard: false,
            }
          );
          postTestSuccessModal.show();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log("可用活動", res);
          res.data.Data.reverse();
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
          this.searchEvents = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // getactivityGet: async function () {
    //   const token = sessionStorage.getItem("access_token");
    //   const params = {
    //     Authorization: "Bearer " + token,
    //   };

    //   await allapi2
    //     .activityGet(params)
    //     .then((res) => {
    //       console.log('可用活動',res);
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤");
    //       console.log(err);
    //     });
    // },
    selectCalendar_event(e) {
      this.schedule_name = e.id;
      this.calendar_event = e.title;
      this.autoShow = false;
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    dayChange() {
      this.editinput = true;
      console.log(this.day);
    },
    timeChange() {
      this.editinput = true;
    },
    addLabelsToIncludes(todo) {
      if (this.selectGroup === "測試人員") {
        this.getTestMember();
      } else {
        const ids = this.includeLabels.map((d) => {
          return d.labelId || d.label_id;
        });
        const hasid = ids.findIndex((d) => {
          return d === todo.label_id || d === todo.labelId;
        });

        console.log(ids);
        console.log(hasid);
        if (hasid != -1) {
          return;
        }
        this.includeLabels.push(todo);
        if (todo.label_id != undefined) {
          this.usedID.push(todo.label_id);
        } else if (todo.labelId != undefined) {
          this.usedID.push(todo.labelId);
        }

        console.log("this.usedID", this.usedID);
        console.log(this.includeLabels);
        this.checkCombineLabelMemberCount();
      }
    },
    addLabelsToExcludes(todo) {
      const ids = this.excludeLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const hasid = ids.findIndex((d) => {
        return d === todo.label_id || d === todo.labelId;
      });

      console.log(ids);
      console.log(hasid);
      if (hasid != -1) {
        return;
      }
      this.excludeLabels.push(todo);
      if (todo.label_id != undefined) {
        this.usedID.push(todo.label_id);
      } else if (todo.labelId != undefined) {
        this.usedID.push(todo.labelId);
      }

      console.log(this.excludeLabels);
      this.checkCombineLabelMemberCount();
    },
    deleteLabelsFromIncludes(todo) {
      console.log(todo);
      if (todo.label === "測試人員") {
        this.usedID = this.usedID.filter((d) => {
          return d != "123456798";
        });
      }
      this.includeLabels = this.includeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      this.checkCombineLabelMemberCount();
    },
    deleteLabelsFromExcludes(todo) {
      console.log(todo);
      if (todo.label === "測試人員") {
        this.usedID = this.usedID.filter((d) => {
          return d != "123456798";
        });
      }
      this.excludeLabels = this.excludeLabels.filter((d) => {
        return d != todo;
      });
      this.usedID = this.usedID.filter((d) => {
        return d != todo.labelId && d != todo.label_id;
      });
      console.log("this.usedID", this.usedID);
      this.checkCombineLabelMemberCount();
    },
    gethtmltodosApi: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .templatev2HtmlInfoGet(params)
        .then((res) => {
          console.log("拿所有的D箱子", res);
          this.htmltodos = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getRFM: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelAilabelGet(params)
        .then((res) => {
          console.log("選擇客群裡的價值分群", res);
          this.RFMtodos = res.data.message.Data;
          this.todos = this.RFMtodos;
          this.searchList = this.todos;
          // this.newtodos = this.searchList.slice(0, this.perPage);

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getRFMcustom: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelListGet(params)
        .then((res) => {
          console.log("選擇客群裡的個性化分群", res);
          this.customtodos = res.data.message.Data;
          this.customtodos.sort((a, b) => {
            return b.time - a.time;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getcustomlabelMarketingAllGet: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .customlabelMarketingAllGet(params)
        .then((res) => {
          console.log("選擇客群裡的全部", res);
          this.ALLtodos = res.data.message.Data;
          // this.newtodos = this.ALLtodos;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getEmailList() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      allapi2
        .userEmaillistGet(params)
        .then((res) => {
          console.log("寄件人Email的可選Email", res);
          this.emailList = res.data.maillist;
          this.selectEmail = this.emailList[0];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    onPage(event) {
      const startIndex = event.rows * event.page;
      const endIndex = startIndex + event.rows;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
      this.newtodos = this.searchList.slice(startIndex, endIndex);
      console.log(this.newtodos);
    },

    getTemplateById: async function () {
      console.log(this.templatekey);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.templatekey,
      };

      await allapi2
        .templatev2TempKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.templatekey拿A箱子  現在的this.templatekey是${this.templatekey}`,
            res
          );
          if (res.data.html === undefined) {
            res.data.map((d) => {
              if (d.name === this.template_name) {
                this.templatekey = d.key;
              }
            });
          }
          this.AboxHtml = res.data.html;
          this.AboxJson = res.data.json;
          this.html = res.data.html;
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("資料缺失，將返回排程");
          this.$router.push({
            name: "MarketingSchedule",
          });
          console.log(err);
        });
    },
    getBboxById: async function () {
      console.log(this.templatekey);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.random_id,
      };

      await allapi2
        .templatev2TransitKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.random_id拿B箱子  現在的this.random_id是${this.random_id}`,
            res
          );
          this.BboxHtml = res.data.html;
          this.BboxJson = res.data.json;
          this.html = res.data.html;
          if (res.data.html == "not found") {
            this.hasBbox = false;
          } else {
            this.hasBbox = true;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("資料缺失，將返回排程");
          this.$router.push({
            name: "MarketingSchedule",
          });
          console.log(err);
        });
    },
    getCboxById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.random_Cbox_key,
      };
      // const params = {
      //   Authorization: "Bearer " + token,
      //   key: '1663739789893',
      // };

      await allapi2
        .templatev2CacheKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.random_Cbox_key拿C箱子  現在的this.random_Cbox_key是${this.random_Cbox_key}`,
            res
          );
          if (res.data.html == "not found") {
            this.noCbox = true;
          } else {
            this.noCbox = false;
            this.CboxHtml = res.data.html;
            this.CboxJson = res.data.json;
            this.html = res.data.html;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          // alert("資料缺失，將返回排程");
          // this.$router.push({
          //   name: "MarketingSchedule",
          // });
          console.log(err);
        });
    },
    getBboxById_b: async function () {
      console.log(this.random_id);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.random_id + "_b",
      };

      await allapi2
        .templatev2TransitKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.random_id拿B箱子  現在的this.random_id是${this.random_id}`,
            res
          );
          this.BboxHtml_b = res.data.html;
          this.BboxJson_b = res.data.json;
          this.html = res.data.html;
          if (res.data.html == "not found") {
            this.hasBbox_b = false;
          } else {
            this.hasBbox_b = true;
          }
        })
        .catch((err) => {
          this.hasBbox_b = false;
          console.log("連線有誤");
          alert("資料缺失，將返回排程");
          this.$router.push({
            name: "MarketingSchedule",
          });
          console.log(err);
        });
    },
    getCboxById_b: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.random_Cbox_key + "_b",
      };
      // const params = {
      //   Authorization: "Bearer " + token,
      //   key: '1663739789893',
      // };

      await allapi2
        .templatev2CacheKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.random_Cbox_key +b 拿C箱子  現在的this.random_Cbox_key +b 是${this.random_Cbox_key}+b`,
            res
          );
          if (res.data.html == "not found") {
            this.noCbox_b = true;
          } else {
            this.noCbox_b = false;
            this.CboxHtml_b = res.data.html;
            this.CboxJson_b = res.data.json;
            this.html = res.data.html;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          // alert("資料缺失，將返回排程");
          // this.$router.push({
          //   name: "MarketingSchedule",
          // });
          console.log(err);
        });
    },

    sliceStyle() {
      const bodyEnd = this.originHtml.indexOf("</body>");
      const html1 = this.originHtml.slice(0, bodyEnd);
      const html3 = this.originHtml.slice(bodyEnd);
      this.newHtml = html1 + "<div>{{tracking}}</div>" + html3;
    },
    changeCustomTool() {
      this.newHtml = this.newHtml
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png",
          "{{p1}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png",
          "{{p2}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png",
          "{{p3}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png",
          "{{p4}}"
        )
        .replace("www.google1.com", "{{p1_path}}")
        .replace("www.google2.com", "{{p2_path}}")
        .replace("www.google3.com", "{{p3_path}}")
        .replace("www.google4.com", "{{p4_path}}");
    },
    getallhttp: async function () {
      function isValidURL(string) {
        var res = string.match(
          /"(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newHtml);

      this.fullurlArr = this.fullurlArr.filter((d) => {
        return (
          !d.includes(
            "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"
          ) &&
          !d.includes("http://www.w3.org/1999/xhtml") &&
          !d.includes("rema-images") &&
          !d.includes("rema-image-resize") &&
          !d.includes("cdn.tools.unlayer") &&
          !d.includes("@")
        );
      });
      this.fullurlArr = this.fullurlArr.map((d) => {
        return d.slice(1, -1);
      });

      console.log("this.fullurlArr", this.fullurlArr);
    },
    getAllShortHttp: async function (b) {
      this.shortUrlArr = [];
      this.urlloading = true;

      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrlApi(this.fullurlArr[index], b);
      }
      this.urlloading = false;
    },
    changeHtmlhttp() {
      console.log("轉短網址");
      for (let index = 0; index < this.fullurlArr.length; index++) {
        this.Parr.push("{[pfullurl]}" + index);
        this.newHtml = this.newHtml.replace(
          this.fullurlArr[index],
          this.Parr[index]
        );
      }
      for (let index = 0; index < this.fullurlArr.length; index++) {
        this.newHtml = this.newHtml.replace(
          this.Parr[index],
          this.shortUrlArr[index]
        );
      }
    },
    getShortUrlApi: async function (fullurl, b) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {
        url: fullurl,
        ruleID: this.random_id,
      };
      if (b === "_b") {
        body = {
          url: fullurl,
          ruleID: this.random_id + b,
        };
      }
      await allapi2
        .templateShortenUrlPost(params, body)
        .then((res) => {
          console.log(res);
          this.shortUrlArr.push(res.data.url + `?i={{id}}&z=${this.randomString(12)}&x=${this.randomString(12)}`);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.htmlok = false;
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },

    // 開始確認預約
    successBookSchedule: async function () {
      // await this.getTemplateById();
      // await this.postBboxApi(this.AboxJson, this.AboxHtml);

      // if(this.postBboxError){
      //   alert('建立排程模板失敗')
      //   return
      // }

      if (this.schedule_name === "") {
        alert("活動名稱不能是空的");
      } else if (this.title_name === "") {
        this.titleError2 = true;
        this.$refs.titleInput2.focus();
        this.titleErrMsg2 = "主旨不能是空的";
      } else if (this.includeLabels[0] === undefined) {
        alert("發送客群不能是空的");
      } else if (this.selectEmail === "") {
        alert("寄件人不能是空的");
      } else if (this.abSetting === true && this.percent === "") {
        alert("有開ABtest，但沒選人數占比");
      } else if (
        this.abSetting === true &&
        this.abOption === "主旨調整" &&
        (this.title_nameB === "" || this.title_nameB === null)
      ) {
        alert("有要調整主旨，B組主旨不能為空");
      } else if (this.day === null) {
        alert("發送時間不能為空");
      } else {
        console.log("this.day", this.day);
        console.log("this.time", this.time);
        var moment = require("moment");
        const day = moment(this.day)
          .add(+8, "h")
          .toISOString()
          .substr(0, 10);
        const time = this.time.toString().substr(16, 5);

        var UTC = moment(day + " " + time).add(-8, "h");
        console.log(UTC);

        this.selectdate =
          "cron(" +
          UTC.format("mm") +
          " " +
          UTC.format("HH") +
          " " +
          UTC.format("D") +
          " " +
          UTC.format("M") +
          " " +
          "?" +
          " " +
          UTC.format("Y") +
          ")";

        console.log("POST DATE", this.selectdate);
        this.urlloading=true

        this.originHtml = this.AboxHtml;
        this.sliceStyle();
        this.changeCustomTool();
        await this.getallhttp();
        await this.getAllShortHttp();
        this.changeHtmlhttp();
        this.urlloading=false
        if (this.htmlok === true) {
          this.postScheduleModal = new bootstrap.Modal(
            document.getElementById("postScheduleModal"),
            {
              keyboard: false,
            }
          );
          this.postScheduleModal.show();
        } else {
          alert("轉html出錯了");
        }
      }
    },
    successPatchSchedule: async function () {
      // await this.getTemplateById();
      await this.getCboxById();
      if (this.noCbox == false) {
        await this.patchBboxApi(this.CboxJson, this.CboxHtml);
        this.originHtml = this.CboxHtml;
      } else {
        await this.getBboxById();
        this.originHtml = this.BboxHtml;
      }

      console.log("this.day", this.day);
      console.log("this.time", this.time);
      var moment = require("moment");
      const day = moment(this.day)
        .add(+8, "h")
        .toISOString()
        .substr(0, 10);
      const time = this.time.toString().substr(16, 5);

      var UTC = moment(day + " " + time).add(-8, "h");
      console.log(UTC);

      this.selectdate =
        "cron(" +
        UTC.format("mm") +
        " " +
        UTC.format("HH") +
        " " +
        UTC.format("D") +
        " " +
        UTC.format("M") +
        " " +
        "?" +
        " " +
        UTC.format("Y") +
        ")";

      console.log("POST DATE", this.selectdate);

      if (this.schedule_name === "") {
        this.titleError = true;
        this.titleErrMsg = "活動名稱不能是空的";
        this.$refs.titleInput.focus();
        console.log(this.titleError);
      } else if (this.title_name === "") {
        this.titleError2 = true;
        this.$refs.titleInput2.focus();
        this.titleErrMsg2 = "主旨不能是空的";
      } else if (this.includeLabels[0] === undefined) {
        alert("發送客群不能是空的");
      } else if (this.selectEmail === "") {
        alert("寄件人不能是空的");
      } else if (this.abSetting === true && this.percent === "") {
        alert("有開ABtest，但沒選人數占比");
      } else if (
        this.abSetting === true &&
        this.abOption === "主旨調整" &&
        (this.title_nameB === "" || this.title_nameB === null)
      ) {
        alert("有要調整主旨，B組主旨不能為空");
      } else if (this.day === null) {
        alert("發送時間不能為空");
      } else {
        this.sliceStyle();
        this.changeCustomTool();
        await this.getallhttp();
        await this.getAllShortHttp();
        this.changeHtmlhttp();

        if (this.htmlok === true) {
          this.postScheduleModal = new bootstrap.Modal(
            document.getElementById("postScheduleModal"),
            {
              keyboard: false,
            }
          );
          this.postScheduleModal.show();
        } else {
          alert("轉html出錯了");
        }
      }
    },

    postBboxApi: async function (design, html) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        json: design,
        html: html,
      };

      await allapi2
        .templatev2TransitInfoPost(params, body)
        .then(async (res) => {
          console.log("根據A箱子建立B箱子", res);
          this.postBboxError = false;
        })
        .catch((err) => {
          alert("連線有誤");
          this.postBboxError = true;
          console.log("連線有誤");
          console.log(err);
          this.goSet = false;
        });
    },
    patchBboxApi: async function (design, html) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        json: design,
        html: html,
      };

      await allapi2
        .templatev2TransitInfoPatch(params, body)
        .then(async (res) => {
          console.log("根據C箱子改B箱子", res);
          this.postBboxError = false;
        })
        .catch((err) => {
          alert("連線有誤");
          this.postBboxError = true;
          console.log("連線有誤");
          console.log(err);
          this.goSet = false;
        });
    },
    postBboxApi_b: async function (design, html) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id + "_b",
        json: design,
        html: html,
      };

      await allapi2
        .templatev2TransitInfoPost(params, body)
        .then(async (res) => {
          console.log("根據A箱子建立B_b箱子", res);
          this.postBboxError = false;
        })
        .catch((err) => {
          alert("連線有誤");
          this.postBboxError = true;
          console.log("連線有誤");
          console.log(err);
          this.goSet = false;
        });
    },
    patchBboxApi_b: async function (design, html) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id + "_b",
        json: design,
        html: html,
      };

      await allapi2
        .templatev2TransitInfoPatch(params, body)
        .then(async (res) => {
          console.log("根據C箱子改B箱子", res);
          this.postBboxError = false;
        })
        .catch((err) => {
          alert("連線有誤");
          this.postBboxError = true;
          console.log("連線有誤");
          console.log(err);
          this.goSet = false;
        });
    },

    postHtmlApi: async function (postingtest) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};
      if (postingtest) {
        this.testRandomkey = "T_" + this.randomString(8);
        body = {
          name: this.template_name,
          key: this.testRandomkey + "_test",
          html: this.newHtml,
        };
      } else {
        body = {
          name: this.template_name,
          key: this.random_id,
          html: this.newHtml,
        };
      }
      await allapi2
        .templatev2HtmlInfoPost(params, body)
        .then(async (res) => {
          console.log("postHtmlApi 上傳template_a schedule模板 成功", res);
          if (postingtest) {
            console.log("just send to testing email");
          } else {
            if (this.abSetting) {
              if (this.abOption === "內容調整") {
                await this.getCboxById_b();
                if (this.noCbox_b == false) {
                  await this.patchBboxApi_b(this.CboxJson_b, this.CboxHtml_b);
                  this.originHtml = this.CboxHtml_b;
                }
              }
              if (this.postBboxError) {
                alert("建立排程模板失敗");
                return;
              }

              this.sliceStyle();
              this.changeCustomTool();
              await this.getallhttp();
              await this.getAllShortHttp("_b");
              this.changeHtmlhttp();
              await this.postHtmlApi_b();
            }
            this.getCombineLabelID(false);
          }
        })
        .catch((err) => {
          console.log("postHtmlApi連線有誤");
          console.log(err);
          alert("postHtmlApi連線有誤");
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },
    postHtmlApi_b: async function (postingtest) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.template_name,
        key: this.random_id + "_b",
        html: this.newHtml,
      };
      await allapi2
        .templatev2HtmlInfoPost(params, body)
        .then(async (res) => {
          console.log("postHtmlApi 上傳template_b schedule模板 成功", res);
        })
        .catch((err) => {
          console.log("postHtmlApi_b 連線有誤");
          console.log(err);
          alert("postHtmlApi_b連線有誤");
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },
    // 修改D箱子  
    patchHtmlApi: async function (postingtest) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};
      if (postingtest) {
        body = {
          name: this.template_name,
          key: this.random_id + "_test",
          html: this.newHtml,
        };
      } else {
        body = {
          name: this.template_name,
          key: this.random_id,
          html: this.newHtml,
        };
      }
      await allapi2
        .templatev2HtmlInfoPatch(params, body)
        .then(async (res) => {
          console.log("patchHtmlApi 上傳template_a schedule模板 成功", res);
          if (postingtest) {
            console.log("just send to testing email");
          } else {
            if (this.abSetting) {
              if (this.abOption === "內容調整") {
                await this.getCboxById_b();
                if (this.noCbox_b == false) {
                  await this.patchBboxApi_b(this.CboxJson_b, this.CboxHtml_b);
                  this.originHtml = this.CboxHtml_b;
                this.sliceStyle();
                this.changeCustomTool();
                await this.getallhttp();
                await this.getAllShortHttp("_b");
                this.changeHtmlhttp();
                await this.patchHtmlApi_b();
                }
              }else{
                this.getBboxById()
                await this.patchBboxApi_b(this.BboxJson, this.BboxHtml);
                
                // 如果內容挑整完 不想保留Dbox_b就加下面那段 要保留就拿掉
                this.changeCustomTool();
                await this.getallhttp();
                await this.getAllShortHttp("_b");
                this.changeHtmlhttp();
                await this.patchHtmlApi_b();
              }

              if (this.postBboxError) {
                alert("建立排程模板失敗");
                return;
              }

            }
          }
        })
        .catch((err) => {
          console.log("postHtmlApi連線有誤");
          console.log(err);
          alert("postHtmlApi連線有誤");
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },
    patchHtmlApi_b: async function (postingtest) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.template_name,
        key: this.random_id + "_b",
        html: this.newHtml,
      };
      await allapi2
        .templatev2HtmlInfoPatch(params, body)
        .then(async (res) => {
          console.log("patchHtmlApi_b 上傳template_b schedule模板 成功", res);
        })
        .catch((err) => {
          console.log("patchHtmlApi_b 連線有誤");
          console.log(err);
          alert("patchHtmlApi_b連線有誤");
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },

    postScheduleApi: async function () {
      this.loading=true
      var moment = require("moment");
      console.log("請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)");
      console.log(
        'this.time < moment(new Date()).add(1, "m")',
        this.time < moment(new Date()).add(1, "m")
      );
      console.log(
        "this.day.toDateString() === new Date().toDateString()",
        this.day.toDateString()
      );
      console.log("new Date().toDateString()", new Date().toDateString());
      if (
        this.time < moment(new Date()).add(1, "m") &&
        this.day.toDateString() === new Date().toDateString()
      ) {
        this.timeAlertShow = true;
        this.loading=false
        return;
      } else {
        this.timeAlertShow = false;
      }
      await this.postBboxApi(this.AboxJson, this.AboxHtml);
      await this.getBboxById_b();
      if (this.hasBbox_b) {
        console.log("有B箱子了");
      } else {
        await this.postBboxApi_b(this.AboxJson, this.AboxHtml);
      }
      this.originHtml = this.AboxHtml;
      if (this.postBboxError) {
        alert("建立排程模板失敗");
        return;
      }
      await this.postHtmlApi(false);

      // await this.getCombineLabelID(false);
    },
    // 建立排程
    postHtmlApiSuccess: async function () {
      var moment = require("moment");
      let AB_type = null;
      this.loading = true;
      let AB = "1";
      if (this.abSetting) {
        AB = this.percent / 100;
        if (this.abOption === "主旨調整") {
          AB_type = "主旨調整";
          console.log("主旨調整");
        } else {
          AB_type = "內容調整";
          console.log("內容調整");
          this.title_nameB = this.title_name;
        }
        this.random_id_b = this.random_id + "_b";
      } else {
        AB = "1";
        this.title_nameB = null;
      }

      let sender_email=''

      if (this.sender_name=='') {
        sender_email = this.selectEmail
      } else {
        sender_email =`${this.sender_name}_${this.selectEmail}`
      }

      const include_names = this.includeLabels.map((d) => {
        return d.label;
      });
      const exclude_names = this.excludeLabels.map((d) => {
        return d.label;
      });

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        label_id: this.combineLabelID,
        label_name: {
          includeLabelNames: include_names,
          excludeLabelNames: exclude_names,
        },
        cron: this.selectdate,
        AB_type: AB_type,
        template: this.random_id,
        template_b: this.random_id_b,
        subject: this.title_name,
        subject_b: this.title_nameB,
        sender: sender_email,
        sms_sender: this.realselectSms,
        configuration_set: "ReMA",
        limit: "0",
        AB_proportion: AB,
        script_name: this.schedule_name,
      };

      await allapi2
        .schedulePost(params, body)
        .then((res) => {
          this.loading = false;

          console.log("上傳新的預約排程了", res);
          sessionStorage.removeItem("getlabelId");
          sessionStorage.removeItem("getlabelTitle");
          sessionStorage.removeItem("getlabelNumber");
          const alertModalroutergo = new bootstrap.Modal(
            document.getElementById("alertModalroutergo"),
            {
              keyboard: false,
            }
          );
          // if(this.CboxHtml!=''){
          //     this.deleteCboxById()
          // }
          alertModalroutergo.show();
          setTimeout(() => {
            alertModalroutergo.hide();
            this.routergoScript();
          }, 2000);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    // 更新排程
    patchScheduleApi: async function () {
      this.loading=true
      var moment = require("moment");
      console.log("請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)");
      console.log(
        'this.time < moment(new Date()).add(1, "m")',
        this.time < moment(new Date()).add(1, "m")
      );
      console.log(
        "this.day.toDateString() === new Date().toDateString()",
        this.day.toDateString()
      );
      console.log("new Date().toDateString()", new Date().toDateString());
      console.log("請預留當下時間1分鐘以便系統判斷。(時區:GMT+8)");
      if (
        this.time < moment(new Date()).add(1, "m") &&
        this.day.toDateString() === new Date().toDateString()
      ) {
        this.timeAlertShow = true;
        this.loading=false
        return;
      } else {
        this.timeAlertShow = false;
      }

      
      let sender_email=''

      if (this.sender_name=='') {
        sender_email = this.selectEmail
      } else {
        sender_email =`${this.sender_name}_${this.selectEmail}`
      }
      // const isPatch = true;
      // await this.getCombineLabelID(isPatch);
      await this.patchHtmlApi();
      await this.getCombineLabelID(true);
      let AB_type = null;

      console.log("runpatch");
      let AB = "1";
      this.loading = true;
      if (this.abSetting) {
        AB = this.percent / 100;
        if (this.abOption === "主旨調整") {
          AB_type = "主旨調整";
          console.log("主旨調整");
        } else {
          AB_type = "內容調整";
          console.log("內容調整");
          this.title_nameB = this.title_name;
        }
        this.random_id_b = this.random_id + "_b";
      } else {
        AB = "1";
        this.title_nameB = null;
      }

      const include_names = this.includeLabels.map((d) => {
        return d.label;
      });
      const exclude_names = this.excludeLabels.map((d) => {
        return d.label;
      });

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.schedule_id,
      };
      const body = {
        label_id: this.combineLabelID,
        label_name: {
          includeLabelNames: include_names,
          excludeLabelNames: exclude_names,
        },
        cron: this.selectdate,
        AB_type: AB_type,
        script_id: this.script_id,
        template: this.random_id,
        template_b: this.random_id_b,
        subject: this.title_name,
        subject_b: this.title_nameB,
        sender: sender_email,
        sms_sender: this.realselectSms,
        configuration_set: "ReMA",
        limit: "0",
        AB_proportion: AB,
        script_name: this.schedule_name,
      };

      await allapi2
        .scheduleRuleIDPatch(params, body)
        .then(async (res) => {
          this.loading = false;
          console.log("更新新的預約排程了", res);
          await this.patchRunScriptApi();
          const alertModalroutergo2 = new bootstrap.Modal(
            document.getElementById("alertModalroutergo2"),
            {
              keyboard: false,
            }
          );
          alertModalroutergo2.show();
          setTimeout(() => {
            alertModalroutergo2.hide();
            this.routergoScript();
          }, 2000);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    patchRunScriptApi: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.script_id,
        state: this.has_event,
      };

      await allapi2
        .scheduleUpdateHasEventScriptIdPatch(params)
        .then((res) => {
          console.log("成功更改劇本狀態為" + this.has_event, res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("patch連線有誤" + err);
        });
    },

    // 想要建立新的活動
    wantaddCalen() {
      this.changedateModal = new bootstrap.Modal(
        document.getElementById("changedateModal"),
        {
          keyboard: false,
        }
      );
      this.changedateModal.show();
    },
    // 確定建立
    addCalen: async function () {
      if (this.renameEvent === "") {
        alert("必須輸入活動名稱");
        return;
      } else if (this.calenday === "" || this.calentime === "") {
        alert("必須輸入活動時間");
        return;
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        activity_id: Date.now(),
        name: this.renameEvent,
        start: this.calenday,
        end: this.calentime,
      };

      await allapi2
        .activityPost(params, body)
        .then(async (res) => {
          console.log(res);
          await this.getCalenEvents();
          const newEvent = this.events.find(
            (d) => d.title === this.renameEvent
          );
          this.selectCalendar_event(newEvent);
          this.renameEvent = "";
          this.calenday = "";
          this.calentime = "";
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    routergoScript() {
      this.$router.push({
        name: "MarketingSchedule",
      });
    },
  },
};
</script>

<style scoped>
/* EmailSetting */
input::-webkit-input-placeholder {
  text-align: left;
}
.home {
  padding: 50px;
}
.contain-top {
  padding: 20px 40px 25px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  height: 198px;
}
.form-container2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}
.form-control,
.form-control2 {
  border: none;
  background-color: #eaf3f4;
  overflow: none;
}
.input-style {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.input-style-2 {
  width: 50%;
  height: 40px;
  /* background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%); */
  border-radius: 10px;
  background-color: #fff;
}
.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.basic-btn {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}
.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 30px;
  width: 100%;
  height: 37px;
}
.search-container {
  position: relative;
}
.searchImg {
  position: absolute;
  /* width: 3%; */
  height: 20px;
  top: 10px;
  left: 8px;
}
.button-group-div {
  background-color: #eaf3f4;
  width: 200px;
  border-radius: 10px;
}
.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-color2 {
  width: 100px;
  background-color: #eaf3f4;
  border-radius: 10px;
}
.B-text-input-container {
  width: 91.7%;
}
.B-text-input {
  background-color: #eaf3f4;
  border-radius: 10px;
  border: none;
  width: 98.5%;
  height: 37px;
}
.B-text-input:focus {
  border: none;
}
.modal-btn-close-cancel {
  width: 10vw;
}
input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: left;
}
.ab-select {
  width: 14vw;
  height: 40px;
  border-radius: 10px;
}
.child-popout6 {
  font-size: 14px;
  background-color: #63a7c8;
  z-index: 199;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 285px;
  right: -295px;
  top: -49px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.auto-complicate-div-setting {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  z-index: 99;
}
.popout-hover:hover {
  background-color: #eaf3f4;
}
.popout-hover:hover:first-child {
  background-color: #eaf3f4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popout-hover:hover:last-child {
  background-color: #eaf3f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.disable {
  background-color: #eeeeee;
  color: #c4c4c4;
}
:deep(.vc-time-date) {
  display: none !important;
}
</style>
