<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div v-else>
    <div class="container home">
      <div class="loading-background" v-show="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <DatePicker
                :from-page="{ month: this.endMonth, year: this.endYear }"
                @dayclick="onDayClick"
                v-model="vue3Date"
                is-range
                is-expanded
                :model-config="modelConfig"
                :max-date="hasDataEnd"
                :min-date="hasDataFirst"
              />
              <p>{{ vue3Date.start }}~{{ vue3Date.end }}</p>
              <!-- <p></p> -->
              <button
                class="btn confrim-btn"
                @click="customDatePicker"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                選取
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- main -->
      <div class="header d-flex justify-content-between">
        <div class="d-flex">
          <span class="title-span fs-medium">整體投放趨勢 (Email)</span>
        </div>
        <div class="dropdown">
          <a
            class="
              btn
              dropdown-toggle
              datepicker
              d-flex
              justify-content-between
              align-items-center
            "
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="font-size: 14px"
          >
            <span class="d-flex align-items-center">
              <img
                class="me-2"
                src="../assets/black-calendar.svg"
                alt="calendar"
              />
              <span>{{ dateSelector }}</span>
            </span>
            <span> {{ startDate }}~{{ endDate }} </span>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li class="change-date-li">
              <button class="change-date-button" @click="getPass1Days">
                最近1天
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getPass7Days">
                最近7天
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getRecentMonth">
                最近30天
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getRecentYear">
                最近一年
              </button>
            </li>
            <li class="change-date-li">
              <button
                type="button"
                class="change-date-button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                自訂
              </button>
            </li>
            <li class="change-date-li">
              <button class="change-date-button" @click="getRecentAll">
                全部
              </button>
            </li>
          </ul>
          <p
            class="ms-2"
            style="
              position: absolute;
              color: #c4c4c4;
              font-size: 14px;
              right: 5px;
            "
          >
            {{ endDate }} 為最近一筆投放資料
          </p>
        </div>
        <!-- Modal -->
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <DatePicker
                  v-model="vue3Date"
                  is-range
                  is-expanded
                  :model-config="modelConfig"
                  :max-date="hasDataEnd"
                  :min-date="hasDataFirst"
                />
                <p>{{ startDate }}~{{ endDate }}</p>

                <button
                  class="btn confrim-btn"
                  @click="customDatePicker"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  選取
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-4" style="padding: 0px">
        <div class="col-12">
          <div class="d-flex flex-wrap result-second-row">
              <div class="col-12 mb-4" style="color: #71afb6; font-size: 20px">
                投放成效
              </div>
            <div class="d-flex flex-wrap" style="width: 65%">
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">寄出數量</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.寄出數量.toLocaleString() }}&nbsp;(次)
                  </span>
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">送達率</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.送達率.toFixed(2) }}%</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.送達數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_送達率 != null &&
                    apiEmailRate.增加_送達率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_送達率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_送達率 != null &&
                    apiEmailRate.增加_送達率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_送達率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">開信率</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.開信率.toFixed(2) }}(%)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.開信數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_開信率 != null &&
                    apiEmailRate.增加_開信率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_開信率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_開信率 != null &&
                    apiEmailRate.增加_開信率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_開信率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">點擊率</div>
                <div class="d-flex align-items-end flex-wrap">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.點擊率.toFixed(2) }}(%)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.點擊數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_點擊率 != null &&
                    apiEmailRate.增加_點擊率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_點擊率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_點擊率 != null &&
                    apiEmailRate.增加_點擊率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_點擊率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">轉換數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.轉換率.toFixed(2) }}(%)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.轉換數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_轉換率 != null &&
                    apiEmailRate.增加_轉換率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_轉換率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_轉換率 != null &&
                    apiEmailRate.增加_轉換率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_轉換率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">抱怨數</div>
                <div class="d-flex align-items-end flex-wrap">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.抱怨率.toFixed(2) }}(%)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.抱怨數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_抱怨率 != null &&
                    apiEmailRate.增加_抱怨率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_抱怨率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_抱怨率 != null &&
                    apiEmailRate.增加_抱怨率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_抱怨率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">取消訂閱數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.取消訂閱率.toFixed(2) }}(%)
                  </span>
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.取消訂閱數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_取消訂閱率 != null &&
                    apiEmailRate.增加_取消訂閱率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_取消訂閱率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_取消訂閱率 != null &&
                    apiEmailRate.增加_取消訂閱率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_取消訂閱率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <div class="titleTag" style="color: #c4c4c4">未開信數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.未開信率.toFixed(2) }}(%)
                  </span>
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.未開信數.toLocaleString() }}次)
                  </span>
                </div>
                <div
                  style="color: #ff8b8b"
                  v-if="
                    apiEmailRate.增加_未開信率 != null &&
                    apiEmailRate.增加_未開信率 > 0
                  "
                >
                  <img
                    src="../assets/redUpArrow.svg"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  +{{ apiEmailRate.增加_未開信率.toFixed(2) }}(%)
                </div>
                <div
                  style="color: #71afb6"
                  v-else-if="
                    apiEmailRate.增加_未開信率 != null &&
                    apiEmailRate.增加_未開信率 < 0
                  "
                >
                  <img
                    src="../assets/green-down-arrow.png"
                    alt=""
                    style="position: relative; top: -1px; margin-right: 8px"
                  />
                  {{ apiEmailRate.增加_未開信率.toFixed(2) }}(%)
                </div>
              </div>
              <div class="col-4 mb-4">
                <!-- <span class="titleTag" style="color: #c4c4c4">花費成本</span>
                <div class="d-flex align-items-center">
                  <span class="title is-5">{{ apiEmailRate.花費成本 }}</span>
                  <span class="ms-3">&nbsp; &nbsp;($)</span>
                </div> -->
              </div>
            </div>
            <div class="" style="position: relative; width: 35%">
              <span
                class="text-center"
                style="position: absolute; top: 26%; left: 11%; color: #c4c4c4"
                v-if="this.pieSeries.every((d) => d === 0)"
              >
                <img src="../assets/missData.svg" alt="" style="width: 200px" />
                <br />
                此時間段無資料</span
              >
              <div class="text-center">僅開啟/點擊/轉換/未開啟</div>
              <apexchart
                class="mt-4"
                type="donut"
                height="350"
                :options="pieOptions"
                :series="pieSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="padding: 0px">
        <div class="col-12 p-0">
          <div class="d-flex justify-content-end" style="margin-top: 30px">
            <div class="title-button-div">
              <button
                class="button-nofocus"
                :class="openMail ? 'button-focus' : ''"
                @click="clickOpenMail"
              >
                開信
              </button>
              <button
                class="button-nofocus"
                :class="clickMail ? 'button-focus' : ''"
                @click="clickClickMail"
              >
                點擊
              </button>
            </div>
          </div>
          <div class="line-div" style="position: relative">
            <span class="titleTag">每小時的時間趨勢</span>
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="this.lineSeries[0] === undefined"
              >此時間段無資料</span
            >
            <apexchart
              type="line"
              height="170"
              :options="lineOptions"
              :series="lineSeries"
            ></apexchart>
          </div>
          <div class="line-div" style="position: relative">
            <span class="titleTag">每天的時間趨勢</span>
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="this.lineSeries2[0] === undefined"
              >此時間段無資料</span
            >
            <apexchart
              type="line"
              height="200"
              :options="lineOptions2"
              :series="lineSeries2"
            ></apexchart>
          </div>
          <div class="line-div" style="position: relative">
            <span class="titleTag">每星期的時間趨勢</span>
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="this.lineSeries3[0] === undefined"
              >此時間段無資料</span
            >
            <apexchart
              type="line"
              height="170"
              :options="lineOptions3"
              :series="lineSeries3"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi3 } from "../../public/static3/api/apigClient.js";
import moment from "moment";
import VueApexCharts from "vue3-apexcharts";
import { Calendar, DatePicker } from "v-calendar";
export default {
  name: "LatestResult",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
  },
  data() {
    return {
      notshow: false,
      loading: false,
      vue3Date: {
        // start: "",
        // end: "",
        //  start: new Date("2022-01-06").toISOString().slice(0, 10),
        // end: new Date("2022-01-10").toISOString().slice(0, 10),
        start: new Date("2021-12-09").toISOString().slice(0, 10),
        end: new Date("2022-01-10").toISOString().slice(0, 10),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        // timeAdjust: "12:00:00",
      },
      endYear: 2022,
      endMonth: 6,

      pieSeries: [],
      pieOptions: {
        chart: {
          height: 200,
          type: "donut",
        },
        colors: ["#96C5D7", "#F9C357", "#FC9F66", "#ABABAB"],
        labels: ["僅開啟", "點擊", "轉換", "未開啟"],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: "bold",
          fontSize: "16px",

          //  floating: true,
        },
      },

      lineSeries: [
        {
          data: [],
        },
      ],
      lineOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#71AFB6"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [
            "01/01/2011 GMT",
            "01/02/2011 GMT",
            "01/03/2011 GMT",
            "01/04/2011 GMT",
            "01/05/2011 GMT",
            "01/06/2011 GMT",
          ],
          title: {
            text: "（點）",
          },
        },
        yaxis: {
          title: {
            text: "次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
      lineSeries2: [
        {
          data: [],
        },
      ],
      lineOptions2: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#71AFB6"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [
            "01/01/2011 GMT",
            "01/02/2011 GMT",
            "01/03/2011 GMT",
            "01/04/2011 GMT",
            "01/05/2011 GMT",
            "01/06/2011 GMT",
          ],
          title: {
            text: "（日期）",
          },
        },
        yaxis: {
          title: {
            text: "次數",
          },

          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
      lineSeries3: [
        {
          data: [],
        },
      ],
      lineOptions3: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },
        colors: ["#71AFB6"],
        responsive: [
          {
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: "（星期）",
          },
        },
        yaxis: {
          title: {
            text: "次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },

      openMail: true,
      clickMail: false,

      dateSelector: "",

      startDate: "2021-11-10",
      endDate: "2022-01-10",
      hasDataFirst: "",
      hasDataEnd: "",
      apiEmailRate: {
        取消訂閱數: 0,
        取消訂閱率: 0,
        增加_寄出率: 0,
        增加_送達率: 0,
        增加_開信率: 0,
        增加_點擊率: 0,
        增加_轉換率: 0,
        增加_抱怨率: 0,
        增加_取消訂閱率: 0,
        增加_未開信率: 0,
        寄出數量: 0,
        抱怨數: 0,
        抱怨率: 0,
        未開信數: 0,
        未開信率: 0,
        轉換數: 0,
        轉換率: 0,
        送達數: 0,
        送達率: 0,
        開信數: 0,
        開信率: 0,
        點擊數: 0,
        點擊率: 0,
      },
      lineData: {},

      performance_end_date:'',
    };
  },

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();
    this.highlightVue3Date();

    this.loading = false;
  },

  // beforeMount() {
  //   // await this.getTime()
  //   this.getRangeWeek();
  // },

  methods: {
    getRangeWeek: async function () {
      await this.getTime();
      let rangeEnd = this.hasDataEnd;
      let rangeStart = moment(rangeEnd)
        .subtract(4, "weeks")
        .format()
        .slice(0, 10);

      this.vue3Date.start = rangeStart;
      this.vue3Date.end = rangeEnd;

      console.log("start", rangeStart);
      console.log("end", rangeEnd);

      console.log("ve4", this.vue3Date);
    },
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .emailOverallPeriodGet(params)
        .then((res) => {
          console.log(res);
          this.hasDataFirst = res.data.Data.schedule.start_date;
          this.hasDataEnd = res.data.Data.schedule.end_date;
          this.startDate = res.data.Data.schedule.start_date;
          this.endDate = res.data.Data.schedule.end_date;

          this.performance_end_date=res.data.Data.performance.end_date

          this.vue3Date.start = moment(this.endDate).add(-1, "days")._i;
          console.log("vue3date", this.vue3Date.start);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getOverallRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.performance_end_date,
      };
      await allapi3
        .emailOverallRateGet(params)
        .then((res) => {
          console.log("RateGet", res);
          this.apiEmailRate = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getOverallPie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.performance_end_date,
      };
      await allapi3
        .emailOverallPieGet(params)
        .then((res) => {
          console.log("PieGet", res);
          const pieData = res.data.Data;
          console.log(Object.values(pieData));
          this.pieSeries = [
            pieData.開啟,
            pieData.點擊,
            pieData.轉換,
            pieData.未開啟,
          ];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getOverallLine: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.performance_end_date,
      };
      await allapi3
        .emailOverallTrendGet(params)
        .then((res) => {
          console.log("3line chart", res);
          this.lineData = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
      this.changeLineChart();
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.getOverallRate(),
        this.getOverallPie(),
        this.getOverallLine(),
      ]);

      this.changeLineChart();
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "全部";
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      await Promise.all([
        this.getOverallRate(),
        this.getOverallPie(),
        this.getOverallLine(),
      ]);

      this.changeLineChart();
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近一年";
    },
    getRecentMonth: async function () {
      this.loading = true;
      console.log(this.hasDataEnd);
      if (this.hasDataEnd === "") {
        return (this.loading = false);
      }
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      await Promise.all([
        this.getOverallRate(),
        this.getOverallPie(),
        this.getOverallLine(),
      ]);

      this.changeLineChart();
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近30天";
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await Promise.all([
        this.getOverallRate(),
        this.getOverallPie(),
        this.getOverallLine(),
      ]);

      this.changeLineChart();
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近7天";
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await Promise.all([
        this.getOverallRate(),
        this.getOverallPie(),
        this.getOverallLine(),
      ]);

      this.changeLineChart();
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近1天";
    },
    customDatePicker: async function () {
      this.startDate = this.vue3Date.start.slice(0, 10);
      this.endDate = this.vue3Date.end.slice(0, 10);
      this.loading = true;

      await Promise.all([
        this.getOverallRate(),
        this.getOverallPie(),
        this.getOverallLine(),
      ]);

      this.changeLineChart();
      this.loading = false;
      this.dateSelector = "自訂";
    },
    changeLineChart() {
      console.log(this.lineData.開信.小時);
      if (
        this.lineData.開信.小時 === undefined ||
        this.lineData.點擊.小時 === undefined
      ) {
        this.lineSeries = [];
        this.lineOptions = {
          xaxis: {
            categories: [],
          },
        };
        this.lineSeries2 = [];
        this.lineOptions2 = {
          xaxis: {
            categories: [],
          },
        };
        this.lineSeries3 = [];
        this.lineOptions3 = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        if (this.openMail === true) {
          this.lineSeries = [
            {
              name: "次數",
              data: this.lineData.開信.小時.count,
            },
          ];
          this.lineOptions = {
            xaxis: {
              categories: this.lineData.開信.小時.hour,
            },
          };
          this.lineSeries2 = [
            {
              name: "次數",
              data: this.lineData.開信.日期.count,
            },
          ];
          this.lineOptions2 = {
            xaxis: {
              categories: this.lineData.開信.日期.date,
            },
          };
          this.lineSeries3 = [
            {
              name: "次數",
              data: this.lineData.開信.星期.count,
            },
          ];
          this.lineOptions3 = {
            xaxis: {
              categories: this.lineData.開信.星期.week,
            },
          };
        } else {
          this.lineSeries = [
            {
              name: "次數",
              data: this.lineData.點擊.小時.count,
            },
          ];
          this.lineOptions = {
            xaxis: {
              categories: this.lineData.點擊.小時.hour,
            },
          };
          this.lineSeries2 = [
            {
              name: "次數",
              data: this.lineData.點擊.日期.count,
            },
          ];
          this.lineOptions2 = {
            xaxis: {
              categories: this.lineData.點擊.日期.date,
            },
          };
          this.lineSeries3 = [
            {
              name: "次數",
              data: this.lineData.點擊.星期.count,
            },
          ];
          this.lineOptions3 = {
            xaxis: {
              categories: this.lineData.點擊.星期.week,
            },
          };
        }
      }
    },
    clickOpenMail() {
      this.openMail = true;
      this.clickMail = false;
      this.changeLineChart();
    },
    clickClickMail() {
      this.openMail = false;
      this.clickMail = true;
      this.changeLineChart();
    },
  },
};
</script>

<style scoped>
/* LatestResult */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
p {
  margin: 0;
}
.fs-small {
  font-size: 20px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding: 50px;
}
.titleTag {
  margin-bottom: 20px;
}
.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}
.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}
.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}
.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}
.result-second-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-top: 25px;
  padding: 15px 20px 0px;
  border-radius: 15px;
}
.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
  height: 356px;
}
.overallperformance-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
}
.bottomPie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  /* margin-right: 30px; */
  height: 885px;
}
.line-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  /* margin-right: 30px; */
  height: 252px;
}
.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}
.change-date-li:hover {
  background-color: #eaf3f4;
}
.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
.red {
  color: red;
}
.green {
  color: green;
}
.titleTag {
  color: #c4c4c4;
  font-size: 18px;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>