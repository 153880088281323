<template>
  <!-- backdrop -->
  <div
    id="CycleTimeDetail-backdrop"
    class="modal-backdrop fade show"
    style="display: none"
  ></div>
  <div>
    <!-- TouchList modal by id #TouchListModal -->
    <TouchListEmailDetail
      :event_name="this.$props.event_name"
      :dropDown_event="this.nowAction_emailDetail==='email'?['開信', '點擊', '送達', '退件']:['點擊', '送達']"
      :marketChannel="this.nowAction_emailDetail"
      :rule_id="this.vice_ruleID"
      :hasAB="this.hasAB"
      :zIndex="5000"
      :key="this.refreash"
    ></TouchListEmailDetail>
    <!-- main -->
    <div
      style="font-size: 22px"
      class="ms-2"
      v-if="
        this.$props.emailChannel === true ||
        this.$props.emailChannel === 'trigger_email'
      "
    >
      Email報告
    </div>
    <div
      style="font-size: 22px"
      class="ms-2"
      v-if="
        this.$props.emailChannel === false ||
        this.$props.emailChannel === 'trigger_sms'
      "
    >
      SMS報告
    </div>
    <div
      class="d-flex flex-wrap result-second-row"
      v-if="
        this.$props.emailChannel === true ||
        this.$props.emailChannel === 'trigger_email'
      "
    >
      <div
        class="col-12 d-flex align-items-center justify-content-between mb-4"
        style="color: #71afb6; font-size: 20px"
      >
        <span>投放成效</span>
        <button
          class="btn-basic bg-SkyBlue Cyan"
          style="width: 180px"
          @click="this.openTouchListEmailDetailModal('A')"
          v-if="this.$props.emailChannel === true"
        >
          查看互動名單
        </button>
      </div>
      <div class="col-xl-8 col-xxl-9 d-flex flex-wrap">
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">寄出數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px">
              {{ apiEmailRate.寄出數.toLocaleString() }}&nbsp;(次)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">送達數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.送達數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.送達率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">開信數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.開信數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.開信率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">點擊數</div>
          <div class="d-flex align-items-end flex-wrap">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.點擊數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.點擊率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">轉換數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.轉換數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.轉換率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">抱怨數</div>
          <div class="d-flex align-items-end flex-wrap">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.抱怨數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.抱怨率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">取消訂閱數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px">
              {{ apiEmailRate.取消訂閱數.toLocaleString() }}&nbsp;(次)
            </span>
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.取消訂閱率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">未開信數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px">
              {{ apiEmailRate.未開信數.toLocaleString() }}&nbsp;(次)
            </span>
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.未開信率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <!-- <span class="titleTag" style="color: #c4c4c4">花費成本</span>
                <div class="d-flex align-items-center">
                  <span class="title is-5">{{ apiEmailRate.花費成本 }}</span>
                  <span class="ms-3">&nbsp; &nbsp;($)</span>
                </div> -->
        </div>
      </div>
      <div class="col-xl-4 col-xxl-3" style="position: relative">
        <div class="text-center">僅開啟/點擊/轉換/未開啟</div>
        <div
          class="text-center"
          style="position: absolute; top: 27%; left: 10%; color: #c4c4c4"
          v-if="this.seriesPie.every((d) => d == 0)"
        >
          <img src="../assets/missData.svg" alt="" style="width: 180px" />
          <br />
          此時間段無資料
        </div>
        <apexchart
          class="mt-5"
          type="donut"
          height="300"
          :options="chartPieOptions"
          :series="seriesPie"
        >
        </apexchart>
      </div>
    </div>
    <div
      class="d-flex flex-wrap result-second-row"
      style="height: auto"
      v-if="
        this.$props.emailChannel === false ||
        this.$props.emailChannel === 'trigger_sms'
      "
    >
      <div
        class="col-12 d-flex align-items-center justify-content-between mb-4"
        style="color: #71afb6; font-size: 20px"
      >
        <span>投放成效</span>
        <button
          class="btn-basic bg-SkyBlue Cyan"
          style="width: 180px"
          @click="this.openTouchListEmailDetailModal('A')"
          v-if="this.$props.emailChannel === false"
        >
          查看互動名單
        </button>
      </div>
      <div class="col-xl-8 col-xxl-9 d-flex flex-wrap">
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">寄出數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px">
              {{ apiEmailRate.寄出數.toLocaleString() }}&nbsp;(次)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">送達數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.送達數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.送達率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <!-- <p class="titleTag">花費成本</p>
              <div class="d-flex align-items-center">
                <p class="titleText">{{ rateData.花費成本 }}</p>
                <span class="mid-span">($)</span>
              </div> -->
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">點擊數</div>
          <div class="d-flex align-items-end flex-wrap">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.點擊數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.點擊率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">轉換數</div>
          <div class="d-flex align-items-end">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.轉換數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.轉換率.toFixed(2) }}%)
            </span>
          </div>
        </div>
        <div class="col-4">
          <div class="titleTag" style="color: #c4c4c4">未點擊數</div>
          <div class="d-flex align-items-end flex-wrap">
            <span class="m-0" style="font-size: 22px"
              >{{ apiEmailRate.未點擊數.toLocaleString() }}&nbsp;(次)</span
            >
            <span class="ms-3" style="color: #71afb6; font-size: 14px">
              ({{ apiEmailRate.未點擊率.toFixed(2) }}%)
            </span>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-xxl-3" style="position: relative">
        <div class="text-center">點擊/轉換/未點擊</div>
        <div
          class="text-center"
          style="position: absolute; top: 23%; left: 10%; color: #c4c4c4"
          v-if="this.seriesPie2.every((d) => d == 0)"
        >
          <img src="../assets/missData.svg" alt="" style="width: 180px" />
          <br />
          此時間段無資料
        </div>
        <apexchart
          class="mt-2"
          type="donut"
          height="240"
          :options="chartPieOptions2"
          :series="seriesPie2"
        >
        </apexchart>
      </div>
    </div>
    <div
      class="d-flex flex-wrap bar-div"
      v-if="typeof this.$props.emailChannel === 'boolean'"
    >
      <div class="d-flex align-items-center col-12">
        <p style="color: #71afb6">
          發送表現
          <BtnInfoBox
            content="此為投放後所得到的成效時間點與其數量，輕鬆掌握發送後表現。"
            direction="right"
          ></BtnInfoBox>

          <!-- <b-button v-b-popover.hover.top="'Popover!'" variant="primary">Top</b-button> -->
        </p>
      </div>
      <div class="col-12" style="position: relative">
        <span
          style="position: absolute; top: 35%; left: 45%; color: #c4c4c4"
          v-if="
            this.stackBarSeries.every(
              (d) => d.data.every((d) => d == 0) == true
            )
          "
          >此時間段無資料</span
        >
        <apexchart
          type="bar"
          height="300"
          :options="stackBarOptions"
          :series="stackBarSeries"
        ></apexchart>
      </div>
    </div>
    <div class="d-flex flex-wrap bar-div" v-else>
      <div
        class="d-flex align-items-center justify-content-between col-12"
        style="color: #71afb6"
      >
        <div>
          時序分析
          <BtnInfoBox
            content="此為該信件寄出後，被回應的時間點與其對應次數，可看出劇本信成效的平均時序狀況。"
            direction="right"
          ></BtnInfoBox>
        </div>
        <div class="group-btn d-flex" style="width: 200px">
          <button
            class="basic-btn"
            :class="this.triggerTrendTime === '48小時' ? 'highlight-btn' : ''"
            @click="changeTriggerTrend('48小時')"
          >
            前48小時
          </button>
          <button
            class="basic-btn"
            :class="this.triggerTrendTime === '48小時' ? '' : 'highlight-btn'"
            @click="changeTriggerTrend('15天')"
          >
            前15天
          </button>
        </div>
      </div>
      <div class="col-12" style="position: relative">
        <span
          style="position: absolute; top: 35%; left: 45%; color: #c4c4c4"
          v-if="
            this.stackBarSeries.every(
              (d) => d.data.every((d) => d == 0) == true
            )
          "
          >此時間段無資料</span
        >
        <apexchart
          type="bar"
          height="300"
          :options="lineOptions"
          :series="lineSeries"
        ></apexchart>
      </div>
    </div>
    <div class="result-second-row" style="height: auto; min-height: 200px">
      <div class="d-flex align-items-center p-0">
        <p style="color: #71afb6">連結點擊表現</p>
      </div>
      <div
        class="text-center p-0"
        style="color: #c4c4c4; line-height: 100px"
        v-if="linkTable[0] === undefined"
      >
        此投放的連結尚未被點擊
      </div>
      <div class="p-0" v-else>
        <DataTable :value="linkTable" showGridlines responsiveLayout="scroll">
          <Column field="連結" header="連結">
            <template #body="slotProps">
              <div>
                <a :href="slotProps.data.連結" target="_blank">{{
                  slotProps.data.連結
                }}</a>
              </div>
            </template>
          </Column>
          <Column field="不重複點擊次數" header="不重複點擊數"  style="min-width: 160px"></Column>
          <Column field="重複點擊次數" header="重複點擊數"  style="min-width: 160px"></Column>
          <Column field="互動名單" header="互動名單" style="min-width: 160px">
              <template #body="slotProps">
                <div
                  class="GrayC4"
                  style="text-decoration: underline; cursor: pointer"
                  :title="slotProps.data.連結"
                  @click="this.openTouchListEmailDetailModal(slotProps.data.連結)"
                >
                  查看
                </div>
              </template>
            </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import TouchListEmailDetail from "../components/TouchListEmailDetail.vue";
import BtnInfoBox from "./BtnInfoBox.vue";

export default {
  name: "EmailDetail",
  components: {
    apexchart: VueApexCharts,
    DataTable,
    Column,
    TouchListEmailDetail,
    BtnInfoBox,
  },
  props: {
    emailChannel: [String, Boolean],
    topChannel: String,
    vice_ruleID: String,
    event_name: String,
  },
  data() {
    return {
      nowAction_emailDetail:'',
      refreash:0,
      TouchListEmailDetail:{},
      triggerTrendTime: "48小時",
      triggerTrend: {},
      isShow: false,

      linkTable: [],
      apiEmailPie: {
        未開啟: 0,
        轉換: 0,
        開啟: 0,
        點擊: 0,
      },
      apiEmailTrend: {
        click: [0],
        open: [0],
        purchase: [0],
        time: [""],
      },
      apiEmailRate: {
        取消訂閱數: 0,
        取消訂閱率: 0,
        寄出數: 0,
        抱怨數: 0,
        抱怨率: 0,
        未開信數: 0,
        未開信率: 0,
        模板名稱: "",
        活動名稱: "",
        活動時間: "",
        發送客群: { excludeLabelNames: [], includeLabelNames: [] },
        發送日期: "",
        轉換數: 0,
        轉換率: 0,
        送達數: 0,
        送達率: 0,
        開信數: 0,
        開信率: 0,
        點擊數: 0,
        點擊率: 0,
        pie: { 未點擊: 0, 轉換: 0, 點擊: 0 },
        template_id: "",
        未點擊數: 0,
        未點擊率: 0,
      },

      seriesPie: [],
      chartPieOptions: {},
      seriesPie2: [],
      chartPieOptions2: {},
      stackBarSeries: [],
      stackBarOptions: {},
      lineSeries: [],
      lineOptions: {},

      hasAB:'',
    };
  },
  created: async function () {
    console.log(this.emailChannel)
    if(this.emailChannel){
      this.nowAction_emailDetail='email'
    }else{
      this.nowAction_emailDetail='sms'
    }
  },
  watch: {
    vice_ruleID: {
      handler: async function (newval, oldval) {
        console.log("this.$props.emailChannel", this.$props.emailChannel);
        console.log("this.$props.topChannel", this.$props.topChannel);
        this.refreash++
        if (
          this.$props.emailChannel === true &&
          this.$props.topChannel == "email"
        ) {
          await Promise.all([
            this.postEmailRate(),
            this.postEmailPie(),
            this.postEmailTrend(),
            this.postEmailLinkData(),
          ]);
          this.DemoPieChart();
          this.stackBarChart();
        } else if (
          this.$props.emailChannel === false &&
          this.$props.topChannel == "sms"
        ) {
          await Promise.all([
            this.postSmsRate(),
            this.postSmsTrend(),
            this.postSmsLinkData(),
          ]);
          this.DemoPieChart2();
          this.stackBarChart2();
        } else if (
          this.$props.emailChannel === true &&
          this.$props.topChannel == "sms"
        ) {
          await this.postCrossChannelPerformance();
          this.DemoPieChart();
          this.stackBarChart();
        } else if (
          this.$props.emailChannel === false &&
          this.$props.topChannel == "email"
        ) {
          await this.postCrossChannelPerformance();
          this.DemoPieChart2();
          this.stackBarChart2();
        } else if (this.$props.emailChannel === "trigger_email") {
          console.log("this.$props.emailChannel", this.$props.emailChannel);

          await Promise.all([
            this.postTriggerEmailRate(),
            this.postTriggerEmailTrend(),
            this.postTriggerEmailLinkData(),
          ]);
          this.DemoPieChart();
          this.lineChart();
        } else if (this.$props.emailChannel === "trigger_sms") {
          console.log("this.$props.emailChannel", this.$props.emailChannel);
          await Promise.all([
            this.postTriggerSmsRate(),
            this.postTriggerSmsTrend(),
            this.postTriggerSmsLinkData(),
          ]);
          this.DemoPieChart2();
          this.lineChart2();
        }
      },
      immediate: true,
    },
    TouchListEmailDetail: {
      handler: function () {
        if (this.TouchListEmailDetail._isShown == false) {
          const backdrop = document.getElementById("CycleTimeDetail-backdrop");
          backdrop.style["display"] = "none";
        }
      },
      deep: true,
    },
  },
  methods: {
    // 開頭劇本動作跟中間不一致時CALL的detail成效
    postCrossChannelPerformance: async function () {
      let type = "";
      if (this.$props.topChannel == "email") {
        type = "sms";
      } else {
        type = "email";
      }
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        script_id: this.$props.vice_ruleID,
        types: type,
      };

      await allapi3
        .overallTotalperformancePost(params, body)
        .then((res) => {
          console.log("開頭劇本動作跟中間不一致時CALL的detail成效", res);
          console.log("this.$props.topChannel", this.$props.topChannel);
          if (this.$props.topChannel === "email") {
            this.apiEmailPie = {
              點擊: res.data.pie.點擊,
              轉換: res.data.pie.轉換,
              未點擊: res.data.pie.未點擊,
            };
          } else {
            this.apiEmailPie = {
              僅開啟: res.data.pie.開啟,
              點擊: res.data.pie.點擊,
              轉換: res.data.pie.轉換,
              未開啟: res.data.pie.未開啟,
            };
          }
          this.apiEmailTrend = res.data.trend;
          this.apiEmailRate = res.data.rate;
          this.linkTable = res.data.url;
          console.log("this.apiEmailPie", this.apiEmailPie);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("delete連線有誤" + err);
          this.loading = false;
        });
    },

    openTouchListEmailDetailModal(type_url) {
      this.hasAB=type_url
      this.TouchListEmailDetail = new bootstrap.Modal(
        document.getElementById("TouchListEmailDetailModal"),
        {
          keyboard: false,
        }
      );
      this.TouchListEmailDetail.show();
      const backdrop = document.getElementById("CycleTimeDetail-backdrop");
      backdrop.style["display"] = "block";
    },
    postEmailRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      allapi3
        .emailEventRateGet(params)
        .then((res) => {
          console.log("投放成效 email", res);
          this.apiEmailRate = res.data.Data;

          this.saveEventName = res.data.Data.活動名稱;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postEmailPie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .emailEventPieGet(params)
        .then((res) => {
          console.log("emailpie", res);
          res.data.Data = {
            僅開啟: res.data.Data.開啟,
            點擊: res.data.Data.點擊,
            轉換: res.data.Data.轉換,
            未開啟: res.data.Data.未開啟,
          };
          this.apiEmailPie = res.data.Data;
          // console.log("mailpie", this.apiEmailPie.未寄出);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postEmailTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .emailEventTrendGet(params)
        .then((res) => {
          console.log("emailTrend", res);
          this.apiEmailTrend = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postEmailLinkData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .emailEventUrlGet(params)
        .then((res) => {
          console.log("EventUrlGet", res);
          this.linkTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postSmsRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .smsEventRateGet(params)
        .then((res) => {
          console.log("投放成效 sms", res);
          if (res.data.寄出數 != undefined) {
            this.apiEmailRate = res.data;
            this.apiEmailPie = {
              點擊: res.data.pie.點擊,
              轉換: res.data.pie.轉換,
              未點擊: res.data.pie.未點擊,
            };
          } else {
            this.apiEmailPie = {
              點擊: 0,
              轉換: 0,
              未點擊: 0,
            };
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postSmsTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .smsEventTrendGet(params)
        .then((res) => {
          console.log("smsTrend", res);
          this.apiEmailTrend = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postSmsLinkData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .smsEventUrlGet(params)
        .then((res) => {
          console.log("sms EventUrlGet", res);
          this.linkTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    // 觸發劇本要得api
    postTriggerEmailRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .triggerScriptEmailRateGet(params)
        .then((res) => {
          console.log("TriggerEmail投放成效", res);
          this.apiEmailRate = res.data.Data;
          this.apiEmailPie = res.data.Data.pie;

          this.saveEventName = res.data.Data.活動名稱;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postTriggerEmailTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .triggerScriptEmailTrendGet(params)
        .then((res) => {
          console.log("TriggerEmail Trend", res);
          this.triggerTrend = res.data.Data;
          this.apiEmailTrend = res.data.Data["48小時"];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postTriggerEmailLinkData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .triggerScriptEmailUrlGet(params)
        .then((res) => {
          console.log("TriggerEmail UrlGet", res);
          this.linkTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postTriggerSmsRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .triggerScriptSmsRateGet(params)
        .then((res) => {
          console.log("TriggerSms 投放成效", res);
          this.apiEmailRate = res.data;
          this.apiEmailPie = {
            點擊: res.data.pie.點擊,
            轉換: res.data.pie.轉換,
            未點擊: res.data.pie.未點擊,
          };
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postTriggerSmsTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .triggerScriptSmsTrendGet(params)
        .then((res) => {
          console.log("TriggerSms Trend", res);
          this.apiEmailTrend = res.data.Data["48小時"];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postTriggerSmsLinkData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.$props.vice_ruleID,
      };
      await allapi3
        .triggerScriptSmsUrlGet(params)
        .then((res) => {
          console.log("TriggerSms UrlGet", res);
          this.linkTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    // 畫甜甜圈圖 觸發跟行銷劇本都有
    DemoPieChart() {
      console.log("pieipe", Object.values(this.apiEmailPie));
      (this.seriesPie = Object.values(this.apiEmailPie).map((d) => {
        return parseInt(d);
      })),
        (this.chartPieOptions = {
          chart: {
            height: 300,
            type: "donut",
          },
          colors: ["#96C5D7", "#F9C357", "#FC9F66", "#ABABAB"],
          labels: Object.keys(this.apiEmailPie),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //floating: true,
          },
        });
    },
    DemoPieChart2() {
      console.log("here", Object.values(this.apiEmailPie));
      (this.seriesPie2 = Object.values(this.apiEmailPie).map((d) => {
        return parseInt(d);
      })),
        (this.chartPieOptions2 = {
          chart: {
            height: 240,
            type: "donut",
          },
          colors: ["#F9C357", "#FC9F66", "#ABABAB"],
          labels: Object.keys(this.apiEmailPie),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //floating: true,
          },
        });
    },
    // 畫非觸發劇本柱狀圖
    stackBarChart() {
      (this.stackBarSeries = [
        {
          name: "轉換次數",
          data: this.apiEmailTrend.purchase,
        },
        {
          name: "點擊次數",
          data: this.apiEmailTrend.click,
        },
        {
          name: "開信次數",
          data: this.apiEmailTrend.open,
        },
      ]),
        (this.stackBarOptions = {
          chart: {
            type: "bar",
            height: 300,
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
            zoom: {
              enabled: true,
            },
          },

          // responsive: [
          //   {
          //     // breakpoint: 480,
          //     options: {
          //       legend: {
          //         position: "bottom",
          //         offsetX: -10,
          //         offsetY: 0,
          //       },
          //     },
          //   },
          // ],
          colors: ["#FC9F66", "#F9C357", "#96C5D7"],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {
            // type: "datetime",
            categories: this.apiEmailTrend.time,
            title: {
              text: "日期",
            },
          },
          yaxis: {
            title: {
              text: "次數",
              style: {
                cssClass: "apexchart-label",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toFixed(0);
              },
            },
          },
          tooltip: {
            y: {
              formatter: (y) => {
                return y.toFixed(0);
              },
            },
          },
          legend: {
            position: "bottom",
            offsetY: 3,
            offsetX: -10,
          },
          fill: {
            opacity: 1,
          },
        });
    },
    stackBarChart2() {
      console.log("apiEmailTrend", this.apiEmailTrend);
      if (this.apiEmailTrend.purchase[0] === undefined) {
        return;
      }
      (this.stackBarSeries = [
        {
          name: "轉換次數",
          data: this.apiEmailTrend.purchase,
        },
        {
          name: "點擊次數",
          data: this.apiEmailTrend.click,
        },
      ]),
        (this.stackBarOptions = {
          chart: {
            type: "bar",
            height: 300,
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
            zoom: {
              enabled: true,
            },
          },

          // responsive: [
          //   {
          //     // breakpoint: 480,
          //     options: {
          //       legend: {
          //         position: "bottom",
          //         offsetX: -10,
          //         offsetY: 0,
          //       },
          //     },
          //   },
          // ],
          colors: ["#FC9F66", "#F9C357"],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {
            // type: "datetime",
            categories: this.apiEmailTrend.date,
            title: {
              text: "日期",
            },
          },
          yaxis: {
            title: {
              text: "次數",
              style: {
                cssClass: "apexchart-label",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toFixed(0);
              },
            },
          },
          tooltip: {
            y: {
              formatter: (y) => {
                return y.toFixed(0);
              },
            },
          },
          legend: {
            position: "bottom",
            offsetY: 3,
            offsetX: -10,
          },
          fill: {
            opacity: 1,
          },
        });
    },
    // 畫觸發劇本才有的線圖
    lineChart() {
      (this.lineSeries = [
        {
          name: "開信次數",
          data: this.apiEmailTrend.open,
        },
        {
          name: "點擊次數",
          data: this.apiEmailTrend.click,
        },
        {
          name: "轉換次數",
          data: this.apiEmailTrend.purchase,
        },
      ]),
        (this.lineOptions = {
          chart: {
            height: 270,
            type: "line",
            dropShadow: {
              enabled: false,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#F9C357", "#63A7C8", "#FC9F66"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          title: {
            align: "left",
            style: {
              fontSize: "20px",
              fontWeight: "medium",
              color: "#71AFB6",
            },
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: "category",
            categories: this.apiEmailTrend.time,
            // tickAmount: 7,
            labels: {
              show: true,
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            title: {
              text: "人數",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            offsetY: 6,
            offsetX: -5,
          },
        });
    },
    lineChart2() {
      (this.lineSeries = [
        {
          name: "點擊次數",
          data: this.apiEmailTrend.click,
        },
        {
          name: "轉換次數",
          data: this.apiEmailTrend.purchase,
        },
      ]),
        (this.lineOptions = {
          chart: {
            height: 270,
            type: "line",
            dropShadow: {
              enabled: false,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#FC9F66"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          title: {
            align: "left",
            style: {
              fontSize: "20px",
              fontWeight: "medium",
              color: "#71AFB6",
            },
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: "category",
            categories: this.apiEmailTrend.time,
            // tickAmount: 7,
            labels: {
              show: true,
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            title: {
              text: "人數",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            offsetY: 6,
            offsetX: -5,
          },
        });
    },

    changeTriggerTrend(triggerTrendTime) {
      if (triggerTrendTime === "15天") {
        this.triggerTrendTime = "15天";
        this.apiEmailTrend = this.triggerTrend["15天"];
      } else {
        this.triggerTrendTime = "48小時";
        this.apiEmailTrend = this.triggerTrend["48小時"];
      }
    },
  },
};
</script>

<style scoped>
:deep(.p-datatable table) {
  /* border-radius: 15px;
  border-collapse: separate;
  border-spacing: 1px;
  border: 1px solid #dee2e6; */
  border-collapse: collapse;
  border-radius: 15px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #575757; /* this draws the table border  */
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th) {
  border-width: 1px 1px 1px 1px;
}
:deep(th:last-child) {
  border-top-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  transition: box-shadow 0.2s;
}
:deep(.p-datatable-wrapper) {
  box-shadow: none;
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td) {
  border-width: 1px 1px 1px 1px;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: center;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 5px;
  line-height: 45px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td:last-child) {
  border-bottom-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td:first-child) {
  border-bottom-left-radius: 15px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td) {
  border-bottom: 1px solid #dee2e6 !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  /* border-bottom: none !important; */
}

.child-popout11 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 286px;
  right: -300px;
  top: -42px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}

.titleTag {
  margin-bottom: 20px;
}
.result-second-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-top: 25px;
  padding: 15px 20px 24px;
  border-radius: 15px;
}
.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-top: 25px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  height: 380px;
}
</style>