<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium">會員銷售</span>
        <div class="title-button-div fs-small">
          <button
            class="button-nofocus"
            :class="all ? 'button-focus' : ''"
            @click="getall"
          >
            全部
          </button>
          <button
            class="button-nofocus"
            :class="online ? 'button-focus' : ''"
            @click="getonline"
          >
            線上
          </button>
          <button
            class="button-nofocus"
            :class="offline ? 'button-focus' : ''"
            @click="getoffline"
          >
            線下
          </button>
        </div>
      </div>

      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2 mb-5"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <DatePicker
                :from-page="{ month: this.endMonth, year: this.endYear }"
                @dayclick="onDayClick"
                v-model="vue3Date"
                is-expanded
                is-range
                :model-config="modelConfig"
                :min-date="hasDataFirst"
                :max-date="hasDataEnd"
              />
              <!-- :max-date="hasDataEnd" -->
              <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
                {{ vue3Date.start }}~{{ vue3Date.end }}
              </p>

              <button
                class="btn confrim-btn mt-3"
                @click="customDatePicker"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                選取
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4" style="padding: 0px">
      <div class="d-flex flex-wrap">
        <div class="col-5">
          <div class="member-div" style="margin-top: 5px">
            <span class="fs-small" style="color: #71afb6">會員總數</span>
            <div id="donut" style="position: relative">
              <span
                class="text-center"
                style="position: absolute; top: 21%; left: 35%; color: #c4c4c4"
                v-if="this.memberBarSeries[0].data.every((d) => d === 0)"
              >
                此時間段無資料</span
              >
              <apexchart
                type="bar"
                height="110"
                :options="memberBarOptions"
                :series="memberBarSeries"
              ></apexchart>
            </div>
          </div>
          <div class="pie-div" style="margin-top: 22px">
            <span
              class="fs-small"
              style="color: #71afb6"
              v-if="this.onlineAndOffilne === '整體'"
              >會員營業額占比</span
            >
            <span
              class="fs-small"
              style="color: #71afb6"
              v-else-if="this.onlineAndOffilne === '線上'"
              >各通路營業額占比</span
            >
            <span
              class="fs-small"
              style="color: #71afb6"
              v-else-if="this.onlineAndOffilne === '線下'"
              >會員/非會員 營業額占比</span
            >
            <BtnInfoBox content="根據每次會員消費時所透過的渠道來源。" direction="right"></BtnInfoBox>
            <div id="donut" style="position: relative">
              <span
                class="text-center"
                style="position: absolute; top: 12%; left: 30%; color: #c4c4c4"
                v-if="this.pieSeries.every((d) => d === 0)"
              >
                <img src="../assets/missData.svg" alt="" style="width: 160px" />
                <br />
                此時間段無資料</span
              >
              <apexchart
                type="donut"
                height="230"
                :options="pieOptions"
                :series="pieSeries"
              ></apexchart>
            </div>
          </div>
        </div>
        <div class="col-7 line-div-top-right" style="margin-top: 5px">
          <span
            class="fs-small"
            style="color: #71afb6"
            v-if="this.onlineAndOffilne === '整體'"
            >會員消費走勢</span
          >
          <span
            class="fs-small"
            style="color: #71afb6"
            v-else-if="this.onlineAndOffilne === '線上'"
            >各通路會員消費走勢</span
          >
          <span
            class="fs-small"
            style="color: #71afb6"
            v-else-if="this.onlineAndOffilne === '線下'"
            >會員/非會員 消費走勢</span
          >
          <div id="line" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%"
              v-if="this.seletedApiChannelLine === undefined"
              >此時間段無資料</span
            >
            <apexchart
              type="line"
              height="370"
              :options="lineOptions"
              :series="lineSeries"
            ></apexchart>
            <!-- <iframe width="1202" height="721" src="https://www.youtube.com/embed/sIsCELMpeWk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          </div>
        </div>
        <div class="col-8 pe-2">
        <div class=" line-div " style="margin-top: 25px;">
          <span class="fs-small" style="color: #71afb6">銷售輪廓樣貌分析</span>
          <div id="line" style="position: relative">
            <span
              style="position: absolute; top: 35%; left: 40%; color: #c4c4c4"
              v-if="this.barseries[0].data.every((d) => d === 0)"
              >此時間段無資料</span
            >
            <div id="chart">
              <apexchart
                type="bar"
                height="320"
                :options="barchartOptions"
                :series="barseries"
              ></apexchart>
            </div>
            <!-- <iframe width="1202" height="721" src="https://www.youtube.com/embed/sIsCELMpeWk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          </div>
        </div>
      </div>
      <div class="col-4 ps-2">
        <div  class=" line-div  " style="margin-top: 25px">
          <span class="fs-small" style="color: #71afb6">購買頻率占比</span>
          <div id="donut2" style="position: relative; ">
          <span
              style="position: absolute; top: 20%; left: 30%; color: #c4c4c4"
              v-if="this.pieBuyFreqSeries.every((d) => d === null)"
              >     <img src="../assets/missData.svg" alt="" style="width: 160px" />
                <br />
                此時間段無資料</span
            >
           
            <apexchart
                type="donut"
                height="520"
                :options="pieBuyFreqOptions"
                :series="pieBuyFreqSeries"
              ></apexchart>
           
              </div>
        </div>
      </div>

        <div class="col-12 line-div" style="margin-top: 25px">
          <div class="d-flex align-items-center">
            <span class="fs-small me-4" style="color: #71afb6"
              >會員銷售地區分布</span
            >
            <div class="title-button-div fs-small me-2">
              <button
                class="button-nofocus"
                :class="this.selectedMixPeople === '會員' ? 'button-focus' : ''"
                @click="clickMixReceiver()"
              >
                購買人
              </button>
              <button
                class="button-nofocus"
                :class="
                  this.selectedMixPeople === '收貨人' ? 'button-focus' : ''
                "
                @click="clickMixMember()"
              >
                收貨地址
              </button>
            </div>
            <Dropdown
              style="width: 270px"
              :valueArr="this.dropdownValueArr"
              :selectValue="selectedCityIndex"
              :changingSelected="'selectedCity'"
              v-on:selectValueByDrop="selectValueByDrop"
            />
          </div>
          <div id="line" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="this.mixSeries[0] === undefined"
              >此時間段無資料</span
            >
            <div id="lineBarchart">
              <apexchart
                type="bar"
                height="320"
                :options="mixOptions"
                :series="mixSeries"
              ></apexchart>
            </div>
            <!-- <iframe width="1202" height="721" src="https://www.youtube.com/embed/sIsCELMpeWk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../components/Dropdown.vue";
import { allapi } from "../../public/static/api/apigClient.js";
import moment from "moment";
import VueApexCharts from "vue3-apexcharts";
import { Calendar, DatePicker } from "v-calendar";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "MembershipSales",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    Dropdown,
    BtnInfoBox,
  },
  data() {
    return {
      loading: false,
      memberShow2: false,

      vue3Date: {
        start: "",
        end: "",
        // start: new Date("2022-01-06").toISOString().slice(0, 10),
        // end: new Date("2022-01-10").toISOString().slice(0, 10),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        // timeAdjust: "12:00:00",
      },
      attrs: [
        {
          highlight: true,
          dates: { start: new Date("2022-03-01"), end: new Date("2022-03-18") },
        },
      ],
      endYear: 2022,
      endMonth: 6,

      dateSelector: "全部",
      // dount

      apiChannelMemberBar: [],
      apiChannelLine: [],
      apiChannelBar: [],
      apiBuyFreqPieData:[],
      apiBuyFreqPieLabel:[],

      online: false,
      offline: false,
      all: true,

      seletedApiChannelMemberBar: [],
      seletedApiChannelPie: [],
      seletedApiChannelLine: [],
      seletedApiChannelBar: {},
      seletedApiBuyFreqPie:[],
      seletedApiChannelBarRatio: {},
      selectedMixPeople: "會員",

      seletedchannelpie: [],
      seletedchannelline: [],

      memberBarSeries: [
        {
          data: [0, 0],
        },
      ],
      memberBarOptions: {},
      pieSeries: [],
      pieOptions: {},
      pieBuyFreqSeries:[],
      pieBuyFreqOptions:{},
      lineSeries: [],
      lineOptions: {
        chart: {
          height: 340,
          xaxis: {
            tickAmount: 3,
            // tickPlacement: 'between',
          },
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },

        colors: [
          "#71AFB6",
          "#F9C357",
          "#FC9F66",
          "#96C5D7",
          "#85A9CD",
          "#63A7C8",
        ],

        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        title: {
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "medium",
            color: "#71AFB6",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "營業額",
            style: {
              fontSize: "12px",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
          // min:auto,
          // max: auto,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          offsetY: 0,
          offsetX: 0,
        },
      },
      barseries: [
        {
          data: [0, 0],
        },
      ],
      barchartOptions: {},
      maleAllcount: 0,
      femaleAllcount: 0,
      mixSeries: [],
      mixOptions: {},
      selectedMixedLine: {},
      selectedMixRes: {},
      selectedCity: "全台縣市",
      selectedCityIndex: "全台縣市",
      dropdownValueArr: [],

      onlineAndOffilne: "整體",

      // range:{
      //   start: new Date(2020, 0, 6),
      //   end: new Date(2020, 0, 23),
      // },

      startDate: "2021-11-21",
      endDate: "2021-12-22",
      hasDataFirst: "",
      hasDataEnd: "",

      dropdownOptions: [
        { text: "全部", value: "全部" },
        { text: "最近7天", value: this.getRecentMonth },
        { text: "demo2", value: this.demoDate2 },
      ],

      pass1week: {},
      colorBR: ["#63A7C8", "#FFA09D"],
    };
  },

  beforeMount() {
    // this.getRangeWeek();
  },

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();

    this.loading = false;
  },
  watch: {},

  methods: {
    selectValueByDrop(selectValue, changingSelected, index) {
      this[changingSelected] = selectValue;
      this.selectedCityIndex = selectValue;
      console.log("this.selectedCity", selectValue);
      this.mixedChart();
    },
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },

    clickMixReceiver() {
      this.selectedMixPeople = "會員";
      this.selectedCityIndex = "全台縣市";
      this.selectedCity = "全台縣市";

      this.selectedMixedLine = this.selectedMixRes[this.selectedMixPeople];
      const needSortObj = this.selectedMixedLine[this.onlineAndOffilne];
      const sortable = [];
      for (var region in needSortObj) {
        sortable.push([region, needSortObj[region].id]);
      }
      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });
      this.dropdownValueArr = sortable.map((d) => d[0]);

      // this.dropdownValueArr = Object.keys(
      //   this.selectedMixedLine[this.onlineAndOffilne]
      // );
      this.mixedChart();
    },
    clickMixMember() {
      this.selectedMixPeople = "收貨人";
      this.selectedCityIndex = "全台縣市";
      this.selectedCity = "全台縣市";

      this.selectedMixedLine = this.selectedMixRes[this.selectedMixPeople];

      const needSortObj = this.selectedMixedLine[this.onlineAndOffilne];
      const sortable = [];
      for (var region in needSortObj) {
        sortable.push([region, needSortObj[region].id]);
      }
      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });
      this.dropdownValueArr = sortable.map((d) => d[0]);

      // this.dropdownValueArr = Object.keys(
      //   this.selectedMixedLine[this.onlineAndOffilne]
      // );

      this.mixedChart();
    },

    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },

    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeSalesGet(params)
        .then((res) => {
          console.log("getTime", res);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getMemberCount: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationMemberMemberGet(params)
        .then((res) => {
          console.log("會員總數", res);
          this.apiChannelMemberBar = res.data;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postBarData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationMemberStructureGet(params)
        .then((res) => {
          console.log("bar", res);
          this.apiChannelBar = res.data.Data;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postPieBuyFreq:async function (){
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
      .operationMemberBuyFreqGet(params)
      .then((res) =>{
        console.log('buyfreqpie',res)
        this.apiBuyFreqPieData = res.data.Data
      })
      .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    postPieData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationMemberSalesGet(params)
        .then((res) => {
          console.log("demodate", res);
          this.apiChannelPie = res.data.Data;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postLineData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationMemberTrendGet(params)
        .then((res) => {
          console.log("lineaws", res);
          this.apiChannelLine = res.data.Data;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postMixData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationMemberRegionGet(params)
        .then((res) => {
          console.log("mix", res);
          this.selectedMixRes = res.data.Data;
          this.selectedMixedLine = this.selectedMixRes[this.selectedMixPeople];
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    memberBarchart() {
      // console.log("qsqs", this.seletedApiChannelPie),
      (this.memberBarSeries = [
        {
          name: "人數",
          data: this.seletedApiChannelMemberBar.數量,
        },
      ]),
        (this.memberBarOptions = {
          chart: {
            type: "bar",
            height: 125,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
            offsetY: -15,
          },
          colors: ["#71AFB6", "#FC9F66"],
          plotOptions: {
            bar: {
              borderRadius: 4,
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: "bottom",
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: this.seletedApiChannelMemberBar.title,
            labels: {
              formatter: function (x) {
                return x.toLocaleString();
              },
            },
          },
          yaxis: {
            title: {
              text: "",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
          legend: {
            show: false,
          },
          // tooltip: {
          //   y: {
          //     formatter: (y) => {
          //       return "人數: " + y;
          //     },
          //   },
          // },
        });
    },
    piechart() {
      if (this.seletedApiChannelPie === undefined) {
        this.pieSeries = [];
        this.pieOptions = {
          labels: [],
        };
      } else {
        (this.pieSeries = this.seletedApiChannelPie.銷售額),
          (this.pieOptions = {
            chart: {
              height: 230,
              type: "donut",
            },
            colors: [
              "#71AFB6",
              "#F9C357",
              "#FC9F66",
              "#96C5D7",
              "#85A9CD",
              "#63A7C8",
            ],
            labels: this.seletedApiChannelPie.項目,
            legend: {
              position: "bottom",
            },
            responsive: [
              {
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          });
      }
    },
    pieChartBuyFreq(){
     this.pieBuyFreqSeries = this.seletedApiBuyFreqPie.members
      this.pieBuyFreqOptions = {
            chart: {
              height: 350,
              type: "donut",
            },
            colors: [
              "#71AFB6",
              "#F9C357",
              "#FC9F66",
              "#96C5D7",
              "#85A9CD",
              "#63A7C8",
            ],
            labels: this.seletedApiBuyFreqPie.freq,
            legend: {
              position: "bottom",
            },
            responsive: [
              {
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          }
  console.log( "pieChartBuyFreq",this.pieBuyFreqSeries)
    },
    barchart() {
      const vm = this;
      // if (this.seletedApiChannelBar.男性 === undefined) {
      //   this.barSeries = [
      //     {
      //       data: [],
      //     },
      //     {
      //       data: [],
      //     },
      //   ];
      //   this.barchartOptions = {
      //     labels: [],
      //   };
      //   console.log("123456", this.barSeries);
      // } else {
      (this.barseries = [
        {
          name: "男性",
          data: this.seletedApiChannelBar.男性.sales,
        },
        {
          name: "女性",
          data: this.seletedApiChannelBar.女性.sales,
        },
      ]),
        (this.barchartOptions = {
          chart: {
            type: "bar",
            height: "100%",
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#FFA09D"],

          dataLabels: {
            enabled: false,
            // formatter: function (val, opts) {
            //   if (opts.seriesIndex === 0) {
            //     return (
            //       Math.round(
            //         (vm.seletedApiChannelBar.男性.ratio[opts.dataPointIndex] +
            //           Number.EPSILON) *
            //           10
            //       ) /
            //         10 +
            //       "%"
            //     );
            //   } else {
            //     return (
            //       Math.round(
            //         (vm.seletedApiChannelBar.女性.ratio[opts.dataPointIndex] +
            //           Number.EPSILON) *
            //           10
            //       ) /
            //         10 +
            //       "%"
            //     );
            //   }
            // },
            // offsetY: -20,
            // style: {
            //   fontSize: "12px",
            //   colors: ["#304758"],
            // },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["#63A7C8", "#FFA09D"],
          },
          xaxis: {
            title: {
              text: "年齡層分層",
              offsetY: 0,
              style: {
                fontSize: "12px",
                cssClass: "padding-for-apex",
              },
            },

            categories: this.seletedApiChannelBar.女性.age,
          },
          legend: {
            formatter: function (seriesName, opts) {
              return (
                "<div style='display:flex; align-items:center'>" +
                seriesName +
                ": " +
                `<div style='color:${
                  vm.colorBR[opts.seriesIndex]
                }; margin-top:3px'>` +
                vm.seletedApiChannelBarRatio[seriesName].toFixed(2) +
                "%" +
                "</div>" +
                "</div>"
              );
            },
          },
          yaxis: {
            title: {
              text: "消費金額",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
            max: function (max) {
              // console.log(10 ** (max.toFixed(0).toString().length - 2) * 5);
              // console.log(max / 10 ** (max.toString().length - 2));
              // console.log(
              //   Math.ceil(max / 10 ** (max.toString().length - 2)) *
              //     10 ** (max.toString().length - 2)
              // );
              // console.log(
              //   Math.ceil(max / 10 ** (max.toString().length - 1)) *
              //     10 ** (max.toString().length - 1) +
              //     10 ** (max.toString().length - 1)
              // );

              return (
                Math.ceil(max / 10 ** (max.toFixed(0).length - 2)) *
                  10 ** (max.toFixed(0).length - 2) +
                10 ** (max.toFixed(0).length - 2) * 3
              );
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                "<div style='width:200px;font-size:12px;font-family:sans-serif'>" +
                "<div style='background-color:#ECEFF1;padding:5px;border-bottom:1px solid #c4c4c4'>" +
                vm.seletedApiChannelBar[w.globals.seriesNames[seriesIndex]].age[
                  dataPointIndex
                ] +
                "</div>" +
                "<div style='padding:10px'>" +
                "<div style='margin-bottom:10px'>" +
                `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                w.globals.seriesNames[seriesIndex] +
                ": " +
                "<span style='font-weight:bold'>" +
                "$NT " +
                vm.seletedApiChannelBar[
                  w.globals.seriesNames[seriesIndex]
                ].sales[dataPointIndex].toLocaleString() +
                "</span>" +
                // vm.scatterData[dataPointIndex][1] +
                "</div>" +
                "<div style='margin:0'>" +
                `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                "占比" +
                ": " +
                "<span style='font-weight:bold'>" +
                vm.seletedApiChannelBar[
                  w.globals.seriesNames[seriesIndex]
                ].ratio[dataPointIndex].toFixed(1) +
                "%" +
                "</span>" +
                "</div>" +
                "</div>" +
                "</div>"
              );
            },
          },
          //   x: {
          //     show: true,
          //     formatter: function (val) {
          //       return val;
          //     },
          //   },
          //   y: {
          //     formatter: function (val) {
          //       return " $NT " + val.toLocaleString();
          //     },
          //   },
          // },
        });
      // }
    },
    mixedChart() {
      const vm = this;
      if (
        this.selectedMixedLine[this.onlineAndOffilne].全台縣市.region[0] ===
        undefined
      ) {
        this.mixSeries = [];
        this.mixchartOptions = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        (this.mixSeries = [
          {
            name: "營業額",
            type: "column",
            data: this.selectedMixedLine[this.onlineAndOffilne][
              this.selectedCity
            ].sales,
          },
          {
            name: "訂單數",
            type: "line",
            data: this.selectedMixedLine[this.onlineAndOffilne][
              this.selectedCity
            ].counts,
          },
        ]),
          (this.mixOptions = {
            chart: {
              // parentHeightOffset: 50,
              // width:820,
              height: 320,
              type: "line",
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                },
              },
            },
            colors: ["#63A7C8", "#F9C357"],
            dataLabels: {
              enabled: false,
            },

            stroke: {
              curve: "smooth",
              width: 2,
            },
            title: {
              align: "left",
              style: {
                fontSize: "20px",
                fontWeight: "medium",
                color: "#71AFB6",
              },
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            markers: {
              size: 0,
            },
            xaxis: {
              categories:
                this.selectedMixedLine[this.onlineAndOffilne][this.selectedCity]
                  .region,
              labels: {
                show: true,
                style: {
                  fontSize: "12px",
                },
              },
            },
            yaxis: [
              {
                title: {
                  text: "營業額",
                  style: {
                    fontSize: "12px",
                  },
                },
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                  formatter: function (y) {
                    return y.toLocaleString();
                  },
                },
                max: function (max) {
                  if (max != undefined) {
                    return (
                      Math.ceil(max / 10 ** (max.toFixed(0).length - 2)) *
                        10 ** (max.toFixed(0).length - 2) +
                      10 ** (max.toFixed(0).length - 2) * 3
                    );
                  }
                },
              },
              {
                opposite: true,
                title: {
                  text: "訂單數",
                  style: {
                    fontSize: "12px",
                  },
                },
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                  formatter: function (y) {
                    return y.toLocaleString();
                  },
                },
              },
            ],
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // console.log(w,seriesIndex,series);
                return (
                  "<div style='width:200px;font-size:12px;font-family:sans-serif'>" +
                  "<div style='background-color:#ECEFF1;padding:5px;border-bottom:1px solid #c4c4c4'>" +
                  w.globals.labels[dataPointIndex] +
                  "</div>" +
                  "<div style='padding:10px'>" +
                  "<div style='margin-bottom:10px'>" +
                  `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                  w.globals.seriesNames[seriesIndex] +
                  ": " +
                  "<span style='font-weight:bold'>" +
                  "$NT " +
                  vm.selectedMixedLine[vm.onlineAndOffilne][
                    vm.selectedCity
                  ].sales[dataPointIndex].toLocaleString() +
                  "</span>" +
                  "</div>" +
                  "<div style='margin-bottom:10px'>" +
                  `<div style='background-color:${
                    w.globals.colors[seriesIndex + 1]
                  };border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                  w.globals.seriesNames[seriesIndex + 1] +
                  ": " +
                  "<span style='font-weight:bold'>" +
                  vm.selectedMixedLine[vm.onlineAndOffilne][vm.selectedCity]
                    .counts[dataPointIndex] +
                  "</span>" +
                  "</div>" +
                  "<div style='margin:0'>" +
                  `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                  "占比" +
                  ": " +
                  "<span style='font-weight:bold'>" +
                  vm.selectedMixedLine[vm.onlineAndOffilne][
                    vm.selectedCity
                  ].ratio[dataPointIndex].toFixed(1) +
                  "%" +
                  "</span>" +
                  "</div>" +
                  "</div>" +
                  "</div>"
                );
              },
            },

            responsive: [
              {
                //  breakpoint: 1000,
                options: {
                  width: 820,
                },
              },
            ],

            legend: {
              position: "bottom",
              horizontalAlign: "center",
              floating: false,
              offsetX: -5,
            },
          });
      }
    },
    getall() {
      this.onlineAndOffilne = "整體";
      // this.onlineOrOffline = "all";
      this.all = true;
      this.online = false;
      this.offline = false;

      // this.seletedchannelpie = this.channelpie[this.timeSwitch].all;
      this.seletedApiChannelMemberBar = this.apiChannelMemberBar.整體;
      this.seletedApiChannelPie = this.apiChannelPie.整體;
      this.seletedApiChannelLine = this.apiChannelLine.整體;
      this.seletedApiBuyFreqPie = this.apiBuyFreqPieData.整體
      if(this.apiChannelBar.整體!=undefined){
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
    
      }

      if (this.seletedApiChannelLine === undefined) {
        this.lineSeries = [];
      } else {
        const names = Object.keys(this.seletedApiChannelLine).filter(
          (d) => d != "日期"
        );

        this.lineSeries = [];
        for (let index = 0; index < names.length; index++) {
          this.lineSeries.push({
            name: names[index],
            data: this.seletedApiChannelLine[names[index]],
          });
        }

        this.lineOptions = {
          xaxis: {
            tickAmount: 7,
            categories: this.seletedApiChannelLine.日期,
          },
        };
      }

      const needSortObj = this.selectedMixedLine[this.onlineAndOffilne];

      const sortable = [];
      for (var region in needSortObj) {
        sortable.push([region, needSortObj[region].id]);
      }
      sortable.sort(function (a, b) {
        return a[1] - b[1];
      });
      this.dropdownValueArr = sortable.map((d) => d[0]);

      this.memberBarchart();
      this.piechart();
      this.pieChartBuyFreq()
    },
    getonline() {
      this.onlineAndOffilne = "線上";
      this.all = false;
      this.online = true;
      this.offline = false;

      this.seletedApiChannelMemberBar = this.apiChannelMemberBar.線上;
      this.seletedApiChannelPie = this.apiChannelPie.線上;
      this.seletedApiChannelLine = this.apiChannelLine.線上;
      this.seletedApiBuyFreqPie = this.apiBuyFreqPieData.線上
      console.log(this.apiChannelBar);
      if(this.apiChannelBar.整體!=undefined){
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
      }

      console.log(this.seletedApiChannelLine);

      if (this.seletedApiChannelLine === undefined) {
        this.lineSeries = [];
      } else {
        const names = Object.keys(this.seletedApiChannelLine).filter(
          (d) => d != "日期"
        );
        console.log(names);
        this.lineSeries = [];
        for (let index = 0; index < names.length; index++) {
          this.lineSeries.push({
            name: names[index],
            data: this.seletedApiChannelLine[names[index]],
          });
        }
        console.log(this.lineSeries);

        this.lineOptions = {
          xaxis: {
            tickAmount: 7,
            categories: this.seletedApiChannelLine.日期,
          },
        };
      }

      // this.dropdownValueArr = Object.keys(
      //   this.selectedMixedLine[this.onlineAndOffilne]
      // );
      this.memberBarchart();
      this.piechart();
      this.pieChartBuyFreq()
    },
    getoffline() {
      this.onlineAndOffilne = "線下";
      this.all = false;
      this.online = false;
      this.offline = true;

      this.seletedApiChannelMemberBar = this.apiChannelMemberBar.線下;
      this.seletedApiChannelPie = this.apiChannelPie.線下;
      this.seletedApiChannelLine = this.apiChannelLine.線下;
      this.seletedApiBuyFreqPie = this.apiBuyFreqPieData.線下
      if(this.apiChannelBar.整體!=undefined){
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
      }

      if (this.seletedApiChannelLine === undefined) {
        this.lineSeries = [];
      } else {
        const names = Object.keys(this.seletedApiChannelLine).filter(
          (d) => d != "日期"
        );
        this.lineSeries = [];
        for (let index = 0; index < names.length; index++) {
          this.lineSeries.push({
            name: names[index],
            data: this.seletedApiChannelLine[names[index]],
          });
        }

        this.lineOptions = {
          xaxis: {
            tickAmount: 7,
            categories: this.seletedApiChannelLine.日期,
          },
        };
      }

      // this.dropdownValueArr = Object.keys(
      //   this.selectedMixedLine[this.onlineAndOffilne]
      // );
      this.memberBarchart();
      this.piechart();
      this.pieChartBuyFreq()
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.getMemberCount(),
        this.postLineData(),
        this.postPieData(),
        this.postPieBuyFreq(),
      ]);

      if (this.all === true) {
        this.getall();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver();
        this.pieChartBuyFreq();
      } else if (this.online === true) {
        this.getonline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver();
        this.pieChartBuyFreq();
      } else {
        this.getoffline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver();
        this.pieChartBuyFreq();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "全部";
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await Promise.all([
        this.getMemberCount(),
        this.postLineData(),
        this.postPieData(),
        this.postPieBuyFreq(),
      ]);

      if (this.all === true) {
        this.getall();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else if (this.online === true) {
        this.getonline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else {
        this.getoffline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      }
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近一年";
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "M");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log("start for 30", start);

      await Promise.all([
        this.getMemberCount(),
        this.postLineData(),
        this.postPieData(),
        this.postPieBuyFreq(),
      ]);

      if (this.all === true) {
        this.getall();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else if (this.online === true) {
        this.getonline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else {
        this.getoffline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      }
      this.highlightVue3Date();
      this.loading = false;

      this.dateSelector = "最近30天";
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(start);
      console.log(strStart);

      await Promise.all([
        this.getMemberCount(),
        this.postLineData(),
        this.postPieData(),
        this.postPieBuyFreq(),
      ]);

      if (this.all === true) {
        this.getall();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else if (this.online === true) {
        this.getonline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver();
        this.pieChartBuyFreq();
      } else {
        this.getoffline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近7天";
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

     await Promise.all([
        this.getMemberCount(),
        this.postLineData(),
        this.postPieData(),
        this.postPieBuyFreq(),
      ]);

      if (this.all === true) {
        this.getall();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else if (this.online === true) {
        this.getonline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else {
        this.getoffline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      }
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近1天";
    },
    customDatePicker: async function () {
      this.startDate = this.vue3Date.start.slice(0, 10);
      this.endDate = this.vue3Date.end.slice(0, 10);

      console.log("customstart", this.startDate);
      console.log("customend", this.endDate);

     await Promise.all([
        this.getMemberCount(),
        this.postLineData(),
        this.postPieData(),
        this.postPieBuyFreq(),
      ]);

      if (this.all === true) {
        this.getall();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.整體.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.整體.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver()
        this.pieChartBuyFreq();
      } else if (this.online === true) {
        this.getonline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線上.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線上.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver();
        this.pieChartBuyFreq();
      } else {
        this.getoffline();
        await Promise.all([this.postBarData(), this.postMixData()]);
        this.seletedApiChannelBar = this.apiChannelBar.線下.structure;
        this.seletedApiChannelBarRatio = this.apiChannelBar.線下.ratio.ratio;
        this.barchart();
        this.mixedChart();
        this.clickMixReceiver();
        this.pieChartBuyFreq();
      }
      this.loading = false;
      this.dateSelector = "自訂";
    },
  },
};
</script>

<style scoped>
/* MembershipSales */
.padding-for-apex {
  margin-right: 60px;
}

.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding-left: 50px;
}

.title-span {
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}

.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}

.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}

.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}

.member-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  /* margin-top: 30px; */
  margin-right: 30px;
  height: 157px;
}

.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  /* margin-top: 30px; */
  margin-right: 30px;
  height: 263px;
}

.line-div-top-right {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  /* margin-top: 30px; */
  height: 441.5px;
}

.line-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  /* margin-top: 30px; */
  height: 386px;
}

.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}

.change-date-li:hover {
  background-color: #eaf3f4;
}

.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}

.child-popout-inMembershipSales {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -250px;
  text-align: left;
  top: -42px;
  width: 230px;
  z-index: 99;
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>
