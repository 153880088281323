<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium">銷售表現</span>
        <div class="title-button-div fs-small">
          <button
            class="button-nofocus"
            :class="all ? 'button-focus' : ''"
            @click="getall"
          >
            全部
          </button>
          <button
            class="button-nofocus"
            :class="online ? 'button-focus' : ''"
            @click="getonline"
          >
            線上
          </button>
          <button
            class="button-nofocus"
            :class="offline ? 'button-focus' : ''"
            @click="getoffline"
          >
            線下
          </button>
        </div>
        <div class="m-l-24">
          <!-- 商品/產品類別 -->
          <Dropdown
            style="width: 250px"
            :valueArr="this.selecteddropdown"
            :selectValue="selectViewType"
            :changingSelected="'selectViewType'"
            v-on:selectValueByDrop="selectValueByDrop"
          />
        </div>
        <!-- <button @click="test">test</button> -->
      </div>
      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              is-expanded
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-4" style="padding: 0px">
      <div class="d-flex">
        <div class="col-5">
          <div class="pie-div" style="margin-top: 5px">
            <span class="fs-small" style="color: #71afb6"
              >TOP 5 熱門{{
                selectViewType == "商品" ? "商品" : "類別"
              }}營業額</span
            >
            <div id="donut" style="position: relative; height: 298px">
              <span
                class="text-center"
                style="position: absolute; top: 20%; left: 24%; color: #c4c4c4"
                v-if="checkDataIsNull(this.salesPieSeries)"
              >
                <img src="../assets/missData.svg" alt="" style="width: 200px" />
                <br />
                此時間段無資料</span
              >
              <apexchart
                type="donut"
                height="330"
                :options="salesPieOptions"
                :series="
                  checkDataIsNull(this.salesPieSeries) ? [] : salesPieSeries
                "
              ></apexchart>
            </div>
          </div>
        </div>

        <div class="col-7 bar-div-commodity" style="margin-top: 5px">
          <div class="d-flex justify-content-between">
            <p class="m-0">
              <span class="fs-small" style="color: #71afb6"
                >TOP 5
                {{
                  selectViewType == "商品" ? "商品" : "類別"
                }}營業額成長率</span
              >

              <BtnInfoBox
                :content="selectViewType == '商品' ?
                  '此為商品銷售的成長狀況，是根據您所選天數(30天)的時段同比(30-60天)。' :
                  '此為產品類別銷售的成長狀況，是根據您所選天數(30天)的時段同比(30-60天)。'"
                direction="right"
              ></BtnInfoBox>
            </p>
            <div class="title-button-div">
              <button
                class="button-nofocus fs-small"
                :class="Top5Active ? 'button-focus' : ''"
                @click="getCategoriesTop5"
              >
                Top 5
              </button>
              <button
                class="button-nofocus fs-small"
                :class="Bottom5Active ? 'button-focus' : ''"
                @click="getCategoriesBottom5"
              >
                Bottom 5
              </button>
            </div>
          </div>
          <div id="bar" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="
                checkDataIsNull(this.barSeries) && this.barSeriesOK === true
              "
              >此時間段無資料</span
            >
            <apexchart
              type="bar"
              height="300"
              :options="barOptions"
              :series="checkDataIsNull(this.barSeries) ? [] : barSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="padding: 0px">
      <div class="d-flex">
        <div class="col-5">
          <div class="pie-div">
            <span class="fs-small" style="color: #71afb6"
              >TOP 5 熱門{{
                selectViewType == "商品" ? "商品" : "類別"
              }}訂單數</span
            >
            <div id="donut" style="position: relative; height: 298px">
              <span
                class="text-center"
                style="position: absolute; top: 20%; left: 24%; color: #c4c4c4"
                v-if="checkDataIsNull(this.orderPieSeries)"
              >
                <img src="../assets/missData.svg" alt="" style="width: 200px" />
                <br />
                此時間段無資料</span
              >
              <apexchart
                type="donut"
                height="330"
                :options="orderPieOptions"
                :series="
                  checkDataIsNull(this.orderPieSeries) ? [] : orderPieSeries
                "
              ></apexchart>
            </div>
          </div>
        </div>

        <div class="col-7 bar-div-commodity">
          <div class="d-flex justify-content-between">
            <p class="m-0">
              <span class="fs-small" style="color: #71afb6"
                >TOP 5
                {{
                  selectViewType == "商品" ? "商品" : "類別"
                }}訂單數成長率</span
              >
            </p>
            <div class="title-button-div">
              <button
                class="button-nofocus fs-small"
                :class="Top5ProfitActive ? 'button-focus' : ''"
                @click="getProfitTop5"
              >
                Top 5
              </button>
              <button
                class="button-nofocus fs-small"
                :class="Bottom5ProfitActive ? 'button-focus' : ''"
                @click="getProfitBottom5"
              >
                Bottom 5
              </button>
            </div>
          </div>
          <div id="bar" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="
                checkDataIsNull(this.profitBarSeries) &&
                this.barSeriesOK === true
              "
              >此時間段無資料</span
            >
            <apexchart
              type="bar"
              height="300"
              :options="profitBarOptions"
              :series="
                checkDataIsNull(this.profitBarSeries) ? [] : profitBarSeries
              "
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="padding: 0px">
      <div class="d-flex flex-wrap">
        <div class="col-12 bar-div-commodity">
          <div class="d-flex justify-content-between">
            <span class="fs-small" style="color: #71afb6"
              >各{{ selectViewType == "商品" ? "商品" : "類別" }}銷售分布圖
            </span>
          </div>
          <div id="scatter" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="checkDataIsNull(this.seriesScatter)"
              >此時間段無資料</span
            >
            <apexchart
              type="scatter"
              height="300"
              :options="chartOptionsScatter"
              :series="checkDataIsNull(this.seriesScatter) ? [] : seriesScatter"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="padding: 0px; height: 600px">
      <div class="d-flex flex-wrap">
        <div class="col-12 pie-div">
          <div class="d-flex justify-content-between">
            <p class="m-0">
              <span class="fs-small" style="color: #71afb6"
                >TOP20
                {{ selectViewType == "商品" ? "商品" : "類別" }}同時購買次數
              </span>
              
              <BtnInfoBox
                content="此為單一會員在「同一筆訂單購買A+B」的次數統計，當次數越高代表商品組合黏性越強，依此可設計合理的促銷策略(例如買A送B)。"
                direction="right"
              ></BtnInfoBox>
            </p>
          </div>
          <div id="bar" style="position: relative">
            <span
              style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="checkDataIsNull(this.barSeries3) && barSeries3OK == true"
              >此時間段無資料</span
            >
            <apexchart
              type="bar"
              height="540"
              :options="barOptions3"
              :series="checkDataIsNull(this.barSeries3) ? [] : barSeries3"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../components/Dropdown.vue";
import { allapi } from "../../public/static/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import { onClickOutside } from "@vueuse/core";
import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref } from "vue";
import { def } from "@vue/shared";
// import ColumnGroup from 'primevue/columngroup';
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "CommoditySales",
  components: {
    apexchart: VueApexCharts,
    DataTable,
    Column,
    Calendar,
    DatePicker,
    Dropdown,
    BtnInfoBox,
  },

  setup() {
    const advModal = ref(false);
    const modal = ref(null);
    const advDropdown1 = ref(false);
    const advDropdown1Ref = ref(null);
    const advDropdown2 = ref(false);
    const advDropdown2Ref = ref(null);

    onClickOutside(modal, () => (advModal.value = false));

    return {
      advModal,
      modal,
      advDropdown1,
      advDropdown1Ref,
      advDropdown2,
      advDropdown2Ref,
    };
  },

  data() {
    return {
      loading: false, /// 判斷Loading 顯示
      memberShow: false, /// 判斷提示顯示

      vue3Date: {
        start: "",
        end: "",
        //   start: new Date("2022-01-06").toISOString().slice(0, 10),
        //  end: new Date("2022-01-10").toISOString().slice(0, 10),
      },

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        // timeAdjust: "12:00:00",
      },

      startDate: "2021-10-02",
      endDate: "2022-01-10",

      hasDataFirst: "",
      hasDataEnd: "",
      endYear: 2022,
      endMonth: 6,

      dateSelector: "全部",

      commodityPie: {},
      commodityBar: {},

      // bubble chart data
      seriesScatter: [],
      chartOptionsScatter: {},

      all: true,
      online: false,
      offline: false,
      Top5Active: true,
      Bottom5Active: false,
      Top5ProfitActive: true,
      Bottom5ProfitActive: false,

      selectViewType: "商品",
      selectViewTypeIndex: "商品",
      selecteddropdown: {
        商品: "商品",
        產品類別: "產品類別",
      },

      /// TOP 5 熱門 商品/類別 訂單數 圖表資料
      orderPieSeries: [],
      orderPieOptions: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#D197CC", "#FC9F66", "#F9C357", "#96C5D7", "#85A9CD"],
        labels: [],
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },

              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      /// TOP 5 熱門 商品/類別 營業額 圖表資料
      salesPieSeries: [],
      salesPieOptions: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#D197CC", "#FC9F66", "#F9C357", "#96C5D7", "#85A9CD"],
        labels: [],
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      /// TOP 5 商品/類別 營業額成長率 圖表資料
      barSeries: [],
      barSeriesOK: false,
      barOptions: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              position: "top",
            },
            // columnWidth: '70%',
            // barHeight: '70%',
          },
        },
        dataLabels: {
          enabled: false,
          textAnchor: "start",
          offsetX: 30,
          style: {
            colors: ["#8A8A8A"],
          },
          formatter: function (val) {
            return val.toFixed(2).toLocaleString() + "%";
          },
        },
        grid: {
          xaxis: {
            lines: {
              // show: false
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return (Math.round(val * 100) / 100).toLocaleString() + "%";
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            // show: false,
            formatter: function (val) {
              return val.toLocaleString() + "%";
            },
          },
          axisBorder: {
            // show: false
          },
          axisTicks: {
            // show: false
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      /// TOP 5 商品/類別 訂單數成長率 圖表資料
      profitBarSeries: [],
      profitBarOptions: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              position: "top",
            },
            // columnWidth: '70%',
            // barHeight: '70%',
          },
        },
        dataLabels: {
          enabled: false,
          textAnchor: "start",
          offsetX: 30,
          style: {
            colors: ["#8A8A8A"],
          },
          formatter: function (val) {
            return val.toFixed(2).toLocaleString() + "%";
          },
        },
        grid: {
          xaxis: {
            lines: {
              // show: false
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              // return val.toFixed(2).toLocaleString() + '%'
              return (Math.round(val * 100) / 100).toLocaleString() + "%";
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            // show: false,
            formatter: function (val) {
              return val.toLocaleString() + "%";
            },
          },
          axisBorder: {
            // show: false
          },
          axisTicks: {
            // show: false
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      sameTimeBuyBarData: null,

      barSeries3: [],
      barSeries3OK: false,
      barOptions3: {
        chart: {
          type: "bar",
          height: 560,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          offsetY: -10,
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            barHeight: "50%",
            horizontal: true,
          },
        },
        tooltip: {
          y: {
            formatter: function (value, { dataPointIndex, w }) {
              return w.config.series[0].data[dataPointIndex];
            },
          },
          x: {
            formatter: function (value, { dataPointIndex, w }) {
              return w.globals.labels[dataPointIndex];
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          title: {
            text: "次數",
          },
          labels: {
            show: true,
            formatter: function (x) {
              return x.toLocaleString();
            },
          },
        },
        yaxis: {
          title: {
            text: "",
          },

          labels: {
            minWidth: 210,
            maxWidth: 250,
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              if (typeof y != "number") {
                if (y.length > 16) {
                  const a = y.split("+")[0].slice(0, 6);
                  const b = y.split("+")[1].slice(0, 6);
                  let c = "";
                  let d = "";
                  if (a.length > 5) {
                    c = a + "...";
                  } else {
                    c = a;
                  }
                  if (b.length > 5) {
                    d = b + "...";
                  } else {
                    d = b;
                  }
                  return c + "+" + d;
                } else {
                  return y;
                }
              }
            },
          },
        },
      },

      onlineOrOffline: "整體",

      topOrBottomCategory: "top5",
      topOrBottomProfit: "top5",
      scatterData: [],

      advShow: false,

      selectedOnlineOrOffline: "整體",
      postinputID: {},
    };
  },

  beforeMount() {
    // this.getRangeWeek();
  },

  watch: {},

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();

    this.loading = false;
  },

  methods: {
    // test(){
    //   const x ='sadas'
    //   console.log(x.slice(0,2))
    // },
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },

    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    /**
     * 取得時間
     */
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeSalesGet(params)
        .then((res) => {
          console.log(res);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;

          // this.loading = false;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    /**
     * 取得 Top5熱門商品/類別 營業額&訂單數
     */
    getTop5Revorder: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationProductTop5revorderGet(params)
        .then((res) => {
          console.log("Top5Revorder", res);
          this.commodityPie = res.data.Data;

          // this.loading = false;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * Top5商品&類別 營業額/訂單數成長率
     */
    getRank5Grow: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationProductRank5growGet(params)
        .then((res) => {
          console.log("Rank5Grow", res);

          this.commodityBar = res.data.Data;
          this.barSeriesOK = true;
          this.drawBarChart2();
          // this.loading = false;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    /**
     * 各商品&類別銷售分布圖
     */
    getScatter: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationProductScatterGet(params)
        .then((res) => {
          console.log("Scatter", res);
          this.scatterData = res.data.Data;

          this.drawScatter();

          // this.loading = false;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    getcommodityTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationProductAssociationCountsGet(params)

        .then((res) => {
          console.log("commodityTable", res);
          this.sameTimeBuyBarData = res.data.Data;
          this.barSeries3OK = true;
          this.drawBarChart();
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    /**
     * 取得全部資料
     */
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.getTop5Revorder(),
        this.getRank5Grow(),
        this.getScatter(),
        this.getcommodityTable(),
      ]);

      this.getAllCurrentChart();
      this.loading = false;
      this.dateSelector = "全部";
    },
    /**
     * 取得最近一年資料
     */
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近一年";

      await Promise.all([
        this.getTop5Revorder(),
        this.getRank5Grow(),
        this.getScatter(),
        this.getcommodityTable(),
      ]);

      this.highlightVue3Date();
      this.getAllCurrentChart();
      this.loading = false;
    },
    /**
     * 取得最近一個月資料
     */
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近30天";

      await Promise.all([
        this.getTop5Revorder(),
        this.getRank5Grow(),
        this.getScatter(),
        this.getcommodityTable(),
      ]);

      this.highlightVue3Date();
      this.getAllCurrentChart();
      this.loading = false;
    },
    /**
     * 取得最近七天資料
     */
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近7天";

      await Promise.all([
        this.getTop5Revorder(),
        this.getRank5Grow(),
        this.getScatter(),
        this.getcommodityTable(),
      ]);

      this.highlightVue3Date();
      this.getAllCurrentChart();
      this.loading = false;
    },
    /**
     * 取得最近一天資料
     */
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近1天";

      await Promise.all([
        this.getTop5Revorder(),
        this.getRank5Grow(),
        this.getScatter(),
        this.getcommodityTable(),
      ]);

      this.highlightVue3Date();
      this.getAllCurrentChart();
      this.loading = false;
    },

    /**
     * 取得自訂時間區段資料
     */
    customDatePicker: async function () {
      this.loading = true;
      this.startDate = this.vue3Date.start;
      this.endDate = this.vue3Date.end;
      this.dateSelector = "自訂";

      await Promise.all([
        this.getTop5Revorder(),
        this.getRank5Grow(),
        this.getScatter(),
        this.getcommodityTable(),
      ]);

      this.getAllCurrentChart();
      this.loading = false;
    },

    /**
     * TOP 5 商品/類別 訂單數成長率 圖表生成
     */
    /// UP
    drawBarChart2: function () {
      if (
        this.commodityBar[this.selectViewType][this.onlineOrOffline][
          "訂單數成長率"
        ]["top5"] === undefined
      ) {
        this.profitBarSeries = [];
        this.profitBarOptions = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        this.profitBarSeries = [
          {
            name: "成長率",
            data: this.commodityBar[this.selectViewType][this.onlineOrOffline][
              "訂單數成長率"
            ][this.topOrBottomProfit]["values"].map((i) => i / 100),
          },
        ];
        this.profitBarOptions = {
          xaxis: {
            categories:
              this.commodityBar[this.selectViewType][this.onlineOrOffline][
                "訂單數成長率"
              ][this.topOrBottomProfit]["prod_cate"],
          },
        };
      }

      if (
        this.commodityBar[this.selectViewType][this.onlineOrOffline][
          "營業額成長率"
        ]["top5"] === undefined
      ) {
        this.barSeries = [];
        this.barOptions = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        this.barSeries = [
          {
            name: "成長率",
            data: this.commodityBar[this.selectViewType][this.onlineOrOffline][
              "營業額成長率"
            ][this.topOrBottomCategory]["values"].map((i) => i / 100),
          },
        ];

        this.barOptions = {
          xaxis: {
            categories:
              this.commodityBar[this.selectViewType][this.onlineOrOffline][
                "營業額成長率"
              ][this.topOrBottomCategory]["prod_cate"],
            //  formatter: function (y)  { return y.toLocaleString() },
          },
        };
      }
    },

    /**
     * TOP 5 熱門 商品/類別 營業額 圖表生成
     */
    /// UP
    getAllCurrentChart() {
      if (
        this.commodityPie[this.selectViewType][this.onlineOrOffline]["營業額"][
          "values"
        ] === undefined
      ) {
        this.salesPieSeries = [];
        this.orderPieSeries = [];
      } else {
        this.salesPieSeries =
          this.commodityPie[this.selectViewType][this.onlineOrOffline][
            "營業額"
          ]["values"];

        this.salesPieOptions = {
          labels:
            this.commodityPie[this.selectViewType][this.onlineOrOffline][
              "營業額"
            ]["prod_cate"],
        };
        this.orderPieSeries =
          this.commodityPie[this.selectViewType][this.onlineOrOffline][
            "訂單數"
          ]["values"];

        this.orderPieOptions = {
          labels:
            this.commodityPie[this.selectViewType][this.onlineOrOffline][
              "訂單數"
            ]["prod_cate"],
        };
      }
    },

    /**
     * 各 商品/類別 銷售分布圖 圖表生成
     */
    /// UP
    drawScatter() {
      const vm = this;
      if (
        this.scatterData[this.selectViewType][this.onlineOrOffline] ===
        undefined
      ) {
        this.seriesScatter = [];
        this.chartOptionsScatter = {};
      } else {
        this.seriesScatter = [
          {
            name: "訂單數量:",
            data: this.scatterData[this.selectViewType][this.onlineOrOffline],
          },
        ];

        this.chartOptionsScatter = {
          chart: {
            height: 300,
            type: "scatter",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          colors: ["#71AFB6", "#F9C357", "#FC9F66", "#96C5D7", "#85A9CD"],
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                "<div style='width:200px;font-size:12px;font-family:sans-serif'>" +
                "<div style='background-color:#ECEFF1;padding:5px'>" +
                vm.scatterData[vm.selectViewType][vm.onlineOrOffline][
                  dataPointIndex
                ][2] +
                "</div>" +
                "<div style='padding:10px'>" +
                "<p>" +
                "訂單數量" +
                ": " +
                vm.scatterData[vm.selectViewType][vm.onlineOrOffline][
                  dataPointIndex
                ][1] +
                " (筆)" +
                "</p>" +
                "<p style='margin:0'>" +
                "營業額" +
                ": " +
                vm.scatterData[vm.selectViewType][vm.onlineOrOffline][
                  dataPointIndex
                ][0] +
                "</p>" +
                "</div>" +
                "</div>"
              );
            },
          },
          xaxis: {
            tickAmount: 6,
            labels: {
              show: true,
              formatter: function (x) {
                return "NT$" + parseInt(x).toLocaleString();
              },
            },
            title: {
              text:
                (this.selectViewType == "商品" ? "商品" : "類別") + "營業額",
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            tickAmount: 7,
            title: {
              text:
                (this.selectViewType == "商品" ? "商品" : "類別") + "訂單數量",
            },
            labels: {
              show: true,
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
            tooltip: {
              enabled: false,
            },
          },
        };
      }
    },

    drawBarChart() {
      if (
        this.sameTimeBuyBarData?.[this.selectViewType]?.[
          this.onlineOrOffline
        ]?.["產品"] === undefined ||
        this.sameTimeBuyBarData?.[this.selectViewType]?.[
          this.onlineOrOffline
        ]?.["產品"]?.length === 0
      ) {
        this.barSeries3 = [];
        this.barOptions3 = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        this.barSeries3 = [
          {
            name: "次數",
            data:
              this.sameTimeBuyBarData?.[this.selectViewType]?.[
                this.onlineOrOffline
              ]?.["數量"] || undefined,
          },
        ];
        this.barOptions3 = {
          xaxis: {
            categories:
              this.sameTimeBuyBarData?.[this.selectViewType]?.[
                this.onlineOrOffline
              ]?.["產品"] || undefined,
          },
        };
      }
    },

    getall() {
      this.onlineOrOffline = "整體";
      this.selectedOnlineOrOffline = "整體";
      this.all = true;
      this.online = false;
      this.offline = false;
      this.getAllCurrentChart();
      this.drawBarChart();
      this.drawBarChart2();
      this.drawScatter();
    },

    getonline() {
      this.onlineOrOffline = "線上";
      this.selectedOnlineOrOffline = "線上";
      this.all = false;
      this.online = true;
      this.offline = false;
      this.getAllCurrentChart();
      this.drawBarChart();
      this.drawBarChart2();
      this.drawScatter();
    },

    getoffline() {
      this.onlineOrOffline = "線下";
      this.selectedOnlineOrOffline = "線下";
      this.all = false;
      this.online = false;
      this.offline = true;
      this.getAllCurrentChart();
      this.drawBarChart();
      this.drawBarChart2();
      this.drawScatter();
    },

    getCategoriesTop5() {
      this.Top5Active = true;
      this.Bottom5Active = false;
      this.topOrBottomCategory = "top5";
      // this.getAllCurrentChart();
      this.drawBarChart2();
    },

    getCategoriesBottom5() {
      this.Top5Active = false;
      this.Bottom5Active = true;
      this.topOrBottomCategory = "buttom5";
      // this.getAllCurrentChart();
      this.drawBarChart2();
    },

    getProfitTop5() {
      this.Top5ProfitActive = true;
      this.Bottom5ProfitActive = false;
      this.topOrBottomProfit = "top5";
      // this.getAllCurrentChart();
      this.drawBarChart2();
    },

    getProfitBottom5() {
      this.Top5ProfitActive = false;
      this.Bottom5ProfitActive = true;
      this.topOrBottomProfit = "buttom5";
      // this.getAllCurrentChart();
      this.drawBarChart2();
    },

    selectValueByDrop(selectValue, changingSelected, index) {
      this[changingSelected] = selectValue;
      this[changingSelected + "Index"] = index;

      this.getAllCurrentChart();
      this.drawBarChart();
      this.drawBarChart2();
      this.drawScatter();
    },

    checkDataIsNull(data) {
      // console.log(data);
      switch (data) {
        case undefined:
          // console.log(true)
          return true;

        case []:
          // console.log(true)
          return true;

        case [null]:
          // console.log(true)
          return true;

        case [[]]:
          // console.log(true)
          return true;

        default:
          if (data[0] == null) {
            // console.log(true)
            return true
          }
          if (data[0] == undefined) {
            // console.log(true)
            return true
          }
          if (data?.[0].data) {
            switch (data?.[0].data) {
              case undefined:
                // console.log(true)
                return true;

              case []:
                // console.log(true)
                return true;

              case "":
                // console.log(true)
                return true;

              case null:
                // console.log(true)
                return true;

              case [null]:
                // console.log(true)
                return true;

              default:
                switch (data?.[0].data?.[0]) {
                  case 0:
                    // console.log(false)
                    return false;

                  case null:
                    // console.log(true)
                    return true;

                  case undefined:
                    // console.log(true)
                    return true;

                  case "":
                    // console.log(true)
                    return true;
                  
                  default: 
                    if (data?.[0]?.data?.[0]?.length == 0) {
                      // console.log(true)
                      return true;
                    }
                    // console.log(false)
                    return false;
                }
            }
          }
          // console.log(false)
          return false;
      }
    },
  },
};
</script>

<style scoped>
/* CommoditySales */
.modal-content {
  border-radius: 15px;
  /* width: 560px; */
}

.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding: 50px;
}

.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}

.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
  border: none;
  outline: none;
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}

.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}

.flex-wrap .pie-div {
  padding: 20px 20px 0;
}

.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
  /* height: 356px; */
}

.bar-div-commodity {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 356px;
}

.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}

.change-date-li:hover {
  background-color: #eaf3f4;
}

.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}


:deep(.vue-apexcharts) {
  /* min-height: 500px !important; */
}

.m-l-24 {
  margin-left: 24px;
}
</style>
