<template>
  <div class="p-4">
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <p>
              <img src="../../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
            </p>
            <p class="mb-5" style="margin-left: 36px; word-break: break-all">
              {{
                this.resGroup.includeLabelNames.toString().replace(/,/g, "、")
              }}
            </p>
            <p>
              <img
                src="../../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
            </p>
            <p style="margin-left: 36px">
              {{
                this.resGroup.excludeLabelNames.toString().replace(/,/g, "、")
              }}
            </p>
          </div>
          <!-- <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div> -->
        </div>
      </div>
    </div>
    <DataTable
      v-model:selection="selectedEvent"
      :value="this.newTable"
      :paginator="true"
      :rows="5"
      paginatorPosition="top"
      :pageLinkSize="3"
      paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
      currentPageReportTemplate="目前 {currentPage} ，共 {totalPages} 頁"
      :style="{ textAlign: 'center' }"
      :first="this.nowpage"
      selectionMode="single"
    >
      <template #paginatorstart>
        <div class="d-flex">
          <div
            style="background-color: #eaf3f4; border-radius: 10px; width: 220px"
            class="d-flex"
          >
            <button
              class="basic-btn highlight-btn"
              style="color: #575757"
              @click="changeTable('排程')"
            >
              排程
            </button>
            <button
              class="basic-btn"
              style="color: #575757"
              @click="changeTable('自動化劇本')"
            >
              自動化劇本
            </button>
          </div>
          <div
            class="d-flex justify-content-between align-items-center ms-3"
            style="width: 280px"
          >
            <div class="col-7" style="position: relative">
              <img
                style="position: absolute; top: 8px; left: 10px"
                src="../../assets/search-black.png"
                alt="searchImg"
              />
              <input
                style="
                  padding-left: 35px;
                  width: 100%;
                  height: 35px;
                  border-radius: 10px;
                  border: 1px solid #575757;
                "
                v-model="searchInput"
                placeholder="搜尋名稱"
              />
            </div>
            <div
              class="search-container col-5"
              style="margin-left: 10px"
              ref="target"
            >
              <div
                class="d-flex justify-content-between align-items-center"
                style="
                  width: 330px;
                  border: 1px solid #c4c4c4;
                  border-radius: 10px;
                  padding: 0 14px;
                  height: 35px;
                "
                @click="autoShow = !autoShow"
              >
                {{ triggerType }}
                <div
                  class="d-flex align-items-center"
                  style="width: 50px"
                  :class="
                    this.filterCount === 0
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  "
                >
                  <div
                    style="
                      position: relative;
                      background-color: #71afb6;
                      border-radius: 10px;
                      width: 20px;
                      height: 20px;
                    "
                    v-if="this.filterCount != 0"
                  >
                    <span
                      style="
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        top: 0px;
                        left: 6.5px;
                      "
                    >
                      {{ filterCount }}
                    </span>
                  </div>
                  <img
                    style="width: 10px; height: 8px"
                    src="../../assets/selection-arrow.png"
                    alt="searchImg"
                  />
                </div>
              </div>
              <div
                class="auto-complicate-div text-start"
                style="width: 330px"
                v-show="autoShow"
                @mouseenter="autoShow = true"
              >
                <p class="m-0 p-1 ps-3 my-2">劇本狀態</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div class="d-flex flex-wrap">
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="無"
                      id="無"
                      value="無"
                      v-model="checkedNames2"
                    />
                    <label for="無" style="width: 83%">無</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="執行中"
                      id="執行中"
                      value="執行中"
                      v-model="checkedNames2"
                    />
                    <label for="執行中" style="width: 83%">執行中</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="已完成"
                      id="已完成"
                      value="已完成"
                      v-model="checkedNames2"
                    />
                    <label for="已完成" style="width: 83%">已完成</label>
                  </p>
                </div>
                <p class="m-0 p-1 ps-3 my-2">活動時間</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div
                  class="
                    m-0
                    p-2
                    ps-3
                    auto-complicate-p
                    d-flex
                    align-items-center
                  "
                >
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="dateRange"
                    id="dateRange"
                    :value="0"
                    v-model="checkedNames3"
                  />
                  <div style="width: 90%; position: relative">
                    <DatePicker v-model="filterDateRange" is-range>
                      <!-- @dayclick="updateRangeday(index)" -->
                      <template v-slot="{ togglePopover }">
                        <input
                          id="dropdown-moreoption-rangeinput"
                          class="ps-5"
                          style="
                            cursor: default;
                            width: 100%;
                            height: 40px;
                            border-radius: 10px;
                            border: 1px solid #c4c4c4;
                          "
                          placeholder="選擇日期"
                          :value="time_range"
                          @click="togglePopover()"
                          readonly
                        />
                        <img
                          src="../../assets/date.svg"
                          alt=""
                          style="position: absolute; left: 10px; top: 10px"
                        />
                        <img
                          src="../../assets/selection-arrow.png"
                          alt="searchImg"
                          style="position: absolute; right: 10px; top: 16px"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <hr
        class="m-0"
        style="
          width: 100%;
          opacity: 1;
          height: 0;
          border: 0;
          border-bottom: 1px solid #c4c4c4;
          position: relative;
          top: 78px;
        "
      />
      <Column
        selectionMode="single"
        headerStyle="width: 3em"
        style="line-height: 54px"
      >
      </Column>
      <Column field="script_name" header="活動名稱" :sortable="true">
        <template #body="slotProps2">
          <div>
            <div>
              {{ slotProps2.data.script_name }}
            </div>
            <div style="color: #c4c4c4; font-size: 12px">
              {{ slotProps2.data.活動時間 }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="template_name" header="模板名稱" :sortable="true">
      </Column>
      <Column
        field="label_name"
        header="選擇客群"
        :sortable="true"
        style="max-width: 210px"
      >
        <template #body="slotProps2">
          <div
            class="d-flex flex-wrap flex-column align-items-center justify-content-center"
          >
            <div
              class="ellipsis-labelName-inRFMCustomPage col-12"
              v-tooltip.bottom="
                slotProps2.data.label_name.includeLabelNames.toString().length >
                13
                  ? {
                      value:
                        slotProps2.data.label_name.includeLabelNames.toString(),
                      class: 'tip-LabelManage',
                    }
                  : {
                      value: '',
                      class: '',
                    }
              "
            >
              {{ slotProps2.data.label_name.includeLabelNames.toString() }}
            </div>
            <div
              style="
                color: #c4c4c4;
                font-size: 12px;
                text-decoration: underline;
                cursor: pointer;
              "
              @click="openExcludeModal(slotProps2.data.label_name)"
              v-if="
                slotProps2.data.label_name.excludeLabelNames[0] != undefined
              "
            >
              瀏覽完整名單
            </div>
          </div>
        </template>
      </Column>
      <Column field="script_state" header="自動回應" :sortable="true">
        <template #body="slotProps2">
          <div>
            <div v-if="slotProps2.data.script_state == '無'"></div>
            <div
              :style="
                slotProps2.data.script_state === '執行中' ? 'color:#6BB996' : ''
              "
              v-else
            >
              <img
                src="../../assets/greenSpot.png"
                alt=""
                class="mb-1 me-2"
                style="width: 8px"
                v-if="slotProps2.data.script_state === '執行中'"
              /><img
                src="../../assets/blackBigSpot.png"
                alt=""
                class="mb-1 me-2"
                style="width: 8px"
                v-if="slotProps2.data.script_state === '已完成'"
              />{{ slotProps2.data.script_state }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="ScheduleExpression" header="發送時間" :sortable="true">
      </Column>
    </DataTable>
    <div class="d-flex justify-content-center mt-4">
      <button
        type="button"
        class="basic-btn"
        style="width: 130px; color: #575757"
        data-bs-dismiss="modal"
      >
        取消
      </button>
      <button
        type="button"
        class="basic-btn highlight-btn ms-3"
        style="width: 130px"
        data-bs-dismiss="modal"
        @click="rowSelect"
      >
        確認
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "ScheduleTable",
  components: {
    DataTable,
    Column,
  },
  props: {
    selectedTable: Array,
    eventID: String,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return { target, autoShow };
  },
  data() {
    return {
      nowpage: 0,

      selectedEvent: {},
      originTable: [],
      newTable: [],

      triggerType: "篩選",
      checkedNames: [],
      checkedNames2: [],
      checkedNames3: [],
      filterDateRange: {},
      time_range: "",
      searchInput: "",

      resGroup: {
        includeLabelNames: [],
        excludeLabelNames: [],
      },
    };
  },
  watch: {
    monthData: function (val) {
      console.log("val", val);

      if (val === null) {
        this.get();
      } else {
        const searched = this.searchTodos.filter((d) => {
          console.log(
            "dS",
            d.ScheduleExpression.toLocaleString("en-ZA").slice(0, 10)
          );

          if ((val.month + 1).toString().length < 2) {
            this.searchMonth = "0" + (val.month + 1).toString();
          } else {
            this.searchMonth = val.month + 1;
          }
          console.log("month", this.searchMonth);

          const dateString = val.year + "/" + this.searchMonth;

          console.log(
            "find its",
            d.ScheduleExpression.toLocaleString("en-ZA").includes(dateString)
          );
          return d.ScheduleExpression.toLocaleString("en-ZA").includes(
            dateString
          );
        });
        console.log("try this", searched);
        this.newtodos = searched;
      }
    },
    searchInput: function (val) {
      if (val === "") {
        this.newTable = this.originTable;
      } else {
        console.log(val);
        this.newTable = this.newTable.filter((d) =>
          d.script_name.includes(val)
        );
      }
    },
    checkedNames(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.newTable = this.originTable;
        return;
      }
      this.newTable = this.originTable.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    checkedNames2(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.newTable = this.originTable;
        return;
      }
      this.newTable = this.originTable.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    checkedNames3(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.newTable = this.originTable;
        return;
      }
      this.newTable = this.originTable.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    filterDateRange() {
      this.updateRange();
      if (this.checkedNames3[0] != undefined) {
        this.newTable = this.originTable.filter((d) => {
          return (
            (this.checkedNames[0] === d.AB_type ||
              this.checkedNames[1] === d.AB_type ||
              this.checkedNames[2] === d.AB_type ||
              this.checkedNames[0] === undefined) &&
            (this.checkedNames2[0] === d.script_state ||
              this.checkedNames2[1] === d.script_state ||
              this.checkedNames2[2] === d.script_state ||
              this.checkedNames2[3] === d.script_state ||
              this.checkedNames2[0] === undefined) &&
            ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
              this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
              (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
                this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
              this.checkedNames3[0] === undefined)
          );
        });
      }
    },
    selectedEvent() {
      console.log(this.selectedEvent);
    },
  },
  computed: {
    filterCount() {
      return (
        this.checkedNames.length +
        this.checkedNames2.length +
        this.checkedNames3.length
      );
    },
  },
  created() {
    var selectedEventIndex = 0;
    var event = {};
    this.originTable = this.$props.selectedTable;
    this.newTable = this.$props.selectedTable;
    if (this.$props.eventID != "" && this.$props.eventID != undefined) {
      event = this.originTable.find((d) => {
        return d.Name === this.$props.eventID;
      });
      selectedEventIndex = this.originTable.findIndex((d, index) => {
        return d.Name === this.$props.eventID;
      });
      if (selectedEventIndex != -1) {
        this.nowpage = (Math.ceil(selectedEventIndex / 5) - 1) * 5;
      }

      if (event != undefined) {
        this.selectedEvent = event;
      }
    }
  },
  methods: {
    changeTable(select) {
      this.$emit("changeTable", select);
    },
    openExcludeModal(resGroup) {
      this.resGroup = resGroup;
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    updateRange() {
      console.log(this.filterDateRange);
      console.log(this.filterDateRange.start.toISOString());
      this.time_range =
        this.filterDateRange.start.toISOString().slice(0, 10) +
        "~" +
        this.filterDateRange.end.toISOString().slice(0, 10);
    },
    // 確認排程
    rowSelect() {
      if (this.selectedEvent != undefined) {
        this.$emit(
          "saveEventID",
          this.selectedEvent.Name,
          this.selectedEvent.script_name,
          this.selectedEvent.ScheduleExpression
        );
      }
      this.$emit("closeEventTable");
    },
  },
};
</script>

<style scoped>
.ellipsis-labelName-inRFMCustomPage {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:deep(.p-paginator) {
  margin-bottom: 60px;
}
:deep(.p-datatable-thead tr th) {
  line-height: 90px;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  height: 70px;
}
:deep(.p-datatable .p-datatable-tbody > tr.p-highlight) {
  color: #575757 !important;
}
:deep(.p-radiobutton .p-radiobutton-box .p-radiobutton-icon) {
  display: none;
}
:deep(.p-radiobutton-focused) {
  display: none;
}
:deep(.p-radiobutton .p-radiobutton-box.p-highlight) {
  background-color: #71afb6;
  height: 14px;
  width: 14px;
  border-radius: 20px;
  margin-top: 2px;
  margin-left: 2px;
}
:deep(.p-radiobutton) {
  height: 20px;
  margin-bottom: 1rem;
  width: 20px;
}
</style>