<template>
  <div v-if="notshow" class="d-flex justify-content-center align-items-center">
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" style="padding-left: 40px; padding-top: 53px" v-else>
    <div class="title-span fs-medium">行銷名單管理</div>

    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div class="container-fluid p-0">
      <div class="tab-container d-flex">
        <div class="col-6 d-flex justify-content-between align-items-center">
          <div class="col-2">
            <label for="namelist" class="label-font">名單狀態</label>
          </div>
          <div class="col-10">
            <select id="namelist" class="selector" v-model="selectedNameList">
              <option value="all">全部會員</option>
              <option value="normal">正常可寄送名單</option>
              <option value="email_invalid">Email 無效名單</option>
              <option value="sms_invalid">SMS 無效名單</option>
            </select>
          </div>
        </div>
        <div class="col-6 d-flex">
          <div class="col-10">
            <div v-if="this.selectedNameList === 'normal'">
              <button id="dropdownMenuClickableInside" class="btn dropdown-toggle btn-clickforce"
                data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"
                @click="clickforceMenufunction">
                <span v-if="this.checkedNames2.length === 0">選擇渠道</span>
                <span v-else>{{ this.checkedNames2.join(",") }} </span>
                <img src="../assets/blue-dropdown-arrow.svg" alt="" style="float: right; margin-top: 0.7rem" />
              </button>
              <ul class="dropdown-menu dropdown-custom" id="dropdownMenu1" style="background-color: #eaf3f4">
                <li>
                  <div class="
                      dropdown-item
                      form-check
                      d-flex
                      align-items-center
                      custom-checkbox
                      no-hover
                    " style="margin-left: 2rem">
                    <input class="form-check-input no-hover" type="checkbox" name="action" value="34"
                      id="flexCheckDefault" v-model="checked2" @click="changeAllChecked2()" />
                    <!-- <span class="cr" @click="changeAllChecked2()"
                                >{{checked2}}</span>
                             -->
                    <label class="no-hover" style="margin-left: 1rem; background-color: transparent">
                      全選
                    </label>
                  </div>
                </li>
                <hr />
                <div class="d-flex">
                  <div class="d-flex flex-row normal-list-subcontainer" v-for="items in tools" :key="items"
                    style="margin-left: 1rem">
                    <input class="form-check-input" type="checkbox" :id="items" :value="items"
                      v-model="checkedNames2" />
                    <!-- @click="saveValue(items)" -->
                    <label :for="items" style="margin-left: 1rem">{{ items }}
                    </label>
                  </div>
                </div>
              </ul>
            </div>

            <select id="namelist2" class="selector" v-if="
              this.selectedNameList === 'email_invalid' ||
              this.selectedNameList === 'sms_invalid'
            " v-model="selectedAction">
              <option value="bounce" v-if="this.selectedNameList === 'email_invalid'">
                永久退信
              </option>
              <option value="soft_bounce" v-if="this.selectedNameList === 'email_invalid'">
                暫時退信
              </option>
              <option value="unsubscribe" v-if="this.selectedNameList === 'email_invalid'">
                取消訂閱
              </option>
              <option value="complaint" v-if="this.selectedNameList === 'email_invalid'">
                抱怨
              </option>
              <option value="delete" v-if="this.selectedNameList === 'email_invalid'">
                Email手動移除
              </option>

              <option value="bounce" v-if="this.selectedNameList === 'sms_invalid'">
                永久退信
              </option>
              <option value="delete" v-if="this.selectedNameList === 'sms_invalid'">
                SMS手動移除
              </option>
            </select>
          </div>
          <div class="col-2">
            <button class="check-button" @click="getTableButton()">
              查看名單
            </button>
          </div>
        </div>
      </div>

      <div class="tab-container">
        <div style="position: relative">
          <div class="absolute-class2">
            <span class="label-font">名單:</span>
            <span v-if="this.total != ''">&nbsp; &nbsp;{{ total }}人</span>
            <span v-else></span>
          </div>
        </div>

        <DataTable :value="tableApi" :paginator="true" :rows="10"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink" paginatorPosition="top"
          :style="{ textAlign: 'center' }">
          <Column field="name" header="會員名稱" :sortable="true"></Column>
          <Column field="member_id" header="Member ID" :sortable="true"></Column>
          <Column field="email" header="Email" :sortable="true"></Column>
          <Column field="phone" header="電話" :sortable="true"></Column>
        </DataTable>
      </div>
      <div class="container-fluid d-flex justify-content-center pe-4 mt-5">
        <button class="btn btn-lg btn-download2" style="padding: 10px" @click="getcsv">
          匯出篩選名單
        </button>
      </div>
    </div>
  </div>
</template>

        

<script>
import { allapi2 } from "../../public/static2/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "MemberStatus",
  components: {
    DataTable,
    Column,
  },

  data() {
    return {
      notshow: false,
      selectedNameList: "all",
      selectedAction: "all", // all
      checked2: false,
      checkedNames2: [],
      total: "",
      tableApi: [],

      tools: [
        // {
        //   text:'Email',
        //   value:'Email'
        // },
        // {
        //   text:'SMS',
        //   value:'Sms'
        // },
        //    {
        //   text:'Line',
        //   value:'Line'
        // },

        "Email",
        "SMS",
        "Line",
      ],

      smsData: "",
      emailData: "",
      lineData: "",

      savingValue: "",

      json_data: [],

      memberIdForExport: [],
    };
  },

  watch: {
    checkedNames2() {
      // console.log('join',this.checkedNames2.join('&'));

      this.selectedAction = this.checkedNames2.join("&");

      console.log("joinaction", this.selectedAction);
    },

    selectedNameList() {
      if (   this.selectedNameList === "email_invalid" ||  this.selectedNameList === "sms_invalid"  ) {
        this.selectedAction = "bounce";
      }
    },
    selectedAction() {
      // this.memberTableApi()
    },
  },

  mounted() {
    // http://localhost:8080 test
    if (
      window.location.origin === "https://firstcdp.retailing-data.net" ||
      window.location.origin === "https://firstcdp-test.retailing-data.net"
    ) {
      this.notshow = true;
    }
    // this.memberTableApi()
    this.memberTableApi()

  },
  methods: {
    // saveValue(items){
    // console.log("save name ", items)
    // this.savingValue = items
    // },

    getTableButton() {
      this.memberTableApi();
    },


    memberTableApi() {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};

      if (
        this.selectedNameList === "email_invalid" ||
        this.selectedNameList === "sms_invalid"
      ) {
        body = {
          member_type: this.selectedNameList,
          action: this.selectedAction,
        };
      } else if (this.selectedNameList === "normal") {
        body = {
          member_type: this.selectedNameList,
          action: this.selectedAction,
        };
      } else {
        body = {
          member_type: "all",
          action: "all",
        };
      }

      // const body = {
      //     member_type: this.selectedNameList,

      //      action: "all"
      //   }
      allapi2
        .memberStatusPost(params, body)
        .then((res) => {
          // console.log("membertable",res);
          this.tableApi = res.data.Data.members;
          this.total = res.data.Data.total;
          console.log("membertableRES", res);
          console.log("membertable", this.tableApi);
          this.tableApi = res.data.Data.members;

          // this.tableApi.forEach((element,index,array) => {
          //   console.log(element.member_id)

          //   this.memberIdForExport = element.member_id

          // });
          this.tableApi.forEach((id) => {
            // for (let key in id){
            //   console.log("aaa", id.member_id)
            //   this.memberIdForExport = id.member_id;
            // }
            // console.log("aa",id.member_id)
            this.memberIdForExport.push(id.member_id);
          });
          // console.log("thelist",this.memberIdForExport)
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    emailapi() {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        member_type: "normal",
        action: "email",
      };

      allapi2
        .memberStatusPost(params, body)
        .then((res) => {
          // console.log("membertable",res);
          this.emailData = res.data.Data.members;
          console.log("emailtable", this.emailData);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    smsapi() {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        member_type: "normal",
        action: "sms",
      };

      allapi2
        .memberStatusPost(params, body)
        .then((res) => {
          // console.log("membertable",res);
          this.smsData = res.data.Data.members;
          console.log("smstable", this.smsData);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    changeAllChecked2() {
      if (this.checked2 == true) {
        this.checkedNames2 = [];
      } else {
        const a = [];
        // this.checkedNames2 = a.Object.assign(this.tools[0][1][2]);
        this.checkedNames2 = a.concat(this.tools);
        this.selectedAction = "all";
        console.log("all has been selected");
        // this.memberTableApi()
      }
    },

    clickforceMenufunction() {
      var myDropdown = document.getElementById("dropdownMenuClickableInside");
      myDropdown.addEventListener("show.bs.dropdown", function () {
        this.dropupArrow1 = true;
      });
      var urDropdown = document.getElementById("dropdownMenuClickableInside");
      urDropdown.addEventListener("hide.bs.dropdown", function () {
        this.dropupArrow1 = false;

        console.log("123");
        console.log("tes2", this.dropupArrow1);
      });
    },
    download() {
      const buildData = (data) => {
        return new Promise((resolve, reject) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(data[0]);
          arrayData.push(arrayTitle);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(data, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title] || "無";
              items.push(item);
            });
            arrayData.push(items);
          });

          resolve(arrayData);
        });
      };
      const downloadCSV = (data) => {
        let csvContent = "";
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(",") + "\n";
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName =
          "下載" +
          this.selectedNameList +
          "資料" +
          new Date().toISOString() +
          ".csv";

        // 建立一個 a，並點擊它
        let link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
        );
        link.setAttribute("download", fileName);
        link.click();
      };
      const data = this.json_data;
      buildData(data).then((data) => downloadCSV(data));
    },
    getcsv: async function () {
      // if (this.editinput) {
      //   const alertModal2 = new bootstrap.Modal(
      //     document.getElementById("alertModal2"),
      //     {
      //       keyboard: false,
      //     }
      //   );
      //   alertModal2.show();
      //   // $("#alertModal2").modal("show");
      //   this.$refs.titleInput.focus();
      // } else if (this.selectedID === "") {
      //   const alertModal3 = new bootstrap.Modal(
      //     document.getElementById("alertModal3"),
      //     {
      //       keyboard: false,
      //     }
      //   );
      //   alertModal3.show();
      // } else {
      // if (this.selectedID != "") {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        member_list: this.memberIdForExport,
      };
      await allapi2
        .memberStatusExportPost(params, body)
        .then((res) => {
          console.log("getcsv", res.data.Data);
          this.json_data = res.data.Data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });

      this.download();
      // }
    },
  },
};
</script>

<style scoped>
/* MemberStatus */
.label-font {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #71afb6;
}

.selector {
  color: #71afb6;
  border-radius: 10px;
  padding: 5px;
  width: 95%;
  text-align: center;
  border-color: #c4c4c4;
  /* text-align-last: center; */
}

option:hover {
  background-color: red;
}

.btn-clickforce {
  background-color: #fff;
  border-color: #c4c4c4;
  height: 40px;
  padding: 0px 20px;
  border-radius: 10px;
  width: 95%;
  color: #71afb6;
}

.btn-clickforce::after {
  display: none;
}

.normal-list-subcontainer {
  width: 33%;
  /* justify-content: space-around; */
  padding-left: 10px;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.check-button {
  color: #fff;
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.dropdown-custom {
  width: 29vw;
}

.btn-download2 {
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
  border: none;
  color: #fff;
  font-size: 18px;
}

.form-check-input:hover {}

.no-hover:hover {
  background-color: transparent;
}

.form-check-input:checked {
  background-color: #575757;
  border-color: #575757;
}

.form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.absolute-class2 {
  position: absolute;
  top: 5px;
  width: 70%;
  z-index: 1;
}
</style>