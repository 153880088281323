<template>
  <div class="col-12 p-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="col-3">
        <p class="section-title fs-large">分群名單</p>
      </div>
      <div class="d-flex">
        <div class="text-end me-5">
          <img class="searchImg" src="../../assets/search-black.png" alt="searchImg" />
          <input class="search-input-in-rfm-custom" v-model="searchInput" placeholder="搜尋名稱" />
        </div>
        <div class="text-end me-4" style="position: relative">
          <Paginator :rows="this.perPage" :pageLinkSize="3" template="PrevPageLink PageLinks NextPageLink "
            :totalRecords="todosAdv.length" @page="onPage($event)" class="m-0"></Paginator>
           <div
              style="
                position: absolute;
                right: 5px;
                bottom: -25px;
                font-size: 14px;
                color: #8a8a8a;
                min-width:100px;
              "
              v-if="this.todosAdv.length === 0"
            >
              目前 0 ，共
              {{ Math.ceil(this.todosAdv.length / this.perPage) }} 頁
            </div>
            <div
              style="
                position: absolute;
                right: 5px;
                bottom: -25px;
                font-size: 14px;
                color: #8a8a8a;
                min-width:100px;
              "
              v-else
            >
              目前 {{ this.page }} ，共
              {{ Math.ceil(this.todosAdv.length / this.perPage) }} 頁
            </div>
        </div>
      </div>
    </div>

    <div class="noData" v-if="this.todosAdv.length === 0">查無分群名單</div>
    <div class="container-fluid container-flexdirection-custom p-0 m-0" v-else>
      <div class="col-3">
        <button class="add-template mx-0" @click="addNewTodo">
          <img src="../../assets/greenPlus.svg" alt="" style="position: relative; top: -1.5px" class="me-2" />新的個性化分群
        </button>
      </div>
      <FavoriteNameList v-bind="$attrs" :key="index" v-for="(todo, index) in displayedRecords" :todo="todo"
        :selectedID="selectedID"></FavoriteNameList>
    </div>
  </div>
</template>

<script>
import FavoriteNameList from "./FavoriteNameList.vue";
import Paginator from "primevue/paginator";
import Pagination from "v-pagination-3";
import { allapi2 } from "../../../public/static2/api/apigClient.js";

export default {
  props: ["todosAdv", "selectedID"],
  name: "FavoriteList",
  components: {
    FavoriteNameList,
    Paginator,
  },
  data() {
    return {
      page: 1,
      perPage: 7,
      records: [],
      searchInput: "",
    };
  },
  watch: {
    searchInput: function (val, oldVal) {
      console.log(this.todosAdv);
      console.log(val);
      this.page = 1;
      // this.searchTodos=this.todos.filter((d) => d.template_name.includes(val))
      this.$emit("search", val);
    },
  },
  methods: {
    onPage(event) {
      this.page = event.page + 1;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
    },
    addNewTodo() {
      this.$emit("clearall");
    },
  },
  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.todosAdv.slice(startIndex, endIndex);
    },
  },
};
</script>

<style scoped>
.fs-large {
  font-size: 18px;
}

.pagination {
  justify-content: end;
  margin-right: 22px;
}

.noData {
  padding: 50px;
  font-size: 20px;
}

.searchImg {
  position: relative;
  top: -2px;
  left: 28px;
}

.search-input-in-rfm-custom {
  border-radius: 10px;
  text-indent: 30px;
  border: 1px solid #575757;
  /* width: 12rem; */
  height: 40px;
}

.section-title {
  color: #71afb6;
}

.container-flexdirection-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-template {
  width: 91%;
  height: 40px;
  margin: 12.5px;
  border: 1px dashed #71afb6;
  border-radius: 10px;
  background-color: white;
  color: #71afb6;
  position: relative;
}

/* .VuePagination__pagination-item-prev-chunk {
  display: none;
}
.VuePagination__pagination-item-next-chunk {
  display: none;
}
.VuePagination__pagination-item-next-page .page-link {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.VuePagination__pagination-item-prev-page .page-link {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.VuePagination__count {
  display: none;
}
.page-item.disabled .page-link {
  background-color: #eaf3f4;
}
.page-item.active .page-link {
  z-index: 3;
  background-color: #71afb6;
  color: #fff;
  border-color: transparent;
}
.page-item.disable .page-link {
  background-color: #71afb6;
}
.page-link {
  color: #000;
  font-weight: 700;
  background-color: #eaf3f4;
} */
/* @media screen and (min-width: 1024px) and (max-width: 1200px) {
  .container-flexdirection-custom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .template-pagination {
    transform: translate(-50%, 0);
  }
} */
</style>
