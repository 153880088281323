<template>
  <div class="leftNav" v-if="!this.login">
    <ul class="list-unstyled components">
      <li
        style="margin-bottom: 30px"
        v-if="this.leftshow.OperationalPerformance"
      >
        <div
          :class="behavior ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.behavior = !this.behavior"
        >
          <img src="../assets/trendGray.png" v-show="!behavior" />
          <img src="../assets/trend.png" v-show="behavior" />
          <span class="ms-6rem-custom"> 營運表現 </span>
          <img src="../assets/rightArrow.png" v-show="!behavior" />
          <img src="../assets/downArrow.png" v-show="behavior" />
        </div>
        <div class="ms-3" :class="behavior ? 'show' : 'noshow'">
          <router-link
            to="/OperationalOverview"
            active-class="active"
            v-if="this.leftshow.OperationalOverview"
          >
            營運總覽
          </router-link>
          <router-link
            to="/MembershipSales"
            active-class="active"
            v-if="this.leftshow.MembershipSales"
          >
            會員銷售
          </router-link>
          <div v-if="this.leftshow.CommoditySales">
            <div
              class="retailGoods"
              :class="retailGoods ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.retailGoods = !this.retailGoods"
            >
              商品銷售

              <img src="../assets/rightArrow.png" v-show="!retailGoods" />
              <img src="../assets/downArrow.png" v-show="retailGoods" />
            </div>
            <div class="ms-2" :style="retailGoods ? '' : 'display:none'">
              <router-link
                to="/CommoditySales"
                active-class="active"
                style="padding-left: 55px; margin: 0 10px 14px 0"
                v-if="this.leftshow.CommoditySales"
              >
                銷售表現
              </router-link>
              <!-- <router-link
                to="/CombinatorialAnalysis"
                active-class="active"
                style="padding-left: 55px; margin: 0 10px 0 0"
              >
                進階組合分析
              </router-link> -->
            </div>
          </div>
          <router-link
            to="/StoreChannelSales"
            active-class="active"
            v-if="this.leftshow.StoreChannelSales"
          >
            門市通路銷售
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.leftshow.WebsiteBehavior">
        <div
          :class="Website ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.Website = !this.Website"
        >
          <img src="../assets/website-anal-grey.svg" v-show="!Website" />
          <img src="../assets/website-anal-blue.svg" v-show="Website" />
          <span class="ms-6rem-custom"> 網站行為 </span>
          <img src="../assets/rightArrow.png" v-show="!Website" />
          <img src="../assets/downArrow.png" v-show="Website" />
        </div>
        <div class="ms-3" :class="Website ? 'show' : 'noshow'">
          <router-link
            to="/WebsiteAnalysis"
            active-class="active"
            v-if="this.leftshow.WebsiteAnalysis"
          >
            網站行為分析
          </router-link>
          <router-link
            to="/clickForceTag"
            active-class="active"
            v-if="this.leftshow.clickForceTag"
          >
            域動站外標籤
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.leftshow.CustomerShopbehavior">
        <div
          :class="labelGroup ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.labelGroup = !this.labelGroup"
        >
          <img src="../assets/analyzeGray.png" v-show="!labelGroup" />
          <img src="../assets/analyze.png" v-show="labelGroup" />
          <span class="ms-6rem-custom"> 顧客購物行為 </span>
          <img src="../assets/rightArrow.png" v-show="!labelGroup" />
          <img src="../assets/downArrow.png" v-show="labelGroup" />
        </div>
        <div class="ms-3" :class="labelGroup ? 'show' : 'noshow'">
          <router-link
            to="/ValueGrouping"
            active-class="active"
            v-if="this.leftshow.ValueGrouping"
          >
            整體顧客行為
          </router-link>
          <router-link
            to="/CustomLabel"
            active-class="active"
            v-if="this.leftshow.CustomLabel"
          >
            線上購物行為
          </router-link>
          <router-link
            to="/OfflineBehavior"
            active-class="active"
            v-if="this.leftshow.OfflineBehavior"
          >
            線下購物行為
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.leftshow.New360Page">
        <div
          :class="New360 ? 'highlight' : 'nohighlight'"
          active-class="active"
        >
          <router-link
            class="my-0"
            to="/New360Page"
            active-class="active"
            style="padding-left: 5px"
          >
            <img src="../assets/New360-grey.svg" v-show="!New360" />
            <img src="../assets/New360.svg" v-show="New360" />
            <span style="padding-left: 0.65rem"> 360度顧客輪廓 </span>
          </router-link>
        </div>
      </li>
      <li style="margin-bottom: 30px" v-if="this.leftshow.CustomerLabel">
        <div
          :class="tagGroup ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.tagGroup = !this.tagGroup"
        >
          <img src="../assets/tag.svg" v-show="!tagGroup" />
          <img src="../assets/lightTag.svg" v-show="tagGroup" />
          <span class="ms-6rem-custom"> 顧客貼標分群 </span>
          <img src="../assets/rightArrow.png" v-show="!tagGroup" />
          <img src="../assets/downArrow.png" v-show="tagGroup" />
        </div>
        <div class="ms-3" :class="tagGroup ? 'show' : 'noshow'">
          <router-link
            to="/RFMpage"
            active-class="active"
            v-if="this.leftshow.RFMpage"
          >
            價值分群
          </router-link>
          <router-link
            to="/RFMCustomPage"
            active-class="active"
            v-if="this.leftshow.RFMCustomPage"
          >
            個性化分群
          </router-link>
          <!-- <router-link to="/ChannelMarketing" active-class="active">
            渠道行銷分群
          </router-link> -->
          <!-- <router-link to="/LabelsReport" active-class="active">
            分群標籤報表
          </router-link> -->
          <router-link
            to="/LabelManage"
            active-class="active"
            v-if="this.leftshow.LabelManage"
          >
            分群管理與報表
          </router-link>
        </div>
      </li>

      <hr
        style="
          width: 100%;
          margin-top: 0px;
          opacity: 1;
          height: 0;
          border: 0;
          border-bottom: 1px solid #63a7c8;
        "
      />
      <li class="mt-4" style="margin-bottom: 30px" v-if="this.leftshow.Rema">
        <div
          :class="auto ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.auto = !this.auto"
        >
          <!-- 拿掉 @click="this.auto = !this.auto" -->
          <img src="../assets/rema.svg" />
          <span class="ms-025rem-custom"> ReMA行銷自動化 </span>
          <img src="../assets/rightArrow.png" v-show="!auto" />
          <img src="../assets/downArrow.png" v-show="auto" />
        </div>

        <div :class="auto ? 'show' : 'noshow'">
          <div class="ps-3 mb-3 mt-3">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaEmail ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaEmail = !this.remaEmail"
              v-if="this.leftshow.RemaEmail"
            >
              Email
              <img src="../assets/rightArrow.png" v-show="!remaEmail" />
              <img src="../assets/downArrow.png" v-show="remaEmail" />
            </div>
            <div :style="remaEmail ? '' : 'display:none'">
              <router-link
                to="/ProductTextTemplate"
                :class="highlightEmail ? 'active' : ''"
                active-class="active"
                v-if="this.leftshow.ProductTextTemplate"
              >
                Email模板
              </router-link>
              <router-link
                to="/MarketingSchedule"
                active-class="active"
                v-if="this.leftshow.MarketingSchedule"
              >
                排程
              </router-link>
            </div>
          </div>
          <div class="ps-3 mb-3 mt-3" v-if="notshow">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaSms ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaSms = !this.remaSms"
              v-if="this.leftshow.RemaSMS"
            >
              SMS

              <img src="../assets/rightArrow.png" v-show="!remaSms" />
              <img src="../assets/downArrow.png" v-show="remaSms" />
            </div>
            <div class="ms-1" :style="remaSms ? '' : 'display:none'">
              <router-link
                to="/ProductSmsTemplate"
                :class="highlightSms ? 'active' : ''"
                active-class="active"
                v-if="this.leftshow.ProductSmsTemplate"
              >
                簡訊模板
              </router-link>
              <router-link
                to="/SmsSchedule"
                active-class="active"
                v-if="this.leftshow.SmsSchedule"
              >
                排程
              </router-link>
            </div>
          </div>
          <!-- <div class="ps-3 mb-3 mt-3" v-if="notshow">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaGoogle ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaGoogle = !this.remaGoogle"
            >
              Google Ads

              <img src="../assets/rightArrow.png" v-show="!remaGoogle" />
              <img src="../assets/downArrow.png" v-show="remaGoogle" />
            </div>
            <div :style="remaGoogle ? '' : 'display:none'">
              <router-link to="/" active-class="active">
                google test
              </router-link>
            </div>
          </div> -->

          <div class="ps-3 mb-3 mt-3" v-if="this.leftshow.RemaFB">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaFacebook ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaFacebook = !this.remaFacebook"
            >
              FB Ads

              <img src="../assets/rightArrow.png" v-show="!remaFacebook" />
              <img src="../assets/downArrow.png" v-show="remaFacebook" />
            </div>
            <div class="ms-2" :style="remaFacebook ? '' : 'display:none'">
              <router-link
                to="/FacebookAdsPackage"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.FacebookAdsPackage"
              >
                受眾包預覽
              </router-link>
              <router-link
                to="/FbShortUrl"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.FbShortUrl"
              >
                點擊追蹤功能
              </router-link>
            </div>
          </div>
          <div class="ps-3 mb-3 mt-3" v-if="this.leftshow.RemaLine">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaLine ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaLine = !this.remaLine"
            >
              Line

              <img src="../assets/rightArrow.png" v-show="!remaLine" />
              <img src="../assets/downArrow.png" v-show="remaLine" />
            </div>
            <div class="ms-2" :style="remaLine ? '' : 'display:none'">
              <router-link
                to="/LineAdsPackage"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.LineAdsPackage"
              >
                受眾包預覽
              </router-link>
              <router-link
                to="/LineShortUrl"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.LineShortUrl"
              >
                點擊追蹤功能
              </router-link>
            </div>
          </div>
          <div class="ps-3 mb-3 mt-3" v-if="this.leftshow.AutomationScript">
            <div
              class="ms-6rem-custom ps-4"
              :class="remaScript ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.remaScript = !this.remaScript"
            >
              自動化劇本

              <img src="../assets/rightArrow.png" v-show="!remaScript" />
              <img src="../assets/downArrow.png" v-show="remaScript" />
            </div>
            <div class="ms-1" :style="remaScript ? '' : 'display:none'">
              <router-link
                to="/ScriptTable"
                active-class="active"
                v-if="this.leftshow.ScriptTable"
              >
                劇本一覽表
              </router-link>
            </div>
          </div>
        </div>
      </li>

      <li style="margin-bottom: 30px" v-if="this.leftshow.MarketingPerformance">
        <div
          :class="advEffect ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.advEffect = !this.advEffect"
        >
          <img src="../assets/effectGray.svg" v-show="!advEffect" />
          <img src="../assets/effect.svg" v-show="advEffect" />
          <span class="ms-6rem-custom"> 行銷成效 </span>
          <img src="../assets/rightArrow.png" v-show="!advEffect" />
          <img src="../assets/downArrow.png" v-show="advEffect" />
        </div>
        <div class="ms-3" :class="advEffect ? 'show' : 'noshow'">
          <router-link
            to="/FullCalendar"
            active-class="active"
            v-if="this.leftshow.FullCalendar"
          >
            活動行事曆
          </router-link>
          <router-link
            to="/AllAdvEffect"
            active-class="active"
            v-if="this.leftshow.AllAdvEffect"
          >
            整體行銷成效
          </router-link>
          <div class="ps-3 mb-3" v-if="this.leftshow.EmailPerformance">
            <div
              class="ms-6rem-custom ps-4"
              :class="email ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.email = !this.email"
            >
              Email
              <img src="../assets/rightArrow.png" v-show="!email" />
              <img src="../assets/downArrow.png" v-show="email" />
            </div>
            <div :style="email ? '' : 'display:none'">
              <router-link
                to="/EmailMarketingTable"
                :class="highlightPerformanceEmail ? 'active' : ''"
                active-class="active"
                style="
                  padding-left: 40px;
                  margin: 0 10px 0 0;
                  position: relative;
                "
                v-if="this.leftshow.EmailMarketingTable"
              >
                投放記錄一覽表
              </router-link>
              <router-link
                to="/LatestResult"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.LatestResult"
              >
                整體投放趨勢
              </router-link>
            </div>
          </div>
          <div class="ps-3 mb-3" v-if="this.leftshow.SMSperformance">
            <div
              class="ms-6rem-custom ps-4"
              :class="sms ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.sms = !this.sms"
            >
              SMS
              <img src="../assets/rightArrow.png" v-show="!sms" />
              <img src="../assets/downArrow.png" v-show="sms" />
            </div>
            <div :style="sms ? '' : 'display:none'">
              <router-link
                :class="highlightPerformanceSMS ? 'active' : ''"
                to="/SmsMarketingTable"
                class="overflow-text"
                active-class="active "
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.SmsMarketingTable"
              >
                投放記錄一覽表
              </router-link>

              <router-link
                to="/SmsLatestResult"
                class="overflow-text"
                active-class="active "
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.SmsLatestResult"
              >
                整體投放趨勢
              </router-link>
            </div>
          </div>
          <div class="ps-3 mb-3" v-if="this.leftshow.FBperformance">
            <div
              class="ms-6rem-custom ps-4"
              :class="fb ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.fb = !this.fb"
            >
              Fb ads

              <img src="../assets/rightArrow.png" v-show="!fb" />
              <img src="../assets/downArrow.png" v-show="fb" />
            </div>
            <div :style="fb ? '' : 'display:none'">
              <router-link
                to="/FbadsTable"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.FbadsTable"
              >
                投放紀錄一覽表
              </router-link>
              <router-link
                to="/FbTrendAnal"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.FbTrendAnal"
              >
                趨勢比較分析
              </router-link>
              <router-link
                to="/FbThemeAnal"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
                v-if="this.leftshow.FbThemeAnal"
              >
                活動主題比較
              </router-link>
            </div>
          </div>
          <!-- <div class="ps-3 mb-3">
            <div
              class="ms-6rem-custom ps-4"
              :class="google ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.google = !this.google"
            >
              Google Ads

              <img src="../assets/rightArrow.png" v-show="!google" />
              <img src="../assets/downArrow.png" v-show="google" />
            </div>
            <div :style="google ? '' : 'display:none'">
              <router-link
                to="/GoogleMarketingTable"
                active-class="active"
                style="
                  padding-left: 40px;
                  margin: 0 10px 0 0;
                  position: relative;
                "
              >
                投放記錄一覽表
              </router-link>
              <router-link
                to="/GoogleMarketingTrend"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
              >
                整體投放趨勢
              </router-link>
            </div>
          </div> -->
          <!-- <div class="ps-3 mb-3">F
            <div
              class="ms-6rem-custom ps-4"
              :class="line ? 'inside-highlight' : 'inside-nohighlight'"
              @click="this.line = !this.line"
            >
              Line
              <img src="../assets/rightArrow.png" v-show="!line" />
              <img src="../assets/downArrow.png" v-show="line" />
            </div>
            <div :style="line ? '' : 'display:none'">
              <router-link
                to="/LineMarketingTable"
                active-class="active"
                style="
                  padding-left: 40px;
                  margin: 0 10px 0 0;
                  position: relative;
                "
              >
                投放記錄一覽表
              </router-link>
              <router-link
                to="/LineMarketingTrend"
                active-class="active"
                style="padding-left: 40px; margin: 0 10px 0 0"
              >
                整體投放趨勢
              </router-link>
            </div>
          </div> -->
        </div>
      </li>
      <li v-if="this.leftshow.PlatformManage">
        <div
          :class="memberManage ? 'highlight' : 'nohighlight'"
          class="ps-3"
          @click="this.memberManage = !this.memberManage"
        >
          <img src="../assets/MemberManage.svg" v-show="memberManage" />
          <img src="../assets/lightMember.svg" v-show="!memberManage" />
          <span class="ms-6rem-custom"> 平台管理 </span>
          <img src="../assets/rightArrow.png" v-show="!memberManage" />
          <img src="../assets/downArrow.png" v-show="memberManage" />
        </div>
        <div class="ms-3" :class="memberManage ? 'show' : 'noshow'">
          <router-link
            to="/reportExport"
            active-class="active"
            v-if="this.leftshow.reportExport"
          >
            報表資料管理
          </router-link>
          <router-link
            to="/MemberUploadDel"
            :class="memberLimit ? 'active' : ''"
            active-class="active"
            v-if="this.leftshow.MemberUploadDel"
          >
            行銷名單管理
          </router-link>
          <router-link
            to="/MemberData"
            active-class="active"
            v-if="this.leftshow.MemberData"
          >
            行銷投放紀錄
          </router-link>
        </div>
      </li>
      <li style="margin-top: 8rem">
        <!-- <img  class="logo-power" src="../assets/leftnav-new.png" alt="logo"  />    -->

        <div class="power-logo">
          <img
            class="logo-width"
            src="../assets/leftnav-created.png"
            alt="logo"
          />
        </div>
      </li>
    </ul>
    <div class="power-logo"></div>
  </div>
</template>

<script>
export default {
  name: "LeftNav",
  data() {
    return {
      leftshow: {
        AllAdvEffect: true,
        AutomationScript: true,
        CommoditySales: true,
        CustomLabel: true,
        CustomerLabel: true,
        CustomerShopbehavior: true,
        EmailMarketingTable: true,
        EmailPerformance: true,
        FBperformance: true,
        FacebookAdsPackage: true,
        FbShortUrl: true,
        FbThemeAnal: true,
        FbTrendAnal: true,
        FbadsTable: true,
        FullCalendar: true,
        LabelManage: true,
        LatestResult: true,
        LineAdsPackage: true,
        LineShortUrl: true,
        MarketingPerformance: true,
        MarketingSchedule: true,
        MemberData: true,
        MemberUploadDel: true,
        MembershipSales: true,
        New360Page: true,
        OfflineBehavior: true,
        OperationalOverview: true,
        OperationalPerformance: true,
        PlatformManage: true,
        ProductSmsTemplate: true,
        ProductTextTemplate: true,
        RFMCustomPage: true,
        RFMpage: true,
        Rema: true,
        RemaEmail: true,
        RemaFB: true,
        RemaLine: true,
        RemaSMS: true,
        SMSperformance: true,
        ScriptTable: true,
        SmsLatestResult: true,
        SmsMarketingTable: true,
        SmsSchedule: true,
        StoreChannelSales: true,
        ValueGrouping: true,
        WebsiteAnalysis: true,
        WebsiteBehavior: true,
        clickForceTag: true,
        reportExport: true,
      },
      login: false,
      notshow: true,

      behavior: false,
      Website: false,
      New360: false,
      labelGroup: false,
      customerProfile: false,
      tagGroup: false,
      memberManage: false,
      auto: false,
      advEffect: false,
      retailGoods: false,

      email: false,
      fb: false,
      google: false,
      line: false,

      remaEmail: false,
      remaSms: false,
      remaGoogle: false,
      remaFacebook: false,
      remaLine: false,
      remaScript: false,

      highlight: false,
      highlightPerformanceSMS: false,
      highlightPerformanceEmail: false,
      highlightEmail: false,
      highlightSms: false,
      sms: false,

      memberLimit: false,
    };
  },
  watch: {
    $route: function () {
      this.login =
        this.$route.path === "/Login" ||
        this.$route.path === "/ForgotPassword" ||
        this.$route.path === "/ResetPassword";

      this.behavior =
        this.$route.path === "/OperationalOverview" ||
        this.$route.path === "/MembershipSales" ||
        this.$route.path === "/CommoditySales" ||
        this.$route.path === "/CombinatorialAnalysis" ||
        this.$route.path === "/StoreChannelSales";

      this.retailGoods =
        this.$route.path === "/CommoditySales" ||
        this.$route.path === "/CombinatorialAnalysis";

      this.Website =
        this.$route.path === "/WebsiteAnalysis" ||
        this.$route.path === "/clickForceTag";
      this.New360 = this.$route.path === "/New360Page";

      this.labelGroup =
        this.$route.path === "/ValueGrouping" ||
        this.$route.path === "/CustomLabel" ||
        this.$route.path === "/OfflineBehavior";

      this.customerProfile =
        this.$route.path === "/BasicInfo" ||
        this.$route.path === "/PurchaseRecord" ||
        this.$route.path === "/OnlineBehavior";

      this.tagGroup =
        this.$route.path === "/RFMpage" ||
        this.$route.path === "/RFMCustomPage" ||
        this.$route.path === "/ChannelMarketing" ||
        this.$route.path === "/LabelsReport" ||
        this.$route.path === "/LabelManage";

      this.memberManage =
        this.$route.path === "/MemberData" ||
        this.$route.path === "/MemberUploadDel" ||
        this.$route.path === "/reportExport" ||
        this.$route.path === "/MemberLimit";

      this.memberLimit = this.$route.path === "/MemberLimit";

      this.auto =
        this.$route.path === "/ProductTextTemplate" ||
        this.$route.path === "/MarketingSchedule" ||
        this.$route.path === "/MarketingScript" ||
        this.$route.path === "/TriggerScript" ||
        this.$route.path === "/ScriptTable" ||
        this.$route.path === "/SmsSchedule" ||
        this.$route.path === "/ProductSmsTemplate" ||
        this.$route.path === "/LineAdsPackage" ||
        this.$route.path === "/FacebookAdsPackage" ||
        this.$route.path === "/LineShortUrl" ||
        this.$route.path === "/FbShortUrl" ||
        this.$route.path === "/SmsSetting" ||
        this.$route.path === "/EmailSetting" ||
        this.$route.path === "/AfterTriggerScript";

      this.advEffect =
        this.$route.path.includes("AllAdvEffect") ||
        this.$route.path.includes("MarketingTable") ||
        this.$route.path.includes("ResultPerformance") ||
        this.$route.path.includes("LatestResult") ||
        this.$route.path.includes("EventPerformance") ||
        this.$route.path.includes("MarketingTrend") ||
        this.$route.path.includes("FbadsTable") ||
        this.$route.path.includes("FbTrendAnal") ||
        this.$route.path.includes("FbThemeAnal") ||
        this.$route.path.includes("FullCalendar");

      this.email =
        this.$route.path === "/EmailMarketingTable" ||
        this.$route.path === "/ResultPerformance" ||
        this.$route.path === "/EventPerformance" ||
        this.$route.path === "/LatestResult";

      this.sms =
        this.$route.path === "/SmsMarketingTable" ||
        this.$route.path === "/SmsLatestResult" ||
        this.$route.path === "/SmsResultPerformance" ||
        this.$route.path == "/SmsEventPerformance";

      this.highlightPerformanceSMS =
        this.$route.path == "/SmsResultPerformance" ||
        this.$route.path == "/SmsEventPerformance";
      this.highlightPerformanceEmail =
        this.$route.path === "/ResultPerformance" ||
        this.$route.path === "/EventPerformance";

      this.highlightEmail = this.$route.path === "/EmailSetting";
      this.highlightSms = this.$route.path === "/SmsSetting";

      this.fb =
        this.$route.path === "/FbMarketingTable" ||
        this.$route.path === "/FbMarketingTrend" ||
        this.$route.path === "/FbadsTable" ||
        this.$route.path === "/FbTrendAnal" ||
        this.$route.path === "/FbThemeAnal";

      this.google =
        this.$route.path === "/GoogleMarketingTable" ||
        this.$route.path === "/GoogleMarketingTrend";

      this.line =
        this.$route.path === "/LineMarketingTable" ||
        this.$route.path === "/LineMarketingTrend";

      this.remaEmail =
        this.$route.path === "/ProductTextTemplate" ||
        this.$route.path === "/MarketingSchedule" ||
        this.$route.path === "/EmailSetting";

      this.remaSms =
        this.$route.path === "/SmsSchedule" ||
        this.$route.path === "/ProductSmsTemplate" ||
        this.$route.path === "/SmsSetting";

      this.remaFacebook =
        this.$route.path === "/FacebookAdsPackage" ||
        this.$route.path === "/FbShortUrl";

      this.remaLine =
        this.$route.path === "/LineAdsPackage" ||
        this.$route.path === "/LineShortUrl";

      this.remaScript = this.$route.path === "/ScriptTable";
    },
    login: function () {
      let leftshow = JSON.parse(sessionStorage.getItem("leftshow"));
      if (!this.login) {
        if (leftshow === null) {
          return;
        }
        if (Object.prototype.toString.call(leftshow) === "[object Array]") {
          return;
        }
        this.leftshow = JSON.parse(sessionStorage.getItem("leftshow"));
      }
      console.log("this.leftshow", this.leftshow);
    },
  },
  created() {
    console.log("this.login", this.login);
    let leftshow = JSON.parse(sessionStorage.getItem("leftshow"));
    if (!this.login) {
      if (leftshow === null) {
        return;
      }
      if (Object.prototype.toString.call(leftshow) === "[object Array]") {
        return;
      }
      this.leftshow = JSON.parse(sessionStorage.getItem("leftshow"));
    }
    console.log(
      "this.leftshow",
      JSON.parse(sessionStorage.getItem("leftshow"))
    );
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ms-6rem-custom {
  margin-left: 0.6rem;
}
.ms-025rem-custom {
  margin-left: 0.15rem;
}
li {
  cursor: pointer;
}

img {
  position: relative;
  top: -2px;
}
.leftNav {
  position: relative;
  background-color: #eaf3f4;
  min-width: 215px;
  min-height: calc(89vh);
  padding-top: 30px;
}
.highlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #63a7c8;
}
.nohighlight {
  vertical-align: bottom;
  font-size: 18px;
  color: #8a8a8a;
}
.inside-highlight {
  /* background-color: #63a7c8; */
  color: #63a7c8;
  border-radius: 8px;
  font-size: 18px;
  padding-left: 35px;
  height: 40px;
  line-height: 40px;
}
.inside-nohighlight {
  color: #8a8a8a;
  font-size: 18px;
}
.show {
  display: block;
}
.noshow {
  display: none;
}
a {
  display: block;
  color: #8a8a8a;
  text-decoration: none;
  border-radius: 8px;
  margin: 10px;
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
}
.active {
  display: block;
  background-color: #63a7c8;
  color: white;
}
.inside-active {
  color: #71afb6;
}
.power-logo {
  /* vertical-align: bottom; */
  position: absolute;
  bottom: 10px;
  /* padding-top: 20rem; */
  /* width: 14rem; */
  margin-left: 1.5rem;
  /* margin-bottom: 1rem; */
}

.logo-power {
  position: inherit;
  margin-top: 20rem;
  margin-left: 1.5rem;
  /* bottom: -10; */
}
.logo-width {
  width: 100%;
}
.overflow-text {
  white-space: nowrap;
  /* text-overflow:inherit; */
}
.retailGoods {
  padding-left: 45px !important;
  margin-bottom: 10px;
  line-height: 40px;
}
</style>
