<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- main -->
    <div class="d-flex justify-content-between">
      <span class="title-span fs-medium">整體行銷成效</span>
      <div style="position: relative" ref="target">
        <div
          class="px-3 py-1 d-flex align-items-center"
          style="
            border: 1px solid #575757;
            width: 300px;
            border-radius: 10px;
            position: relative;
            height: 40px;
          "
          @click="autoShow = !autoShow"
        >
          <img
            src="../assets/date.svg"
            alt=""
            style="position: relative; top: 1px"
            class="me-4"
          />
          <span v-if="this.setTime == 'month'"
            >{{ this.start_yearByMonth }}年{{ this.start_monthByMonth }}月-{{
              this.end_yearByMonth
            }}年{{ this.end_monthByMonth }}月</span
          >
          <span v-if="this.setTime == 'year'"
            >{{ this.start_yearByYear }}年-{{ this.end_yearByYear }}年</span
          >
          <img
            src="../assets/selection-arrow.png"
            alt="selection-arrow"
            style="position: absolute; top: 16px; right: 20px"
          />
        </div>
        <div
          class="shadow-box"
          style="
            position: absolute;
            top: 45px;
            left: 0px;
            padding: 12px 20px;
            width: 100%;
            background-color: white;
            z-index: 99;
          "
          v-if="autoShow"
        >
          <!-- border: 1px solid #575757; -->
          <div
            class="d-flex align-items-center justify-content-between"
            style="color: #71afb6"
          >
            <span> 時間範圍 </span>
            <div
              class="btn-group"
              style="
                background-color: #eaf3f4;
                border-radius: 10px;
                width: 130px;
              "
            >
              <button
                class="basic-btn"
                :class="this.setTime == 'month' ? 'highlight' : ''"
                @click="this.setTime = 'month'"
              >
                月
              </button>
              <button
                class="basic-btn"
                :class="this.setTime == 'year' ? 'highlight' : ''"
                @click="this.setTime = 'year'"
              >
                年
              </button>
            </div>
          </div>
          <div v-if="this.setTime == 'month'">
            <div class="d-flex align-items-center mt-3">
              <span class="col-2">從</span>
              <div class="flex-fill">
                <Datepicker
                  v-model="monthData_start"
                  locale="tw"
                  autoApply
                  monthPicker
                  :format="format_start"
                  :clearable="false"
                  inputClassName="dp-custom-input"
                  :minDate="this.minDate"
                  :maxDate="this.maxDate_start"
                >
                  <template #input-icon>
                    <img
                      src="../assets/date.svg"
                      alt=""
                      style="position: relative; right: -10px; top: -3px"
                    />
                    <img
                      src="../assets/dropdownArrowBlack.svg"
                      alt=""
                      style="
                        position: absolute;
                        top: 11px;
                        right: -185px;
                        z-index: 50;
                      "
                    />
                  </template>
                </Datepicker>
              </div>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span class="col-2">至</span>
              <div class="flex-fill">
                <Datepicker
                  v-model="monthData_end"
                  locale="tw"
                  autoApply
                  monthPicker
                  :format="format_end"
                  :clearable="false"
                  inputClassName="dp-custom-input"
                  :minDate="this.minDate_end"
                  :maxDate="this.maxDate"
                >
                  <template #input-icon>
                    <img
                      src="../assets/date.svg"
                      alt=""
                      style="position: relative; right: -10px; top: -3px"
                    />
                    <img
                      src="../assets/dropdownArrowBlack.svg"
                      alt=""
                      style="
                        position: absolute;
                        top: 11px;
                        right: -185px;
                        z-index: 50;
                      "
                    />
                  </template>
                </Datepicker>
              </div>
            </div>
          </div>
          <div v-if="this.setTime == 'year'">
            <div class="d-flex align-items-center mt-3">
              <span class="col-2">從</span>
              <div class="flex-fill">
                <Datepicker
                  v-model="yearData_start"
                  locale="tw"
                  autoApply
                  yearPicker
                  :clearable="false"
                  inputClassName="dp-custom-input"
                  :minDate="this.minDateYear"
                  :maxDate="this.maxDateYear_start"
                >
                  <template #input-icon>
                    <img
                      src="../assets/date.svg"
                      alt=""
                      style="position: relative; right: -10px; top: -3px"
                    />
                    <img
                      src="../assets/dropdownArrowBlack.svg"
                      alt=""
                      style="
                        position: absolute;
                        top: 11px;
                        right: -185px;
                        z-index: 50;
                      "
                    />
                  </template>
                </Datepicker>
              </div>
            </div>
            <div class="d-flex align-items-center mt-3">
              <span class="col-2">至</span>
              <div class="flex-fill">
                <Datepicker
                  v-model="yearData_end"
                  locale="tw"
                  autoApply
                  yearPicker
                  :clearable="false"
                  inputClassName="dp-custom-input"
                  :minDate="this.minDateYear_end"
                  :maxDate="this.maxDateYear"
                >
                  <template #input-icon>
                    <img
                      src="../assets/date.svg"
                      alt=""
                      style="position: relative; right: -10px; top: -3px"
                    />
                    <img
                      src="../assets/dropdownArrowBlack.svg"
                      alt=""
                      style="
                        position: absolute;
                        top: 11px;
                        right: -185px;
                        z-index: 50;
                      "
                    />
                  </template>
                </Datepicker>
              </div>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <button
              class="basic-btn linear"
              style="width: 215px"
              @click="setupTime()"
            >
              確認
            </button>
          </div>
        </div>
      <p
            class="ms-2"
            style="
              position: absolute;
              color: #c4c4c4;
              font-size: 14px;
              right: 5px;
            "
          >
            {{ schedule_end_date }} 為最近一筆投放資料
          </p>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div style="width: 49%">
          <div class="bar-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6"
              >各渠道投放次數與比例</span
            >
            <span
              class="text-center"
              style="position: absolute; top: 29%; left: 34%; color: #c4c4c4"
              v-if="this.pieSeries.every((d) => d == 0)"
            >
              <img src="../assets/missData.svg" alt="" style="width: 160px" />
              <br />
              此時間段無資料</span
            >
            <div>
              <apexchart
                type="pie"
                height="230"
                :options="pieOptions"
                :series="pieSeries"
              ></apexchart>
            </div>
          </div>
        </div>
        <div style="width: 49%">
          <div class="bar-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6"
              >各渠道互動評級之比例
              <button class="btn btn-default button px-0 mx-0 pt-0 btn-info-pop"
                    @mouseover="isShow2 = true"
                    @mouseout="isShow2 = false">
                <!-- <span>
                  <img
                    src="../assets/question-img.svg"
                    style="
                      width: 20px;
                      position: relative;
                      top: -2px;
                      z-index: 20;
                    "
                    @mouseover="isShow2 = true"
                    @mouseout="isShow2 = false"
                  />
                </span> -->
                <div class="parent-popout">
                  <div
                    style="
                      width: 20px;
                      height: 40px;
                      position: absolute;
                      top: -17px;
                      left: 5px;
                      z-index: 5;
                    "
                    @mouseover="isShow2 = true"
                    @mouseout="isShow2 = false"
                  ></div>
                  <div
                    v-show="isShow2"
                    class="child-popout-inAllAdvEffect-first font-12"
                    @mouseover="isShow2 = true"
                    @mouseout="isShow2 = false"
                  >
                    會員溝通評分機制，智能計算各渠道的回應溝通表現，您亦可利用此分群進行再行銷；詳情計算方式請見
                    <a
                      :style="colorChange ? 'color:#395185' : 'color:white'"
                      @mouseover="colorChange = true"
                      @mouseleave="colorChange = false"
                      href="https://docs.google.com/spreadsheets/d/1TZjijoFktTBlD8m2nMXtCOEZD7QrQlzpLjnD5I0vLU4/edit?usp=sharing"
                      target="_blank"
                    >
                      標籤一覽表</a
                    >
                    。
                    <div class="container__arrow"></div>
                  </div>
                </div>
              </button>
            </span>
            <span
              class="text-center"
              style="position: absolute; top: 43%; left: 44%; color: #c4c4c4"
              v-if="
                this.stackBarSeries.every(
                  (i) => i.data.every((d) => d == 0) == true
                )
              "
            >
              此時間段無資料</span
            >
            <div>
              <apexchart
                type="bar"
                height="230"
                :options="stackBarOptions"
                :series="stackBarSeries"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div style="width: 49%">
          <div class="bar-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6">各渠道點擊率</span>
            <div
              class="d-flex justify-content-center"
              style="position: absolute; top: 118px; left: 44%; color: #c4c4c4"
              v-if="this.barSeries[0].data.every((d) => d == 0)"
            >
              此時間段無資料
            </div>
            <div>
              <apexchart
                type="bar"
                height="230"
                :options="barOptions"
                :series="barSeries"
              ></apexchart>
            </div>
          </div>
        </div>
        <div style="width: 49%">
          <div class="bar-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6">各渠道轉換率</span>
            <div
              class="d-flex justify-content-center"
              style="position: absolute; top: 118px; left: 44%; color: #c4c4c4"
              v-if="this.barSeries2[0].data.every((d) => d == 0)"
            >
              此時間段無資料
            </div>
            <div>
              <apexchart
                type="bar"
                height="230"
                :options="barOptions2"
                :series="barSeries2"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div style="width: 49%">
          <div class="pie-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6"
              >各渠道每次點擊成本</span
            >
            <span
              class="text-center"
              style="position: absolute; top: 118px; left: 44%; color: #c4c4c4"
              v-if="this.barSeries3[0].data.every((d) => d == 0)"
            >
              此時間段無資料</span
            >
            <apexchart
              type="bar"
              height="230"
              :options="barOptions3"
              :series="barSeries3"
            ></apexchart>
          </div>
        </div>
        <div style="width: 49%">
          <div class="bar-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6"
              >各渠道每次轉換成本</span
            >
            <span
              class="text-center"
              style="position: absolute; top: 118px; left: 44%; color: #c4c4c4"
              v-if="this.barSeries4[0].data.every((d) => d == 0)"
            >
              此時間段無資料</span
            >
            <apexchart
              type="bar"
              height="230"
              :options="barOptions4"
              :series="barSeries4"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div style="width: 49%">
          <div class="pie-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6">各渠道花費金額</span>
            <span
              class="text-center"
              style="position: absolute; top: 25%; left: 34%; color: #c4c4c4"
              v-if="this.pieSeries2.every((d) => d == 0)"
            >
              <img src="../assets/missData.svg" alt="" style="width: 160px" />
              <br />
              此時間段無資料</span
            >
            <apexchart
              type="donut"
              height="230"
              :options="pieOptions2"
              :series="pieSeries2"
            ></apexchart>
          </div>
        </div>
        <div style="width: 49%">
          <div class="bar-div me-0" style="position: relative">
            <span class="fs-small" style="color: #71afb6">各渠道名單數量</span>
            <span
              class="text-center"
              style="position: absolute; top: 25%; left: 34%; color: #c4c4c4"
              v-if="this.barSeries5[0].data.every((d) => d == 0)"
            >
              <img src="../assets/missData.svg" alt="" style="width: 160px" />
              <br />
              此時間段無資料</span
            >
            <apexchart
              type="bar"
              height="230"
              :options="barOptions5"
              :series="barSeries5"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";
export default {
  name: "AllAdvEffect",
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const monthData_start = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });
    const monthData_end = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });

    const format_start = (monthData_start) => {
      const month = monthData_start.month + 1;
      const year = monthData_start.year;

      return `${year}/${month}`;
    };
    const format_end = (monthData_end) => {
      const month = monthData_end.month + 1;
      const year = monthData_end.year;

      return `${year}/${month}`;
    };

    const yearData_start = ref(new Date().getFullYear());
    const yearData_end = ref(new Date().getFullYear());

    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return {
      monthData_start,
      monthData_end,
      format_start,
      format_end,
      yearData_start,
      yearData_end,
      target,
      autoShow,
    };
  },
  data() {
    return {
      setTime: "month",
      start_yearByMonth: new Date().getFullYear(),
      end_yearByMonth: new Date().getFullYear(),
      start_monthByMonth: new Date().getMonth() + 1,
      end_monthByMonth: new Date().getMonth() + 1,
      start_yearByYear: new Date().getFullYear(),
      end_yearByYear: new Date().getFullYear(),

      notshow: false,
      loading: true,
      isShow2: false,
      colorChange: false,

      endYear: 2022,
      endMonth: 6,
      minDate: "",
      maxDate_start: "",
      minDate_end: "",
      maxDate: "",

      minDateYear: "",
      maxDateYear_start: "",
      maxDateYear: "",
      minDateYear_end: "",

      pieSeries: [],
      pieOptions: {},
      pieSeries2: [],
      pieOptions2: {},
      barSeries: [{ data: [] }],
      barOptions: {},
      barSeries2: [{ data: [] }],
      barOptions2: {},
      barSeries3: [{ data: [] }],
      barOptions3: {},
      barSeries4: [{ data: [] }],
      barOptions4: {},
      barSeries5: [{ data: [] }],
      barOptions5: {},
      stackBarSeries: [{ data: [] }],
      stackBarOptions: {},

      apiPie: {
        標題: [],
        比例: [],
      },
      apiPie2: {
        標題: [],
        比例: [],
      },
      apiBar1: {
        標題: [],
        比例: [],
      },
      apiBar2: {
        標題: [],
        比例: [],
      },
      apiBar3: {
        標題: [],
        比例: [],
      },
      apiBar4: {
        標題: [],
        比例: [],
      },
      apiBar5: {
        標題: [],
        比例: [],
      },
      apistackBar: {},

      sms_start_date:'',
      email_start_date:'',
      sms_end_date:'',
      email_end_date:'',
      sms_performance_end_date:'',
      email_performance_end_date:'',
      schedule_end_date:'',
      performance_end_date:'',
    };
  },
  watch: {
    monthData_start: function (val) {
      console.log(val);
      this.minDate_end =
        this.monthData_start.year +
        "-" +
        (this.monthData_start.month + 1) +
        "-01";
      console.log("this.minDate_end", this.minDate_end);
    },
    monthData_end: function (val) {
      console.log(val);
      let month_end = "";
      if (this.monthData_end.month + 1 > 9) {
        month_end = this.monthData_end.month + 1;
      } else {
        month_end = "0" + (this.monthData_end.month + 1);
      }

      this.maxDate_start = moment(`${this.monthData_end.year}-${month_end}`)
        .endOf("month")
        .toISOString()
        .slice(0, 10);
    },
    yearData_start: function (val) {
      console.log(val);
      this.minDateYear_end = this.yearData_start + "";
      console.log("this.yearData_start", this.yearData_start);
    },
    yearData_end: function (val) {
      console.log("this.yearData_end", val);
      this.maxDateYear_start = this.yearData_end + "-12-31";
    },
  },
  created: async function () {
    // http://localhost:8080 test
    this.loading = true;

    await this.getEmailTime();
    await this.getSmsTime();
    if (this.sms_end_date > this.email_end_date) {
      this.setMinMax_Date(this.sms_start_date, this.sms_end_date);
      this.schedule_end_date=this.sms_end_date
      this.performance_end_date=this.sms_performance_end_date
    } else {
      this.setMinMax_Date(this.email_start_date, this.email_end_date);
      this.schedule_end_date=this.email_end_date
      this.performance_end_date=this.email_performance_end_date
    }

    this.setupTime();

    this.loading = false;
  },
  methods: {
    setupTime() {
      if (this.setTime == "month") {
        this.start_yearByMonth = this.monthData_start.year;
        this.end_yearByMonth = this.monthData_end.year;
        this.start_monthByMonth = this.monthData_start.month + 1;
        this.end_monthByMonth = this.monthData_end.month + 1;
      } else {
        this.start_yearByYear = this.yearData_start;
        this.end_yearByYear = this.yearData_end;
      }
      this.getOverallBar();
      this.autoShow = false;
    },
    setMinMax_Date(start_date, end_date) {
      console.log(start_date)
      this.minDate = start_date.slice(0, -2) + "01";
      this.maxDate_start = moment(end_date)
        .endOf("month")
        .toISOString()
        .slice(0, 10);
      this.minDate_end = start_date;
      this.maxDate = moment(end_date).endOf("month").toISOString().slice(0, 10);

      this.minDateYear = start_date;
      this.maxDateYear_start = moment(end_date)
        .endOf("year")
        .toISOString()
        .slice(0, 10);
      this.minDateYear_end = start_date;
      this.maxDateYear = moment(end_date)
        .endOf("year")
        .toISOString()
        .slice(0, 10);

      this.monthData_start = {
        month: end_date.slice(5, 7) - 1,
        year: end_date.slice(0, 4),
      };
      this.monthData_end = {
        month: end_date.slice(5, 7) - 1,
        year: end_date.slice(0, 4),
      };
      this.yearData_start = end_date.slice(0, 4);
      this.yearData_end = end_date.slice(0, 4);
    },
    getEmailTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {};
      await allapi3
        .emailOverallPeriodGet(params, body)
        .then((res) => {
          console.log("email time", res);
          this.email_start_date = res.data.Data.schedule.start_date;
          this.email_end_date = res.data.Data.schedule.end_date;
          this.email_performance_end_date= res.data.Data.performance.end_date
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getSmsTime: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .smsOverallPeriodGet(params)
        .then((res) => {
          console.log("smstime", res);
          this.sms_start_date = res.data.schedule.start_date;
          this.sms_end_date = res.data.schedule.end_date;
          this.sms_performance_end_date = res.data.performance.end_date
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getOverallBar: async function () {
      this.loading = true;
      console.log("this.monthData_start", this.monthData_start);
      let month_start = "";
      // let month_end = "";

      let start = "";
      let end = "";
      console.log(
        "this.monthData_start.month + 1",
        this.monthData_start.month + 1
      );

      if (this.setTime == "month") {
        if (this.monthData_start.month + 1 > 9) {
          month_start = this.monthData_start.month + 1;
        } else {
          month_start = "0" + (this.monthData_start.month + 1);
        }
        // if (this.monthData_end.month + 1 > 9) {
        //   month_end = this.monthData_end.month + 1;
        // } else {
        //   month_end = "0" + (this.monthData_end.month + 1);
        // }
        start = `${this.monthData_start.year}-${month_start}-01`;
        end = this.performance_end_date
      } else {
        start = this.yearData_start + "-01-01";
        end = this.performance_end_date;
      }

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        start_date: start,
        end_date: end,
        time: "month",
      };
      await allapi3
        .overallBarPost(params, body)
        .then((res) => {
          console.log("overallbar", res);
          const keys = ["中", "低", "無", "高"];
          for (let index = 0; index < keys.length; index++) {
            res.data.Data.各渠道互動評級之比例[keys[index]].forEach(
              (item, index, arr) => {
                arr[index] = (item * 100).toFixed(2);
              }
            );
          }
          res.data.Data.各渠道點擊率.比例 = res.data.Data.各渠道點擊率.比例.map(
            (d) => {
              return d * 100;
            }
          );
          res.data.Data.各渠道轉換率.比例 = res.data.Data.各渠道轉換率.比例.map(
            (d) => {
              return d * 100;
            }
          );

          this.apiPie = res.data.Data.各渠道投放次數與比例;
          this.apiPie2 = res.data.Data.各渠道花費金額;
          this.apiBar1 = res.data.Data.各渠道點擊率;
          this.apiBar2 = res.data.Data.各渠道轉換率;
          this.apiBar3 = res.data.Data.各渠道每次點擊成本;
          this.apiBar4 = res.data.Data.各渠道每次轉換成本;

          this.apistackBar = res.data.Data.各渠道互動評級之比例;
          this.apiBar5 = res.data.Data.各渠道名單數量;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);

          this.loading = false;
        });
      this.pieChart();
      this.pieChart2();
      this.barChart();
      this.barChart2();
      this.barChart3();
      this.barChart4();
      this.barChart5();
      this.stackBarChart();
    },

    pieChart() {
      (this.pieSeries = this.apiPie.比例),
        (this.pieOptions = {
          chart: {
            height: 230,
            type: "donut",
          },
          colors: ["#FFA09D", "#FFCF46", "#96C5D7", "#FC9F66"],
          labels: this.apiPie.標題,
          legend: {
            position: "bottom",
          },
        });
    },
    pieChart2() {
      (this.pieSeries2 = this.apiPie2.比例),
        (this.pieOptions2 = {
          chart: {
            height: 230,
            type: "donut",
          },
          colors: ["#FFA09D", "#FFCF46", "#96C5D7", "#FC9F66"],
          labels: this.apiPie.標題,
          legend: {
            position: "bottom",
          },
          tooltip: {
            y: {
              formatter: (y) => {
                return y.toFixed(2);
              },
            },
          },
        });
    },
    barChart() {
      this.barSeries = [
        {
          name: "各渠道點擊率",
          data: this.apiBar1.比例,
        },
      ];
      this.barOptions = {
        chart: {
          type: "bar",
          height: 210,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.apiBar1.標題,
          title: {
            text: "點擊率(%)",
          },
          labels: {
            formatter: function (x) {
              return x.toFixed(2) + "%";
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return y.toFixed(2) + "%";
            },
          },
        },
      };
    },
    barChart2() {
      let barChart2_title = [];
      if (this.apiBar2.比例.length == 2) {
        barChart2_title = ["Email", "SMS"];
      } else {
        barChart2_title = ["Email", "SMS", "facebook"];
      }
      this.barSeries2 = [
        {
          name: "各渠道轉換率",
          data: this.apiBar2.比例,
        },
      ];
      this.barOptions2 = {
        chart: {
          type: "bar",
          height: 210,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: barChart2_title,
          title: {
            text: "轉換率(%)",
          },
          labels: {
            formatter: function (x) {
              return x.toFixed(2) + "%";
            },
          },
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return y.toFixed(2) + "%";
            },
          },
        },
      };
    },
    barChart3() {
      this.barSeries3 = [
        {
          name: "各渠道每次點擊成本",
          data: this.apiBar3.比例,
        },
      ];
      this.barOptions3 = {
        chart: {
          type: "bar",
          height: 210,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.apiBar3.標題,
          title: {
            text: "金額(NT$)",
          },
          labels: {
            formatter: function (x) {
              return x.toLocaleString();
            },
          },
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return y.toFixed(2) + " NT$";
            },
          },
        },
      };
    },
    barChart4() {
      this.barSeries4 = [
        {
          name: "各渠道每次轉換成本",
          data: this.apiBar4.比例,
        },
      ];
      this.barOptions4 = {
        chart: {
          type: "bar",
          height: 210,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.apiBar4.標題,
          title: {
            text: "金額(NT$)",
          },
          labels: {
            formatter: function (x) {
              return x.toLocaleString();
            },
          },
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return y.toFixed(2) + " NT$";
            },
          },
        },
      };
    },
    stackBarChart() {
      (this.stackBarSeries = [
        {
          name: "高",
          data: this.apistackBar.高,
        },
        {
          name: "中",
          data: this.apistackBar.中,
        },
        {
          name: "低",
          data: this.apistackBar.低,
        },
        {
          name: "無",
          data: this.apistackBar.無,
        },
      ]),
        (this.stackBarOptions = {
          chart: {
            type: "bar",
            height: 200,
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
            zoom: {
              enabled: true,
            },
          },
          colors: ["#96C5D7", "#FC9F66", "#F9C357", "#ABABAB"],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {
            // type: "datetime",
            categories: this.apistackBar.標題,
          },
          yaxis: {
            title: {
              text: "比例(%)",
              style: {
                cssClass: "apexchart-label",
              },
            },
            max: 100,
            tickAmount: 5,
          },
          legend: {
            position: "bottom",
            offsetY: 3,
            offsetX: -10,
          },
          fill: {
            opacity: 1,
          },
        });
    },
    barChart5() {
      this.barSeries5 = [
        {
          name: "各渠道名單數量",
          data: this.apiBar5.比例,
        },
      ];
      this.barOptions5 = {
        chart: {
          type: "bar",
          height: 210,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#FFA09D", "#FFCF46", "#96C5D7", "#FC9F66"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.apiBar5.標題,
        },
        yaxis: {
          title: {
            text: "名單數(筆)",
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return value;
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
/* AllAdvEffect */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding: 50px;
}

.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
  height: 265px;
}

.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
  height: 265px;
}
.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.highlight {
  background-color: #71afb6;
  color: white;
}
.linear {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.child-popout-inAllAdvEffect-first {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 220px;
  right: -230px;
  top: -48px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  cursor: default;
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>
