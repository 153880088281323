<template>
  <div class="col-3">
    <button
      class="btn btn-default button col-11 col-xxl-11 px-0 mx-0"
      v-on:click="emitToggleEvent(todo)"
      v-on:mouseleave="mouseLeave"
      :class="{ highlight: todo.label_id === selectedID }"
      v-tooltip.bottom="
        todo.label.length > 7
          ? {
              value: todo.label,
              class: 'tip-LabelManage',
            }
          : {
              value: '',
              class: '',
            }
      "
    >
      <div class="fs-large todo-span">{{ todo.label }}</div>
      <button class="del-btn" v-on:click.stop="show()">
        <img
          src="../../assets/white3spot.svg"
          v-if="todo.label_id === selectedID"
        />
        <img src="../../assets/spot.svg" v-else />
      </button>
      <div class="popout" style="width: 100px" v-show="isShow">
        <p class="p-hover-up mb-0 p-1 pb-0" v-on:click.stop="copy(todo)">
          <img src="../../assets/copy.svg" class="mx-2 me-3" />複製
        </p>
        <p class="p-hover-bot mb-0 p-1 pt-0" v-on:click.stop="removeTodo(todo)">
          <img
            src="../../assets/delete-black.svg"
            style="width: 12px; position: relative; top: -1px"
            class="mx-2 me-3"
          />刪除
        </p>
      </div>
    </button>
  </div>
</template>


<script>
import { allapi2 } from "../../../public/static2/api/apigClient.js";

export default {
  name: "FavoriteNameList",
  props: ["todo", "selectedID"],
  data() {
    return {
      isEditing: false,
      isShow: false,
    };
  },
  methods: {
    activateInEditMode() {
      this.isEditing = true;
    },
    deActivateInEditMode() {
      this.isEditing = false;
    },
    mouseLeave() {
      if (this.isShow) {
        this.isShow = !this.isShow;
      }
    },
    show() {
      this.isShow = !this.isShow;
    },
    copy(todo) {
      this.$emit("copy-todo", todo);
    },
    removeTodo(todo) {
      this.$emit("remove-todos", todo);
    },
    emitToggleEvent(todo) {
      this.$emit("toggle-value", todo);
    },
  },
};
</script>

<style scoped>
.todo-span {
  width: 85%;
  padding-right: 30px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fs-large {
  font-size: 18px;
}
.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.container-fluid {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 30px;
}
.section-title {
  color: #71afb6;
}
.form-control {
  border: none;
  background-color: #eaf3f4;
}
.items {
  background-color: #eaf3f4;
}
.button-color {
  background-color: #eaf3f4;
}
.favorite-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.button {
  /* width: 280px; */
  height: 40px;
  margin: 12.5px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
  position: relative;
}
.del-btn {
  background-color: inherit;
  background: transparent;
  border: none;
  height: 35px;
  position: absolute;
  right: 10px;
  color: #c4c4c4;
}
.del-btn img {
  position: relative;
  top: -6px;
}
.popout {
  background-color: #fff;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  right: -60px;
  top: 30px;
  color: #575757;
}
.p-hover-up:hover {
  border-radius: 10px 10px 0 0;
  background-color: #eaf3f4;
  color: #575757;
}
.p-hover-bot:hover {
  border-radius: 0 0 10px 10px;
  background-color: #eaf3f4;
  color: #575757;
}
</style>