<template>
  <div v-if="!notshow">測試帳號尚未開放</div>
  <div class="container-fluid home" v-else>
    <!-- alert修改成功Modal -->
    <div
      class="modal fade"
      id="renameSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">成功修改模板名稱</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert刪除成功Modal -->
    <div
      class="modal fade"
      id="deleteSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert複製成功Modal -->
    <div
      class="modal fade"
      id="copySuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">複製成功</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert建立模板名稱Modal -->
    <div
      class="modal fade"
      id="alertModal5"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>請為此Email模板建立名稱</p>
            <div>
              <input
                v-bind:class="{ 'is-invalid': eventNameError }"
                class="ps-2"
                style="
                  border-radius: 5px;
                  height: 30px;
                  border: 0.5px solid black;
                "
                ref="eventName"
                placeholder=""
                v-model="eventName"
              />
              <div class="invalid-feedback">
                {{ eventNameErrMsg }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close-cancel me-1"
              style="width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-1"
              style="width: 125px"
              @click="goHref(this.eventName, 'undefined')"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert重新命名模板名稱Modal -->
    <div
      class="modal fade"
      id="renameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>Email模板重新命名</p>
            <div>
              <input
                v-bind:class="{ 'is-invalid': newTempNameError }"
                style="
                  border-radius: 5px;
                  height: 30px;
                  border: 0.5px solid black;
                "
                ref="eventName"
                placeholder=""
                v-model="newTempName"
              />
              <div class="invalid-feedback">
                {{ newTempNameErrMsg }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close-cancel me-1"
              style="width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-1"
              style="width: 125px"
              @click="confirmrename()"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="title-span " style="padding-left: 13px;">
      Email 模板
      <!-- <button @click="test">test</button> -->
    
    </div>
    <div class="container-fluid">
      <div class="p-4  form-container2" style="margin-top: 35px;">
        <ProductList
          v-bind:todos="todos"
          ref="RefChilde"
          @toggle-value="selectValue"
          @copy-todo="copytodo"
          @showrename-todo="renametodo"
          @showDeleteSuccess="showDeleteSuccess"
          @refresh-todos="refreshtodo"
          v-on:changeLoading="changeLoading"
          v-bind:selectedID="selectedID"
          v-on:search="search"
        />
      </div>
    </div>

    <div class="container-fluid">
      <div class="p-4 mt-5 form-container2">
        <div class="row">
          <div class="col-6 p-4">
            <div class="d-flex justify-content-center">
              <img src="../assets/drag-icon.svg" class="text-center" alt="" />
            </div>
            <div class="text-center">
              <p>拖曳式編輯器</p>
            </div>
            <div class="text-center">
              <p style="color: #c4c4c4">
                使用排版或自己的內容，輕鬆建立響應式EMAIL。
              </p>
            </div>
          </div>
          <div class="col-6 p-4 d-flex flex-column justify-content-end">
            <div>
              <button
                type="button"
                class="btn add-template-button-color"
                style="
                height:40px;
                  width: 100%;
                  border-radius: 10px;
                  background-color: #eaf3f4;
                "
                data-bs-toggle="modal"
                data-bs-target="#alertModal5"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/greenPlus.svg" class="pe-3" />
                  <span style="color: #71afb6">建立新的Email模板</span>
                </div>
              </button>
            </div>
            <div :style="this.selectedID === '' ? 'cursor:not-allowed' : ''">
              <button
                type="button"
                class="btn button-color mt-4"
                style="width: 100%; border-radius: 10px"
                :class="this.selectedID === '' ? 'disabled' : ''"
                :aria-disabled="this.selectedID === '' ? 'true' : 'false'"
                @click="
                  goHref(this.childtodo.name, this.childtodo.key, this.group)
                "
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/rename-white.svg" class="pe-3" />
                  <span> 編輯 </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import ProductList from "../components/ProductTextTemplate/ProductList.vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";

// import InputMoneySection from "./InputMoneySection.vue";

export default {
  name: "ProductInputForm",
  components: {
    ProductList,
  },
  data() {
    return {
      random_id: "",
      notshow: true,
      loading: false,
      selectedID: "",
      i: 1,
      groupId: "",
      group: "",
      number: "",

      eventName: "",
      eventNameError: false,
      eventNameErrMsg: "",
      newTempName: "",
      newTempNameError: false,
      newTempNameErrMsg: "",

      todos: [],
      searchTodos: [],
      childtodo: [],
      editinput: false,
    };
  },
  watch: {
    eventName: function () {
      var isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      if (this.eventName === "") {
        this.eventNameError = true;
        this.eventNameErrMsg = "名稱不能是空的";
      } else if (isText.test(this.eventName)) {
        this.eventNameError = true;
        this.eventNameErrMsg = "名稱不能含有是有特殊字元";
      } else if (
        this.searchTodos.findIndex((d) => d.name === this.eventName) != "-1"
      ) {
        this.eventNameError = true;
        this.eventNameErrMsg = "您要使用的名稱已有其他模板名單使用";
      } else {
        this.eventNameError = false;
      }
      // sessionStorage.setItem("名稱", this.newTodoTitle);
    },
    newTempName: function () {
      var isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      if (this.newTempName === "") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "名稱不能是空的";
      } else if (isText.test(this.newTempName)) {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "名稱不能含有是有特殊字元";
      } else if (
        this.searchTodos.findIndex(
          (d) => d.name === this.newTempName && d.key != this.childtodo.key
        ) != "-1"
      ) {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "您要使用的名稱已有其他模板名單使用";
      } else {
        this.newTempNameError = false;
      }
      // sessionStorage.setItem("名稱", this.newTodoTitle);
    },
  },
  created: async function () {
    this.random_id = "T_" + this.randomString(8);

    // console.log(this.$store);
    this.loading = true;
    this.group = sessionStorage.getItem("getlabelTitle");
    this.groupId = sessionStorage.getItem("getlabelId");
    this.number = sessionStorage.getItem("getlabelNumber");
    // this.group = this.$store.state.rfm.title;
    // this.groupId = this.$store.state.rfm.id;
    // this.number = this.$store.state.rfm.number;
    await this.get();
    this.loading = false;

    this.todos.map((d) => {
      if (d.template_id === this.random_id) {
        this.random_id = "T_" + this.randomString(8);
      }
    });
  },
  // beforeRouteLeave: function (to, from) {
  //   if (
  //     to.path === "/ProductTextTemplate" ||
  //     to.path === "/MarketingSchedule"
  //   ) {
  //     return;
  //   } else {
  //     console.log("remove texttemplate storge");

  //     sessionStorage.removeItem("haveBtemplateFromSche");
  //     sessionStorage.removeItem("haveABtestFromSche");
  //     sessionStorage.removeItem("ABtestPercentFromSche");
  //     sessionStorage.removeItem("AtemplateFromSche");
  //     sessionStorage.removeItem("BtemplateFromSche");
  //   }
  // },
  methods: {
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    showDeleteSuccess() {
      const deleteSuccessModal = new bootstrap.Modal(
        document.getElementById("deleteSuccessModal"),
        {
          keyboard: false,
        }
      );
      deleteSuccessModal.show();
    },
    
    search(val) {
      const searched = this.searchTodos.filter((d) => d.name.includes(val));
      this.todos = searched;
    },
    changeLoading() {
      this.loading = !this.loading;
    },
    refreshtodo: async function () {
      await this.get();
      // await this.selectValue(this.todos[0]);
    },
    renametodo: async function (todo) {
      await this.getTemplateById(todo);
      this.childtodo = todo;
      console.log(this.childtodo);
      this.newTempName = todo.name;

      const renameModal = new bootstrap.Modal(
        document.getElementById("renameModal"),
        {
          keyboard: false,
        }
      );
      renameModal.show();
    },
    confirmrename: async function () {
      if (this.todos.findIndex((d) => d.name === this.newTempName) != "-1") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "您要使用的模板名稱已有其他模板使用";
        const renameModal = new bootstrap.Modal(
          document.getElementById("renameModal"),
          {
            keyboard: false,
          }
        );
        renameModal.show();
      } else if (this.newTempName === "") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "必須輸入模板名稱";
        const renameModal = new bootstrap.Modal(
          document.getElementById("renameModal"),
          {
            keyboard: false,
          }
        );
        renameModal.show();
      } else {
        await this.patchTemplateApi(this.childtodo);
        this.selectValue(this.searchTodos[0]);
        this.newTempName = "";
      }
    },
    patchTemplateApi: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
        name: this.newTempName,
        json: this.todojson,
        html: this.todohtml,
      };

      await allapi2
        .templatev2TempInfoPatch(params, body)
        .then((res) => {
          console.log(res);
          const renameSuccessModal = new bootstrap.Modal(
            document.getElementById("renameSuccessModal"),
            {
              keyboard: false,
            }
          );
          renameSuccessModal.show();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
      await this.get();
    },
    getTemplateById: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: todo.key,
      };

      await allapi2
        .templatev2TempKeyGet(params)
        .then((res) => {
          console.log(res);
          this.todojson = res.data.json;
          this.todohtml = res.data.html;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    copytodo: async function (todo) {
      this.loading = true;
      await this.getTemplateById(todo);
      console.log(todo);
      this.childtodo = todo;
      while (
        this.todos.findIndex(
          (d) => d.name === this.childtodo.name + "(" + this.i + ")"
        ) != "-1"
      ) {
        this.i++;
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.childtodo.name + "(" + this.i + ")",
        json: this.todojson,
        html: this.todohtml,
      };

      await allapi2
        .templatev2TempInfoPost(params, body)
        .then((res) => {
          console.log("posttemplate", res);
          const copySuccessModal = new bootstrap.Modal(
            document.getElementById("copySuccessModal"),
            {
              keyboard: false,
            }
          );
          copySuccessModal.show();
        })
        .catch((err) => {
          alert("複製失敗");
          console.log("連線有誤");
          console.log(err);
        });
      await this.get();
      await this.selectValue(this.todos[0]);
      this.$refs.RefChilde.changePage();
    },
    selectValue(todo) {
      console.log(todo);
      this.childtodo = todo;
      this.selectedID = todo.key;
    },
    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .templatev2TempInfoGet(params)
        .then((res) => {
          console.log("GET", res);
          this.loading = false;
          this.todos = res.data;

          this.todos.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });
          this.searchTodos = this.todos;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    test() {
      this.$refs.RefChilde.changePage();
    },
    goHref(name, key, group) {
      if (this.eventNameError) {
        return;
      } else {
        sessionStorage.setItem("template_name", name);
        sessionStorage.setItem("template_key", key);
        const from = "template";
        const group = sessionStorage.getItem("getlabelTitle");
        const token = sessionStorage.getItem("access_token");
        console.log(name);
        console.log(key);
        console.log("群", group);
        console.log(token);
        if (window.location.origin === "http://localhost:8080") {
          window.location.href = `http://localhost:8081/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          window.location.href = `https://firstcdp-test2.retailing-data.net/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        } else if (
          window.location.origin === "https://firstcdp-dev.retailing-data.net"
        ) {
          window.location.href = `https://firstcdp-dev2.retailing-data.net/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        } else {
          window.location.href = `https://firstcdp2.retailing-data.net/?name=${name}&key=${key}&token=${token}&group=${group}&from=${from}`;
        }
        // &group=${group}
      }
    },
  },
};
</script>

<style scoped>
.fs-large {
  font-size: 22px;
}
.fs-medium {
  font-size: 18px;
}
.fs-small {
  font-size: 16px;
}
.home {
  /* padding: 50px; */
  padding-left: 63px;
   padding-top: 53px;
}
.modal {
  z-index: 2000;
}
.banner-span {
  margin-right: 42px;
}
.file-span {
  display: inline-block;
  background-color: #eaf3f4;
  margin-bottom: 15px;
  line-height: 50px;
}
.file-span2 {
  display: inline-block;
  background-color: #eaf3f4;
  margin-bottom: 15px;
  line-height: 50px;
}
.header {
  font-size: 24px;
  font-weight: bold;
  margin: 30px;
}
.p-style {
  text-align: center;
  font-size: 50px;
  margin-top: 100px;
  margin-left: 70px;
}
.leftImg {
  position: relative;
  top: -2px;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.expEmailModal2-background {
  position: fixed;
  width: 100%;
  background-color: rgba(168, 161, 161, 0.7);
  z-index: 9999;
  text-align: center;
  /* display: none; */
}
.form-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}
.form-container2 {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
}
.form-control,
.form-control2 {
  border: none;
  background-color: #eaf3f4;
  overflow: none;
}
.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-group-div {
  background-color: #eaf3f4;
  width: 200px;
  border-radius: 10px;
}
.button-color2 {
  width: 100px;
  background-color: #eaf3f4;
  border-radius: 10px;
}
.add-template-button-color-new {
  position: absolute;
  bottom: 7rem;
}
.add-template-button-color {
  border: none;
  background-color: #eaf3f4;
}
.add-span {
  position: relative;
  top: -2px;
}
.btn-lg {
  min-width: 30.5rem;
  font-size: 20px;
  border-radius: 10px;
}
.section-title {
  color: #71afb6;
}
.upload-sub {
  color: #71afb6;
  font-size: 14px;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
.form-control {
  border: none;
}
.modal-content {
  border: none;
}
.alert-modal-header {
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  padding: 0.5rem 1rem;
}
.client-modal-header {
  background-color: #8b8b8b;
}
.alert-modal-body {
  text-align: center;
}
.client-modal-body {
  background-color: #f5fefd;
}

.client-modal-footer {
  background-color: #8b8b8b;
}
.modal-header .btn-close {
  color: #fff;
}
.modal-content,
.modal-card {
  border-radius: 15px;
  /* width: 700px; */
}
.alert-modal-footer {
  border-top: none;
  justify-content: center;
}
.modal-footer {
  display: flex;
}
.modal-btn-close {
  color: #fff;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border: none;
  border-radius: 10px;
  width: 10vw;
}
.modal-btn-close-cancel {
  color: #000;
  background-color: #eaf3f4;
  border: none;
  border-radius: 10px;
  width: 10vw;
}
textarea {
  resize: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5fefd;
}
.form-sub {
  background-color: #f5fefd;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #575757;
}
.form-sec-sub {
  width: 100%;
  border: none;
  background-color: #f5fefd;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #ababab;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.footer-text {
  font-family: "Roboto", sans-serif;
  color: #fff;
}
.clear-img-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.clear-file-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.ab-orange-div {
  background-color: #fff6f0;
  height: 110px;
  border-radius: 10px;
}
.ab-select {
  width: 14vw;
  height: 40px;
  border-radius: 10px;
}
#abTestModal {
  z-index: 1500;
}
.form-control:disabled {
  background-color: #eaf3f4;
}
/* .btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  background-image: linear-gradient(to bottom right, #71afb6, #b5dce0);
  color: #fff;
} */
.child-popout6 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -100px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.child-popout7 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -45px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -70px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.child-popout9 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -75px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1215px) {
  .btn-lg {
    min-width: 18rem;
    font-size: 20px;
    border-radius: 10px;
  }
}
@media screen and (min-device-width: 1216px) and (max-device-width: 1409px) {
  .btn-lg {
    min-width: 20.5rem;
    font-size: 20px;
    border-radius: 10px;
  }
}
</style>
