<template>
  <div class="container home">
    <!-- TouchList modal by id #TouchListModal -->
    <TouchList
      :event_name="apiEmailRate.活動名稱"
      :dropDown_event="['點擊', '送達']"
      :marketChannel="'sms'"
      :rule_id="this.touch_key"
      :hasAB="this.seeAB"
      :zIndex="5000"
      v-if="this.apiEmailRate.template_id != ''"
    ></TouchList>
    <!-- unlayerModal -->
    <EmailPreview :subject="subject" :html="html"></EmailPreview>

    <!-- alert修改成功Modal -->
    <div
      class="modal fade"
      id="deleteSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="deleteSuccess()"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div v-if="this.userID === '123'">
      請重新從前面<a href="/SmsMarketingTable">投放紀錄一覽表</a
      >裡的連結進入這頁面
    </div>
    <div v-else>
      <div class="loading-background" v-show="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- sms exp modal -->
      <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>
      <!-- show excludeModal -->
      <div
        class="modal fade"
        id="excludeModal"
        tabindex="-1"
        aria-labelledby="alertModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div
              class="modal-header alert-modal-header d-flex justify-content-end"
            >
              <img
                src="../assets/whiteX.svg"
                alt="arrow"
                class=""
                data-bs-dismiss="modal"
              />
            </div>
            <div
              class="modal-body alert-modal-body text-start"
              style="padding: 25px 28px"
            >
              <p>
                <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
                發送名單:
              </p>
              <p class="mb-5" style="margin-left: 36px; word-break: break-all">
                {{
                  apiEmailRate.發送客群.includeLabelNames
                    .toString()
                    .replace(/,/g, "、")
                }}
              </p>
              <p>
                <img
                  src="../assets/redAirplane.svg"
                  alt=""
                  class="me-3"
                />排除名單:
              </p>
              <p style="margin-left: 36px">
                {{
                  apiEmailRate.發送客群.excludeLabelNames
                    .toString()
                    .replace(/,/g, "、")
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- show EmailDetail -->
      <div
        class="modal fade"
        id="EmailDetail"
        tabindex="-1"
        aria-labelledby="alertModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style="max-width: 1078px"
        >
          <div class="modal-content" style="width: 1078px">
            <div
              class="modal-header alert-modal-header d-flex justify-content-end"
            >
              <img
                src="../assets/whiteX.svg"
                alt="arrow"
                class=""
                data-bs-dismiss="modal"
              />
            </div>
            <div
              class="modal-body alert-modal-body text-start"
              v-if="this.vice_ruleID != ''"
            >
              <EmailDetail
                v-bind:vice_ruleID="this.vice_ruleID"
                v-bind:emailChannel="this.nowAction"
                v-bind:topChannel="this.topChannel"
                :event_name="apiEmailRate.活動名稱"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- main -->
      <div class="d-flex justify-content-between">
        <span class="title-span fs-medium">
          <img
            src="../assets/back-arrow.png"
            @click="$router.go(-1)"
            style="cursor: pointer"
          />
          &nbsp; &nbsp; 單筆投放表現(SMS)</span
        >
        <button
          class="showDetails-btn"
          style="width: 200px; height: 40px"
          @click="getTemplateApi()"
        >
          <img src="../assets/eye.svg" style="width: 1.5rem" class="mb-1" />

          查看SMS資訊
        </button>
      </div>

      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12 mt-2 row-section">
          <div
            class="result-first-row d-flex justify-content-between"
            style="padding: 23px"
          >
            <div class="align-items-center d-flex" style="width: 33%">
              <div class="fs-small me-3" style="color: #c4c4c4">活動名稱</div>
              <div
                :class="
                  getLength(apiEmailRate.活動名稱) > 18
                    ? 'word-overflow'
                    : ''
                "
                style="max-width: 240px"
                v-tooltip.bottom="getLength(apiEmailRate.活動名稱.toString()) > 18? { value: apiEmailRate.活動名稱, class: 'tip-LabelManage'} : { value: '', class: ''}"
              >
                <div style="font-size: 22px">
                  {{ apiEmailRate.活動名稱 }}
                </div>
                <div style="font-size: 12px; color: #c4c4c4">
                  {{ apiEmailRate.活動時間 }}
                </div>
              </div>
            </div>
            <div class="align-items-center d-flex" style="width: 33%">
              <div
                class="fs-small me-3"
                style="color: #c4c4c4; min-width: 75px"
              >
                模板名稱
              </div>
              <div
                :class="getLength(template_name) > 18 ? 'word-overflow' : ''"
                style="max-width: 240px"
                v-tooltip.bottom="getLength(template_name) > 18? { value: template_name, class: 'tip-LabelManage'} : { value: '', class: ''}"
              >
                <div style="font-size: 22px">
                  {{ template_name }}
                </div>
                <div style="font-size: 12px; color: #c4c4c4">
                  {{ apiEmailRate.發送時間 }}
                </div>
              </div>
            </div>
            <div class="align-items-center d-flex" style="width: 33%">
              <div
                class="fs-small me-3"
                style="color: #c4c4c4; min-width: 80px"
              >
                發送客群
              </div>
              <div
                :class="
                  getLength(
                    apiEmailRate.發送客群.includeLabelNames.toString()
                  ) > 18
                    ? 'word-overflow'
                    : ''
                "
                style="max-width: 240px"
                v-tooltip.bottom="getLength(
                    apiEmailRate.發送客群.includeLabelNames.toString()
                  ) > 18 ? { value: apiEmailRate.發送客群.includeLabelNames
                      .toString()
                      .replace(/,/g, '、'), class: 'tip-LabelManage'} : { value: '', class: ''}"
              >
                <div
                  class="fs-medium"
                  v-if="apiEmailRate.發送客群.includeLabelNames[0] != undefined"
                >
                  {{
                    apiEmailRate.發送客群.includeLabelNames
                      .toString()
                      .replace(/,/g, "、")
                  }}
                </div>
                <div
                  style="
                    font-size: 14px;
                    font-weight: 100;
                    color: #c4c4c4;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  @click="openExcludeModal()"
                  v-if="apiEmailRate.發送客群.excludeLabelNames[0] != undefined"
                >
                  瀏覽完整名單
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12">
          <div class="d-flex flex-wrap result-second-row" style="height: auto">
            <div
              class="
                col-12
                d-flex
                align-items-center
                justify-content-between
                mb-4
              "
              style="color: #71afb6; font-size: 20px"
            >
              <span>投放成效</span>
              <button
                class="btn-basic bg-SkyBlue Cyan"
                style="width: 180px"
                @click="this.openTouchListModal(this.apiEmailRate.template_id,'AB')"
              >
                查看互動名單
              </button>
            </div>
            <div class="col-xl-7 col-xxl-7 d-flex flex-wrap">
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">寄出數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.寄出數.toLocaleString() }}&nbsp;(次)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">送達數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.送達數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.送達率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <!-- <p class="titleTag">花費成本</p>
              <div class="d-flex align-items-center">
                <p class="titleText">{{ rateData.花費成本 }}</p>
                <span class="mid-span">($)</span>
              </div> -->
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">點擊數</div>
                <div class="d-flex align-items-end flex-wrap">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.點擊數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.點擊率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">轉換數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.轉換數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.轉換率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">未點擊數</div>
                <div class="d-flex align-items-end flex-wrap">
                  <span class="m-0" style="font-size: 22px"
                    >{{
                      apiEmailRate.未點擊數.toLocaleString()
                    }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.未點擊率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-xxl-5">
              <div class="text-center">點擊/轉換/未點擊</div>
              <apexchart
                class="mt-2"
                type="donut"
                height="240"
                :options="chartPieOptions"
                :series="seriesPie"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-xl-center p-0 mt-4">
        <div class="col-12">
          <div class="row bar-div">
            <div class="d-flex align-items-center p-0">
              <p style="color: #71afb6">
                發送表現
                <BtnInfoBox content="此為投放後所得到的成效時間點與其數量，輕鬆掌握發送後表現。" direction="right"></BtnInfoBox>

                <!-- <b-button v-b-popover.hover.top="'Popover!'" variant="primary">Top</b-button> -->
              </p>
            </div>
            <div class="p-0">
              <apexchart
                type="bar"
                height="300"
                :options="stackBarOptions"
                :series="stackBarSeries"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="result-second-row" style="height: auto; min-height: 200px">
        <div class="d-flex align-items-center p-0">
          <p style="color: #71afb6">連結點擊表現</p>
        </div>
        <div
          class="text-center p-0"
          style="color: #c4c4c4; line-height: 100px"
          v-if="linkTable[0] === undefined"
        >
          此投放的連結尚未被點擊
        </div>
        <div class="p-0" v-else>
          <DataTable :value="linkTable" showGridlines responsiveLayout="scroll">
            <Column field="連結" header="連結">
              <template #body="slotProps">
                <div>
                  <a :href="slotProps.data.連結" target="_blank" style="word-break:break-all">{{
                    slotProps.data.連結
                  }}</a>
                </div>
              </template>
            </Column>
            <Column field="不重複點擊次數" header="不重複點擊數" style="min-width:120px"></Column>
            <Column field="重複點擊次數" header="重複點擊數" style="min-width:120px"></Column>
            <Column field="互動名單" header="互動名單" style="min-width: 120px">
              <template #body="slotProps">
                <div
                  class="GrayC4"
                  style="text-decoration: underline; cursor: pointer"
                  :title="slotProps.data.連結"
                  @click="this.openTouchListModal(this.htmlkey, slotProps.data.連結)"
                >
                  查看
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
     
      <div class="d-flex flex-wrap" v-if="this.apiTableData != ''">
        <div class="col-12">
          <SeeScript2
            v-bind:scriptID2="scriptID2"
            v-bind:emailChannel="emailChannel"
            v-bind:addadd2="addadd2"
            v-on:showUnlayer="showUnlayer"
            v-on:showSMSexp="showSMSexp"
            v-on:showEmailDetail="showEmailDetail"
            v-on:showOutsideExp="showOutsideExp"
            :key="forceRerender"
            v-bind:delivery="delivery"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button
          class="
            basic-btn
            highlight-btn
            d-flex
            align-items-center
            justify-content-center
          "
          style="width: 300px"
          @click="deletePerformanceApi"
        >
          <img src="../assets/delete.svg" alt="" class="me-3" />
          <span> 刪除此投放紀錄 </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
var popoverTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="popover"]')
);
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

// var popover = new bootstrap.Popover(document.querySelector('.example-popover'), {
//   container: 'body'
// })
// import Button from "vue-bootstrap";
import SeeScript2 from "../components/SeeScript2.vue";
import EmailDetail from "../components/EmailDetail.vue";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import VueApexCharts from "vue3-apexcharts";
import SmsPreview from "../components/SmsPreview.vue";
import TouchList from "../components/TouchList.vue";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "SmsResultPerformance",
  components: {
    apexchart: VueApexCharts,
    DataTable,
    Column,
    SeeScript2,
    EmailDetail,
    SmsPreview,
    TouchList,
    EmailPreview,
    BtnInfoBox,
  },
  data() {
    return {
      seeAB:'',
      touch_key:'',
      topChannel:"sms",

      loading: false,
      series: [],
      seriesPie: [],
      chartPieOptions: {
        chart: {
          height: 240,
          type: "donut",
        },
        colors: ["#F9C357", "#FC9F66", "#ABABAB"],
        labels: ["點擊", "轉換", "未點擊"],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: "bold",
          fontSize: "16px",

          //  floating: true,
        },
      },

      stackBarSeries: [],
      stackBarOptions: {
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      rateData: {},
      midPieData: [],
      botLeftPie: [],
      botRightPie: [],

      userID: "123",

      apiEmailRate: {
        pie: { 未點擊: 3594, 轉換: 1847, 點擊: 1788 },
        template_id: "",
        寄出數: 7298,
        未點擊數: 3594,
        未點擊率: 1.0195744680851064,
        活動名稱: "行銷活動2",
        發送客群: { excludeLabelNames: [], includeLabelNames: [] },
        發送時間: "2021-12-31 08:15:23",
        轉換數: 1847,
        轉換率: 25.55,
        送達數: 7229,
        送達率: 99.05,
        點擊數: 3635,
        點擊率: 50.28,
      },
      apiEmailPie: [],
      apiEmailPurchase: [],
      apiEmailTrend: [],
      apiTableData: "",

      apiOpenEmail: "",
      isShow: false,
      ABShow: false,
      saveEventName: "",
      saveEventTable: "",
      scheTodos: "",

      templateA: "",
      templateB: {},

      url: "",
      url2: "",
      url3: "",
      url4: "",

      template_name: "",

      htmlkey: "",
      html: "",

      emailChannel: false,
      forceRerender: 0,
      scriptID2: 0,
      addadd2: 0,

      resSubsec: "",
      newText: "",
      exampleModal2: "",
      Parr: [],
      events: [],

      deleteSuccessModal: {},

      countText: "",
      delivery: "",
      vice_ruleID: "",
      linkTable: [],
      has_script: 0,

      subject: "",
      exampleModal: {},
    };
  },

  created: async function () {
    // this.loading = true;
    this.has_script = sessionStorage.getItem("has_script");
    this.rule_id = sessionStorage.getItem("script_id");
    this.htmlkey = sessionStorage.getItem("template_id");

    await this.getCalenEvents();

    console.log(this.rule_id);
    if (this.rule_id === undefined) {
      this.userID = "123";
    } else {
      this.userID = this.rule_id;
      await Promise.all([
        this.postEmailRate(),
        this.postEmailTrend(),
        this.postSmsLinkData(),
      ]);
      if (this.has_script === "3" || this.has_script === "2") {
        await this.postSmsTable();
      }

      this.stackBarChart();
    }
    this.getScheAPi();

    this.scriptID2 = this.rule_id;
    // this.getRate();
  },
  methods: {

    // 計算中英文字元
    getLength(str) {
      return str.replace(/[\u4E00-\u9FFF]/g, "OO").length;
    },
    openTouchListModal(key,seeAB) {
      this.touch_key=''
      this.touch_key=key
      this.seeAB=seeAB
      const TouchListModal = new bootstrap.Modal(
        document.getElementById("TouchListModal"),
        {
          keyboard: false,
        }
      );
      TouchListModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    openExcludeModal() {
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    showSMSexp: function (childValue) {
      this.newText = childValue;
      this.newText = this.newText.replace(/{id}/g, "uid000");

      this.resSubsec = childValue;
      this.resSubsec = this.resSubsec.replace(/{id}/g, "uid000");

      this.highlightUrl();
      this.exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    showUnlayer: function (childHtml, childSub) {
      this.html = childHtml;
      this.subject = childSub;
      this.changeAllUrl();

      this.exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      this.exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.html);
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.html = this.html.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },
    deletePerformanceApi: async function () {
      var yes = confirm("確定刪除嗎?");

      if (yes) {
        const token = sessionStorage.getItem("access_token");

        const params = {
          Authorization: "Bearer " + token,
          script_id: this.rule_id,
        };
        await allapi3
          .overallDeleteScriptIdGet(params)
          .then((res) => {
            console.log("delete 排程成效", res);
            this.deleteSuccessModal = new bootstrap.Modal(
              document.getElementById("deleteSuccessModal"),
              {
                keyboard: false,
              }
            );
            this.deleteSuccessModal.show();
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("delete連線有誤" + err);
            this.loading = false;
          });
      } else {
        console.log("不刪除");
      }
    },
    deleteSuccess() {
      this.deleteSuccessModal.hide();
      this.$router.push({
        name: "SmsMarketingTable",
      });
    },

    highlightUrl() {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.resSubsec);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index].slice(0, -9) +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
    },
    test(d) {
      console.log("rowData", d);
      sessionStorage.setItem("vice_ruleID", d.ruleID);
      sessionStorage.setItem("eventTable", d.投放事件名稱);
      sessionStorage.setItem("eventName", this.apiEmailRate.活動名稱);
      sessionStorage.setItem("eventTemplate_ID", this.templateA);
      this.$router.push({
        name: "SmsEventPerformance",
      });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    showEmailDetail(key, action) {
      console.log(key, action);
      this.vice_ruleID = key;
      if (action == "傳送Email") {
        this.nowAction = true;
      } else {
        this.nowAction = false;
      }
      const EmailDetail = new bootstrap.Modal(
        document.getElementById("EmailDetail"),
        {
          keyboard: false,
        }
      );
      EmailDetail.show();
    },
    // 從預覽中顯示外層
    showOutsideExp() {
      this.getTemplateApi();
    },

    getScheAPi: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .smsTableGet(params)
        .then((res) => {
          console.log("3 item", res);
          console.log("this.rule_id", this.rule_id);
          this.scheTodos = res.data;
          const todo = this.scheTodos.find((d) => d.script_id === this.rule_id);
          console.log("efe", todo);
          this.template_name = todo.模板名稱;
          sessionStorage.setItem("setResEventTitle", todo.模板名稱);
          this.addadd2 = 1;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    getTemplateApi: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        key: this.apiEmailRate.template_id,
      };
      await allapi2
        .smsTemplateSmsScheduleKeyGet(params)
        .then((res) => {
          console.log("sms-template", res);

          this.newText = res.data.content;
          this.resSubsec = res.data.content
            .replace(/{id}/g, "uid000")
            .replace(/{lastname}/g, "王")
            .replace(/{firstname}/g, "◯◯")
            .replace(/{name}/g, "王◯◯");

          this.countText = res.data.content
            .replace(/{id}/g, "uid000")
            .replace(/{lastname}/g, "王")
            .replace(/{firstname}/g, "◯◯")
            .replace(/{name}/g, "王◯◯");

          console.log("this.resSubsec", this.resSubsec);
          this.highlightUrl();

          const exampleModal2 = new bootstrap.Modal(
            document.getElementById("exampleModal2"),
            {
              keyboard: false,
            }
          );
          exampleModal2.show();
          const backdrop = document.getElementsByClassName("modal-backdrop");
          backdrop[backdrop.length - 1].style["z-index"] = 4500;
        })
        .catch((err) => {
          console.log("連線有誤demotest");
          console.log("wdwd", this.apiEmailRate.template_id);
          console.log(err);
        });
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    postEmailRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .smsScriptRateGet(params)
        .then((res) => {
          console.log("emailrate", res);
          this.apiEmailRate = res.data;
          if (this.apiEmailRate.送達數 === undefined) {
            this.$router.push({
              name: "SmsMarketingTable",
            });
          }
          this.delivery = res.data.送達數;
          this.apiEmailPie = res.data;
          this.seriesPie = [
            this.apiEmailPie.pie.點擊,
            this.apiEmailPie.pie.轉換,
            this.apiEmailPie.pie.未點擊,
          ];
          console.log("pie", this.apiEmailRate);
          const d = this.events.find(
            (d) => d.id === this.apiEmailRate.活動名稱
          );
          if (d != undefined) {
            this.apiEmailRate.活動名稱 = d.title;
            this.apiEmailRate.活動時間 = `${d.start} - ${d.end}`;
          }
          // this.saveEventName = res.data.Data.活動名稱;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      // console.log("pie2",this.apiEmailRate )
      // console.log("data3",this.apiEmailPie )
    },
    postEmailTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .smsScriptTrendGet(params)
        .then((res) => {
          console.log("smsTrend", res);
          this.apiEmailTrend = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postSmsTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .smsScriptEventGet(params)
        .then((res) => {
          console.log("tabledata", res);
          this.apiTableData = res.data.Data;

          // this.saveEventTable = res.data.Data.事件名稱;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postSmsLinkData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .smsScriptUrlGet(params)
        .then((res) => {
          console.log("IndividualUrlGet", res);
          this.linkTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    DemoPieChart() {
      // console.log("pieipe", Object.values(this.apiEmailPie));
      (this.seriesPie = Object.values(this.apiEmailPie).map((d) => {
        return parseInt(d);
      })),
        (this.chartPieOptions = {
          chart: {
            height: 240,
            type: "donut",
          },
          colors: ["#96C5D7", "#F9C357", "#FC9F66", "#ABABAB"],
          labels: Object.keys(this.apiEmailPie),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //floating: true,
          },
        });
    },
    stackBarChart() {
      this.stackBarSeries = [
        {
          name: "轉換次數",
          data: this.apiEmailTrend.purchase,
        },
        {
          name: "點擊次數",
          data: this.apiEmailTrend.click,
        },
      ];
      this.stackBarOptions = {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
          zoom: {
            enabled: true,
          },
        },

        colors: ["#FC9F66", "#F9C357", "#96C5D7"],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          // type: "datetime",
          categories: this.apiEmailTrend.date,
          title: {
            text: "日期",
          },
        },
        yaxis: {
          title: {
            text: "次數",
            style: {
              cssClass: "apexchart-label",
            },
          },

          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toFixed(0);
            },
          },
        },
        tooltip: {
          y: {
            formatter: (y) => {
              return y.toFixed(0);
            },
          },
        },
        legend: {
          position: "bottom",
          offsetY: 3,
          offsetX: -10,
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>

<style scoped>
:deep(.p-datatable-responsive-scroll > .p-datatable-wrapper) {
  overflow: visible !important;
  overflow-x: visible !important;
}
:deep(.p-datatable table) {
  /* border-radius: 15px;
  border-collapse: separate;
  border-spacing: 1px;
  border: 1px solid #dee2e6; */
  border-collapse: collapse;
  border-radius: 15px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #575757; /* this draws the table border  */
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th) {
  border-width: 1px 1px 1px 1px;
}
:deep(th:last-child) {
  border-top-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  transition: box-shadow 0.2s;
}
:deep(.p-datatable-wrapper) {
  box-shadow: none;
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td) {
  border-width: 1px 1px 1px 1px;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: center;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 5px;
  line-height: 45px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td:last-child) {
  border-bottom-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td:first-child) {
  border-bottom-left-radius: 15px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td) {
  border-bottom: 1px solid #dee2e6 !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  /* border-bottom: none !important; */
}

.btn:focus,
.btn:active {
  /* outline: none !important; */
  box-shadow: none;
}

.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding: 50px;
}
.titleTag {
  margin-bottom: 20px;
}
.result-first-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 25px;
}

.result-second-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-top: 25px;
  padding: 15px 20px 24px;
  border-radius: 15px;
}

.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  /* margin-top: 15px; */
  height: 380px;
}

.abtesting-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 754px;
}

.ab-title {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 56px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.loading-background {
  position: fixed;
  left: 15%;
  top: 0;
  width: 85%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.apexchart-label {
  /* -webkit-writing-mode: vertical-lr;*/
  /* writing-mode: vertical-lr ;  */
  font-size: 14px;
  padding-right: 30px;
}

.questionMark-button:hover {
}

.parent-popout {
  position: relative;
}

.child-popout {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 300px;
  right: -315px;
  top: -42px;
  color: #fff;
  text-align: left;
  font-size: 14px;
  padding: 10px;
}

.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}

.negativeNum {
  /* content:"%"; */
  color: green;
}

.postiveNum {
  color: red;
}

.child-popout10 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -88px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

.child-popout11 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 286px;
  right: -300px;
  top: -42px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}

.showDetails-btn {
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
}

.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 10rem;
  padding: 30px;
  border-radius: 25px;
}

.sms-bubble:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 4%;
  left: -0.8%;
  height: 15px;
  width: 20px;
  background: #e6e6e6;
  border-top-left-radius: 105px;
  border-bottom-right-radius: 55px;
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}

/* @media (max-width: 1400px) {
  .fs-small {
    font-size: 15px;
  }
  .fs-medium {
    font-size: 20px;
  }
} */
</style>
