<template>
  <div
    id="liveToast"
    class="toast"
    style="
      width: 282px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
      position: absolute;
      left: 50%;
      top: 120px;
    "
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-bs-delay="3000"
  >
    <div class="d-flex">
      <div
        class="d-flex justify-content-between align-items-center flex-fill"
        style="padding: 15px 20px; background-color: #76d37a"
      >
        <span class="flex-fill text-center" style="color: white">
          上傳成功
        </span>
        <button
          style="border: none; background-color: #76d37a"
          class="d-flex justify-content-center align-items-center"
          data-bs-dismiss="toast"
          aria-label="Close"
        >
          <img src="../assets/whiteX.svg" alt="" style="width: 12px" />
        </button>
      </div>
    </div>
  </div>
  <!-- loading -->
  <div class="loading-background" v-show="loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <!-- uploading -->
  <div class="loading-background" v-show="uploading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden"> 檔案上傳中...</span>
    </div>
    <p>檔案上傳中...</p>
  </div>
  <!-- 分群仍在建立 -->
  <div
    class="modal fade"
    id="alertLabelNotReadyModal"
    tabindex="-1"
    aria-labelledby="alertModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header alert-modal-header">
          <img
            src="../assets/arrow-white.svg"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </div>
        <div class="modal-body alert-modal-body">
          因您才剛建立此分群標籤，系統正在載入報表分析結果，所需時間約3分鐘，請您稍後再試，謝謝。
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-secondary modal-btn-close"
            data-bs-dismiss="modal"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- uploadModal -->
  <div
    class="modal fade"
    id="uploadModal"
    tabindex="-1"
    aria-labelledby="alertModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 552px">
      <div class="modal-content" style="width: 100%">
        <div class="modal-header alert-modal-header">
          <img
            src="../assets/arrow-white.svg"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </div>
        <div class="modal-body alert-modal-body" style="padding: 24px 32px">
          <div class="text-start" style="font-size: 22px">點擊瀏覽檔案</div>
          <div class="mt-2">
            <input
              ref="file"
              type="file"
              class="custom-file-input"
              style="display: none"
              id="customFile"
              v-on:change="handleFileUpload()"
              accept=".csv,.xlsx,.xls"
            />
            <label
              style="position: relative"
              class="
                custom-file-label
                d-flex
                flex-column
                justify-content-center
                align-items-center
                flex-wrap
              "
              for="customFile"
            >
              <p
                id="upload-text"
                class="col-12 d-flex justify-content-between mt-2 mb-4"
              >
                <input
                  style="
                    font-size: 20px;
                    border-radius: 10px;
                    border: 1px solid #c4c4c4;
                    padding-left: 10px;
                    background-color: white;
                    width: 295px;
                  "
                  v-model="fileName"
                  disabled
                />
                <button
                  class="upload-btn ms-2"
                  style="font-size: 18px"
                  @click="goUpload"
                >
                  瀏覽...
                </button>
              </p>
              <div
                style="
                  color: red;
                  font-size: 12px;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                "
                v-if="uploadFail"
              >
                {{ this.uploadFailMessage }}。
              </div>
            </label>
          </div>
          <div style="color: #8a8a8a" class="text-start">
            支援格式為 .xlsx .csv .xls，請先
            <a
              href="https://docs.google.com/spreadsheets/d/1Q0mTg1ejeY4I_sic2gfxnTj3G3tHLBSUhjyqJZv2cAM/edit#gid=0"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #71afb6"
              >參考公版格式</a
            >
            再進行上傳，必要欄位為姓名、手機與信箱。
          </div>
          <div class="mt-3">
            <button
              type="button"
              class="btn btn-primary me-4"
              style="
                width: 125px;
                background-color: #eaf3f4;
                color: #71afb6;
                border: none;
                border-radius: 10px;
              "
              data-bs-dismiss="modal"
              @click="clearFile"
            >
              取消
            </button>
            <button
              class="basic-btn highlight2"
              style="width: 125px; cursor: pointer"
              @click="startUpload"
            >
              <div class="d-flex justify-content-center align-items-center">
                <span> 上傳</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- main -->
  <div class="container home">
    <div class="mb-4 d-flex align-items-center">
      <span style="font-size: 22px; font-weight: 700"> 分群管理與報表 </span>
    </div>
    <div class="shadow-box" style="padding: 24px">
      <DataTable
        v-model:selection="selectedLabel"
        dataKey="label_id"
        :value="this.labelsTable"
        :style="{ textAlign: 'left' }"
        :paginator="true"
        :rows="12"
        paginatorPosition="top"
        :pageLinkSize="3"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="目前 {currentPage} ，共 {totalPages} 頁"
        :first="this.pageFirst"
        @page="onPage($event)"
        @rowSelect="onRowSelect"
        :rowClass="rowClass"
      >
        <template #paginatorstart>
          <div>
            <div class="d-flex align-items-center" style="line-height: 40px">
              <div class="me-4">
                <span class="me-4" style="color: #71afb6">分群</span>
                <span>{{ this.labelsTable.length }} 個分群</span>
              </div>
              <div
                class="d-flex justify-content-between align-items-center ms-3"
                style="width: 380px"
              >
                <div class="col-7" style="position: relative">
                  <img
                    style="
                      position: absolute;
                      top: 12px;
                      left: 10px;
                      width: 19px;
                    "
                    src="../assets/search-black.png"
                    alt="searchImg"
                  />
                  <input
                    style="
                      padding-left: 35px;
                      width: 100%;
                      height: 35px;
                      border-radius: 10px;
                      border: 1px solid #575757;
                    "
                    v-model="searchInput"
                    placeholder="搜尋名稱"
                  />
                </div>
                <div
                  class="search-container col-5"
                  style="margin-left: 10px"
                  ref="target"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="
                      width: 330px;
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                      padding: 0 14px;
                      height: 35px;
                    "
                    @click="autoShow = !autoShow"
                  >
                    {{ triggerType }}
                    <div
                      class="d-flex align-items-center"
                      style="width: 50px"
                      :class="
                        this.filterCount === 0
                          ? 'justify-content-end'
                          : 'justify-content-between'
                      "
                    >
                      <div
                        style="
                          position: relative;
                          background-color: #71afb6;
                          border-radius: 10px;
                          width: 20px;
                          height: 20px;
                        "
                        v-if="this.filterCount != 0"
                      >
                        <span
                          style="
                            color: white;
                            font-size: 14px;
                            position: absolute;
                            top: -10px;
                            left: 6px;
                          "
                        >
                          {{ filterCount }}
                        </span>
                      </div>
                      <img
                        style="width: 10px; height: 8px"
                        src="../assets/selection-arrow.png"
                        alt="searchImg"
                      />
                    </div>
                  </div>
                  <div
                    class="auto-complicate-div text-start"
                    style="width: 330px"
                    v-show="autoShow"
                    @mouseenter="autoShow = true"
                  >
                    <p class="m-0 p-1 ps-3 my-2">分群</p>
                    <hr
                      class="m-0"
                      style="
                        width: 100%;
                        opacity: 1;
                        height: 0;
                        border: 0;
                        border-bottom: 1px solid #c4c4c4;
                      "
                    />
                    <div class="d-flex flex-wrap">
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="個性化分群"
                          id="個性化分群"
                          value="個性化分群"
                          v-model="checkedNames2"
                        />
                        <label for="個性化分群" style="width: 83%"
                          >個性化分群</label
                        >
                      </p>
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="自訂分群"
                          id="自訂分群"
                          value="自訂分群"
                          v-model="checkedNames2"
                        />
                        <label for="自訂分群" style="width: 83%"
                          >自訂分群</label
                        >
                      </p>
                    </div>
                    <p class="m-0 p-1 ps-3 my-2">建立時間</p>
                    <hr
                      class="m-0"
                      style="
                        width: 100%;
                        opacity: 1;
                        height: 0;
                        border: 0;
                        border-bottom: 1px solid #c4c4c4;
                      "
                    />
                    <div
                      class="
                        m-0
                        p-2
                        ps-3
                        auto-complicate-p
                        d-flex
                        align-items-center
                      "
                    >
                      <input
                        class="me-2"
                        style="position: relative; top: 1px"
                        type="checkbox"
                        name="dateRange"
                        id="dateRange"
                        :value="0"
                        v-model="checkedNames3"
                      />
                      <div style="width: 90%; position: relative">
                        <DatePicker v-model="filterDateRange" is-range>
                          <!-- @dayclick="updateRangeday(index)" -->
                          <template v-slot="{ togglePopover }">
                            <input
                              id="dropdown-moreoption-rangeinput"
                              class="ps-5"
                              style="
                                cursor: default;
                                width: 100%;
                                height: 40px;
                                border-radius: 10px;
                                border: 1px solid #c4c4c4;
                              "
                              placeholder="選擇日期"
                              :value="time_range"
                              @click="togglePopover()"
                              readonly
                            />
                            <img
                              src="../assets/date.svg"
                              alt=""
                              style="position: absolute; left: 10px; top: 10px"
                            />
                            <img
                              src="../assets/selection-arrow.png"
                              alt="searchImg"
                              style="position: absolute; right: 10px; top: 16px"
                            />
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-start mt-5">
              已選取 {{ this.selectedLabel.length }} 個分群，共
              {{ this.selectedLabel_member }} 人。
            </div>
          </div>
        </template>

        <Column
          selectionMode="multiple"
          headerStyle="min-width: 80px"
          class="m-auto"
        >
        </Column>
        <Column
          field="label"
          header="分群名稱"
          style="overflow-wrap: anywhere; width: 800px"
          sortable
        >
          <template #body="slotProps2">
            <div
              v-tooltip.bottom="
                slotProps2.data.label.length > 14
                  ? {
                      value: slotProps2.data.label,
                      class: 'tip-LabelManage',
                    }
                  : {
                      value: '',
                      class: '',
                    }
              "
              class="word-overflow"
              style="width: 240px; padding-top: 5px"
            >
              {{ slotProps2.data.label }}
            </div>
          </template>
        </Column>
        <Column
          field="label_type"
          header="建立來源"
          style="min-width: 180px"
          sortable
        >
        </Column>
        <Column
          field="time"
          header="建立時間"
          style="min-width: 240px"
          sortable
        >
        </Column>
        <Column field="status" header="狀態" style="min-width: 140px" sortable>
          <template #body="slotProps2">
            <div
              :style="this.statuscolor(slotProps2)"
              v-tooltip.bottom="
                slotProps2.data.status_message != ''
                  ? {
                      value: slotProps2.data.status_message,
                      class: 'tip-LabelManage tip-LabelManage-status_message',
                    }
                  : {
                      value: '',
                      class: '',
                    }
              "
            >
              <img
                src="../assets/blackBigSpot.png"
                alt=""
                class="mb-1 me-1"
                v-if="slotProps2.data.status == '完整上傳'"
              />
              <img
                src="../assets/graySpot.png"
                alt=""
                class="mb-1 me-1"
                v-else-if="slotProps2.data.status == '處理中'"
              />
              <img
                src="../assets/orangeOneSpot.svg"
                style="width: 10px"
                alt=""
                class="mb-1 me-1"
                v-else-if="slotProps2.data.status == '部分上傳'"
              />
              <img
                src="../assets/pinkSpot.png"
                alt=""
                class="mb-1 me-1"
                v-if="slotProps2.data.status == '上傳失敗'"
              />
              {{ slotProps2.data.status }}
            </div>
          </template>
        </Column>
        <Column field="" header="" style="overflow: visible; min-width: 80px">
          <template #body="slotProps2">
            <button
              class="del-btn"
              id="delete-button"
              @click="toggleMoreOption($event, slotProps2)"
            >
              <img src="../assets/spot.svg" />
            </button>
            <Menu
              :key="this.refreshMenu"
              class="menu-style-LabelManage"
              ref="menu"
              :model="menuItems"
              :popup="true"
            >
              <!-- @mouseleave="hide" -->
              <template #item="{ item }">
                <p
                  class="m-0 d-flex align-items-center"
                  style="min-width: 80px"
                  @click="deleteLabel()"
                >
                  <img
                    style="position: relative; top: 2px"
                    :src="item.imgSrc"
                    alt="Image"
                  />
                  <span class="ms-2">{{ item.label }}</span>
                </p>
              </template>
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex justify-content-between" style="width: 38vw">
        <button class="basic-btn" style="width: 18vw" @click="openUploadModal">
          <img
            src="../assets/upload.svg"
            style="position: relative; top: -1px; margin-right: 15px"
            alt=""
          />
          上傳自訂分群
        </button>
        <button class="basic-btn" style="width: 18vw" @click="getcsv">
          <img
            src="../assets/green-download.svg"
            style="position: relative; top: -1px; margin-right: 15px"
            alt=""
          />匯出分群名單
        </button>
      </div>
      <button
        class="
          basic-btn
          highlight-btn
          d-flex
          align-items-center
          justify-content-center
        "
        style="width: 18vw"
        @click="getmemberinfo"
      >
        <img
          src="../assets/white-list.svg"
          style="position: relative; top: 1px; margin-right: 15px"
          alt=""
        />查看報表
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import Button from "primevue/button";
import moment from "moment";
import { allapi2 } from "../../public/static2/api/apigClient.js";

export default {
  name: "LabelManage",
  components: {
    DataTable,
    Column,
    Menu,
    Button,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return { target, autoShow };
  },
  data() {
    return {
      labelTimer: {},
      refreshMenu: 0,
      uploadFail: false,
      uploadFailMessage: "",
      uploading: false,
      loading: false,

      searchInput: "",
      triggerType: "篩選",
      checkedNames2: [],
      checkedNames3: [],
      filterDateRange: {},
      time_range: "",

      pageFirst: 0,
      nowPage: 0,
      selectedLabel: [],
      selectedLabel_member: 0,
      selectAll: false,

      labelsTable: [],
      originLabelsTable: [],

      base64Banner: "",
      uploadUrl: "",
      fileName: "",
      fileSize: "",

      nowsize: 0,
      first_slice: 0,
      next_slice: 5000000,
      slice_size: 5000000,
      count: 1,
      timecount: 0,

      id: "",
      value: 0,

      listData: {},
      menuItems: [
        {
          label: "刪除",
          imgSrc: require("../assets/delete-black.svg"),
        },
      ],

      uploadModal: {},
      originCustomLabels: [],
      originUploadLabels: [],

      json_data: [],
    };
  },
  watch: {
    selectedLabel() {
      console.log("selectedLabel", this.selectedLabel);

      if (this.selectedLabel[0] != undefined) {
        // this.selectedLabel = this.selectedLabel.filter(
        //   (d) => d.status != "處理中"
        // );
        this.getHeadCount();
      } else {
        this.selectedLabel_member = 0;
      }
      this.refreshMenu += 1;
    },
    monthData: function (val) {
      console.log("val", val);

      if (val === null) {
        this.get();
      } else {
        const searched = this.searchTodos.filter((d) => {
          console.log(
            "dS",
            d.ScheduleExpression.toLocaleString("en-ZA").slice(0, 10)
          );

          if ((val.month + 1).toString().length < 2) {
            this.searchMonth = "0" + (val.month + 1).toString();
          } else {
            this.searchMonth = val.month + 1;
          }
          console.log("month", this.searchMonth);

          const dateString = val.year + "/" + this.searchMonth;

          console.log(
            "find its",
            d.ScheduleExpression.toLocaleString("en-ZA").includes(dateString)
          );
          return d.ScheduleExpression.toLocaleString("en-ZA").includes(
            dateString
          );
        });
        console.log("try this", searched);
        this.newtodos = searched;
      }
    },
    searchInput: function (val) {
      if (val === "") {
        if (
          this.checkedNames2[0] === undefined &&
          this.checkedNames3[0] === undefined
        ) {
          this.labelsTable = this.originLabelsTable;
        } else {
          this.labelsTable = this.originLabelsTable.filter((d) => {
            return (
              (this.checkedNames2[0] === d.label_type ||
                this.checkedNames2[1] === d.label_type ||
                this.checkedNames2[0] === undefined) &&
              ((this.time_range.slice(0, 10) <= d.create_time.slice(0, 10) &&
                this.time_range.substr(11, 10) >= d.create_time.slice(0, 10)) ||
                this.checkedNames3[0] === undefined)
            );
          });
        }
        this.labelsTable.map((d, index) => {
          d.index = index;
        });
      } else {
        console.log(val);
        this.labelsTable = this.labelsTable.filter((d) =>
          d.label.includes(val)
        );
      }
      this.selectedLabel = [];
      this.selectAll = false;
    },
    checkedNames2(val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.labelsTable = this.originLabelsTable;
      } else {
        this.labelsTable = this.originLabelsTable.filter((d) => {
          return (
            (this.checkedNames2[0] === d.label_type ||
              this.checkedNames2[1] === d.label_type ||
              this.checkedNames2[0] === undefined) &&
            ((this.time_range.slice(0, 10) <= d.create_time.slice(0, 10) &&
              this.time_range.substr(11, 10) >= d.create_time.slice(0, 10)) ||
              this.checkedNames3[0] === undefined)
          );
        });
      }
      this.labelsTable.map((d, index) => {
        d.index = index;
      });
      this.selectedLabel = [];
      this.selectAll = false;
    },
    checkedNames3(val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.labelsTable = this.originLabelsTable;
      } else {
        this.labelsTable = this.originLabelsTable.filter((d) => {
          console.log(
            this.time_range.slice(0, 10) <= d.create_time.slice(0, 10) &&
              this.time_range.substr(11, 10) >= d.create_time.slice(0, 10)
          );
          return (
            (this.checkedNames2[0] === d.label_type ||
              this.checkedNames2[1] === d.label_type ||
              this.checkedNames2[0] === undefined) &&
            ((this.time_range.slice(0, 10) <= d.create_time.slice(0, 10) &&
              this.time_range.substr(11, 10) >= d.create_time.slice(0, 10)) ||
              this.checkedNames3[0] === undefined)
          );
        });
      }
      this.labelsTable.map((d, index) => {
        d.index = index;
      });
      this.selectedLabel = [];
      this.selectAll = false;
    },
    filterDateRange() {
      this.updateRange();
      if (this.checkedNames3[0] != undefined) {
        this.labelsTable = this.originLabelsTable.filter((d) => {
          return (
            (this.checkedNames2[0] === d.label_type ||
              this.checkedNames2[1] === d.label_type ||
              this.checkedNames2[0] === undefined) &&
            ((this.time_range.slice(0, 10) <= d.create_time.slice(0, 10) &&
              this.time_range.substr(11, 10) >= d.create_time.slice(0, 10)) ||
              this.checkedNames3[0] === undefined)
          );
        });
      }
      this.labelsTable.map((d, index) => {
        d.index = index;
      });
      this.selectedLabel = [];
      this.selectAll = false;
    },
  },
  computed: {
    filterCount() {
      return this.checkedNames2.length + this.checkedNames3.length;
    },
  },
  created: async function () {
    this.loading = true;
    await this.getCustomLabelList();
    await this.getUploadLabelList();
    this.combineLabel();
    this.loading = false;
    this.labelTimer = setInterval(async () => {
      await this.getUploadLabelList();
      this.combineLabel();
      this.filterCombineLabel();
    }, 10000);
  },
  unmounted() {
    clearInterval(this.labelTimer);
  },
  methods: {
    onRowSelect() {
      this.selectedLabel = this.selectedLabel.filter(
        (d) => d.status != "處理中"
      );
    },
    rowClass(data) {
      return data.status === "處理中" ? "color-c4c4c4" : "";
    },
    // 判斷status color
    statuscolor(slotProps2) {
      if (slotProps2.data.status == "完整上傳") {
        return "color:#575757";
      } else if (slotProps2.data.status == "處理中") {
        return "color:#C4C4C4";
      } else if (slotProps2.data.status == "部分上傳") {
        return "color:#FC9F66";
      } else if (slotProps2.data.status == "上傳失敗") {
        return "color:#FF8B8B";
      }
    },
    // 顯示彈出訊息
    showToast() {
      let toastLiveExample = document.getElementById("liveToast");
      let toast = new bootstrap.Toast(toastLiveExample);

      toast.show();
    },
    // 更改篩選裡的時間範圍
    updateRange() {
      console.log(this.filterDateRange);
      console.log(this.filterDateRange.start.toISOString());
      this.time_range =
        this.filterDateRange.start.toISOString().slice(0, 10) +
        "~" +
        this.filterDateRange.end.toISOString().slice(0, 10);
    },
    /**更多選項相關功能 */
    // 開啟更多選項
    toggleMoreOption(event, slotProps2) {
      console.log("拿到slotProps2.data", slotProps2.data);
      this.listData = slotProps2.data;
      this.$refs.menu.toggle(event);
    },
    // 關閉更多選項
    hideMoreOption() {
      this.$refs.menu.hide();
    },
    // 更多選項中的刪除
    deleteLabel: async function () {
      this.loading = true;
      this.hideMoreOption();
      if (this.listData.label_id.includes("C_")) {
        await this.deleteCustomLabel(this.listData.label_id);
      } else {
        await this.deleteUploadLabel(this.listData.label_id);
      }
     this.selectedLabel= this.selectedLabel.filter((d) => {
        return d.label_id != this.listData.label_id;
      });
      await this.getCustomLabelList();
      await this.getUploadLabelList();
      this.combineLabel();
      this.loading = false;
    },
    // 刪除個性化分群的api
    deleteCustomLabel: async function (label_id) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        label_id: label_id,
      };
      await allapi2
        .customlabelDeleteDelete(params, body)
        .then((res) => {
          console.log("deleteCustomLabel", res);
          if (res.data.message === "The Label cannot be deleted") {
            alert("在投放中的分群無法被刪除");
          }
          // else {
          //   const deleteSuccessModal = new bootstrap.Modal(
          //     document.getElementById("deleteSuccessModal"),
          //     {
          //       keyboard: false,
          //     }
          //   );
          //   deleteSuccessModal.show();
          // }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    // 刪除自訂分群的api
    deleteUploadLabel: async function (label_id) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        label_id: label_id,
      };
      await allapi2
        .uploadLabelDelete(params)
        .then((res) => {
          console.log("deleteCustomLabel", res);
          if (res.data.message === "The Label cannot be deleted") {
            alert("在投放中的分群無法被刪除");
          }
          //  else {
          //   const deleteSuccessModal = new bootstrap.Modal(
          //     document.getElementById("deleteSuccessModal"),
          //     {
          //       keyboard: false,
          //     }
          //   );
          //   deleteSuccessModal.show();
          // }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },

    /**上傳相關功能 */
    // 開啟上傳檔案的彈出視窗
    openUploadModal() {
      this.uploadModal = new bootstrap.Modal(
        document.getElementById("uploadModal"),
        {
          keyboard: false,
        }
      );
      this.uploadModal.show();
    },
    // 在其他按鈕 觸發handleFileUpload
    goUpload() {
      this.$refs.file.click();
    },
    // 開啟瀏覽器內建的電腦文件選取功能
    handleFileUpload: async function () {
      if (this.$refs.file.files[0] != undefined) {
        this.fileName = this.$refs.file.files[0].name;
        let explabelName = "";
        if (this.fileName.slice(-3) == "csv") {
          explabelName = this.fileName.slice(0, -4);
        } else if (this.fileName.slice(-3) == "xls") {
          explabelName = this.fileName.slice(0, -4);
        } else if (this.fileName.slice(-3) == "lsx") {
          explabelName = this.fileName.slice(0, -5);
        } else {
          this.uploadFail = true;
          this.uploadFailMessage = "檔案類型錯誤，無法進行上傳";
          return;
        }
        const has_same_name = this.originUploadLabels.findIndex(
          (d) => d.label == explabelName
        );
        if (has_same_name != -1) {
          this.uploadFail = true;
          this.uploadFailMessage = "檔案名稱重複，無法進行上傳";
          return;
        }
        let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=\s]/;
        if (isText.test(explabelName)) {
          this.uploadFail = true;
          this.uploadFailMessage = "名稱不能含有是有特殊字元或空格";
          return;
        }
        this.fileSize = this.$refs.file.files[0].size;
        console.log("filaen", this.$refs.file);
        this.first_slice = 0;
        this.next_slice = this.slice_size;
        this.count = 1;

        this.id = Date.now();
        // this.leftsize = this.fileSize;
        this.getbase64();
        this.uploadFail = false;
      }
    },
    // 將要上傳的檔案轉成ArrayBuffer 並切割出第一段
    getbase64() {
      if (this.$refs.file.files[0].size > 1024000000) {
        this.base64Banner = "";
        this.fileName = "";
        this.fileSize = "";
        alert("檔案不能超過1GB");
      } else {
        const file = this.$refs.file.files[0].slice(
          this.first_slice,
          this.next_slice
        );
        // 轉成base64
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          console.log(reader.result);
          this.base64Banner = reader.result;
        };

        this.first_slice = this.first_slice + this.slice_size;
        this.next_slice = this.next_slice + this.slice_size;

        console.log("this.first_slice", this.first_slice);
        console.log("this.next_slice", this.next_slice);
        console.log("this.count", this.count);
        this.count = this.count + 1;
      }
    },
    // 開始上傳第一個分隔檔案 成功後接續上傳直到完成
    startUpload: async function () {
      if (this.uploadFail) {
        return;
      }
      this.uploading = true;
      if (this.base64Banner === "") {
        alert("請選擇檔案");
        return;
      }
      let fileName = "";
      if (this.fileName.slice(-3) == "csv") {
        fileName = this.fileName.slice(0, -4);
      } else if (this.fileName.slice(-3) == "xls") {
        fileName = this.fileName.slice(0, -4);
      } else {
        fileName = this.fileName.slice(0, -5);
      }
      const token = sessionStorage.getItem("access_token");
      await axios
        .post(
          `https://firstcdp-dataupload.retailing-data.net/api/upload_label/upload?name=${this.id}&filename=${fileName}`,
          this.base64Banner,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(async (res) => {
          console.log(res);
          if (this.first_slice < this.fileSize) {
            this.value = Math.ceil((this.first_slice / this.fileSize) * 100);
            this.nowsize = this.nowsize + this.slice_size;
            this.getbase64();
            setTimeout(() => {
              this.startUpload();
            }, 500);
          } else {
            this.value = 100;
            this.uploadWasEnd();
          }
        })
        .catch((error) => {
          console.log("連線有誤");
          console.log(error);
          alert("上傳中斷了");
          this.uploading = false;
          this.clearFile();
        });
    },
    // 所有接續上傳都已完成 則call此api讓後端將分隔的檔案合併
    uploadWasEnd() {
      const token = sessionStorage.getItem("access_token");
      let fileName = "";
      if (this.fileName.slice(-3) == "csv") {
        fileName = this.fileName.slice(0, -4);
      } else if (this.fileName.slice(-3) == "xls") {
        fileName = this.fileName.slice(0, -4);
      } else {
        fileName = this.fileName.slice(0, -5);
      }
      let extension = "";
      if (this.fileName.slice(-3) == "csv") {
        extension = this.fileName.slice(-4);
      } else if (this.fileName.slice(-3) == "xls") {
        extension = this.fileName.slice(-4);
      } else {
        extension = this.fileName.slice(-5);
      }

      axios
        .get(
          `https://firstcdp-dataupload.retailing-data.net/api/upload_label/upload?name=${this.id}&filename=${fileName}&extension=${extension}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(async (res) => {
          console.log(res);
          if (res.data.status != "success") {
            this.uploadFail = true;
            this.uploadFailMessage = "上傳分群失敗，缺少必要欄位";
          } else {
            setTimeout(() => {
              this.fileName = "";
              this.base64Banner = "";
              this.value = 0;
              this.count = 1;
            }, 1000);
            await this.getUploadLabelList();
            this.combineLabel();
            this.uploadModal.hide();
            this.showToast();
          }
          this.uploading = false;
        })
        .catch((error) => {
          console.log("連線有誤");
          console.log(error);
          this.uploading = false;
          this.clearFile();
          this.uploadFail = true;
          this.uploadFailMessage =
            "上傳分群失敗，請確認上傳檔案格式為.xlsx .csv .xls。";
        });
    },
    // 清空file內容
    clearFile() {
      this.fileName = "";
      this.base64Banner = "";
      this.value = 0;
      this.count = 1;
      this.uploadFail = false;
    },

    // 拿所有個性化分群labels
    getCustomLabelList: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelListGet(params)
        .then((res) => {
          console.log("CustomLabelList", res);

          res.data.message.Data.map((d) => {
            d.create_time = new Date(moment(d.time).add(-8, "h")).toISOString();
            d.time = new Date(moment(d.time).add(-8, "h"))
              .toLocaleString()
              .slice(0, -3);
            d.label_type = "個性化分群";
          });
          this.originCustomLabels = res.data.message.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    // 拿所有自訂分群labels
    getUploadLabelList: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .uploadLabelGet(params)
        .then((res) => {
          console.log("uploadLabelList", res);

          res.data.map((d) => {
            d.create_time = d.time;
            d.time = new Date(d.time).toLocaleString().slice(0, -3);
            d.label_type = "自訂分群";
            if (d.status.split("_")[1] != undefined) {
              d.status_message =
                d.status.split("_")[1] + "\n" + d.status.split("_")[2];
            } else {
              d.status_message = "";
            }
            d.status = d.status.split("_")[0];
          });
          this.originUploadLabels = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    // 合併兩個大類的分群(個性化分群和自訂分群)
    combineLabel() {
      this.originLabelsTable = [
        ...this.originCustomLabels,
        ...this.originUploadLabels,
      ];
      this.labelsTable = this.originLabelsTable;
      this.labelsTable.sort((a, b) => {
        return new Date(b.create_time) - new Date(a.create_time);
      });
      this.labelsTable.map((d, index) => {
        d.index = index;
      });
      console.log("this.labelsTable", this.labelsTable);
    },
    // 合併後 若已有篩選則根據篩選顯示
    filterCombineLabel() {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.labelsTable = this.originLabelsTable;
      } else {
        this.labelsTable = this.originLabelsTable.filter((d) => {
          return (
            (this.checkedNames2[0] === d.label_type ||
              this.checkedNames2[1] === d.label_type ||
              this.checkedNames2[0] === undefined) &&
            ((this.time_range.slice(0, 10) <= d.create_time.slice(0, 10) &&
              this.time_range.substr(11, 10) >= d.create_time.slice(0, 10)) ||
              this.checkedNames3[0] === undefined)
          );
        });
      }
      this.labelsTable = this.labelsTable.filter((d) =>
        d.label.includes(this.searchInput)
      );
      console.log('this.selectedLabel',this.selectedLabel)
      if (this.selectedLabel[0] != undefined) {
        this.getHeadCount();
      } else {
        this.selectedLabel_member = 0;
      }
      this.refreshMenu += 1;
      this.labelsTable.map((d, index) => {
        d.index = index;
      });
    },
    //  前往標籤報表資訊
    getmemberinfo() {
      const labelsID = this.selectedLabel.map((d) => {
        return d.label_id;
      });
      const has_upload_label = labelsID.filter((d) => d.includes("U_"));
      if (has_upload_label.length > 0) {
        alert("自訂分群暫未開放查看報表。");
        return;
      }
      sessionStorage.setItem("reportLabel", JSON.stringify(labelsID));

      if (labelsID[0] != undefined) {
        /// 確認報表產生完成
        this.checkMultipleLabel().then((res) => {
          if (res == true) {
            this.$router.push({
              name: "TagReport",
            });
          } else {
            /// 跳出提示視窗
            const alertLabelNotReadyModal = new bootstrap.Modal(
              document.getElementById("alertLabelNotReadyModal"),
              {
                keyboard: false,
              }
            );
            alertLabelNotReadyModal.show();
          }
        });
      } else {
        alert("請先選擇分群");
      }
    },
    //  確認報表資訊產生完成
    checkMultipleLabel: async function () {
      const labelsID = this.selectedLabel.map((d) => {
        return d.label_id;
      });

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        labelId: labelsID,
      };

      return await allapi2
        .multipleLabelCheckPost(params, body)
        .then((res) => {
          console.log("checkMultipleLabel", res["data"]["Data"]);
          return res["data"]["Data"];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    //  全選
    // onSelectAllChange(event) {
    //   if (this.selectAll) {
    //     this.selectAll = false;
    //     this.selectedLabel = this.selectedLabel.filter((d) => {
    //       return (
    //         d.index < 12 * this.nowPage || d.index >= 12 * (this.nowPage + 1)
    //       );
    //     });
    //   } else {
    //     this.selectAll = true;
    //     this.selectedLabel = this.selectedLabel.concat(
    //       this.labelsTable.slice(12 * this.nowPage, 12 * (this.nowPage + 1))
    //     );
    //     this.selectedLabel = [...new Set(this.selectedLabel)];
    //   }
    // },
    // 頁碼選擇
    onPage(event) {
      this.nowPage = event.page;
      const selectOnPage = this.selectedLabel.filter((d) => {
        return (
          d.index >= 12 * this.nowPage && d.index < 12 * (this.nowPage + 1)
        );
      });
      if (selectOnPage.length == 12) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },

    // 拿分群總人數
    getHeadCount: async function () {
      const include_ids = this.selectedLabel.map((d) => {
        return d.label_id;
      });
      const exclude_ids = [];
      console.log("include_ids", include_ids);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: { include: include_ids, exclude: exclude_ids },
      };
      await allapi2
        .customlabelMarketingRatePost(params, body)
        .then((res) => {
          console.log("分群總人數與人數占比", res);
          this.selectedLabel_member = res.data.message.Data.count;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          if (err.status === 502) {
            const alertLabelNotReadyModal = new bootstrap.Modal(
              document.getElementById("alertLabelNotReadyModal"),
              {
                keyboard: false,
              }
            );
            alertLabelNotReadyModal.show();
          } else {
            alert("連線有誤");
          }
        });
    },
    // 下載分群CSV
    download() {
      const buildData = (data) => {
        return new Promise((resolve, reject) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(data[0]);
          arrayData.push(arrayTitle);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(data, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title] || "無";
              items.push(item);
            });
            arrayData.push(items);
          });

          resolve(arrayData);
        });
      };
      const selectNames = this.selectedLabel.map((d) => d.label);
      const downloadCSV = (data) => {
        let csvContent = "";
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(",") + "\n";
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName =
          "下載" +
          "[" +
          selectNames +
          "]" +
          "資料" +
          new Date().toISOString() +
          ".csv";

        // 建立一個 a，並點擊它
        let link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
        );
        link.setAttribute("download", fileName);
        link.click();
      };
      const data = this.json_data;
      buildData(data).then((data) => downloadCSV(data));
    },
    getcsv: async function () {
      if (this.selectedLabel[0] != undefined) {
        const include_ids = this.selectedLabel.map((d) => d.label_id);
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
        };
        const body = {
          label_ids: include_ids,
        };
        await allapi2
          .multipleLabelExportPost(params, body)
          .then((res) => {
            console.log("getcsv", res);
            this.json_data = res.data.message.Data.map((o) => {
              return {
                會員ID: o.會員ID,
                姓名: o.姓名,
                性別: o.性別,
                年齡: o.年齡,
                生日: o.生日,
                手機: o.手機,
                信箱: o.信箱,
                地址1: o.地址1,
                地址2: o.地址2,
                地址3: o.地址3,
                加入日期: o.加入日期,
                會員註冊來源: o.會員註冊來源,
                "LINE ID": o["LINE ID"],
                婚姻狀態: o.婚姻狀態,
                會員等級: o.會員等級,
              };
            });
            console.log("this.json_data", this.json_data);
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            alert("連線有誤");
          });

        this.download();
      } else {
        alert("請先選擇分群");
      }
    },
  },
};
</script>

<style scoped>
/* 表格 */
:deep(.color-c4c4c4) {
  color: #c4c4c4;
}
:deep(.p-datatable-thead tr) {
  height: 70px;
}
:deep(thead > tr > th > div > .p-checkbox) {
  display: none;
}
:deep(.p-datatable-table) {
  border-radius: 15px;
}
:deep(.p-datatable .p-column-header-content) {
  display: block;
}
:deep(.p-column-header-content .p-checkbox) {
  margin: auto;
}
:deep(.p-checkbox) {
  margin-left: 20px;
}
:deep(.p-datatable .p-datatable-tbody > tr.p-highlight) {
  color: #575757 !important;
}
:deep(.p-paginator) {
  align-items: flex-start;
}
/* 主內容 */
.upload-btn {
  top: 0;
  left: 600px;
  z-index: 3;
  display: block;
  text-align: center;
  width: 150px;
  height: 40px;
  padding: 0.375rem 0.75rem;
  line-height: 1;
  color: #fff;
  content: "瀏覽...";
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
</style>