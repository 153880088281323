<template>
  <div class="container home">
    <!-- sms modal -->
    <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>
    <!-- TouchList modal by id #TouchListModal -->
    <TouchList
      :event_name="apiEmailRate.活動名稱"
      :dropDown_event="['開信', '點擊', '送達', '退件']"
      :marketChannel="'email'"
      :rule_id="this.touch_key"
      :zIndex="5000"
      :hasAB="this.seeAB"
    ></TouchList>
    <!-- alert修改成功Modal -->
    <div
      class="modal fade"
      id="deleteSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="deleteSuccess()"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <p>
              <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
            </p>
            <p class="mb-5" style="margin-left: 36px; word-break: break-all">
              {{
                apiEmailRate.發送客群.includeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
            <p>
              <img
                src="../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
            </p>
            <p style="margin-left: 36px">
              {{
                apiEmailRate.發送客群.excludeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- show EmailDetail -->
    <div
      class="modal fade"
      id="EmailDetail"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 1078px">
        <div class="modal-content" style="width: 1078px">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            v-if="this.vice_ruleID != ''"
          >
            <EmailDetail
              v-bind:vice_ruleID="this.vice_ruleID"
              v-bind:emailChannel="this.nowAction"
              v-bind:topChannel="this.topChannel"
              :event_name="apiEmailRate.活動名稱"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div v-if="this.userID === '123'">
      請重新從前面<a href="/EmailMarketingTable">投放紀錄一覽表</a
      >裡的連結進入這頁面
    </div>
    <div v-else>
      <div class="loading-background" v-show="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!-- unlayerModal -->
      <!-- expEmailModal -->
      <EmailPreview :subject="subject" :html="html"></EmailPreview>

      <!-- main -->
      <div class="d-flex justify-content-between">
        <span class="title-span fs-medium">
          <img
            src="../assets/back-arrow.png"
            @click="$router.go(-1)"
            style="cursor: pointer"
          />
          &nbsp; &nbsp; 單筆投放表現(Email)</span
        >
        <button class="showDetails-btn" @click="showDetailsTemp(this.htmlkey)">
          <img src="../assets/eye.svg" style="width: 1.5rem" class="mb-1" />
          查看Email資訊
        </button>
      </div>

      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12 mt-2 row-section">
          <div
            class="result-first-row d-flex justify-content-between"
            style="padding: 23px"
          >
            <div class="align-items-center d-flex" style="width: 33%">
              <div class="fs-small me-3" style="color: #c4c4c4">活動名稱</div>
              <div
                :class="
                  getLength(apiEmailRate.活動名稱) > 18 ? 'word-overflow' : ''
                "
                style="max-width: 240px"
                v-tooltip.bottom="
                  getLength(apiEmailRate.活動名稱) > 18
                    ? { value: apiEmailRate.活動名稱, class: 'tip-LabelManage' }
                    : { value: '', class: '' }
                "
              >
                <div style="font-size: 22px">
                  {{ apiEmailRate.活動名稱 }}
                </div>
                <div style="font-size: 12px; color: #c4c4c4">
                  {{ apiEmailRate.活動時間 }}
                </div>
              </div>
            </div>
            <div class="align-items-center d-flex" style="width: 33%">
              <div
                class="fs-small me-3"
                style="color: #c4c4c4; min-width: 75px"
              >
                模板名稱
              </div>
              <div
                :class="
                  getLength(apiEmailRate.模板名稱) > 18 ? 'word-overflow' : ''
                "
                style="max-width: 240px"
                v-tooltip.bottom="
                  getLength(apiEmailRate.模板名稱) > 18
                    ? { value: apiEmailRate.模板名稱, class: 'tip-LabelManage' }
                    : { value: '', class: '' }
                "
              >
                <div style="font-size: 22px">
                  {{ apiEmailRate.模板名稱 }}
                </div>
                <div style="font-size: 12px; color: #c4c4c4">
                  {{ apiEmailRate.發送日期 }}
                </div>
              </div>
            </div>
            <div class="align-items-center d-flex" style="width: 33%">
              <div
                class="fs-small me-3"
                style="color: #c4c4c4; min-width: 80px"
              >
                發送客群
              </div>
              <div>
                <div
                  class="fs-medium"
                  :class="
                    getLength(
                      apiEmailRate.發送客群.includeLabelNames.toString()
                    ) > 18
                      ? 'word-overflow'
                      : ''
                  "
                  style="max-width: 240px"
                  v-tooltip.bottom="
                    getLength(
                      apiEmailRate.發送客群.includeLabelNames.toString()
                    ) > 18
                      ? {
                          value: apiEmailRate.發送客群.includeLabelNames
                            .toString()
                            .replace(/,/g, '、'),
                          class: 'tip-LabelManage',
                        }
                      : { value: '', class: '' }
                  "
                  v-if="apiEmailRate.發送客群.includeLabelNames[0] != undefined"
                >
                  {{
                    apiEmailRate.發送客群.includeLabelNames
                      .toString()
                      .replace(/,/g, "、")
                  }}
                </div>
                <div
                  style="
                    font-size: 14px;
                    font-weight: 100;
                    color: #c4c4c4;
                    text-decoration: underline;
                    cursor: pointer;
                  "
                  @click="openExcludeModal()"
                  v-if="apiEmailRate.發送客群.excludeLabelNames[0] != undefined"
                >
                  瀏覽完整名單
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12">
          <div class="d-flex flex-wrap result-second-row">
            <div
              class="
                col-12
                d-flex
                align-items-center
                justify-content-between
                mb-4
              "
              style="color: #71afb6; font-size: 20px"
            >
              <span>投放成效</span>
              <button
                class="btn-basic bg-SkyBlue Cyan"
                style="width: 180px"
                @click="this.openTouchListModal(this.htmlkey, 'AB')"
              >
                查看互動名單
              </button>
            </div>
            <div class="col-xl-7 col-xxl-7 d-flex flex-wrap">
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">寄出數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.寄出數.toLocaleString() }}&nbsp;(次)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">送達數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.送達數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.送達率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">開信數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.開信數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.開信率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">點擊數</div>
                <div class="d-flex align-items-end flex-wrap">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.點擊數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.點擊率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">轉換數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.轉換數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.轉換率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">抱怨數</div>
                <div class="d-flex align-items-end flex-wrap">
                  <span class="m-0" style="font-size: 22px"
                    >{{ apiEmailRate.抱怨數.toLocaleString() }}&nbsp;(次)</span
                  >
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.抱怨率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">取消訂閱數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.取消訂閱數.toLocaleString() }}&nbsp;(次)
                  </span>
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.取消訂閱率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <div class="titleTag" style="color: #c4c4c4">未開信數</div>
                <div class="d-flex align-items-end">
                  <span class="m-0" style="font-size: 22px">
                    {{ apiEmailRate.未開信數.toLocaleString() }}&nbsp;(次)
                  </span>
                  <span class="ms-3" style="color: #71afb6; font-size: 14px">
                    ({{ apiEmailRate.未開信率.toFixed(2) }}%)
                  </span>
                </div>
              </div>
              <div class="col-4">
                <!-- <span class="titleTag" style="color: #c4c4c4">花費成本</span>
                <div class="d-flex align-items-center">
                  <span class="title is-5">{{ apiEmailRate.花費成本 }}</span>
                  <span class="ms-3">&nbsp; &nbsp;($)</span>
                </div> -->
              </div>
            </div>
            <div class="col-xl-5 col-xxl-5">
              <div class="text-center">僅開啟/點擊/轉換/未開啟</div>
              <apexchart
                class="mt-5"
                type="donut"
                height="300"
                :options="chartPieOptions"
                :series="seriesPie"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
      <!-- <button @click="DemoPieChart">123</button> -->
      <div class="d-flex justify-content-xl-center p-0 mt-4">
        <div class="col-12">
          <div class="row bar-div">
            <div class="d-flex align-items-center p-0">
              <p style="color: #71afb6">
                發送表現
                <BtnInfoBox
                  content="此為投放後所得到的成效時間點與其數量，輕鬆掌握發送後表現。"
                  direction="right"
                ></BtnInfoBox>
              </p>
            </div>
            <div class="p-0">
              <apexchart
                type="bar"
                height="300"
                :options="stackBarOptions"
                :series="stackBarSeries"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="result-second-row" style="height: auto; min-height: 200px">
        <div class="d-flex align-items-center p-0">
          <p style="color: #71afb6">連結點擊表現</p>
        </div>
        <div
          class="text-center p-0"
          style="color: #c4c4c4; line-height: 100px"
          v-if="linkTable[0] === undefined"
        >
          此投放的連結尚未被點擊
        </div>
        <div class="p-0" v-else>
          <DataTable :value="linkTable" showGridlines responsiveLayout="scroll">
            <Column field="連結" header="連結">
              <template #body="slotProps">
                <div>
                  <a
                    :href="slotProps.data.連結"
                    target="_blank"
                    style="word-break: break-all"
                    >{{ slotProps.data.連結 }}</a
                  >
                </div>
              </template>
            </Column>
            <Column
              field="不重複點擊次數"
              header="不重複點擊數"
              style="min-width: 120px"
            ></Column>
            <Column
              field="重複點擊次數"
              header="重複點擊數"
              style="min-width: 120px"
            ></Column>
            <Column field="互動名單" header="互動名單" style="min-width: 120px">
              <template #body="slotProps">
                <div
                  class="GrayC4"
                  style="text-decoration: underline; cursor: pointer"
                  :title="slotProps.data.連結"
                  @click="this.openTouchListModal(this.htmlkey, slotProps.data.連結)"
                >
                  查看
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="d-flex flex-wrap" v-if="this.apiTableData != ''">
        <div class="col-12">
          <SeeScript2
            v-bind:scriptID2="scriptID2"
            v-bind:emailChannel="emailChannel"
            v-bind:addadd2="addadd2"
            v-on:showUnlayer="showUnlayer"
            v-on:showSMSexp="showSMSexp"
            v-on:showEmailDetail="showEmailDetail"
            v-on:showOutsideExp="showOutsideExp"
            :key="forceRerender"
            v-bind:delivery="delivery"
          />
        </div>
      </div>
      <div class="container p-0">
        <div class="abtesting-div" v-if="this.hasAB != '無'">
          <div class="row">
            <div class="col-4">
              <span class="fs-small" style="color: #71afb6"> AB Testing </span>
              <span
                class="ms-3"
                style="color: #575757; font-size: 22px; font-weight: bold"
              >
                {{ apiABSendData.類別 }}
              </span>
              <div>
                <p class="mt-3 mb-0" style="color: #c4c4c4">AB組名單寄送比例</p>
                <apexchart
                  class="mt-3"
                  type="donut"
                  height="300"
                  :options="chartPieOptions4"
                  :series="seriesPie4"
                >
                </apexchart>
              </div>
            </div>
            <div class="col-8">
              <div class="col-12">
                <div class="ab-table-1">
                  <DataTable
                    :value="apiABSendData.ratio"
                    showGridlines
                    responsiveLayout="scroll"
                  >
                    <Column field="title" header="" style="min-width:120px"></Column>
                    <Column field="A組" header="A組">
                      <template #body="slotProps">
                        <div
                          class="GrayC4"
                          style="text-decoration: underline; cursor: pointer"
                          v-if="slotProps.data.title == '模板'"
                          @click="showDetailsTemp(slotProps.data.A組)"
                        >
                          A模板預覽
                        </div>
                        <div
                          class="GrayC4"
                          style="text-decoration: underline; cursor: pointer"
                          v-else-if="slotProps.data.title == '互動名單'"
                          @click="openTouchListModal(slotProps.data.A組, 'A')"
                        >
                          查看
                        </div>
                        <div v-else>
                          {{ slotProps.data.A組 }}
                        </div>
                      </template>
                    </Column>
                    <Column field="B組" header="B組">
                      <template #body="slotProps">
                        <div
                          class="GrayC4"
                          style="text-decoration: underline; cursor: pointer"
                          v-if="slotProps.data.title == '模板'"
                          @click="showDetailsTemp(slotProps.data.B組)"
                        >
                          B模板預覽
                        </div>
                        <div
                          class="GrayC4"
                          style="text-decoration: underline; cursor: pointer"
                          v-else-if="slotProps.data.title == '互動名單'"
                          @click="openTouchListModal(slotProps.data.A組, 'B')"
                        >
                          查看
                        </div>
                        <div v-else>
                          {{ slotProps.data.B組 }}
                        </div>
                      </template>
                    </Column>
                    <Column field="比較" header="比較">
                      <template #body="slotProps">
                        <div v-if="slotProps.data.比較 === null">-</div>
                        <div v-else-if="slotProps.data.title === '寄出數'">
                          -
                        </div>
                        <div v-else-if="slotProps.data.title === '送達數'">
                          -
                        </div>
                        <div
                          style="color: #ff8b8b"
                          v-else-if="slotProps.data.比較 > 0"
                        >
                          + {{ slotProps.data.比較 }} (%)
                        </div>
                        <div
                          style="color: #71afb6"
                          v-else-if="slotProps.data.比較 < 0"
                        >
                          {{ slotProps.data.比較 }} (%)
                        </div>
                        <div v-else-if="slotProps.data.比較 === 0">
                          {{ slotProps.data.比較 }} (%)
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button
          class="
            basic-btn
            highlight-btn
            d-flex
            align-items-center
            justify-content-center
          "
          style="width: 300px"
          @click="deletePerformanceApi"
        >
          <img src="../assets/delete.svg" alt="" class="me-3" />
          <span> 刪除此投放紀錄 </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
var popoverTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="popover"]')
);
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

// var popover = new bootstrap.Popover(document.querySelector('.example-popover'), {
//   container: 'body'
// })
// import Button from "vue-bootstrap";
import SeeScript2 from "../components/SeeScript2.vue";
import EmailDetail from "../components/EmailDetail.vue";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import VueApexCharts from "vue3-apexcharts";
import SmsPreview from "../components/SmsPreview.vue";
import TouchList from "../components/TouchList.vue";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from "../components/BtnInfoBox.vue";

export default {
  name: "ResultPerformance",
  components: {
    apexchart: VueApexCharts,
    DataTable,
    Column,
    SeeScript2,
    EmailDetail,
    SmsPreview,
    TouchList,
    EmailPreview,
    BtnInfoBox,
  },
  data() {
    return {
      seeAB: "",
      touch_key: "",
      topChannel: "email",

      loading: false,
      series: [],
      seriesPie: [],
      chartPieOptions: {
        chart: {
          height: 200,
          type: "donut",
        },
        colors: ["#EAF3F4", "#71AFB6", "#F9C357", "#FC9F66"],
        labels: ["未寄出", "未打開", "打開", "點擊"],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: "bold",
          fontSize: "16px",

          //  floating: true,
        },
      },
      seriesPie4: [],
      chartPieOptions4: {},

      stackBarSeries: [],
      stackBarOptions: {
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },

      rateData: {},
      midPieData: [],
      botLeftPie: [],
      botRightPie: [],

      userID: "123",

      apiEmailRate: {
        取消訂閱數: 0,
        取消訂閱率: 0,
        寄出數: 0,
        抱怨數: 0,
        抱怨率: 0,
        未開信數: 0,
        未開信率: 0,
        模板名稱: "",
        活動名稱: "",
        活動時間: "",
        發送客群: { excludeLabelNames: [], includeLabelNames: [] },
        發送日期: "",
        轉換數: 0,
        轉換率: 0,
        送達數: 0,
        送達率: 0,
        開信數: 0,
        開信率: 0,
        點擊數: 0,
        點擊率: 0,
      },
      apiEmailPie: [],
      apiEmailPurchase: [],
      apiEmailTrend: [],

      RecPuchase: [],
      unRecPuchase: [],

      Purchase: [],
      unPurchase: [],

      linkTable: [],
      apiTableData: "",

      apiABAllData: [
        {
          brand: [1],
          model: [2],
        },
        {},
        {},
      ],
      apiABSendData: {
        AB: {
          AB_proportion: [],
        },
      },
      apiOpenEmail: "",
      isShow: false,
      ABShow: false,
      saveEventName: "",
      saveEventTable: "",
      scheTodos: "",

      templateA: {},
      templateB: {},

      url: "",
      url2: "",
      url3: "",
      url4: "",

      findScriptName: {
        template_id: {
          A: "",
          B: "",
        },
      },

      url8081: "",
      htmlkey: "",
      html: "",
      subject: "",
      top_subject: "",

      nowAction: true,
      emailChannel: true,
      forceRerender: 0,
      delivery: 0,
      scriptID2: 0,
      addadd2: 0,
      events: [],
      hasAB: "",
      has_script: 0,
      vice_ruleID: "",

      deleteSuccessModal: {},
      fullurlArr: [],

      resSubsec: "",
      todotext: "",
      countText: "",
      exampleModal2: {},
      Parr: [],
    };
  },

  created: async function () {
    this.loading = true;
    this.has_script = sessionStorage.getItem("has_script");
    this.hasAB = sessionStorage.getItem("hasAB");
    this.rule_id = sessionStorage.getItem("rule_id");
    this.htmlkey = sessionStorage.getItem("template_id");

    await this.getCalenEvents();

    console.log(this.rule_id);
    if (this.rule_id === undefined) {
      this.userID = "123";
    } else {
      this.userID = this.rule_id;
      await Promise.all([
        this.postEmailRate(),
        this.postEmailPie(),
        this.postEmailTrend(),
        this.postLinkData(),
      ]);
      if (this.hasAB === "有") {
        await this.postABSend();
      }
      if (this.has_script === "3" || this.has_script === "2") {
        await this.postEmailTable();
      }

      this.DemoPieChart();
      this.DemoPieChart4();
      this.stackBarChart();
      // this.getTemplateApi();
      this.loading = false;
    }
    this.getHtmlById(this.htmlkey);
    this.getScheAPi();
    const group = sessionStorage.getItem("getlabelTitle");
    const token = sessionStorage.getItem("access_token");
    const aa = undefined;
    const bb = undefined;
    this.url8081 = `http://localhost:8081/?name=${aa}&key=${bb}&token=${token}&group=${group}`;

    this.scriptID2 = this.rule_id;

    // this.getRate();
  },
  methods: {
    // 計算中英文字元
    getLength(str) {
      return str.replace(/[\u4E00-\u9FFF]/g, "OO").length;
    },
    openTouchListModal(key, seeAB) {
      this.touch_key = key;
      this.seeAB = seeAB;
      const TouchListModal = new bootstrap.Modal(
        document.getElementById("TouchListModal"),
        {
          keyboard: false,
        }
      );
      TouchListModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    openExcludeModal() {
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    showUnlayer: function (childHtml, childSub) {
      this.html = childHtml;
      this.subject = childSub;
      this.changeAllUrl();

      this.exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      this.exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    showSMSexp: function (childValue) {
      this.todotext = childValue;
      this.resSubsec = childValue;
      this.highlightUrl();
      this.exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    highlightUrl() {
      console.log(this.todotext);
      console.log(this.resSubsec);
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.todotext);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index].slice(0, -9) +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
      this.countText = this.todotext
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      this.resSubsec = this.resSubsec
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");

      console.log("this.countText", this.countText);
    },
    deletePerformanceApi: async function () {
      var yes = confirm("確定刪除嗎?");

      if (yes) {
        const token = sessionStorage.getItem("access_token");

        const params = {
          Authorization: "Bearer " + token,
          script_id: this.rule_id,
        };
        await allapi3
          .overallDeleteScriptIdGet(params)
          .then((res) => {
            console.log("delete 排程成效", res);
            this.deleteSuccessModal = new bootstrap.Modal(
              document.getElementById("deleteSuccessModal"),
              {
                keyboard: false,
              }
            );
            this.deleteSuccessModal.show();
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("delete連線有誤" + err);
            this.loading = false;
          });
      } else {
        console.log("不刪除");
      }
    },
    deleteSuccess() {
      this.deleteSuccessModal.hide();
      this.$router.push({
        name: "EmailMarketingTable",
      });
    },
    test(d) {
      console.log("rowData", d);
      sessionStorage.setItem("vice_ruleID", d.vice_ruleID);
      sessionStorage.setItem("eventTable", d.事件名稱);
      const newjson = JSON.stringify(this.apiEmailRate.發送客群);
      sessionStorage.setItem("labelFromPerform", newjson);
      this.$router.push({
        name: "EventPerformance",
      });
    },
    getScheAPi: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .emailTableGet(params)
        .then((res) => {
          console.log("3 item", res);
          this.scheTodos = res.data.Data;
          const todo = this.scheTodos.find((d) => d.ruleID === this.rule_id);
          console.log("efe", todo);
          this.findScriptName = todo;
          sessionStorage.setItem("setResEventTitle", todo.模板名稱);
          this.addadd2 = 1;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    getHtmlById: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: key,
      };

      await allapi2
        .templatev2HtmlKeyGet(params)
        .then((res) => {
          console.log(res);
          this.html = res.data.content;
          this.html = this.html
            .replace(
              "{{p1}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
            )
            .replace(
              "{{p2}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
            )
            .replace(
              "{{p3}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
            )
            .replace(
              "{{p4}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
            )
            .replace("{{tracking}}", "");

          // console.log(this.html);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 從預覽中顯示外層
    showOutsideExp() {
      this.subject = this.top_subject;
      this.showDetailsTemp(this.htmlkey);
    },

    showDetailsTemp: async function (key) {
      await this.getHtmlById(key);
      this.changeAllUrl();
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    showEmailDetail(key, action) {
      console.log(key, action);
      this.vice_ruleID = key;
      console.log("this.vice_ruleID", this.vice_ruleID);
      if (action == "傳送Email") {
        this.nowAction = true;
      } else {
        this.nowAction = false;
      }
      const EmailDetail = new bootstrap.Modal(
        document.getElementById("EmailDetail"),
        {
          keyboard: false,
        }
      );
      EmailDetail.show();
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.html);
      console.log(this.fullurlArr);
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.html = this.html.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },

    postEmailRate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      allapi3
        .emailIndividualRateGet(params)
        .then((res) => {
          console.log("投放成效", res);
          this.apiEmailRate = res.data.Data;
          if (this.apiEmailRate.送達數 === undefined) {
            alert("成效建立中，請稍等５分鐘");
            this.$router.push({
              name: "EmailMarketingTable",
            });
          }
          this.delivery = res.data.Data.送達數;
          this.subject = res.data.Data.主旨;
          this.top_subject = res.data.Data.主旨;
          const d = this.events.find(
            (d) => d.id === this.apiEmailRate.活動名稱
          );
          if (d != undefined) {
            this.apiEmailRate.活動名稱 = d.title;
            this.apiEmailRate.活動時間 = `${d.start} - ${d.end}`;
          }
          this.saveEventName = res.data.Data.活動名稱;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    postEmailPie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .emailIndividualPieGet(params)
        .then((res) => {
          console.log("emailpie", res);
          res.data.Data = {
            僅開啟: res.data.Data.開啟,
            點擊: res.data.Data.點擊,
            轉換: res.data.Data.轉換,
            未開啟: res.data.Data.未開啟,
          };
          this.apiEmailPie = res.data.Data;
          // console.log("mailpie", this.apiEmailPie.未寄出);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postEmailTrend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .emailIndividualTrendGet(params)
        .then((res) => {
          console.log("emailTrend", res);
          this.apiEmailTrend = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postEmailTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .emailIndividualEventGet(params)
        .then((res) => {
          console.log("tabledata", res);
          this.apiTableData = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postABSend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .emailIndividualAbsendGet(params)
        .then((res) => {
          console.log("absend", res);
          res.data.Data.ratio.push({
            A組: this.htmlkey,
            B組: this.htmlkey + "_b",
            title: "互動名單",
            比較: null,
          });
          this.apiABSendData = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postLinkData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.userID,
      };
      await allapi3
        .emailIndividualUrlGet(params)
        .then((res) => {
          console.log("IndividualUrlGet", res);
          this.linkTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    DemoPieChart() {
      // console.log("pieipe", Object.values(this.apiEmailPie));
      (this.seriesPie = Object.values(this.apiEmailPie).map((d) => {
        return parseInt(d);
      })),
        (this.chartPieOptions = {
          chart: {
            height: 300,
            type: "donut",
          },
          colors: ["#96C5D7", "#F9C357", "#FC9F66", "#ABABAB"],
          labels: Object.keys(this.apiEmailPie),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //floating: true,
          },
        });
    },
    DemoPieChart4() {
      (this.seriesPie4 = this.apiABSendData.AB.AB_proportion),
        (this.chartPieOptions4 = {
          chart: {
            height: 280,
            type: "donut",
          },
          colors: ["#71AFB6", "#EAF3F4"],
          labels: ["A組", "B組"],
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //  floating: true,
          },
        });
    },

    stackBarChart() {
      (this.stackBarSeries = [
        {
          name: "轉換次數",
          data: this.apiEmailTrend.purchase,
        },
        {
          name: "點擊次數",
          data: this.apiEmailTrend.click,
        },
        {
          name: "開信次數",
          data: this.apiEmailTrend.open,
        },
      ]),
        (this.stackBarOptions = {
          chart: {
            type: "bar",
            height: 300,
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
            zoom: {
              enabled: true,
            },
          },

          // responsive: [
          //   {
          //     // breakpoint: 480,
          //     options: {
          //       legend: {
          //         position: "bottom",
          //         offsetX: -10,
          //         offsetY: 0,
          //       },
          //     },
          //   },
          // ],
          colors: ["#FC9F66", "#F9C357", "#96C5D7"],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {
            // type: "datetime",
            categories: this.apiEmailTrend.time,
            title: {
              text: "日期",
            },
          },
          yaxis: {
            title: {
              text: "次數",
              style: {
                cssClass: "apexchart-label",
              },
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toFixed(0);
              },
            },
          },
          tooltip: {
            y: {
              formatter: (y) => {
                return y.toFixed(0);
              },
            },
          },
          legend: {
            position: "bottom",
            offsetY: 3,
            offsetX: -10,
          },
          fill: {
            opacity: 1,
          },
        });
    },
  },
};
</script>

<style scoped>
:deep(.p-datatable-responsive-scroll > .p-datatable-wrapper) {
  overflow: visible !important;
  overflow-x: visible !important;
}
:deep(.p-datatable table) {
  /* border-radius: 15px;
  border-collapse: separate;
  border-spacing: 1px;
  border: 1px solid #dee2e6; */
  border-collapse: collapse;
  border-radius: 15px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #575757; /* this draws the table border  */
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th) {
  border-width: 1px 1px 1px 1px;
}
:deep(th:last-child) {
  border-top-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  transition: box-shadow 0.2s;
}
:deep(.p-datatable-wrapper) {
  box-shadow: none;
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td) {
  border-width: 1px 1px 1px 1px;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: center;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 5px;
  line-height: 45px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td:last-child) {
  border-bottom-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td:first-child) {
  border-bottom-left-radius: 15px;
}
:deep(.p-datatable .p-datatable-tbody > tr:last-child > td) {
  border-bottom: 1px solid #dee2e6 !important;
}

.highlight-btn {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}

.btn:focus,
.btn:active {
  /* outline: none !important; */
  box-shadow: none;
}

.loading-background {
  position: fixed;
  left: 15%;
  top: 0;
  width: 85%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding: 50px;
}
.titleTag {
  margin-bottom: 20px;
}

.result-first-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 5px;
}

.result-second-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-top: 25px;
  padding: 15px 20px 24px;
  border-radius: 15px;
}

.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  /* margin-top: 15px; */
  height: 380px;
}

.abtesting-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: auto;
}

.ab-title {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 56px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.apexchart-label {
  /* -webkit-writing-mode: vertical-lr;*/
  /* writing-mode: vertical-lr ;  */
  font-size: 14px;
  padding-right: 30px;
}

.parent-popout {
  position: relative;
}

.child-popout {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 300px;
  right: -315px;
  top: -42px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}

.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}

.child-popout10 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 286px;
  right: -300px;
  top: -42px;
  color: #fff;
  text-align: left;
  font-size: 14px;
  padding: 10px;
}

.child-popout11 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 286px;
  right: -300px;
  top: -42px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}

.showDetails-btn {
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}

/* @media (max-width: 1400px) {
  .fs-small {
    font-size: 15px;
  }
  .fs-medium {
    font-size: 20px;
  }
} */
</style>
