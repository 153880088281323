<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <p>
              <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
            </p>
            <p class="mb-5" style="margin-left: 36px; word-break: break-all">
              {{
                this.label_names.includeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
            <p>
              <img
                src="../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
            </p>
            <p style="margin-left: 36px">
              {{
                this.label_names.excludeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
          </div>
          <!-- <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div> -->
        </div>
      </div>
    </div>
    <!-- main -->

    <div class="col-12">
      <span class="title-span fs-medium mb-5">投放活動紀錄一覽表 (Email)</span>
      <DataTable
        id="emailmarketingtable"
        :value="searchTable"
        class="header-table mt-3"
        showGridlines
        :paginator="true"
        :rows="10"
        paginatorPosition="top"
        :pageLinkSize="3"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="目前 {currentPage} ，共 {totalPages} 頁"
        style="text-align: center"
      >
        <template #paginatorstart>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-6" style="position: relative">
              <img
                style="position: absolute; top: 8px; left: 10px"
                src="../assets/search-black.png"
                alt="searchImg"
              />
              <input
                style="
                  padding-left: 35px;
                  width: 100%;
                  height: 35px;
                  border-radius: 10px;
                  border: 1px solid #575757;
                "
                v-model="searchInput"
                placeholder="搜尋名稱"
              />
            </div>
            <div class="search-container col-5" ref="target">
              <div
                class="d-flex justify-content-between align-items-center"
                style="
                  width: 350px;
                  border: 1px solid #c4c4c4;
                  border-radius: 10px;
                  padding: 0 14px;
                  height: 35px;
                "
                @click="autoShow = !autoShow"
              >
                {{ triggerType }}
                <div
                  class="d-flex align-items-center"
                  style="width: 50px"
                  :class="
                    this.filterCount === 0
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  "
                >
                  <div
                    style="
                      position: relative;
                      background-color: #71afb6;
                      border-radius: 10px;
                      width: 20px;
                      height: 20px;
                    "
                    v-if="this.filterCount != 0"
                  >
                    <span
                      style="
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        top: 0px;
                        left: 5.5px;
                      "
                    >
                      {{ filterCount }}
                    </span>
                  </div>
                  <img
                    style="width: 10px; height: 8px"
                    src="../assets/selection-arrow.png"
                    alt="searchImg"
                  />
                </div>
              </div>
              <div
                class="auto-complicate-div text-start"
                style="width: 350px"
                v-show="autoShow"
                @mouseenter="autoShow = true"
              >
                <p class="m-0 p-1 ps-3 my-2">AB Testing</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div class="d-flex flex-wrap">
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="AB無"
                      id="AB無"
                      value="null"
                      v-model="checkedNames"
                    />
                    <label for="AB無" style="width: 83%">無</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="AB主旨調整"
                      id="AB主旨調整"
                      value="主旨調整"
                      v-model="checkedNames"
                    />
                    <label for="AB主旨調整" style="width: 83%">主旨調整</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="AB內容調整"
                      id="AB內容調整"
                      value="內容調整"
                      v-model="checkedNames"
                    />
                    <label for="AB內容調整" style="width: 83%">內容調整</label>
                  </p>
                </div>
                <p class="m-0 p-1 ps-3 my-2">自動回應</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div class="d-flex flex-wrap">
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="無"
                      id="無"
                      value="無"
                      v-model="checkedNames2"
                    />
                    <label for="無" style="width: 83%">無</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="執行中"
                      id="執行中"
                      value="執行中"
                      v-model="checkedNames2"
                    />
                    <label for="執行中" style="width: 83%">執行中</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="已完成"
                      id="已完成"
                      value="已完成"
                      v-model="checkedNames2"
                    />
                    <label for="已完成" style="width: 83%">已完成</label>
                  </p>
                </div>
                <p class="m-0 p-1 ps-3 my-2">活動時間</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div
                  class="
                    m-0
                    p-2
                    ps-3
                    auto-complicate-p
                    d-flex
                    align-items-center
                  "
                >
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="dateRange"
                    id="dateRange"
                    :value="0"
                    v-model="checkedNames3"
                  />
                  <div style="width: 90%; position: relative">
                    <DatePicker v-model="filterDateRange" is-range>
                      <!-- @dayclick="updateRangeday(index)" -->
                      <template v-slot="{ togglePopover }">
                        <input
                          id="dropdown-moreoption-rangeinput"
                          class="ps-5"
                          style="
                            cursor: default;
                            width: 100%;
                            height: 40px;
                            border-radius: 10px;
                            border: 1px solid #c4c4c4;
                          "
                          placeholder="選擇日期"
                          :value="time_range"
                          @click="togglePopover()"
                          readonly
                        />
                        <img
                          src="../assets/date.svg"
                          alt=""
                          style="position: absolute; left: 10px; top: 10px"
                        />
                        <img
                          src="../assets/selection-arrow.png"
                          alt="searchImg"
                          style="position: absolute; right: 10px; top: 16px"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <Column field="活動名稱" header="活動名稱" :sortable="true">
          <template #body="slotProps2">
            <div style="padding: 5px 0">
              <div class="too-over-div" v-tooltip.bottom="slotProps2.data.活動名稱?.length > 6 ? { value: slotProps2.data.活動名稱, class: 'tip-LabelManage'} : { value: '', class: ''}">
                {{ slotProps2.data.活動名稱 }}
              </div>
              <div style="color: #c4c4c4; font-size: 12px">
                {{ slotProps2.data.活動時間 }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="模板名稱" header="模板名稱" :sortable="true">
          <template #body="slotProps2">
            <div style="padding: 5px 0">
              <div class="too-over-div" v-tooltip.bottom="slotProps2.data.模板名稱?.length > 6 ? { value: slotProps2.data.模板名稱, class: 'tip-LabelManage'} : { value: '', class: ''}">
                {{ slotProps2.data.模板名稱 }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="客群名稱" header="選擇客群" :sortable="true">
          <template #body="slotProps2">
            <div class="" v-tooltip.bottom="slotProps2.data.客群名稱.includeLabelNames.toString()?.length > 6 ? { value: slotProps2.data.客群名稱.includeLabelNames.toString().replace(/,/g, '、'), class: 'tip-LabelManage'} : { value: '', class: ''}">
              <p class="m-0 too-over-div">
                {{
                  slotProps2.data.客群名稱.includeLabelNames
                    .toString()
                    .replace(/,/g, "、")
                }}
              </p>
              <p
                class="m-0"
                style="
                  font-size: 14px;
                  font-weight: 100;
                  color: #c4c4c4;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="openExcludeModal(slotProps2.data.客群名稱)"
                v-if="
                  slotProps2.data.客群名稱.excludeLabelNames[0] != undefined
                "
              >
                瀏覽完整名單
              </p>
            </div>
          </template>
        </Column>
        <Column field="發送時間" header="投放時間" :sortable="true">
          <template #body="slotProps2">
            <div>
              {{
                slotProps2.data.發送時間.slice(
                  0,
                  slotProps2.data.發送時間.length - 9
                )
              }}
            </div>
            <div style="color: #c4c4c4; font-size: 12px">
              {{ slotProps2.data.發送時間.slice(-8).slice(0, 5) }}
            </div>
          </template>
        </Column>
        <Column field="發送數量" header="傳送數量" :sortable="true">
          <template #body="slotProps2">
            <div class="too-over-div" v-if="slotProps2.data.發送數量 === 0">
              傳送中
            </div>
            <div class="too-over-div" v-else>
              {{ slotProps2.data.發送數量.toLocaleString() }}
            </div>
          </template>
        </Column>
        <Column field="開信率" header="開信率" :sortable="true"></Column>
        <Column field="點擊率" header="點擊率" :sortable="true"></Column>
        <Column field="數據查看" header="數據查看">
          <template #body="slotProps">
            <a
              type="button"
              label="Connect"
              class="p-button-raised p-button-secondary more-info"
              @click="goResult(slotProps.data)"
              v-if="slotProps.data.發送數量 != 0"
            >
              more info
            </a>
            <a
              type="button"
              label="Connect"
              style="color: #c4c4c4; cursor: default"
              class="p-button-raised p-button-secondary more-info"
              v-else
            >
              more info
            </a>
          </template>
        </Column>
      </DataTable>
      <div
        class="text-center mt-4"
        style="color: #c4c4c4"
        v-if="this.searchTable[0] === undefined"
      >
        目前無投放紀錄
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import moment from "moment";

export default {
  name: "EmailMarketingTable",
  components: {
    DataTable,
    Column,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return { target, autoShow };
  },
  data() {
    return {
      notshow: false,
      searchInput: "",
      triggerType: "篩選",
      filterDateRange: {},
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      time_range: "",

      testList: ["123", "456"],
      loading: true,
      // primevue table data exp
      userID: "",
      event: "",
      loopData: {
        label: "",
      },
      tableData: [],

      table: [
        {
          brand: "Volkswagen",
          year: 2018,
          model: "Golf",
          housepower: "340",
          aaa: "a",
        },
        {
          brand: "Audi",
          year: 2020,
          model: "RS7",
          housepower: "620",
          aaa: "a",
        },
        {
          brand: "Nissan",
          year: 2017,
          model: "GTR",
          housepower: "560",
          aaa: "a",
        },
      ],
      events: [],
      searchTable: [],
      checkedNames: [],
      checkedNames2: [],
      checkedNames3: [],

      label_names: {
        includeLabelNames: [],
        excludeLabelNames: [],
      },
    };
  },
  watch: {
    checkedNames(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTable = this.table;
        return;
      }
      this.searchTable = this.table.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    checkedNames2(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTable = this.table;
        return;
      }
      this.searchTable = this.table.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    checkedNames3(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTable = this.table;
        return;
      }
      this.searchTable = this.table.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    searchInput(val) {
      this.searchTable = this.table.filter((d) => {
        return d.模板名稱.includes(val) || d.活動名稱.includes(val);
      });
    },
    filterDateRange() {
      this.updateRange();
      if (this.checkedNames3[0] != undefined) {
        this.searchTable = this.table.filter((d) => {
          return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
        });
      }
    },
  },
  computed: {
    filterCount() {
      return (
        this.checkedNames.length +
        this.checkedNames2.length +
        this.checkedNames3.length
      );
    },
  },

  created: async function () {
    // http://localhost:8080 test

    await this.getCalenEvents();
    this.getTable();
    this.loading = false;
  },
  methods: {
    updateRange() {
      console.log(this.filterDateRange);
      console.log(this.filterDateRange.start.toISOString());
      this.time_range =
        this.filterDateRange.start.toISOString().slice(0, 10) +
        "~" +
        this.filterDateRange.end.toISOString().slice(0, 10);
    },
    openExcludeModal(labelNames) {
      this.label_names = labelNames;
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    // stockClass(data) {
    //   console.log(data);
    //   return [
    //     {
    //       noevent: data.事件 === 2,
    //       hasevent: data.事件 === 0,
    //     },
    //   ];
    // },
    goResult(data) {
      console.log(data);
      sessionStorage.setItem("has_script", data.事件);
      sessionStorage.setItem("hasAB", data.AB);
      sessionStorage.setItem("rule_id", data.ruleID);
      sessionStorage.setItem("template_id", data.template_id);
      this.$router.push({
        name: "ResultPerformance",
      });
    },
    getTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .emailTableGet(params)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.Data;
          const arrayData = res.data.Data;
          arrayData.forEach((d) => {
            d.發送時間 = new Date(
              moment(d.發送時間).add(-8, "h")
            ).toLocaleString("zh-cn");
            d.開信率 = d.開信率.toFixed(0) + "%";
            d.點擊率 = d.點擊率.toFixed(0) + "%";
            if (d.AB === 0) {
              d.AB = "有";
            } else {
              d.AB = "無";
            }
            const data = this.events.find((e) => e.id === d.活動名稱 );
            if (data != undefined) {
              d.活動名稱 = data.title;
              d.活動時間 = `${data.start} - ${data.end}`;
            }
            if(d.事件==2){
              d.script_state='執行中'
            }else if(d.事件==3){
              d.script_state='已完成'
            }else if(d.事件==0){
              d.script_state='無'
            }
          });
          arrayData.sort((a, b) => {
            if (new Date(b.發送時間) >new Date(a.發送時間)) {
              return 1;
            } else {
              return -1;
            }
          });
          console.log("change number", arrayData);
          this.table = arrayData;
          this.searchTable = arrayData;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
  },
};
</script>

<style >
/* emailMarketingtable */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.p-datatable-table > .p-datatable-thead {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
th:first-child {
  border-top-left-radius: 15px;
}
.p-datatable th:last-child {
  border-top-right-radius: 15px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  background-color: #fff;
}
.p-column-header-content {
  /* padding:30px 0 */
  justify-content: center;
}
.p-datatable-wrapper {
  overflow-x: auto;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.p-datatable .p-datatable-tbody > tr > td {
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dee2e6 !important;
  /* text-align: center !important; */
}
.p-datatable .p-datatable-tbody > tr:last-child > td {
  border-bottom: none !important;
}
.p-datatable .p-datatable-tbody > tr > td:first-child {
  padding: 0px 10px !important;
}
#emailmarketingtable.p-datatable .p-datatable-tbody > tr > td {
  padding: 0 10px 0 20px !important;
}
#emailmarketingtable.p-datatable .p-datatable-thead > tr > th {
  padding: 0 10px 0 20px !important;
}
#emailmarketingtable.p-datatable
  .p-datatable-tbody
  > tr
  > td:nth-child(-n + 4) {
  text-align: left;
}
#emailmarketingtable.p-datatable
  .p-datatable-thead
  > tr
  > th:nth-child(-n + 4)
  .p-column-header-content {
  justify-content: flex-start;
}

.hasevent {
  background-color: #eaf3f4;
  text-align: center;
  height: 58px;
  line-height: 58px;
}
.noevent {
  background-color: #ffedc9;
  text-align: center;
  height: 58px;
  line-height: 58px;
}
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  padding: 50px;
}
.more-info {
  color: #71afb6;
  text-decoration: none;
}
.too-over-div {
  margin: 0 auto 0 0;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
input[type="checkbox"]:checked {
  background: #575757;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 16px;
  width: 16px;
}
input[type="checkbox"] {
  border: 1px solid #575757;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 16px;
  width: 16px;
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>
