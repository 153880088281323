<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能為正式付費使用 </span>
  </div>
  <div class="container home" v-else>
    <!-- toast -->
    <div class="position-fixed" style="z-index: 11; top: 80px; right: 32%">
      <div
        id="liveToast"
        class="toast"
        style="width: 282px; box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-bs-delay="3000"
      >
        <div class="d-flex">
          <div
            class="d-flex justify-content-between align-items-center flex-fill"
            style="padding: 15px 20px; background-color: #76d37a"
          >
            <div class="text-center flex-fill" style="color: white">
              啟用成功!
            </div>
            <button
              style="border: none; background-color: #76d37a"
              class="d-flex justify-content-center align-items-center"
              data-bs-dismiss="toast"
              aria-label="Close"
            >
              <img
                src="../assets/whiteX.svg"
                alt=""
                style="width: 12px; position: relative; top: -1px"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <p>
              <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
            </p>
            <p class="mb-5" style="margin-left: 36px; word-break: break-all">
              {{
                this.resGroup.includeLabelNames.toString().replace(/,/g, "、")
              }}
            </p>
            <p>
              <img
                src="../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
            </p>
            <p style="margin-left: 36px">
              {{
                this.resGroup.excludeLabelNames.toString().replace(/,/g, "、")
              }}
            </p>
          </div>
          <!-- <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div> -->
        </div>
      </div>
    </div>

    <!-- unlayerModal -->
    <!-- expEmailModal -->
    <EmailPreview :subject="subject" :html="html"></EmailPreview>
    
    <!-- sms exp modal -->
    <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>

    <!-- exptriggerModal -->
    <div
      class="modal fade"
      id="triggerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" style="width: 100%">
          <SeeTriggerScript
            v-bind:scriptID="scriptID"
            v-bind:groupName="groupName"
            v-bind:addadd="addadd"
            v-on:showUnlayer="showUnlayer"
            v-on:showSMSexp="showSMSexp"
            :key="forceRerender"
          />
        </div>
      </div>
    </div>
    <!-- expscriptModal -->
    <div
      class="modal fade"
      id="scriptModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" style="width: 100%">
          <SeeScript
            v-bind:scriptID2="scriptID2"
            v-bind:emailChannel="emailChannel"
            v-bind:addadd2="addadd2"
            v-on:showUnlayer="showUnlayer"
            v-on:showSMSexp="showSMSexp"
            :key="forceRerender"
          />
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="col-12">
      <div class="">
        <span class="title-span fs-medium"> 劇本一覽表</span>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-4">
        <div class="col-9 d-flex justify-content-start align-items-center">
          <div class="col-5" style="position: relative">
            <img
              style="position: absolute; top: 8px; left: 10px"
              src="../assets/search-black.png"
              alt="searchImg"
            />
            <input
              style="
                padding-left: 35px;
                width: 100%;
                height: 35px;
                border-radius: 10px;
                border: 1px solid #575757;
              "
              v-model="searchInput"
              placeholder="搜尋名稱"
            />
          </div>
          <div class="search-container col-7 ms-3" ref="target">
            <div
              class="d-flex justify-content-between align-items-center"
              style="
                width: 350px;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                padding: 0 14px;
                height: 35px;
              "
              @click="autoShow = !autoShow"
            >
              {{ triggerType }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>
            <div
              class="auto-complicate-div"
              style="width: 350px"
              v-show="autoShow"
              @mouseenter="autoShow = true"
            >
              <p class="m-0 p-1 ps-3 my-2">觸發事件</p>
              <hr
                class="m-0"
                style="
                  width: 100%;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div class="d-flex flex-wrap">
                <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="註冊"
                    id="註冊"
                    value="註冊"
                    v-model="checkedNames2"
                  />
                  <label for="註冊" style="width: 83%">註冊</label>
                </p>
                <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="訂單確認"
                    id="訂單確認"
                    value="訂單確認"
                    v-model="checkedNames2"
                  />
                  <label for="訂單確認" style="width: 83%">訂單確認</label>
                </p>
                <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="購物車未結"
                    id="購物車未結"
                    value="購物車未結"
                    v-model="checkedNames2"
                  />
                  <label for="購物車未結" style="width: 83%">購物車未結</label>
                </p>
                <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="定期投放"
                    id="定期投放"
                    value="定期投放"
                    v-model="checkedNames2"
                  />
                  <label for="定期投放" style="width: 83%">定期投放</label>
                </p>
                <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="購買後促銷"
                    id="購買後促銷"
                    value="購買後促銷"
                    v-model="checkedNames2"
                  />
                  <label for="購買後促銷" style="width: 83%">購物後促銷</label>
                </p>
              </div>
              <p class="m-0 p-1 ps-3 my-2">活動時間</p>
              <hr
                class="m-0"
                style="
                  width: 100%;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div
                class="m-0 p-2 ps-3 auto-complicate-p d-flex align-items-center"
              >
                <input
                  class="me-2"
                  style="position: relative; top: 1px"
                  type="checkbox"
                  name="dateRange"
                  id="dateRange"
                  :value="0"
                  v-model="checkedNames3"
                />
                <div style="width: 90%; position: relative">
                  <DatePicker v-model="filterDateRange" is-range>
                    <!-- @dayclick="updateRangeday(index)" -->
                    <template v-slot="{ togglePopover }">
                      <input
                        id="dropdown-moreoption-rangeinput"
                        class="ps-5"
                        style="
                          cursor: default;
                          width: 100%;
                          height: 40px;
                          border-radius: 10px;
                          border: 1px solid #c4c4c4;
                        "
                        placeholder="選擇日期"
                        :value="time_range"
                        @click="togglePopover()"
                        readonly
                      />
                      <img
                        src="../assets/date.svg"
                        alt=""
                        style="position: absolute; left: 10px; top: 10px"
                      />
                      <img
                        src="../assets/selection-arrow.png"
                        alt="searchImg"
                        style="position: absolute; right: 10px; top: 16px"
                      />
                    </template>
                  </DatePicker>
                </div>
              </div>
              <div
                class="m-0 p-2 ps-3 auto-complicate-p d-flex align-items-center"
              >
                <input
                  class="me-2"
                  style="position: relative; top: 1px"
                  type="checkbox"
                  name="永久"
                  id="永久"
                  :value="1"
                  v-model="checkedNames4"
                />
                <label for="永久" style="width: 90%">永久</label>
              </div>
            </div>
          </div>
        </div>

        <div
          id="routerlink"
          v-if="this.tabSwitch === '觸發劇本'"
          class="showDetails-btn text-center"
          style="width: 200px; height: 40px; line-height: 40px; padding: 0"
        >
          <router-link
            to="/TriggerScript"
            style="color: #fff; text-decoration: none"
            @click="goCreateScript()"
          >
            <img
              src="../assets/whitePlus.svg"
              alt=""
              style="position: relative; top: -1.65px"
            />
            建立自動化劇本
          </router-link>
        </div>
        <div style="width: 265px" class="d-flex justify-content-between" v-else>
          <button
            @click="goEmailMark"
            class="showDetails-btn text-center"
            style="
              text-decoration: none;
              width: 120px;
              height: 40px;
              line-height: 40px;
              padding: 0;
            "
          >
            <img src="../assets/whitePlus.svg" alt="" class="mb-1" /> Email
          </button>
          <button
            @click="goSmsMark"
            class="text-center"
            style="
              border: none;
              border-radius: 10px;
              background-color: #eaf3f4;
              width: 120px;
              height: 40px;
              line-height: 40px;
              padding: 0;
            "
          >
            <img src="../assets/blackPlus.svg" alt="" class="mb-1" /> SMS
          </button>
        </div>
      </div>
      <hr
        style="
          width: 100%;
          margin: 25px 0;
          opacity: 1;
          height: 0;
          border: 0;
          border-bottom: 1px solid #c4c4c4;
        "
      />
      <div class="mt-4 scriptTable-paginator" style="position: relative">
        <div>
          <DataTable
            :value="this.selectedTable"
            :paginator="true"
            :rows="10"
            paginatorPosition="top"
            :pageLinkSize="3"
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate="目前 {currentPage} ，共 {totalPages} 頁"
            :style="{ textAlign: 'center' }"
          >
            <template #paginatorstart>
              <div
                style="
                  background-color: #eaf3f4;
                  border-radius: 10px;
                  width: 400px;
                "
                class="d-flex"
              >
                <button
                  class="basic-btn"
                  :class="
                    this.script_state === 'notworkData' ? 'highlight-btn' : ''
                  "
                  style="color: #575757"
                  @click="changeTable('notworkData')"
                >
                  草稿
                </button>
                <button
                  class="basic-btn"
                  :class="
                    this.script_state === 'preworkingData'
                      ? 'highlight-btn'
                      : ''
                  "
                  style="color: #575757"
                  @click="changeTable('preworkingData')"
                >
                  啟用中
                </button>
                <button
                  class="basic-btn"
                  :class="
                    this.script_state === 'workingData' ? 'highlight-btn' : ''
                  "
                  style="color: #575757"
                  @click="changeTable('workingData')"
                >
                  執行中
                </button>
                <button
                  class="basic-btn"
                  :class="
                    this.script_state === 'doneworkData' ? 'highlight-btn' : ''
                  "
                  style="color: #575757"
                  @click="changeTable('doneworkData')"
                >
                  已完成
                </button>
              </div>
            </template>

            <Column
              v-if="this.tabSwitch === '觸發劇本'"
              field="event"
              header="觸發事件"
              :sortable="true"
            ></Column>
            <Column
              v-if="this.tabSwitch === '行銷劇本'"
              field="type"
              header="投放渠道"
              :sortable="true"
            ></Column>
            <Column
              field="script_name"
              header="活動名稱"
              :sortable="true"
              draggable="true"
            >
              <template #body="slotProps2">
                <div
                  :class="stockClass(slotProps2.data)"
                  class="d-flex flex-column align-items-center"
                >
                  <div
                    class="ellipsis-labelName"
                    v-tooltip.bottom="
                      slotProps2.data.script_name.length > 13
                        ? {
                            value: slotProps2.data.script_name,
                            class: 'tip-LabelManage',
                          }
                        : {
                            value: '',
                            class: '',
                          }
                    "
                  >
                    {{ slotProps2.data.script_name }}
                  </div>
                  <div
                    style="color: #c4c4c4; font-size: 12px"
                    v-if="slotProps2.data.end_time != '永久'"
                  >
                    {{ slotProps2.data.活動時間 }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              v-if="this.tabSwitch === '行銷劇本'"
              field="template_name"
              header="模板名稱"
              :sortable="true"
              draggable="true"
            >
              <template #body="slotProps2">
                <div :class="stockClass(slotProps2.data)">
                  <span class="ellipsis-labelName">
                    {{ slotProps2.data.template_name }}
                  </span>
                </div>
              </template>
            </Column>
            <Column field="label_name" header="客群名稱" :sortable="true"
              ><template #body="slotProps2">
                <div
                  class="d-flex align-items-center justify-content-center"
                  :class="stockClass(slotProps2.data)"
                >
                  <div
                    class="ellipsis-labelName"
                    v-tooltip.bottom="
                      slotProps2.data.label_name.includeLabelNames.toString()
                        .length > 13
                        ? {
                            value:
                              slotProps2.data.label_name.includeLabelNames.toString(),
                            class: 'tip-LabelManage',
                          }
                        : {
                            value: '',
                            class: '',
                          }
                    "
                  >
                    {{
                      slotProps2.data.label_name.includeLabelNames.toString()
                    }}
                  </div>
                  <div
                    style="
                      color: #c4c4c4;
                      font-size: 12px;
                      text-decoration: underline;
                      cursor: pointer;
                    "
                    @click="openExcludeModal(slotProps2.data.label_name)"
                    v-if="
                      slotProps2.data.label_name.excludeLabelNames[0] !=
                      undefined
                    "
                  >
                    瀏覽完整名單
                  </div>
                </div>
              </template>
            </Column>
            <Column field="end_time" header="開始/結束時間" :sortable="true">
              <template #body="slotProps2">
                <div :class="stockClass(slotProps2.data)">
                  <div>
                    {{ slotProps2.data.start_time }}~{{
                      slotProps2.data.end_time
                    }}
                  </div>
                  <div
                    style="color: #c4c4c4; font-size: 12px"
                    v-if="slotProps2.data.next_time != ''"
                  >
                    下次投放: {{ slotProps2.data.next_time.slice(0, -3) }}
                  </div>
                </div>
              </template>
            </Column>
            <Column field="modified_time" header="更新時間" :sortable="true">
              <template #body="slotProps2">
                <div :class="stockClass(slotProps2.data)">
                  <div>
                    {{ slotProps2.data.modified_time.split(" ")[0] }}
                  </div>
                  <div style="color: #c4c4c4; font-size: 12px" c>
                    {{ slotProps2.data.modified_time.split(" ")[1] }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              field="數據查看"
              header=""
              style="overflow: visible; max-width: 80px"
            >
              <template #body="slotProps">
                <button
                  class="del-btn"
                  style="width: 100px; padding: 0 30px 0 30px"
                  id="delete-button"
                  v-on:click="show(slotProps)"
                  @mouseleave="teleportData = false"
                >
                  <img src="../assets/spot.svg" />
                  <div
                    class="popout-in-scripttable"
                    v-show="teleportData === slotProps.data.script_id"
                  >
                    <p
                      class="
                        p-hover-up
                        m-0
                        px-2
                        py-1
                        d-flex
                        justify-content-around
                        align-items-center
                      "
                      @click.stop="showModal()"
                    >
                      <img src="../assets/eye-black.svg" alt="" /> 預覽劇本
                    </p>
                    <p
                      class="
                        p-hover-up
                        m-0
                        px-2
                        py-1
                        d-flex
                        justify-content-around
                        align-items-center
                      "
                      @click.stop="patchScript()"
                    >
                      <img
                        src="../assets/book.svg"
                        alt=""
                        style="width: 18px"
                      />
                      劇本設定
                    </p>
                    <p
                      class="
                        p-hover-up
                        m-0
                        px-2
                        py-1
                        d-flex
                        justify-content-around
                        align-items-center
                      "
                      @click.stop="goPerformance(slotProps)"
                      v-if="
                        this.script_state === 'workingData' ||
                        this.script_state === 'doneworkData'
                      "
                    >
                      <img
                        src="../assets/3bar.svg"
                        alt=""
                        style="width: 18px"
                      />
                      查看成效
                    </p>
                    <p
                      class="
                        p-hover-bot
                        m-0
                        px-2
                        py-1
                        d-flex
                        justify-content-around
                        align-items-center
                      "
                      @click.stop="deleteScriptApi()"
                    >
                      <img
                        src="../assets/delete-black.svg"
                        alt=""
                        style="margin-left: 1.5px"
                      />
                      <span style="width: 71px" class="text-start"> 刪除 </span>
                    </p>
                  </div>
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
        <div
          class="text-center"
          style="line-height: 100px; color: #c4c4c4"
          v-if="this.selectedTable[0] === undefined"
        >
          無此狀態的劇本
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import moment from "moment";
import SeeTriggerScript from "../components/SeeTriggerScript.vue";
import SeeScript from "../components/SeeScript.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ContextMenu from "primevue/contextmenu";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import SmsPreview from "../components/SmsPreview.vue";
import EmailPreview from "../components/EmailPreview.vue";

export default {
  name: "ScriptTable",
  components: {
    DataTable,
    Column,
    ContextMenu,
    SeeTriggerScript,
    SeeScript,
    SmsPreview,
    EmailPreview,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return { target, autoShow };
  },
  data() {
    return {
      script_state: "notworkData",
      checkedNames2: [],
      checkedNames3: [],
      checkedNames4: [],
      filterDateRange: {},
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      time_range: "",

      loading: false,
      notshow: false,
      tableData: [],
      tableData2: [],

      perPage: 5,
      page: 0,

      loopData: {
        label: "",
      },

      tabSwitch: "觸發劇本",
      selectedTable: [],
      selectedTable2: [],
      selectedTable3: [],
      triggerType: "篩選",
      searchInput: "",

      workingData: [],
      notworkData: [],
      doneworkData: [],
      workingData2: [],
      notworkData2: [],

      table: [],
      table2: [],
      selectedProduct: null,

      rowData: {},
      resGroup: {
        includeLabelNames: [],
        excludeLabelNames: [],
      },

      isShow: false,
      teleportData: false,

      html: "",
      subject: "",

      triggerModal: "",
      scriptModal: "",
      exampleModal: "",
      exampleModal2: "",

      scriptID: "",
      groupName: "",
      scriptID2: "",
      emailChannel: false,

      fullurlArr: [],
      Parr: [],
      resSubsec: "",
      newSubsec: "",
      newText: "",
      emailSches: [],
      smsSches: [],

      forceRerender: 0,
      addadd: 0,
      addadd2: 0,

      countText: "",
    };
  },
  watch: {
    searchInput: function (val) {
      this.selectedTable = this.selectedTable.filter((d) => {
        return d.script_name.includes(val);
      });
    },
    checkedNames2: function (val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined &&
        this.checkedNames4[0] === undefined
      ) {
        this.selectedTable = this[this.script_state];
        return;
      }
      this.selectedTable = this[this.script_state].filter((d) => {
        return (
          this.checkedNames2[0] === d.event ||
          this.checkedNames2[1] === d.event ||
          this.checkedNames2[2] === d.event ||
          this.checkedNames2[3] === d.event ||
          this.checkedNames2[4] === d.event ||
          (this.checkedNames2[0] === undefined &&
            ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
              this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
              (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
                this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
              this.checkedNames3[0] === undefined) &&
            (d.end_time === "永久" || this.checkedNames4[0] === undefined))
        );
      });
    },
    checkedNames3: function (val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined &&
        this.checkedNames4[0] === undefined
      ) {
        this.selectedTable = this[this.script_state];
        return;
      }
      this.selectedTable = this[this.script_state].filter((d) => {
        return (
          this.checkedNames2[0] === d.event ||
          this.checkedNames2[1] === d.event ||
          this.checkedNames2[2] === d.event ||
          this.checkedNames2[3] === d.event ||
          this.checkedNames2[4] === d.event ||
          (this.checkedNames2[0] === undefined &&
            ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
              this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
              (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
                this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
              this.checkedNames3[0] === undefined) &&
            (d.end_time === "永久" || this.checkedNames4[0] === undefined))
        );
      });
    },
    checkedNames4: function (val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined &&
        this.checkedNames4[0] === undefined
      ) {
        this.selectedTable = this[this.script_state];
        return;
      }
      this.selectedTable = this[this.script_state].filter((d) => {
        return (
          this.checkedNames2[0] === d.event ||
          this.checkedNames2[1] === d.event ||
          this.checkedNames2[2] === d.event ||
          this.checkedNames2[3] === d.event ||
          this.checkedNames2[4] === d.event ||
          (this.checkedNames2[0] === undefined &&
            ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
              this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
              (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
                this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
              this.checkedNames3[0] === undefined) &&
            (d.end_time === "永久" || this.checkedNames4[0] === undefined))
        );
      });
    },
    filterDateRange() {
      this.updateRange();
      if (this.checkedNames3[0] != undefined) {
        this.selectedTable = this[this.script_state].filter((d) => {
          return (
            this.checkedNames2[0] === d.event ||
            this.checkedNames2[1] === d.event ||
            this.checkedNames2[2] === d.event ||
            this.checkedNames2[3] === d.event ||
            this.checkedNames2[4] === d.event ||
            (this.checkedNames2[0] === undefined &&
              ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
                this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
                (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
                  this.time_range.substr(11, 10) <=
                    d.活動時間.substr(13, 10)) ||
                this.checkedNames3[0] === undefined) &&
              (d.end_time === "永久" || this.checkedNames4[0] === undefined))
          );
        });
      }
    },
  },
  created: async function () {
    // this.gotop()
    if (!this.notshow) {
      this.loading = true;
      await this.getCalenEvents();
      await this.getScriptTable();
      this.getAllEmailSche();
      this.getAllSmsSche();
      if (sessionStorage.getItem("prework") === "prework") {
        this.changeTable("preworkingData");
        this.showToast();
      } else if (sessionStorage.getItem("prework") === "work") {
        this.changeTable("workingData");
        this.showToast();
      }
      sessionStorage.removeItem("prework");
    }
  },

  beforeRouteLeave: function (to, from) {
    // console.log("beforeRouteLeave");
    // sessionStorage.removeItem("triggerGroup");
    // sessionStorage.removeItem("triggerEmail");
  },
  methods: {
    
    showToast() {
      var toastLiveExample = document.getElementById("liveToast");
      var toast = new bootstrap.Toast(toastLiveExample);

      toast.show();
    },
    gotop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },

    updateRange() {
      console.log(this.filterDateRange);
      console.log(this.filterDateRange.start.toISOString());
      this.time_range =
        this.filterDateRange.start.toISOString().slice(0, 10) +
        "~" +
        this.filterDateRange.end.toISOString().slice(0, 10);
    },
    openExcludeModal(resGroup) {
      this.resGroup = resGroup;
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    goEmailMark() {
      this.$router.push({
        name: "MarketingSchedule",
      });
    },
    goSmsMark() {
      this.$router.push({
        name: "SmsSchedule",
      });
    },
    showModal() {
      console.log(this.tabSwitch);
      if (this.tabSwitch === "觸發劇本") {
        this.addadd = this.addadd + 1;
        this.scriptID = this.rowData.data.script_id;
        this.groupName = this.rowData.data.label_name;
        this.triggerModal = new bootstrap.Modal(
          document.getElementById("triggerModal"),
          {
            keyboard: false,
          }
        );
        this.triggerModal.show();
      } else {
        if (this.rowData.data.type === "Email") {
          this.emailChannel = true;
          const data = this.emailSches.filter(
            (d) => d.ScheduleName === this.rowData.data.Name
          );
          console.log(data);
          sessionStorage.setItem("setResEventTitle", data[0].template_name);
          sessionStorage.setItem("setResEventName", data[0].script_name);
        } else {
          this.emailChannel = false;
          const data = this.smsSches.filter(
            (d) => d.Name === this.rowData.data.Name
          );
          console.log(data);
          sessionStorage.setItem("setResEventTitle", data[0].template_name);
          sessionStorage.setItem("setResEventName", data[0].script_name);
        }

        this.addadd2 = this.addadd2 + 1;
        this.scriptID2 = this.rowData.data.script_id;
        this.scriptModal = new bootstrap.Modal(
          document.getElementById("scriptModal"),
          {
            keyboard: false,
          }
        );
        this.scriptModal.show();
      }
    },
    show(slotProps) {
      this.forceRerender = this.forceRerender + 1;
      console.log(this.forceRerender);
      console.log("sotsot", slotProps);
      this.isShow = slotProps.data.script_id;
      this.teleportData = slotProps.data.script_id;
      this.rowData = slotProps;
    },
    goPerformance(slotProps) {
      console.log("sotsot23", slotProps);
      sessionStorage.setItem("bringScriptID", slotProps.data.script_id);
      this.$router.push({
        name: "TriggerScriptPerformance",
      });
    },
    patchScript() {
      // const data = this.emailSches.find(
      //   (d) => d.script_id === this.rowData.data.script_id
      // );
      // console.log(data);
      // console.log(new Date(this.rowData.data.ScheduleExpression));
      // console.log(moment(this.rowData.data.ScheduleExpression).add(-1, "h")._d);
      // console.log(new Date());

      console.log("checkindex", this.rowData.data.script_id);
      if (this.tabSwitch === "觸發劇本") {
        sessionStorage.setItem("setResScriptId", this.rowData.data.script_id);
        sessionStorage.setItem(
          "setResScriptHasEvent",
          this.rowData.data.has_event
        );
        sessionStorage.setItem("fromScript", true);
        this.$router.push({
          name: "TriggerScript",
        });
      } else {
        if (
          new Date() >
          moment(this.rowData.data.ScheduleExpression).add(-1, "h")._d
        ) {
          return alert("此排程將在1小時內執行，已無法在進行更改與刪除");
        }
        sessionStorage.setItem(
          "setResScheduleId",
          this.rowData.data.ScheduleName
        );
        sessionStorage.setItem("setResScriptId", this.rowData.data.script_id);
        sessionStorage.setItem("setResScheduleId", this.rowData.data.Name);
        sessionStorage.setItem("fromScript", false);
        if (this.rowData.data.type === "Email") {
          const data = this.emailSches.filter(
            (d) => d.ScheduleName === this.rowData.data.Name
          );
          console.log(data);
          sessionStorage.setItem("setResEventTitle", data[0].template_name);
          sessionStorage.setItem("setResEventName", data[0].script_name);
          sessionStorage.setItem("isEmailChannel", true);
          sessionStorage.setItem(
            "setResScriptHasEvent",
            this.rowData.data.has_event
          );
        } else {
          const data = this.smsSches.filter(
            (d) => d.Name === this.rowData.data.Name
          );
          console.log(data);
          sessionStorage.setItem("setResEventTitle", data[0].template_name);
          sessionStorage.setItem("setResEventName", data[0].script_name);
          sessionStorage.setItem("isEmailChannel", false);
          sessionStorage.setItem(
            "setResScriptHasEvent",
            this.rowData.data.has_event
          );
        }

        this.$router.push({
          name: "MarketingScript",
        });
      }
    },
    stockClass(data) {
      return [
        {
          noevent: data.事件 === 2,
          hasevent: data.事件 === 0,
        },
      ];
    },
    getAllEmailSche: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .scheduleGet(params)
        .then((res) => {
          console.log("3 item", res);
          // console.log("sorting", res.data.Data[0].ScheduleExpression)

          this.emailSches = res.data.Data;
          this.emailSches.map((d) => {
            d.ScheduleExpression = moment(d.ScheduleExpression).add(-8, "h")._d;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    getAllSmsSche: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .smsScheduleGet(params)
        .then((res) => {
          console.log("3 item", res);

          this.smsSches = res.data.Data;
          this.smsSches.map((d) => {
            d.ScheduleExpression = moment(d.ScheduleExpression).add(-8, "h")._d;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },

    changeTable(data) {
      this.script_state = data;
      this.selectedTable = this[data];
    },
    getScriptTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .scriptOverallGet(params)
        .then((res) => {
          console.log("觸發劇本table", res);
          this.tableData = res.data.data.觸發劇本;
          this.tableData.reverse()

          this.tableData.forEach((d) => {
            d.modified_time = new Date(d.modified_time).toLocaleString();
            if (d.event === "購買行銷") {
              d.event = "購買後促銷";
            } else if (d.event === "購買") {
              d.event = "訂單確認";
            }
            const data = this.events.find((e) => e.id === d.script_name);
            if (data != undefined) {
              d.script_name = data.title;
              d.realScript_name = data.id;
              d.活動時間 = `${data.start} - ${data.end}`;
            } else {
              d.活動時間 = "";
              d.end_time = "永久";
            }
          });

          this.preworkingData = this.tableData.filter(
            (d) =>
              d.has_event === "2" &&
              d.start_time > new Date().toISOString().slice(0, 10)
          );
          this.workingData = this.tableData.filter(
            (d) =>
              d.has_event === "2" &&
              d.start_time <= new Date().toISOString().slice(0, 10)
          );
          this.notworkData = this.tableData.filter((d) => d.has_event === "1");
          this.doneworkData = this.tableData.filter((d) => d.has_event === "3");

          if (this.script_state != "") {
            this.selectedTable = this[this.script_state];
          } else {
            this.selectedTable = this.notworkData;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    goCreateScript() {
      sessionStorage.removeItem("setResScriptId");
      sessionStorage.removeItem("fromScript");
      sessionStorage.setItem("setResScriptHasEvent", "0");
    },

    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    showUnlayer: function (childHtml, childSub) {
      this.html = childHtml;
      this.subject = childSub;
      this.changeAllUrl();
      this.exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      this.exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      console.log("backdrop[1]", backdrop);
      backdrop[backdrop.length-1].style['z-index']=4500
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.html);
      console.log(this.fullurlArr);
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.html = this.html.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },
    showSMSexp: function (childValue) {
      console.log(childValue);
      this.newText = childValue;
      this.newText = this.newText
        .replace(/{id}/g, this.randomString(6))
        .replace("{lastname}", "王")
        .replace("{firstname}", "◯◯")
        .replace("{name}", "王◯◯");
      this.resSubsec = childValue;
      this.resSubsec = this.resSubsec
        .replace(/{id}/g, this.randomString(6))
        .replace("{lastname}", "王")
        .replace("{firstname}", "◯◯")
        .replace("{name}", "王◯◯");
      this.countText = this.newText;

      this.highlightUrl();
      this.exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length-1].style['z-index']=4500
    },
    highlightUrl() {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.newText);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index] +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
    },
    deleteScriptApi() {
      if (this.tabSwitch === "觸發劇本") {
        let deleteyes = confirm("刪除後即無法返回，是否確認刪除?");
        if (deleteyes) {
          this.loading = true;
          console.log("this.eventScriptIdFromSche", this.eventScriptIdFromSche);
          const token = sessionStorage.getItem("access_token");
          const params = {
            Authorization: "Bearer " + token,
            script_id: this.rowData.data.script_id,
          };
          allapi2
            .triggerScriptScriptIdDelete(params)
            .then((res) => {
              console.log("成功刪除劇本", res);
              this.getScriptTable();

              this.editList = false;
              this.loading = false;
            })
            .catch((err) => {
              console.log("連線有誤");
              this.loading = false;
              console.log(err);
              alert("連線有誤" + err);
            });
        } else {
          return;
        }
      } else {
        if (this.rowData.data.type === "Email") {
          const token = sessionStorage.getItem("access_token");
          const params = {
            Authorization: "Bearer " + token,
            script_id: this.rowData.data.script_id,
          };
          allapi2
            .scriptScriptIdDelete(params)
            .then((res) => {
              console.log("成功刪除劇本", res);
              this.patchRunScriptApi("0");
            })
            .catch((err) => {
              console.log("連線有誤");
              this.loading = false;
              console.log(err);
              alert("連線有誤" + err);
            });
        } else {
          const token = sessionStorage.getItem("access_token");
          const params = {
            Authorization: "Bearer " + token,
            id: this.rowData.data.script_id,
          };
          allapi2
            .smsScriptIdDelete(params)
            .then((res) => {
              console.log("成功刪除劇本", res);
              this.patchRunScriptApi("0");
            })
            .catch((err) => {
              console.log("連線有誤");
              this.loading = false;
              console.log(err);
              alert("連線有誤" + err);
            });
        }
      }
    },
    patchRunScriptApi: async function (work) {
      if (this.rowData.data.type === "Email") {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
          script_id: this.rowData.data.script_id,
          state: work,
        };

        await allapi2
          .scheduleUpdateHasEventScriptIdPatch(params)
          .then((res) => {
            console.log("成功更改劇本狀態為" + work, res);
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("patch連線有誤" + err);
          });
      } else {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
          id: this.rowData.data.script_id,
        };
        const body = {
          state: work,
        };

        await allapi2
          .smsScheduleUpdateHasEventIdPost(params, body)
          .then((res) => {
            console.log("成功更改劇本狀態為" + work, res);
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("patch連線有誤" + err);
          });
      }
      this.getScriptTable();
    },
  },
};
</script>

<style>
/* ScriptTable */
#routerlink a:hover {
  color: #fff !important;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.del-btn {
  position: relative;
}

/* #popout-in-scripttable-container{
 position: relative;
} */
.button-nofocus {
  width: 150px;
}
.popout-in-scripttable {
  background-color: #fff;
  z-index: 1002;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 7.5rem;
  left: -45px;
  top: 30px;
}
.p-hover-up:hover {
  background-color: #eaf3f4;
}
.auto-complicate-div {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 300px;
  background-color: white;
  z-index: 99;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
}
.p-paginator-element {
  height: 40px;
}
.p-paginator-pages .p-paginator-page {
  height: 40px;
}
.p-paginator {
  margin-bottom: 30px;
}
.work-script-table {
  position: absolute;
  font-size: 22px;
  border-radius: 5px;
  background-color: #c6e0c9;
  width: 100px;
  height: 40px;
  line-height: 40px;
  z-index: 99;
  text-align: center;
}
.notwork-script-table {
  position: absolute;
  font-size: 22px;
  border-radius: 5px;
  background-color: #eeeeee;
  width: 100px;
  line-height: 40px;
  height: 40px;
  z-index: 99;
  text-align: center;
}
.donework-script-table {
  position: absolute;
  font-size: 22px;
  border-radius: 5px;
  background-color: #575757;
  color: #fff;
  width: 100px;
  line-height: 40px;
  height: 40px;
  z-index: 99;
  text-align: center;
}
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  overflow: visible;
  overflow-x: visible;
}
.p-datatable-wrapper {
  overflow-x: visible;
}
.p-datatable-resizable > .p-datatable-wrapper {
  overflow: visible;
}
.p-datatable .p-datatable-tbody > tr > td {
  height: 50px;
}
.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 10rem;
  padding: 30px;
  border-radius: 25px;
  width: 330px;
  word-break: break-all;
  /* overflow-wrap: anywhere; */
  white-space: pre-wrap;
}
.p-paginator-current {
  position: absolute !important;
  top: 45px;
  font-size: 14px;
  right: 5px;
  color: #8a8a8a;
}
.p-paginator {
  justify-content: end !important;
}
.showDetails-btn {
  border: none;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
}
.ellipsis-labelName {
  display: inline-block;
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>