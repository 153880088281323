<template>
  <div class="container home">
    <div class="loading-background" style="padding-top: 396px" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- show explainAIModal -->
    <div
      class="modal fade"
      id="explainAIModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <img
              src="../assets/已Group-explainAI.svg"
              alt=""
              style="position: relative; top: -7px; left: -8px; width: 1057px"
            />
            <div
              style="
                width: 100%;
                height: 20px;
                position: absolute;
                background-color: white;
                bottom: 4px;
                border-radius: 18px;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="title-span fs-medium" style="padding-left: 2px">
      360度顧客輪廓
    </div>
    <div class="d-flex mt-3">
      <div class="col-2 d-flex align-items-end pb-2">
        <span v-if="this.searchInput != ''">
          <span style="color: #71afb6">人數</span>
          <span>&nbsp; &nbsp;{{ this.searchTable.length }}人</span>
        </span>
        <span v-else>
          <span style="color: #71afb6">人數</span>
          <span>&nbsp; &nbsp;{{ this.totalPeople }}人</span>
        </span>
      </div>
      <!-- <div class="col-6 d-flex align-items-end">
 
     
      </div> -->
      <div class="col-10 ps-4 d-flex justify-content-end align-items-end">
        <div class="col-6" style="position: relative">
          <input
            v-model="searchInput"
            class="search-member360"
            placeholder="直接輸入ID.姓名.電子郵件.電話"
          />
          <div class="search-input-icon">
            <img src="../assets/search.png" alt="" />
          </div>
          <button class="search-btn-in-New360Page" @click="searchUpdate()">
            <img src="../assets/white-search.svg" alt="" />
          </button>
          <div class="close-input" @click="clearInput()">
            <img src="../assets/grayX.svg" alt="" />
          </div>
        </div>
        <div class="col-4">
          <div
            class="filter-dropdown d-flex align-items-center"
            @click="filterDropdown = !filterDropdown"
          >
            <span class="col-8">
              <img src="../assets/filter-icon.svg" class="me-2 ms-2" />篩選
            </span>
            <span class="col-4 d-flex justify-content-end pe-3">
              <svg
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z"
                  fill="#71AFB6"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="col-2 d-flex justify-content-end ps-4">
          <button
            class="clear-filter-button"
            style="width: 100%; padding-bottom: 12px; padding-top: 12px"
            @click="clearFilter()"
          >
            <img src="../assets/clear-filter-icon.svg" class="me-2 ms-2" /> 清除
          </button>
        </div>
      </div>
      <div class="dropdown-contain" v-if="filterDropdown === true">
        <div ref="filterDropdownRef">
          <!-- here -->
          <div class="customRFM-shadowBox mt-4">
            <div
              style="
                padding: 25px 30px 25px 36px;
                border-bottom: 1px solid #c4c4c4;
              "
              class="d-flex flex-wrap"
              v-for="(obj, index) in customRFM_tableValue"
              :key="index"
            >
              <div style="width: 93%">
                <div class="d-flex mb-3" v-if="index != 0">
                  <span style="line-height: 40px; color: #71afb6" class="me-3"
                    >條件設定</span
                  >
                  <div style="width: 18%">
                    <DropdownAll
                      style="width: 100%"
                      :mxHeight="200"
                      :valueArr="this.mode"
                      :selectValue="obj.mode"
                      :changingSelected="index + ''"
                      :holder="'選擇條件'"
                      v-on:selectValueByDrop="selectValueByDrop_mode"
                    />
                  </div>
                </div>
                <!-- selection div -->
                <div class="d-flex justify-content-start flex-wrap">
                  <!-- 標籤屬性 -->
                  <div style="width: 28%; margin-right: 2.64%">
                    <DropdownType
                      style="width: 100%"
                      :mxHeight="200"
                      :valueArr="this.tagType"
                      :selectValue="obj.type"
                      :changingSelected="index + ''"
                      :holder="'標籤屬性'"
                      v-on:selectValueByDrop="selectValueByDrop_tagType"
                    />
                  </div>
                  <!-- 網站選項 -->
                  <div
                    style="width: 17%; margin-right: 1.69%"
                    v-if="this.allTypeWebState[obj.type] === 'web'"
                  >
                    <DropdownAll
                      style="width: 100%"
                      :mxHeight="200"
                      :valueArr="this.websites"
                      :selectValue="obj.web"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_web"
                    />
                  </div>
                  <div
                    style="width: 17%; margin-right: 1.69%"
                    v-else-if="this.allTypeWebState[obj.type] === 'overall'"
                  >
                    <DropdownAll
                      style="width: 100%"
                      :mxHeight="200"
                      :valueArr="this.overall"
                      :selectValue="obj.web"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_web"
                    />
                  </div>
                  <div
                    style="width: 17%; margin-right: 1.69%"
                    v-else-if="
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽' &&
                      obj.type != '價值分群' &&
                      obj.type != ''
                    "
                  >
                    <DropdownAll
                      style="width: 100%"
                      :mxHeight="200"
                      :valueArr="this.customWeb[obj.type]"
                      :selectValue="obj.web"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_web"
                    />
                  </div>
                  <!-- 標籤 -->
                  <div
                    style="margin-right: 1.69%"
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:24.5%'
                        : 'width:28%'
                    "
                    v-if="
                      obj.type != '' && obj.web != '' && obj.tag != '價值分群'
                    "
                  >
                    <DropdownTypeAI
                      style="width: 100%"
                      :mxHeight="200"
                      :holder="'條件類型'"
                      :type="obj.type"
                      :valueArr="
                        this.allTypeWebState[obj.type + '_' + obj.web] ===
                        'noweb'
                          ? this.allKindTag[obj.type + '_' + obj.web]
                          : this.allKindTag[obj.type]
                      "
                      :selectValue="obj.tag"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_tag"
                      v-on:openExplainAIModal="openExplainAIModal"
                    />
                  </div>
                  <!-- 標籤數值 [] -->
                  <!-- value 在loading -->
                  <div
                    v-if="valueLoading === obj.tag && obj.tag != ''"
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20.5%'
                        : 'width:28%'
                    "
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style="
                        border: 1px solid #c4c4c4;
                        border-radius: 10px;
                        height: 40px;
                      "
                    >
                      <div
                        class="
                          flex-fill
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                      >
                        <div
                          class="spinner-border"
                          style="width: 1.6rem; height: 1.6rem"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <img
                        src="../assets/selection-arrow.png"
                        alt="selection-arrow"
                        style="margin-right: 14px"
                      />
                    </div>
                  </div>
                  <!-- value 會是 arr -->
                  <div
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20.5%'
                        : 'width:28%'
                    "
                    v-else-if="typeof this.allTagValue[obj.tag] === 'object'"
                  >
                    <DropdownCheckbox
                      style="width: 100%"
                      :holder="'選擇'"
                      :valueArr="this.allTagValue[obj.tag]"
                      :selectValue="obj.value"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_Checkbox"
                    />
                  </div>
                  <!-- value costrange -->
                  <div
                    class="d-flex"
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:23%'
                        : 'width:28%'
                    "
                    v-else-if="
                      typeof this.allTagValue[obj.tag] === 'string' &&
                      this.allTagValue[obj.tag].includes('costrange-')
                    "
                  >
                    <DropdownAll
                      style="width: 90px"
                      :mxHeight="200"
                      :valueArr="this.moreOrlessForCost2"
                      :selectValue="obj.value.moreOrless"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="
                        selectValueByDrop_moreOrlessForCost2
                      "
                    />
                    <input
                      type="number"
                      class="count-input-customRFM mx-2"
                      :value="obj.value.cost"
                      @input="updateCost2($event, index)"
                      v-if="
                        obj.value.moreOrless === '大於' ||
                        obj.value.moreOrless === '小於'
                      "
                    />
                    <span
                      style="line-height: 40px"
                      v-if="
                        obj.value.moreOrless === '大於' ||
                        obj.value.moreOrless === '小於'
                      "
                      >{{ this.allTagValue[obj.tag].split("-")[1] }}</span
                    >
                  </div>
                  <!-- value 會是 range normal -->
                  <div
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20.5%'
                        : 'width:28%'
                    "
                    v-else-if="
                      typeof this.allTagValue[obj.tag] === 'string' &&
                      this.allTagValue[obj.tag].includes('range-') &&
                      this.allTagValue[obj.tag].split('-')[1] != 'date'
                    "
                  >
                    <input
                      type="number"
                      class="range-input-customRFM"
                      style="width: 55px"
                      :value="obj.value.low"
                      @input="updateCustomTableRange($event, index, 'low')"
                    />
                    <span style="margin-left: 5px">{{
                      this.allTagValue[obj.tag].split("-")[1]
                    }}</span>
                    <span style="margin: 0 5px">-</span>
                    <input
                      type="number"
                      class="range-input-customRFM"
                      style="width: 55px"
                      :value="obj.value.high"
                      @input="updateCustomTableRange($event, index, 'high')"
                    />
                    <span style="margin-left: 5px">{{
                      this.allTagValue[obj.tag].split("-")[1]
                    }}</span>
                    <div
                      style="color: red; font-size: 12px; margin-left: 5px"
                      v-if="obj.value.low > obj.value.high"
                    >
                      左邊不能大於右邊
                    </div>
                  </div>
                  <!-- value 會是 range date -->
                  <div
                    class="d-flex align-items-center justify-content-between"
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20.5%'
                        : 'width:28%'
                    "
                    v-else-if="
                      typeof this.allTagValue[obj.tag] === 'string' &&
                      this.allTagValue[obj.tag].includes('range-') &&
                      this.allTagValue[obj.tag].split('-')[1] === 'date'
                    "
                  >
                    <DatePicker
                      v-model="rangeday"
                      :max-date="new Date()"
                      @dayclick="updateRangeday(index)"
                      is-range
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="flex justify-center items-center">
                          <input
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            class="ps-2"
                            style="
                              width: 45%;
                              height: 40px;
                              border-radius: 10px;
                              border: 1px solid #c4c4c4;
                            "
                          />
                          <span style="margin: 0 5px">-</span>
                          <input
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            class="ps-2"
                            style="
                              width: 45%;
                              height: 40px;
                              border-radius: 10px;
                              border: 1px solid #c4c4c4;
                            "
                          />
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                  <!-- value 會是 count -->
                  <div
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20.5%'
                        : 'width:28%'
                    "
                    v-else-if="this.allTagValue[obj.tag] === 'count'"
                  ></div>
                  <!-- value 價值分群 -->
                  <div
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20%'
                        : 'width:28%'
                    "
                    v-else-if="obj.type === '價值分群'"
                  >
                    <DropdownCheckbox
                      style="width: 100%"
                      :holder="'選擇'"
                      :valueArr="this.allTagValue[obj.tag]"
                      :selectValue="obj.value"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_Checkbox"
                    />
                  </div>
                  <!-- value 要CALL API -->
                  <div
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:20.5%'
                        : 'width:28%'
                    "
                    v-else-if="obj.tag != ''"
                  >
                    <!-- @click="callValueApi(this.allTagValue[obj.tag], obj.web, obj.tag)" -->
                    <DropdownCheckbox
                      style="width: 100%"
                      :holder="'選擇'"
                      :valueArr="this.apiTagValue[obj.tag]"
                      :selectValue="obj.value"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_Checkbox"
                    />
                  </div>
                  <!-- value costrange && value moreOrless 區間-->
                  <div class="mt-3" v-if="obj.value.moreOrless === '區間'">
                    <div class="d-flex">
                      <span style="line-height: 40px">介於</span>
                      <input
                        type="number"
                        class="count-input-customRFM mx-2"
                        :value="obj.value.low"
                        @input="updateLowCost2($event, index)"
                      />
                      <span style="line-height: 40px"
                        >{{ this.allTagValue[obj.tag].split("-")[1] }} -</span
                      >
                      <input
                        type="number"
                        class="count-input-customRFM mx-2"
                        :value="obj.value.high"
                        @input="updateHighCost2($event, index)"
                      />
                      <span style="line-height: 40px"
                        >{{
                          this.allTagValue[obj.tag].split("-")[1]
                        }}
                        之間</span
                      >
                    </div>
                    <div
                      style="color: red; font-size: 12px; margin-left: 5px"
                      v-if="obj.value.low > obj.value.high"
                    >
                      左邊不能大於右邊
                    </div>
                  </div>
                </div>
                <!-- CustomTable.value  div -->
                <div class="d-flex">
                  <div
                    class="mt-4 d-flex flex-wrap"
                    style="
                      padding: 10px 2px 0px 17px;
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                    "
                    :style="
                      obj.type != '價值分群' &&
                      obj.type != '基本屬性' &&
                      obj.type != 'AI顧客分群' &&
                      obj.type != 'CF站外瀏覽'
                        ? 'width:98%'
                        : 'width:88.4%'
                    "
                    v-if="
                      obj.value[0] != undefined &&
                      !this.allTagValue[obj.tag].includes('range') &&
                      this.allTagValue[obj.tag] != 'count'
                    "
                  >
                    <div
                      class="col-3"
                      style="padding-right: 15px"
                      v-for="item in obj.value"
                      :key="item"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                        style="
                          padding: 0 15px;
                          height: 40px;
                          background-color: #eaf3f4;
                          border-radius: 10px;
                          margin-bottom: 10px;
                        "
                      >
                        <span
                          class="word-overflow"
                          v-tooltip.top="{
                            value: item,
                            class: 'tipInCustomRFM',
                          }"
                          >{{ item }}</span
                        >
                        <img
                          style="cursor: pointer"
                          src="../assets/blackX.svg"
                          alt="blackX"
                          @click="deleteCustomTableValue(item, index)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 更多選項按了後的內容 -->
                <div
                  class="mt-3 d-flex align-items-center flex-wrap"
                  v-if="obj.more_option != undefined"
                >
                  <span class="me-3">在</span>
                  <div style="width: 250px">
                    <DropdownMoreOption
                      style="width: 100%"
                      :valueArr="this.timeState"
                      :selectValue="
                        obj.more_option.time_range.start_time != ''
                          ? obj.more_option.time_range.start_time +
                            '~' +
                            obj.more_option.time_range.end_time
                          : obj.more_option.time_range.days + ''
                      "
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_timeState"
                    />
                  </div>
                  <div
                    class="d-flex"
                    v-if="this.allTagMoreOption[obj.tag].includes('time-count')"
                  >
                    <span style="line-height: 40px" class="mx-3"
                      >，總{{ this.allTagMoreOptionUnit[obj.tag] }}次數</span
                    >
                    <input
                      type="number"
                      class="count-input-customRFM"
                      :value="obj.more_option.count.count"
                      @input="updateCount($event, index)"
                    />
                    <span style="line-height: 40px" class="mx-3">次</span>
                    <div style="width: 120px">
                      <DropdownAll
                        style="width: 100%"
                        :mxHeight="200"
                        :valueArr="this.moreOrless"
                        :selectValue="obj.more_option.count.moreOrless"
                        :changingSelected="index + ''"
                        v-on:selectValueByDrop="selectValueByDrop_moreOrless"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex flex-fill"
                    v-if="this.allTagMoreOption[obj.tag].includes('-cost')"
                  >
                    <span>，</span>
                  </div>
                  <div
                    class="d-flex col-12 align-items-center mt-3"
                    v-if="this.allTagMoreOption[obj.tag].includes('-cost')"
                  >
                    <span class="me-3">且各單筆消費金額</span>
                    <DropdownAll
                      style="width: 90px"
                      :mxHeight="200"
                      :valueArr="this.moreOrlessForCost"
                      :selectValue="obj.more_option.cost.moreOrlessForCost"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="
                        selectValueByDrop_moreOrlessForCost
                      "
                    />
                    <div style="position: relative">
                      <div class="d-flex align-items-center">
                        <input
                          type="number"
                          class="count-input-customRFM mx-3"
                          :value="obj.more_option.cost.lowCost"
                          @input="updateLowCost($event, index)"
                          v-if="
                            obj.more_option.cost.moreOrlessForCost === '區間'
                          "
                        />
                        <span
                          v-if="
                            obj.more_option.cost.moreOrlessForCost === '區間'
                          "
                          >$ -</span
                        >
                        <input
                          type="number"
                          class="count-input-customRFM mx-3"
                          :value="obj.more_option.cost.highCost"
                          @input="updateHighCost($event, index)"
                          v-if="
                            obj.more_option.cost.moreOrlessForCost === '區間'
                          "
                        />
                      </div>
                      <div
                        style="
                          color: red;
                          font-size: 12px;
                          position: absolute;
                          bottom: -20px;
                          left: 22px;
                        "
                        v-if="
                          obj.more_option.cost.lowCost >
                          obj.more_option.cost.highCost
                        "
                      >
                        左邊不能大於右邊
                      </div>
                    </div>
                    <input
                      type="number"
                      class="count-input-customRFM mx-3"
                      :value="obj.more_option.cost.cost"
                      @input="updateCost($event, index)"
                      v-if="
                        obj.more_option.cost.moreOrlessForCost === '大於' ||
                        obj.more_option.cost.moreOrlessForCost === '小於'
                      "
                    />
                    <span
                      class="me-3"
                      v-if="obj.more_option.cost.moreOrlessForCost != '-'"
                      >$</span
                    >
                  </div>
                </div>
              </div>
              <!-- 右側按鈕 -->
              <div
                style="width: 7%"
                class="d-flex flex-column justify-content-between"
              >
                <span class="text-end" style="height: 40px"
                  ><img
                    class="me-1"
                    style="cursor: pointer; width: 15px"
                    src="../assets/greenX.svg"
                    alt="greenX"
                    @click="delete_customRFM_tableValue(index)"
                /></span>
                <div
                  class="d-flex flex-column align-items-end"
                  v-if="
                    obj.tag != '' &&
                    !this.allTagMoreOption[obj.tag].includes(
                      'notime-nocount'
                    ) &&
                    obj.more_option === undefined
                  "
                >
                  <div
                    style="
                      color: #71afb6;
                      cursor: pointer;
                      font-size: 14px;
                      height: 25px;
                    "
                    class="text-end pe-1"
                    @click="addMoreOption(index)"
                  >
                    更多選項
                  </div>
                  <div
                    style="
                      color: #71afb6;
                      cursor: pointer;
                      background-color: #eaf3f4;
                      height: 30px;
                      border-radius: 10px;
                      width: 74px;
                    "
                    class="text-center"
                    @click="add_customRFM_tableValue(index)"
                  >
                    <img
                      style="width: 12px"
                      class="me-1"
                      src="../assets/greenPlus.svg"
                      alt="greenPlus"
                    /><span style="font-size: 14px"> 新增 </span>
                  </div>
                </div>
                <div
                  class="d-flex flex-column align-items-end"
                  v-else-if="
                    obj.tag != '' &&
                    this.allTagMoreOption[obj.tag] != 'notime-nocount' &&
                    obj.more_option != undefined
                  "
                >
                  <div
                    style="
                      color: #71afb6;
                      cursor: pointer;
                      font-size: 14px;
                      height: 25px;
                    "
                    class="text-end pe-1"
                    @click="deleteMoreOption(index)"
                  >
                    取消選項
                  </div>
                  <div
                    style="
                      color: #71afb6;
                      cursor: pointer;
                      background-color: #eaf3f4;
                      height: 30px;
                      border-radius: 10px;
                      width: 74px;
                    "
                    class="text-center"
                    @click="add_customRFM_tableValue(index)"
                  >
                    <img
                      style="width: 12px"
                      class="me-1"
                      src="../assets/greenPlus.svg"
                      alt="greenPlus"
                    /><span style="font-size: 14px"> 新增 </span>
                  </div>
                </div>
                <div class="mt-3 d-flex justify-content-end" v-else>
                  <div
                    style="
                      color: #71afb6;
                      cursor: pointer;
                      background-color: #eaf3f4;
                      height: 30px;
                      border-radius: 10px;
                      width: 74px;
                    "
                    class="text-center"
                    @click="add_customRFM_tableValue(index)"
                  >
                    <img
                      style="width: 12px"
                      class="me-1"
                      src="../assets/greenPlus.svg"
                      alt="greenPlus"
                    /><span style="font-size: 14px"> 新增 </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- api 算人數 -->
            <div style="padding: 25px 0" class="d-flex justify-content-center">
              <button
                class="basic-btn-customRFM highlight-btn-customRFM"
                style="width: 50%"
                @click="this.beforeRatePostCustomTable()"
              >
                篩選
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="table-wrapper"
      :style="loadingTableWrapper === true ? 'background-color:#fff' : ''"
    >
      <div
        class="loading-background-tablewrapper"
        :class="
          loadingTableWrapper === true ? 'd-flex  justify-content-center' : ''
        "
        v-if="loadingTableWrapper"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <!-- v-if="this.loadingTable === false" -->
      <!-- :loading="loadingTable" -->
      <!-- @scroll="this.scrollTable($event)" -->
      <div style="position: relative" v-show="!loadingTableWrapper">
        <DataTable
          id="datatable"
          :lazy="true"
          :value="searchTable"
          v-model:selection="selectedProducts3"
          :scrollable="true"
          scrollHeight="295px"
        >
          <Column selectionMode="single" style="max-width: 50px"></Column>
          <Column
            field="姓名"
            header="姓名"
            :sortable="false"
            style="min-width: 100px"
          ></Column>
          <Column
            field="會員ID"
            header="會員ID"
            :sortable="false"
            style="min-width: 100px"
          ></Column>
          <Column
            field="Email"
            header="Email"
            :sortable="false"
            style="min-width: 100px"
          ></Column>
          <Column
            field="電話"
            header="電話"
            :sortable="false"
            style="min-width: 100px"
          ></Column>
          <Column
            field="註冊日期"
            header="註冊日期"
            :sortable="false"
            style="min-width: 100px"
          ></Column>
        </DataTable>
        <div
          style="
            position: absolute;
            width: calc(100% - 10px);
            bottom: 1px;
            z-index: 99;
            background-color: white;
            border-bottom-left-radius: 10px;
            height: 37px;
          "
          class="d-flex justify-content-center"
          v-if="this.loadingTable"
        >
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <!-- <div style="height:400px" class="d-flex flex-column justify-content-center " v-else>
          <div class="d-flex justify-content-center my-auto">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
      </div> -->
    </div>
    <div class="d-flex mt-4">
      <div class="col-4">
        <div class="title-button-div fs-small" style="width: 100%">
          <button
            class="button-nofocus"
            :class="this.buttonSelected === 'basicinfo' ? 'button-focus' : ''"
            @click="clickBasicInfo"
          >
            基本資料
          </button>
          <button
            class="button-nofocus"
            :class="
              this.buttonSelected === 'purchaserecord' ? 'button-focus' : ''
            "
            @click="clickPurchaseRecord"
          >
            購買紀錄
          </button>
          <button
            class="button-nofocus"
            :class="this.buttonSelected === 'online' ? 'button-focus' : ''"
            @click="clickOnline"
          >
            線上行為
          </button>
        </div>
      </div>
      <div
        class="ps-3"
        style="width: 200px"
        v-if="this.buttonSelected === 'purchaserecord'"
      >
        <Dropdown
          :valueArr="['整體', '線上', '線下']"
          :selectValue="dropdownIndex"
          :changingSelected="'dropdownIndex'"
          v-on:selectValueByDrop="selectValueByDrop"
        />
      </div>
    </div>

    <!-- basicinfo section -->
    <div
      class="mt-4"
      style="position: relative"
      v-if="this.buttonSelected === 'basicinfo'"
    >
      <div
        class="
          loading-background-bottom-info-in-New360Page
          d-flex
          justify-content-center
          align-items-center
        "
        v-if="loadingBasic"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="d-flex">
          <div class="pe-3" style="width: 59%; flex: 0 0 auto">
            <div class="p-4 section-div-basicinfo" style="height: 100%">
              <div class="d-flex col-12">
                <p style="color: #71afb6">基本資料</p>
              </div>
              <div class="d-flex col-12 mt-3">
                <div class="col-20">
                  <span style="color: #c4c4c4">名字</span
                  ><span> &nbsp; &nbsp;{{ this.basicInfoData.姓名 }}</span>
                </div>
                <div class="col-20 ps-1">
                  <span style="color: #c4c4c4">性別</span
                  ><span>&nbsp; &nbsp;{{ this.basicInfoData.性別 }}</span>
                </div>
                <div class="col-2 ps-1">
                  <span style="color: #c4c4c4">年齡</span
                  ><span>&nbsp; &nbsp;{{ this.basicInfoData.年齡 }}</span>
                </div>
                <div class="col-20 ps-1">
                  <span style="color: #c4c4c4">生日</span
                  ><span>&nbsp; &nbsp;{{ this.basicInfoData.生日 }}</span>
                </div>
                <div class="col-3 ps-1">
                  <span style="color: #c4c4c4">婚姻狀態</span
                  ><span>&nbsp; &nbsp;{{ this.basicInfoData.婚姻狀態 }}</span>
                </div>
              </div>
              <div class="d-flex col-12 mt-4">
                <span style="color: #c4c4c4">地址</span
                ><span>&nbsp; &nbsp;{{ this.basicInfoData.地址 }}</span>
              </div>
              <div class="d-flex col-12 mt-4">
                <div class="col-xxl-4 col-xl-4" style="width: 30%">
                  <span style="color: #c4c4c4">會員等級</span
                  ><span> &nbsp; &nbsp;{{ this.basicInfoData.會員等級 }}</span>
                </div>
                <div class="col-xxl-4 col-xl-4">
                  <span style="color: #c4c4c4">加入時間</span
                  ><span>&nbsp; &nbsp;{{ this.basicInfoData.入會時間 }}</span>
                </div>
                <div class="col-xxl-5 col-xl-5">
                  <span style="color: #c4c4c4">MEMBER ID</span
                  ><span>&nbsp; &nbsp;{{ this.basicInfoData.MEMBER_ID }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ps-3" style="width: 41%; flex: 0 0 auto">
            <div class="p-4 section-div-basicinfo">
              <p style="color: #71afb6">
                會員價值標籤
                <BtnInfoBox content="R為最近一次消費，M、F則針對近半年消費表現，皆以 PR 值分為5級。從1開始依序為前20%、20-40%、40-60%、60-80% 與最後20%。" direction="right"></BtnInfoBox>

              </p>
              <div class="d-flex">
                <div
                  class="d-flex flex-column justify-content-between"
                  style="margin-right: 24px"
                >
                  <div>
                    <img
                      :src="RFMsImg[this.RFMData.客群]"
                      style="width: 100px; height: 100px"
                      class="logo"
                      alt="Girl in a jacket"
                    />
                  </div>
                  <p class="text-center mb-0">{{ this.RFMData.客群 }}</p>
                </div>
                <div class="d-flex flex-fill flex-column">
                  <div class="d-flex align-items-center">
                    <div
                      style="
                        background-color: #fc9f66;
                        border-radius: 10px;
                        margin-right: 18px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                      "
                    >
                      {{ this.RFMData.最近消費 }}
                    </div>
                    <div
                      class="
                        d-flex
                        flex-fill
                        justify-content-between
                        align-items-center
                      "
                    >
                      <span style="color: #c4c4c4">近一次消費</span>
                      <span> {{ this.RFMData.最近消費_天 }}天</span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div
                      style="
                        background-color: #fc9f66;
                        border-radius: 10px;
                        margin-right: 18px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                      "
                    >
                      {{ this.RFMData.消費頻率 }}
                    </div>
                    <div
                      class="
                        d-flex
                        flex-fill
                        justify-content-between
                        align-items-center
                      "
                    >
                      <span style="color: #c4c4c4">近半年消費頻率</span>
                      <span> {{ this.RFMData.消費頻率_次 }}次 </span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div
                      style="
                        background-color: #fc9f66;
                        border-radius: 10px;
                        margin-right: 18px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                      "
                    >
                      {{ this.RFMData.消費金額 }}
                    </div>
                    <div
                      class="
                        d-flex
                        flex-fill
                        justify-content-between
                        align-items-center
                      "
                    >
                      <span style="color: #c4c4c4">近半年消費金額</span>
                      <span> {{ this.RFMDataLast }}(NT$) </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="col-12">
            <div class="section-div-basicinfo p-4">
              <span style="color: #71afb6">個人標籤組合</span>
              <BtnInfoBox content="此為「近一個月內」該會員身上擁有的標籤，包括顧客行為、購買與觀看商品、興趣與關注媒體、渠道回應等。" direction="right"></BtnInfoBox>

              <div class="d-flex flex-wrap ps-2 pb-3 label-tag-div">
                <span
                  style="color: #c4c4c4"
                  v-if="
                    labelTagAI[0] === undefined &&
                    labelTagOther[0] === undefined &&
                    labelTagChannel[0] === undefined &&
                    labelTagBrowser[0] === undefined &&
                    labelTagPuchases[0] === undefined
                  "
                  >無資料</span
                >
                <span
                  v-for="items in labelTagBrowser"
                  :key="items"
                  style="
                    background-color: #e0eef3;
                    margin-right: 1rem;
                    margin-top: 15px;
                    padding: 5px;
                    color: #575757;
                    border-radius: 10px;
                    height: 40px;
                  "
                  >{{ items }}</span
                >
                <span
                  v-for="items in labelTagPuchases"
                  :key="items"
                  style="
                    background-color: #feedcd;
                    margin-right: 1rem;
                    margin-top: 15px;
                    padding: 5px;
                    color: #575757;
                    border-radius: 10px;
                    height: 40px;
                  "
                  >{{ items }}</span
                >
                <span
                  v-for="items in labelTagChannel"
                  :key="items"
                  style="
                    background-color: #eaf3f4;
                    margin-right: 1rem;
                    margin-top: 15px;
                    padding: 5px;
                    color: #575757;
                    border-radius: 10px;
                    height: 40px;
                  "
                  >{{ items }}</span
                >
                <span
                  v-for="items in labelTagOther"
                  :key="items"
                  style="
                    background-color: #fcddd9;
                    margin-right: 1rem;
                    margin-top: 15px;
                    padding: 5px;
                    color: #575757;
                    border-radius: 10px;
                    height: 40px;
                  "
                  >{{ items }}</span
                >
                <span
                  v-for="items in labelTagAI"
                  :key="items"
                  style="
                    background-color: #ddede1;
                    margin-right: 1rem;
                    margin-top: 15px;
                    padding: 5px;
                    color: #575757;
                    border-radius: 10px;
                    height: 40px;
                  "
                  >{{ items }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mt-4">
          <div class="col-6 pe-3">
            <div class="section-div-basicinfo p-4" style="position: relative">
              <p style="color: #71afb6">TOP5 消費類別</p>
              <span
                style="position: absolute; top: 46%; left: 48%; color: #c4c4c4"
                v-if="this.barTop5labelSeries[0].data[0] === undefined"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="235"
                :options="barTop5labelOptions"
                :series="barTop5labelSeries"
              ></apexchart>
            </div>
          </div>
          <div class="col-6 ps-3">
            <div class="section-div-basicinfo p-4" style="position: relative">
              <p style="color: #71afb6">TOP5 消費商品</p>
              <span
                style="position: absolute; top: 46%; left: 48%; color: #c4c4c4"
                v-if="this.barTop5ProductSeries[0].data[0] === undefined"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="235"
                :options="barTop5ProductOptions"
                :series="barTop5ProductSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="col-12">
            <div class="section-div-basicinfo p-4">
              <span style="color: #71afb6">AI 推薦購買商品</span>
              <BtnInfoBox content="彙整消費行為、顧客行為、時間序列等資料，以AI推薦引擎技術預測可能的感興趣商品，若該會員沒有交易紀錄，也會透過整體歷史資料進行推薦。" direction="right"></BtnInfoBox>

              <DataTable
                :value="recommendProductTableData"
                :style="{ textAlign: 'center' }"
              >
                <Column field="商品編號" header="商品編號"></Column>
                <Column field="產品類別" header="產品類別"></Column>
                <Column field="品牌名稱" header="品牌名稱"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-4"
      style="position: relative"
      v-if="this.buttonSelected === 'purchaserecord'"
    >
      <div
        class="
          loading-background-bottom-info-in-New360Page
          d-flex
          justify-content-center
          align-items-center
        "
        v-if="loadingBuyRecord"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="d-flex">
          <div
            class="col-12"
            :class="
              this.dropdownIndex === '線上' || this.dropdownIndex === '線下'
                ? 'col-12'
                : ''
            "
          >
            <div
              class="section-div-basicinfo p-4"
              style="height: 100%; position: relative"
            >
              <p style="color: #71afb6">前10筆消費紀錄</p>
              <span
                style="position: absolute; top: 66%; left: 48%; color: #c4c4c4"
                v-if="this.top5ExpensesRecordData[0] === undefined"
                >無資料</span
              >
              <DataTable
                :value="top5ExpensesRecordData"
                :lazy="true"
                :scrollable="true"
                scrollHeight="315px"
              >
                <Column
                  style="white-space: nowrap"
                  field="線上/線下"
                  header="線上/線下"
                  v-if="this.dropdownIndex === '整體'"
                ></Column>
                <Column field="商品編號" header="商品名稱">
                  <template #body="slotProps">
                    <div
                      class="templateTest"
                      :class="
                        slotProps.data.商品編號.length > 20
                          ? 'templateTestWidth'
                          : ''
                      "
                      v-tooltip.top="{
                        value: slotProps.data.商品編號,
                        class: 'tipInCustomRFM',
                      }"
                    >
                      <span> {{ slotProps.data.商品編號 }}</span>
                    </div>
                  </template>
                </Column>
                <Column
                  style="white-space: nowrap"
                  field="銷售額"
                  header="消費金額"
                ></Column>
                <Column
                  style="white-space: nowrap"
                  field="消費日期"
                  header="消費日期"
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="col-6 pe-3" v-if="this.dropdownIndex === '整體'">
            <div
              class="section-div-basicinfo p-4"
              style="height: 100%; position: relative"
            >
              <p style="color: #71afb6">線上/線下消費金額占比</p>
              <span
                class="text-center"
                style="position: absolute; top: 28%; left: 19%; color: #c4c4c4"
                v-if="this.expensesPieSeries.every((d) => d === 0)"
              >
                <img src="../assets/missData.svg" alt="" style="width: 200px" />
                <br />
                無資料</span
              >
              <apexchart
                type="donut"
                height="300"
                :options="expensesPieOptions"
                :series="expensesPieSeries"
              >
              </apexchart>
            </div>
          </div>

          <div
            class="col-6"
            :class="this.dropdownIndex === '整體' ? ' ps-3' : 'pe-3'"
          >
            <div class="d-flex flex-wrap" style="height: 100%">
              <div class="col-6">
                <div class="section-div-basicinfo p-4" style="height: 100%">
                  <p style="color: #71afb6">總消費金額</p>
                  <div class="d-flex justify-content-between">
                    <span>{{ overallThreeData1 }}</span>
                    <span style="color: #c4c4c4">($)</span>
                  </div>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="section-div-basicinfo p-4" style="height: 100%">
                  <p style="color: #71afb6">總消費筆數</p>
                  <div class="d-flex justify-content-between">
                    <span>{{ overallThreeData2 }}</span>
                    <span style="color: #c4c4c4">(筆)</span>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <div class="section-div-basicinfo p-4" style="height: 100%">
                  <p style="color: #71afb6">平均客單價</p>
                  <div class="d-flex justify-content-between">
                    <span>{{ overallThreeData3 }}</span>
                    <span style="color: #c4c4c4">($)</span>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3 ps-3">
                <div class="section-div-basicinfo p-4" style="height: 100%">
                  <p style="color: #71afb6">平均購買間隔時長</p>
                  <div class="d-flex justify-content-between">
                    <span>{{ overallThreeData4 }}</span>
                    <span style="color: #c4c4c4">(天)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-6 ps-3"
            :style="
              this.dropdownIndex === '整體' ? 'display:none' : 'display:block'
            "
          >
            <div class="section-div-basicinfo p-4" style="position: relative">
              <p style="color: #71afb6">購買時序分析</p>
              <span
                style="position: absolute; top: 48%; left: 48%; color: #c4c4c4"
                v-if="this.lineHourSeries[0] === undefined"
                >無資料</span
              >
              <apexchart
                class="columm-div-scroll"
                style="height: 210px; min-height: 150px"
                type="line"
                :width="
                  this.lineHourSeries[0] != undefined &&
                  this.lineHourSeries[0].data.length > 12
                    ? '1000'
                    : '100%'
                "
                height="200"
                :options="lineHourOptions"
                :series="lineHourSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
        <div
          class="mt-4"
          style="display: none"
          :style="this.dropdownIndex === '整體' ? 'display:flex' : ''"
        >
          <div class="col-12">
            <div class="section-div-basicinfo p-4" style="position: relative">
              <p style="color: #71afb6">購買時序分析</p>
              <span
                style="position: absolute; top: 48%; left: 48%; color: #c4c4c4"
                v-if="this.lineHourSeries[0] === undefined"
                >無資料</span
              >
              <apexchart
                class="columm-div-scroll"
                style="height: 210px; min-height: 150px"
                type="line"
                :width="
                  this.lineHourSeries[0] != undefined &&
                  this.lineHourSeries[0].data.length > 12
                    ? '100%'
                    : '100%'
                "
                height="200"
                :options="lineHourOptions"
                :series="lineHourSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
        <div class="d-flex mt-4">
          <div class="col-6 pe-3">
            <div class="section-div-basicinfo p-4" style="position: relative">
              <p style="color: #71afb6">購買時間點(星期)</p>
              <span
                style="position: absolute; top: 46%; left: 49%; color: #c4c4c4"
                v-if="this.barWeekSeries[0].data.every((d) => d === 0)"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="250"
                :options="barWeekOptions"
                :series="barWeekSeries"
              >
              </apexchart>
            </div>
          </div>
          <div class="col-6 ps-3">
            <div class="section-div-basicinfo p-4" style="position: relative">
              <p style="color: #71afb6">購買時間點(小時)</p>
              <span
                style="position: absolute; top: 46%; left: 49%; color: #c4c4c4"
                v-if="this.barHourSeries[0].data.every((d) => d === 0)"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="250"
                :options="barHourOptions"
                :series="barHourSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-4"
      style="position: relative"
      v-if="this.buttonSelected === 'online'"
    >
      <div
        class="
          loading-background-bottom-info-in-New360Page
          d-flex
          justify-content-center
          align-items-center
        "
        v-if="loadingOnlineBehavior"
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="d-flex">
          <div class="col-12">
            <div class="section-div-basicinfo p-4" style="position: relative">
              <div class="d-flex justify-content-between">
                <p style="color: #71afb6">顧客網站行為</p>
                <div class="title-button-div fs-small mb-3" style="width: 70%">
                  <button
                    class="button-nofocus"
                    :class="
                      this.onlineTableKey === '瀏覽商品名單'
                        ? 'button-focus'
                        : ''
                    "
                    @click="clickBrowserProduct"
                  >
                    造訪頁面名稱
                  </button>
                  <button
                    class="button-nofocus"
                    :class="
                      this.onlineTableKey === '點擊商品名單'
                        ? 'button-focus'
                        : ''
                    "
                    @click="clickClickProduct"
                  >
                    點擊商品名單
                  </button>
                  <button
                    class="button-nofocus"
                    :class="
                      this.onlineTableKey === '點擊加入購物車名單'
                        ? 'button-focus'
                        : ''
                    "
                    @click="clickAddToCartProduct"
                  >
                    點擊加入購物車名單
                  </button>
                </div>
              </div>
              <span
                style="position: absolute; top: 52%; left: 48%; color: #c4c4c4"
                v-if="this.onlineSelectedTable[0] === undefined"
                >無資料</span
              >
              <DataTable
                :value="this.onlineSelectedTable"
                :lazy="true"
                :scrollable="true"
                scrollHeight="275px"
              >
                <Column
                  field="項目"
                  header="頁面名稱"
                  v-if="this.onlineTableKey === '瀏覽商品名單'"
                ></Column>
                <Column
                  field="數量"
                  header="點擊次數"
                  v-if="this.onlineTableKey === '瀏覽商品名單'"
                ></Column>

                <Column
                  field="商品編號"
                  header="商品名稱"
                  v-if="this.onlineTableKey === '點擊加入購物車名單'"
                >
                  <template #body="slotProps2">
                    <div style="padding: 5px 0">
                      <div
                        class="too-over-div"
                        style="max-width: 180px"
                        v-tooltip.bottom="
                          slotProps2.data.商品編號?.length > 8
                            ? {
                                value: slotProps2.data.商品編號,
                                class: 'tip-LabelManage',
                              }
                            : { value: '', class: '' }
                        "
                      >
                        {{ slotProps2.data.商品編號 }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  field="產品類別"
                  header="產品類別"
                  v-if="this.onlineTableKey === '點擊加入購物車名單'"
                ></Column>
                <Column
                  field="count"
                  header="次數"
                  v-if="this.onlineTableKey === '點擊加入購物車名單'"
                ></Column>

                <Column
                  field="商品編號"
                  header="商品名稱"
                  v-if="this.onlineTableKey === '點擊商品名單'"
                >
                  <template #body="slotProps2">
                    <div style="padding: 5px 0">
                      <div
                        class="too-over-div"
                        style="max-width: 180px"
                        v-tooltip.bottom="
                          slotProps2.data.商品編號?.length > 8
                            ? {
                                value: slotProps2.data.商品編號,
                                class: 'tip-LabelManage',
                              }
                            : { value: '', class: '' }
                        "
                      >
                        {{ slotProps2.data.商品編號 }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  field="產品類別"
                  header="產品類別"
                  v-if="this.onlineTableKey === '點擊商品名單'"
                ></Column>
                <Column
                  field="count"
                  header="次數"
                  v-if="this.onlineTableKey === '點擊商品名單'"
                ></Column>

                <!-- <Column field="銷售額" header="銷售額"></Column> -->
              </DataTable>
            </div>
          </div>
        </div>

        <div class="d-flex mt-4">
          <div class="col-6 pe-3">
            <div
              class="section-div-basicinfo p-4"
              style="height: 100%; position: relative"
            >
              <p style="color: #71afb6">過往行銷紀錄</p>
              <span
                style="position: absolute; top: 52%; left: 48%; color: #c4c4c4"
                v-if="this.passRecordTable[0] === undefined"
                >無資料</span
              >
              <DataTable
                :value="this.passRecordTable"
                :lazy="true"
                :scrollable="true"
                scrollHeight="315px"
              >
                <Column field="日期" header="日期"></Column>
                <Column field="行銷活動" header="行銷活動"></Column>
                <Column field="有無回應" header="有無回應"></Column>
              </DataTable>
            </div>
          </div>

          <div class="col-6 ps-3">
            <div
              class="section-div-basicinfo p-4"
              style="height: 100%; position: relative"
            >
              <p style="color: #71afb6">TOP5 頁面停留時間</p>
              <span
                style="position: absolute; top: 43%; left: 48%; color: #c4c4c4"
                v-if="this.barStopByTimeSeries[0].data[0] === undefined"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="275"
                :options="barStopByTimeOptions"
                :series="barStopByTimeSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>

        <div class="d-flex mt-4">
          <div class="col-6 pe-3">
            <div
              class="section-div-basicinfo p-4"
              style="height: 100%; position: relative"
            >
              <p style="color: #71afb6">各渠道投放次數</p>
              <span
                class="text-center"
                style="position: absolute; top: 29%; left: 29%; color: #c4c4c4"
                v-if="this.pieChannelSeries.every((d) => d === 0)"
              >
                <img src="../assets/missData.svg" alt="" style="width: 200px" />
                <br />
                無資料</span
              >
              <apexchart
                type="donut"
                height="300"
                :options="pieChannelOptions"
                :series="pieChannelSeries"
              >
              </apexchart>
            </div>
          </div>
          <div class="col-6 ps-3">
            <div
              class="section-div-basicinfo p-4"
              style="height: 100%; position: relative"
            >
              <p style="color: #71afb6">各渠道回應比例</p>
              <span
                style="position: absolute; top: 52%; left: 48%; color: #c4c4c4"
                v-if="this.barChannelSeries[0].data.every((d) => d === null)"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="255"
                :options="barChannelOptions"
                :series="barChannelSeries"
              >
              </apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { allapi } from "../../public/static/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import DropdownAll from "../components/DropdownAll.vue";
import DropdownCheckbox from "../components/CustomRFM/DropdownCheckbox.vue";
import DropdownMoreOption from "../components/CustomRFM/DropdownMoreOption.vue";
import DropdownTypeAI from "../components/CustomRFM/DropdownTypeAI.vue";
import DropdownType from "../components/CustomRFM/DropdownType.vue";
import Dropdown from "../components/Dropdown.vue";
import VueApexCharts from "vue3-apexcharts";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  components: {
    DataTable,
    Column,
    Dropdown,
    DropdownAll,
    DropdownCheckbox,
    DropdownMoreOption,
    DropdownType,
    DropdownTypeAI,
    apexchart: VueApexCharts,
    BtnInfoBox,
  },

  setup() {
    // const selectedProducts3 = ref();

    const filterDropdown = ref(false);
    const filterDropdownRef = ref(null);

    onClickOutside(filterDropdownRef, () => {
      filterDropdown.value = false;
    });

    return {
      filterDropdown,
      filterDropdownRef,
    };
  },
  data() {
    return {
      valueLoading: "",

      RFMsImg: {
        新客客群: require("../assets/RFMgroupPic0.svg"),
        高價值客群: require("../assets/RFMgroupPic1.svg"),
        忠誠客群: require("../assets/RFMgroupPic2.svg"),
        沉睡客群: require("../assets/RFMgroupPic3.svg"),
        流失客群: require("../assets/RFMgroupPic4.svg"),
        未購買客群: require("../assets/RFMgroupPic5.svg"),
        "- -": require("../assets/RFMgroupPic5.svg"),
      },

      loadingBasic: true,
      loadingBuyRecord: true,
      loadingOnlineBehavior: true,
      loadingTableWrapper: false,
      loading: false,
      loadingPass: "",
      memberShow: false,
      AIShow: false,
      AIShow2: false,

      selectedProducts3: {},
      selectedProducts3MemberID: 1,
      loadingTable: false,
      tableData: [],
      searchInput: "",
      searchTable: [],

      onlineTableKey: "瀏覽商品名單",

      dropdownIndex: "整體",
      top10PageSelector: "整體",
      selectFilter: false,

      timeState: ["全部時間", "時間段", "時間內"],
      rangeday: {
        start: "2022-07-05",
        end: "2022-07-12",
      },

      // 基本的值
      mode: ["或", "且"],
      tagType: ["基本屬性", "網站行為", "消費行為"],
      websites: ["桂冠官網", "營養研究室"],
      overall: ["整體", "線上", "線下"],
      customWeb: {
        客製標籤一: [],
        客製標籤二: [],
      },
      allKindTag: {
        基本屬性: ["性別", "年齡級距", "生日"],
        網站行為: [
          "造訪商品名稱",
          "有來造訪",
          "平均瀏覽時間",
          "有加入購物車但未結",
        ],
        消費行為: ["幾天內有購買的人", "購買的商品名稱", "客單價分級"],
      },
      allTagValue: {
        性別: ["男", "女"],
        年齡級距: "range",
        生日: [
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        造訪商品名稱: "click",
        有來造訪: "count",
        平均瀏覽時間: "range",
        有加入購物車但未結: "count",
        幾天內有購買的人: "count",
        購買的商品名稱: [
          "Aadsadwasdasadadwadsadsadwda商品",
          "B商品",
          "L商品",
          "K商品",
          "T商品",
        ],
        客單價分級: ["M1", "S1", "T1"],
      },
      apiTagValue: {},
      moreOrless: ["-", "以上(含)", "以下(含)"],
      moreOrlessForCost: ["-", "大於", "區間", "小於"],
      moreOrlessForCost2: ["大於", "區間", "小於"],
      // 判斷值的狀態
      allTagMoreOption: {
        性別: "notime-nocount",
        年齡級距: "notime-nocount",
        生日: "notime-nocount",
        造訪商品名稱: "time-count",
        有來造訪: "time-count",
        平均瀏覽時間: "time-count",
        有加入購物車但未結: "time-nocount",
        幾天內有購買的人: "time-count",
        購買的商品名稱: "time-count",
        客單價分級: "notime-nocount",
      },
      allTypeWebState: {
        基本屬性: "noweb",
        網站行為: "web",
        消費行為: "overall",
      },
      allTagMoreOptionUnit: {},

      customRFM_tableValue: [
        {
          mode: "初",
          type: "",
          web: "",
          tag: "",
          value: "",
        },
      ],
      repeatTypeArr: [],

      ids: 0,
      notEnd: true,
      bottom: false,
      searchload: false,

      totalPeople: "",
      // basicInfoState
      buttonSelected: "basicinfo",
      basicInfoData: "",
      RFMData: "",
      RFMDataLast: 0,
      barTop5labelData: [],
      barTop5labelLabel: [],
      barTop5productData: [],
      barTop5productLabel: [],
      recommendProductTableData: [],

      barTop5labelSeries: [],
      barTop5labelOptions: {},
      barTop5ProductSeries: [],
      barTop5ProductOptions: {},

      // purchaseRecordState
      top5ExpensesRecordRes: "",
      top5ExpensesRecordData: [],
      expensesPieRes: "",
      expensesPieData: {},
      overallThreeRes: "",
      overallThreeData1: "",
      overallThreeData2: "",
      overallThreeData3: "",
      overallThreeData4: "",
      overallHourRes: "",
      overallTimelineData: [],
      overallHourTime: [],
      overWeekRes: "",
      overWeekData: [],
      overWeekLabel: [],
      overHourRes: "",
      overHourData: [],
      overHourLabel: [],
      tableRes: "",
      channelPieData: [],
      channelPieLabel: {},

      channelBarData: [],
      channelBarLabel: {},

      stopByPieData: [],
      stopByPieLabel: [],

      expensesPieSeries: [],
      expensesPieOptions: {},

      lineHourSeries: [],
      lineHourOptions: {},

      barWeekSeries: [],
      barWeekOptions: {},
      barHourSeries: [],
      barHourOptions: {},

      barStopByTimeSeries: [],
      barStopByTimeOptions: {},

      pieChannelSeries: [],
      pieChannelOptions: {},

      barChannelSeries: [],
      barChannelOptions: {},

      onlineSelectedTable: [],
      passRecordTable: [],

      labelTagAI: [],
      labelTagOther: [],
      labelTagChannel: [],
      labelTagBrowser: [],
      labelTagPuchases: [],
    };
  },

  watch: {
    dropdownIndex() {
      console.log("selector", this.dropdownIndex);
      this.top5ExpensesRecordData =
        this.top5ExpensesRecordRes[this.dropdownIndex];
      this.expensesPieData = this.expensesPieRes[this.dropdownIndex];

      if (this.overallThreeRes[this.dropdownIndex] === undefined) {
        this.overallThreeData1 = 0;
        this.overallThreeData2 = 0;
        this.overallThreeData3 = 0;
        this.overallThreeData4 = 0;
      } else {
        this.overallThreeData1 =
          this.overallThreeRes[
            this.dropdownIndex
          ].平均購買間隔時長.toLocaleString(0);
        this.overallThreeData2 =
          this.overallThreeRes[this.dropdownIndex].總消費筆數;
        this.overallThreeData3 = Number(
          this.overallThreeRes[this.dropdownIndex].平均客單價.toFixed(0)
        ).toLocaleString();
        if (
          this.overallThreeRes[this.dropdownIndex].平均購買間隔時長 === null
        ) {
          this.overallThreeData4 = 0;
        } else {
          this.overallThreeData4 =
            this.overallThreeRes[this.dropdownIndex].平均購買間隔時長.toFixed(
              0
            );
        }
        this.overallTimelineData =
          this.overallHourRes[this.dropdownIndex].sales;
        this.overallTimelineLabel =
          this.overallHourRes[this.dropdownIndex].date;
        this.overWeekData = this.overWeekRes[this.dropdownIndex].sales;
        this.overWeekLabel = this.overWeekRes[this.dropdownIndex].week;
        this.overHourData = this.overHourRes[this.dropdownIndex].sales;
        this.overHourLabel = this.overHourRes[this.dropdownIndex].time;
      }
      setTimeout(() => {
        this.lineChartHour();
      }, 0);
      this.PieChartExpenses();
      this.BarChartWeek();
      this.BarChartHour();
    },
    searchInput(val) {
      // this.searchTable = this.tableData.filter((d) => {
      //   return (
      //     d.會員ID.includes(val) ||
      //     d.姓名.includes(val) ||
      //     d.電話.includes(val) ||
      //     d.Email.includes(val)
      //   );
      // });
    },
    selectedProducts3MemberID() {
      // this.loading = true
      if (this.loadingPass === "ok") {
        setTimeout(() => {
          this.loading = false;
        }, 8000);
      }
    },
    selectedProducts3: async function () {
      console.log("watch options api", this.selectedProducts3);

      this.selectedProducts3MemberID = this.selectedProducts3.member_id;

      this.loading = true;

      if (this.buttonSelected === "basicinfo") {
        this.loading = true;
        (this.loadingBasic = true),
          (this.loadingBuyRecord = true),
          (this.loadingOnlineBehavior = true),
          await Promise.all([
            this.getBasicinfo(),
            this.getRFM(),
            this.getmemberBasicPreferGet(),
            this.getmemberBasicLabelGet(),
            this.getmemberBasicRecommendGet(),
          ]);
        (this.loadingBasic = false),
          this.BarchartTop5label(),
          this.BarchartTop5Product(),
          await Promise.all([
            //  this.loading = false,
            this.getmemberOverallPurchaseGet(),
            this.getmemberOverallShopieGet(),
            this.getmemberOverallThreeGet(),
            this.getmemberOverallHourGet(),
            this.getmemberOverallWeekGet(),
            this.getmemberOverallLinechartGet(),

            this.getmemberOnlineListsGet(),
            this.getmemberOnlineTrackGet(),
            this.getmemberOnlineViewGet(),
            this.getmemberOnlineLfgepieGet(),
            this.getmemberOnlineResponseGet(),
            (this.loading = false),
          ]);
        (this.loadingBuyRecord = false),
          (this.loadingOnlineBehavior = false),
          this.PieChartExpenses();
        this.lineChartHour();
        this.BarChartWeek();
        this.BarChartHour();

        this.BarStopBy();
        this.PieChannel();
        this.BarChannel();
      } else if (this.buttonSelected === "purchaserecord") {
        //  this.loading = true
        (this.loadingBasic = true),
          (this.loadingBuyRecord = true),
          (this.loadingOnlineBehavior = true),
          await Promise.all([
            this.getmemberOverallPurchaseGet(),
            this.getmemberOverallShopieGet(),
            this.getmemberOverallThreeGet(),
            this.getmemberOverallHourGet(),
            this.getmemberOverallWeekGet(),
            this.getmemberOverallLinechartGet(),
          ]);
        this.PieChartExpenses();
        this.lineChartHour();
        this.BarChartWeek();
        this.BarChartHour();
        (this.loadingBuyRecord = false),
          await Promise.all([
            this.getBasicinfo(),
            this.getRFM(),
            this.getmemberBasicPreferGet(),
            this.getmemberBasicLabelGet(),
            this.getmemberBasicRecommendGet(),
            this.getmemberOnlineListsGet(),
            this.getmemberOnlineTrackGet(),
            this.getmemberOnlineViewGet(),
            this.getmemberOnlineLfgepieGet(),
            this.getmemberOnlineResponseGet(),
            (this.loading = false),
          ]);
        (this.loadingBasic = false),
          (this.loadingOnlineBehavior = false),
          this.BarchartTop5label(),
          this.BarchartTop5Product(),
          this.BarStopBy();
        this.PieChannel();
        this.BarChannel();
      } else if (this.buttonSelected === "online") {
        this.loading = true;
        (this.loadingBasic = true),
          (this.loadingBuyRecord = true),
          (this.loadingOnlineBehavior = true),
          await Promise.all([
            this.getmemberOnlineListsGet(),
            this.getmemberOnlineTrackGet(),
            this.getmemberOnlineViewGet(),
            this.getmemberOnlineLfgepieGet(),
            this.getmemberOnlineResponseGet(),
          ]);
        this.BarStopBy();
        this.PieChannel();
        this.BarChannel();
        (this.loadingOnlineBehavior = false),
          await Promise.all([
            this.getBasicinfo(),
            this.getRFM(),
            this.getmemberBasicPreferGet(),
            this.getmemberBasicLabelGet(),
            this.getmemberBasicRecommendGet(),
            this.getmemberOverallPurchaseGet(),
            this.getmemberOverallShopieGet(),
            this.getmemberOverallThreeGet(),
            this.getmemberOverallHourGet(),
            this.getmemberOverallWeekGet(),
            this.getmemberOverallLinechartGet(),
            (this.loading = false),
          ]);
        (this.loadingBasic = false),
          (this.loadingBuyRecord = false),
          this.PieChartExpenses();
        this.lineChartHour();
        this.BarChartWeek();
        this.BarChartHour();
        this.BarchartTop5label();
        this.BarchartTop5Product();
      }
    },
  },

  created: async function () {
    this.loading = true;
    this.loadingTable = true;
    this.loadingTableWrapper = false;
    await this.searchUpdate(), (this.selectedProducts3 = this.tableData[0]);
    await Promise.all([
      this.customlabelInfoPost("web"),
      this.customlabelInfoPost("overall"),
      this.customlabelStructrueGet(),
    ]);
    const datatable = document
      .getElementById("datatable")
      .getElementsByClassName("p-datatable-wrapper");
    console.log(datatable[0]);
    datatable[0].addEventListener("scroll", (e) => this.scrollTable(e));
  },

  methods: {
    openExplainAIModal() {
      const explainAIModal = new bootstrap.Modal(
        document.getElementById("explainAIModal"),
        {
          keyboard: false,
        }
      );
      explainAIModal.show();
    },
    // stockClass(){
    //   return[
    //     {
    //       'text-eli':
    //     }
    //   ];
    // },

    //filter function
    selectValueByDrop_mode(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].mode = selectValue;
    },
    selectValueByDrop_tagType(selectValue, changingSelected, index) {
      if (this.allTypeWebState[selectValue] === "noweb") {
        this.customRFM_tableValue[+changingSelected].web = "noweb";
      } else if (this.allTypeWebState[selectValue] === "web") {
        this.customRFM_tableValue[+changingSelected].web = this.websites[0];
      } else if (this.allTypeWebState[selectValue] === "overall") {
        this.customRFM_tableValue[+changingSelected].web = this.overall[0];
      } else {
        this.customRFM_tableValue[+changingSelected].web = "";
      }
      if (selectValue === "價值分群") {
        this.customRFM_tableValue[+changingSelected].type = selectValue;
        this.customRFM_tableValue[+changingSelected].tag = "價值分群";
        this.customRFM_tableValue[+changingSelected].value = [];
        delete this.customRFM_tableValue[+changingSelected].more_option;
      } else {
        this.customRFM_tableValue[+changingSelected].type = selectValue;
        this.customRFM_tableValue[+changingSelected].tag = "";
        this.customRFM_tableValue[+changingSelected].value = [];
        delete this.customRFM_tableValue[+changingSelected].more_option;
      }
    },
    selectValueByDrop_web(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].web = selectValue;
    },
    selectValueByDrop_tag: async function (
      selectValue,
      changingSelected,
      index
    ) {
      this.customRFM_tableValue[+changingSelected].tag = selectValue;
      delete this.customRFM_tableValue[+changingSelected].more_option;

      if (this.allTagValue[selectValue] === "count") {
        this.customRFM_tableValue[+changingSelected].value = "all";
      } else if (this.allTagValue[selectValue].includes("range")) {
        this.customRFM_tableValue[+changingSelected].value = {
          low: "",
          high: "",
        };
      } else if (this.allTagValue[selectValue] === "costrange") {
        this.customRFM_tableValue[+changingSelected].value = {
          cost: "",
          low: "",
          high: "",
          moreOrless: "",
        };
      } else if (
        typeof this.allTagValue[selectValue] === "object" &&
        this.allTagValue[selectValue][0] != undefined
      ) {
        this.customRFM_tableValue[+changingSelected].value = [];
      } else {
        this.customRFM_tableValue[+changingSelected].value = [];
        this.valueLoading = this.customRFM_tableValue[+changingSelected].tag;
        await this.customlabelInfoPost(
          this.allTagValue[selectValue],
          this.customRFM_tableValue[+changingSelected].web,
          this.customRFM_tableValue[+changingSelected].tag
        );
      }
      console.log("給後端的資料", this.customRFM_tableValue);
    },
    selectValueByDrop_timeState(selectValue, changingSelected, index) {
      console.log("123", selectValue);
      if (index === 1) {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = selectValue.split("~")[0];
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = selectValue.split("~")[1];
      } else if (index === 2) {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = selectValue;
      } else if (index === 0) {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = "2000-01-01";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = new Date()
          .toISOString()
          .slice(0, 10);
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = "";
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    selectValueByDrop_moreOrless(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].more_option.moreOrless =
        selectValue;
    },
    selectValueByDrop_moreOrlessForCost(selectValue, changingSelected, index) {
      this.customRFM_tableValue[
        +changingSelected
      ].more_option.cost.moreOrlessForCost = selectValue;
      this.customRFM_tableValue[+changingSelected].more_option.cost.lowCost =
        "";
      this.customRFM_tableValue[+changingSelected].more_option.cost.highCost =
        "";
      this.customRFM_tableValue[+changingSelected].more_option.cost.cost = "";
    },
    selectValueByDrop_moreOrlessForCost2(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].value.moreOrless =
        selectValue;
      this.customRFM_tableValue[+changingSelected].value.low = "";
      this.customRFM_tableValue[+changingSelected].value.high = "";
      this.customRFM_tableValue[+changingSelected].value.cost = "";
    },
    selectValueByDrop(selectValue, changingSelected, index) {
      this[changingSelected] = selectValue;
    },

    // 值是arr的 function
    selectValueByDrop_Checkbox(selectValueArr, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].value = selectValueArr;
    },
    deleteCustomTableValue(item, index) {
      this.customRFM_tableValue[index].value = this.customRFM_tableValue[
        index
      ].value.filter((d) => !d.includes(item));
    },
    // 值是range的 function
    updateCustomTableRange(e, index, valueindex) {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      this.customRFM_tableValue[index].value[valueindex] = +e.target.value;
    },
    updateRangeday(index) {
      if (typeof this.rangeday.start != "string") {
        this.customRFM_tableValue[index].value.low = this.rangeday.start
          .toISOString()
          .slice(0, 10);
        this.customRFM_tableValue[index].value.high = this.rangeday.end
          .toISOString()
          .slice(0, 10);
      }
      console.log(this.customRFM_tableValue[index].value);
    },
    // update more_option.count的 function
    updateLowCost(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.cost.lowCost =
          e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.cost.lowCost =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateHighCost(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.cost.highCost =
          e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.cost.highCost =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateLowCost2(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].value.low = e.target.value;
      } else {
        this.customRFM_tableValue[index].value.low = +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateHighCost2(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].value.high = e.target.value;
      } else {
        this.customRFM_tableValue[index].value.high = +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateCost(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.cost.cost = e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.cost.cost =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateCost2(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].value.cost = e.target.value;
      } else {
        this.customRFM_tableValue[index].value.cost = +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    // update more_option.count的 function
    updateCount(e, index) {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.count.count =
          e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.count.count =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    customlabelInfoPost: async function (item, web, tag) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        item: item,
      };

      await allapi2
        .customlabelInfoPost(params, body)
        .then((res) => {
          console.log("tagValue資訊", res);
          if (item === "web") {
            this.websites = res.data.item;
          } else if (item === "overall") {
            this.overall = res.data.item;
          } else if (web === "noweb") {
            this.apiTagValue[tag] = res.data.item;
          } else if (
            this.websites.includes(web) ||
            this.overall.includes(web)
          ) {
            this.apiTagValue[tag] = res.data.item[web];
          } else {
            this.apiTagValue[tag] = res.data.item;
          }
          this.valueLoading = "";
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.valueLoading = "";
          this.loading = false;
          alert("連線有誤");
        });
    },
    // 值是要CALL 在下面

    // 新增或刪除table
    add_customRFM_tableValue(index) {
      const emptyObj = {
        mode: "或",
        type: "",
        web: "",
        tag: "",
        value: [],
      };
      this.customRFM_tableValue.splice(index + 1, 0, emptyObj);
    },
    delete_customRFM_tableValue(index) {
      if (this.customRFM_tableValue.length === 1) {
        this.customRFM_tableValue = [
          {
            mode: "初",
            type: "",
            web: "",
            tag: "",
            value: [],
          },
        ];
      } else {
        this.customRFM_tableValue.splice(index, 1);
      }
    },
    addMoreOption(index) {
      this.customRFM_tableValue[index].more_option = {
        time_range: {
          start_time: "2000-01-01",
          end_time: new Date().toISOString().slice(0, 10),
          days: "",
        },
        count: {
          count: "",
          moreOrless: "-",
        },
        cost: {
          cost: "",
          lowCost: "",
          highCost: "",
          moreOrlessForCost: "-",
        },
      };
    },
    deleteMoreOption(index) {
      delete this.customRFM_tableValue[index].more_option;
    },

    beforeRatePostCustomTable() {
      this.loadingTableWrapper = true;
      this.selectFilter = true;
      this.customRFM_tableValue.map((d) => {
        if (
          d.more_option != undefined &&
          d.more_option.time_range.start_time === "" &&
          d.more_option.time_range.days === ""
        ) {
          delete d.more_option;
        }
      });
      let missdata = false;

      this.customRFM_tableValue.map((d) => {
        if (Object.values(d).includes("")) {
          missdata = true;
        }
        if (
          Object.prototype.toString.call(d.value) === "[object Array]" &&
          d.value[0] === undefined
        ) {
          missdata = true;
        }
        // if (
        //   Object.prototype.toString.call(d.value) === "[object Object]" &&
        //   (d.value.low === "" || d.value.high === "")
        // ) {
        //   missdata = true;
        // }
        if (
          (d.value.moreOrless === "大於" || d.value.moreOrless === "小於") &&
          d.value.cost === ""
        ) {
          missdata = true;
        }
        if (
          d.value.moreOrless === "區間" &&
          (d.value.lowCost === "" || d.value.highCost === "")
        ) {
          missdata = true;
        }
        if (
          d.value.moreOrless === "區間" &&
          d.value.lowCost > d.value.highCost
        ) {
          missdata = true;
        }
        if (d.value.moreOrless === undefined && Object.values(d).includes("")) {
          return alert("區間左側數值不能大於右側");
        }
      });
      console.log("給後端的資料", this.customRFM_tableValue);
      if (missdata) {
        this.loadingTableWrapper = false;
        return alert("資料有缺");
      } else {
        console.log("給後端的資料", this.customRFM_tableValue);
        this.searchUpdate();
        // this.loading = false
        // this.showCustomTable()
      }
      this.searchTable = this.tableData;
      this.filterDropdown = false;
      // this.loading = false
      // console.log("給後端的資料", fixedCustomRFM_tableValue);
      // console.log("給後端的資料", Object.values(this.customRFM_tableValue[4]));
    },

    // api
    // 拿值要CALL的API

    customlabelStructrueGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelStructrueGet(params)
        .then((res) => {
          console.log("基本資訊", res);
          const splitType = res.data.type.map((d) => d.split("_"));
          const fullTypeArr = splitType.map((d) => d[0]);
          const noRepeatTypeArr = fullTypeArr.filter(function (
            element,
            index,
            arr
          ) {
            return arr.indexOf(element) === index;
          });
          this.tagType = noRepeatTypeArr;

          this.repeatTypeArr = noRepeatTypeArr.slice(5);
          for (let index = 0; index < this.repeatTypeArr.length; index++) {
            const customWebKey = splitType
              .filter((d) => d[0] === this.repeatTypeArr[index])
              .map((d) => d[1])
              .filter((d) => d != undefined);
            this.customWeb[this.repeatTypeArr[index]] = customWebKey;
          }

          this.allTypeWebState = res.data.web;
          this.allKindTag = res.data.tag;
          this.allTagValue = res.data.value;
          this.allTagMoreOption = res.data.more_option;
          this.allTagMoreOptionUnit = res.data.count_unit;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    scrollTable: async function (e) {
      const { target } = e;
      if (
        Math.ceil(target.scrollTop) >=
        target.scrollHeight - target.offsetHeight
      ) {
        if (this.searchInput != "") {
          console.log("miao");
        } else if (this.notEnd) {
          if (this.searchload === false) {
            this.loadingTable = true;
            await this.searchMore();

            // this.searchload = false;
            this.loadingTable = false;
          }
        }
        this.bottom = true;
      }

      console.log("this.bottom", this.bottom);
    },

    clickBasicInfo() {
      this.buttonSelected = "basicinfo";
    },
    clickPurchaseRecord() {
      this.buttonSelected = "purchaserecord";
    },
    clickOnline() {
      this.buttonSelected = "online";
    },

    clickBrowserProduct() {
      this.onlineTableKey = "瀏覽商品名單";
      this.onlineSelectedTable = this.tableRes[this.onlineTableKey];
    },
    clickAddToCartProduct() {
      this.onlineTableKey = "點擊加入購物車名單";
      this.onlineSelectedTable = this.tableRes[this.onlineTableKey];
    },
    clickClickProduct() {
      this.onlineTableKey = "點擊商品名單";
      this.onlineSelectedTable = this.tableRes[this.onlineTableKey];
    },

    getBasicinfo: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberBasicInfoGet(params)
        .then((res) => {
          console.log("es", res);
          this.basicInfoData = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getRFM: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberBasicRfmGet(params)
        .then((res) => {
          console.log("RFM", res);
          this.RFMData = res.data.Data;
          if (this.RFMData.消費金額_NT === null) {
            this.RFMData = {
              客群: "未購買客群",
              最近消費: "R",
              最近消費_天: "- -",
              消費金額: "F",
              消費金額_NT: "- -",
              消費頻率: "M",
              消費頻率_次: "- -",
            };
            this.RFMDataLast = "- -";
          } else {
            this.RFMDataLast = this.RFMData.消費金額_NT.toLocaleString();
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getmemberBasicPreferGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberBasicPreferGet(params)
        .then((res) => {
          console.log("getmemberBasicPreferGet", res);
          this.barTop5labelData = res.data.Data.偏好商品.消費金額;
          this.barTop5labelLabel = res.data.Data.偏好商品.商品編號;
          this.barTop5productData = res.data.Data.偏好類別.消費金額;
          this.barTop5productLabel = res.data.Data.偏好類別.類別名稱;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberBasicRecommendGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberBasicRecommendGet(params)
        .then((res) => {
          console.log("memberBasicRecommendGet", res);
          this.recommendProductTableData = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getmemberOverallPurchaseGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberOverallPurchaseGet(params)
        .then((res) => {
          console.log("getmemberOverallPurchaseGet", res);
          this.top5ExpensesRecordRes = res.data.Data;
          this.top5ExpensesRecordData =
            this.top5ExpensesRecordRes[this.dropdownIndex];
          console.log(
            "  this.top5ExpensesRecordData",
            this.top5ExpensesRecordData
          );
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOverallShopieGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberOverallShopieGet(params)
        .then((res) => {
          console.log("getmemberOverallShopieGet", res);
          this.expensesPieRes = res.data.Data;
          // this.expensesPieData = this.expensesPieRes[this.dropdownIndex]
          console.log("  this.expensesPieData", this.expensesPieData);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOverallThreeGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberOverallThreeGet(params)
        .then((res) => {
          console.log("memberOverallThreeGet", res);
          this.overallThreeRes = res.data.Data;
          if (this.overallThreeRes[this.dropdownIndex] === undefined) {
            this.overallThreeData1 = "- -";
            this.overallThreeData2 = "- -";
            this.overallThreeData3 = "- -";
            this.overallThreeData4 = "- -";
          } else {
            this.overallThreeData1 =
              this.overallThreeRes[
                this.dropdownIndex
              ].總消費金額.toLocaleString();
            this.overallThreeData2 =
              this.overallThreeRes[this.dropdownIndex].總消費筆數;
            this.overallThreeData3 = Number(
              this.overallThreeRes[this.dropdownIndex].平均客單價.toFixed(0)
            ).toLocaleString();
            this.overallThreeData4 =
              this.overallThreeRes[this.dropdownIndex].平均購買間隔時長.toFixed(
                0
              );
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getmemberOverallLinechartGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberOverallLinechartGet(params)
        .then((res) => {
          console.log("OverallHLinechartGetourGet", res);
          this.overallHourRes = res.data.Data;
          if (this.overallHourRes[this.dropdownIndex] === undefined) {
            this.overallTimelineData = [];
            this.overallTimelineLabel = [];
          } else {
            this.overallTimelineData =
              this.overallHourRes[this.dropdownIndex].sales;
            this.overallTimelineLabel =
              this.overallHourRes[this.dropdownIndex].date;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOverallWeekGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberOverallWeekGet(params)
        .then((res) => {
          console.log("OverallWeekGet", res);
          this.overWeekRes = res.data.Data;
          this.overWeekData = this.overWeekRes[this.dropdownIndex].sales;
          this.overWeekLabel = this.overWeekRes[this.dropdownIndex].week;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOverallHourGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberOverallHourGet(params)
        .then((res) => {
          console.log("OverallHourGet", res);
          this.overHourRes = res.data.Data;
          this.overHourData = this.overHourRes[this.dropdownIndex].sales;
          this.overHourLabel = this.overHourRes[this.dropdownIndex].time;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberBasicLabelGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      allapi
        .memberBasicLabel1Get(params)
        .then((res) => {
          console.log("BasicLabe11111", res);
          this.labelTagBrowser = res.data.Data.瀏覽產品類別;
          this.labelTagPuchases = res.data.Data.購買產品類別;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });

      allapi
        .memberBasicLabel2Get(params)
        .then((res) => {
          console.log("BasicLabe22222", res);
          this.labelTagOther = res.data.Data.其他;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });

      allapi
        .memberBasicLabel3Get(params)
        .then((res) => {
          console.log("BasicLabe33333", res);
          this.labelTagAI = res.data.Data.AI;
          this.labelTagChannel = res.data.Data.渠道;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOnlineListsGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberOnlineListsGet(params)
        .then((res) => {
          console.log("memberOnlineListsGet", res);
          // console.log('this.onlineTableKey',this.onlineTableKey)
          this.tableRes = res.data.Data;
          this.onlineSelectedTable = this.tableRes[this.onlineTableKey];
          console.log("this.onlineSelectedTable", this.onlineSelectedTable);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getmemberOnlineTrackGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberOnlineTrackGet(params)
        .then((res) => {
          console.log("memberOnlineTrackGet", res);
          this.passRecordTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOnlineViewGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberOnlineViewGet(params)
        .then((res) => {
          console.log("memberOnlineViewGet", res);
          this.stopByPieData = res.data.Data.停留秒數;
          console.log("this.stopByPieData", this.stopByPieData);
          this.stopByPieLabel = res.data.Data.商品編號;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getmemberOnlineLfgepieGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };

      await allapi
        .memberOnlineLfgepieGet(params)
        .then((res) => {
          console.log("memberOnlineLfgepieGet", res);
          this.channelPieData = res.data.Data;
          this.channelPieLabel = Object.keys(res.data.Data);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getmemberOnlineResponseGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectedProducts3MemberID,
      };
      await allapi
        .memberOnlineResponseGet(params)
        .then((res) => {
          console.log("getmemberOnlineResponseGet", res);
          this.channelBarData = res.data.Data.回應數;
          this.channelBarLabel = res.data.Data.渠道;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    searchUpdate: async function () {
      this.ids = 0;
      this.loadingTableWrapper = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      var body = {};
      if (this.customRFM_tableValue[0].type === "") {
        body = {
          content: null,
          ids: this.ids,
          search: this.searchInput,
        };
      } else if (this.selectFilter === true) {
        body = {
          content: this.customRFM_tableValue,
          ids: 0,
          search: this.searchInput,
        };
      } else {
        body = {
          content: this.customRFM_tableValue,
          ids: this.ids,
          search: this.searchInput,
        };
      }
      await allapi
        .memberSearchUpdatePost(params, body)
        .then((res) => {
          this.loading = false;
          this.loadingTable = false;
          this.totalPeople = res.data.Count.toLocaleString();
          console.log("update", res);
          this.tableData = res.data.Data;
          this.searchTable = this.tableData;
          this.ids = res.data.ids;
          this.selectFilter = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          // alert("連線有誤");
        });
      this.loadingTableWrapper = false;
      setTimeout(() => {
        this.loading = false;
      }, 0);
    },
    searchMore: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      var body = {};
      if (this.customRFM_tableValue[0].type === "") {
        body = {
          content: null,
          ids: this.ids,
          search: this.searchInput,
        };
      } else {
        body = {
          content: this.customRFM_tableValue,
          ids: this.ids,
          search: this.searchInput,
        };
      }
      await allapi
        .memberSearchUpdatePost(params, body)
        .then((res) => {
          console.log("update", res);

          this.tableData = res.data.Data;
          this.searchTable = this.searchTable.concat(this.tableData);
          this.ids = res.data.ids;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          // alert("連線有誤");
        });
    },

    clearInput() {
      this.searchInput = "";
      this.searchUpdate();
    },
    clearFilter() {
      this.loadingTableWrapper = true;

      setTimeout(() => {
        if (this.loadingTableWrapper === true) {
          this.searchInput = "";
          this.customRFM_tableValue = [
            {
              mode: "初",
              type: "",
              web: "",
              tag: "",
              value: "",
            },
          ];
          this.searchUpdate();
          this.loadingTableWrapper = false;
        }
      }, 500);
    },

    BarchartTop5label() {
      (this.barTop5labelSeries = [
        {
          name: "消費金額",
          data: this.barTop5labelData,
        },
      ]),
        (this.barTop5labelOptions = {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {},
          colors: ["#71AFB6"],
          xaxis: {
            categories: this.barTop5labelLabel,
            title: {
              text: "消費金額",
            },
          },
        });
    },
    BarchartTop5Product() {
      (this.barTop5ProductSeries = [
        {
          name: "消費金額",
          data: this.barTop5productData,
        },
      ]),
        (this.barTop5ProductOptions = {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#71AFB6"],
          xaxis: {
            categories: this.barTop5productLabel,
            title: {
              text: "消費金額",
            },
          },
        });
    },
    PieChartExpenses() {
      console.log("2323", this.expensesPieRes.線上比例);
      this.expensesPieSeries = [
        this.expensesPieRes.線上比例,
        this.expensesPieRes.線下比例,
      ];

      this.expensesPieOptions = {
        chart: {
          type: "donut",
        },
        labels: ["線上比例", "線下比例"],
        colors: ["#63A7C8", "#F9C357"],
        legend: {
          position: "bottom",
        },
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200
        //     },
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // }]
      };
    },
    lineChartHour() {
      if (
        this.overallHourRes[this.dropdownIndex] === undefined ||
        this.overallHourRes[this.dropdownIndex].date[0] === undefined
      ) {
        this.lineHourSeries = [];
        this.lineHourOptions = {};
      } else {
        (this.lineHourSeries = [
          {
            name: "消費金額",
            data: this.overallTimelineData,
          },
        ]),
          (this.lineHourOptions = {
            chart: {
              height: 190,
              type: "line",
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            title: {
              // text: 'Product Trends by Month',
              align: "left",
            },
            colors: ["#71AFB6"],
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            yaxis: {
              title: {
                text: "消費金額",
              },
            },
            xaxis: {
              categories: this.overallTimelineLabel,
              tickAmount: 4,
              // title:{
              //   text:'日期'
              // }
            },
          });
      }
    },
    BarChartWeek() {
      this.barWeekSeries = [
        {
          name: "消費金額",
          data: this.overWeekData,
        },
      ];
      this.barWeekOptions = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#71AFB6"],
        xaxis: {
          categories: this.overWeekLabel,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          title: {
            text: "星期",
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          title: {
            text: "消費金額",
          },
          // labels: {
          //   show: false,
          //   formatter: function (val) {
          //     return val + "%";
          //   }
          // }
        },
        title: {
          // text: 'Monthly Inflation in Argentina, 2002',
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      };
    },
    BarChartHour() {
      this.barHourSeries = [
        {
          name: "消費金額",
          data: this.overHourData,
        },
      ];
      this.barHourOptions = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#71AFB6"],
        xaxis: {
          categories: this.overHourLabel,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          title: {
            text: "小時",
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          title: {
            text: "消費金額",
          },
          // labels: {
          //   show: false,
          //   formatter: function (val) {
          //     return val + "%";
          //   }
          // }
        },
        title: {
          // text: 'Monthly Inflation in Argentina, 2002',
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      };
    },

    BarStopBy() {
      this.barStopByTimeSeries = [
        {
          name: "秒",
          data: this.stopByPieData,
        },
      ];
      this.barStopByTimeOptions = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#71AFB6"],
        xaxis: {
          categories: this.stopByPieLabel,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          title: {
            text: "秒",
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },

          // labels: {
          //   show: false,
          //   formatter: function (val) {
          //     return val + "%";
          //   }
          // }
        },
        title: {
          // text: 'Monthly Inflation in Argentina, 2002',
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      };
    },
    PieChannel() {
      this.pieChannelSeries = [
        this.channelPieData.Email,
        this.channelPieData.FB,
        this.channelPieData.SMS,
        this.channelPieData.Line,
      ];

      this.pieChannelOptions = {
        chart: {
          type: "donut",
        },
        labels: this.channelPieLabel,
        colors: ["#D197CC", "#96C5D7", "#FC9F66", "#F9C357"],
        legend: {
          position: "bottom",
        },
      };
    },
    BarChannel() {
      this.barChannelSeries = [
        {
          name: "次數",
          data: this.channelBarData,
        },
      ];
      this.barChannelOptions = {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },

        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        colors: ["#71AFB6"],
        xaxis: {
          categories: this.channelBarLabel,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          title: {
            text: "次數",
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          // labels: {
          //   show: false,
          //   formatter: function (val) {
          //     return val + "%";
          //   }
          // }
        },
        title: {
          // text: 'Monthly Inflation in Argentina, 2002',
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      };
    },
  },
};
</script>



<style scoped>
:deep(.p-datatable .p-datatable-thead > tr > th) {
  justify-content: center;
}

/* :deep(.p-datatable .p-datatable-thead>tr>th:nth-child(1)) {

  width: 10px;
} */
:deep(.p-column-header-content) {
  justify-content: center;
}
:deep(.p-datatable .p-datatable-tbody > tr > td:first-child) {
  padding: 8px 0px !important;
}

:deep(.p-datatable-table) {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
}

:deep(.p-radiobutton .p-radiobutton-box .p-radiobutton-icon) {
  background-color: #71afb6;
  height: 15px;
  margin-top: 15px;
  width: 14px;
}

:deep(.p-radiobutton) {
  height: 16px;
  margin-bottom: 0.4rem;
  width: 16px;
}

:deep(.p-datatable .p-datatable-tbody > tr.p-highlight) {
  color: #575757 !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  min-height: 50px;
  padding: 0 10px !important;
}

.tipInCustomRFM {
  opacity: 0.7 !important;
  padding: 3px 6px !important;
  background-color: #575757;
  border: 1px solid #575757;
  border-radius: 10px;
  color: white;
  /* height: 30px; */
  /* line-height: 30px; */
}

.templateTest {
  margin: 0 auto;
  text-align: center;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 1110px) {
  .templateTestWidth {
    width: 100px;
  }
}
@media screen and (max-width: 1290px) and (min-width: 1101px) {
  .templateTestWidth {
    width: 300px;
  }
}
@media screen and (max-width: 1520px) and (min-width: 1291px) {
  .templateTestWidth {
    width: 400px;
  }
}
@media screen and(min-width:1521px) {
  .templateTestWidth {
    /* white-space: nowrap; */
    width: 500px;
  }
}

/* .templateTest:hover {
  background-color: inherit;
  contain: paint;
  display: inline-flex;
  outline: 1px solid;

  width: 100%;
} */

.table-wrapper {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
  height: 295px;
}

.loading-background-tablewrapper {
  background-color: #fff;
  left: 0rem;
  position: absolute;
  top: 0rem;
  width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 10px;
}
.loading-background-bottom-info-in-New360Page {
  background-color: #fff;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 102%;
  height: 200px;
  z-index: 99;
  border-radius: 10px;
}

.search-member360 {
  width: calc(95% - 80px);
  height: 43px;
  border: 0.5px solid #c4c4c4;
  border-radius: 10px 0px 0px 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-indent: 30px;
}

.search-bg {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border-radius: 0px 10px 10px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 75%;
}

.close-input {
  position: absolute;
  top: 6px;
  right: calc(5% + 100px);
  cursor: pointer;
}
.search-btn-in-New360Page {
  position: absolute;
  top: 0px;
  right: 5%;
  border: 1px solid #c4c4c4;
  border-left: none;
  width: 80px;
  height: 43px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(to top left, #71afb6, #b5dce0);
}

.search-input-icon {
  position: absolute;
  top: 6px;
  left: 8px;
}

.filter-dropdown {
  width: 100%;
  border: 1px solid;
  border-color: #c4c4c4;
  border-radius: 10px;
  height: 70%;
  padding-bottom: 8px;
  padding-top: 7px;
}

.dropdown-contain {
  border: 1px solid transparent;
  border-radius: 15px;
  width: 76%;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  margin-top: 5px;
  top: 16rem;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
}

.basic-btn-customRFM {
  border: none;
  border-radius: 10px;
  height: 40px;
}

.highlight-btn-customRFM {
  background-image: linear-gradient(to top left, #71afb6, #b5dce0);
  color: white;
}

.section-div-basicinfo {
  padding: 0 15px;
  /* margin-top: 5px; */
  /* margin-bottom: 2rem; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 33.33%;
}

.columm-div-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
}

.col-20 {
  width: 20%;
}

.col-30 {
  width: 30%;
}

.range-input-customRFM {
  border: none;
  background-color: #eaf3f4;
  border-radius: 10px;
  height: 40px;
  width: 100px;
  text-align: center;
}

.count-input-customRFM {
  border: none;
  background-color: #eaf3f4;
  border-radius: 10px;
  height: 40px;
  width: 100px;
  text-align: center;
}

.basic-btn-customRFM {
  border: none;
  border-radius: 10px;
  height: 40px;
}

.highlight-btn-customRFM {
  background-image: linear-gradient(to top left, #71afb6, #b5dce0);
  color: white;
}

.child-popout-inNew360Page-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -154px;
  text-align: left;
  top: -120px;
  width: 350px;
  z-index: 99;
}
.container__arrow-inNew360Page-first {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: -8px;
  height: 0;
  left: 53.8%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(360deg);
  width: 0;
  z-index: 10;
}

.child-popout-inNew360Page-second {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -400px;
  text-align: left;
  top: -43px;
  width: 380px;
  z-index: 99;
}
.child-popout-inNew360Page-third {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -400px;
  text-align: left;
  top: -52px;
  width: 380px;
  z-index: 99;
}

.label-tag-div {
  border: 0.5px solid #c4c4c4;
  border-radius: 5px;
  height: 170px;
  overflow-y: scroll;
}

.clear-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #71afb6 0%, #b4dbdf 100%);
  border-radius: 10px;
  color: #fff;
  border: none;
}
</style>

