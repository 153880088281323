<template>
  <div class="container-fluid p-0 m-0">
    <div class="container-fluid p-0 m-0">
      <div class="d-flex">
        <div class="col-4 d-flex">
          <div class="col-12">
            <p class="section-title">常用模板</p>
          </div>
        </div>
        <div
          class="d-flex col-8 justify-content-end align-items-center mb-4"
          style="padding-right: 22px"
        >
          <div class="col-6">
            <img
              class="searchImg"
              src="../../assets/search.png"
              alt="searchImg"
            />
            <input
              class="search-input"
              v-model="searchInput"
              placeholder="搜尋名稱"
            />
          </div>
          <div style="position: relative; min-width: 110px">
            <Paginator
              :rows="this.perPage"
              :pageLinkSize="3"
              template="PrevPageLink PageLinks NextPageLink "
              :first="nowPage"
              :totalRecords="todos.length"
              @page="onPage($event)"
              class="m-0"
            ></Paginator>
            <div
              style="
                position: absolute;
                right: 5px;
                bottom: -25px;
                font-size: 14px;
                color: #8a8a8a;
                min-width: 100px;
              "
              v-if="this.todos.length === 0"
            >
              目前 0 ，共
              {{ Math.ceil(this.todos.length / this.perPage) }} 頁
            </div>
            <div
              style="
                position: absolute;
                right: 5px;
                bottom: -25px;
                font-size: 14px;
                color: #8a8a8a;
                min-width: 100px;
              "
              v-else
            >
              目前 {{ this.page }} ，共
              {{ Math.ceil(this.todos.length / this.perPage) }} 頁
            </div>
          </div>
          <!-- @paginate="setPage" -->
        </div>
      </div>
    </div>
    <div class="noData" v-if="this.todos.length === 0">查無模板</div>
    <div class="container-fluid m-0 p-0 container-flexdirection-custom" v-else>
      <div class="col-3">
        <button class="add-template mx-0" @click="addNewTodo">
          <img
            src="../../assets/greenPlus.svg"
            alt=""
            style="position: relative; top: -1.5px"
            class="me-2"
          />新的簡訊模板
        </button>
      </div>
      <ProductSmsFavNameList
        class="m-0 p-0"
        v-on:remove-todo="removeTodo"
        :key="index"
        v-for="(todo, index) in displayedRecords"
        :todo="todo"
        :selectedID="selectedID"
        v-bind="$attrs"
      >
      </ProductSmsFavNameList>
    </div>
  </div>
</template>

<script>
import ProductSmsFavNameList from "./ProductSmsFavNameList.vue";
import Pagination from "v-pagination-3";
import Paginator from "primevue/paginator";
import { allapi2 } from "../../../public/static2/api/apigClient.js";

export default {
  props: ["todos", "selectedID", "cssProps"],
  name: "ProductList",
  components: {
    ProductSmsFavNameList,
    Paginator,
  },
  data() {
    return {
      productSelected: {},
      page: 1,
      perPage: 7,
      records: [],
      // searchTodos: "",
      searchInput: "",
      nowPage: 0,
    };
  },
  watch: {
    searchInput: function (val, oldVal) {
      console.log(this.todos);
      // this.searchTodos=this.todos.filter((d) => d.template_name.includes(val))
      this.page = 1;
      this.$emit("search", val);
    },
  },
  methods: {
    onPage(event) {
      this.page = event.page + 1;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
    },
    addNewTodo() {
      this.$emit("clearall");
    },
    removeTodo(todo) {
      console.log(todo);
      let deleteyes = confirm("刪除後即無法返回，是否確認刪除?");
      if (deleteyes) {
        this.$emit("changeLoading");
        this.delete(todo);
      } else {
        return;
      }
    },
    delete(todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
      };

      allapi2
        .smsTemplateSmsTempInfoDelete(params, body)
        .then((res) => {
          console.log(res);
          this.$emit("refresh-todos");
          this.$emit("changeLoading");
          this.$emit("showDeleteSuccess");
          // if (this.nowPage > 6) {
          //   this.nowPage = 0;
          // } else {
          //   this.nowPage = this.nowPage + 1;
          // }
          // this.page = 1;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("delete連線有誤" + err);
          this.$emit("changeLoading");
        });
    },
    changePage() {
      if (this.nowPage > 6) {
        this.nowPage = 0;
      } else {
        this.nowPage = this.nowPage + 1;
      }
      this.page = 1;
    },
  },
  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.todos.slice(startIndex, endIndex);
    },
  },
};
</script>
<style scoped>
.noData {
  padding: 50px;
  font-size: 20px;
}

.searchImg {
  position: relative;
  top: -2px;
  left: 24px;
}

.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 30px;
  width: 17rem;
  height: 37px;
}

.section-title {
  color: #71afb6;
}

.container-flexdirection-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-template {
  width: 91%;
  height: 40px;
  margin: 12.5px;
  border: 1px dashed #71afb6;
  border-radius: 10px;
  background-color: white;
  color: #71afb6;
  position: relative;
}
</style>
