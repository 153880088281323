<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium" style="line-height: 40px"
          >線上行為</span
        >
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div class="customerNumber-div" style="width: 23.5%">
          <div class="">
            <p class="fs-small p-3 pb-0 mb-0" style="color: #71afb6">
              顧客編號
            </p>
            <div>
              <div class="search-div">
                <img
                  class="searchImg-basic"
                  src="../assets/search.png"
                  alt="searchImg"
                />
                <input
                  id="input360"
                  class="search-input-360"
                  v-model="searching"
                  v-on:keyup.enter="enterinput()"
                  placeholder="可輸入ID.姓名.電話.信箱搜尋"
                  v-tooltip.right="{
                    value: '請按Enter搜尋',
                    class: 'tipInBasic',
                  }"
                />
                <img
                  class="xImg-basic"
                  src="../assets/greenX.svg"
                  alt=""
                  @click="this.searchClear()"
                  style="cursor: pointer"
                />
              </div>
              <hr
                style="
                  width: 100%;
                  margin: 0px;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div class="d-flex flex-wrap sorting-button-div">
                <button
                  :class="cost ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickCost"
                >
                  消費金額
                  <img class="sorting-img" :src="upimg" alt="searchImg" />
                </button>
                <button
                  :class="count ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickCount"
                >
                  消費次數
                  <img class="sorting-img" :src="upimg2" alt="searchImg" />
                </button>
                <button
                  :class="consumption ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickConsumption"
                >
                  最近消費
                  <img class="sorting-img" :src="upimg3" alt="searchImg" />
                </button>
                <button
                  :class="addtime ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickTime"
                >
                  加入時間
                  <img class="sorting-img" :src="upimg4" alt="clickTime" />
                </button>
              </div>
              <hr
                style="
                  width: 100%;
                  margin-top: 0px;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div
                style="height: 450px"
                class="d-flex flex-column justify-content-end"
                v-if="this.wholeSearchloading"
              >
                <div class="d-flex justify-content-center my-auto">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="customerID-div-basic"
                  v-if="this.member_id.length === 0"
                >
                  查無此資料
                </div>
                <div
                  class="customerID-div-basic"
                  @scroll="this.scroll($event)"
                  v-else
                >
                  <div
                    class="loop-div"
                    :key="item"
                    v-for="(item, index) in member_id"
                  >
                    <input
                      type="radio"
                      id="vehicle1"
                      name="vehicle1"
                      :value="item"
                      v-model="checkbox"
                      @click="check($event, customerID[index])"
                    />
                    {{ customerID[index] }}
                  </div>
                  <div class="d-flex justify-content-center" v-if="searchload">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <!-- <p>{{this.customerID}}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ps-4" style="width: 76.5%">
          <div class="row">
            <div class="p-0" style="padding-left: 12px">
              <div
                class="table-bar-div d-flex align-items-center"
                style="
                  position: relative;
                  height: 90px;
                  padding: 20px;
                  margin-top: 30px;
                "
              >
                <p class="m-0">
                  <span style="color: #c4c4c4" class="me-4">姓名</span>
                  <span class="me-5">{{ member_info.姓名 }}</span>
                  <span style="color: #c4c4c4" class="me-4">手機</span>
                  <span class="me-5">{{ member_info.手機 }}</span>
                  <span style="color: #c4c4c4" class="me-4">信箱</span>
                  <span class="me-5">{{ member_info.信箱 }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="table-bar-div" style="position: relative">
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <span class="fs-small pb-0 mb-0 col-2" style="color: #71afb6">
                  顧客網站行為
                </span>
                <div class="title-button-div col-10 d-flex">
                  <button
                    class="button-nofocus1 fs-small col-4"
                    :class="browse ? 'button-focus' : 'button-nofocus1'"
                    @click="clickBrowse"
                  >
                    造訪頁面名稱
                  </button>
                  <button
                    class="button-nofocus1 fs-small col-4"
                    :class="click ? 'button-focus' : 'button-nofocus1'"
                    @click="clickClick"
                  >
                    點擊商品名單
                  </button>
                  <button
                    class="button-nofocus1 fs-small col-4"
                    :class="buy ? 'button-focus' : 'button-nofocus1'"
                    @click="clickBuy"
                  >
                    點擊加入購物車名單
                  </button>
                </div>
              </div>
              <span
                style="position: absolute; top: 70%; left: 48%; color: #c4c4c4"
                v-if="this.clickedTableList[0] === undefined"
                >無資料</span
              >
              <DataTable
                :value="clickedTableList"
                class="bottom-table"
                showGridlines
                :scrollable="true"
                scrollHeight="250px"
              >
                <Column
                  field="項目"
                  header="頁面名稱"
                  :sortable="true"
                  v-if="this.browse"
                ></Column>
                <Column
                  field="數量"
                  header="點擊次數"
                  :sortable="true"
                  v-if="this.browse"
                ></Column>
                <Column
                  field="商品編號"
                  header="商品名稱"
                  :sortable="true"
                  v-if="!this.browse"
                ></Column>
                <Column
                  field="產品類別"
                  header="產品類別"
                  :sortable="true"
                  v-if="!this.browse"
                ></Column>
                <Column
                  field="品牌名稱"
                  header="品牌名稱"
                  :sortable="true"
                  v-if="!this.browse"
                ></Column>
              </DataTable>
            </div>
          </div>
          <div class="col-12">
            <div class="table-bar-div" style="position: relative">
              <p class="fs-small mb-0" style="color: #71afb6">停留時間</p>
              <span
                style="position: absolute; top: 45%; left: 48%; color: #c4c4c4"
                v-if="this.series2[0].data[0] === undefined"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="270"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex">
        <div class="col-4">
          <div class="down-table" style="position: relative">
            <p class="fs-small mb-3" style="color: #71afb6">過往行銷紀錄</p>
            <span
              style="position: absolute; top: 70%; left: 40%; color: #c4c4c4"
              v-if="this.trackTable[0] === undefined"
              >無資料</span
            >
            <DataTable
              :value="trackTable"
              class="bottom-table mb-3"
              showGridlines
              :scrollable="true"
              scrollHeight="220px"
            >
              <Column
                field="日期"
                header="日期"
                :sortable="true"
                style="min-width: 120px"
              ></Column>
              <Column
                field="行銷活動"
                header="行銷活動"
                :sortable="true"
                style="min-width: 120px"
              ></Column>
              <Column
                field="有無回應"
                header="有無回應"
                :sortable="true"
                style="min-width: 120px"
              ></Column>
            </DataTable>
          </div>
        </div>
        <div class="col-4 ps-4">
          <div class="down-table" style="position: relative">
            <p class="fs-small mb-0" style="color: #71afb6">各渠道投放次數</p>
            <span
              class="text-center"
              style="position: absolute; top: 20%; left: 20%; color: #c4c4c4"
              v-if="this.seriesPie.every((d) => d === 0)"
            >
              <img src="../assets/missData.svg" alt="" style="width: 200px" />
              <br />
              無資料</span
            >
            <apexchart
              type="donut"
              height="250"
              :options="chartOptionsPie"
              :series="seriesPie"
            ></apexchart>
          </div>
        </div>
        <div class="col-4 ps-4">
          <div class="down-table" style="position: relative">
            <p class="fs-small mb-0" style="color: #71afb6">各渠道回應比例</p>
            <span
              style="position: absolute; top: 48%; left: 48%; color: #c4c4c4"
              v-if="this.series3[0].data.every((d) => d === 0)"
              >無資料</span
            >
            <apexchart
              type="bar"
              height="250"
              :options="chartOptions3"
              :series="series3"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";

import updownimage from "../assets/sorting-button.png";
import upimage from "../assets/up-arrow.png";
import downimage from "../assets/down-arrow.png";

import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "OnlineBehavior",
  components: {
    DataTable,
    Column,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: false,
      wholeSearchloading: false,
      searching: "",
      rank: "",
      ascending: false,
      checkbox: "",
      selectId: "19",
      customerID: [],
      member_id: [],
      member_info: {},

      browse: true,
      click: false,
      buy: false,

      cost: false,
      count: false,
      consumption: false,
      addtime: false,

      upimg: updownimage,
      upimg2: updownimage,
      upimg3: updownimage,
      upimg4: updownimage,

      //primevue table data

      tableList: {},
      clickedTableList: [],
      trackTable: [],

      // bar
      series2: [
        {
          name: "秒",
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          type: "bar",
          height: 270,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          title: {
            text: "秒",
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      // bar
      series3: [
        {
          name: "次數",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          title: {
            text: "次數",
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      // piedata
      seriesPie: [],
      chartOptionsPie: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#71AFB6", "#FC9F66", "#F9C357", "#96C5D7"],
        labels: ["線上", "線下"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      ids: 0,
      notEnd: true,
      bottom: false,
      searchload: false,
    };
  },
  beforeRouteLeave: function (to, from) {
    sessionStorage.removeItem("template_key");
    if (
      to.path === "/BasicInfo" ||
      to.path === "/PurchaseRecord" ||
      to.path === "/OnlineBehavior"
    ) {
      return;
    } else {
      console.log("remove checkboxID");

      sessionStorage.removeItem("checkboxID");
    }
  },
  watch: {
    searching: function (val) {
      if (val === "") {
        this.searchClear();
      }
    },
  },
  created: async function () {
    console.log(sessionStorage.getItem("checkboxID"));
    this.searching = sessionStorage.getItem("checkboxID");
    this.selectId = sessionStorage.getItem("checkboxMemberID");
    await this.search();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  methods: {
    beforeunloadcall(e) {
      e = e || window.event;
      // e.returnValue = '确定要关闭窗口吗？'
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        sessionStorage.removeItem("checkboxID");
        sessionStorage.removeItem("checkboxMemberID");
        console.log("456");
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "whatever";
    },
    scroll: async function (e) {
      const { target } = e;
      if (
        Math.ceil(target.scrollTop) >=
        target.scrollHeight - target.offsetHeight
      ) {
        if (this.searchload === false) {
          await this.searchMore();
          this.searchload = false;
        }
        this.bottom = true;
      }
      console.log("this.bottom", this.bottom);
    },
    clickCost() {
      this.rank = "消費金額";
      this.cost = true;
      (this.count = false), (this.consumption = false), (this.addtime = false);
      if (this.upimg === downimage) {
        this.ascending = true;
        this.upimg = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg = downimage;
        this.search();
      }

      this.upimg2 = updownimage;
      this.upimg3 = updownimage;
      this.upimg4 = updownimage;
    },
    clickCount() {
      this.rank = "消費次數";
      (this.cost = false), (this.count = true);
      (this.consumption = false), (this.addtime = false);
      if (this.upimg2 === downimage) {
        this.ascending = true;
        this.upimg2 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg2 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg3 = updownimage;
      this.upimg4 = updownimage;
    },
    clickConsumption() {
      this.rank = "最近消費";
      (this.cost = false), (this.count = false);
      (this.consumption = true), (this.addtime = false);
      if (this.upimg3 === downimage) {
        this.ascending = true;
        this.upimg3 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg3 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg2 = updownimage;
      this.upimg4 = updownimage;
    },
    clickTime() {
      this.rank = "加入時間";
      (this.cost = false), (this.count = false);
      (this.consumption = false), (this.addtime = true);
      if (this.upimg4 === downimage) {
        this.ascending = true;
        this.upimg4 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg4 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg2 = updownimage;
      this.upimg3 = updownimage;
    },
    enterinput() {
      this.search();
    },
    search: async function () {
      this.ids = 0;
      this.loading = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "online",
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log("search", res);
          this.customerID = res.data.Data.ID;
          this.member_id = res.data.Data.member_id;
          if (this.customerID[0] != undefined) {
            this.checkbox = this.member_id[0];
            this.selectId = this.member_id[0];
            sessionStorage.setItem("checkboxID", this.customerID[0]);
            sessionStorage.setItem("checkboxMemberID", this.member_id[0]);
          }
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      await Promise.all([
        this.getMemberInfo(),
        this.getMemberView(),
        this.getMemberlfgepie(),
        this.getMemberResponse(),
        this.getMemberTable(),
        this.getMemberTrackTable(),
      ]);
      this.loading = false;
    },
    searchMore: async function () {
      this.searchload = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "online",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log("searchmore", res);

          this.customerID = this.customerID.concat(res.data.Data.ID);
          this.member_id = this.member_id.concat(res.data.Data.member_id);
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    searchClear: async function () {
      this.wholeSearchloading = true;
      this.ids = 0;
      this.searching = "";
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "online",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log(res);
          this.customerID = res.data.Data.ID;
          this.member_id = res.data.Data.member_id;
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
          this.wholeSearchloading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.wholeSearchloading = false;
          alert("連線有誤");
        });
    },
    getMemberInfo: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicInfoGet(params)
        .then((res) => {
          console.log("MemberInfo", res);
          const values = Object.values(res.data.Data);
          const keys = Object.keys(res.data.Data);
          console.log(values);
          for (let index = 0; index < values.length; index++) {
            if (values[index] === null) {
              res.data.Data[keys[index]] = "- -";
            }
          }
          this.member_info = res.data.Data;
          console.log(this.member_info);

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberView: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOnlineViewGet(params)
        .then((res) => {
          console.log("停留", res);
          this.series2 = [
            {
              data: res.data.Data.停留秒數,
            },
          ];
          this.chartOptions2 = {
            xaxis: {
              categories: res.data.Data.商品編號,
            },
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberlfgepie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOnlineLfgepieGet(params)
        .then((res) => {
          console.log("pie", res);
          this.seriesPie = Object.values(res.data.Data).map((d) => {
            return parseInt(d);
          });
          this.chartOptionsPie = {
            labels: Object.keys(res.data.Data),
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberResponse: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOnlineResponseGet(params)
        .then((res) => {
          console.log("Response", res);

          this.series3 = [
            {
              data: res.data.Data.回應數,
            },
          ];
          this.chartOptions3 = {
            xaxis: {
              categories: res.data.Data.渠道,
            },
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOnlineListsGet(params)
        .then((res) => {
          console.log("table", res);
          this.tableList = res.data.Data;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      if (this.browse === true) {
        this.clickedTableList = this.tableList.瀏覽商品名單;
      } else if (this.click === true) {
        this.clickedTableList = this.tableList.點擊商品名單;
      } else {
        this.clickedTableList = this.tableList.點擊加入購物車名單;
      }
    },
    getMemberTrackTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberOnlineTrackGet(params)
        .then((res) => {
          console.log("Track", res);
          // res.data.Data
          this.trackTable = res.data.Data;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    check: async function (e, id) {
      this.loading = true;
      console.log(e.target.value);
      this.selectId = e.target.value;
      sessionStorage.setItem("checkboxID", id);
      sessionStorage.setItem("checkboxMemberID", this.selectId);
      await Promise.all([
        this.getMemberInfo(),
        this.getMemberView(),
        this.getMemberlfgepie(),
        this.getMemberResponse(),
        this.getMemberTable(),
        this.getMemberTrackTable(),
      ]);
      this.loading = false;
    },
    clickBrowse() {
      this.browse = true;
      this.click = false;
      this.buy = false;
      this.clickedTableList = this.tableList.瀏覽商品名單;
    },
    clickClick() {
      this.browse = false;
      this.click = true;
      this.buy = false;
      this.clickedTableList = this.tableList.點擊商品名單;
    },
    clickBuy() {
      this.browse = false;
      this.click = false;
      this.buy = true;
      this.clickedTableList = this.tableList.點擊加入購物車名單;
    },
  },
};
</script>

<style>
/* onlinebehavior */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.searchImg-online {
  z-index: 2;
  position: relative;
  top: 10px;
  left: 10px;
}
.search-div {
  padding-top: 8px;
  height: 40px;
}
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  padding-top: 53px;
  /* padding: 50px; */
}
.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}
.number-div {
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 145px;
}
.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}
.button-nofocus1 {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  height: 40px;
}
.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}
/* .search-input-360 {
  vertical-align: bottom;
  width: 75%;
  border: none;
  outline: none;
  text-indent: 15px;
} */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c4c4c4;
  opacity: 1; /* Firefox */
}
.customerNumber-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  margin-top: 30px;
}
.customerID-div-online {
  margin: 20px;
  padding: 15px;
  height: 525px;
  overflow-y: scroll;
}
.sorting-button-div {
  padding: 8px;
  /* width: 80%; */
}
.sorting-button {
  background-color: transparent;
  border: none;
  /* margin-top: 10px; */
  padding: 10px;
  color: #c4c4c4;
}
.button-highlight {
  background-color: #71afb6;
  border: none;
  /* margin-top: 10px; */
  padding: 10px;
  color: #fff;
  border-radius: 8px;
}

.sorting-img {
  padding-left: 10px;
}
.table-bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 25px;
  height: 308px;
}
.down-table {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 25px;
  height: 311px;
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>