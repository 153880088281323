<template>
  <!-- <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能為正式付費使用 </span>
  </div> -->

  <!-- liveToast  -->
  <div
    id="liveToast"
    class="toast position-fixed"
    style="
      width: auto;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
      z-index: 11;
      top: 127px;
      right: calc((83.34% - 634px) / 2);
    "
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-bs-delay="3000"
  >
    <div class="d-flex">
      <div
        style="width: 55px; height: 55px; background-color: #ff614d"
        class="d-flex justify-content-center align-items-center"
      >
        <img
          src="../assets/whiteBigX.svg"
          alt=""
          style="width: 22px; display: block"
        />
      </div>
      <div
        class="d-flex justify-content-between align-items-center flex-fill"
        style="padding: 15px 20px"
      >
        <span class="me-2"> 此處應放 {{ errorDrop }} </span>
        <button
          style="border: none; background-color: white"
          class="d-flex justify-content-center align-items-center"
          data-bs-dismiss="toast"
          aria-label="Close"
        >
          <img src="../assets/blackX.svg" alt="" style="width: 12px" />
        </button>
      </div>
    </div>
  </div>
  <div>
    <!-- loading screen -->

    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- loading_copy screen -->

    <div class="loading-background" v-show="loading_copy">
      <p>建立複製模板中...</p>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span></span>
    </div>
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
            v-if="this.schedule[0] != undefined"
          >
            <p>
              <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
              {{
                this.schedule[0].label_name.includeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
            <p
              class="mb-5"
              style="margin-left: 36px; word-break: break-all"
            ></p>
            <p>
              <img
                src="../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
              {{
                this.schedule[0].label_name.excludeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
            <p style="margin-left: 36px"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- expEmailModal -->
    <EmailPreview :subject="newSub" :html="newHtml"></EmailPreview>

    <!-- offcanvas -->
    <div
      class=""
      v-if="
        this.eventScriptIdFromSche != null ||
        this.eventNameFromSche === undefined
      "
    >
      <button
        class="btn floating-button"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <img
          src="../assets/offcanvas-arrow.png"
          alt="arrow"
          class=""
          data-bs-dismiss="modal"
        />
      </button>
      <div
        class="offcanvas offcanvas-bottom"
        style="
          height: 310px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        "
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <button
          class="btn floating-button2 py-2"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <img
            src="../assets/offcanvas-downarrow.png"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </button>
        <!-- <div class="offcanvas-header py-1">
          <h5 id="offcanvasRightLabel"></h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div> -->
        <div class="offcanvas-body">
          <div
            class="d-flex justify-content-between"
            style="padding: 5px 113px"
            v-if="this.tabState === 'event'"
          >
            <div style="width: 45%">
              <p style="color: #71afb6" class="d-flex justify-content-between">
                <span> 回應事件設定 </span>
                <span
                  style="cursor: pointer"
                  @click="this.event_moreoptionChange(true)"
                  v-if="!event_moreoption"
                >
                  更多條件選項...
                </span>
                <span
                  style="cursor: pointer"
                  @click="this.event_moreoptionChange(false)"
                  v-if="event_moreoption"
                >
                  隱藏更多條件選項...
                </span>
              </p>
              <div v-if="this.event_moreoption === false">
                <p>事件</p>
                <DropdownBlack
                  :valueArr="
                    this.emailChannel
                      ? ['開啟信件', '點擊連結', '轉換']
                      : ['點擊連結', '轉換']
                  "
                  :selectValue="eventSelected"
                  :changingSelected="'eventSelected'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
                <p class="mt-3">發生事件時間</p>
                <div class="d-flex justify-content-between">
                  <input
                    type="number"
                    class="select-style"
                    style="width: 46%"
                    v-model="time_interval_Selected"
                  />
                  <DropdownBlack
                    :topSide="-75"
                    :mxHeight="-75"
                    style="width: 46%"
                    :valueArr="['小時內', '天內']"
                    :selectValue="time_state_Selected"
                    :changingSelected="'time_state_Selected'"
                    v-on:selectValueByDrop="selectValueByDrop"
                  />
                </div>
              </div>
              <div v-if="this.event_moreoption === true">
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin-bottom: 35px"
                >
                  <span>已滿足事件</span>
                  <div style="width: 77%">
                    <DropdownBlack
                      :valueArr="
                        this.emailChannel
                          ? ['已開啟信件', '已點擊連結']
                          : ['已點擊連結']
                      "
                      :selectValue="eventSelected"
                      :changingSelected="'eventSelected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin-bottom: 35px"
                >
                  <span>條件設定</span>
                  <div style="width: 77%">
                    <DropdownBlack
                      :valueArr="
                        this.eventSelected === '已開啟信件'
                          ? ['且點擊', '且轉換']
                          : ['且轉換']
                      "
                      :selectValue="event_moreoption_Selected"
                      :changingSelected="'event_moreoption_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <!-- <div
                    style="width: 77%"
                    v-if="this.eventSelected === '點擊連結'"
                  >
                    <DropdownBlack
                      :valueArr="['點擊連結未轉換']"
                      :selectValue="event_moreoption_Selected"
                      :changingSelected="'event_moreoption_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div> -->
                </div>
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin-bottom: 35px"
                >
                  <span>發生事件時間</span>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 77%"
                  >
                    <input
                      type="number"
                      class="select-style"
                      style="width: 46%"
                      v-model="time_interval_Selected"
                    />
                    <DropdownBlack
                      :topSide="-74"
                      style="width: 46%"
                      :valueArr="['小時內', '天內']"
                      :selectValue="time_state_Selected"
                      :changingSelected="'time_state_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 45%">
              <p style="color: #71afb6">事件時間說明</p>
              <p>
                郵件發送後要讓收件人有<span style="color: #71afb6"
                  >足夠的時間完成回應事件(Response)</span
                >，系統為您新增等候時間預設為 24 小時。<span
                  v-if="this.eventSelected != '轉換'"
                  >您亦可<span style="color: #71afb6">設定條件</span>，</span
                >請依據需求進行調整，完成後請到<span style="color: #71afb6"
                  >時間</span
                >進行下一步。
              </p>
              <div class="d-flex justify-content-end" style="margin-top: 61px">
                <button
                  class="col-5 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6"
                  @click="deleteEventTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  @click="confirmUpdateEventTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between"
            style="padding: 5px 50px 5px 60px"
            v-if="this.tabState === 'time'"
          >
            <div style="width: 40%">
              <p style="color: #71afb6; margin-bottom: 23px">發送時間設定</p>
              <p style="margin-bottom: 20px">經過多少時間寄第一封</p>
              <div
                class="d-flex justify-content-between"
                style="margin-bottom: 25px"
              >
                <input
                  type="number"
                  class="select-style"
                  style="width: 48%; border: 1px solid #c4c4c4"
                  v-model="time_after_Selected"
                />
                <DropdownBlack
                  style="width: 48%"
                  :valueArr="['小時後', '天後']"
                  :selectValue="time_after_state_Selected"
                  :changingSelected="'time_after_state_Selected'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
              </div>
              <!-- <p style="margin-bottom: 17px">發送方式</p>
              <div class="d-flex align-items-center">
                <label class="radio-container" for="一次性投放3">
                  <input
                    type="radio"
                    id="一次性投放3"
                    value="一次性"
                    v-model="picked3"
                    name="group3"
                  />
                  <span class="checkmark"></span>
                  一次性投放
                </label>
                <label class="radio-container" for="週期性投放2">
                  <input
                    type="radio"
                    id="週期性投放2"
                    value="週期性"
                    v-model="picked3"
                    name="group3"
                  />
                  <span class="checkmark"></span>
                  週期性投放
                </label>
              </div> -->
            </div>
            <!-- <div style="width: 26%; position: relative">
              <div
                style="
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  background-color: white;
                  opacity: 0.5;
                  z-index: 999;
                "
                v-if="picked3 === '一次性'"
              ></div>
              <p style="color: #71afb6; margin-bottom: 14px">寄完第一封後</p>
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 25px"
              >
                <span class="me-4"> 發生周期 </span>
                <div
                  class="d-flex justify-content-between flex-fill"
                  style="background-color: #eaf3f4; border-radius: 10px"
                >
                  <button
                    class="basic-btn"
                    :class="happenTime === '年' ? 'highlight2' : ''"
                    @click="happenTime = '年'"
                  >
                    年
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '月' ? 'highlight2' : ''"
                    @click="happenTime = '月'"
                  >
                    月
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '週' ? 'highlight2' : ''"
                    @click="happenTime = '週'"
                  >
                    週
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '日' ? 'highlight2' : ''"
                    @click="happenTime = '日'"
                  >
                    日
                  </button>
                </div>
              </div>
              <div>
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-bottom: 25px"
                >
                  <div class="flex-fill" v-if="happenTime === '日'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="happenDaySelector"
                      :selectValue="happenDay_Selected"
                      :changingSelected="'happenDay_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '週'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1週', '每2週', '每3週', '每4週', '每5週']"
                      :selectValue="happenWeek_Selected"
                      :changingSelected="'happenWeek_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '月'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="happenMonthSelector2"
                      :selectValue="happenMonth_Selected"
                      :changingSelected="'happenMonth_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '年'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1年', '每2年', '每3年']"
                      :selectValue="happenYear_Selected"
                      :changingSelected="'happenYear_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="ms-4 me-2">投放一次</div>
                </div>
                <div style="margin-bottom: 25px">
                  <Datepicker
                    locale="tw"
                    inputClassName="dp-custom-input-triggerscript"
                    cancelText="取消"
                    selectText="選擇"
                    v-model="daytime"
                    timePicker
                  >
                    <template #input-icon>
                      <img
                        src="../assets/time.svg"
                        alt=""
                        style="position: relative; right: -10px; top: -2px"
                      />
                    </template>
                  </Datepicker>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span>結束次數</span>
                  <input
                    class="text-center"
                    type="number"
                    style="
                      width: 181px;
                      height: 40px;
                      border: none;
                      border-radius: 10px;
                      background-color: #eaf3f4;
                    "
                    v-model="endCount2"
                  />
                  <span>次</span>
                </div>
              </div>
            </div> -->
            <div style="width: 55%">
              <p style="color: #71afb6">發送說明</p>
              <p>
                設定顧客經<span style="color: #71afb6">回應事件後過多久</span
                >，系統即會開始寄信。完成後請到<span style="color: #71afb6"
                  >模板</span
                >進行下一步。
              </p>
              <p style="color: #71afb6">時間說明</p>
              <p>系統為您新增等候時間預設為7天後。請依據您的需求進行調整。</p>
              <div class="d-flex justify-content-end" style="margin-top: 17px">
                <button
                  class="col-5 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6"
                  @click="deleteTimeTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  @click="confirmUpdateTimeTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between"
            style="padding: 5px 130px; height: 225px"
            v-if="this.tabState === 'action'"
          >
            <div style="width: 45%">
              <p style="color: #71afb6">動作設定</p>
              <DropdownBlack
                :valueArr="['傳送Email', '傳送SMS']"
                :selectValue="action_Selected"
                :changingSelected="'action_Selected'"
                v-on:selectValueByDrop="selectValueByDrop"
              />
            </div>

            <div
              class="d-flex flex-column justify-content-between"
              style="width: 45%"
            >
              <div>
                <p style="color: #71afb6">動作說明</p>
                <p>設定下一個動作。</p>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  class="col-5 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6; width: 40%"
                  @click="deleteActionTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  style="width: 40%"
                  @click="confirmUpdateActionTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <!-- template -->
          <div
            class="d-flex flex-wrap justify-content-between px-5"
            v-if="this.tabState === 'template'"
          >
            <div v-if="this.emailChannel">
              <p
                class="col-12 d-flex justify-content-between"
                style="color: #71afb6"
              >
                <span> 郵件投放設定 </span>
              </p>
              <div class="d-flex col-12 justify-content-between">
                <div style="width: 30%">
                  <div class="align-items-center">
                    <label
                      for=""
                      class="col-form-label section-title"
                      style="width: 100%"
                      >模板名稱</label
                    >
                    <div style="width: 100%" class="mt-3">
                      <input
                        v-bind:class="{ 'is-invalid': newTodoTitleError }"
                        ref="titleInput"
                        name="add-input"
                        class="form-control"
                        style="border-radius: 10px"
                        v-model="newTodoTitle"
                        @click="titleChange()"
                      />
                      <div class="invalid-feedback">
                        {{ newTodoTitleErrMsg }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                      <div style="width: 47%">
                        <button
                          class="btn btn-primary add-template-button-color2"
                          style="width: 100%"
                          @click="showexp()"
                        >
                          預覽模板
                        </button>
                      </div>
                      <div style="width: 47%">
                        <button
                          class="add-template-button-color22"
                          style="width: 100%"
                          @click="edittodoinscript()"
                        >
                          編輯
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap" style="width: 34%">
                  <p class="m-0" style="color: #71afb6">說明</p>
                  <p style="line-height: 1.6">
                    當您將模板拖移至右方，該模板即會自動複製(1)，若要更改內容，請選擇複製後的模板並按左方編輯按鈕
                    ( 此動作並不會影響原有的模板內容 ) 。
                  </p>
                </div>
                <div style="width: 30%">
                  <div class="col-2">
                    <label
                      for="exampleFormControlInput1"
                      class="form-label"
                      style="line-height: 40px"
                      >主旨</label
                    >
                  </div>
                  <div class="col-12">
                    <input
                      v-bind:class="{ 'is-invalid': subError }"
                      class="form-control"
                      style="height: 40px"
                      ref="subInput"
                      placeholder=""
                      v-model="newSub"
                      @click="subChange()"
                    />
                    <div class="invalid-feedback">
                      {{ subErrMsg }}
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mt-4">
                    <div style="width: 47%">
                      <button
                        class="btn-basic"
                        style="
                          background-color: #eaf3f4;
                          color: #71afb6;
                          width: 100%;
                        "
                        @click="deleteTemplateTag"
                      >
                        <img src="../assets/remove.svg" alt="" class="mb-1" />
                        移除
                      </button>
                    </div>
                    <div style="width: 47%">
                      <button
                        class="btn btn-primary add-template-button-color2"
                        style="width: 100%"
                        @click="confirmUpdateTemplateTab"
                      >
                        <img
                          src="../assets/save-icon-white.svg"
                          alt="save"
                          style="position: relative; top: -2px"
                        />
                        &nbsp;&nbsp;儲存
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-5 px-5" v-else>
              <p
                class="col-12 d-flex justify-content-between"
                style="color: #71afb6"
              >
                <span> SMS投放設定 </span>
              </p>
              <div class="d-flex col-12 justify-content-between">
                <div style="width: 40%">
                  <div class="align-items-center">
                    <label
                      for=""
                      class="col-form-label section-title"
                      style="width: 100%"
                      >模板名稱</label
                    >
                    <div style="width: 100%" class="mt-3">
                      <input
                        v-bind:class="{ 'is-invalid': newTodoTitleError }"
                        ref="titleInput"
                        name="add-input"
                        class="form-control"
                        style="border-radius: 10px"
                        v-model="newTodoTitle"
                        @click="titleChange()"
                        disabled
                      />
                      <div class="invalid-feedback">
                        {{ newTodoTitleErrMsg }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                      <div style="width: 47%">
                        <button
                          class="btn btn-primary add-template-button-color2"
                          style="width: 100%"
                          @click="expshow(true)"
                        >
                          預覽模板
                        </button>
                      </div>
                      <div style="width: 47%">
                        <button
                          class="add-template-button-color22"
                          style="width: 100%"
                          @click="edittodoinscript()"
                        >
                          編輯
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap" style="width: 50%">
                  <p class="m-0" style="color: #71afb6">說明</p>
                  <p style="line-height: 1.6">
                    當您將模板拖移至右方，該模板即會自動複製(1)，若要更改內容，請選擇複製後的模板並按左方編輯按鈕
                    ( 此動作並不會影響原有的模板內容 ) 。
                  </p>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div style="width: 47%">
                      <button
                        class="btn-basic"
                        style="
                          background-color: #eaf3f4;
                          color: #71afb6;
                          width: 100%;
                        "
                        @click="deleteTemplateTag"
                      >
                        <img
                          src="../assets/stop-icon-blue.svg"
                          alt="save"
                          style="position: relative; top: -2px"
                        />
                        &nbsp;&nbsp; 移除
                      </button>
                    </div>
                    <div style="width: 47%">
                      <button
                        class="btn btn-primary add-template-button-color2"
                        style="width: 100%"
                        @click="confirmUpdateTemplateTab"
                      >
                        <img
                          src="../assets/save-icon-white.svg"
                          alt="save"
                          style="position: relative; top: -2px"
                        />
                        &nbsp;&nbsp;儲存
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- alert變數Modal -->
    <div
      class="modal fade"
      id="varnameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
      style="z-index: 5000"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @click="autoShow = false">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body" style="text-align: left">
            <div class="d-flex justify-content-start align-items-center mb-2">
              <div class="col-4 p-0" style="color: #71afb6">插入個人化變數</div>
              <div
                class="search-container col-6 p-0"
                style="position: relative"
              >
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                    padding: 0 14px;
                    height: 35px;
                  "
                  @click.stop="autoShow = !autoShow"
                >
                  {{ triggerType }}
                  <img src="../assets/selection-arrow.png" alt="searchImg" />
                </div>
                <div
                  class="auto-complicate-div"
                  style="width: 100%"
                  v-show="autoShow"
                  @mouseenter="autoShow = true"
                >
                  <p
                    class="m-0 p-1 px-3 auto-complicate-p"
                    @click.stop="
                      triggerType = '顧客姓名';
                      varName = '{name}';
                      autoShow = false;
                    "
                  >
                    顧客姓名
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <p class="mb-4" style="color: #8a8a8a">
                複製下方變數並插入文字中，使信件客製化；可讓信件中加入訂閱者的姓名，增加親切感。
              </p>
              <div class="d-flex align-items-center mb-4">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客姓名</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    color: white;
                    background-color: #71afb6;
                  "
                  v-clipboard:copy="varName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
              <div class="d-flex align-items-center mb-4">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客姓</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varLastName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    color: white;
                    background-color: #71afb6;
                  "
                  v-clipboard:copy="varLastName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
              <div class="d-flex align-items-center">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客名</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varFirstName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    color: white;
                    background-color: #71afb6;
                  "
                  v-clipboard:copy="varFirstName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- alert儲存成功Modal -->
    <div
      class="modal fade"
      id="alertModal3"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
      style="z-index: 4100"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body text-center">儲存成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert添加模板成功Modal -->
    <div
      class="modal fade"
      id="alertModal4"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">添加成功</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert刪除劇本成功Modal -->
    <div
      class="modal fade"
      id="alertModal5"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert執行Modal -->
    <div
      class="modal fade"
      id="alertModal6"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            確認後系統將自動執行該流程
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary btn-basic"
              style="background-color: #eaf3f4; width: 10vw; color: #575757"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close btn-basic ms-4"
              data-bs-dismiss="modal"
              @click="runScript"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- unlayer Modal -->
    <div
      class="modal fade"
      id="unlayerModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 100%">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body" style="height: 90vh">
            <iframe
              :src="this.url8081"
              height="100%"
              width="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- sms modal -->
    <div
      class="modal expEmailModal2-background"
      id="smsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 100%">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="p-5 pt-4 pb-3">
            <p style="font-size: 24px" class="m-0">簡訊模板</p>
            <div
              style="
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                border-radius: 10px;
              "
            >
              <div
                class="modal-body client-modal-body p-0"
                style="border-radius: 10px"
              >
                <div
                  class="container mb-3"
                  style="background-color: white; border-radius: 10px"
                >
                  <div class="pt-4 pb-1 px-2 mt-3 form-container">
                    <div
                      class="row justify-content-xl-between align-items-center"
                    >
                      <span class="col-3" style="color: #71afb6">
                        模板內容
                      </span>
                      <div class="col-3">
                        <button
                          class="basic-btn highlight-SmsTemp"
                          style="cursor: pointer; color: white"
                          @click="showVar"
                        >
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <img
                              src="../assets/插-icon.svg"
                              alt=""
                              class="pe-2 pt-1"
                            />

                            <span>插入個人化變數</span>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div class="row pt-3">
                      <label for="" style="width: 13%; color: #71afb6"
                        >模板名稱</label
                      >
                      <div style="width: 87%">
                        <input
                          v-bind:class="{ 'is-invalid': titleError2 }"
                          ref="titleInput2"
                          name="add-input"
                          class="form-control"
                          v-model="smsTitle"
                          @click="titleChange()"
                        />
                        <div class="invalid-feedback">
                          {{ titleErrMsg2 }}
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 row mt-4">
                      <div style="width: 13%">
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label"
                          >簡訊內容</label
                        >
                      </div>
                      <div style="width: 87%">
                        <textarea
                          v-bind:class="{ 'is-invalid': textError }"
                          ref="textInput"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          v-model="newSubsec"
                          :todo="todos"
                          style="height: 130px"
                          @click="textChange()"
                        ></textarea>
                        <div class="invalid-feedback">
                          {{ textErrMsg }}
                        </div>
                        <div>
                          中英數簡訊一封70字元
                          (縮址佔37字元)，若超過則會分為兩則。
                        </div>
                      </div>
                    </div>

                    <div class="row mb-1">
                      <div style="width: 13%">
                        <span class="banner-span" style="line-height: 56px"
                          >點擊追蹤</span
                        >
                      </div>
                      <div style="width: 87%">
                        <p class="upload-sub mt-3" style="color: #71afb6">
                          請輸入原始網址連結，系統將會自動轉為縮址以便追蹤，建議點擊預覽模板即可測試並觀看縮址後的結果。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-end mt-4 mb-3"
              v-if="this.editScriptSms === false"
            >
              <button
                type="button"
                class="btn btn-primary me-4"
                style="
                  width: 12vw;
                  background-color: #eaf3f4;
                  color: #71afb6;
                  border: none;
                  border-radius: 10px;
                "
                @click="expshow(false)"
              >
                <img
                  src="../assets/green-eye.svg"
                  style="width: 21px; position: relative; top: -1px"
                  class="me-2"
                />
                預覽
              </button>
              <button
                v-on:click="addNewSms"
                class="basic-btn highlight2"
                style="width: 12vw; cursor: pointer"
                v-if="this.selectedID === ''"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="../assets/save-icon.svg"
                    alt=""
                    style="position: relative; top: 1px"
                    class="me-3"
                  />
                  <span>新建</span>
                </div>
              </button>
              <button
                v-on:click="updateNewSms"
                class="basic-btn highlight2"
                style="width: 12vw; cursor: pointer"
                v-else
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="../assets/save-icon.svg"
                    alt=""
                    style="position: relative; top: 1px"
                    class="me-3"
                  />
                  <span>儲存</span>
                </div>
              </button>
            </div>
            <div class="d-flex justify-content-end mt-4 mb-3" v-else>
              <button
                type="button"
                class="btn btn-primary me-4"
                style="
                  width: 12vw;
                  background-color: #eaf3f4;
                  color: #71afb6;
                  border: none;
                  border-radius: 10px;
                "
                @click="expshow(false)"
              >
                <img
                  src="../assets/green-eye.svg"
                  style="width: 21px; position: relative; top: -1px"
                  class="me-2"
                />
                預覽
              </button>
              <button
                v-on:click="updateSmsScriptTemp()"
                class="basic-btn highlight2"
                style="width: 12vw; cursor: pointer"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="../assets/save-icon.svg"
                    alt=""
                    style="position: relative; top: 1px"
                    class="me-3"
                  />
                  <span>儲存</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sms exp modal -->
    <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>

    <!-- alert暫停Modal -->
    <div
      class="modal fade"
      id="alertModal7"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            確認後系統將暫停執行該流程
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary btn-basic"
              style="background-color: #eaf3f4; width: 10vw; color: #575757"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close btn-basic ms-4"
              data-bs-dismiss="modal"
              @click="stopScript"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- choose label alert -->
    <div
      class="modal fade"
      id="alertSaveScriptModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            是否在離開前儲存此行銷劇本?
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              離開
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="saveScript"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- alert建立模板名稱Modal -->
    <div
      class="modal fade"
      id="nameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>請為此Email模板建立名稱</p>
            <div>
              <input
                v-bind:class="{ 'is-invalid': eventNameError }"
                class="ps-2"
                style="
                  border-radius: 5px;
                  height: 30px;
                  border: 0.5px solid black;
                "
                ref="eventName"
                placeholder=""
                v-model="eventName"
              />
              <div class="invalid-feedback">
                {{ eventNameErrMsg }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close-cancel me-1"
              style="width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-1"
              style="width: 125px"
              @click="nameEmailTemp()"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert重新命名模板名稱Modal -->
    <div
      class="modal fade"
      id="renameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>Email模板重新命名</p>
            <div>
              <input
                v-bind:class="{ 'is-invalid': newTempNameError }"
                style="
                  border-radius: 5px;
                  height: 30px;
                  border: 0.5px solid black;
                "
                ref="eventName"
                placeholder=""
                v-model="newTempName"
              />
              <div class="invalid-feedback">
                {{ newTempNameErrMsg }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close-cancel me-1"
              style="width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-1"
              style="width: 125px"
              @click="confirmrename()"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- main -->
    <div class="container home" v-if="this.eventScriptIdFromSche != null">
      <div class="d-flex mb-4">
        <div style="width: 344px">
          <span class="script-header">
            <img
              src="../assets/back-arrow.png"
              @click="gobacktoschedule"
              style="cursor: pointer"
            />
            &nbsp; &nbsp; 自動回應</span
          >
        </div>
        <!-- <button @click="zoomin">test1</button>
        <button @click="zoomout">test2</button> -->
        <div style="position: relative" class="right-contain flex-fill">
          <div class="script-header text-center">
            排程活動名稱:{{ eventNameFromSche }}
          </div>
          <div class="script-tip-in-marketing-script">
            <div
              style="width: 30px; height: 30px; position: absolute"
              @mouseover="scriptTipShow = true"
              @mouseout="scriptTipShow = false"
            ></div>
            <img src="../assets/marketingScriptTip.svg" alt="" />
            <div class="parent-popout font-14">
              <div
                v-show="scriptTipShow"
                class="child-popout-top"
                style="--l2: -568px; width: 200px; right: -80px"
              >
                請按照著顏色順序依序拖曳至樹狀圖。
                <div class="container__arrow-inCustomLabel-trd"></div>
              </div>
            </div>
          </div>
          <span
            class="nowork"
            v-if="this.eventScriptHasEventFromSche === '0'"
          ></span>
          <span class="notwork" v-if="this.eventScriptHasEventFromSche === '1'"
            >草稿</span
          >
          <span
            class="work"
            v-if="
              this.eventScriptHasEventFromSche === '2' &&
              new Date(this.scriptDateStart) <= new Date()
            "
            >執行中</span
          >
          <span
            class="work"
            style="background-color: #dbebba"
            v-if="
              this.eventScriptHasEventFromSche === '2' &&
              new Date(this.scriptDateStart) > new Date()
            "
            >啟用中</span
          >
          <div style="inline-block" class="zoom2">
            <button
              @click="zoomin"
              style="
                border-radius: 10px;
                border: none;
                width: 30px;
                height: 30px;
                font-size: 20px;
                position: relative;
                top: -2px;
              "
              class="me-2"
            >
              +
            </button>
            <button
              @click="zoomout"
              style="
                border-radius: 10px;
                border: none;
                width: 30px;
                height: 30px;
                font-size: 24px;
              "
              class="me-2"
            >
              -
            </button>
            <button
              @click="zoomreset"
              style="
                border-radius: 10px;
                border: none;
                width: 30px;
                height: 30px;
                font-size: 24px;
              "
            >
              <img
                src="../assets/resetZoom.png"
                alt=""
                style="position: relative; top: -3px"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="me-4" style="width: 320px">
          <div class="contain-left-all">
            <!-- Tabs -->
            <div class="tab-div">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link active col-12 p-2"
                    id="event-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#event"
                    type="button"
                    role="tab"
                    aria-controls="event"
                    aria-selected="true"
                    @click="this.tabState = 'event'"
                  >
                    事件
                  </button>
                </li>
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link col-12 p-2"
                    id="time-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#time"
                    type="button"
                    role="tab"
                    aria-controls="time"
                    aria-selected="false"
                    @click="this.tabState = 'time'"
                  >
                    時間
                  </button>
                </li>
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link col-12 p-2"
                    id="action-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#action"
                    type="button"
                    role="tab"
                    aria-controls="action"
                    aria-selected="false"
                    @click="this.tabState = 'action'"
                  >
                    動作
                  </button>
                </li>
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link col-12 p-2"
                    id="template-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#template"
                    type="button"
                    role="tab"
                    aria-controls="template"
                    aria-selected="false"
                    @click="this.tabState = 'template'"
                  >
                    <!-- @click="offCanvas()" -->
                    模板
                  </button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="myTabContent">
              <!-- 事件div -->
              <div
                class="tab-pane fade show active"
                style="padding: 30px 0"
                id="event"
                role="tabpanel"
                aria-labelledby="event-tab"
              >
                <div class="d-flex flex-wrap">
                  <div
                    class="text-center col-4"
                    :draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 mail-image"
                      style="cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >開啟信件</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 click-image"
                      style="cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >點擊連結</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 change-image"
                      style="cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px">轉換</span>
                  </div>
                </div>
              </div>
              <!-- 時間div -->
              <div
                class="tab-pane fade text-center"
                style="padding: 30px 0"
                id="time"
                role="tabpanel"
                aria-labelledby="time-tab"
              >
                <div
                  class="text-center col-4"
                  draggable="true"
                  @dragstart="dragStart($event)"
                  @dragover="allowDrop"
                >
                  <div
                    class="blue-tag2 clock-image"
                    style="background-color: #fc9f66; cursor: grab"
                  ></div>
                  <span style="font-size: 14px; margin-top: 10px">時間後</span>
                </div>
              </div>
              <!-- 動作div -->
              <div
                class="tab-pane fade text-center"
                style="padding: 30px 0"
                id="action"
                role="tabpanel"
                aria-labelledby="action-tab"
              >
                <div class="d-flex">
                  <div
                    class="text-center col-4"
                    :draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 sendmail-image"
                      style="
                        background-color: #f9c357;
                        padding: 24px 8px;
                        cursor: grab;
                      "
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >傳送Email</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    :draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 sendsms-image"
                      style="
                        background-color: #f9c357;
                        padding: 20px 16px;
                        cursor: grab;
                      "
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >傳送SMS</span
                    >
                  </div>
                </div>
              </div>
              <!-- 模板div -->
              <div
                class="tab-pane fade text-center"
                style="padding: 30px 0; position: relative"
                id="template"
                role="tabpanel"
                aria-labelledby="template-tab"
              >
                <div class="group-div">
                  <button
                    class="channel-btn"
                    :style="
                      emailChannel
                        ? 'background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);color:white;opacity: 1;'
                        : ''
                    "
                    @click="changeChannel(true)"
                  >
                    Email
                  </button>
                  <button
                    class="channel-btn"
                    :style="
                      emailChannel
                        ? ''
                        : 'background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);color:white;opacity: 1;'
                    "
                    @click="changeChannel(false)"
                  >
                    SMS
                  </button>
                </div>

                <ProductList
                  v-bind:todos="todos"
                  ref="RefChilde"
                  @showrename-todo="renametodo"
                  @toggle-value="selectValue"
                  @copy-todo="copytodo"
                  @edit-todo="edittodo"
                  @clearalltoadd="clearalltoadd"
                  @refresh-todos="refreshtodo"
                  v-on:changeLoading="changeLoading"
                  v-bind:selectedID="selectedID"
                  v-bind:emailChannel="emailChannel"
                  v-on:search="search"
                />
              </div>
            </div>
          </div>
          <div
            class="shodow-box-afterTriggerScript"
            style="padding: 15px 12px; font-size: 14px"
            v-if="this.schedule[0] != undefined"
          >
            <p>
              <span style="margin-right: 15px; color: #c4c4c4">活動名稱</span
              ><span>{{ eventNameFromSche }}</span>
            </p>
            <p class="d-flex align-items-center">
              <span style="margin-right: 15px; color: #c4c4c4">選擇名單</span
              ><span
                class="select-label-bottom-span word-overflow"
                style="margin-right: 10px; width: 120px"
                >{{
                  this.schedule[0].label_name.includeLabelNames
                    .toString()
                    .replace(/,/g, "、")
                }}</span
              ><span
                class="flex-fill text-end"
                style="
                  font-family: sans-serif;
                  color: #c4c4c4;
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 12px;
                "
                @click="openExcludeModal()"
                >瀏覽完整名單</span
              >
            </p>
            <p class="d-flex m-0">
              <span style="margin-right: 15px; color: #c4c4c4">劇本時間</span
              ><span style="margin-right: 10px">
                <span>{{ this.scriptDateStart }}</span>
              </span>
            </p>
          </div>
        </div>
        <div
          style="position: relative; max-width: calc(100% - 354px)"
          class="ps-2 right-contain flex-fill"
        >
          <div
            id="treeDiv"
            class="contain-right-top-marketing"
            style="padding-top: 30px; cursor: grab"
            :style="grabbing ? 'cursor: grabbing' : ''"
            @dragover="allowDrop"
            @drop="dropEvent($event)"
            @mousedown="this.grabbing = true"
            @mouseup="this.grabbing = false"
            v-dragscroll
          >
            <div
              id="zoom"
              class="text-center"
              style="width: 680px; margin: 0 auto"
              :style="[fat ? 'width: 1000px' : '', zoom]"
              v-if="this.marketingChannel != ''"
            >
              <div
                class="action-tag2"
                style="position: relative"
                v-if="this.marketingChannel == 'email'"
              >
                <span
                  style="position: absolute; top: -15px; right: -15px"
                  @click="clearScript"
                  v-if="this.eventScriptHasEventFromSche != '0'"
                >
                  <img src="../assets/deleteScript.svg" alt="" />
                </span>
                <img src="../assets/sendmail.svg" class="mb-2" alt="" />
                <span style="position: relative; left: 2px; font-size: 14px">
                  傳送Email
                </span>
              </div>
              <div class="action-tag3" style="position: relative" v-else>
                <span
                  style="position: absolute; top: -15px; right: -15px"
                  @click="clearScript"
                  v-if="this.eventScriptHasEventFromSche != '0'"
                >
                  <img src="../assets/deleteScript.svg" alt="" />
                </span>
                <img
                  src="../assets/sendsms.svg"
                  style="position: relative; left: 2px"
                  alt=""
                />
                <br />
                <span style="position: relative; font-size: 14px">
                  傳送SMS
                </span>
              </div>
              <div class="d-flex justify-content-center col-12">
                <img src="../assets/treeline.svg" alt="" />
              </div>
              <div class="tree-tag" style="color: #71afb6; position: relative">
                <div class="template-tag">
                  {{ eventTitleFromSche }}
                </div>
              </div>

              <div class="d-flex justify-content-center col-12">
                <img src="../assets/treeline.svg" alt="" />
              </div>
              <!-- 樹狀圖 -->

              <div
                style="background-color: white"
                class="tree-div text-center"
                v-if="this.currentEvent != null"
              >
                <div class="blue-tag" @click="updateEventTimeInterval">
                  <img
                    src="../assets/hourglass.svg"
                    alt=""
                    style="width: 31px"
                  />
                  <div class="mt-2">
                    {{ this.time_interval }}{{ this.time_state }}
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div
                  class="blue-tag3"
                  :style="
                    this.currentEvent === '已開啟信件且點擊' ||
                    this.currentEvent === '已開啟信件且轉換' ||
                    this.currentEvent === '已點擊連結且轉換'
                      ? 'padding-top:4px'
                      : ''
                  "
                  @click="updateEventTimeInterval"
                >
                  <img
                    src="../assets/openmail.svg"
                    style="width: 40px"
                    alt=""
                    v-if="this.currentEvent.includes('開啟信件')"
                  />
                  <img
                    src="../assets/clicklink.svg"
                    style="width: 40px"
                    alt=""
                    v-if="this.currentEvent.includes('點擊連結')"
                  />
                  <img
                    src="../assets/change.svg"
                    style="width: 40px"
                    alt=""
                    v-if="this.currentEvent === '轉換'"
                  />
                  <div
                    style="
                      width: 100px;
                      position: relative;
                      left: -30px;
                      top: 3px;
                      color: white;
                    "
                    v-if="
                      this.currentEvent === '已開啟信件且點擊' ||
                      this.currentEvent === '已開啟信件且轉換' ||
                      this.currentEvent === '已點擊連結且轉換'
                    "
                  >
                    {{ this.currentEvent.slice(0, -3) }}
                    <br />
                    <span style="font-size: 12px">{{
                      this.currentEvent.slice(-3)
                    }}</span>
                  </div>
                  <div
                    style="
                      width: 100px;
                      position: relative;
                      left: -30px;
                      top: 5px;
                      color: white;
                    "
                    v-else
                  >
                    {{ this.currentEvent }}
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div class="d-flex justify-content-center">
                  <hr
                    class="m-0"
                    style="
                      border: none;
                      height: 0;
                      border-top: 2px solid #71afb6;
                      width: 50%;
                      opacity: 1;
                    "
                  />
                </div>

                <div class="d-flex">
                  <!-- Yes -->

                  <div
                    class="col-6"
                    style="background-color: white; height: 400px"
                    @dragover="allowDrop"
                    @drop.stop="dropTimeAtYes($event)"
                  >
                    <div class="d-flex justify-content-center">
                      <img src="../assets/treeline.svg" alt="" />
                    </div>
                    <div class="yes-tag">是</div>
                    <div class="d-flex justify-content-center">
                      <img src="../assets/treeline.svg" alt="" />
                    </div>
                    <div v-if="yes_time_after !== ''">
                      <div
                        style="background-color: white; height: 400px"
                        @dragover="allowDrop"
                        @drop.stop="dropActionAtYes($event)"
                      >
                        <div
                          class="time-tag"
                          style="position: relative"
                          @click="updateYesTime"
                        >
                          <img
                            src="../assets/redalert.svg"
                            alt=""
                            style="position: absolute; top: -10px; right: -5px"
                            v-if="
                              this.ader_yes_triggerCron === '' &&
                              this.cycleTime_yes != '一次性'
                            "
                          />
                          <img
                            src="../assets/clock.svg"
                            alt=""
                            style="width: 40px"
                          />
                          <div class="mt-1">
                            <p class="m-0" style="font-size: 14px">
                              {{ yes_time_after }}
                              {{ yes_time_after_state }}
                            </p>
                            <p class="m-0" style="font-size: 12px">
                              {{ cycleTime_yes }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center">
                          <img src="../assets/treeline.svg" alt="" />
                        </div>
                        <div
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropTemplateAtYes($event)"
                          v-if="yes_action != ''"
                        >
                          <div
                            class="action-tag2"
                            @click="updateYesAction"
                            v-if="this.yes_action == '傳送Email'"
                          >
                            <img
                              src="../assets/sendmail.svg"
                              class="mb-2"
                              alt=""
                            />
                            <span
                              style="
                                position: relative;
                                left: 2px;
                                font-size: 14px;
                              "
                            >
                              {{ yes_action }}
                            </span>
                          </div>
                          <div
                            class="action-tag3"
                            @click="updateYesAction"
                            v-else
                          >
                            <img
                              src="../assets/sendsms.svg"
                              style="position: relative; left: 2px"
                              alt=""
                            />
                            <br />
                            <span style="position: relative; font-size: 14px">
                              {{ yes_action }}
                            </span>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            style="background-color: white; height: 400px"
                            @dragover="allowDrop"
                            @drop.stop="dropEventAtYes($event)"
                            v-if="yes_template != ''"
                          >
                            <div
                              class="tree-tag"
                              style="color: #71afb6; position: relative"
                              @click="updateYesTemplate($event)"
                            >
                              <div
                                style="
                                  position: absolute;
                                  top: -10px;
                                  right: -5px;
                                "
                                v-if="
                                  this.yes_subject === '' &&
                                  this.yes_action == '傳送Email'
                                "
                              >
                                <img src="../assets/redalert.svg" alt="" />
                              </div>
                              <div class="template-tag">
                                {{ yes_template }}
                              </div>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img src="../assets/treeline.svg" alt="" />
                            </div>
                            <!-- UnderYes -->
                            <div
                              style="
                                background-color: white;
                                height: 400px;
                                width: 500px;
                              "
                              v-if="this.currentEventYes != null"
                            >
                              <div
                                class="blue-tag"
                                @click="updateYesEventTimeInterval"
                              >
                                <img
                                  src="../assets/hourglass.svg"
                                  alt=""
                                  style="width: 31px"
                                />
                                <div class="mt-2">
                                  {{ this.yes_time_interval
                                  }}{{ this.yes_time_state }}
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div
                                class="blue-tag3"
                                :style="
                                  this.currentEventYes === '已開啟信件且點擊' ||
                                  this.currentEventYes === '已開啟信件且轉換' ||
                                  this.currentEventYes === '已點擊連結且轉換'
                                    ? 'padding-top:4px'
                                    : ''
                                "
                                @click="updateYesEventTimeInterval"
                              >
                                <img
                                  src="../assets/openmail.svg"
                                  style="width: 40px"
                                  alt=""
                                  v-if="
                                    this.currentEventYes.includes('開啟信件')
                                  "
                                />
                                <img
                                  src="../assets/clicklink.svg"
                                  style="width: 40px"
                                  alt=""
                                  v-if="
                                    this.currentEventYes.includes('點擊連結')
                                  "
                                />
                                <img
                                  src="../assets/change.svg"
                                  style="width: 40px"
                                  alt=""
                                  v-if="this.currentEventYes === '轉換'"
                                />
                                <div
                                  style="
                                    width: 100px;
                                    position: relative;
                                    left: -30px;
                                    top: 3px;
                                    color: white;
                                  "
                                  v-if="
                                    this.currentEventYes ===
                                      '已開啟信件且點擊' ||
                                    this.currentEventYes ===
                                      '已開啟信件且轉換' ||
                                    this.currentEventYes === '已點擊連結且轉換'
                                  "
                                >
                                  {{ this.currentEventYes.slice(0, -3) }}
                                  <br />
                                  <span style="font-size: 12px">{{
                                    this.currentEventYes.slice(-3)
                                  }}</span>
                                </div>
                                <div
                                  style="
                                    width: 100px;
                                    position: relative;
                                    left: -30px;
                                    top: 5px;
                                    color: white;
                                  "
                                  v-else
                                >
                                  {{ this.currentEventYes }}
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div class="d-flex justify-content-center">
                                <hr
                                  class="m-0"
                                  style="
                                    border: none;
                                    height: 0;
                                    border-top: 2px solid #71afb6;
                                    width: 50%;
                                    opacity: 1;
                                  "
                                />
                              </div>
                              <div class="d-flex">
                                <div
                                  class="col-6"
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropTimeAtYesYes($event)"
                                >
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div class="yes-tag">是</div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div v-if="this.yesyes_time_after !== ''">
                                    <div
                                      style="
                                        background-color: white;
                                        height: 400px;
                                      "
                                      @dragover="allowDrop"
                                      @drop.stop="dropActionAtYesYes($event)"
                                    >
                                      <div
                                        class="time-tag"
                                        style="position: relative"
                                        @click="updateYesYesTime"
                                      >
                                        <img
                                          src="../assets/redalert.svg"
                                          alt=""
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.ader_yesyes_triggerCron ===
                                              '' &&
                                            this.cycleTime_yesyes != '一次性'
                                          "
                                        />
                                        <img
                                          src="../assets/clock.svg"
                                          alt=""
                                          style="width: 40px"
                                        />
                                        <div class="mt-1">
                                          <p
                                            class="m-0"
                                            style="font-size: 14px"
                                          >
                                            {{ yesyes_time_after }}
                                            {{ yesyes_time_after_state }}
                                          </p>
                                          <p
                                            class="m-0"
                                            style="font-size: 12px"
                                          >
                                            {{ cycleTime_yesyes }}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="d-flex justify-content-center"
                                      >
                                        <img
                                          src="../assets/treeline.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="
                                          dropTemplateAtYesYes($event)
                                        "
                                        v-if="this.yesyes_action != ''"
                                      >
                                        <div
                                          class="action-tag2"
                                          @click="updateYesYesAction"
                                          v-if="
                                            this.yesyes_action == '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/sendmail.svg"
                                            alt=""
                                            class="mb-2"
                                          />
                                          <span
                                            style="
                                              position: relative;
                                              left: 2px;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ yesyes_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="action-tag3"
                                          @click="updateYesYesAction"
                                          v-else
                                        >
                                          <img
                                            src="../assets/sendsms.svg"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            alt=""
                                          />
                                          <br />
                                          <span
                                            style="
                                              position: relative;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ yesyes_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="background-color: white"
                                          v-if="this.yesyes_template != ''"
                                        >
                                          <div
                                            class="tree-tag"
                                            style="
                                              color: #71afb6;
                                              position: relative;
                                            "
                                            @click="updateYesYesTemplate"
                                          >
                                            <div
                                              style="
                                                position: absolute;
                                                top: -10px;
                                                right: -5px;
                                              "
                                              v-if="
                                                this.yesyes_subject === '' &&
                                                this.yesyes_action ==
                                                  '傳送Email'
                                              "
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="template-tag">
                                              {{ this.yesyes_template }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-6"
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropTimeAtYesNo($event)"
                                >
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div class="no-tag">否</div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div v-if="this.yesno_time_after !== ''">
                                    <div
                                      style="
                                        background-color: white;
                                        height: 400px;
                                      "
                                      @dragover="allowDrop"
                                      @drop.stop="dropActionAtYesNo($event)"
                                    >
                                      <div
                                        class="time-tag"
                                        style="position: relative"
                                        @click="updateYesNoTime"
                                      >
                                        <img
                                          src="../assets/redalert.svg"
                                          alt=""
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.ader_yesno_triggerCron ===
                                              '' &&
                                            this.cycleTime_yesno != '一次性'
                                          "
                                        />
                                        <img
                                          src="../assets/clock.svg"
                                          alt=""
                                          style="width: 40px"
                                        />
                                        <div class="mt-1">
                                          <p
                                            class="m-0"
                                            style="font-size: 14px"
                                          >
                                            {{ yesno_time_after }}
                                            {{ yesno_time_after_state }}
                                          </p>
                                          <p
                                            class="m-0"
                                            style="font-size: 12px"
                                          >
                                            {{ cycleTime_yesno }}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="d-flex justify-content-center"
                                      >
                                        <img
                                          src="../assets/treeline.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTemplateAtYesNo($event)"
                                        v-if="this.yesno_action != ''"
                                      >
                                        <div
                                          class="action-tag2"
                                          @click="updateYesNoAction"
                                          v-if="
                                            this.yesno_action == '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/sendmail.svg"
                                            alt=""
                                            class="mb-2"
                                          />
                                          <span
                                            style="
                                              position: relative;
                                              left: 2px;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ yesno_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="action-tag3"
                                          @click="updateYesNoAction"
                                          v-else
                                        >
                                          <img
                                            src="../assets/sendsms.svg"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            alt=""
                                          />
                                          <br />
                                          <span
                                            style="
                                              position: relative;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ yesno_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="background-color: white"
                                          v-if="this.yesno_template != ''"
                                        >
                                          <div
                                            class="tree-tag"
                                            style="
                                              color: #71afb6;
                                              position: relative;
                                            "
                                            @click="updateYesNoTemplate"
                                          >
                                            <div
                                              style="
                                                position: absolute;
                                                top: -10px;
                                                right: -5px;
                                              "
                                              v-if="
                                                this.yesno_subject === '' &&
                                                this.yesno_action == '傳送Email'
                                              "
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="template-tag">
                                              {{ this.yesno_template }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- NO -->
                  <div
                    class="col-6"
                    style="background-color: white; height: 400px"
                    @dragover="allowDrop"
                    @drop.stop="dropTimeAtNo($event)"
                  >
                    <div class="d-flex justify-content-center">
                      <img src="../assets/treeline.svg" alt="" />
                    </div>
                    <div class="no-tag">否</div>
                    <div class="d-flex justify-content-center">
                      <img src="../assets/treeline.svg" alt="" />
                    </div>
                    <div v-if="no_time_after !== ''">
                      <div
                        style="background-color: white; height: 400px"
                        @dragover="allowDrop"
                        @drop.stop="dropActionAtNo($event)"
                      >
                        <div
                          class="time-tag"
                          style="position: relative"
                          @click="updateNoTime"
                        >
                          <img
                            src="../assets/redalert.svg"
                            alt=""
                            style="position: absolute; top: -10px; right: -5px"
                            v-if="
                              this.ader_no_triggerCron === '' &&
                              this.cycleTime_no != '一次性'
                            "
                          />
                          <img
                            src="../assets/clock.svg"
                            alt=""
                            style="width: 40px"
                          />
                          <div class="mt-1">
                            <p class="m-0" style="font-size: 14px">
                              {{ no_time_after }}
                              {{ no_time_after_state }}
                            </p>
                            <p class="m-0" style="font-size: 12px">
                              {{ cycleTime_no }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center">
                          <img src="../assets/treeline.svg" alt="" />
                        </div>
                        <div
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropTemplateAtNo($event)"
                          v-if="no_action != ''"
                        >
                          <div
                            class="action-tag2"
                            @click="updateNoAction"
                            v-if="this.no_action == '傳送Email'"
                          >
                            <img
                              src="../assets/sendmail.svg"
                              alt=""
                              class="mb-2"
                            />
                            <span
                              style="
                                position: relative;
                                left: 2px;
                                font-size: 14px;
                              "
                            >
                              {{ no_action }}
                            </span>
                          </div>
                          <div
                            class="action-tag3"
                            @click="updateNoAction"
                            v-else
                          >
                            <img
                              src="../assets/sendsms.svg"
                              style="position: relative; left: 2px"
                              alt=""
                            />
                            <br />
                            <span style="position: relative; font-size: 14px">
                              {{ no_action }}
                            </span>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            style="background-color: white; height: 400px"
                            @dragover="allowDrop"
                            @drop.stop="dropEventAtNo($event)"
                            v-if="no_template != ''"
                          >
                            <div
                              class="tree-tag"
                              style="color: #71afb6; position: relative"
                              @click="updateNoTemplate"
                            >
                              <div
                                style="
                                  position: absolute;
                                  top: -10px;
                                  right: -5px;
                                "
                                v-if="
                                  this.no_subject === '' &&
                                  this.no_action == '傳送Email'
                                "
                              >
                                <img src="../assets/redalert.svg" alt="" />
                              </div>
                              <div class="template-tag">
                                {{ no_template }}
                              </div>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img src="../assets/treeline.svg" alt="" />
                            </div>
                            <!-- UnderNo -->
                            <div
                              style="
                                background-color: white;
                                height: 400px;
                                width: 500px;
                              "
                              v-if="this.currentEventNo != null"
                            >
                              <div
                                class="blue-tag"
                                @click="updateNoEventTimeInterval"
                              >
                                <img
                                  src="../assets/hourglass.svg"
                                  alt=""
                                  style="width: 31px"
                                />
                                <div class="mt-2">
                                  {{ this.no_time_interval
                                  }}{{ this.no_time_state }}
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div
                                class="blue-tag3"
                                :style="
                                  this.currentEventNo === '已開啟信件且點擊' ||
                                  this.currentEventNo === '已開啟信件且轉換' ||
                                  this.currentEventNo === '已點擊連結且轉換'
                                    ? 'padding-top:4px'
                                    : ''
                                "
                                @click="updateNoEventTimeInterval"
                              >
                                <img
                                  src="../assets/openmail.svg"
                                  style="width: 40px"
                                  alt=""
                                  v-if="
                                    this.currentEventNo.includes('開啟信件')
                                  "
                                />
                                <img
                                  src="../assets/clicklink.svg"
                                  style="width: 40px"
                                  alt=""
                                  v-if="
                                    this.currentEventNo.includes('點擊連結')
                                  "
                                />
                                <img
                                  src="../assets/change.svg"
                                  style="width: 40px"
                                  alt=""
                                  v-if="this.currentEventNo === '轉換'"
                                />
                                <div
                                  style="
                                    width: 100px;
                                    position: relative;
                                    left: -30px;
                                    top: 3px;
                                    color: white;
                                  "
                                  v-if="
                                    this.currentEventNo ===
                                      '已開啟信件且點擊' ||
                                    this.currentEventNo ===
                                      '已開啟信件且轉換' ||
                                    this.currentEventNo === '已點擊連結且轉換'
                                  "
                                >
                                  {{ this.currentEventNo.slice(0, -3) }}
                                  <br />
                                  <span style="font-size: 12px">{{
                                    this.currentEventNo.slice(-3)
                                  }}</span>
                                </div>
                                <div
                                  style="
                                    width: 100px;
                                    position: relative;
                                    left: -30px;
                                    top: 5px;
                                    color: white;
                                  "
                                  v-else
                                >
                                  {{ this.currentEventNo }}
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div class="d-flex justify-content-center">
                                <hr
                                  class="m-0"
                                  style="
                                    border: none;
                                    height: 0;
                                    border-top: 2px solid #71afb6;
                                    width: 50%;
                                    opacity: 1;
                                  "
                                />
                              </div>
                              <div class="d-flex">
                                <div
                                  class="col-6"
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropTimeAtNoYes($event)"
                                >
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div class="yes-tag">是</div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div v-if="this.noyes_time_after !== ''">
                                    <div
                                      style="
                                        background-color: white;
                                        height: 400px;
                                      "
                                      @dragover="allowDrop"
                                      @drop.stop="dropActionAtNoYes($event)"
                                    >
                                      <div
                                        class="time-tag"
                                        style="position: relative"
                                        @click="updateNoYesTime"
                                      >
                                        <img
                                          src="../assets/redalert.svg"
                                          alt=""
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.ader_noyes_triggerCron ===
                                              '' &&
                                            this.cycleTime_noyes != '一次性'
                                          "
                                        />
                                        <img
                                          src="../assets/clock.svg"
                                          alt=""
                                          style="width: 40px"
                                        />
                                        <div class="mt-1">
                                          <p
                                            class="m-0"
                                            style="font-size: 14px"
                                          >
                                            {{ noyes_time_after }}
                                            {{ noyes_time_after_state }}
                                          </p>
                                          <p
                                            class="m-0"
                                            style="font-size: 12px"
                                          >
                                            {{ cycleTime_noyes }}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="d-flex justify-content-center"
                                      >
                                        <img
                                          src="../assets/treeline.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTemplateAtNoYes($event)"
                                        v-if="this.noyes_action != ''"
                                      >
                                        <div
                                          class="action-tag2"
                                          @click="updateNoYesAction"
                                          v-if="
                                            this.noyes_action == '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/sendmail.svg"
                                            alt=""
                                            class="mb-2"
                                          />
                                          <span
                                            style="
                                              position: relative;
                                              left: 2px;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ noyes_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="action-tag3"
                                          @click="updateNoYesAction"
                                          v-else
                                        >
                                          <img
                                            src="../assets/sendsms.svg"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            alt=""
                                          />
                                          <br />
                                          <span
                                            style="
                                              position: relative;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ noyes_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="background-color: white"
                                          @dragover="allowDrop"
                                          v-if="this.noyes_template != ''"
                                        >
                                          <div
                                            class="tree-tag"
                                            style="
                                              color: #71afb6;
                                              position: relative;
                                            "
                                            @click="updateNoYesTemplate"
                                          >
                                            <div
                                              style="
                                                position: absolute;
                                                top: -10px;
                                                right: -5px;
                                              "
                                              v-if="
                                                this.noyes_subject === '' &&
                                                this.noyes_action == '傳送Email'
                                              "
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="template-tag">
                                              {{ this.noyes_template }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-6"
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropTimeAtNoNo($event)"
                                >
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div class="no-tag">否</div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div v-if="this.nono_time_after !== ''">
                                    <div
                                      style="
                                        background-color: white;
                                        height: 400px;
                                      "
                                      @dragover="allowDrop"
                                      @drop.stop="dropActionAtNoNo($event)"
                                    >
                                      <div
                                        class="time-tag"
                                        style="position: relative"
                                        @click="updateNoNoTime"
                                      >
                                        <img
                                          src="../assets/redalert.svg"
                                          alt=""
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.ader_nono_triggerCron === '' &&
                                            this.cycleTime_nono != '一次性'
                                          "
                                        />
                                        <img
                                          src="../assets/clock.svg"
                                          alt=""
                                          style="width: 40px"
                                        />
                                        <div class="mt-1">
                                          <p
                                            class="m-0"
                                            style="font-size: 14px"
                                          >
                                            {{ nono_time_after }}
                                            {{ nono_time_after_state }}
                                          </p>
                                          <p
                                            class="m-0"
                                            style="font-size: 12px"
                                          >
                                            {{ cycleTime_nono }}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        class="d-flex justify-content-center"
                                      >
                                        <img
                                          src="../assets/treeline.svg"
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTemplateAtNoNo($event)"
                                        v-if="this.nono_action != ''"
                                      >
                                        <div
                                          class="action-tag2"
                                          @click="updateNoNoAction"
                                          v-if="this.nono_action == '傳送Email'"
                                        >
                                          <img
                                            src="../assets/sendmail.svg"
                                            alt=""
                                            class="mb-2"
                                          />
                                          <span
                                            style="
                                              position: relative;
                                              left: 2px;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ nono_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="action-tag3"
                                          @click="updateNoNoAction"
                                          v-else
                                        >
                                          <img
                                            src="../assets/sendsms.svg"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            alt=""
                                          />
                                          <br />
                                          <span
                                            style="
                                              position: relative;
                                              font-size: 14px;
                                            "
                                          >
                                            {{ nono_action }}
                                          </span>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="background-color: white"
                                          @dragover="allowDrop"
                                          v-if="this.nono_template != ''"
                                        >
                                          <div
                                            class="tree-tag"
                                            style="
                                              color: #71afb6;
                                              position: relative;
                                            "
                                            @click="updateNoNoTemplate"
                                          >
                                            <div
                                              style="
                                                position: absolute;
                                                top: -10px;
                                                right: -5px;
                                              "
                                              v-if="
                                                this.nono_subject === '' &&
                                                this.nono_action == '傳送Email'
                                              "
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="template-tag">
                                              {{ this.nono_template }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button
              class="bot-left-left-btn mt-4"
              style="height: 50px; font-size: 20px; width: 14%"
              @click="openStopScriptModel"
            >
              停用
            </button>
            <div class="d-flex justify-content-between" style="width: 75%">
              <button
                class="bot-left-btn mt-4"
                style="
                  height: 50px;
                  font-size: 20px;
                  width: 31%;
                  color: #71afb6;
                "
                @click="gobacktoschedule()"
              >
                <img
                  src="../assets/green-left-arrow.svg"
                  alt=""
                  style="position: relative; top: -1px"
                />
                &nbsp;返回列表
              </button>
              <button
                class="bot-left-btn mt-4"
                style="
                  height: 50px;
                  font-size: 20px;
                  width: 31%;
                  color: #71afb6;
                "
                @click="saveScript()"
              >
                <img
                  src="../assets/greenSave.svg"
                  style="position: relative; top: -2px"
                />
                &nbsp;儲存
              </button>
              <button
                class="bot-right-btn mt-4"
                style="height: 50px; font-size: 20px; width: 31%"
                @click="notDev()"
              >
                <!-- @click="openRunScriptModel" -->
                <img
                  src="../assets/check.png"
                  style="position: relative; top: -2px"
                />
                &nbsp;啟用
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="
        container
        home
        v-else-div
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <a @click="gosche" class="mt-5" style="cursor: pointer"
        ><img src="../assets/guideToSche.svg" alt="img"
      /></a>
    </div>
  </div>
</template>



<script>
import { ref } from "vue";
import ProductList from "../components/MarketingScript/ProductList.vue";
import DropdownBlack from "../components/DropdownBlack.vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import moment from "moment";
import SmsPreview from "../components/SmsPreview.vue";
import EmailPreview from "../components/EmailPreview.vue";

export default {
  name: "MarketingScript",
  components: {
    ProductList,
    DropdownBlack,
    SmsPreview,
    EmailPreview,
  },
  setup() {
    const daytime = ref({
      hours: new Date().getHours(),
      minutes: new Date().getMinutes(),
    });

    return {
      daytime,
    };
  },
  data() {
    return {
      random_Cbox_key:'',
      firstNode: {},
      cacheNode: {},
      scriptDateStart: "",
      scriptTipShow: false,
      loading_copy: false,

      event_moreoption: false,
      event_moreoption_Selected: "",
      endCount2: "",
      picked3: "一次性",

      z: 1,
      zoom: "",
      resSubsec: "",

      from: "",
      group: "",
      name: "",
      token: "",
      key: "",
      marketingChannel: "",
      emailChannel: true,
      random_id: "",

      grabbing: false,
      fat: false,
      notshow: false,
      loading: false,

      testList2: [],
      editList: false,

      happenTime: "月",
      happenDaySelector: [],
      happenMonthSelector2: [],
      happenYearMonthSelector: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],

      happenDay_Selected: "",
      happenWeek_Selected: "",
      happenWeek2_Selected: "",
      happenMonth_Selected: "",
      happenMonth2_Selected: "",
      happenMonth3_Selected: "",
      happenMonth4_Selected: "",
      happenMonth5_Selected: "",
      happenYear_Selected: "",
      happenYear2_Selected: "",
      happenYear3_Selected: "",
      happenYear4_Selected: "",
      happenYear5_Selected: "",

      cycleTime_yes: "一次性",
      cycleTime_yesyes: "一次性",
      cycleTime_yesno: "一次性",
      cycleTime_no: "一次性",
      cycleTime_noyes: "一次性",
      cycleTime_nono: "一次性",

      ader_yes_triggerCron: "",
      ader_yesyes_triggerCron: "",
      ader_yesno_triggerCron: "",
      ader_no_triggerCron: "",
      ader_noyes_triggerCron: "",
      ader_nono_triggerCron: "",

      yes_triggerCron: {},
      yesyes_triggerCron: {},
      yesno_triggerCron: {},
      no_triggerCron: {},
      noyes_triggerCron: {},
      nono_triggerCron: {},

      yesindex: null,
      yesyesindex: null,
      yesnoindex: null,
      noindex: null,
      noyesindex: null,
      nonoindex: null,

      hour: "",
      minute: "",
      day: "",
      onlyhour: "",

      // data for template
      selectedID: "",
      i: 1,

      titleError2: false,
      titleErrMsg2: "",
      titleError: false,
      titleErrMsg: "",
      subError: false,
      subErrMsg: "",
      textError: false,
      textErrMsg: "",
      noBanner: false,

      newTodoTitle: "",
      newTodoTitleError: false,
      newTodoTitleErrMsg: "",
      smsTitle: "",
      newSub: "",
      newSubsec: "",

      searchQuery: "",
      todos: [],
      email_todos: [],
      sms_todos: [],
      searchTodos: [],
      schedule: [],
      schedules: [],
      email_schedules: [],
      sms_schedules: [],
      childtodo: [],
      editinput: false,
      editfile: false,
      editimage: false,
      url: null,
      url2: null,
      beClick: true,
      base64Banner: "",
      base64Footer: "",
      bannerURL: "",
      bannerKEY: null,
      footerURL: "",
      footerKEY: null,

      isShow: false,
      isShow2: false,
      isShow3: false,
      isShowMoney: false,

      // Tab offcanvas data
      tabState: "event",
      updateWho: "",

      eventSelected: "",
      currentEvent: null,
      currentEventYes: null,
      currentEventNo: null,
      time_interval_Selected: "",
      time_interval: "",
      yes_time_interval: "",
      no_time_interval: "",
      time_state_Selected: "",
      time_state: "",
      yes_time_state: "",
      no_time_state: "",

      time_after_Selected: "",
      yes_time_after: "",
      yesyes_time_after: "",
      yesno_time_after: "",
      no_time_after: "",
      noyes_time_after: "",
      nono_time_after: "",
      time_after_state_Selected: "",
      yes_time_after_state: "天後",
      yesyes_time_after_state: "天後",
      yesno_time_after_state: "天後",
      no_time_after_state: "天後",
      noyes_time_after_state: "天後",
      nono_time_after_state: "天後",

      action_Selected: "",
      yes_action: "",
      yesyes_action: "",
      yesno_action: "",
      no_action: "",
      noyes_action: "",
      nono_action: "",

      yes_template: "",
      yesyes_template: "",
      yesno_template: "",
      no_template: "",
      noyes_template: "",
      nono_template: "",

      yes_subject: "",
      yesyes_subject: "",
      yesno_subject: "",
      no_subject: "",
      noyes_subject: "",
      nono_subject: "",

      //  從sessionStorage裏的活動名稱等資料data
      eventNameFromSche: "",
      eventTitleFromSche: "",
      eventScheduleIdFromSche: "",
      eventScriptIdFromSche: null,
      eventLabelIdFromSche: "",
      eventScriptHasEventFromSche: "0",

      // 8081 url
      url8081: "",
      unlayerJson: "",
      eventName: "",
      eventNameError: "",
      eventNameErrMsg: "",
      newTempName: "",
      newTempNameError: false,
      newTempNameErrMsg: "",
      todojson: "",
      todohtml: "",
      html: "",
      newHtml: "",
      vice_id: "",
      Parr: [],
      fullurlArr: [],
      shortUrlArr: [],

      // sms template
      expSubsec: "",
      newText: "",
      urlok: true,
      smsModal: {},
      editScriptSms: false,
      offCanvasClick: {},
      unlayerModal: {},
      closeUnlayerModal: {},
      alertModal3: {},

      varName: "{name}",
      varLastName: "{lastname}",
      varFirstName: "{firstname}",
      triggerType: "顧客姓名",
      autoShow: false,

      countText: "",
      events: [],
      errorDrop: "",
    };
  },
  // computed:{
  //   percent: function(){
  //     return (100/this.timeArrayYes.length()+1) + '%'
  //   }
  // },
  watch: {
    time_interval_Selected() {
      if (this.time_state_Selected === "小時內") {
        if (this.time_interval_Selected > 24) {
          return (this.time_interval_Selected = 24);
        } else if (this.time_interval_Selected < 1) {
          this.time_interval_Selected = "";
        } else if (this.time_interval_Selected.toString().length > 2) {
          this.time_interval_Selected = 23;
        }
      } else {
        if (this.time_interval_Selected > 365) {
          return (this.time_interval_Selected = 365);
        } else if (this.time_interval_Selected < 1) {
          this.time_interval_Selected = "";
        } else if (this.time_interval_Selected.toString().length > 3) {
          this.time_interval_Selected = 365;
        }
      }
    },
    time_after_Selected() {
      if (this.time_after_state_Selected === "小時後") {
        if (this.time_after_Selected > 24) {
          return (this.time_after_Selected = 24);
        } else if (this.time_after_Selected < 1) {
          this.time_after_Selected = "";
        } else if (this.time_after_Selected.toString().length > 2) {
          this.time_after_Selected = 23;
        }
      } else {
        if (this.time_after_Selected > 365) {
          return (this.time_after_Selected = 365);
        } else if (this.time_after_Selected < 1) {
          this.time_after_Selected = "";
        } else if (this.time_after_Selected.toString().length > 3) {
          this.time_after_Selected = 365;
        }
      }
    },
    time_after_state_Selected() {
      if (this.time_after_state_Selected === "小時後") {
        if (this.time_after_Selected > 24) {
          return (this.time_after_Selected = 24);
        } else if (this.time_after_Selected < 1) {
          this.time_after_Selected = "";
        } else if (this.time_after_Selected.toString().length > 2) {
          this.time_after_Selected = 23;
        }
      }
    },
    time_state_Selected() {
      if (this.time_state_Selected === "小時內") {
        if (this.time_interval_Selected > 24) {
          return (this.time_interval_Selected = 24);
        } else if (this.time_interval_Selected < 1) {
          this.time_interval_Selected = "";
        } else if (this.time_interval_Selected.toString().length > 2) {
          this.time_interval_Selected = 23;
        }
      }
    },
    currentEventYes() {
      if (this.currentEventYes === null && this.currentEventNo === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventNo() {
      if (this.currentEventYes === null && this.currentEventNo === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    testList2: {
      handler: function () {
        this.editList = true;
        console.log("this.editList", this.editList);
      },
      deep: true,
    },
    newTempName(val) {
      var isText = /^[\u4e00-\u9fa5_a-zA-Z0-9()-\s]+$/;
      if (!isText.test(val)) {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "你輸入了不允許的特殊字元";
      } else if (val === "") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "模板名稱不能為空";
      } else {
        this.newTempNameError = false;
      }
    },
    newTodoTitle(val) {
      var isText = /^[\u4e00-\u9fa5_a-zA-Z0-9()-\s]+$/;
      if (!isText.test(val)) {
        this.newTodoTitleError = true;
        this.newTodoTitleErrMsg = "你輸入了不允許的特殊字元";
      } else if (val === "") {
        this.newTodoTitleError = true;
        this.newTodoTitleErrMsg = "模板名稱不能為空";
      } else {
        this.newTodoTitleError = false;
      }
    },
    emailChannel() {
      if (this.emailChannel) {
        this.todos = this.email_todos;
      } else {
        this.todos = this.sms_todos;
      }
    },
  },

  mounted: async function () {
    for (let index = 1; index < 31; index++) {
      const day = "每" + index + "天";
      this.happenDaySelector.push(day);
    }
    for (let index3 = 1; index3 < 12; index3++) {
      const day = "每" + index3 + "個月";
      this.happenMonthSelector2.push(day);
    }
    if (sessionStorage.getItem("lv") == "0") {
      this.notshow = 0;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "1") {
      this.notshow = 1;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "2") {
      this.notshow = 2;
      sessionStorage.removeItem("lv");
    }
    this.random_id = "T_" + this.randomString(8);
    this.random_Cbox_key= "T_" + new Date().getTime();

    this.loading = true;
    this.eventNameFromSche = sessionStorage.getItem("setResEventName");
    this.eventTitleFromSche = sessionStorage.getItem("setResEventTitle");
    this.eventScheduleIdFromSche = sessionStorage.getItem("setResScheduleId");
    this.eventScriptIdFromSche = sessionStorage.getItem("setResScriptId");

    this.from = "script";
    this.token = sessionStorage.getItem("access_token");
    this.group = sessionStorage.getItem("setResLabelName");

    if (window.location.origin === "http://localhost:8080") {
      this.url8081 = `http://localhost:8081/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    } else if (
      window.location.origin === "https://firstcdp-test.retailing-data.net"
    ) {
      this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    } else {
      this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    }

    this.eventScriptHasEventFromSche = sessionStorage.getItem(
      "setResScriptHasEvent"
    );
    await this.get();
    await this.getSchedules();
    await this.getSms();
    await this.getSmsSchedules();
    if (sessionStorage.getItem("isEmailChannel") === "true") {
      this.marketingChannel = "email";
      this.emailChannel = true;
      this.schedules = this.email_schedules;
      this.todos = this.email_todos;
      this.schedule = this.email_schedules.filter((d) => {
        return d.script_id === this.eventScriptIdFromSche;
      });
    } else if (sessionStorage.getItem("isEmailChannel") === "false") {
      this.marketingChannel = "sms";
      this.emailChannel = false;
      this.schedules = this.sms_schedules;
      this.todos = this.sms_todos;
      this.schedule = this.sms_schedules.filter((d) => {
        return d.script_id === this.eventScriptIdFromSche;
      });
    }

    // await this.getCalenEvents();

    this.todos.map((d) => {
      if (d.template_id === this.random_id) {
        this.random_id = "T_" + this.randomString(8);
      }
    });
    // this.eventNameFromSche = this.$store.state.toScriptData.selectedTodo;
    // this.eventScheduleIdFromSche = 'ReMA_c6847bf3';
    if (
      this.eventScheduleIdFromSche != null &&
      this.eventScriptHasEventFromSche != "0"
    ) {
      this.getScriptById();
    } else {
      this.loading = false;
    }
    console.log("eventname", this.eventNameFromSche);
    console.log("eventTitle", this.eventTitleFromSche);
    console.log("eventScriptIdFromSche", this.eventScriptIdFromSche);
    console.log("eventScheduleIdFromSche", this.eventScheduleIdFromSche);

    window.addEventListener("beforeunload", (e) => this.beforeunloadcall(e));

    // this.group = sessionStorage.getItem("getlabelTitle");
  },
  // mounted() {
  // },
  beforeUnmount() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  beforeRouteLeave: function (to, from) {
    console.log("beforeRouteLeave");

    sessionStorage.removeItem("setResEventName");
    sessionStorage.removeItem("setResEventTitle");
    sessionStorage.removeItem("setResScheduleId");
    sessionStorage.removeItem("setResScriptId");
    sessionStorage.removeItem("setResScriptHasEvent");
  },
  methods: {
    // 顯示彈出訊息
    showToast() {
      var toastLiveExample = document.getElementById("liveToast");
      var toast = new bootstrap.Toast(toastLiveExample);

      toast.show();
    },
    // 換模板channel
    changeChannel(boolean) {
      this.emailChannel = boolean;
    },
    notDev() {
      if (this.notshow == 1) {
        alert("該功能為正式付費使用");
      } else {
        this.openRunScriptModel();
      }
    },
    // getCalenEvents: async function () {
    //   const token = sessionStorage.getItem("access_token");
    //   const params = {
    //     Authorization: "Bearer " + token,
    //   };

    //   await allapi2
    //     .activityCalenderGet(params)
    //     .then((res) => {
    //       console.log('拿行事曆活動',res);
    //       res.data.Data.map(
    //         (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
    //       );
    //       res.data.Data.map(
    //         (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
    //       );
    //       this.events = res.data.Data;
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤");
    //       console.log(err);
    //     });
    // },
    openExcludeModal() {
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    onCopy: function (e) {
      console.log("You just copied: " + e.text);
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
    showVar() {
      const varnameModal = new bootstrap.Modal(
        document.getElementById("varnameModal"),
        {
          keyboard: false,
        }
      );
      varnameModal.show();
    },
    selectValueByDrop(selectValue, changingSelected) {
      this[changingSelected] = selectValue;
      if (selectValue === "已開啟信件" || selectValue === "已點擊連結") {
        this.event_moreoption_Selected = "";
      }
    },

    gobacktoschedule() {
      if (this.marketingChannel == "email") {
        this.$router.push({
          name: "MarketingSchedule",
        });
      } else {
        this.$router.push({
          name: "SmsSchedule",
        });
      }
    },
    renametodo(todo) {
      this.childtodo = todo;
      console.log(this.childtodo);
      this.newTempName = todo.name;

      const renameModal = new bootstrap.Modal(
        document.getElementById("renameModal"),
        {
          keyboard: false,
        }
      );
      renameModal.show();
    },
    clearalltoadd() {
      this.selectedID = "";
      this.newTodoTitle = "";
      this.newSub = "";
      this.newSubsec = "";
      this.smsTitle = "";

      if (this.emailChannel) {
        this.nameModal = new bootstrap.Modal(
          document.getElementById("nameModal"),
          {
            keyboard: false,
          }
        );
        this.nameModal.show();
      } else {
        this.editScriptSms = false;
        this.smsModal = new bootstrap.Modal(
          document.getElementById("smsModal"),
          {
            keyboard: false,
          }
        );
        this.smsModal.show();
        const backdrop = document.getElementsByClassName("modal-backdrop");
        backdrop[backdrop.length - 1].style["z-index"] = 3500;
      }
    },
    nameEmailTemp() {
      if (this.todos.findIndex((d) => d.name === this.eventName) != "-1") {
        this.eventNameError = true;
        this.eventNameErrMsg = "您要使用的模板名稱已有其他模板使用";
        const nameModal = new bootstrap.Modal(
          document.getElementById("nameModal"),
          {
            keyboard: false,
          }
        );
        nameModal.show();
      } else if (this.eventName === "") {
        this.eventNameError = true;
        this.eventNameErrMsg = "必須輸入模板名稱";
        const nameModal = new bootstrap.Modal(
          document.getElementById("nameModal"),
          {
            keyboard: false,
          }
        );
        nameModal.show();
      } else {
        this.from = "scriptTemplate";
        this.name = this.eventName;
        this.key = "undefined";
        if (window.location.origin === "http://localhost:8080") {
          this.url8081 = `http://localhost:8081/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else {
          this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        }
        this.showUnlayer();
      }
    },

    confirmrename: async function () {
      if (this.newTempNameError) {
        alert("模板命名有誤");
        return;
      }
      if (this.todos.findIndex((d) => d.name === this.newTempName) != "-1") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "您要使用的模板名稱已有其他模板使用";
        const renameModal = new bootstrap.Modal(
          document.getElementById("renameModal"),
          {
            keyboard: false,
          }
        );
        renameModal.show();
      } else if (this.newTempName === "") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "必須輸入模板名稱";
        const renameModal = new bootstrap.Modal(
          document.getElementById("renameModal"),
          {
            keyboard: false,
          }
        );
        renameModal.show();
      } else {
        await this.patchTemplateApi(this.childtodo);

        this.selectValue(this.searchTodos[0]);
        this.newTempName = "";
      }
    },
    patchTemplateApi: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
        name: this.newTempName,
        json: this.todojson,
        html: this.todohtml,
      };

      await allapi2
        .templatev2TempPatch(params, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
      await this.get();
    },

    showUnlayer() {
      this.unlayerModal = new bootstrap.Modal(
        document.getElementById("unlayerModal"),
        {
          keyboard: false,
        }
      );
      this.unlayerModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 3500;

      const unlayerModal = document.getElementById("unlayerModal");

      if (unlayerModal.refreshtodos) {
        unlayerModal.removeEventListener(
          "hidden.bs.modal",
          unlayerModal.refreshtodos
        );
      }
      unlayerModal.refreshtodos = () => {
        const token = sessionStorage.getItem("access_token");

        const params = {
          Authorization: "Bearer " + token,
        };
        allapi2
          .templatev2TempInfoGet(params)
          .then((res) => {
            console.log("GET", res);
            this.todos = res.data;
            this.todos.sort((a, b) => {
              return new Date(b.time) - new Date(a.time);
            });

            this.searchTodos = this.todos;
            console.log("this.todos", this.todos);
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("get連線有誤" + err);
            this.loading = false;
          });
      };
      unlayerModal.addEventListener(
        "hidden.bs.modal",
        unlayerModal.refreshtodos
      );
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    offCanvas() {
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
    },
    showexp: async function () {
      await this.getScriptCboxBykey(this.key);
      if (this.noCbox!=true) {
        console.log("Cbox");
      } else {
        await this.getTemplateByScriptkey(this.key);
      }
      this.newHtml = this.newHtml
        .replace(
          "{{p1}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
        )
        .replace(
          "{{p2}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
        )
        .replace(
          "{{p3}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
        )
        .replace(
          "{{p4}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
        )
        .replace("{{tracking}}", "");
      this.changeAllUrl();
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.newHtml);
      console.log(this.fullurlArr);
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.newHtml = this.newHtml.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },
    changePage() {
      this.$refs.page.changePage;
    },
    show() {
      this.isShow = !this.isShow;
    },
    show2() {
      this.isShow2 = !this.isShow2;
    },
    show3() {
      this.isShow3 = !this.isShow3;
    },
    titleChange() {
      this.editinput = true;
    },
    subChange() {
      this.editinput = true;
    },
    textChange() {
      this.editinput = true;
    },

    search(val) {
      const searched = this.searchTodos.filter((d) => d.name.includes(val));
      this.todos = searched;
    },
    changeLoading() {
      this.loading = !this.loading;
    },
    refreshtodo: async function () {
      if (this.emailChannel) {
        await this.get();
      } else {
        await this.getSms();
      }
      await this.selectValue(this.todos[0]);
    },

    getTemplateById: async function (todo) {
      this.loading_copy = true;

      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: todo.key,
      };

      await allapi2
        .templatev2TempKeyGet(params)
        .then((res) => {
          console.log(res);
          this.todojson = res.data.json;
          this.todohtml = res.data.html;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
      this.sliceStyle();
      this.changeCustomTool();
      await this.getallhttp();
      await this.getAllShortHttp();
      this.changeHtmlhttp();
      this.loading_copy = false;
    },
    sliceStyle() {
      // const styleStart = this.todohtml.indexOf('<style type="text/css">');
      // const styleEnd = this.todohtml.indexOf("</style>");
      const bodyEnd = this.todohtml.indexOf("</body>");
      // const html1 = this.todohtml.slice(0, styleStart);
      // const html2 = this.todohtml.slice(styleEnd + 8, bodyEnd);
      // const html3 = this.todohtml.slice(bodyEnd);
      // this.newHtml = html1 + html2 + "<div>{{tracking}}</div>" + html3;
      // var re = /<p style="/gi;
      // this.newHtml = this.newHtml.replace(re, '<p style="margin:0;');
      const html1 = this.todohtml.slice(0, bodyEnd);
      const html3 = this.todohtml.slice(bodyEnd);
      this.newHtml = html1 + "<div>{{tracking}}</div>" + html3;
      console.log("newHtml", this.newHtml);
    },
    changeCustomTool() {
      this.newHtml = this.newHtml
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png",
          "{{p1}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png",
          "{{p2}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png",
          "{{p3}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png",
          "{{p4}}"
        )
        .replace("www.google1.com", "{{p1_path}}")
        .replace("www.google2.com", "{{p2_path}}")
        .replace("www.google3.com", "{{p3_path}}")
        .replace("www.google4.com", "{{p4_path}}");
    },
    getallhttp: async function () {
      function isValidURL(string) {
        var res = string.match(
          /"(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newHtml);

      this.fullurlArr = this.fullurlArr.filter((d) => {
        return (
          !d.includes(
            "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"
          ) &&
          !d.includes("http://www.w3.org/1999/xhtml") &&
          // 排除 舊版的模板會用的圖片路徑不要轉短網址 
          !d.includes("rema-images") &&
          // 排除 新版的模板會用的圖片路徑不要轉短網址 
          !d.includes("rema-image-resize") &&
          !d.includes("cdn.tools.unlayer") &&
          !d.includes("@")
        );
      });
      this.fullurlArr = this.fullurlArr.map((d) => {
        return d.slice(1, -1);
      });

      console.log("this.fullurlArr", this.fullurlArr);
    },
    getAllShortHttp: async function () {
      this.shortUrlArr = [];
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrlApi(this.fullurlArr[index]);
      }
    },
    changeHtmlhttp() {
      for (let index = 0; index < this.fullurlArr.length; index++) {
        this.Parr.push("{[pfullurl]}" + index);
        this.newHtml = this.newHtml.replace(
          this.fullurlArr[index],
          this.Parr[index]
        );
      }
      for (let index = 0; index < this.fullurlArr.length; index++) {
        this.newHtml = this.newHtml.replace(
          this.Parr[index],
          this.shortUrlArr[index]
        );
      }
      console.log("newHtml", this.newHtml);
    },
    getShortUrlApi: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        ruleID: this.vice_id+this.version,
      };
      await allapi2
        .templateShortenUrlPost(params, body)
        .then((res) => {
          console.log(res);
          this.shortUrlArr.push(res.data.url + `?i={{id}}&z=${this.randomString(12)}&x=${this.randomString(12)}`);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.htmlok = false;
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },

    copytodo: async function (todo) {
      this.loading = true;
      await this.getTemplateById(todo);
      console.log(todo);
      this.childtodo = todo;
      while (
        this.todos.findIndex(
          (d) => d.name === this.childtodo.name + "(" + this.i + ")"
        ) != "-1"
      ) {
        this.i++;
      }
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.childtodo.name + "(" + this.i + ")",
        json: this.todojson,
        html: this.todohtml,
      };
      await allapi2
        .templatev2TempPost(params, body)
        .then((res) => {
          console.log(res);
          const alertModal4 = new bootstrap.Modal(
            document.getElementById("alertModal4"),
            {
              keyboard: false,
            }
          );
          alertModal4.show();
          // $("#alertModal4").modal("show");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
      await this.get();
      await this.selectValue(this.todos[0]);
      this.$refs.RefChilde.changePage();
      this.loading = false;
    },
    edittodo: async function (todo) {
      await this.selectValue(todo);
      if (this.emailChannel) {
        console.log("todo", todo);
        this.name = todo.name;
        this.key = todo.key;
        this.from = "scriptTemplate";
        if (window.location.origin === "http://localhost:8080") {
          this.url8081 = `http://localhost:8081/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else {
          this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        }

        this.showUnlayer();
      } else {
        this.titleError2 = false;
        this.textError = false;
        this.editScriptSms = false;
        console.log("todo", todo);
        this.getSmsTemplateById(todo);
        this.smsTitle = todo.name;
        this.key = todo.key;
        this.smsModal = new bootstrap.Modal(
          document.getElementById("smsModal"),
          {
            keyboard: false,
          }
        );
        this.smsModal.show();
        const backdrop = document.getElementsByClassName("modal-backdrop");
        backdrop[backdrop.length - 1].style["z-index"] = 3500;
      }
    },
    edittodoinscript: async function () {
      let unlayer_key =''
      if (this.emailChannel) {
        this.url8081 = "";
        console.log(`準備去unlayer那頁編輯劇本模板,現在this.key是${this.key}`);
        await this.getScriptCboxBykey(this.key);
        if (this.noCbox!=true) {
          this.from = "scriptSchedule_Cbox";
        unlayer_key = this.key + this.random_Cbox_key;
        } else {
          this.from = "scriptSchedule";
        unlayer_key =  this.key + this.random_Cbox_key;
        }
         console.log(
          `準備去unlayer那頁編輯劇本模板,現在this.from是${this.from}`
        );
        if (window.location.origin === "http://localhost:8080") {
          this.url8081 = `http://localhost:8081/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else {
          this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        }
        this.showUnlayer();
      } else {
        this.titleError2 = false;
        this.textError = false;
        this.editScriptSms = true;
        await this.getScriptCboxBykey(this.key);
        if (this.noCbox!=true) {
          console.log("Cbox");
        } else {
          await this.getTemplateByScriptkey(this.key);
        }
        this.smsTitle = this.newTodoTitle;
        this.smsModal = new bootstrap.Modal(
          document.getElementById("smsModal"),
          {
            keyboard: false,
          }
        );
        this.smsModal.show();
        const backdrop = document.getElementsByClassName("modal-backdrop");
        backdrop[backdrop.length - 1].style["z-index"] = 3500;
      }
    },
    getSmsTemplateById: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: todo.key,
      };

      await allapi2
        .smsTemplateSmsTempKeyGet(params)
        .then((res) => {
          console.log(res.data);
          this.newSubsec = res.data.data.text;
          this.todojson = res.data.data.text;
          this.newHtml = res.data.data.newText;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    addNewSms: async function () {
      await this.findAllURl();
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;

      if (this.todos.findIndex((d) => d.name === this.smsTitle) != "-1") {
        this.$refs.titleInput.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "您要使用的模板名稱已有其他模板使用";
      } else if (isText.test(this.smsTitle)) {
        this.$refs.titleInput.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "名稱不能含有是有特殊字元";
      } else if (this.smsTitle === null || this.smsTitle === "") {
        this.$refs.titleInput.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "必須輸入標題";
      } else if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;
        // 上傳圖片API

        await this.postSms();
        this.smsModal.hide();
      }
    },
    updateNewSms: async function () {
      await this.findAllURl();
      console.log("?????");
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      if (
        this.todos.findIndex(
          (d) =>
            d.name === this.smsTitle && this.smsTitle != this.childtodo.name
        ) != "-1"
      ) {
        this.$refs.titleInput2.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "您要使用的模板名稱已有其他模板使用";
      } else if (isText.test(this.smsTitle)) {
        this.$refs.titleInput.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "名稱不能含有是有特殊字元";
      } else if (this.smsTitle === null || this.smsTitle === "") {
        this.$refs.titleInput2.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "必須輸入標題";
      } else if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;

        await this.patchSms();
        this.editinput = false;

        this.loading = false;
        this.smsModal.hide();
      }
    },
    patchSms: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.selectedID,
        name: this.smsTitle,
        temp: { text: this.newSubsec, newText: this.newHtml },
      };
      await allapi2
        .smsTemplateSmsTempInfoPatch(params, body)
        .then((res) => {
          console.log(res);
          this.getSms();
          this.alertModal3 = new bootstrap.Modal(
            document.getElementById("alertModal3"),
            {
              keyboard: false,
            }
          );
          this.alertModal3.show();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("patch連線有誤" + err);
          this.loading = false;
        });
    },
    postSms: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        name: this.smsTitle,
        temp: { text: this.newSubsec, newText: this.newHtml },
      };
      await allapi2
        .smsTemplateSmsTempInfoPost(params, body)
        .then((res) => {
          console.log(res);
          this.random_id = "T_" + this.randomString(8);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
      await this.getSms();
      await this.selectValue(this.todos[0]);
    },

    updateSmsScriptTemp: async function () {
      await this.findAllURl();
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      console.log("?????");
      if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (isText.test(this.smsTitle)) {
        this.$refs.titleInput.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "名稱不能含有是有特殊字元";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;

        if(this.noCbox!=true){
          await this.patchScriptCbox();
        }else{
          await this.postScriptCbox();
          }
        this.editinput = false;
        this.editList = true;

        this.loading = false;
        this.smsModal.hide();
      }
    },
    getShortUrl: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");

      let temp_id = null;

      if (this.selectedID != "" && this.editScriptSms === false) {
        temp_id = this.selectedID;
      } else if (this.editScriptSms) {
        temp_id = this.vice_id;
      } else if (this.selectedID === "" && this.editScriptSms === false) {
        temp_id = this.random_id;
      }

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        template_id: temp_id+this.version,
      };
      await allapi2
        .smsScheduleShortenUrlPost(params, body)
        .then((res) => {
          console.log("GETUrl", res);

          this.newHtml = this.newHtml.replace(
            fullurl,
            res.data.url + "?i={id}"
          );

          console.log("this.newText", this.newText);
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.urlok = false;
          this.$refs.textInput.focus();
          this.textError = true;
          this.textErrMsg = "短網址生成失敗。";
          this.loading = false;
          console.log(this.shortUrlArr);
        });
    },
    getAllShortHttp2: async function () {
      this.urlok = true;
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrl(this.fullurlArr[index]);
      }
    },
    findAllURl: async function () {
      this.newHtml = this.newSubsec;
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newSubsec);
      console.log(this.fullurlArr);
      if (this.fullurlArr != null) {
        await this.getAllShortHttp2();
      } else {
        this.textError = false;
        this.urlok = true;
      }
    },
    expshow: async function (boolean) {
      if (boolean) {
        await this.getScriptCboxBykey(this.key);
        if (this.noCbox!=true) {
          console.log("Cbox");
        } else {
          await this.getTemplateByScriptkey(this.key);
        }
        this.newHtml = this.newHtml.replace(/{id}/g, "uid000");
        this.resSubsec = this.newHtml
          .replace(/{lastname}/g, "王")
          .replace(/{firstname}/g, "◯◯")
          .replace(/{name}/g, "王◯◯");
        this.highlightUrl();
      } else {
        await this.findAllURl();
        this.newHtml = this.newHtml.replace(/{id}/g, "uid000");
        this.resSubsec = this.newHtml
          .replace(/{lastname}/g, "王")
          .replace(/{firstname}/g, "◯◯")
          .replace(/{name}/g, "王◯◯");
        this.highlightUrl();
      }
      this.countText = this.newHtml
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      console.log("this.countText", this.countText);
      const exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    highlightUrl() {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.newHtml);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index].slice(0, -9) +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
    },

    selectValue(todo) {
      console.log(todo);
      this.childtodo = todo;
      this.selectedID = todo.key;
    },
    // 拿所有Email A箱子
    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .templatev2TempInfoGet(params)
        .then((res) => {
          console.log("GET 拿所有Email A箱子", res);
          this.email_todos = res.data;
          this.email_todos.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });

          this.searchTodos = this.todos;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    // 拿所有SMS A箱子
    getSms: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .smsTemplateSmsTempInfoGet(params)
        .then((res) => {
          console.log("GET 拿所有SMS A箱子", res);
          this.loading = false;
          this.sms_todos = res.data;
          this.sms_todos.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });

          this.searchTodos = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    // 拿所有email排程資料
    getSchedules: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .scheduleGet(params)
        .then((res) => {
          console.log("此排程資料", res);
          this.email_schedules = res.data.Data;
          this.email_schedules.map((d) => {
            if (d.script_id === this.eventScriptIdFromSche) {
              this.scriptDateStart = new Date(
                moment(d.ScheduleExpression).add(-8, "h")
              ).toLocaleString("zh-tw");
            }
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("getSchedule連線有誤" + err);
          this.loading = false;
        });
    },
    // 拿所有sms排程資料
    getSmsSchedules: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .smsScheduleGet(params)
        .then((res) => {
          console.log("此排程資料", res);
          this.sms_schedules = res.data.Data;
          this.sms_schedules.map((d) => {
            if (d.script_id === this.eventScriptIdFromSche) {
              this.scriptDateStart = new Date(
                moment(d.ScheduleExpression).add(-8, "h")
              ).toLocaleString("zh-tw");
            }
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("getSchedule連線有誤" + err);
          this.loading = false;
        });
    },
    postScriptTemplate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.vice_id,
        temp: {
          json: this.todojson,
          newHtml: this.newHtml,
        },
      };
      await allapi2
        .scriptTempTempPost(params, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    postScriptTemplate2: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.vice_id,
        temp: {
          json: this.newSubsec,
          newHtml: this.newHtml,
        },
      };
      await allapi2
        .scriptTempTempPost(params, body)
        .then((res) => {
          console.log(res);
          this.newTodoTitle = this.smsTitle;
          this.confirmUpdateTemplateTab();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    postScriptSchedule: async function (key) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: key,
        schedule: this.newHtml,
      };
      await allapi2
        .scriptTempSchedulePost(params, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    getTemplateByScriptkey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: key,
      };

      await allapi2
        .scriptTempTempIdGet(params)
        .then((res) => {
          console.log(res);
          if (this.emailChannel === false) {
            this.newSubsec = res.data.data.json;
          }
          this.newHtml = res.data.data.newHtml;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getScriptCboxBykey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: key+this.random_Cbox_key,
      };

      await allapi2
        .scriptTempCacheKeyGet(params)
        .then((res) => {
          console.log("拿劇本Cbox模板ID的資料", res);
        if (res.data.html === 'not found') {
            this.noCbox=true
          }else{
            this.noCbox=false
          }
          if (this.emailChannel === false) {
            this.newSubsec = res.data.json;
          }
          this.todojson = res.data.json;
          this.newHtml = res.data.html;
           console.log(
            `現在getScriptCboxBykey是${this.key} this.noCbox是${res.data.html} `
          );
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    postScriptCbox: async function (design) {
      console.log(design);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key+this.random_Cbox_key,
        json: this.newSubsec,
        html: this.newHtml,
        node: new Date().getTime() + "abc",
      };
      await allapi2
        .scriptTempCachePost(params, body)
        .then((res) => {
          console.log("只在更改SMS時 根據劇本B箱子建C箱子", res);
          this.newTodoTitle = this.smsTitle;
          this.confirmUpdateTemplateTab();
          this.editList = true;
        })
        .catch((err) => {
          this.patchScriptCbox();
          console.log("連線有誤", err);
          this.loading = false;
        });
    },
    patchScriptCbox: async function (design) {
      console.log(design);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key+this.random_Cbox_key,
        json: this.newSubsec,
        html: this.newHtml,
        node: new Date().getTime() + "abc",
      };
      await allapi2
        .scriptTempCachePatch(params, body)
        .then((res) => {
          console.log("只在更改SMS時 根據劇本C箱子改C箱子", res);
          this.newTodoTitle = this.smsTitle;
          this.confirmUpdateTemplateTab();
          this.editList = true;
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.loading = false;
        });
    },
    deleteScriptCbox: async function (design) {
      console.log(design);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key+this.random_Cbox_key,
      };
      await allapi2
        .scriptTempCacheDelete(params, body)
        .then((res) => {
          console.log("刪除C箱子", res);
          this.editList = true;
        })
        .catch((err) => {
          this.patchScriptCbox();
          console.log("連線有誤", err);
          this.loading = false;
        });
    },

    // 以下FOR 拖曳功能和樹狀圖

    allowDrop(e) {
      e.preventDefault();
    },
    //开始拖动
    dragStart(e) {
      let tar = e.target.innerText;
      console.log(e);
      console.log(tar);
      e.dataTransfer.setData("Text", tar);
    },
    //放置
    dropEvent(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      console.log(e);
      if (
        dragText != "開啟信件" &&
        dragText != "點擊連結" &&
        dragText != "轉換"
      ) {
        this.showToast();
        if (this.marketingChannel == "sms") {
          this.errorDrop = "點擊連結或轉換";
        } else {
          this.errorDrop = "開啟信件或點擊連結或轉換";
        }
        return;
      }
      if (this.marketingChannel == "sms") {
        if (dragText === "開啟信件") {
          this.showToast();
          this.errorDrop = "點擊連結或轉換";
          return;
        }
      }
      if (
        dragText === "開啟信件" ||
        dragText === "點擊連結" ||
        dragText === "轉換"
      ) {
        if (this.currentEvent != null) {
          this.testList2.map((d) => {
            if (d.vice_ruleID.length === 12) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEvent = dragText;
        this.time_interval = 1;
        this.time_state = "天內";
        this.updateEventTimeInterval();
      }
      console.log(this.testList2);
      console.log(e);
    },

    // 第1層事件
    dropTimeAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
        return;
      }
      const have_1 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID === "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1"
        );
      });
      if (have_1 === -1) {
        if (dragText === "時間後") {
          this.yes_time_after = 7;
          let timeinterval = 1;
          if (this.time_state === "天內") {
            timeinterval = this.time_interval * 24;
          } else {
            timeinterval = this.time_interval;
          }

          this.testList2.push({
            event: this.currentEvent,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: this.eventScheduleIdFromSche,
            vice_ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
          });
          this.updateYesTime();
        }
      } else {
        if (dragText === "時間後") {
          this.yes_time_after = 7;
          this.testList2[have_1].time_after = 168;
          this.updateYesTime();
        }
      }
      this.yes_triggerCron = {
        picked: "一次性",
      };
      this.ader_yes_triggerCron = "";
      this.cycleTime_yes = "一次性";
      console.log("have_1", have_1);
      console.log(this.testList2);
    },
    dropTimeAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
        return;
      }
      const have_2 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID === "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2"
        );
      });
      if (have_2 === -1) {
        if (dragText === "時間後") {
          this.no_time_after = 7;
          let timeinterval = 1;
          if (this.time_state === "天內") {
            timeinterval = this.time_interval * 24;
          } else {
            timeinterval = this.time_interval;
          }

          this.testList2.push({
            event: this.currentEvent,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: this.eventScheduleIdFromSche,
            vice_ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
          });
          this.updateNoTime();
        }
      } else {
        if (dragText === "時間後") {
          this.no_time_after = 7;
          this.testList2[have_2].time_after = 168;
          this.updateNoTime();
        }
      }
      this.yes_triggerCron = {
        picked: "一次性",
      };
      this.ader_yes_triggerCron = "";
      this.cycleTime_yes = "一次性";
      console.log(this.testList2);
    },
    dropActionAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "時間後") {
        this.dropTimeAtYes(e);
        return;
      }
      if (
        dragText != "傳送Email" &&
        dragText != "傳送SMS" &&
        dragText != "時間後"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email或傳送SMS";
        return;
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 1;
        });
        if (this.yes_action != dragText) {
          this.updateWho = "yestemplate";
          this.deleteTemplateTag();
        }
        this.yes_action = dragText;
        this.testList2[yesindex].action = dragText;
        this.updateYesAction();
      }
      if (dragText == "傳送Email") {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
      console.log(this.testList2);
    },
    dropActionAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "時間後") {
        this.dropTimeAtNo(e);
        return;
      }
      if (
        dragText != "傳送Email" &&
        dragText != "傳送SMS" &&
        dragText != "時間後"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email或傳送SMS";
        return;
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 0;
        });
        if (this.no_action != dragText) {
          this.updateWho = "notemplate";
          this.deleteTemplateTag();
        }
        this.no_action = dragText;
        this.testList2[noindex].action = dragText;
        this.updateNoAction();
      }
      if (dragText == "傳送Email") {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
      console.log(this.testList2);
    },
    dropTemplateAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtYes(e);
        return;
      }

      const wasEmail = this.testList2.findIndex(
        (d) =>
          d.vice_ruleID.length === 12 &&
          d.is_yes == 1 &&
          d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }

      if (this.yes_template != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }
      if (yes) {
        if (wasEmail != -1) {
          this.email_todos.map(async (d) => {
            if (d.name === dragText) {
              const yesindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.length === 12 && d.is_yes === 1;
              });
              // const template_id = d.key;
              // this.testList2[yesindex].templateA_id = template_id;
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1";

              this.yes_template = dragText + " copy";
              this.testList2[yesindex].template_name = dragText + " copy";
              this.yes_subject = "";
              this.testList2[yesindex].subject = "";

              await this.getTemplateById(d);

              this.postScriptTemplate();

              this.updateYesTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        } else {
          this.sms_todos.map(async (d) => {
            if (d.name === dragText) {
              const yesindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.length === 12 && d.is_yes === 1;
              });
              // const template_id = d.key;
              // this.testList2[yesindex].templateA_id = template_id;
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1";

              this.yes_template = dragText + " copy";
              this.testList2[yesindex].template_name = dragText + " copy";
              this.yes_subject = "";
              this.testList2[yesindex].subject = "";
              this.editScriptSms = true;
              await this.getSmsTemplateById(d);
              await this.findAllURl();

              this.postScriptTemplate();

              this.updateYesTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        }

        console.log(this.testList2);
      } else {
        console.log("你按了取消按鈕");
      }
    },
    dropTemplateAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtNo(e);
        return;
      }
      const wasEmail = this.testList2.findIndex(
        (d) =>
          d.vice_ruleID.length === 12 &&
          d.is_yes == 0 &&
          d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }
      if (this.no_template != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        if (wasEmail != -1) {
          this.email_todos.map(async (d) => {
            if (d.name === dragText) {
              const noindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.length === 12 && d.is_yes === 0;
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
              // const template_id = d.key;
              // this.testList2[noindex].template_id = template_id;
              this.no_template = dragText + " copy";
              this.testList2[noindex].template_name = dragText + " copy";
              this.no_subject = "";
              this.testList2[noindex].subject = "";
              await this.getTemplateById(d);

              this.postScriptTemplate();

              this.updateNoTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        } else {
          this.sms_todos.map(async (d) => {
            if (d.name === dragText) {
              const noindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.length === 12 && d.is_yes === 0;
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
              // const template_id = d.key;
              // this.testList2[noindex].template_id = template_id;
              this.no_template = dragText + " copy";
              this.testList2[noindex].template_name = dragText + " copy";
              this.no_subject = "";
              this.testList2[noindex].subject = "";

              this.editScriptSms = true;
              await this.getSmsTemplateById(d);
              await this.findAllURl();
              this.postScriptTemplate();

              this.updateNoTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        }

        console.log(this.testList2);
      } else {
        console.log("你按了取消按鈕");
      }
    },

    // 第2層事件
    dropEventAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.todos.map((d) => {
        if (d.name === dragText) {
          this.dropTemplateAtYes(e);
        }
      });
      const wasEmail = this.testList2.findIndex(
        (d) =>
          d.vice_ruleID.length === 12 &&
          d.is_yes == 1 &&
          d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false && this.tabState === "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true && this.tabState === "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }
      if (
        dragText != "開啟信件" &&
        dragText != "點擊連結" &&
        dragText != "轉換" &&
        this.tabState != "template"
      ) {
        this.showToast();
        if (wasEmail == -1) {
          this.errorDrop = "點擊連結或轉換";
        } else {
          this.errorDrop = "開啟信件或點擊連結或轉換";
        }
        return;
      }

      if (wasEmail == -1) {
        if (dragText == "開啟信件") {
          this.showToast();
          this.errorDrop = "點擊連結或轉換";
          return;
        }
      }

      if (
        dragText === "開啟信件" ||
        dragText === "點擊連結" ||
        dragText === "轉換"
      ) {
        if (this.currentEventYes != null) {
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_1_1")) {
              d.event = dragText;
              d.time_interval = 24;
            }
            if (d.vice_ruleID.includes("_1_2")) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEventYes = dragText;
        this.yes_time_interval = 1;
        this.yes_time_state = "天內";
        this.fat = true;
        this.updateYesEventTimeInterval();
      }
    },
    dropEventAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.todos.map((d) => {
        if (d.name === dragText) {
          this.dropTemplateAtNo(e);
        }
      });
      const wasEmail = this.testList2.findIndex(
        (d) =>
          d.vice_ruleID.length === 12 &&
          d.is_yes == 0 &&
          d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false && this.tabState === "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true && this.tabState === "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }
      if (
        dragText != "開啟信件" &&
        dragText != "點擊連結" &&
        dragText != "轉換" &&
        this.tabState != "template"
      ) {
        this.showToast();
        if (wasEmail == -1) {
          this.errorDrop = "點擊連結或轉換";
        } else {
          this.errorDrop = "開啟信件或點擊連結或轉換";
        }
        return;
      }

      if (wasEmail == -1) {
        if (dragText == "開啟信件") {
          this.showToast();
          this.errorDrop = "點擊連結或轉換";
          return;
        }
      }

      if (
        dragText === "開啟信件" ||
        dragText === "點擊連結" ||
        dragText === "轉換"
      ) {
        if (this.currentEventNo != null) {
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_2_1")) {
              d.event = dragText;
              d.time_interval = 24;
            }
            if (d.vice_ruleID.includes("_2_2")) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEventNo = dragText;
        this.no_time_interval = 1;
        this.no_time_state = "天內";
        this.fat = true;
        this.updateNoEventTimeInterval();
      }
    },

    // 第2層事件YES
    dropTimeAtYesYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
        return;
      }
      const have_1_1 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1"
        );
      });
      if (have_1_1 === -1) {
        if (dragText === "時間後") {
          this.yesyes_time_after = 7;
          let timeinterval = 1;
          if (this.yes_time_state === "天內") {
            timeinterval = this.yes_time_interval * 24;
          } else {
            timeinterval = this.yes_time_interval;
          }
          this.testList2.push({
            event: this.currentEventYes,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            vice_ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1",
          });
          this.updateYesYesTime();
        }
      } else {
        if (dragText === "時間後") {
          this.yesyes_time_after = 7;
          this.testList2[have_1_1].time_after = 168;
          this.updateYesYesTime();
        }
      }
      this.no_triggerCron = {
        picked: "一次性",
      };
      this.ader_no_triggerCron = "";
      this.cycleTime_no = "一次性";
      console.log(this.testList2);
    },
    dropTimeAtYesNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
        return;
      }
      const have_1_2 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2"
        );
      });
      if (have_1_2 === -1) {
        if (dragText === "時間後") {
          this.yesno_time_after = 7;
          let timeinterval = 1;
          if (this.yes_time_state === "天內") {
            timeinterval = this.yes_time_interval * 24;
          } else {
            timeinterval = this.yes_time_interval;
          }
          this.testList2.push({
            event: this.currentEventYes,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            vice_ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2",
          });
          this.updateYesNoTime();
        }
      } else {
        if (dragText === "時間後") {
          this.yesno_time_after = 7;
          this.testList2[have_1_2].time_after = 168;
          this.updateYesNoTime();
        }
      }
      this.yesno_triggerCron = {
        picked: "一次性",
      };
      this.ader_yesno_triggerCron = "";
      this.cycleTime_yesno = "一次性";
      console.log(this.testList2);
    },
    dropActionAtYesYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "時間後") {
        this.dropTimeAtYesYes(e);
        return;
      }
      if (
        dragText != "傳送Email" &&
        dragText != "傳送SMS" &&
        dragText != "時間後"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email或傳送SMS";
        return;
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (this.yesyes_action != dragText) {
          this.updateWho = "yesyestemplate";
          this.deleteTemplateTag();
        }
        this.yesyes_action = dragText;
        this.testList2[yesyesindex].action = dragText;
        this.updateYesYesAction();
      }
      if (dragText == "傳送Email") {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
      console.log(this.testList2);
    },
    dropActionAtYesNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      if (dragText === "時間後") {
        this.dropTimeAtYesNo(e);
        return;
      }
      if (
        dragText != "傳送Email" &&
        dragText != "傳送SMS" &&
        dragText != "時間後"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email或傳送SMS";
        return;
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (this.yesno_action != dragText) {
          this.updateWho = "yesnotemplate";
          this.deleteTemplateTag();
        }
        this.yesno_action = dragText;
        this.testList2[yesnoindex].action = dragText;
        this.updateYesNoAction();
      }
      if (dragText == "傳送Email") {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
    },
    dropTemplateAtYesYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtYesYes(e);
        return;
      }
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_1_1") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }
      if (this.yesyes_template != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        if (wasEmail != -1) {
          this.email_todos.map(async (d) => {
            if (d.name === dragText) {
              const yesyesindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_1_1");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
              // const template_id = d.key;
              // this.testList2[yesyesindex].key = template_id;
              this.yesyes_template = dragText + " copy";
              this.testList2[yesyesindex].template_name = dragText + " copy";
              this.yesyes_subject = "";
              this.testList2[yesyesindex].subject = "";
              await this.getTemplateById(d);

              this.postScriptTemplate();

              this.updateYesYesTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        } else {
          this.sms_todos.map(async (d) => {
            if (d.name === dragText) {
              const yesyesindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_1_1");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
              // const template_id = d.key;
              // this.testList2[yesyesindex].key = template_id;
              this.yesyes_template = dragText + " copy";
              this.testList2[yesyesindex].template_name = dragText + " copy";
              this.yesyes_subject = "";
              this.testList2[yesyesindex].subject = "";

              this.editScriptSms = true;
              await this.getSmsTemplateById(d);
              await this.findAllURl();
              this.postScriptTemplate();

              this.updateYesYesTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        }

        console.log(this.testList2);
      } else {
        console.log("你按了取消按鈕");
      }
    },
    dropTemplateAtYesNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtYesNo(e);
        return;
      }
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_1_2") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }
      if (this.yesno_template != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        if (wasEmail != -1) {
          this.email_todos.map(async (d) => {
            if (d.name === dragText) {
              const yesnoindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_1_2");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
              // const template_id = d.key;
              // this.testList2[yesnoindex].template_id = template_id;
              this.yesno_template = dragText + " copy";
              this.testList2[yesnoindex].template_name = dragText + " copy";
              this.yesno_subject = "";
              this.testList2[yesnoindex].subject = "";
              await this.getTemplateById(d);

              this.postScriptTemplate();

              this.updateYesNoTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        } else {
          this.sms_todos.map(async (d) => {
            if (d.name === dragText) {
              const yesnoindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_1_2");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
              // const template_id = d.key;
              // this.testList2[yesnoindex].template_id = template_id;
              this.yesno_template = dragText + " copy";
              this.testList2[yesnoindex].template_name = dragText + " copy";
              this.yesno_subject = "";
              this.testList2[yesnoindex].subject = "";

              this.editScriptSms = true;
              await this.getSmsTemplateById(d);
              await this.findAllURl();
              this.postScriptTemplate();

              this.updateYesNoTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        }

        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtYesNo(e);
        }
        console.log(this.testList2);
        console.log("yeyeyye");
      } else {
        console.log("你按了取消按鈕");
      }
    },
    // 第2層事件No
    dropTimeAtNoYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
        return;
      }
      const have_2_1 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1"
        );
      });
      if (have_2_1 === -1) {
        if (dragText === "時間後") {
          this.noyes_time_after = 7;
          let timeinterval = 1;
          if (this.no_time_state === "天內") {
            timeinterval = this.no_time_interval * 24;
          } else {
            timeinterval = this.no_time_interval;
          }
          this.testList2.push({
            event: this.currentEventNo,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            vice_ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1",
          });
          this.updateNoYesTime();
        }
      } else {
        if (dragText === "時間後") {
          this.noyes_time_after = 7;
          this.testList2[have_2_1].time_after = 168;
          this.updateNoYesTime();
        }
      }
      this.noyes_triggerCron = {
        picked: "一次性",
      };
      this.ader_noyes_triggerCron = "";
      this.cycleTime_noyes = "一次性";
      console.log(this.testList2);
    },
    dropTimeAtNoNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
        return;
      }
      const have_2_2 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2"
        );
      });
      if (have_2_2 === -1) {
        if (dragText === "時間後") {
          this.nono_time_after = 7;
          let timeinterval = 1;
          if (this.no_time_state === "天內") {
            timeinterval = this.no_time_interval * 24;
          } else {
            timeinterval = this.no_time_interval;
          }
          this.testList2.push({
            event: this.currentEventNo,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            vice_ruleID: "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2",
          });
          this.updateNoNoTime();
        }
      } else {
        if (dragText === "時間後") {
          this.nono_time_after = 7;
          this.testList2[have_2_2].time_after = 168;
          this.updateNoNoTime();
        }
      }
      this.nono_triggerCron = {
        picked: "一次性",
      };
      this.ader_nono_triggerCron = "";
      this.cycleTime_nono = "一次性";
      console.log(this.testList2);
    },
    dropActionAtNoYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "時間後") {
        this.dropTimeAtNoYes(e);
        return;
      }
      if (
        dragText != "傳送Email" &&
        dragText != "傳送SMS" &&
        dragText != "時間後"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email或傳送SMS";
        return;
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (this.noyes_action != dragText) {
          this.updateWho = "noyestemplate";
          this.deleteTemplateTag();
        }
        this.noyes_action = dragText;
        this.testList2[noyesindex].action = dragText;
        this.updateNoYesAction();
      }
      if (dragText == "傳送Email") {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
    },
    dropActionAtNoNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "時間後") {
        this.dropTimeAtNoYes(e);
        return;
      }
      if (
        dragText != "傳送Email" &&
        dragText != "傳送SMS" &&
        dragText != "時間後"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email或傳送SMS";
        return;
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (this.nono_action != dragText) {
          this.updateWho = "nonotemplate";
          this.deleteTemplateTag();
        }
        this.nono_action = dragText;
        this.testList2[noyesindex].action = dragText;
        this.updateNoNoAction();
      }
      if (dragText == "傳送Email") {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
    },
    dropTemplateAtNoYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtNoYes(e);
        return;
      }
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_2_1") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }
      if (this.noyes_template != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        if (wasEmail != -1) {
          this.email_todos.map(async (d) => {
            if (d.name === dragText) {
              const noyesindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_2_1");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
              // const template_id = d.template_id;
              // this.testList2[noyesindex].template_id = template_id;
              this.noyes_template = dragText + " copy";
              this.testList2[noyesindex].template_name = dragText + " copy";
              this.noyes_subject = "";
              this.testList2[noyesindex].subject = "";

              await this.getTemplateById(d);

              this.postScriptTemplate();

              this.updateNoYesTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        } else {
          this.sms_todos.map(async (d) => {
            if (d.name === dragText) {
              const noyesindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_2_1");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
              // const template_id = d.template_id;
              // this.testList2[noyesindex].template_id = template_id;
              this.noyes_template = dragText + " copy";
              this.testList2[noyesindex].template_name = dragText + " copy";
              this.noyes_subject = "";
              this.testList2[noyesindex].subject = "";
              this.editScriptSms = true;
              await this.getSmsTemplateById(d);
              await this.findAllURl();
              this.postScriptTemplate();

              this.updateNoYesTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        }

        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtNoYes(e);
        }
        console.log(this.testList2);
      } else {
        console.log("你按了取消按鈕");
      }
    },
    dropTemplateAtNoNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtNoNo(e);
        return;
      }
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_2_2") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        if (this.emailChannel === false || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "Email模板";
          return;
        }
      } else {
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
          return;
        }
      }

      if (this.nono_template != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        if (wasEmail != -1) {
          this.email_todos.map(async (d) => {
            if (d.name === dragText) {
              const nonoindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_2_2");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
              // const template_id = d.template_id;
              // this.testList2[nonoindex].template_id = template_id;
              this.nono_template = dragText + " copy";
              this.testList2[nonoindex].template_name = dragText + " copy";
              this.nono_subject = "";
              this.testList2[nonoindex].subject = "";
              await this.getTemplateById(d);

              this.postScriptTemplate();
              this.updateNoNoTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        } else {
          this.sms_todos.map(async (d) => {
            if (d.name === dragText) {
              const nonoindex = this.testList2.findIndex((d) => {
                return d.vice_ruleID.includes("_2_2");
              });
              this.vice_id =
                "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
              // const template_id = d.template_id;
              // this.testList2[nonoindex].template_id = template_id;
              this.nono_template = dragText + " copy";
              this.testList2[nonoindex].template_name = dragText + " copy";
              this.nono_subject = "";
              this.testList2[nonoindex].subject = "";

              this.editScriptSms = true;
              await this.getSmsTemplateById(d);
              await this.findAllURl();

              this.postScriptTemplate();
              this.updateNoNoTemplate(e, dragText);
              this.deleteScriptCbox();
            }
          });
        }

        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtNoNo(e);
        }
        console.log(this.testList2);
      } else {
        console.log("你按了取消按鈕");
      }
    },

    // offcanvas update
    updateEventTimeInterval() {
      this.tabState = "event";
      if (
        this.currentEvent != "開啟信件" &&
        this.currentEvent != "點擊連結" &&
        this.currentEvent != "轉換"
      ) {
        if (this.currentEvent.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption_Selected = this.currentEvent.slice(-3);
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEvent;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.time_interval;
      this.time_state_Selected = this.time_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "event";
    },
    updateYesEventTimeInterval() {
      this.tabState = "event";
      if (
        this.currentEventYes != "開啟信件" &&
        this.currentEventYes != "點擊連結" &&
        this.currentEventYes != "轉換"
      ) {
        if (this.currentEventYes.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption_Selected = this.currentEventYes.slice(-3);
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEventYes;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.yes_time_interval;
      this.time_state_Selected = this.yes_time_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesevent";
    },
    updateNoEventTimeInterval() {
      this.tabState = "event";
      if (
        this.currentEventNo != "開啟信件" &&
        this.currentEventNo != "點擊連結" &&
        this.currentEventNo != "轉換"
      ) {
        if (this.currentEventNo.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption_Selected = this.currentEventNo.slice(-3);
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEventNo;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.no_time_interval;
      this.time_state_Selected = this.no_time_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noevent";
    },
    deleteEventTag() {
      if (this.updateWho === "event") {
        this.currentEvent = null;
        this.currentEventYes = null;
        this.currentEventNo = null;
        this.time_interval = null;
        this.yes_time_interval = null;
        this.no_time_interval = null;
        this.time_state = null;
        this.yes_time_state = null;
        this.no_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList2 = [];

        this.yes_time_after = "";
        this.yes_action = "";
        this.yes_subject = "";
        this.yes_template = "";
        this.no_time_after = "";
        this.no_action = "";
        this.no_subject = "";
        this.no_template = "";
        this.yesyes_time_after = "";
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        this.yesno_time_after = "";
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";
        this.noyes_time_after = "";
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        this.nono_time_after = "";
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
      } else if (this.updateWho === "yesevent") {
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";

        this.yesyes_time_after = "";
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        this.yesno_time_after = "";
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";

        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
      } else if (this.updateWho === "noevent") {
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";

        this.noyes_time_after = "";
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        this.nono_time_after = "";
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateEventTab() {
      if (this.updateWho === "event") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          console.log("here?");
          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList2.map((d) => {
              if (d.vice_ruleID.length === 12) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.time_interval_Selected;
                } else {
                  d.time_interval = this.time_interval_Selected * 24;
                }
              }
            });
            console.log("here?");
            this.currentEvent =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            console.log("here?");
            this.testList2.map((d) => {
              if (d.vice_ruleID.length === 12) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.time_interval_Selected;
                } else {
                  d.time_interval = this.time_interval_Selected * 24;
                }
              }
            });
            this.currentEvent = this.eventSelected;
          }
          this.time_interval = this.time_interval_Selected;
          this.time_state = this.time_state_Selected;

          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesevent") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.yes_time_interval = this.time_interval_Selected;
          this.yes_time_state = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_1_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_1_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
            });
            this.currentEventYes =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_1_1")) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_1_2")) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
            });
            this.currentEventYes = this.eventSelected;
          }
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noevent") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.no_time_interval = this.time_interval_Selected;
          this.no_time_state = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_2_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_2_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
            });
            this.currentEventNo =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_2_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_2_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
            });
            this.currentEventNo =
              this.eventSelected + this.event_moreoption_Selected;
          }

          console.log(this.testList2);
        }
      }
      this.offCanvasClick.hide();
      this.tabState = "time";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(2) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },
    event_moreoptionChange(boolean) {
      this.event_moreoption = boolean;
      this.event_moreoption_Selected = "";
      this.eventSelected = "";
      // this.time_interval_Selected=''
      // this.time_state_Selected=''
    },
    // 更新時間
    updateYesTime() {
      this.tabState = "time";
      if (this.yes_triggerCron.happenTime != undefined) {
        this.happenTime = this.yes_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yes_triggerCron.happenDay;
      this.happenWeek_Selected = this.yes_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.yes_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.yes_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.yes_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.yes_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.yes_triggerCron.happenMonth4;
      this.happenYear_Selected = this.yes_triggerCron.happenYear;
      this.happenYear2_Selected = this.yes_triggerCron.happenYear2;
      this.happenYear3_Selected = this.yes_triggerCron.happenYear3;
      this.happenYear4_Selected = this.yes_triggerCron.happenYear4;
      this.happenYear5_Selected = this.yes_triggerCron.happenYear5;
      this.daytime = this.yes_triggerCron.daytime;
      this.endCount2 = this.yes_triggerCron.endCount;
      if (this.yes_triggerCron.picked != undefined) {
        this.picked3 = this.yes_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.yes_time_after;
      this.time_after_state_Selected = this.yes_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yestime";
    },
    updateYesYesTime() {
      this.tabState = "time";
      if (this.yesyes_triggerCron.happenTime != undefined) {
        this.happenTime = this.yesyes_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yesyes_triggerCron.happenDay;
      this.happenWeek_Selected = this.yesyes_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.yesyes_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.yesyes_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.yesyes_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.yesyes_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.yesyes_triggerCron.happenMonth4;
      this.happenYear_Selected = this.yesyes_triggerCron.happenYear;
      this.happenYear2_Selected = this.yesyes_triggerCron.happenYear2;
      this.happenYear3_Selected = this.yesyes_triggerCron.happenYear3;
      this.happenYear4_Selected = this.yesyes_triggerCron.happenYear4;
      this.happenYear5_Selected = this.yesyes_triggerCron.happenYear5;
      this.daytime = this.yesyes_triggerCron.daytime;
      this.endCount2 = this.yesyes_triggerCron.endCount;
      if (this.yesyes_triggerCron.picked != undefined) {
        this.picked3 = this.yesyes_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.yesyes_time_after;
      this.time_after_state_Selected = this.yesyes_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyestime";
    },
    updateYesNoTime() {
      this.tabState = "time";
      if (this.yesno_triggerCron.happenTime != undefined) {
        this.happenTime = this.yesno_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yesno_triggerCron.happenDay;
      this.happenWeek_Selected = this.yesno_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.yesno_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.yesno_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.yesno_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.yesno_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.yesno_triggerCron.happenMonth4;
      this.happenYear_Selected = this.yesno_triggerCron.happenYear;
      this.happenYear2_Selected = this.yesno_triggerCron.happenYear2;
      this.happenYear3_Selected = this.yesno_triggerCron.happenYear3;
      this.happenYear4_Selected = this.yesno_triggerCron.happenYear4;
      this.happenYear5_Selected = this.yesno_triggerCron.happenYear5;
      this.daytime = this.yesno_triggerCron.daytime;
      this.endCount2 = this.yesno_triggerCron.endCount;
      if (this.yesno_triggerCron.picked != undefined) {
        this.picked3 = this.yesno_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.yesno_time_after;
      this.time_after_state_Selected = this.yesno_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnotime";
    },
    updateNoTime() {
      this.tabState = "time";
      if (this.no_triggerCron.happenTime != undefined) {
        this.happenTime = this.no_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.no_triggerCron.happenDay;
      this.happenWeek_Selected = this.no_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.no_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.no_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.no_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.no_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.no_triggerCron.happenMonth4;
      this.happenYear_Selected = this.no_triggerCron.happenYear;
      this.happenYear2_Selected = this.no_triggerCron.happenYear2;
      this.happenYear3_Selected = this.no_triggerCron.happenYear3;
      this.happenYear4_Selected = this.no_triggerCron.happenYear4;
      this.happenYear5_Selected = this.no_triggerCron.happenYear5;
      this.daytime = this.no_triggerCron.daytime;
      this.endCount2 = this.no_triggerCron.endCount;
      if (this.no_triggerCron.picked != undefined) {
        this.picked3 = this.no_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.no_time_after;
      this.time_after_state_Selected = this.no_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "notime";
    },
    updateNoYesTime() {
      this.tabState = "time";
      if (this.noyes_triggerCron.happenTime != undefined) {
        this.happenTime = this.noyes_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.noyes_triggerCron.happenDay;
      this.happenWeek_Selected = this.noyes_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.noyes_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.noyes_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.noyes_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.noyes_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.noyes_triggerCron.happenMonth4;
      this.happenYear_Selected = this.noyes_triggerCron.happenYear;
      this.happenYear2_Selected = this.noyes_triggerCron.happenYear2;
      this.happenYear3_Selected = this.noyes_triggerCron.happenYear3;
      this.happenYear4_Selected = this.noyes_triggerCron.happenYear4;
      this.happenYear5_Selected = this.noyes_triggerCron.happenYear5;
      this.daytime = this.noyes_triggerCron.daytime;
      this.endCount2 = this.noyes_triggerCron.endCount;
      if (this.noyes_triggerCron.picked != undefined) {
        this.picked3 = this.noyes_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.noyes_time_after;
      this.time_after_state_Selected = this.noyes_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyestime";
    },
    updateNoNoTime() {
      this.tabState = "time";
      if (this.nono_triggerCron.happenTime != undefined) {
        this.happenTime = this.nono_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.nono_triggerCron.happenDay;
      this.happenWeek_Selected = this.nono_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.nono_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.nono_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.nono_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.nono_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.nono_triggerCron.happenMonth4;
      this.happenYear_Selected = this.nono_triggerCron.happenYear;
      this.happenYear2_Selected = this.nono_triggerCron.happenYear2;
      this.happenYear3_Selected = this.nono_triggerCron.happenYear3;
      this.happenYear4_Selected = this.nono_triggerCron.happenYear4;
      this.happenYear5_Selected = this.nono_triggerCron.happenYear5;
      this.daytime = this.nono_triggerCron.daytime;
      this.endCount2 = this.nono_triggerCron.endCount;
      if (this.nono_triggerCron.picked != undefined) {
        this.picked3 = this.nono_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.nono_time_after;
      this.time_after_state_Selected = this.nono_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonotime";
    },
    deleteTimeTag() {
      if (this.updateWho === "yestime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yes_time_after = "";
        this.yes_time_after_state = "天後";
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yes_action = "";
        this.yes_subject = "";
        this.yes_template = "";
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.yesyes_action = "";
        this.yesno_action = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.yesyes_template = "";
        this.yesno_template = "";
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 1;
        });
        this.testList2.splice(yesindex, 1);
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "notime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.no_time_after = "";
        this.no_time_after_state = "天後";
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.no_action = "";
        this.no_subject = "";
        this.no_template = "";
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.noyes_action = "";
        this.nono_action = "";
        this.noyes_subject = "";
        this.nono_subject = "";
        this.noyes_template = "";
        this.nono_template = "";
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 0;
        });
        this.testList2.splice(noindex, 1);
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "yesyestime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "yesnotime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "noyestime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "nonotime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log("testList2", this.testList2);
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateTimeTab() {
      if (this.updateWho === "yestime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.yes_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.daytime,
            endCount: this.endCount2,
            picked: this.picked3,
          };
          if (this.yes_triggerCron.daytime != undefined) {
            this.ader_yes_triggerCron = "ok";
          }

          this.cycleTime_yes = this.picked3;
          this.yes_time_after = this.time_after_Selected;
          this.yes_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.length === 12 && d.is_yes === 1) {
              if (this.yes_time_after_state === "小時後") {
                d.time_after = this.yes_time_after;
              } else {
                d.time_after = this.yes_time_after * 24;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "notime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.no_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.daytime,
            endCount: this.endCount2,
            picked: this.picked3,
          };
          if (this.no_triggerCron.daytime != undefined) {
            this.ader_no_triggerCron = "ok";
          }

          this.cycleTime_no = this.picked3;
          this.no_time_after = this.time_after_Selected;
          this.no_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.length === 12 && d.is_yes === 0) {
              if (this.no_time_after_state === "小時後") {
                d.time_after = this.no_time_after;
              } else {
                d.time_after = this.no_time_after * 24;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesyestime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.yesyes_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.daytime,
            endCount: this.endCount2,
            picked: this.picked3,
          };
          if (this.yesyes_triggerCron.daytime != undefined) {
            this.ader_yesyes_triggerCron = "ok";
          }

          this.cycleTime_yesyes = this.picked3;
          this.yesyes_time_after = this.time_after_Selected;
          this.yesyes_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_1_1")) {
              if (this.yesyes_time_after_state === "小時後") {
                d.time_after = this.yesyes_time_after;
              } else {
                d.time_after = this.yesyes_time_after * 24;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesnotime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.yesno_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.daytime,
            endCount: this.endCount2,
            picked: this.picked3,
          };
          if (this.yesno_triggerCron.daytime != undefined) {
            this.ader_yesno_triggerCron = "ok";
          }

          this.cycleTime_yesno = this.picked3;
          this.yesno_time_after = this.time_after_Selected;
          this.yesno_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_1_2")) {
              if (this.yesno_time_after_state === "小時後") {
                d.time_after = this.yesno_time_after;
              } else {
                d.time_after = this.yesno_time_after * 24;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noyestime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.noyes_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.daytime,
            endCount: this.endCount2,
            picked: this.picked3,
          };
          if (this.noyes_triggerCron.daytime != undefined) {
            this.ader_noyes_triggerCron = "ok";
          }

          this.cycleTime_noyes = this.picked3;
          this.noyes_time_after = this.time_after_Selected;
          this.noyes_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_2_1")) {
              if (this.noyes_time_after_state === "小時後") {
                d.time_after = this.noyes_time_after;
              } else {
                d.time_after = this.noyes_time_after * 24;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "nonotime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.nono_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.daytime,
            endCount: this.endCount2,
            picked: this.picked3,
          };
          if (this.nono_triggerCron.daytime != undefined) {
            this.ader_nono_triggerCron = "ok";
          }

          this.cycleTime_nono = this.picked3;
          this.nono_time_after = this.time_after_Selected;
          this.nono_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_2_2")) {
              if (this.nono_time_after_state === "小時後") {
                d.time_after = this.nono_time_after;
              } else {
                d.time_after = this.nono_time_after * 24;
              }
            }
          });
          console.log(this.testList2);
        }
      }
      this.offCanvasClick.hide();
      this.tabState = "action";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(3) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },

    updateYesAction() {
      this.tabState = "action";
      const yesindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.length === 12 && d.is_yes === 1;
      });
      this.action_Selected = this.testList2[yesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesaction";
    },
    updateYesYesAction() {
      this.tabState = "action";
      const yesyesindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_1_1");
      });
      this.action_Selected = this.testList2[yesyesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyesaction";
    },
    updateYesNoAction() {
      this.tabState = "action";
      const yesnoindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_1_2");
      });
      this.action_Selected = this.testList2[yesnoindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnoaction";
    },
    updateNoAction() {
      this.tabState = "action";
      const noindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.length === 12 && d.is_yes === 0;
      });
      this.action_Selected = this.testList2[noindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noaction";
    },
    updateNoYesAction() {
      this.tabState = "action";
      const noyesindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_2_1");
      });
      this.action_Selected = this.testList2[noyesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyesaction";
    },
    updateNoNoAction() {
      this.tabState = "action";
      const nonoindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_2_2");
      });
      this.action_Selected = this.testList2[nonoindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonoaction";
    },
    deleteActionTag() {
      if (this.updateWho === "yesaction") {
        this.yes_action = "";
        this.yes_subject = "";
        this.yes_template = "";
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yesyes_action = "";
        this.yesno_action = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.yesyes_template = "";
        this.yesno_template = "";
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 1;
        });
        this.testList2[yesindex].action = undefined;
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "noaction") {
        this.no_action = "";
        this.no_subject = "";
        this.no_template = "";
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.noyes_action = "";
        this.nono_action = "";
        this.noyes_subject = "";
        this.nono_subject = "";
        this.noyes_template = "";
        this.nono_template = "";
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 0;
        });
        this.testList2[noindex].action = undefined;
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "yesyesaction") {
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList2[yesyesindex].action = undefined;

        console.log(this.testList2);
      } else if (this.updateWho === "yesnoaction") {
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList2[yesnoindex].action = undefined;

        console.log(this.testList2);
      } else if (this.updateWho === "noyesaction") {
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList2[noyesindex].action = undefined;

        console.log(this.testList2);
      } else if (this.updateWho === "nonoaction") {
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList2[nonoindex].action = undefined;

        console.log(this.testList2);
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateActionTab() {
      if (this.updateWho === "yesaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.length === 12 && d.is_yes === 1;
          });
          this.testList2[yesindex].action = this.action_Selected;
          if (this.action_Selected != this.yes_action) {
            this.updateWho = "yestemplate";
            this.deleteTemplateTag();
          }
          if (this.action_Selected == "傳送Email") {
            this.emailChannel = true;
          } else {
            this.emailChannel = false;
          }
          this.yes_action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const noindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.length === 12 && d.is_yes === 0;
          });
          this.testList2[noindex].action = this.action_Selected;
          if (this.action_Selected != this.no_action) {
            this.updateWho = "notemplate";
            this.deleteTemplateTag();
          }
          if (this.action_Selected == "傳送Email") {
            this.emailChannel = true;
          } else {
            this.emailChannel = false;
          }
          this.no_action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesyesaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesyesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_1_1");
          });
          this.testList2[yesyesindex].action = this.action_Selected;
          if (this.action_Selected != this.yesyes_action) {
            this.updateWho = "yesyestemplate";
            this.deleteTemplateTag();
          }
          if (this.action_Selected == "傳送Email") {
            this.emailChannel = true;
          } else {
            this.emailChannel = false;
          }
          this.yesyes_action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesnoaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesnoindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_1_2");
          });
          this.testList2[yesnoindex].action = this.action_Selected;
          if (this.action_Selected != this.yesno_action) {
            this.updateWho = "yesnotemplate";
            this.deleteTemplateTag();
          }
          if (this.action_Selected == "傳送Email") {
            this.emailChannel = true;
          } else {
            this.emailChannel = false;
          }
          this.yesno_action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noyesaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const noyesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_2_1");
          });
          this.testList2[noyesindex].action = this.action_Selected;
          if (this.action_Selected != this.noyes_action) {
            this.updateWho = "noyestemplate";
            this.deleteTemplateTag();
          }
          if (this.action_Selected == "傳送Email") {
            this.emailChannel = true;
          } else {
            this.emailChannel = false;
          }
          this.noyes_action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "nonoaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const nonoindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_2_2");
          });
          this.testList2[nonoindex].action = this.action_Selected;
          if (this.action_Selected != this.nono_action) {
            this.updateWho = "nonotemplate";
            this.deleteTemplateTag();
          }
          if (this.action_Selected == "傳送Email") {
            this.emailChannel = true;
          } else {
            this.emailChannel = false;
          }
          this.nono_action = this.action_Selected;
          console.log(this.testList2);
        }
      }
      this.offCanvasClick.hide();
      this.tabState = "template";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(4) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },

    updateYesTemplate(e, dragText) {
      const wasEmail = this.testList2.findIndex(
        (d) =>
          d.vice_ruleID.length === 12 &&
          d.is_yes == 1 &&
          d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
      this.tabState = "template";
      this.key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
      this.vice_id = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
      this.name = this.yes_template;
      this.newTodoTitle = this.yes_template;
      this.newSub = this.yes_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();

      this.updateWho = "yestemplate";
    },
    updateYesYesTemplate(e, dragText) {
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_1_1") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
      this.tabState = "template";
      this.key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
      this.vice_id = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
      this.name = this.yesyes_template;
      this.newTodoTitle = this.yesyes_template;
      this.newSub = this.yesyes_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyestemplate";
    },
    updateYesNoTemplate(e, dragText) {
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_1_2") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }
      this.tabState = "template";
      this.key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
      this.vice_id = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
      this.name = this.yesno_template;
      this.newTodoTitle = this.yesno_template;
      this.newSub = this.yesno_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnotemplate";
    },
    updateNoTemplate(e, dragText) {
      const wasEmail = this.testList2.findIndex(
        (d) =>
          d.vice_ruleID.length === 12 &&
          d.is_yes == 0 &&
          d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }

      this.tabState = "template";
      this.key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
      this.vice_id = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
      this.name = this.no_template;
      this.newTodoTitle = this.no_template;
      this.newSub = this.no_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "notemplate";
    },
    updateNoYesTemplate(e, dragText) {
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_2_1") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }

      this.tabState = "template";
      this.key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
      this.vice_id = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
      this.name = this.noyes_template;
      this.newTodoTitle = this.noyes_template;
      this.newSub = this.noyes_subject;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyestemplate";
    },
    updateNoNoTemplate(e, dragText) {
      const wasEmail = this.testList2.findIndex(
        (d) => d.vice_ruleID.includes("_2_2") && d.action == "傳送Email"
      );
      if (wasEmail != -1) {
        this.changeChannel(true);
      } else {
        this.changeChannel(false);
      }

      this.tabState = "template";
      this.key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
      this.vice_id = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
      this.name = this.nono_template;
      this.newTodoTitle = this.nono_template;
      this.newSub = this.nono_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonotemplate";
    },
    confirmUpdateTemplateTab() {
      if (this.newTodoTitleError) {
        alert("模板名稱有誤");
        return;
      }
      if (this.updateWho === "yestemplate") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const yesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.length === 12 && d.is_yes === 1;
          });
          this.testList2[yesindex].template_name = this.newTodoTitle;
          this.testList2[yesindex].subject = this.newSub;
          this.yes_template = this.newTodoTitle;
          this.yes_subject = this.newSub;
        }
      } else if (this.updateWho === "notemplate") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const noindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.length === 12 && d.is_yes === 0;
          });
          this.testList2[noindex].template_name = this.newTodoTitle;
          this.testList2[noindex].subject = this.newSub;
          this.no_template = this.newTodoTitle;
          this.no_subject = this.newSub;
        }
      } else if (this.updateWho === "yesyestemplate") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const yesyesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_1_1");
          });
          this.testList2[yesyesindex].template_name = this.newTodoTitle;
          this.testList2[yesyesindex].subject = this.newSub;
          this.yesyes_template = this.newTodoTitle;
          this.yesyes_subject = this.newSub;
        }
      } else if (this.updateWho === "yesnotemplate") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const yesnoindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_1_2");
          });
          this.testList2[yesnoindex].template_name = this.newTodoTitle;
          this.testList2[yesnoindex].subject = this.newSub;
          this.yesno_template = this.newTodoTitle;
          this.yesno_subject = this.newSub;
        }
      } else if (this.updateWho === "noyestemplate") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const noyesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_2_1");
          });
          this.testList2[noyesindex].template_name = this.newTodoTitle;
          this.testList2[noyesindex].subject = this.newSub;
          this.noyes_template = this.newTodoTitle;
          this.noyes_subject = this.newSub;
        }
      } else if (this.updateWho === "nonotemplate") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const nonoindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_2_2");
          });
          this.testList2[nonoindex].template_name = this.newTodoTitle;
          this.testList2[nonoindex].subject = this.newSub;
          this.nono_template = this.newTodoTitle;
          this.nono_subject = this.newSub;
        }
      }

      this.offCanvasClick.hide();
    },
    deleteTemplateTag() {
      if (this.updateWho === "yestemplate") {
        this.yes_subject = "";
        this.yes_template = "";
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yesyes_action = "";
        this.yesno_action = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.yesyes_template = "";
        this.yesno_template = "";
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 1;
        });
        this.testList2[yesindex].template_name = undefined;
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "notemplate") {
        this.no_subject = "";
        this.no_template = "";
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.noyes_action = "";
        this.nono_action = "";
        this.noyes_subject = "";
        this.nono_subject = "";
        this.noyes_template = "";
        this.nono_template = "";
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 12 && d.is_yes === 0;
        });
        this.testList2[noindex].template_name = undefined;
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "yesyestemplate") {
        this.yesyes_subject = "";
        this.yesyes_template = "";
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList2[yesyesindex].template_name = undefined;
        console.log(this.testList2);
      } else if (this.updateWho === "yesnotemplate") {
        this.yesno_subject = "";
        this.yesno_template = "";
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList2[yesnoindex].template_name = undefined;
        console.log(this.testList2);
      } else if (this.updateWho === "noyestemplate") {
        this.noyes_subject = "";
        this.noyes_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList2[noyesindex].template_name = undefined;
        console.log(this.testList2);
      } else if (this.updateWho === "nonotemplate") {
        this.nono_subject = "";
        this.nono_template = "";
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList2[nonoindex].template_name = undefined;
        console.log(this.testList2);
      }
      // this.offCanvasClick = new bootstrap.Offcanvas(
      //   document.getElementById("offcanvasRight"),
      //   {
      //     keyboard: false,
      //   }
      // );
      this.offCanvasClick.hide();
    },

    saveScript: async function (work) {
      console.log("work", work);

      console.log("save 前", this.testList2);
      this.testList2.forEach((o) => {
        if (o.action === "傳送Email") {
          o.action = "寄信";
        } else if (o.action === "傳送SMS") {
          o.action = "寄簡訊";
        }
      });
      if (sessionStorage.getItem("username") == "franktest") {
        this.testList2 = this.testList2.map((o) => {
          if (o.template_name != undefined) {
            return {
              action: o.action,
              event: o.event,
              is_yes: o.is_yes,
              ruleID: o.ruleID,
              subject: o.subject,
              template: o.template_name,
              time_after: 0.05,
              time_interval: 0.05,
              vice_ruleID: o.vice_ruleID,
            };
          } else {
            return {
              action: o.action,
              event: o.event,
              is_yes: o.is_yes,
              ruleID: o.ruleID,
              subject: o.subject,
              template: o.template,
              time_after: 0.05,
              time_interval: 0.05,
              vice_ruleID: o.vice_ruleID,
            };
          }
        });
      } else {
        this.testList2 = this.testList2.map((o) => {
          if (o.template_name != undefined) {
            return {
              action: o.action,
              event: o.event,
              is_yes: o.is_yes,
              ruleID: o.ruleID,
              subject: o.subject,
              template: o.template_name,
              time_after: o.time_after,
              time_interval: o.time_interval,
              vice_ruleID: o.vice_ruleID,
            };
          } else {
            return {
              action: o.action,
              event: o.event,
              is_yes: o.is_yes,
              ruleID: o.ruleID,
              subject: o.subject,
              template: o.template,
              time_after: o.time_after,
              time_interval: o.time_interval,
              vice_ruleID: o.vice_ruleID,
            };
          }
        });
      }

      this.testList2.forEach((d) => {
        if (d.action == "寄簡訊") {
          d.subject = "";
        }
      });
      console.log(this.testList2);
      const ok = this.testList2.map((d) => {
        const arrvalues = Object.values(d);
        var ok2Event = true;
        if (this.currentEventYes != null || this.currentEventNo != null) {
          ok2Event = arrvalues.some((d) => {
            return (
              String(d).includes("_1_1") ||
              String(d).includes("_1_2") ||
              String(d).includes("_2_1") ||
              String(d).includes("_2_2")
            );
          });
        }
        console.log("ok2Event", ok2Event);
        return ok2Event;
      });
      console.log("ok", ok);
      const okfor2event = ok.some((d) => d === true);
      console.log("okfor2event", okfor2event);

      const okok = this.testList2.map((d) => {
        const arrkey = Object.keys(d);
        const arrvalues = Object.values(d);
        return (
          arrkey.includes("action") &&
          arrkey.includes("template") &&
          arrkey.includes("subject") &&
          !arrvalues.includes(undefined)
        );
      });
      console.log("okok", okok);
      const okforkey_value = okok.every((d) => d === true);
      console.log("okforkey_value", okforkey_value);

      if (okfor2event === false || okforkey_value === false) {
        console.log(this.testList2);
        alert("樹狀圖不完整");
      } else if (this.eventScriptIdFromSche === "") {
        alert("沒有選擇排程活動");
      } else if (
        (this.yes_template != "" &&
          this.yes_subject === "" &&
          this.yes_action === "寄信" &&
          (work === "2" || this.eventScriptHasEventFromSche === "2") &&
          work != "1") ||
        (this.no_template != "" &&
          this.no_subject === "" &&
          this.no_action === "寄信" &&
          (work === "2" || this.eventScriptHasEventFromSche === "2") &&
          work != "1") ||
        (this.yesyes_template != "" &&
          this.yesyes_subject === "" &&
          this.yesyes_action === "寄信" &&
          (work === "2" || this.eventScriptHasEventFromSche === "2") &&
          work != "1") ||
        (this.yesno_template != "" &&
          this.yesno_subject === "" &&
          this.yesno_action === "寄信" &&
          (work === "2" || this.eventScriptHasEventFromSche === "2") &&
          work != "1") ||
        (this.noyes_template != "" &&
          this.noyes_subject === "" &&
          this.noyes_action === "寄信" &&
          (work === "2" || this.eventScriptHasEventFromSche === "2") &&
          work != "1") ||
        (this.nono_template != "" &&
          this.nono_subject === "" &&
          this.nono_action === "寄信" &&
          (work === "2" || this.eventScriptHasEventFromSche === "2") &&
          work != "1")
      ) {
        console.log("no_template", this.no_template);
        console.log("yes_subject", this.yes_subject);
        alert("有缺主旨");
      } else {
        console.log("儲存劇本");
        this.loading = true;
        if (this.yes_template != "") {
          const key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
          this.vice_id = key;
          await this.getScriptCboxBykey(key);
          if (this.noCbox!=true) {
            console.log("用CboxHtml");
            await this.postScriptTemplate();
          } else {
            await this.getTemplateByScriptkey(key);
          }
          await this.postScriptSchedule(key);
        }
        if (this.no_template != "") {
          const key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
          this.vice_id = key;
          await this.getScriptCboxBykey(key);
          if (this.noCbox!=true) {
            console.log("用CboxHtml");
            await this.postScriptTemplate();
          } else {
            await this.getTemplateByScriptkey(key);
          }
          await this.postScriptSchedule(key);
        }
        if (this.yesyes_template != "") {
          const key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
          this.vice_id = key;
          await this.getScriptCboxBykey(key);
          if (this.noCbox!=true) {
            console.log("用CboxHtml");
            await this.postScriptTemplate();
          } else {
            await this.getTemplateByScriptkey(key);
          }
          await this.postScriptSchedule(key);
        }
        if (this.yesno_template != "") {
          const key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
          this.vice_id = key;
          await this.getScriptCboxBykey(key);
          if (this.noCbox!=true) {
            console.log("用CboxHtml");
            await this.postScriptTemplate();
          } else {
            await this.getTemplateByScriptkey(key);
          }
          await this.postScriptSchedule(key);
        }
        if (this.noyes_template != "") {
          const key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
          this.vice_id = key;
          await this.getScriptCboxBykey(key);
          if (this.noCbox!=true) {
            console.log("用CboxHtml");
            await this.postScriptTemplate();
          } else {
            await this.getTemplateByScriptkey(key);
          }
          await this.postScriptSchedule(key);
        }
        if (this.nono_template != "") {
          const key = "v_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
          this.vice_id = key;
          await this.getScriptCboxBykey(key);
          if (this.noCbox!=true) {
            console.log("用CboxHtml");
            await this.postScriptTemplate();
          } else {
            await this.getTemplateByScriptkey(key);
          }
          await this.postScriptSchedule(key);
        }

        this.runSaveScriptApi(work);
      }
    },
    openStopScriptModel() {
      const alertModal7 = new bootstrap.Modal(
        document.getElementById("alertModal7"),
        {
          keyboard: false,
        }
      );
      alertModal7.show();
    },
    stopScript: async function () {
      await this.saveScript("1");
    },
    openRunScriptModel() {
      const alertModal6 = new bootstrap.Modal(
        document.getElementById("alertModal6"),
        {
          keyboard: false,
        }
      );
      alertModal6.show();
    },
    runScript: async function () {
      await this.saveScript("2");
    },
    clearScript() {
      let deleteyes = confirm(
        "刪除後即無法返回，是否確認刪除?\n執行前的1小時內將無法進行更改與刪除"
      );
      if (deleteyes) {
        this.deleteScriptApi();
        this.patchRunScriptApi("0");
      } else {
        return;
      }
    },
    // api
    runSaveScriptApi(work) {
      if (this.marketingChannel == "email") {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
        };
        const body = {
          flows: this.testList2,
          script_id: this.eventScriptIdFromSche,
        };

        allapi2
          .scriptPost(params, body)
          .then(async(res) => {
            console.log("成功儲存劇本", res);
            if (work === "1") {
             await this.patchRunScriptApi("1");
            } else if (work === "2") {
             await this.patchRunScriptApi("2");
            } else if (this.eventScriptHasEventFromSche === "0") {
             await this.patchRunScriptApi("1");
            }
            this.alertModal3 = new bootstrap.Modal(
              document.getElementById("alertModal3"),
              {
                keyboard: false,
              }
            );
            this.alertModal3.show();
            this.getScriptById();
            this.editList = false;
            this.loading = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("連線有誤" + err);
            this.loading = false;
          });
      } else {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
        };
        const body = {
          flows: this.testList2,
          script_id: this.eventScriptIdFromSche,
        };

        allapi2
          .smsScriptPost(params, body)
          .then(async(res) => {
            console.log("work", work);
            console.log(
              "this.eventScriptHasEventFromSche",
              this.eventScriptHasEventFromSche
            );
            console.log("成功儲存劇本", res);
            if (work === "1") {
            await  this.patchRunScriptApi("1");
            } else if (work === "2") {
            await  this.patchRunScriptApi("2");
            } else if (this.eventScriptHasEventFromSche === "0") {
             await this.patchRunScriptApi("1");
            }
            this.alertModal3 = new bootstrap.Modal(
              document.getElementById("alertModal3"),
              {
                keyboard: false,
              }
            );
            this.alertModal3.show();
            this.getScriptById();
            this.editList = false;
            this.loading = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("連線有誤" + err);
            this.loading = false;
          });
      }
    },
    patchRunScriptApi(work) {
      if (this.marketingChannel == "email") {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
          script_id: this.eventScriptIdFromSche,
          state: work,
        };

        allapi2
          .scheduleUpdateHasEventScriptIdPatch(params)
          .then((res) => {
            console.log("成功更改劇本狀態為" + work, res);
            this.eventScriptHasEventFromSche = work;
            sessionStorage.setItem("setResScriptHasEvent", work);
            setTimeout(() => {
              if (work === "2") {
                this.alertModal3.hide();
                this.$router.push({
                  name: "MarketingSchedule",
                });
              }
            }, 1500);
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("patch連線有誤" + err);
          });
      } else {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
          id: this.eventScriptIdFromSche,
        };
        const body = {
          state: work,
        };

        allapi2
          .smsScheduleUpdateHasEventIdPost(params, body)
          .then((res) => {
            console.log("成功更改劇本狀態為" + work, res);
            this.eventScriptHasEventFromSche = work;
            sessionStorage.setItem("setResScriptHasEvent", work);
            setTimeout(() => {
              if (work === "2") {
                this.alertModal3.hide();
                this.$router.push({
                  name: "SmsSchedule",
                });
              }
            }, 1500);
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("patch連線有誤" + err);
          });
      }
    },
    getScriptById: async function () {
      const token = sessionStorage.getItem("access_token");

      if (this.marketingChannel == "email") {
        const params = {
          Authorization: "Bearer " + token,
          script_id: this.eventScriptIdFromSche,
        };
        await allapi2
          .scriptScriptIdGet(params)
          .then((res) => {
            console.log("成功根據排成獲得劇本內容", res);
            this.testList2 = res.data.flows;
            this.loading = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
          });
      } else {
        const params = {
          Authorization: "Bearer " + token,
          id: this.eventScriptIdFromSche,
        };
        await allapi2
          .smsScriptIdGet(params)
          .then((res) => {
            console.log("成功根據排成獲得劇本內容", res);
            this.testList2 = res.data.flows;
            this.loading = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
          });
      }

      this.testList2 = this.testList2.map((o) => {
        return {
          action: o.action,
          event: o.event,
          is_yes: o.is_yes,
          ruleID: o.ruleID,
          subject: o.subject,
          template_name: o.template,
          time_after: o.time_after,
          time_interval: o.time_interval,
          vice_ruleID: o.vice_ruleID,
        };
      });
      this.testList2.forEach((o) => {
        if (o.action === "寄信") {
          o.action = "傳送Email";
        } else if (o.action === "寄簡訊") {
          o.action = "傳送SMS";
        }
      });
      console.log(this.testList2);

      this.testList2.map((d) => {
        if (d.vice_ruleID.length === 12 && d.is_yes === 1) {
          this.currentEvent = d.event;
          console.log(d.event.length);
          if (d.event.length > 5) {
            this.event_moreoption_Selected = d.event;
          }
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after = d.time_after / 24;
            this.yes_time_after_state = "天後";
          } else {
            this.yes_time_after = d.time_after;
            this.yes_time_after_state = "小時後";
          }
          this.yes_action = d.action;
          this.yes_template = d.template_name;
          this.yes_subject = d.subject;
        } else if (d.vice_ruleID.length === 12 && d.is_yes === 0) {
          this.currentEvent = d.event;
          if (d.event.length > 5) {
            this.event_moreoption_Selected = d.event;
          }
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after = d.time_after / 24;
            this.no_time_after_state = "天後";
          } else {
            this.no_time_after = d.time_after;
            this.no_time_after_state = "小時後";
          }
          this.no_action = d.action;
          this.no_template = d.template_name;
          this.no_subject = d.subject;
        } else if (d.vice_ruleID.includes("_1_1")) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after = d.time_after / 24;
            this.yesyes_time_after_state = "天後";
          } else {
            this.yesyes_time_after = d.time_after;
            this.yesyes_time_after_state = "小時後";
          }
          this.yesyes_action = d.action;
          this.yesyes_template = d.template_name;
          this.yesyes_subject = d.subject;
        } else if (d.vice_ruleID.includes("_1_2")) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after = d.time_after / 24;
            this.yesno_time_after_state = "天後";
          } else {
            this.yesno_time_after = d.time_after;
            this.yesno_time_after_state = "小時後";
          }
          this.yesno_action = d.action;
          this.yesno_template = d.template_name;
          this.yesno_subject = d.subject;
        } else if (d.vice_ruleID.includes("_2_1")) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after = d.time_after / 24;
            this.noyes_time_after_state = "天後";
          } else {
            this.noyes_time_after = d.time_after;
            this.noyes_time_after_state = "小時後";
          }
          this.noyes_action = d.action;
          this.noyes_template = d.template_name;
          this.noyes_subject = d.subject;
        } else if (d.vice_ruleID.includes("_2_2")) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after = d.time_after / 24;
            this.nono_time_after_state = "天後";
          } else {
            this.nono_time_after = d.time_after;
            this.nono_time_after_state = "小時後";
          }
          this.nono_action = d.action;
          this.nono_template = d.template_name;
          this.nono_subject = d.subject;
        }
      });
      this.editList = false;
      console.log("this.editList", this.editList);
    },
    deleteScriptApi() {
      console.log("this.eventScriptIdFromSche", this.eventScriptIdFromSche);
      const token = sessionStorage.getItem("access_token");

      if (this.marketingChannel == "email") {
        const params = {
          Authorization: "Bearer " + token,
          script_id: this.eventScriptIdFromSche,
        };
        allapi2
          .scriptScriptIdDelete(params)
          .then((res) => {
            console.log("成功刪除劇本", res);
            const alertModal5 = new bootstrap.Modal(
              document.getElementById("alertModal5"),
              {
                keyboard: false,
              }
            );
            alertModal5.show();
            this.offCanvasClick = new bootstrap.Offcanvas(
              document.getElementById("offcanvasRight"),
              {
                keyboard: false,
              }
            );
            this.offCanvasClick.show();
            this.updateWho = "event";
            this.deleteEventTag();
            this.editList = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("連線有誤" + err);
          });
      } else {
        const params = {
          Authorization: "Bearer " + token,
          id: this.eventScriptIdFromSche,
        };
        allapi2
          .smsScriptIdDelete(params)
          .then((res) => {
            console.log("成功刪除劇本", res);
            const alertModal5 = new bootstrap.Modal(
              document.getElementById("alertModal5"),
              {
                keyboard: false,
              }
            );
            alertModal5.show();
            this.updateWho = "event";
            this.deleteEventTag();
            this.editList = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("連線有誤" + err);
          });
      }
    },
    beforeunloadcall(e) {
      e = e || window.event;
      // e.returnValue = '确定要关闭窗口吗？'
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "确定要close？";
        console.log("123");
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "whatever";
    },
    gosche() {
      this.$router.push({
        name: "MarketingSchedule",
      });
    },
    test() {
      this.z = this.z - 0.1;
      if (this.z < 0.4) {
        this.z = 0.4;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomout() {
      this.z = this.z - 0.1;
      if (this.z < 0.4) {
        this.z = 0.4;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomin() {
      this.z = this.z + 0.1;
      if (this.z > 1) {
        this.z = 1;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomreset() {
      this.z = 1;
      this.zoom = { transform: `scale(1)` };
    },
  },
};
</script>


<style>
/* MarketingScript */
.tab-div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
}
/* .form-control:focus {
  box-shadow: none;
  border: 1px solid #ff614d;
} */
.zoom2 {
  position: absolute;
  font-size: 16px;
  top: 721px;
  left: 78%;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: transparent;
  z-index: 99;
}
.script-tip-in-marketing-script {
  position: absolute;
  font-size: 16px;
  top: 80px;
  right: 30px;
  z-index: 99;
}
.child-popout-inMarketingScript-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: 40px;
  text-align: left;
  top: -524px;
  width: 152px;
  z-index: 99;
}
.container__arrow-inMarketingScript-first {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  height: 0;
  left: 104.2%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(270deg);
  width: 0;
  z-index: 10;
}
.nowork {
  display: none;
}
.notwork {
  background-color: #eeeeee;
  border-radius: 5px;
  font-size: 16px;
  left: 20px;
  padding: 5px 10px;
  position: absolute;
  top: 80px;
  z-index: 99;
}
.work {
  background-color: #c6e0c9;
  border-radius: 5px;
  font-size: 16px;
  left: 20px;
  padding: 5px 10px;
  position: absolute;
  top: 80px;
  z-index: 99;
}

/* 一般樣式 */
.script-header {
  font-size: 24px;
  font-weight: bold;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.nav-tabs .nav-link {
  background-color: white;
  height: 40px;
  color: #575757;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.nav-tabs .nav-link.active {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.contain-left-all {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 645px;
  margin-bottom: 25px;
}
.moveTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
hr {
  border: 1px solid black;
}
.timeTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
.time-input {
  border: none;
  border-bottom: 1px solid black;
  background-color: #eaf3f4;
  width: 50px;
  outline: none;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.contain-right-top-marketing {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 721px;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}
.tree-div {
  width: 100%;
}
.tree-tag {
  border-radius: 10px;
  background-color: #eaf3f4;
  text-align: center;
  width: 90px;
  margin: 0 auto;
  cursor: pointer;
}
.template-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 10px;
  background-color: #eaf3f4;
  padding: 0 10px;
  text-align: center;
  width: 90px;
  margin: 0 auto;
}
.blue-tag {
  background-color: #96c5d7;
  color: white;
  border-radius: 10px;
  padding: 13px 19px 0 19px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.blue-tag2 {
  background-color: #96c5d7;
  border-radius: 15px;
  padding: 16px;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.blue-tag3 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding: 12px 25px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.mail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/openmail.svg");
}
.click-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clicklink.svg");
}
.change-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/change.svg");
}
.clock-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clock.svg");
}
.sendmail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendmail.svg");
}
.sendsms-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendsms.svg");
}
.yes-tag {
  background-color: #8bc19b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
}
.no-tag {
  background-color: #ff8b8b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
}
.time-tag {
  background-color: #fc9f66;
  border-radius: 10px;
  color: white;
  padding-top: 5px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag2 {
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 13px 15px 10px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag3 {
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 0px 20px 0px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.contain-right-bot {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 120px;
  margin-top: 30px;
}
.bot-left-left-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #ff998b, #ff614d);
  color: white;
}
.bot-left-btn {
  border-radius: 10px;
  border: none;
  background-color: #eaf3f4;
}
.bot-right-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.floating-button {
  position: fixed;
  bottom: 0;
  /* top: 50%; */
  left: 45.6%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  z-index: 10;
  display: flex;
  width: 8rem;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.floating-button2 {
  left: 50%;

  z-index: 10;
  display: flex;
  /* width: 8rem; */
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
  /* border-top-left-radius: 20px;
border-top-right-radius: 20px; */
}
.select-style {
  border-radius: 10px;
  text-align: center;
  height: 40px;
  border: 1px solid #c4c4c4;
}
.btn-basic {
  border: none;
  border-radius: 10px;
  width: 14rem;
  height: 40px;
}
.modal-btn-close {
  color: #fff;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border: none;
  width: 10vw;
}

.btn-save {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-group-div {
  background-color: #eaf3f4;
  width: 68%;
  border-radius: 10px;
}
.button-color2 {
  width: 50%;
  background-color: #eaf3f4;
  border-radius: 10px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -70px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.file-span {
  display: inline-block;
  background-color: #eaf3f4;
  line-height: 40px;
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clear-img-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.clear-file-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.add-template-button-color2 {
  border: 1px solid #71afb6;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  border-radius: 5px;
}
.add-template-button-color22 {
  border: 1px solid #71afb6;
  height: 39px;
  color: #71afb6;
  background-color: white;
  border-radius: 5px;
}
.delete-btn {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 93%;
}
.delete-btn2 {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 930px;
}
.group-div {
  position: absolute;
  right: 5px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.channel-btn {
  border: none;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.modal-backdrop {
  z-index: 999;
  background-color: rgb(155, 153, 153);
}
.highlight2 {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 10rem;
  padding: 30px;
  border-radius: 25px;
  width: 330px;
  word-break: break-all;
  /* overflow-wrap: anywhere; */
  white-space: normal;
}
.highlight-SmsTemp {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.highlight-SmsTemp-var {
  background-color: #71afb6;
  color: white;
}
/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35%;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 5px;
  left: 4px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  top: 5px;
  left: 5px;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #e0dada;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #71afb6;
}
.shodow-box-afterTriggerScript {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
}
</style>