<template>
  <div class="container-fluid p-0 m-0">
    <div class="container-fluid p-0 m-0">
      <div class="d-flex flex-wrap">
        <div class="col-6 d-flex">
          <p
            class="col-6 section-title m-0 ms-2"
            style="line-height: 35px; height: 50px"
          >
            常用模板
          </p>
        </div>
        <div class="col-6">
          <!-- @paginate="setPage" -->
        </div>
        <div class="col-12">
          <img
            class="searchImg"
            src="../../assets/search.png"
            alt="searchImg"
          />
          <input class="search-input" v-model="searchInput" />
        </div>
      </div>
    </div>
    <hr
      style="
        width: 100%;
        opacity: 1;
        height: 0;
        border: 0;
        border-bottom: 1px solid #c4c4c4;
      "
    />
    <div class="ms-3">
      <div class="mb-4" style="position: relative">
        <Paginator
          :rows="this.perPage"
          :pageLinkSize="3"
          template="PrevPageLink PageLinks NextPageLink "
          :first="nowPage"
          :totalRecords="todos.length"
          @page="onPage($event)"
          class="m-0 me-2"
        ></Paginator>
        <div
          style="
            position: absolute;
            right: 15px;
            bottom: -25px;
            font-size: 14px;
            color: #8a8a8a;
            min-width: 100px;
          "
          v-if="this.todos.length === 0"
        >
          目前 0 ，共
          {{ Math.ceil(this.todos.length / this.perPage) }} 頁
        </div>
        <div
          style="
            position: absolute;
            right: 15px;
            bottom: -25px;
            font-size: 14px;
            color: #8a8a8a;
            min-width: 100px;
          "
          v-else
        >
          目前 {{ this.page }} ，共
          {{ Math.ceil(this.todos.length / this.perPage) }} 頁
        </div>
      </div>
    </div>
    <div class="noData" v-if="this.todos.length === 0">查無此模板名稱</div>
    <div class="container-fluid m-0 p-0 container-flexdirection-custom" v-else>
      <div class="col-12">
        <button
          class="add-template mx-0"
          @click="addNewTodo"
          v-if="emailChannel"
        >
          <img
            src="../../assets/greenPlus.svg"
            alt=""
            style="position: relative; top: -1.5px"
            class="me-2"
          />新的Email模板
        </button>
        <button class="add-template mx-0" @click="addNewTodo" v-else>
          <img
            src="../../assets/greenPlus.svg"
            alt=""
            style="position: relative; top: -1.5px"
            class="me-2"
          />新的簡訊模板
        </button>
      </div>
      <ProductFavNameList
        class="m-0 p-0 hover-grow"
        v-on:remove-todo="removeTodo"
        :key="index"
        v-for="(todo, index) in displayedRecords"
        :todo="todo"
        :selectedID="selectedID"
        v-bind="$attrs"
        v-bind:emailChannel="emailChannel"
        draggable="true"
        @dragstart="dragStart($event)"
        @dragover="allowDrop"
      ></ProductFavNameList>
    </div>
  </div>
</template>

<script>
import ProductFavNameList from "./ProductFavNameList.vue";
import Pagination from "v-pagination-3";
import { allapi2 } from "../../../public/static2/api/apigClient.js";
import Paginator from "primevue/paginator";

export default {
  props: ["todos", "selectedID", "cssProps", "emailChannel"],
  name: "ProductList",
  components: {
    ProductFavNameList,
    Pagination,
    Paginator,
  },
  data() {
    return {
      productSelected: {},
      page: 1,
      perPage: 4,
      nowPage: 0,
      records: [],
      // searchTodos: "",
      searchInput: "",
      gopage1: false,
    };
  },
  watch: {
    searchInput: function (val, oldVal) {
      console.log(this.todos);
      // this.searchTodos=this.todos.filter((d) => d.template_name.includes(val))
      if (this.nowPage > 2) {
        this.nowPage = 0;
      } else {
        this.nowPage = this.nowPage + 1;
      }
        this.gopage1 = true;
        setTimeout(() => {
        this.gopage1 = false;
        }, 0);
        console.log("this.gopage1",this.gopage1);
      this.page = 1;
      this.$emit("search", val);
    },
  },
  methods: {
    onPage(event) {
      console.log("onpage 了", event);
      if (this.gopage1) {
        console.log("nothing");
      } else {
        this.page = event.page + 1;
      }

      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
    },
    addNewTodo() {
      this.$emit("clearalltoadd");
    },
    removeTodo(todo) {
      console.log(todo);
      let deleteyes = confirm("刪除後即無法返回，是否確認刪除?");
      if (deleteyes) {
        this.$emit("changeLoading");
        if (this.$props.emailChannel) {
          this.delete(todo);
        } else {
          this.deleteSms(todo);
        }
      } else {
        console.log(this.$props);
        return;
      }
    },
    delete(todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
      };

      allapi2
        .templatev2TempDelete(params, body)
        .then((res) => {
          console.log(res);
          this.$emit("refresh-todos");
          this.$emit("changeLoading");
          alert("成功刪除email模板");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("delete連線有誤" + err);
          this.$emit("changeLoading");
        });
    },
    deleteSms(todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
      };

      allapi2
        .smsTemplateSmsTempInfoDelete(params, body)
        .then((res) => {
          console.log(res);
          this.$emit("refresh-todos");
          this.$emit("changeLoading");
          alert("成功刪除sms模板");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("delete連線有誤" + err);
          this.$emit("changeLoading");
        });
    },
    changePage() {
      if (this.nowPage > 2) {
        this.nowPage = 0;
      } else {
        this.nowPage = this.nowPage + 1;
      }
        this.gopage1 = true;
        setTimeout(() => {
        this.gopage1 = false;
        }, 0);
        console.log("this.gopage1",this.gopage1);
      this.page = 1;
    },
    allowDrop(e) {
      e.preventDefault();
    },
    //开始拖动
    dragStart(e) {
      let tar = e.target.innerText;
      console.log(e);
      console.log(tar);
      e.dataTransfer.setData("Text", tar);
    },
  },
  computed: {
    displayedRecords() {
      console.log("computed getter");
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.todos.slice(startIndex, endIndex);
    },
  },
};
</script>
<style scoped>
/* .hover-grow{
   transition: all .2s ease-in-out;
}
.hover-grow:hover{
  transform: scale(1.04);
} */
.noData {
  padding: 50px;
  font-size: 20px;
}
.searchImg {
  position: relative;
  top: -2px;
  left: 24px;
}
.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 30px;
  width: 17rem;
  height: 37px;
}
.section-title {
  color: #71afb6;
}
.container-flexdirection-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.add-template {
  width: 91%;
  height: 40px;
  margin: 12.5px;
  border: 1px dashed #71afb6;
  border-radius: 10px;
  background-color: white;
  color: #71afb6;
  position: relative;
}

/* .VuePagination__pagination-item-prev-chunk {
  display: none;
}
.VuePagination__pagination-item-next-chunk {
  display: none;
}
.VuePagination__pagination-item-next-page .page-link {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.VuePagination__pagination-item-prev-page .page-link {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.VuePagination__count {
  display: none;
}


.page-item.disabled .page-link {
  background-color: #eaf3f4;
}
.page-item.active .page-link {
  z-index: 3;
  background-color: #71afb6;
  color: #fff;
  border-color: transparent;
}
.page-item.disable .page-link {
  background-color: #71afb6;
}
.page-link {
  color: #000;
  font-weight: 700;
  background-color: #eaf3f4;
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .container-flexdirection-custom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
} */
</style>
