<template>
  <div
    class="select-container"
    style="width: 100%; position: relative"
    ref="target"
  >
    <div
      class="d-flex justify-content-end align-items-center"
      style="
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 0 14px;
        height: 40px;
      "
      @click="autoShow = !autoShow"
    >
      <span
        class="flex-fill text-start d-flex align-items-center"
        v-if="selectValue != ''"
      >
        <div
          style="
            background-color: #fc9f66;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === '價值分群'"
        ></div>
        <div
          style="
            background-color: #cae7ea;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === '渠道再行銷'"
        ></div>
        <div
          style="
            background-color: #8a8a8a;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === '基本屬性'"
        ></div>
        <div
          style="
            background-color: #96c5d7;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === '網站行為'"
        ></div>
        <div
          style="
            background-color: #f9c357;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === '消費行為'"
        ></div>
        <div
          style="
            background-color: #8bc19a;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === 'AI顧客分群'"
        ></div>
        <div
          style="
            background-color: #f28d80;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="selectValue === 'CF站外瀏覽'"
        ></div>
        <div
          style="
            background-color: #71afb6;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="
            selectValue != '價值分群' &&
            selectValue != '渠道再行銷' &&
            selectValue != '基本屬性' &&
            selectValue != '網站行為' &&
            selectValue != '消費行為' &&
            selectValue != 'AI顧客分群' &&
            selectValue != 'CF站外瀏覽'
          "
        ></div>
        <span>
          {{ selectValue }}
        </span>
      </span>
      <span class="flex-fill text-start" style="color: #575757" v-else>
        {{ holder }}
      </span>
      <img src="../../assets/selection-arrow.png" alt="searchImg" />
    </div>
    <div
      class="auto-complicate-div"
      style="width: 100%"
      :style="`max-height:${mxHeight}px;top:${topSide}px`"
      v-show="autoShow"
      @mouseenter="autoShow = true"
    >
      <div
        v-for="(value, index) in valueArr"
        :key="index"
        class="
          text-start
          m-0
          auto-complicate-p
          d-flex
          justify-content-end
          align-items-center
        "
        style="padding: 5px 14px"
        @click="childClick(value, index)"
      >
        <div
          style="
            background-color: #fc9f66;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === '價值分群'"
        ></div>
        <div
          style="
            background-color: #cae7ea;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === '渠道再行銷'"
        ></div>
        <div
          style="
            background-color: #8a8a8a;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === '基本屬性'"
        ></div>
        <div
          style="
            background-color: #96c5d7;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === '網站行為'"
        ></div>
        <div
          style="
            background-color: #f9c357;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === '消費行為'"
        ></div>
        <div
          style="
            background-color: #8bc19a;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === 'AI顧客分群'"
        ></div>
        <div
          style="
            background-color: #f28d80;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="value === 'CF站外瀏覽'"
        ></div>
        <div
          style="
            background-color: #71afb6;
            width: 8px;
            height: 18px;
            margin-top: 2px;
          "
          class="me-2"
          v-if="
            value != '價值分群' &&
            value != '渠道再行銷' &&
            value != '基本屬性' &&
            value != '網站行為' &&
            value != '消費行為' &&
            value != 'AI顧客分群' &&
            value != 'CF站外瀏覽'
          "
        ></div>
        <span
          class="flex-fill"
          v-if="Object.prototype.toString.call(valueArr) === '[object Object]'"
        >
          {{ index }}
        </span>
        <span class="flex-fill" v-else>
          {{ value }}
        </span>
        <span style="width: 10px">&nbsp;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "DropdownType",
  components: {},
  props: {
    topSide: Number,
    mxHeight: Number,
    valueArr: [Array, Object],
    selectValue: String,
    changingSelected: String,
    holder: String,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return {
      target,
      autoShow,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    childClick(value, index) {
      this.$emit(
        "selectValueByDrop",
        value,
        this.$props.changingSelected,
        index
      );
      this.autoShow = false;
    },
  },
};
</script>

<style scoped>
/* DropdownType */
.auto-complicate-div {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  background-color: white;
  z-index: 99;
  max-height: 200px;
  overflow: auto;
}
.select-container {
  color: #575757;
  text-align: center;
  cursor: default;
}
.auto-complicate-p {
  color: #575757;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.auto-complicate-p:hover:last-child {
  background-color: #eaf3f4;
  color: #575757;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.auto-complicate-p:hover:first-child {
  background-color: #eaf3f4;
  color: #575757;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>