<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="d-flex">
      <span class="title-span fs-medium" style="height: 40px; line-height: 40px"
        >基本資料</span
      >
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div class="customerNumber-div" style="width: 23.5%">
          <div>
            <p class="fs-small p-3 pb-0 mb-0" style="color: #71afb6">
              顧客編號
            </p>
            <div>
              <div style="position: relative" class="search-div">
                <img
                  class="searchImg-basic"
                  src="../assets/search.png"
                  alt="searchImg"
                />
                <input
                  id="input360"
                  class="search-input-360"
                  v-model="searching"
                  v-on:keyup.enter="enterinput()"
                  placeholder="可輸入ID.姓名.電話.信箱搜尋"
                  v-tooltip.right="{
                    value: '請按Enter搜尋',
                    class: 'tipInBasic',
                  }"
                />
                <img
                  class="xImg-basic"
                  src="../assets/greenX.svg"
                  alt=""
                  @click="this.searchClear()"
                  style="cursor: pointer"
                />
              </div>
              <hr
                style="
                  width: 100%;
                  margin: 0px;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div class="d-flex flex-wrap sorting-button-div">
                <button
                  :class="cost ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickCost"
                >
                  消費金額
                  <img class="sorting-img" :src="upimg" alt="searchImg" />
                </button>
                <button
                  :class="count ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickCount"
                >
                  消費次數
                  <img class="sorting-img" :src="upimg2" alt="searchImg" />
                </button>
                <button
                  :class="consumption ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickConsumption"
                >
                  最近消費
                  <img class="sorting-img" :src="upimg3" alt="searchImg" />
                </button>
                <button
                  :class="addtime ? 'button-highlight' : 'sorting-button '"
                  class="sorting-button col-6"
                  @click="clickTime"
                >
                  加入時間
                  <img class="sorting-img" :src="upimg4" alt="clickTime" />
                </button>
              </div>
              <hr
                style="
                  width: 100%;
                  margin-top: 0px;
                  opacity: 1;
                  height: 0;
                  border: 0;
                  border-bottom: 1px solid #c4c4c4;
                "
              />
              <div
                style="height: 450px"
                class="d-flex flex-column justify-content-end"
                v-if="this.wholeSearchloading"
              >
                <div class="d-flex justify-content-center my-auto">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="customerID-div-basic"
                  v-if="this.member_id.length === 0"
                >
                  查無此資料
                </div>
                <div
                  class="customerID-div-basic"
                  @scroll="this.scroll($event)"
                  v-else
                >
                  <div
                    class="loop-div"
                    :key="item"
                    v-for="(item, index) in member_id"
                  >
                    <input
                      type="radio"
                      id="vehicle1"
                      name="vehicle1"
                      :value="item"
                      v-model="checkbox"
                      @click="check($event, customerID[index])"
                    />
                    {{ customerID[index] }}
                  </div>
                  <div class="d-flex justify-content-center" v-if="searchload">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <!-- <p>{{this.customerID}}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ps-4" style="width: 76.5%">
          <div class="col-12 profileinfo-div d-flex">
            <div class="col-2 profile-col2-border">
              <div style="padding: 20px" class="profile-col2">
                <img
                  class="align-center"
                  style="width: 100%"
                  src="../assets/RFM-Profile.png"
                  alt="clickTime"
                />
                <p
                  class="text-center"
                  style="margin-top: 25px; margin-bottom: 0px"
                >
                  {{ member_info.姓名 }}
                </p>
              </div>
            </div>
            <div class="col-10 profile-col10">
              <div class="row">
                <div class="col-12 col-xxl-6 d-flex">
                  <div class="col-4">
                    <p class="fs-small" style="margin-bottom: 0px">性別</p>

                    <p class="fs-small" style="margin-bottom: 0px">
                      {{ member_info.性別 }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="fs-small" style="margin-bottom: 0px">年齡</p>

                    <p class="fs-small" style="margin-bottom: 0px">
                      {{ member_info.年齡 }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="fs-small" style="margin-bottom: 0px">生日</p>

                    <p class="fs-small" style="margin-bottom: 0px">
                      {{ member_info.生日 }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-xxl-6 d-flex">
                  <div class="col-6">
                    <p class="fs-small" style="margin-bottom: 0px">手機</p>

                    <p class="fs-small" style="margin-bottom: 0px">
                      {{ member_info.手機 }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="fs-small" style="margin-bottom: 0px">婚姻狀態</p>

                    <p class="fs-small" style="margin-bottom: 0px">
                      {{ member_info.婚姻狀態 }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 row profile-col10-2row">
                <div class="col-6">
                  <p class="fs-small" style="margin-bottom: 0px">信箱</p>

                  <p class="fs-small" style="margin-bottom: 0px">
                    {{ member_info.信箱 }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="fs-small" style="margin-bottom: 0px">地址</p>

                  <p class="fs-small" style="margin-bottom: 0px">
                    {{ member_info.地址 }}
                  </p>
                </div>
              </div>
              <div class="col-12 row profile-col10-2row">
                <div class="col-3">
                  <p class="fs-small" style="margin-bottom: 0px">Member ID</p>

                  <p class="fs-small" style="margin-bottom: 0px">
                    {{ member_info.MEMBER_ID }}
                  </p>
                </div>
                <div class="col-3">
                  <p class="fs-small" style="margin-bottom: 0px">GA-UID</p>

                  <p class="fs-small" style="margin-bottom: 0px">
                    {{ member_info.GA_UID }}
                  </p>
                </div>
                <div class="col-2">
                  <p class="fs-small" style="margin-bottom: 0px">會員等級</p>

                  <p class="fs-small" style="margin-bottom: 0px">
                    {{ member_info.會員等級 }}
                  </p>
                </div>
                <div class="col-3">
                  <p class="fs-small" style="margin-bottom: 0px">加入時間</p>

                  <p class="fs-small" style="margin-bottom: 0px">
                    {{ member_info.入會時間 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-4">
              <div class="RFM-div">
                <p class="fs-small mb-0" style="color: #71afb6">
                  會員標籤 (RFM)
                </p>
                <div class="profile-col3">
                  <img src="../assets/RFM-Profile.png" />
                  <p class="mt-3">{{ member_rfm.客群 }}</p>
                </div>
                <div>
                  <p style="margin: 0px">最近消費 (R)</p>
                  <div class="highRFM text-center">
                    <span
                      class="fs-medium"
                      v-if="member_rfm.最近消費 === undefined"
                      >- -</span
                    >
                    <span v-else>
                      {{ member_rfm.最近消費 }}
                    </span>
                  </div>
                </div>
                <div>
                  <p style="margin: 0px">消費頻率 (F)</p>
                  <div class="mediumRFM text-center">
                    <span
                      class="fs-medium"
                      v-if="member_rfm.消費頻率 === undefined"
                      >- -</span
                    >
                    <span v-else>
                      {{ member_rfm.消費頻率 }}
                    </span>
                  </div>
                </div>
                <div>
                  <p style="margin: 0px">消費金額 (M)</p>
                  <div class="highRFM text-center">
                    <span
                      class="fs-medium"
                      v-if="member_rfm.消費金額 === undefined"
                      >- -</span
                    >
                    <span v-else>
                      {{ member_rfm.消費金額 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="col-12 ps-3">
                <div class="table-bar-div-360" style="position: relative">
                  <p class="fs-small mb-0" style="color: #71afb6">
                    推薦購買商品
                  </p>
                  <span
                    style="
                      position: absolute;
                      top: 75%;
                      left: 45%;
                      color: #c4c4c4;
                    "
                    v-if="this.member_recommend[0] === undefined"
                    >無資料</span
                  >
                  <DataTable
                    style="margin-top: 10px"
                    :value="member_recommend"
                    class="header-table"
                    :scrollable="true"
                    scrollHeight="140px"
                  >
                    <Column
                      field="商品編號"
                      header="商品名稱"
                      :sortable="true"
                    ></Column>
                    <Column
                      field="產品類別"
                      header="產品類別"
                      :sortable="true"
                    ></Column>
                    <Column
                      field="品牌名稱"
                      header="品牌名稱"
                      :sortable="true"
                    ></Column>
                  </DataTable>
                </div>
              </div>
              <div class="col-12 ps-3">
                <div class="table-bar-div-3602">
                  <div class="d-flex justify-content-between">
                    <span class="fs-small" style="color: #71afb6"
                      >偏好類別</span
                    >
                  </div>
                  <div id="bar" style="position: relative">
                    <span
                      style="
                        position: absolute;
                        top: 30%;
                        left: 45%;
                        color: #c4c4c4;
                      "
                      v-if="this.series2[0].data[0] === undefined"
                      >無資料</span
                    >
                    <apexchart
                      type="bar"
                      height="180"
                      :options="chartOptions2"
                      :series="series2"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div class="col-6">
          <div class="bottom-table-div" style="position: relative">
            <div class="d-flex justify-content-between">
              <span class="fs-small" style="color: #71afb6"
                >過往優惠使用紀錄</span
              >
              <div class="title-button-div">
                <button
                  class="button-nofocus fs-small"
                  :class="selectOnline ? 'button-focus' : 'button-nofocus'"
                  @click="online"
                >
                  線上
                </button>
                <button
                  class="button-nofocus fs-small"
                  :class="selectOnline ? 'button-nofocus' : 'button-focus'"
                  @click="offline"
                >
                  線下
                </button>
              </div>
            </div>
            <span
              style="position: absolute; top: 75%; left: 45%; color: #c4c4c4"
              v-if="this.member_discount[0] === undefined"
              >無資料</span
            >
            <DataTable
              style="margin-top: 10px"
              :value="member_discount"
              class="bottom-table"
              :scrollable="true"
              scrollHeight="140px"
            >
              <Column
                field="商品編號"
                header="商品名稱"
                :sortable="true"
              ></Column>
              <Column
                field="產品類別"
                header="產品類別"
                :sortable="true"
              ></Column>
              <Column
                field="品牌名稱"
                header="品牌名稱"
                :sortable="true"
              ></Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="bottom-table-div">
            <div class="d-flex justify-content-between">
              <span class="fs-small" style="color: #71afb6">偏好商品</span>
            </div>
            <div id="bar" style="position: relative">
              <span
                style="position: absolute; top: 30%; left: 45%; color: #c4c4c4"
                v-if="this.series3[0].data[0] === undefined"
                >無資料</span
              >
              <apexchart
                type="bar"
                height="180"
                :options="chartOptions3"
                :series="series3"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";
import updownimage from "../assets/sorting-button.png";
import upimage from "../assets/up-arrow.png";
import downimage from "../assets/down-arrow.png";

import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  name: "BasicInfo",
  components: {
    DataTable,
    Column,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      json_data: [
        {
          中文: "次是",
          中文2: "測試",
        },
        {
          中文: "次是2",
          中文2: "測試2",
        },
      ],
      wholeSearchloading: false,
      loading: false,
      searching: "",
      rank: "",
      ascending: false,
      checkbox: "",
      checkboxPoint: "",
      selectId: "",
      customerID: [],
      member_id: [],

      member_info: {},
      member_rfm: {},
      member_recommend: [],
      member_discount: [],

      selectOnline: true,

      cost: false,
      count: false,
      consumption: false,
      addtime: false,

      customerProfilePic: "",
      upimg: updownimage,
      upimg2: updownimage,
      upimg3: updownimage,
      upimg4: updownimage,

      series2: [
        {
          name: "消費金額",
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "消費金額",
          },
          labels: {
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      // bar
      series3: [
        {
          name: "消費金額",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["桌子", "椅子", "電腦", "檯燈", "衣櫃", "沙發"],
          title: {
            text: "消費金額",
          },
          labels: {
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },

      ids: 0,
      notEnd: true,
      bottom: false,
      searchload: false,
    };
  },
  beforeRouteLeave: function (to, from) {
    sessionStorage.removeItem("template_key");
    if (
      to.path === "/BasicInfo" ||
      to.path === "/PurchaseRecord" ||
      to.path === "/OnlineBehavior"
    ) {
      return;
    } else {
      console.log("remove checkboxID");

      sessionStorage.removeItem("checkboxID");
    }
  },
  watch: {
    searching: function (val) {
      if (val === "") {
        this.searchClear();
      }
    },
  },
  created() {
    console.log(sessionStorage.getItem("checkboxID"));
    this.searching = sessionStorage.getItem("checkboxID");
    this.selectId = sessionStorage.getItem("checkboxMemberID");
    this.search();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadcall(e));
  },
  methods: {
    beforeunloadcall(e) {
      e = e || window.event;
      // e.returnValue = '确定要关闭窗口吗？'
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        sessionStorage.removeItem("checkboxID");
        sessionStorage.removeItem("checkboxMemberID");
        console.log("123");
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "whatever";
    },
    scroll: async function (e) {
      const { target } = e;
      if (
        Math.ceil(target.scrollTop) >=
        target.scrollHeight - target.offsetHeight
      ) {
        if (this.notEnd) {
          if (this.searchload === false) {
            await this.searchMore();
            this.searchload = false;
          }
        }
        this.bottom = true;
      }

      console.log("this.bottom", this.bottom);
    },
    clickCost() {
      this.rank = "消費金額";
      this.cost = true;
      (this.count = false), (this.consumption = false), (this.addtime = false);
      if (this.upimg === downimage) {
        this.ascending = true;
        this.upimg = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg = downimage;
        this.search();
      }

      this.upimg2 = updownimage;
      this.upimg3 = updownimage;
      this.upimg4 = updownimage;
    },
    clickCount() {
      this.rank = "消費次數";
      (this.cost = false), (this.count = true);
      (this.consumption = false), (this.addtime = false);
      if (this.upimg2 === downimage) {
        this.ascending = true;
        this.upimg2 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg2 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg3 = updownimage;
      this.upimg4 = updownimage;
    },
    clickConsumption() {
      this.rank = "最近消費";
      (this.cost = false), (this.count = false);
      (this.consumption = true), (this.addtime = false);
      if (this.upimg3 === downimage) {
        this.ascending = true;
        this.upimg3 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg3 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg2 = updownimage;
      this.upimg4 = updownimage;
    },
    clickTime() {
      this.rank = "加入時間";
      (this.cost = false), (this.count = false);
      (this.consumption = false), (this.addtime = true);
      if (this.upimg4 === downimage) {
        this.ascending = true;
        this.upimg4 = upimage;
        this.search();
      } else {
        this.ascending = false;
        this.upimg4 = downimage;
        this.search();
      }
      this.upimg = updownimage;
      this.upimg2 = updownimage;
      this.upimg3 = updownimage;
    },
    enterinput() {
      this.search();
    },
    search: async function () {
      this.ids = 0;
      this.loading = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "basic",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log(res);
          this.customerID = res.data.Data.ID;
          this.member_id = res.data.Data.member_id;

          if (this.customerID[0] != undefined) {
            this.checkbox = this.member_id[0];
            this.selectId = this.member_id[0];
            sessionStorage.setItem("checkboxID", this.customerID[0]);
            sessionStorage.setItem("checkboxMemberID", this.member_id[0]);
          }

          this.notEnd = res.data.state;
          this.ids = res.data.ids;
          this.getMemberInfo();
          this.getMemberRfm();
          this.getMemberPrefer();
          this.getMemberRecommend();
          this.getMemberDiscount();
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      this.loading = false;
    },
    searchMore: async function () {
      this.searchload = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "basic",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log(res);

          this.customerID = this.customerID.concat(res.data.Data.ID);
          this.member_id = this.member_id.concat(res.data.Data.member_id);

          this.notEnd = res.data.state;
          this.ids = res.data.ids;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },
    searchClear: async function () {
      this.wholeSearchloading = true;
      this.searching = "";
      this.ids = 0;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        search: this.searching,
        rank: this.rank,
        ascending: this.ascending,
        type: "basic",
        ids: this.ids,
      };
      await allapi
        .memberSearchPost(params, body)
        .then((res) => {
          console.log(res);
          this.customerID = res.data.Data.ID;
          this.member_id = res.data.Data.member_id;
          this.notEnd = res.data.state;
          this.ids = res.data.ids;
          this.wholeSearchloading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
          this.wholeSearchloading = false;
        });
    },
    getMemberInfo: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicInfoGet(params)
        .then((res) => {
          console.log(res.data.Data);
          const values = Object.values(res.data.Data);
          const keys = Object.keys(res.data.Data);
          console.log(values);
          for (let index = 0; index < values.length; index++) {
            if (values[index] === null) {
              res.data.Data[keys[index]] = "- -";
            }
          }
          this.member_info = res.data.Data;
          console.log(this.member_info);

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberRfm: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicRfmGet(params)
        .then((res) => {
          console.log("會員標籤", res);
          this.member_rfm = res.data.Data;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberRecommend: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicRecommendGet(params)
        .then((res) => {
          console.log(res);
          this.member_recommend = res.data.Data;
          this.json_data = res.data.Data;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberDiscount: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicDiscountGet(params)
        .then((res) => {
          this.discount = res.data.Data;
          if (this.selectOnline === true) {
            this.member_discount = res.data.Data.線上;
          } else {
            this.member_discount = res.data.Data.線下;
          }

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getMemberPrefer: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        member_id: this.selectId,
      };
      await allapi
        .memberBasicPreferGet(params)
        .then((res) => {
          console.log(res.data.Data);
          // this.member_prefer = res.data.Data;
          this.series2 = [
            {
              data: res.data.Data.偏好類別.消費金額,
            },
          ];
          this.chartOptions2 = {
            xaxis: { categories: res.data.Data.偏好類別.類別名稱 },
          };
          this.series3 = [
            {
              data: res.data.Data.偏好商品.消費金額,
            },
          ];
          this.chartOptions3 = {
            xaxis: { categories: res.data.Data.偏好商品.商品編號 },
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    check: async function (e, id) {
      this.loading = true;
      console.log(e.target.value);
      console.log(id);
      this.selectId = e.target.value;
      this.checkboxPoint = e.target.value;
      sessionStorage.setItem("checkboxID", id);
      sessionStorage.setItem("checkboxMemberID", this.selectId);
      await Promise.all([
        this.getMemberInfo(),
        this.getMemberRfm(),
        this.getMemberPrefer(),
        this.getMemberRecommend(),
        this.getMemberDiscount(),
      ]);
      this.loading = false;
    },
    online() {
      this.selectOnline = true;
      this.member_discount = this.discount.線上;
    },
    offline() {
      this.selectOnline = false;
      this.member_discount = this.discount.線下;
    },
  },
};
</script>

<style >
/* BasicInfo */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  /* padding-top: 50px; */
  /* padding: 50px; */
}
.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}
.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}
.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}
.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}
.search-div {
  padding-top: 8px;
  height: 40px;
}
.search-input-360 {
  /* position: relative; */
  vertical-align: bottom;
  width: 80%;
  border: none;
  outline: none;
  text-indent: 15px;
  line-height: normal;
}
#input360::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-size: 14px;
}
#input360 input::placeholder {
  font-size: 14px;
}
.searchImg {
  z-index: 2;
  position: relative;
  top: 10px;
  left: 10px;
}
.searchImg-basic {
  z-index: 2;
  position: relative;
  top: 0;
  left: 10px;
}
.xImg-basic {
  z-index: 2;
  position: relative;
  top: 0;
  left: 10px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c4c4c4;
  opacity: 1; /* Firefox */
}

.customerNumber-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  /* padding: 15px; */
  margin-top: 30px;
}
.customerID-div-basic {
  margin: 20px;
  padding: 15px;
  overflow-y: scroll;
  height: 510px;
}
.sorting-button-div {
  padding: 8px;
}
.sorting-button {
  background-color: transparent;
  border: none;
  /* margin-top: 10px; */
  padding: 10px;
  color: #c4c4c4;
}
.button-highlight {
  background-color: #71afb6;
  border: none;
  /* margin-top: 10px; */
  padding: 10px;
  color: #fff;
  border-radius: 8px;
}

.sorting-img {
  padding-left: 10px;
}
.profileinfo-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 0px;
  margin-top: 30px;
}
.profile-col2-border {
  border-right: 1px solid #c4c4c4;
}
.profile-col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 26px 32px;
}
.profile-col3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 26px 0px;
}
.profile-col10 {
  /* display: flex; */
  padding: 10px 26px 18px;
}
.profile-col10-2row {
  margin-top: 20px;
}
.RFM-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 30px;
  height: 476px;
}
.highRFM {
  color: #fff;
  background-color: #fc9f66;
  padding: 10px;
  border-radius: 5px;
}
.mediumRFM {
  color: #fff;
  background-color: #f9c357;
  padding: 10px;
  border-radius: 5px;
}
.table-bar-div-360 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px 20px 24px;
  margin-top: 30px;
  height: 230px;
}
.table-bar-div-3602 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 230px;
}
.bottom-table-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;

  height: 230px;
}
.bottom-table-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 230px;
}
.tipInBasic {
  opacity: 0.7 !important;
  background-color: #575757;
  border: 1px solid #575757;
  border-radius: 10px;
  color: white;
  height: 35px;
  line-height: 35px;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
@media (min-width: 1600px) {
  
}
@media screen and (max-width: 1590px) {
  
  .customerID-div-basic {
    margin: 20px;
    padding: 15px;
    overflow-y: scroll;
    height: 535px;
  }
}
</style>