<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium">門市通路銷售</span>
      </div>

      <div class="dropdown">
        <a class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
          style="font-size: 14px">
          <span class="d-flex align-items-center">
            <img class="me-2" src="../assets/black-calendar.svg" alt="calendar" />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button type="button" class="change-date-button" data-bs-toggle="modal" data-bs-target="#exampleModal">
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p class="ms-2" style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          ">
          {{ endDate }} 為最近一筆資料
        </p>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <DatePicker :from-page="{ month: this.endMonth, year: this.endYear }" @dayclick="onDayClick"
                v-model="vue3Date" is-range :model-config="modelConfig" is-expanded :min-date="hasDataFirst"
                :max-date="hasDataEnd" />
              <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
                {{ vue3Date.start }}~{{ vue3Date.end }}
              </p>

              <button class="btn confrim-btn mt-3" @click="customDatePicker" data-bs-dismiss="modal" aria-label="Close">
                選取
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4" style="padding: 0px">
      <div class="d-flex">
        <div class="col-5">
          <div class="pie-div" style="margin-top: 5px">
            <span class="fs-small" style="color: #71afb6">門市營業額 會員 x 非會員佔比</span>
            <div id="donut" style="position: relative">
              <span class="text-center" style="position: absolute; top: 20%; left: 28%; color: #c4c4c4"
                v-if="this.memberPieSeries.every((d) => d === 0)"><img src="../assets/missData.svg" alt=""
                  style="width: 200px" />
                <br />此時間段無資料</span>
              <apexchart type="donut" height="330" :options="memberPieOptions" :series="memberPieSeries"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-7 heat-div" style="margin-top: 5px">
          <div class="d-flex justify-content-between">
            <span class="fs-small" style="color: #71afb6">熱門時段門市營業額佔比</span>
          </div>
          <div class="heatmap-div" style="position: relative">
            <span style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
              v-if="this.heatSeries[0].data[0] === undefined">此時間段無資料</span>
            <apexchart type="heatmap" width="2000" height="260" :options="heatOptions" :series="heatSeries"></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex">
        <div class="col-6">
          <div class="bar-div">
            <div class="d-flex justify-content-between">
              <span class="fs-small" style="color: #71afb6">TOP 5 門市營業額表現</span>
              <div class="title-button-div">
                <button class="button-nofocus fs-small" :class="Top5StoreProfitActive ? 'button-focus' : ''"
                  @click="getStoreProfitTop5">
                  Top 5
                </button>
                <button class="button-nofocus fs-small" :class="Bottom5StoreProfitActive ? 'button-focus' : ''"
                  @click="getStoreProfitBottom5">
                  Bottom 5
                </button>
              </div>
            </div>

            <div id="bar" style="position: relative">
              <span style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
                v-if="this.profitBarSeries[0].data[0] === undefined">此時間段無資料</span>
              <apexchart type="bar" height="300" :options="profitBarOptions" :series="profitBarSeries"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-6 bar-div">
          <div class="d-flex justify-content-between">
            <span class="fs-small" style="color: #71afb6">TOP 5 門市成長率表現</span>
            <div class="title-button-div">
              <button class="button-nofocus fs-small" :class="Top5StoreGrowActive ? 'button-focus' : ''"
                @click="getStoreGrowTop5">
                Top 5
              </button>
              <button class="button-nofocus fs-small" :class="Bottom5StoreGrowActive ? 'button-focus' : ''"
                @click="getStoreGrowBottom5">
                Bottom 5
              </button>
            </div>
          </div>
          <div id="bar" style="position: relative">
            <span style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
              v-if="this.growBarSeries[0].data[0] === undefined">此時間段無資料</span>
            <apexchart type="bar" height="300" :options="growBarOptions" :series="growBarSeries"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";
import VueApexCharts from "vue3-apexcharts";
import { Calendar, DatePicker } from "v-calendar";
import moment from "moment";

export default {
  name: "StoreChannelSales",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      vue3Date: {
        start: "",
        end: "",
        // start: new Date("2022-01-06").toISOString().slice(0, 10),
        // end: new Date("2022-01-10").toISOString().slice(0, 10),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      endYear: 2022,
      endMonth: 6,

      dateSelector: "全部",

      //熱點圖data
      heatSeries: [
        {
          data: [],
        },
      ],
      heatOptions: {
        chart: {
          height: 350,
          type: "heatmap",
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          type: "category",
          categories: [],
        },
        colors: ["#79B4BA"],
      },

      //bardata

      apiMemberPie: [],
      apiLineStoreProfit: [],
      apiLineStoreGrow: [],
      apiHeatData: {},
      apiHeatDataMax: 0,

      Top5StoreProfitActive: true,
      Bottom5StoreProfitActive: false,
      Top5StoreGrowActive: true,
      Bottom5StoreGrowActive: false,

      selectedMemberPie: [],
      selectedStoreProfit: [],
      selectedStoreGrow: [],

      memberPieSeries: [],
      memberPieOptions: {},

      profitBarSeries: [
        {
          data: [],
        },
      ],
      profitBarOptions: {},
      growBarSeries: [
        {
          data: [],
        },
      ],
      growBarOptions: {},

      timeSwitch: "allTime",
      topOrBottomStoreProfit: "Top5",
      topOrBottomStoreGrow: "Top5",

      startDate: "2021-11-21",
      endDate: "2021-12-22",
      hasDataFirst: "",
      hasDataEnd: "",
      colorBR: ['#CEE3E6', '#B1CDD0', '#6EA9AF', '#649494', '#265655']
    };
  },

  // beforeMount() {
  //   this.getRangeWeek();
  // },

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();
    this.highlightVue3Date();

    // await this.postPieData();
    // await this.postProfitBarData();
    // await this.postHeatMapData();
    this.loading = false;
  },
  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeSalesGet(params)
        .then((res) => {
          console.log(res);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postPieData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      await allapi
        .operationChannelMemberateGet(params)
        .then((res) => {
          console.log("piemember", res);
          this.apiMemberPie = res.data.Data;

          // console.log("wer", this.apiMemberPie)
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postProfitBarData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationChannelStoreperformanceGet(params)
        .then((res) => {
          console.log("linestore", res);
          this.apiLineStoreProfit = res.data.Data.銷售額;
          this.apiLineStoreGrow = res.data.Data.成長率;
          console.log("profit", this.apiLineStoreProfit);
          console.log("grow", this.apiLineStoreGrow);
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postHeatMapData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .operationChannelHotsaletimeGet(params)
        .then((res) => {
          this.apiHeatData = res.data.Data.rate;
          this.apiHeatDataMax = res.data.Data.max
          console.log("heatmap1", res);
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    memberPie() {
      (this.memberPieSeries = [
        this.selectedMemberPie.會員,
        this.selectedMemberPie.非會員,
      ]),
        [
          (this.memberPieOptions = {
            chart: {
              height: 330,
              type: "donut",
            },
            colors: ["#63A7C8", "#F9C357"],
            labels: ["會員", "非會員"],
            legend: {
              position: "bottom",
            },
            responsive: [
              {
                // breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          }),
        ];
    },
    storeProfitBar() {
      if (this.selectedStoreProfit === undefined) {
        (this.profitBarSeries = [
          {
            data: [],
          },
        ]),
          (this.profitBarOptions = {
            chart: {
              type: "bar",
              height: 310,
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            colors: ["#71AFB6"],

            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: [],
              title: {
                text: "營業額",
              },
              labels: {
                style: {
                  fontSize: "12px",
                },
                formatter: function (x) {
                  return 'NT$' + x.toLocaleString();
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          });
      } else {
        (this.profitBarSeries = [
          {
            name: "營業額",
            data: this.selectedStoreProfit.值,
          },
        ]),
          (this.profitBarOptions = {
            chart: {
              type: "bar",
              height: 310,
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            colors: ["#71AFB6"],

            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: this.selectedStoreProfit.門市,
              title: {
                text: "營業額 ",
              },
              labels: {
                style: {
                  fontSize: "12px",
                },
                formatter: function (x) {
                  return 'NT$' + x.toLocaleString();
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
            tooltip: {
              y: {
                formatter: function (y) {
                  return y.toLocaleString();
                },
              },
            },
          });
      }
    },
    heatMapChart() {
      const vm = this;
      console.log('vm', vm)
      if (this.apiHeatData.Mon === undefined) {
        this.apiHeatData.Mon = [];
      }
      if (this.apiHeatData.Tue === undefined) {
        this.apiHeatData.Tue = [];
      }
      if (this.apiHeatData.Wed === undefined) {
        this.apiHeatData.Wed = [];
      }
      if (this.apiHeatData.Thu === undefined) {
        this.apiHeatData.Thu = [];
      }
      if (this.apiHeatData.Fri === undefined) {
        this.apiHeatData.Fri = [];
      }
      if (this.apiHeatData.Sat === undefined) {
        this.apiHeatData.Sat = [];
      }
      if (this.apiHeatData.Sun === undefined) {
        this.apiHeatData.Sun = [];
      }



      (this.heatSeries = [
        {
          name: "星期一",
          data: this.apiHeatData.Mon,
        },
        {
          name: "星期二",
          data: this.apiHeatData.Tue,
        },
        {
          name: "星期三",
          data: this.apiHeatData.Wed,
        },
        {
          name: "星期四",
          data: this.apiHeatData.Thu,
        },
        {
          name: "星期五",
          data: this.apiHeatData.Fri,
        },
        {
          name: "星期六",
          data: this.apiHeatData.Sat,
        },
        {
          name: "星期日",
          data: this.apiHeatData.Sun,
        },
      ]),
        (this.heatOptions = {
          chart: {
            height: 270,
            type: "heatmap",
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            type: "category",
            categories: [],
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "12px",
                cssClass:'staticYaxis',
              },
        
            },
          },
          reverseNegativeShade: true,
          // colors: ["#F9C357","#63A7C8"],
          plotOptions: {
            heatmap: {
              enableShades: false,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: this.apiHeatDataMax / 5,
                    color: "#CEE3E6",
                  },
                  {
                    from: this.apiHeatDataMax / 5,
                    to: this.apiHeatDataMax / 5 * 2,
                    color: "#B1CDD0",
                  },
                  {
                    from: this.apiHeatDataMax / 5 * 2,
                    to: this.apiHeatDataMax / 5 * 3,
                    color: "#6EA9AF",
                  },
                  {
                    from: this.apiHeatDataMax / 5 * 3,
                    to: this.apiHeatDataMax / 5 * 4,
                    color: "#649494",
                  },
                  {
                    from: this.apiHeatDataMax / 5 * 4,
                    to: this.apiHeatDataMax / 5 * 5,
                    color: "#265655",
                  },
                ],
              },

            },

          },
          legend: {
            markers: {
              width: 0,
              height: 0,
              strokeWidth: 0,
              customHTML: function () {
                return '<span style="display:none;margin:0px"><i class="fas fa-chart-pie"></i></span>'
              }
            },
            itemMargin: {
              horizontal: 0,
              vertical: 0
            },
      
            formatter: function (seriesName, opts) {
              console.log('seriesName', seriesName)
              console.log('opts', opts)
              return (
                "<div style='position:relative;  width:100%; overflow: hidden; left:0px;'>"+
                "<div style='display:flex; flex-direction:row;  width:100%; position:sticky; left:0px;'>" +
                   "<div style='margin:0px; width:100%'>" +
                   `<div style=background-color:${vm.colorBR[opts.seriesIndex]};color:transparent;margin:0px;width:100px;height:15px >` + seriesName + "</div>" +
                   "</div>" +
                "</div>"+
                "</div>"

              )
            }
          }
        });
    },

    storeGrowBar() {
      if (this.selectedStoreProfit === undefined) {
        (this.growBarSeries = [
          {
            data: [],
          },
        ]),
          (this.growBarOptions = {
            chart: {
              type: "bar",
              height: 310,
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            colors: ["#71AFB6"],

            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: [],
              title: {
                text: "成長率 (%)",
              },
              labels: {
                show: true,
                formatter: function (x) {
                  return x.toFixed(2) + '%';
                },
              }
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          });
      } else {
        (this.growBarSeries = [
          {
            name: "成長率 (%)",
            data: this.selectedStoreGrow.值,
          },
        ]),
          (this.growBarOptions = {
            chart: {
              type: "bar",
              height: 310,
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            colors: ["#71AFB6"],
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                colors: {
                  ranges: [
                    {
                      from: -100,
                      to: -46,
                      color: "#71AFB6",
                    },
                    {
                      from: -45,
                      to: 0,
                      color: "#FEB019",
                    },
                  ],
                },
                columnWidth: "80%",
              },
              yaxis: {
                labels: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: this.selectedStoreGrow.門市,
              title: {
                text: "成長率(%)",
              },
              labels: {
                show: true,
                formatter: function (x) {
                  return x.toFixed(2) + '%';
                },
              }

            },
          });
      }
    },

    getallTime() {
      this.selectedMemberPie = this.apiMemberPie;

      this.selectedStoreProfit =
        this.apiLineStoreProfit[this.topOrBottomStoreProfit];
      this.selectedStoreGrow = this.apiLineStoreGrow[this.topOrBottomStoreGrow];

      this.memberPie();
      this.storeProfitBar();
      this.storeGrowBar();
      this.heatMapChart();
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.postPieData(),
        this.postProfitBarData(),
        this.postHeatMapData(),
      ]);
      this.getallTime();
      this.loading = false;
      this.dateSelector = "全部";
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await Promise.all([
        this.postPieData(),
        this.postProfitBarData(),
        this.postHeatMapData(),
      ]);

      this.highlightVue3Date();
      this.getallTime();
      this.dateSelector = "最近一年";
      this.loading = false;
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      await Promise.all([
        this.postPieData(),
        this.postProfitBarData(),
        this.postHeatMapData(),
      ]);
      this.highlightVue3Date();
      this.getallTime();
      this.dateSelector = "最近30天";
      this.loading = false;
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      await Promise.all([
        this.postPieData(),
        this.postProfitBarData(),
        this.postHeatMapData(),
      ]);
      this.highlightVue3Date();
      this.getallTime();
      this.dateSelector = "最近7天";
      this.loading = false;
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      await Promise.all([
        this.postPieData(),
        this.postProfitBarData(),
        this.postHeatMapData(),
      ]);
      this.highlightVue3Date();
      this.getallTime();
      this.dateSelector = "最近1天";
      this.loading = false;
    },

    customDatePicker: async function () {
      this.loading = true;
      this.dateSelector = "自訂";
      this.startDate = this.vue3Date.start;
      this.endDate = this.vue3Date.end;
      await Promise.all([
        this.postPieData(),
        this.postProfitBarData(),
        this.postHeatMapData(),
      ]);

      this.getallTime();
      this.loading = false;
    },

    getStoreProfitTop5() {
      // console.log("1232")
      this.Top5StoreProfitActive = true;
      this.Bottom5StoreProfitActive = false;
      this.topOrBottomStoreProfit = "Top5";
      this.selectedStoreProfit = this.apiLineStoreProfit.Top5;
      this.storeProfitBar();
      // this.storeGrowBar();
    },
    getStoreProfitBottom5() {
      this.Top5StoreProfitActive = false;
      this.Bottom5StoreProfitActive = true;
      this.topOrBottomStoreProfit = "Bottom5";
      this.selectedStoreProfit = this.apiLineStoreProfit.Bottom5;
      this.storeProfitBar();
      // this.storeGrowBar();
    },
    getStoreGrowTop5() {
      this.Top5StoreGrowActive = true;
      this.Bottom5StoreGrowActive = false;
      // console.log("321")
      this.topOrBottomStoreGrow = "Top5";
      this.selectedStoreGrow = this.apiLineStoreGrow.Top5;

      this.storeGrowBar();
    },
    getStoreGrowBottom5() {
      // console.log("6437")
      this.Top5StoreGrowActive = false;
      this.Bottom5StoreGrowActive = true;
      this.topOrBottomStoreGrow = "Bottom5";
      this.selectedStoreGrow = this.apiLineStoreGrow.Bottom5;

      this.storeGrowBar();
    },
  },
};
</script>

<style scoped>
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 24px;
}

.home {
  padding: 50px;
}

.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}

.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}

.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}

.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 30px;
  height: 356px;
}

.heat-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 356px;
}

.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-right: 30px;
  margin-top: 30px;
  height: 356px;
}

.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}

.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}

.change-date-li:hover {
  background-color: #eaf3f4;
}

.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}

.heatmap-div {
  overflow-y: hidden;
  overflow-x: scroll;
}
.staticYaxis{
  position: sticky;
  left: 0;
  min-width: 200px;
}

@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .fs-small {
    font-size: 12px;
  }

  .fs-medium {
    font-size: 24px;
  }
}
</style>
