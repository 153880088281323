/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

var apigClientFactory = {};
apigClientFactory.newClient = function (config) {
    var apigClient = { };
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: 'ap-northeast-1',
apiKey: 'ble92Y0z8E8PjdDEvHjhQ3KIt05khYnpajVVaKEO',
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }

    
    // extract endpoint and path from url
    var invokeUrl = 'https://cqnt39vpxf.execute-api.ap-northeast-1.amazonaws.com/performance';
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);
    
    
    
    apigClient.emailOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventPieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var emailEventPieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventPieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventPieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailEventPieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventPieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventPurchaseGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var emailEventPurchaseGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventPurchaseGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventPurchaseOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailEventPurchaseOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventPurchaseOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var emailEventRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailEventRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var emailEventTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailEventTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var emailEventUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailEventUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailEventUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/event/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailEventUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualAballGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualAballGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/aball').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualAballGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualAballOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualAballOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/aball').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualAballOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualAbsendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualAbsendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/absend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualAbsendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualAbsendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualAbsendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/absend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualAbsendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualEventGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualEventGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualEventGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualPieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualPieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualPieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualPieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualPieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualPieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualPurchaseGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualPurchaseGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualPurchaseGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualPurchaseOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualPurchaseOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualPurchaseOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var emailIndividualUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailIndividualUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailIndividualUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/individual/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailIndividualUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOverallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallPeriodGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var emailOverallPeriodGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/period').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallPeriodGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallPeriodOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOverallPeriodOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/period').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallPeriodOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallPieGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var emailOverallPieGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallPieGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallPieOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOverallPieOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/pie').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallPieOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallPurchaseGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var emailOverallPurchaseGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallPurchaseGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallPurchaseOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOverallPurchaseOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallPurchaseOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var emailOverallRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOverallRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var emailOverallTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailOverallTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailOverallTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/overall/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailOverallTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailStructureGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var emailStructureGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['script_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailStructureGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailStructureOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailStructureOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailStructureOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailTableGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var emailTableGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/email/table').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailTableGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.emailTableOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var emailTableOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/email/table').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(emailTableOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookAdsetGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['end_time', 'Authorization', 'start_time'], ['body']);
        
        var facebookAdsetGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/adset').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['end_time', 'start_time']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookAdsetGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookAdsetOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookAdsetOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/adset').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookAdsetOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookCampaignGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['end_time', 'Authorization', 'start_time'], ['body']);
        
        var facebookCampaignGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/campaign').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['end_time', 'start_time']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookCampaignGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookCampaignOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookCampaignOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/campaign').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookCampaignOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookCompareGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var facebookCompareGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/compare').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookCompareGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookCompareOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookCompareOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/compare').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookCompareOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookCompareCampaignIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['campaign_id', 'Authorization'], ['body']);
        
        var facebookCompareCampaignIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/compare/{campaign_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['campaign_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookCompareCampaignIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookCompareCampaignIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookCompareCampaignIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/compare/{campaign_id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookCompareCampaignIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookTimeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var facebookTimeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookTimeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var facebookTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.facebookTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var facebookTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/facebook/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(facebookTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.interactGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['action', 'type', 'Authorization', 'ruleID'], ['body']);
        
        var interactGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/interact').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['action', 'type', 'ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(interactGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.interactOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var interactOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/interact').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(interactOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.interactExportGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['filename', 'action', 'type', 'Authorization', 'ruleID'], ['body']);
        
        var interactExportGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/interact/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['filename', 'action', 'type', 'ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(interactExportGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.interactExportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var interactExportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/interact/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(interactExportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallBarPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var overallBarPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/bar').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallBarPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallBarOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallBarOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/bar').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallBarOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallBar2Post = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var overallBar2PostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/bar2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallBar2PostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallBar2Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallBar2OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/bar2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallBar2OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallDeleteOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallDeleteOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/delete').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallDeleteOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallDeleteScriptIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var overallDeleteScriptIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/delete/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallDeleteScriptIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallDeleteScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallDeleteScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/delete/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallDeleteScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallTimePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var overallTimePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallTimePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallTotaldeliveryPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var overallTotaldeliveryPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/totaldelivery').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallTotaldeliveryPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallTotaldeliveryOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallTotaldeliveryOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/totaldelivery').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallTotaldeliveryOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallTotalperformancePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var overallTotalperformancePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/totalperformance').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallTotalperformancePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.overallTotalperformanceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var overallTotalperformanceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/overall/totalperformance').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(overallTotalperformanceOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsEventRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsEventRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsEventTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsEventTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsEventUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsEventUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsEventUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/event/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsEventUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsOverallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallPeriodGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsOverallPeriodGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall/period').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallPeriodGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallPeriodOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsOverallPeriodOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall/period').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallPeriodOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var smsOverallRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsOverallRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var smsOverallTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOverallTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsOverallTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/overall/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOverallTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptContentGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptContentGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/content').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptContentGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptContentOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptContentOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/content').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptContentOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptEventGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptEventGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptEventGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptStructureGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptStructureGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptStructureGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptStructureOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptStructureOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptStructureOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var smsScriptUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['ruleID']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/script/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTableGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTableGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/table').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTableGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTableOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTableOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/table').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTableOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptEmailOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var triggerScriptEmailRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptEmailRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var triggerScriptEmailTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptEmailTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var triggerScriptEmailUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptEmailUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptEmailUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/email/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptEmailUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptSmsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsRateGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var triggerScriptSmsRateGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsRateGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptSmsRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsTrendGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var triggerScriptSmsTrendGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsTrendGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsTrendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptSmsTrendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms/trend').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsTrendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['vice_ruleID', 'Authorization'], ['body']);
        
        var triggerScriptSmsUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['vice_ruleID', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptSmsUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptSmsUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/sms/url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptSmsUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptVersionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptVersionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/version').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptVersionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptVersionScriptIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptVersionScriptIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/version/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptVersionScriptIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptVersionScriptIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptVersionScriptIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/version/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptVersionScriptIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptVersionScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptVersionScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/version/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptVersionScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptScriptIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    return apigClient;
};
export var allapi3 = apigClientFactory.newClient();