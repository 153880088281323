<template>
  <div v-if="!laurel" class="d-flex justify-content-center align-items-center">
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              is-expanded
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <span class="title-span fs-medium"> 網站行為分析 </span>

        <div class="d-flex justify-content-end">
          <div class="title-button-div">
            <button
              class="button-nofocus"
              :class="whichWeb === web ? 'button-focus' : ''"
              @click="changeWeb(web)"
              v-for="web in websites"
              :key="web"
            >
              {{ web }}
            </button>
          </div>
        </div>
      </div>

      <div class="dropdown" style="border-radius:10px;border:1px solid #c4c4c4;width: 20.8vw;">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span> {{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <!-- <li class="change-date-li">
                        <button class="change-date-button" @click="getPass14Days">
                            最近14天
                        </button>
                    </li> -->
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>

          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-4">
        <div
          class="d-flex justify-content-between number-div align-items-center"
        >
          <span class="number-label">使用者流量 </span>
          <span v-if="this.userFlow === '0'">- - (次)</span>
          <span class="number-amount" v-else>{{ this.userFlow }}(次) </span>
        </div>
        <div
          class="d-flex justify-content-between number-div align-items-center"
        >
          <span class="number-label">網站平均停留時間 </span>
          <span v-if="this.webSwtich2.停留時間[0] === 0">- - (次)</span>
          <span class="number-amount" v-else
            >{{ webSwtich2.停留時間[0] }}(分)
          </span>
        </div>
        <div
          class="d-flex justify-content-between number-div align-items-center"
        >
          <span class="number-label">網站平均跳出率 </span>
          <span v-if="this.webSwtich2.bounce[0] === 0">- - (次)</span>
          <span class="number-amount" v-else
            >{{ webSwtich2.bounce[0] }}(%)
          </span>
        </div>
      </div>
      <div class="col-8 ps-4">
        <div class="line-1row-div" style="position: relative">
          <div
            class="d-flex justify-content-between mb-2"
            style="padding-top: 20px"
          >
            <span class="number-label">{{ lineTitle }} </span>
            <div class="title-button-div">
              <button
                class="button-nofocus"
                :class="lineAmount ? 'button-focus' : ''"
                @click="lineAmountClick()"
              >
                流量
              </button>
              <button
                class="button-nofocus"
                :class="lineTime ? 'button-focus' : ''"
                @click="lineTimeClick()"
              >
                停留
              </button>
              <button
                class="button-nofocus"
                :class="lineExit ? 'button-focus' : ''"
                @click="lineExitClick()"
              >
                跳出
              </button>
            </div>
          </div>
          <span
            style="position: absolute; top: 50%; left: 40%; color: #c4c4c4"
            v-if="this.seriesLine[0].data[0] === undefined"
            >此時間段無資料</span
          >
          <apexchart
            type="line"
            height="190"
            :options="lineOptions"
            :series="seriesLine"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-4">
        <div
          class="
            website-2row-div-1
            d-flex
            flex-column
            justify-content-between
            px-3
          "
        >
          <span class="number-label p-1 pt-2">網站訪客人數 </span><br />

          <span class="number-amount d-flex justify-content-between px-1 pb-2">
            <span v-if="this.visitMember === 0">- -</span>
            <span v-else>{{ this.visitMember.toLocaleString() }}</span>
            <span>(人)</span>
          </span>
          <!-- <apexchart type="donut" height="235" :options="PieChartOptions" :series="pieSeries"></apexchart> -->
        </div>
        <div
          class="website-2row-div-1 d-flex flex-column justify-content-between"
        >
          <p class="m-0">
            <span class="number-label p-1 pt-2">網站新註冊會員 </span>
            <button class="btn btn-default button px-0 mx-0 pt-0">
               <span>
                  <img
                    src="../assets/question-img.svg"
                    style="width: 20px; margin-bottom: 2px"
                    @mouseover="CustomShow = true"
                    @mouseout="CustomShow = false"
                /></span>
              <div class="parent-popout">
                <div
                  v-show="CustomShow"
                  class="child-popout-inWebsiteAnalysis-first"
                >
                  此數值為GA所統計資料。
                  <div class="container__arrow"></div>
                </div>
              </div>
            </button>
          </p>
          <br />
          <span class="number-amount d-flex justify-content-between px-1 pb-2">
            <span v-if="this.newMember === null">- -</span>
            <span v-else>{{ this.newMember.toLocaleString() }}</span>
            <span>(人)</span>
          </span>
          <!-- <apexchart type="donut" height="235" :options="PieChartOptions" :series="pieSeries"></apexchart> -->
        </div>
      </div>
      <div class="col-8 ps-4">
        <div class="website-2row-div" style="padding-top: 20px">
          <span class="number-label p-1 pt-2">訪客註冊漏斗 </span>

          <div class="d-flex flex-column justify-content-center px-3">
            <div class="d-flex align-items-center" style="margin-top: 38px">
              <div class="col-2">
                <span style="font-size: 16px">瀏覽頁面</span>
              </div>
              <div
                class="col-10 1 d-flex justify-content-center"
                style="position: relative"
              >
                <div
                  class="color-bar d-flex flex-column justify-content-center"
                  id="color-bar5"
                  style="background-color: #9dc9da"
                ></div>
                <p
                  class="text-center mb-0 mx-auto"
                  style="
                    color: #575757;
                    font-size: 12px;
                    white-space: nowrap;
                    position: absolute;
                    height: 42px;
                    line-height: 42px;
                  "
                  v-if="this.colorBarData[this.selectedColorBar] != undefined"
                >
                  {{
                    this.colorBarData[
                      this.selectedColorBar
                    ].landing_count.toLocaleString()
                  }}人 ({{
                    this.colorBarData[this.selectedColorBar].landing_count_rate
                  }}%)
                </p>
              </div>
            </div>
            <div class="d-flex align-items-center" style="margin-top: 38px">
              <div class="col-2">
                <span style="font-size: 16px">註冊頁面</span>
              </div>
              <div
                class="col-10 d-flex justify-content-center"
                style="position: relative"
              >
                <div
                  class="color-bar d-flex flex-column justify-content-center"
                  id="color-bar6"
                  style="background-color: #85a9cd"
                ></div>
                <p
                  class="text-center mb-0 mx-auto"
                  style="
                    color: #575757;
                    font-size: 12px;
                    white-space: nowrap;
                    position: absolute;
                    height: 42px;
                    line-height: 42px;
                  "
                  v-if="this.colorBarData[this.selectedColorBar] != undefined"
                >
                  {{
                    this.colorBarData[
                      this.selectedColorBar
                    ].landing_user_count.toLocaleString()
                  }}人({{
                    this.colorBarData[this.selectedColorBar]
                      .landing_user_count_rate
                  }}%)
                </p>
              </div>
            </div>

            <div class="d-flex align-items-center" style="margin-top: 38px">
              <div class="col-2">
                <span style="font-size: 16px">成為會員</span>
              </div>
              <div
                class="col-10 d-flex justify-content-center"
                style="position: relative"
              >
                <div
                  class="color-bar d-flex flex-column justify-content-center"
                  id="color-bar8"
                  style="background-color: #d197cc"
                ></div>
                <p
                  class="text-center mb-0 mx-auto"
                  style="
                    color: #575757;
                    font-size: 12px;
                    white-space: nowrap;
                    position: absolute;
                    height: 42px;
                    line-height: 42px;
                  "
                  v-if="this.colorBarData[this.selectedColorBar] != undefined"
                >
                  {{
                    this.colorBarData[
                      this.selectedColorBar
                    ].register_count.toLocaleString()
                  }}人({{
                    this.colorBarData[this.selectedColorBar]
                      .register_count_rate
                  }}%)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-12">
        <div class="website-3row-div" style="position: relative; height: 320px">
          <span class="number-label p-1 pt-2">
            訪客人數走勢圖 X 新註冊人數
          </span>
          <span
            style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
            v-if="this.DoubleLineSeries[0].data[0] === undefined"
            >此時間段無資料</span
          >
          <apexchart
            type="line"
            height="270"
            :options="DoubleLineOptions"
            :series="DoubleLineSeries"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-12">
        <div class="website-3row-div">
          <div class="d-flex justify-content-between">
            <div>
              <span class="number-label p-1 pt-2"
                >前後頁路徑點擊
                <button class="btn btn-default button px-0 mx-0 pt-0 mb-1">
                  <span>
                  <img
                    src="../assets/question-img.svg"
                    style="width: 20px; "
                    @mouseover="isShow = true"
                    @mouseout="isShow = false"
                /></span>
                  <div class="parent-popout">
                    <div v-show="isShow" class="child-popout-WebsiteAnalysis">
                      <div class="child-popout-WebsiteAnalysis-top"></div>
                      <div class="p-3">
                        此模組協助您輕鬆查看網站的每個分頁之下，被點擊次數與人數，以及頁面被總造訪次數。就如同網站一樣，您可透過每個路徑持續進入到下個頁面。上方標題為當前頁面，表格內容為該頁面底下的不同路徑，您亦可透過右上角的搜尋工具快速查找想去的頁面。
                        <img src="../assets/exproute.svg" alt="" class="mt-4" />
                      </div>
                      <div
                        class="container__arrow"
                        style="border-top-color: white; left: -0.5%"
                      ></div>
                    </div>
                  </div>
                </button>
              </span>

              <span
                style="color: #0978fb; cursor: pointer"
                @click="getPathTable('')"
                >初始頁</span
              >
              <span style="color: #0978fb"
                >/ {{ path }}
                <span
                  style="font-size: 12px; color: black"
                  v-show="this.path != ''"
                  >(當前頁面)</span
                >
              </span>
            </div>

            <div
              class=""
              id="search-div"
              style="position: relative"
              ref="target"
            >
              <img
                class="searchImg"
                style="width: 17px"
                src="../assets/search-black.png"
                alt="searchImg"
              />
              <input
                @focus="this.autoShow = true"
                placeholder="搜尋名稱"
                class="search-input"
                style="
                  width: 300px;
                  border: 1px solid #575757;
                  background-color: white;
                  border-radius: 10px;
                "
                v-model="searchInput"
              />
              <div
                class="auto-complicate-div"
                style="
                  background-color: white;
                  position: absolute;
                  right: 0;
                  z-index: 5;
                  width: 300px;
                "
                v-if="searchList[0] != undefined && this.autoShow"
              >
                <p
                  class="px-2 py-1 m-0 auto-complicate-p"
                  style="cursor: pointer; color: #575757"
                  v-for="item in searchList"
                  :key="item"
                  @click="
                    getPathTable(item);
                    this.path = item;
                  "
                >
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
          <div style="height: 390px; position: relative" class="mt-4 font-14">
            <span
              style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
              v-if="this.selectedTable[0] === undefined"
              >此時間段無資料</span
            >
            <DataTable
              :value="selectedTable"
              :scrollable="true"
              scrollHeight="400px"
              id="selectedTable_Bearer"
              showGridlines
            >
              <Column field="title" :sortable="false" style="max-width:395px; justify-content: flex-start;">
                <template #header>
                  <div style="padding-left: 5px;">
                    <span>後頁名稱</span>
                  </div>
                </template>
                <template #body="slotProps">
                  <div
                    style="padding-left: 5px;" 
                    v-tooltip.bottom="slotProps.data.title?.length > 12 ? { value: slotProps.data.title, class: 'tip-LabelManage'} : { value: '', class: ''}"
                  >
                    <span
                      class="font-14 title"
                      style="
                        cursor: pointer;
                        color: #0978fb;
                      "
                      @click="getPathTable(slotProps.data.title)"
                      v-if="slotProps.data.state === true"
                    >
                      {{ slotProps.data.title }}
                    </span>
                    <span
                      class="font-14 title"
                      style="
                        color: black;
                      "
                      v-else
                    >
                      {{ slotProps.data.title }}
                    </span>
                  </div>
                </template>
              </Column>

              <Column
                field="search_count"
                header="次數"
                :sortable="true"
              >
                <template #body="slotProps">
                  <div>
                    {{ slotProps.data.search_count.toLocaleString() }}
                  </div>
                </template>
              </Column>

              <Column
                field="user_count"
                header="人數"
                :sortable="true"
              >
                <template #body="slotProps">
                  <div>
                    {{ slotProps.data.user_count.toLocaleString() }}
                  </div>
                </template>
              </Column>

              <Column
                field="view_count"
                header="後頁面總造訪次數"
                :sortable="true"
                v-if="selectedTable?.[0]?.view_count"
              >
                <template #body="slotProps">
                  <div>
                    {{ slotProps.data.view_count.toLocaleString() }}
                  </div>
                </template>
              </Column>

              <Column
                field="per"
                header="點擊次數占總造訪次數比例"
                :sortable="true"
                v-if="selectedTable?.[0]?.per"
              >
                <template #body="slotProps">
                  <div>
                    {{ slotProps.data.per }}%
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="col-9">
        <div class="website-3row-div" style="position: relative">
          <div class="d-flex">
            <span class="number-label p-1 pt-2 pe-3"> TOP10 點擊計數</span>
            <Dropdown
              style="width: 250px"
              :valueArr="this.selecteddropdown"
              :selectValue="top10PageSelectorIndex"
              :changingSelected="'top10PageSelector'"
              v-on:selectValueByDrop="selectValueByDrop"
            />
          </div>
          <span
            style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
            v-if="this.seriesBar2[0].data[0] === undefined"
            >此時間段無資料</span
          >
          <div class="pe-4">
            <apexchart
              type="bar"
              height="420"
              :options="chartOptionsBar2"
              :series="seriesBar2"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-3 ps-4">
        <div class="website-3row-div p-0">
          <p class="number-label p-3 mb-0">
            熱門搜尋字
            <img src="../assets/search-green.png" alt="" class="ms-2 mb-1" />
          </p>
          <hr
            style="
              width: 100%;
              margin-top: 0px;
              opacity: 1;
              height: 0;
              border: 0;
              border-bottom: 1px solid #c4c4c4;
            "
          />
          <div class="row overflow-search" style="width:103% ; padding:0 5px">
            <div class="col-12">
              <span
                style="color: #c4c4c4"
                v-if="this.selectedTop10.keyword[0] === undefined"
                >此時間段無資料</span
              >
              <ol v-else>
                <li
                  class="p-1"
                  style="margin-bottom: 1px"
                  v-for="(item, index) in selectedTop10.keyword"
                  :key="item"
                >
                  <div class="row justify-content-between">
                    <span class="col-10">{{ item }}</span>
                    <span class="text-end col-2">
                      {{ selectedTop10.count[index] }}</span
                    >
                  </div>
                </li>
              </ol>
            </div>
            <!-- <div class="col-3">
              <ul style="list-style: none;">
                <li class="p-2" v-for="item in selectedTop10.count" :key="item">
                  <div class="d-flex justify-content-between">
                    <span>{{ item }}</span>

                  </div>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-4">
        <div class="pie-div-lastrow" style="position: relative">
          <span class="number-label p-1 pt-2">裝置來源</span>
          <span
            class="text-center"
            style="position: absolute; top: 24%; left: 24%; color: #c4c4c4"
            v-if="this.pieSeries.every((d) => d === 0)"
          >
            <img src="../assets/missData.svg" alt="" style="width: 200px" />
            <br />
            此時間段無資料</span
          >
          <apexchart
            type="donut"
            height="235"
            :options="PieChartOptions"
            :series="pieSeries"
          ></apexchart>
        </div>
      </div>
      <div class="col-8 ps-4">
        <div class="pie-div-lastrow px-3" style="position: relative">
          <span class="number-label p-1 pt-2">會員登入方式</span>
          <span
            style="position: absolute; top: 40%; left: 45%; color: #c4c4c4"
            v-if="this.seriesBar1[0].data[0] === undefined"
            >此時間段無資料</span
          >
          <apexchart
            type="bar"
            height="235"
            :options="chartOptionsBar1"
            :series="seriesBar1"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi } from "../../public/static/api/apigClient.js";
import Dropdown from "../components/Dropdown.vue";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  name: "WebsiteAnalysis",
  components: {
    apexchart: VueApexCharts,
    DataTable,
    Column,
    Dropdown,
    Calendar,
    DatePicker,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return {
      target,
      autoShow,
    };
  },
  data() {
    return {
      loading: false,
      CustomShow: false,
      laurel: false,
      isShow: false,
      searchInput: "",
      vue3Date: {
        start: "",
        end: "",
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      dateSelector: "全部",
      startDate: "2021-11-01",
      endDate: "2021-12-22",
      hasDataFirst: "",
      hasDataEnd: "",
      endYear: 2022,
      endMonth: 6,

      websites: [],
      whichWeb: "",

      lineAmount: true,
      lineTime: false,
      lineExit: false,

      lineSwtich: "流量",
      lineTitle: "使用者流量",

      webSwtich: "",
      webSwtich2: {
        人次: [],
        停留時間: [],
        bounce: [],
        瀏覽人數: [0],
      },

      userFlow: "",
      totalMember: 0,
      newMember: 0,
      visitMember:0,

      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      selectedTable: [],
      selectedTop10: {
        keyword: [],
      },
      selectedLine: {},
      selectedTop10Bar: "",

      pieSeries: [],
      PieChartOptions: {
        // responsive: [
        //   {
        //     // breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      },

      seriesBar1: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      chartOptionsBar1: {},

      seriesBar2: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptionsBar2: {},

      seriesLine: [
        {
          name: "",
          data: [],
        },
      ],

      lineOptions: {},

      DoubleLineSeries: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],

      DoubleLineOptions: {},

      webMemberData: [],
      web11Data: [],

      webSessionData: [],
      web22Data: [],

      DoubleLineData1: {},

      DoubleLineData2: {},

      selectedDoubleLineData: {},

      lineWeb1Data: {
        流量: {
          date: [],
          value: [200, 514, 517, 505, 544, 537, 537, 295, 23],
        },
        停留: {
          date: [],
          value: [300, 114, 317, 205, 844, 937, 137, 95, 243],
        },
        跳出: {
          date: [],
          value: [700, 914, 117, 25, 144, 37, 637, 195, 843],
        },
      },
      selected3TabsData: {},

      pieWeb1Data: [60, 40],
      pieWeb2Data: [80, 20],
      selectedPieData: [],

      barWeb1Data: [10, 10, 30, 40],
      barWeb2Data: [90, 40, 70, 20],

      barTop10Web1Data: {
        homepage: [10, 10, 30, 40, 70, 90, 10, 10, 20, 50],
        banner: [70, 30, 10, 90, 10, 40, 20, 70, 10, 50],
        menu: [20, 30, 50, 40, 10, 90, 20, 60, 90, 50],
        社群連結: [90, 10, 80, 40, 30, 10, 20, 30, 50, 50],
        內容文章1: [40, 20, 70, 20, 10, 30, 60, 90, 20, 10],
        內容文章2: [10, 30, 50, 10, 67, 10, 30, 70, 10, 50],
      },

      barTop10Web2Data: {
        homepage: [20, 30, 50, 40, 10, 90, 20, 60, 90, 50],
        banner: [40, 20, 10, 30, 0, 10, 50, 90, 10, 10],
        menu: [10, 10, 30, 40, 70, 90, 10, 10, 20, 50],
        社群連結: [10, 20, 40, 30, 10, 30, 10, 50, 70, 10],
        內容文章1: [80, 80, 90, 60, 20, 40, 50, 0, 70, 10],
        內容文章2: [80, 90, 10, 40, 88, 10, 30, 20, 90, 100],
      },
      selectedBarTop10Data: {},

      tableWeb1Data: [
        {
          name: "商品分類",
          count: "123,456",
          people: "567,890",
        },
        {
          name: "多包組優惠方案",
          count: "123,456",
          people: "567,890",
        },
        {
          name: "長期訂購方案",
          count: "123,456",
          people: "567,890",
        },
      ],

      tableWeb2Data: [
        {
          name: "媽媽節日",
          count: "311,456",
          people: "527,890",
        },
        {
          name: "520有害",
          count: "723,456",
          people: "967,890",
        },
        {
          name: "端午節粽子特價",
          count: "423,456",
          people: "877,810",
        },
      ],

      top10SearchWeb1: [
        {
          name: "Honda",
          value: 100,
        },
        {
          name: "Toyota",
          value: 90,
        },
        {
          name: "BMW",
          value: 80,
        },
        {
          name: "Volvo",
          value: 70,
        },
        {
          name: "Audi",
          value: 60,
        },
        {
          name: "Porsche",
          value: 50,
        },
        {
          name: "VolksWagen",
          value: 40,
        },
        {
          name: "Nissan",
          value: 30,
        },
        {
          name: "Hyundai",
          value: 20,
        },
        {
          name: "Subaru",
          value: 10,
        },
      ],
      top10SearchWeb2: [],

      top10PageSelector: "homepage",
      top10PageSelectorIndex: "首頁",

      top10PurchaseSelector: "商品",

      colorBarData: {
        桂冠官網: {
          landing_count: 0,
          landing_count_rate: 0,
          landing_user_count: 0,
          landing_user_count_rate: 0,
          register_count: 0,
          register_count_rate: 0,
        },
        colorBarData2: {
          landing_count: 0,
          landing_count_rate: 0,
          landing_user_count: 0,
          landing_user_count_rate: 0,
          register_count: 0,
          register_count_rate: 0,
        },
      },

      selectedColorBar: "",
      website1BarName: "",
      website2BarName: "",
      line3TabsCounts: [],
      line3TabsLabels: [],
      DoubleLineCounts: [],
      DoubleLineSelectorDate: [],
      pieCounts: [],
      pieLabels: [],
      barCounts: [],
      barLabels: [],
      top10MediumBarCounts: [],
      top10MediumBarLabels: [],

      path: "",
      canfindWeb1table: [],
      canfindWeb2table: [],
      searchList: [],
      dropdownObj: {},
      dropdownObj2: {},
      selecteddropdown: {},
    };
  },
  watch: {
    top10PageSelector() {
      console.log("selector", this.top10PageSelector);
      // this.seriesBar2[0].data = this.barTop10Web1Data[this.top10PageSelector];
      // this.seriesBar2[0].data = this.barTop10Web2Data[this.top10PageSelector];
    },
    searchInput: function (val) {
      console.log(val);
      if (val != "") {
        this.searchList = this.canfindWeb1table[this.whichWeb].filter((d) =>
          d.includes(val)
        );
        this.autoShow = true;
      } else {
        this.searchList = [];
      }
    },
  },

  created() {
    //  this.DoubleLineChart()
    //   this.pieChartFunctions()
    // this.pieChart()
    //    this.selectedTable = this.tableWeb1Data
  },
  mounted: async function () {
    await this.getTime();
    await this.getBehaviorSession();
    this.whichWeb = this.websites[0];

    if (this.laurel) {
      await this.getRecentMonth();
      this.getFullTable();
      this.loading = false;
    }
    this.highlightVue3Date();
  },

  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    selectValueByDrop(selectValue, changingSelected, index) {
      this[changingSelected] = selectValue;
      this.top10PageSelectorIndex = index;
      console.log("slesltval", selectValue);
      this.top10MediumBarCounts =
        this.selectedBarTop10Data[selectValue].search_count;
      this.top10MediumBarLabels = this.selectedBarTop10Data[selectValue].title;
      console.log("slesltval333", this.selectedBarTop10Data[selectValue].title);
      console.log(
        "slesltval44",
        this.selectedBarTop10Data[selectValue].search_count
      );
      this.Top10MediumBar();
    },

    getFullTable: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };
      await allapi
        .onlineBehaviorProductSearchGet(params)
        .then((res) => {
          console.log("FullTable路徑", res);

          this.canfindWeb1table = res.data;
          console.log(this.canfindWeb1table);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeOnlineGet(params)
        .then((res) => {
          console.log(res.data.Data);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
          if (res.data.Data.start_date === null) {
            this.laurel = false;
          } else {
            this.laurel = true;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log("start", this.startDate);
      console.log("this.hasDataEnd", this.hasDataEnd);
      this.dateSelector = "最近1天";
      await Promise.all([
        this.getBehaviorWord(),
        this.getBehaviorSession(),
        this.getBehaviorMember(),
        this.getBehaviorRegister(),
        this.getBehaviorRegisterLine(),
        this.getBehaviorDevice(),
        this.getBehaviorSessionLine(),
        this.getBehaviorOther(),
        this.getPathTable(),
      ]);
      this.lineChart3Tabs();
      this.DoubleLineChart();
      this.pieChartFunctions();
      this.Top10MediumBar();
      this.changeWeb();
      this.highlightVue3Date();

      //   this.loading = false;
      this.loading = false;
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近7天";

      console.log("start", this.startDate);
      console.log("end", this.endDate);

      await Promise.all([
        this.getBehaviorWord(),
        this.getBehaviorSession(),
        this.getBehaviorMember(),
        this.getBehaviorRegister(),
        this.getBehaviorRegisterLine(),
        this.getBehaviorDevice(),
        this.getBehaviorSessionLine(),
        this.getBehaviorOther(),
        this.getPathTable(),
      ]);
      this.lineChart3Tabs();
      this.DoubleLineChart();
      this.pieChartFunctions();
      this.Top10MediumBar();
      this.changeWeb();
      this.highlightVue3Date();
      this.loading = false;
    },
    getRecentMonth: async function () {
      //   this.loading = true;
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      console.log("start", this.startDate);
      console.log("end", this.endDate);
      this.dateSelector = "最近30天";

      await Promise.all([
        this.getBehaviorWord(),
        this.getBehaviorSession(),
        this.getBehaviorMember(),
        this.getBehaviorRegister(),
        this.getBehaviorRegisterLine(),
        this.getBehaviorDevice(),
        this.getBehaviorSessionLine(),
        this.getBehaviorOther(),
        this.getPathTable(),
      ]);
      this.lineChart3Tabs();
      this.DoubleLineChart();
      this.pieChartFunctions();
      this.Top10MediumBar();

      this.changeWeb();
      this.highlightVue3Date();
      this.loading = false;
    },
    getRecentYear: async function () {
      //   this.loading = true;
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近一年";
      console.log("start", this.startDate);
      console.log("end", this.endDate);

      await Promise.all([
        this.getBehaviorWord(),
        this.getBehaviorSession(),
        this.getBehaviorMember(),
        this.getBehaviorRegister(),
        this.getBehaviorRegisterLine(),
        this.getBehaviorDevice(),
        this.getBehaviorSessionLine(),
        this.getBehaviorOther(),
        this.getPathTable(),
      ]);
      this.lineChart3Tabs();
      this.DoubleLineChart();
      this.pieChartFunctions();
      this.Top10MediumBar();
      this.changeWeb();
      this.highlightVue3Date();
      this.loading = false;
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      await Promise.all([
        this.getBehaviorWord(),
        this.getBehaviorSession(),
        this.getBehaviorMember(),
        this.getBehaviorRegister(),
        this.getBehaviorRegisterLine(),
        this.getBehaviorDevice(),
        this.getBehaviorSessionLine(),
        this.getBehaviorOther(),
        this.getPathTable(),
      ]);
      this.lineChart3Tabs();
      this.DoubleLineChart();
      this.pieChartFunctions();
      this.Top10MediumBar();

      this.dateSelector = "全部";
      this.changeWeb();
      this.highlightVue3Date();
      this.loading = false;
    },

    customDatePicker: async function () {
      //   this.loading = true;
      this.startDate = this.vue3Date.start;
      this.endDate = this.vue3Date.end;
      this.dateSelector = "自訂";
      //   await Promise.all([
      //     this.getBehaviorAll(),
      //     this.getBehaviorAll2(),
      //     this.getBehaviorAllPie(),
      //     this.getBehaviorLinechart(),
      //   ]);
      //   this.loading = false;
      await Promise.all([
        this.getBehaviorWord(),
        this.getBehaviorSession(),
        this.getBehaviorMember(),
        this.getBehaviorRegister(),
        this.getBehaviorRegisterLine(),
        this.getBehaviorDevice(),
        this.getBehaviorSessionLine(),
        this.getBehaviorOther(),
        this.getPathTable(),
      ]);
      this.lineChart3Tabs();
      this.DoubleLineChart();
      this.pieChartFunctions();
      this.Top10MediumBar();
      this.changeWeb();
      this.loading = false;
    },

    lineChart3Tabs() {
      let labelY = "";
      let unit = "";
      if (this.lineSwtich === "流量") {
        labelY = "流量";
        unit = "次";
      } else if (this.lineSwtich === "停留") {
        labelY = "停留時間";
        unit = "分";
      } else {
        labelY = "跳出率";
        unit = "%";
      }
      (this.seriesLine = [
        {
          name: "人次",
          data: this.line3TabsCounts,
        },
      ]),
        (this.lineOptions = {
          chart: {
            height: 190,
            type: "line",
            dropShadow: {
              enabled: false,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            tooltip: {
              enabled: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#F9C357"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          title: {
            align: "left",
            style: {
              fontSize: "20px",
              fontWeight: "medium",
              color: "#71AFB6",
            },
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            categories: this.line3TabsLabels,
            tickAmount: 7,
          },
          yaxis: {
            title: {
              text: labelY,
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString() + unit;
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            offsetY: 6,
            offsetX: -5,
          },
        });
    },

    DoubleLineChart() {
      (this.DoubleLineSeries = [
        {
          name: "訪客數",
          data: this.selectedDoubleLineData.landing_count,
        },
        {
          name: "註冊數",
          data: this.selectedDoubleLineData.register_count,
        },
      ]),
        (this.DoubleLineOptions = {
          chart: {
            height: 270,
            type: "line",
            dropShadow: {
              enabled: false,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#F9C357"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          title: {
            align: "left",
            style: {
              fontSize: "20px",
              fontWeight: "medium",
              color: "#71AFB6",
            },
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: "category",
            categories: this.DoubleLineSelectorDate,
            // tickAmount: 7,
            labels: {
              show: true,
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            title: {
              text: "人數",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            offsetY: 6,
            offsetX: -5,
          },
        });
    },
    pieChartFunctions() {
      (this.pieSeries = this.pieCounts),
        (this.PieChartOptions = {
          chart: {
            type: "donut",
            height: 230,
          },
          colors: ["#D197CC", "#96C5D7", "#FC9F66", "#F9C357"],
          labels: this.pieLabels,
          // dataLabels:{
          //   formatter:function(y){
          //      return y.toLocaleString();
          //   }
          // },
          // plotOptions:{
          // value:{
          //     formatter:function(y){
          //       return y.toLocaleString();
          //     }
          // },
          // },
          legend: {
            position: "bottom",
          },
        });
    },
    BottomBar() {
      (this.seriesBar1 = [
        {
          name: "",
          data: this.barCounts,
        },
      ]),
        (this.chartOptionsBar1 = {
          chart: {
            type: "bar",
            height: 210,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          colors: ["#71AFB6"],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: this.barLabels,
            title: {
              text: "登入次數",
            },
          },
          yaxis: {
            title: {
              text: "",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },
        });
    },
    Top10MediumBar() {
      (this.seriesBar2 = [
        {
          name: "",
          data: this.top10MediumBarCounts,
        },
      ]),
        (this.chartOptionsBar2 = {
          chart: {
            type: "bar",
            height: 420,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          colors: ["#71AFB6"],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: this.top10MediumBarLabels,
            title: {
              text: "點擊次數",
            },
          },
          yaxis: {
            title: {
              text: "",
            },
            labels: {
              minWidth: 90,
              style: {
                fontSize: "12px",
              },
            },
          },
          tooltip: {
            x: {
              formatter: (seriesName) => {
                const addClass = document.getElementsByClassName(
                  "apexcharts-tooltip apexcharts-theme-light"
                );
                if (seriesName.includes("http")) {
                  addClass[2].classList.add("newLeft");
                  return '<img style="width:500px" src=' + seriesName + ">";
                } else {
                  addClass[2].classList.remove("newLeft");
                  return seriesName;
                }
              },
            },
            y: {
              formatter: (y) => {
                return "點擊次數: " + y;
              },
            },
          },
        });
    },

    getBehaviorSession: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorSessionGet(params)
        .then((res) => {
          console.log("BehaviorSession", res);
          this.websites = Object.keys(res.data);
          this.webSessionData = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getBehaviorWord: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };
      await allapi
        .onlineBehaviorWordGet(params)
        .then((res) => {
          console.log("top10search", res);
          this.top10SearchWeb1 = res.data;
          // console.log("seetop10", this.top10SearchWeb1)
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getBehaviorMember: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorMemberGet(params)
        .then((res) => {
          console.log("BehaviorMember", res);
          this.webMemberData = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getBehaviorRegister: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorRegisterGet(params)
        .then((res) => {
          console.log("訪客註冊漏斗", res);
          this.colorBarData = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getBehaviorRegisterLine: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorRegisterLineGet(params)
        .then((res) => {
          console.log("ssee", res);
          this.DoubleLineData1 = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getBehaviorDevice: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorDeviceGet(params)
        .then((res) => {
          console.log("device", res);
          this.pieWeb1Data = res.data;
          // this.barWeb1Data = res.daya[keys]
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getBehaviorSessionLine: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorSessionLineGet(params)
        .then((res) => {
          console.log("3tabsline", res);

          this.lineWeb1Data = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getBehaviorOther: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
      };

      await allapi
        .onlineBehaviorOtherGet(params)
        .then((res) => {
          console.log("other", res);

          this.barTop10Web1Data = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getPathTable: async function (path) {
      console.log("path", path);
      if (path === undefined) {
        this.path = "";
      } else {
        this.path = path;
      }
      console.log("this.path", this.path);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate, //2022-11-19 this.startDate
        end_date: this.endDate, //2022-12-20 this.endDate,
        item: this.path,
      };

      await allapi
        .onlineBehaviorProductGet(params)
        .then((res) => {
          console.log("path table", res);

          this.tableWeb1Data = res.data[this.whichWeb];
          this.selectedTable = this.tableWeb1Data;

          // this.barWeb1Data = res.daya[keys]
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    // test(){
    //   console.log('-'-'123')
    //   console.log('123'-'-')
    //   console.log('1,000'<'1,230')
    //   console.log('1,000'<'1,230')
    //   console.log('1,000'<'100,230')
    // },

    changeWeb(web) {
      if (web != undefined) {
        this.whichWeb = web;
      }
      this.lineAmount = true;
      this.lineTime = false;
      this.lineExit = false;

      console.log("this.whichWeb", this.whichWeb);
      this.webSwtich = this.webMemberData.find((d) => d.type === this.whichWeb);
      console.log("this.webSwtich", this.webSwtich);

      this.newMember = this.webSwtich.新會員數.toLocaleString();
      this.visitMember = this.webSwtich.瀏覽人數.toLocaleString();

      this.selectedColorBar = this.whichWeb;

      this.webSwtich2 = this.webSessionData[this.whichWeb];

      this.userFlow = this.webSwtich2.人次[0].toLocaleString();

      this.line3TabsCounts = this.lineWeb1Data[this.whichWeb].人次;
      this.line3TabsLabels = this.lineWeb1Data[this.whichWeb].date;
      this.selected3TabsData = this.lineWeb1Data[this.whichWeb];

      this.selectedPieData = this.pieWeb1Data[this.whichWeb];
      this.pieCounts = this.selectedPieData.device.counts;
      this.pieLabels = this.selectedPieData.device.device;

      this.barCounts = this.selectedPieData.login_method.counts;
      this.barLabels = this.selectedPieData.login_method.login_method;

      this.selectedBarTop10Data = this.barTop10Web1Data[this.whichWeb];
      this.top10MediumBarCounts =
        this.barTop10Web1Data[this.whichWeb].首頁.search_count;
      this.top10MediumBarLabels =
        this.barTop10Web1Data[this.whichWeb].首頁.title;

      this.selectedDoubleLineData = this.DoubleLineData1[this.whichWeb];

      this.DoubleLineSelectorDate = this.DoubleLineData1[this.whichWeb].date;
      this.selectedTable = this.tableWeb1Data;
      this.selectedTop10 = this.top10SearchWeb1[this.whichWeb];

      this.top10PageSelectorIndex = "首頁";
      this.dropdownObj = {};
      const dropdowns = Object.keys(this.barTop10Web1Data[this.whichWeb]);
      const b = dropdowns.pop();
      dropdowns.unshift(b);
      for (let index = 0; index < dropdowns.length; index++) {
        this.dropdownObj[dropdowns[index]] = dropdowns[index];
      }
      console.log('TOP10 點擊計數 旁邊的下拉選單',this.dropdownObj)
      this.selecteddropdown = this.dropdownObj;

      this.getPathTable();
      this.path = "";
      this.selectedTable = this.tableWeb1Data;

      setTimeout(() => {
        let colorBar5 = document.getElementById("color-bar5");
        colorBar5.style.width =
          this.colorBarData[this.selectedColorBar].landing_count_rate + "%";
      }, 100);

      setTimeout(() => {
        let colorBar6 = document.getElementById("color-bar6");
        colorBar6.style.width =
          this.colorBarData[this.selectedColorBar].landing_user_count_rate +
          "%";
      }, 100);

      setTimeout(() => {
        let colorBar8 = document.getElementById("color-bar8");
        colorBar8.style.width =
          this.colorBarData[this.selectedColorBar].register_count_rate + "%";
      }, 100);

      this.pieChartFunctions();
      this.DoubleLineChart();
      this.BottomBar();
      this.lineChart3Tabs();
      this.Top10MediumBar();
    },

    lineAmountClick() {
      this.lineAmount = true;
      this.lineTime = false;
      this.lineExit = false;
      this.lineSwtich = "流量";
      this.lineTitle = "使用者流量";
      // this.seriesLine[0].data = this.lineWeb2Data[this.lineSwtich].value;
      this.line3TabsCounts = this.selected3TabsData.人次;
      this.lineChart3Tabs();
    },

    lineTimeClick() {
      this.lineAmount = false;
      this.lineTime = true;
      this.lineExit = false;
      this.lineSwtich = "停留";
      this.lineTitle = "網站平均停留時間";
      this.line3TabsCounts = this.selected3TabsData.停留時間;
      console.log("stop", this.line3TabsCounts);
      this.lineChart3Tabs();
    },

    lineExitClick() {
      this.lineAmount = false;
      this.lineTime = false;
      this.lineExit = true;
      this.lineSwtich = "跳出";
      this.lineTitle = "網站平均跳出率";
      this.line3TabsCounts = this.selected3TabsData.bounce;
      console.log("bounce", this.line3TabsCounts);
      this.lineChart3Tabs();
    },

    pieChart() {
      (this.pieSeries = [
        // Number(this.selectedPieNew.線上),
        // Number(this.selectedPieNew.線下),
      ]),
        (this.PieChartOptions = {});
    },
  },
};
</script>

<style>
/* WebsiteAnalysis */
.p-sortable-column {
  justify-content: center;
}

#selectedTable_Bearer .title {
  width: 12.5vw;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

#selectedTable_Bearer .p-datatable-wrapper {
  overflow-y: overlay!important;
  border: 1px solid #C4C4C4;
}

#selectedTable_Bearer ::-webkit-scrollbar {
  width: 4px;
}

#selectedTable_Bearer .p-datatable-table > .p-datatable-thead > tr > th {
  height: 60px;
  line-height: 60px;
}

#selectedTable_Bearer .p-datatable-table > .p-datatable-tbody > tr > td {
  /* border: 1px solid #C4C4C4; */
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #C4C4C4;
  min-height: 40px;
  height: 40px;
  line-height: 40px;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 0px 10px !important;
  height: 50px;
  line-height: 50px;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0px 10px !important;
  min-height: 50px;
}

.p-datatable-scrollable .p-datatable-tbody > tr > td {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .p-column-title {
  font-size: 14px;
} */


/* 的 */
.p-tooltip.p-tooltip-top {
  padding: 5px 10px;
}
.tip {
  background-color: #ababab;
  border: 1px solid #ababab;
  border-radius: 10px;
  color: white;
}
 .tip:after {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 100%;
}
.tip:after,
.tip:before {
  opacity: 1;
  pointer-events: none;
  border: 6px solid transparent;
  border-top-color: #ababab;
  bottom: -12px;
  left: 50%;
  position: absolute;
  height: 0;
  width: 0;
  z-index: 5000;
} 

.parent-popout {
  position: relative;
}

.child-popout {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -30px;
  color: #fff;
  text-align: left;
  padding: 10px;
}

.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
.searchImg {
  position: absolute;
  /* width: 3%; */
  top: 10px;
  left: 8px;
}
.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 30px;
  width: 100%;
  height: 37px;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
  color: rgb(113, 175, 182);
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 7.7vw;
  height: 40px;
}

.home {
  padding-top: 50px;
  padding-left: 50px;
}

.button-focus {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
  height: 40px;
}

.number-div {
  padding: 0 15px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 70px;
}

.line-1row-div {
  padding: 0 15px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 260px;
}

.website-2row-div {
  padding: 10px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 315px;
}

.website-2row-div-1 {
  padding: 10px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 145px;
}

.website-3row-div {
  padding: 18px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 500px;
}

.overflow-search {
  height: 400px;
  overflow-y: scroll;
}

.pie-div-lastrow {
  padding: 10px;
  margin-top: 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 260px;
}

.number-label {
  color: #71afb6;
  font-size: 18px;
}

.number-amount {
  font-size: 22px;
  font-size: bold;
}

.selector-top10 {
  color: #71afb6;
  border-radius: 10px;
  padding: 5px;
  width: 30%;
  text-align: center;
  border-color: #c4c4c4;
}

.gap-custom {
  gap: 20px 25px;
}

.color-bar {
  height: 42px;
}

.p-datatable-thead {
  border-bottom: 1px solid #c4c4c4;
}
.p-datatable-thead tr th{
  background-color: white;
}

.child-popout-WebsiteAnalysis {
  background-color: white;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: -190px;
  left: 40px;
  width: 720px;
  text-align: left;
}

.child-popout-WebsiteAnalysis-top {
  width: 100%;
  height: 30px;
  background-color: #63a7c8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

input::-webkit-input-placeholder {
  text-align: left;
}
.newLeft {
  left: 400px !important;
}
.child-popout-inWebsiteAnalysis-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -190px;
  text-align: left;
  top: -33px;
  width: 180px;
  z-index: 99;
}
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}
.change-date-li:hover {
  background-color: #eaf3f4;
}
.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
</style>