import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '@vuepic/vue-datepicker/dist/main.css'
import 'normalize.css/normalize.css'

import PrimeVue from "primevue/config";
import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Datepicker from '@vuepic/vue-datepicker';
import { dragscrollNext } from "vue-dragscroll";
import VueClipboard from 'vue3-clipboard'
import Tooltip from 'primevue/tooltip';
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'

// import './index.css'


import { allapi2 } from "../public/static2/api/apigClient.js";

require('highcharts/highcharts-more.js')(Highcharts);

router.beforeEach(async (to, from, next) => {

  if (to.path === '/Productarchive' || to.path === '/ProductTextTemplate' || to.path === '/MarketingSchedule' || to.path === '/ProductSmsTemplate'
    || to.path === '/SmsSchedule' || to.path === '/LineAdsPackage' || to.path === '/FacebookAdsPackage' || to.path === '/EmailSetting'
  ) {
    console.log('no remove label data')
  } else {
    sessionStorage.removeItem("getlabelId");
    sessionStorage.removeItem("getlabelTitle");
    sessionStorage.removeItem("getlabelNumber");
  }

  const access = sessionStorage.getItem('access_token')
  const id = sessionStorage.getItem('id_token')
  const body = {
    id_token: id,
    access_token: access,
    page: to.path.slice(1)
  };
  await allapi2
    .userVerifytokenPost(null, body)
    .then((res) => {
      console.log("檢驗token", res);
      sessionStorage.setItem("token", "ImLogin");
      sessionStorage.setItem("name", res.data.name);
      sessionStorage.setItem("username", res.data.username);
      sessionStorage.setItem("email", res.data.email);
      sessionStorage.setItem('lv', res.data.authorization)
      sessionStorage.setItem('leftshow', JSON.stringify(res.data.module))
      if (res.data.authorization == 4) {
        alert('Verify有誤')
      }
    })
    .catch((err) => {
      sessionStorage.setItem("token", "ImNotLogin");
      console.log("檢驗token有誤,過期");
      console.log(err);
    });

  const isLogin = sessionStorage.getItem('token') == 'ImLogin';
  const leftshow = JSON.parse(sessionStorage.getItem("leftshow"))
  if (isLogin) {
    if (to.path === '/Login' || to.path === '/') {
      if (leftshow.OperationalPerformance == false) {
        next('/RFMCustomPage')
      } else {
        next('/OperationalOverview')
      }
    } else {
      next()
    }
  } else {
    console.log(to.path)
    if (to.path === '/ForgotPassword') {
      console.log(from)
      next()
    } else if (to.path === '/Login') {
      console.log(from)
      next()
    } else if (to.path === '/ResetPassword') {
      console.log(from)
      next()
    } else if (to.path != '/ForgotPassword' || to.path != '/Login' || to.path != '/ResetPassword') {
      console.log(from)
      next('/Login')
    } else {
      next()

    }
  }




});


createApp(App).use(store).use(router).use(PrimeVue, { zIndex: { tooltip: 5000 } })
  .use(SetupCalendar, {})
  .use(VueAxios, axios)
  .use(MonthPicker)
  .use(MonthPickerInput)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(HighchartsVue, {
    highcharts: Highcharts
  })
  .component('Calendar', Calendar)
  .component('DatePicker', DatePicker)
  .component('Datepicker', Datepicker)
  .directive('dragscroll', dragscrollNext)
  .directive('tooltip', Tooltip)
  .mount('#app')
