<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>

  <div class="container home" v-else>
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- datepicker modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              is-expanded
              :model-config="modelConfig"
              :max-date="hasDataEnd"
              :min-date="hasDataFirst"
            />
            <p>{{ vue3Date.start }}~{{ vue3Date.end }}</p>
            <button
              class="btn confrim-btn"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <span class="title-span fs-medium"> 投放紀錄一覽表(FB Ads)</span>
      <div class="search-container">
        <div>
          <img class="searchImg" src="../assets/search.svg" alt="searchImg" />
          <input
            class="search-input"
            placeholder="搜尋活動名稱或廣告組合名稱"
            v-model="searchinput"
            @focus="this.autoShow = true"
            @blur="blursearch"
          />
        </div>
        <div class="auto-complicate-div" v-show="autoShow">
          <p class="m-0 p-1 ps-3">搜尋建議</p>
          <hr class="m-0" />
          <p class="m-0 p-1 ps-3 auto-complicate-p" @click="search('all')">
            從全部中查詢{{ searchinput }}
          </p>
          <p class="m-0 p-1 ps-3 auto-complicate-p" @click="search('活動名稱')">
            從活動名稱中查詢{{ searchinput }}
          </p>
          <p class="m-0 p-1 ps-3 auto-complicate-p" @click="search('廣告組合')">
            從廣告組合中查詢{{ searchinput }}
          </p>
        </div>
      </div>
      <div class="dropdown" style="min-width:250px">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" v-if="this.hasDataEnd != null">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            color: #c4c4c4;
            font-size: 14px;
            right: 5px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>
    <div class="row" style="margin-top: 25px">
      <div class="col-12 p-0">
        <div class="view">
          <div class="wrapper">
            <table class="table" id="table">
              <thead>
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <th
                    @mouseenter="this.underline = header"
                    @mouseleave="this.underline = ''"
                    :class="this.underline === header ? 'addUnderline' : ''"
                    style="cursor: grab"
                    :style="grabbing ? 'cursor: grabbing' : ''"
                    v-for="header in headers"
                    :key="header"
                    scope="col"
                    :draggable="true"
                    @dragenter="this.underline = header"
                    @dragover="allowDrop"
                    @dragstart="dragStart($event)"
                    @dragleave="removeclass($event, header)"
                    @drop="dropEvent($event)"
                    @mousedown="this.grabbing = true"
                    @mouseup="this.grabbing = false"
                  >
                    <div
                      class="d-flex align-items-center"
                      style="
                        min-width: 40px;
                        border-bottom: none;
                        padding: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                      v-if="header != '刪除'"
                    >
                      <span
                        style="min-width: 36px; border-bottom: none"
                        :style="
                          header === this.currentKey ? 'color:#4197B9' : ''
                        "
                      >
                        {{ header }}
                      </span>
                      <img
                        :draggable="false"
                        class="sort-img ms-1"
                        style="
                          cursor: pointer;
                          height: 16px;
                          border-bottom: none;
                          margin-right: 5px;
                        "
                        src="../assets/green-up-arrow.png"
                        alt=""
                        @click="sortingButton($event)"
                        v-if="
                          header === this.currentKey && this.issort === false
                        "
                      />
                      <img
                        :draggable="false"
                        class="sort-img ms-1"
                        style="
                          cursor: pointer;
                          height: 16px;
                          border-bottom: none;
                          margin-right: 5px;
                        "
                        src="../assets/green-down-arrow.png"
                        alt=""
                        @click="sortingButton($event)"
                        v-if="
                          header === this.currentKey && this.issort === true
                        "
                      />
                      <img
                        :draggable="false"
                        class="sort-img ms-1"
                        style="
                          cursor: pointer;
                          height: 16px;
                          border-bottom: none;
                          margin-left: 5px;
                        "
                        src="../assets/sort-black.png"
                        alt=""
                        @click="sortingButton($event)"
                        v-if="header != this.currentKey"
                      />
                    </div>
                  </th>
                </transition-group>
              </thead>
              <tbody>
                <tr v-for="(item, index1) in searchlist" :key="index1">
                  <td v-for="(header, index) in headers" :key="index">
                    <button
                      class="btn-basic"
                      v-if="index === 1 && item[header] != '-'"
                      @click="changeToAdvList($event)"
                    >
                      <a
                        href="javascript:;"
                        style="color: #71afb6; text-decoration: none"
                        >{{ item[header] }}</a
                      >
                    </button>
                    <span v-else-if="index === 1 && item[header] === '-'">{{
                      item[header]
                    }}</span>
                    <div
                      class="d-flex flex-column cost-div align-items-end"
                      style="width: 150px"
                      v-else-if="header === '成果與成果次數'"
                    >
                      <div v-if="item[item.目標] != undefined">
                        {{ item[item.目標] }}
                      </div>
                      <div
                        v-else-if="
                          item[item.目標] === undefined &&
                          item.成果與成果次數[item.成果名稱] != undefined
                        "
                      >
                        {{ item.成果與成果次數[item.成果名稱] }}
                      </div>
                      <div v-else>{{ item.觸及人數 }}</div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                        style="width: 150px"
                      >
                        <img
                          class="ms-3"
                          src="../assets/show-result.png"
                          alt=""
                          @mouseover="(item.觸及人數 != '-' && item[header] != {}) ? show(index1) : ''"
                          @mouseout="this.rowindex = ''"
                        />
                        <div class="parent-popout font-14">
                          <div
                            v-show="this.rowindex === index1"
                            class="child-popout8"
                          >
                            <div>
                              <div
                                class="d-flex flex-column align-items-end"
                                v-for="(val, index) in Object.values(
                                  item[header]
                                )"
                                :key="index"
                              >
                                {{ val }}
                                <div style="font-size: 14px">
                                  {{ Object.keys(item[header])[index] }}
                                </div>
                              </div>
                            </div>
                            <div class="container__arrow"></div>
                          </div>
                        </div>
                        <div
                          style="font-size: 14px; color: gray"
                          v-if="item[item.目標] != undefined"
                        >
                          {{ item.目標 }}
                        </div>
                        <div
                          style="font-size: 14px; color: gray"
                          v-else-if="
                            item[item.目標] === undefined &&
                            item.成果與成果次數[item.成果名稱] != undefined
                          "
                        >
                          {{ item.成果名稱 }}
                        </div>
                        <div style="font-size: 14px; color: gray" v-else>
                          觸及人數
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column cost-div align-items-end"
                      v-else-if="header === '每次成果成本'"
                    >
                      <div>NT${{ item[header] }}</div>
                      <div
                        style="font-size: 14px; color: gray"
                        v-if="item[item.目標] != undefined"
                      >
                        {{ item.目標 }}
                      </div>
                      <div
                        style="font-size: 14px; color: gray"
                        v-else-if="
                          item[item.目標] === undefined &&
                          item.成果與成果次數[item.成果名稱] != undefined
                        "
                      >
                        {{ item.成果名稱 }}
                      </div>
                      <div style="font-size: 14px; color: gray" v-else>
                        觸及人數
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column cost-div"
                      v-else-if="header === '排程'"
                    >
                      <div style="height: 20px">
                        {{ item[header].substr(0, 10) }}
                      </div>
                      <div style="height: 10px">
                        <div
                          style="
                            transform: rotate(90deg);
                            position: relative;
                            top: -3px;
                          "
                        >
                          ~
                        </div>
                      </div>
                      <div class="mb-1" style="height: 20px">
                        {{ item[header].substr(20, 10) }}
                      </div>
                    </div>
                    <span v-else>
                      {{ item[header] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="text-center mt-4"
              style="color: #c4c4c4"
              v-if="this.searchlist[0] === undefined"
            >
              目前無投放紀錄
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-evenly mt-4">
          <!-- <button
            class="btn btn-lg btn-primary upload-button"
            @click="MergeCell()"
          >
            <span> 一鍵上傳</span>
          </button> -->
        </div>
      </div>

      <!-- <rawDisplayer class="col-2" :value="list" title="List" /> -->

      <!-- <rawDisplayer class="col-2" :value="headers" title="Headers" /> -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import { allapi } from "../../public/static/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
export default {
  name: "FbadsTable",
  display: "Table Column",
  order: 9,
  components: {
    Calendar,
    DatePicker,
  },
  data() {
    return {
      underline: false,
      loading: false,
      isShow: false,
      notshow: false,

      dateSelector: "",
      startDate: "2021-12-01",
      endDate: "2022-03-15",
      hasDataFirst: "",
      hasDataEnd: "",
      endYear: 2022,
      endMonth: 6,

      searchinput: "",
      autoShow: false,

      vue3Date: {
        start: new Date("2022-01-06").toISOString().slice(0, 10),
        end: new Date("2022-01-10").toISOString().slice(0, 10),
      },

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },

      headers: [
        "活動名稱",
        "廣告組合",
        "狀態",
        "觸及人數",
        "曝光次數",
        "頻率",
        "成果與成果次數",
        "每次成果成本",
        "排程",
        "點擊",
        "CTR(全部)",
        "CPC",
        "CPM",
        "預算",
        "花費",
      ],
      list: [
        // {
        //   CPC: 0.1,
        //   CPM: 1.2,
        //   "CTR(全部)": 0.2,
        //   廣告組合: "電器買五送一加碼大優惠",
        //   成果與成果次數: { ssss: 1230 },
        //   每次成果成本: "0.88",
        //   排程: "Sun, 13 Feb 2022 00:00:00 GMT",
        //   曝光次數: 897,
        //   活動名稱: "黑五特賣會會會會",
        //   狀態: "進行中",
        //   花費: 875,
        //   觸及人數: 230,
        //   預算: 23,
        //   頻率: 1.52,
        //   點擊: 54,
        // },
      ],
      searchlist: [],
      issort: true,
      currentKey: "",
      rowindex: "",

      grabbing: false,
      drag: false,
    };
  },
  watch: {
    searchinput() {
      if (this.searchinput === "") {
        this.searchlist = this.list;
        this.autoShow = false;
        setTimeout(() => {
          this.MergeCell();
        }, 500);
      } else {
        this.autoShow = true;
      }
    },
  },
  created: async function () {
    // http://localhost:8080 test
    if (sessionStorage.getItem("lv") === "test") {
      this.notshow = true;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "laurel") {
      this.notshow = true;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "dev") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "bd") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    }
    if (!this.notshow) {
      this.loading = true;
      this.currentKey = "狀態";
      await this.getTime();
      if (this.hasDataEnd === null) {
        this.loading = false;
        return;
      }
      await this.getRecentMonth();
      await this.getTableApi();
      await this.test1();
      await this.sortstate();
      this.highlightVue3Date();
      this.loading = false;
    }
  },
  methods: {
    test() {
      const str = "abab<ca>aacccaabc";
      const index = str.indexOf("<ca>");
      console.log(index);
    },
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    show(index1) {
      this.rowindex = index1;
    },
    search(key) {
      if (key === "all") {
        this.searchlist = this.searchlist.filter((d) => {
          return (
            d.活動名稱.includes(this.searchinput) ||
            d.廣告組合.includes(this.searchinput)
          );
        });
      } else {
        this.searchlist = this.searchlist.filter((d) => {
          return d[key].includes(this.searchinput);
        });
      }
      const table = document.getElementById("table");
      console.log(table.col);
      for (var i = 0; i < table.rows.length - 1; i++) {
        table.rows[i + 1].cells[0].style.color = "black";
      }
      // table.col[0].cells[0].style.color = "black";

      this.autoShow = false;
    },
    blursearch() {
      setTimeout(() => {
        this.autoShow = false;
      }, 200);
    },
    sortingButton(e) {
      this.issort = !this.issort;
      const key = e.target.parentNode.innerText.trim();
      if (this.currentKey != key) {
        this.issort = true;
      }
      this.currentKey = key;

      if (key === "活動名稱") {
        this.sortevent();
      } else if (key === "廣告組合") {
        this.sortadv();
      } else if (key === "狀態") {
        this.sortstate();
      } else if (key === "成果與成果次數") {
        this.sortcount();
      } else if (key === "每次成果成本") {
        this.sortcost();
      } else if (key === "排程") {
        this.sorttime();
      } else {
        if (this.issort) {
          this.test1();
          this.test7(key);
          this.test2(key);
          this.test3();
        } else {
          this.test1();
          this.test8(key);
          this.test5(key);
          this.test6();
        }
      }
    },
    test1: async function () {
      function compare(a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          return a.廣告組合.length - b.廣告組合.length;
        }

        if (b.活動名稱 > a.活動名稱) {
          return 1;
        }
        if (b.活動名稱 < a.活動名稱) {
          return -1;
        }
      }
      await this.searchlist.sort(compare);
    },
    test7(key) {
      function compare(a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2 && a.廣告組合 != "-" && b.廣告組合 != "-") {
          return b[key] - a[key];
        }
      }
      this.searchlist.sort(compare);
    },
    test2(key) {
      let arr = this.searchlist;
      function compare2(a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告組合 === "-" && d.活動名稱 === a.活動名稱;
          });

          a.rank = data[key];
          b.rank = data[key];
        }
      }

      this.searchlist.sort(compare2);
    },
    test3() {
      function compare(a, b) {
        return b.rank - a.rank;
      }
      this.searchlist.sort(compare);
    },
    test8(key) {
      function compare(a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2 && a.廣告組合 != "-" && b.廣告組合 != "-") {
          return a[key] - b[key];
        }
      }
      this.searchlist.sort(compare);
    },
    test5(key) {
      let arr = this.searchlist;
      function compare2(a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告組合 === "-" && d.活動名稱 === a.活動名稱;
          });

          a.rank = data[key];
          b.rank = data[key];
        }
      }

      this.searchlist.sort(compare2);
    },
    test6() {
      function compare(a, b) {
        return a.rank - b.rank;
      }
      this.searchlist.sort(compare);
    },

    sortevent() {
      if (this.issort) {
        const compare = function (a, b) {
          return b.活動名稱.length - a.活動名稱.length;
        };
        this.searchlist.sort(compare);
      } else {
        const compare = function (a, b) {
          return a.活動名稱.length - b.活動名稱.length;
        };
        this.searchlist.sort(compare);
      }
    },
    sortadv() {
      if (this.issort) {
        const compare = function (a, b) {
          const value1 = a.活動名稱;
          const value2 = b.活動名稱;
          if (value1 === value2 && a.廣告組合 != "-" && b.廣告組合 != "-") {
            return b.廣告組合.length - a.廣告組合.length;
          }
        };
        this.searchlist.sort(compare);
      } else {
        const compare2 = function (a, b) {
          const value1 = a.活動名稱;
          const value2 = b.活動名稱;
          if (value1 === value2 && a.廣告組合 != "-" && b.廣告組合 != "-") {
            return a.廣告組合.length - b.廣告組合.length;
          }
        };
        this.searchlist.sort(compare2);
      }
    },
    sortstate() {
      let arr = this.searchlist;
      const compare3 = function (a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告組合 === "-" && d.活動名稱 === a.活動名稱;
          });

          a.rank = data.狀態;
          b.rank = data.狀態;
        }
      };

      this.searchlist.sort(compare3);
      if (this.issort) {
        const compare = function (a, b) {
          return b.rank.length - a.rank.length;
        };
        this.searchlist.sort(compare);
      } else {
        const compare2 = function (a, b) {
          return a.rank.length - b.rank.length;
        };
        this.searchlist.sort(compare2);
      }
    },
    sortcost() {
      let arr = this.searchlist;
      const compare3 = function (a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告組合 === "-" && d.活動名稱 === a.活動名稱;
          });

          a.rank = data.每次成果成本;
          b.rank = data.每次成果成本;
        }
      };

      this.searchlist.sort(compare3);
      if (this.issort) {
        const compare = function (a, b) {
          return b.rank - a.rank;
        };
        this.searchlist.sort(compare);
      } else {
        const compare2 = function (a, b) {
          return a.rank - b.rank;
        };
        this.searchlist.sort(compare2);
      }
    },
    sortcount() {
      let arr = this.searchlist;
      const compare3 = function (a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告組合 === "-" && d.活動名稱 === a.活動名稱;
          });

          a.rank = Object.values(data.成果與成果次數)[0];
          b.rank = Object.values(data.成果與成果次數)[0];
        }
      };

      this.searchlist.sort(compare3);
      if (this.issort) {
        const compare = function (a, b) {
          return b.rank - a.rank;
        };
        this.searchlist.sort(compare);
      } else {
        const compare2 = function (a, b) {
          return a.rank - b.rank;
        };
        this.searchlist.sort(compare2);
      }
    },
    sorttime() {
      let arr = this.searchlist;
      const compare3 = function (a, b) {
        const value1 = a.活動名稱;
        const value2 = b.活動名稱;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告組合 === "-" && d.活動名稱 === a.活動名稱;
          });
          a.rank = data.排程.substr(20, 10);
          b.rank = data.排程.substr(20, 10);
        }
      };

      this.searchlist.sort(compare3);
      if (this.issort) {
        const compare = function (a, b) {
          if (b.rank > a.rank) {
            return 1;
          }
          if (b.rank < a.rank) {
            return -1;
          }
        };
        this.searchlist.sort(compare);
      } else {
        const compare2 = function (a, b) {
          if (b.rank > a.rank) {
            return -1;
          }
          if (b.rank < a.rank) {
            return 1;
          }
        };
        this.searchlist.sort(compare2);
      }
    },

    compareStr() {
      console.log(
        "'躂季大促銷全館8折起'>'黑五特賣會'",
        "躂季大促銷全館8折起" > "黑五特賣會"
      );
      console.log("'冬季產品出清'>'黑五特賣會'", "冬季產品出清" > "黑五特賣會");
      console.log(
        "'夏季大促銷，全館8折起'>'冬季產品出清'",
        "夏季大促銷，全館8折起" > "冬季產品出清"
      );
      console.log("'2022-01-09'>'2022-01-05'", "2021-01-20" > "2022-01-09");
    },

    allowDrop(e) {
      e.preventDefault();
      e.target.className = "addUnderline";
    },
    dragStart(e) {
      let tar = e.target.innerText;
      console.log(e);
      console.log(tar);
      e.dataTransfer.setData("Text", tar);
      e.target.className = "dragclass";
    },
    removeclass(e, header) {
      e.target.className = "addUnderline";
      this.underline = header;
      console.log("e.target", e.target.innerText === "");
      this.grabbing = false;
    },

    endOfthis(e) {
      e.target.className = "table-header-custom";
      e.target.className = "removeUnderline";
    },
    removeUnderline(e) {
      e.target.className = "table-header-custom";
    },

    dropEvent(e) {
      this.allowDrop(e);
      e.target.className = "removeUnderline";
      const fixDragText = e.dataTransfer.getData("Text").trim();
      const dragText = fixDragText;

      const newheaders = this.headers.slice(2, 20);

      const haveheader = newheaders.some((d) => {
        return d === dragText;
      });
      const not12 = newheaders.some((d) => {
        return d === e.target.innerText.trim();
      });

      if (haveheader && not12) {
        const dropindex = this.headers.findIndex((d) => {
          return d === e.target.innerText.trim();
        });
        const dragindex = this.headers.findIndex((d) => {
          return d === dragText;
        });

        this.headers[dropindex] = dragText;
        this.headers[dragindex] = e.target.innerText.trim();
      }
      this.grabbing = false;
    },
    MergeCell() {
      const table = document.getElementById("table");
      console.log("table", table);
      if (table.rows[0] != undefined) {
        for (var i = 0; i < table.rows.length - 1; i++) {
          if (
            table.rows[i].cells[0].innerHTML ==
            table.rows[i + 1].cells[0].innerHTML
          ) {
            table.rows[i + 1].cells[0].style.color = "white";
          }
        }
        console.log("table-col", table.rows[0].cells);
      }
    },
    getTableApi: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_time: this.startDate,
        end_time: this.endDate,
      };

      await allapi3
        .facebookCampaignGet(params)
        .then((res) => {
          console.log("fbtable", res);
          this.list = res.data.Data;
          console.log("list", this.list);
          this.searchlist = this.list;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤" + err);
        });
    },
    changeToAdvList(e) {
      sessionStorage.setItem("advname", e.target.innerText);
      this.$router.push({
        name: "FbadsTableByAdv",
      });
      console.log(e.target.innerText);
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .facebookTimeGet(params)
        .then((res) => {
          console.log(res);
          this.hasDataFirst = res.data.Data.start_time;
          this.hasDataEnd = res.data.Data.end_time;
          this.startDate = res.data.Data.start_time;
          this.endDate = res.data.Data.end_time;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.highlightVue3Date();

      this.loading = false;
      this.dateSelector = "最近7天";
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近1天";
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近一年";
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "最近30天";
    },
    getLastMonth: async function () {
      const start = moment(this.hasDataEnd).startOf("month").add(-1, "months");
      const end = moment(this.hasDataEnd).endOf("month").add(-1, "months");
      const strStart = start._d.toISOString().slice(0, 10);
      const strEnd = end._d.toISOString().slice(0, 10);
      this.startDate = strStart;
      this.endDate = strEnd;
      console.log("start", strStart);
      console.log("end", strEnd);
      //  this.startDate = strStart;

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.highlightVue3Date();
      this.dateSelector = "上個月";
    },
    getThisMonth: async function () {
      const start = moment()
        .startOf("month")
        .add(+1, "day");
      const end = moment();
      const strStart = start._d.toISOString().slice(0, 10);
      const strEnd = end._d.toISOString().slice(0, 10);
      this.startDate = strStart;
      this.endDate = strEnd;
      console.log("thisstart", strStart);
      console.log("thisend", strEnd);

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.highlightVue3Date();
      this.dateSelector = "這個月";
    },

    customDatePicker: async function () {
      this.startDate = this.vue3Date.start.slice(0, 10);
      this.endDate = this.vue3Date.end.slice(0, 10);

      console.log("customstart", this.startDate);
      console.log("customend", this.endDate);

      // await Promise.all([this.getChart(), this.clickOpenMail()]);

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();
      this.loading = false;
      this.dateSelector = "自訂";
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();

      await this.getTableApi();
      await this.test1();
      this.MergeCell();
      this.sorttime();

      this.highlightVue3Date();
      this.loading = false;
      this.dateSelector = "全部";
    },
  },
};
</script>
<style scoped>
/* FbadsTable */
.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.buttons {
  margin-top: 35px;
}
.view {
  padding: 15px;
  margin: auto;
  width: 100%;
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.wrapper {
  position: relative;
  overflow: auto;
  height: 600px;
  /* border: 1px solid black; */
  white-space: nowrap;
  /* box-shadow: 10px 0 5px -2px #888; */
}

td:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  min-width: 150px;
  max-width: 150px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 9;
  white-space: normal;
}
td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  left: 150px;
  border-right: none;
  /* filter: drop-shadow(2px 0px 1px rgba(124, 124, 124, 0.446)); */
  filter: drop-shadow(3px 0px 1px rgba(202, 201, 201, 0.446));
  white-space: normal;
  z-index: 9;
}
th:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  min-width: 150px;
  max-width: 150px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 59;
}
th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 150px;
  background-color: #fff;
  border-right: 0px;
  filter: drop-shadow(3px 0px 1px rgba(202, 201, 201, 0.446));
  z-index: 59;
}

th {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  top: 0px;
  border-right: none;
  filter: drop-shadow(3px 0px 1px rgba(202, 201, 201, 0.446));
  z-index: 9;
}
table {
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}
th {
  border-bottom: 2px solid rgb(235, 230, 230);
  padding: 5px 15px;
}
th,
td {
  border-right: 2px solid rgb(235, 230, 230);
}
.upload-button {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
}
.btn-lg {
  min-width: 34.5vw;
  font-size: 20px;
  border-radius: 10px;
}
.search-container {
  position: relative;
  right: 0;
  /* height:50px ; */
}
.search-input {
  border: 1px solid #575757;
  border-radius: 10px;
  text-indent: 50px;
  height: 35px;
  width: 350px;
}
.searchImg {
  position: absolute;
  top: 8px;
  left: 15px;
}
.auto-complicate-div {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  background-color: white;
  z-index: 99;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
}
.btn-basic {
  border: none;
  background-color: white;
}
.sort-img {
  padding: 0;
  position: relative;
  top: -2px;
  right: 0px;
  margin-left: 10px;
}
.cost-div {
  width: 130px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: -23px;
  left: -20px;
  width: 120px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.parent-popout:hover .container__arrow,
.parent-popout:hover .child-popout {
  opacity: 1;
  pointer-events: initial;
}

.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  top: 7px;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
.addUnderline {
  border-bottom: 2px solid #63a7c8;
}
.dragclass {
  background-color: #eaf3f4;
}
</style>
