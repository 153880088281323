<template>
  <!-- <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能為正式付費使用 </span>
  </div> -->
  <div>
    <!-- loading screen -->

    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- loading_copy screen -->

    <div class="loading-background" v-show="loading_copy">
      <p>建立複製模板中...</p>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span></span>
    </div>
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <p>
              <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
            </p>
            <p class="mb-5" style="margin-left: 36px; word-break: break-all">
              {{
                this.groupName.includeLabelNames.toString().replace(/,/g, "、")
              }}
            </p>
            <p>
              <img
                src="../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
            </p>
            <p style="margin-left: 36px">
              {{
                this.groupName.excludeLabelNames.toString().replace(/,/g, "、")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- changedateModal -->
    <div
      class="modal fade"
      id="changedateModal"
      tabindex="-1"
      aria-labelledby="changedateModalLabel"
      aria-hidden="true"
      style="z-index: 4000"
    >
      <div class="modal-dialog" style="width: 410px; font-size: 18px">
        <div class="modal-content" style="width: 410px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body">
            <p>活動名稱</p>
            <input
              type="text"
              class="mb-3 ps-3"
              style="
                width: 100%;
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                height: 40px;
              "
              placeholder="請輸入活動名稱"
              v-model="renameEvent"
            />
            <p>活動時間</p>
            <div class="row align-items-center justify-content-between">
              <span class="col-1 set-time-title">
                <img class="mb-1" src="../assets/date.svg" alt=""
              /></span>
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  v-model="calenday"
                  is24hr
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
              ~
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  v-model="calentime"
                  is24hr
                  is-expanded
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="basic-btn-calen"
              style="width:130px;background-color"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              data-bs-dismiss="modal"
              @click="addCalen"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- scriptTimeModal -->
    <div
      class="modal fade"
      id="scriptTimeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
      style="z-index: 3000"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :style="this.goingToRunScript2 ? 'max-width: 900px' : 'max-width:555px'"
      >
        <div
          class="modal-content"
          :style="this.goingToRunScript2 ? 'width: 900px' : 'width: 555px'"
          @click="autoShow = false"
        >
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="d-flex" style="padding: 34px 30px 0px 30px">
            <div
              class="modal-body alert-modal-body p-0"
              style="text-align: left"
              :style="this.goingToRunScript2 ? 'width: 418px' : ''"
            >
              <div class="d-flex justify-content-start align-items-center mb-2">
                <div class="col-4 p-0" style="color: #71afb6">劇本投放設定</div>
              </div>

              <div class="mt-4">
                <div class="d-flex align-items-center mb-4">
                  <span class="me-4" style="width: 72px">開始時間</span>
                  <div
                    :style="
                      this.goingToRunScript2 ? 'width: 300px' : 'width: 380px'
                    "
                  >
                    <DatePicker
                      :min-date="
                        !this.scriptHasStart ? new Date() : '2000-01-01'
                      "
                      :max-date="scriptDayEnd"
                      :model-config="modelConfig"
                      style="width: 100%"
                      v-model="scriptDayStart"
                      mode="date"
                    >
                      <template v-slot="{ inputValue, togglePopover }">
                        <div
                          class="d-flex align-items-center"
                          @click="togglePopover()"
                          v-if="!this.scriptHasStart"
                        >
                          <input
                            :value="inputValue"
                            class="col-12 date-input"
                            style="
                              background-color: white;
                              border: 1px solid #c4c4c4;
                            "
                            readonly
                          />
                          <img
                            src="../assets/date.svg"
                            alt=""
                            style="position: relative; left: -95%"
                          />
                          <img
                            src="../assets/selection-arrow.png"
                            alt="searchImg"
                            style="position: relative; left: -42px"
                          />
                        </div>
                        <div class="d-flex align-items-center" v-else>
                          <input
                            :value="inputValue"
                            class="col-12 date-input"
                            style="
                              background-color: white;
                              border: 1px solid #c4c4c4;
                              color: #c4c4c4;
                            "
                            disabled
                          />
                          <img
                            src="../assets/date.svg"
                            alt=""
                            style="position: relative; left: -95%"
                            v-if="!this.scriptHasStart"
                          />
                          <img
                            src="../assets/gray-calendar.svg"
                            alt=""
                            style="position: relative; left: -95%"
                            v-else
                          />
                          <img
                            src="../assets/selection-arrow.png"
                            alt="searchImg"
                            style="position: relative; left: -42px"
                            v-if="!this.scriptHasStart"
                          />
                          <img
                            src="../assets/gray-selection-arrow.svg"
                            alt="searchImg"
                            style="position: relative; left: -42px"
                            v-else
                          />
                        </div>
                      </template>
                    </DatePicker>
                  </div>
                </div>
                <div class="d-flex">
                  <span class="me-4" style="width: 72px; line-height: 40px"
                    >結束時間</span
                  >
                  <div
                    class="d-flex flex-wrap"
                    :style="this.goingToRunScript2 ? 'width: 300px' : ''"
                  >
                    <div
                      class="mb-4"
                      :style="
                        this.goingToRunScript2
                          ? 'width: 300px'
                          : 'width: 141px;margin-right: 21px'
                      "
                    >
                      <DropdownBlack
                        :valueArr="['永久', '自訂']"
                        :selectValue="scriptDayState"
                        :changingSelected="'scriptDayState'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                    </div>
                    <div
                      class="mb-4"
                      :style="
                        this.goingToRunScript2 ? 'width: 300px' : 'width: 218px'
                      "
                    >
                      <DatePicker
                        :min-date="scriptDayStart"
                        :model-config="modelConfig"
                        v-model="scriptDayEnd"
                        mode="date"
                        style="position: relative"
                      >
                        <template v-slot="{ inputValue, togglePopover }">
                          <div
                            class="d-flex align-items-center"
                            @click="togglePopover()"
                          >
                            <input
                              :value="inputValue"
                              class="col-12 date-input"
                              style="
                                background-color: white;
                                border: 1px solid #c4c4c4;
                              "
                              readonly
                            />
                            <img
                              src="../assets/date.svg"
                              alt=""
                              style="position: relative; left: -95%"
                            />
                            <img
                              src="../assets/selection-arrow.png"
                              alt="searchImg"
                              style="position: relative; left: -42px"
                            />
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <span class="me-4" style="width: 72px; line-height: 40px"
                    >活動名稱</span
                  >
                  <div
                    :style="
                      this.goingToRunScript2 ? 'width: 300px' : 'width: 380px'
                    "
                    v-if="this.scriptDayState === '永久'"
                  >
                    <input
                      class=""
                      style="
                        border: none;
                        height: 40px;
                        width: 100%;
                        background-color: #eaf3f4;
                        border-radius: 10px;
                        padding-left: 20px;
                      "
                      type="text"
                      v-model="this.script_title"
                    />
                  </div>

                  <div
                    class="search-container"
                    style="position: relative; height: 40px"
                    :style="
                      this.goingToRunScript2 ? 'width: 300px' : 'width: 380px'
                    "
                    ref="target"
                    v-else
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                      style="
                        width: 100%;
                        border: 1px solid #c4c4c4;
                        border-radius: 10px;
                        padding: 0 14px;
                        height: 40px;
                      "
                      @click="autoShowcalendar_event = !autoShowcalendar_event"
                    >
                      {{ calendar_event }}
                      <img
                        src="../assets/selection-arrow.png"
                        alt="searchImg"
                      />
                    </div>

                    <div
                      class="auto-complicate-div-AfterTriggerScript"
                      style="width: 380px"
                      v-show="autoShowcalendar_event"
                      @mouseenter="autoShowcalendar_event = true"
                    >
                      <img
                        src="../assets/search-black.png"
                        alt="searchImg"
                        class="ms-3 my-2 mt-1"
                        style="width: 18px"
                      />
                      <input
                        class="ms-1 p-1 ps-2 my-2"
                        style="border: none; width: 88%"
                        placeholder="搜尋活動名稱"
                        v-model="searchInput2"
                      />
                      <hr
                        class="m-0"
                        style="
                          width: 100%;
                          opacity: 1;
                          height: 0;
                          border: 0;
                          border-bottom: 1px solid #c4c4c4;
                        "
                      />
                      <div style="max-height: 150px; overflow: auto">
                        <p
                          class="m-0 p-1 ps-3 auto-complicate-p"
                          style="cursor: default"
                          @click="selectCalendar_event(event)"
                          v-for="event in searchEvents"
                          :key="event.id"
                        >
                          <span>{{ event.title }}</span>
                          <span
                            style="
                              font-size: 12px;
                              color: #c4c4c4;
                              display: block;
                            "
                            >{{ event.start }} ~ {{ event.end }}</span
                          >
                        </p>
                      </div>
                      <hr
                        class="m-0"
                        style="
                          width: 100%;
                          opacity: 1;
                          height: 0;
                          border: 0;
                          border-bottom: 1px solid #c4c4c4;
                        "
                      />
                      <div
                        class="ms-3 my-1"
                        style="cursor: pointer"
                        @click="wantaddCalen"
                      >
                        <img
                          class="mb-1 me-2"
                          src="../assets/blackPlus.svg"
                          alt=""
                        />
                        建立新活動名稱
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal-body alert-modal-body p-0 ps-4"
              style="
                text-align: left;
                width: 418px;
                border-left: 1px solid #c4c4c4;
              "
              v-if="this.goingToRunScript2"
            >
              <div class="d-flex justify-content-start align-items-center mb-2">
                <div class="col-4 p-0" style="color: #71afb6">執行提醒</div>
              </div>
              <div>
                執行前的<span style="font-weight: bold">10分鐘</span
                >將無法進行更改與刪除，確認後系統將自動執行該流程
              </div>
              <div class="d-flex justify-content-start align-items-center mb-2">
                <div class="col-4 p-0 mt-3" style="color: #71afb6">
                  版本提醒
                </div>
              </div>
              <div>
                若更新執行中的觸發劇本，系統自動會將<span
                  style="font-weight: bold"
                  >成效資料重新紀錄，最多只保留十個版本</span
                >(包含資料)，您可到劇本成效進行查看。
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            :class="
              this.goingToRunScript2
                ? 'my-4 justify-content-end'
                : 'mb-4 justify-content-center'
            "
            :style="this.goingToRunScript2 ? 'margin-right:55px' : ''"
          >
            <button
              class="col-3 btn-basic me-3"
              style="background-color: #eaf3f4; width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              class="col-3 btn-basic btn-save"
              style="width: 125px"
              @click="this.openRunScriptModel()"
              v-if="this.goingToRunScript2"
            >
              確認
            </button>
            <button
              class="col-3 btn-basic btn-save"
              style="width: 125px"
              @click="this.openRunScriptModel()"
              v-else
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert變數Modal -->
    <div
      class="modal fade"
      id="varnameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
      style="z-index: 5000"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" @click="autoShow = false">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body" style="text-align: left">
            <div class="d-flex justify-content-start align-items-center mb-2">
              <div class="col-4 p-0" style="color: #71afb6">插入個人化變數</div>
              <div
                class="search-container col-6 p-0"
                style="position: relative"
              >
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                    padding: 0 14px;
                    height: 35px;
                  "
                  @click.stop="autoShow = !autoShow"
                >
                  {{ triggerType }}
                  <img src="../assets/selection-arrow.png" alt="searchImg" />
                </div>
                <div
                  class="auto-complicate-div"
                  style="width: 100%"
                  v-show="autoShow"
                  @mouseenter="autoShow = true"
                >
                  <p
                    class="m-0 p-1 px-3 auto-complicate-p"
                    @click.stop="
                      triggerType = '顧客姓名';
                      varName = '{name}';
                      autoShow = false;
                    "
                  >
                    顧客姓名
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <p class="mb-4" style="color: #8a8a8a">
                複製下方變數並插入文字中，使信件客製化；可讓信件中加入訂閱者的姓名，增加親切感。
              </p>
              <div class="d-flex align-items-center mb-4">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客姓名</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    color: white;
                    background-color: #71afb6;
                  "
                  v-clipboard:copy="varName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
              <div class="d-flex align-items-center mb-4">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客姓</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varLastName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    color: white;
                    background-color: #71afb6;
                  "
                  v-clipboard:copy="varLastName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
              <div class="d-flex align-items-center">
                <span class="me-4" style="color: #71afb6; width: 80px"
                  >顧客名</span
                >
                <input
                  type="text"
                  style="
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border: 0.2px solid #8a8a8a;
                    height: 30px;
                    height: 40px;
                    padding-left: 10px;
                  "
                  ref="eventName"
                  v-model="varFirstName"
                  disabled
                />
                <button
                  class="basic-btn highlight-SmsTemp-var"
                  style="
                    width: 90px;
                    height: 40px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    color: white;
                    background-color: #71afb6;
                  "
                  v-clipboard:copy="varFirstName"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  data-dismiss="modal"
                >
                  複製
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- alert建立模板名稱Modal -->
    <div
      class="modal fade"
      id="nameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>請為此Email模板建立名稱</p>
            <div>
              <input
                v-bind:class="{ 'is-invalid': eventNameError }"
                class="ps-2"
                style="
                  border-radius: 5px;
                  height: 30px;
                  border: 0.5px solid black;
                "
                ref="eventName"
                placeholder=""
                v-model="eventName"
              />
              <div class="invalid-feedback">
                {{ eventNameErrMsg }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close-cancel me-1"
              style="
                width: 125px;
                background-color: #eaf3f4;
                color: #71afb6;
                border: none;
              "
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-1"
              style="width: 125px"
              @click="nameEmailTemp()"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert重新命名模板名稱Modal -->
    <div
      class="modal fade"
      id="renameModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>Email模板重新命名</p>
            <div>
              <input
                v-bind:class="{ 'is-invalid': newTempNameError }"
                style="
                  border-radius: 5px;
                  height: 30px;
                  border: 0.5px solid black;
                "
                ref="eventName"
                placeholder=""
                v-model="newTempName"
              />
              <div class="invalid-feedback">
                {{ newTempNameErrMsg }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close-cancel me-1"
              style="width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-1"
              style="width: 125px"
              @click="confirmrename()"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- expEmailModal -->
    <EmailPreview :subject="newSub" :html="newHtml"></EmailPreview>

    <!-- offcanvas -->
    <div class="">
      <button
        class="btn btn-primary floating-button"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <img
          src="../assets/offcanvas-arrow.png"
          alt="arrow"
          class=""
          data-bs-dismiss="modal"
        />
      </button>
      <div
        class="offcanvas offcanvas-bottom"
        style="
          height: auto;
          min-height: 310px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        "
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <button
          class="btn btn-primary floating-button2 py-2"
          style="
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          "
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <img
            src="../assets/offcanvas-downarrow.png"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </button>
        <div class="offcanvas-body">
          <div
            class="d-flex justify-content-between"
            style="margin: 18px 80px 9px 100px; min-height: 255px"
            v-if="this.tabState === 'trigger'"
          >
            <div style="width: 45%">
              <p style="color: #71afb6">觸發事件設定</p>
              <p style="margin-bottom: 25px">觸發事件 (Trigger)</p>
              <div style="margin-bottom: 25px">
                <DropdownBlack
                  :mxHeight="130"
                  :valueArr="
                    this.cantSignup
                      ? [
                          '註冊',
                          '購物車未結',
                          '訂單確認',
                          '購買後促銷',
                          '定期投放',
                        ]
                      : ['購物車未結', '訂單確認', '購買後促銷', '定期投放']
                  "
                  :selectValue="triggerSelected"
                  :changingSelected="'triggerSelected'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
              </div>
              <p
                style="margin-bottom: 25px"
                v-if="triggerSelected === '購買後促銷'"
              >
                購買項目
              </p>
              <div
                class="d-flex justify-content-between"
                v-if="triggerSelected === '購買後促銷'"
              >
                <div style="width: 22.5%">
                  <DropdownBlack
                    :mxHeight="130"
                    :topSide="-130"
                    :valueArr="['商品', '類別', '品牌', '-']"
                    :selectValue="tagSelected"
                    :changingSelected="'tagSelected'"
                    v-on:selectValueByDrop="selectValueByDrop_triggerBuyTag"
                  />
                </div>
                <!-- value 在loading -->
                <div
                  v-if="this.valueLoading != '' && this.tagSelected != '-'"
                  style="width: 73.5%"
                >
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                      height: 40px;
                    "
                  >
                    <div
                      class="
                        flex-fill
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <div
                        class="spinner-border"
                        style="width: 1.6rem; height: 1.6rem"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <img
                      src="../assets/selection-arrow.png"
                      alt="selection-arrow"
                      style="margin-right: 14px"
                    />
                  </div>
                </div>
                <div style="width: 73.5%" v-else-if="this.tagSelected != '-'">
                  <DropdownWithSearch
                    :mxHeight="192"
                    :topSide="-192"
                    style="width: 100%"
                    :holder="'選擇'"
                    :valueArr="this.tagValue"
                    :selectValue="tagValueSelected"
                    :changingSelected="'tagValueSelected'"
                    v-on:selectValueByDrop="selectValueByDrop_triggerBuyValue"
                  />
                </div>
                <div
                  class="d-flex justify-content-end align-items-center"
                  style="
                    width: 73.5%;
                    border: 1px solid #c4c4c4;
                    padding: 0px 14px;
                    border-radius: 10px;
                  "
                  v-else
                >
                  <span class="flex-fill text-center" style="color: #71afb6"
                    >-</span
                  ><img
                    style="width: 10px; height: 8px"
                    src="../assets/selection-arrow.png"
                    alt="searchImg"
                  />
                </div>
              </div>
            </div>
            <div
              style="width: 45%"
              class="d-flex flex-column justify-content-between"
            >
              <div>
                <p style="color: #71afb6">事件說明</p>
                <p v-if="triggerSelected === '購買後促銷'">
                  設定當<span style="color: #71afb6">顧客購買後過多久</span>
                  ，系統即會觸發，您可以選擇購買的項目或者不做選擇(即是全部)。完成後請到<span
                    style="color: #71afb6"
                    >時間</span
                  >功能做下一步設定。
                </p>
                <p v-else-if="triggerSelected === '訂單確認'">
                  設定當<span style="color: #71afb6"
                    >顧客購買或下訂單後過多久</span
                  >
                  ，系統即會觸發。請到<span style="color: #71afb6">時間</span
                  >功能做下一步設定。
                </p>
                <p v-else-if="triggerSelected === '註冊'">
                  設定當<span style="color: #71afb6">顧客註冊後過多久</span>
                  ，系統即會觸發。請到<span style="color: #71afb6">時間</span
                  >功能做下一步設定。
                </p>
                <p v-else-if="triggerSelected === '購物車未結'">
                  設定當顧客加入購物車且<span style="color: #71afb6"
                    >在幾天內未進行結帳</span
                  >，系統即會觸發。請到<span style="color: #71afb6">時間</span
                  >功能做下一步設定。
                </p>
                <p v-else-if="triggerSelected === '定期投放'">
                  根據<span style="color: #71afb6">指定的時間範圍與頻率</span
                  >，系統即會觸發。請到<span style="color: #71afb6">時間</span
                  >功能做下一步設定。
                </p>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  class="col-5 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6; width: 40%"
                  @click="deleteTriggerTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  style="width: 40%"
                  @click="confirmUpdateTriggerTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin: 0px 50px 0px 60px; min-height: 276px"
            v-if="this.tabState === 'trigger_time'"
          >
            <div
              :style="
                this.triggerSelected != '定期投放' ? 'width: 30%' : 'width: 21%'
              "
            >
              <p style="color: #71afb6; margin-bottom: 23px">發送時間設定</p>
              <p
                style="margin-bottom: 20px"
                v-if="this.triggerSelected != '定期投放'"
              >
                經過多少時間寄第一封
              </p>
              <p style="margin-bottom: 20px" v-else>條件開始的時間</p>
              <div
                class="d-flex justify-content-between"
                style="margin-bottom: 25px"
                v-if="this.triggerSelected != '定期投放'"
              >
                <input
                  type="number"
                  class="select-style"
                  style="width: 48%; border: 1px solid #c4c4c4"
                  v-model="time_trigger_Selected"
                />
                <DropdownBlack
                  style="width: 48%"
                  :valueArr="
                    triggerSelected != '購物車未結'
                      ? ['立即', '小時後', '天後']
                      : ['天內']
                  "
                  :selectValue="time_trigger_state_Selected"
                  :changingSelected="'time_trigger_state_Selected'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
              </div>
              <div style="margin-bottom: 25px" v-else>
                <DatePicker
                  :min-date="new Date()"
                  :model-config="modelConfig"
                  v-model="cycleDayStart"
                  mode="date"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        style="
                          background-color: white;
                          border: 1px solid #c4c4c4;
                        "
                        readonly
                      />
                      <img
                        src="../assets/date.svg"
                        alt=""
                        style="position: relative; left: -95%"
                      />
                      <img
                        src="../assets/selection-arrow.png"
                        alt="searchImg"
                        style="position: relative; left: -42px"
                      />
                    </div>
                  </template>
                </DatePicker>
              </div>
              <p style="margin-bottom: 17px">發送方式</p>
              <div
                class="d-flex align-items-center"
                v-if="this.triggerSelected != '定期投放'"
              >
                <label class="radio-container" for="一次性投放">
                  <input
                    type="radio"
                    id="一次性投放"
                    value="一次性"
                    v-model="picked"
                    name="group1"
                  />
                  <span class="checkmark"></span>
                  一次性投放
                </label>
                <label class="radio-container" for="週期性投放">
                  <input
                    type="radio"
                    id="週期性投放"
                    value="週期性"
                    v-model="picked"
                    name="group1"
                  />
                  <span class="checkmark"></span>
                  週期性投放
                </label>
              </div>
              <div class="d-flex align-items-center" v-else>
                <label
                  class="radio-container"
                  style="width: 50%"
                  for="定期投放"
                >
                  <input
                    type="radio"
                    id="定期投放"
                    value="定期投放"
                    v-model="picked4"
                    name="group1"
                  />
                  <span class="checkmark"></span>
                  定期投放
                </label>
              </div>
            </div>
            <div
              style="position: relative"
              :style="
                this.triggerSelected != '定期投放' ? 'width: 26%' : 'width: 38%'
              "
            >
              <div
                style="
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  background-color: white;
                  opacity: 0.5;
                  z-index: 999;
                "
                v-if="picked === '一次性' && this.triggerSelected != '定期投放'"
              ></div>
              <p
                style="color: #71afb6; margin-bottom: 14px"
                v-if="this.triggerSelected != '定期投放'"
              >
                寄完第一封後
              </p>
              <div
                class="d-flex justify-content-between"
                style="color: #71afb6; margin-bottom: 16px"
                v-else
              >
                <div
                  style="width: 100%"
                  class="d-flex justify-content-end"
                  v-if="
                    happenTime === '月' && this.triggerSelected === '定期投放'
                  "
                >
                  <span
                    style="color: #71afb6; cursor: pointer"
                    @click="happenMonth_moreoption = true"
                    v-if="happenMonth_moreoption === false"
                    >更多選項...</span
                  >
                  <span
                    style="color: #71afb6; cursor: pointer"
                    @click="happenMonth_moreoption = false"
                    v-else-if="happenMonth_moreoption === true"
                    >取消選項...</span
                  >
                </div>
                <div
                  style="width: 100%"
                  class="d-flex justify-content-end"
                  v-else
                >
                  &nbsp;
                </div>
              </div>
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 25px"
              >
                <span class="me-4" v-if="this.triggerSelected != '定期投放'">
                  發生周期
                </span>
                <span class="me-4" v-else> 選擇發生周期 </span>
                <div
                  class="d-flex justify-content-between flex-fill"
                  style="background-color: #eaf3f4; border-radius: 10px"
                >
                  <button
                    class="basic-btn"
                    :class="happenTime === '年' ? 'highlight' : ''"
                    @click="happenTime = '年'"
                  >
                    年
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '月' ? 'highlight' : ''"
                    @click="happenTime = '月'"
                  >
                    月
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '週' ? 'highlight' : ''"
                    @click="happenTime = '週'"
                  >
                    週
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '日' ? 'highlight' : ''"
                    @click="happenTime = '日'"
                  >
                    日
                  </button>
                </div>
              </div>
              <div v-if="this.triggerSelected != '定期投放'">
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-bottom: 25px"
                >
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-start
                      flex-fill
                    "
                    v-if="happenTime === '日'"
                  >
                    <span class="me-4">每</span>
                    <input
                      type="number"
                      class="mx-2 text-center"
                      style="
                        width: 90%;
                        height: 40px;
                        border: none;
                        border-radius: 10px;
                        background-color: #eaf3f4;
                      "
                      v-model="happenDay_Selected"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '週'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1週', '每2週', '每3週', '每4週', '每5週']"
                      :selectValue="happenWeek_Selected"
                      :changingSelected="'happenWeek_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '月'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="happenMonthSelector2"
                      :selectValue="happenMonth_Selected"
                      :changingSelected="'happenMonth_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '年'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1年', '每2年', '每3年']"
                      :selectValue="happenYear_Selected"
                      :changingSelected="'happenYear_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div
                    class="ps-4 pe-2"
                    style="white-space: nowrap"
                    v-if="happenTime === '日'"
                  >
                    天，投放一次
                  </div>
                  <div class="ps-4 pe-2" style="white-space: nowrap" v-else>
                    投放一次
                  </div>
                </div>
                <div style="margin-bottom: 25px">
                  <Datepicker
                    locale="tw"
                    inputClassName="dp-custom-input-triggerscript"
                    cancelText="取消"
                    selectText="選擇"
                    v-model="daytime"
                    timePicker
                  >
                    <template #input-icon>
                      <img
                        src="../assets/time.svg"
                        alt=""
                        style="position: relative; right: -10px; top: -2px"
                      />
                    </template>
                  </Datepicker>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span style="line-height: 40px"
                    >結束次數
                    <BtnInfoBox content="系統會自動記錄每人在該週期所收到的次數，並且累計；不會因為模板更動、調整次數而導致重新計算累計次數。" direction="right"></BtnInfoBox>

                  </span>
                  <input
                    type="number"
                    class="text-center"
                    style="
                      width: 60%;
                      height: 40px;
                      border: none;
                      border-radius: 10px;
                      background-color: #eaf3f4;
                    "
                    v-model="endCount2"
                  />
                  <span>次</span>
                </div>
              </div>
              <div
                v-if="
                  happenTime === '日' && this.triggerSelected === '定期投放'
                "
              >
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-bottom: 25px"
                >
                  <div class="flex-fill">
                    <div
                      class="d-flex align-items-center justify-content-start"
                    >
                      <span class="me-4">每</span>
                      <input
                        type="number"
                        class="text-center"
                        style="
                          width: 90%;
                          height: 40px;
                          border: none;
                          border-radius: 10px;
                          background-color: #eaf3f4;
                        "
                        v-model="happenDay_Selected"
                      />
                    </div>
                  </div>
                  <div class="ps-4 pe-2" style="white-space: nowrap">
                    天，投放一次
                  </div>
                </div>
                <div style="margin-bottom: 25px">
                  <Datepicker
                    locale="tw"
                    inputClassName="dp-custom-input-triggerscript"
                    cancelText="取消"
                    selectText="選擇"
                    v-model="daytime"
                    timePicker
                  >
                    <template #input-icon>
                      <img
                        src="../assets/time.svg"
                        alt=""
                        style="position: relative; right: -10px; top: -2px"
                      />
                    </template>
                  </Datepicker>
                </div>
              </div>
              <div
                v-if="
                  happenTime === '週' && this.triggerSelected === '定期投放'
                "
              >
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-bottom: 25px"
                >
                  <div class="flex-fill">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1週', '每2週', '每3週', '每4週', '每5週']"
                      :selectValue="happenWeek_Selected"
                      :changingSelected="'happenWeek_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <span class="ms-4 me-2">投放一次</span>
                </div>
                <div style="margin-bottom: 25px">
                  <DropdownBlack
                    style="width: 100%"
                    :mxHeight="130"
                    :topSide="-130"
                    :valueArr="[
                      '星期一',
                      '星期二',
                      '星期三',
                      '星期四',
                      '星期五',
                      '星期六',
                      '星期日',
                    ]"
                    :selectValue="happenWeek2_Selected"
                    :changingSelected="'happenWeek2_Selected'"
                    v-on:selectValueByDrop="selectValueByDrop"
                  />
                </div>
                <div>
                  <Datepicker
                    locale="tw"
                    inputClassName="dp-custom-input-triggerscript"
                    cancelText="取消"
                    selectText="選擇"
                    v-model="daytime"
                    timePicker
                  >
                    <template #input-icon>
                      <img
                        src="../assets/time.svg"
                        alt=""
                        style="position: relative; right: -10px; top: -2px"
                      />
                    </template>
                  </Datepicker>
                </div>
              </div>
              <div
                v-if="
                  happenTime === '月' && this.triggerSelected === '定期投放'
                "
              >
                <div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                    style="margin-bottom: 25px"
                  >
                    <span class="me-4" v-if="this.happenMonth_moreoption"
                      >投放於</span
                    >
                    <span class="me-4" v-else>投放於每月</span>
                    <div class="flex-fill" v-if="!happenMonth_moreoption">
                      <DropdownBlack
                        :mxHeight="130"
                        :valueArr="happenMonthSelector"
                        :selectValue="happenMonth_Selected"
                        :changingSelected="'happenMonth_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                    </div>
                    <div class="flex-fill" v-else>
                      <DropdownBlack
                        :mxHeight="130"
                        :valueArr="happenMonthSelector2"
                        :selectValue="happenMonth2_Selected"
                        :changingSelected="'happenMonth2_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between"
                    style="margin-bottom: 25px"
                    v-if="happenMonth_moreoption"
                  >
                    <label
                      class="radio-container d-flex align-items-center"
                      style="width: 38%"
                      for="one"
                    >
                      <input
                        type="radio"
                        id="one"
                        value="One"
                        v-model="picked2"
                        name="group2"
                      />
                      <span class="checkmark" style="top: 11px"></span>
                      <span class="me-2">於</span>
                      <div class="flex-fill me-2">
                        <DropdownBlack
                          :mxHeight="130"
                          :topSide="-130"
                          :valueArr="happenMonthSelector"
                          :selectValue="happenMonth3_Selected"
                          :changingSelected="'happenMonth3_Selected'"
                          v-on:selectValueByDrop="selectValueByDrop"
                        />
                      </div>
                    </label>
                    <label
                      class="radio-container d-flex align-items-center"
                      style="width: 63%"
                      for="two"
                    >
                      <input
                        type="radio"
                        id="two"
                        value="Two"
                        v-model="picked2"
                        name="group2"
                      />
                      <span class="checkmark" style="top: 11px"></span>
                      <span class="me-2">於</span>
                      <div class="flex-fill d-flex justify-content-between">
                        <DropdownBlack
                          style="width: 52%"
                          :topSide="-130"
                          :mxHeight="130"
                          :valueArr="[
                            '第1個',
                            '第2個',
                            '第3個',
                            '第4個',
                            '最後一個',
                          ]"
                          :selectValue="happenMonth4_Selected"
                          :changingSelected="'happenMonth4_Selected'"
                          v-on:selectValueByDrop="selectValueByDrop"
                        />
                        <DropdownBlack
                          style="width: 45%"
                          :topSide="-130"
                          :mxHeight="130"
                          :valueArr="[
                            '星期一',
                            '星期二',
                            '星期三',
                            '星期四',
                            '星期五',
                            '星期六',
                            '星期日',
                          ]"
                          :selectValue="happenMonth5_Selected"
                          :changingSelected="'happenMonth5_Selected'"
                          v-on:selectValueByDrop="selectValueByDrop"
                        />
                      </div>
                    </label>
                  </div>
                  <div>
                    <Datepicker
                      locale="tw"
                      inputClassName="dp-custom-input-triggerscript"
                      cancelText="取消"
                      selectText="選擇"
                      v-model="daytime"
                      timePicker
                    >
                      <template #input-icon>
                        <img
                          src="../assets/time.svg"
                          alt=""
                          style="position: relative; right: -10px; top: -2px"
                        />
                      </template>
                    </Datepicker>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  happenTime === '年' && this.triggerSelected === '定期投放'
                "
              >
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-bottom: 25px"
                >
                  <span class="me-2">依據</span>
                  <div
                    style="
                      background-color: #eaf3f4;
                      border-radius: 10px;
                      width: 85%;
                    "
                  >
                    <button
                      class="basic-btn"
                      style="width: 50%"
                      :class="yearBasedWith === '日期' ? 'highlight' : ''"
                      @click="yearBasedWith = '日期'"
                    >
                      日期
                    </button>
                    <button
                      class="basic-btn"
                      style="width: 50%"
                      :class="yearBasedWith === '天' ? 'highlight' : ''"
                      @click="yearBasedWith = '天'"
                    >
                      天
                    </button>
                  </div>
                </div>
                <div v-if="yearBasedWith === '日期'">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="margin-bottom: 25px"
                  >
                    <span>投放於</span>
                    <div
                      class="d-flex justify-content-between"
                      style="width: 85%"
                    >
                      <DropdownBlack
                        style="width: 48%"
                        :mxHeight="130"
                        :topSide="-130"
                        :valueArr="this.happenYearMonthSelector"
                        :selectValue="happenYear_Selected"
                        :changingSelected="'happenYear_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                      <DropdownBlack
                        style="width: 48%"
                        :mxHeight="130"
                        :topSide="-130"
                        :valueArr="this.happenYearSelector"
                        :selectValue="happenYear2_Selected"
                        :changingSelected="'happenYear2_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                    </div>
                  </div>
                  <div style="width: 100%">
                    <Datepicker
                      locale="tw"
                      inputClassName="dp-custom-input-triggerscript"
                      cancelText="取消"
                      selectText="選擇"
                      v-model="daytime"
                      timePicker
                    >
                      <template #input-icon>
                        <img
                          src="../assets/time.svg"
                          alt=""
                          style="position: relative; right: -10px; top: -2px"
                        />
                      </template>
                    </Datepicker>
                  </div>
                </div>
                <div v-if="yearBasedWith === '天'">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="margin-bottom: 25px"
                  >
                    <span>投放於</span>
                    <div
                      class="d-flex justify-content-between"
                      style="width: 85%"
                    >
                      <DropdownBlack
                        style="width: 32%"
                        :mxHeight="130"
                        :topSide="-130"
                        :valueArr="this.happenYearMonthSelector"
                        :selectValue="happenYear3_Selected"
                        :changingSelected="'happenYear3_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                      <DropdownBlack
                        style="width: 32%"
                        :mxHeight="130"
                        :topSide="-130"
                        :valueArr="[
                          '第1個',
                          '第2個',
                          '第3個',
                          '第4個',
                          '最後一個',
                        ]"
                        :selectValue="happenYear4_Selected"
                        :changingSelected="'happenYear4_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                      <DropdownBlack
                        style="width: 32%"
                        :mxHeight="130"
                        :topSide="-130"
                        :valueArr="[
                          '星期一',
                          '星期二',
                          '星期三',
                          '星期四',
                          '星期五',
                          '星期六',
                          '星期日',
                        ]"
                        :selectValue="happenYear5_Selected"
                        :changingSelected="'happenYear5_Selected'"
                        v-on:selectValueByDrop="selectValueByDrop"
                      />
                    </div>
                  </div>
                  <div style="width: 100%">
                    <Datepicker
                      locale="tw"
                      inputClassName="dp-custom-input-triggerscript"
                      cancelText="取消"
                      selectText="選擇"
                      v-model="daytime"
                      timePicker
                    >
                      <template #input-icon>
                        <img
                          src="../assets/time.svg"
                          alt=""
                          style="position: relative; right: -10px; top: -2px"
                        />
                      </template>
                    </Datepicker>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column justify-content-between"
              style="width: 45%"
              :style="
                this.triggerSelected != '定期投放' ? 'width: 37%' : 'width: 34%'
              "
            >
              <div class="flex-fill">
                <p
                  style="color: #71afb6"
                  v-if="this.triggerSelected === '定期投放'"
                >
                  信寄出時間
                  <button
                    @click="aderExpSendDate"
                    style="background-color: white; border: none"
                  >
                    <a
                      href="javascript:;"
                      class="ms-3 me-1"
                      style="color: #c4c4c4"
                    >
                      試算
                    </a>
                    <img
                      src="../assets/doubleRightArrow.svg"
                      alt=""
                      class="mb-1"
                    />
                  </button>
                  <!-- <button @click="getCron">test</button>
                  <button @click="aderExpSendDate">ader api test</button> -->
                </p>
                <p
                  v-if="this.triggerSelected === '定期投放'"
                  style="height: 64px"
                >
                  第一封 - {{ exp_cycleTime_first_ader }}
                  <br />
                  第二封 - {{ exp_cycleTime_second_ader }}
                </p>
                <p style="color: #71afb6">發送說明</p>
                <p
                  v-if="
                    triggerSelected != '定期投放' &&
                    triggerSelected != '購物車未結'
                  "
                >
                  設定當顧客<span style="color: #71afb6"
                    >觸發該事件後過多久</span
                  >，系統即會開始寄信。您可選擇<span style="color: #71afb6"
                    >一次或者重複寄信。</span
                  >選擇週期性時，<span style="color: #71afb6"
                    >亦可設定重複幾次之後結束該劇本。</span
                  >完成後請到<span style="color: #71afb6">模板</span
                  >進行下一步。
                </p>
                <p v-else-if="triggerSelected === '購物車未結'">
                  設定當顧客<span style="color: #71afb6"
                    >加入購物車且在幾天內未進行結帳</span
                  >，系統即會開始寄信。您可選擇<span style="color: #71afb6"
                    >一次或者重複寄信。</span
                  >若選擇週期性時，<span style="color: #71afb6"
                    >亦可設定重複幾次之後結束該劇本。</span
                  >完成後請到<span style="color: #71afb6">模板</span
                  >進行下一步。
                </p>
                <p v-else-if="triggerSelected === '定期投放'">
                  上方時間為您設定的條件所寄出的第一與第二封，請先確認是否符合預期。完成後請到<span
                    style="color: #71afb6"
                    >模板</span
                  >進行下一步。
                </p>
                <p
                  style="color: #71afb6"
                  v-if="this.triggerSelected != '定期投放'"
                >
                  時間說明
                </p>
                <p v-if="triggerSelected === '購買後促銷'">
                  系統為您新增等候時間預設為1小時後。請依據您的需求進行調整。
                </p>
                <p v-else-if="triggerSelected === '註冊'">
                  系統為您新增等候時間預設為立即。請依據您的需求進行調整。
                </p>
                <p v-else-if="triggerSelected === '訂單確認'">
                  系統為您新增等候時間預設為立即。請依據您的需求進行調整。
                </p>
                <p v-else-if="triggerSelected === '購物車未結'">
                  系統為您新增等候時間預設為2天內。請依據您的需求進行調整。
                </p>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  class="col-3 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6; width: 47%"
                  @click="deleteTriggerTime"
                >
                  <img src="../assets/remove.svg" alt="" /> 移除
                </button>
                <button
                  class="col-3 btn-basic btn-save"
                  style="width: 47%"
                  @click="confirmUpdateTriggerTime"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between"
            style="margin: 0px 113px 9px 113px; min-height: 235px"
            v-if="this.tabState === 'event'"
          >
            <div style="width: 45%">
              <p style="color: #71afb6" class="d-flex justify-content-between">
                <span> 回應事件設定 </span>
                <span
                  style="cursor: pointer"
                  @click="this.event_moreoptionChange(true)"
                  v-if="!event_moreoption"
                >
                  更多條件選項...
                </span>
                <span
                  style="cursor: pointer"
                  @click="this.event_moreoptionChange(false)"
                  v-if="event_moreoption"
                >
                  隱藏更多條件選項...
                </span>
              </p>
              <div v-if="this.event_moreoption === false">
                <p>事件</p>
                <DropdownBlack
                  :valueArr="
                    !this.updateWho.includes('2')
                      ? ['開啟信件', '點擊連結', '轉換']
                      : ['點擊連結', '轉換']
                  "
                  :selectValue="eventSelected"
                  :changingSelected="'eventSelected'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
                <p style="margin-top: 25px; margin-bottom: 17px">
                  發生事件時間
                </p>
                <div class="d-flex justify-content-between">
                  <input
                    type="number"
                    class="select-style"
                    style="width: 46%"
                    v-model="time_interval_Selected"
                  />
                  <DropdownBlack
                    :topSide="-74"
                    style="width: 46%"
                    :valueArr="['小時內', '天內']"
                    :selectValue="time_state_Selected"
                    :changingSelected="'time_state_Selected'"
                    v-on:selectValueByDrop="selectValueByDrop"
                  />
                </div>
              </div>
              <div v-if="this.event_moreoption === true">
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin-bottom: 35px"
                >
                  <span>已滿足事件</span>
                  <div style="width: 77%">
                    <DropdownBlack
                      :valueArr="
                        !this.updateWho.includes('2')
                          ? ['已開啟信件', '已點擊連結']
                          : ['已點擊連結']
                      "
                      :selectValue="eventSelected"
                      :changingSelected="'eventSelected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="margin-bottom: 35px"
                >
                  <span>條件設定</span>
                  <div style="width: 77%">
                    <DropdownBlack
                      :valueArr="
                        this.eventSelected === '已開啟信件'
                          ? ['且點擊', '且轉換']
                          : ['且轉換']
                      "
                      :selectValue="event_moreoption_Selected"
                      :changingSelected="'event_moreoption_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <!-- <div
                    style="width: 77%"
                    v-if="this.eventSelected === '已點擊連結'"
                  >
                    <DropdownBlack
                      :valueArr="['且轉換']"
                      :selectValue="event_moreoption_Selected"
                      :changingSelected="'event_moreoption_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div> -->
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span>發生事件時間</span>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 77%"
                  >
                    <input
                      type="number"
                      class="select-style"
                      style="width: 46%"
                      v-model="time_interval_Selected"
                    />
                    <DropdownBlack
                      :topSide="-74"
                      style="width: 46%"
                      :valueArr="['小時內', '天內']"
                      :selectValue="time_state_Selected"
                      :changingSelected="'time_state_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style="width: 45%"
              class="d-flex flex-column justify-content-between"
            >
              <div class="flex-fill">
                <p style="color: #71afb6">事件時間說明</p>
                <p>
                  郵件發送後要讓收件人有<span style="color: #71afb6"
                    >足夠的時間完成回應事件(Response)</span
                  >，系統為您新增等候時間預設為 24 小時。<span
                    v-if="this.eventSelected != '轉換'"
                    >您亦可<span style="color: #71afb6">設定條件</span>，</span
                  >請依據需求進行調整，完成後請到<span style="color: #71afb6"
                    >時間</span
                  >進行下一步。
                </p>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  class="col-5 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6; width: 40%"
                  @click="deleteEventTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  style="width: 40%"
                  @click="confirmUpdateEventTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between"
            style="padding: 5px 50px 5px 60px"
            v-if="this.tabState === 'time'"
          >
            <div style="width: 30%">
              <p style="color: #71afb6; margin-bottom: 23px">發送時間設定</p>
              <p style="margin-bottom: 20px">經過多少時間寄第一封</p>
              <div
                class="d-flex justify-content-between"
                style="margin-bottom: 25px"
              >
                <input
                  type="number"
                  class="select-style"
                  style="width: 48%; border: 1px solid #c4c4c4"
                  v-model="time_after_Selected"
                />
                <DropdownBlack
                  style="width: 48%"
                  :valueArr="
                    this.currentTrigger === '訂單確認' &&
                    this.updateWho === 'trigger_time'
                      ? ['立即', '小時後', '天後']
                      : ['小時後', '天後']
                  "
                  :selectValue="time_after_state_Selected"
                  :changingSelected="'time_after_state_Selected'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
              </div>
              <p style="margin-bottom: 17px">發送方式</p>
              <div class="d-flex align-items-center">
                <label class="radio-container" for="一次性投放3">
                  <input
                    type="radio"
                    id="一次性投放3"
                    value="一次性"
                    v-model="picked3"
                    name="group3"
                  />
                  <span class="checkmark"></span>
                  一次性投放
                </label>
                <label class="radio-container" for="週期性投放2">
                  <input
                    type="radio"
                    id="週期性投放2"
                    value="週期性"
                    v-model="picked3"
                    name="group3"
                  />
                  <span class="checkmark"></span>
                  週期性投放
                </label>
              </div>
            </div>
            <div style="width: 26%; position: relative">
              <div
                style="
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  background-color: white;
                  opacity: 0.5;
                  z-index: 999;
                "
                v-if="picked3 === '一次性'"
              ></div>
              <p style="color: #71afb6; margin-bottom: 14px">寄完第一封後</p>
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 25px"
              >
                <span class="me-4"> 發生周期 </span>
                <div
                  class="d-flex justify-content-between flex-fill"
                  style="background-color: #eaf3f4; border-radius: 10px"
                >
                  <button
                    class="basic-btn"
                    :class="happenTime === '年' ? 'highlight' : ''"
                    @click="happenTime = '年'"
                  >
                    年
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '月' ? 'highlight' : ''"
                    @click="happenTime = '月'"
                  >
                    月
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '週' ? 'highlight' : ''"
                    @click="happenTime = '週'"
                  >
                    週
                  </button>
                  <button
                    class="basic-btn"
                    :class="happenTime === '日' ? 'highlight' : ''"
                    @click="happenTime = '日'"
                  >
                    日
                  </button>
                </div>
              </div>
              <div>
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-bottom: 25px"
                >
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-start
                      flex-fill
                    "
                    v-if="happenTime === '日'"
                  >
                    <span class="me-4">每</span>
                    <input
                      type="number"
                      class="mx-2 text-center"
                      style="
                        width: 90%;
                        height: 40px;
                        border: none;
                        border-radius: 10px;
                        background-color: #eaf3f4;
                      "
                      v-model="happenDay_Selected"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '週'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1週', '每2週', '每3週', '每4週', '每5週']"
                      :selectValue="happenWeek_Selected"
                      :changingSelected="'happenWeek_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '月'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="happenMonthSelector2"
                      :selectValue="happenMonth_Selected"
                      :changingSelected="'happenMonth_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div class="flex-fill" v-if="happenTime === '年'">
                    <DropdownBlack
                      :mxHeight="130"
                      :valueArr="['每1年', '每2年', '每3年']"
                      :selectValue="happenYear_Selected"
                      :changingSelected="'happenYear_Selected'"
                      v-on:selectValueByDrop="selectValueByDrop"
                    />
                  </div>
                  <div
                    class="ps-4 pe-2"
                    style="white-space: nowrap"
                    v-if="happenTime === '日'"
                  >
                    天，投放一次
                  </div>
                  <div class="ps-4 pe-2" style="white-space: nowrap" v-else>
                    投放一次
                  </div>
                </div>
                <div style="margin-bottom: 25px">
                  <Datepicker
                    locale="tw"
                    inputClassName="dp-custom-input-triggerscript"
                    cancelText="取消"
                    selectText="選擇"
                    v-model="daytime"
                    timePicker
                  >
                    <template #input-icon>
                      <img
                        src="../assets/time.svg"
                        alt=""
                        style="position: relative; right: -10px; top: -2px"
                      />
                    </template>
                  </Datepicker>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span style="line-height: 40px"
                    >結束次數
                    <BtnInfoBox content="系統會自動記錄每人在該週期所收到的次數，並且累計；不會因為模板更動、調整次數而導致重新計算累計次數。" direction="right"></BtnInfoBox>
                    
                  </span>
                  <input
                    type="number"
                    class="text-center"
                    style="
                      width: 181px;
                      height: 40px;
                      border: none;
                      border-radius: 10px;
                      background-color: #eaf3f4;
                    "
                    v-model="endCount2"
                  />
                  <span>次</span>
                </div>
              </div>
            </div>
            <div style="width: 35%">
              <p style="color: #71afb6">發送說明</p>
              <p>
                設定顧客<span style="color: #71afb6">觸發該事件後過多久</span
                >，系統即會開始寄信。您可選擇<span style="color: #71afb6"
                  >一次或者重複寄信。</span
                >選擇週期性時，<span style="color: #71afb6"
                  >亦可設定重複幾次之後結束該劇本。</span
                >完成後請到<span style="color: #71afb6">模板</span>進行下一步。
              </p>
              <p style="color: #71afb6">時間說明</p>
              <p>系統為您新增等候時間預設為7天後。請依據您的需求進行調整。</p>
              <div
                class="d-flex justify-content-between"
                style="margin-top: 17px"
              >
                <button
                  class="col-5 btn-basic"
                  style="background-color: #eaf3f4; color: #71afb6; width: 48%"
                  @click="deleteTimeTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  style="width: 48%"
                  @click="confirmUpdateTimeTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-between"
            style="padding: 5px 180px; height: 225px"
            v-if="this.tabState === 'action'"
          >
            <div style="width: 45%">
              <p style="color: #71afb6">動作設定</p>
              <DropdownBlack
                :valueArr="
                  !this.updateWho.includes('2') ? ['傳送Email'] : ['傳送SMS']
                "
                :selectValue="action_Selected"
                :changingSelected="'action_Selected'"
                v-on:selectValueByDrop="selectValueByDrop"
              />
            </div>
            <div
              style="width: 45%"
              class="d-flex flex-column justify-content-between"
            >
              <div class="flex-fill">
                <p style="color: #71afb6">動作說明</p>
                <p>設定下一個動作。</p>
              </div>
              <div class="d-flex justify-content-end">
                <button
                  class="col-5 btn-basic me-4"
                  style="background-color: #eaf3f4; color: #71afb6; width: 42%"
                  @click="deleteActionTag"
                >
                  <img src="../assets/remove.svg" alt="" class="mb-1" /> 移除
                </button>
                <button
                  class="col-5 btn-basic btn-save"
                  style="width: 42%"
                  @click="confirmUpdateActionTab"
                >
                  下一步
                  <img
                    src="../assets/white-rightArrow.png"
                    alt=""
                    style="margin-bottom: 3px"
                    class="ms-2"
                  />
                </button>
              </div>
            </div>
          </div>

          <!-- template -->
          <div
            class="d-flex flex-wrap justify-content-between px-5"
            v-if="this.tabState === 'template'"
          >
            <div v-if="this.emailChannel">
              <p
                class="col-12 d-flex justify-content-between"
                style="color: #71afb6"
              >
                <span> 郵件投放設定 </span>
              </p>
              <div class="d-flex col-12 justify-content-between">
                <div style="width: 29%">
                  <div class="align-items-center">
                    <label
                      for=""
                      class="col-form-label section-title"
                      style="width: 100%"
                      >模板名稱</label
                    >
                    <div style="width: 100%" class="mt-3">
                      <input
                        v-bind:class="{ 'is-invalid': titleError }"
                        ref="titleInput"
                        name="add-input"
                        class="form-control"
                        style="border-radius: 10px"
                        v-model="newTodoTitle"
                        @click="titleChange()"
                      />
                      <div class="invalid-feedback">
                        {{ titleErrMsg }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                      <div style="width: 47%">
                        <button
                          class="btn btn-primary add-template-button-color2"
                          style="width: 100%"
                          @click="showexp()"
                        >
                          預覽Email模板
                        </button>
                      </div>
                      <div style="width: 47%">
                        <button
                          class="add-template-button-color22"
                          style="width: 100%"
                          @click="edittodoinscript()"
                        >
                          編輯
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap" style="width: 38%">
                  <p class="m-0" style="color: #71afb6">說明</p>
                  <p style="line-height: 1.6">
                    當您將模板拖移至右方，該模板即會自動複製(1)，若要更改內容，請選擇複製後的模板並按左方編輯按鈕
                    ( 此動作並不會影響原有的模板內容 ) 。
                  </p>
                </div>
                <div style="width: 29%">
                  <div class="col-2">
                    <label
                      for="exampleFormControlInput1"
                      class="form-label"
                      style="line-height: 40px"
                      >主旨</label
                    >
                  </div>
                  <div class="col-12">
                    <input
                      v-bind:class="{ 'is-invalid': subError }"
                      class="form-control"
                      style="height: 40px"
                      ref="subInput"
                      placeholder=""
                      v-model="newSub"
                      @click="subChange()"
                    />
                    <div class="invalid-feedback">
                      {{ subErrMsg }}
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mt-4">
                    <div style="width: 47%">
                      <button
                        class="btn-basic"
                        style="
                          background-color: #eaf3f4;
                          color: #71afb6;
                          width: 100%;
                        "
                        @click="deleteTemplateTag"
                      >
                        <img
                          src="../assets/stop-icon-blue.svg"
                          alt="save"
                          style="position: relative; top: -2px"
                        />
                        &nbsp;&nbsp; 移除
                      </button>
                    </div>
                    <div style="width: 47%">
                      <button
                        class="btn btn-primary add-template-button-color2"
                        style="width: 100%; border-radius: 10px"
                        @click="confirmUpdateTemplateTab"
                      >
                        下一步
                        <img
                          src="../assets/white-rightArrow.png"
                          alt=""
                          style="margin-bottom: 3px"
                          class="ms-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-5 px-5" v-else>
              <p
                class="col-12 d-flex justify-content-between"
                style="color: #71afb6"
              >
                <span> 郵件投放設定 </span>
              </p>
              <div class="d-flex col-12 justify-content-between">
                <div style="width: 40%">
                  <div class="align-items-center">
                    <label
                      for=""
                      class="col-form-label section-title"
                      style="width: 100%"
                      >模板名稱</label
                    >
                    <div style="width: 100%" class="mt-3">
                      <input
                        v-bind:class="{ 'is-invalid': titleError }"
                        ref="titleInput"
                        name="add-input"
                        class="form-control"
                        style="border-radius: 10px"
                        v-model="newTodoTitle"
                        @click="titleChange()"
                        disabled
                      />
                      <div class="invalid-feedback">
                        {{ titleErrMsg }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                      <div style="width: 47%">
                        <button
                          class="btn btn-primary add-template-button-color2"
                          style="width: 100%"
                          @click="expshow(true)"
                        >
                          預覽簡訊模板
                        </button>
                      </div>
                      <div style="width: 47%">
                        <button
                          class="add-template-button-color22"
                          style="width: 100%"
                          @click="edittodoinscript()"
                        >
                          編輯
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap" style="width: 50%">
                  <p class="m-0" style="color: #71afb6">說明</p>
                  <p style="line-height: 1.6">
                    當您將模板拖移至右方，該模板即會自動複製(1)，若要更改內容，請選擇複製後的模板並按左方編輯按鈕
                    ( 此動作並不會影響原有的模板內容 ) 。
                  </p>
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div style="width: 47%">
                      <button
                        class="btn-basic"
                        style="
                          background-color: #eaf3f4;
                          color: #71afb6;
                          width: 100%;
                        "
                        @click="deleteTemplateTag"
                      >
                        <img
                          src="../assets/stop-icon-blue.svg"
                          alt="save"
                          style="position: relative; top: -2px"
                        />
                        &nbsp;&nbsp; 移除
                      </button>
                    </div>
                    <div style="width: 47%">
                      <button
                        class="btn btn-primary add-template-button-color2"
                        style="width: 100%; border-radius: 10px"
                        @click="confirmUpdateTemplateTab"
                      >
                        下一步
                        <img
                          src="../assets/white-rightArrow.png"
                          alt=""
                          style="margin-bottom: 3px"
                          class="ms-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- unlayer Modal -->
    <div
      class="modal fade"
      id="unlayerModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 100%">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body" style="height: 90vh">
            <iframe
              :src="this.url8081"
              height="100%"
              width="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- sms modal -->
    <div
      class="modal expEmailModal2-background"
      id="smsModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content" style="width: 100%">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="p-5 pt-4 pb-3">
            <p style="font-size: 24px">簡訊模板</p>
            <div
              style="
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                border-radius: 10px;
              "
            >
              <div
                class="modal-body client-modal-body p-0"
                style="border-radius: 10px"
              >
                <div
                  class="container mb-3"
                  style="background-color: white; border-radius: 10px"
                >
                  <div class="pt-4 pb-1 px-2 mt-3 form-container">
                    <div
                      class="row justify-content-xl-between align-items-center"
                    >
                      <span class="col-3" style="color: #71afb6">
                        模板內容
                      </span>
                      <div class="col-3">
                        <button
                          class="basic-btn highlight-SmsTemp"
                          style="cursor: pointer; color: white"
                          @click="showVar"
                        >
                          <div
                            class="
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <img
                              src="../assets/插-icon.svg"
                              alt=""
                              class="pe-2 pt-1"
                            />

                            <span>插入個人化變數</span>
                          </div>
                        </button>
                      </div>
                    </div>

                    <div class="row pt-3">
                      <label for="" style="width: 13%; color: #71afb6"
                        >模板名稱</label
                      >
                      <div style="width: 87%">
                        <input
                          v-bind:class="{ 'is-invalid': titleError2 }"
                          ref="titleInput2"
                          name="add-input"
                          class="form-control"
                          v-model="smsTitle"
                          @click="titleChange()"
                        />
                        <div class="invalid-feedback">
                          {{ titleErrMsg2 }}
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 row mt-4">
                      <div style="width: 13%">
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label"
                          >簡訊內容</label
                        >
                      </div>
                      <div style="width: 87%">
                        <textarea
                          v-bind:class="{ 'is-invalid': textError }"
                          ref="textInput"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          v-model="newSubsec"
                          :todo="todos"
                          style="height: 130px"
                          @click="textChange()"
                        ></textarea>
                        <div class="invalid-feedback">
                          {{ textErrMsg }}
                        </div>
                        <div>
                          中英數簡訊一封70字元
                          (縮址佔37字元)，若超過則會分為兩則。
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div style="width: 13%">
                        <span class="banner-span" style="line-height: 56px"
                          >點擊追蹤</span
                        >
                      </div>
                      <div style="width: 87%">
                        <p class="upload-sub mt-3" style="color: #71afb6">
                          請輸入原始網址連結，系統將會自動轉為縮址以便追蹤，建議點擊預覽模板即可測試並觀看縮址後的結果。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-end mt-4 mb-2"
              v-if="this.editScriptSms === false"
            >
              <button
                type="button"
                class="btn btn-primary me-4"
                style="
                  width: 180px;
                  background-color: #eaf3f4;
                  color: #71afb6;
                  border: none;
                  border-radius: 10px;
                "
                @click="expshow(false)"
              >
                <img
                  src="../assets/green-eye.svg"
                  style="width: 1.3rem"
                  class="me-2"
                />
                預覽
              </button>
              <button
                v-on:click="addNewSms"
                class="basic-btn highlight2"
                style="width: 20%; cursor: pointer"
                v-if="this.selectedID === ''"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/save-icon.svg" alt="" class="pe-2 mt-1" />
                  <span> 新建</span>
                </div>
              </button>
              <button
                v-on:click="updateNewSms"
                class="basic-btn highlight2"
                style="width: 20%; cursor: pointer"
                v-else
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="../assets/save-icon.svg"
                    alt=""
                    style="position: relative; top: 1.5px"
                    class="pe-2 me-2"
                  />
                  <span> 儲存</span>
                </div>
              </button>
            </div>
            <div class="d-flex justify-content-end mt-4 mb-3" v-else>
              <button
                type="button"
                class="btn btn-primary me-4"
                style="
                  width: 180px;
                  background-color: #eaf3f4;
                  color: #71afb6;
                  border: none;
                  border-radius: 10px;
                "
                @click="expshow(false)"
              >
                <img
                  src="../assets/green-eye.svg"
                  style="width: 1.3rem"
                  class="me-2"
                />
                預覽
              </button>
              <button
                v-on:click="updateSmsScriptTemp()"
                class="basic-btn highlight2"
                style="width: 20%; cursor: pointer"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/save-icon.svg" alt="" class="pe-2 mt-1" />
                  <span> 儲存</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sms exp modal -->
    <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>

    <!-- alert儲存成功Modal -->
    <div
      class="modal fade"
      id="alertModal3"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body text-center">儲存成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert添加模板成功Modal -->
    <div
      class="modal fade"
      id="alertModal4"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">添加成功</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert刪除劇本成功Modal -->
    <div
      class="modal fade"
      id="alertModal5"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert執行Modal -->
    <div
      class="modal fade"
      id="alertModal6"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            確認後系統將自動執行該流程
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-basic"
              style="background-color: #eaf3f4; width: 10vw"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-4"
              data-bs-dismiss="modal"
              @click="runScript"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert暫停Modal -->
    <div
      class="modal fade"
      id="alertModal7"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            確認後系統將暫停執行該流程
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-basic"
              style="background-color: #eaf3f4; width: 10vw"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close ms-4"
              data-bs-dismiss="modal"
              @click="stopScript"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- choose label alert -->
    <div
      class="modal fade"
      id="alertSaveScriptModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            是否在離開前儲存此行銷劇本?
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              離開
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              @click="saveScript()"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container home">
      <div class="d-flex mb-4">
        <div style="width: 352px">
          <span class="script-header">
            <img
              src="../assets/back-arrow.png"
              @click="gobacktotriggerscript"
              style="cursor: pointer"
            />
            &nbsp; &nbsp; 自動化劇本</span
          >
          <!-- <button @click="test">test</button> -->
        </div>
        <div style="position: relative" class="right-contain flex-fill">
          <span
            class="nowork"
            v-if="this.eventScriptHasEventFromSche === '0'"
          ></span>
          <span class="notwork" v-if="this.eventScriptHasEventFromSche === '1'"
            >草稿</span
          >
          <span
            class="work"
            v-if="
              this.eventScriptHasEventFromSche === '2' &&
              this.scriptDayStart_sure <= new Date().toISOString().slice(0, 10)
            "
            >執行中</span
          >
          <span
            class="work"
            style="background-color: #dbebba"
            v-if="
              this.eventScriptHasEventFromSche === '2' &&
              this.scriptDayStart_sure > new Date().toISOString().slice(0, 10)
            "
            >啟用中</span
          >
          <div class="script-tip">
            <div
              style="width: 30px; height: 30px; position: absolute"
              @mouseover="scriptTipShow = true"
              @mouseout="scriptTipShow = false"
            ></div>
            <img src="../assets/scriptTip1.svg" alt="" />
            <div class="parent-popout">
              <div
                v-show="scriptTipShow"
                class="child-popout-inAfterTriggerScript-first"
              >
                請按照著顏色順序依序拖曳至樹狀圖。
                <div class="container__arrow-inAfterTriggerScript-first"></div>
              </div>
            </div>
          </div>
        </div>
        <div style="inline-block" class="zoom d-flex align-items-center">
          <button
            @click="zoomin"
            style="
              border-radius: 10px;
              border: none;
              width: 30px;
              height: 30px;
              font-size: 20px;
            "
            class="me-2"
          >
            +
          </button>
          <button
            @click="zoomout"
            style="
              border-radius: 10px;
              border: none;
              width: 30px;
              height: 30px;
              font-size: 24px;
            "
            class="me-2"
          >
            -
          </button>
          <button
            @click="zoomreset"
            style="border-radius: 10px; border: none; width: 30px; height: 30px"
            class="pb-1"
          >
            <img src="../assets/resetZoom.png" alt="" class="" />
          </button>
        </div>
      </div>
      <div class="d-flex">
        <div class="me-4" style="width: 320px">
          <div class="contain-left-all">
            <!-- Tabs -->
            <div class="tab-div">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link active col-12 p-2"
                    id="event-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#event"
                    type="button"
                    role="tab"
                    aria-controls="event"
                    aria-selected="true"
                    @click="this.tabState = 'event'"
                  >
                    事件
                  </button>
                </li>
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link col-12 p-2"
                    id="time-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#time"
                    type="button"
                    role="tab"
                    aria-controls="time"
                    aria-selected="false"
                    @click="this.tabState = 'time'"
                  >
                    時間
                  </button>
                </li>
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link col-12 p-2"
                    id="action-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#action"
                    type="button"
                    role="tab"
                    aria-controls="action"
                    aria-selected="false"
                    @click="this.tabState = 'action'"
                  >
                    動作
                  </button>
                </li>
                <li class="nav-item col-3" role="presentation">
                  <button
                    class="nav-link col-12 p-2"
                    id="template-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#template"
                    type="button"
                    role="tab"
                    aria-controls="template"
                    aria-selected="false"
                    @click="this.tabState = 'template'"
                  >
                    <!-- @click="offCanvas()" -->
                    模板
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="tab-content contain-left-trigger-script"
              id="myTabContent"
            >
              <!-- 事件div -->

              <div
                class="tab-pane fade show active"
                style="padding: 30px 0"
                id="event"
                role="tabpanel"
                aria-labelledby="event-tab"
              >
                <div class="mb-4 ms-3">觸發事件 (Trigger)</div>
                <div class="d-flex flex-wrap mb-3">
                  <div
                    class="text-center col-4 mb-2"
                    style="opacity: 0.5"
                    v-tooltip.bottom="{
                      value: '僅供 未來新加入名單 使用',
                      class: 'tip-LabelManage',
                    }"
                    v-if="!this.cantSignup"
                  >
                    <div
                      class="purple-tag2 signup-image"
                      style="cursor: auto; padding: 7px"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px">註冊</span>
                  </div>
                  <div
                    class="text-center col-4 mb-2"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                    v-else
                  >
                    <div
                      class="purple-tag2 signup-image"
                      style="cursor: grab; padding: 7px"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px">註冊</span>
                  </div>
                  <div
                    class="text-center col-4 mb-2"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="purple-tag2 shopcar-image"
                      style="cursor: grab; padding: 16px 9.6px"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >購物車未結</span
                    >
                  </div>
                  <div
                    class="text-center col-4 mb-2"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="purple-tag2 Order-done"
                      style="cursor: grab; padding: 16px 18.4px"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >訂單確認</span
                    >
                  </div>
                  <div
                    class="text-center col-4 mb-2"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="purple-tag2 buy-image"
                      style="cursor: grab; padding: 16px 12.8px"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >購買後促銷</span
                    >
                  </div>
                  <div
                    class="text-center col-4 mb-2"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="purple-tag2 cycleTime-image"
                      style="cursor: grab; padding: 16px 16px"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >定期投放</span
                    >
                  </div>
                </div>
                <hr
                  style="
                    width: 100%;
                    margin: 16px 0px;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div class="d-flex flex-wrap mt-4">
                  <div class="mb-4 ms-3 col-12">回應事件 (Response)</div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 mail-image"
                      style="cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >開啟信件</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 click-image"
                      style="cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >點擊連結</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 change-image"
                      style="cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px">轉換</span>
                  </div>
                </div>
              </div>
              <!-- 時間div -->
              <div
                class="tab-pane fade text-center"
                style="padding: 30px 0"
                id="time"
                role="tabpanel"
                aria-labelledby="time-tab"
              >
                <div class="d-flex flex-wrap">
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 hourglass-image"
                      style="
                        background-color: #fc9f66;
                        cursor: grab;
                        padding: 16px 20.7px;
                      "
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >時間內</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 clock-image"
                      style="background-color: #fc9f66; cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >時間後</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 cycleTime2-image"
                      style="background-color: #fc9f66; cursor: grab"
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >定期時間</span
                    >
                  </div>
                </div>
              </div>
              <!-- 動作div -->
              <div
                class="tab-pane fade text-center"
                style="padding: 30px 0"
                id="action"
                role="tabpanel"
                aria-labelledby="action-tab"
              >
                <div class="d-flex">
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 sendmail-image"
                      style="
                        background-color: #f9c357;
                        padding: 24px 8px;
                        cursor: grab;
                      "
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >傳送Email</span
                    >
                  </div>
                  <div
                    class="text-center col-4"
                    draggable="true"
                    @dragstart="dragStart($event)"
                    @dragover="allowDrop"
                  >
                    <div
                      class="blue-tag2 sendsms-image"
                      style="
                        background-color: #f9c357;
                        padding: 20px 16px;
                        cursor: grab;
                      "
                    ></div>
                    <span style="font-size: 14px; margin-top: 10px"
                      >傳送SMS</span
                    >
                  </div>
                </div>
              </div>
              <!-- 模板div -->
              <div
                class="tab-pane fade text-center"
                style="padding: 30px 0; position: relative"
                id="template"
                role="tabpanel"
                aria-labelledby="template-tab"
              >
                <div class="group-div">
                  <button
                    class="trigger-channel-btn"
                    :style="
                      emailChannel
                        ? 'background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);color:white'
                        : ''
                    "
                    @click="changeChannel(true)"
                  >
                    Email
                  </button>
                  <button
                    class="trigger-channel-btn"
                    :style="
                      emailChannel
                        ? ''
                        : 'background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);color:white'
                    "
                    @click="changeChannel(false)"
                  >
                    SMS
                  </button>
                </div>
                <ProductList
                  v-bind:todos="todos"
                  v-bind:emailChannel="emailChannel"
                  ref="RefChilde"
                  @showrename-todo="renametodo"
                  @clearalltoadd="clearalltoadd"
                  @copy-todo="copytodo"
                  @toggle-value="selectValue"
                  @refresh-todos="refreshtodo"
                  @edit-todo="edittodo"
                  v-on:changeLoading="changeLoading"
                  v-bind:selectedID="selectedID"
                  v-on:search="search"
                />
              </div>
            </div>
          </div>
          <div
            class="shodow-box-afterTriggerScript"
            style="padding: 15px 12px; font-size: 14px"
          >
            <p>
              <span style="margin-right: 15px; color: #c4c4c4">活動名稱</span
              ><span>{{ script_title_sure }}</span>
            </p>
            <p class="d-flex align-items-center">
              <span style="margin-right: 15px; color: #c4c4c4">選擇名單</span
              ><span
                class="select-label-bottom-span"
                style="margin-right: 10px"
                >{{
                  groupName.includeLabelNames.toString().replace(/,/g, "、")
                }}</span
              ><span
                class="flex-fill text-end"
                style="
                  font-family: sans-serif;
                  color: #c4c4c4;
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 12px;
                "
                @click="openExcludeModal()"
                >瀏覽完整名單</span
              >
            </p>
            <p class="d-flex">
              <span style="margin-right: 15px; color: #c4c4c4">劇本時間</span
              ><span style="margin-right: 10px">
                <span>{{ scriptDayStart_sure }}</span>
                <br />
                <span v-if="scriptDayEnd != ''"> {{ scriptDayEnd_sure }}</span>
                <span v-if="scriptDayState_sure === '永久'">永久</span>
              </span>
              <span
                class="flex-fill text-end"
                style="
                  font-weight: 100;
                  color: #c4c4c4;
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: 12px;
                "
                @click="
                  scriptTimeSetting();
                  this.goingToRunScript2 = false;
                "
                >設定劇本時間</span
              >
            </p>
          </div>
        </div>
        <!-- tree -->
        <div
          style="position: relative; max-width: calc(100% - 354px)"
          class="ps-2 right-contain flex-fill"
        >
          <div
            class="position-fixed"
            style="z-index: 11; top: 127px; right: calc((83.34% - 634px) / 2)"
          >
            <div
              id="liveToast"
              class="toast"
              style="width: auto; box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              data-bs-delay="3000"
            >
              <div class="d-flex">
                <div
                  style="width: 55px; height: 55px; background-color: #ff614d"
                  class="d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../assets/whiteBigX.svg"
                    alt=""
                    style="width: 22px; display: block"
                  />
                </div>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-fill
                  "
                  style="padding: 15px 20px"
                >
                  <span class="me-2"> 此處應放 {{ errorDrop }} </span>
                  <button
                    style="border: none; background-color: white"
                    class="d-flex justify-content-center align-items-center"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                  >
                    <img
                      src="../assets/blackX.svg"
                      alt=""
                      style="width: 12px"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="treeDiv"
            class="contain-right-top"
            style="padding-top: 30px; cursor: grab"
            :style="grabbing ? 'cursor: grabbing' : ''"
            @dragover="allowDrop"
            @drop="dropTrigger($event)"
            @mousedown="this.grabbing = true"
            @mouseup="this.grabbing = false"
            v-dragscroll
          >
            <div
              class="text-center"
              style="width: 600px; margin: 0 auto"
              :style="[fat ? 'width: 1200px' : '', zoom]"
            >
              <!-- <div
                class="tree-tag"
                style="color: #71afb6; width: 230px; position: relative"
                @click="updateGroup"
              >
                <span
                  style="position: absolute; top: -15px; right: -15px"
                  @click="clearScript"
                  v-if="
                    this.eventScriptHasEventFromSche === '1' ||
                    this.eventScriptHasEventFromSche === '2'
                  "
                >
                  <img src="../assets/deleteScript.svg" alt="" />
                </span>
                {{ groupName.includeLabelNames.toString() }}
              </div> -->

              <!-- <div class="d-flex justify-content-center col-12">
                <img src="../assets/treeline.svg" alt="" />
              </div> -->
              <!-- 樹狀圖 -->
              <div
                v-if="this.currentTrigger != null"
                style="height: 350px"
                @dragover="allowDrop"
                @drop.stop="
                  dropTrigger($event);
                  dropTriggerTime($event);
                "
              >
                <div
                  class="purple-tag"
                  style="position: relative"
                  :style="
                    this.currentTrigger === '購買後促銷' &&
                    this.triggerBuy.value != ''
                      ? 'padding:5px 0px'
                      : ''
                  "
                  @click="updateTriggerEvent"
                >
                  <span
                    style="position: absolute; top: -15px; right: -15px"
                    @click="clearScript"
                    v-if="
                      this.eventScriptHasEventFromSche === '1' ||
                      this.eventScriptHasEventFromSche === '2'
                    "
                  >
                    <img src="../assets/deleteScript.svg" alt="" />
                  </span>
                  <img
                    src="../assets/signup.svg"
                    style="
                      width: 63px;
                      position: relative;
                      top: -10px;
                      left: -1px;
                    "
                    alt=""
                    v-if="this.currentTrigger === '註冊'"
                  />
                  <img
                    src="../assets/shopcar.svg"
                    style="width: 54px"
                    alt=""
                    v-if="this.currentTrigger === '購物車未結'"
                  />
                  <img
                    src="../assets/buy.svg"
                    style="width: 45px"
                    alt=""
                    v-if="this.currentTrigger === '購買後促銷'"
                  />
                  <img
                    src="../assets/Order-done.svg"
                    style="width: 40px; position: relative; left: 1px"
                    alt=""
                    v-if="this.currentTrigger === '訂單確認'"
                  />
                  <img
                    src="../assets/cycleTime.svg"
                    style="width: 40px"
                    alt=""
                    v-if="this.currentTrigger === '定期投放'"
                  />
                  <div
                    style="
                      color: white;
                      position: relative;
                      top: -18px;
                      font-size: 14px;
                      margin-top: 5px;
                    "
                    v-if="this.currentTrigger === '註冊'"
                  >
                    {{ this.currentTrigger }}
                  </div>
                  <div
                    style="color: white; font-size: 14px; margin-top: 4px"
                    v-else
                  >
                    {{ this.currentTrigger }}
                  </div>
                  <div
                    class="triggerBuy-value"
                    v-if="
                      this.currentTrigger === '購買後促銷' &&
                      this.triggerBuy.value != ''
                    "
                  >
                    {{ this.triggerBuy.value }}
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div
                  style="position: relative"
                  v-if="this.time_trigger_state != ''"
                  @drop.stop="dropTriggerTime($event)"
                >
                  <img
                    style="
                      position: absolute;
                      top: -10px;
                      right: calc(50% - 50px);
                    "
                    src="../assets/redalert.svg"
                    alt=""
                    v-if="
                      this.currentTrigger != '定期投放' &&
                      this.ader_triggerCron === '' &&
                      this.cycleTime_trigger === '週期性'
                    "
                  />
                  <img
                    style="
                      position: absolute;
                      top: -10px;
                      right: calc(50% - 50px);
                    "
                    src="../assets/redalert.svg"
                    alt=""
                    v-if="
                      this.currentTrigger === '定期投放' &&
                      this.ader_triggerCron === ''
                    "
                  />
                  <div
                    class="time-tag2"
                    @click="updateTriggerTime"
                    v-if="this.currentTrigger === '購物車未結'"
                  >
                    <img
                      src="../assets/hourglass.svg"
                      alt=""
                      style="width: 31.5px"
                    />
                    <div class="mt-1" style="color: white; font-size: 14px">
                      <p class="m-0" style="font-size: 14px">
                        {{ this.time_trigger }} {{ this.time_trigger_state }}
                      </p>
                      <p class="m-0" style="font-size: 12px">
                        {{ this.cycleTime_trigger }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="time-tag2"
                    style="padding-top: 10px"
                    @click="updateTriggerTime"
                    v-else-if="this.currentTrigger === '定期投放'"
                  >
                    <img src="../assets/cycleTime2.svg" alt="" />
                    <div class="mt-1" style="color: white; font-size: 14px">
                      <p class="m-0" style="font-size: 14px">
                        {{ this.cycleTimeinCycle }}
                      </p>
                    </div>
                  </div>
                  <div class="time-tag2" @click="updateTriggerTime" v-else>
                    <img src="../assets/clock.svg" alt="" style="width: 40px" />
                    <div class="mt-1" v-if="this.time_trigger_state === '立即'">
                      <p class="m-0" style="font-size: 14px">
                        {{ this.time_trigger_state }}
                      </p>
                      <p class="m-0" style="font-size: 12px">
                        {{ this.cycleTime_trigger }}
                      </p>
                    </div>
                    <div class="mt-1" style="color: white" v-else>
                      <p class="m-0" style="font-size: 14px">
                        {{ this.time_trigger }} {{ this.time_trigger_state }}
                      </p>
                      <p class="m-0" style="font-size: 12px">
                        {{ this.cycleTime_trigger }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div class="d-flex justify-content-center">
                    <hr
                      class="m-0"
                      style="
                        border: none;
                        height: 0;
                        border-top: 2px solid #71afb6;
                        width: 50%;
                        opacity: 1;
                      "
                    />
                  </div>
                  <div class="d-flex">
                    <div class="col-6" @dragover="allowDrop">
                      <!-- @drop.stop="dropTemplateAtEmail" -->
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="action-tag2">
                        <img src="../assets/sendmail.svg" class="mb-2" alt="" />
                        <span
                          style="position: relative; left: 2px; font-size: 14px"
                        >
                          傳送Email
                        </span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                    </div>
                    <div class="col-6" @dragover="allowDrop">
                      <!-- @drop.stop="dropTemplateAtSms" -->
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="action-tag3">
                        <img
                          src="../assets/sendsms.svg"
                          style="position: relative; left: 2px"
                          alt=""
                        />
                        <br />
                        <span style="position: relative; font-size: 14px">
                          傳送SMS
                        </span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex"
                v-if="
                  this.currentTrigger != null && this.time_trigger_state != ''
                "
              >
                <div
                  class="col-6"
                  style="height: 300px"
                  @drop.stop="dropTemplateAtEmail"
                >
                  <div style="position: relative; width: 105px; margin: 0 auto">
                    <div
                      style="position: absolute; top: -13px; right: 0px"
                      v-if="
                        this.email_subject === '' && this.template_email != ''
                      "
                    >
                      <img src="../assets/redalert.svg" alt="" />
                    </div>
                    <div
                      class="template-tag"
                      style="color: #71afb6"
                      @click="updateEmailTemplate"
                      v-if="this.template_email != ''"
                    >
                      {{ template_email }}
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    v-if="this.template_email != ''"
                  >
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div
                    class="col-12"
                    style="height: 300px"
                    @dragover="allowDrop"
                    @drop.stop="dropEvent"
                    v-if="this.template_email != ''"
                  >
                    <div
                      style="background-color: white"
                      class="tree-div text-center"
                      v-if="this.currentEvent != null"
                    >
                      <div class="blue-tag" @click="updateEventTimeInterval">
                        <img
                          src="../assets/hourglass.svg"
                          alt=""
                          style="width: 31px"
                        />
                        <div class="mt-2">
                          {{ this.time_interval }}{{ this.time_state }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div
                        class="blue-tag3"
                        :style="
                          this.currentEvent === '已開啟信件且點擊' ||
                          this.currentEvent === '已開啟信件且轉換' ||
                          this.currentEvent === '已點擊連結且轉換'
                            ? 'padding-top:4px'
                            : ''
                        "
                        @click="updateEventTimeInterval"
                      >
                        <img
                          src="../assets/openmail.svg"
                          style="width: 40px"
                          alt=""
                          v-if="this.currentEvent.includes('開啟信件')"
                        />
                        <img
                          src="../assets/clicklink.svg"
                          style="width: 40px"
                          alt=""
                          v-if="this.currentEvent.includes('點擊連結')"
                        />
                        <img
                          src="../assets/change.svg"
                          style="width: 40px"
                          alt=""
                          v-if="this.currentEvent === '轉換'"
                        />
                        <div
                          style="
                            width: 100px;
                            position: relative;
                            left: -30px;
                            top: 3px;
                            color: white;
                          "
                          v-if="
                            this.currentEvent === '已開啟信件且點擊' ||
                            this.currentEvent === '已開啟信件且轉換' ||
                            this.currentEvent === '已點擊連結且轉換'
                          "
                        >
                          {{ this.currentEvent.slice(0, -3) }}
                          <br />
                          <span style="font-size: 12px">{{
                            this.currentEvent.slice(-3)
                          }}</span>
                        </div>
                        <div
                          style="
                            width: 100px;
                            position: relative;
                            left: -30px;
                            top: 5px;
                            color: white;
                          "
                          v-else
                        >
                          {{ this.currentEvent }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="d-flex justify-content-center">
                        <hr
                          class="m-0"
                          style="
                            border: none;
                            height: 0;
                            border-top: 2px solid #71afb6;
                            width: 50%;
                            opacity: 1;
                          "
                        />
                      </div>

                      <div class="d-flex">
                        <!-- Yes -->

                        <div
                          class="col-6"
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropTimeAtYes($event)"
                        >
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div class="yes-tag">是</div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div v-if="yes_time_after !== ''">
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropActionAtYes($event)"
                            >
                              <div
                                class="time-tag"
                                style="position: relative"
                                @click="updateYesTime"
                              >
                                <img
                                  src="../assets/redalert.svg"
                                  alt=""
                                  style="
                                    position: absolute;
                                    top: -13px;
                                    right: 0px;
                                  "
                                  v-if="
                                    this.ader_yes_triggerCron === '' &&
                                    this.cycleTime_yes != '一次性'
                                  "
                                />
                                <img
                                  src="../assets/clock.svg"
                                  alt=""
                                  style="width: 40px"
                                />
                                <div class="mt-1">
                                  <p class="m-0" style="font-size: 14px">
                                    {{ yes_time_after }}
                                    {{ yes_time_after_state }}
                                  </p>
                                  <p class="m-0" style="font-size: 12px">
                                    {{ cycleTime_yes }}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div
                                style="background-color: white; height: 400px"
                                @dragover="allowDrop"
                                @drop.stop="dropTemplateAtYes($event)"
                                v-if="yes_action != ''"
                              >
                                <div
                                  class="action-tag2"
                                  @click="updateYesAction"
                                >
                                  <img
                                    src="../assets/sendmail.svg"
                                    class="mb-2"
                                    alt=""
                                  />
                                  <span
                                    style="
                                      position: relative;
                                      left: 2px;
                                      font-size: 14px;
                                    "
                                  >
                                    {{ yes_action }}
                                  </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropEventAtYes($event)"
                                  v-if="yes_template != ''"
                                >
                                  <div
                                    style="
                                      position: relative;
                                      width: 105px;
                                      margin: 0 auto;
                                    "
                                  >
                                    <div
                                      style="
                                        position: absolute;
                                        top: -13px;
                                        right: 0px;
                                        z-index: 99;
                                      "
                                      v-if="this.yes_subject === ''"
                                    >
                                      <img
                                        src="../assets/redalert.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      class="template-tag"
                                      style="color: #71afb6; position: relative"
                                      @click="updateYesTemplate($event)"
                                    >
                                      {{ yes_template }}
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <!-- UnderYes -->
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                      width: 300px;
                                    "
                                    v-if="this.currentEventYes != null"
                                  >
                                    <div
                                      class="blue-tag"
                                      @click="updateYesEventTimeInterval"
                                    >
                                      <img
                                        src="../assets/hourglass.svg"
                                        style="width: 31px"
                                        alt=""
                                      />
                                      <div class="mt-2">
                                        {{ this.yes_time_interval
                                        }}{{ this.yes_time_state }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      class="blue-tag3"
                                      :style="
                                        this.currentEventYes ===
                                          '已開啟信件且點擊' ||
                                        this.currentEventYes ===
                                          '已開啟信件且轉換' ||
                                        this.currentEventYes ===
                                          '已點擊連結且轉換'
                                          ? 'padding-top:4px'
                                          : ''
                                      "
                                      @click="updateYesEventTimeInterval"
                                    >
                                      <img
                                        src="../assets/openmail.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="
                                          this.currentEventYes.includes(
                                            '開啟信件'
                                          )
                                        "
                                      />
                                      <img
                                        src="../assets/clicklink.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="
                                          this.currentEventYes.includes(
                                            '點擊連結'
                                          )
                                        "
                                      />
                                      <img
                                        src="../assets/change.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="this.currentEventYes === '轉換'"
                                      />
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 3px;
                                          color: white;
                                        "
                                        v-if="
                                          this.currentEventYes ===
                                            '已開啟信件且點擊' ||
                                          this.currentEventYes ===
                                            '已開啟信件且轉換' ||
                                          this.currentEventYes ===
                                            '已點擊連結且轉換'
                                        "
                                      >
                                        {{ this.currentEventYes.slice(0, -3) }}
                                        <br />
                                        <span style="font-size: 12px">{{
                                          this.currentEventYes.slice(-3)
                                        }}</span>
                                      </div>
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 5px;
                                          color: white;
                                        "
                                        v-else
                                      >
                                        {{ this.currentEventYes }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <hr
                                        class="m-0"
                                        style="
                                          border: none;
                                          height: 0;
                                          border-top: 2px solid #71afb6;
                                          width: 50%;
                                          opacity: 1;
                                        "
                                      />
                                    </div>
                                    <div class="d-flex">
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtYesYes($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="yes-tag">是</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          v-if="this.yesyes_time_after !== ''"
                                        >
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtYesYes($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateYesYesTime"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_yesyes_triggerCron ===
                                                    '' &&
                                                  this.cycleTime_yesyes !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ yesyes_time_after }}
                                                  {{ yesyes_time_after_state }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_yesyes }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtYesYes($event)
                                              "
                                              v-if="this.yesyes_action != ''"
                                            >
                                              <div
                                                class="action-tag2"
                                                @click="updateYesYesAction"
                                              >
                                                <img
                                                  src="../assets/sendmail.svg"
                                                  class="mb-2"
                                                  alt=""
                                                />
                                                <span
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ yesyes_action }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                v-if="
                                                  this.yesyes_template != ''
                                                "
                                              >
                                                <div
                                                  style="
                                                    position: relative;
                                                    width: 105px;
                                                    margin: 0 auto;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      position: absolute;
                                                      top: -13px;
                                                      right: 0px;
                                                      z-index: 99;
                                                    "
                                                    v-if="
                                                      this.yesyes_subject === ''
                                                    "
                                                  >
                                                    <img
                                                      src="../assets/redalert.svg"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div
                                                    class="template-tag"
                                                    style="color: #71afb6"
                                                    @click="
                                                      updateYesYesTemplate
                                                    "
                                                  >
                                                    {{ this.yesyes_template }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtYesNo($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="no-tag">否</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          v-if="this.yesno_time_after !== ''"
                                        >
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtYesNo($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateYesNoTime"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_yesno_triggerCron ===
                                                    '' &&
                                                  this.cycleTime_yesno !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ yesno_time_after }}
                                                  {{ yesno_time_after_state }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_yesno }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtYesNo($event)
                                              "
                                              v-if="this.yesno_action != ''"
                                            >
                                              <div
                                                class="action-tag2"
                                                @click="updateYesNoAction"
                                              >
                                                <img
                                                  src="../assets/sendmail.svg"
                                                  class="mb-2"
                                                  alt=""
                                                />
                                                <span
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ yesno_action }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                v-if="this.yesno_template != ''"
                                              >
                                                <div
                                                  style="
                                                    position: relative;
                                                    width: 105px;
                                                    margin: 0 auto;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      position: absolute;
                                                      top: -13px;
                                                      right: 0px;
                                                      z-index: 99;
                                                    "
                                                    v-if="
                                                      this.yesno_subject === ''
                                                    "
                                                  >
                                                    <img
                                                      src="../assets/redalert.svg"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div
                                                    class="template-tag"
                                                    style="color: #71afb6"
                                                    @click="updateYesNoTemplate"
                                                  >
                                                    {{ this.yesno_template }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- NO -->
                        <div
                          class="col-6"
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropTimeAtNo($event)"
                        >
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div class="no-tag">否</div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div v-if="no_time_after !== ''">
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropActionAtNo($event)"
                            >
                              <div
                                class="time-tag"
                                style="position: relative"
                                @click="updateNoTime"
                              >
                                <img
                                  src="../assets/redalert.svg"
                                  alt=""
                                  style="
                                    position: absolute;
                                    top: -13px;
                                    right: 0px;
                                  "
                                  v-if="
                                    this.ader_no_triggerCron === '' &&
                                    this.cycleTime_no != '一次性'
                                  "
                                />
                                <img
                                  src="../assets/clock.svg"
                                  alt=""
                                  style="width: 40px"
                                />
                                <div class="mt-1">
                                  <p class="m-0" style="font-size: 14px">
                                    {{ no_time_after }}
                                    {{ no_time_after_state }}
                                  </p>
                                  <p class="m-0" style="font-size: 12px">
                                    {{ cycleTime_no }}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div
                                style="background-color: white; height: 400px"
                                @dragover="allowDrop"
                                @drop.stop="dropTemplateAtNo($event)"
                                v-if="no_action != ''"
                              >
                                <div
                                  class="action-tag2"
                                  @click="updateNoAction"
                                >
                                  <img
                                    src="../assets/sendmail.svg"
                                    class="mb-2"
                                    alt=""
                                  />
                                  <span
                                    style="
                                      position: relative;
                                      left: 2px;
                                      font-size: 14px;
                                    "
                                  >
                                    {{ no_action }}
                                  </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropEventAtNo($event)"
                                  v-if="no_template != ''"
                                >
                                  <div
                                    style="
                                      position: relative;
                                      width: 105px;
                                      margin: 0 auto;
                                    "
                                  >
                                    <div
                                      style="
                                        position: absolute;
                                        top: -10px;
                                        right: -5px;
                                        z-index: 99;
                                      "
                                      v-if="this.no_subject === ''"
                                    >
                                      <img
                                        src="../assets/redalert.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      class="template-tag"
                                      style="color: #71afb6"
                                      @click="updateNoTemplate"
                                    >
                                      {{ no_template }}
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <!-- UnderNo -->
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                      width: 300px;
                                    "
                                    v-if="this.currentEventNo != null"
                                  >
                                    <div
                                      class="blue-tag"
                                      @click="updateNoEventTimeInterval"
                                    >
                                      <img
                                        src="../assets/hourglass.svg"
                                        style="width: 31px"
                                        alt=""
                                      />
                                      <div class="mt-2">
                                        {{ this.no_time_interval
                                        }}{{ this.no_time_state }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      class="blue-tag3"
                                      :style="
                                        this.currentEventNo ===
                                          '已開啟信件且點擊' ||
                                        this.currentEventNo ===
                                          '已開啟信件且轉換' ||
                                        this.currentEventNo ===
                                          '已點擊連結且轉換'
                                          ? 'padding-top:4px'
                                          : ''
                                      "
                                      @click="updateNoEventTimeInterval"
                                    >
                                      <img
                                        src="../assets/openmail.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="
                                          this.currentEventNo.includes(
                                            '開啟信件'
                                          )
                                        "
                                      />
                                      <img
                                        src="../assets/clicklink.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="
                                          this.currentEventNo.includes(
                                            '點擊連結'
                                          )
                                        "
                                      />
                                      <img
                                        src="../assets/change.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="this.currentEventNo === '轉換'"
                                      />
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 3px;
                                          color: white;
                                        "
                                        v-if="
                                          this.currentEventNo ===
                                            '已開啟信件且點擊' ||
                                          this.currentEventNo ===
                                            '已開啟信件且轉換' ||
                                          this.currentEventNo ===
                                            '已點擊連結且轉換'
                                        "
                                      >
                                        {{ this.currentEventNo.slice(0, -3) }}
                                        <br />
                                        <span style="font-size: 12px">{{
                                          this.currentEventNo.slice(-3)
                                        }}</span>
                                      </div>
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 5px;
                                          color: white;
                                        "
                                        v-else
                                      >
                                        {{ this.currentEventNo }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <hr
                                        class="m-0"
                                        style="
                                          border: none;
                                          height: 0;
                                          border-top: 2px solid #71afb6;
                                          width: 50%;
                                          opacity: 1;
                                        "
                                      />
                                    </div>
                                    <div class="d-flex">
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtNoYes($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="yes-tag">是</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          v-if="this.noyes_time_after !== ''"
                                        >
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtNoYes($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateNoYesTime"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_noyes_triggerCron ===
                                                    '' &&
                                                  this.cycleTime_noyes !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ noyes_time_after }}
                                                  {{ noyes_time_after_state }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_noyes }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtNoYes($event)
                                              "
                                              v-if="this.noyes_action != ''"
                                            >
                                              <div
                                                class="action-tag2"
                                                @click="updateNoYesAction"
                                              >
                                                <img
                                                  src="../assets/sendmail.svg"
                                                  class="mb-2"
                                                  alt=""
                                                />
                                                <span
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ noyes_action }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                @dragover="allowDrop"
                                                @drop.stop="
                                                  dropEventAtNoYes($event)
                                                "
                                                v-if="this.noyes_template != ''"
                                              >
                                                <div
                                                  style="
                                                    position: relative;
                                                    width: 105px;
                                                    margin: 0 auto;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      position: absolute;
                                                      top: -13px;
                                                      right: 0px;
                                                      z-index: 99;
                                                    "
                                                    v-if="
                                                      this.noyes_subject === ''
                                                    "
                                                  >
                                                    <img
                                                      src="../assets/redalert.svg"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div
                                                    class="template-tag"
                                                    style="color: #71afb6"
                                                    @click="updateNoYesTemplate"
                                                  >
                                                    {{ this.noyes_template }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtNoNo($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="no-tag">否</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div v-if="this.nono_time_after !== ''">
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtNoNo($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateNoNoTime"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this.ader_nono_triggerCron ===
                                                    '' &&
                                                  this.cycleTime_nono !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ nono_time_after }}
                                                  {{ nono_time_after_state }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_nono }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtNoNo($event)
                                              "
                                              v-if="this.nono_action != ''"
                                            >
                                              <div
                                                class="action-tag2"
                                                @click="updateNoNoAction"
                                              >
                                                <img
                                                  src="../assets/sendmail.svg"
                                                  class="mb-2"
                                                  alt=""
                                                />
                                                <span
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ nono_action }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                @dragover="allowDrop"
                                                @drop.stop="
                                                  dropEventAtNoNo($event)
                                                "
                                                v-if="this.nono_template != ''"
                                              >
                                                <div
                                                  style="
                                                    position: relative;
                                                    width: 105px;
                                                    margin: 0 auto;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      position: absolute;
                                                      top: -13px;
                                                      right: 0px;
                                                      z-index: 99;
                                                    "
                                                    v-if="
                                                      this.nono_subject === ''
                                                    "
                                                  >
                                                    <img
                                                      src="../assets/redalert.svg"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div
                                                    class="template-tag"
                                                    style="color: #71afb6"
                                                    @click="updateNoNoTemplate"
                                                  >
                                                    {{ this.nono_template }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6"
                  style="height: 300px"
                  @drop.stop="dropTemplateAtSms"
                >
                  <div
                    class="template-tag"
                    style="color: #71afb6"
                    @click="updateSmsTemplate"
                    v-if="this.template_sms != ''"
                  >
                    {{ template_sms }}
                  </div>
                  <div
                    class="d-flex justify-content-center"
                    v-if="this.template_sms != ''"
                  >
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div
                    class="col-12"
                    style="height: 300px"
                    @dragover="allowDrop"
                    @drop.stop="dropEvent2"
                    v-if="this.template_sms != ''"
                  >
                    <div
                      style="background-color: white"
                      class="tree-div text-center"
                      v-if="this.currentEvent2 != null"
                    >
                      <div class="blue-tag" @click="updateEventTimeInterval2">
                        <img
                          src="../assets/hourglass.svg"
                          style="width: 31px"
                          alt=""
                        />
                        <div class="mt-2">
                          {{ this.time_interval2 }}{{ this.time_state2 }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div
                        class="blue-tag3"
                        :style="
                          this.currentEvent2 === '已點擊連結且轉換'
                            ? 'padding-top:4px'
                            : ''
                        "
                        @click="updateEventTimeInterval2"
                      >
                        <img
                          src="../assets/clicklink.svg"
                          style="width: 40px"
                          alt=""
                          v-if="this.currentEvent2.includes('點擊連結')"
                        />
                        <img
                          src="../assets/change.svg"
                          style="width: 40px"
                          alt=""
                          v-if="this.currentEvent2 === '轉換'"
                        />
                        <div
                          style="
                            width: 100px;
                            position: relative;
                            left: -30px;
                            top: 3px;
                            color: white;
                          "
                          v-if="this.currentEvent2 === '已點擊連結且轉換'"
                        >
                          {{ this.currentEvent2.slice(0, -3) }}
                          <br />
                          <span style="font-size: 12px">{{
                            this.currentEvent2.slice(-3)
                          }}</span>
                        </div>
                        <div
                          style="
                            width: 100px;
                            position: relative;
                            left: -30px;
                            top: 5px;
                            color: white;
                          "
                          v-else
                        >
                          {{ this.currentEvent2 }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="d-flex justify-content-center">
                        <hr
                          class="m-0"
                          style="
                            border: none;
                            height: 0;
                            border-top: 2px solid #71afb6;
                            width: 50%;
                            opacity: 1;
                          "
                        />
                      </div>

                      <div class="d-flex">
                        <!-- Yes -->

                        <div
                          class="col-6"
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropTimeAtYes2($event)"
                        >
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div class="yes-tag">是</div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div v-if="yes_time_after2 != ''">
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropActionAtYes2($event)"
                            >
                              <div
                                class="time-tag"
                                style="position: relative"
                                @click="updateYesTime2"
                              >
                                <img
                                  src="../assets/redalert.svg"
                                  alt=""
                                  style="
                                    position: absolute;
                                    top: -13px;
                                    right: 0px;
                                  "
                                  v-if="
                                    this.ader_yes_triggerCron2 === '' &&
                                    this.cycleTime_yes2 != '一次性'
                                  "
                                />
                                <img
                                  src="../assets/clock.svg"
                                  alt=""
                                  style="width: 40px"
                                />
                                <div class="mt-1">
                                  <p class="m-0" style="font-size: 14px">
                                    {{ yes_time_after2 }}
                                    {{ yes_time_after_state2 }}
                                  </p>
                                  <p class="m-0" style="font-size: 12px">
                                    {{ cycleTime_yes2 }}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div
                                style="background-color: white; height: 400px"
                                @dragover="allowDrop"
                                @drop.stop="dropTemplateAtYes2($event)"
                                v-if="yes_action2 != ''"
                              >
                                <div
                                  class="action-tag3"
                                  style="position: relative; left: 2px"
                                  @click="updateYesAction2"
                                >
                                  <img
                                    src="../assets/sendsms.svg"
                                    style="position: relative; left: 2px"
                                    alt=""
                                  />
                                  <br />
                                  <span
                                    style="position: relative; font-size: 14px"
                                  >
                                    {{ yes_action2 }}
                                  </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropEventAtYes2($event)"
                                  v-if="yes_template2 != ''"
                                >
                                  <div
                                    class="template-tag"
                                    style="color: #71afb6"
                                    @click="updateYesTemplate2($event)"
                                  >
                                    {{ yes_template2 }}
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <!-- UnderYes -->
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                      width: 300px;
                                    "
                                    v-if="this.currentEventYes2 != null"
                                  >
                                    <div
                                      class="blue-tag"
                                      @click="updateYesEventTimeInterval2"
                                    >
                                      <img
                                        src="../assets/hourglass.svg"
                                        style="width: 31px"
                                        alt=""
                                      />
                                      <div class="mt-2">
                                        {{ this.yes_time_interval2
                                        }}{{ this.yes_time_state2 }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      class="blue-tag3"
                                      :style="
                                        this.currentEventYes2 ===
                                        '已點擊連結且轉換'
                                          ? 'padding-top:4px'
                                          : ''
                                      "
                                      @click="updateYesEventTimeInterval2"
                                    >
                                      <img
                                        src="../assets/clicklink.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="
                                          this.currentEventYes2.includes(
                                            '點擊連結'
                                          )
                                        "
                                      />
                                      <img
                                        src="../assets/change.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="this.currentEventYes2 === '轉換'"
                                      />
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 3px;
                                          color: white;
                                        "
                                        v-if="
                                          this.currentEventYes2 ===
                                          '已點擊連結且轉換'
                                        "
                                      >
                                        {{ this.currentEventYes2.slice(0, -3) }}
                                        <br />
                                        <span style="font-size: 12px">{{
                                          this.currentEventYes2.slice(-3)
                                        }}</span>
                                      </div>
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 5px;
                                          color: white;
                                        "
                                        v-else
                                      >
                                        {{ this.currentEventYes2 }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <hr
                                        class="m-0"
                                        style="
                                          border: none;
                                          height: 0;
                                          border-top: 2px solid #71afb6;
                                          width: 50%;
                                          opacity: 1;
                                        "
                                      />
                                    </div>
                                    <div class="d-flex">
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtYesYes2($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="yes-tag">是</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          v-if="this.yesyes_time_after2 != ''"
                                        >
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtYesYes2($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateYesYesTime2"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_yesyes_triggerCron2 ===
                                                    '' &&
                                                  this.cycleTime_yesyes2 !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ yesyes_time_after2 }}
                                                  {{ yesyes_time_after_state2 }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_yesyes2 }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtYesYes2($event)
                                              "
                                              v-if="this.yesyes_action2 != ''"
                                            >
                                              <div
                                                class="action-tag3"
                                                style="
                                                  position: relative;
                                                  left: 2px;
                                                "
                                                @click="updateYesYesAction2"
                                              >
                                                <img
                                                  src="../assets/sendsms.svg"
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                  "
                                                  alt=""
                                                />
                                                <br />
                                                <span
                                                  style="
                                                    position: relative;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ yesyes_action2 }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                v-if="
                                                  this.yesyes_template2 != ''
                                                "
                                              >
                                                <div
                                                  class="template-tag"
                                                  style="color: #71afb6"
                                                  @click="updateYesYesTemplate2"
                                                >
                                                  {{ this.yesyes_template2 }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtYesNo2($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="no-tag">否</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          v-if="this.yesno_time_after2 != ''"
                                        >
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtYesNo2($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateYesNoTime2"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_yesno_triggerCron2 ===
                                                    '' &&
                                                  this.cycleTime_yesno2 !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ yesno_time_after2 }}
                                                  {{ yesno_time_after_state2 }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_yesno2 }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtYesNo2($event)
                                              "
                                              v-if="this.yesno_action2 != ''"
                                            >
                                              <div
                                                class="action-tag3"
                                                style="
                                                  position: relative;
                                                  left: 2px;
                                                "
                                                @click="updateYesNoAction2"
                                              >
                                                <img
                                                  src="../assets/sendsms.svg"
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                  "
                                                  alt=""
                                                />
                                                <br />
                                                <span
                                                  style="
                                                    position: relative;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ yesno_action2 }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                v-if="
                                                  this.yesno_template2 != ''
                                                "
                                              >
                                                <div
                                                  class="template-tag"
                                                  style="color: #71afb6"
                                                  @click="updateYesNoTemplate2"
                                                >
                                                  {{ this.yesno_template2 }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- NO -->
                        <div
                          class="col-6"
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropTimeAtNo2($event)"
                        >
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div class="no-tag">否</div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div v-if="no_time_after2 != ''">
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropActionAtNo2($event)"
                            >
                              <div
                                class="time-tag"
                                style="position: relative"
                                @click="updateNoTime2"
                              >
                                <img
                                  src="../assets/redalert.svg"
                                  alt=""
                                  style="
                                    position: absolute;
                                    top: -13px;
                                    right: 0px;
                                  "
                                  v-if="
                                    this.ader_no_triggerCron2 === '' &&
                                    this.cycleTime_no2 != '一次性'
                                  "
                                />
                                <img
                                  src="../assets/clock.svg"
                                  alt=""
                                  style="width: 40px"
                                />
                                <div class="mt-1">
                                  <p class="m-0" style="font-size: 14px">
                                    {{ no_time_after2 }}
                                    {{ no_time_after_state2 }}
                                  </p>
                                  <p class="m-0" style="font-size: 12px">
                                    {{ cycleTime_no2 }}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div
                                style="background-color: white; height: 400px"
                                @dragover="allowDrop"
                                @drop.stop="dropTemplateAtNo2($event)"
                                v-if="no_action2 != ''"
                              >
                                <div
                                  class="action-tag2"
                                  @click="updateNoAction2"
                                >
                                  <img
                                    src="../assets/sendmail.svg"
                                    class="mb-2"
                                    alt=""
                                  />
                                  <span
                                    style="
                                      position: relative;
                                      left: 2px;
                                      font-size: 14px;
                                    "
                                  >
                                    {{ no_action2 }}
                                  </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropEventAtNo2($event)"
                                  v-if="no_template2 != ''"
                                >
                                  <div
                                    class="template-tag"
                                    style="color: #71afb6"
                                    @click="updateNoTemplate2"
                                  >
                                    {{ no_template2 }}
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <!-- UnderNo -->
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                      width: 300px;
                                    "
                                    v-if="this.currentEventNo2 != null"
                                  >
                                    <div
                                      class="blue-tag"
                                      @click="updateNoEventTimeInterval2"
                                    >
                                      <img
                                        src="../assets/hourglass.svg"
                                        style="width: 31px"
                                        alt=""
                                      />
                                      <div class="mt-2">
                                        {{ this.no_time_interval2
                                        }}{{ this.no_time_state2 }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      class="blue-tag3"
                                      :style="
                                        this.currentEventNo2 ===
                                        '已點擊連結且轉換'
                                          ? 'padding-top:4px'
                                          : ''
                                      "
                                      @click="updateYesEventTimeInterval2"
                                    >
                                      <img
                                        src="../assets/clicklink.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="
                                          this.currentEventNo2.includes(
                                            '點擊連結'
                                          )
                                        "
                                      />
                                      <img
                                        src="../assets/change.svg"
                                        style="width: 40px"
                                        alt=""
                                        v-if="this.currentEventNo2 === '轉換'"
                                      />
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 3px;
                                          color: white;
                                        "
                                        v-if="
                                          this.currentEventNo2 ===
                                          '已點擊連結且轉換'
                                        "
                                      >
                                        {{ this.currentEventNo2.slice(0, -3) }}
                                        <br />
                                        <span style="font-size: 12px">{{
                                          this.currentEventNo2.slice(-3)
                                        }}</span>
                                      </div>
                                      <div
                                        style="
                                          width: 100px;
                                          position: relative;
                                          left: -30px;
                                          top: 5px;
                                          color: white;
                                        "
                                        v-else
                                      >
                                        {{ this.currentEventNo2 }}
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <hr
                                        class="m-0"
                                        style="
                                          border: none;
                                          height: 0;
                                          border-top: 2px solid #71afb6;
                                          width: 50%;
                                          opacity: 1;
                                        "
                                      />
                                    </div>
                                    <div class="d-flex">
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtNoYes2($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="yes-tag">是</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          v-if="this.noyes_time_after2 != ''"
                                        >
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtNoYes2($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateNoYesTime2"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_noyes_triggerCron2 ===
                                                    '' &&
                                                  this.cycleTime_noyes2 !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ noyes_time_after2 }}
                                                  {{ noyes_time_after_state2 }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_noyes2 }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtNoYes2($event)
                                              "
                                              v-if="this.noyes_action2 != ''"
                                            >
                                              <div
                                                class="action-tag3"
                                                style="
                                                  position: relative;
                                                  left: 2px;
                                                "
                                                @click="updateNoYesAction2"
                                              >
                                                <img
                                                  src="../assets/sendsms.svg"
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                  "
                                                  alt=""
                                                />
                                                <br />
                                                <span
                                                  style="
                                                    position: relative;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ noyes_action2 }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                @dragover="allowDrop"
                                                @drop.stop="
                                                  dropEventAtNoYes2($event)
                                                "
                                                v-if="
                                                  this.noyes_template2 != ''
                                                "
                                              >
                                                <div
                                                  class="template-tag"
                                                  style="color: #71afb6"
                                                  @click="updateNoYesTemplate2"
                                                >
                                                  {{ this.noyes_template2 }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-6"
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropTimeAtNoNo2($event)"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="no-tag">否</div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div v-if="this.nono_time_after2 != ''">
                                          <div
                                            style="
                                              background-color: white;
                                              height: 400px;
                                            "
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropActionAtNoNo2($event)
                                            "
                                          >
                                            <div
                                              style="position: relative"
                                              class="time-tag"
                                              @click="updateNoNoTime2"
                                            >
                                              <img
                                                src="../assets/redalert.svg"
                                                alt=""
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 0px;
                                                "
                                                v-if="
                                                  this
                                                    .ader_nono_triggerCron2 ===
                                                    '' &&
                                                  this.cycleTime_nono2 !=
                                                    '一次性'
                                                "
                                              />
                                              <img
                                                src="../assets/clock.svg"
                                                alt=""
                                                style="width: 40px"
                                              />
                                              <div class="mt-1">
                                                <p
                                                  class="m-0"
                                                  style="font-size: 14px"
                                                >
                                                  {{ nono_time_after2 }}
                                                  {{ nono_time_after_state2 }}
                                                </p>
                                                <p
                                                  class="m-0"
                                                  style="font-size: 12px"
                                                >
                                                  {{ cycleTime_nono2 }}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              class="
                                                d-flex
                                                justify-content-center
                                              "
                                            >
                                              <img
                                                src="../assets/treeline.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              style="
                                                background-color: white;
                                                height: 400px;
                                              "
                                              @dragover="allowDrop"
                                              @drop.stop="
                                                dropTemplateAtNoNo2($event)
                                              "
                                              v-if="this.nono_action2 != ''"
                                            >
                                              <div
                                                class="action-tag3"
                                                style="
                                                  position: relative;
                                                  left: 2px;
                                                "
                                                @click="updateNoNoAction2"
                                              >
                                                <img
                                                  src="../assets/sendsms.svg"
                                                  style="
                                                    position: relative;
                                                    left: 2px;
                                                  "
                                                  alt=""
                                                />
                                                <br />
                                                <span
                                                  style="
                                                    position: relative;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  {{ nono_action2 }}
                                                </span>
                                              </div>
                                              <div
                                                class="
                                                  d-flex
                                                  justify-content-center
                                                "
                                              >
                                                <img
                                                  src="../assets/treeline.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                style="background-color: white"
                                                @dragover="allowDrop"
                                                @drop.stop="
                                                  dropEventAtNoNo2($event)
                                                "
                                                v-if="this.nono_template2 != ''"
                                              >
                                                <div
                                                  class="template-tag"
                                                  style="color: #71afb6"
                                                  @click="updateNoNoTemplate2"
                                                >
                                                  {{ this.nono_template2 }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button
              class="bot-left-left-btn mt-4"
              style="height: 50px; font-size: 20px; width: 14%"
              @click="openStopScriptModel"
            >
              停用
            </button>
            <div class="d-flex justify-content-between" style="width: 75%">
              <button
                class="bot-left-btn mt-4"
                style="
                  height: 50px;
                  font-size: 20px;
                  width: 31%;
                  color: #71afb6;
                "
                @click="goScriptTable()"
              >
                <img
                  src="../assets/green-left-arrow.svg"
                  alt=""
                  style="position: relative; top: -1px"
                />
                &nbsp;返回列表
              </button>
              <button
                class="bot-left-btn mt-4"
                style="
                  height: 50px;
                  font-size: 20px;
                  width: 31%;
                  color: #71afb6;
                "
                @click="saveScript()"
              >
                <img
                  src="../assets/greenSave.svg"
                  style="position: relative; top: -2px"
                />
                &nbsp;儲存
              </button>
              <button
                class="bot-right-btn mt-4"
                style="height: 50px; font-size: 20px; width: 31%"
                @click="notDev()"
              >
                <!-- @click="saveScript('2')" -->
                <img
                  src="../assets/check.png"
                  style="position: relative; top: -2px"
                />
                &nbsp;啟用
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import ProductList from "../components/MarketingScript/ProductList.vue";
import DropdownBlack from "../components/DropdownBlack.vue";
import Dropdown from "../components/Dropdown.vue";
import DropdownAll from "../components/DropdownAll.vue";
import DropdownWithSearch from "../components/DropdownWithSearch.vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import Pagination from "v-pagination-3";
import moment from "moment";
import SmsPreview from "../components/SmsPreview.vue";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "AfterTriggerScript",
  components: {
    ProductList,
    Pagination,
    Dropdown,
    DropdownAll,
    DropdownBlack,
    DropdownWithSearch,
    SmsPreview,
    EmailPreview,
    BtnInfoBox,
  },
  setup() {
    const daytime = ref();
    const autoShowcalendar_event = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShowcalendar_event.value = false));

    return {
      daytime,
      target,
      autoShowcalendar_event,
    };
  },

  data() {
    return {
      random_Cbox_key:'',
      noCbox:true,
      afterTriggerScriptShow2: false,
      firstNode: {},
      cacheNode: {},
      savework: "",
      calenday: "",
      calentime: "",
      renameEvent: "",

      calendar_eventID: "",
      calendar_event: "請選擇活動",
      searchEvents: [],
      events: [],
      searchInput2: "",

      valueLoading: "",
      scriptHasStart: false,
      loading_copy: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },

      errorDrop: "",
      scriptTipShow: false,
      triggerBuy: {
        tag: "",
        value: "",
      },
      ader_cycleDayStart: "",
      cycleDayStart: null,
      scriptDayStart: "",
      scriptDayEnd: "",
      scriptDayStart_sure: "",
      scriptDayEnd_sure: "",
      scriptDayState: "永久",
      scriptDayState_sure: "",

      event_moreoption: false,
      event_moreoption_Selected: "",
      tagValue: [],
      tagSelected: "-",
      tagValueSelected: "-",

      triggerCron: {},
      cycleCron: "",

      exp_cycleTime_first: "",
      exp_cycleTime_second: "",
      exp_cycleTime_first_ader: "",
      exp_cycleTime_second_ader: "",

      ader_triggerCron: "",
      ader_yes_triggerCron: "",
      ader_yesyes_triggerCron: "",
      ader_yesno_triggerCron: "",
      ader_no_triggerCron: "",
      ader_noyes_triggerCron: "",
      ader_nono_triggerCron: "",
      ader_yes_triggerCron2: "",
      ader_yesyes_triggerCron2: "",
      ader_yesno_triggerCron2: "",
      ader_no_triggerCron2: "",
      ader_noyes_triggerCron2: "",
      ader_nono_triggerCron2: "",

      yes_triggerCron: {},
      yesyes_triggerCron: {},
      yesno_triggerCron: {},
      no_triggerCron: {},
      noyes_triggerCron: {},
      nono_triggerCron: {},
      yes_triggerCron2: {},
      yesyes_triggerCron2: {},
      yesno_triggerCron2: {},
      no_triggerCron2: {},
      noyes_triggerCron2: {},
      nono_triggerCron2: {},

      endCount2: "",
      time_every: 0,
      ader_daytime: "",
      ader_endCount: "",
      aderTestCron: "",

      yearBasedWith: "日期",
      picked: "一次性",
      picked2: "",
      picked3: "一次性",
      picked4: "定期投放",
      happenMonth_moreoption: false,
      happenTime: "月",
      happenDaySelector: [],
      happenYearSelector: [],
      happenMonthSelector: [],
      happenMonthSelector2: [],
      happenYearMonthSelector: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],

      happenDay_Selected: "",
      happenWeek_Selected: "",
      happenWeek2_Selected: "",
      happenMonth_Selected: "",
      happenMonth2_Selected: "",
      happenMonth3_Selected: "",
      happenMonth4_Selected: "",
      happenMonth5_Selected: "",
      happenYear_Selected: "",
      happenYear2_Selected: "",
      happenYear3_Selected: "",
      happenYear4_Selected: "",
      happenYear5_Selected: "",

      cycleTime_trigger: "一次性",
      cycleTimeinCycle: "",
      cycleTime_yes: "一次性",
      cycleTime_yesyes: "一次性",
      cycleTime_yesno: "一次性",
      cycleTime_no: "一次性",
      cycleTime_noyes: "一次性",
      cycleTime_nono: "一次性",
      cycleTime_yes2: "一次性",
      cycleTime_yesyes2: "一次性",
      cycleTime_yesno2: "一次性",
      cycleTime_no2: "一次性",
      cycleTime_noyes2: "一次性",
      cycleTime_nono2: "一次性",

      endtime: "",
      z: 1,
      zoom: "",
      resSubsec: "",
      selectedLabels: [],
      // selectCustomGroup: "價值分群",

      from: "",
      group: "",
      name: "",
      token: "",
      key: "",
      emailChannel: true,
      random_id: "",

      page: 1,
      perPage: 4,
      nowPage: 0,
      records: [],

      selectedgroupID: "",
      searchInput: "",
      script_title: "",
      script_title_sure: "",
      selectGroup: "",
      groupName: {
        includeLabelNames: [],
        excludeLabelNames: [],
      },
      selectEmail: "",
      selectSms: "",
      cantSignup: false,
      // grouptodos: [],
      // RFMtodos: [],
      // customtodos: [],
      // channeltodos: [],

      grabbing: false,
      fat: false,
      notshow: false,
      loading: false,

      testList: [],
      testList2: [],
      editList: false,

      yesindex: null,
      yesyesindex: null,
      yesnoindex: null,
      noindex: null,
      noyesindex: null,
      nonoindex: null,
      // for 2nd testlist
      yesindex2: null,
      yesyesindex2: null,
      yesnoindex2: null,
      noindex2: null,
      noyesindex2: null,
      nonoindex2: null,

      // data for template
      selectedID: "",
      i: 1,

      titleError2: false,
      titleErrMsg2: "",
      titleError: false,
      titleErrMsg: "",
      subError: false,
      subErrMsg: "",
      textError: false,
      textErrMsg: "",
      noBanner: false,

      newTodoTitle: "",
      smsTitle: "",
      newSub: "",
      newSubsec: "",

      fullurl: "",
      shortUrl: "",

      searchQuery: "",
      todos: [],
      emailtodos: [],
      smstodos: [],
      searchTodos: [],
      schedules: [],
      childtodo: [],
      editinput: false,
      beClick: true,

      isShow: false,
      isShow2: false,
      isShow3: false,
      isShowMoney: false,

      // Tab offcanvas data
      tabState: "event",
      updateWho: "",

      currentTrigger: null,
      triggerSelected: "",
      time_trigger: "",
      time_trigger_state: "",
      time_trigger_Selected: "",
      time_trigger_state_Selected: "",

      template_email: "",
      template_sms: "",

      eventSelected: "",
      currentEvent: null,
      currentEventYes: null,
      currentEventNo: null,
      time_interval_Selected: "",
      time_interval: "",
      yes_time_interval: "",
      no_time_interval: "",
      time_state_Selected: "",
      time_state: "",
      yes_time_state: "",
      no_time_state: "",

      time_after_Selected: "",
      yes_time_after: "",
      yesyes_time_after: "",
      yesno_time_after: "",
      no_time_after: "",
      noyes_time_after: "",
      nono_time_after: "",
      time_after_state_Selected: "",
      yes_time_after_state: "天後",
      yesyes_time_after_state: "天後",
      yesno_time_after_state: "天後",
      no_time_after_state: "天後",
      noyes_time_after_state: "天後",
      nono_time_after_state: "天後",

      action_Selected: "",
      yes_action: "",
      yesyes_action: "",
      yesno_action: "",
      no_action: "",
      noyes_action: "",
      nono_action: "",

      yes_template: "",
      yesyes_template: "",
      yesno_template: "",
      no_template: "",
      noyes_template: "",
      nono_template: "",

      // for 2nd testlist

      currentEvent2: null,
      currentEventYes2: null,
      currentEventNo2: null,
      time_interval2: "",
      yes_time_interval2: "",
      no_time_interval2: "",
      time_state2: "",
      yes_time_state2: "",
      no_time_state2: "",

      yes_time_after2: "",
      yesyes_time_after2: "",
      yesno_time_after2: "",
      no_time_after2: "",
      noyes_time_after2: "",
      nono_time_after2: "",
      yes_time_after_state2: "天後",
      yesyes_time_after_state2: "天後",
      yesno_time_after_state2: "天後",
      no_time_after_state2: "天後",
      noyes_time_after_state2: "天後",
      nono_time_after_state2: "天後",

      yes_action2: "",
      yesyes_action2: "",
      yesno_action2: "",
      no_action2: "",
      noyes_action2: "",
      nono_action2: "",

      yes_template2: "",
      yesyes_template2: "",
      yesno_template2: "",
      no_template2: "",
      noyes_template2: "",
      nono_template2: "",

      // subject
      email_subject: "",
      sms_subject: "",
      yes_subject: "",
      yesyes_subject: "",
      yesno_subject: "",
      no_subject: "",
      noyes_subject: "",
      nono_subject: "",
      yes_subject2: null,
      yesyes_subject2: null,
      yesno_subject2: null,
      no_subject2: null,
      noyes_subject2: null,
      nono_subject2: null,
      // 8081 url
      url8081: "",
      unlayerJson: "",
      eventName: "",
      eventNameError: "",
      eventNameErrMsg: "",
      newTempName: "",
      newTempNameError: "",
      newTempNameErrMsg: "",
      todojson: "",
      todohtml: "",
      html: "",
      newHtml: "",
      vice_id: "",
      Parr: [],
      fullurlArr: [],
      shortUrlArr: [],

      // sms template
      expSubsec: "",
      newText: "",
      urlok: true,
      smsModal: "",
      editScriptSms: false,
      offCanvasClick: "",
      unlayerModal: "",
      alertModal3: "",

      //  從sessionStorage裏的活動名稱等資料data
      eventNameFromSche: "",
      eventTitleFromSche: "",
      eventScheduleIdFromSche: "ReMA_15e78a6b",
      eventScriptIdFromSche: null,
      eventScriptHasEventFromSche: "0",

      // start event
      emailList: {},
      smsList: {},
      finalList: [],

      varName: "{name}",
      varLastName: "{lastname}",
      varFirstName: "{firstname}",
      triggerType: "顧客姓名",
      autoShow: false,

      origingrouptodos: {},
      countText: "",

      scriptTimeModal: {},
      goingToRunScript2: false,
      goingToSaveScript: false,

      hasnotSave: true,
    };
  },
  computed: {
    // displayedRecords() {
    //   const startIndex = this.perPage * (this.page - 1);
    //   const endIndex = startIndex + this.perPage;
    //   return this.grouptodos.slice(startIndex, endIndex);
    // },
  },
  watch: {
    time_trigger_state_Selected() {
      if (this.time_trigger_state_Selected === "立即") {
        this.time_trigger_Selected = 0;
      } else if (
        this.time_trigger_state_Selected != "立即" &&
        this.time_trigger_Selected === 0
      ) {
        this.time_trigger_Selected = 1;
      } else if (
        this.time_trigger_state_Selected === "小時後" &&
        this.time_trigger_Selected > 24
      ) {
        this.time_trigger_Selected = 24;
      }
    },
    time_trigger_Selected() {
      if (this.time_trigger_state_Selected === "小時後") {
        if (this.time_trigger_Selected > 24) {
          return (this.time_trigger_Selected = 24);
        } else if (this.time_trigger_Selected < 1) {
          this.time_trigger_Selected = 1;
        } else if (this.time_trigger_Selected.toString().length > 2) {
          this.time_trigger_Selected = 23;
        }
      } else if (
        this.time_trigger_state_Selected === "立即" &&
        this.time_trigger_Selected != 0
      ) {
        this.time_trigger_state_Selected = "天後";
      } else if (this.time_trigger_state_Selected === "天後") {
        if (this.time_trigger_Selected > 365) {
          return (this.time_trigger_Selected = 365);
        } else if (this.time_trigger_Selected < 1) {
          this.time_trigger_Selected = 1;
        } else if (this.time_trigger_Selected.toString().length > 3) {
          this.time_trigger_Selected = 365;
        }
      }
    },
    time_interval_Selected() {
      if (this.time_state_Selected === "小時內") {
        if (this.time_interval_Selected > 24) {
          return (this.time_interval_Selected = 24);
        } else if (this.time_interval_Selected < 1) {
          this.time_interval_Selected = 1;
        } else if (this.time_interval_Selected.toString().length > 2) {
          this.time_interval_Selected = 23;
        }
      } else {
        if (this.time_interval_Selected > 365) {
          return (this.time_interval_Selected = 365);
        } else if (this.time_interval_Selected < 1) {
          this.time_interval_Selected = 1;
        } else if (this.time_interval_Selected.toString().length > 3) {
          this.time_interval_Selected = 365;
        }
      }
    },
    time_after_state_Selected() {
      if (this.time_after_state_Selected === "立即") {
        this.time_after_Selected = 0;
      } else if (
        this.time_after_state_Selected != "立即" &&
        this.time_after_Selected === 0
      ) {
        this.time_after_Selected = 1;
      } else if (
        this.time_after_state_Selected === "小時後" &&
        this.time_after_Selected > 24
      ) {
        this.time_after_Selected = 24;
      }
    },
    time_after_Selected() {
      if (this.time_after_state_Selected === "小時後") {
        if (this.time_after_Selected > 24) {
          return (this.time_after_Selected = 24);
        } else if (this.time_after_Selected < 1) {
          this.time_after_Selected = 1;
        } else if (this.time_after_Selected.toString().length > 2) {
          this.time_after_Selected = 23;
        }
      } else if (
        this.time_after_state_Selected === "立即" &&
        this.time_after_Selected != 0
      ) {
        this.time_after_state_Selected = "天後";
      } else if (this.time_after_state_Selected === "天後") {
        if (this.time_after_Selected > 365) {
          return (this.time_after_Selected = 365);
        } else if (this.time_after_Selected < 1) {
          this.time_after_Selected = 1;
        } else if (this.time_after_Selected.toString().length > 3) {
          this.time_after_Selected = 365;
        }
      }
    },
    currentEventYes() {
      if (
        this.currentEventYes === null &&
        this.currentEventNo === null &&
        this.currentEventYes2 === null &&
        this.currentEventNo2 === null
      ) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventNo() {
      if (
        this.currentEventYes === null &&
        this.currentEventNo === null &&
        this.currentEventYes2 === null &&
        this.currentEventNo2 === null
      ) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    testList2: {
      handler: function () {
        this.editList = true;
        console.log("this.editList", this.editList);
      },
      deep: true,
    },
    currentEventYes2() {
      if (
        this.currentEventYes === null &&
        this.currentEventNo === null &&
        this.currentEventYes2 === null &&
        this.currentEventNo2 === null
      ) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventNo2() {
      if (
        this.currentEventYes === null &&
        this.currentEventNo === null &&
        this.currentEventYes2 === null &&
        this.currentEventNo2 === null
      ) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    testList: {
      handler: function () {
        this.editList = true;
        console.log("this.editList", this.editList);
      },
      deep: true,
    },
    newTodoTitle: function () {
      // var isText = /^\S+$/;
      // if (!isText.test(this.newTodoTitle))
      var isText = /^[\u4e00-\u9fa5_a-zA-Z0-9()-\s]+$/;
      if (!isText.test(this.newTodoTitle)) {
        this.titleError = true;
        this.titleErrMsg = "模板名稱不可以包含特殊符號字元";
      } else if (this.newTodoTitle === "") {
        this.titleError = true;
        this.titleErrMsg = "標題不能是空的";
      } else if (
        this.searchTodos.findIndex((d) => d.label === this.newTodoTitle) != "-1"
      ) {
        this.titleError = true;
        this.titleErrMsg = "您要使用的名單名稱已有其他分群名單使用";
      } else {
        this.newTodoTitleError = false;
        this.titleError = false;
      }
      // sessionStorage.setItem("名稱", this.newTodoTitle);
    },
    newSub: function () {
      if (this.newSub === "") {
        this.subError = true;
        this.subErrMsg = "主旨不能是空的";
      } else {
        this.subError = false;
      }
      //  sessionStorage.setItem("主子", this.newSub)
    },
    newSubsec: function () {
      if (this.newSubsec === "") {
        this.textError = true;
        this.textErrMsg = "訊息內容不能是空的";
      } else {
        this.textError = false;
      }
      // sessionStorage.setItem("訊息", this.newSubsec);
    },
    newTempName() {
      var isText = /^[\u4e00-\u9fa5_a-zA-Z0-9()-\s]+$/;
      if (!isText.test(this.newTempName)) {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "模板名稱不可以包含特殊符號字元";
      } else if (this.newTempName === "") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "模板名稱不能為空";
      } else {
        this.newTempNameError = false;
      }
    },
    // selectCustomGroup: function (val) {
    //   if (val === "價值分群") {
    //     this.grouptodos = this.RFMtodos;
    //     this.origingrouptodos = this.RFMtodos;
    //   } else if (val === "個性化分群") {
    //     this.grouptodos = this.customtodos;
    //     this.origingrouptodos = this.customtodos;
    //   } else if (val === "渠道行銷分群") {
    //     this.grouptodos = this.channeltodos;
    //     this.origingrouptodos = this.channeltodos;
    //   }
    // },
    // groupName: function (val) {
    //   if (this.groupName.includeLabelNames[0] === "針對未來新加入名單") {
    //     this.cantSignup = true;
    //   } else {
    //     this.cantSignup = false;
    //   }
    // },
    emailChannel: function (val) {
      if (this.emailChannel) {
        this.todos = this.emailtodos;
      } else {
        this.todos = this.smstodos;
      }
    },
    searchInput: function (val) {
      console.log(this.grouptodos);
      if (val === "") {
        this.grouptodos = this.origingrouptodos;
      } else {
        this.grouptodos = this.origingrouptodos.filter((d) => {
          if (d.label != undefined) {
            return d.label.includes(val);
          } else {
            return d.label_name.includes(val);
          }
        });
      }
    },
    daytime: function (val) {
      console.log(val);
    },
    scriptDayState() {
      if (this.scriptDayState === "永久") {
        this.scriptDayEnd = "";
      }
    },
    scriptDayEnd() {
      if (this.scriptDayEnd != "") {
        this.scriptDayState = "自訂";
      }
    },
    happenYear_Selected() {
      let happenYearSelectorIndex = 1;
      if (this.happenYear_Selected === "二月") {
        this.happenYearSelector = [];
        for (
          happenYearSelectorIndex = 1;
          happenYearSelectorIndex < 30;
          happenYearSelectorIndex++
        ) {
          const day = happenYearSelectorIndex + "號";
          this.happenYearSelector.push(day);
        }
      } else if (
        this.happenYear_Selected === "一月" ||
        this.happenYear_Selected === "三月" ||
        this.happenYear_Selected === "五月" ||
        this.happenYear_Selected === "七月" ||
        this.happenYear_Selected === "八月" ||
        this.happenYear_Selected === "十月" ||
        this.happenYear_Selected === "十二月"
      ) {
        this.happenYearSelector = [];
        for (
          happenYearSelectorIndex = 1;
          happenYearSelectorIndex < 32;
          happenYearSelectorIndex++
        ) {
          const day = happenYearSelectorIndex + "號";
          this.happenYearSelector.push(day);
        }
      } else {
        this.happenYearSelector = [];
        for (
          happenYearSelectorIndex = 1;
          happenYearSelectorIndex < 31;
          happenYearSelectorIndex++
        ) {
          const day = happenYearSelectorIndex + "號";
          this.happenYearSelector.push(day);
        }
      }
    },
    endCount2() {
      console.log(this.endCount2);
      if (this.endCount2 === "") {
        this.endCount2 = "";
      } else if (this.endCount2 < 1) {
        this.endCount2 = "";
      }
    },
    happenDay_Selected() {
      if (this.happenDay_Selected === "") {
        this.happenDay_Selected = "";
      } else if (this.happenDay_Selected < 1) {
        this.happenDay_Selected = 1;
      }
    },
    emailList: {
      handler: function () {
        this.editList = true;
        console.log("this.editList", this.editList);
      },
      deep: true,
    },
    smsList: {
      handler: function () {
        this.editList = true;
        console.log("this.editList", this.editList);
      },
      deep: true,
    },
  },

  mounted: async function () {
    this.getCalenEvents();
    for (let index = 1; index < 31; index++) {
      const day = "每" + index + "天";
      this.happenDaySelector.push(day);
    }
    for (let index2 = 1; index2 < 32; index2++) {
      const day = index2 + "號";
      this.happenMonthSelector.push(day);
    }
    this.happenMonthSelector.push("最後一天");
    for (let index3 = 1; index3 < 12; index3++) {
      const day = "每" + index3 + "個月";
      this.happenMonthSelector2.push(day);
    }
    for (let index4 = 1; index4 < 32; index4++) {
      const day = index4 + "號";
      this.happenYearSelector.push(day);
    }
    if (sessionStorage.getItem("lv") == "0") {
      this.notshow = 0;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "1") {
      this.notshow = 1;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "2") {
      this.notshow = 2;
      sessionStorage.removeItem("lv");
    }
    this.loading = true;
    this.random_id = "T_" + this.randomString(8);
    this.random_Cbox_key= "T_" + new Date().getTime();

    console.log(sessionStorage.getItem("triggerGroupName"));

    if (
      JSON.parse(sessionStorage.getItem("triggerGroupName"))
        .includeLabelNames[0] === "針對未來新加入名單"
    ) {
      this.cantSignup = true;
    }
    this.selectEmail = sessionStorage.getItem("triggerEmail");
    this.selectSms = sessionStorage.getItem("triggerSms");
    this.script_title_sure = sessionStorage.getItem("triggerTitle");
    this.eventScheduleIdFromSche = sessionStorage.getItem("setResScheduleId");
    this.eventScriptIdFromSche = sessionStorage.getItem("setResScriptId");
    this.eventScriptHasEventFromSche = sessionStorage.getItem(
      "setResScriptHasEvent"
    );
    this.from = "script";
    this.token = sessionStorage.getItem("access_token");
    this.group = JSON.parse(
      sessionStorage.getItem("triggerGroupName")
    ).includeLabelNames;
    if (window.location.origin === "http://localhost:8080") {
      this.url8081 = `http://localhost:8081/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    } else if (
      window.location.origin === "https://firstcdp-test.retailing-data.net"
    ) {
      this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    } else if (
      window.location.origin === "https://firstcdp-dev.retailing-data.net"
    ) {
      this.url8081 = `https://firstcdp-dev2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    } else {
      this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
    }

    await this.get();
    await this.getSms();
    this.todos = this.emailtodos;
    this.smstodos.map((d) => {
      while (d.template_id === this.random_id) {
        this.random_id = "T_" + this.randomString(8);
      }
    });
    if (sessionStorage.getItem("fromScript") === "true") {
      this.getScriptById();
    } else {
      this.loading = false;
      this.version = '_ver_1'
    }
    // this.getRFM(),
    //   this.getRFMcustom(),
    //   this.getRFMchannel(),
    this.selectedgroupID = sessionStorage.getItem("triggerLabel");
    this.groupName = JSON.parse(sessionStorage.getItem("triggerGroupName"));
    if (sessionStorage.getItem("triggerEndtime") === "null") {
      this.endtime = null;
    } else {
      this.endtime = new Date(
        sessionStorage.getItem("triggerEndtime")
      ).toLocaleString("zh-CN", { hour12: false });
    }
    console.log(this.endtime);
    console.log(this.selectedgroupID);
    console.log(this.groupName);
    this.selectGroup = sessionStorage.getItem("triggerGroup");
    console.log("create this.selectedLabels", this.selectedLabels);

    window.addEventListener("beforeunload", this.beforeunloadcall);
  },
  // mounted() {
  // },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeunloadcall);
  },
  beforeRouteLeave: function (to, from, next) {
    let deleteyes = true;

    if (this.editList) {
      deleteyes = confirm("注意! 若尚為儲存就離開將不會保留劇本!");
    }
    if (deleteyes) {
      console.log("beforeRouteLeave");
      sessionStorage.removeItem("setResEventName");
      sessionStorage.removeItem("setResEventTitle");
      sessionStorage.removeItem("setResScheduleId");
      next();
    } else {
      return;
    }

    // sessionStorage.removeItem("setResScriptId");
    // sessionStorage.removeItem("setResScriptHasEvent");
    // sessionStorage.removeItem("fromScript");
    // sessionStorage.removeItem("triggerGroupName");
    // sessionStorage.removeItem("triggerLabel");
    // sessionStorage.removeItem("triggerTitle");
    // sessionStorage.removeItem("triggerGroup");
    // sessionStorage.removeItem("triggerEmail");
  },
  methods: {
    notDev() {
      if (this.notshow == 1) {
        alert("該功能為正式付費使用");
      } else {
        this.saveScript("2");
      }
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log("可用活動", res);
          res.data.Data.reverse();
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
          this.searchEvents = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    addCalen: async function () {
      if (this.renameEvent === "") {
        alert("必須輸入活動名稱");
        return;
      } else if (this.calenday === "" || this.calentime === "") {
        alert("必須輸入活動時間");
        return;
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        activity_id: Date.now(),
        name: this.renameEvent,
        start: this.calenday,
        end: this.calentime,
      };

      await allapi2
        .activityPost(params, body)
        .then((res) => {
          console.log(res);
          this.getCalenEvents();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    wantaddCalen() {
      this.changedateModal = new bootstrap.Modal(
        document.getElementById("changedateModal"),
        {
          keyboard: false,
        }
      );
      this.changedateModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      console.log(backdrop);
      backdrop[backdrop.length-1].style.zIndex = 3500;
    },
    selectCalendar_event(e) {
      this.calendar_eventID = e.id;
      this.calendar_event = e.title;
      this.autoShowcalendar_event = false;
    },

    customlabelInfoPost: async function (item, web, tag) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        item: item,
      };

      await allapi2
        .customlabelInfoPost(params, body)
        .then((res) => {
          console.log("tagValue資訊", res);
          this.tagValue = res.data.item.線上;

          this.valueLoading = "";
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.valueLoading = "";
          this.loading = false;
          alert("連線有誤");
        });
    },

    goScriptTable() {
      this.$router.push({
        name: "ScriptTable",
      });
    },
    getExpCycleTime() {
      var min = "";
      var hour = "";

      if (this.daytime.minutes.toString().length === 1) {
        min = "0" + this.daytime.minutes;
        console.log("min", min);
      } else {
        min = this.daytime.minutes;
      }

      if (this.daytime.hours.toString().length === 1) {
        hour = "0" + this.daytime.hours;
      } else {
        hour = this.daytime.hours;
      }

      if (this.happenTime === "年") {
        var month = "";
        if (
          this.happenYear_Selected === "一月" ||
          this.happenYear3_Selected === "一月"
        ) {
          month = "01";
        } else if (
          this.happenYear_Selected === "二月" ||
          this.happenYear3_Selected === "二月"
        ) {
          month = "02";
        } else if (
          this.happenYear_Selected === "三月" ||
          this.happenYear3_Selected === "三月"
        ) {
          month = "03";
        } else if (
          this.happenYear_Selected === "四月" ||
          this.happenYear3_Selected === "四月"
        ) {
          month = "04";
        } else if (
          this.happenYear_Selected === "五月" ||
          this.happenYear3_Selected === "五月"
        ) {
          month = "05";
        } else if (
          this.happenYear_Selected === "六月" ||
          this.happenYear3_Selected === "六月"
        ) {
          month = "06";
        } else if (
          this.happenYear_Selected === "七月" ||
          this.happenYear3_Selected === "七月"
        ) {
          month = "07";
        } else if (
          this.happenYear_Selected === "八月" ||
          this.happenYear3_Selected === "八月"
        ) {
          month = "08";
        } else if (
          this.happenYear_Selected === "九月" ||
          this.happenYear3_Selected === "九月"
        ) {
          month = "09";
        } else if (
          this.happenYear_Selected === "十月" ||
          this.happenYear3_Selected === "十月"
        ) {
          month = "10";
        } else if (
          this.happenYear_Selected === "十一月" ||
          this.happenYear3_Selected === "十一月"
        ) {
          month = "11";
        } else if (
          this.happenYear_Selected === "十二月" ||
          this.happenYear3_Selected === "十二月"
        ) {
          month = "12";
        }
        if (this.yearBasedWith === "日期") {
          var day = "";
          if (this.happenYear2_Selected.slice(0, -1).length < 2) {
            day = "0" + this.happenYear2_Selected.slice(0, -1);
          } else {
            day = this.happenYear2_Selected.slice(0, -1);
          }

          this.exp_cycleTime_first = `${moment(
            this.scriptDayStart
          ).year()}-${month}-${day} ${hour}:${min}`;

          if (moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)) {
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              1,
              "y"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else {
            this.exp_cycleTime_first = moment(this.exp_cycleTime_first).add(
              1,
              "y"
            );
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              1,
              "y"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }
        } else {
          var week = "";
          if (this.happenYear5_Selected === "星期一") {
            week = 1;
          } else if (this.happenYear5_Selected === "星期二") {
            week = 2;
          } else if (this.happenYear5_Selected === "星期三") {
            week = 3;
          } else if (this.happenYear5_Selected === "星期四") {
            week = 4;
          } else if (this.happenYear5_Selected === "星期五") {
            week = 5;
          } else if (this.happenYear5_Selected === "星期六") {
            week = 6;
          } else if (this.happenYear5_Selected === "星期日") {
            week = 0;
          }

          this.exp_cycleTime_first = `${moment(
            this.scriptDayStart
          ).year()}-${month}`;
          var searchFirstMonday = moment(this.exp_cycleTime_first).startOf(
            "month"
          );
          var searchLastMonday = moment(this.exp_cycleTime_first).endOf(
            "month"
          );

          console.log("searchFirstMonday", searchFirstMonday);
          console.log("searchLastMonday", searchLastMonday);

          if (this.happenYear4_Selected === "第1個") {
            while (searchFirstMonday.weekday() !== week) {
              searchFirstMonday.add(1, "day");
            }
            this.exp_cycleTime_first = new Date(
              searchFirstMonday.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenYear4_Selected === "第2個") {
            while (searchFirstMonday.weekday() !== week) {
              searchFirstMonday.add(1, "day");
            }
            searchFirstMonday.add(7, "day");
            this.exp_cycleTime_first = new Date(
              searchFirstMonday.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenYear4_Selected === "第3個") {
            while (searchFirstMonday.weekday() !== week) {
              searchFirstMonday.add(1, "day");
            }
            searchFirstMonday.add(14, "day");
            this.exp_cycleTime_first = new Date(
              searchFirstMonday.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenYear4_Selected === "第4個") {
            while (searchFirstMonday.weekday() !== week) {
              searchFirstMonday.add(1, "day");
            }
            searchFirstMonday.add(21, "day");
            this.exp_cycleTime_first = new Date(
              searchFirstMonday.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenYear4_Selected === "最後一個") {
            while (searchLastMonday.weekday() !== week) {
              searchLastMonday.add(-1, "day");
            }
            this.exp_cycleTime_first = new Date(
              searchLastMonday
                .add(-1, "day")
                .add(hour, "h")
                .add(min, "m")
                .add(1, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }

          if (moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)) {
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              1,
              "y"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else {
            this.exp_cycleTime_first = moment(this.exp_cycleTime_first).add(
              1,
              "y"
            );
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              1,
              "y"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }
        }
      }
      if (this.happenTime === "月") {
        if (this.happenMonth_moreoption && this.picked2 === "One") {
          this.exp_cycleTime_first = `${moment(this.scriptDayStart).year()}-${
            moment(this.scriptDayStart).month() + 1
          }-${this.happenMonth3_Selected.slice(0, -1)} ${hour}:${min}`;
          console.log("exp_cycleTime_first", this.exp_cycleTime_first);
          console.log(
            "moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)",
            moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)
          );
          if (moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)) {
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              this.happenMonth2_Selected.slice(1, -2),
              "M"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else {
            this.exp_cycleTime_first = moment(this.exp_cycleTime_first).add(
              1,
              "M"
            );
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              this.happenMonth2_Selected.slice(1, -2),
              "M"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }
        } else if (this.happenMonth_moreoption && this.picked2 === "Two") {
          var week2 = "";
          if (this.happenMonth5_Selected === "星期一") {
            week2 = 1;
          } else if (this.happenMonth5_Selected === "星期二") {
            week2 = 2;
          } else if (this.happenMonth5_Selected === "星期三") {
            week2 = 3;
          } else if (this.happenMonth5_Selected === "星期四") {
            week2 = 4;
          } else if (this.happenMonth5_Selected === "星期五") {
            week2 = 5;
          } else if (this.happenMonth5_Selected === "星期六") {
            week2 = 6;
          } else if (this.happenMonth5_Selected === "星期日") {
            week2 = 0;
          }

          this.exp_cycleTime_first = `${moment(this.scriptDayStart).year()}-${
            moment(this.scriptDayStart).month() + 1
          }`;
          var searchFirstMonday2 = moment(this.exp_cycleTime_first).startOf(
            "month"
          );
          var searchLastMonday2 = moment(this.exp_cycleTime_first).endOf(
            "month"
          );

          console.log("searchFirstMonday2", searchFirstMonday2);
          console.log("searchLastMonday2", searchLastMonday2);

          if (this.happenMonth4_Selected === "第1個") {
            while (searchFirstMonday2.weekday() !== week2) {
              searchFirstMonday2.add(1, "day");
            }
            this.exp_cycleTime_first = new Date(
              searchFirstMonday2.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenMonth4_Selected === "第2個") {
            while (searchFirstMonday2.weekday() !== week2) {
              searchFirstMonday2.add(1, "day");
            }
            searchFirstMonday2.add(7, "day");
            this.exp_cycleTime_first = new Date(
              searchFirstMonday2.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenMonth4_Selected === "第3個") {
            while (searchFirstMonday2.weekday() !== week2) {
              searchFirstMonday2.add(1, "day");
            }
            searchFirstMonday2.add(14, "day");
            this.exp_cycleTime_first = new Date(
              searchFirstMonday2.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenMonth4_Selected === "第4個") {
            while (searchFirstMonday2.weekday() !== week2) {
              searchFirstMonday2.add(1, "day");
            }
            searchFirstMonday2.add(21, "day");
            this.exp_cycleTime_first = new Date(
              searchFirstMonday2.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenMonth4_Selected === "最後一個") {
            while (searchLastMonday2.weekday() !== week2) {
              searchLastMonday2.add(-1, "day");
            }
            this.exp_cycleTime_first = new Date(
              searchLastMonday2
                .add(-1, "day")
                .add(hour, "h")
                .add(min, "m")
                .add(1, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }

          if (moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)) {
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              this.happenMonth2_Selected.slice(1, -2),
              "M"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else {
            this.exp_cycleTime_first = `${moment(this.scriptDayStart).year()}-${
              moment(this.scriptDayStart).month() + 2
            }`;
            var searchFirstMonday3 = moment(this.exp_cycleTime_first).startOf(
              "month"
            );
            var searchLastMonday3 = moment(this.exp_cycleTime_first).endOf(
              "month"
            );

            console.log("searchFirstMonday3", searchFirstMonday3);
            console.log("searchLastMonday3", searchLastMonday3);

            if (this.happenMonth4_Selected === "第1個") {
              while (searchFirstMonday3.weekday() !== week2) {
                searchFirstMonday3.add(1, "day");
              }
              this.exp_cycleTime_first = new Date(
                searchFirstMonday3.add(hour, "h").add(min, "m")
              )
                .toLocaleString("zh-CN")
                .slice(0, -3);
            } else if (this.happenMonth4_Selected === "第2個") {
              while (searchFirstMonday3.weekday() !== week2) {
                searchFirstMonday3.add(1, "day");
              }
              searchFirstMonday3.add(7, "day");
              this.exp_cycleTime_first = new Date(
                searchFirstMonday3.add(hour, "h").add(min, "m")
              )
                .toLocaleString("zh-CN")
                .slice(0, -3);
            } else if (this.happenMonth4_Selected === "第3個") {
              while (searchFirstMonday3.weekday() !== week2) {
                searchFirstMonday3.add(1, "day");
              }
              searchFirstMonday3.add(14, "day");
              this.exp_cycleTime_first = new Date(
                searchFirstMonday3.add(hour, "h").add(min, "m")
              )
                .toLocaleString("zh-CN")
                .slice(0, -3);
            } else if (this.happenMonth4_Selected === "第4個") {
              while (searchFirstMonday3.weekday() !== week2) {
                searchFirstMonday3.add(1, "day");
              }
              searchFirstMonday3.add(21, "day");
              this.exp_cycleTime_first = new Date(
                searchFirstMonday3.add(hour, "h").add(min, "m")
              )
                .toLocaleString("zh-CN")
                .slice(0, -3);
            } else if (this.happenMonth4_Selected === "最後一個") {
              while (searchLastMonday3.weekday() !== week2) {
                searchLastMonday3.add(-1, "day");
              }
              this.exp_cycleTime_first = new Date(
                searchLastMonday3
                  .add(-1, "day")
                  .add(hour, "h")
                  .add(min, "m")
                  .add(1, "m")
              )
                .toLocaleString("zh-CN")
                .slice(0, -3);
            }
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              this.happenMonth2_Selected.slice(1, -2),
              "M"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }
        } else if (this.happenMonth_moreoption === false) {
          this.exp_cycleTime_first = `${moment(this.scriptDayStart).year()}-${
            moment(this.scriptDayStart).month() + 1
          }-${this.happenMonth_Selected.slice(0, -1)} ${hour}:${min}`;
          console.log("exp_cycleTime_first", this.exp_cycleTime_first);
          console.log(
            "moment(this.exp_cycleTime_first)",
            moment(this.exp_cycleTime_first)
          );
          console.log(
            "moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)",
            moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)
          );
          if (moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)) {
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              1,
              "M"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else {
            this.exp_cycleTime_first = moment(this.exp_cycleTime_first).add(
              1,
              "M"
            );
            this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
              1,
              "M"
            );
            this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
              .toLocaleString("zh-CN")
              .slice(0, -3);
            this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }
        }
      }
      if (this.happenTime === "週") {
        var week3 = "";
        if (this.happenWeek2_Selected === "星期一") {
          week3 = 1;
        } else if (this.happenWeek2_Selected === "星期二") {
          week3 = 2;
        } else if (this.happenWeek2_Selected === "星期三") {
          week3 = 3;
        } else if (this.happenWeek2_Selected === "星期四") {
          week3 = 4;
        } else if (this.happenWeek2_Selected === "星期五") {
          week3 = 5;
        } else if (this.happenWeek2_Selected === "星期六") {
          week3 = 6;
        } else if (this.happenWeek2_Selected === "星期日") {
          week3 = 7;
        }
        console.log(moment(this.scriptDayStart).weekday(week3));
        this.exp_cycleTime_first = moment(this.scriptDayStart).weekday(week3);
        if (moment(this.exp_cycleTime_first) > moment(this.scriptDayStart)) {
          this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
            7 * this.happenWeek_Selected.slice(1, 2),
            "d"
          );
          this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
            .toLocaleString("zh-CN")
            .slice(0, -3);
          this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
            .toLocaleString("zh-CN")
            .slice(0, -3);
        } else {
          this.exp_cycleTime_first = moment(this.exp_cycleTime_first).add(
            7,
            "d"
          );
          this.exp_cycleTime_second = moment(this.exp_cycleTime_first).add(
            7 * this.happenWeek_Selected.slice(1, 2),
            "d"
          );

          this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
            .toLocaleString("zh-CN")
            .slice(0, -3);
          this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
            .toLocaleString("zh-CN")
            .slice(0, -3);
        }
      }
      if (this.happenTime === "日") {
        this.exp_cycleTime_first = moment(this.scriptDayStart).add(
          this.happenDay_Selected.slice(1, -1),
          "d"
        );
        this.exp_cycleTime_second = moment(this.scriptDayStart)
          .add(this.happenDay_Selected.slice(1, -1), "d")
          .add(this.happenDay_Selected.slice(1, -1), "d");
        this.exp_cycleTime_first = new Date(this.exp_cycleTime_first)
          .toLocaleString("zh-CN")
          .slice(0, -3);
        this.exp_cycleTime_second = new Date(this.exp_cycleTime_second)
          .toLocaleString("zh-CN")
          .slice(0, -3);
      }
    },
    showToast() {
      var toastLiveExample = document.getElementById("liveToast");
      var toast = new bootstrap.Toast(toastLiveExample);

      toast.show();
    },
    openExcludeModal() {
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    scriptTimeSetting() {
      this.scriptTimeModal = new bootstrap.Modal(
        document.getElementById("scriptTimeModal"),
        {
          keyboard: false,
        }
      );
      this.scriptTimeModal.show();
    },
    getCron() {
      var cronday = "";
      var cronmonth = "";
      var cronweek = "";
      var min = "";
      var hour = "";

      console.log("this.daytime", this.daytime);

      if (this.daytime != undefined || this.daytime != null) {
        if (this.daytime.minutes.toString().length === 1) {
          min = "0" + this.daytime.minutes;
          console.log("min", min);
        } else {
          min = this.daytime.minutes;
        }

        if (this.daytime.hours.toString().length === 1) {
          hour = "0" + this.daytime.hours;
        } else {
          hour = this.daytime.hours;
        }
        this.ader_daytime = `${hour}:${min}`;
      } else {
        this.ader_triggerCron = "";
      }

      if (this.happenTime === "月") {
        if (this.happenMonth_moreoption && this.picked2 === "Two") {
          cronday = "?";
          var whichWeek = 1;
          if (this.happenMonth5_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenMonth5_Selected === "星期一") {
            whichWeek = 1;
          } else if (this.happenMonth5_Selected === "星期二") {
            whichWeek = 2;
          } else if (this.happenMonth5_Selected === "星期三") {
            whichWeek = 3;
          } else if (this.happenMonth5_Selected === "星期四") {
            whichWeek = 4;
          } else if (this.happenMonth5_Selected === "星期五") {
            whichWeek = 5;
          } else if (this.happenMonth5_Selected === "星期六") {
            whichWeek = 6;
          } else if (this.happenMonth5_Selected === "星期日") {
            whichWeek = 7;
          }
          var latestWeek = 1;
          if (this.happenMonth4_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenMonth4_Selected.slice(1, 2) != "後") {
            latestWeek = this.happenMonth4_Selected.slice(1, 2);
          } else {
            latestWeek = "L";
          }
          cronweek = whichWeek + "#" + latestWeek;

          this.exp_cycleTime_first = `${moment(this.cycleDayStart).year()}-${
            moment(this.cycleDayStart).month() + 1
          }`;
          var searchFirstMonday2 = moment(this.exp_cycleTime_first).startOf(
            "month"
          );
          var searchLastMonday2 = moment(this.exp_cycleTime_first).endOf(
            "month"
          );
          if (this.happenMonth4_Selected != "最後一個") {
            while (searchFirstMonday2.weekday() !== whichWeek) {
              searchFirstMonday2.add(1, "day");
            }
            searchFirstMonday2.add(
              7 * (this.happenMonth4_Selected.slice(1, 2) - 1),
              "day"
            );
            this.exp_cycleTime_first = new Date(
              searchFirstMonday2.add(hour, "h").add(min, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          } else if (this.happenMonth4_Selected === "最後一個") {
            while (searchLastMonday2.weekday() !== whichWeek) {
              searchLastMonday2.add(-1, "day");
            }
            this.exp_cycleTime_first = new Date(
              searchLastMonday2
                .add(-1, "day")
                .add(hour, "h")
                .add(min, "m")
                .add(1, "m")
            )
              .toLocaleString("zh-CN")
              .slice(0, -3);
          }

          console.log("this.cycleDayStart", this.cycleDayStart);
          console.log("this.exp_cycleTime_first)", moment("2022-08-05"));
          console.log(
            "moment(this.exp_cycleTime_first) > moment(this.cycleDayStart)",
            moment(this.exp_cycleTime_first) > moment(this.cycleDayStart)
          );

          if (moment(this.exp_cycleTime_first) > moment(this.cycleDayStart)) {
            cronmonth = moment(this.exp_cycleTime_first).month() + 1;
          } else {
            cronmonth =
              moment(this.exp_cycleTime_first).add(1, "M").month() + 1;
          }

          if (this.happenMonth2_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }

          this.time_every = this.happenMonth2_Selected.slice(1, -2) * 31;
        } else if (this.happenMonth_moreoption && this.picked2 === "One") {
          if (this.happenMonth3_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenMonth3_Selected.slice(0, -1) != "最後一") {
            cronday = this.happenMonth3_Selected.slice(0, -1);
            this.exp_cycleTime_first = `${moment(this.cycleDayStart).year()}-${
              moment(this.cycleDayStart).month() + 1
            }-${this.happenMonth3_Selected.slice(0, -1)} ${hour}:${min}`;
          } else {
            cronday = "L";
            const endofmonth = moment(this.cycleDayStart)
              .endOf("month")
              .format("YYYY-MM-DD");
            this.exp_cycleTime_first = `${endofmonth} ${hour}:${min}`;
          }

          if (moment(this.exp_cycleTime_first) > moment(this.cycleDayStart)) {
            cronmonth = moment(this.exp_cycleTime_first).month() + 1;
          } else {
            cronmonth =
              moment(this.exp_cycleTime_first).add(1, "M").month() + 1;
          }

          cronweek = "?";
          if (this.happenMonth2_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }

          this.time_every = this.happenMonth2_Selected.slice(1, -2) * 31;
        } else if (this.happenMonth_moreoption === false) {
          if (this.happenMonth_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenMonth_Selected.slice(0, -1) != "最後一") {
            cronday = this.happenMonth_Selected.slice(0, -1);
            this.exp_cycleTime_first = `${moment(this.cycleDayStart).year()}-${
              moment(this.cycleDayStart).month() + 1
            }-${this.happenMonth_Selected.slice(0, -1)} ${hour}:${min}`;
          } else {
            cronday = "L";
            const endofmonth = moment(this.cycleDayStart)
              .endOf("month")
              .format("YYYY-MM-DD");
            this.exp_cycleTime_first = `${endofmonth} ${hour}:${min}`;
          }
          console.log("this.exp_cycleTime_first", this.exp_cycleTime_first);
          console.log(
            "moment(this.exp_cycleTime_first)",
            moment("2022-08-01 16:55")
          );

          if (moment(this.exp_cycleTime_first) > moment(this.cycleDayStart)) {
            cronmonth = moment(this.exp_cycleTime_first).month() + 1;
          } else {
            cronmonth =
              moment(this.exp_cycleTime_first).add(1, "M").month() + 1;
          }
          cronweek = "?";

          this.time_every = 31;
        }
      } else if (this.happenTime === "年") {
        if (this.yearBasedWith === "日期") {
          var month = "";
          if (this.happenYear_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenYear_Selected === "一月") {
            month = "1";
          } else if (this.happenYear_Selected === "二月") {
            month = "2";
          } else if (this.happenYear_Selected === "三月") {
            month = "3";
          } else if (this.happenYear_Selected === "四月") {
            month = "4";
          } else if (this.happenYear_Selected === "五月") {
            month = "5";
          } else if (this.happenYear_Selected === "六月") {
            month = "6";
          } else if (this.happenYear_Selected === "七月") {
            month = "7";
          } else if (this.happenYear_Selected === "八月") {
            month = "8";
          } else if (this.happenYear_Selected === "九月") {
            month = "9";
          } else if (this.happenYear_Selected === "十月") {
            month = "10";
          } else if (this.happenYear_Selected === "十一月") {
            month = "11";
          } else if (this.happenYear_Selected === "十二月") {
            month = "12";
          }
          cronmonth = month;
          if (this.happenYear2_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          cronday = this.happenYear2_Selected.slice(0, -1);
          cronweek = "?";
        } else if (this.yearBasedWith === "天") {
          var month2 = "";
          if (this.happenYear3_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenYear3_Selected === "一月") {
            month2 = "1";
          } else if (this.happenYear3_Selected === "二月") {
            month2 = "2";
          } else if (this.happenYear3_Selected === "三月") {
            month2 = "3";
          } else if (this.happenYear3_Selected === "四月") {
            month2 = "4";
          } else if (this.happenYear3_Selected === "五月") {
            month2 = "5";
          } else if (this.happenYear3_Selected === "六月") {
            month2 = "6";
          } else if (this.happenYear3_Selected === "七月") {
            month2 = "7";
          } else if (this.happenYear3_Selected === "八月") {
            month2 = "8";
          } else if (this.happenYear3_Selected === "九月") {
            month2 = "9";
          } else if (this.happenYear3_Selected === "十月") {
            month2 = "10";
          } else if (this.happenYear3_Selected === "十一月") {
            month2 = "11";
          } else if (this.happenYear3_Selected === "十二月") {
            month2 = "12";
          }
          var yearweek = "";
          if (this.happenYear5_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenYear5_Selected === "星期一") {
            yearweek = 1;
          } else if (this.happenYear5_Selected === "星期二") {
            yearweek = 2;
          } else if (this.happenYear5_Selected === "星期三") {
            yearweek = 3;
          } else if (this.happenYear5_Selected === "星期四") {
            yearweek = 4;
          } else if (this.happenYear5_Selected === "星期五") {
            yearweek = 5;
          } else if (this.happenYear5_Selected === "星期六") {
            yearweek = 6;
          } else if (this.happenYear5_Selected === "星期日") {
            yearweek = 7;
          }
          var whenweek = "";
          if (this.happenYear4_Selected === undefined) {
            this.cycleCron = "NaN";
            return;
          }
          if (this.happenYear4_Selected.slice(1, 2) === "後") {
            whenweek = "L";
          } else {
            whenweek = this.happenYear4_Selected.slice(1, 2);
          }
          cronmonth = month2;
          cronday = "?";
          cronweek = yearweek + "#" + whenweek;
        }
        this.time_every = 0;
      } else if (this.happenTime === "週") {
        var week3 = "";
        if (this.happenWeek2_Selected === undefined) {
          this.cycleCron = "NaN";
          return;
        }
        if (this.happenWeek2_Selected === "星期一") {
          week3 = 1;
        } else if (this.happenWeek2_Selected === "星期二") {
          week3 = 2;
        } else if (this.happenWeek2_Selected === "星期三") {
          week3 = 3;
        } else if (this.happenWeek2_Selected === "星期四") {
          week3 = 4;
        } else if (this.happenWeek2_Selected === "星期五") {
          week3 = 5;
        } else if (this.happenWeek2_Selected === "星期六") {
          week3 = 6;
        } else if (this.happenWeek2_Selected === "星期日") {
          week3 = 7;
        }
        console.log(moment(this.cycleDayStart).weekday(week3)._d);
        this.exp_cycleTime_first = moment(this.cycleDayStart).weekday(week3)._d;
        if (
          moment(this.exp_cycleTime_first).add(hour, "h").add(min, "m") >
          moment(this.cycleDayStart)
        ) {
          cronday = moment(this.exp_cycleTime_first).get("date");
          cronmonth = moment(this.exp_cycleTime_first).month() + 1;
        } else {
          cronday = moment(this.exp_cycleTime_first).add(7, "d").get("date");
          cronmonth =
            moment(this.exp_cycleTime_first).add(7, "d").get("month") + 1;
        }
        cronweek = "?";
        if (this.happenWeek_Selected === undefined) {
          this.cycleCron = "NaN";
          return;
        }

        this.time_every = this.happenWeek_Selected.slice(1, -1) * 7;
      } else if (this.happenTime === "日") {
        this.exp_cycleTime_first = `${moment(this.cycleDayStart).year()}-${
          moment(this.cycleDayStart).month() + 1
        }-${moment(this.cycleDayStart).get("date")} ${hour}:${min}`;
        console.log(
          "moment(this.exp_cycleTime_first)",
          moment(this.exp_cycleTime_first)
        );
        console.log("moment(new Date())", new Date());

        cronday = moment(this.exp_cycleTime_first).get("date");
        cronmonth = moment(this.exp_cycleTime_first).month() + 1;

        // } else {
        //   cronday = moment(this.exp_cycleTime_first).get("date") + 1;
        //   cronmonth = moment(this.exp_cycleTime_first).month() + 1;
        // }
        cronweek = "?";
        if (this.happenDay_Selected === undefined) {
          this.cycleCron = "NaN";
          return;
        }

        this.time_every = this.happenDay_Selected;
      }

      if (this.happenTime === "年") {
        this.cycleCron = `${min} ${hour} ${cronday} ${cronmonth} ${cronweek} *`;
      } else {
        this.cycleCron = `${min} ${hour} ${cronday} ${cronmonth} ${cronweek} ${moment(
          this.cycleDayStart
        ).year()}`;
      }
      if (this.happenTime === "週") {
        this.ader_cycleDayStart = `${moment(
          this.cycleDayStart
        ).year()}-${cronmonth}-${cronday}`;
      } else {
        this.ader_cycleDayStart = this.cycleDayStart;
      }

      console.log("this.cycleCron", this.cycleCron);
    },
    getNotCron(which, which2) {
      console.log("this.happenWeek_Selected", this.happenWeek_Selected);
      if (this.happenTime === "年") {
        if (this.happenYear_Selected != undefined) {
          this.time_every = this.happenYear_Selected.slice(1, 2) * 365;
        } else {
          this.time_every = "";
        }
      } else if (this.happenTime === "月") {
        if (this.happenMonth_Selected != undefined) {
          this.time_every = this.happenMonth_Selected.slice(1, 2) * 31;
        } else {
          this.time_every = "";
        }
      } else if (this.happenTime === "週") {
        if (this.happenWeek_Selected != undefined) {
          this.time_every = this.happenWeek_Selected.slice(1, 2) * 7;
        } else {
          this.time_every = "";
        }
      } else if (this.happenTime === "日") {
        if (this.happenDay_Selected != undefined) {
          this.time_every = this.happenDay_Selected;
        } else {
          this.time_every = "";
        }
      }

      var min = "";
      var hour = "";
      console.log(this.daytime);
      if (this.daytime != undefined || this.daytime != null) {
        if (this.daytime.minutes.toString().length === 1) {
          min = "0" + this.daytime.minutes;
          console.log("min", min);
        } else {
          min = this.daytime.minutes;
        }

        if (this.daytime.hours.toString().length === 1) {
          hour = "0" + this.daytime.hours;
        } else {
          hour = this.daytime.hours;
        }
        this.ader_daytime = `${hour}:${min}`;
      } else {
        this.ader_daytime = "";
      }

      console.log("this.endCount2", this.endCount2);

      if (this.endCount2 === "") {
        this.ader_endCount = -1;
      } else {
        this.ader_endCount = +this.endCount2;
      }

      console.log(this.time_every);
      console.log(this.ader_daytime);
      console.log(this.ader_endCount);
      if (which2 === "1") {
        if (this.ader_daytime != "" && this.time_every != "") {
          console.log("1ok");
          this["ader_" + which + "triggerCron"] = "ok";
        } else {
          console.log("1notok");
          this["ader_" + which + "triggerCron"] = "";
        }
      }

      if (which2 === "2") {
        if (this.ader_daytime != "" && this.time_every != "") {
          console.log("2ok");
          this["ader_" + which + "triggerCron2"] = "ok";
        } else {
          console.log("2notok");
          this["ader_" + which + "triggerCron2"] = "";
        }
      }
    },
    aderExpSendDate: async function () {
      this.getCron();
      this.aderTestCron = "";
      if (this.happenTime === "年") {
        this.aderTestCron = this.cycleCron.replace("?", "*").slice(0, -2);
      } else {
        this.aderTestCron = this.cycleCron.slice(0, -5).replace("?", "*");
        this.aderTestCron =
          this.aderTestCron.slice(0, 8).trim() +
          " * " +
          this.aderTestCron.split(" ")[4];
      }
      console.log("this.cycleCron", this.cycleCron);
      console.log("this.aderTestCron", this.aderTestCron);
      console.log("this.cycleDayStart", this.cycleDayStart);

      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        time_start: this.ader_cycleDayStart,
        cron: this.aderTestCron,
        time_every: this.time_every,
      };

      await allapi2
        .triggerScriptGetExecTimePost(params, body)
        .then((res) => {
          console.log("ader 試算寄送時間", res);
          this.exp_cycleTime_first_ader = res.data.next1.slice(0, -3);
          this.exp_cycleTime_second_ader = res.data.next2.slice(0, -3);
          this.ader_triggerCron = "ok";
        })
        .catch((err) => {
          console.log("連線有誤");
          this.exp_cycleTime_first_ader = "試算失敗";
          this.exp_cycleTime_second_ader = "";
          console.log(err);
          this.ader_triggerCron = "";
        });
    },
    onCopy: function (e) {
      console.log("You just copied: " + e.text);
    },
    onError: function (e) {
      alert("Failed to copy texts");
    },
    showVar() {
      const varnameModal = new bootstrap.Modal(
        document.getElementById("varnameModal"),
        {
          keyboard: false,
        }
      );
      varnameModal.show();
    },
    selectValueByDrop(selectValue, changingSelected) {
      this[changingSelected] = selectValue;
      if (changingSelected === "triggerSelected") {
        this.time_trigger_state_Selected = "";
      }
      if (selectValue === "已開啟信件" || selectValue === "已點擊連結") {
        this.event_moreoption_Selected = "";
      }
    },
    selectValueByDrop_triggerBuyTag(selectValue, changingSelected) {
      this[changingSelected] = selectValue;
      if (selectValue === "-") {
        this.tagValue = [];
        this.tagValueSelected = "-";
      } else {
        let item = "";
        this.valueLoading = "loading";
        if (selectValue === "商品") {
          item = "productsales";
        } else if (selectValue === "類別") {
          item = "categorysales";
        } else if (selectValue === "品牌") {
          item = "brandsales";
        }
        this.customlabelInfoPost(item);
      }
    },
    selectValueByDrop_triggerBuyValue(selectValue, changingSelected) {
      this[changingSelected] = selectValue;
    },
    gobacktotriggerscript() {
      this.$router.push({
        name: "TriggerScript",
      });
    },
    addLabels(todo) {
      const ids = this.selectedLabels.map((d) => {
        return d.labelId || d.label_id;
      });
      const hasid = ids.findIndex((d) => {
        return d === todo.label_id || d === todo.labelId;
      });

      console.log(ids);
      console.log(hasid);
      if (hasid != -1) {
        return;
      }
      this.selectedLabels.push(todo);
    },
    deleteLabels(todo) {
      this.selectedLabels = this.selectedLabels.filter((d) => {
        return d != todo;
      });
    },
    test() {
      location.href("");
    },
    getlabelID: async function (state) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        state: state,
      };

      await allapi2
        .triggerScriptCreateLabelPost(params, body)
        .then((res) => {
          console.log("選到的LABELID", res);
          this.selectedgroupID = res.data.label_id;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // getRFM: async function () {
    //   const token = sessionStorage.getItem("access_token");
    //   const params = {
    //     Authorization: "Bearer " + token,
    //   };

    //   await allapi2
    //     .customerRfmGet(params)
    //     .then((res) => {
    //       console.log(res);
    //       this.RFMtodos = res.data.Data;
    //       this.grouptodos = this.RFMtodos;
    //       this.origingrouptodos = this.RFMtodos;
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤");
    //       console.log(err);
    //     });
    // },
    // getRFMcustom: async function () {
    //   const token = sessionStorage.getItem("access_token");
    //   const params = {
    //     Authorization: "Bearer " + token,
    //   };

    //   await allapi2
    //     .customerLabelGet(params)
    //     .then((res) => {
    //       console.log(res);
    //       this.customtodos = res.data.Data;
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤");
    //       console.log(err);
    //     });
    // },
    // getRFMchannel: async function () {
    //   const token = sessionStorage.getItem("access_token");
    //   const params = {
    //     Authorization: "Bearer " + token,
    //   };

    //   await allapi2
    //     .remarketinglabelGet(params)
    //     .then((res) => {
    //       console.log(res);
    //       this.channeltodos = res.data.Data;
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤");
    //       console.log(err);
    //     });
    // },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    updateGroup() {
      this.tabState = "group";
      this.selectGroup = this.groupName.toString();
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
    },

    renametodo(todo) {
      this.childtodo = todo;
      console.log(this.childtodo);
      this.newTempName = todo.name;

      const renameModal = new bootstrap.Modal(
        document.getElementById("renameModal"),
        {
          keyboard: false,
        }
      );
      renameModal.show();
    },
    confirmrename: async function () {
      if (this.newTempNameError) {
        alert("模板命名有誤");
        return;
      }
      if (
        this.emailtodos.findIndex((d) => d.name === this.newTempName) != "-1"
      ) {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "您要使用的模板名稱已有其他模板使用";
        const renameModal = new bootstrap.Modal(
          document.getElementById("renameModal"),
          {
            keyboard: false,
          }
        );
        renameModal.show();
      } else if (this.newTempName === "") {
        this.newTempNameError = true;
        this.newTempNameErrMsg = "必須輸入模板名稱";
        const renameModal = new bootstrap.Modal(
          document.getElementById("renameModal"),
          {
            keyboard: false,
          }
        );
        renameModal.show();
      } else {
        await this.patchTemplateApi(this.childtodo);
        this.selectValue(this.searchTodos[0]);
        this.newTempName = "";
        this.changePage();
      }
    },
    patchTemplateApi: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: todo.key,
        name: this.newTempName,
        json: this.todojson,
        html: this.todohtml,
      };

      await allapi2
        .templatev2TempPatch(params, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
        });
      await this.get();
    },

    confirmUpdateGroupTab() {
      if (this.selectGroup === "自建名單") {
        if (this.currentTrigger === "註冊") {
          return alert("只有針對未來新加入名單，可以觸發註冊");
        }
        if (this.selectedLabels[0] === undefined) {
          return alert("自建名單需選擇客群");
        }
        this.selectedgroupID = this.selectedLabels.map((d) => {
          if (d.label_id === undefined) {
            return d.labelId;
          } else {
            return d.label_id;
          }
        });
        this.groupName = this.selectedLabels.map((d) => d.label);
        console.log("this.selectedgroupID", this.selectedgroupID);
      } else {
        if (this.selectGroup === "現有顧客+未來新加入名單") {
          if (this.currentTrigger === "註冊") {
            return alert("只有針對未來新加入名單，可以觸發註冊");
          }
          this.groupName = ["現有顧客+未來新加入名單"];
          this.getlabelID("all");
        } else if (this.selectGroup === "針對現有顧客名單") {
          if (this.currentTrigger === "註冊") {
            return alert("只有針對未來新加入名單，可以觸發註冊");
          }
          this.groupName = ["針對現有顧客名單"];
          this.getlabelID("now");
        } else if (this.selectGroup === "針對未來新加入名單") {
          this.groupName = ["針對未來新加入名單"];
          this.getlabelID("new");
        }
      }
      this.offCanvasClick.hide();
    },
    showUnlayer() {
      this.unlayerModal = new bootstrap.Modal(
        document.getElementById("unlayerModal"),
        {
          keyboard: false,
        }
      );
      this.unlayerModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=3500

      const unlayerModal = document.getElementById("unlayerModal");
      var oldtodos = this.todos;
      var vm = this;

      if (unlayerModal.refreshtodos) {
        unlayerModal.removeEventListener(
          "hidden.bs.modal",
          unlayerModal.refreshtodos
        );
      }
      unlayerModal.refreshtodos = () => {
        const token = sessionStorage.getItem("access_token");

        const params = {
          Authorization: "Bearer " + token,
        };
        allapi2
          .templatev2TempInfoGet(params)
          .then((res) => {
            console.log("GET", res);
            this.emailtodos = res.data;
            this.todos = res.data;
            this.todos.sort((a, b) => {
              return new Date(b.time) - new Date(a.time);
            });

            this.searchTodos = this.todos;
            console.log("this.todos", this.todos);
            if (oldtodos[0].name != this.todos[0].name) {
              vm.changePage();
            }
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
            alert("資料連線有誤" + err + "將返回劇本一覽表");
            this.$router.push({
              name: "ScriptTable",
            });

            this.loading = false;
          });
      };
      unlayerModal.addEventListener(
        "hidden.bs.modal",
        unlayerModal.refreshtodos
      );
    },
    offCanvas() {
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
    },
    showexp: async function () {
      await this.getScriptCboxBykey(this.key);
      if (this.noCbox!=true) {
        console.log("Cbox");
      } else {
        await this.getTemplateByScriptkey(this.key);
      }
      this.changeAllUrl()
      this.newHtml = this.newHtml
        .replace(
          "{{p1}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
        )
        .replace(
          "{{p2}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
        )
        .replace(
          "{{p3}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
        )
        .replace(
          "{{p4}}",
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
        )
        .replace("{{tracking}}", "");
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    changeChannel(boolean) {
      this.emailChannel = boolean;
      console.log(this.emailChannel === true);
      if (this.emailChannel) {
        this.todos = this.emailtodos;
      } else {
        this.todos = this.smstodos;
      }
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newHtml);
      console.log(this.fullurlArr)
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.newHtml = this.newHtml.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },

    showMoneyPop() {
      // console.log("pop")
      this.isShowMoney = !this.isShowMoney;
    },
    changePage() {
      this.$refs.RefChilde.changePage();
    },
    show() {
      this.isShow = !this.isShow;
    },
    show2() {
      this.isShow2 = !this.isShow2;
    },
    show3() {
      this.isShow3 = !this.isShow3;
    },
    titleChange() {
      this.editinput = true;
    },
    subChange() {
      this.editinput = true;
    },
    textChange() {
      this.editinput = true;
    },

    // new script template
    getTemplateById: async function (todo) {
      this.loading_copy = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: todo.key,
      };

      await allapi2
        .templatev2TempKeyGet(params)
        .then((res) => {
          console.log("拿原始模板ID的todohtml", res);
          this.todojson = res.data.json;
          this.todohtml = res.data.html;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
        });
      this.sliceStyle();
      this.changeCustomTool();
      await this.getallhttp();
      await this.getAllShortHttp();
      this.changeHtmlhttp();
      this.loading_copy = false;
    },
    getSmsTemplateById: async function (todo) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: todo.key,
      };

      await allapi2
        .smsTemplateSmsTempKeyGet(params)
        .then((res) => {
          console.log(res.data);
          this.newSubsec = res.data.data.text;
          this.todojson = res.data.data.text;
          this.newHtml = res.data.data.newText;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
        });
    },
    postScriptTemplate: async function () {
      console.log(this.todojson);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.vice_id,
        temp: {
          json: this.todojson,
          newHtml: this.newHtml,
        },
      };
      await allapi2
        .scriptTempTempPost(params, body)
        .then((res) => {
          console.log("儲存劇本模板ID的newHtml", res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
    },
    postScriptTemplate2: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.vice_id,
        temp: {
          json: this.newSubsec,
          newHtml: this.newHtml,
        },
      };
      await allapi2
        .scriptTempTempPost(params, body)
        .then((res) => {
          console.log(res);
          this.newTodoTitle = this.smsTitle;
          this.confirmUpdateTemplateTab();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
    },
    postScriptSchedule: async function (key) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: key,
        schedule: this.newHtml,
      };
      await allapi2
        .scriptTempSchedulePost(params, body)
        .then((res) => {
          console.log("儲存劇本的D箱子", res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("資料連線有誤" + err + "將返回劇本一覽表");
          // this.$router.push({
          //   name: "ScriptTable",
          // });
          this.patchScriptSchedule(key);
          this.loading = false;
        });
    },
    patchScriptSchedule: async function (key) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: key,
        schedule: this.newHtml,
      };
      await allapi2
        .scriptTempSchedulePatch(params, body)
        .then((res) => {
          console.log("儲存劇本的D箱子", res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("資料連線有誤" + err + "將返回劇本一覽表");
          // this.$router.push({
          //   name: "ScriptTable",
          // });
          this.loading = false;
        });
    },
    getTemplateByScriptkey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: key,
      };

      await allapi2
        .scriptTempTempIdGet(params)
        .then((res) => {
          console.log("拿劇本模板ID的資料", res);
          console.log("拿劇本模板ID的newHtml", res);
          if (this.emailChannel === false) {
            this.newSubsec = res.data.data.json;
          }
          this.newHtml = res.data.data.newHtml;
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          console.log(err);
        });
    },
    getScriptCboxBykey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: key+this.random_Cbox_key,
      };

      await allapi2
        .scriptTempCacheKeyGet(params)
        .then((res) => {
          console.log("拿劇本Cbox模板ID的資料", res);
          if (res.data.html === 'not found') {
            this.noCbox=true
          }else{
            this.noCbox=false
          }
          if (this.emailChannel === false) {
            this.newSubsec = res.data.json;
          }
          this.todojson = res.data.json;
          this.newHtml = res.data.html;
          console.log(
            `現在getScriptCboxBykey是${this.key} this.noCbox是${res.data.html} `
          );
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getScheByScriptkey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: key,
      };

      await allapi2
        .scriptTempScheduleIdGet(params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          console.log(err);
        });
    },

    // For template control
    sliceStyle() {
      // const styleStart = this.todohtml.indexOf('<style type="text/css">');
      // const styleEnd = this.todohtml.indexOf("</style>");
      const bodyEnd = this.todohtml.indexOf("</body>");
      // const html1 = this.todohtml.slice(0, styleStart);
      // const html2 = this.todohtml.slice(styleEnd + 8, bodyEnd);
      // const html3 = this.todohtml.slice(bodyEnd);
      // this.newHtml = html1 + html2 + "<div>{{tracking}}</div>" + html3;
      // var re = /<p style="/gi;
      // this.newHtml = this.newHtml.replace(re, '<p style="margin:0;');
      const html1 = this.todohtml.slice(0, bodyEnd);
      const html3 = this.todohtml.slice(bodyEnd);
      this.newHtml = html1 + "<div>{{tracking}}</div>" + html3;
    },
    changeCustomTool() {
      this.newHtml = this.newHtml
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png",
          "{{p1}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png",
          "{{p2}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png",
          "{{p3}}"
        )
        .replace(
          "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png",
          "{{p4}}"
        )
        .replace("www.google1.com", "{{p1_path}}")
        .replace("www.google2.com", "{{p2_path}}")
        .replace("www.google3.com", "{{p3_path}}")
        .replace("www.google4.com", "{{p4_path}}");
    },
    getallhttp: async function () {
      function isValidURL(string) {
        var res = string.match(
          /"(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newHtml);

      this.fullurlArr = this.fullurlArr.filter((d) => {
        return (
          !d.includes(
            "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"
          ) &&
          !d.includes("http://www.w3.org/1999/xhtml") &&
          !d.includes("rema-images") &&
          !d.includes("rema-image-resize") &&
          !d.includes("cdn.tools.unlayer") &&
          !d.includes("@")
        );
      });
      this.fullurlArr = this.fullurlArr.map((d) => {
        return d.slice(1, -1);
      });

      console.log("this.fullurlArr", this.fullurlArr);
    },
    getAllShortHttp: async function () {
      this.shortUrlArr = [];
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrlApi(this.fullurlArr[index]);
      }
      console.log("this.shortUrlArr", this.shortUrlArr);
    },
    changeHtmlhttp() {
      for (let index = 0; index < this.fullurlArr.length; index++) {
        this.Parr.push("{[pfullurl]}" + index);
        this.newHtml = this.newHtml.replace(
          this.fullurlArr[index],
          this.Parr[index]
        );
      }
      for (let index = 0; index < this.fullurlArr.length; index++) {
        this.newHtml = this.newHtml.replace(
          this.Parr[index],
          this.shortUrlArr[index]
        );
      }
      console.log("this.shortUrlArr", this.shortUrlArr);
    },
    getShortUrlApi: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        template_id: this.vice_id+this.version,
      };
      await allapi2
        .triggerScriptShortenUrlPost(params, body)
        .then((res) => {
          console.log(res);
          this.shortUrlArr.push(res.data.url + `?i={{id}}&z=${this.randomString(12)}&x=${this.randomString(12)}`);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("短網址生成失敗");
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.htmlok = false;
          this.fullurlArr = [];
          this.shortUrlArr = [];
        });
    },

    search(val) {
      var searched = [];
      if (this.emailChannel) {
        searched = this.emailtodos.filter((d) => d.name.includes(val));
      } else {
        searched = this.smstodos.filter((d) => d.name.includes(val));
      }
      this.todos = searched;
    },
    changeLoading() {
      this.loading = !this.loading;
    },
    nameEmailTemp() {
      if (this.emailtodos.findIndex((d) => d.name === this.eventName) != "-1") {
        this.eventNameError = true;
        this.eventNameErrMsg = "您要使用的模板名稱已有其他模板使用";
        const nameModal = new bootstrap.Modal(
          document.getElementById("nameModal"),
          {
            keyboard: false,
          }
        );
        nameModal.show();
      } else if (this.eventName === "") {
        this.eventNameError = true;
        this.eventNameErrMsg = "必須輸入模板名稱";
        const nameModal = new bootstrap.Modal(
          document.getElementById("nameModal"),
          {
            keyboard: false,
          }
        );
        nameModal.show();
      } else {
        this.from = "scriptTemplate";
        this.name = this.eventName;
        this.key = "undefined";
        if (window.location.origin === "http://localhost:8080") {
          this.url8081 = `http://localhost:8081/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-dev.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-dev2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else {
          this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        }
        this.showUnlayer();
      }
    },
    clearalltoadd() {
      this.selectedID = "";
      this.newTodoTitle = "";
      this.newSub = "";
      this.newSubsec = "";
      this.smsTitle = "";

      if (this.emailChannel) {
        this.nameModal = new bootstrap.Modal(
          document.getElementById("nameModal"),
          {
            keyboard: false,
          }
        );
        this.nameModal.show();
      } else {
        this.smsModal = new bootstrap.Modal(
          document.getElementById("smsModal"),
          {
            keyboard: false,
          }
        );
        this.smsModal.show();
        const backdrop = document.getElementsByClassName("modal-backdrop");
        backdrop[backdrop.length - 1].style['z-index']=3500
      }
    },
    refreshtodo: async function () {
      await this.get();
      await this.selectValue(this.todos[0]);
    },
    copytodo: async function (todo) {
      this.loading = true;
      console.log(todo);
      this.childtodo = todo;
      while (
        this.todos.findIndex(
          (d) =>
            d.template_name ===
            this.childtodo.template_name + "(" + this.i + ")"
        ) != "-1"
      ) {
        this.i++;
      }
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        template_name: this.childtodo.template_name + "(" + this.i + ")",
        subject: this.childtodo.subject,
        body_text: this.childtodo.body_text,
        banner_name: this.childtodo.banner_name,
        image_name: this.childtodo.image_name,
        banner_key: this.childtodo.banner_key,
        image_key: this.childtodo.image_key,
        is_rec: this.childtodo.is_rec,
      };
      await allapi2
        .templatePost(params, body)
        .then((res) => {
          console.log(res);
          const alertModal4 = new bootstrap.Modal(
            document.getElementById("alertModal4"),
            {
              keyboard: false,
            }
          );
          alertModal4.show();
          // $("#alertModal4").modal("show");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
      await this.get();
      await this.selectValue(this.todos[0]);
      this.$refs.RefChilde.changePage();
    },
    edittodo: async function (todo) {
      await this.selectValue(todo);
      if (this.emailChannel) {
        console.log("todo", todo);
        this.name = todo.name;
        this.key = todo.key;
        this.from = "scriptTemplate";
        if (window.location.origin === "http://localhost:8080") {
          this.url8081 = `http://localhost:8081/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-dev.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-dev2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else {
          this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${this.key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        }

        this.showUnlayer();
      } else {
        this.editScriptSms = false;
        console.log("todo", todo);
        this.getSmsTemplateById(todo);
        this.smsTitle = todo.name;
        this.key = todo.key;
        this.smsModal = new bootstrap.Modal(
          document.getElementById("smsModal"),
          {
            keyboard: false,
          }
        );
        this.smsModal.show();
        const backdrop = document.getElementsByClassName("modal-backdrop");
        backdrop[backdrop.length - 1].style['z-index']=3500
      }
    },
    edittodoinscript: async function () {
      let unlayer_key =''
      if (this.emailChannel) {
        this.url8081 = "";
        console.log(`準備去unlayer那頁編輯劇本模板,現在this.key是${this.key}`);
        await this.getScriptCboxBykey(this.key);
        if (this.noCbox!=true) {
          this.from = "triggerScriptSchedule_Cbox";
        unlayer_key = this.key + this.random_Cbox_key;
        } else {
          this.from = "triggerScriptSchedule";
        unlayer_key =  this.key + this.random_Cbox_key;
        }
        console.log(
          `準備去unlayer那頁編輯劇本模板,現在this.from是${this.from}`
        );
        if (window.location.origin === "http://localhost:8080") {
          this.url8081 = `http://localhost:8081/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-test.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-test2.retailing-data.net/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else if (
          window.location.origin === "https://firstcdp-dev.retailing-data.net"
        ) {
          this.url8081 = `https://firstcdp-dev2.retailing-data.net/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        } else {
          this.url8081 = `https://firstcdp2.retailing-data.net/?name=${this.name}&key=${unlayer_key}&token=${this.token}&group=${this.group}&from=${this.from}`;
        }

        this.showUnlayer();
      } else {
        this.editScriptSms = true;
        console.log("this.key", this.key);
        await this.getScriptCboxBykey(this.key);
        if (this.noCbox!=true) {
          console.log("Cbox");
        } else {
          await this.getTemplateByScriptkey(this.key);
        }
        this.smsTitle = this.newTodoTitle;
        this.smsModal = new bootstrap.Modal(
          document.getElementById("smsModal"),
          {
            keyboard: false,
          }
        );
        this.smsModal.show();
        const backdrop = document.getElementsByClassName("modal-backdrop");
        backdrop[backdrop.length - 1].style['z-index']=3500
      }
    },
    updateSmsScriptTemp: async function () {
      await this.findAllURl();
      console.log("?????");
      if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;

        // await this.postScriptTemplate2();
        console.log("this.vice_id", this.vice_id);
        console.log("this.key", this.key);

        if(this.noCbox!=true){
          await this.patchScriptCbox();
        }else{
          await this.postScriptCbox();
        }

        this.editinput = false;
        this.editList = true;

        this.loading = false;
        this.smsModal.hide();
      }
    },

    postScriptCbox: async function (design) {
      console.log(design);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key+this.random_Cbox_key,
        json: this.newSubsec,
        html: this.newHtml,
        node: new Date().getTime() + "abc",
      };
      await allapi2
        .scriptTempCachePost(params, body)
        .then((res) => {
          console.log("只在更改SMS時 根據劇本B箱子建C箱子", res);
          this.newTodoTitle = this.smsTitle;
          this.confirmUpdateTemplateTab();
          this.editList = true;
        })
        .catch((err) => {
          this.patchScriptCbox();
          console.log("連線有誤", err);
          this.loading = false;
        });
    },
    patchScriptCbox: async function (design) {
      console.log(design);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key+this.random_Cbox_key,
        json: this.newSubsec,
        html: this.newHtml,
        node: new Date().getTime() + "abc",
      };
      await allapi2
        .scriptTempCachePatch(params, body)
        .then((res) => {
          console.log("只在更改SMS時 根據劇本C箱子改C箱子", res);
          this.newTodoTitle = this.smsTitle;
          this.confirmUpdateTemplateTab();
          this.editList = true;
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.loading = false;
        });
    },
    deleteScriptCbox: async function (design) {
      console.log(design);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key+this.random_Cbox_key,
      };
      await allapi2
        .scriptTempCacheDelete(params, body)
        .then((res) => {
          console.log("刪除C箱子", res);
          this.editList = true;
        })
        .catch((err) => {
          this.patchScriptCbox();
          console.log("連線有誤", err);
          this.loading = false;
        });
    },

    getShortUrl: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");

      let temp_id = null;

      if (this.selectedID != "" && this.editScriptSms === false) {
        temp_id = this.selectedID;
      } else if (this.editScriptSms) {
        temp_id = this.vice_id;
      } else if (this.selectedID === "" && this.editScriptSms === false) {
        temp_id = this.random_id;
      }

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        template_id: temp_id+this.version,
      };
      await allapi2
        .triggerScriptShortenUrlPost(params, body)
        .then((res) => {
          console.log("GETUrl", res);

          this.newHtml = this.newHtml.replace(
            fullurl,
            res.data.url + "?i={id}"
          );

          console.log("this.newHtml", this.newHtml);
        })
        .catch((err) => {
          console.log("連線有誤", err);
          alert("短網址生成失敗");
          this.urlok = false;
          this.$refs.textInput.focus();
          this.textError = true;
          this.textErrMsg = "短網址生成失敗。";
          this.loading = false;
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          console.log(this.shortUrlArr);
        });
    },
    getAllShortHttp2: async function () {
      this.urlok = true;
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrl(this.fullurlArr[index]);
      }
    },
    findAllURl: async function () {
      this.loading_copy=true
      this.newHtml = this.newSubsec;
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newSubsec);
      console.log(this.fullurlArr);
      if (this.fullurlArr != null) {
        await this.getAllShortHttp2();
      } else {
        this.textError = false;
        this.urlok = true;
      }
      this.loading_copy=false
    },
    expshow: async function (boolean) {
      if (boolean) {
        await this.getScriptCboxBykey(this.key);
        if (this.noCbox!=true) {
          console.log("Cbox");
        } else {
          await this.getTemplateByScriptkey(this.key);
        }
        this.newHtml = this.newHtml.replace(/{id}/g, "uid000");
        this.resSubsec = this.newHtml
          .replace(/{lastname}/g, "王")
          .replace(/{firstname}/g, "◯◯")
          .replace(/{name}/g, "王◯◯");
        this.highlightUrl();
      } else {
        await this.findAllURl();
        this.newHtml = this.newHtml.replace(/{id}/g, "uid000");
        this.resSubsec = this.newHtml
          .replace(/{lastname}/g, "王")
          .replace(/{firstname}/g, "◯◯")
          .replace(/{name}/g, "王◯◯");
        this.highlightUrl();
      }
      this.countText = this.newHtml
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      console.log("this.countText", this.countText);
      const exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    highlightUrl() {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.newHtml);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index] +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
    },

    selectValue(todo) {
      console.log(todo);
      this.childtodo = todo;
      this.selectedID = todo.key;
    },

    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .templatev2TempInfoGet(params)
        .then((res) => {
          console.log("GET", res);
          this.emailtodos = res.data;
          this.emailtodos.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });

          this.searchTodos = res.data;
          this.todos = this.emailtodos;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
    },
    getSms: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .smsTemplateSmsTempInfoGet(params)
        .then((res) => {
          console.log("GET", res);
          this.loading = false;
          this.smstodos = res.data;
          this.smstodos.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });

          this.searchTodos = res.data;
          this.todos = this.smstodos;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
    },
    addNewSms: async function () {
      await this.findAllURl();

      if (this.smstodos.findIndex((d) => d.name === this.smsTitle) != "-1") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "您要使用的模板名稱已有其他模板使用";
      } else if (this.smsTitle === null || this.smsTitle === "") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "必須輸入標題";
      } else if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;
        // 上傳圖片API

        await this.postSms();
        this.smsModal.hide();
        this.$refs.RefChilde.changePage();
      }
    },
    updateNewSms: async function () {
      await this.findAllURl();
      console.log("?????");
      if (
        this.todos.findIndex(
          (d) =>
            d.name === this.smsTitle && this.smsTitle != this.childtodo.name
        ) != "-1"
      ) {
        this.$refs.titleInput2.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "您要使用的模板名稱已有其他模板使用";
      } else if (this.smsTitle === null || this.smsTitle === "") {
        this.$refs.titleInput2.focus();
        this.titleError2 = true;
        this.titleErrMsg2 = "必須輸入標題";
      } else if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else {
        this.loading = true;

        await this.patchSms();
        this.editinput = false;

        this.loading = false;
        this.smsModal.hide();
        this.$refs.RefChilde.changePage();
      }
    },
    patchSms: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.key,
        name: this.smsTitle,
        temp: { text: this.newSubsec, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTempInfoPatch(params, body)
        .then((res) => {
          console.log(res);
          this.getSms();
          this.alertModal3 = new bootstrap.Modal(
            document.getElementById("alertModal3"),
            {
              keyboard: false,
            }
          );
          this.alertModal3.show();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
    },
    postSms: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.random_id,
        name: this.smsTitle,
        temp: { text: this.newSubsec, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsTempInfoPost(params, body)
        .then((res) => {
          console.log(res);
          this.random_id = "T_" + this.randomString(8);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
          this.loading = false;
        });
      await this.getSms();
      await this.selectValue(this.todos[0]);
    },

    // 以下FOR 拖曳功能和樹狀圖

    allowDrop(e) {
      e.preventDefault();
    },
    //开始拖动
    dragStart(e) {
      let tar = e.target.innerText;
      console.log(e);
      console.log(tar);
      e.dataTransfer.setData("Text", tar);
    },

    //放置
    dropTrigger(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      console.log(e);
      if (dragText === "註冊") {
        this.currentTrigger = dragText;
        if (this.time_trigger != "") {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        }

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.event = dragText;
          } else if (this.smsList.event != undefined) {
            this.smsList.event = dragText;
          }
        }
        this.triggerCron = {
          picked: "一次性",
        };

        this.updateTriggerEvent();
      } else if (dragText === "購物車未結") {
        this.currentTrigger = dragText;
        if (this.time_trigger !== "") {
          this.time_trigger = 2;
          this.time_trigger_state = "天內";
        }

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.event = dragText;
          } else if (this.smsList.event != undefined) {
            this.smsList.event = dragText;
          }
        }
        this.triggerCron = {
          picked: "一次性",
        };

        this.updateTriggerEvent();
      } else if (dragText === "購買後促銷") {
        this.currentTrigger = dragText;
        if (this.time_trigger != "") {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        }
        console.log(this.emailList);

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.event = dragText;
            this.emailList.tag = "-";
            this.emailList.value = "-";
          } else if (this.smsList.event != undefined) {
            this.smsList.event = dragText;
            this.smsList.tag = "-";
            this.smsList.value = "-";
          }
        }
        this.triggerCron = {
          picked: "一次性",
        };

        this.updateTriggerEvent();
      } else if (dragText === "訂單確認") {
        this.currentTrigger = dragText;
        if (this.time_trigger != "") {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        }

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.event = dragText;
          } else if (this.smsList.event != undefined) {
            this.smsList.event = dragText;
          }
        }
        this.triggerCron = {
          picked: "一次性",
        };

        this.updateTriggerEvent();
      } else if (dragText === "定期投放") {
        this.currentTrigger = dragText;
        if (this.time_trigger != "") {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        }

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.event = dragText;
          } else if (this.smsList.event != undefined) {
            this.smsList.event = dragText;
          }
        }

        this.triggerCron = {
          picked: "一次性",
        };
        if (this.currentTrigger === "購買後促銷") {
          if (this.tagValueSelected != "") {
            this.triggerBuy = {
              tag: this.tagSelected,
              value: this.tagValueSelected,
            };
          } else {
            this.triggerBuy = {
              tag: "",
              value: "",
            };
          }
        }

        this.updateTriggerEvent();
      }
      this.cycleTimeinCycle = "";
      this.ader_triggerCron = "";
      console.log("this.currentTrigger", this.currentTrigger);
      console.log("this.yes_template", this.yes_template);
    },
    dropTriggerTime(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      console.log(e);
      console.log("this.tabState", this.tabState);
      if (
        dragText != "註冊" &&
        dragText != "購物車未結" &&
        dragText != "購買後促銷" &&
        dragText != "訂單確認" &&
        dragText != "定期投放"
      ) {
        if (
          this.currentTrigger != "購物車未結" &&
          this.currentTrigger != "定期投放" &&
          dragText != "時間後"
        ) {
          this.showToast();
          this.errorDrop = "時間後";
        } else if (
          this.currentTrigger === "購物車未結" &&
          dragText != "時間內"
        ) {
          this.showToast();
          this.errorDrop = "時間內";
        } else if (
          this.currentTrigger === "定期投放" &&
          dragText != "定期時間"
        ) {
          this.showToast();
          this.errorDrop = "定期時間";
        }
      }

      if (
        dragText === "時間後" &&
        this.currentTrigger != "購物車未結" &&
        this.currentTrigger != "定期投放"
      ) {
        this.time_trigger = 0;
        this.time_trigger_state = "立即";
        this.cycleTime_trigger = "一次性";
        this.triggerCron = {
          cron: "",
          time_every: 0,
          picked: "一次性",
          endCount2: -1,
        };

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.time_after = 0;
            this.emailList.cron = "";
          } else if (this.smsList.event != undefined) {
            this.smsList.time_after = 0;
            this.smsList.cron = "";
          }
        }

        this.updateTriggerTime();
        this.cycleTimeinCycle = "";
        this.ader_triggerCron = "";
      } else if (
        dragText === "時間內" &&
        this.currentTrigger === "購物車未結"
      ) {
        this.time_trigger = 2;
        this.time_trigger_state = "天內";
        this.cycleTime_trigger = "一次性";
        this.triggerCron = {
          cron: "",
          time_every: 0,
          picked: "一次性",
          endCount2: -1,
        };

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.time_after = 48;
            this.emailList.cron = "";
          } else if (this.smsList.event != undefined) {
            this.smsList.time_after = 48;
            this.smsList.cron = "";
          }
        }

        this.updateTriggerTime();
        this.cycleTimeinCycle = "";
        this.ader_triggerCron = "";
      } else if (
        dragText === "定期時間" &&
        this.currentTrigger === "定期投放"
      ) {
        this.time_trigger = 0;
        this.time_trigger_state = "立即";
        this.triggerCron = {
          happenTime: this.happenTime,
          happenMonth_moreoption: this.happenMonth_moreoption,
          happenMonth: this.happenMonth_Selected,
          daytime: this.daytime,
          endCount2: 0,
        };
        this.triggerCron = {
          cron: "",
          time_every: 0,
          picked: "一次性",
          endCount2: -1,
        };

        console.log(this.emailList);

        if (this.currentTrigger != null) {
          if (this.emailList.event != undefined) {
            this.emailList.time_after = 0;
            this.emailList.cron = "";
          } else if (this.smsList.event != undefined) {
            this.smsList.time_after = 0;
            this.smsList.cron = "";
          }
        }

        this.updateTriggerTime();
        this.cycleTimeinCycle = "";
        this.ader_triggerCron = "";
      }
      // this.dropTrigger(e);

      console.log(this.time_trigger);
      console.log("this.currentTrigger", this.currentTrigger);
      console.log("this.yes_template", this.yes_template);
    },
    dropEvent(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      console.log(e);
      if (
        dragText === "開啟信件" ||
        dragText === "點擊連結" ||
        dragText === "轉換"
      ) {
        if (this.currentEvent != null) {
          this.testList2.map((d) => {
            if (d.vice_ruleID.length === 13) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEvent = dragText;
        this.time_interval = 1;
        this.time_state = "天內";
        this.updateEventTimeInterval();
      } else {
        this.showToast();
        this.errorDrop = "開啟信件、點擊連結或轉換";
      }
      console.log(this.testList2);
      console.log(e);
    },
    dropTemplateAtEmail(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.template_email != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0";
            this.template_email = dragText + " copy";
            this.email_subject = "";
            await this.getTemplateById(d);
            this.postScriptTemplate();
            this.updateEmailTemplate(e, dragText);
            if (this.time_trigger_state === "小時後") {
              this.emailList = {
                action: "傳送Email",
                event: this.currentTrigger,
                ruleID: this.eventScheduleIdFromSche,
                time_after: this.time_trigger,
                is_yes: null,
                template_name: this.template_email,
                subject: "",
                time_interval: null,
                vice_ruleID:
                  "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
                cron: "",
                time_every: "",
                endcount: "",
                daytime: "",
                time_setting: this.triggerCron,
                time_start: this.triggerCron.ader_cycleDayStart,
              };
            } else {
              this.emailList = {
                action: "傳送Email",
                event: this.currentTrigger,
                ruleID: this.eventScheduleIdFromSche,
                time_after: this.time_trigger * 24,
                is_yes: null,
                template_name: this.template_email,
                subject: "",
                time_interval: null,
                vice_ruleID:
                  "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
                cron: "",
                time_every: "",
                endcount: "",
                daytime: "",
                time_setting: this.triggerCron,
                time_start: this.triggerCron.ader_cycleDayStart,
              };
            }
            console.log("this.triggerCron", this.triggerCron);
            if (
              this.currentTrigger != "定期投放" &&
              this.triggerCron.picked === "週期性"
            ) {
              this.emailList.cron = "trigger";
              this.emailList.time_every = this.triggerCron.time_every;
              this.emailList.daytime = this.triggerCron.daytime;
              this.emailList.endcount = this.triggerCron.endCount2;
              this.emailList.time_setting = this.triggerCron;
              this.emailList.time_start = "";
            } else if (
              this.currentTrigger != "定期投放" &&
              this.triggerCron.picked === "一次性"
            ) {
              this.emailList.cron = "";
              this.emailList.time_every = 0;
              this.emailList.daytime = "";
              this.emailList.endcount = -1;
              this.emailList.time_setting = this.triggerCron;
              this.emailList.time_start = "";
            } else {
              this.emailList.cron = this.triggerCron.cron;
              this.emailList.time_every = this.triggerCron.time_every;
              this.emailList.daytime = "";
              this.emailList.endcount = -1;
              this.emailList.time_setting = this.triggerCron;
              this.emailList.time_start = this.triggerCron.ader_cycleDayStart;
            }
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      setTimeout(() => {
        console.log("this.emailList", this.emailList);
      }, 5000);
      console.log("this.emailList", this.emailList);
    },
    dropTemplateAtSms(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.smstodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === false) {
          if (this.template_sms != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0";
            this.template_sms = dragText + " copy";
            this.sms_subject = "";
            this.editScriptSms = true;

            await this.getSmsTemplateById(d);
            await this.findAllURl();

           await this.postScriptTemplate();

            this.updateSmsTemplate(e, dragText);
            if (this.time_trigger_state === "小時後") {
              this.smsList = {
                action: "傳送SMS",
                event: this.currentTrigger,
                ruleID: this.eventScheduleIdFromSche,
                time_after: this.time_trigger,
                is_yes: null,
                template_name: this.template_sms,
                subject: null,
                time_interval: null,
                vice_ruleID:
                  "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
                cron: "",
                time_every: "",
                endcount: -1,
                daytime: "",
                time_setting: this.triggerCron,
                time_start: this.triggerCron.ader_cycleDayStart,
              };
            } else {
              this.smsList = {
                action: "傳送SMS",
                event: this.currentTrigger,
                ruleID: this.eventScheduleIdFromSche,
                time_after: this.time_trigger * 24,
                is_yes: null,
                template_name: this.template_sms,
                subject: "",
                time_interval: null,
                vice_ruleID:
                  "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
                cron: "",
                time_every: "",
                endcount: -1,
                daytime: "",
                time_setting: this.triggerCron,
                time_start: this.triggerCron.ader_cycleDayStart,
              };
            }
            if (
              this.currentTrigger != "定期投放" &&
              this.triggerCron.picked === "週期性"
            ) {
              this.smsList.cron = "trigger";
              this.smsList.time_every = this.triggerCron.time_every;
              this.smsList.daytime = this.triggerCron.daytime;
              this.smsList.endcount = this.triggerCron.endCount2;
              this.smsList.time_setting = this.triggerCron;
              this.smsList.time_start = "";
            } else if (
              this.currentTrigger != "定期投放" &&
              this.triggerCron.picked === "一次性"
            ) {
              this.smsList.cron = "";
              this.smsList.time_every = 0;
              this.smsList.daytime = "";
              this.smsList.endcount = -1;
              this.smsList.time_setting = this.triggerCron;
              this.smsList.time_start = "";
            } else {
              this.smsList.cron = this.triggerCron.cron;
              this.smsList.time_every = this.triggerCron.time_every;
              this.smsList.daytime = "";
              this.smsList.endcount = -1;
              this.smsList.time_setting = this.triggerCron;
              this.smsList.time_start = this.triggerCron.ader_cycleDayStart;
            }
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === true || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "SMS模板";
      }
      setTimeout(() => {
        console.log("this.smsList", this.smsList);
      }, 5000);
      console.log("this.smsList", this.smsList);
    },
    updateEmailTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0";
      this.name = this.template_email;
      this.newTodoTitle = this.template_email;
      this.newSub = this.email_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "emailtemplate";
    },
    updateSmsTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0";
      this.name = this.template_sms;
      this.newTodoTitle = this.template_sms;
      this.newSub = this.sms_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "smstemplate";
    },

    // 第1層事件
    dropTimeAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      const have_1 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1"
        );
      });
      this.yes_triggerCron = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_1 === -1) {
        if (dragText === "時間後") {
          this.yes_time_after = 7;
          let timeinterval = 1;
          if (this.time_state === "天內") {
            timeinterval = this.time_interval * 24;
          } else {
            timeinterval = this.time_interval;
          }
          this.testList2.push({
            event: this.currentEvent,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
            vice_ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.yes_triggerCron,
            time_start: "",
          });

          this.updateYesTime();
        }
      } else {
        if (dragText === "時間後") {
          this.yes_time_after = 7;
          this.testList2[have_1].time_after = 168;
          this.testList2[have_1].cron = "";
          this.testList2[have_1].time_every = 0;
          this.testList2[have_1].daytime = "";
          this.testList2[have_1].endcount = -1;
          this.testList2[have_1].time_setting = this.yes_triggerCron;
          this.testList2[have_1].time_start = "";
        }
      }

      this.ader_yes_triggerCron = "";
      this.cycleTime_yes = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log("have_1", have_1);
      console.log(this.testList2);
    },
    dropTimeAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      const have_2 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2"
        );
      });

      this.no_triggerCron = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_2 === -1) {
        if (dragText === "時間後") {
          this.no_time_after = 7;
          let timeinterval = 1;
          if (this.time_state === "天內") {
            timeinterval = this.time_interval * 24;
          } else {
            timeinterval = this.time_interval;
          }
          this.testList2.push({
            event: this.currentEvent,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
            vice_ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.no_triggerCron,
            time_start: "",
          });
          this.updateNoTime();
        }
      } else {
        if (dragText === "時間後") {
          this.no_time_after = 7;
          this.testList2[have_2].time_after = 168;
          this.testList2[have_2].cron = "";
          this.testList2[have_2].time_every = 0;
          this.testList2[have_2].daytime = "";
          this.testList2[have_2].endcount = -1;
          this.testList2[have_2].time_setting = this.no_triggerCron;
          this.testList2[have_2].time_start = "";
        }
      }

      this.ader_no_triggerCron = "";
      this.cycleTime_no = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList2);
    },
    dropActionAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email") {
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.yes_action = dragText;
        this.testList2[yesindex].action = dragText;
        this.updateYesAction();
      }
      console.log(this.testList2);
      if (dragText === "時間後") {
        this.dropTimeAtYes(e);
      }
      if (
        dragText != "傳送Email" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropActionAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email") {
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.no_action = dragText;
        this.testList2[noindex].action = dragText;
        this.updateNoAction();
      }
      console.log(this.testList2);
      if (dragText === "時間後") {
        this.dropTimeAtNo(e);
      }
      if (
        dragText != "傳送Email" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropTemplateAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.yes_template != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            const yesindex = this.testList2.findIndex((d) => {
              return d.vice_ruleID.length === 13 && d.is_yes === 1;
            });
            // const template_id = d.key;
            // this.testList2[yesindex].templateA_id = template_id;
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1";

            this.yes_template = dragText + " copy";
            this.testList2[yesindex].template_name = dragText + " copy";
            this.yes_subject = "";
            this.testList2[yesindex].subject = "";
            await this.getTemplateById(d);
            this.postScriptTemplate();

            this.updateYesTemplate(e, dragText);
            this.deleteScriptCbox();
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtYes(e);
      }
      console.log(this.testList2);
    },
    dropTemplateAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.no_template != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            const noindex = this.testList2.findIndex((d) => {
              return d.vice_ruleID.length === 13 && d.is_yes === 0;
            });
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
            // const template_id = d.key;
            // this.testList2[noindex].template_id = template_id;
            this.no_template = dragText + " copy";
            this.testList2[noindex].template_name = dragText + " copy";
            this.no_subject = "";
            this.testList2[noindex].subject = "";
            await this.getTemplateById(d);

            this.postScriptTemplate();

            this.updateNoTemplate(e, dragText);
            this.deleteScriptCbox();
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtNo(e);
      }
      console.log(this.testList2);
    },

    // 第2層事件
    dropEventAtYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (
        dragText === "開啟信件" ||
        dragText === "點擊連結" ||
        dragText === "轉換"
      ) {
        if (this.currentEventYes != null) {
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_1_1")) {
              d.event = dragText;
              d.time_interval = 24;
            }
            if (d.vice_ruleID.includes("_1_2")) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEventYes = dragText;
        this.yes_time_interval = 1;
        this.yes_time_state = "天內";
        this.fat = true;
        this.updateYesEventTimeInterval();
      } else {
        if (this.tabState != "template") {
          this.showToast();
          this.errorDrop = "開啟信件、點擊連結或轉換";
        }
      }
      this.emailtodos.map((d) => {
        if (d.name === dragText) {
          this.dropTemplateAtYes(e);
        }
      });
    },
    dropEventAtNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (
        dragText === "開啟信件" ||
        dragText === "點擊連結" ||
        dragText === "轉換"
      ) {
        if (this.currentEventNo != null) {
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_2_1")) {
              d.event = dragText;
              d.time_interval = 24;
            }
            if (d.vice_ruleID.includes("_2_2")) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEventNo = dragText;
        this.no_time_interval = 1;
        this.no_time_state = "天內";
        this.fat = true;
        this.updateNoEventTimeInterval();
      } else {
        if (this.tabState != "template") {
          this.showToast();
          this.errorDrop = "開啟信件、點擊連結或轉換";
        }
      }
      this.emailtodos.map((d) => {
        if (d.name === dragText) {
          this.dropTemplateAtNo(e);
        }
      });
    },

    // 第2層事件YES
    dropTimeAtYesYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      const have_1_1 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1"
        );
      });
      this.yesyes_triggerCron = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_1_1 === -1) {
        if (dragText === "時間後") {
          this.yesyes_time_after = 7;
          let timeinterval = 1;
          if (this.yes_time_state === "天內") {
            timeinterval = this.yes_time_interval * 24;
          } else {
            timeinterval = this.yes_time_interval;
          }
          this.testList2.push({
            event: this.currentEventYes,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            vice_ruleID:
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.yesyes_triggerCron,
            time_start: "",
          });
          this.updateYesYesTime();
        }
      } else {
        if (dragText === "時間後") {
          this.yesyes_time_after = 7;
          this.testList2[have_1_1].time_after = 168;
          this.testList2[have_1_1].cron = "";
          this.testList2[have_1_1].time_every = 0;
          this.testList2[have_1_1].daytime = "";
          this.testList2[have_1_1].endcount = -1;
          this.testList2[have_1_1].time_setting = this.yesyes_triggerCron;
          this.testList2[have_1_1].time_start = "";
        }
      }

      this.ader_yesyes_triggerCron = "";
      this.cycleTime_yesyes = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList2);
    },
    dropTimeAtYesNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      const have_1_2 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2"
        );
      });
      this.yesno_triggerCron = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_1_2 === -1) {
        if (dragText === "時間後") {
          this.yesno_time_after = 7;
          let timeinterval = 1;
          if (this.yes_time_state === "天內") {
            timeinterval = this.yes_time_interval * 24;
          } else {
            timeinterval = this.yes_time_interval;
          }
          this.testList2.push({
            event: this.currentEventYes,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            vice_ruleID:
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.yesno_triggerCron,
            time_start: "",
          });
          this.updateYesNoTime();
        }
      } else {
        if (dragText === "時間後") {
          this.yesno_time_after = 7;
          this.testList2[have_1_2].time_after = 168;
          this.testList2[have_1_2].cron = "";
          this.testList2[have_1_2].time_every = 0;
          this.testList2[have_1_2].daytime = "";
          this.testList2[have_1_2].endcount = -1;
          this.testList2[have_1_2].time_setting = this.yesno_triggerCron;
          this.testList2[have_1_2].time_start = "";
        }
      }

      this.ader_yesno_triggerCron = "";
      this.cycleTime_yesno = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList2);
    },
    dropActionAtYesYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email") {
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.yesyes_action = "傳送Email";
        this.testList2[yesyesindex].action = "傳送Email";
        this.updateYesYesAction();
      }
      if (dragText === "時間後") {
        this.dropTimeAtYesYes(e);
      }
      if (
        dragText != "傳送Email" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropActionAtYesNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email") {
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.yesno_action = "傳送Email";
        this.testList2[yesnoindex].action = "傳送Email";
        this.updateYesNoAction();
      }
      if (dragText === "時間後") {
        this.dropTimeAtYesNo(e);
      }
      if (
        dragText != "傳送Email" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropTemplateAtYesYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.yesyes_template != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            const yesyesindex = this.testList2.findIndex((d) => {
              return d.vice_ruleID.includes("_1_1");
            });
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
            // const template_id = d.key;
            // this.testList2[yesyesindex].key = template_id;
            this.yesyes_template = dragText + " copy";
            this.testList2[yesyesindex].template_name = dragText + " copy";
            this.yesyes_subject = "";
            this.testList2[yesyesindex].subject = "";
            await this.getTemplateById(d);

            this.postScriptTemplate();

            this.updateYesYesTemplate(e, dragText);
            this.deleteScriptCbox();
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtYesYes(e);
      }
      console.log(this.testList2);
    },
    dropTemplateAtYesNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.yesno_template != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            const yesnoindex = this.testList2.findIndex((d) => {
              return d.vice_ruleID.includes("_1_2");
            });
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
            // const template_id = d.key;
            // this.testList2[yesnoindex].template_id = template_id;
            this.yesno_template = dragText + " copy";
            this.testList2[yesnoindex].template_name = dragText + " copy";
            this.yesno_subject = "";
            this.testList2[yesnoindex].subject = "";
            await this.getTemplateById(d);

            this.postScriptTemplate();

            this.updateYesNoTemplate(e, dragText);
            this.deleteScriptCbox();
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtYesNo(e);
      }
      console.log(this.testList2);
    },
    // 第2層事件No
    dropTimeAtNoYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      const have_2_1 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1"
        );
      });
      this.noyes_triggerCron = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_2_1 === -1) {
        if (dragText === "時間後") {
          this.noyes_time_after = 7;
          let timeinterval = 1;
          if (this.no_time_state === "天內") {
            timeinterval = this.no_time_interval * 24;
          } else {
            timeinterval = this.no_time_interval;
          }
          this.testList2.push({
            event: this.currentEventNo,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            vice_ruleID:
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.noyes_triggerCron,
            time_start: "",
          });
          this.updateNoYesTime();
        }
      } else {
        if (dragText === "時間後") {
          this.noyes_time_after = 7;
          this.testList2[have_2_1].time_after = 168;
          this.testList2[have_2_1].cron = "";
          this.testList2[have_2_1].time_every = 0;
          this.testList2[have_2_1].daytime = "";
          this.testList2[have_2_1].endcount = -1;
          this.testList2[have_2_1].time_setting = this.noyes_triggerCron;
          this.testList2[have_2_1].time_start = "";
        }
      }

      this.ader_noyes_triggerCron = "";
      this.cycleTime_noyes = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList2);
    },
    dropTimeAtNoNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      const have_2_2 = this.testList2.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2"
        );
      });
      this.nono_triggerCron = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_2_2 === -1) {
        if (dragText === "時間後") {
          this.nono_time_after = 7;
          let timeinterval = 1;
          if (this.no_time_state === "天內") {
            timeinterval = this.no_time_interval * 24;
          } else {
            timeinterval = this.no_time_interval;
          }
          this.testList2.push({
            event: this.currentEventNo,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            vice_ruleID:
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.nono_triggerCron,
            time_start: "",
          });
          this.updateNoNoTime();
        }
      } else {
        if (dragText === "時間後") {
          this.nono_time_after = 7;
          this.testList2[have_2_2].time_after = 168;
          this.testList2[have_2_2].cron = "";
          this.testList2[have_2_2].time_every = 0;
          this.testList2[have_2_2].daytime = "";
          this.testList2[have_2_2].endcount = -1;
          this.testList2[have_2_2].time_setting = this.nono_triggerCron;
          this.testList2[have_2_2].time_start = "";
        }
      }

      this.ader_nono_triggerCron = "";
      this.cycleTime_nono = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList2);
    },
    dropActionAtNoYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email") {
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.noyes_action = "傳送Email";
        this.testList2[noyesindex].action = "傳送Email";
        this.updateNoYesAction();
      }
      if (dragText === "時間後") {
        this.dropTimeAtNoYes(e);
      }
      if (
        dragText != "傳送Email" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropActionAtNoNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送Email") {
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.nono_action = "傳送Email";
        this.testList2[noyesindex].action = "傳送Email";
        this.updateNoNoAction();
      }
      if (dragText === "時間後") {
        this.dropTimeAtNoYes(e);
      }
      if (
        dragText != "傳送Email" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送Email";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropTemplateAtNoYes(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.noyes_template != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            const noyesindex = this.testList2.findIndex((d) => {
              return d.vice_ruleID.includes("_2_1");
            });
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
            // const template_id = d.template_id;
            // this.testList2[noyesindex].template_id = template_id;
            this.noyes_template = dragText + " copy";
            this.testList2[noyesindex].template_name = dragText + " copy";
            this.noyes_subject = "";
            this.testList2[noyesindex].subject = "";
            await this.getTemplateById(d);
            this.postScriptTemplate();

            this.updateNoYesTemplate(e, dragText);
            this.deleteScriptCbox();
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtNoYes(e);
      }
      console.log(this.testList2);
    },
    dropTemplateAtNoNo(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      this.emailtodos.map(async (d) => {
        if (d.name === dragText && this.emailChannel === true) {
          if (this.nono_template != "") {
            var yes = confirm("即將覆蓋此模板");
          } else {
            yes = true;
          }
          if (yes) {
            const nonoindex = this.testList2.findIndex((d) => {
              return d.vice_ruleID.includes("_2_2");
            });
            this.vice_id =
              "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
            // const template_id = d.template_id;
            // this.testList2[nonoindex].template_id = template_id;
            this.nono_template = dragText + " copy";
            this.testList2[nonoindex].template_name = dragText + " copy";
            this.nono_subject = "";
            this.testList2[nonoindex].subject = "";
            await this.getTemplateById(d);
            this.postScriptTemplate();
            this.updateNoNoTemplate(e, dragText);
            this.deleteScriptCbox();
          } else {
            console.log("你按了取消按鈕");
          }
        }
      });
      if (this.emailChannel === false || this.tabState != "template") {
        this.showToast();
        this.errorDrop = "Email模板";
      }
      if (dragText === "傳送Email" || dragText === "傳送SMS") {
        this.dropActionAtNoNo(e);
      }
      console.log(this.testList2);
    },

    // offcanvas update
    updateEventTimeInterval() {
      this.tabState = "event";
      if (
        this.currentEvent != "開啟信件" &&
        this.currentEvent != "點擊連結" &&
        this.currentEvent != "轉換"
      ) {
        console.log("this.currentEvent", this.currentEvent);
        console.log("this.currentEvent.slice", this.currentEvent.slice(-3));
        this.event_moreoption_Selected = this.currentEvent.slice(-3);
        if (this.currentEvent.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEvent;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.time_interval;
      this.time_state_Selected = this.time_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "event";
    },
    updateYesEventTimeInterval() {
      this.tabState = "event";
      if (
        this.currentEventYes != "開啟信件" &&
        this.currentEventYes != "點擊連結" &&
        this.currentEventYes != "轉換"
      ) {
        this.event_moreoption_Selected = this.currentEventYes.slice(-3);
        if (this.currentEventYes.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEventYes;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.yes_time_interval;
      this.time_state_Selected = this.yes_time_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesevent";
    },
    updateNoEventTimeInterval() {
      this.tabState = "event";
      if (
        this.currentEventNo != "開啟信件" &&
        this.currentEventNo != "點擊連結" &&
        this.currentEventNo != "轉換"
      ) {
        this.event_moreoption_Selected = this.currentEventNo.slice(-3);
        if (this.currentEventNo.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEventNo;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.no_time_interval;
      this.time_state_Selected = this.no_time_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noevent";
    },
    deleteEventTag() {
      if (this.updateWho === "event") {
        this.currentEvent = null;
        this.currentEventYes = null;
        this.currentEventNo = null;
        this.time_interval = null;
        this.yes_time_interval = null;
        this.no_time_interval = null;
        this.time_state = null;
        this.yes_time_state = null;
        this.no_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList2 = [];

        this.yes_time_after = "";
        this.yes_action = "";
        this.yes_subject = "";
        this.yes_template = "";
        this.no_time_after = "";
        this.no_action = "";
        this.no_subject = "";
        this.no_template = "";
        this.yesyes_time_after = "";
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        this.yesno_time_after = "";
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";
        this.noyes_time_after = "";
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        this.nono_time_after = "";
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
      } else if (this.updateWho === "yesevent") {
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";

        this.yesyes_time_after = "";
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        this.yesno_time_after = "";
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";

        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
      } else if (this.updateWho === "noevent") {
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.noyes_time_after = "";
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        this.nono_time_after = "";
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
      } else if (
        this.updateWho === "event2" ||
        this.updateWho === "yesevent2" ||
        this.updateWho === "noevent2"
      ) {
        this.deleteEventTag2();
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateEventTab() {
      if (this.updateWho === "event") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          console.log("here?");
          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList2.map((d) => {
              if (d.vice_ruleID.length === 13) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.time_interval;
                } else {
                  d.time_interval = this.time_interval * 24;
                }
              }
            });
            console.log("here?");
            this.currentEvent =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList2.map((d) => {
              if (d.vice_ruleID.length === 13) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.time_interval;
                } else {
                  d.time_interval = this.time_interval * 24;
                }
              }
            });
            this.currentEvent = this.eventSelected;
          }
          this.time_interval = this.time_interval_Selected;
          this.time_state = this.time_state_Selected;

          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesevent") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.yes_time_interval = this.time_interval_Selected;
          this.yes_time_state = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_1_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_1_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
            });
            this.currentEventYes =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_1_1")) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_1_2")) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.yes_time_interval;
                } else {
                  d.time_interval = this.yes_time_interval * 24;
                }
              }
            });
            this.currentEventYes = this.eventSelected;
          }
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noevent") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.no_time_interval = this.time_interval_Selected;
          this.no_time_state = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_2_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_2_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
            });
            this.currentEventNo =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList2.map((d) => {
              if (d.vice_ruleID.includes("_2_1")) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
              if (d.vice_ruleID.includes("_2_2")) {
                d.event = this.eventSelected;
                if (this.time_state === "小時內") {
                  d.time_interval = this.no_time_interval;
                } else {
                  d.time_interval = this.no_time_interval * 24;
                }
              }
            });
            this.currentEventNo = this.eventSelected;
          }

          console.log(this.testList2);
        }
      } else if (
        this.updateWho === "event2" ||
        this.updateWho === "yesevent2" ||
        this.updateWho === "noevent2"
      ) {
        this.confirmUpdateEventTab2();
      }
      this.offCanvasClick.hide();
      this.tabState = "time";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(2) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },
    event_moreoptionChange(boolean) {
      this.event_moreoption = boolean;
      this.event_moreoption_Selected = "";
      this.eventSelected = "";
      // this.time_interval_Selected = "";
      // this.time_state_Selected = "";
    },

    updateYesTime() {
      this.tabState = "time";
      if (this.yes_triggerCron.happenTime != undefined) {
        this.happenTime = this.yes_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yes_triggerCron.happenDay;
      this.happenWeek_Selected = this.yes_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.yes_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.yes_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.yes_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.yes_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.yes_triggerCron.happenMonth4;
      this.happenYear_Selected = this.yes_triggerCron.happenYear;
      this.happenYear2_Selected = this.yes_triggerCron.happenYear2;
      this.happenYear3_Selected = this.yes_triggerCron.happenYear3;
      this.happenYear4_Selected = this.yes_triggerCron.happenYear4;
      this.happenYear5_Selected = this.yes_triggerCron.happenYear5;
      this.daytime = this.yes_triggerCron.daytime2;
      this.endCount2 = this.yes_triggerCron.endCount2;
      if (this.yes_triggerCron.picked != undefined) {
        this.picked3 = this.yes_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.yes_time_after;
      this.time_after_state_Selected = this.yes_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yestime";
    },
    updateYesYesTime() {
      this.tabState = "time";
      if (this.yesyes_triggerCron.happenTime != undefined) {
        this.happenTime = this.yesyes_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yesyes_triggerCron.happenDay;
      this.happenWeek_Selected = this.yesyes_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.yesyes_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.yesyes_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.yesyes_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.yesyes_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.yesyes_triggerCron.happenMonth4;
      this.happenYear_Selected = this.yesyes_triggerCron.happenYear;
      this.happenYear2_Selected = this.yesyes_triggerCron.happenYear2;
      this.happenYear3_Selected = this.yesyes_triggerCron.happenYear3;
      this.happenYear4_Selected = this.yesyes_triggerCron.happenYear4;
      this.happenYear5_Selected = this.yesyes_triggerCron.happenYear5;
      this.daytime = this.yesyes_triggerCron.daytime2;
      this.endCount2 = this.yesyes_triggerCron.endCount2;
      if (this.yesyes_triggerCron.picked != undefined) {
        this.picked3 = this.yesyes_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.yesyes_time_after;
      this.time_after_state_Selected = this.yesyes_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyestime";
    },
    updateYesNoTime() {
      this.tabState = "time";
      if (this.yesno_triggerCron.happenTime != undefined) {
        this.happenTime = this.yesno_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yesno_triggerCron.happenDay;
      this.happenWeek_Selected = this.yesno_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.yesno_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.yesno_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.yesno_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.yesno_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.yesno_triggerCron.happenMonth4;
      this.happenYear_Selected = this.yesno_triggerCron.happenYear;
      this.happenYear2_Selected = this.yesno_triggerCron.happenYear2;
      this.happenYear3_Selected = this.yesno_triggerCron.happenYear3;
      this.happenYear4_Selected = this.yesno_triggerCron.happenYear4;
      this.happenYear5_Selected = this.yesno_triggerCron.happenYear5;
      this.daytime = this.yesno_triggerCron.daytime2;
      this.endCount2 = this.yesno_triggerCron.endCount2;
      if (this.yesno_triggerCron.picked != undefined) {
        this.picked3 = this.yesno_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.yesno_time_after;
      this.time_after_state_Selected = this.yesno_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnotime";
    },
    updateNoTime() {
      this.tabState = "time";
      if (this.no_triggerCron.happenTime != undefined) {
        this.happenTime = this.no_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.no_triggerCron.happenDay;
      this.happenWeek_Selected = this.no_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.no_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.no_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.no_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.no_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.no_triggerCron.happenMonth4;
      this.happenYear_Selected = this.no_triggerCron.happenYear;
      this.happenYear2_Selected = this.no_triggerCron.happenYear2;
      this.happenYear3_Selected = this.no_triggerCron.happenYear3;
      this.happenYear4_Selected = this.no_triggerCron.happenYear4;
      this.happenYear5_Selected = this.no_triggerCron.happenYear5;
      this.daytime = this.no_triggerCron.daytime2;
      this.endCount2 = this.no_triggerCron.endCount2;
      if (this.no_triggerCron.picked != undefined) {
        this.picked3 = this.no_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.no_time_after;
      this.time_after_state_Selected = this.no_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "notime";
    },
    updateNoYesTime() {
      this.tabState = "time";
      if (this.noyes_triggerCron.happenTime != undefined) {
        this.happenTime = this.noyes_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.noyes_triggerCron.happenDay;
      this.happenWeek_Selected = this.noyes_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.noyes_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.noyes_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.noyes_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.noyes_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.noyes_triggerCron.happenMonth4;
      this.happenYear_Selected = this.noyes_triggerCron.happenYear;
      this.happenYear2_Selected = this.noyes_triggerCron.happenYear2;
      this.happenYear3_Selected = this.noyes_triggerCron.happenYear3;
      this.happenYear4_Selected = this.noyes_triggerCron.happenYear4;
      this.happenYear5_Selected = this.noyes_triggerCron.happenYear5;
      this.daytime = this.noyes_triggerCron.daytime2;
      this.endCount2 = this.noyes_triggerCron.endCount2;
      if (this.noyes_triggerCron.picked != undefined) {
        this.picked3 = this.noyes_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.noyes_time_after;
      this.time_after_state_Selected = this.noyes_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyestime";
    },
    updateNoNoTime() {
      this.tabState = "time";
      if (this.nono_triggerCron.happenTime != undefined) {
        this.happenTime = this.nono_triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.nono_triggerCron.happenDay;
      this.happenWeek_Selected = this.nono_triggerCron.happenWeek;
      this.happenWeek2_Selected = this.nono_triggerCron.happenWeek2;
      this.happenMonth_Selected = this.nono_triggerCron.happenMonth;
      this.happenMonth2_Selected = this.nono_triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.nono_triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.nono_triggerCron.happenMonth4;
      this.happenYear_Selected = this.nono_triggerCron.happenYear;
      this.happenYear2_Selected = this.nono_triggerCron.happenYear2;
      this.happenYear3_Selected = this.nono_triggerCron.happenYear3;
      this.happenYear4_Selected = this.nono_triggerCron.happenYear4;
      this.happenYear5_Selected = this.nono_triggerCron.happenYear5;
      this.daytime = this.nono_triggerCron.daytime2;
      this.endCount2 = this.nono_triggerCron.endCount2;
      if (this.nono_triggerCron.picked != undefined) {
        this.picked3 = this.nono_triggerCron.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.time_after_Selected = this.nono_time_after;
      this.time_after_state_Selected = this.nono_time_after_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonotime";
    },
    deleteTimeTag() {
      if (this.updateWho === "yestime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yes_time_after = "";
        this.yes_time_after_state = "天後";
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yes_action = "";
        this.yes_subject = "";
        this.yes_template = "";
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.yesyes_action = "";
        this.yesno_action = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.yesyes_template = "";
        this.yesno_template = "";
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList2.splice(yesindex, 1);
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "notime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.no_time_after = "";
        this.no_time_after_state = "天後";
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.no_action = "";
        this.no_subject = "";
        this.no_template = "";
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.noyes_action = "";
        this.nono_action = "";
        this.noyes_subject = "";
        this.nono_subject = "";
        this.noyes_template = "";
        this.nono_template = "";
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList2.splice(noindex, 1);
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "yesyestime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "yesnotime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "noyestime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (this.updateWho === "nonotime") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log("testList2", this.testList2);
      } else if (
        this.updateWho === "yestime2" ||
        this.updateWho === "notime2" ||
        this.updateWho === "yesyestime2" ||
        this.updateWho === "yesnotime2" ||
        this.updateWho === "noyestime2" ||
        this.updateWho === "nonotime2"
      ) {
        this.deleteTimeTag2();
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateTimeTab() {
      if (this.updateWho === "yestime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("yes_", "1");
          }
          console.log("this.endCount2", this.endCount2);
          console.log("this.time_every", this.time_every);
          this.yes_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };
          console.log("this.yes_triggerCron", this.yes_triggerCron);

          this.cycleTime_yes = this.picked3;
          this.yes_time_after = this.time_after_Selected;
          this.yes_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.length === 13 && d.is_yes === 1) {
              if (this.yes_time_after_state === "小時後") {
                d.time_after = this.yes_time_after;
              } else {
                d.time_after = this.yes_time_after * 24;
              }
              if (this.yes_triggerCron.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.yes_triggerCron.time_every;
                d.daytime = this.yes_triggerCron.daytime;
                d.endcount = this.yes_triggerCron.endCount2;
                d.time_setting = this.yes_triggerCron;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.yes_triggerCron;
              }
            }
          });
          console.log(this.testList2);
          console.log("yes_time_after", this.yes_time_after);
        }
      } else if (this.updateWho === "notime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("no_", "1");
          }
          this.no_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_no = this.picked3;
          this.no_time_after = this.time_after_Selected;
          this.no_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.length === 13 && d.is_yes === 0) {
              if (this.no_time_after_state === "小時後") {
                d.time_after = this.no_time_after;
              } else {
                d.time_after = this.no_time_after * 24;
              }
              if (this.no_triggerCron.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.no_triggerCron.time_every;
                d.daytime = this.no_triggerCron.daytime;
                d.endcount = this.no_triggerCron.endCount2;
                d.time_setting = this.no_triggerCron;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.no_triggerCron;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesyestime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("yesyes_", "1");
          }
          this.yesyes_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_yesyes = this.picked3;
          this.yesyes_time_after = this.time_after_Selected;
          this.yesyes_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_1_1")) {
              if (this.yesyes_time_after_state === "小時後") {
                d.time_after = this.yesyes_time_after;
              } else {
                d.time_after = this.yesyes_time_after * 24;
              }
              if (this.yesyes_triggerCron.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.yesyes_triggerCron.time_every;
                d.daytime = this.yesyes_triggerCron.daytime;
                d.endcount = this.yesyes_triggerCron.endCount2;
                d.time_setting = this.yesyes_triggerCron;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.yesyes_triggerCron;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesnotime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("yesno_", "1");
          }
          this.yesno_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_yesno = this.picked3;
          this.yesno_time_after = this.time_after_Selected;
          this.yesno_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_1_2")) {
              if (this.yesno_time_after_state === "小時後") {
                d.time_after = this.yesno_time_after;
              } else {
                d.time_after = this.yesno_time_after * 24;
              }
              if (this.yesno_triggerCron.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.yesno_triggerCron.time_every;
                d.daytime = this.yesno_triggerCron.daytime;
                d.endcount = this.yesno_triggerCron.endCount2;
                d.time_setting = this.yesno_triggerCron;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.yesno_triggerCron;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noyestime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("noyes_", "1");
          }
          this.noyes_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_noyes = this.picked3;
          this.noyes_time_after = this.time_after_Selected;
          this.noyes_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_2_1")) {
              if (this.noyes_time_after_state === "小時後") {
                d.time_after = this.noyes_time_after;
              } else {
                d.time_after = this.noyes_time_after * 24;
              }
              if (this.noyes_triggerCron.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.noyes_triggerCron.time_every;
                d.daytime = this.noyes_triggerCron.daytime;
                d.endcount = this.noyes_triggerCron.endCount2;
                d.time_setting = this.noyes_triggerCron;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.noyes_triggerCron;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (this.updateWho === "nonotime") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("nono_", "1");
          }
          this.nono_triggerCron = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_nono = this.picked3;
          this.nono_time_after = this.time_after_Selected;
          this.nono_time_after_state = this.time_after_state_Selected;
          this.testList2.map((d) => {
            if (d.vice_ruleID.includes("_2_2")) {
              if (this.nono_time_after_state === "小時後") {
                d.time_after = this.nono_time_after;
              } else {
                d.time_after = this.nono_time_after * 24;
              }
              if (this.nono_triggerCron.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.nono_triggerCron.time_every;
                d.daytime = this.nono_triggerCron.daytime;
                d.endcount = this.nono_triggerCron.endCount2;
                d.time_setting = this.nono_triggerCron;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.nono_triggerCron;
              }
            }
          });
          console.log(this.testList2);
        }
      } else if (
        this.updateWho === "yestime2" ||
        this.updateWho === "notime2" ||
        this.updateWho === "yesyestime2" ||
        this.updateWho === "yesnotime2" ||
        this.updateWho === "noyestime2" ||
        this.updateWho === "nonotime2"
      ) {
        this.confirmUpdateTimeTab2();
      }
      this.offCanvasClick.hide();
      this.tabState = "action";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(3) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },

    updateYesAction() {
      this.tabState = "action";
      const yesindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.length === 13 && d.is_yes === 1;
      });
      this.action_Selected = this.testList2[yesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesaction";
    },
    updateYesYesAction() {
      this.tabState = "action";
      const yesyesindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_1_1");
      });
      this.action_Selected = this.testList2[yesyesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyesaction";
    },
    updateYesNoAction() {
      this.tabState = "action";
      const yesnoindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_1_2");
      });
      this.action_Selected = this.testList2[yesnoindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnoaction";
    },
    updateNoAction() {
      this.tabState = "action";
      const noindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.length === 13 && d.is_yes === 0;
      });
      this.action_Selected = this.testList2[noindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noaction";
    },
    updateNoYesAction() {
      this.tabState = "action";
      const noyesindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_2_1");
      });
      this.action_Selected = this.testList2[noyesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyesaction";
    },
    updateNoNoAction() {
      this.tabState = "action";
      const nonoindex = this.testList2.findIndex((d) => {
        return d.vice_ruleID.includes("_2_2");
      });
      this.action_Selected = this.testList2[nonoindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonoaction";
    },
    deleteActionTag() {
      if (this.updateWho === "yesaction") {
        this.yes_action = "";
        this.yes_subject = "";
        this.yes_template = "";
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yesyes_action = "";
        this.yesno_action = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.yesyes_template = "";
        this.yesno_template = "";
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList2[yesindex].action = undefined;
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "noaction") {
        this.no_action = "";
        this.no_subject = "";
        this.no_template = "";
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.noyes_action = "";
        this.nono_action = "";
        this.noyes_subject = "";
        this.nono_subject = "";
        this.noyes_template = "";
        this.nono_template = "";
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList2[noindex].action = undefined;
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "yesyesaction") {
        this.yesyes_action = "";
        this.yesyes_subject = "";
        this.yesyes_template = "";
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList2[yesyesindex].action = undefined;

        console.log(this.testList2);
      } else if (this.updateWho === "yesnoaction") {
        this.yesno_action = "";
        this.yesno_subject = "";
        this.yesno_template = "";
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList2[yesnoindex].action = undefined;

        console.log(this.testList2);
      } else if (this.updateWho === "noyesaction") {
        this.noyes_action = "";
        this.noyes_subject = "";
        this.noyes_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList2[noyesindex].action = undefined;

        console.log(this.testList2);
      } else if (this.updateWho === "nonoaction") {
        this.nono_action = "";
        this.nono_subject = "";
        this.nono_template = "";
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList2[nonoindex].action = undefined;

        console.log(this.testList2);
      } else if (
        this.updateWho === "yesaction2" ||
        this.updateWho === "noaction2" ||
        this.updateWho === "yesyesaction2" ||
        this.updateWho === "yesnoaction2" ||
        this.updateWho === "noyesaction2" ||
        this.updateWho === "nonoaction2"
      ) {
        this.deleteActionTag2();
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateActionTab() {
      if (this.updateWho === "yesaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.length === 13 && d.is_yes === 1;
          });
          this.testList2[yesindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const noindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.length === 13 && d.is_yes === 0;
          });
          this.testList2[noindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesyesaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesyesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_1_1");
          });
          this.testList2[yesyesindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesnoaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesnoindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_1_2");
          });
          this.testList2[yesnoindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noyesaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const noyesindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_2_1");
          });
          this.testList2[noyesindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "nonoaction") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const nonoindex = this.testList2.findIndex((d) => {
            return d.vice_ruleID.includes("_2_2");
          });
          this.testList2[nonoindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (
        this.updateWho === "yesaction2" ||
        this.updateWho === "noaction2" ||
        this.updateWho === "yesyesaction2" ||
        this.updateWho === "yesnoaction2" ||
        this.updateWho === "noyesaction2" ||
        this.updateWho === "nonoaction2"
      ) {
        this.confirmUpdateActionTab2();
      }
      this.offCanvasClick.hide();
      this.tabState = "template";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(4) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },

    updateYesTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
      this.name = this.yes_template;
      this.newTodoTitle = this.yes_template;
      this.newSub = this.yes_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yestemplate";
    },
    updateYesYesTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
      this.name = this.yesyes_template;
      this.newTodoTitle = this.yesyes_template;
      this.newSub = this.yesyes_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyestemplate";
    },
    updateYesNoTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
      this.name = this.yesno_template;
      this.newTodoTitle = this.yesno_template;
      this.newSub = this.yesno_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnotemplate";
    },
    updateNoTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
      this.name = this.no_template;
      this.newTodoTitle = this.no_template;
      this.newSub = this.no_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "notemplate";
    },
    updateNoYesTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
      this.name = this.noyes_template;
      this.newTodoTitle = this.noyes_template;
      this.newSub = this.noyes_subject;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyestemplate";
    },
    updateNoNoTemplate(e, dragText) {
      this.tabState = "template";
      this.emailChannel = true;
      this.key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
      this.name = this.nono_template;
      this.newTodoTitle = this.nono_template;
      this.newSub = this.nono_subject;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonotemplate";
    },
    confirmUpdateTemplateTab() {
      if (this.newTodoTitleError) {
        alert("模板名稱不可以包含特殊符號字元");
        return;
      } else if (this.newTodoTitle === "") {
        alert("模板名稱不能為空");
        return;
      }
      if (this.updateWho === "emailtemplate") {
        this.emailList.template_name = this.newTodoTitle;
        this.emailList.subject = this.newSub;
        this.template_email = this.newTodoTitle;
        this.email_subject = this.newSub;
      } else if (this.updateWho === "smstemplate") {
        this.smsList.template_name = this.newTodoTitle;
        this.smsList.subject = this.newSub;
        this.template_sms = this.newTodoTitle;
        this.sms_subject = this.newSub;
        this.updateWho = "event";
      } else if (this.updateWho === "yestemplate") {
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList2[yesindex].template_name = this.newTodoTitle;
        this.testList2[yesindex].subject = this.newSub;
        this.yes_template = this.newTodoTitle;
        this.yes_subject = this.newSub;
      } else if (this.updateWho === "notemplate") {
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList2[noindex].template_name = this.newTodoTitle;
        this.testList2[noindex].subject = this.newSub;
        this.no_template = this.newTodoTitle;
        this.no_subject = this.newSub;
      } else if (this.updateWho === "yesyestemplate") {
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList2[yesyesindex].template_name = this.newTodoTitle;
        this.testList2[yesyesindex].subject = this.newSub;
        this.yesyes_template = this.newTodoTitle;
        this.yesyes_subject = this.newSub;
      } else if (this.updateWho === "yesnotemplate") {
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList2[yesnoindex].template_name = this.newTodoTitle;
        this.testList2[yesnoindex].subject = this.newSub;
        this.yesno_template = this.newTodoTitle;
        this.yesno_subject = this.newSub;
      } else if (this.updateWho === "noyestemplate") {
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList2[noyesindex].template_name = this.newTodoTitle;
        this.testList2[noyesindex].subject = this.newSub;
        this.noyes_template = this.newTodoTitle;
        this.noyes_subject = this.newSub;
      } else if (this.updateWho === "nonotemplate") {
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList2[nonoindex].template_name = this.newTodoTitle;
        this.testList2[nonoindex].subject = this.newSub;
        this.nono_template = this.newTodoTitle;
        this.nono_subject = this.newSub;
      } else if (
        this.updateWho === "yestemplate2" ||
        this.updateWho === "notemplate2" ||
        this.updateWho === "yesyestemplate2" ||
        this.updateWho === "yesnotemplate2" ||
        this.updateWho === "noyestemplate2" ||
        this.updateWho === "nonotemplate2"
      ) {
        this.confirmUpdateActionTab2();
      }

      this.tabState = "event";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(1) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();

      this.offCanvasClick.hide();
    },
    deleteTemplateTag() {
      if (this.updateWho === "emailtemplate") {
        this.template_email = "";
        this.email_subject = "";
        this.emailList = {};
        this.updateWho = "event";
        this.deleteEventTag();
      } else if (this.updateWho === "smstemplate") {
        this.template_sms = "";
        this.sms_subject = "";
        this.smsList = {};
        this.updateWho = "event2";
        this.deleteEventTag2();
      }
      if (this.updateWho === "yestemplate") {
        this.yes_subject = "";
        this.yes_template = "";
        this.currentEventYes = null;
        this.yes_time_interval = null;
        this.yes_time_state = null;
        this.yesyes_time_after = "";
        this.yesyes_time_after_state = "天後";
        this.yesno_time_after = "";
        this.yesno_time_after_state = "天後";
        this.yesyes_action = "";
        this.yesno_action = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.yesyes_template = "";
        this.yesno_template = "";
        const yesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList2[yesindex].template_name = undefined;
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList2.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList2.splice(yesnoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "notemplate") {
        this.no_subject = "";
        this.no_template = "";
        this.currentEventNo = null;
        this.no_time_interval = null;
        this.no_time_state = null;
        this.noyes_time_after = "";
        this.noyes_time_after_state = "天後";
        this.nono_time_after = "";
        this.nono_time_after_state = "天後";
        this.noyes_action = "";
        this.nono_action = "";
        this.noyes_subject = "";
        this.nono_subject = "";
        this.noyes_template = "";
        this.nono_template = "";
        const noindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList2[noindex].template_name = undefined;
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList2.splice(noyesindex, 1);
        }
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList2.splice(nonoindex, 1);
        }
        console.log(this.testList2);
      } else if (this.updateWho === "yesyestemplate") {
        this.yesyes_subject = "";
        this.yesyes_template = "";
        const yesyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList2[yesyesindex].template_name = undefined;
        console.log(this.testList2);
      } else if (this.updateWho === "yesnotemplate") {
        this.yesno_subject = "";
        this.yesno_template = "";
        const yesnoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList2[yesnoindex].template_name = undefined;
        console.log(this.testList2);
      } else if (this.updateWho === "noyestemplate") {
        this.noyes_subject = "";
        this.noyes_template = "";
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList2[noyesindex].template_name = undefined;
        console.log(this.testList2);
      } else if (this.updateWho === "nonotemplate") {
        this.nono_subject = "";
        this.nono_template = "";
        const nonoindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList2[nonoindex].template_name = undefined;
        console.log(this.testList2);
      } else {
        this.deleteTemplateTag2();
      }
      this.offCanvasClick.hide();
    },

    // FOR SMS

    dropEvent2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      console.log(e);
      if (dragText === "點擊連結" || dragText === "轉換") {
        if (this.currentEvent2 != null) {
          this.testList.map((d) => {
            if (d.vice_ruleID.length === 13) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEvent2 = dragText;
        this.time_interval2 = 1;
        this.time_state2 = "天內";
        this.updateEventTimeInterval2();
      } else {
        this.showToast();
        this.errorDrop = "點擊連結或轉換";
      }
      console.log(this.testList);
      console.log(e);
    },

    // 第1層事件
    dropTimeAtYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      const have_1 = this.testList.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1"
        );
      });
      this.yes_triggerCron2 = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_1 === -1) {
        if (dragText === "時間後") {
          this.yes_time_after2 = 7;
          let timeinterval = 1;
          if (this.no_time_state === "天內") {
            timeinterval = this.time_interval2 * 24;
          } else {
            timeinterval = this.time_interval2;
          }
          this.testList.push({
            event: this.currentEvent2,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
            vice_ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.yes_triggerCron2,
            time_start: "",
          });
          this.updateYesTime2();
        }
      } else {
        if (dragText === "時間後") {
          this.yes_time_after2 = 7;
          this.testList[have_1].time_after = 168;
          this.testList[have_1].cron = "";
          this.testList[have_1].time_every = 0;
          this.testList[have_1].daytime = "";
          this.testList[have_1].endcount = -1;
          this.testList[have_1].time_setting = this.yes_triggerCron2;
          this.testList[have_1].time_start = "";
        }
      }

      this.ader_yes_triggerCron2 = "";
      this.cycleTime_yes2 = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log("have_1", have_1);
      console.log(this.testList);
    },
    dropTimeAtNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      const have_2 = this.testList.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2"
        );
      });
      this.no_triggerCron2 = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_2 === -1) {
        if (dragText === "時間後") {
          this.no_time_after2 = 7;
          let timeinterval = 1;
          if (this.no_time_state === "天內") {
            timeinterval = this.time_interval2 * 24;
          } else {
            timeinterval = this.time_interval2;
          }
          this.testList.push({
            event: this.currentEvent2,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0",
            vice_ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.no_triggerCron2,
            time_start: "",
          });
          this.updateNoTime2();
        }
      } else {
        if (dragText === "時間後") {
          this.no_time_after2 = 7;
          this.testList[have_2].time_after = 168;
          this.testList[have_2].cron = "";
          this.testList[have_2].time_every = 0;
          this.testList[have_2].daytime = "";
          this.testList[have_2].endcount = -1;
          this.testList[have_2].time_setting = this.no_triggerCron2;
          this.testList[have_2].time_start = "";
        }
      }

      this.ader_no_triggerCron2 = "";
      this.cycleTime_no2 = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList);
    },
    dropActionAtYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送SMS") {
        const yesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.yes_action2 = dragText;
        this.testList[yesindex].action = dragText;
        this.updateYesAction2();
      }
      console.log(this.testList);
      if (dragText === "時間後") {
        this.dropTimeAtYes2(e);
      }
      if (
        dragText != "傳送SMS" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送SMS";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropActionAtNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送SMS") {
        const noindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.no_action2 = dragText;
        console.log("this.testList", this.testList);
        this.testList[noindex].action = dragText;
        this.updateNoAction2();
      }
      console.log(this.testList);
      if (
        dragText != "傳送SMS" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送SMS";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropTemplateAtYes2(e) {
      if (this.yes_template2 != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        this.allowDrop(e);
        const dragText = e.dataTransfer.getData("Text");
        this.smstodos.map(async (d) => {
          if (d.name === dragText && this.emailChannel === false) {
            const yesindex = this.testList.findIndex((d) => {
              return d.vice_ruleID.length === 13 && d.is_yes === 1;
            });
            // const template_id = d.key;
            // this.testList[yesindex].templateA_id = template_id;
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1";

            this.yes_template2 = dragText + " copy";
            this.testList[yesindex].template_name = dragText + " copy";
            this.yes_subject2 = null;
            this.testList[yesindex].subject = "";
            this.editScriptSms = true;
            await this.getSmsTemplateById(d);
            await this.findAllURl();
            this.postScriptTemplate();

            this.updateYesTemplate2(e, dragText);
            this.deleteScriptCbox();
          }
        });
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
        }
        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtYes2(e);
        }
        console.log(this.testList);
      } else {
        console.log("你按了取消按鈕");
      }
    },
    dropTemplateAtNo2(e) {
      if (this.no_template2 != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        this.allowDrop(e);
        const dragText = e.dataTransfer.getData("Text");

        this.smstodos.map(async (d) => {
          if (d.name === dragText && this.emailChannel === false) {
            const noindex = this.testList.findIndex((d) => {
              return d.vice_ruleID.length === 13 && d.is_yes === 0;
            });
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
            // const template_id = d.key;
            // this.testList[noindex].template_id = template_id;
            this.no_template2 = dragText + " copy";
            this.testList[noindex].template_name = dragText + " copy";
            this.no_subject2 = null;
            this.testList[noindex].subject = "";
            this.editScriptSms = true;
            await this.getSmsTemplateById(d);
            await this.findAllURl();
            this.postScriptTemplate();

            this.updateNoTemplate2(e, dragText);
            this.deleteScriptCbox();
          }
        });
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
        }
        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtNo2(e);
        }
        console.log(this.testList);
      } else {
        console.log("你按了取消按鈕");
      }
    },

    // 第2層事件
    dropEventAtYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "點擊連結" || dragText === "轉換") {
        if (this.currentEventYes2 != null) {
          this.testList.map((d) => {
            if (d.vice_ruleID.includes("_1_1")) {
              d.event = dragText;
              d.time_interval = 24;
            }
            if (d.vice_ruleID.includes("_1_2")) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEventYes2 = dragText;
        this.yes_time_interval2 = 1;
        this.yes_time_state2 = "天內";
        this.fat = true;
        this.updateYesEventTimeInterval2();
      } else {
        if (this.tabState != "template") {
          this.showToast();
          this.errorDrop = "點擊連結或轉換";
        }
      }
      this.smstodos.map((d) => {
        if (d.name === dragText) {
          this.dropTemplateAtYes2(e);
        }
      });
    },
    dropEventAtNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "點擊連結" || dragText === "轉換") {
        if (this.currentEventNo2 != null) {
          this.testList.map((d) => {
            if (d.vice_ruleID.includes("_2_1")) {
              d.event = dragText;
              d.time_interval = 24;
            }
            if (d.vice_ruleID.includes("_2_2")) {
              d.event = dragText;
              d.time_interval = 24;
            }
          });
        }
        this.currentEventNo2 = dragText;
        this.no_time_interval2 = 1;
        this.no_time_state2 = "天內";
        this.fat = true;
        this.updateNoEventTimeInterval2();
      } else {
        if (this.tabState != "template") {
          this.showToast();
          this.errorDrop = "點擊連結或轉換";
        }
      }
      this.smstodos.map((d) => {
        if (d.name === dragText) {
          this.dropTemplateAtNo2(e);
        }
      });
    },

    // 第2層事件YES
    dropTimeAtYesYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      const have_1_1 = this.testList.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1"
        );
      });
      this.yesyes_triggerCron2 = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_1_1 === -1) {
        if (dragText === "時間後") {
          this.yesyes_time_after2 = 7;
          let timeinterval = 1;
          if (this.yes_time_state2 === "天內") {
            timeinterval = this.yes_time_interval2 * 24;
          } else {
            timeinterval = this.yes_time_interval2;
          }
          this.testList.push({
            event: this.currentEventYes2,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            vice_ruleID:
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.yes_triggerCron2,
            time_start: "",
          });
          this.updateYesYesTime2();
        }
      } else {
        if (dragText === "時間後") {
          this.yesyes_time_after2 = 7;
          this.testList[have_1_1].time_after = 168;
          this.testList[have_1_1].cron = "";
          this.testList[have_1_1].time_every = 0;
          this.testList[have_1_1].daytime = "";
          this.testList[have_1_1].endcount = -1;
          this.testList[have_1_1].time_setting = this.yes_triggerCron2;
          this.testList[have_1_1].time_start = "";
        }
      }

      this.ader_yesyes_triggerCron2 = "";
      this.cycleTime_yesyes2 = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList);
    },
    dropTimeAtYesNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      const have_1_2 = this.testList.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2"
        );
      });
      this.yesno_triggerCron2 = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_1_2 === -1) {
        if (dragText === "時間後") {
          this.yesno_time_after2 = 7;
          let timeinterval = 1;
          if (this.yes_time_state2 === "天內") {
            timeinterval = this.yes_time_interval2 * 24;
          } else {
            timeinterval = this.yes_time_interval2;
          }
          this.testList.push({
            event: this.currentEventYes2,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1",
            vice_ruleID:
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.yesno_triggerCron2,
            time_start: "",
          });
          this.updateYesNoTime2();
        }
      } else {
        if (dragText === "時間後") {
          this.yesno_time_after2 = 7;
          this.testList[have_1_2].time_after = 168;
          this.testList[have_1_2].cron = "";
          this.testList[have_1_2].time_every = 0;
          this.testList[have_1_2].daytime = "";
          this.testList[have_1_2].endcount = -1;
          this.testList[have_1_2].time_setting = this.yesno_triggerCron2;
          this.testList[have_1_2].time_start = "";
        }
      }

      this.ader_yesno_triggerCron2 = "";
      this.cycleTime_yesno2 = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList);
    },
    dropActionAtYesYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送SMS") {
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.yesyes_action2 = "傳送SMS";
        this.testList[yesyesindex].action = "傳送SMS";
        this.updateYesYesAction2();
      }
      if (dragText === "時間後") {
        this.dropTimeAtYesYes2(e);
      }
      if (
        dragText != "傳送SMS" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送SMS";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropActionAtYesNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送SMS") {
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.yesno_action2 = "傳送SMS";
        this.testList[yesnoindex].action = "傳送SMS";
        this.updateYesNoAction2();
      }
      if (
        dragText != "傳送SMS" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送SMS";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropTemplateAtYesYes2(e) {
      if (this.yesyes_template2 != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        this.allowDrop(e);
        const dragText = e.dataTransfer.getData("Text");
        this.smstodos.map(async (d) => {
          if (d.name === dragText && this.emailChannel === false) {
            const yesyesindex = this.testList.findIndex((d) => {
              return d.vice_ruleID.includes("_1_1");
            });
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
            // const template_id = d.key;
            // this.testList[yesyesindex].key = template_id;
            this.yesyes_template2 = dragText + " copy";
            this.testList[yesyesindex].template_name = dragText + " copy";
            this.yesyes_subject2 = null;
            this.testList[yesyesindex].subject = "";
            this.editScriptSms = true;
            await this.getSmsTemplateById(d);
            await this.findAllURl();
            this.postScriptTemplate();

            this.updateYesYesTemplate2(e, dragText);
            this.deleteScriptCbox();
          }
        });
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
        }
        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtYesYes2(e);
        }
        console.log(this.testList);
      } else {
        console.log("你按了取消按鈕");
      }
    },
    dropTemplateAtYesNo2(e) {
      if (this.yesno_template2 != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        this.allowDrop(e);
        const dragText = e.dataTransfer.getData("Text");
        this.smstodos.map(async (d) => {
          if (d.name === dragText && this.emailChannel === false) {
            const yesnoindex = this.testList.findIndex((d) => {
              return d.vice_ruleID.includes("_1_2");
            });
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
            // const template_id = d.key;
            // this.testList[yesnoindex].template_id = template_id;
            this.yesno_template2 = dragText + " copy";
            this.testList[yesnoindex].template_name = dragText + " copy";
            this.yesno_subject2 = null;
            this.testList[yesnoindex].subject = "";
            this.editScriptSms = true;
            await this.getSmsTemplateById(d);
            await this.findAllURl();
            this.postScriptTemplate();

            this.updateYesNoTemplate2(e, dragText);
            this.deleteScriptCbox();
          }
        });
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
        }
        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtYesNo2(e);
        }
        console.log(this.testList);
        console.log("yeyeyye");
      } else {
        console.log("你按了取消按鈕");
      }
    },
    // 第2層事件No
    dropTimeAtNoYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");

      const have_2_1 = this.testList.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1"
        );
      });
      this.noyes_triggerCron2 = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_2_1 === -1) {
        if (dragText === "時間後") {
          this.noyes_time_after2 = 7;
          let timeinterval = 1;
          if (this.no_time_state2 === "天內") {
            timeinterval = this.no_time_interval2 * 24;
          } else {
            timeinterval = this.no_time_interval2;
          }
          this.testList.push({
            event: this.currentEventNo2,
            time_interval: timeinterval,
            is_yes: 1,
            time_after: 168,
            ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            vice_ruleID:
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.noyes_triggerCron2,
            time_start: "",
          });
          this.updateNoYesTime2();
        }
      } else {
        if (dragText === "時間後") {
          this.noyes_time_after2 = 7;
          this.testList[have_2_1].time_after = 168;
          this.testList[have_2_1].cron = "";
          this.testList[have_2_1].time_every = 0;
          this.testList[have_2_1].daytime = "";
          this.testList[have_2_1].endcount = -1;
          this.testList[have_2_1].time_setting = this.noyes_triggerCron2;
          this.testList[have_2_1].time_start = "";
        }
      }

      this.ader_noyes_triggerCron2 = "";
      this.cycleTime_noyes2 = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList);
    },
    dropTimeAtNoNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      const have_2_2 = this.testList.findIndex((d) => {
        return (
          d.vice_ruleID ===
          "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2"
        );
      });
      this.nono_triggerCron2 = {
        picked: "一次性",
        endCount2: -1,
      };
      if (have_2_2 === -1) {
        if (dragText === "時間後") {
          this.nono_time_after2 = 7;
          let timeinterval = 1;
          if (this.no_time_state2 === "天內") {
            timeinterval = this.no_time_interval2 * 24;
          } else {
            timeinterval = this.no_time_interval2;
          }
          this.testList.push({
            event: this.currentEventNo2,
            time_interval: timeinterval,
            is_yes: 0,
            time_after: 168,
            ruleID: "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2",
            vice_ruleID:
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2",
            cron: "",
            time_every: 0,
            daytime: "",
            endcount: -1,
            time_setting: this.nono_triggerCron2,
            time_start: "",
          });
          this.updateNoNoTime2();
        }
      } else {
        if (dragText === "時間後") {
          this.nono_time_after2 = 7;
          this.testList[have_2_2].time_after = 168;
          this.testList[have_2_2].cron = "";
          this.testList[have_2_2].time_every = 0;
          this.testList[have_2_2].daytime = "";
          this.testList[have_2_2].endcount = -1;
          this.testList[have_2_2].time_setting = this.nono_triggerCron2;
          this.testList[have_2_2].time_start = "";
        }
      }

      this.ader_nono_triggerCron2 = "";
      this.cycleTime_nono2 = "一次性";
      if (dragText != "時間後") {
        this.showToast();
        this.errorDrop = "時間後";
      }
      console.log(this.testList);
    },
    dropActionAtNoYes2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送SMS") {
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.noyes_action2 = "傳送SMS";
        this.testList[noyesindex].action = "傳送SMS";
        this.updateNoYesAction2();
      }
      if (dragText === "時間後") {
        this.dropTimeAtNoYes2(e);
      }
      if (
        dragText != "傳送SMS" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送SMS";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropActionAtNoNo2(e) {
      this.allowDrop(e);
      const dragText = e.dataTransfer.getData("Text");
      if (dragText === "傳送SMS") {
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.nono_action2 = "傳送SMS";
        this.testList[noyesindex].action = "傳送SMS";
        this.updateNoNoAction2();
      }
      if (dragText === "時間後") {
        this.dropTimeAtNoYes2(e);
      }
      if (
        dragText != "傳送SMS" &&
        dragText != "時間內" &&
        dragText != "定期時間"
      ) {
        this.showToast();
        this.errorDrop = "傳送SMS";
      }
      if (dragText === "時間內" || dragText === "定期時間") {
        this.showToast();
        this.errorDrop = "時間後";
      }
    },
    dropTemplateAtNoYes2(e) {
      if (this.noyes_template2 != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        this.allowDrop(e);
        const dragText = e.dataTransfer.getData("Text");
        this.todos.map(async (d) => {
          if (d.name === dragText && this.emailChannel === false) {
            const noyesindex = this.testList.findIndex((d) => {
              return d.vice_ruleID.includes("_2_1");
            });
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
            // const template_id = d.template_id;
            // this.testList[noyesindex].template_id = template_id;
            this.noyes_template2 = dragText + " copy";
            this.testList[noyesindex].template_name = dragText + " copy";
            this.noyes_subject2 = null;
            this.testList[noyesindex].subject = "";
            this.editScriptSms = true;
            await this.getSmsTemplateById(d);
            await this.findAllURl();
            this.postScriptTemplate();

            this.updateNoYesTemplate2(e, dragText);
            this.deleteScriptCbox();
          }
        });
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
        }
        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtNoYes2(e);
        }
        console.log(this.testList);
      } else {
        console.log("你按了取消按鈕");
      }
    },
    dropTemplateAtNoNo2(e) {
      if (this.nono_template2 != "") {
        var yes = confirm("即將覆蓋此模板");
      } else {
        yes = true;
      }

      if (yes) {
        this.allowDrop(e);
        const dragText = e.dataTransfer.getData("Text");
        this.todos.map(async (d) => {
          if (d.name === dragText && this.emailChannel === false) {
            const nonoindex = this.testList.findIndex((d) => {
              return d.vice_ruleID.includes("_2_2");
            });
            this.vice_id =
              "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
            // const template_id = d.template_id;
            // this.testList[nonoindex].template_id = template_id;
            this.nono_template2 = dragText + " copy";
            this.testList[nonoindex].template_name = dragText + " copy";
            this.nono_subject2 = null;
            this.testList[nonoindex].subject = "";
            this.editScriptSms = true;
            await this.getSmsTemplateById(d);
            await this.findAllURl();
            this.postScriptTemplate();
            this.updateNoNoTemplate2(e, dragText);
            this.deleteScriptCbox();
          }
        });
        if (this.emailChannel === true || this.tabState != "template") {
          this.showToast();
          this.errorDrop = "SMS模板";
        }
        if (dragText === "傳送Email" || dragText === "傳送SMS") {
          this.dropActionAtNoNo2(e);
        }
        console.log(this.testList);
      } else {
        console.log("你按了取消按鈕");
      }
    },

    // offcanvas update
    updateEventTimeInterval2() {
      this.tabState = "event";
      if (
        this.currentEvent2 != "開啟信件" &&
        this.currentEvent2 != "點擊連結" &&
        this.currentEvent2 != "轉換"
      ) {
        this.event_moreoption_Selected = this.currentEvent2.slice(-3);
        if (this.currentEvent2.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEvent2;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.time_interval2;
      this.time_state_Selected = this.time_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "event2";
    },
    updateYesEventTimeInterval2() {
      this.tabState = "event";
      if (
        this.currentEventYes2 != "開啟信件" &&
        this.currentEventYes2 != "點擊連結" &&
        this.currentEventYes2 != "轉換"
      ) {
        this.event_moreoption_Selected = this.currentEventYes2.slice(-3);
        if (this.currentEventYes2.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEventYes2;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.yes_time_interval2;
      this.time_state_Selected = this.yes_time_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesevent2";
    },
    updateNoEventTimeInterval2() {
      this.tabState = "event";
      if (
        this.currentEventNo2 != "開啟信件" &&
        this.currentEventNo2 != "點擊連結" &&
        this.currentEventNo2 != "轉換"
      ) {
        this.event_moreoption_Selected = this.currentEventNo2.slice(-3);
        if (this.currentEventNo2.includes("開啟信件")) {
          this.eventSelected = "已開啟信件";
        } else {
          this.eventSelected = "已點擊連結";
        }
        this.event_moreoption = true;
      } else {
        this.eventSelected = this.currentEventNo2;
        this.event_moreoption_Selected = "";
        this.event_moreoption = false;
      }
      this.time_interval_Selected = this.no_time_interval2;
      this.time_state_Selected = this.no_time_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noevent2";
    },
    deleteEventTag2() {
      if (this.updateWho === "event2") {
        this.currentEvent2 = null;
        this.currentEventYes2 = null;
        this.currentEventNo2 = null;
        this.time_interval2 = null;
        this.yes_time_interval2 = null;
        this.no_time_interval2 = null;
        this.time_state2 = null;
        this.yes_time_state2 = null;
        this.no_time_state2 = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList = [];

        this.yes_time_after2 = "";
        this.yes_action2 = "";
        this.yes_subject2 = null;
        this.yes_template2 = "";
        this.no_time_after2 = "";
        this.no_action2 = "";
        this.no_subject2 = null;
        this.no_template2 = "";
        this.yesyes_time_after2 = "";
        this.yesyes_action2 = "";
        this.yesyes_subject2 = null;
        this.yesyes_template2 = "";
        this.yesno_time_after2 = "";
        this.yesno_action2 = "";
        this.yesno_subject2 = null;
        this.yesno_template2 = "";
        this.noyes_time_after2 = "";
        this.noyes_action2 = "";
        this.noyes_subject2 = null;
        this.noyes_template2 = "";
        this.nono_time_after2 = "";
        this.nono_action2 = "";
        this.nono_subject2 = null;
        this.nono_template2 = "";
      } else if (this.updateWho === "yesevent2") {
        this.currentEventYes2 = null;
        this.yes_time_interval2 = null;
        this.yes_time_state2 = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";

        this.yesyes_time_after2 = "";
        this.yesyes_action2 = "";
        this.yesyes_subject2 = null;
        this.yesyes_template2 = "";
        this.yesno_time_after2 = "";
        this.yesno_action2 = "";
        this.yesno_subject2 = null;
        this.yesno_template2 = "";

        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList.splice(yesnoindex, 1);
        }
      } else if (this.updateWho === "noevent2") {
        this.currentEventNo2 = null;
        this.no_time_interval2 = null;
        this.no_time_state2 = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.noyes_time_after2 = "";
        this.noyes_action2 = "";
        this.noyes_subject2 = null;
        this.noyes_template2 = "";
        this.nono_time_after2 = "";
        this.nono_action2 = "";
        this.nono_subject2 = null;
        this.nono_template2 = "";
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList.splice(noyesindex, 1);
        }
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList.splice(nonoindex, 1);
        }
      }
    },
    confirmUpdateEventTab2() {
      if (this.updateWho === "event2") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.time_interval2 = this.time_interval_Selected;
          this.time_state2 = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList.map((d) => {
              if (d.vice_ruleID.length === 13) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.time_interval2;
                } else {
                  d.time_interval = this.time_interval2 * 24;
                }
              }
            });
            this.currentEvent2 =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList.map((d) => {
              if (d.vice_ruleID.length === 13) {
                d.event = this.eventSelected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.time_interval2;
                } else {
                  d.time_interval = this.time_interval2 * 24;
                }
              }
            });
            this.currentEvent2 = this.eventSelected;
          }

          console.log(this.testList);
        }
      } else if (this.updateWho === "yesevent2") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.yes_time_interval2 = this.time_interval_Selected;
          this.yes_time_state2 = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList.map((d) => {
              if (d.vice_ruleID.includes("_1_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.yes_time_interval2;
                } else {
                  d.time_interval = this.yes_time_interval2 * 24;
                }
              }
              if (d.vice_ruleID.includes("_1_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.yes_time_interval2;
                } else {
                  d.time_interval = this.yes_time_interval2 * 24;
                }
              }
            });
            this.currentEventYes2 =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList.map((d) => {
              if (d.vice_ruleID.includes("_1_1")) {
                d.event = this.eventSelected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.yes_time_interval2;
                } else {
                  d.time_interval = this.yes_time_interval2 * 24;
                }
              }
              if (d.vice_ruleID.includes("_1_2")) {
                d.event = this.eventSelected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.yes_time_interval2;
                } else {
                  d.time_interval = this.yes_time_interval2 * 24;
                }
              }
            });
            this.currentEventYes2 = this.eventSelected;
          }

          console.log(this.testList);
        }
      } else if (this.updateWho === "noevent2") {
        if (
          this.eventSelected === "" ||
          this.time_interval_Selected === "" ||
          this.time_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          this.no_time_interval2 = this.time_interval_Selected;
          this.no_time_state2 = this.time_state_Selected;

          if (
            this.event_moreoption_Selected != "" &&
            this.event_moreoption === true
          ) {
            this.testList.map((d) => {
              if (d.vice_ruleID.includes("_2_1")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.no_time_interval2;
                } else {
                  d.time_interval = this.no_time_interval2 * 24;
                }
              }
              if (d.vice_ruleID.includes("_2_2")) {
                d.event = this.eventSelected + this.event_moreoption_Selected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.no_time_interval2;
                } else {
                  d.time_interval = this.no_time_interval2 * 24;
                }
              }
            });
            this.currentEventNo2 =
              this.eventSelected + this.event_moreoption_Selected;
          } else if (
            this.event_moreoption_Selected === "" &&
            this.event_moreoption === true
          ) {
            alert("輸入欄不能為空");
          } else {
            this.testList.map((d) => {
              if (d.vice_ruleID.includes("_2_1")) {
                d.event = this.eventSelected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.no_time_interval2;
                } else {
                  d.time_interval = this.no_time_interval2 * 24;
                }
              }
              if (d.vice_ruleID.includes("_2_2")) {
                d.event = this.eventSelected;
                if (this.time_state2 === "小時內") {
                  d.time_interval = this.no_time_interval2;
                } else {
                  d.time_interval = this.no_time_interval2 * 24;
                }
              }
            });
            this.currentEventNo2 = this.eventSelected;
          }

          console.log(this.testList);
        }
      }
      this.offCanvasClick.hide();
      this.tabState = "time";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(2) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },

    updateYesTime2() {
      this.tabState = "time";
      if (this.yes_triggerCron2.happenTime != undefined) {
        this.happenTime = this.yes_triggerCron2.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yes_triggerCron2.happenDay;
      this.happenWeek_Selected = this.yes_triggerCron2.happenWeek;
      this.happenWeek2_Selected = this.yes_triggerCron2.happenWeek2;
      this.happenMonth_Selected = this.yes_triggerCron2.happenMonth;
      this.happenMonth2_Selected = this.yes_triggerCron2.happenMonth2;
      this.happenMonth3_Selected = this.yes_triggerCron2.happenMonth3;
      this.happenMonth4_Selected = this.yes_triggerCron2.happenMonth4;
      this.happenYear_Selected = this.yes_triggerCron2.happenYear;
      this.happenYear2_Selected = this.yes_triggerCron2.happenYear2;
      this.happenYear3_Selected = this.yes_triggerCron2.happenYear3;
      this.happenYear4_Selected = this.yes_triggerCron2.happenYear4;
      this.happenYear5_Selected = this.yes_triggerCron2.happenYear5;
      this.daytime = this.yes_triggerCron2.daytime2;
      this.endCount2 = this.yes_triggerCron2.endCount2;
      if (this.yes_triggerCron2.picked != undefined) {
        this.picked3 = this.yes_triggerCron2.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.cycleTime_yes2 = this.picked3;
      this.time_after_Selected = this.yes_time_after2;
      this.time_after_state_Selected = this.yes_time_after_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yestime2";
    },
    updateYesYesTime2() {
      this.tabState = "time";
      if (this.yesyes_triggerCron2.happenTime != undefined) {
        this.happenTime = this.yesyes_triggerCron2.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yesyes_triggerCron2.happenDay;
      this.happenWeek_Selected = this.yesyes_triggerCron2.happenWeek;
      this.happenWeek2_Selected = this.yesyes_triggerCron2.happenWeek2;
      this.happenMonth_Selected = this.yesyes_triggerCron2.happenMonth;
      this.happenMonth2_Selected = this.yesyes_triggerCron2.happenMonth2;
      this.happenMonth3_Selected = this.yesyes_triggerCron2.happenMonth3;
      this.happenMonth4_Selected = this.yesyes_triggerCron2.happenMonth4;
      this.happenYear_Selected = this.yesyes_triggerCron2.happenYear;
      this.happenYear2_Selected = this.yesyes_triggerCron2.happenYear2;
      this.happenYear3_Selected = this.yesyes_triggerCron2.happenYear3;
      this.happenYear4_Selected = this.yesyes_triggerCron2.happenYear4;
      this.happenYear5_Selected = this.yesyes_triggerCron2.happenYear5;
      this.daytime = this.yesyes_triggerCron2.daytime2;
      this.endCount2 = this.yesyes_triggerCron2.endCount2;
      if (this.yesyes_triggerCron2.picked != undefined) {
        this.picked3 = this.yesyes_triggerCron2.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.cycleTime_yesyes2 = this.picked3;
      this.time_after_Selected = this.yesyes_time_after2;
      this.time_after_state_Selected = this.yesyes_time_after_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyestime2";
    },
    updateYesNoTime2() {
      this.tabState = "time";
      if (this.yesno_triggerCron2.happenTime != undefined) {
        this.happenTime = this.yesno_triggerCron2.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.yesno_triggerCron2.happenDay;
      this.happenWeek_Selected = this.yesno_triggerCron2.happenWeek;
      this.happenWeek2_Selected = this.yesno_triggerCron2.happenWeek2;
      this.happenMonth_Selected = this.yesno_triggerCron2.happenMonth;
      this.happenMonth2_Selected = this.yesno_triggerCron2.happenMonth2;
      this.happenMonth3_Selected = this.yesno_triggerCron2.happenMonth3;
      this.happenMonth4_Selected = this.yesno_triggerCron2.happenMonth4;
      this.happenYear_Selected = this.yesno_triggerCron2.happenYear;
      this.happenYear2_Selected = this.yesno_triggerCron2.happenYear2;
      this.happenYear3_Selected = this.yesno_triggerCron2.happenYear3;
      this.happenYear4_Selected = this.yesno_triggerCron2.happenYear4;
      this.happenYear5_Selected = this.yesno_triggerCron2.happenYear5;
      this.daytime = this.yesno_triggerCron2.daytime2;
      this.endCount2 = this.yesno_triggerCron2.endCount2;
      if (this.yesno_triggerCron2.picked != undefined) {
        this.picked3 = this.yesno_triggerCron2.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.cycleTime_yesno2 = this.picked3;
      this.time_after_Selected = this.yesno_time_after2;
      this.time_after_state_Selected = this.yesno_time_after_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnotime2";
    },
    updateNoTime2() {
      this.tabState = "time";
      if (this.no_triggerCron2.happenTime != undefined) {
        this.happenTime = this.no_triggerCron2.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.no_triggerCron2.happenDay;
      this.happenWeek_Selected = this.no_triggerCron2.happenWeek;
      this.happenWeek2_Selected = this.no_triggerCron2.happenWeek2;
      this.happenMonth_Selected = this.no_triggerCron2.happenMonth;
      this.happenMonth2_Selected = this.no_triggerCron2.happenMonth2;
      this.happenMonth3_Selected = this.no_triggerCron2.happenMonth3;
      this.happenMonth4_Selected = this.no_triggerCron2.happenMonth4;
      this.happenYear_Selected = this.no_triggerCron2.happenYear;
      this.happenYear2_Selected = this.no_triggerCron2.happenYear2;
      this.happenYear3_Selected = this.no_triggerCron2.happenYear3;
      this.happenYear4_Selected = this.no_triggerCron2.happenYear4;
      this.happenYear5_Selected = this.no_triggerCron2.happenYear5;
      this.daytime = this.no_triggerCron2.daytime2;
      this.endCount2 = this.no_triggerCron2.endCount2;
      if (this.no_triggerCron2.picked != undefined) {
        this.picked3 = this.no_triggerCron2.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.cycleTime_no2 = this.picked3;
      this.time_after_Selected = this.no_time_after2;
      this.time_after_state_Selected = this.no_time_after_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "notime2";
    },
    updateNoYesTime2() {
      this.tabState = "time";
      if (this.noyes_triggerCron2.happenTime != undefined) {
        this.happenTime = this.noyes_triggerCron2.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.noyes_triggerCron2.happenDay;
      this.happenWeek_Selected = this.noyes_triggerCron2.happenWeek;
      this.happenWeek2_Selected = this.noyes_triggerCron2.happenWeek2;
      this.happenMonth_Selected = this.noyes_triggerCron2.happenMonth;
      this.happenMonth2_Selected = this.noyes_triggerCron2.happenMonth2;
      this.happenMonth3_Selected = this.noyes_triggerCron2.happenMonth3;
      this.happenMonth4_Selected = this.noyes_triggerCron2.happenMonth4;
      this.happenYear_Selected = this.noyes_triggerCron2.happenYear;
      this.happenYear2_Selected = this.noyes_triggerCron2.happenYear2;
      this.happenYear3_Selected = this.noyes_triggerCron2.happenYear3;
      this.happenYear4_Selected = this.noyes_triggerCron2.happenYear4;
      this.happenYear5_Selected = this.noyes_triggerCron2.happenYear5;
      this.daytime = this.noyes_triggerCron2.daytime2;
      this.endCount2 = this.noyes_triggerCron2.endCount2;
      if (this.noyes_triggerCron2.picked != undefined) {
        this.picked3 = this.noyes_triggerCron2.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.cycleTime_noyes2 = this.picked3;
      this.time_after_Selected = this.noyes_time_after2;
      this.time_after_state_Selected = this.noyes_time_after_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyestime2";
    },
    updateNoNoTime2() {
      this.tabState = "time";
      if (this.nono_triggerCron2.happenTime != undefined) {
        this.happenTime = this.nono_triggerCron2.happenTime;
      } else {
        this.happenTime = "月";
      }
      this.happenDay_Selected = this.nono_triggerCron2.happenDay;
      this.happenWeek_Selected = this.nono_triggerCron2.happenWeek;
      this.happenWeek2_Selected = this.nono_triggerCron2.happenWeek2;
      this.happenMonth_Selected = this.nono_triggerCron2.happenMonth;
      this.happenMonth2_Selected = this.nono_triggerCron2.happenMonth2;
      this.happenMonth3_Selected = this.nono_triggerCron2.happenMonth3;
      this.happenMonth4_Selected = this.nono_triggerCron2.happenMonth4;
      this.happenYear_Selected = this.nono_triggerCron2.happenYear;
      this.happenYear2_Selected = this.nono_triggerCron2.happenYear2;
      this.happenYear3_Selected = this.nono_triggerCron2.happenYear3;
      this.happenYear4_Selected = this.nono_triggerCron2.happenYear4;
      this.happenYear5_Selected = this.nono_triggerCron2.happenYear5;
      this.daytime = this.nono_triggerCron2.daytime2;
      this.endCount2 = this.nono_triggerCron2.endCount2;
      if (this.nono_triggerCron2.picked != undefined) {
        this.picked3 = this.nono_triggerCron2.picked;
      } else {
        this.picked3 = "一次性";
      }

      this.cycleTime_nono2 = this.picked3;
      this.time_after_Selected = this.nono_time_after2;
      this.time_after_state_Selected = this.nono_time_after_state2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonotime2";
    },
    deleteTimeTag2() {
      if (this.updateWho === "yestime2") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yes_time_after2 = "";
        this.yes_time_after_state2 = "天後";
        this.yesyes_time_after2 = "";
        this.yesyes_time_after_state2 = "天後";
        this.yesno_time_after2 = "";
        this.yesno_time_after_state2 = "天後";
        this.yes_action2 = "";
        this.yes_subject2 = null;
        this.yes_template2 = "";
        this.currentEventYes2 = null;
        this.yes_time_interval2 = null;
        this.yes_time_state2 = null;
        this.yesyes_action2 = "";
        this.yesno_action2 = "";
        this.yesyes_subject2 = null;
        this.yesno_subject2 = null;
        this.yesyes_template2 = "";
        this.yesno_template2 = "";
        const yesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList.splice(yesindex, 1);
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList.splice(yesnoindex, 1);
        }
        console.log("testList2", this.testList);
      } else if (this.updateWho === "notime2") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.no_time_after2 = "";
        this.no_time_after_state2 = "天後";
        this.noyes_time_after2 = "";
        this.noyes_time_after_state2 = "天後";
        this.nono_time_after2 = "";
        this.nono_time_after_state2 = "天後";
        this.no_action2 = "";
        this.no_subject2 = null;
        this.no_template2 = "";
        this.currentEventNo2 = null;
        this.no_time_interval2 = null;
        this.no_time_state2 = null;
        this.noyes_action2 = "";
        this.nono_action2 = "";
        this.noyes_subject2 = null;
        this.nono_subject2 = null;
        this.noyes_template2 = "";
        this.nono_template2 = "";
        const noindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList.splice(noindex, 1);
        const noyesindex = this.testList2.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList.splice(noyesindex, 1);
        }
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList.splice(nonoindex, 1);
        }
        console.log("testList", this.testList);
      } else if (this.updateWho === "yesyestime2") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yesyes_time_after2 = "";
        this.yesyes_time_after_state2 = "天後";
        this.yesyes_action2 = "";
        this.yesyes_subject2 = null;
        this.yesyes_template2 = "";
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList.splice(yesyesindex, 1);
        }
        console.log("testList", this.testList);
      } else if (this.updateWho === "yesnotime2") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.yesno_time_after2 = "";
        this.yesno_time_after_state2 = "天後";
        this.yesno_action2 = "";
        this.yesno_subject2 = null;
        this.yesno_template2 = "";
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList.splice(yesnoindex, 1);
        }
        console.log("testList", this.testList);
      } else if (this.updateWho === "noyestime2") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.noyes_time_after2 = "";
        this.noyes_time_after_state2 = "天後";
        this.noyes_action2 = "";
        this.noyes_subject2 = null;
        this.noyes_template2 = "";
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList.splice(noyesindex, 1);
        }
        console.log("testList", this.testList);
      } else if (this.updateWho === "nonotime2") {
        this.time_after_Selected = "";
        this.time_after_state_Selected = "";
        this.nono_time_after2 = "";
        this.nono_time_after_state2 = "天後";
        this.nono_action2 = "";
        this.nono_subject2 = null;
        this.nono_template2 = "";
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList.splice(nonoindex, 1);
        }
        console.log("testList", this.testList);
      }
    },
    confirmUpdateTimeTab2() {
      if (this.updateWho === "yestime2") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("yes_", "2");
          }
          this.yes_triggerCron2 = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_yes2 = this.picked3;
          this.yes_time_after2 = this.time_after_Selected;
          this.yes_time_after_state2 = this.time_after_state_Selected;
          this.testList.map((d) => {
            if (d.vice_ruleID.length === 13 && d.is_yes === 1) {
              if (this.yes_time_after_state2 === "小時後") {
                d.time_after = this.yes_time_after2;
              } else {
                d.time_after = this.yes_time_after2 * 24;
              }
              if (this.yes_triggerCron2.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.yes_triggerCron2.time_every;
                d.daytime = this.yes_triggerCron2.daytime;
                d.endcount = this.yes_triggerCron2.endCount2;
                d.time_setting = this.yes_triggerCron2;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.yes_triggerCron2;
              }
            }
          });
          console.log(this.testList);
        }
      } else if (this.updateWho === "notime2") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("no_", "2");
          }
          this.no_triggerCron2 = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_no2 = this.picked3;
          this.no_time_after2 = this.time_after_Selected;
          this.no_time_after_state2 = this.time_after_state_Selected;
          this.testList.map((d) => {
            if (d.vice_ruleID.length === 13 && d.is_yes === 0) {
              if (this.no_time_after_state2 === "小時後") {
                d.time_after = this.no_time_after2;
              } else {
                d.time_after = this.no_time_after2 * 24;
              }
              if (this.no_triggerCron2.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.no_triggerCron2.time_every;
                d.daytime = this.no_triggerCron2.daytime;
                d.endcount = this.no_triggerCron2.endCount2;
                d.time_setting = this.no_triggerCron2;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.no_triggerCron2;
              }
            }
          });
          console.log(this.testList);
        }
      } else if (this.updateWho === "yesyestime2") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("yesyes_", "2");
          }
          this.yesyes_triggerCron2 = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_yesyes2 = this.picked3;
          this.yesyes_time_after2 = this.time_after_Selected;
          this.yesyes_time_after_state2 = this.time_after_state_Selected;
          this.testList.map((d) => {
            if (d.vice_ruleID.includes("_1_1")) {
              if (this.yesyes_time_after_state2 === "小時後") {
                d.time_after = this.yesyes_time_after2;
              } else {
                d.time_after = this.yesyes_time_after2 * 24;
              }
              if (this.yesyes_triggerCron2.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.yesyes_triggerCron2.time_every;
                d.daytime = this.yesyes_triggerCron2.daytime;
                d.endcount = this.yesyes_triggerCron2.endCount2;
                d.time_setting = this.yesyes_triggerCron2;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.yesyes_triggerCron2;
              }
            }
          });
          console.log(this.testList);
        }
      } else if (this.updateWho === "yesnotime2") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("yesno_", "2");
          }
          this.yesno_triggerCron2 = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_yesno2 = this.picked3;
          this.yesno_time_after2 = this.time_after_Selected;
          this.yesno_time_after_state2 = this.time_after_state_Selected;
          this.testList.map((d) => {
            if (d.vice_ruleID.includes("_1_2")) {
              if (this.yesno_time_after_state2 === "小時後") {
                d.time_after = this.yesno_time_after2;
              } else {
                d.time_after = this.yesno_time_after2 * 24;
              }
              if (this.yesno_triggerCron2.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.yesno_triggerCron2.time_every;
                d.daytime = this.yesno_triggerCron2.daytime;
                d.endcount = this.yesno_triggerCron2.endCount2;
                d.time_setting = this.yesno_triggerCron2;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.yesno_triggerCron2;
              }
            }
          });
          console.log(this.testList);
        }
      } else if (this.updateWho === "noyestime2") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("noyes_", "2");
          }
          this.noyes_triggerCron2 = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_noyes2 = this.picked3;
          this.noyes_time_after2 = this.time_after_Selected;
          this.noyes_time_after_state2 = this.time_after_state_Selected;
          this.testList.map((d) => {
            if (d.vice_ruleID.includes("_2_1")) {
              if (this.noyes_time_after_state === "小時後") {
                d.time_after = this.noyes_time_after2;
              } else {
                d.time_after = this.noyes_time_after2 * 24;
              }
              if (this.noyes_triggerCron2.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.noyes_triggerCron2.time_every;
                d.daytime = this.noyes_triggerCron2.daytime;
                d.endcount = this.noyes_triggerCron2.endCount2;
                d.time_setting = this.noyes_triggerCron2;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.noyes_triggerCron2;
              }
            }
          });
          console.log(this.testList);
        }
      } else if (this.updateWho === "nonotime2") {
        if (
          this.time_after_Selected === "" ||
          this.time_after_state_Selected === ""
        ) {
          alert("輸入欄不能為空");
        } else {
          if (this.picked3 != "一次性") {
            this.getNotCron("nono_", "2");
          }
          this.nono_triggerCron2 = {
            happenTime: this.happenTime,
            happenDay: this.happenDay_Selected,
            happenWeek: this.happenWeek_Selected,
            happenMonth: this.happenMonth_Selected,
            happenYear: this.happenYear_Selected,
            daytime: this.ader_daytime,
            daytime2: this.daytime,
            endCount2: this.ader_endCount,
            picked: this.picked3,
            time_every: this.time_every,
          };

          this.cycleTime_nono2 = this.picked3;
          this.nono_time_after2 = this.time_after_Selected;
          this.nono_time_after_state2 = this.time_after_state_Selected;
          this.testList.map((d) => {
            if (d.vice_ruleID.includes("_2_2")) {
              if (this.nono_time_after_state2 === "小時後") {
                d.time_after = this.nono_time_after2;
              } else {
                d.time_after = this.nono_time_after2 * 24;
              }
              if (this.nono_triggerCron2.picked != "一次性") {
                d.cron = "trigger";
                d.time_every = this.nono_triggerCron2.time_every;
                d.daytime = this.nono_triggerCron2.daytime;
                d.endcount = this.nono_triggerCron2.endCount2;
                d.time_setting = this.nono_triggerCron2;
              } else {
                d.cron = "";
                d.time_every = 0;
                d.daytime = "";
                d.endcount = -1;
                d.time_setting = this.nono_triggerCron2;
              }
            }
          });
          console.log(this.testList);
        }
      }
    },

    updateYesAction2() {
      this.tabState = "action";
      const yesindex = this.testList.findIndex((d) => {
        return d.vice_ruleID.length === 13 && d.is_yes === 1;
      });
      this.action_Selected = this.testList[yesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesaction2";
    },
    updateYesYesAction2() {
      this.tabState = "action";
      const yesyesindex = this.testList.findIndex((d) => {
        return d.vice_ruleID.includes("_1_1");
      });
      this.action_Selected = this.testList[yesyesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyesaction2";
    },
    updateYesNoAction2() {
      this.tabState = "action";
      const yesnoindex = this.testList.findIndex((d) => {
        return d.vice_ruleID.includes("_1_2");
      });
      this.action_Selected = this.testList[yesnoindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnoaction2";
    },
    updateNoAction2() {
      this.tabState = "action";
      const noindex = this.testList.findIndex((d) => {
        return d.vice_ruleID.length === 13 && d.is_yes === 0;
      });
      this.action_Selected = this.testList[noindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noaction2";
    },
    updateNoYesAction2() {
      this.tabState = "action";
      const noyesindex = this.testList.findIndex((d) => {
        return d.vice_ruleID.includes("_2_1");
      });
      this.action_Selected = this.testList[noyesindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyesaction2";
    },
    updateNoNoAction2() {
      this.tabState = "action";
      const nonoindex = this.testList.findIndex((d) => {
        return d.vice_ruleID.includes("_2_2");
      });
      this.action_Selected = this.testList[nonoindex].action;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonoaction2";
    },
    deleteActionTag2() {
      if (this.updateWho === "yesaction2") {
        this.yes_action2 = "";
        this.yes_subject2 = null;
        this.yes_template2 = "";
        this.currentEventYes2 = null;
        this.yes_time_interval2 = null;
        this.yes_time_state2 = null;
        this.yesyes_time_after2 = "";
        this.yesyes_time_after_state2 = "天後";
        this.yesno_time_after2 = "";
        this.yesno_time_after_state2 = "天後";
        this.yesyes_action2 = "";
        this.yesno_action2 = "";
        this.yesyes_subject2 = null;
        this.yesno_subject2 = null;
        this.yesyes_template2 = "";
        this.yesno_template2 = "";
        const yesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList[yesindex].action = undefined;
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList.splice(yesnoindex, 1);
        }
        console.log(this.testList);
      } else if (this.updateWho === "noaction2") {
        this.no_action2 = "";
        this.no_subject2 = null;
        this.no_template2 = "";
        this.currentEventNo2 = null;
        this.no_time_interval2 = null;
        this.no_time_state2 = null;
        this.noyes_time_after2 = "";
        this.noyes_time_after_state2 = "天後";
        this.nono_time_after2 = "";
        this.nono_time_after_state2 = "天後";
        this.noyes_action2 = "";
        this.nono_action2 = "";
        this.noyes_subject2 = null;
        this.nono_subject2 = null;
        this.noyes_template2 = "";
        this.nono_template2 = "";
        const noindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList[noindex].action = undefined;
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList.splice(noyesindex, 1);
        }
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList.splice(nonoindex, 1);
        }
        console.log(this.testList);
      } else if (this.updateWho === "yesyesaction2") {
        this.yesyes_action2 = "";
        this.yesyes_subject2 = null;
        this.yesyes_template2 = "";
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList[yesyesindex].action = undefined;

        console.log(this.testList);
      } else if (this.updateWho === "yesnoaction2") {
        this.yesno_action2 = "";
        this.yesno_subject2 = null;
        this.yesno_template2 = "";
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList[yesnoindex].action = undefined;

        console.log(this.testList);
      } else if (this.updateWho === "noyesaction2") {
        this.noyes_action2 = "";
        this.noyes_subject2 = null;
        this.noyes_template2 = "";
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList[noyesindex].action = undefined;

        console.log(this.testList);
      } else if (this.updateWho === "nonoaction2") {
        this.nono_action2 = "";
        this.nono_subject2 = null;
        this.nono_template2 = "";
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList[nonoindex].action = undefined;

        console.log(this.testList);
      }
    },
    confirmUpdateActionTab2() {
      if (this.updateWho === "yesaction2") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.length === 13 && d.is_yes === 1;
          });
          this.testList[yesindex].action = this.action_Selected;
          console.log(this.testList);
        }
      } else if (this.updateWho === "noaction2") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const noindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.length === 13 && d.is_yes === 0;
          });
          this.testList[noindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesyesaction2") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesyesindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_1_1");
          });
          this.testList[yesyesindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "yesnoaction2") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const yesnoindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_1_2");
          });
          this.testList[yesnoindex].action = this.action_Selected;
          console.log(this.testList2);
        }
      } else if (this.updateWho === "noyesaction2") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const noyesindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_2_1");
          });
          this.testList[noyesindex].action = this.action_Selected;
          console.log(this.testList);
        }
      } else if (this.updateWho === "nonoaction2") {
        if (this.action_Selected === "") {
          alert("輸入欄不能為空");
        } else {
          const nonoindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_2_2");
          });
          this.testList[nonoindex].action = this.action_Selected;
          console.log(this.testList);
        }
      }
      this.offCanvasClick.hide();
    },

    updateYesTemplate2(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
      this.name = this.yes_template2;
      this.newTodoTitle = this.yes_template2;
      this.newSub = this.yes_subject2;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yestemplate2";
    },
    updateYesYesTemplate2(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
      this.name = this.yesyes_template2;
      this.newTodoTitle = this.yesyes_template2;
      this.newSub = this.yesyes_subject2;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesyestemplate2";
    },
    updateYesNoTemplate2(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
      this.name = this.yesno_template2;
      this.newTodoTitle = this.yesno_template2;
      this.newSub = this.yesno_subject2;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "yesnotemplate2";
    },
    updateNoTemplate2(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
      this.name = this.no_template2;
      this.newTodoTitle = this.no_template2;
      2;
      this.newSub = this.no_subject2;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "notemplate2";
    },
    updateNoYesTemplate2(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
      this.name = this.noyes_template2;
      this.newTodoTitle = this.noyes_template2;
      this.newSub = this.noyes_subject2;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "noyestemplate2";
    },
    updateNoNoTemplate2(e, dragText) {
      this.tabState = "template";
      this.emailChannel = false;
      this.key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
      this.name = this.nono_template2;
      this.newTodoTitle = this.nono_template2;
      this.newSub = this.nono_subject2;

      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "nonotemplate2";
    },
    confirmUpdateTemplateTab2() {
      if (this.updateWho === "yestemplate2") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const yesindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.length === 13 && d.is_yes === 1;
          });
          this.testList[yesindex].template_name = this.newTodoTitle;
          this.testList[yesindex].subject = this.newSub;
          this.yes_template2 = this.newTodoTitle;
          this.yes_subject2 = null;
        }
      } else if (this.updateWho === "notemplate2") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const noindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.length === 13 && d.is_yes === 0;
          });
          this.testList[noindex].template_name = this.newTodoTitle;
          this.testList[noindex].subject = this.newSub;
          this.no_template2 = this.newTodoTitle;
          this.no_subject2 = null;
        }
      } else if (this.updateWho === "yesyestemplate2") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const yesyesindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_1_1");
          });
          this.testList[yesyesindex].template_name = this.newTodoTitle;
          this.testList[yesyesindex].subject = this.newSub;
          this.yesyes_template2 = this.newTodoTitle;
          this.yesyes_subject2 = null;
        }
      } else if (this.updateWho === "yesnotemplate2") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const yesnoindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_1_2");
          });
          this.testList[yesnoindex].template_name = this.newTodoTitle;
          this.testList[yesnoindex].subject = this.newSub;
          this.yesno_template2 = this.newTodoTitle;
          this.yesno_subject2 = null;
        }
      } else if (this.updateWho === "noyestemplate2") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const noyesindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_2_1");
          });
          this.testList[noyesindex].template_name = this.newTodoTitle;
          this.testList[noyesindex].subject = this.newSub;
          this.noyes_template2 = this.newTodoTitle;
          this.noyes_subject2 = null;
        }
      } else if (this.updateWho === "nonotemplate2") {
        if (this.newTodoTitle === "") {
          alert("模板名稱不能為空");
        } else {
          const nonoindex = this.testList.findIndex((d) => {
            return d.vice_ruleID.includes("_2_2");
          });
          this.testList[nonoindex].template_name = this.newTodoTitle;
          this.testList[nonoindex].subject = this.newSub;
          this.nono_template2 = this.newTodoTitle;
          this.nono_subject2 = null;
        }
      }

      this.offCanvasClick.hide();
    },
    deleteTemplateTag2() {
      if (this.updateWho === "yestemplate2") {
        this.yes_subject2 = null;
        this.yes_template2 = "";
        this.currentEventYes2 = null;
        this.yes_time_interval2 = null;
        this.yes_time_state2 = null;
        this.yesyes_time_after2 = "";
        this.yesyes_time_after_state2 = "天後";
        this.yesno_time_after2 = "";
        this.yesno_time_after_state2 = "天後";
        this.yesyes_action2 = "";
        this.yesno_action2 = "";
        this.yesyes_subject2 = null;
        this.yesno_subject2 = null;
        this.yesyes_template2 = "";
        this.yesno_template2 = "";
        const yesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 1;
        });
        this.testList[yesindex].template_name = undefined;
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        if (yesyesindex != -1) {
          this.testList.splice(yesyesindex, 1);
        }
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        if (yesnoindex != -1) {
          this.testList.splice(yesnoindex, 1);
        }
        console.log(this.testList);
      } else if (this.updateWho === "notemplate2") {
        this.no_subject2 = null;
        this.no_template2 = "";
        this.currentEventNo2 = null;
        this.no_time_interval2 = null;
        this.no_time_state2 = null;
        this.noyes_time_after2 = "";
        this.noyes_time_after_state2 = "天後";
        this.nono_time_after2 = "";
        this.nono_time_after_state2 = "天後";
        this.noyes_action2 = "";
        this.nono_action2 = "";
        this.noyes_subject2 = null;
        this.nono_subject2 = null;
        this.noyes_template2 = "";
        this.nono_template2 = "";
        const noindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.length === 13 && d.is_yes === 0;
        });
        this.testList[noindex].template_name = undefined;
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        if (noyesindex != -1) {
          this.testList.splice(noyesindex, 1);
        }
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        if (nonoindex != -1) {
          this.testList.splice(nonoindex, 1);
        }
        console.log(this.testList);
      } else if (this.updateWho === "yesyestemplate2") {
        this.yesyes_subject2 = null;
        this.yesyes_template2 = "";
        const yesyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_1");
        });
        this.testList[yesyesindex].template_name = undefined;
        console.log(this.testList);
      } else if (this.updateWho === "yesnotemplate2") {
        this.yesno_subject2 = null;
        this.yesno_template2 = "";
        const yesnoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_1_2");
        });
        this.testList[yesnoindex].template_name = undefined;
        console.log(this.testList);
      } else if (this.updateWho === "noyestemplate2") {
        this.noyes_subject2 = null;
        this.noyes_template2 = "";
        const noyesindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_1");
        });
        this.testList[noyesindex].template_name = undefined;
        console.log(this.testList);
      } else if (this.updateWho === "nonotemplate2") {
        this.nono_subject2 = null;
        this.nono_template2 = "";
        const nonoindex = this.testList.findIndex((d) => {
          return d.vice_ruleID.includes("_2_2");
        });
        this.testList[nonoindex].template_name = undefined;
        console.log(this.testList);
      }
    },

    updateTriggerEvent() {
      this.tabState = "trigger";
      this.triggerSelected = this.currentTrigger;
      this.time_trigger_Selected = this.time_trigger;
      this.time_trigger_state_Selected = this.time_trigger_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );
      this.offCanvasClick.show();
      this.updateWho = "trigger";
    },
    deleteTriggerTag() {
      if (this.updateWho === "trigger") {
        this.currentTrigger = null;
        this.time_trigger = "";
        this.time_trigger_state = "";
        this.template_email = "";
        this.template_sms = "";
        this.time_tempalte_Selected = "";
        this.time_tempalte_state_Selected = "";

        this.currentEvent = null;
        this.currentEventYes = null;
        this.currentEventNo = null;
        this.time_interval = null;
        this.yes_time_interval = null;
        this.no_time_interval = null;
        this.time_state = null;
        this.yes_time_state = null;
        this.no_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList2 = [];

        this.yes_time_after = "";
        this.yes_action = "";
        this.yes_template = "";
        this.no_time_after = "";
        this.no_action = "";
        this.no_template = "";
        this.yesyes_time_after = "";
        this.yesyes_action = "";
        this.yesyes_template = "";
        this.yesno_time_after = "";
        this.yesno_action = "";
        this.yesno_template = "";
        this.noyes_time_after = "";
        this.noyes_action = "";
        this.noyes_template = "";
        this.nono_time_after = "";
        this.nono_action = "";
        this.nono_template = "";

        this.currentEvent2 = null;
        this.currentEventYes2 = null;
        this.currentEventNo2 = null;
        this.time_interval2 = null;
        this.yes_time_interval2 = null;
        this.no_time_interval2 = null;
        this.time_state2 = null;
        this.yes_time_state2 = null;
        this.no_time_state2 = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList = [];

        this.yes_time_after2 = "";
        this.yes_action2 = "";
        this.yes_template2 = "";
        this.no_time_after2 = "";
        this.no_action2 = "";
        this.no_template2 = "";
        this.yesyes_time_after2 = "";
        this.yesyes_action2 = "";
        this.yesyes_template2 = "";
        this.yesno_time_after2 = "";
        this.yesno_action2 = "";
        this.yesno_template2 = "";
        this.noyes_time_after2 = "";
        this.noyes_action2 = "";
        this.noyes_template2 = "";
        this.nono_time_after2 = "";
        this.nono_action2 = "";
        this.nono_template2 = "";

        this.email_subject = "";
        this.sms_subject = "";
        this.yes_subject = "";
        this.no_subject = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.noyes_subject = "";
        this.nono_subject = "";
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateTriggerTab() {
      if (this.updateWho === "trigger") {
        if (this.triggerSelected === "") {
          alert("輸入欄不能為空");
        } else {
          this.currentTrigger = this.triggerSelected;
        }
        if (this.time_trigger != "" && this.currentTrigger != "購物車未結") {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else if (
          this.time_trigger != "" &&
          this.currentTrigger === "購物車未結"
        ) {
          this.time_trigger = 2;
          this.time_trigger_state = "天內";
        }
      }
      if (this.currentTrigger === "購買後促銷") {
        if (this.tagValueSelected != "") {
          this.triggerBuy = {
            tag: this.tagSelected,
            value: this.tagValueSelected,
          };
        } else {
          this.triggerBuy = {
            tag: "",
            value: "",
          };
        }
      }
      this.offCanvasClick.hide();
      this.tabState = "trigger_time";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(2) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
    },

    updateTriggerTime() {
      this.triggerSelected = this.currentTrigger;
      this.tabState = "trigger_time";
      this.exp_cycleTime_first_ader = "";
      this.exp_cycleTime_second_ader = "";

      if (this.triggerCron.happenTime != undefined) {
        this.happenTime = this.triggerCron.happenTime;
      } else {
        this.happenTime = "月";
      }
      if (this.triggerCron.happenMonth_moreoption != undefined) {
        this.happenMonth_moreoption = this.triggerCron.happenMonth_moreoption;
      } else {
        this.happenMonth_moreoption = false;
      }
      this.cycleDayStart = this.triggerCron.ader_cycleDayStart;
      this.happenDay_Selected = this.triggerCron.happenDay;
      this.happenWeek_Selected = this.triggerCron.happenWeek;
      this.happenWeek2_Selected = this.triggerCron.happenWeek2;
      this.happenMonth_Selected = this.triggerCron.happenMonth;
      this.happenMonth2_Selected = this.triggerCron.happenMonth2;
      this.happenMonth3_Selected = this.triggerCron.happenMonth3;
      this.happenMonth4_Selected = this.triggerCron.happenMonth4;
      this.happenMonth5_Selected = this.triggerCron.happenMonth5;
      this.happenYear_Selected = this.triggerCron.happenYear;
      this.happenYear2_Selected = this.triggerCron.happenYear2;
      this.happenYear3_Selected = this.triggerCron.happenYear3;
      this.happenYear4_Selected = this.triggerCron.happenYear4;
      this.happenYear5_Selected = this.triggerCron.happenYear5;
      this.yearBasedWith = this.triggerCron.yearBasedWith;
      this.daytime = this.triggerCron.daytime2;
      this.endCount2 = this.triggerCron.endCount2;
      this.picked = this.triggerCron.picked;
      this.picked2 = this.triggerCron.picked2;

      this.time_trigger_Selected = this.time_trigger;
      this.time_trigger_state_Selected = this.time_trigger_state;
      this.offCanvasClick = new bootstrap.Offcanvas(
        document.getElementById("offcanvasRight"),
        {
          keyboard: false,
        }
      );

      this.offCanvasClick.show();
      this.updateWho = "trigger_time";
    },
    deleteTriggerTime() {
      if (this.updateWho === "trigger_time") {
        this.time_trigger = "";
        this.time_trigger_state = "";
        this.template_email = "";
        this.template_sms = "";
        this.time_tempalte_Selected = "";
        this.time_tempalte_state_Selected = "";

        this.currentEvent = null;
        this.currentEventYes = null;
        this.currentEventNo = null;
        this.time_interval = null;
        this.yes_time_interval = null;
        this.no_time_interval = null;
        this.time_state = null;
        this.yes_time_state = null;
        this.no_time_state = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList2 = [];

        this.yes_time_after = "";
        this.yes_action = "";
        this.yes_template = "";
        this.no_time_after = "";
        this.no_action = "";
        this.no_template = "";
        this.yesyes_time_after = "";
        this.yesyes_action = "";
        this.yesyes_template = "";
        this.yesno_time_after = "";
        this.yesno_action = "";
        this.yesno_template = "";
        this.noyes_time_after = "";
        this.noyes_action = "";
        this.noyes_template = "";
        this.nono_time_after = "";
        this.nono_action = "";
        this.nono_template = "";

        this.currentEvent2 = null;
        this.currentEventYes2 = null;
        this.currentEventNo2 = null;
        this.time_interval2 = null;
        this.yes_time_interval2 = null;
        this.no_time_interval2 = null;
        this.time_state2 = null;
        this.yes_time_state2 = null;
        this.no_time_state2 = null;
        this.eventSelected = "";
        this.time_interval_Selected = "";
        this.time_state_Selected = "";
        this.testList = [];

        this.yes_time_after2 = "";
        this.yes_action2 = "";
        this.yes_template2 = "";
        this.no_time_after2 = "";
        this.no_action2 = "";
        this.no_template2 = "";
        this.yesyes_time_after2 = "";
        this.yesyes_action2 = "";
        this.yesyes_template2 = "";
        this.yesno_time_after2 = "";
        this.yesno_action2 = "";
        this.yesno_template2 = "";
        this.noyes_time_after2 = "";
        this.noyes_action2 = "";
        this.noyes_template2 = "";
        this.nono_time_after2 = "";
        this.nono_action2 = "";
        this.nono_template2 = "";

        this.email_subject = "";
        this.sms_subject = "";
        this.yes_subject = "";
        this.no_subject = "";
        this.yesyes_subject = "";
        this.yesno_subject = "";
        this.noyes_subject = "";
        this.nono_subject = "";
      }
      this.offCanvasClick.hide();
    },
    confirmUpdateTriggerTime: async function () {
      if (
        this.triggerSelected === "購物車未結" &&
        this.time_trigger_state_Selected != "天內"
      ) {
        return alert("第一封信的寄出時間設定只能為天內");
      } else if (
        this.triggerSelected != "購物車未結" &&
        this.time_trigger_state_Selected === "天內"
      ) {
        return alert("第一封信的寄出時間設定不能為天內");
      } else if (
        this.time_trigger_Selected === "" ||
        this.time_trigger_state_Selected === ""
      ) {
        return alert("第一封信的寄出時間設定不能為空");
      }

      if (this.updateWho === "trigger_time") {
        if (this.time_trigger_state_Selected === "小時後") {
          if (this.emailList != undefined) {
            this.emailList.time_after = this.time_trigger_Selected;
            this.emailList.event = this.triggerSelected;
          }
          if (this.smsList != undefined) {
            this.smsList.time_after = this.time_trigger_Selected;
            this.smsList.event = this.triggerSelected;
          }
        } else {
          if (this.emailList != undefined) {
            this.emailList.time_after = this.time_trigger_Selected * 24;
            this.emailList.event = this.triggerSelected;
          }
          if (this.smsList != undefined) {
            this.smsList.time_after = this.time_trigger_Selected * 24;
            this.smsList.event = this.triggerSelected;
          }
        }
        if (this.triggerSelected != "定期投放") {
          this.getNotCron("", "1");
        } else {
          await this.aderExpSendDate();
        }
        this.triggerCron = {
          ader_cycleDayStart: this.ader_cycleDayStart,
          cycleDayStart: this.cycleDayStart,
          picked2: this.picked2,
          happenTime: this.happenTime,
          happenMonth_moreoption: this.happenMonth_moreoption,
          yearBasedWith: this.yearBasedWith,
          happenDay: this.happenDay_Selected,
          happenWeek: this.happenWeek_Selected,
          happenWeek2: this.happenWeek2_Selected,
          happenMonth: this.happenMonth_Selected,
          happenMonth2: this.happenMonth2_Selected,
          happenMonth3: this.happenMonth3_Selected,
          happenMonth4: this.happenMonth4_Selected,
          happenMonth5: this.happenMonth5_Selected,
          happenYear: this.happenYear_Selected,
          happenYear2: this.happenYear2_Selected,
          happenYear3: this.happenYear3_Selected,
          happenYear4: this.happenYear4_Selected,
          happenYear5: this.happenYear5_Selected,
          daytime: this.ader_daytime,
          daytime2: this.daytime,
          endCount2: this.ader_endCount,
          picked: this.picked,
          cron: this.aderTestCron,
          time_every: this.time_every,
        };

        if (this.emailList != undefined) {
          if (
            this.triggerSelected != "定期投放" &&
            this.triggerCron.picked === "週期性"
          ) {
            this.emailList.cron = "trigger";
            this.emailList.time_every = this.triggerCron.time_every;
            this.emailList.daytime = this.triggerCron.daytime;
            this.emailList.endcount = this.triggerCron.endCount2;
            this.emailList.time_setting = this.triggerCron;
            this.emailList.time_start = "";
          } else if (
            this.triggerSelected != "定期投放" &&
            this.triggerCron.picked === "一次性"
          ) {
            this.emailList.cron = "";
            this.emailList.time_every = 0;
            this.emailList.daytime = "";
            this.emailList.endcount = -1;
            this.emailList.time_setting = this.triggerCron;
            this.emailList.time_start = "";
          } else {
            this.emailList.cron = this.triggerCron.cron;
            this.emailList.time_every = this.triggerCron.time_every;
            this.emailList.daytime = "";
            this.emailList.endcount = -1;
            this.emailList.time_setting = this.triggerCron;
            this.emailList.time_start = this.triggerCron.ader_cycleDayStart;
          }
        }

        if (this.smsList != undefined) {
          if (
            this.triggerSelected != "定期投放" &&
            this.triggerCron.picked === "週期性"
          ) {
            this.smsList.cron = "trigger";
            this.smsList.time_every = this.triggerCron.time_every;
            this.smsList.daytime = this.triggerCron.daytime;
            this.smsList.endcount = this.triggerCron.endCount2;
            this.smsList.time_setting = this.triggerCron;
            this.smsList.time_start = "";
          } else if (
            this.triggerSelected != "定期投放" &&
            this.triggerCron.picked === "一次性"
          ) {
            this.smsList.cron = "";
            this.smsList.time_every = 0;
            this.smsList.daytime = "";
            this.smsList.endcount = -1;
            this.smsList.time_setting = this.triggerCron;
            this.smsList.time_start = "";
          } else {
            this.smsList.cron = this.triggerCron.cron;
            this.smsList.time_every = this.triggerCron.time_every;
            this.smsList.daytime = "";
            this.smsList.endcount = -1;
            this.smsList.time_setting = this.triggerCron;
            this.smsList.time_start = this.triggerCron.ader_cycleDayStart;
          }
        }

        this.time_trigger = this.time_trigger_Selected;
        this.time_trigger_state = this.time_trigger_state_Selected;
        this.cycleTime_trigger = this.picked;
        this.cycleTimeinCycle = this.happenTime;
      }
      if (
        this.scriptDayStart_sure > this.cycleDayStart &&
        this.currentTrigger === "定期投放"
      ) {
        alert("定期投放的條件開始時間不能『早於』劇本開始時間");
        return;
      } else {
        this.offCanvasClick.hide();
      }

      this.tabState = "template";
      var someTabTriggerEl = document.querySelector(
        "#myTab li:nth-child(4) button"
      );
      var tab = new bootstrap.Tab(someTabTriggerEl);

      tab.show();
      console.log("this.triggerCron", this.triggerCron);
      console.log("this.emailList", this.emailList);
      console.log("this.smsList", this.smsList);
    },

    // For send data
    saveScript: async function (work) {
      this.finalList = [];
      console.log("this.testList2 1", this.finalList);
      if (this.template_email != "") {
        this.finalList.push(this.emailList);
      }
      console.log("this.testList2 2", this.finalList);
      if (this.template_sms != "") {
        this.finalList.push(this.smsList);
      }
      console.log("this.testList2 3", this.finalList);
      this.finalList = this.finalList
        .concat(this.testList)
        .concat(this.testList2);
      console.log("this.testList2 4", this.finalList);
      this.finalList = this.finalList.map((o) => {
        if (o.template_name != undefined) {
          return {
            action: o.action,
            event: o.event,
            is_yes: o.is_yes,
            ruleID: o.ruleID,
            subject: o.subject,
            template: o.template_name,
            time_after: 0.05,
            time_interval: 0.05,
            vice_ruleID: o.vice_ruleID,
            cron: o.cron,
            time_every: 0.01,
            endcount: o.endcount,
            daytime: o.daytime,
            time_setting: o.time_setting,
            time_start: o.time_start,
          };
        } else {
          return {
            action: o.action,
            event: o.event,
            is_yes: o.is_yes,
            ruleID: o.ruleID,
            subject: o.subject,
            template: o.template,
            time_after: 0.05,
            time_interval: 0.05,
            vice_ruleID: o.vice_ruleID,
            cron: o.cron,
            time_every: 0.01,
            endcount: o.endcount,
            time_setting: o.time_setting,
            time_start: o.time_start,
          };
        }
      });
      this.finalList.forEach((o) => {
        if (o.action === "傳送Email") {
          o.action = "寄信";
        }
        if (o.action === "傳送SMS") {
          o.action = "寄簡訊";
        }
        if (o.event === "購買後促銷") {
          o.event = "購買行銷";
        }
        if (o.event === "訂單確認") {
          o.event = "購買";
        }
      });

      console.log("this.finalList before saveScript", this.finalList);

      const ok = this.finalList.map((d) => {
        const arrvalues = Object.values(d);
        var ok2Event = true;
        if (this.currentEventYes != null || this.currentEventNo != null) {
          ok2Event = arrvalues.some((d) => {
            return (
              (String(d).includes("_1_1") && String(d).includes("ve")) ||
              (String(d).includes("_1_2") && String(d).includes("ve")) ||
              (String(d).includes("_2_1") && String(d).includes("ve")) ||
              (String(d).includes("_2_2") && String(d).includes("ve"))
            );
          });
        }
        console.log("ok2Event", ok2Event);
        return ok2Event;
      });
      const ok2 = this.finalList.map((d) => {
        const arrvalues = Object.values(d);
        var ok22Event = true;
        if (this.currentEventYes2 != null || this.currentEventNo2 != null) {
          ok22Event = arrvalues.some((d) => {
            return (
              (String(d).includes("_1_1") && String(d).includes("vs")) ||
              (String(d).includes("_1_2") && String(d).includes("vs")) ||
              (String(d).includes("_2_1") && String(d).includes("vs")) ||
              (String(d).includes("_2_2") && String(d).includes("vs"))
            );
          });
        }
        console.log("ok2Event", ok22Event);
        return ok22Event;
      });
      console.log("ok2", ok2);
      const okfor2event = ok.some((d) => d === true);
      const okfor22event = ok2.some((d) => d === true);
      console.log("okfor2event", okfor2event);

      const okok = this.finalList.map((d) => {
        const arrkey = Object.keys(d);
        const arrvalues = Object.values(d);
        return (
          arrkey.includes("action") &&
          arrkey.includes("template") &&
          arrkey.includes("subject") &&
          !arrvalues.includes(undefined)
        );
      });
      console.log("okok", okok);
      const okforkey_value = okok.every((d) => d === true);
      console.log("okforkey_value", okforkey_value);

      if (
        okfor2event === false ||
        okforkey_value === false ||
        okfor22event === false
      ) {
        alert("樹狀圖不完整");
      } else if (
        this.currentTrigger === "註冊" &&
        this.groupName[0] === "針對現有顧客名單"
      ) {
        alert(`客群為針對現有顧客名單時，觸發事件不能為"註冊"`);
      } else if (this.eventScriptIdFromSche === "") {
        alert("沒有選擇排程活動");
      } else if (
        (work === "2" || this.eventScriptHasEventFromSche === "2") &&
        work != "1"
      ) {
        if (
          (this.template_email != "" && this.email_subject === "") ||
          (this.yes_template != "" && this.yes_subject === "") ||
          (this.no_template != "" && this.no_subject === "") ||
          (this.yesyes_template != "" && this.yesyes_subject === "") ||
          (this.yesno_template != "" && this.yesno_subject === "") ||
          (this.noyes_template != "" && this.noyes_subject === "") ||
          (this.nono_template != "" && this.nono_subject === "")
        ) {
          console.log("no_template", [
            this.yes_template,
            this.yesyes_template,
            this.yesno_template,
            this.no_template,
            this.noyes_template,
            this.nono_template,
            this.template_email,
          ]);
          console.log("yes_subject", [
            this.yes_subject,
            this.yesyes_subject,
            this.yesno_subject,
            this.no_subject,
            this.noyes_subject,
            this.nono_subject,
            this.email_subject,
          ]);
          alert("有缺主旨");
          return;
        }

        console.log(
          "new Date(this.cycleDayStart+this.triggerCron.daytime )",
          new Date(`${this.cycleDayStart} ${this.triggerCron.daytime}`)
        );

        // if (
        //   new Date(`${this.cycleDayStart} ${this.triggerCron.daytime}`) <
        //   new Date()
        // ) {
        //   alert("定期投放開始時間不能『早於』現在時間");
        //   return;
        // }

        if (
          (this.currentTrigger != "定期投放" &&
            this.ader_triggerCron === "" &&
            this.cycleTime_trigger === "週期性") ||
          (this.ader_yes_triggerCron === "" &&
            this.cycleTime_yes != "一次性") ||
          (this.ader_yesyes_triggerCron === "" &&
            this.cycleTime_yesyes != "一次性") ||
          (this.ader_yesno_triggerCron === "" &&
            this.cycleTime_yesno != "一次性") ||
          (this.ader_no_triggerCron === "" && this.cycleTime_no != "一次性") ||
          (this.ader_noyes_triggerCron === "" &&
            this.cycleTime_noyes != "一次性") ||
          (this.ader_nono_triggerCron === "" &&
            this.cycleTime_nono != "一次性") ||
          (this.ader_yes_triggerCron2 === "" &&
            this.cycleTime_yes2 != "一次性") ||
          (this.ader_yesyes_triggerCron2 === "" &&
            this.cycleTime_yesyes2 != "一次性") ||
          (this.ader_yesno_triggerCron2 === "" &&
            this.cycleTime_yesno2 != "一次性") ||
          (this.ader_no_triggerCron2 === "" &&
            this.cycleTime_no2 != "一次性") ||
          (this.ader_noyes_triggerCron2 === "" &&
            this.cycleTime_noyes2 != "一次性") ||
          (this.ader_nono_triggerCron2 === "" &&
            this.cycleTime_nono2 != "一次性")
        ) {
          alert("週期性時間資料不完整");
          return;
        }
        this.beforeRunScript(work);
      } else {
        this.beforeRunScript(work);
      }
    },
    startRunSaveScriptApi: async function (work) {
      this.loading = true;
      console.log("儲存劇本");
      if (this.template_email != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_0";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.template_sms != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_0";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.yes_template != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.no_template != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.yesyes_template != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.yesno_template != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.noyes_template != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.nono_template != "") {
        const key = "ve_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.yes_template2 != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.no_template2 != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.yesyes_template2 != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_1";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.yesno_template2 != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_1_2";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.noyes_template2 != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_1";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          console.log("用CboxHtml");
          await this.postScriptTemplate();
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      if (this.nono_template2 != "") {
        const key = "vs_" + this.eventScheduleIdFromSche.slice(-8) + "_2_2";
        this.vice_id = key;
        await this.getScriptCboxBykey(key);
        if (this.noCbox!=true) {
          await this.postScriptTemplate();
          console.log("用CboxHtml");
        } else {
          await this.getTemplateByScriptkey(key);
        }
        await this.postScriptSchedule(key);
      }
      this.runSaveScriptApi(work);
    },
    openStopScriptModel() {
      const alertModal7 = new bootstrap.Modal(
        document.getElementById("alertModal7"),
        {
          keyboard: false,
        }
      );
      alertModal7.show();
    },
    stopScript: async function () {
      await this.saveScript("1");
    },
    // 確定啟用或儲存的最後一步 做好時間的選擇判斷和活動名稱
    openRunScriptModel() {
      if (this.goingToRunScript2) {
        if (this.scriptDayStart === "") {
          alert("需填寫劇本開始時間");
        } else if (this.scriptDayEnd === "" && this.scriptDayState === "自訂") {
          alert("需填寫劇本結束時間");
        } else {
          this.scriptDayStart_sure = this.scriptDayStart;
          this.scriptDayEnd_sure = this.scriptDayEnd;
          this.scriptDayState_sure = this.scriptDayState;
          if (this.scriptDayState === "永久") {
            if (this.script_title === "") {
              alert("需填寫活動名稱");
              return;
            }
            this.script_title_sure = this.script_title;
          } else {
            if (this.calendar_event === "請選擇活動") {
              alert("需填寫活動名稱");
              return;
            }
            this.script_title_sure = this.calendar_event;
          }
          if (
            this.scriptDayStart_sure > this.cycleDayStart &&
            this.currentTrigger === "定期投放"
          ) {
            alert("劇本開始時間不能『晚於』定期投放的條件開始時間");
            return;
          } else {
            this.scriptTimeModal.hide();
          }

          this.runScript();
        }
      } else if (this.goingToSaveScript) {
        console.log("123");
        this.scriptDayStart_sure = this.scriptDayStart;
        this.scriptDayEnd_sure = this.scriptDayEnd;
        this.scriptDayState_sure = this.scriptDayState;
        if (this.scriptDayState === "永久") {
          if (this.script_title === "") {
            alert("需填寫活動名稱");
            return;
          }
          this.script_title_sure = this.script_title;
        } else {
          if (this.calendar_event === "請選擇活動") {
            alert("需填寫活動名稱");
            return;
          }
          this.script_title_sure = this.calendar_event;
        }
        this.scriptTimeModal.hide();
        this.startRunSaveScriptApi(this.savework);
        return;
      }
      if (this.goingToRunScript2 != true && this.goingToSaveScript != true) {
        this.scriptDayStart_sure = this.scriptDayStart;
        this.scriptDayEnd_sure = this.scriptDayEnd;
        this.scriptDayState_sure = this.scriptDayState;
        if (this.scriptDayState === "永久") {
          if (this.script_title === "") {
            alert("需填寫活動名稱");
            return;
          }
          this.script_title_sure = this.script_title;
          console.log("this.script_title_sure", this.script_title_sure);
        } else {
          if (this.calendar_event === "請選擇活動") {
            alert("需填寫活動名稱");
            return;
          }
          this.script_title_sure = this.calendar_event;
        }
        this.scriptTimeModal.hide();
      }
    },
    runScript: async function () {
      await this.startRunSaveScriptApi("2");
    },
    beforeRunScript(work) {
      console.log(work);
      if (work === "2") {
        this.goingToRunScript2 = true;
        this.goingToSaveScript = false;
        this.scriptTimeSetting();
        this.savework = work;
      } else if (this.eventScriptHasEventFromSche === "2" && work != "1") {
        this.goingToRunScript2 = true;
        this.goingToSaveScript = false;
        this.scriptTimeSetting();
        this.savework = work;
      } else if (work == 1) {
        this.goingToSaveScript = true;
        this.goingToRunScript2 = false;
        this.startRunSaveScriptApi(work);
        this.savework = work;
      } else {
        this.goingToSaveScript = true;
        this.goingToRunScript2 = false;
        this.savework = work;
        this.scriptTimeSetting();
        // this.startRunSaveScriptApi(work);
      }
      console.log("this.savework", this.savework);
    },
    clearScript() {
      let deleteyes = confirm("刪除後即無法返回，是否確認刪除?");
      if (deleteyes) {
        this.deleteScriptApi();
        this.patchRunScriptApi("0");
      } else {
        return;
      }
    },
    // api
    runSaveScriptApi(work) {
      console.log(this.selectedgroupID);
      console.log(Object.prototype.toString.call(this.groupName));
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};
      if (this.scriptDayState_sure === "永久") {
        body = {
          tag: this.triggerBuy.tag,
          value: this.triggerBuy.value,
          flows: this.finalList,
          script_id: this.eventScriptIdFromSche,
          script_name: this.script_title_sure,
          label_id: this.selectedgroupID,
          label_name: this.groupName,
          email_sender: this.selectEmail,
          sms_sender: this.selectSms,
          end_time: this.scriptDayEnd_sure,
          start_time: this.scriptDayStart_sure,
          // start : start,
        };
      } else {
        body = {
          tag: this.triggerBuy.tag,
          value: this.triggerBuy.value,
          flows: this.finalList,
          script_id: this.eventScriptIdFromSche,
          script_name: this.calendar_eventID,
          label_id: this.selectedgroupID,
          label_name: this.groupName,
          email_sender: this.selectEmail,
          sms_sender: this.selectSms,
          end_time: this.scriptDayEnd_sure,
          start_time: this.scriptDayStart_sure,
          // start : start,
        };
      }

      allapi2
        .triggerScriptPost(params, body)
        .then(async(res) => {
          console.log("work", work);
          console.log("成功儲存劇本", res);
          if (work === "1") {
          await  this.patchRunScriptApi("1");
          } else if (work === "2") {
          await  this.patchRunScriptApi("2");
          } else if (this.eventScriptHasEventFromSche === "0") {
           await this.patchRunScriptApi("1");
            // this.alertModal3 = new bootstrap.Modal(
            //   document.getElementById("alertModal3"),
            //   {
            //     keyboard: false,
            //   }
            // );
            // this.alertModal3.show();
            // setTimeout(() => {
            //   this.alertModal3.hide();
            // }, 400);
          }
          this.hasnotSave = false;
          this.getScriptById();
          this.editList = false;
          this.finalList = [];
        })
        .catch(async (err) => {
          console.log("連線有誤");
          console.log(err);
          if (err.status === 502) {
            console.log(
              "this.eventScriptHasEventFromSche",
              this.eventScriptHasEventFromSche
            );
            await this.patchTriggerScriptApi(work);
            // if (this.eventScriptHasEventFromSche === "2") {
            //   this.patchRunScriptApi("2");
            // }else if(this.eventScriptHasEventFromSche === "1"){
            //   this.patchRunScriptApi("1");
            // }
          } else {
            alert("資料連線有誤，劇本建立失敗，請聯繫客服人員");
          }
          this.finalList = [];
        });
    },
    patchTriggerScriptApi(work) {
      this.loading = true;
      // var start = false;
      // if (this.scriptDayStart === new Date().toISOString().slice(0, 10)) {
      //   start = true;
      // }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.eventScriptIdFromSche,
      };
      let body = {};
      if (this.scriptDayState_sure === "永久") {
        body = {
          tag: this.triggerBuy.tag,
          value: this.triggerBuy.value,
          flows: this.finalList,
          script_name: this.script_title_sure,
          label_id: this.selectedgroupID,
          label_name: this.groupName,
          email_sender: this.selectEmail,
          sms_sender: this.selectSms,
          end_time: this.scriptDayEnd_sure,
          start_time: this.scriptDayStart_sure,
          // start : start,
        };
      } else {
        body = {
          tag: this.triggerBuy.tag,
          value: this.triggerBuy.value,
          flows: this.finalList,
          script_name: this.calendar_eventID,
          label_id: this.selectedgroupID,
          label_name: this.groupName,
          email_sender: this.selectEmail,
          sms_sender: this.selectSms,
          end_time: this.scriptDayEnd_sure,
          start_time: this.scriptDayStart_sure,
          // start : start,
        };
      }

      allapi2
        .triggerScriptScriptIdPatch(params, body)
        .then(async(res) => {
          console.log("work", work);
          console.log("成功修改劇本", res);
          if (work === "1") {
           await this.patchRunScriptApi("1");
          } else if (work === "2") {
           await this.patchRunScriptApi("2");
          } else if (this.eventScriptHasEventFromSche === "2") {
           await this.patchRunScriptApi("2");
          } else if (this.eventScriptHasEventFromSche === "1") {
           await this.patchRunScriptApi("1");
          }
          // this.alertModal3 = new bootstrap.Modal(
          //   document.getElementById("alertModal3"),
          //   {
          //     keyboard: false,
          //   }
          // );
          // this.alertModal3.show();
          this.hasnotSave = false;
          this.getScriptById();
          this.editList = false;
          this.finalList = [];
        })
        .catch((err) => {
          this.loading = false;
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤，劇本建立失敗，請聯繫客服人員");
        });
    },
    patchRunScriptApi(work) {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.eventScriptIdFromSche,
      };
      const body = {
        state: work,
      };

      allapi2
        .triggerScriptUpdateHasEventScriptIdPost(params, body)
        .then((res) => {
          console.log("成功更改劇本狀態為" + work, res);
          this.eventScriptHasEventFromSche = work;
          sessionStorage.setItem("setResScriptHasEvent", work);
          if (work === "2" && this.savework == 2) {
            // this.alertModal3.hide();
            if (
              this.scriptDayStart_sure <= new Date().toISOString().slice(0, 10)
            ) {
              sessionStorage.setItem("prework", "work");
            } else {
              sessionStorage.setItem("prework", "prework");
            }
            this.$router.push({
              name: "ScriptTable",
            });
          } else {
            this.alertModal3 = new bootstrap.Modal(
              document.getElementById("alertModal3"),
              {
                keyboard: false,
              }
            );
            this.alertModal3.show();
            this.loading = false;
          }
          this.hasnotSave = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("劇本狀態連線有誤");
        });
    },
    getScriptById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.eventScriptIdFromSche,
      };

      await allapi2
        .triggerScriptScriptIdGet_1(params)
        .then((res) => {
          console.log("成功根據排成獲得劇本內容", res);
          this.version = `_ver_${res.data.message.version+1}`
          console.log("this.version", this.version);
          if (res.data.message.start) {
            this.scriptHasStart = true;
          }

          this.scriptDayStart_sure = res.data.message.start_time;
          this.scriptDayStart = res.data.message.start_time;

          if (res.data.message.end_time != "") {
            this.scriptDayEnd_sure = res.data.message.end_time;
            this.scriptDayEnd = res.data.message.end_time;
          } else {
            this.scriptDayEnd_sure = "";
            this.scriptDayEnd = "";
          }
          const data = this.events.find(
            (d) => d.id === res.data.message.script_name
          );
          console.log("行事曆有無此活動名稱", data);
          if (data === undefined) {
            this.script_title_sure = res.data.message.script_name;
            this.script_title = res.data.message.script_name;
            this.scriptDayState = "永久";
            if (this.scriptDayStart != null) {
              this.scriptDayState_sure = "永久";
            } else {
              this.scriptDayState_sure = "";
            }
          } else {
            this.script_title = "";
            this.script_title_sure = data.title;
            this.calendar_event = data.title;
            this.calendar_eventID = data.id;
            this.scriptDayState = "自訂";
            this.scriptDayState_sure = "自訂";
          }
          this.triggerBuy = {
            tag: res.data.message.tag,
            value: res.data.message.value,
          };

          res.data.message.flows.forEach((o) => {
            if (o.action === "寄信") {
              o.action = "傳送Email";
            }
            if (o.action === "寄簡訊") {
              o.action = "傳送SMS";
            }
            if (o.event === "購買行銷") {
              o.event = "購買後促銷";
            }
            if (o.event === "購買") {
              o.event = "訂單確認";
            }
            o.template_name = o.template;
            delete o.template;
          });

          this.testList2 = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) != "0" && d.vice_ruleID.charAt(1) === "e"
            );
          });
          this.testList = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) != "0" && d.vice_ruleID.charAt(1) === "s"
            );
          });
          this.emailList = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) === "0" &&
              d.vice_ruleID.charAt(1) === "e"
            );
          })[0];
          this.smsList = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) === "0" &&
              d.vice_ruleID.charAt(1) === "s"
            );
          })[0];
          // this.loading = false;
          console.log("this.testList2", this.testList2);
          console.log("this.testList", this.testList);
          console.log("this.emailList", this.emailList);
          console.log("this.smsList", this.smsList);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get資料連線有誤將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
        });

      // this.testList2.forEach((o) => {
      //   return (o.template_name = o.template);
      // });
      // this.testList.forEach((o) => {
      //   return (o.template_name = o.template);
      // });
      // if (this.emailList != undefined) {
      //   this.emailList.template_name = this.emailList.template;
      //   delete this.emailList.template;
      // }
      // if (this.smsList != undefined) {
      //   this.smsList.template_name = this.smsList.template;
      //   delete this.smsList.template;
      // }
      console.log("this.testList", this.testList);

      this.testList2.map((d) => {
        if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 1 &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after = d.time_after / 24;
            this.yes_time_after_state = "天後";
          } else {
            this.yes_time_after = d.time_after;
            this.yes_time_after_state = "小時後";
          }
          this.yes_action = d.action;
          this.yes_subject = d.subject;
          this.yes_template = d.template_name;
          this.yes_triggerCron = d.time_setting;
          this.cycleTime_yes = d.time_setting.picked;
          this.ader_yes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 0 &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after = d.time_after / 24;
            this.no_time_after_state = "天後";
          } else {
            this.no_time_after = d.time_after;
            this.no_time_after_state = "小時後";
          }
          this.no_action = d.action;
          this.no_subject = d.subject;
          this.no_template = d.template_name;
          this.no_triggerCron = d.time_setting;
          this.cycleTime_no = d.time_setting.picked;
          this.ader_no_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_1_1") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after = d.time_after / 24;
            this.yesyes_time_after_state = "天後";
          } else {
            this.yesyes_time_after = d.time_after;
            this.yesyes_time_after_state = "小時後";
          }
          this.yesyes_action = d.action;
          this.yesyes_subject = d.subject;
          this.yesyes_template = d.template_name;
          this.yesyes_triggerCron = d.time_setting;
          this.cycleTime_yesyes = d.time_setting.picked;
          this.ader_yesyes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_1_2") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after = d.time_after / 24;
            this.yesno_time_after_state = "天後";
          } else {
            this.yesno_time_after = d.time_after;
            this.yesno_time_after_state = "小時後";
          }
          this.yesno_action = d.action;
          this.yesno_subject = d.subject;
          this.yesno_template = d.template_name;
          this.yesno_triggerCron = d.time_setting;
          this.cycleTime_yesno = d.time_setting.picked;
          this.ader_yesno_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_2_1") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after = d.time_after / 24;
            this.noyes_time_after_state = "天後";
          } else {
            this.noyes_time_after = d.time_after;
            this.noyes_time_after_state = "小時後";
          }
          this.noyes_action = d.action;
          this.noyes_subject = d.subject;
          this.noyes_template = d.template_name;
          this.noyes_triggerCron = d.time_setting;
          this.cycleTime_noyes = d.time_setting.picked;
          this.ader_noyes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_2_2") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after = d.time_after / 24;
            this.nono_time_after_state = "天後";
          } else {
            this.nono_time_after = d.time_after;
            this.nono_time_after_state = "小時後";
          }
          this.nono_action = d.action;
          this.nono_template = d.template_name;
          this.nono_subject = d.subject;
          this.nono_triggerCron = d.time_setting;
          this.cycleTime_nono = d.time_setting.picked;
          this.ader_nono_triggerCron = "ok";
        }
      });
      this.testList.map((d) => {
        if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 1 &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEvent2 = d.event;
          if (d.time_interval > 23) {
            this.time_interval2 = d.time_interval / 24;
            this.time_state2 = "天內";
          } else {
            this.time_interval2 = d.time_interval;
            this.time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after2 = d.time_after / 24;
            this.yes_time_after_state2 = "天後";
          } else {
            this.yes_time_after2 = d.time_after;
            this.yes_time_after_state2 = "小時後";
          }
          this.yes_action2 = d.action;
          this.yes_template2 = d.template_name;
          this.yes_triggerCron2 = d.time_setting;
          this.cycleTime_yes2 = d.time_setting.picked;
          this.ader_yes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 0 &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEvent2 = d.event;
          if (d.time_interval > 23) {
            this.time_interval2 = d.time_interval / 24;
            this.time_state2 = "天內";
          } else {
            this.time_interval2 = d.time_interval;
            this.time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after2 = d.time_after / 24;
            this.no_time_after_state2 = "天後";
          } else {
            this.no_time_after2 = d.time_after;
            this.no_time_after_state2 = "小時後";
          }
          this.no_action2 = d.action;
          this.no_template2 = d.template_name;
          this.no_triggerCron2 = d.time_setting;
          this.cycleTime_no2 = d.time_setting.picked;
          this.ader_no_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_1_1") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventYes2 = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval2 = d.time_interval / 24;
            this.yes_time_state2 = "天內";
          } else {
            this.yes_time_interval2 = d.time_interval;
            this.yes_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after2 = d.time_after / 24;
            this.yesyes_time_after_state2 = "天後";
          } else {
            this.yesyes_time_after2 = d.time_after;
            this.yesyes_time_after_state2 = "小時後";
          }
          this.yesyes_action2 = d.action;
          this.yesyes_template2 = d.template_name;
          this.yesyes_triggerCron2 = d.time_setting;
          this.cycleTime_yesyes2 = d.time_setting.picked;
          this.ader_yesyes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_1_2") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventYes2 = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval2 = d.time_interval / 24;
            this.yes_time_state2 = "天內";
          } else {
            this.yes_time_interval2 = d.time_interval;
            this.yes_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after2 = d.time_after / 24;
            this.yesno_time_after_state2 = "天後";
          } else {
            this.yesno_time_after2 = d.time_after;
            this.yesno_time_after_state2 = "小時後";
          }
          this.yesno_action2 = d.action;
          this.yesno_template2 = d.template_name;
          this.yesno_triggerCron2 = d.time_setting;
          this.cycleTime_yesno2 = d.time_setting.picked;
          this.ader_yesno_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_2_1") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventNo2 = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval2 = d.time_interval / 24;
            this.no_time_state2 = "天內";
          } else {
            this.no_time_interval2 = d.time_interval;
            this.no_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after2 = d.time_after / 24;
            this.noyes_time_after_state2 = "天後";
          } else {
            this.noyes_time_after2 = d.time_after;
            this.noyes_time_after_state2 = "小時後";
          }
          this.noyes_action2 = d.action;
          this.noyes_template2 = d.template_name;
          this.noyes_triggerCron2 = d.time_setting;
          this.cycleTime_noyes2 = d.time_setting.picked;
          this.ader_noyes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_2_2") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventNo2 = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval2 = d.time_interval / 24;
            this.no_time_state2 = "天內";
          } else {
            this.no_time_interval2 = d.time_interval;
            this.no_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after2 = d.time_after / 24;
            this.nono_time_after_state2 = "天後";
          } else {
            this.nono_time_after2 = d.time_after;
            this.nono_time_after_state2 = "小時後";
          }
          this.nono_action2 = d.action;
          this.nono_template2 = d.template_name;
          this.nono_triggerCron2 = d.time_setting;
          this.cycleTime_nono2 = d.time_setting.picked;
          this.ader_nono_triggerCron2 = "ok";
        }
      });
      if (this.emailList != undefined) {
        this.currentTrigger = this.emailList.event;
        if (this.emailList.time_after > 23) {
          this.time_trigger = this.emailList.time_after / 24;
          this.time_trigger_state = "天後";
        } else if (this.emailList.time_after === 0) {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else {
          this.time_trigger = this.emailList.time_after;
          this.time_trigger_state = "小時後";
        }
        if (this.emailList.event === "購物車未結") {
          this.time_trigger = this.emailList.time_after / 24;
          this.time_trigger_state = "天內";
        }
        this.email_subject = this.emailList.subject;
        this.template_email = this.emailList.template_name;
        this.triggerCron = this.emailList.time_setting;
        this.ader_triggerCron = "ok";
        if (this.emailList.event != "定期投放") {
          this.cycleTime_trigger = this.emailList.time_setting.picked;
        } else {
          this.cycleTimeinCycle = this.emailList.time_setting.happenTime;
          this.cycleDayStart = this.emailList.time_setting.cycleDayStart;
        }
      }

      if (this.smsList != undefined) {
        this.currentTrigger = this.smsList.event;

        if (this.smsList.time_after > 23) {
          this.time_trigger = this.smsList.time_after / 24;
          this.time_trigger_state = "天後";
        } else if (this.smsList.time_after === 0) {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else {
          this.time_trigger = this.smsList.time_after;
          this.time_trigger_state = "小時後";
        }
        if (this.smsList.event === "購物車未結") {
          this.time_trigger = this.smsList.time_after / 24;
          this.time_trigger_state = "天內";
        }
        this.sms_subject = this.smsList.subject;
        this.template_sms = this.smsList.template_name;
        this.triggerCron = this.smsList.time_setting;
        this.ader_triggerCron = "ok";
        if (this.smsList.event != "定期投放") {
          this.cycleTime_trigger = this.smsList.time_setting.picked;
        } else {
          this.cycleTimeinCycle = this.smsList.time_setting.happenTime;
          this.cycleDayStart = this.smsList.time_setting.cycleDayStart;
        }
      }
      this.editList = false;
      console.log("this.editList", this.editList);
    },
    deleteScriptApi() {
      console.log("this.eventScriptIdFromSche", this.eventScriptIdFromSche);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.eventScriptIdFromSche,
      };
      allapi2
        .triggerScriptScriptIdDelete(params)
        .then((res) => {
          console.log("成功刪除劇本", res);
          const alertModal5 = new bootstrap.Modal(
            document.getElementById("alertModal5"),
            {
              keyboard: false,
            }
          );
          alertModal5.show();
          this.updateWho = "trigger";
          this.deleteTriggerTag();
          this.editList = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤" + err);
        });
    },
    beforeunloadcall(e) {
      e = e || window.event;
      // e.returnValue = '确定要关闭窗口吗？'
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "确定要关闭窗口吗？";
        console.log("123");
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "whatever";
    },
    gosche() {
      this.$router.push({
        name: "MarketingSchedule",
      });
    },
    zoomout() {
      this.z = this.z - 0.1;
      if (this.z < 0.4) {
        this.z = 0.4;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomin() {
      this.z = this.z + 0.1;
      if (this.z > 1) {
        this.z = 1;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomreset() {
      this.z = 1;
      this.zoom = { transform: `scale(1)` };
    },
  },
};
</script>


<style scoped>
/* AfterTriggerScript */
.tab-div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
}
.script-tip {
  position: absolute;
  font-size: 16px;
  top: 80px;
  right: 30px;
  z-index: 99;
}
.zoom {
  position: absolute;
  font-size: 16px;
  top: 875px;
  left: 86%;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: transparent;
  z-index: 99;
}
.nowork {
  display: none;
}
.notwork {
  position: absolute;
  font-size: 16px;
  top: 80px;
  left: 20px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #eeeeee;
  z-index: 99;
}
.work {
  position: absolute;
  font-size: 16px;
  top: 80px;
  left: 20px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #c6e0c9;
  z-index: 99;
}
/* 拖曳物件樣式 */
/* .bedrag {
  min-height: 100px;
  width: 200px;
  margin: 20px auto;
  background: #eee;
} */
/* .bedrag p {
  min-height: 2em;
  margin-top: 10px;
  background: #abcded;
} */
/* .drog-move {
  transition: transform 1s;
} */

/* 一般樣式 */
.select-label-bottom-span {
  width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.triggerBuy-value {
  position: relative;
  top: -4px;
  font-size: 12px;
  width: 60px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.script-header {
  font-size: 24px;
  font-weight: bold;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.nav-tabs .nav-link {
  background-color: white;
  height: 40px;
  color: #575757;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.nav-tabs .nav-link.active {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.shodow-box-afterTriggerScript {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
}
.contain-left-trigger-script {
  height: 582px;
}
.contain-left-all {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 622px;
  margin-bottom: 25px;
}
.moveTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
.timeTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
.time-input {
  border: none;
  border-bottom: 1px solid black;
  background-color: #eaf3f4;
  width: 50px;
  outline: none;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.contain-right-top {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 736px;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}
.tree-div {
  width: 100%;
}
.tree-tag {
  border-radius: 10px;
  background-color: #eaf3f4;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
}
.template-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 10px;
  background-color: #eaf3f4;
  padding: 5px;
  text-align: center;
  width: 90px;
  margin: 0 auto;
}
.blue-tag {
  background-color: #96c5d7;
  color: white;
  border-radius: 10px;
  padding: 13px 19px 0 19px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.blue-tag2 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.blue-tag3 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding: 12px 25px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.blue-tag4 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding-top: 10px;
  margin: 0 auto;
  width: 120px;
  height: 105px;
  cursor: pointer;
}
.purple-tag {
  background-color: #baabe7;
  color: white;
  border-radius: 10px;
  padding: 13px 0px 3px 0px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.purple-tag2 {
  background-color: #baabe7;
  border-radius: 10px;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.signup-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/signup.svg");
}
.shopcar-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/shopcar.svg");
}
.Order-done {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/Order-done.svg");
}
.buy-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/buy.svg");
}
.cycleTime-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/cycleTime.svg");
}
.mail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/openmail.svg");
}
.click-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clicklink.svg");
}
.change-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/change.svg");
}
.hourglass-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/hourglass.svg");
}
.clock-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clock.svg");
}
.cycleTime2-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/cycleTime2.svg");
}
.sendmail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendmail.svg");
}
.sendsms-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendsms.svg");
}
.yes-tag {
  background-color: #8bc19b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
}
.no-tag {
  background-color: #ff8b8b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
}
.time-tag {
  background-color: #fc9f66;
  border-radius: 10px;
  color: white;
  padding-top: 5px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.time-tag2 {
  background-color: #fc9f66;
  border-radius: 10px;
  color: white;
  padding-top: 5px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag2 {
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 13px 15px 10px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag3 {
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 0px 20px 0px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.bot-left-left-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #ff998b, #ff614d);
  color: white;
}
.bot-left-btn {
  border-radius: 10px;
  border: none;
  background-color: #eaf3f4;
}
.bot-right-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.floating-button {
  position: fixed;
  bottom: 0;
  /* top: 50%; */
  left: 45.6%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  z-index: 10;
  display: flex;
  width: 8rem;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.floating-button2 {
  left: 50%;
  z-index: 10;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
}
.select-style {
  border-radius: 10px;
  text-align: center;
  height: 40px;
  border: 1px solid #c4c4c4;
}
.btn-basic {
  border: none;
  border-radius: 10px;
  width: 14rem;
  height: 40px;
}
.modal-btn-close {
  color: #fff;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border: none;
  width: 10vw;
}

.btn-save {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-group-div {
  background-color: #eaf3f4;
  width: 68%;
  border-radius: 10px;
}
.button-color2 {
  width: 50%;
  background-color: #eaf3f4;
  border-radius: 10px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -70px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.file-span {
  display: inline-block;
  background-color: #eaf3f4;
  line-height: 40px;
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clear-img-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.clear-file-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.delete-btn {
  position: absolute;
  cursor: pointer;
  top: 20px;
  left: 630px;
}
.delete-btn2 {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 1500px;
}
.group-div {
  position: absolute;
  right: 5px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.trigger-channel-btn {
  border: none;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.todo-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.basic-btn {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}
.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.contain-bottom {
  border: 1px solid black;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  height: 210px;
}
.search-input {
  border-radius: 5px;
  background-color: #eaf3f4;
  border: none;
  text-indent: 30px;
  width: 13rem;
  height: 37px;
}
.searchImg {
  position: relative;
  top: -2px;
  left: 24px;
}
.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 10rem;
  padding: 30px;
  border-radius: 25px;
  width: 330px;
  word-break: break-all;
  /* overflow-wrap: anywhere; */
  white-space: pre-wrap;
}
:deep(.dp-custom-input-triggerscript) {
  text-align: center;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 6px 12px;
}
/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35%;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 5px;
  left: 4px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  top: 5px;
  left: 0px;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #e0dada;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #71afb6;
}
.child-popout-inAfterTriggerScript-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: 40px;
  text-align: left;
  top: -575px;
  width: 152px;
  z-index: 99;
}
.container__arrow-inAfterTriggerScript-first {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  height: 0;
  left: 104.2%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(270deg);
  width: 0;
  z-index: 10;
}
.auto-complicate-div-AfterTriggerScript {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  z-index: 99;
}
.child-popout-inAfterTriggerScript-second {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -410px;
  text-align: left;
  top: -42px;
  width: 400px;
  z-index: 99;
}
@media (min-width: 1500px) {
  .zoom {
    position: absolute;
    font-size: 16px;
    top: 875px;
    left: 88%;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    z-index: 99;
  }
  .nowork {
    position: absolute;
    font-size: 16px;
    top: 80px;
    left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    z-index: 99;
  }
  .notwork {
    position: absolute;
    font-size: 16px;
    top: 80px;
    left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #eeeeee;
    z-index: 99;
  }
  .work {
    position: absolute;
    font-size: 16px;
    top: 80px;
    left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #c6e0c9;
    z-index: 99;
  }
}
</style>