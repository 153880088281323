<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- unlayerModal -->
    <!-- expEmailModal -->
    <EmailPreview :subject="subject" :html="html"></EmailPreview>

    <div v-if="this.viceID === '123'">
      請重新從前面<a href="/EmailMarketingTable">投放紀錄一覽表</a
      >裡的連結進入這頁面
    </div>
    <!-- main -->
    <div v-else>
      <div class="d-flex justify-content-between">
        <span class="title-span fs-medium"
          ><img
            src="../assets/back-arrow.png"
            @click="$router.go(-1)"
            style="cursor: pointer"
          />
          &nbsp; &nbsp;投放事件成效</span
        >
        <button class="showDetails-btn" @click="showDetailsTemp()">
          查看Email資訊
        </button>
      </div>
      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12 mt-3 row-section">
          <div class="result-first-row row" style="padding: 23px">
            <div class="row justify-content-between px-1">
              <div class="col-4 align-items-center d-flex p-0">
                <span class="topTitle mr-5 fs-medium" style="color: #c4c4c4"
                  >發送客群</span
                >

                <span class="titleText fs-medium"
                  >&nbsp; &nbsp;{{ getlabelId.includeLabelNames.toString()
                      .replace(/,/g, "、") }}</span
                >
                <span
                  style="
                    font-size: 14px;
                    font-weight: 100;
                    color: #c4c4c4;
                    text-decoration: underline;
                    cursor: pointer;
                    width: 25%;
                  "
                  @click="openExcludeModal()"
                  v-if="getlabelId.excludeLabelNames[0] != undefined"
                  >瀏覽完整名單
                </span>
              </div>
              <div class="col-4 align-items-center d-flex p-0">
                <span class="topTitle mr-5 fs-medium" style="color: #c4c4c4"
                  >活動名稱</span
                >

                <span class="titleText fs-medium"
                  >&nbsp; &nbsp;{{ getsaveEventData }}</span
                >
              </div>
              <div class="col-4 align-items-center d-flex p-0">
                <span class="topTitle mr-5 fs-medium" style="color: #c4c4c4"
                  >活動事件</span
                >
                <span class="titleText fs-medium"
                  >&nbsp; &nbsp; {{ geteventTable }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12">
          <div class="row result-second-row">
            <div
              class="
                col-xl-8 col-xxl-9
                d-flex
                flex-wrap
                space-between
                mid-content
              "
            >
              <div class="col-12" style="color: #71afb6; font-size: 18px">
                投放活動成效
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">開信率</p>
                <div class="d-flex align-items-center">
                  <p class="titleText">
                    {{ Number(apiEventAllData.點擊率).toFixed(2) }}
                  </p>
                  <p class="mid-span">&nbsp; &nbsp; (%)</p>
                  <p class="mid-span" style="color: #f9c357">
                    &nbsp; &nbsp;({{ apiEventAllData.開信數 }}次)
                  </p>
                </div>
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">點擊率</p>
                <div class="d-flex align-items-center">
                  <p class="titleText">
                    {{ Number(apiEventAllData.點擊率).toFixed(2) }}
                  </p>
                  <p class="mid-span">&nbsp; &nbsp; (%)</p>
                  <p class="mid-span" style="color: #f9c357">
                    &nbsp; &nbsp;({{ apiEventAllData.點擊數 }}次)
                  </p>
                </div>
              </div>
              <div class="col-4">
                <!-- <p class="titleTag">花費成本</p>
              <div class="d-flex align-items-center">
                <p class="titleText">{{ rateData.花費成本 }}</p>
                <span class="mid-span">($)</span>
              </div> -->
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">送達率</p>
                <div class="d-flex align-items-center flex-wrap">
                  <p class="titleText">
                    {{ Number(apiEventAllData.送達率).toFixed(2) }}
                  </p>
                  <p class="mid-span">&nbsp; &nbsp; (%)</p>
                </div>
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">投訴率</p>
                <div class="d-flex align-items-center">
                  <p class="titleText">
                    {{ Number(apiEventAllData.投訴率).toFixed(2) }}
                  </p>
                  <p class="mid-span">&nbsp; &nbsp; (%)</p>
                </div>
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">退訂率</p>
                <div class="d-flex align-items-center flex-wrap">
                  <p class="titleText">
                    {{ Number(apiEventAllData.退訂率).toFixed(2) }}
                  </p>
                  <p class="mid-span">&nbsp; &nbsp; (%)</p>
                </div>
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">轉換率</p>
                <div class="d-flex align-items-center">
                  <p class="titleText">
                    {{ Number(apiEventAllData.轉換率).toFixed(2) }}
                  </p>
                  <p class="mid-span">&nbsp; &nbsp; (%)</p>
                </div>
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">ROAS</p>
                <div class="d-flex align-items-center">
                  <p class="titleText">
                    {{ Number(apiEventAllData.ROAS).toFixed(2) }}
                  </p>
                  <p class="mid-span">(%)</p>
                </div>
              </div>
              <div class="col-4">
                <p class="titleTag" style="color: #c4c4c4">花費成本</p>
                <div class="d-flex align-items-center">
                  <p class="title is-5">{{ apiEventAllData.花費成本 }}</p>
                  <p class="mid-span">($)</p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-xxl-3">
              <div class="text-center" style="color: #c4c4c4">
                點擊/打開/未打開
              </div>
              <apexchart
                class="mt-5"
                type="donut"
                height="350"
                :options="chartPieOptions"
                :series="seriesPie"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <!-- <button @click="DemoPieChart">123</button> -->
      <div class="d-flex justify-content-xl-center p-0 pr-5">
        <div class="col-12">
          <div class="result-second-row">
            <div class="d-flex row-section justify-content-between">
              <div class="col-6 mb-5">
                <p class="titleTag is-5 mt-1" style="color: #c4c4c4">
                  購買推薦商品比例
                </p>
                <apexchart
                  class="mt-5"
                  type="donut"
                  height="350"
                  :options="chartPieOptions2"
                  :series="seriesPie2"
                ></apexchart>
              </div>
              <div class="col-6">
                <p class="titleTag is-5 mt-1" style="color: #c4c4c4">
                  購買商品比例
                </p>
                <apexchart
                  class="mt-5"
                  type="donut"
                  height="350"
                  :options="chartPieOptions3"
                  :series="seriesPie3"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-xl-center p-0 mt-4">
        <div class="col-12">
          <div class="row bar-div">
            <p class="titleTag" style="color: #71afb6">
              時序分析
              <BtnInfoBox content="此圖表可依此觀察該次行銷活動所獲取成效之數量的時間點。" direction="right"></BtnInfoBox>

            </p>
            <apexchart
              type="bar"
              height="300"
              :options="stackBarOptions"
              :series="stackBarSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "EventPerformance",
  components: {
    apexchart: VueApexCharts,
    EmailPreview,
    BtnInfoBox,
  },
  data() {
    return {
      loading: false,
      series: [],

      seriesPie: [],
      chartPieOptions: {},
      seriesPie2: [],
      chartPieOptions2: {},
      seriesPie3: [],
      chartPieOptions3: {},

      stackBarSeries: [],
      stackBarOptions: {},

      viceID: "V0000002",
      vice_ruleID: "",

      apiEventAllData: {},
      apiEventPie: [],
      apiEventPiePurchase: [],

      apiEventStack: [],
      isShow: false,
      getsaveEventData: "",
      geteventTable: "",
      getlabelId: {
          includeLabelNames: [],
          excludeLabelNames: [],
      },

      scheTodos: "",

      templateA: {},
      templateB: {},

      url: "",
      url2: "",
      url3: "",
      url4: "",

      findScriptName: "",

      url8081: "",
      subject: "",
      html: "",
    };
  },
  created: async function () {
    this.loading = true;
    this.vice_ruleID = sessionStorage.getItem("vice_ruleID");
    const newjson = JSON.parse(sessionStorage.getItem("labelFromPerform"))
    this.getlabelId = newjson;
    this.getsaveEventData = sessionStorage.getItem("saveEventData");
    this.geteventTable = sessionStorage.getItem("eventTable");
    if (this.vice_ruleID === undefined) {
      this.viceID = "123";
      this.loading = false;
    } else {
      this.viceID = this.vice_ruleID;
      await Promise.all([
        this.postEventNumberData(),
        this.postEventPie(),
        this.postEventStackData(),
        this.postEventPurchasePie(),
      ]);

      this.DemoPieChart();
      this.DemoPieChart2();
      this.DemoPieChart3();
      this.stackBarChart();
      this.getScheAPi();
      this.loading = false;

      const group = sessionStorage.getItem("getlabelTitle");
      const token = sessionStorage.getItem("access_token");
      const aa = undefined;
      const bb = undefined;
      this.url8081 = `http://localhost:8081/?name=${aa}&key=${bb}&token=${token}&group=${group}`;
    }
  },
  methods: {
    openExcludeModal() {
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    getScheAPi: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .scheduleGet(params)
        .then((res) => {
          console.log("3 item", res);
          this.scheTodos = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },

    showDetailsTemp: async function () {
      this.getTemplateByScriptkey();
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },

    show() {
      this.isShow = !this.isShow;
    },
    getTemplateByScriptkey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: this.vice_ruleID,
      };

      await allapi2
        .scriptTempScheduleIdGet(params)
        .then((res) => {
          console.log(res);
          this.html = res.data.data;
          this.html = this.html
            .replace(
              "{{p1}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
            )
            .replace(
              "{{p2}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
            )
            .replace(
              "{{p3}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
            )
            .replace(
              "{{p4}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
            ).replace("{{tracking}}", "");

          // if (this.emailChannel===false) {
          //   this.newSubsec = res.data.data.json;
          // }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    postEventNumberData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.viceID,
      };
      await allapi3.emailEventRateGet(params).then((res) => {
        console.log("eventall", res);
        this.apiEventAllData = res.data.Data;
      });
    },

    postEventPie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.viceID,
      };
      await allapi3.emailEventPieGet(params).then((res) => {
        console.log("eventpie", res);
        this.apiEventPie = res.data.Data;
        console.log("eventpiedata", this.apiEventPie);
      });
    },

    postEventStackData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.viceID,
      };
      await allapi3.emailEventTrendGet(params).then((res) => {
        console.log("stackdata", res);
        this.apiEventStack = res.data.Data;
      });
    },
    postEventPurchasePie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        vice_ruleID: this.viceID,
      };
      await allapi3.emailEventPurchaseGet(params).then((res) => {
        console.log("eventpuchaepie", res);
        this.apiEventPiePurchase = res.data.Data;
      });
    },

    DemoPieChart() {
      // console.log("numberpiecv", this.apiEventPie);
      (this.seriesPie = [
        parseInt(this.apiEventPie.未寄出),
        parseInt(this.apiEventPie.未開啟),
        parseInt(this.apiEventPie.開啟),
        parseInt(this.apiEventPie.點擊),
      ]),
        (this.chartPieOptions = {
          chart: {
            height: 300,
            type: "donut",
          },
          colors: ["#F7C4A5", "#71AFB6", "#F9C357", "#FC9F66"],
          labels: Object.keys(this.apiEventPie),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //  floating: true,
          },
        });
    },
    DemoPieChart2() {
      (this.seriesPie2 = Object.values(this.apiEventPiePurchase)
        .slice(0, 2)
        .map((d) => {
          return parseInt(d);
        })),
        (this.chartPieOptions2 = {
          chart: {
            height: 300,
            type: "donut",
          },
          colors: ["#F9C357", "#71AFB6", "#F9C357", "#FC9F66"],
          labels: Object.keys(this.apiEventPiePurchase).slice(0, 2),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //  floating: true,
          },
        });
    },
    DemoPieChart3() {
      (this.seriesPie3 = Object.values(this.apiEventPiePurchase)
        .slice(2, 4)
        .map((d) => {
          return parseInt(d);
        })),
        (this.chartPieOptions3 = {
          chart: {
            height: 300,
            type: "donut",
          },
          colors: ["#F9C357", "#71AFB6", "#F9C357", "#FC9F66"],
          labels: Object.keys(this.apiEventPiePurchase).slice(2, 4),
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontWeight: "bold",
            fontSize: "16px",

            //  floating: true,
          },
        });
    },
    stackBarChart() {
      console.log("numberpiecv", this.apiEventStack);
      (this.stackBarSeries = [
        {
          name: "轉換次數",
          data: this.apiEventStack.purchase,
        },
        {
          name: "點擊次數",
          data: this.apiEventStack.click,
        },
        {
          name: "開信次數",
          data: this.apiEventStack.open,
        },
      ]),
        (this.stackBarOptions = {
          chart: {
            type: "bar",
            height: 300,
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
            zoom: {
              enabled: true,
            },
          },
          // responsive: [
          //   {
          //     // breakpoint: 480,
          //     options: {
          //       legend: {
          //         position: "bottom",
          //         offsetX: -10,
          //         offsetY: 0,
          //       },
          //     },
          //   },
          // ],
          colors: ["#FC9F66", "#F9C357", "#96C5D7"],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {
            // type: "datetime",
            categories: this.apiEventStack.time,
            title: {
              text: "日期",
            },
          },
          yaxis: {
            title: {
              text: "次數",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
            },
          },

          legend: {
            position: "bottom",
            offsetY: 10,
            offsetX: -10,
          },
          fill: {
            opacity: 1,
          },
        });
    },
  },
};
</script>

<style scoped>
/* EventPerformance */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  padding: 50px;
}
.titleTag {
  font-size: 18px;
}
.result-first-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 80px;
}
.result-second-row {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
}
.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  /* margin-top: 15px; */
  height: 380px;
}
.abtesting-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 734px;
}
.ab-title {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 20px 24px;
  border-radius: 15px;
  margin-top: 15px;
  height: 56px;
}
.child-popout12 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -46px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
@media (max-width: 1400px) {
  .fs-small {
    font-size: 15px;
  }
  .fs-medium {
    font-size: 20px;
  }
}
</style>