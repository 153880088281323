<template>
  <div class="container home">
    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- unlayerModal -->
    <!-- expEmailModal -->
    <EmailPreview :subject="subject" :html="html"></EmailPreview>

    <!-- sms exp modal -->
    <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>

    <div class="d-flex justify-content-between">
      <span class="title-span fs-medium">
        <img
          src="../assets/back-arrow.png"
          @click="$router.go(-1)"
          style="cursor: pointer"
        />
        &nbsp; &nbsp; 自動化劇本成效
        <BtnInfoBox content="若曾更新執行中的自動化劇本，系統會自動將成效資料重新紀錄，並最多只保留十個版本(包含資料)，您可在下方進行版本刪除。" direction="right"></BtnInfoBox>

      </span>

      <Dropdown
        style="width: 300px"
        :mxHeight="200"
        :valueArr="this.scriptTimeRange"
        :selectValue="scriptTimeRangeIndex"
        :changingSelected="'scriptTimeRangeIndex'"
        v-on:selectValueByDrop="selectValueByDrop"
      />
    </div>

    <div class="d-flex justify-content-xl-center p-0 pr-5 mb-4">
      <div class="col-12 mt-3 shadow-box">
        <div class="result-first-row d-flex" style="padding: 23px">
          <div class="col-4 align-items-center d-flex">
            <span class="fs-small" style="color: #c4c4c4;min-width:125px">觸發事件名稱</span>
            <span class="fs-medium">
              {{ triggerApiData.觸發事件名稱 }}
            </span>
          </div>
          <div class="col-4 align-items-center d-flex">
            <span class="fs-small" style="color: #c4c4c4;min-width:100px">活動名稱</span>
            <span class="fs-medium" style="overflow-wrap: anywhere;">
              {{ triggerApiData.script_name }}
            </span>
          </div>
          <div class="col-4 align-items-center justify-content-start d-flex">
            <span class="fs-small" style="color: #c4c4c4;min-width:100px">發送客群</span>
            <span class="fs-medium">
              {{
                triggerApiData.發送客群.includeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <p style="position: absolute; top: 20px; left: 20px; z-index: 99">
        <span style="color: #71afb6"> 劇本成效報告 </span>
        <BtnInfoBox content="灰色數值為每步驟人數，點擊分析ICON或者原有節點，以便查看更多訊息。" direction="right"></BtnInfoBox>

      </p>
      <SeeTriggerScript2
        v-bind:scriptID="version_id"
        v-bind:groupName="groupName"
        v-bind:addadd="addadd"
        v-on:showUnlayer="showUnlayer"
        v-on:showSMSexp="showSMSexp"
        :key="forceRerender"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import VueApexCharts from "vue3-apexcharts";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import SeeTriggerScript2 from "../components/SeeTriggerScript2.vue";
import { onMounted } from "vue";
import { reactive, computed, watchEffect, watch, ref } from "vue";
import Dropdown from "../components/Dropdown.vue";
import SmsPreview from "../components/SmsPreview.vue";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "TriggerScriptPerformance",
  components: {
    apexchart: VueApexCharts,
    DataTable,
    Column,
    Calendar,
    DatePicker,
    SeeTriggerScript2,
    Dropdown,
    SmsPreview,
    EmailPreview,
    BtnInfoBox,
  },

  data() {
    return {
      scriptTimeRange: [1, 2, 3],
      scriptTimeRangeIndex: "",
      allVersionData: [],

      TriggerScriptPerformancShow: false,
      TriggerScriptPerformancShow2: false,
      forceRerender: 0,
      idFromTable: "",
      groupName: "",
      subject: "",
      html: "",
      addadd: 0,

      openEmailTeleport: false,

      channelTab: "Email",
      hourdaysTab: "前48小時",

      isShow: false,
      loading: false,

      triggerApiData: {
        發送客群: {
          includeLabelNames: [],
          excludeLabelNames: [],
        },
        活動名稱: "",
      },
      recentRecord: "",

      newHtml: "",
      newText: "",
      countText: "",
      resSubsec: "",
      exampleModal2: "",

      triggerApiUpperTable: [],
      triggerApiBottomTable: [],
      lineData: {},

      fullurlArr: [],
      Parr: [],

      events: [],
      version_id: "",
    };
  },

  created: async function () {
    this.loading = true;
    this.idFromTable = sessionStorage.getItem("bringScriptID");
    console.log(this.idFromTable);
    await this.getScriptTimeRange();
    this.getTriggerScriptByVersionkey();
    setTimeout(() => {
      this.addadd = Math.random(this.addadd);
    }, 0);
  },

  methods: {
    selectValueByDrop(selectValue, changingSelected, index) {
      this.forceRerender = this.forceRerender + 1;
      this[changingSelected] = selectValue;
      // this.getTriggerScriptByVersionkey();
      setTimeout(() => {
        this.version_id = this.allVersionData[index].script_id;
        this.addadd = this.addadd + 1;
      }, 0);
    },
    show() {
      this.isShow = !this.isShow;
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    highlightUrl() {
      console.log(this.resSubsec);
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.resSubsec);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index] +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }

      console.log("this.countText", this.countText);
    },

    // 拿所有版本
    getScriptTimeRange: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.idFromTable,
      };

      await allapi3
        .triggerScriptVersionScriptIdGet(params)
        .then((res) => {
          console.log("拿所有版本", res);
          this.allVersionData = res.data.Data;
          this.scriptTimeRange = res.data.Data.map((d, index) => {
            return (
              `${d.版本}` +
              "　" +
              `${moment(res.data.Data[index].begin)
                .toISOString()
                .slice(0, 10)}` +
              "　" +
              `${moment(res.data.Data[index].begin)
                .toISOString()
                .slice(11, 16)} `
            );
          });
          this.scriptTimeRangeIndex = this.scriptTimeRange[0];
          this.version_id = res.data.Data[0].script_id;
          console.log("this.allVersionData", this.allVersionData);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿該版本的劇本樹
    getTriggerScriptByVersionkey: async function () {
      this.openEmailTeleport = true;

      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.version_id,
      };

      await allapi3
        .triggerScriptScriptIdGet(params)
        .then((res) => {
          console.log("version_id all tree info", res);
          this.triggerApiData = res.data.Data;

          const data = this.events.find(
            (e) => e.id === this.triggerApiData.活動名稱
          );
          if (data != undefined) {
            this.triggerApiData.script_name = data.title;
            this.triggerApiData.realScript_name = data.id;
          } else {
            this.triggerApiData.script_name = res.data.Data.活動名稱;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    // 調換活動名稱
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 顯示模擬模板
    showUnlayer: function (childHtml, childSub) {
      this.html = childHtml;
      this.subject = childSub;
      this.exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      this.exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    showSMSexp: function (childValue) {
      console.log(childValue);
      this.newText = childValue;
      this.newText = this.newText
        .replace(/{id}/g, this.randomString(6))
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      this.resSubsec = childValue;
      this.resSubsec = this.resSubsec
        .replace(/{id}/g, this.randomString(6))
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      this.countText = this.newText;

      this.highlightUrl();
      this.exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
  },
};
</script>

<style>
.line-chart {
  width: 100%;
  height: 300px;
}

.modal-teleport-email {
  background-color: #fff;
  position: fixed;
  z-index: 7;
  top: 10%;
  left: 30%;
  width: 60%;
  /* height:600px; */
  margin-left: -150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.modal-bg-email {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 6;
  height: 200vh;
  overflow: hidden;
}

.template-button {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  color: white;
  border-radius: 8px;
  height: 40px;

  width: 7rem;
}

.custom-1st-td {
  background-color: #eaf3f4;
}

th.custom-1st-td {
  background-color: #fff;
}
.child-popout-inTriggerScriptPerformance-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -310px;
  text-align: left;
  top: -52px;
  width: 300px;
  z-index: 99;
}
.child-popout-inTriggerScriptPerformance-second {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -290px;
  text-align: left;
  top: -42px;
  width: 280px;
  z-index: 99;
}
.shadow-box{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 10px;
}
</style>
