<template>
  <div
    class="select-container"
    style="width: 100%; position: relative"
    ref="target"
  >
    <div
      class="d-flex justify-content-end align-items-center"
      style="
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 0 14px;
        height: 40px;
      "
      @click="autoShow = !autoShow"
    >
      <span class="flex-fill text-start" v-if="selectValue[0] != undefined">
        選擇{{ selectValue.length }}項
      </span>
      <span class="flex-fill text-start" v-else-if="valueArr[0] === undefined">
        目前該標籤條件沒有選項
      </span>
      <span class="flex-fill text-start" style="color: #575757" v-else>
        {{ holder }}
      </span>
      <img src="../../assets/selection-arrow.png" alt="searchImg" />
    </div>
    <div
      class="auto-complicate-div"
      style="width: 100%"
      v-show="autoShow"
      @mouseenter="autoShow = true"
    >
      <p
        class="m-0 text-start p-2"
        style="position: relative; border-bottom: 1px solid #c4c4c4"
        v-if="typeof valueArr === 'object' && valueArr.length > 12"
      >
        <input
          type="text"
          class="search-input-customRFM-checkbox"
          placeholder="搜尋名稱"
          v-model="searchInput"
        />
        <img
          src="../../assets/search-black.png"
          alt=""
          style="position: absolute; left: 10px; top: 12px"
        />
      </p>
      <div style="max-height: 200px; overflow-y: auto">
        <p
          v-for="(value, index) in searchedValueArr"
          :key="index"
          class="text-start m-0 auto-complicate-p d-flex align-items-center"
          style="padding: 5px 14px"
          @click="clickPtagCheck(value)"
        >
          <span
            class="d-flex align-items-center"
            style="width: 32px"
            v-if="
              Object.prototype.toString.call(valueArr) === '[object Object]'
            "
          >
            <input
              class="me-3"
              type="checkbox"
              :id="index"
              :value="index"
              v-model="checkedValues"
            />
          </span>
          <span
            class="d-flex align-items-center"
            style="width: 32px"
            v-else-if="value != '- -'"
          >
            <input
              class="me-3"
              type="checkbox"
              :id="value"
              :value="value"
              v-model="checkedValues"
            />
          </span>

          <label
            class="word-overflow"
            v-tooltip.top="{
              value: index,
              class: 'tipInCustomRFM',
            }"
            v-if="
              Object.prototype.toString.call(valueArr) === '[object Object]'
            "
            >{{ index }}</label
          >
          <label
            class="word-overflow"
            v-tooltip.top="{
              value: value,
              class: 'tipInCustomRFM',
            }"
            v-else
            >{{ value }}</label
          >
          <span style="width: 10px">&nbsp;</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  name: "DropdownCheckbox",
  components: {},
  props: {
    valueArr: [Array, Object],
    selectValue: [Array, Object, String],
    changingSelected: String,
    holder: String,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return {
      target,
      autoShow,
    };
  },
  data() {
    return {
      checkedValues: this.$props.selectValue,
      searchInput: "",
    };
  },
  created() {},
  watch: {
    selectValue(val) {
      this.checkedValues = val;
    },
    checkedValues() {
      console.log("this.checkedValues", this.checkedValues);
      this.childClick(this.checkedValues);
    },
    valueArr() {
      this.searchInput = "";
    },
  },
  computed: {
    searchedValueArr: function () {
      if (this.searchInput === "") {
        return this.$props.valueArr;
      } else {
        const arr = this.$props.valueArr.filter((d) => {
          return d.includes(this.searchInput);
        });
        return arr;
      }
    },
  },
  methods: {
    childClick(checkedValues, index) {
      console.log("123");
      this.$emit(
        "selectValueByDrop",
        checkedValues,
        this.$props.changingSelected,
        index
      );
      // this.checkedValues.push(value)
      // this.autoShow = false;
    },
    clickPtagCheck(value) {
      if (this.checkedValues.includes(value)) {
        this.checkedValues = this.checkedValues.filter(
          (d) => !d.includes(value)
        );
      } else {
        this.checkedValues.push(value);
      }
      this.childClick(this.checkedValues);
    },
  },
};
</script>

<style>
/* DropdownCheckbox */
.auto-complicate-div {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  z-index: 99;
}
.select-container {
  color: #575757;
  text-align: center;
  cursor: default;
}
.auto-complicate-p {
  color: #575757;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.tipInCustomRFM {
  opacity: 0.7 !important;
  padding: 3px 6px !important;
  background-color: #575757;
  border: 1px solid #575757;
  border-radius: 10px;
  color: white;
  /* height: 30px; */
  /* line-height: 30px; */
}
.word-overflow {
  /* display: inline-block; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search-input-customRFM-checkbox {
  /* position: relative; */
  vertical-align: bottom;
  width: 100%;
  border: none;
  outline: none;
  text-indent: 30px;
}
.search-input-customRFM-checkbox::placeholder {
  font-size: 18px;
}
</style>