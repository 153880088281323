<template>
  <div v-if="notshow==0" class="d-flex justify-content-center align-items-center"  style="height:100%">
    <span style="color:#c4c4c4"> 該功能為正式付費使用 </span>
  </div>

  <div class="container home" v-else>
    <div class="d-flex mb-4">
      <span class="title-span fs-medium d-flex align-items-center">
        LINE 點擊追蹤功能 (強烈推薦)
      </span>
    </div>
    <div class="contain-top">
      <div class="mb-3">產生追蹤網址</div>
      <div class="d-flex">
        <span style="color: #71afb6; width: 11%">投放編號 </span>
        <input
          class="input-style ps-3"
          style="width: 89%"
          type="text"
          placeholder="輸入欲追蹤轉換成效的廣告活動名稱"
          v-model="title"
        />
      </div>
      <div class="mb-4 d-flex">
        <span style="color: #71afb6; width: 11%"> </span>
        <span style="color: #71afb6; width: 89%"
          >注意：建議操作人員進行記錄，將此輸入的編號對應為該投放的內容。</span
        >
      </div>
      <div class="mb-4 d-flex align-items-center">
        <span style="color: #71afb6; width: 11%">網址</span>
        <input
          class="input-style ps-3"
          type="text"
          placeholder="輸入廣告欲追蹤網址"
          v-model="fullUrl"
        />
        <button class="btn" @click="createShortUrlApi">產生</button>
      </div>
      <div class="mb-4 d-flex align-items-center">
        <span style="color: #71afb6; width: 11%">追蹤網址</span>
        <input
          class="input-style ps-3"
          type="text"
          placeholder="請複製該網址到廣告欲埋入的連結裡"
          v-model="shortUrl"
          disabled
        />
        <button
          class="btn"
          type="button"
          v-clipboard:copy="shortUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          複製
        </button>
      </div>
    </div>
    <div class="contain-bottom mt-4">
      <div class="d-flex align-items-center">
        <span style="color: #71afb6; width: 11%">功能說明</span>
        <span style="width: 89%"
          >透過追蹤網址設計，行銷人員能知道廣告訊息所產生後續的站外成效，清楚了解不同的廣告所帶來之效益。另外，亦能追蹤點擊連結的使用者之站外行為並辨識該使用者，以做為後續受眾包與再行銷的精準優化。</span
        >
      </div>
    </div>
    <div style="position: relative">
      <div
        class="ms-5"
        style="
          color: #71afb6;
          position: absolute;
          height: 40px;
          line-height: 40px;
        "
      >
        追蹤連結紀錄表
      </div>
      <DataTable
        :value="table"
        class="header-table mt-3"
        :paginator="true"
        :rows="5"
        paginatorPosition="top"
        :pageLinkSize="3"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="目前 {currentPage} ，共 {totalPages}"
      >
        <Column field="name" header="廣告名稱" :sortable="true">
          <template #body="slotProps2">
            <div :class="stockClass(slotProps2.data)">
              <span class="ms-2">
                {{ slotProps2.data.name }}
              </span>
            </div>
          </template>
        </Column>
        <Column field="origin_url" header="網址" :sortable="true"></Column>
        <Column field="url" header="追蹤網址" :sortable="true"></Column>
        <Column field="" header="">
          <template #body="slotProps">
            <button
              style="border: none; background-color: white"
              @click="deleteList(slotProps.data)"
            >
              <img src="../assets/green-delete.svg" alt="" />
            </button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { allapi2 } from "../../public/static2/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  setup() {
    const message = "Hello Clipborad!";
    const onCopy = (e) => {
      alert("You just copied: " + e.text);
    };
    const onError = (e) => {
      alert("Failed to copy texts");
    };

    return { message, onCopy, onError };
  },

  name: "FbShortUrl",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      notshow: false,
      title: "",
      fullUrl: "",
      shortUrl: "",

      table: [
        {
          name: "test1svwsf",
          origin_url: "https://firstcdp.retailing-data.net/Login",
          ruleID: "F_2df2c0",
          time: "Wed, 23 Mar 2022 17:46:29 GMT",
          url: "https://reurl.cc/Go29VD",
        },
      ],
    };
  },
  created() {
   if (sessionStorage.getItem("lv") == "0") {
      this.notshow = 0;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "1") {
      this.notshow = 1;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "2") {
      this.notshow = 2;
      sessionStorage.removeItem("lv");
    } 
    this.getTableApi();
  },
  methods: {
    stockClass(data) {
      console.log(data);
      return [
        {
          hascolor: data.name != "",
        },
      ];
    },
    createShortUrlApi() {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        name: this.title,
        url: this.fullUrl,
      };
      allapi2
        .audienceLineShortenUrlPost(params, body)
        .then((res) => {
          console.log(res);
          if (res.data.message === "name already exist") {
            alert("與之前的廣告名稱命名重複");
          } else {
            this.shortUrl = res.data.url;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
        });
    },
    getTableApi() {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      allapi2
        .audienceLineListUrlGet(params)
        .then((res) => {
          console.log(res);
          this.table = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
        });
    },
    deleteList(data) {
      console.log("data", data);
      const id = data.ruleID;
      this.deleteApi(id);
    },
    deleteApi(id) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        id: id,
      };
      allapi2
        .audienceLineDeleteIdDelete(params)
        .then((res) => {
          console.log(res);
          this.getTableApi();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
        });
    },
  },
};
</script>

<style scoped>
/* LineShortUrl */
.contain-top {
  padding: 20px 40px 25px 30px;
 box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  height: 292px;
}
.contain-bottom {
  padding: 27px 40px 27px 30px;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  height: 105px;
}
.input-style {
  width: 73.8%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
}
input[type="text"]::placeholder {
  text-align: left;
}
.btn {
  width: 14.5%;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  height: 40px;
  border-radius: 10px;
  margin-left: 2%;
}
.hascolor {
  background-color: #eaf3f4;
}
</style>