<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <!-- show explainAIModal -->
    <div
      class="modal fade"
      id="explainAIModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 1044px">
        <div class="modal-content" style="width: 1044px">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 0px; position: relative"
          >
            <img
              src="../assets/已Group-explainAI.svg"
              alt=""
              style="position: relative; top: -7px; left: -8px; width: 1057px"
            />
            <div
              style="
                width: 100%;
                height: 20px;
                position: absolute;
                background-color: white;
                bottom: 4px;
                border-radius: 18px;
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- show eventTableModal -->
    <div
      class="modal fade"
      id="eventTableModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 78vw">
        <div class="modal-content" style="width: 100%">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 0px; position: relative"
          >
            <ScheduleTable
              :selectedTable="this.selectedTable"
              :eventID="
                this?.customRFM_tableValue[this.nowIndex]?.more_option?.event
              "
              :key="this.refreshModal"
              @changeTable="changeTable"
              @closeEventTable="closeEventTable"
              @saveEventID="saveEventID"
              v-if="this.table_state[this.nowIndex] === '排程'"
            />
            <TriggerTable
              :selectedTable="this.selectedTable"
              :eventID="
                this?.customRFM_tableValue[this.nowIndex]?.more_option?.event
              "
              :key="this.refreshModal"
              @changeTable="changeTable"
              @closeEventTable="closeEventTable"
              @saveEventID="saveEventID"
              v-if="this.table_state[this.nowIndex] === '自動化劇本'"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- roadModal alert -->
    <div
      class="modal fade"
      id="roadModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>請選擇投放管道。</p>
            <div id="channel" class="d-flex flex-column align-items-center">
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'email' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'email'"
              >
                Email
              </button>
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'sms' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'sms'"
              >
                SMS
              </button>
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'line' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'line'"
              >
                Line
              </button>
              <button
                class="road-button-email my-2"
                :class="selectChannel === 'fb' ? 'road-button-email-focus' : ''"
                v-on:click="this.selectChannel = 'fb'"
              >
                FB Ads
              </button>

              <p class="road-button my-2">Google Ads</p>

              <p class="road-button my-2">域動clickforce</p>
              <div>
                <button
                  class="road-button-confirm mt-2"
                  data-bs-dismiss="modal"
                  @click="shareData()"
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- alert添加成功Modal -->
    <div
      class="modal fade"
      id="addSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">建立成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- alert刪除成功Modal -->
    <div
      class="modal fade"
      id="deleteSuccessModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">刪除成功</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="mb-4">
      <span style="font-size: 22px; font-weight: 700"> 個性化分群 </span>
      <BtnInfoBox
        content="透過不同維度進行條件篩選，包括基本訊息、消費行為、網站行為等，適合針對特定活動或人群時使用。另外，亦提供聯集與交集設定，以及獨家AI價值標籤，有效掌握精準受眾。"
        direction="right"
      ></BtnInfoBox>
    </div>
    <!-- 分群名單 -->
    <div class="customRFM-shadowBox">
      <FavoriteList
        v-bind:todosAdv="todosAdv"
        v-bind:selectedID="selectedID"
        @toggle-value="selectValue"
        @clearall="clearall"
        @refresh-todos="refreshtodo"
        @remove-todos="removetodo"
        @copy-todo="copytodo"
        @search="search"
      />
    </div>
    <!-- 從全部會員名單裡篩選出 -->
    <div class="mt-4 customRFM-shadowBox">
      <div
        class="d-flex justify-content-between align-items-center"
        style="
          background-color: #71afb6;
          height: 50px;
          padding: 0 26px 0 40px;
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
        "
      >
        <span style="color: white">從全部會員名單裡篩選出</span>
        <img
          src="../assets/downArrowWhite.svg"
          alt="downArrowWhite"
          style="width: 30px; cursor: pointer"
          v-if="hideTable === false"
          @click="changeHideTable()"
        />
        <img
          src="../assets/upArrowWhite.svg"
          alt="downArrowWhite"
          style="width: 15px; margin-right: 7.5px; cursor: pointer"
          v-if="hideTable === true"
          @click="changeHideTable()"
        />
      </div>
      <transition name="slide">
        <div :style="hideTable2 ? 'overflow: hidden' : ''" v-if="!hideTable">
          <!-- min-height: 126px; -->
          <div
            style="
              padding: 25px 30px 25px 36px;
              border-bottom: 1px solid #c4c4c4;
            "
            class="d-flex flex-wrap"
            v-for="(obj, index) in customRFM_tableValue"
            :key="index"
          >
            <div style="width: 93%">
              <div class="d-flex mb-3" v-if="index != 0">
                <span style="line-height: 40px; color: #71afb6" class="me-3"
                  >條件設定</span
                >
                <div style="width: 18%">
                  <DropdownAll
                    style="width: 100%"
                    :mxHeight="200"
                    :valueArr="this.mode"
                    :selectValue="obj.mode"
                    :changingSelected="index + ''"
                    :holder="'選擇條件'"
                    v-on:selectValueByDrop="selectValueByDrop_mode"
                  />
                </div>
              </div>
              <!-- selection div -->
              <div class="d-flex justify-content-start flex-wrap">
                <!-- 標籤屬性 -->
                <div style="width: 28%; margin-right: 2.64%">
                  <DropdownType
                    style="width: 100%"
                    :mxHeight="200"
                    :valueArr="this.tagType"
                    :selectValue="obj.type"
                    :changingSelected="index + ''"
                    :holder="'標籤屬性'"
                    v-on:selectValueByDrop="selectValueByDrop_tagType"
                  />
                </div>
                <!-- 網站選項 -->
                <div
                  style="width: 17%; margin-right: 1.69%"
                  v-if="this.allTypeWebState[obj.type] === 'web'"
                >
                  <DropdownAll
                    style="width: 100%"
                    :mxHeight="200"
                    :valueArr="this.websites"
                    :selectValue="obj.web"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_web"
                  />
                </div>
                <div
                  style="width: 17%; margin-right: 1.69%"
                  v-else-if="this.allTypeWebState[obj.type] === 'overall'"
                >
                  <DropdownAll
                    style="width: 100%"
                    :mxHeight="200"
                    :valueArr="this.overall"
                    :selectValue="obj.web"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_web"
                  />
                </div>
                <div
                  style="width: 17%; margin-right: 1.69%"
                  v-else-if="this.allTypeWebState[obj.type] === 'channel'"
                >
                  <DropdownAll
                    style="width: 100%"
                    :mxHeight="200"
                    :valueArr="this.channels"
                    :selectValue="obj.web"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_web"
                  />
                </div>
                <div
                  style="width: 17%; margin-right: 1.69%"
                  v-else-if="
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽' &&
                    obj.type != '價值分群' &&
                    obj.type != ''
                  "
                >
                  <DropdownAll
                    style="width: 100%"
                    :mxHeight="200"
                    :valueArr="this.customWeb[obj.type]"
                    :selectValue="obj.web"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_web"
                  />
                </div>
                <!-- 標籤 -->
                <div
                  style="margin-right: 1.69%"
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:24.5%'
                      : 'width:28%'
                  "
                  v-if="
                    obj.type != '' &&
                    obj.web != '' &&
                    obj.tag != '價值分群' &&
                    obj.web != 'SMS'
                  "
                >
                  <DropdownTypeAI
                    style="width: 100%"
                    :mxHeight="200"
                    :holder="'條件類型'"
                    :type="obj.type"
                    :valueArr="
                      this.allTypeWebState[obj.type + '_' + obj.web] === 'noweb'
                        ? this.allKindTag[obj.type + '_' + obj.web]
                        : this.allKindTag[obj.type]
                    "
                    :selectValue="obj.tag"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_tag"
                    v-on:openExplainAIModal="openExplainAIModal"
                  />
                </div>
                <div
                  style="margin-right: 1.69%"
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:24.5%'
                      : 'width:28%'
                  "
                  v-if="
                    obj.type != '' &&
                    obj.web != '' &&
                    obj.tag != '價值分群' &&
                    obj.web === 'SMS'
                  "
                >
                  <DropdownTypeAI
                    style="width: 100%"
                    :mxHeight="200"
                    :holder="'條件類型'"
                    :type="obj.type"
                    :valueArr="
                      this.allKindTag[obj.type].filter((d) => d != '有開信過')
                    "
                    :selectValue="obj.tag"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_tag"
                    v-on:openExplainAIModal="openExplainAIModal"
                  />
                </div>
                <!-- 標籤數值 [] -->
                <!-- value 在loading -->
                <div
                  v-if="valueLoading === obj.tag && obj.tag != ''"
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20.5%'
                      : 'width:28%'
                  "
                >
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                      height: 40px;
                    "
                  >
                    <div
                      class="
                        flex-fill
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <div
                        class="spinner-border"
                        style="width: 1.6rem; height: 1.6rem"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <img
                      src="../assets/selection-arrow.png"
                      alt="selection-arrow"
                      style="margin-right: 14px"
                    />
                  </div>
                </div>
                <!-- value 會是 arr -->
                <div
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20.5%'
                      : 'width:28%'
                  "
                  v-else-if="typeof this.allTagValue[obj.tag] === 'object'"
                >
                  <DropdownCheckbox
                    style="width: 100%"
                    :holder="'選擇'"
                    :valueArr="this.allTagValue[obj.tag]"
                    :selectValue="obj.value"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_Checkbox"
                  />
                </div>
                <!-- value costrange -->
                <div
                  class="d-flex"
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:23%'
                      : 'width:28%'
                  "
                  v-else-if="
                    typeof this.allTagValue[obj.tag] === 'string' &&
                    this.allTagValue[obj.tag].includes('costrange-')
                  "
                >
                  <DropdownAll
                    style="width: 90px"
                    :mxHeight="200"
                    :valueArr="this.moreOrlessForCost2"
                    :selectValue="obj.value.moreOrless"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="
                      selectValueByDrop_moreOrlessForCost2
                    "
                  />
                  <input
                    type="number"
                    class="count-input-customRFM mx-2"
                    :value="obj.value.cost"
                    @input="updateCost2($event, index)"
                    v-if="
                      obj.value.moreOrless === '大於' ||
                      obj.value.moreOrless === '小於'
                    "
                  />
                  <span
                    style="line-height: 40px"
                    v-if="
                      obj.value.moreOrless === '大於' ||
                      obj.value.moreOrless === '小於'
                    "
                    >{{ this.allTagValue[obj.tag].split("-")[1] }}</span
                  >
                </div>
                <!-- value 會是 range normal -->
                <div
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20.5%'
                      : 'width:28%'
                  "
                  v-else-if="
                    typeof this.allTagValue[obj.tag] === 'string' &&
                    this.allTagValue[obj.tag].includes('range-') &&
                    this.allTagValue[obj.tag].split('-')[1] != 'date'
                  "
                >
                  <input
                    type="number"
                    class="range-input-customRFM"
                    style="width: 55px"
                    :value="obj.value.low"
                    @input="updateCustomTableRange($event, index, 'low')"
                  />
                  <span style="margin-left: 5px">{{
                    this.allTagValue[obj.tag].split("-")[1]
                  }}</span>
                  <span style="margin: 0 5px">-</span>
                  <input
                    type="number"
                    class="range-input-customRFM"
                    style="width: 55px"
                    :value="obj.value.high"
                    @input="updateCustomTableRange($event, index, 'high')"
                  />
                  <span style="margin-left: 5px">{{
                    this.allTagValue[obj.tag].split("-")[1]
                  }}</span>
                  <div
                    style="color: red; font-size: 12px; margin-left: 5px"
                    v-if="obj.value.low > obj.value.high"
                  >
                    左邊不能大於右邊
                  </div>
                </div>
                <!-- value 會是 range date -->
                <div
                  class="d-flex align-items-center justify-content-between"
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20.5%'
                      : 'width:28%'
                  "
                  v-else-if="
                    typeof this.allTagValue[obj.tag] === 'string' &&
                    this.allTagValue[obj.tag].includes('range-') &&
                    this.allTagValue[obj.tag].split('-')[1] === 'date'
                  "
                >
                  <DatePicker
                    v-model="rangeday"
                    :max-date="new Date()"
                    @dayclick="updateRangeday(index)"
                    is-range
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="flex justify-center items-center">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="ps-2"
                          style="
                            width: 45%;
                            height: 40px;
                            border-radius: 10px;
                            border: 1px solid #c4c4c4;
                          "
                        />
                        <span style="margin: 0 5px">-</span>
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="ps-2"
                          style="
                            width: 45%;
                            height: 40px;
                            border-radius: 10px;
                            border: 1px solid #c4c4c4;
                          "
                        />
                      </div>
                    </template>
                  </DatePicker>
                </div>
                <!-- value 會是 count -->
                <div
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20.5%'
                      : 'width:28%'
                  "
                  v-else-if="this.allTagValue[obj.tag] === 'count'"
                ></div>
                <!-- value 價值分群 -->
                <div
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20%'
                      : 'width:28%'
                  "
                  v-else-if="obj.type === '價值分群'"
                >
                  <DropdownCheckbox
                    style="width: 100%"
                    :holder="'選擇'"
                    :valueArr="this.allTagValue[obj.tag]"
                    :selectValue="obj.value"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_Checkbox"
                  />
                </div>
                <!-- tag是點擊事件名稱  value 要有兩個框 並且放到下面 -->
                <div
                  class="d-flex justify-content-between mt-3"
                  style="width: 50%"
                  v-else-if="this.allTagValue[obj.tag] === 'click'"
                >
                  <div style="width: 48%">
                    <DropdownAll
                      style="width: 100%"
                      :holder="'選擇類別'"
                      :mxHeight="200"
                      :valueArr="this.clickPage"
                      :selectValue="
                        Object.keys(obj.value)[0] != undefined
                          ? Object.keys(obj.value)[0]
                          : ''
                      "
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_clickPage"
                    />
                  </div>
                  <div v-if="valueLoading === 'click'" style="width: 48%">
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style="
                        border: 1px solid #c4c4c4;
                        border-radius: 10px;
                        height: 40px;
                      "
                    >
                      <div
                        class="
                          flex-fill
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                      >
                        <div
                          class="spinner-border"
                          style="width: 1.6rem; height: 1.6rem"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <img
                        src="../assets/selection-arrow.png"
                        alt="selection-arrow"
                        style="margin-right: 14px"
                      />
                    </div>
                  </div>
                  <div
                    style="width: 48%"
                    v-if="
                      Object.keys(obj.value)[0] != undefined &&
                      valueLoading == ''
                    "
                  >
                    <DropdownCheckbox
                      style="width: 100%"
                      :holder="'- -'"
                      :valueArr="this.clickValue"
                      :selectValue="obj.value[Object.keys(obj.value)[0]]"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_clickValue"
                    />
                  </div>
                </div>
                <!-- value 要CALL API -->
                <div
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:20.5%'
                      : 'width:28%'
                  "
                  v-else-if="obj.tag != ''"
                >
                  <!-- @click="callValueApi(this.allTagValue[obj.tag], obj.web, obj.tag)" -->
                  <DropdownCheckbox
                    style="width: 100%"
                    :holder="'選擇'"
                    :valueArr="this.apiTagValue[obj.tag]"
                    :selectValue="obj.value"
                    :changingSelected="index + ''"
                    v-on:selectValueByDrop="selectValueByDrop_Checkbox"
                  />
                </div>

                <!-- value costrange && value moreOrless 區間-->
                <div class="mt-3" v-if="obj.value.moreOrless === '區間'">
                  <div class="d-flex">
                    <span style="line-height: 40px">介於</span>
                    <input
                      type="number"
                      class="count-input-customRFM mx-2"
                      :value="obj.value.low"
                      @input="updateLowCost2($event, index)"
                    />
                    <span style="line-height: 40px"
                      >{{ this.allTagValue[obj.tag].split("-")[1] }} -</span
                    >
                    <input
                      type="number"
                      class="count-input-customRFM mx-2"
                      :value="obj.value.high"
                      @input="updateHighCost2($event, index)"
                    />
                    <span style="line-height: 40px"
                      >{{ this.allTagValue[obj.tag].split("-")[1] }} 之間</span
                    >
                  </div>
                  <div
                    style="color: red; font-size: 12px; margin-left: 5px"
                    v-if="obj.value.low > obj.value.high"
                  >
                    左邊不能大於右邊
                  </div>
                </div>
              </div>
              <!-- CustomTable.value  div -->
              <div class="d-flex">
                <div
                  class="mt-4 d-flex flex-wrap"
                  style="
                    padding: 10px 2px 0px 17px;
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                  "
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:98%'
                      : 'width:88.4%'
                  "
                  v-if="
                    obj.value[0] != undefined &&
                    !this.allTagValue[obj.tag].includes('range') &&
                    this.allTagValue[obj.tag] != 'count'
                  "
                >
                  <div
                    class="col-3"
                    style="padding-right: 15px"
                    v-for="item in obj.value"
                    :key="item"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                      style="
                        padding: 0 15px;
                        height: 40px;
                        background-color: #eaf3f4;
                        border-radius: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <span
                        class="word-overflow"
                        v-tooltip.top="{
                          value: item,
                          class: 'tipInCustomRFM',
                        }"
                        >{{ item }}</span
                      >
                      <img
                        style="cursor: pointer"
                        src="../assets/blackX.svg"
                        alt="blackX"
                        @click="deleteCustomTableValue(item, index)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="mt-4 d-flex flex-wrap"
                  style="
                    padding: 10px 2px 0px 17px;
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                  "
                  :style="
                    obj.type != '價值分群' &&
                    obj.type != '基本屬性' &&
                    obj.type != 'AI顧客分群' &&
                    obj.type != 'CF站外瀏覽'
                      ? 'width:98%'
                      : 'width:88.4%'
                  "
                  v-else-if="
                    this.allTagValue[obj.tag] == 'click' &&
                    obj.value[Object.keys(obj.value)[0]] != undefined &&
                    obj.value[Object.keys(obj.value)[0]][0] != undefined
                  "
                >
                  <div
                    class="col-3"
                    style="padding-right: 15px"
                    v-for="item in obj.value[Object.keys(obj.value)[0]]"
                    :key="item"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                      style="
                        padding: 0 15px;
                        height: 40px;
                        background-color: #eaf3f4;
                        border-radius: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <span
                        class="word-overflow"
                        v-tooltip.top="{
                          value: item,
                          class: 'tipInCustomRFM',
                        }"
                        >{{ item }}</span
                      >
                      <img
                        style="cursor: pointer"
                        src="../assets/blackX.svg"
                        alt="blackX"
                        @click="deleteCustomTableValue(item, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 更多選項按了後的內容 -->
              <div
                class="mt-3 d-flex align-items-center flex-wrap"
                v-if="obj.more_option != undefined"
              >
                <div
                  class="d-flex align-items-center flex-wrap"
                  style="width: 100%"
                  v-if="this.allTagMoreOption[obj.tag].includes('-event')"
                >
                  <div style="width: 28%; margin-right: 2.64%">
                    <DropdownAll
                      style="width: 100%"
                      :holder="'篩選類型'"
                      :valueArr="['時間篩選', '活動篩選']"
                      :selectValue="channelState[index + '']"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_channelState"
                    />
                  </div>
                  <div
                    class="d-flex align-items-center"
                    v-if="this.channelState[index + ''] === '時間篩選'"
                  >
                    <span class="me-3">在</span>
                    <div style="width: 250px">
                      <DropdownMoreOption
                        style="width: 100%"
                        :valueArr="this.timeState"
                        :selectValue="
                          obj.more_option.time_range.start_time != ''
                            ? obj.more_option.time_range.start_time +
                              '~' +
                              obj.more_option.time_range.end_time
                            : obj.more_option.time_range.days + ''
                        "
                        :changingSelected="index + ''"
                        v-on:selectValueByDrop="selectValueByDrop_timeState"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex"
                    v-else-if="this.channelState[index + ''] === '活動篩選'"
                  >
                    <button
                      class="basic-btn-customRFM highlight-btn-customRFM me-3"
                      style="width: 160px"
                      @click="openEventTable(obj.web, index)"
                    >
                      選擇活動
                    </button>
                    <div class="event-input-customRFM">
                      <span
                        class="word-overflow"
                        style="width: 100%; line-height: 40px"
                        v-if="eventName[obj.more_option.event] != undefined"
                      >
                        {{ eventName[obj.more_option.event] }}
                        -
                        {{ this.table_state[index] }}
                        -
                        {{ this.table_eventTime[index] }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-wrap" v-else>
                  <span class="me-3">在</span>
                  <div style="width: 250px">
                    <DropdownMoreOption
                      style="width: 100%"
                      :valueArr="this.timeState"
                      :selectValue="
                        obj.more_option.time_range.start_time != ''
                          ? obj.more_option.time_range.start_time +
                            '~' +
                            obj.more_option.time_range.end_time
                          : obj.more_option.time_range.days + ''
                      "
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="selectValueByDrop_timeState"
                    />
                  </div>
                  <div
                    class="d-flex"
                    v-if="this.allTagMoreOption[obj.tag].includes('time-count')"
                  >
                    <span style="line-height: 40px" class="mx-3"
                      >，總{{ this.allTagMoreOptionUnit[obj.tag] }}次數</span
                    >
                    <input
                      type="number"
                      class="count-input-customRFM"
                      :value="obj.more_option.count.count"
                      @input="updateCount($event, index)"
                    />
                    <span style="line-height: 40px" class="mx-3">次</span>
                    <div style="width: 120px">
                      <DropdownAll
                        style="width: 100%"
                        :mxHeight="200"
                        :valueArr="this.moreOrless"
                        :selectValue="obj.more_option.count.moreOrless"
                        :changingSelected="index + ''"
                        v-on:selectValueByDrop="selectValueByDrop_moreOrless"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex flex-fill"
                    v-if="this.allTagMoreOption[obj.tag].includes('-cost')"
                  >
                    <span>，</span>
                  </div>
                  <div
                    class="d-flex col-12 align-items-center mt-3"
                    v-if="this.allTagMoreOption[obj.tag].includes('-cost')"
                  >
                    <span class="me-3">且各單筆消費金額</span>
                    <DropdownAll
                      style="width: 90px"
                      :mxHeight="200"
                      :valueArr="this.moreOrlessForCost"
                      :selectValue="obj.more_option.cost.moreOrlessForCost"
                      :changingSelected="index + ''"
                      v-on:selectValueByDrop="
                        selectValueByDrop_moreOrlessForCost
                      "
                    />
                    <div style="position: relative">
                      <div class="d-flex align-items-center">
                        <input
                          type="number"
                          class="count-input-customRFM mx-3"
                          :value="obj.more_option.cost.lowCost"
                          @input="updateLowCost($event, index)"
                          v-if="
                            obj.more_option.cost.moreOrlessForCost === '區間'
                          "
                        />
                        <span
                          v-if="
                            obj.more_option.cost.moreOrlessForCost === '區間'
                          "
                          >$ -</span
                        >
                        <input
                          type="number"
                          class="count-input-customRFM mx-3"
                          :value="obj.more_option.cost.highCost"
                          @input="updateHighCost($event, index)"
                          v-if="
                            obj.more_option.cost.moreOrlessForCost === '區間'
                          "
                        />
                      </div>
                      <div
                        style="
                          color: red;
                          font-size: 12px;
                          position: absolute;
                          bottom: -20px;
                          left: 22px;
                        "
                        v-if="
                          obj.more_option.cost.lowCost >
                          obj.more_option.cost.highCost
                        "
                      >
                        左邊不能大於右邊
                      </div>
                    </div>
                    <input
                      type="number"
                      class="count-input-customRFM mx-3"
                      :value="obj.more_option.cost.cost"
                      @input="updateCost($event, index)"
                      v-if="
                        obj.more_option.cost.moreOrlessForCost === '大於' ||
                        obj.more_option.cost.moreOrlessForCost === '小於'
                      "
                    />
                    <span
                      class="me-3"
                      v-if="obj.more_option.cost.moreOrlessForCost != '-'"
                      >$</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 右側按鈕 -->
            <div
              style="width: 7%"
              class="d-flex flex-column justify-content-between"
            >
              <span class="text-end" style="height: 40px"
                ><img
                  class="me-1"
                  style="cursor: pointer; width: 15px"
                  src="../assets/greenX.svg"
                  alt="greenX"
                  @click="delete_customRFM_tableValue(index)"
              /></span>
              <div
                class="d-flex flex-column align-items-end"
                v-if="
                  obj.tag != '' &&
                  (!this.allTagMoreOption[obj.tag].includes('notime-nocount') ||
                    this.allTagMoreOption[obj.tag].includes('-event')) &&
                  obj.more_option === undefined
                "
              >
                <div
                  style="
                    color: #71afb6;
                    cursor: pointer;
                    font-size: 14px;
                    height: 25px;
                  "
                  class="text-end pe-1"
                  @click="addMoreOption(index)"
                >
                  更多選項
                </div>
                <div
                  style="
                    color: #71afb6;
                    cursor: pointer;
                    background-color: #eaf3f4;
                    height: 30px;
                    border-radius: 10px;
                    width: 74px;
                  "
                  class="text-center"
                  @click="add_customRFM_tableValue(index)"
                >
                  <img
                    style="width: 12px"
                    class="me-1"
                    src="../assets/greenPlus.svg"
                    alt="greenPlus"
                  /><span style="font-size: 14px"> 新增 </span>
                </div>
              </div>
              <div
                class="d-flex flex-column align-items-end"
                v-else-if="
                  obj.tag != '' &&
                  this.allTagMoreOption[obj.tag] != 'notime-nocount' &&
                  obj.more_option != undefined
                "
              >
                <div
                  style="
                    color: #71afb6;
                    cursor: pointer;
                    font-size: 14px;
                    height: 25px;
                  "
                  class="text-end pe-1"
                  @click="deleteMoreOption(index)"
                >
                  取消選項
                </div>
                <div
                  style="
                    color: #71afb6;
                    cursor: pointer;
                    background-color: #eaf3f4;
                    height: 30px;
                    border-radius: 10px;
                    width: 74px;
                  "
                  class="text-center"
                  @click="add_customRFM_tableValue(index)"
                >
                  <img
                    style="width: 12px"
                    class="me-1"
                    src="../assets/greenPlus.svg"
                    alt="greenPlus"
                  /><span style="font-size: 14px"> 新增 </span>
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-end" v-else>
                <div
                  style="
                    color: #71afb6;
                    cursor: pointer;
                    background-color: #eaf3f4;
                    height: 30px;
                    border-radius: 10px;
                    width: 74px;
                  "
                  class="text-center"
                  @click="add_customRFM_tableValue(index)"
                >
                  <img
                    style="width: 12px"
                    class="me-1"
                    src="../assets/greenPlus.svg"
                    alt="greenPlus"
                  /><span style="font-size: 14px"> 新增 </span>
                </div>
              </div>
            </div>
          </div>
          <!-- api 算人數 -->
          <div style="padding: 25px 0" class="d-flex justify-content-center">
            <button
              class="basic-btn-customRFM highlight-btn-customRFM"
              style="width: 50%"
              @click="this.beforeRatePostCustomTable()"
            >
              運行設定好的條件
              <img
                src="../assets/runWhite.svg"
                alt=""
                class="ms-2"
                style="position: relative; top: -1.5px"
              />
            </button>
          </div>
        </div>
      </transition>
    </div>
    <!-- 輪廓樣貌組合 -->
    <div class="customRFM-shadowBox mt-4">
      <div>
        <div class="loading-background-rate-CustomRFM" v-if="loadRate">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-between"
          style="padding: 25px 13px 25px 40px; border-bottom: 1px solid #c4c4c4"
          v-else
        >
          <div style="width: 24.5%">
            <div
              class="
                customRFM-shadowBox
                d-flex
                flex-column
                justify-content-between
              "
              style="height: 145px; padding: 20px"
            >
              <p style="color: #71afb6">總人數</p>
              <p class="d-flex justify-content-between align-items-center m-0">
                <span style="font-size: 22px">{{ resultCount }}</span
                ><span>(人)</span>
              </p>
            </div>
            <div
              class="
                customRFM-shadowBox
                mt-4
                d-flex
                flex-column
                justify-content-between
              "
              style="height: 145px; padding: 20px"
            >
              <p style="color: #71afb6">人數占比</p>
              <p class="d-flex justify-content-between align-items-center m-0">
                <span style="font-size: 22px">{{ resultRate }}</span
                ><span>(%)</span>
              </p>
            </div>
          </div>
          <div
            class="customRFM-shadowBox"
            style="width: 73.5%; height: 315px; padding: 17px 25px"
          >
            <p style="color: #71afb6">輪廓樣貌組合</p>
            <div id="barchart">
              <apexchart
                type="bar"
                height="235"
                :options="barchartOptions"
                :series="barseries"
              ></apexchart>
            </div>
          </div>
        </div>
        <div style="padding: 25px 35px">
          <p style="color: #71afb6">輸入此名單名稱</p>
          <p
            style="color: #71afb6"
            class="d-flex justify-content-between align-items-center m-0"
          >
            <span style="width: 8%">名單名稱</span>
            <input
              type="text"
              style="
                border: none;
                width: 90%;
                background-color: #eaf3f4;
                border-radius: 10px;
                height: 40px;
              "
              class="ps-2"
              v-model="labelName"
            />
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <button
        class="basic-btn-customRFM me-4"
        style="background-color: #eaf3f4; color: #71afb6; width: 45%"
        @click="beforePostCustomTable(false)"
      >
        <img
          src="../assets/greenPlus.svg"
          alt="greenPlus"
          style="position: relative; top: -2px; width: 17px"
          class="me-2"
        />
        <span> 建立新名單 </span>
      </button>
      <button
        class="basic-btn-customRFM highlight-btn-customRFM"
        style="width: 45%"
        @click="goSelectChannel"
      >
        <span> 儲存及選擇渠道 </span>
        <img
          src="../assets/white-arrow.svg"
          alt="white-arrow"
          class="ms-2"
          style="position: relative; top: -2px"
        />
      </button>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import FavoriteList from "../components/CustomRFM/FavoriteList.vue";
import FavoriteNameList from "../components/CustomRFM/FavoriteNameList.vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";
// import ClickForceItem from "../components/RFMCustomPage/ClickForceItem.vue";
import DropdownAll from "../components/DropdownAll.vue";
import DropdownCheckbox from "../components/CustomRFM/DropdownCheckbox.vue";
import DropdownMoreOption from "../components/CustomRFM/DropdownMoreOption.vue";
import DropdownType from "../components/CustomRFM/DropdownType.vue";
import DropdownTypeAI from "../components/CustomRFM/DropdownTypeAI.vue";
import ScheduleTable from "../components/CustomRFM/ScheduleTable.vue";
import TriggerTable from "../components/CustomRFM/TriggerTable.vue";
import moment from "moment";
import BtnInfoBox from "../components/BtnInfoBox.vue";

export default {
  name: "RFMCustomPage",
  components: {
    apexchart: VueApexCharts,
    FavoriteNameList,
    FavoriteList,
    // ClickForceItem,
    DropdownAll,
    DropdownCheckbox,
    DropdownMoreOption,
    DropdownType,
    DropdownTypeAI,
    ScheduleTable,
    TriggerTable,
    BtnInfoBox,
  },
  data() {
    return {
      refreshModal: 0,
      table_state: {},
      table_eventTime: {},
      allCannelTable: [],
      selectedTable: [],
      channelState: {},
      selectChannel: "",

      hideTable: false,
      hideTable2: false,
      valueLoading: "",
      loadRate: false,
      notshow: false,
      loading: false,
      CustomShow: false,

      todosAdv: [],
      searchTodos: [],
      selectedID: "",
      labelName: "",
      i: 1,

      timeState: ["全部時間", "時間段", "時間內"],
      rangeday: {
        start: "",
        end: "",
      },

      // 基本的值
      mode: ["或", "且"],
      tagType: ["基本屬性", "網站行為", "消費行為"],
      websites: ["桂冠官網", "營養研究室"],
      channels: ["Email", "SMS"],
      overall: ["整體", "線上", "線下"],
      clickPage: ["首頁", "Banner"],
      clickValue: [],
      customWeb: {
        客製標籤一: [],
        客製標籤二: [],
      },
      allKindTag: {
        基本屬性: ["性別", "年齡級距", "生日"],
        網站行為: [
          "造訪商品名稱",
          "有來造訪",
          "平均瀏覽時間",
          "有加入購物車但未結",
        ],
        消費行為: ["幾天內有購買的人", "購買的商品名稱", "客單價分級"],
      },
      allTagValue: {
        性別: ["男", "女"],
        年齡級距: "range",
        生日: [
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        造訪商品名稱: "click",
        有來造訪: "count",
        平均瀏覽時間: "range",
        有加入購物車但未結: "count",
        幾天內有購買的人: "count",
        購買的商品名稱: [
          "Aadsadwasdasadadwadsadsadwda商品",
          "B商品",
          "L商品",
          "K商品",
          "T商品",
        ],
        客單價分級: ["M1", "S1", "T1"],
      },
      apiTagValue: {},
      moreOrless: ["-", "以上(含)", "以下(含)"],
      moreOrlessForCost: ["-", "大於", "區間", "小於"],
      moreOrlessForCost2: ["大於", "區間", "小於"],
      // 判斷值的狀態
      allTagMoreOption: {
        性別: "notime-nocount-nocost-noevent",
        年齡級距: "notime-nocount",
        生日: "notime-nocount",
        造訪商品名稱: "time-count",
        有來造訪: "time-count",
        平均瀏覽時間: "time-count",
        有加入購物車但未結: "time-nocount",
        幾天內有購買的人: "time-count",
        購買的商品名稱: "time-count",
        客單價分級: "notime-nocount",
      },
      allTypeWebState: {
        基本屬性: "noweb",
        網站行為: "web",
        消費行為: "overall",
      },
      allTagMoreOptionUnit: {},

      customRFM_tableValue: [
        {
          mode: "初",
          type: "",
          web: "",
          tag: "",
          value: "",
        },
      ],

      repeatTypeArr: [],

      resultCount: " - - ",
      resultRate: " - - ",
      barseries: [
        {
          data: [0, 0],
        },
      ],
      barchartOptions: {},
      seletedApiChannelBar: {},
      seletedApiChannelBarRatio: {},
      colorBR: ["#63A7C8", "#FFA09D"],

      events: [],
      eventTableModal: {},
      eventID: "",
      eventName: {},
      nowIndex: 0,

      missdata: false,
    };
  },
  created: async function () {
    this.customlabelInfoPost("web");
    this.customlabelInfoPost("overall");
    this.customlabelInfoPost("channel");
    this.customlabelStructrueGet();
    this.getCalenEvents();
    await this.getCustomLabelList();
  },
  watch: {
    rangeday() {
      console.log(this.rangeday);
      console.log(this.rangeday.start.toISOString());
      console.log(this.rangeday.end.toISOString());
    },
  },
  methods: {
    // 開啟table modal
    openEventTable: async function (web, index) {
      console.log(web, index);
      console.log(
        "this.table_state[this.nowIndex]",
        this.table_state[this.nowIndex]
      );
      this.nowIndex = index;
      await this.getChannelTable(web);
      let dataindex = 0;
      dataindex = this.allCannelTable.排程.findIndex(
        (d) => d.Name == this.customRFM_tableValue[index].more_option.event
      );
      if (dataindex != -1) {
        this.table_state[this.nowIndex] = "排程";
        this.selectedTable = this.allCannelTable.排程;
      } else {
        dataindex = this.allCannelTable.自動化劇本.findIndex(
          (d) => d.Name == this.customRFM_tableValue[index].more_option.event
        );
        if (dataindex != -1) {
          this.table_state[this.nowIndex] = "自動化劇本";
          this.selectedTable = this.allCannelTable.自動化劇本;
        } else {
          this.selectedTable =
            this.allCannelTable[this.table_state[this.nowIndex]];
        }
      }

      this.refreshModal = this.refreshModal + 1;
      this.eventTableModal = new bootstrap.Modal(
        document.getElementById("eventTableModal"),
        {
          keyboard: false,
        }
      );
      this.eventTableModal.show();
    },
    // 關閉table modal
    closeEventTable() {
      this.eventTableModal.hide();
    },
    // 記住event id name time
    saveEventID(id, name, time) {
      this.eventName[id] = name;
      this.customRFM_tableValue[this.nowIndex].more_option.event = id;
      this.table_eventTime[this.nowIndex] = time;
    },

    openExplainAIModal() {
      const explainAIModal = new bootstrap.Modal(
        document.getElementById("explainAIModal"),
        {
          keyboard: false,
        }
      );
      explainAIModal.show();
    },
    changeHideTable() {
      if (!this.hideTable) {
        this.hideTable2 = !this.hideTable2;
        setTimeout(() => {
          this.hideTable = !this.hideTable;
        }, 50);
        console.log(this.hideTable);
      } else {
        setTimeout(() => {
          this.hideTable2 = !this.hideTable2;
        }, 500);
        this.hideTable = !this.hideTable;
      }
    },
    selectValueByDrop_mode(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].mode = selectValue;
    },
    selectValueByDrop_tagType(selectValue, changingSelected, index) {
      if (this.allTypeWebState[selectValue] === "noweb") {
        this.customRFM_tableValue[+changingSelected].web = "noweb";
      } else if (this.allTypeWebState[selectValue] === "web") {
        this.customRFM_tableValue[+changingSelected].web = this.websites[0];
      } else if (this.allTypeWebState[selectValue] === "overall") {
        this.customRFM_tableValue[+changingSelected].web = this.overall[0];
      } else {
        this.customRFM_tableValue[+changingSelected].web = this.channels[0];
      }
      if (selectValue === "價值分群") {
        this.customRFM_tableValue[+changingSelected].type = selectValue;
        this.customRFM_tableValue[+changingSelected].tag = "價值分群";
        this.customRFM_tableValue[+changingSelected].value = [];
        delete this.customRFM_tableValue[+changingSelected].more_option;
      } else {
        this.customRFM_tableValue[+changingSelected].type = selectValue;
        this.customRFM_tableValue[+changingSelected].tag = "";
        this.customRFM_tableValue[+changingSelected].value = [];

        delete this.customRFM_tableValue[+changingSelected].more_option;
      }
    },
    // 點開第2個下拉框
    selectValueByDrop_web(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].web = selectValue;
      this.customRFM_tableValue[+changingSelected].tag = "";
      this.customRFM_tableValue[+changingSelected].value = [];
      delete this.customRFM_tableValue[+changingSelected].more_option;
      console.log("給後端的資料", this.customRFM_tableValue);
    },
    // 點開第3個下拉框
    selectValueByDrop_tag: async function (
      selectValue,
      changingSelected,
      index
    ) {
      this.customRFM_tableValue[+changingSelected].tag = selectValue;
      delete this.customRFM_tableValue[+changingSelected].more_option;

      if (this.allTagValue[selectValue] === "count") {
        this.customRFM_tableValue[+changingSelected].value = "all";
      } else if (this.allTagValue[selectValue].includes("range")) {
        this.customRFM_tableValue[+changingSelected].value = {
          low: "",
          high: "",
        };
      } else if (this.allTagValue[selectValue] === "costrange") {
        this.customRFM_tableValue[+changingSelected].value = {
          cost: "",
          low: "",
          high: "",
          moreOrless: "",
        };
      } else if (
        typeof this.allTagValue[selectValue] === "object" &&
        this.allTagValue[selectValue][0] != undefined
      ) {
        this.customRFM_tableValue[+changingSelected].value = [];
      } else if (this.allTagValue[selectValue] === "click") {
        this.customRFM_tableValue[+changingSelected].value = {};
        await this.customlabelInfoPost(
          this.allTagValue[selectValue],
          this.customRFM_tableValue[+changingSelected].web,
          this.customRFM_tableValue[+changingSelected].tag
        );
      } else {
        this.customRFM_tableValue[+changingSelected].value = [];
        this.valueLoading = this.customRFM_tableValue[+changingSelected].tag;
        await this.customlabelInfoPost(
          this.allTagValue[selectValue],
          this.customRFM_tableValue[+changingSelected].web,
          this.customRFM_tableValue[+changingSelected].tag
        );
      }
      console.log("給後端的資料", this.customRFM_tableValue);
    },
    // 點開下方第1個下拉框
    selectValueByDrop_clickPage: async function (
      selectValue,
      changingSelected,
      index
    ) {
      this.customRFM_tableValue[+changingSelected].value = {};
      this.customRFM_tableValue[+changingSelected].value[selectValue] = [];
      this.valueLoading = "click";
      await this.customlabelInfoPost(
        selectValue,
        this.customRFM_tableValue[+changingSelected].web,
        this.customRFM_tableValue[+changingSelected].tag
      );
      this.valueLoading = "";

      console.log("給後端的資料", this.customRFM_tableValue);
    },
    // 點開下方第2個下拉框
    selectValueByDrop_clickValue: async function (
      selectValueArr,
      changingSelected,
      index
    ) {
      if (selectValueArr.includes('- -')) {
        this.customRFM_tableValue[+changingSelected].value[
          Object.keys(this.customRFM_tableValue[+changingSelected].value)[0]
        ] = []
      } else {
        this.customRFM_tableValue[+changingSelected].value[
          Object.keys(this.customRFM_tableValue[+changingSelected].value)[0]
        ] = selectValueArr;
      }

      console.log("selectValueArr", selectValueArr);
      console.log("給後端的資料", this.customRFM_tableValue);
    },
    selectValueByDrop_timeState(selectValue, changingSelected, index) {
      console.log("123", index);
      if (index === 1) {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = selectValue.split("~")[0];
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = selectValue.split("~")[1];
      } else if (index === 2) {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = selectValue;
      } else if (index === 0) {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = "2000-01-01";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = new Date()
          .toISOString()
          .slice(0, 10);
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = "";
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    selectValueByDrop_moreOrless(selectValue, changingSelected, index) {
      this.customRFM_tableValue[
        +changingSelected
      ].more_option.count.moreOrless = selectValue;
    },
    selectValueByDrop_moreOrlessForCost(selectValue, changingSelected, index) {
      this.customRFM_tableValue[
        +changingSelected
      ].more_option.cost.moreOrlessForCost = selectValue;
      this.customRFM_tableValue[+changingSelected].more_option.cost.lowCost =
        "";
      this.customRFM_tableValue[+changingSelected].more_option.cost.highCost =
        "";
      this.customRFM_tableValue[+changingSelected].more_option.cost.cost = "";
    },
    selectValueByDrop_moreOrlessForCost2(selectValue, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].value.moreOrless =
        selectValue;
      this.customRFM_tableValue[+changingSelected].value.low = "";
      this.customRFM_tableValue[+changingSelected].value.high = "";
      this.customRFM_tableValue[+changingSelected].value.cost = "";
    },
    selectValueByDrop_channelState(selectValue, changingSelected, index) {
      console.log(index);
      this.channelState[changingSelected] = selectValue;
      this.table_state[changingSelected] = "排程";
      if (selectValue === "活動篩選") {
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.days = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.start_time = "";
        this.customRFM_tableValue[
          +changingSelected
        ].more_option.time_range.end_time = "";
      } else {
        this.customRFM_tableValue[+changingSelected].more_option.event = "";
      }
    },
    selectValueByDrop(selectValue, changingSelected, index) {
      this[changingSelected] = selectValue;
    },
    // 值是arr的 function
    selectValueByDrop_Checkbox(selectValueArr, changingSelected, index) {
      this.customRFM_tableValue[+changingSelected].value = selectValueArr;
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    // 刪除value[]內的值
    deleteCustomTableValue(item, index) {
      if (
        Object.prototype.toString.call(
          this.customRFM_tableValue[index].value
        ) === "[object Object]"
      ) {
        this.customRFM_tableValue[index].value[
          Object.keys(this.customRFM_tableValue[index].value)[0]
        ] = this.customRFM_tableValue[index].value[
          Object.keys(this.customRFM_tableValue[index].value)[0]
        ].filter((d) => !d.includes(item));
      } else {
        this.customRFM_tableValue[index].value = this.customRFM_tableValue[
          index
        ].value.filter((d) => !d.includes(item));
      }
    },
    // 值是range的 function
    updateCustomTableRange(e, index, valueindex) {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      this.customRFM_tableValue[index].value[valueindex] = +e.target.value;
    },
    updateRangeday(index) {
      if (typeof this.rangeday.start != "string") {
        this.customRFM_tableValue[index].value.low = this.rangeday.start
          .toISOString()
          .slice(0, 10);
        this.customRFM_tableValue[index].value.high = this.rangeday.end
          .toISOString()
          .slice(0, 10);
      }
      console.log(this.customRFM_tableValue[index].value);
    },
    // update more_option.count的 function
    updateLowCost(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.cost.lowCost =
          e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.cost.lowCost =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateHighCost(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.cost.highCost =
          e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.cost.highCost =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateLowCost2(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].value.low = e.target.value;
      } else {
        this.customRFM_tableValue[index].value.low = +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateHighCost2(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].value.high = e.target.value;
      } else {
        this.customRFM_tableValue[index].value.high = +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateCost(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.cost.cost = e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.cost.cost =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    updateCost2(e, index) {
      console.log("e.target.value", e.target.value);
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].value.cost = e.target.value;
      } else {
        this.customRFM_tableValue[index].value.cost = +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    // update more_option.count的 function
    updateCount(e, index) {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
      if (e.target.value === "") {
        e.target.value = "";
        this.customRFM_tableValue[index].more_option.count.count =
          e.target.value;
      } else {
        this.customRFM_tableValue[index].more_option.count.count =
          +e.target.value;
      }
      console.log("this.customRFM_tableValue", this.customRFM_tableValue);
    },
    // 根據web拿tag
    customlabelInfoPost: async function (item, web, tag) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        item: item,
      };

      await allapi2
        .customlabelInfoPost(params, body)
        .then((res) => {
          console.log("tagValue資訊", res);
          if (item === "web") {
            this.websites = res.data.item;
          } else if (item === "overall") {
            this.overall = res.data.item;
          } else if (item === "channel") {
            this.channels = res.data.item;
          } else if (item === "click") {
            this.clickPage =  res.data.item[web];
          } else if (this.clickPage.includes(item)) {
            res.data.item[web].unshift('- -');
            this.clickValue = res.data.item[web]
          } else if (web === "noweb") {
            this.apiTagValue[tag] = res.data.item;
          } else if (
            this.websites.includes(web) ||
            this.overall.includes(web)
          ) {
            this.apiTagValue[tag] = res.data.item[web];
          } else {
            this.apiTagValue[tag] = res.data.item;
          }
          this.valueLoading = "";
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.valueLoading = "";
          this.loading = false;
          alert("連線有誤");
        });
    },
    // 新增或刪除table
    add_customRFM_tableValue(index) {
      const emptyObj = {
        mode: "或",
        type: "",
        web: "",
        tag: "",
        value: [],
      };
      this.customRFM_tableValue.splice(index + 1, 0, emptyObj);
    },
    delete_customRFM_tableValue(index) {
      if (this.customRFM_tableValue.length === 1) {
        this.customRFM_tableValue = [
          {
            mode: "初",
            type: "",
            web: "",
            tag: "",
            value: [],
          },
        ];
      } else {
        this.customRFM_tableValue.splice(index, 1);
      }
    },
    addMoreOption(index) {
      this.customRFM_tableValue[index].more_option = {
        time_range: {
          start_time: "2000-01-01",
          end_time: new Date().toISOString().slice(0, 10),
          days: "",
        },
        count: {
          count: "",
          moreOrless: "-",
        },
        cost: {
          cost: "",
          lowCost: "",
          highCost: "",
          moreOrlessForCost: "-",
        },
        event: "",
      };
    },
    deleteMoreOption(index) {
      delete this.customRFM_tableValue[index].more_option;
    },
    // 基本校驗
    customTablecheck() {
      this.customRFM_tableValue.map((d) => {
        if (
          d.more_option != undefined &&
          d.more_option.time_range.start_time === "" &&
          d.more_option.time_range.days === "" &&
          d.more_option.event === ""
        ) {
          delete d.more_option;
        }
      });
      this.missdata = false;

      this.customRFM_tableValue.map((d) => {
        if (Object.values(d).includes("")) {
          this.missdata = true;
        }
        if (
          Object.prototype.toString.call(d.value) === "[object Array]" &&
          d.value[0] === undefined
        ) {
          this.missdata = true;
        }
        if (
          Object.prototype.toString.call(d.value) === "[object Object]" &&
          (d.value.low === "" || d.value.high === "")
        ) {
          this.missdata = true;
        }
        if (
          Object.prototype.toString.call(d.value) === "[object Object]" &&
          Object.keys(d.value).length == 0
        ) {
          this.missdata = true;
        }

        if (
          (d.value.moreOrless === "大於" || d.value.moreOrless === "小於") &&
          d.value.cost === ""
        ) {
          this.missdata = true;
        }
        if (
          d.value.moreOrless === "區間" &&
          (d.value.lowCost === "" || d.value.highCost === "")
        ) {
          this.missdata = true;
        }
        if (
          d.value.moreOrless === "區間" &&
          d.value.lowCost > d.value.highCost
        ) {
          this.missdata = true;
        }
        if (d.value.moreOrless === undefined && Object.values(d).includes("")) {
          return alert("區間左側數值不能大於右側");
        }
      });
    },

    // 在建立名單前做前要做得校驗
    beforePostCustomTable: async function (goSelectChannel) {
      this.customTablecheck();
      console.log("給後端的資料", this.customRFM_tableValue);
      if (this.missdata) {
        return alert("資料有缺");
      } else if (this.labelName === "") {
        return alert("名單名稱不能是空的");
      } else if (
        this.todosAdv.findIndex((d) => d.label === this.labelName) != -1
      ) {
        return alert("名單名稱不能重複");
      } else {
        await this.postCustomTable(this.labelName, goSelectChannel);
      }
    },
    // 在運行設定好的條件前要做得校驗
    beforeRatePostCustomTable: async function () {
      this.customTablecheck();
      console.log("給後端的資料", this.customRFM_tableValue);
      if (this.missdata) {
        return alert("資料有缺");
      } else {
        this.customlabelRatePost();
        // this.showCustomTable();
      }
      // console.log("給後端的資料", fixedCustomRFM_tableValue);
      // console.log("給後端的資料", Object.values(this.customRFM_tableValue[4]));
    },

    // api
    postCustomTable: async function (name, goSelectChannel) {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: this.customRFM_tableValue,
        name: name,
      };
      await allapi2
        .customlabelCreatePost(params, body)
        .then(async (res) => {
          console.log("上傳給後端CustomTable", res);
          await this.getCustomLabelList();
          this.selectValue(this.todosAdv[0]);
          const addSuccessModal = new bootstrap.Modal(
            document.getElementById("addSuccessModal"),
            {
              keyboard: false,
            }
          );
          addSuccessModal.show();
          console.log(goSelectChannel);
          if (goSelectChannel) {
            setTimeout(() => {
              addSuccessModal.hide();
              const roadModal = new bootstrap.Modal(
                document.getElementById("roadModal"),
                {
                  keyboard: false,
                }
              );
              roadModal.show();
            }, 1000);
          }
          window, scrollTo(0, 0);
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getCustomLabelList: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelListGet(params)
        .then((res) => {
          this.showCustomTable();
          console.log("CustomLabelList", res);
          this.todosAdv = res.data.message.Data;
          this.searchTodos = res.data.message.Data;
          this.searchTodos.sort((a, b) => {
            return b.time - a.time;
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getCustomTableByID: async function (label_id) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        label_id: label_id,
      };
      await allapi2
        .customlabelLabelIdGet(params)
        .then((res) => {
          console.log("getCustomTableByID", res);
          this.customRFM_tableValue = res.data.message.Data;

          this.customRFM_tableValue.map((d) => {
            const item = this.allTagValue[d.tag];
            const web = d.web;
            const tag = d.tag;
            console.log("item", item);
            if (
              item != "count" &&
              !item.includes("range") &&
              typeof item != "object"
            ) {
              this.customlabelInfoPost(item, web, tag);
            }
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    deleteCustomLabel: async function (label_id) {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        label_id: label_id,
      };
      await allapi2
        .customlabelDeleteDelete(params, body)
        .then((res) => {
          console.log("deleteCustomLabel", res);
          if (res.data.message === "The Label cannot be deleted") {
            alert("在投放中的分群無法被刪除");
          } else {
            const deleteSuccessModal = new bootstrap.Modal(
              document.getElementById("deleteSuccessModal"),
              {
                keyboard: false,
              }
            );
            deleteSuccessModal.show();
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      this.getCustomLabelList();
      this.clearall();
    },
    // 拿各種基本資料
    customlabelStructrueGet: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelStructrueGet(params)
        .then((res) => {
          console.log("基本資訊", res);
          const splitType = res.data.type.map((d) => d.split("_"));
          const fullTypeArr = splitType.map((d) => d[0]);
          const noRepeatTypeArr = fullTypeArr.filter(function (
            element,
            index,
            arr
          ) {
            return arr.indexOf(element) === index;
          });
          this.tagType = noRepeatTypeArr;

          this.repeatTypeArr = noRepeatTypeArr.slice(6);
          for (let index = 0; index < this.repeatTypeArr.length; index++) {
            const customWebKey = splitType
              .filter((d) => d[0] === this.repeatTypeArr[index])
              .map((d) => d[1])
              .filter((d) => d != undefined);
            this.customWeb[this.repeatTypeArr[index]] = customWebKey;
          }

          this.allTypeWebState = res.data.web;
          this.allKindTag = res.data.tag;
          this.allTagValue = res.data.value;
          this.allTagMoreOption = res.data.more_option;
          this.allTagMoreOptionUnit = res.data.count_unit;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    // 運行設定好的條件
    customlabelRatePost: async function () {
      this.loadRate = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        content: this.customRFM_tableValue,
      };
      await allapi2
        .customlabelRatePost(params, body)
        .then((res) => {
          console.log("運行設定好的條件", res);
          this.resultCount = res.data.message.Data.count;
          this.resultRate = res.data.message.Data.ratio;
          this.seletedApiChannelBar = res.data.message.Data.structure.structure;
          this.seletedApiChannelBarRatio =
            res.data.message.Data.structure.ratio.ratio;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      this.barchart();
      this.loadRate = false;
    },
    showCustomTable() {
      this.customRFM_tableValue.map((d) => {
        this.repeatTypeArr.map((a) => {
          if (d.type.includes(a)) {
            const showingweb = d.type.split("_")[1];
            d.type = a;
            d.web = showingweb;
          }
        });
      });
    },
    barchart() {
      const vm = this;
      console.log("qsqs", this.seletedApiChannelBar);
      if (this.seletedApiChannelBar.男性 === undefined) {
        this.barseries = [];
        this.barchartOptions = {};
        console.log("123456", this.barseries);
      } else {
        (this.barseries = [
          {
            name: "男性",
            data: this.seletedApiChannelBar.男性.counts,
          },
          {
            name: "女性",
            data: this.seletedApiChannelBar.女性.counts,
          },
        ]),
          (this.barchartOptions = {
            chart: {
              type: "bar",
              height: "230px",
              toolbar: {
                show: true,
                tools: {
                  download: false,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset:
                    true | '<img src="/static/icons/reset.png" width="20">',
                },
              },
            },
            colors: ["#63A7C8", "#FFA09D"],

            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["#63A7C8", "#FFA09D"],
            },
            xaxis: {
              title: {
                text: "年齡層分層",
                offsetY: 0,
                style: {
                  fontSize: "12px",
                  cssClass: "padding-for-apex",
                },
              },

              categories: this.seletedApiChannelBar.女性.age,
            },
            legend: {
              formatter: function (seriesName, opts) {
                console.log("seriesName", seriesName);
                console.log("opts", opts);
                return (
                  "<div style='display:flex; align-items:center'>" +
                  seriesName +
                  ": " +
                  `<div style='color:${
                    vm.colorBR[opts.seriesIndex]
                  }; margin-top:3px'>` +
                  vm.seletedApiChannelBarRatio[seriesName].toFixed(2) +
                  "%" +
                  "</div>" +
                  "</div>"
                );
              },
            },
            yaxis: {
              title: {
                text: "人數",
              },
              labels: {
                style: {
                  fontSize: "12px",
                },
                formatter: function (y) {
                  return y.toLocaleString();
                },
              },
              // max: function (max) {
              //   return (
              //     Math.ceil(max / 10 ** (max.toFixed(0).length - 2)) *
              //       10 ** (max.toFixed(0).length - 2) +
              //     10 ** (max.toFixed(0).length - 2) * 3
              //   );
              // },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                  "<div style='width:200px;font-size:12px;font-family:sans-serif'>" +
                  "<div style='background-color:#ECEFF1;padding:5px;border-bottom:1px solid #c4c4c4'>" +
                  vm.seletedApiChannelBar[w.globals.seriesNames[seriesIndex]]
                    .age[dataPointIndex] +
                  "</div>" +
                  "<div style='padding:10px'>" +
                  "<div style='margin-bottom:10px'>" +
                  `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                  w.globals.seriesNames[seriesIndex] +
                  ": " +
                  "<span style='font-weight:bold'>" +
                  vm.seletedApiChannelBar[
                    w.globals.seriesNames[seriesIndex]
                  ].counts[dataPointIndex].toLocaleString() +
                  " (人)" +
                  "</span>" +
                  // vm.scatterData[dataPointIndex][1] +
                  "</div>" +
                  "<div style='margin:0'>" +
                  `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                  "占比" +
                  ": " +
                  "<span style='font-weight:bold'>" +
                  vm.seletedApiChannelBar[
                    w.globals.seriesNames[seriesIndex]
                  ].ratio[dataPointIndex].toFixed(1) +
                  "%" +
                  "</span>" +
                  "</div>" +
                  "</div>" +
                  "</div>"
                );
              },
            },
          });
      }
    },

    getChannelTable: async function (web) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        web: web,
      };
      await allapi2
        .customlabelChannelPost(params, body)
        .then((res) => {
          console.log("ChannelTable", res);
          this.allCannelTable = res.data;

          this.allCannelTable.排程.forEach((d) => {
            d.ScheduleExpression = new Date(
              moment(d.ScheduleExpression).add(-8, "h")
            ).toLocaleString();
            const data = this.events.find((e) => e.id === d.script_name);
            if (data != undefined) {
              d.script_name = data.title;
              d.realScript_name = data.id;
              d.活動時間 = `${data.start} - ${data.end}`;
            }
            if (d.has_event == 0) {
              d.script_state = "無";
            } else if (d.has_event == 2) {
              d.script_state = "執行中";
            } else if (d.has_event == 3) {
              d.script_state = "已完成";
            }
          });

          this.allCannelTable.自動化劇本.forEach((d) => {
            if (d.event === "購買行銷") {
              d.event = "購買後促銷";
            } else if (d.event === "購買") {
              d.event = "訂單確認";
            }
            const data = this.events.find((e) => e.id === d.script_name);
            if (data != undefined) {
              d.script_name = data.title;
              d.realScript_name = data.id;
              d.活動時間 = `${data.start} - ${data.end}`;
            } else {
              d.活動時間 = "";
              d.end_time = "永久";
            }
            if (d.has_event == 0) {
              d.script_state = "無";
            } else if (d.has_event == 1) {
              d.script_state = "草稿";
            } else if (d.has_event == 2) {
              d.script_state = "執行中";
            } else if (d.has_event == 3) {
              d.script_state = "已完成";
            }
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    changeTable(select) {
      this.table_state[this.nowIndex] = select;
      this.selectedTable = this.allCannelTable[select];
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    // control labelList
    selectValue: async function (todo) {
      this.selectedID = todo.label_id;
      this.labelName = todo.label;
      await this.getCustomTableByID(this.selectedID);
      await this.customlabelRatePost();
    },
    refreshtodo() {
      this.getCustomLabelList();
    },
    removetodo(todo) {
      var yes = confirm("刪除後即無法返回，是否確認刪除?");
      if (yes) {
        this.deleteCustomLabel(todo.label_id);
      }
    },
    clearall() {
      this.selectedID = "";
      this.labelName = "";
      this.customRFM_tableValue = [
        {
          mode: "初",
          type: "",
          web: "",
          tag: "",
          value: [],
        },
      ];
      this.resultCount = "- -";
      this.resultRate = "- -";
      this.barseries = [];
    },
    copytodo: async function (todo) {
      console.log(todo);
      while (
        this.todosAdv.findIndex(
          (d) => d.label === todo.label + "(" + this.i + ")"
        ) != "-1"
      ) {
        this.i++;
      }
      await this.getCustomTableByID(todo.label_id);
      const nowName = todo.label + "(" + this.i + ")";
      this.postCustomTable(nowName);
    },
    search(val) {
      const searched = this.searchTodos.filter((d) => d.label.includes(val));
      this.todosAdv = searched;
    },

    goSelectChannel() {
      this.beforePostCustomTable(true);
    },
    shareData() {
      if (this.selectChannel != "") {
        if (this.selectChannel === "email") {
          this.$router.push({
            name: "ProductTextTemplate",
          });
        } else if (this.selectChannel === "sms") {
          this.$router.push({
            name: "ProductSmsTemplate",
          });
        } else if (this.selectChannel === "line") {
          this.$router.push({
            name: "LineAdsPackage",
          });
        } else if (this.selectChannel === "fb") {
          this.$router.push({
            name: "FacebookAdsPackage",
          });
        }
      } else {
        alert("需選取投放管道");
        const roadModal = new bootstrap.Modal(
          document.getElementById("roadModal"),
          {
            keyboard: false,
          }
        );
        roadModal.show();
      }
    },
  },
};
</script>

<style scoped>
.loading-background-rate-CustomRFM {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid #c4c4c4;
  z-index: 9999;
  text-align: center;
  padding: 150px 0;
  height: 366px;
}
/* CustomRFM */
.child-popout-inNewRFMCustomPage {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 400px;
  right: -415px;
  top: -52px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
}
.customRFM-shadowBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
}
.transparent-btn {
  border: none;
  background-color: white;
}
.range-input-customRFM {
  border: none;
  background-color: #eaf3f4;
  border-radius: 10px;
  height: 40px;
  width: 122px;
  text-align: center;
}
.count-input-customRFM {
  border: none;
  background-color: #eaf3f4;
  border-radius: 10px;
  height: 40px;
  width: 100px;
  text-align: center;
}
.event-input-customRFM {
  border: none;
  background-color: #eaf3f4;
  border-radius: 10px;
  width: 28vw;
  height: 40px;
  padding: 0 16px;
}
.basic-btn-customRFM {
  border: none;
  border-radius: 10px;
  height: 40px;
}
.highlight-btn-customRFM {
  background-image: linear-gradient(to top left, #71afb6, #b5dce0);
  color: white;
}
.word-overflow {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tipInCustomRFM {
  opacity: 0.7 !important;
  padding: 3px 6px !important;
  background-color: #575757;
  border: 1px solid #575757;
  border-radius: 10px;
  color: white;
  /* height: 30px; */
  /* line-height: 30px; */
}
.slide-enter-active {
  transition: max-height 1.2s ease;
  /* transition: max-height 0.7s ease-out; */
}
.slide-leave-active {
  transition: max-height 0.6s ease;
  /* transition: max-height 0.7s ease-out; */
}
.slide-enter-from,
.slide-leave-to {
  /* transform: translateY(-100%); */
  max-height: 0;
}
.slide-enter-to,
.slide-leave-from {
  /* transform: translateY(0%); */
  max-height: 800px;
}
</style>