<template>
  <div v-if="!notshow" class="p-5 m-5">測試帳號尚未開放</div>
  <div
    class="container home demo-app d-flex flex-wrap align-content-start"
    v-else
  >
    <!-- expscriptModal -->
    <div
      class="modal fade"
      id="scriptModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" style="width: 100%">
          <SeeScript
            v-bind:scriptID2="scriptID2"
            v-bind:emailChannel="emailChannel"
            v-bind:addadd2="addadd2"
            v-on:showUnlayer="showUnlayer"
            v-on:showSMSexp="showSMSexp"
            v-on:showOutsideExp="showOutsideExp"
            :key="forceRerender"
          />
        </div>
      </div>
    </div>
    <!-- sms modal -->
    <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>

    <!-- expEmailModal -->
    <EmailPreview :subject="subject" :html="html"></EmailPreview>

    <!-- exptriggerModal -->
    <div
      class="modal fade"
      id="triggerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content" style="width: 100%">
          <SeeTriggerScript
            v-bind:scriptID="scriptID"
            v-bind:groupName="groupName"
            v-bind:addadd="addadd"
            v-on:showUnlayer="showUnlayer"
            v-on:showSMSexp="showSMSexp"
            :key="forceRerender"
          />
        </div>
      </div>
    </div>
    <!-- this day events -->
    <div
      class="modal fade"
      id="yearEventsModal"
      tabindex="-1"
      aria-labelledby="yearEventsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 410px; font-size: 18px">
        <div class="modal-content" style="width: 410px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body">
            <p>{{ this.yearSelectDay }} 含有以下活動</p>
            <div
              class="yearevent mb-3"
              v-for="event in yearSelectEvents"
              :key="event.id"
              @click="yearEventClick(event)"
            >
              <p class="p-0">{{ event.title }}</p>
              <p class="m-0">活動週期:{{ event.start }}~{{ event.end }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="changedateModal"
      tabindex="-1"
      aria-labelledby="changedateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 410px; font-size: 18px">
        <div class="modal-content" style="width: 410px">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body">
            <p>活動名稱</p>
            <input
              v-bind:class="{ 'is-invalid': eventNameError }"
              type="text"
              class="date-input mb-0 ps-3 text-start"
              style="width: 100%"
              placeholder="請輸入活動名稱"
              v-model="renameEvent"
            />
            <div class="invalid-feedback">
              {{ eventNameErrMsg }}
            </div>
            <p class="mt-3">活動時間</p>
            <div class="row align-items-center justify-content-between">
              <span class="col-1 set-time-title">
                <img class="mb-1" src="../assets/date.svg" alt=""
              /></span>
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  :max-date="time"
                  v-model="day"
                  is24hr
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
              ~
              <span style="width: 160px">
                <DatePicker
                  class="inline-block h-full"
                  :min-date="day"
                  v-model="time"
                  is24hr
                  is-expanded
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div
                      class="d-flex align-items-center"
                      @click="togglePopover()"
                    >
                      <input
                        :value="inputValue"
                        class="col-12 date-input"
                        readonly
                      />
                    </div>
                  </template>
                </DatePicker>
              </span>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="basic-btn-calen"
              style="width:130px;background-color"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              data-bs-dismiss="modal"
              @click="addCalen"
              v-if="wantaddCalen"
            >
              儲存
            </button>
            <button
              type="button"
              class="basic-btn-calen highlight-btn ms-3"
              style="width: 130px"
              data-bs-dismiss="modal"
              @click="updateCalen"
              v-else
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->

    <div class="col-12 mb-4" style="height: 40px">
      <div
        style="width: 100%"
        class="d-flex justify-content-between align-items-center"
      >
        <span class="title-span">
          活動行事曆
          <BtnInfoBox content="活動行事曆讓您快速瀏覽目前所安排的活動，輕鬆執行建立或者刪除。需注意活動檔期會根據您在投放排程的時間而自動延長。" direction="right"></BtnInfoBox>

          <!-- <button @click="addHolidays()">addHolidays</button> -->
        </span>

        <span class="d-flex justify-content-between align-items-center"
          ><img src="../assets/calendarState.svg" alt="" />
          <button
            class="basic-btn-calen highlight-btn ms-5"
            style="width: 200px"
            @click="handleDateSelect()"
          >
            <img
              src="../assets/whitePlus.svg"
              alt=""
              style="position: relative; top: -1.5px"
              class="me-2"
            />
            新增活動
          </button>
        </span>
      </div>
    </div>
    <div class="d-flex col-12">
      <div class="demo-app-sidebar col-5">
        <div
          class="mb-4"
          style="background-color: #eaf3f4; border-radius: 10px"
        >
          <button
            class="basic-btn-calen"
            style="width: 200px"
            :class="seeTrigger ? 'highlight-btn' : ''"
            @click="seeTrigger = true"
          >
            活動檔期
          </button>
          <button
            class="basic-btn-calen"
            style="width: 115px"
            :class="seeTrigger ? '' : 'highlight-btn'"
            @click="seeTrigger = false"
          >
            常駐劇本
          </button>
        </div>
        <div class="left-container" id="left-container" v-if="seeTrigger">
          <div v-if="select_calen_title != ''" class="pt-1">
            <p
              style="font-size: 22px"
              class="m-4 d-flex justify-content-between align-items-center"
            >
              {{ select_calen_title }}
              <button
                style="width: 50px; border: none; background-color: white"
                @click="this.calendarPopshow = true"
                @mouseleave="this.calendarPopshow = false"
              >
                <img src="../assets/blackSpot.svg" alt="" />
                <div
                  class="popoutInCalenTop text-start"
                  style="color: #575757; font-size: 16px"
                  v-show="calendarPopshow"
                  @mouseleave="this.calendarPopshow = false"
                >
                  <p
                    class="mb-0 p-2"
                    data-bs-toggle="modal"
                    data-bs-target="#changedateModal"
                    @click="wantupdateCalen()"
                  >
                    <img
                      style="width: 17px"
                      class="ms-1 me-2 mb-1"
                      src="../assets/editBlack.svg"
                    />
                    編輯
                  </p>
                  <p class="mb-0 p-2" @click="deleteCalen">
                    <img
                      class="ms-2 me-2 mb-1"
                      style="width: 12px"
                      src="../assets/delete-black.svg"
                    />
                    刪除
                  </p>
                </div>
              </button>
            </p>
            <hr class="mb-0" style="height: 0px" />
            <div id="overheight" style="overflow: auto" class="overheight">
              <div
                v-for="(sche, index) in sches"
                :key="sche.id"
                class="m-4"
                style="
                  width: 250px;
                  border: 1px solid #c4c4c4;
                  border-radius: 10px;
                "
              >
                <div
                  style="
                    padding-left: 12px;
                    height: 40px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                  "
                  :style="[
                    sche.type === 'Email' ? 'background-color:#FFA09D' : '',
                    sche.type === 'SMS' ? 'background-color:#FFCF46' : '',
                    sche.type === 'trigger' ? 'background-color:#BAABE7' : '',
                  ]"
                  class="
                    mb-2
                    d-flex
                    align-items-center
                    justify-content-between
                    pe-2
                  "
                  @mouseleave="this.isShow = ''"
                >
                  <span style="color: white; font-size: 18px">
                    <img
                      src="../assets/Email.svg"
                      alt=""
                      class="mb-1 me-2"
                      v-if="sche.type === 'Email'"
                    />
                    <img
                      src="../assets/white-book.svg"
                      alt=""
                      class="mb-1 me-2"
                      v-else-if="sche.type === 'trigger'"
                    />
                    <img
                      src="../assets/SMS.svg"
                      alt=""
                      class="mb-1 me-2"
                      v-else
                    />
                    <span v-if="sche.type != 'trigger'">
                      {{ sche.type }}
                    </span>
                    <span v-else-if="sche.type === 'trigger'">
                      自動化劇本
                    </span>
                  </span>

                  <span
                    class="px-2"
                    @click="popoutInCalen(sche, index)"
                    style="cursor: pointer; position: relative"
                  >
                    <img
                      src="../assets/white3spot.svg"
                      alt=""
                      v-if="sche.傳送數量 != 0"
                    />
                    <div
                      class="popoutInCalen"
                      style="color: #575757"
                      :style="top"
                      v-show="sche.Name === isShow"
                    >
                      <p
                        class="mb-0 p-2"
                        @click="routergoSet(sche)"
                        v-if="sche.state === 1"
                      >
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/book.svg"
                        />
                        編輯
                      </p>
                      <p
                        class="mb-0 p-2"
                        v-if="sche.type != 'trigger' && sche.has_event != 0"
                        @click="seeScript(sche)"
                      >
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/eye-black.svg"
                        />
                        預覽自動回應
                      </p>
                      <p
                        class="mb-0 p-2"
                        v-if="sche.type === 'trigger'"
                        @click="seeTriggerScript(sche)"
                      >
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/eye-black.svg"
                        />
                        預覽劇本
                      </p>
                      <p
                        class="mb-0 p-2"
                        v-if="sche.state === 0"
                        @click="routergoPerformance(sche)"
                      >
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/3bar.svg"
                        />
                        查看成效
                      </p>
                      <p
                        class="mb-0 p-2"
                        @click="routergoScriptTable()"
                        v-if="sche.has_event != 3 && sche.type === 'trigger'"
                      >
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/book.svg"
                        />
                        劇本設定
                      </p>
                      <p class="mb-0 p-2" @click="deleteModal(sche)">
                        <img
                          style="width: 12px; margin: 0 8px 0 7px"
                          src="../assets/delete-black.svg"
                        />
                        刪除
                      </p>
                    </div>
                  </span>
                </div>
                <div v-if="sche.type != 'trigger'">
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">模板名稱</span>
                    <span
                      class="ellipsis-templateName-inFullCalendar"
                      v-tooltip.bottom="
                        sche.template_name.replace(/[^\x00-\xff]/g, '**')
                          .length > 17
                          ? {
                              value: sche.template_name,
                              class: 'tipInFullCalendar',
                            }
                          : {
                              value: '',
                              class: '',
                            }
                      "
                      >{{ sche.template_name }}</span
                    >
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">發送時間</span>
                    <span class="me-3">{{
                      new Date(sche.ScheduleExpression)
                        .toISOString()
                        .substring(0, 10)
                    }}</span>
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">發送狀態</span>
                    <span v-if="sche.state === 0 && sche.傳送數量 != 0">
                      <img
                        src="../assets/blackSpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      <span style="color: #575757"> 已完成</span>
                    </span>
                    <span v-if="sche.state === 0 && sche.傳送數量 === 0">
                      <img
                        src="../assets/greenSpot.svg"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      <span style="color: #7ac1a1"> 傳送中</span>
                    </span>
                    <span v-if="sche.state === 1">
                      <img
                        src="../assets/orangeOneSpot.svg"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      <span style="color: #fc9f66"> 預定</span>
                    </span>
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4" v-if="sche.has_event != 0">
                      自動回應
                    </span>
                    <span
                      style="color: #76d37a"
                      v-if="sche.state === 1 && sche.has_event === 2"
                    >
                      <img
                        src="../assets/light-green-spot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      啟用中</span
                    >
                    <span
                      style="color: #6bb996"
                      v-else-if="sche.state === 0 && sche.has_event === 2"
                    >
                      <img
                        src="../assets/dark-green-spot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      執行中
                    </span>
                    <span
                      style="color: #c4c4c4"
                      v-else-if="sche.state === 1 && sche.has_event === 1"
                    >
                      <img
                        src="../assets/graySpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      草稿
                    </span>
                    <span
                      style="color: #575757"
                      v-else-if="sche.state === 0 && sche.has_event === 3"
                    >
                      <img
                        src="../assets/blackBigSpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      已完成
                    </span>
                    <!-- <span
                    style="color: #c4c4c4"
                    v-else-if="sche.has_event === 0"
                  >
                    <img
                      src="../assets/graySpot.png"
                      alt=""
                      style="width: 5px; margin-bottom: 2px"
                    />
                    無劇本
                  </span> -->
                  </p>
                </div>
                <div v-else>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">發送渠道</span>
                    <span
                      v-for="(name, index) in sche.Name"
                      :key="name"
                      :style="index === 0 ? 'color:#FFA09D' : 'color:#FFCF46'"
                    >
                      <span v-if="name === 'email'">Email</span>
                      <span v-if="index == 1" style="color: #575757">.</span>
                      <span v-if="name === 'sms'">SMS</span>
                    </span>
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">開始時間</span>
                    <span>{{ sche.start_time }}</span>
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">觸發事件</span>
                    <span>
                      <img
                        class="mb-1"
                        src="../assets/black-buy.png"
                        alt=""
                        v-if="sche.event === '購買後促銷'"
                      />
                      <img
                        class="mb-1"
                        src="../assets/Order-done-gray.svg"
                        alt=""
                        v-if="sche.event === '訂單確認'"
                      />
                      <img
                        class="mb-1"
                        style="width: 15px"
                        src="../assets/black-cycleTime.svg"
                        alt=""
                        v-if="sche.event === '定期投放'"
                      />
                      <img
                        class="mb-1"
                        src="../assets/black-sign.png"
                        alt=""
                        v-if="sche.event === '註冊'"
                      />
                      <img
                        class="mb-1"
                        src="../assets/black-shop.png"
                        alt=""
                        v-if="sche.event === '購物車未結'"
                      />
                      {{ sche.event }}</span
                    >
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">劇本狀態</span>
                    <span
                      style="color: #76d37a"
                      v-if="
                        sche.start_time >
                          new Date().toISOString().slice(0, 10) &&
                        sche.has_event == 2
                      "
                    >
                      <img
                        src="../assets/light-green-spot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      啟用中</span
                    >
                    <span
                      style="color: #6bb996"
                      v-if="
                        sche.start_time <=
                          new Date().toISOString().slice(0, 10) &&
                        sche.has_event == 2
                      "
                    >
                      <img
                        src="../assets/dark-green-spot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      執行中</span
                    >
                    <span
                      style="color: #c4c4c4"
                      v-else-if="sche.has_event == 1"
                    >
                      <img
                        src="../assets/graySpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      草稿
                    </span>
                    <span
                      style="color: #575757"
                      v-else-if="sche.has_event == 3"
                    >
                      <img
                        src="../assets/blackBigSpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      已完成
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="m-4 py-5" v-else>
            點選右邊活動檔期，查看該檔期內的排程
          </div>
        </div>
        <!-- 常駐 -->
        <div class="left-container" id="left-container2" v-else>
          <div class="pt-1">
            <div style="font-size: 18px" class="m-4">
              <div class="search-container col-4" ref="target">
                <div
                  class="d-flex justify-content-between align-items-center"
                  style="
                    width: 250px;
                    border: 1px solid #c4c4c4;
                    border-radius: 10px;
                    padding: 0 14px;
                    height: 35px;
                  "
                  @click="autoShow = !autoShow"
                >
                  {{ triggerType }}
                  <img src="../assets/selection-arrow.png" alt="searchImg" />
                </div>
                <div
                  class="auto-complicate-div"
                  style="width: 250px"
                  v-show="autoShow"
                  @mouseenter="autoShow = true"
                >
                  <p
                    v-for="type in triggerTypes"
                    :key="type"
                    class="m-0 p-1 ps-3 auto-complicate-p"
                    style="cursor: default"
                    @click="
                      triggerType = type;
                      autoShow = false;
                    "
                  >
                    {{ type }}
                  </p>
                </div>
              </div>
            </div>
            <hr class="mb-0" style="height: 0px" />
            <div id="overheight" style="overflow: auto" class="overheight">
              <div
                v-for="(event, index) in triggerEvents"
                :key="event.script_id"
                class="mb-3 m-4"
                style="
                  width: 250px;
                  border: 1px solid #c4c4c4;
                  border-radius: 10px;
                "
              >
                <div
                  style="
                    background-color: #baabe7;
                    height: 40px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                  "
                  class="
                    mb-2
                    d-flex
                    align-items-center
                    justify-content-between
                    px-3
                  "
                  @mouseleave="this.isShow2 = ''"
                >
                  <span
                    style="font-size: 18px; color: white"
                    class="ellipsis-scriptName-inFullCalendar"
                    v-tooltip.bottom="
                      event.script_name.replace(/[^\x00-\xff]/g, '**').length >
                      12
                        ? {
                            value: event.script_name,
                            class: 'tipInFullCalendar',
                          }
                        : {
                            value: '',
                            class: '',
                          }
                    "
                  >
                    <img
                      src="../assets/white-book.svg"
                      alt=""
                      class="mb-1 me-2"
                    />
                    {{ event.script_name }}
                  </span>
                  <span
                    style="cursor: pointer; position: relative"
                    @click="triggerpopoutInCalen(event, index)"
                  >
                    <img src="../assets/white3spot.svg" alt="" />
                    <div
                      class="popoutInCalen"
                      style="color: #575757"
                      :style="top"
                      v-show="event.script_id === isShow2"
                    >
                      <p
                        class="mb-0 p-2"
                        @click="seeTriggerScript(event)"
                      >
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/eye-black.svg"
                        />
                        預覽劇本
                      </p>
                      <p class="mb-0 p-2" @click="routergoScriptTable()">
                        <img
                          style="width: 17px; margin: 0 5px"
                          src="../assets/book.svg"
                        />
                        劇本設定
                      </p>
                      <p class="mb-0 p-2" @click="wantdeleteScript">
                        <img
                          style="width: 12px; margin: 0 8px 0 7px"
                          src="../assets/delete-black.svg"
                        />
                        刪除
                      </p>
                    </div>
                  </span>
                </div>
                <div>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">發送渠道</span>
                    <span
                      v-for="(name, index) in event.Name"
                      :key="name"
                      :style="index === 0 ? 'color:#FFA09D' : 'color:#FFCF46'"
                    >
                      <span v-if="name === 'email'">Email</span>
                      <span v-if="index == 1" style="color: #575757">.</span>
                      <span v-if="name === 'sms'">SMS</span>
                    </span>
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">開始時間</span>
                    <span>{{ event.start_time }}</span>
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">觸發事件</span>
                    <span>
                      <img
                        class="mb-1"
                        src="../assets/black-buy.png"
                        alt=""
                        v-if="event.event === '購買後促銷'"
                      />
                      <img
                        class="mb-1"
                        src="../assets/Order-done-gray.svg"
                        alt=""
                        v-if="event.event === '訂單確認'"
                      />
                      <img
                        class="mb-1"
                        style="width: 15px"
                        src="../assets/black-cycleTime.svg"
                        alt=""
                        v-if="event.event === '定期投放'"
                      />
                      <img
                        class="mb-1"
                        src="../assets/black-sign.png"
                        alt=""
                        v-if="event.event === '註冊'"
                      />
                      <img
                        class="mb-1"
                        src="../assets/black-shop.png"
                        alt=""
                        v-if="event.event === '購物車未結'"
                      />
                      {{ event.event }}</span
                    >
                  </p>
                  <p class="m-0 mx-3 mb-1 d-flex justify-content-start">
                    <span class="me-4">劇本狀態</span>
                    <span
                      style="color: #76d37a"
                      v-if="
                        event.start_time >
                          new Date().toISOString().slice(0, 10) &&
                        event.has_event == 2
                      "
                    >
                      <img
                        src="../assets/light-green-spot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      啟用中</span
                    >
                    <span
                      style="color: #6bb996"
                      v-if="
                        event.start_time <=
                          new Date().toISOString().slice(0, 10) &&
                        event.has_event == 2
                      "
                    >
                      <img
                        src="../assets/dark-green-spot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      執行中</span
                    >
                    <span
                      style="color: #c4c4c4"
                      v-else-if="event.has_event == 1"
                    >
                      <img
                        src="../assets/graySpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      草稿
                    </span>
                    <span
                      style="color: #575757"
                      v-else-if="event.has_event == 3"
                    >
                      <img
                        src="../assets/blackBigSpot.png"
                        alt=""
                        style="width: 5px; margin-bottom: 2px"
                      />
                      已完成
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="demo-app-main right-container ms-4"
        style="position: relative"
      >
        <!-- <div style="position: absolute; top: 29px; right: 160px; z-index: 69">
          <button
            class="basic-btn"
            style="height: 34px; width: 40px"
            @click="showYearCalen()"
          >
            年
          </button>
          <button
            class="basic-btn"
            style="height: 34px; width: 40px"
            @click="showMonthCalen()"
          >
            月
          </button>
        </div> -->
        <div v-if="!this.isYear">
          <FullCalendar
            ref="fullCalendar"
            class="demo-app-calendar"
            :options="calendarOptions"
          >
            <template v-slot:eventContent="arg">
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
            </template>
          </FullCalendar>
        </div>
        <div v-if="this.isYear">
          <div
            class="
              text-center
              d-flex
              justify-content-between
              align-items-center
            "
            style="height: 35.89px; margin-bottom: 21px"
          >
            <div
              class="fc-toolbar-chunk fc flex-row align-items-center"
              style="margin: 0px; width: 140.06px"
            >
              <div class="fc-button-group" style="width: 81.38px">
                <button
                  type="button"
                  title="Previous month"
                  aria-pressed="false"
                  class="fc-prev-button fc-button fc-button-primary"
                  style="
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                  "
                  @click="moveyearCalendarLeft"
                >
                  <span class="fc-icon fc-icon-chevron-left"></span></button
                ><button
                  type="button"
                  title="Next month"
                  aria-pressed="false"
                  class="fc-next-button fc-button fc-button-primary"
                  style="
                    border-left: none;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  "
                  @click="moveyearCalendarRight"
                >
                  <span class="fc-icon fc-icon-chevron-right"></span>
                </button>
              </div>
              <button
                type="button"
                title="今天"
                aria-pressed="false"
                class="fc-myTodayButton-button fc-button fc-button-primary"
                style="margin-left: 10.5px; height: 34.1875px"
                @click="moveyearCalendarToday"
              >
                今天
              </button>
            </div>
            <div style="font-size: 24.5px">{{ this.yearCalenYear }}年</div>
            <div
              class="fc-toolbar-chunk fc"
              style="width: 100.56px; margin: 0px"
            >
              <div
                class="fc-button-group"
                style="height: 34.19px; line-height: 34.19px; font-size: 14px"
              >
                <button
                  type="button"
                  title="年"
                  aria-pressed="false"
                  class="fc-myCustomButton1-button fc-button fc-button-primary"
                  style="
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                  "
                  @click="this.showYearCalen"
                >
                  年</button
                ><button
                  type="button"
                  title="月"
                  aria-pressed="false"
                  class="fc-myCustomButton2-button fc-button fc-button-primary"
                  style="
                    border-radius: 0px;
                    border-right: none;
                    border-left: none;
                  "
                  @click="this.showMonthCalen"
                >
                  月</button
                ><button
                  type="button"
                  title="日"
                  aria-pressed="false"
                  class="fc-myCustomButton3-button fc-button fc-button-primary"
                  style="
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                  "
                  @click="this.showDayCalen"
                >
                  日
                </button>
              </div>
            </div>
          </div>
          <Calendar
            ref="yearCalendar"
            @dayclick="onDayClick"
            :columns="$screens({ default: 2, xl: this.mycol })"
            :rows="$screens({ default: 2, xl: this.myrow })"
            :is-expanded="$screens({ default: true, lg: true })"
            :attributes="attrs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import Paginator from "primevue/paginator";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import FullCalendar, {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg,
} from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import moment from "moment";
import axios from "axios";
import holidays from "../../src/plugins/holidays.json";
import { Calendar, DatePicker } from "v-calendar";
import SeeTriggerScript from "../components/SeeTriggerScript.vue";
import SeeScript from "../components/SeeScript.vue";
// import YearCalendar from 'vue-material-year-calendar'
import SmsPreview from "../components/SmsPreview.vue";
import EmailPreview from "../components/EmailPreview.vue";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  components: {
    // YearCalendar,
    Calendar,
    FullCalendar,
    Paginator,
    SeeTriggerScript,
    SeeScript,
    SmsPreview,
    EmailPreview,
    BtnInfoBox,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return { target, autoShow };
  },
  data() {
    return {
      d_template_key:'',
      newHtml:'',

      ul: [1, 2, 3, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      bottom: false,
      yearCalenYear: "",
      myWidth: window.innerWidth + "px",
      mycol: 2,
      myrow: 2,
      isYear: false,
      notshow: true,
      CustomShow: false,
      allColor: [
        "red",
        "orange",
        "yellow",
        "green",
        "blue",
        "indigo",
        "purple",
        "pink",
      ],
      attrs: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],

      calendarOptions: {
        customButtons: {
          myCustomButton1: {
            text: "年",
            click: this.showYearCalen,
          },
          myCustomButton2: {
            text: "月",
            click: this.showMonthCalen,
          },
          myCustomButton3: {
            text: "日",
            click: this.showDayCalen,
          },
          myTodayButton: {
            text: "今天",
            click: this.goToday,
          },
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next myTodayButton",
          center: "title",
          right: "myCustomButton1,myCustomButton2,myCustomButton3", //myCustomButton1
        },
        initialView: "dayGridMonth",
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        locale: "zh-tw",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        datesSet: this.changeMonth,

        events: [],

        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      // hoildayApi:"https://holidays.abstractapi.com/v1/?api_key=baeb792c038b4a0497431259610dda8d&country=US&year=2020&month=12&day=25",

      currentEvents: [],
      event_title: "",
      sches: [],
      seeTrigger: true,

      select_calen_id: "",
      select_calen_title: "",

      renameEvent: "",
      day: null,
      time: null,

      childtodo: "",
      isShow: "",
      isShow2: "",
      calendarPopshow: "",
      top: "",
      scheduleEvents: [],
      origintriggerEvents: [],
      triggerEvents: [],
      triggerTypes: [
        "全選",
        "註冊",
        "購物車未結",
        "購買後促銷",
        "訂單確認",
        "定期投放",
      ],
      triggerType: "全選",

      changedateModal: "",
      yearEventsModal: "",
      wantaddCalen: true,

      updateday: null,
      updatetime: null,
      calendarEvents: [],

      eventNameError: false,
      eventNameErrMsg: "",

      yearSelectDay: "",
      yearSelectEvents: [],
      nowMonth: "",
      nowYear: "",
      left: true,

      emailTable: [],
      smsTable: [],

      addadd: 0,
      scriptID: "",
      groupName: "",
      triggerModal: {},
      forceRerender: 0,

      scriptID2: "",
      addadd2: 0,
      emailChannel: true,

      Parr: [],
      todotext: "",
      exampleModal: {},
      exampleModal2: {},

      resSubsec: "",
      countText: "",
      html: "",
      subject: "",
      fullurlArr: [],

      child_cycleTimeState: "",
      child_cycleTime_setting: {},
    };
  },
  created: async function () {
    await this.getCalen();
    this.getEmailSche();
    this.getSmsTable();
    this.getEmailTable();
    this.getTriggerCalen();
    this.pushYearCalen();
    if (window.innerWidth < 1485) {
      console.log(window.innerWidth);
      this.mycol = 2;
      this.myrow = 2;
    } else if (1485 < window.innerWidth && window.innerWidth < 1900) {
      console.log(window.innerWidth);
      this.mycol = 3;
      this.myrow = 2;
    } else if (window.innerWidth > 1900) {
      this.mycol = 3;
      this.myrow = 3;
    }
    const dateString = new Date().toLocaleString();
    this.nowMonth = +dateString.split("/")[1];
    this.nowYear = +dateString.split("/")[0];

    const element = document.getElementsByClassName(
      "fc-myCustomButton2-button"
    );
    element[0].classList.add("highlight-btn");
  },
  mounted() {
    const vm = this;
    window.onresize = () => {
      if (window.innerWidth < 1485) {
        vm.mycol = 2;
        vm.myrow = 2;
      } else if (1485 < window.innerWidth && window.innerWidth < 1900) {
        vm.mycol = 3;
        vm.myrow = 2;
      } else if (window.innerWidth > 1900) {
        vm.mycol = 3;
        vm.myrow = 3;
      }
    };
  },
  watch: {
    triggerType() {
      console.log(this.origintriggerEvents);
      if (this.triggerType === "註冊") {
        this.triggerEvents = this.origintriggerEvents.filter((d) => {
          return d.event === "註冊";
        });
      } else if (this.triggerType === "購物車未結") {
        this.triggerEvents = this.origintriggerEvents.filter((d) => {
          return d.event === "購物車未結";
        });
      } else if (this.triggerType === "購買後促銷") {
        this.triggerEvents = this.origintriggerEvents.filter((d) => {
          return d.event === "購買後促銷";
        });
      } else if (this.triggerType === "訂單確認") {
        this.triggerEvents = this.origintriggerEvents.filter((d) => {
          return d.event === "訂單確認";
        });
      } else if (this.triggerType === "定期投放") {
        this.triggerEvents = this.origintriggerEvents.filter((d) => {
          return d.event === "定期投放";
        });
      } else {
        this.triggerEvents = this.origintriggerEvents;
      }
    },
    renameEvent(val) {
      var repeat = "";
      if (this.wantaddCalen) {
        repeat = this.calendarEvents.find((d) => {
          return d.title === val;
        });
      } else {
        repeat = this.calendarEvents.find((d) => {
          return d.title === val && d.id != this.select_calen_id;
        });
      }
      if (repeat != undefined) {
        this.eventNameError = true;
        this.eventNameErrMsg = "跟其他活動名稱重複了";
      } else {
        this.eventNameError = false;
        this.eventNameErrMsg = "";
      }
    },
  },

  methods: {
    // 拿SMS D箱子
    getTemplateById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.d_template_key,
      };

      await allapi2
        .smsTemplateSmsScheduleKeyGet(params)
        .then((res) => {
          console.log("todo", res);
          this.newHtml = res.data.content;
          this.newHtml = this.newHtml.replace(/{id}/g, "uid000");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿Email D箱子
    getHtmlById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.d_template_key,
      };

      await allapi2
        .templatev2HtmlKeyGet(params)
        .then((res) => {
          console.log(res);
          this.newHtml = res.data.content;
          this.newHtml = this.newHtml
            .replace(
              "{{p1}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
            )
            .replace(
              "{{p2}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
            )
            .replace(
              "{{p3}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
            )
            .replace(
              "{{p4}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
            )
            .replace("{{tracking}}", "");

        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    seeScript(data) {
      this.forceRerender++;
      setTimeout(() => {
        console.log(data);
        this.scriptID2 = data.script_id;
        this.addadd2 = this.addadd2 + 1;
        if (data.type === "Email") {
          this.emailChannel = true;
        } else {
          this.emailChannel = false;
        }
        sessionStorage.setItem("setResEventTitle", data.template_name);

        this.scriptModal = new bootstrap.Modal(
          document.getElementById("scriptModal"),
          {
            keyboard: false,
          }
        );
        this.scriptModal.show();
      });
    },
    seeTriggerScript(data) {
      this.forceRerender++;
      setTimeout(() => {
        this.addadd = this.addadd + 1;
        this.scriptID = data.script_id;
        this.groupName = this.select_calen_title;
        this.triggerModal = new bootstrap.Modal(
          document.getElementById("triggerModal"),
          {
            keyboard: false,
          }
        );
        this.triggerModal.show();
      });
    },
    showUnlayer: function (childHtml, childSub) {
      console.log(childHtml);
      this.html = childHtml;
      this.subject = childSub;
      console.log("123");
      this.changeAllUrl();
      this.exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      this.exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.html);
      console.log(this.fullurlArr);
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          console.log(this.html);
          this.html = this.html.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },

    // showSMSexp: function (childValue) {
    //   console.log(childValue);
    //   this.newText = childValue;
    //   this.newText = this.newText
    //     .replace(/{id}/g, this.randomString(6))
    //     .replace("{lastname}", "王")
    //     .replace("{firstname}", "◯◯")
    //     .replace("{name}", "王◯◯");
    //   this.resSubsec = childValue;
    //   this.resSubsec = this.resSubsec
    //     .replace(/{id}/g, this.randomString(6))
    //     .replace("{lastname}", "王")
    //     .replace("{firstname}", "◯◯")
    //     .replace("{name}", "王◯◯");
    //   this.countText = this.newText;

    //   this.highlightUrl();
    //   this.exampleModal2 = new bootstrap.Modal(
    //     document.getElementById("exampleModal2"),
    //     {
    //       keyboard: false,
    //     }
    //   );
    //   this.exampleModal2.show();
    //   const backdrop = document.getElementsByClassName("modal-backdrop");
    //   backdrop[backdrop.length - 1].style['z-index']=4500
    // },
    showSMSexp: function (childValue) {
      this.todotext = childValue;
      this.resSubsec = childValue;
      this.highlightUrl();
      this.exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style["z-index"] = 4500;
    },
    highlightUrl() {
      console.log(this.todotext);
      console.log(this.resSubsec);
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.todotext);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index].slice(0, -9) +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
      this.countText = this.todotext
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      this.resSubsec = this.resSubsec
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");

      console.log("this.countText", this.countText);
    },

    // 從預覽中顯示外層
    showOutsideExp: async function (childValue){
      console.log(childValue)
      if(childValue){
       await this.getHtmlById()
        this.showUnlayer(this.newHtml)
      }else{
       await this.getTemplateById()
        this.showSMSexp(this.newHtml)
      }

    },

    moveyearCalendarLeft() {
      this.$refs.yearCalendar.move(-(this.mycol * this.myrow));
      setTimeout(() => {
        const yearText = document
          .getElementsByClassName("vc-title")[1]
          .innerText.slice(-4);
        this.yearCalenYear = yearText;
      }, 400);
      this.left = true;
    },
    moveyearCalendarRight() {
      this.$refs.yearCalendar.move(this.mycol * this.myrow);
      setTimeout(() => {
        const yearText = document
          .getElementsByClassName("vc-title")[1]
          .innerText.slice(-4);
        this.yearCalenYear = yearText;
      }, 400);
      this.left = false;
    },
    moveyearCalendarToday() {
      if (this.left) {
        this.$refs.yearCalendar.move({
          month: this.nowMonth,
          year: this.nowYear,
        });
      } else {
        this.$refs.yearCalendar.move({
          month: this.nowMonth + this.mycol * this.myrow - 1,
          year: this.nowYear,
        });
      }
      setTimeout(() => {
        const yearText = document
          .getElementsByClassName("vc-title")[1]
          .innerText.slice(-4);
        this.yearCalenYear = yearText;
      }, 400);
    },

    goToday() {
      this.$refs.fullCalendar.getApi().today();
    },
    showYearCalen() {
      this.isYear = true;
      setTimeout(() => {
        const yearText = document
          .getElementsByClassName("vc-title")[1]
          .innerText.slice(-4);
        this.yearCalenYear = yearText;

        const element = document.getElementsByClassName(
          "fc-myCustomButton1-button"
        );
        element[0].classList.add("highlight-btn");

        const left = document.getElementsByClassName("vc-arrow is-left");
        const right = document.getElementsByClassName("vc-arrow is-right");
        left[0].style.display = "none";
        right[0].style.display = "none";
      }, 100);
    },
    showMonthCalen() {
      this.isYear = false;
      setTimeout(() => {
        this.$refs.fullCalendar.getApi().changeView("dayGridMonth");
        const element = document.getElementsByClassName(
          "fc-myCustomButton2-button"
        );
        element[0].classList.add("highlight-btn");
        const element2 = document.getElementsByClassName(
          "fc-myCustomButton3-button"
        );
        element2[0].classList.remove("highlight-btn");
      }, 100);
    },
    showDayCalen() {
      this.isYear = false;
      setTimeout(() => {
        this.$refs.fullCalendar.getApi().changeView("timeGridWeek");
        const element = document.getElementsByClassName(
          "fc-myCustomButton3-button"
        );
        element[0].classList.add("highlight-btn");
        const element2 = document.getElementsByClassName(
          "fc-myCustomButton2-button"
        );
        element2[0].classList.remove("highlight-btn");
      }, 100);
    },
    onDayClick(day) {
      this.yearEventsModal = new bootstrap.Modal(
        document.getElementById("yearEventsModal"),
        {
          keyboard: false,
        }
      );
      this.yearEventsModal.show();
      this.yearSelectDay = day.ariaLabel;
      this.yearSelectEvents = this.calendarEvents.filter((d) => {
        return day.id < d.end && day.id > d.start;
      });
      console.log(this.yearSelectEvents);
      // this.yearEventName: "",
      // this.yearEventStr: "",
      // this.yearEventEnd: "",
    },
    pushYearCalen() {
      let i = 0;
      this.calendarEvents.forEach((e) => {
        this.attrs.push({
          key: e.id,
          dot: this.allColor[i],
          dates: { start: e.start, end: e.end },
        });
        i++;
        if (i > 9) {
          i = 0;
        }
      });
      console.log(this.attrs);
    },
    changeMonth() {
      this.addHolidays();
    },
    addHolidays() {
      const hasHoliday = holidays.filter((d) => d.description != "");
      document.querySelectorAll("td[data-date]").forEach((d) => {
        hasHoliday.forEach((h) => {
          if (
            h.date.slice(0, 4) +
              "-" +
              h.date.slice(4, 6) +
              "-" +
              h.date.slice(-2) ===
            d.getAttribute("data-date")
          ) {
            return d
              .getElementsByClassName("fc-daygrid-day-top")[0]
              .getElementsByTagName("a")[0]
              .setAttribute("data-text", h.description);
          }
        });
      });
    },

    colorBg() {
      this.calendarOptions.events.forEach((d) => {
        if (new Date(d.end) < new Date()) {
          d.backgroundColor = "#EBE6F8";
        } else if (new Date(d.start) > new Date()) {
          d.backgroundColor = "#FC9F66";
        } else {
          d.backgroundColor = "#71AFB6";
        }
      });
    },
    popoutInCalen(todo, index) {
      console.log(todo, index);
      this.d_template_key=todo.template_id
      if (this.isShow != todo.Name) {
        this.isShow = todo.Name;
      } else {
        this.isShow = "";
      }
    },
    triggerpopoutInCalen(todo, index) {
      console.log(todo);
      this.childtodo = todo;
      if (this.isShow2 != todo.script_id) {
        this.isShow2 = todo.script_id;
      } else {
        this.isShow2 = "";
      }
      // this.top = {
      //   top:
      //     422 +
      //     126 * index -
      //     document.getElementById("overheight").scrollTop +
      //     "px",
      // };
    },
    handleDateSelect(selectInfo) {
      this.changedateModal = new bootstrap.Modal(
        document.getElementById("changedateModal"),
        {
          keyboard: false,
        }
      );
      this.changedateModal.show();
      this.wantaddCalen = true;
      this.day = "";
      this.time = "";
      this.renameEvent = "";

      if (selectInfo === undefined) {
        return;
      }

      let calendarApi = selectInfo.view.calendar;
      console.log("123", selectInfo);
      const startDate = moment(selectInfo.start)
        .add(1, "days")
        .toISOString()
        .substring(0, 10);
      const endDate = moment(selectInfo.end).toISOString().substring(0, 10);
      console.log(endDate);

      this.day = startDate;
      this.time = endDate;

      calendarApi.unselect(); // clear date selection

      // if (title) {
      //   this.addCalen(title, selectInfo.startStr, endDate);
      // }
    },
    handleEventClick: async function (clickInfo) {
      console.log(clickInfo);
      this.select_calen_id = clickInfo.event.id;
      this.wantaddCalen = false;

      this.seeTrigger = true;

      await this.getEvents();
      this.select_calen_title = clickInfo.event.title;
      this.updateday = moment(clickInfo.event.start)
        .add(1, "days")
        .toISOString()
        .substring(0, 10);
      this.updatetime = moment(clickInfo.event.end)
        .toISOString()
        .substring(0, 10);
    },
    yearEventClick: async function (event) {
      this.yearEventsModal.hide();
      this.select_calen_id = event.id;
      this.wantaddCalen = false;
      await this.getEvents();
      this.select_calen_title = event.title;
      this.updateday = moment(event.start)
        .add(1, "days")
        .toISOString()
        .substring(0, 10);
      this.updatetime = moment(event.end).toISOString().substring(0, 10);
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    getEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        activity_id: this.select_calen_id,
      };

      await allapi2
        .activityCalenderActivityIdGet(params)
        .then((res) => {
          console.log(res);
          this.sches = res.data.Data;
          this.sches.map((e) => {
            if (e.event === "購買行銷" && e.event != undefined) {
              e.event = "購買後促銷";
            } else if (e.event === "購買" && e.event != undefined) {
              e.event = "訂單確認";
            }
            this.emailTable.map((d) => {
              if (d.ruleID === e.script_id && d.發送數量 === 0) {
                e.傳送數量 = 0;
              }
            });
            this.smsTable.map((d) => {
              if (d.script_id === e.script_id && d.傳送數量 === 0) {
                e.傳送數量 = 0;
              }
            });
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getCalen: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          this.calendarEvents = res.data.Data;
          const events = res.data.Data;
          events.forEach((d) => {
            d.start = new Date(d.start).toISOString().substring(0, 10);
            d.end = moment(new Date(d.end))
              .add(1, "days")
              .toISOString()
              .substring(0, 10);
          });
          this.calendarOptions.events = events;
          console.log(this.calendarOptions.events);
          this.colorBg();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    addCalen: async function () {
      if (this.renameEvent === "") {
        alert("必須輸入活動名稱");
        return;
      } else if (this.day === "" || this.time === "") {
        alert("必須輸入活動時間");
        return;
      } else if (this.eventNameError) {
        alert("跟其他活動名稱重複了");
        return;
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        activity_id: Date.now(),
        name: this.renameEvent,
        start: this.day,
        end: this.time,
      };

      await allapi2
        .activityPost(params, body)
        .then((res) => {
          console.log(res);
          this.getCalen();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    deleteCalen: async function () {
      var yes = confirm("刪除後即無法返回，是否確認刪除?");

      if (yes) {
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
          activity_id: this.select_calen_id,
        };

        await allapi2
          .activityActivityIdDelete(params)
          .then((res) => {
            console.log(res);
            if (res.data.message === "The activity is not empty") {
              alert("此活動內有投放排程，將無法被刪除");
            } else {
              this.getCalen();
              this.select_calen_title = "";
            }
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
          });
      }
    },
    wantupdateCalen() {
      this.wantaddCalen = false;
      this.renameEvent = this.select_calen_title;
      this.day = this.updateday;
      this.time = this.updatetime;
    },
    updateCalen: async function () {
      if (this.renameEvent === "") {
        alert("必須輸入活動名稱");
        return;
      } else if (this.day === "" || this.time === "") {
        alert("必須輸入活動時間");
        return;
      } else if (this.eventNameError) {
        alert("跟其他活動名稱重複了");
        return;
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        activity_id: this.select_calen_id,
        name: this.renameEvent,
        start: this.day,
        end: this.time,
      };
      await allapi2
        .activityPatch(params, body)
        .then((res) => {
          console.log(res);
          this.getCalen();
          this.select_calen_title = this.renameEvent;
          this.updateday = this.day;
          this.updatetime = this.time;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    getEmailSche: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .scheduleGet(params)
        .then((res) => {
          console.log("3 item", res);
          this.emailSches = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    getSmsTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .smsTableGet(params)
        .then((res) => {
          console.log("smstable", res);
          this.SmsTable = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getEmailTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .emailTableGet(params)
        .then((res) => {
          console.log("emailtable", res);
          this.emailTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    getTriggerCalen: async function (labelid) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .activityCalenderTriggerGet(params)
        .then((res) => {
          console.log("觸發劇本行事曆", res);
          res.data.觸發劇本.forEach((d) => {
            if (d.event === "購買行銷") {
              d.event = "購買後促銷";
            } else if (d.event === "購買") {
              d.event = "訂單確認";
            }
          });
          this.triggerEvents = res.data.觸發劇本;
          this.origintriggerEvents = res.data.觸發劇本;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    routergoSet(todo) {
      if (new Date() > moment(todo.ScheduleExpression).add(-1, "h")._d) {
        return alert("此排程將在1小時內執行，已無法在進行更改與刪除");
      }
      if (todo.type === "Email") {
        this.$router.push({
          name: "MarketingSchedule",
          params: {
            scheID: todo.Name,
          },
        });
      } else {
        this.$router.push({
          name: "SmsSchedule",
        });
      }
    },
    routergoPerformance: async function (todo) {
      console.log(todo);
      sessionStorage.setItem("has_script", todo.has_event);
      sessionStorage.setItem("hasAB", todo.AB);
      sessionStorage.setItem("rule_id", todo.script_id);
      sessionStorage.setItem("template_id", todo.template_id);
      if (todo.type === "Email" && todo.傳送數量 != 0) {
        this.$router.push({
          name: "ResultPerformance",
        });
      } else if (todo.type === "SMS" && todo.傳送數量 != 0) {
        this.$router.push({
          name: "SmsResultPerformance",
        });
      }
    },
    routergoScriptTable: async function () {
      this.$router.push({
        name: "ScriptTable",
      });
    },
    deleteModal(todo) {
      this.childtodo = todo;
      let deleteyes = confirm(
        "刪除後即無法返回，是否確認刪除?\n執行前的1小時內將無法進行更改與刪除"
      );
      if (deleteyes) {
        if (todo.type === "Email") {
          this.deleteEmail();
        } else {
          this.deleteSms();
        }
      } else {
        return;
      }
      console.log(todo);
    },
    deleteEmail: async function () {
      var yes = confirm("刪除後即無法返回，是否確認刪除?");

      if (yes) {
        console.log(moment(this.childtodo.ScheduleExpression).add(-1, "h")._d);
        console.log(new Date());
        console.log(
          new Date() > moment(this.childtodo.ScheduleExpression).add(-1, "h")._d
        );
        if (
          new Date() > moment(this.childtodo.ScheduleExpression).add(-1, "h")._d
        ) {
          return alert("此排程將在1小時內執行，已無法在進行更改與刪除");
        }
        const token = sessionStorage.getItem("access_token");

        const params = {
          Authorization: "Bearer " + token,
          ruleID: this.childtodo.Name,
        };

        await allapi2
          .scheduleRuleIDDelete(params)
          .then((res) => {
            console.log(res);
            this.getEvents();
          })
          .catch((err) => {
            console.log("連線有誤");
            alert("delete連線有誤" + err);
            this.loading = false;
            console.log(err);
          });
      }
    },
    deleteSms: async function () {
      var yes = confirm("刪除後即無法返回，是否確認刪除?");

      if (yes) {
        if (
          new Date() > moment(this.childtodo.ScheduleExpression).add(-1, "h")._d
        ) {
          this.loading = false;
          return alert("此排程將在1小時內執行，已無法在進行更改與刪除");
        }
        console.log(this.childtodo.Name);
        const token = sessionStorage.getItem("access_token");

        const params = {
          Authorization: "Bearer " + token,
          scheduleName: this.childtodo.Name,
        };

        await allapi2
          .smsScheduleScheduleNameDelete(params)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log("連線有誤");
            alert("delete連線有誤" + err);
            this.loading = false;
            console.log(err);
          });
        await this.get();
      }
    },
    wantdeleteScript() {
      let deleteyes = confirm("刪除後即無法返回，是否確認刪除?");
      if (deleteyes) {
        this.deleteScriptApi();
      } else {
        return;
      }
    },
    deleteScriptApi() {
      this.loading = true;
      console.log("this.eventScriptIdFromSche", this.eventScriptIdFromSche);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.childtodo.script_id,
      };
      allapi2
        .triggerScriptScriptIdDelete(params)
        .then((res) => {
          console.log("成功刪除劇本", res);
          this.getScriptTable();

          this.editList = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          this.loading = false;
          console.log(err);
          alert("連線有誤" + err);
        });
    },
  },
};
</script>



<style >
/* FullCalendar */
.fc-col-header th:last-child {
  border-top-right-radius: 0px;
}
.overheight {
  height: calc(45vw - 110px);
}
.popout-in-calendar {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -62px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.home {
  padding-top: 53px;
}
.fc .fc-button-primary {
  border-color: white;
  background-color: #eaf3f4;
  color: rgba(58, 56, 56, 0.966);
  border-radius: 10px;
}
.fc .fc-button-primary:hover {
  border-color: white;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.fc .fc-button-primary:hover > .fc-icon {
  color: white;
}
.fc .fc-button .fc-icon {
  vertical-align: middle;
  font-size: 1.5em;
  color: rgba(58, 56, 56, 0.966);
}
.fc .fc-button-primary:disabled {
  background-image: linear-gradient(to bottom right, #eaf3f4, #eaf3f4);
  border-color: white;
  background-color: #eaf3f4;
  color: rgba(58, 56, 56, 0.966);
  opacity: 1;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  border-color: white;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}
.fc .fc-button-primary:focus {
  box-shadow: none;
}
.popoutInCalenTop {
  background-color: #fff;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  left: calc(15% + 330px);
  z-index: 2000;
  width: 100px;
}
.popoutInCalen {
  background-color: #fff;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  z-index: 2000;
  width: 140px;
  left: -90px;
}
.popoutInCalen > p:first-child:hover {
  border-radius: 10px 10px 0 0;
  background-color: #eaf3f4;
  color: #575757;
}
.popoutInCalen > p:last-child:hover {
  border-radius: 0 0 10px 10px;
  background-color: #eaf3f4;
  color: #575757;
}
.popoutInCalen > p:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.vc-pane-container {
  width: 100%;
}
h2 {
  margin: 0;
  font-size: 16px;
}
b {
  /* used for event dates/times */
  margin-right: 3px;
}
.demo-app-main a {
  color: #2c3e50;
  text-decoration: none;
}

.basic-btn-calen {
  height: 40px;
  border-radius: 10px;
  border: none;
  background-color: #eaf3f4;
  font-size: 18px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  max-height: 100%;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 315px;
  line-height: 1.5;
}

.left-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  height: 45vw;
}
.right-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  padding: 25px;
  /* height: 785px; */
}
.highlight-btn {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}

.demo-app-main {
  flex-grow: 1;
  padding: 2em;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
.fc-h-event {
  border: none;
}
.fc-daygrid-day-top ::after {
  content: attr(data-text);
  color: rgba(247, 70, 70, 0.89);
  width: fit-content;
  position: absolute;
  left: -73px;
}
.yearevent {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: 10px;
}
.ellipsis-templateName-inFullCalendar {
  display: inline-block;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tipInFullCalendar {
  opacity: 0.7 !important;
  padding: 3px 6px !important;
  background-color: #575757;
  border: 1px solid #575757;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  /* height: 30px; */
  /* line-height: 30px; */
}
.ellipsis-scriptName-inFullCalendar {
  display: inline-block;
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 1500px) {
  .fc-daygrid-day-top ::after {
    content: attr(data-text);
    color: rgba(247, 70, 70, 0.89);
    width: 50px;
    position: absolute;
    left: -45px;
  }
}
</style>