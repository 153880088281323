<template>
  <div v-if="!notshow" class="d-flex justify-content-center align-items-center">
    <span class="Gray77"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <div class="d-flex justify-content-between mb-4">
      <span class="title-span fs-medium">
        <img
          src="../assets/back-arrow.png"
          @click="$router.go(-1)"
          style="cursor: pointer"
          class="mb-1"
        />
        &nbsp; &nbsp; 標籤報表資訊
        <BtnInfoBox content="此模組提供您所選取的名單輪廓樣貌資料與其KPI，並以月或年的方式檢閱。系統會根據該標籤條件的人群往回取得三個月資料，並且開始每月自動記錄該標籤條件的效益表現。" direction="right"></BtnInfoBox>

      </span>
      <div class="d-flex justify-content-between">
        <div class="group-btn me-4">
          <button
            class="basic-btn-black"
            :class="{'highlight-btn': yearOrMonth === 'month'}"
            @click="changeMonthYear('month')"
          >
            月
          </button>
          <button
            class="basic-btn-black"
            :class="{'highlight-btn': yearOrMonth === 'year'}"
            @click="changeMonthYear('year')"
          >
            年
          </button>
        </div>
        <div v-if="yearOrMonth === 'month'" style="position: relative">
          <img src="../assets/dropdownArrowBlack.svg" class="icon-down" />
          <Datepicker
            v-model="monthData"
            locale="tw"
            autoApply
            monthPicker
            :format="format"
            :clearable="false"
            inputClassName="dp-custom-input"
            :minDate="minDate"
            :maxDate="maxDate"
          >
            <template #input-icon>
              <img src="../assets/date.svg" class="icon-date" />
            </template>
          </Datepicker>
        </div>
        <div style="position: relative" v-else>
          <img src="../assets/dropdownArrowBlack.svg" class="icon-down" />
          <Datepicker
            v-model="yearData"
            locale="tw"
            autoApply
            :clearable="false"
            yearPicker
            inputClassName="dp-custom-input"
            :minDate="minDate"
            :maxDate="maxDateYear"
          >
            <template #input-icon>
              <img src="../assets/date.svg" class="icon-date" />
            </template>
          </Datepicker>
        </div>
      </div>
    </div>
    <div class="p-4 shadow-box d-flex align-items-center mb-4">
      <span class="me-4 sCyan-2">名單名稱</span>
      <span class="font-22">{{ labelNames }}</span>
    </div>
    <div class="flex-wrap-2 mb-4">
      <div class="p-4 shadow-box d-flex justify-content-between align-items-center">
        <span class="sCyan-2">總人數</span>
        <div class="m-r-60">
          <span class="font-22">{{ headCount?.head_count ? Number(headCount.head_count).toLocaleString() : '--' }}</span>&nbsp;&nbsp;
          <span class="font-16 Gray77">(人)</span>
        </div>
      </div>
      <div></div>
      <div class="p-4 shadow-box d-flex justify-content-between align-items-center">
        <span class="sCyan-2">人數占比</span>
        <div class="m-r-60">
          <span class="font-22">{{ headCount?.hc_percentage ? headCount.hc_percentage : '--' }}</span>&nbsp;&nbsp;
          <span class="font-16 Gray77">(%)</span>
        </div>
      </div>
    </div>
    <div class="flex-wrap-2 mb-4">
      <div
        class="shadow-box"
        style="height: 361px; padding: 19px 16px 15px 15px"
      >
        <div class="sCyan-2" style="margin-left: 10px">輪廓樣貌組合</div>
        <div id="barLook" style="position: relative">
          <span
            style="position: absolute; top: 35%; left: 40%;"
            class="Gray77"
            v-if="seriesBarLook[0]?.data?.every((d) => d === 0) && seriesBarLook[1]?.data?.every((d) => d === 0)"
            >此時間範圍無資料</span
          >
          <apexchart
            type="bar"
            height="300"
            :options="chartOptionsBarLook"
            :series="seriesBarLook"
          ></apexchart>
        </div>
      </div>
      <div></div>
      <div
        class="shadow-box"
        style="height: 361px; padding: 19px 16px 15px 15px"
      >
        <div class="sCyan-2" style="margin-bottom: 14px; margin-left: 10px">
          其他標籤組合
          <BtnInfoBox content="快速查看這群會員身上「該月」所具備的標籤(若比例大於該標籤在整體會員的佔比即會顯示)。例子：【瀏覽A產品】標籤在這群會員佔了30%，而【瀏覽A產品】在整體會員占20%，該標籤則會顯示。" direction="right"></BtnInfoBox>
<!-- 
          <button class="btn btn-default button px-0 mx-0 pt-0">
            <span>
              <img
                src="../assets/question-img.svg"
                style="width: 20px; margin-bottom: 2px"
                @mouseover="isShow2 = true"
                @mouseout="isShow2 = false"
              />
            </span>
            <div class="parent-popout">
              <div v-show="isShow2" class="child-popout-inTagReport-second font-12">
                快速查看這群會員身上「該月」所具備的標籤
                (若比例大於該標籤在整體會員的佔比即會顯示)。
                <br />
                例子：【瀏覽A產品】標籤在這群會員佔了30%，而【瀏覽A產品】在整體會員占20%，該標籤則會顯示。
                <div class="container__arrow"></div>
              </div>
            </div>
          </button> -->
        </div>
        <div
          class="d-flex flex-wrap justify-content-start align-content-start other-tags-item"
          v-if="Object.keys(tags).length != 0"
        >
          <button
            class="orange-tagbtn b-GrayGreen"
            v-for="(tag, index) in tags?.AI"
            :key="index"
          >
            {{ tag }}
          </button>
          <button
            class="orange-tagbtn b-GrayCyan"
            v-for="(tag, index) in tags?.渠道"
            :key="index"
          >
            {{ tag }}
          </button>
          <button
            class="orange-tagbtn b-GrayBlue"
            v-for="(tag, index) in tags?.瀏覽產品類別"
            :key="index"
          >
            {{ tag }}
          </button>
          <button
            class="orange-tagbtn b-GrayOrange"
            v-for="(tag, index) in tags?.購買產品類別"
            :key="index"
          >
            {{ tag }}
          </button>
          <button
            class="orange-tagbtn b-GrayRed"
            v-for="(tag, index) in tags?.其他"
            :key="index"
          >
            {{ tag }}
          </button>
        </div>
        <div
          class="d-flex flex-wrap justify-content-start align-content-start other-tags-item Gray77"
          v-else
        >
          此時間範圍無資料
        </div>
      </div>
    </div>
    <div id="selected" class="shadow-box mb-4 table-box">
      <DataTable :value="selectedTable" :scrollable="true">
        <template #header>
          <div class="sCyan-2" style="margin-bottom: 16px; margin-left: 5px">
            關鍵消費表現
          </div>
        </template>
        <Column field="item" :sortable="false" draggable="true">
          <template #body="slotProps">
            <div class="spending-col" >
              <span>
                {{ slotProps.data.item }}
              </span>
            </div>
          </template>
        </Column>
        <Column field="now" :sortable="false" draggable="true">
          <template #header>
            <div class="box-f-center" v-show="tableYearMonth">
              {{ tableYearMonth.includes('-') ?
                Number(tableYearMonth.split('-')[1]).toLocaleString() + '月' :
                tableYearMonth + '年'}}
            </div>
          </template>
          <template #body="slotProps">
            <div class="box-f-center">
              <span>
                {{ slotProps?.data?.now != null ? Number(slotProps?.data?.now).toLocaleString() : '--' }}
                {{ slotPropsUnit(slotProps?.data?.item) }}
              </span>
              <span class="font-12 Gray77">
                {{ avgViewText(slotProps.data.avg, slotProps.data.item) }}
              </span>
            </div>
          </template>
        </Column>
        <Column field="yoy" :sortable="false" draggable="true">
          <template #header>
            <div class="box-f-center">
              {{ yearOrMonth === 'month' ? '上個月同比' : '上個年同比'}}
            </div>
          </template>
          <template #body="slotProps">
            <div class="box-f-center">
              <span :class="slotProps.data.yoy > 0 ? 'lightRed' : 'sCyan'" v-if="slotProps?.data?.yoy != null">
                <img
                  src="../assets/redUpArrow.svg"
                  v-if="slotProps.data.yoy > 0"
                  class="mb-1 me-1"
                />
                <img
                  src="../assets/greenDownArrow.svg"
                  v-else
                  class="mb-1 me-1"
                />
                {{ slotProps?.data?.yoy + '%' }}
              </span>
              <span v-else>--</span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex-wrap-2">
      <div class="shadow-box mb-4" style="height: 520px; padding: 19px 16px 15px 15px">
        <div class="sCyan-2 h-40" >
          <div>新舊客分布</div>
        </div>
        <div class="Gray77" >
          <span>新舊客比例</span>
        </div>
        <div id="pieVisit">
          <apexchart
            type="donut"
            height="200"
            :options="chartOptionsOldnew"
            :series="seriesOldnew"
            v-if="Array.isArray(seriesOldnew) && seriesOldnew?.length > 0"
          ></apexchart>
          <div v-else class="no-data-table Gray77">
            <img src="../assets/missData.svg" style="width: 200px" />
            <br />
            <span>無資料</span>
          </div>
          <div>
            <DataTable :value="oldCustTable" class="interaction-table" responsiveLayout="scroll" showGridlines >
              <Column field="item" >
                <template #body="slotProps">
                  <div class="interaction-table-col" >
                    <span>
                      {{ slotProps.data.item }}
                    </span>
                  </div>
                </template>
              </Column>
              <Column field="now" >
                <template #header>
                  <div class="" v-show="tableYearMonth">
                    {{ tableYearMonth.includes('-') ?
                      Number(tableYearMonth.split('-')[1]).toLocaleString() + '月' :
                      tableYearMonth + '年'}}
                  </div>
                </template>
                <template #body="slotProps">
                  <div class="box-f-center">
                    <span>
                      {{ slotProps?.data?.now != null ? Number(slotProps?.data?.now).toLocaleString() : '--' }}
                      {{ slotPropsUnit(slotProps?.data?.item) }}
                    </span>
                  </div>
                </template>
              </Column>
              <Column field="yoy" :header="yearOrMonth === 'month' ? '上個月同比' : '上個年同比'" >
                <template #body="slotProps">
                  <div class="box-f-center">
                    <span :class="slotProps.data.yoy > 0 ? 'lightRed' : 'sCyan'" v-if="slotProps?.data?.yoy != null">
                      <img
                        src="../assets/redUpArrow.svg"
                        v-if="slotProps.data.yoy > 0"
                        class="mb-1 me-1"
                      />
                      <img
                        src="../assets/greenDownArrow.svg"
                        v-else
                        class="mb-1 me-1"
                      />
                      {{ slotProps?.data?.yoy + '%' }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <div></div>
      <div class="shadow-box mb-4" style="height: 520px; padding: 19px 16px 15px 15px">
        <div class="flex-wrap-2">
          <div class="sCyan-2 h-40" >
            <div>
              <span>行銷互動</span>
              &nbsp;
              <BtnInfoBox content="會員溝通評分機制，智能計算各渠道的回應溝通表現，您亦可利用此分群進行再行銷；詳情計算方式請見標籤一覽表。" direction="right"></BtnInfoBox>

            </div>
          </div>
          <div></div>
          <div class="search-container" ref="target">
            <div
              class="d-flex justify-content-between align-items-center"
              @click="autoShow = !autoShow"
            >
              {{ channelRespond }}
              <img src="../assets/selection-arrow.png" alt="searchImg" />
            </div>
            <div
              class="auto-complicate-div"
              v-show="autoShow"
              @mouseenter="autoShow = true"
            >
              <p
                class="m-0 p-1 ps-3 auto-complicate-p"
                @click="
                  channelRespond = 'Email';
                  autoShow = false;
                "
              >
                Email
              </p>
              <p
                class="m-0 p-1 ps-3 auto-complicate-p"
                @click="
                  channelRespond = 'SMS';
                  autoShow = false;
                "
              >
                SMS
              </p>
            </div>
          </div>
        </div>
        <div class="Gray77" >
          <span>渠道互動評級</span>
        </div>
        <div id="channels">
          <apexchart
            type="donut"
            height="180"
            :options="chartOptionsChannels"
            :series="seriesChannels[channelRespond]"
            v-if="Array.isArray(seriesChannels[channelRespond]) && seriesChannels[channelRespond]?.length > 0&& seriesChannels[channelRespond].every(d=>d!=0)"
          ></apexchart>
          <div v-else class="no-data-table Gray77">
            <img src="../assets/missData.svg" style="width: 171px" />
            <br />
            <span>無資料</span>
          </div>
          <div>
            <DataTable :value="interactionTable[channelRespond]" class="interaction-table" responsiveLayout="scroll" showGridlines >
              <Column field="item">
                <template #body="slotProps">
                  <div class="interaction-table-col" >
                    <span>
                      {{ slotProps.data.item }}
                    </span>
                  </div>
                </template>
              </Column>
              <Column field="now">
                <template #header>
                  <div class="" v-show="tableYearMonth">
                    {{ tableYearMonth.includes('-') ?
                      Number(tableYearMonth.split('-')[1]).toLocaleString() + '月' :
                      tableYearMonth + '年'}}
                  </div>
                </template>
                <template #body="slotProps">
                  <div class="box-f-center">
                    <span>
                      {{ slotProps?.data?.now != null ? Number(slotProps?.data?.now).toLocaleString() : '--' }}
                      {{ slotPropsUnit(slotProps?.data?.item) }}
                    </span>
                    <span class="font-12 Gray77">
                      {{ avgViewText(slotProps.data.avg, slotProps.data.item) }}
                    </span>
                  </div>
                </template>
              </Column>
              <Column field="yoy">
                <template #header>
                  <div class="">
                    {{ yearOrMonth === 'month' ? '上個月同比' : '上個年同比'}}
                  </div>
                </template>
                <template #body="slotProps">
                  <div class="box-f-center">
                    <span :class="slotProps.data.yoy > 0 ? 'lightRed' : 'sCyan'" v-if="slotProps?.data?.yoy != null">
                      <img
                        src="../assets/redUpArrow.svg"
                        v-if="slotProps.data.yoy > 0"
                        class="mb-1 me-1"
                      />
                      <img
                        src="../assets/greenDownArrow.svg"
                        v-else
                        class="mb-1 me-1"
                      />
                      {{ slotProps?.data?.yoy + '%' }}
                    </span>
                    <span v-else>--</span>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <div id="prodBuyBox" class="shadow-box mb-4 table-box"> 
      <DataTable :value="prodBuyTable" :scrollable="true" scrollHeight="300px">
          <template #header>
            <div class="sCyan-2" style="margin-bottom: 16px; margin-left: 5px">
              TOP10同時購買商品
            </div>
          </template>
        <Column field="item" :sortable="false" draggable="true">
          <template #header>
            <div class="prodBuy-h">
              商品名稱
            </div>
          </template>
          <template #body="slotProps">
            <div class="prodBuy-col">
              <span>
                {{ slotProps.data?.product }}
              </span>
            </div>
          </template>
        </Column>
        <Column field="oder" :sortable="false" draggable="true">
          <template #header>
            <div class="box-f-center prodBuy-h">
              購買次數
            </div>
          </template>
          <template #body="slotProps">
            <div class="box-f-center">
              <span>
                {{  Number(slotProps?.data?.count).toLocaleString() + '(次)' }}
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
      <div v-show="prodBuyTable?.length == 0" class="no-data-table Gray77">
        <span>無資料</span>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-center">
      <button
        class="
          basic-btn-black
          highlight-btn
          d-flex
          align-items-center
          justify-content-center
        "
        style="width: 50%"
        @click="getPerformanceCSV"
      >
        <img src="../assets/upload-white.svg" alt="" class="" />
        <span class="ms-3">匯出報表資料</span>
      </button>
    </div> -->
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { ref } from "vue";
import VueApexCharts from "vue3-apexcharts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import moment from "moment";
import { def } from "@vue/shared";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "TagReport",
  components: {
    DataTable,
    Column,
    apexchart: VueApexCharts,
    Datepicker,
    BtnInfoBox,
  },
  setup() {
    const monthData = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });
    const yearData = ref(new Date().getFullYear());

    const format = (monthData) => {
      const month = monthData.month + 1;
      const year = monthData.year;

      return `${year}年${month}月`;
    };

    return {
      monthData,
      format,
      yearData,
      // tw,
    };
  },
  data() {
    return {
      loading: false,
      notshow: true,
      isShow: false,
      isShow2: false,
      isShow3: false,
      autoShow: false,

      minDate: "",
      maxDate: "",
      maxDateYear: "",
      yearOrMonth: "month",
      selectMonthYear: "",

      /// 關鍵消費表現表格
      selectedTable: [
        {
          "item": "累計(平均)消費金額",
          "yoy": null,
          "now": null,
          "avg": null,
        },
        {
          "item": "累計(平均)訂單數",
          "yoy": null,
          "now": null,
          "avg": null,
        },
        {
          "item": "平均客單價",
          "yoy": null,
          "now": null,
        },
        {
          "item": "平均距離上次購買",
          "yoy": null,
          "now": null,
        },
        {
          "item": "平均回購間隔",
          "yoy": null,
          "now": null,
        }
      ],
      /// TOP10同時購買商品表格
      prodBuyTable: [],
      /// 新舊客分布表格
      oldCustTable: [
        {
          "item": "舊客平均回購次數",
          "yoy": null,
          "now": null,
        },
        {
          "item": "舊客平均回購時間",
          "yoy": null,
          "now": null,
        },
        {
          "item": "舊客流失率",
          "yoy": null,
          "now": null,
        },
      ],
      /// 行銷互動表格
      interactionTable:  {
        "Email" : [
          {
            "item": "累計(平均)Email點擊",
            "yoy": null,
            "now": null,
            "avg": null,
          },
          {
            "item": "累計(平均)Email開信",
            "yoy": null,
            "now": null,
            "avg": null,
          },
        ],
        "SMS" : [
          {
            "item": "累計(平均)SMS點擊",
            "yoy": null,
            "now": null,
            "avg": null,
          },
        ]
      },

      seriesOldnew: [],
      chartOptionsOldnew: {},
      seriesChannels: {
        "Email": [],
        "SMS": [],
      },
      chartOptionsChannels: {},


      tableYearMonth: "",
      channelRespond: "Email",

      seriesBarLook: [{ data: [] },{ data: [] }],
      chartOptionsBarLook: {},

      labelIDs: [],
      labelNames: "",
      headCount: {},
      genderBar: "",
      tags: [],
      channelpie: {},
      visitpie: {},

      canWatch: false,
    };
  },
  watch: {
    monthData: function (val) {
      console.log(val);
      if (this.canWatch) {
        this.changeMonthYear("month");
      }
    },
    yearData: function (val) {
      console.log(val);
      if (this.canWatch) {
        this.changeMonthYear("year");
      }
    },
  },
  created: async function () {
    this.labelIDs = JSON.parse(sessionStorage.getItem("reportLabel"));
    console.log("this.labelIDs", this.labelIDs);
    this.loading = true;
    await this.getlabeltimerange();
    this.changeMonthYear("month");
  },
  methods: {
    slotPropsUnit(data) {
      switch(data) {
        case '平均消費金額':
        case '平均客單價':
        case '累計消費金額':
        case '累計(平均)消費金額':
          return '(NT$)'

        case '平均訂單數':
        case '累計訂單數':
        case '累計(平均)訂單數':
          return '(筆)'

        case '平均距離上次購買':
        case '舊客平均回購時間':
        case '平均回購間隔':
          return '(天)'

        case '平均Email點擊':
        case '平均SMS點擊':
        case '平均FB_Ads點擊':
        case '舊客平均回購次數':
        case '累計(平均)Email點擊':
        case '累計(平均)Email開信':
        case '累計(平均)SMS點擊':
          return '(次)'

        case '新客比例(1個月新註冊購買)':
        case '未曾購買過比例':
        case '舊客比例':
        case '舊客回購率':
        case '舊客流失率(3個月未購買)':
        case '舊客流失率':
          return '(%)'

        default:
          return ''
      }
    },
    avgViewText(data, itemName) {
      switch(data) {
        case undefined:
          return '';
        case null:
          return '平均:--' + this.slotPropsUnit(itemName);
        default:
          return '平均:' + data + this.slotPropsUnit(itemName);
      }
    },
    download() {
      const buildData = (data1, data2) => {
        return new Promise((resolve, reject) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(data1[0]);
          arrayData.push(arrayTitle);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(data1, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title];
              items.push(item);
            });
            arrayData.push(items);
          });

          let arrayTitle2 = Object.keys(data2[0]);
          arrayData.push(arrayTitle2);

          Array.prototype.forEach.call(data2, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle2, (title) => {
              let item = d[title];
              items.push(item);
            });
            arrayData.push(items);
          });

          resolve(arrayData);
        });
      };
      const selectNames = this.labelNames;
      const selectDate = this.tableYearMonth;
      const downloadCSV = (data) => {
        let csvContent = "";
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(",") + "\n";
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName =
          "下載" +
          "[" +
          selectNames +
          selectDate +
          "]" +
          "資料" +
          new Date().toISOString() +
          ".csv";

        // 建立一個 a，並點擊它
        let link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
        );
        link.setAttribute("download", fileName);
        link.click();
      };
      const data1 = this.json_data;
      const data2 = this.json_data2;
      buildData(data1, data2).then((data) => downloadCSV(data));
    },
    getPerformanceCSV: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelDownloadPost(params, body)
        .then((res) => {
          console.log("getcsv", res);
          res.data.table1.forEach((d) => (d.分群名稱 = this.labelNames));
          const sortedtable1 = res.data.table1.map((o) => {
            return {
              輪廓: o.輪廓,
              "<16": o["<16"],
              "16-25": o["16-25"],
              "26-35": o["26-35"],
              "36-45": o["36-45"],
              "46-55": o["46-55"],
              "56-65": o["56-65"],
              ">65": o[">65"],
              總人數: o["總人數"],
              選取時間: o["選取時間"],
              分群名稱: o["分群名稱"],
              tags: o["tags"],
            };
          });
          const sortedtable2 = res.data.table2.map((o) => {
            return {
              成效: o.成效,
              平均消費金額: o.平均消費金額,
              平均訂單數: o.平均訂單數,
              平均客單價: o.平均客單價,
              平均距離上次購買: o.平均距離上次購買,
              平均Email點擊: o.平均Email點擊,
              平均SMS點擊: o.平均SMS點擊,
              平均FB_Ads點擊: o.平均FB_Ads點擊,
              EMAIL回應程度_高: o.EMAIL回應程度_高,
              EMAIL回應程度_中: o.EMAIL回應程度_中,
              EMAIL回應程度_低: o.EMAIL回應程度_低,
              SMS回應程度_高: o.SMS回應程度_高,
              SMS回應程度_中: o.SMS回應程度_中,
              SMS回應程度_低: o.SMS回應程度_低,
              FB回應程度_高: o.FB回應程度_高,
              FB回應程度_中: o.FB回應程度_中,
              FB回應程度_低: o.FB回應程度_低,
              網站造訪頻率_高: o.網站造訪頻率_高,
              網站造訪頻率_中: o.網站造訪頻率_中,
              網站造訪頻率_低: o.網站造訪頻率_低,
            };
          });
          this.json_data = sortedtable1.concat(sortedtable2);
          this.json_data2 = sortedtable2;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });

      this.download();
    },
    getlabeltimerange: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelTimePost(params, body)
        .then((res) => {
          console.log("labeltimerange", res);
          this.minDate = res.data.Data.start_month; //2022-05
          this.maxDate = moment(res.data.Data.end_month)
            .endOf("month")
            .toISOString(); //2022-08-28
          this.maxDateYear = moment(res.data.Data.end_month)
            .endOf("year")
            .toISOString(); //2022-08-28

          (this.yearData = this.maxDate?.substr(0, 4)),
            (this.monthData = {
              month: this.maxDate?.substr(5, 2) - 1 || 9,
              year: this.maxDate?.substr(0, 4) || 2022,
            });
          console.log("this.monthData", this.monthData);
          console.log("this.maxDateYear", this.maxDateYear);
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
      this.canWatch = true;
    },
    /**
     * 取得分群名稱
     */
    getlabelname: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelLabelnamePost(params, body)
        .then((res) => {
          console.log("分群名稱", res);
          this.labelNames = res.data.Data.toString();
          this.labelNames.replace(/,/gi, "+");
          this.loading = false;
          console.log("this.labelNames", this.labelNames);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得關鍵消費表現
     */
    getSpending: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelSpendingPost(params, body)
        .then((res) => {
          console.log("關鍵消費表現表", res);
          let val = res.data.Data;

          for (const item of val) {
            switch(item?.item) {
              case '平均回購間隔':
                this.selectedTable[4].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                this.selectedTable[4].now = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              case '平均客單價':
                this.selectedTable[2].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                this.selectedTable[2].now = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              case '平均消費金額':
                this.selectedTable[0].avg = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              case '平均訂單數':
                this.selectedTable[1].avg = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              case '平均距離上次購買':
                this.selectedTable[3].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                this.selectedTable[3].now = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              case '累計消費金額':
                this.selectedTable[0].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                this.selectedTable[0].now = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              case '累計訂單數':
                this.selectedTable[1].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                this.selectedTable[1].now = item["當期"] == 0 ? 0 : item["當期"] || null
                break;
              default:
                break;
            }
          }

          this.tableYearMonth = this.selectMonthYear;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得分群總人數與人數占比
     */
    getHeadCount: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelHeadCountPost(params, body)
        .then((res) => {
          console.log("分群總人數與人數占比", res);
          this.headCount = res.data.Data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得分群輪廓樣貌組合
     */
    getGenderBar: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelAgeGenderPost(params, body)
        .then((res) => {
          console.log("分群輪廓樣貌組合 性別", res);
          this.genderBar = res.data.Data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得分群其他標籤組合
     */
    getOtherTag: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelOtherLabelsPost(params, body)
        .then((res) => {
          console.log("分群其他標籤組合", res);
          this.tags = res.data.Data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得商品同時購買
     */
    getProdBuy: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelProdBuyPost(params, body)
        .then((res) => {
          console.log("商品同時購買", res);
          let val = res.data.Data
          this.prodBuyTable = []
          for (const i in val?.product) {
            this.prodBuyTable.push({
              product: val?.product[i],
              count: val?.count[i]
            })
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得新舊客比例
     */
    getOldnew: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelOldnewPost(params, body)
        .then((res) => {
          console.log("新舊客比例", res);
          let val = res.data.Data;
          if (val["客群"]?.length > 0) {
            this.seriesOldnew = new Array(val["客群"]?.length)
            for (const index in val["客群"]) {
              switch (val["客群"][index]) {
                case '新客比例(1個月新註冊購買)':
                  this.seriesOldnew[0] = val["百分比"][index]
                  break;
                  
                case '未曾購買過比例':
                  this.seriesOldnew[2] = val["百分比"][index]
                  break;
  
                case '舊客比例':
                  this.seriesOldnew[1] = val["百分比"][index]
                  break;
  
                default:
                  break;
              }
            }
          }
          console.log(this.seriesOldnew)
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得新舊客分佈表格
     */
    getOldCust: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelOldCustPost(params, body)
        .then((res) => {
          console.log("新舊客分佈表格", res);
          let val = res.data.Data;

          for(const item of val) {
            switch (item.item) {
              case '舊客平均回購次數':
                this.oldCustTable[0].now = item["當期"] == 0 ? 0 : item["當期"] || null
                this.oldCustTable[0].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                break;
              case '舊客平均回購時間':
                this.oldCustTable[1].now = item["當期"] == 0 ? 0 : item["當期"] || null
                this.oldCustTable[1].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                break;
              case '舊客流失率':
                this.oldCustTable[2].now = item["當期"] == 0 ? 0 : item["當期"] || null
                this.oldCustTable[2].yoy = item["前期比"] == 0 ? 0 : item["前期比"] || null
                break;
              default:
                break;
            }
          }

          setTimeout(() => {
            console.log(this.oldCustTable)
          })

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得渠道互動評級
     */
    getChannels: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelChannelsPost(params, body)
        .then((res) => {
          console.log("渠道互動評級", res);
          let val = res.data.Data;
          this.seriesChannels["Email"] = []
          this.seriesChannels["SMS"] = []
          val["email"]?.["值"]?.map((v) => {
            this.seriesChannels["Email"].push(v)
          })
          val["sms"]?.["值"]?.map((v) => {
            this.seriesChannels["SMS"].push(v)
          })
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    /**
     * 取得行銷互動表格
     */
    getInteraction: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        year_month: this.selectMonthYear,
      };
      const body = {
        labelId: this.labelIDs,
      };
      await allapi2
        .multipleLabelInteractionPost(params, body)
        .then((res) => {
          console.log("行銷互動表格", res);
          let val = res.data.Data;

          /// Email
          this.interactionTable["Email"][0].now = val["email"]?.["累計email點擊"]?.["當期"]?.[0] == 0 ? 0 : val["email"]?.["累計email點擊"]?.["當期"]?.[0] || null
          this.interactionTable["Email"][0].yoy = val["email"]?.["累計email點擊"]?.["前期比"]?.[0] == 0 ? 0 : val["email"]?.["累計email點擊"]?.["前期比"]?.[0] || null
          this.interactionTable["Email"][0].avg = val["email"]?.["平均email點擊"]?.["當期"]?.[0] == 0 ? 0 : val["email"]?.["平均email點擊"]?.["當期"]?.[0] || null
          this.interactionTable["Email"][1].now = val["email"]?.["累計email開信"]?.["當期"]?.[0] == 0 ? 0 : val["email"]?.["累計email開信"]?.["當期"]?.[0] || null
          this.interactionTable["Email"][1].yoy = val["email"]?.["累計email開信"]?.["前期比"]?.[0] == 0 ? 0 : val["email"]?.["累計email開信"]?.["前期比"]?.[0] || null
          this.interactionTable["Email"][1].avg = val["email"]?.["平均email開信"]?.["當期"]?.[0] == 0 ? 0 : val["email"]?.["平均email開信"]?.["當期"]?.[0] || null
          /// SMS
          this.interactionTable["SMS"][0].now = val["sms"]?.["累計sms點擊"]?.["當期"]?.[0] == 0 ? 0 : val["sms"]?.["累計sms點擊"]?.["當期"]?.[0] || null
          this.interactionTable["SMS"][0].yoy = val["sms"]?.["累計sms點擊"]?.["前期比"]?.[0] == 0 ? 0 : val["sms"]?.["累計sms點擊"]?.["前期比"]?.[0] || null
          this.interactionTable["SMS"][0].avg = val["sms"]?.["平均sms點擊"]?.["當期"]?.[0] == 0 ? 0 : val["sms"]?.["平均sms點擊"]?.["當期"]?.[0] || null
          
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    /**
     * 輪廓樣貌組合圖表
     */
    barLook() {
      this.seriesBarLook = [
        {
          name: "男性",
          data: this.genderBar?.男性?.counts,
        },
        {
          name: "女性",
          data: this.genderBar?.女性?.counts,
        },
      ];
      this.chartOptionsBarLook = {
        chart: {
          type: "bar",
          height: 300,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#63A7C8", "#FFA09D"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.genderBar?.男性?.age,
          title: {
            text: "年齡層分層",
          },
        },
        yaxis: {
          title: {
            text: "人數",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },
    /**
     * 新舊客比例圖表
     */
    oldnew() {
      // this.seriesOldnew = []
      this.chartOptionsOldnew = {
        chart: {
          height: 200,
          type: "donut",
        },
        colors: ["#96C5D7", "#F9C357", "#ABABAB"],
        labels: ["新客比例(1個月新註冊購買)", "舊客比例", "未曾購買過比例"],
        legend: {
          position: "right",
          // offsetX: 100,
          // offsetY: 50,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
    /**
     * 渠道互動評級圖表
     */
    channels() {
      // this.seriesChannels = []
      this.chartOptionsChannels = {
        chart: {
          height: 200,
          // width: 200,
          type: "donut",
        },
        colors: ["#96C5D7", "#F9C357", "#FC9F66", "#ABABAB"],
        labels: ["高", "中", "低", "無"],
        legend: {
          position: "right",
          // offsetX: 140,
          // offsetY: 30,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },

    changeChannel(channel) {
      this.channelRespond = channel;
    },

    changeMonthYear: async function (year_month) {
      this.yearOrMonth = year_month || 2022;
      let month = "";
      console.log('this.monthData.month',this.monthData.month)
      if ((this.monthData.month + 1 + "").length === 2) {
        month = this.monthData.month + 1 + "";
      } else {
        month = "0" + (this.monthData.month + 1);
      }
      if (year_month === "year") {
        this.selectMonthYear = "" + this.yearData;
        // this.selectMonthYear = "2021-12";
      } else {
        this.selectMonthYear = this.monthData.year + "-" + month;
        // this.selectMonthYear = "2021-12";
      }
      console.log("this.selectMonthYear", this.selectMonthYear);

      await Promise.all([
        this.getlabelname(),
        this.getHeadCount(),
        this.getGenderBar(),
        this.getOtherTag(),
        this.getSpending(),
        this.getProdBuy(),
        this.getOldnew(),
        this.getOldCust(),
        this.getChannels(),
        this.getInteraction(),
      ]);
      this.barLook();
      this.oldnew()
      this.channels()
    },
  },
};
</script>

<style scoped>
:deep(.p-datatable-responsive-scroll>.p-datatable-wrapper) {
  overflow: visible!important;
  overflow-x: visible!important;
}
:deep(.p-datatable-scrollable .p-datatable-wrapper) {
  /* overflow: visible!important; */
  overflow-y: overlay!important;
}
:deep(#prodBuyBox > .p-datatable-scrollable .p-datatable-tbody) {
  overflow-y: auto;
  max-height: 205px;
}
:deep(.interaction-table table) {
  height: 100%;
  min-height: 200px;
}
:deep(.interaction-table > .p-datatable-wrapper) {
  min-height: 200px;
}

:deep(.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td) {
  border-width: 1px 1px 1px 1px;
}
:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: center;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 5px;
}
:deep(#selected > .p-datatable .p-datatable-thead > tr > th),
:deep(#selected > .p-datatable-scrollable .p-datatable-tbody>tr>td),
:deep(#prodBuyBox > .p-datatable .p-datatable-thead > tr > th),
:deep(#prodBuyBox > .p-datatable-scrollable .p-datatable-tbody>tr>td),
:deep(#prodBuyBox > .p-datatable .p-datatable-thead > tr > th > .p-column-header-content) {
  display: block;
}
:deep(.p-datatable table) {
  border-collapse: collapse;
  border-radius: 15px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #575757; /* this draws the table border  */
}
:deep(.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th) {
  border-width: 1px 1px 1px 1px;
}
:deep(th:last-child) {
  border-top-right-radius: 15px;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  transition: box-shadow 0.2s;
}

/* :deep(.p-datatable .p-datatable-thead > tr > th),
:deep(.p-datatable-scrollable .p-datatable-tbody>tr>td),
:deep(.p-datatable .p-datatable-thead > tr > th > .p-column-header-content) {
  display: block;
} */

:deep(.p-datatable .p-datatable-thead > tr > th) {
  height: auto;
  line-height: auto;
}
.auto-complicate-div {
  max-width: 100%;
}

.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
}
.orange-tagbtn {
  height: 40px;
  border: none;
  border-radius: 10px;
  margin: 1.6%;
}
.group-btn {
  background-color: #eaf3f4;
  border-radius: 10px;
}
.basic-btn-black {
  background-color: #eaf3f4;
  border-radius: 10px;
  border: none;
  height: 40px;
  min-width: 100px;
}
.highlight-btn {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.dp-custom-input {
  height: 40px;
  border: 1px solid #575757;
  background-image: white;
  border-radius: 10px;
}
.child-popout-inTagReport-first {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 350px;
  right: -360px;
  top: -50px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  cursor: default;
}
.child-popout-inTagReport-second {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 355px;
  right: -365px;
  top: -63px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  cursor: default;
}
.child-popout-inTagReport-third {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 300px;
  right: -313px;
  top: -50px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  cursor: default;
}

.flex-wrap-2 {
  display: grid;
  grid-template-columns: 49% 2% 49%;
  align-items: stretch;
}

.other-tags-item {
  height: 287px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 7px 14.5px;
  overflow-y: auto;
}

.spending-col {
  padding-left: 100px;
  height: 100%;
  display: grid;
  justify-content: start;
  align-content: center;
}
.prodBuy-h {
  padding-left: 70px;
  min-height: 90px;
  display: grid;
  align-content: center;
}
.prodBuy-col {
  padding-left: 70px;
  height: 100%;
  display: grid;
  justify-content: start;
  align-content: center;
}
.prodBuy-col > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box-f-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
  /* height: 100px; */
}
.interaction-table {
  height: 180px;
  margin-top: 16px;
}
.interaction-table-col {
  padding-left: 28px;
  height: 100%;
  display: grid;
  justify-content: start;
  align-content: center;
}

.bdr-r-1 {
  border-right-width: 1px;
  border-right-style: inset;
}
.bdr-l-1 {
  border-left-width: 1px;
  border-left-style: outset;
}

.h-40 {
  height: 40px;
}
.l-h-40 {
  line-height: 40px;
}

.m-r--8 {
  margin-right: -8px;
}

.m-l--15 {
  margin-left: -15px;
}
.m-r--9 {
  margin-right: -9px;
}

.m-l--14 {
  margin-left: -14px;
}

.search-container {
  position: relative;
}
.search-container > .justify-content-between {
  width: 300px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 0 14px;
  height: 40px;
  max-width: 100%;
}

.table-box {
  padding: 20px;
}

.icon-date {
  position: relative;
  right: -10px;
  top: -3px;
}
.icon-down {
  position: absolute;
  top: 18px;
  right: 15px;
  z-index: 50;
}

.no-data-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 12px;
}

#prodBuyBox {
  height: 370px;
}

#prodBuyBox ::-webkit-scrollbar {
  width: 4px;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px;
}

.Gray77 {
  color: #C4C4C4;
}
.Gray54 {
  color: #8A8A8A;
}
.lightRed {
  color: #FF8B8B;
}
.sCyan {
  color: #7AB4BB;
}
.sCyan-2 {
  color: #71AFB6;
}

.b-GrayRed {
  background-color: #fcddd9;
}
.b-GrayOrange {
  background-color: #feedcd;
}
.b-GrayBlue  {
  background-color: #e0eef3;
}
.b-GrayCyan {
  background-color: #eaf3f4;
}
.b-GrayGreen {
  background-color: #ddede1;
}

.m-r-60 {
  margin-right: 60px;
}

</style>