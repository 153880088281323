<template>
  <!-- backdrop -->
  <div
    id="SmsSetting-backdrop"
    class="modal-backdrop fade show"
    style="display: none"
  ></div>
  <!-- alert變數Modal -->
  <div
    class="modal fade"
    id="varnameModal"
    tabindex="-1"
    aria-labelledby="alertModalLabel"
    aria-hidden="true"
    style="z-index: 5000"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" @click="autoShow = false">
        <div class="modal-header alert-modal-header">
          <img
            src="../../assets/arrow-white.svg"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </div>
        <div class="modal-body alert-modal-body" style="text-align: left">
          <div class="d-flex justify-content-start align-items-center mb-2">
            <div class="col-4 p-0" style="color: #71afb6">插入個人化變數</div>
            <div class="search-container col-6 p-0" style="position: relative">
              <div
                class="d-flex justify-content-between align-items-center"
                style="
                  border: 1px solid #c4c4c4;
                  border-radius: 10px;
                  padding: 0 14px;
                  height: 35px;
                "
                @click.stop="autoShow = !autoShow"
              >
                {{ triggerType }}
                <img src="../../assets/selection-arrow.png" alt="searchImg" />
              </div>
              <div
                class="auto-complicate-div"
                style="width: 100%"
                v-show="autoShow"
                @mouseenter="autoShow = true"
              >
                <p
                  class="m-0 p-1 px-3 auto-complicate-p"
                  @click.stop="
                    triggerType = '顧客姓名';
                    varName = '{name}';
                    autoShow = false;
                  "
                >
                  顧客姓名
                </p>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <p class="mb-4" style="color: #8a8a8a">
              複製下方變數並插入文字中，使信件客製化；可讓信件中加入訂閱者的姓名，增加親切感。
            </p>
            <div class="d-flex align-items-center mb-4">
              <span class="me-4" style="color: #71afb6; width: 80px"
                >顧客姓名</span
              >
              <input
                type="text"
                style="
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border: 0.2px solid #8a8a8a;
                  height: 30px;
                  height: 40px;
                  padding-left: 10px;
                "
                ref="eventName"
                v-model="varName"
                disabled
              />
              <button
                class="basic-btn highlight-SmsTemp-var"
                style="
                  width: 90px;
                  height: 40px;
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                  color: white;
                  background-color: #71afb6;
                "
                v-clipboard:copy="varName"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                data-dismiss="modal"
              >
                複製
              </button>
            </div>
            <div class="d-flex align-items-center mb-4">
              <span class="me-4" style="color: #71afb6; width: 80px"
                >顧客姓</span
              >
              <input
                type="text"
                style="
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border: 0.2px solid #8a8a8a;
                  height: 30px;
                  height: 40px;
                  padding-left: 10px;
                "
                ref="eventName"
                v-model="varLastName"
                disabled
              />
              <button
                class="basic-btn highlight-SmsTemp-var"
                style="
                  width: 90px;
                  height: 40px;
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                  color: white;
                  background-color: #71afb6;
                "
                v-clipboard:copy="varLastName"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                data-dismiss="modal"
              >
                複製
              </button>
            </div>
            <div class="d-flex align-items-center">
              <span class="me-4" style="color: #71afb6; width: 80px"
                >顧客名</span
              >
              <input
                type="text"
                style="
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  border: 0.2px solid #8a8a8a;
                  height: 30px;
                  height: 40px;
                  padding-left: 10px;
                "
                ref="eventName"
                v-model="varFirstName"
                disabled
              />
              <button
                class="basic-btn highlight-SmsTemp-var"
                style="
                  width: 90px;
                  height: 40px;
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                  color: white;
                  background-color: #71afb6;
                "
                v-clipboard:copy="varFirstName"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                data-dismiss="modal"
              >
                複製
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- alert添加成功Modal -->
  <div
    class="modal fade"
    id="alertModal4"
    tabindex="-1"
    aria-labelledby="alertModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header alert-modal-header">
          <img
            src="../../assets/arrow-white.svg"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </div>
        <div class="modal-body alert-modal-body">儲存成功</div>
        <div class="modal-footer d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-secondary modal-btn-close"
            data-bs-dismiss="modal"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- sms expmodal -->
  <SmsPreview :countText="countText" :resSubsec="expSubsec"></SmsPreview>

  <!-- main  -->
  <!-- sms editorModal -->
  <div class="p-5 pt-4 pb-3">
    <p style="font-size: 24px">簡訊模板</p>
    <div
      style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; border-radius: 10px"
    >
      <div class="modal-body client-modal-body p-0" style="border-radius: 10px">
        <div
          class="container mb-3"
          style="background-color: white; border-radius: 10px"
        >
          <div class="pt-4 px-2 mt-3 form-container">
            <div class="row justify-content-xl-between align-items-center">
              <span class="col-3" style="color: #71afb6"> 模板內容 </span>
              <div class="col-3">
                <button
                  class="basic-btn highlight-SmsTemp"
                  style="cursor: pointer; color: white"
                  @click="showVar"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <img
                      src="../../assets/插-icon.svg"
                      alt=""
                      class="pe-2 pt-1"
                    />

                    <span>插入個人化變數</span>
                  </div>
                </button>
              </div>
            </div>

            <div class="row pt-4">
              <label for="" style="width: 13%; color: #71afb6">模板名稱</label>
              <div style="width: 87%">
                <input
                  v-bind:class="{ 'is-invalid': titleError }"
                  ref="titleInput"
                  name="add-input"
                  class="form-control"
                  v-model="newTodoTitle"
                  disabled
                />
                <div class="invalid-feedback">
                  {{ titleErrMsg }}
                </div>
              </div>
            </div>

            <div class="mb-3 row mt-4">
              <div style="width: 13%">
                <label for="exampleFormControlTextarea1" class="form-label"
                  >簡訊內容</label
                >
              </div>
              <div style="width: 87%">
                <textarea
                  v-bind:class="{ 'is-invalid': textError }"
                  ref="textInput"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  v-model="newSubsec"
                  style="height: 130px"
                ></textarea>
                <div class="invalid-feedback">
                  {{ textErrMsg }}
                </div>
                <div>
                  中英數簡訊一封70字元 (縮址佔37字元)，若超過則會分為兩則。
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div style="width: 13%">
                <span class="banner-span" style="line-height: 56px"
                  >點擊追蹤</span
                >
              </div>
              <div style="width: 87%">
                <p class="upload-sub mt-3" style="color: #71afb6">
                  請輸入原始網址連結，系統將會自動轉為縮址以便追蹤，建議點擊預覽模板即可測試並觀看縮址後的結果。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4 mb-2">
      <button
        type="button"
        class="btn btn-primary me-5"
        style="
          width: 180px;
          background-color: #eaf3f4;
          color: #71afb6;
          border: none;
          border-radius: 10px;
        "
        @click="expshow"
      >
        <img
          src="../../assets/green-eye.svg"
          style="width: 1.3rem"
          class="me-2"
        />
        預覽
      </button>
      <button
        v-on:click="updateCbox"
        class="basic-btn highlight2"
        style="width: 180px; cursor: pointer"
      >
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../../assets/save-icon.svg"
            alt=""
            style="position: relative; top: 1.5px"
            class="pe-2 me-2"
          />
          <span> 儲存</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { allapi2 } from "../../../public/static2/api/apigClient.js";
import SmsPreview from "../SmsPreview.vue";

export default {
  inheritAttrs: false,
  name: "SmsTemplateEditor",
  components: {
    SmsPreview,
  },
  props: {
    template_key: String,
    random_key: String,
  },
  data() {
    return {
      varName: "{name}",
      varLastName: "{lastname}",
      varFirstName: "{firstname}",

      newTodoTitle: "",
      newSubsec: "",
      todos: "",
      triggerType: "",
      autoShow: "",

      titleError: false,
      textError: false,
      titleErrMsg: "",
      textErrMsg: "",

      Ctext: "",
      Btext: "",
      CnewText: "",
      BnewText: "",
      noCbox: true,

      expSubsec: "",
      countText: "",
      exampleModal2: {},
    };
  },
  created: async function () {
    this.get();
    await this.getCboxById();
    if (this.noCbox) {
      // 如果沒改過C箱子 則從B箱子開始 編輯成C箱子
      await this.getBboxById();
      this.newText = this.BnewText;
      this.newSubsec = this.Btext;
    } else {
      // 如果改過C箱子 則拿C箱子再次編輯C箱子
      console.log("接續使用C箱子編輯");
      this.newText = this.CnewText;
      this.newSubsec = this.Ctext;
    }
  },
  watch: {
    newTodoTitle: function (val) {
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;
      if (isText.test(this.newTodoTitle)) {
        this.titleError = true;
        this.titleErrMsg = "名稱不能含有是有特殊字元";
      } else if (val === "") {
        this.titleError = true;
        this.titleErrMsg = "必須輸入模板名稱";
      } else if (val != "") {
        this.titleError = false;
      }
    },
    newSubsec: function (val) {
      if (val != "") {
        this.textError = false;
      } else if (val === "") {
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      }
    },
    exampleModal2: {
      handler: function () {
        if (this.exampleModal2._isShown == false) {
          const backdrop = document.getElementById("SmsSetting-backdrop");
          backdrop.style["display"] = "none";
        }
      },
      deep: true,
    },
  },
  methods: {
    // 獲取現有的所有A箱子
    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .smsTemplateSmsTempInfoGet(params)
        .then((res) => {
          console.log("GET 獲取現有的所有A箱子", res);
          this.todos = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
        });
    },

    // 按鈕複製 成功
    onCopy: function (e) {
      console.log("You just copied: " + e.text);
    },
    // 按鈕複製 失敗
    onError: function (e) {
      alert("Failed to copy texts");
    },
    // 顯示可用變數的彈出視窗
    showVar() {
      const varnameModal = new bootstrap.Modal(
        document.getElementById("varnameModal"),
        {
          keyboard: false,
        }
      );
      varnameModal.show();
    },

    // 拿Bbox的api
    getBboxById: async function () {
      console.log(this.$props.random_key);
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.$props.template_key,
      };

      await allapi2
        .smsTemplateSmsTransitKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.$props.template_key拿B箱子  現在的this.$props.template_key是${this.$props.template_key}`,
            res
          );
          this.BnewText = res.data.temp.newText;
          this.Btext = res.data.temp.text;
          this.newTodoTitle = res.data.name;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿Cbox的api
    getCboxById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.$props.random_key,
      };

      await allapi2
        .smsTemplateSmsCacheKeyGet(params)
        .then((res) => {
          console.log(
            `根據this.$props.random_key拿C箱子  現在的this.$props.random_key是${this.$props.random_key}`,
            res
          );
          if (res.data == "not found") {
            this.noCbox = true;
          } else {
            this.noCbox = false;
            this.CnewText = res.data.temp.newText;
            this.Ctext = res.data.temp.text;
            this.newTodoTitle = res.data.name;
          }
        })
        .catch((err) => {
          this.noCbox = true;
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 上傳C箱子 this.newSubsec是最原始的用戶輸入的簡訊內容 this.newText是轉好短網址的簡訊內容
    postCbox: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.$props.random_key,
        name: this.newTodoTitle,
        temp: { text: this.newSubsec, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsCacheInfoPost(params, body)
        .then((res) => {
          console.log(
            `根據this.$props.random_key  現在的this.$props.random_key${this.$props.random_key}`,
            res
          );
        })
        .catch((err) => {
          this.goSet = false;
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    // 更新C箱子
    patchCbox: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        key: this.$props.random_key,
        name: this.newTodoTitle,
        temp: { text: this.newSubsec, newText: this.newText },
      };
      await allapi2
        .smsTemplateSmsCacheInfoPatch(params, body)
        .then((res) => {
          console.log(
            `根據this.$props.random_key 更新C箱子  現在的this.$props.template_key是${this.$props.random_key}`,
            res
          );
        })
        .catch((err) => {
          this.goSet = false;
          console.log("連線有誤");
          console.log(err);
          alert("post連線有誤" + err);
          this.loading = false;
        });
    },

    // 儲存編輯 再上傳C箱子前 先判斷是要postC箱子 還是patchC箱子 然後校驗和處理文字內容
    updateCbox: async function (alreadyHasCbox) {
      await this.findAllURl();
      this.checkVar();
      if (this.varok) {
        console.log("變數都符合要求");
      } else {
        this.loading = false;
        return alert("個人化變數不符合格式");
      }
      let isText = /[`~!@#$%^&*+<>?:"{},.\/;'[\]=]/;

      if (this.newSubsec === null || this.newSubsec === "") {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "必須輸入訊息內容";
      } else if (this.urlok != true) {
        this.$refs.textInput.focus();
        this.textError = true;
        this.textErrMsg = "短網址生成失敗。";
      } else if (isText.test(this.newTodoTitle)) {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "名稱不能含有是有特殊字元";
      } else {
        this.loading = true;
        // 上傳圖片API
        await this.postCbox();
        this.$emit("close-editor");
      }
    },

    // 預覽模板
    expshow: async function () {
      await this.findAllURl();
      this.countText = this.newText
        .replace(/{id}/g, "uid000")
        .replace(/{lastname}/g, "王")
        .replace(/{firstname}/g, "◯◯")
        .replace(/{name}/g, "王◯◯");
      console.log("this.countText", this.countText);
      this.checkVar();
      if (this.varok) {
        console.log("變數都符合要求");
      } else {
        this.loading = false;
        return alert("個人化變數不符合格式");
      }

      this.exampleModal2 = new bootstrap.Modal(
        // document.getElementById("exampleModal2"),
        document.querySelectorAll('#exampleModal2')[document.querySelectorAll('#exampleModal2').length - 1],
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementById("SmsSetting-backdrop");
      backdrop.style["display"] = "block";
    },
    // 獲得所有正常網址 並將所有正常網址 轉成短網址
    findAllURl: async function () {
      this.expSubsec = this.newSubsec;
      this.newText = this.newSubsec;
      console.log(this.newText);
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.newSubsec);
      console.log(this.fullurlArr);
      if (this.fullurlArr != null) {
        await this.getAllShortHttp();
      } else {
        this.textError = false;
        this.urlok = true;
      }
    },
    // 根據所有正常網址 for循環呼叫轉短網址
    getAllShortHttp: async function () {
      this.urlok = true;
      for (let index = 0; index < this.fullurlArr.length; index++) {
        await this.getShortUrl(this.fullurlArr[index]);
      }
    },
    // 呼叫轉短網址的api 並產生this.newText 用來放轉好短網址的text 和 this.expSubsec用來顯示模擬SMS(加了a標籤)
    getShortUrl: async function (fullurl) {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        url: fullurl,
        template_id: this.$props.template_key,
      };
      await allapi2
        .smsScheduleShortenUrlPost(params, body)
        .then((res) => {
          console.log("GETUrl", res);

          this.newText = this.newSubsec.replace(
            fullurl,
            res.data.url + "?i={id}"
          );
          this.expSubsec = this.expSubsec
            .replace(
              fullurl,
              "<a href='" +
                res.data.url +
                // "?i=" +
                // 'uid000' +
                "' target='_blank'>" +
                res.data.url +
                "?i=" +
                "uid000" +
                "</a>"
            )
            .replace(/{lastname}/g, "王")
            .replace(/{firstname}/g, "◯◯")
            .replace(/{name}/g, "王◯◯");

          console.log("this.newText", this.newText);
        })
        .catch((err) => {
          console.log("連線有誤", err);
          this.urlok = false;
          this.textError = true;
          this.textErrMsg = "短網址生成失敗。";
          this.loading = false;
          console.log(this.shortUrlArr);
        });
    },

    // 查看變數是否符合
    checkVar() {
      function isValidURL(string) {
        var res = string.match(/\{{*.*?\}/g);
        return res;
      }
      const matchList = ["{name}", "{lastname}", "{firstname}", "{tracking}", "{id}"];
      const varArr = isValidURL(this.newSubsec);
      console.log(varArr);
      var boolean = [];
      if (varArr != null) {
        boolean = varArr.map((d) => {
          for (let index = 0; index < matchList.length; index++) {
            if (d === matchList[index]) {
              return true;
            }
          }
        });
      }
      console.log(boolean);
      this.varok = boolean.every((d) => d === true);
      console.log(this.varok);
    },
  },
};
</script>

<style scoped>
</style>