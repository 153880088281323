<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container-fluid pe-0" v-else>
    <div class="container pe-0">
      <div class="">
        <div class="d-flex justify-content-center pe-0">
          <!-- expscriptModal -->
          <div
            class="modal fade"
            id="scriptModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content" style="width: 100%">
                <SeeScript
                  v-bind:scriptID2="scriptID2"
                  v-bind:emailChannel="emailChannel"
                  v-bind:addadd2="addadd2"
                  v-on:showUnlayer="showUnlayer"
                  v-on:showSMSexp="showSMSexp"
                  v-on:showOutsideExp="showOutsideExp"
                  :key="forceRerender"
                />
              </div>
            </div>
          </div>
          <!-- sms modal -->
          <SmsPreview :countText="countText" :resSubsec="resSubsec"></SmsPreview>
          
          <!-- choose label alert -->
          <div
            class="modal fade"
            id="alertModal1"
            tabindex="-1"
            aria-labelledby="alertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header alert-modal-header">
                  <img
                    src="../assets/arrow-white.svg"
                    alt="arrow"
                    class=""
                    data-bs-dismiss="modal"
                  />
                </div>
                <div class="modal-body alert-modal-body">
                  尚未選擇名單與模板，請回前頁進行選取
                </div>
                <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- demo account function unavailible alert -->
          <div
            class="modal fade"
            id="alertModalDemoEmail"
            tabindex="-1"
            aria-labelledby="alertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header alert-modal-header">
                  <img
                    src="../assets/arrow-white.svg"
                    alt="arrow"
                    class=""
                    data-bs-dismiss="modal"
                  />
                </div>
                <div class="modal-body alert-modal-body">
                  測試賬號未開啓此功能
                </div>
                <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- no date alert -->
          <div
            class="modal fade"
            id="alertdate"
            tabindex="-1"
            aria-labelledby="alertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header alert-modal-header">
                  <img
                    src="../assets/arrow-white.svg"
                    alt="arrow"
                    class=""
                    data-bs-dismiss="modal"
                  />
                </div>
                <div class="modal-body alert-modal-body">尚未選擇日期</div>
                <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- expEmailModal -->
          <EmailPreview :subject="subject" :html="html"></EmailPreview>

          <!-- set resName and resTitle to sessionStore alert -->
          <div
            class="modal fade"
            id="alertModalGoScript"
            tabindex="-1"
            aria-labelledby="alertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header alert-modal-header">
                  <img
                    src="../assets/arrow-white.svg"
                    alt="arrow"
                    class=""
                    data-bs-dismiss="modal"
                  />
                </div>
                <div class="modal-body alert-modal-body">
                  確認預約後的模板內容將無法進行編輯，
                  若要更改請取消並回上一頁或直接進行刪除。
                </div>
                <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close2"
                    data-bs-dismiss="modal"
                  >
                    取消
                  </button>

                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                    @click="passToScript()"
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- router push alert -->
          <div
            class="modal fade"
            id="alertModalroutergo"
            tabindex="-1"
            aria-labelledby="alertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header alert-modal-header">
                  <img
                    src="../assets/arrow-white.svg"
                    alt="arrow"
                    class=""
                    data-bs-dismiss="modal"
                  />
                </div>
                <div class="modal-body alert-modal-body">
                  預約成功 ! 是否前往行銷劇本設定?
                </div>
                <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close2"
                    data-bs-dismiss="modal"
                  >
                    否
                  </button>

                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                    @click="routergoScript()"
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- show excludeModal -->
          <div
            class="modal fade"
            id="excludeModal"
            tabindex="-1"
            aria-labelledby="alertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div
                  class="
                    modal-header
                    alert-modal-header
                    d-flex
                    justify-content-end
                  "
                >
                  <img
                    src="../assets/whiteX.svg"
                    alt="arrow"
                    class=""
                    data-bs-dismiss="modal"
                  />
                </div>
                <div
                  class="modal-body alert-modal-body text-start"
                  style="padding: 25px 28px"
                >
                  <p>
                    <img
                      src="../assets/greenAirplane.svg"
                      alt=""
                      class="me-3"
                    />
                    發送名單:
                  </p>
                  <p
                    class="mb-5"
                    style="margin-left: 36px; word-break: break-all"
                  >
                    {{
                      this.resGroup.includeLabelNames
                        .toString()
                        .replace(/,/g, "、")
                    }}
                  </p>
                  <p>
                    <img
                      src="../assets/redAirplane.svg"
                      alt=""
                      class="me-3"
                    />排除名單:
                  </p>
                  <p style="margin-left: 36px">
                    {{
                      this.resGroup.excludeLabelNames
                        .toString()
                        .replace(/,/g, "、")
                    }}
                  </p>
                </div>
                <!-- <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div> -->
              </div>
            </div>
          </div>

          <!-- main -->
          <div class="col-11 col-xxl-11 col-3xl-2 col-xl-12 mt-5">
            <div class="title-span mt-2">排程</div>
            <div
              class="
                col-11 col-xxl-12 col-xl-11 col-lg-11
                px-5
                detail-container
              "
              style="margin-top: 32px"
            >
              <div
                class="
                  d-flex
                  mb-3
                  schedule-sub-title
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <span> 活動詳情 </span>

                <button
                  class="basic-btn highlightsche"
                  style="width: 20%"
                  @click="showDetailsModal(this.childtodo, '_a')"
                >
                  <div class="d-flex justify-content-center">
                    <img
                      src="../assets/eye-white.svg"
                      alt=""
                      class="pe-2 mt-1"
                    />
                    <span> 預覽Email模板 </span>
                  </div>
                </button>
              </div>
              <div class="d-flex flex-wrap">
                <div class="col-6 d-flex align-items-center">
                  <span class="lh-lg schedule-sub-title" style="width: 18%"
                    >受眾名單</span
                  >
                  <span
                    class="green-span d-flex justify-content-between px-3"
                    style="width: 80%"
                  >
                    <span
                      class="todo-span"
                      :style="
                        resGroup.excludeLabelNames[0] != undefined
                          ? 'width: 70%'
                          : 'width: 100%'
                      "
                    >
                      {{
                        this.resGroup.includeLabelNames
                          .toString()
                          .replace(/,/g, "、")
                      }}
                    </span>

                    <span
                      style="
                        font-size: 14px;
                        font-weight: 100;
                        color: #c4c4c4;
                        text-decoration: underline;
                        cursor: pointer;
                        width: 25%;
                      "
                      @click="openExcludeModal()"
                      v-if="resGroup.excludeLabelNames[0] != undefined"
                      >瀏覽完整名單</span
                    >
                  </span>
                </div>
                <div
                  class="col-6 d-flex align-items-center justify-content-end"
                >
                  <span class="lh-lg schedule-sub-title" style="width: 18%"
                    >模板名稱</span
                  >
                  <span class="green-span" style="width: 80%">{{
                    resTitle
                  }}</span>
                </div>
                <div class="col-12 d-flex align-items-center mt-4">
                  <span class="lh-lg schedule-sub-title" style="width: 9%"
                    >主旨</span
                  >
                  <span class="green-span" style="width: 91%">{{
                    resSub
                  }}</span>
                </div>
              </div>
            </div>

            <div
              class="
                col-11 col-xxl-12 col-xl-11 col-lg-11
                px-5
                mt-5 mt-5
                reserving-container
                mb-5
              "
            >
              <div class="d-flex align-items-center mb-5">
                <span class="col-2" style="color: #71afb6"
                  >投放活動排程紀錄</span
                >
                <span style="position: relative" class="me-3">
                  <img
                    style="position: absolute; top: 8px; left: 10px"
                    src="../assets/search-black.png"
                    alt="searchImg" />
                  <input
                    style="
                      padding-left: 35px;
                      width: 13vw;
                      height: 35px;
                      border-radius: 10px;
                      border: 1px solid #575757;
                    "
                    v-model="searchInput"
                    placeholder="搜尋名稱"
                /></span>
                <div class="search-container" style="width: 38%" ref="target">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="
                      width: 350px;
                      border: 1px solid #c4c4c4;
                      border-radius: 10px;
                      padding: 0 14px;
                      height: 35px;
                    "
                    @click="autoShow = !autoShow"
                  >
                    <div>
                      <img
                        src="../assets/black-filter.svg"
                        alt=""
                        style="position: relative; top: -2px"
                        class="me-2"
                      />
                      {{ triggerType }}
                    </div>
                    <div
                      class="d-flex align-items-center"
                      style="width: 50px"
                      :class="
                        this.filterCount === 0
                          ? 'justify-content-end'
                          : 'justify-content-between'
                      "
                    >
                      <div
                        style="
                          position: relative;
                          background-color: #71afb6;
                          border-radius: 10px;
                          width: 20px;
                          height: 20px;
                        "
                        v-if="this.filterCount != 0"
                      >
                        <span
                          style="
                            color: white;
                            font-size: 14px;
                            position: absolute;
                            top: 0px;
                            left: 6.5px;
                          "
                        >
                          {{ filterCount }}
                        </span>
                      </div>
                      <img
                        style="width: 10px; height: 8px"
                        src="../assets/selection-arrow.png"
                        alt="searchImg"
                      />
                    </div>
                  </div>
                  <div
                    class="auto-complicate-div text-start"
                    style="width: 350px"
                    v-show="autoShow"
                    @mouseenter="autoShow = true"
                  >
                    <p class="m-0 p-1 ps-3 my-2">AB Testing</p>
                    <hr
                      class="m-0"
                      style="
                        width: 100%;
                        opacity: 1;
                        height: 0;
                        border: 0;
                        border-bottom: 1px solid #c4c4c4;
                      "
                    />
                    <div class="d-flex flex-wrap">
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="AB無"
                          id="AB無"
                          value="null"
                          v-model="checkedNames"
                        />
                        <label for="AB無" style="width: 83%">無</label>
                      </p>
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="AB主旨調整"
                          id="AB主旨調整"
                          value="主旨調整"
                          v-model="checkedNames"
                        />
                        <label for="AB主旨調整" style="width: 83%"
                          >主旨調整</label
                        >
                      </p>
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="AB內容調整"
                          id="AB內容調整"
                          value="內容調整"
                          v-model="checkedNames"
                        />
                        <label for="AB內容調整" style="width: 83%"
                          >內容調整</label
                        >
                      </p>
                    </div>

                    <p class="m-0 p-1 ps-3 my-2">自動回應</p>
                    <hr
                      class="m-0"
                      style="
                        width: 100%;
                        opacity: 1;
                        height: 0;
                        border: 0;
                        border-bottom: 1px solid #c4c4c4;
                      "
                    />
                    <div class="d-flex flex-wrap">
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="has_event_0"
                          id="has_event_0"
                          value="無"
                          v-model="checkedNames2"
                        />
                        <label for="has_event_0" style="width: 83%">無</label>
                      </p>
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="has_event_2_1"
                          id="has_event_2_1"
                          value="啟用中"
                          v-model="checkedNames2"
                        />
                        <label for="has_event_2_1" style="width: 83%"
                          >啟用中</label
                        >
                      </p>
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="has_event_1"
                          id="has_event_1"
                          value="草稿"
                          v-model="checkedNames2"
                        />
                        <label for="has_event_1" style="width: 83%">草稿</label>
                      </p>
                      <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                        <input
                          class="me-2"
                          style="position: relative; top: 1px"
                          type="checkbox"
                          name="has_event_4"
                          id="has_event_4"
                          value="執行中"
                          v-model="checkedNames2"
                        />
                        <label for="has_event_4" style="width: 83%"
                          >執行中</label
                        >
                      </p>
                    </div>
                    <p class="m-0 p-1 ps-3 my-2">活動時間</p>
                    <hr
                      class="m-0"
                      style="
                        width: 100%;
                        opacity: 1;
                        height: 0;
                        border: 0;
                        border-bottom: 1px solid #c4c4c4;
                      "
                    />
                    <div
                      class="
                        m-0
                        p-2
                        ps-3
                        auto-complicate-p
                        d-flex
                        align-items-center
                      "
                    >
                      <input
                        class="me-2"
                        style="position: relative; top: 1px"
                        type="checkbox"
                        name="dateRange"
                        id="dateRange"
                        :value="0"
                        v-model="checkedNames3"
                      />
                      <div style="width: 90%; position: relative">
                        <DatePicker v-model="filterDateRange" is-range>
                          <!-- @dayclick="updateRangeday(index)" -->
                          <template v-slot="{ togglePopover }">
                            <input
                              id="dropdown-moreoption-rangeinput"
                              class="ps-5"
                              style="
                                cursor: default;
                                width: 100%;
                                height: 40px;
                                border-radius: 10px;
                                border: 1px solid #c4c4c4;
                              "
                              placeholder="選擇日期"
                              :value="time_range"
                              @click="togglePopover()"
                              readonly
                            />
                            <img
                              src="../assets/date.svg"
                              alt=""
                              style="position: absolute; left: 10px; top: 10px"
                            />
                            <img
                              src="../assets/selection-arrow.png"
                              alt="searchImg"
                              style="position: absolute; right: 10px; top: 16px"
                            />
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <span class="col-3">
                  <Datepicker
                    v-model="monthData"
                    locale="tw"
                    inputClassName="dp-custom-input-sche"
                    cancelText="取消"
                    selectText="選擇"
                    monthPicker
                    :format="format"
                  >
                    <template #input-icon>
                      <img
                        src="../assets/date.svg"
                        alt=""
                        style="position: relative; right: -10px; top: -3px"
                      />
                    </template>
                  </Datepicker>
                </span> -->
                <div
                  class="d-flex justify-content-end"
                  style="width: 28%; position: relative; min-width: 110px"
                >
                  <Paginator
                    :rows="this.perPage"
                    v-model:first="nowPage"
                    :pageLinkSize="3"
                    template="PrevPageLink PageLinks NextPageLink"
                    :totalRecords="searchTodos.length"
                    @page="onPage($event)"
                    class="m-0"
                  ></Paginator>
                  <div
                    style="
                      position: absolute;
                      right: 5px;
                      bottom: -25px;
                      font-size: 14px;
                      color: #8a8a8a;
                      min-width: 100px;
                    "
                    v-if="this.searchTodos.length === 0"
                  >
                    目前 0 ，共
                    {{ Math.ceil(this.searchTodos.length / this.perPage) }} 頁
                  </div>
                  <div
                    style="
                      position: absolute;
                      right: 5px;
                      bottom: -25px;
                      font-size: 14px;
                      color: #8a8a8a;
                      min-width: 100px;
                    "
                    v-else
                  >
                    目前 {{ this.page }} ，共
                    {{ Math.ceil(this.searchTodos.length / this.perPage) }} 頁
                  </div>
                </div>
              </div>
              <!-- 查無資料 -->

              <div
                class="
                  d-flex
                  mt-4
                  justify-content-between
                  align-items-center
                  row-background2
                "
              >
                <span class="todos-time-title"
                  >發送時間
                  <span class="ms-2" style="cursor: pointer">
                    <img
                      src="../assets/sorting-button.png"
                      style="position: relative; top: -2px"
                      alt=""
                      @click="reverseButton()"
                      v-show="sortscheBtn === 0"
                    />
                    <img
                      src="../assets/black-down-arrow.png"
                      style="position: relative; top: -2px"
                      alt=""
                      @click="sortingButton()"
                      v-show="sortscheBtn === 1"
                    />
                    <img
                      src="../assets/black-up-arrow.png"
                      style="position: relative; top: -2px"
                      alt=""
                      @click="reverseButton()"
                      v-show="sortscheBtn === 2"
                    />
                  </span>
                </span>
                <!-- <span class="todos-time2">
                
                </span> -->
                <span class="todos-title"> 活動名稱 </span>
                <span class="todos-title"> 模板名稱 </span>
                <span class="todos-subsec text-center" style="width: 7vw">
                  AB Testing
                </span>
                <span class="list text-center" style="width: 6vw"
                  >自動回應</span
                >
                <span class="todos-subsec" style="width: 11vw"
                  >更新時間
                  <span class="ms-2" style="cursor: pointer">
                    <img
                      src="../assets/sorting-button.png"
                      style="position: relative; top: -2px"
                      alt=""
                      @click="reverseButton2()"
                      v-show="sortscheBtn2 === 0"
                    />
                    <img
                      src="../assets/black-down-arrow.png"
                      style="position: relative; top: -2px"
                      alt=""
                      @click="sortingButton2()"
                      v-show="sortscheBtn2 === 1"
                    />
                    <img
                      src="../assets/black-up-arrow.png"
                      style="position: relative; top: -2px"
                      alt=""
                      @click="reverseButton2()"
                      v-show="sortscheBtn2 === 2"
                    />
                  </span>
                </span>
                <span style="width: 63px"> &nbsp; </span>
              </div>

              <div class="noYearData" v-if="this.todos.length === 0">
                查無此月份排程紀錄。
              </div>
              <!-- main -->

              <div
                class="
                  row-background
                  todos-list
                  d-flex
                  justify-content-between
                  align-items-center
                "
                v-for="todo in newtodos"
                :key="todo.ScheduleName"
                :class="[todo.ScheduleName === beClick ? 'high-light' : '']"
                v-on:click="highlight(todo)"
                @mouseleave="mouseleave"
                v-else
              >
                <span class="todos-time"
                  >{{
                    todo.ScheduleExpression.toLocaleString("en-ZA").slice(0, 10)
                  }}
                  {{ todo.ScheduleExpression.toString().substr(16, 5) }}</span
                >
                <span class="todos-title">{{ todo.script_name }}</span>
                <span class="todos-title">{{ todo.template_name }}</span>
                <span
                  class="todos-subsec text-center"
                  style="width: 7vw"
                  v-if="todo.AB_type != null"
                  >{{ todo.AB_type }}</span
                >
                <span class="todos-subsec text-center" style="width: 7vw" v-else
                  >無</span
                >
                <span class="list text-center" style="width: 6vw">
                  <img
                    src="../assets/greenSpot.png"
                    alt=""
                    class="mb-1"
                    v-if="todo.script_state === '執行中'"
                  />
                  <img
                    src="../assets/graySpot.png"
                    alt=""
                    class="mb-1"
                    v-else-if="todo.script_state === '草稿'"
                  />
                  <img
                    src="../assets/lightGreen-spot.png"
                    alt=""
                    class="mb-1"
                    v-else-if="todo.script_state === '啟用中'"
                  />
                  {{ todo.script_state }}</span
                >
                <span class="todos-subsec" style="width: 11vw"
                  >{{ todo.modified_time.toLocaleString("en-ZA").slice(0, 10) }}
                  {{ todo.modified_time.toString().substr(16, 5) }}
                </span>
                <button class="del-btn" v-on:click.stop="willShow(todo)">
                  <div style="width: 50px" v-if="todo.ScheduleName === beClick">
                    <img src="../assets/white3spot.svg" />
                  </div>
                  <div style="width: 50px" v-else>
                    <img src="../assets/spot.svg" />
                  </div>
                  <div
                    class="popout"
                    style="color: #575757"
                    v-show="todo.ScheduleName === isShow"
                  >
                    <p
                      class="p-hover-top mb-0 p-1"
                      @click="routergoEmailSet(todo)"
                    >
                      <img
                        style="width: 17px; margin-right: 7px"
                        src="../assets/editBlack.svg"
                      />
                      編輯
                    </p>
                    <p
                      class="p-hover-mid mb-0 p-1"
                      @click="routergoScript2(todo)"
                      v-if="todo.script_state != '執行中'"
                    >
                      <img
                        style="width: 17px"
                        src="../assets/black-auto-respond.svg"
                      />
                      自動回應設定
                    </p>
                    <p
                      class="p-hover-mid mb-0 p-1"
                      @click.stop="showDetailsModal(todo, '_b')"
                      v-if="todo.AB_type != null"
                    >
                      <img style="width: 17px" src="../assets/eye-black.svg" />
                      預覽B組
                    </p>
                    <p
                      class="p-hover-mid mb-0 p-1"
                      @click="showModal(todo)"
                      v-if="todo.script_state != '無'"
                    >
                      <img style="width: 17px" src="../assets/eye-black.svg" />
                      預覽自動回應
                    </p>
                    <p
                      class="p-hover-bot mb-0 p-1"
                      @click="deleteModal(todo)"
                      v-if="todo.script_state != '執行中'"
                    >
                      <img
                        src="../assets/delete-black.svg"
                        style="margin: 0px 7px"
                      />
                      刪除
                    </p>
                  </div>
                </button>
              </div>
            </div>

            <div class="d-flex justify-content-center mb-5">
              <button
                class="schedule-button"
                @click="routergoEmailSet(this.childtodo)"
                style="
                  background-color: #eaf3f4;
                  color: #71afb6;
                  background-image: none;
                "
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/green-edit.svg" alt="" class="pe-2" />

                  <span> 編輯 </span>
                </div>
              </button>
              <button
                class="schedule-button"
                @click="routergoScript2(this.childtodo)"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../assets/auto-respond.svg" alt="" class="pe-2" />

                  <span> 設定自動回應 </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- loading screen -->
      <div class="loading-background" v-show="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import Pagination from "v-pagination-3";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import SeeScript from "../components/SeeScript.vue";
import Paginator from "primevue/paginator";
// import { tw } from 'date-fns/locale';
import SmsPreview from "../components/SmsPreview.vue";
import EmailPreview from "../components/EmailPreview.vue";

export default {
  setup() {
    const monthData = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });

    const format = (monthData) => {
      const month = monthData.month + 1;
      const year = monthData.year;

      return `${year}/0${month}`;
    };

    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return {
      monthData,
      format,
      target,
      autoShow,
      // tw,
    };
  },

  name: "ScheduleTemplate",
  components: {
    // DatePicker,
    // Globalside,
    Calendar,
    DatePicker,
    Paginator,
    Datepicker,
    SeeScript,
    SmsPreview,
    EmailPreview,
  },
  data() {
    return {
      triggerType: "篩選",
      checkedNames: [],
      checkedNames2: [],
      checkedNames3: [],
      filterDateRange: {},
      time_range: "",

      Parr: [],
      fullurlArr: [],
      searchInput: "",
      origintodos: "",
      scriptID2: "",
      addadd2: 0,
      emailChannel: true,
      forceRerender: 0,
      subject: "",

      notshow: false,
      url: "",
      url2: "",
      url3: "",
      url4: "",
      newSub: "",
      newSubsec: "",
      selectTodo: {},
      templateA: {},
      templateB: {},

      beClick: "",
      goScript: false,
      isShow: "",
      valueMonth: null,
      dayErrMsg: "",
      todos: [],
      searchData: [],
      searchTodos: [],
      templates: [],
      resName: "",
      resScriptName: "",
      resTitle: "",
      resGroupId: "",
      resGroup: {
        includeLabelNames: [],
        excludeLabelNames: [],
      },
      resSub: "",
      resSubsec: "",
      countText:'',
      resCost: "",
      resImg: null,
      reshaveABtest: "",
      reshaveB: "",
      restemplateA_id: "",
      resScheduleId: "",
      resLabelId: "",
      resScriptId: "",
      resScriptHasEvent: "",
      //datepicker控制
      // day: new Date(Date.now() + 3600 * 1000 * 24),
      day: null,
      time: new Date(new Date().setHours(0, 0, 0, 0)),
      // month:new Date,

      // 頁碼控制
      nowPage: 0,
      page: 1,
      perPage: 6,
      records: [],
      editinput: false,
      loading: false,
      loadTimeout: null,
      childtodo: null,
      ABsettingTrue: "",
      sortscheBtn: 0,
      sortscheBtn2: 0,

      range: {
        start: new Date(2020, 0, 6),
        end: new Date(2020, 0, 23),
      },
      masks: {
        input: "YYYY-MM-DD h:mm A",
      },
      modelConfig: {
        type: "string",
        mask: "iso",
        timeAdjust: "12:00:00",
      },

      searchMonth: "",
      htmlkey: "",
      html: "",

      events: [],
      newtodos: [],

      todotext:'',
      exampleModal:{},
      exampleModal2:{},
    };
  },
  watch: {
    monthData: function (val) {
      console.log("val", val);

      if (val === null) {
        this.get();
      } else {
        const searched = this.searchTodos.filter((d) => {
          console.log(
            "dS",
            d.ScheduleExpression.toLocaleString("en-ZA").slice(0, 10)
          );

          if ((val.month + 1).toString().length < 2) {
            this.searchMonth = "0" + (val.month + 1).toString();
          } else {
            this.searchMonth = val.month + 1;
          }
          console.log("month", this.searchMonth);

          const dateString = val.year + "/" + this.searchMonth;

          console.log(
            "find its",
            d.ScheduleExpression.toLocaleString("en-ZA").includes(dateString)
          );
          return d.ScheduleExpression.toLocaleString("en-ZA").includes(
            dateString
          );
        });
        console.log("try this", searched);
        this.newtodos = searched;
      }
    },
    searchInput: function (val) {
      if (val === "") {
        this.searchTodos = this.todos;
        this.newtodos = this.searchTodos.slice(0, this.perPage);
      } else {
        console.log(this.todos);
        this.searchTodos = this.todos.filter((d) => d.template_name.includes(val));
        this.newtodos = this.searchTodos.slice(0, this.perPage);
      }
    },
    checkedNames(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTodos = this.todos;
        this.newtodos = this.searchTodos.slice(0, this.perPage);
        return;
      }
      this.searchTodos = this.todos.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
      console.log(this.searchTodos);
      this.newtodos = this.searchTodos.slice(0, this.perPage);
    },
    checkedNames2(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTodos = this.todos;
        this.newtodos = this.searchTodos.slice(0, this.perPage);
        return;
      }
      this.searchTodos = this.todos.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
      console.log(this.searchTodos);
      this.newtodos = this.searchTodos.slice(0, this.perPage);
    },
    checkedNames3(val) {
      if (
        this.checkedNames[0] === undefined &&
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTodos = this.todos;
        this.newtodos = this.searchTodos.slice(0, this.perPage);
        return;
      }
      this.searchTodos = this.todos.filter((d) => {
        return (
          (this.checkedNames[0] === d.AB_type ||
            this.checkedNames[1] === d.AB_type ||
            this.checkedNames[2] === d.AB_type ||
            this.checkedNames[0] === undefined) &&
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[3] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
      console.log(this.searchTodos);
      this.newtodos = this.searchTodos.slice(0, this.perPage);
    },
    filterDateRange() {
      this.updateRange();
      if (this.checkedNames3[0] != undefined) {
        this.searchTodos = this.todos.filter((d) => {
          return (
            (this.checkedNames[0] === d.AB_type ||
              this.checkedNames[1] === d.AB_type ||
              this.checkedNames[2] === d.AB_type ||
              this.checkedNames[0] === undefined) &&
            (this.checkedNames2[0] === d.script_state ||
              this.checkedNames2[1] === d.script_state ||
              this.checkedNames2[2] === d.script_state ||
              this.checkedNames2[3] === d.script_state ||
              this.checkedNames2[0] === undefined) &&
            ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
              this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
              (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
                this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
              this.checkedNames3[0] === undefined)
          );
        });
      }
      this.newtodos = this.searchTodos.slice(0, this.perPage);
    },
  },
  computed: {
    filterCount() {
      return (
        this.checkedNames.length +
        this.checkedNames2.length +
        this.checkedNames3.length
      );
    },
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.todos.slice(startIndex, endIndex);
    },
  },
  created: async function () {
    // http://localhost:8080 test

    this.beClick = "";
    this.loading = true;
    await this.getCalenEvents();
    await this.get();

    if (this.$route.params.scheID != undefined) {
      const todoindex = this.searchTodos.findIndex((d) => {
        return d.ScheduleName === this.$route.params.scheID;
      });
      if (this.searchTodos[todoindex].ScheduleName != this.beClick) {
        this.highlight(this.searchTodos[0]);
      }

      const page = Math.ceil((todoindex + 1) / this.perPage);

      const startIndex = this.perPage * (page - 1);
      const endIndex = startIndex + this.perPage;
      this.newtodos = this.searchTodos.slice(startIndex, endIndex);
      this.nowPage = todoindex;
    }
    // this.getTemplate();
    // this.sortingButton2();
    // this.reverseButton2();

    // sessionStorage.setItem("resName", this.resTitle)
  },
  beforeRouteLeave: function (to, from) {
    sessionStorage.removeItem("template_key");
    if (
      to.path === "/ProductTextTemplate" ||
      to.path === "/MarketingSchedule"
    ) {
      return;
    } else {
      console.log("remove texttemplate storge");

      sessionStorage.removeItem("haveBtemplateFromSche");
      sessionStorage.removeItem("haveABtestFromSche");
      sessionStorage.removeItem("ABtestPercentFromSche");
      sessionStorage.removeItem("AtemplateFromSche");
      sessionStorage.removeItem("BtemplateFromSche");
    }
  },
  methods: {
    updateRange() {
      console.log(this.filterDateRange);
      console.log(this.filterDateRange.start.toISOString());
      this.time_range =
        this.filterDateRange.start.toISOString().slice(0, 10) +
        "~" +
        this.filterDateRange.end.toISOString().slice(0, 10);
    },
    test() {
      this.todos.find((d) => {
        console.log(d);
      });
    },
    onPage(event) {
      this.page = event.page + 1;
      const startIndex = event.rows * event.page;
      const endIndex = startIndex + event.rows;
      //event.page: New page number
      //event.first: Index of first record
      //event.rows: Number of rows to display in new page
      //event.pageCount: Total number of pages
      this.newtodos = this.searchTodos.slice(startIndex, endIndex);
      console.log(this.newtodos);
    },
    openExcludeModal() {
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    showDetailsModal: async function (todo, temp_b) {
      this.htmlkey = todo.template_id;
      // if (temp_b === "_b") {
      //   await this.getHtmlById_b();
      // } else {
      //   await this.getHtmlById();
      // }
      if (temp_b === "_b") {
        this.subject = todo.subject_b;
        await this.getHtmlById_b();
      } else {
        await this.getHtmlById();
        this.subject = todo.subject;
      }
      this.changeAllUrl();
      const exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    // 從預覽中顯示外層
    showOutsideExp(){
      this.showDetailsModal(this.childtodo, '_a')
    },
    getHtmlById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.htmlkey,
      };

      await allapi2
        .templatev2HtmlKeyGet(params)
        .then((res) => {
          console.log(res);
          this.html = res.data.content;
          this.html = this.html
            .replace(
              "{{p1}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
            )
            .replace(
              "{{p2}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
            )
            .replace(
              "{{p3}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
            )
            .replace(
              "{{p4}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
            )
            .replace("{{tracking}}", "");

          // console.log(this.html);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    getHtmlById_b: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        key: this.htmlkey + "_b",
      };

      await allapi2
        .templatev2HtmlKeyGet(params)
        .then((res) => {
          console.log(res);
          this.html = res.data.content;
          this.html = this.html
            .replace(
              "{{p1}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
            )
            .replace(
              "{{p2}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
            )
            .replace(
              "{{p3}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
            )
            .replace(
              "{{p4}}",
              "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
            )
            .replace("{{tracking}}", "");

          console.log(this.html);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    deleteModal(todo) {
      this.childtodo = todo;
      let deleteyes = confirm("刪除後即無法返回，是否確認刪除?");
      if (deleteyes) {
        this.removeTodo();
      } else {
        return;
      }
      console.log(todo);
    },
    dayChange() {
      this.editinput = true;
    },
    timeChange() {
      this.editinput = true;
    },

    removeTodo() {
      // this.loading = true;
      this.delete();
    },
    highlight: async function (todo) {
      console.log(todo);
      console.log(this.templates);
      if (todo === undefined) {
        this.resName = null;
        this.resGroup = {
          includeLabelNames: [],
          excludeLabelNames: [],
        };
        this.resTitle = null;
        this.resSub = null;
        this.resSubsec = null;
        this.resCost = null;
        this.resScheduleId = null;
        this.resLabelId = null;
        this.day = null;
        this.time = null;
        this.cost = null;
        this.resImg = null;
        this.beClick = undefined;
        this.goScript = false;
        this.childtodo = null;
        this.htmlkey = null;
      } else if (this.beClick != todo.ScheduleName) {
        this.goScript = true;
        this.beClick = todo.ScheduleName;
        this.childtodo = todo;
        this.htmlkey = todo.template_id;
        this.resName = todo.script_name;
        this.resGroup = todo.label_name;
        this.resTitle = todo.template_name;
        this.resScheduleId = todo.ScheduleName;
        this.resLabelId = todo.label_id;
        this.resScriptId = todo.script_id;
        this.resScriptHasEvent = todo.has_event;
        this.resSub = todo.subject;
        this.resSubsec = this.templateA.body_text;
        this.resCost = this.templateA.cost;
      } 
      // else if (this.beClick != "") {
      //   this.resName = null;
      //   this.resGroup = {
      //     includeLabelNames: [],
      //     excludeLabelNames: [],
      //   };
      //   this.resTitle = null;
      //   this.resSub = null;
      //   this.resSubsec = null;
      //   this.resCost = null;
      //   this.resScheduleId = null;
      //   this.resLabelId = null;
      //   this.day = null;
      //   this.time = null;
      //   this.cost = null;
      //   this.resImg = null;
      //   this.beClick = undefined;
      //   this.goScript = false;
      //   this.childtodo = null;
      //   this.htmlkey = null;
      // }
      // this.resImg = "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/" + template.banner_key[3];
      // var moment = require("moment");
      console.log("resimg", this.resImg);
      console.log("this.resGroup", this.resGroup);
      console.log(this.day);
      console.log(this.time);
      // this.day = todo.ScheduleExpression.substr(0, 10);
      // this.time = todo.ScheduleExpression.substr(11, 5);

      this.editinput = false;
    },
    willShow(todo) {
      if (this.isShow != todo.ScheduleName) {
        this.isShow = todo.ScheduleName;
      } else {
        this.isShow = "";
      }
      // if (this.beClick != todo.ScheduleName) {
      //   this.beClick = todo.ScheduleName;
      // }
    },
    mouseleave() {
      this.isShow = "";
    },
    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .scheduleGet(params)
        .then((res) => {
          console.log("3 item", res);
          // console.log("sorting", res.data.Data[0].ScheduleExpression)

          this.todos = res.data.Data;
          this.todos.forEach((e) => {
            const d = this.events.find((d) => d.id === e.script_name);
            if (d != undefined) {
              e.script_name = d.title;
              e.realScript_name = d.id;
              e.活動時間 = `${d.start} - ${d.end}`;
            }
          });
          // this.todos.forEach((a) => {
          //   const event = this.events.filter((d) => {
          //     return d.activity_id === a.script_name;
          //   });
          //   if (event[0] != undefined) {
          //     a.script_name = event[0].activity_name;
          //     a.realScript_name = event[0].activity_id;
          //   }
          // });

          this.todos.map((d) => {
            d.ScheduleExpression = moment(d.ScheduleExpression).add(-8, "h")._d;
            d.modified_time = moment(d.modified_time).add("h")._d;
            if (d.has_event == 2 && d.State == 1) {
              d.script_state = "啟用中";
            } else if (d.has_event == 2 && d.State == 0) {
              d.script_state = "執行中";
            } else if (d.has_event == 0) {
              d.script_state = "無";
            } else if (d.has_event == 1) {
              d.script_state = "草稿";
            }
          });
          this.todos.sort((a, b) => {
            return new Date(b.modified_time) - new Date(a.modified_time);
          });

          this.searchTodos = this.todos;

          this.newtodos = this.searchTodos.slice(0, this.perPage);
          this.highlight(this.searchTodos[0]);
          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    sortingButton() {
      this.todos.sort((a, b) => {
        return new Date(a.ScheduleExpression) - new Date(b.ScheduleExpression);
      });
      this.nowPage = 0;
      this.searchTodos = this.todos;
      if (this.searchTodos[0].ScheduleName != this.beClick) {
        this.highlight(this.searchTodos[0]);
      }
      this.newtodos = this.searchTodos.slice(0, this.perPage);
      this.sortscheBtn = 2;
      this.sortscheBtn2 = 0;
    },
    reverseButton() {
      this.todos.sort((a, b) => {
        return new Date(b.ScheduleExpression) - new Date(a.ScheduleExpression);
      });
      this.nowPage = 0;
      this.searchTodos = this.todos;
      if (this.searchTodos[0].ScheduleName != this.beClick) {
        this.highlight(this.searchTodos[0]);
      }
      this.newtodos = this.searchTodos.slice(0, this.perPage);
      this.sortscheBtn = 1;
      this.sortscheBtn2 = 0;
    },

    sortingButton2() {
      this.todos.sort((a, b) => {
        return new Date(a.modified_time) - new Date(b.modified_time);
      });
      this.nowPage = 0;
      this.searchTodos = this.todos;
      if (this.searchTodos[0].ScheduleName != this.beClick) {
        this.highlight(this.searchTodos[0]);
      }
      this.newtodos = this.searchTodos.slice(0, this.perPage);
      this.sortscheBtn = 0;
      this.sortscheBtn2 = 2;
    },
    reverseButton2() {
      this.todos.sort((a, b) => {
        return new Date(b.modified_time) - new Date(a.modified_time);
      });
      this.nowPage = 0;
      this.searchTodos = this.todos;
      if (this.searchTodos[0].ScheduleName != this.beClick) {
        this.highlight(this.searchTodos[0]);
      }
      this.newtodos = this.searchTodos.slice(0, this.perPage);
      this.sortscheBtn = 0;
      this.sortscheBtn2 = 1;
    },

    getTemplate: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .templateGet(params)
        .then((res) => {
          console.log("templates", res);
          this.templates = res.data.Data;

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
          this.loading = false;
        });
    },
    moveTemplate: async function () {
      console.log("this.restemplateA_id", this.restemplateA_id);
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        template_id: this.restemplateA_id,
      };

      await allapi2
        .templateMigrationTemplateIdPost(params)
        .then((res) => {
          console.log("模板A移走了", res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    moveTemplateB: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        template_id: this.restemplateA_id + "_b",
      };

      await allapi2
        .templateMigrationTemplateIdPost(params)
        .then((res) => {
          console.log("模板B移走了", res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // alert("post連線有誤" + err);
          this.loading = false;
        });
    },
    delete: async function () {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.childtodo.ScheduleName,
      };

      await allapi2
        .scheduleRuleIDDelete(params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          alert("delete連線有誤" + err);
          this.loading = false;
          console.log(err);
        });
      await this.get();
    },
    

    showModal(todo) {
      this.highlight(todo)
      this.forceRerender++
      setTimeout(() => {
        console.log(todo);
        this.scriptID2 = todo.script_id;
        this.addadd2 = this.addadd2 + 1;
        sessionStorage.setItem("setResEventTitle", this.resTitle);
  
        this.scriptModal = new bootstrap.Modal(
          document.getElementById("scriptModal"),
          {
            keyboard: false,
          }
        );
        this.scriptModal.show();
      }, 0);
    },
    showUnlayer: function (childHtml, childSub) {
      this.html = childHtml;
      this.subject = childSub;
      this.changeAllUrl();

      this.exampleModal = new bootstrap.Modal(
        document.getElementById("exampleModal"),
        {
          keyboard: false,
        }
      );
      this.exampleModal.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    showSMSexp: function (childValue) {
      this.todotext = childValue;
      this.resSubsec = childValue;
      this.highlightUrl();
      this.exampleModal2 = new bootstrap.Modal(
        document.getElementById("exampleModal2"),
        {
          keyboard: false,
        }
      );
      this.exampleModal2.show();
      const backdrop = document.getElementsByClassName("modal-backdrop");
      backdrop[backdrop.length - 1].style['z-index']=4500
    },
    highlightUrl() {
      console.log(this.todotext);
      console.log(this.resSubsec);
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }
      this.fullurlArr = isValidURL(this.todotext);
      console.log(this.fullurlArr);

      if (this.fullurlArr != null) {
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.Parr.push("{[pfullurl]}" + index);
          this.resSubsec = this.resSubsec.replace(
            this.fullurlArr[index],
            this.Parr[index]
          );
        }
        for (let index = 0; index < this.fullurlArr.length; index++) {
          this.resSubsec = this.resSubsec.replace(
            this.Parr[index],
            "<a href='" +
              this.fullurlArr[index].slice(0, -9) +
              "' target='_blank'>" +
              this.fullurlArr[index] +
              "</a>"
          );
        }
      }
      this.countText = this.todotext
        .replace(/{lastname}/g, "王")
          .replace(/{firstname}/g, "◯◯")
          .replace(/{name}/g, "王◯◯");
      this.resSubsec = this.resSubsec
        .replace(/{lastname}/g, "王")
          .replace(/{firstname}/g, "◯◯")
          .replace(/{name}/g, "王◯◯");

      console.log("this.countText", this.countText);
    },
    changeAllUrl: async function () {
      function isValidURL(string) {
        var res = string.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res;
      }

      this.fullurlArr = isValidURL(this.html);
      console.log(this.fullurlArr)
      for (let index = 0; index < this.fullurlArr.length; index++) {
        if (this.fullurlArr[index].includes("retailurl.net")) {
          this.html = this.html.replace(
            this.fullurlArr[index] + "{{id}}",
            this.fullurlArr[index]
          );
        }
      }
    },

    routergoEmailSet(todo) {
      if (todo === null) {
        return alert("請先選擇排程");
      }
      console.log(moment(todo.ScheduleExpression).add(-10, "m")._d);
      console.log(new Date());
      console.log(
        new Date() > moment(todo.ScheduleExpression).add(-10, "m")._d
      );
      if (new Date() > moment(todo.ScheduleExpression).add(-10, "m")._d) {
        return alert("此排程將在10分鐘內執行，已無法在進行更改");
      }
      if (this.beClick != todo.ScheduleName) {
        this.highlight(todo);
      }
      if (this.goScript) {
        sessionStorage.setItem("template_name", todo.template_name);
        this.$router.push({
          name: "EmailSetting",
          params: {
            key: todo.template_id,
            template_name: todo.template_name,
            label_id: todo.label_id,
            label_name: JSON.stringify(todo.label_name),
            ScheduleName: todo.ScheduleName,
            script_id: todo.script_id,
            script_name: todo.realScript_name,
            subject: todo.subject,
            subject_b: todo.subject_b,
            AB_proportion: todo.AB_proportion,
            ScheduleExpression: todo.ScheduleExpression,
            sender: todo.Sender,
            sms_sender: todo.sms_sender,
            has_event: todo.has_event,
            AB_type: todo.AB_type,
          },
        });
      } else {
        alert("請先選擇排程");
      }
    },
    routergoScript2: async function (todo) {
      if (todo === null) {
        return alert("請先選擇排程");
      }
      console.log(moment(todo.ScheduleExpression).add(-1, "h")._d);
      console.log(new Date());
      if (new Date() > moment(todo.ScheduleExpression).add(-10, "m")._d) {
        return alert("此排程將在10分鐘內執行，已無法在進行更改");
      }
      if (this.beClick != todo.ScheduleName) {
        this.highlight(todo);
      }
      if (this.goScript) {
        sessionStorage.setItem("setResEventName", this.resName);
        sessionStorage.setItem("setResEventTitle", this.resTitle);
        sessionStorage.setItem("setResScheduleId", this.resScheduleId);
        sessionStorage.setItem("setResScriptId", this.resScriptId);
        sessionStorage.setItem("setResLabelName", this.resGroup);
        sessionStorage.setItem("setResScriptHasEvent", this.resScriptHasEvent);
        sessionStorage.setItem("isEmailChannel", true);
        this.$router.push({
          name: "MarketingScript",
        });
      } else {
        alert("請先選擇排程");
      }
    },
  },
};
</script>

<style>
/* MarketingSchedule */
.modal-footer {
  flex-wrap: inherit;
  border-top: none;
}
/* .alert-modal-body{
  text-align: start;
} */
img.logo {
  width: 100%;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
img.footer {
  width: 100%;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}

.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.modal {
  z-index: 4000;
}
.name-input {
  border: none;
  border-radius: 10px;
  height: 40px;
  background-color: #eaf3f4;
}
.loop-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.mx-input {
  background-color: #eaf3f4;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
}
.VuePagination__pagination-item-prev-chunk {
  display: none;
}
.VuePagination__pagination-item-next-chunk {
  display: none;
}
.VuePagination__pagination-item-next-page .page-link {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  /* border: none; */
}

.VuePagination__pagination-item-prev-page .page-link {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* border: none; */
}
.page-item.disabled .page-link {
  background-color: #eaf3f4;
}
.VuePagination__pagination-item-next-page {
  color: #eaf3f4;
}

.disabled {
  color: #eaf3f4;
}
.page-item.active .page-link {
  z-index: 3;
  /* color: #575757; */
  background-color: #71afb6;
  color: #fff;
  border-color: transparent;
}
.page-item.disable .page-link {
  background-color: #71afb6;
}
.page-link {
  color: #000;
  font-weight: 700;
  background-color: #eaf3f4;
  /* border-top-right-radius: 30px; */
}
.high-light-orange {
  background-image: linear-gradient(to bottom right, #ffedc9, #fcc168);
}
.high-light {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.noShow {
  display: none;
}
.header2 {
  font-size: 24px;
  margin: 20px;
  font-weight: bold;
}
.leftImg {
  position: relative;
  top: -2px;
  font-weight: bold;
  cursor: pointer;
}
.reserve-title {
  font-size: 25px;
  text-align: center;
}
.row-background {
  background-color: #eaf3f4;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 18px;
  height: 40px;
}
.row-background2 {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 18px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.detail-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.green-span {
  /* display: inline-block; */
  background-color: #eaf3f4;
  border-radius: 8px;
  font-size: 18px;
  height: 2rem;
  line-height: 2rem;
  color: #bbb;
  /* width: 11rem; */
  text-align: center;
}
.green-span-1 {
  /* display: inline-block; */
  background-color: #eaf3f4;
  border-radius: 8px;
  font-size: 18px;
  height: 2rem;
  line-height: 2rem;
  /* width: 25rem; */
  text-align: center;
  color: #bbb;
}
.todo-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-div {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin-bottom: 2rem;
}
.text-span {
  /* display: inline-block; */
  background-color: #eaf3f4;
  border-radius: 20px;
  padding: 2rem;
  /* margin-left: 9rem; */
  height: 10rem;
  color: #bbb;
  overflow-y: auto;
  /* width: 60.2rem; */
}
.todos-list {
  margin: 20px 0 0 0;
}
.todos-time {
  margin-left: 30px;
  width: 150px;
}
.todos-time-title {
  margin-left: 30px;
  width: 150px;
}
.todos-time2 {
  margin-left: 1rem;
}

.todos-title {
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todos-title2 {
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todos-sub {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todos-subsec {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control {
  display: inline-block;
  border: none;
  background-color: #eaf3f4;
}
.detail-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;

  padding: 30px 50px;
  margin-bottom: 2rem;
}
.date-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
  padding: 30px 50px;
}
.date-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.schedule-button {
  display: block;
  border: none;
  border-radius: 10px;
  width: 30rem;
  font-size: 18px;
  margin: auto;
  height: 2.5rem;
  background-image: linear-gradient(to bottom right, #71afb6, #b5dce0);
  color: #fff;
}
.reserving-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;

  padding: 30px 50px;
}
.del-btn {
  background-color: inherit;
  background: transparent;
  border: none;
  height: 40px;
  position: relative;
  right: 10px;
}
.popout {
  background-color: #fff;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 152px;
  right: -112px;
  top: 33px;
  color: #575757;

  text-align: left;
}
.popout img {
  position: relative;
  width: 13px;
  top: -2px;
  margin: 0px 5px 0px 5px;
}
.p-hover-top:hover {
  border-radius: 10px 10px 0px 0px;
  background-color: #eaf3f4;
  color: #575757;
}
.p-hover-mid:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.p-hover-bot:hover {
  border-radius: 0px 0px 10px 10px;
  background-color: #eaf3f4;
  color: #575757;
}
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}
.set-time-title {
  color: #71afb6;
}
.schedule-sub-title {
  color: #ababab;
}

.detail-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: 15px;
  outline: 0;
  /* width: 100%; */
}

.modal-content,
.modal-card {
  border-radius: 15px;
  /* width: 700px; */
}
.alert-modal-header {
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  padding: 0.5rem 1rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.alert-modal-body {
  text-align: center;
}

.alert-modal-footer {
  border-top: none;
  justify-content: center;
}

.modal-footer {
  display: flex;
}
.modal-btn-close {
  color: #fff;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border: none;
  width: 10vw;
}

.modal-btn-close2 {
  color: #000;
  background-color: #eaf3f4;
  border: none;
  width: 10vw;
}

.noYearData {
  padding: 20px;
  font-size: 20px;
}
.date-input {
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  height: 40px;
  text-align: center;
}
/* .vc-popover-caret.align-left {
  left: 50%;
}
.vc-time-date {
  display: none !important;
}
.vc-pane-container {
  width: 27vw;
}
.vc-time-picker {
  width: 27vw;
}
.vc-select select {
  width: 11vw;
  text-align: center;
} */
.dp-custom-input-sche {
  border-radius: 10px !important;
  border: 1px solid #575757 !important;
}
.dp__input_wrap {
  position: relative;
  box-sizing: unset;
  border-radius: 20px;
}
/* 
.dp__input {
  background-color: #eaf3f4 !important;
  border-radius: 10px !important;
  border: none !important;
} */
.basic-btn {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}
.highlightsche {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
@media screen and (min-width: 1024px) and (max-width: 1140px) {
  .col-3xl-2 {
    width: 73vw;
    transform: translate(3%, 0);
  }
}

@media screen and (min-device-width: 1600px) and (max-device-width: 1920px) {
  .col-3xl-2 {
    /* display: flex;
    justify-content: center; */
    width: 75vw;
    transform: translate(-2%, 0);
  }
  .btn-lg {
    min-width: 30.5vw;
    font-size: 20px;
    border-radius: 10px;
  }
}
</style>
