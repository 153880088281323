<template>
  <div class="container home">
    <div>use to copy empty page</div>
    <div style="background-color: #c4c4c4"></div>
  </div>
</template>

<script>
export default {
  name: "TryNewTrigger",
  components: {},
  data() {
    return {
      scriptTree: [
        {
          action: "傳送Email",
          cron: "20 16 14 * *",
          daytime: "",
          endcount: -1,
          event: "定期投放",
          is_yes: null,
          ruleID: "ReMA_Trigger_6238b779",
          subject: "每月14號定期 接週期每天",
          template_name: "9月7 成效測試V2 copy",
          time_after: 0,
          time_every: 31,
          time_interval: null,
          time_setting: {},
          time_start: "2022-09-14",
          vice_ruleID: "ve_6238b779_0",
        },
        {
          action: "傳送Email",
          cron: "20 16 14 * *",
          daytime: "",
          endcount: -1,
          event: "定期投放",
          is_yes: 1,
          ruleID: "ReMA_Trigger_6238b779",
          subject: "每月14號定期 接週期每天",
          template_name: "9月7 成效測試V2 copy",
          time_after: 0,
          time_every: 31,
          time_interval: null,
          time_setting: {},
          time_start: "2022-09-14",
          vice_ruleID: "ve_6238b779_0",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
</style>