<template>
  <div class="modal-body alert-modal-body text-start py-3 px-5">
    <div class="d-flex align-items-center mb-3">
      <div style="width: 20%; line-height: 40px">開始時間</div>
      <div class="cycletime-setting-block">
        {{ this.$props.cycleTime_setting.time_start }}
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div style="width: 20%; line-height: 40px">發送方式</div>
      <div class="cycletime-setting-block mb-3">定期投放</div>
    </div>
    <div class="d-flex flex-wrap align-items-center mb-3">
      <div style="width: 20%; line-height: 40px">周期條件</div>
      <div class="cycletime-setting-block ">日</div>
      <div style="width: 20%"></div>
      <div class="cycletime-setting-block mb-3 mt-3" style="width:60%">
        每{{ this.$props.cycleTime_setting.time_setting.time_every }}天
      </div>
      <div style="width: 20%; line-height: 40px;text-align:right">投放一次</div>
      <div style="width: 20%"></div>
      <div class="cycletime-setting-block">
        {{ this.$props.cycleTime_setting.time_setting.daytime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSettingModalDay",
  components: {},
  props: {
    cycleTime_setting: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.cycletime-setting-block {
  width: 80%;
  border: 1px solid #c4c4c4;
  line-height: 40px;
  border-radius: 10px;
  color: #c4c4c4;
  text-align: center;
}
</style>