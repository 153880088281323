<template>
  <div class="container home">use to copy empty page</div>
</template>

<script>
export default {
  name: "LineMarketingTable",
  components: {},
  data() {
    return {};
  },
  created() {
  },
  methods: {
    
  },
};
</script>

<style scoped>
</style>