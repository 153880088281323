<template>
  <div
    class="modal"
    id="downloadBillModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="z-index: 5000"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 350px">
      <div class="modal-content" style="width: 350px">
        <div
          class="modal-header alert-modal-header d-flex justify-content-start"
        >
          <img
            src="../../assets/arrow-white.svg"
            alt="arrow"
            style="cursor: pointer"
            data-bs-dismiss="modal"
          />
        </div>
        <div style="padding: 25px 42px">
          <div>
            時間設定
            <BtnInfoBox
              content="該明細為月結計算，因此僅提供這個月之前的帳單下載。"
              direction="right"
            ></BtnInfoBox>
          </div>
          <div class="mt-3">
            <Datepicker
              v-model="billData"
              locale="tw"
              autoApply
              monthPicker
              :format="format_start"
              :clearable="false"
              inputClassName="dp-custom-input"
              :maxDate="this.monthEnd"
            >
              <template #input-icon>
                <img
                  src="../../assets/date.svg"
                  alt=""
                  style="position: relative; right: -10px; top: -1px"
                />
                <img
                  src="../../assets/greenDownArrow.svg"
                  alt=""
                  style="
                    position: absolute;
                    top: 10px;
                    right: -235px;
                    z-index: 50;
                  "
                />
              </template>
            </Datepicker>
          </div>
          <div class="mt-2">
            <a
              href="https://data-ret-ai-ling.gitbook.io/ju-dian-zi-xun-first-cdp-shi-yong-shui-ming/ping-tai-guan-li-yu-she-ding/you-jian-yu-jian-xun-jia-ge-fang-an"
              target="_blank"
              rel="noopener noreferrer"
              class="GrayC4"
              >查看計價方式</a
            >
          </div>
          <div class="d-flex justify-content-between align-items-center mt-3">
            <button
              class="basic-btn"
              style="width: 125px"
              data-bs-dismiss="modal"
            >
              取消
            </button>
            <button
              class="basic-btn highlight-btn"
              style="width: 125px"
              @click="downloadBill"
            >
              下載
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>	

<script>
import { ref } from "vue";
import BtnInfoBox from "../BtnInfoBox.vue";
import moment from "moment";

export default {
  name: "DownloadBillModal",
  components: {
    BtnInfoBox,
  },
  props: {},

  setup() {
    const billData = ref({
      month: new Date().getMonth()-1,
      year: new Date().getFullYear(),
    });

    const format_start = (billData) => {
      const month = billData.month + 1;
      const year = billData.year;

      return `${year}/${month}`;
    };

    return {
      billData,
      format_start,
    };
  },

  data() {
    return {
      monthEnd: "",
    };
  },
  created() {
    this.monthEnd = moment(new Date()).add(-1,"M").endOf("month").toISOString();
  },
  methods: {
    downloadBill() {
      let bill_date = "";
      console.log(this.billData);
      if (this.billData.month+1 < 10) {
        bill_date = `${this.billData.year}-0${this.billData.month+1}`;
      } else {
        bill_date = `${this.billData.year}-${this.billData.month+1}`;
      }
      this.$emit("downloadBill", bill_date);
    },
  },
};
</script>

<style scoped>
.GrayC4:hover {
  color: #8a8a8a;
}
</style>