<template>
  <div
    class="select-container"
    style="width: 100%; position: relative"
    ref="target"
  >
    <div
      class="d-flex justify-content-end align-items-center"
      style="
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 0 14px;
        height: 40px;
      "
      :style="
        autoShow ? 'border-bottom:none' : 'border-bottom:1px solid #c4c4c4'
      "
      @click="autoShow = !autoShow"
    >
      <div class="flex-fill text-start" v-if="selectValue[0] != undefined">
        <span v-if="!selectValue.includes('2000-01-01')">
          {{ selectValue }}
        </span>
        <span v-else>
          全部時間
        </span>
        <span v-if="!selectValue.includes('~')"> &nbsp;天內</span>
      </div>
      <div class="flex-fill text-start" style="color: #c4c4c4" v-else>
        {{ holder }}
      </div>
      <img src="../../assets/selection-arrow.png" alt="selection-arrow" />
    </div>
    <div
      class="auto-complicate-div-moreoption"
      style="width: 100%; position: absolute"
      v-show="autoShow"
      @mouseenter="autoShow = true"
    >
      <p
        v-for="(value, index) in valueArr"
        :key="index"
        class="
          text-start
          m-0
          auto-complicate-p-moreoption
          d-flex
          align-items-center
        "
        style="padding: 5px 14px"
        @mouseenter="hoverOnIndex = index"
        :class="hoverOnIndex === index ? 'highlight-moreoption-timestate' : ''"
        @click="this.childClick('', index)"
      >
        <label
          :for="index"
          class="word-overflow"
          v-if="Object.prototype.toString.call(valueArr) === '[object Object]'"
          >{{ index }}</label
        >
        <label :for="value" class="word-overflow" v-else>{{ value }}</label>
        <span style="width: 10px">&nbsp;</span>
      </p>
    </div>
    <div
      class="right-auto-complicate-div-moreoption d-flex align-items-center"
      @mouseenter="autoShow = true"
      v-if="autoShow && hoverOnIndex === 1"
    >
      <DatePicker v-model="range" :max-date="new Date()" is-range>
        <template v-slot="{ togglePopover }">
          <input
            id="dropdown-moreoption-rangeinput"
            class="ps-2"
            style="
              cursor: default;
              width: 100%;
              height: 40px;
              border-radius: 10px;
              border: 1px solid #c4c4c4;
            "
            placeholder="選擇日期"
            :value="time_range"
            @click="togglePopover()"
            readonly
          />
          <img
            style="position: absolute; top: 53px; right: 25px"
            src="../../assets/selection-arrow.png"
            alt="selection-arrow"
          />
        </template>
      </DatePicker>
    </div>
    <div
      class="right-auto-complicate-div-moreoption d-flex align-items-center"
      @mouseenter="autoShow = true"
      v-else-if="autoShow && hoverOnIndex === 2"
    >
      <DatePicker v-model="day" :max-date="new Date()">
        <template v-slot="{ togglePopover }">
          <input
            id="dropdown-moreoption-dayinput"
            class="ps-2"
            style="
              width: 75%;
              height: 40px;
              border-radius: 10px;
              border: 1px solid #c4c4c4;
            "
            placeholder="選擇日期或輸入天數"
            :value="day_from_now"
            @click="togglePopover()"
            @input="setDay_from_now($event)"
            autocomplete="off"
          />
          <span class="ms-2"> 天內 </span>
          <img
            style="position: absolute; top: 53px; left: 175px"
            src="../../assets/selection-arrow.png"
            alt="selection-arrow"
          />
        </template>
      </DatePicker>
    </div>
    <div
      class="right-auto-complicate-div-moreoption d-flex align-items-center"
      @mouseenter="autoShow = true"
      v-else-if="autoShow && hoverOnIndex === 0"
    >
      <div class="text-center" style="width:100%">
        --
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import moment from "moment";

export default {
  name: "DropdownMoreOption",
  components: {},
  props: {
    valueArr: [Array, Object],
    selectValue: [Array, Object, String],
    changingSelected: String,
    holder: String,
  },
  setup(props) {
    const autoShow = ref(false);
    const hoverOnIndex = ref();
    const target = ref(null);

    onClickOutside(target, () => {
      autoShow.value = false;
      if (props.selectValue.includes("~")) {
        hoverOnIndex.value = 1;
      } else if (props.selectValue != "") {
        hoverOnIndex.value = 2;
      }else{
        hoverOnIndex.value = 3;
      }
    });

    return {
      target,
      autoShow,
      hoverOnIndex,
    };
  },
  data() {
    return {
      range: {
        start: "",
        end: "",
      },
      time_range: "",
      checkedValues: "",
      day_from_now: "",
      day: "",
    };
  },
  created() {
     if (this.$props.selectValue.includes("2000-01-01")) {
      this.time_range = '';
      this.hoverOnIndex = 0;
    }else if (this.$props.selectValue.includes("~")) {
      this.time_range = this.$props.selectValue;
      this.hoverOnIndex = 1;
    } else if (this.$props.selectValue != "") {
      this.day_from_now = this.$props.selectValue;
      this.hoverOnIndex = 2;
    }
  },
  watch: {
    range() {
      this.updateRange();
      this.childClick(this.time_range, 1);
    },
    day() {
      this.updateDay();
      this.childClick(this.day_from_now, 2);
    },
  },
  methods: {
    updateRange() {
      console.log(this.range);
      console.log(this.range.start.toISOString());
      this.time_range =
        this.range.start.toISOString().slice(0, 10) +
        "~" +
        this.range.end.toISOString().slice(0, 10);
    },
    updateDay() {
      const end = new Date();
      let dayArr = this.day.toISOString().slice(0, 10).split("-");
      dayArr = dayArr.map((d) => {
        return +d;
      });
      const momentMonth = dayArr[1] - 1;
      dayArr.splice(1, 1, momentMonth);
      const start = moment(dayArr);
      console.log(dayArr);
      console.log(start.diff(end, "days"));
      this.day_from_now = -start.diff(end, "days");
    },
    setDay_from_now(e) {
      this.day_from_now = +e.target.value;
      this.childClick(this.day_from_now, 2);
    },
    childClick(checkedValues, index) {
      if (index === 1) {
        checkedValues = this.time_range;
      } else if (index === 2){
        checkedValues = this.day_from_now;
      }else if (index === 0){
        checkedValues = '全部時間';
      }
      this.$emit(
        "selectValueByDrop",
        checkedValues,
        this.$props.changingSelected,
        index
      );
      // this.checkedValues.push(value)
      // this.autoShow = false;
    },
  },
};
</script>

<style>
/* Dropdown-moreoption */
.auto-complicate-div-moreoption {
  position: absolute;
  border: 1px solid #c4c4c4;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  background-color: white;
  z-index: 99;
}
.right-auto-complicate-div-moreoption {
  width: 270px;
  height: 113px;
  border: 1px solid #c4c4c4;
  border-top-right-radius: 10px;
  border-left: none;
  background-color: white;
  position: absolute;
  top: 40px;
  left: 250px;
  border-bottom-right-radius: 10px;
  padding: 17px 15px;
  z-index: 95;
}
.select-container {
  color: #575757;
  text-align: center;
  cursor: default;
}
.auto-complicate-p-moreoption {
  color: #575757;
}
.auto-complicate-p-moreoption:hover {
  background-color: #eaf3f4;
  color: #575757;
}
.auto-complicate-p-moreoption:hover:first-child {
  background-color: #eaf3f4;
  color: #575757;
  border-top-left-radius: 10px;
}
.auto-complicate-p-moreoption:hover:last-child {
  background-color: #eaf3f4;
  color: #575757;
  border-bottom-left-radius: 10px;
}
.tipInCustomRFM {
  opacity: 0.7 !important;
  padding: 3px 6px !important;
  background-color: #575757;
  border: 1px solid #575757;
  border-radius: 10px;
  color: white;
  /* height: 30px; */
  /* line-height: 30px; */
}
.word-overflow {
  /* display: inline-block; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#dropdown-moreoption-rangeinput::-webkit-input-placeholder {
  font-size: 14px;
  transform: translate3d(0, -2px, 0);
}
#dropdown-moreoption-dayinput::-webkit-input-placeholder {
  font-size: 14px;
  transform: translate3d(0, -2px, 0);
}
.highlight-moreoption-timestate {
  background-color: #eaf3f4;
  color: #575757;
}
.highlight-moreoption-timestate:first-child {
  background-color: #eaf3f4;
  color: #575757;
  border-top-left-radius: 10px;
}
.highlight-moreoption-timestate:last-child {
  background-color: #eaf3f4;
  color: #575757;
  border-bottom-left-radius: 10px;
}
</style>