<template>
	<div class="modal expEmailModal2-background" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 5000">
		<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content ">
			<div class="modal-header alert-modal-header d-flex justify-content-end">
				<img src="../assets/whiteX.svg" alt="arrow" style="cursor: pointer" data-bs-dismiss="modal" />
			</div>

			<div class="sms-box-content">
				<p style="font-size: 24px">SMS預覽</p>
			
				<div class="sms-phone-card">
					<div class="modal-header client-modal-header p-0 text-center" style="border: none">
						<img src="../assets/imagedemo/sms-header.svg" class="logo" alt="client-logo" />
					</div>

					<div class="modal-body client-modal-body p-0">
						<div class="container">
							<div class="mb-3">
								<pre v-html="resSubsec" ref="textarea" rows="1" class="sms-bubble font-12" ></pre>
							</div>
						</div>

						<div class="modal-footer d-flex justify-content-center p-0" style="border: none" >
							<img src="../assets/imagedemo/sms-footer.svg" class="logo m-0" alt="client-logo" />
						</div>
					</div>
				</div>

				<p class="mt-3 font-14">
					您目前輸入
					<span class="Cyan">{{ countText?.length }}</span>
					個字元了，簡訊則數為
					<span class="Cyan">{{ Math.ceil(countText?.length / 70) }}</span>
					則
				</p>

				<p class="m-0 mt-3 font-14">
					<span class="Cyan">提示</span>
					請務必點擊測試，確保連結為正確路徑
				</p>
				</div>
			</div>
		</div>
	</div>
</template>	

<script>
export default {
  name: "SmsPreview",
  props: {
	countText: String,
	resSubsec: null
  },
};
</script>

<style scoped>
.modal-content {
  border-radius: 15px;
  max-height: 80vh;
}
.modal-dialog {
  width: 426px;
}

.sms-box-content {
  padding: 25px 75px 20px;
}

img.logo {
  width: 100%;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  height: 76px;
  width: 275px;
}

.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 2rem;
  padding: 30px;
  border-radius: 25px;
  width: 330px;
  word-break: break-all;
  /* overflow-wrap: anywhere; */
  white-space: pre-wrap;
  width: 100%;
  overflow-x: hidden;
}

.sms-phone-card {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	height: calc(80vh - 265px);
	width: 275px;
}
.container {
	padding: 0 36px 0px 20px;
	height: calc(80vh - 265px - 152px);
	overflow: auto;
}
</style>