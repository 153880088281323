<template>
  <div v-if="!notshow" class="d-flex justify-content-center align-items-center">
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium">線上購物行為</span>
        <span class="title-button-div" v-for="web in websites" :key="web">
          <button
            style="height: 40px; width: 120px"
            class="button-nofocus-CustomLabel"
            :class="whichWeb === web ? 'button-focus-CustomLabel' : ''"
            @click="changeWeb(web)"
          >
            {{ web }}
          </button>
        </span>
      </div>

      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }}為最近一筆資料
        </p>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              is-expanded
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div class="col-12 d-flex justify-content-between">
          <div style="width: 24%">
            <div class="number-div">
              <p class="fs-small" style="color: #71afb6; height: 35.36px">
                <span> 註冊數 </span>
              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
                v-if="this.behaviorOnline[this.whichWeb] != undefined"
              >
                <p v-if="this.behaviorOnline[this.whichWeb].註冊數 === null">
                  - -
                </p>
                <p v-else>
                  {{
                    this.behaviorOnline[this.whichWeb].註冊數.toLocaleString()
                  }}
                </p>
                <p>(人)</p>
              </div>
            </div>
          </div>
          <div style="width: 24%">
            <div class="number-div">
              <p class="fs-small" style="color: #71afb6">
                <span> 首購率 </span>
                <BtnInfoBox content="根據所選時段內，新註冊且有購買的比例。" direction="right"></BtnInfoBox>

              </p>

              <div
                class="d-flex justify-content-between fs-medium money-number"
                v-if="this.behaviorOnline[this.whichWeb] != undefined"
              >
                <p v-if="this.behaviorOnline[this.whichWeb].首購率 === null">
                  - -
                </p>
                <p v-else>
                  {{ this.behaviorOnline[this.whichWeb].首購率.toFixed(2) }}
                </p>
                <p>(%)</p>
              </div>
            </div>
          </div>

          <div style="width: 24%">
            <div class="number-div">
              <p class="fs-small" style="color: #71afb6">
                <span> 回購率 </span>
                <BtnInfoBox content="根據所選時段內，有購買(線上)的會員且曾經有消費過兩次及以上的比例。" direction="right"></BtnInfoBox>

              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
                v-if="this.behaviorOnline[this.whichWeb] != undefined"
              >
                <p v-if="this.behaviorOnline[this.whichWeb].回購率 === null">
                  - -
                </p>
                <p v-else>
                  {{ this.behaviorOnline[this.whichWeb].回購率.toFixed(2) }}
                </p>
                <p>(%)</p>
              </div>
            </div>
          </div>
          <div style="width: 24%">
            <div class="number-div">
              <p class="fs-small" style="color: #71afb6">
                <span> 平均回購間隔 </span>
                <BtnInfoBox content="根據所選時段內，有購買(線上)的會員，距離上次購買間隔的總平均。" direction="top"></BtnInfoBox>

              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
                v-if="this.buyTime[this.whichWeb] != undefined"
              >
                <p v-if="this.buyTime[this.whichWeb].duration === null">- -</p>
                <p v-else>{{ this.buyTime[this.whichWeb].duration }}</p>
                <p>(天)</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-7" style="padding: 0px">
          <div class="line-div">
           <span class="fs-small" style="color: #71afb6"
                >訪客人數走勢圖 X 新註冊人數</span
              >
              <apexchart
                type="line"
                height="260"
                :options="chartOptions3"
                :series="series3"
              ></apexchart>
          </div>
        </div> -->
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div class="col-8">
          <!-- <div class="col-12">
            <div class="line-div">
              <span class="fs-small" style="color: #71afb6"
                >訪客人數走勢圖 X 新註冊人數</span
              >
              <apexchart
                type="line"
                height="300"
                :options="chartOptions3"
                :series="series3"
              ></apexchart>
            </div>
          </div> -->
          <div class="col-12">
            <div class="bar-div" style="position: relative">
              <div class="d-flex align-items-center">
                <span class="fs-small pb-1 me-3" style="color: #71afb6"
                  >TOP10 購物旅程點擊排行</span
                >
                <Dropdown
                  style="width: 250px"
                  :valueArr="this.selecteddropdown"
                  :selectValue="top10PurchaseSelectorIndex"
                  :changingSelected="'top10PurchaseSelector'"
                  v-on:selectValueByDrop="selectValueByDrop"
                />
              </div>
              <div
                style="position: absolute; top: 50%; left: 40%; color: #c4c4c4"
                v-if="this.seriesBar[0].data[0] === undefined"
              >
                此時間段無資料
              </div>
              <apexchart
                type="bar"
                height="330"
                :options="chartOptionsBar"
                :series="seriesBar"
              ></apexchart>
            </div>
          </div>
          <div class="col-12">
            <div class="bar-div1" style="position: relative">
              <p class="fs-small" style="color: #71afb6">
                <span> 購物行為漏斗 </span>
                <BtnInfoBox content="此為所選時段內，從瀏覽商品(排除掉其他頁面)再到購買的人數狀況。" direction="right"></BtnInfoBox>

              </p>
              <div
                class="d-flex flex-column justify-content-center px-3"
                v-if="this.colorBarData[this.whichWeb] != undefined"
              >
                <div
                  style="
                    position: absolute;
                    top: 40%;
                    left: 40%;
                    color: #c4c4c4;
                  "
                  v-if="this.colorBarData[this.whichWeb].cart_count === 0"
                >
                  此時間段無資料
                </div>
                <div class="d-flex mt-4">
                  <div class="col-2 d-flex align-items-center">
                    <span style="font-size: 16px; color: #575757"
                      >瀏覽商品</span
                    >
                  </div>
                  <div
                    class="col-10 1 d-flex justify-content-center"
                    style="position: relative"
                  >
                    <div
                      class="me-5 d-flex align-items-center"
                      style="white-space: nowrap;position:absolute;top:10px;right:51%"
                    >
                      &nbsp;
                    </div>
                    <div
                      class="color-bar d-flex justify-content-center"
                      id="color-bar1"
                      style="background-color: #fc9f66"
                    >
                      <p
                        class="text-center mb-0 mx-auto"
                        style="
                          color: #575757;
                          font-size: 12px;
                          white-space: nowrap;
                          position: absolute;
                          height: 40px;
                          line-height: 40px;
                        "
                      >
                        {{ this.colorBarData[this.whichWeb].view_count }}人({{
                          (this.colorBarData[this.whichWeb].view_count /
                            this.colorBarData[this.whichWeb].view_count) *
                          100
                        }}%)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div class="col-2 d-flex align-items-center">
                    <span style="font-size: 16px; color: #575757">購物車</span>
                  </div>
                  <div
                    class="col-10 d-flex justify-content-center"
                    style="position: relative"
                  >
                    <div
                      class="me-5 d-flex align-items-center"
                      style="white-space: nowrap;position:absolute;top:10px;right:51%"
                    >
                      &nbsp;
                    </div>
                    <div
                      class="color-bar d-flex justify-content-center"
                      id="color-bar2"
                      style="background-color: #f9c357"
                    >
                      <p
                        class="text-center mb-0 mx-auto"
                        style="
                          color: #575757;
                          font-size: 12px;
                          white-space: nowrap;
                          position: absolute;
                          height: 40px;
                          line-height: 40px;
                        "
                      >
                        {{ this.colorBarData[this.whichWeb].cart_count }}人({{
                          (
                            (this.colorBarData[this.whichWeb].cart_count /
                              this.colorBarData[this.whichWeb].view_count) *
                            100
                          ).toFixed(2)
                        }}%)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-center mt-4">
                  <div class="col-2 d-flex align-items-center">
                    <span style="font-size: 16px; color: #575757"
                      >運送與金流</span
                    >
                  </div>
                  <div
                    class="col-10 d-flex justify-content-center"
                    style="position: relative"
                  >
                    <div
                      class="me-5 d-flex align-items-center info-pop"
                      style="white-space: nowrap;position:absolute;top:10px;right:51%"
                    >
                      <span style="font-size: 14px">
                        {{
                          this.colorBarData[this.whichWeb].choose_count_add
                        }}人
                      </span>
                      <img
                        src="../assets/polygon-arrow.svg"
                        class="ms-2"
                        alt=""
                        @mouseover="memberShow5 = true"
                        @mouseout="memberShow5 = false"
                      />
                      <div class="parent-popout">
                        <div
                          v-show="memberShow5"
                          class="child-popout-top"
                          style="--l2:-76px"
                        >
                          <span class="font-14">直接中途進入該步驟的人數，下<br>方人數有包含上方人數。</span>
                          <div
                            class="container__arrow-inCustomLabel-trd"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="color-bar d-flex justify-content-center"
                      id="color-bar3"
                      style="background-color: #96c5d7"
                    >
                      <p
                        class="text-center mb-0 mx-auto"
                        style="
                          color: #575757;
                          font-size: 12px;
                          white-space: nowrap;
                          position: absolute;
                          height: 40px;
                          line-height: 40px;
                        "
                      >
                        {{ this.colorBarData[this.whichWeb].choose_count }}人({{
                          (
                            (this.colorBarData[this.whichWeb].choose_count /
                              this.colorBarData[this.whichWeb].view_count) *
                            100
                          ).toFixed(2)
                        }}%)
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-center mt-3">
                  <div class="col-2 d-flex align-items-center">
                    <span style="font-size: 16px; color: #575757">購買</span>
                  </div>
                  <div
                    class="col-10 d-flex justify-content-center"
                    style="position: relative"
                  >
                    <div
                      class="me-5 d-flex align-items-center"
                      style="white-space: nowrap;position:absolute;top:10px;right:51%"
                    >
                      <span style="font-size: 14px">
                        {{
                          this.colorBarData[this.whichWeb].conversion_count_add
                        }}人
                      </span>
                      <img
                        src="../assets/polygon-arrow.svg"
                        class="ms-2"
                        alt=""
                        @mouseover="memberShow6 = true"
                        @mouseout="memberShow6 = false"
                      />
                      <div class="parent-popout">
                        <div
                          v-show="memberShow6"
                          class="child-popout-top"
                          style="--l2:-76px"
                        >
                          <span class="font-14">直接中途進入該步驟的人數，下<br>方人數有包含上方人數。</span>
                          <div
                            class="container__arrow-inCustomLabel-trd"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="color-bar d-flex justify-content-center"
                      id="color-bar4"
                      style="background-color: #d197cc"
                    >
                      <p
                        class="text-center mb-0 mx-auto"
                        style="
                          color: #575757;
                          font-size: 12px;
                          white-space: nowrap;
                          position: absolute;
                          height: 40px;
                          line-height: 40px;
                        "
                      >
                        {{
                          this.colorBarData[this.whichWeb].conversion_count
                        }}人({{
                          (
                            (this.colorBarData[this.whichWeb].conversion_count /
                              this.colorBarData[this.whichWeb].view_count) *
                            100
                          ).toFixed(2)
                        }}%)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <apexchart
                type="bar"
                height="250"
                :options="chartOptionsBar2"
                :series="seriesBar2"
              ></apexchart> -->
            </div>
          </div>
        </div>
        <div style="width: 31%">
          <div class="col-12">
            <div class="columm-div" style="position: relative">
              <span class="fs-small" style="color: #71afb6"
                >購買時間點(星期)</span
              >
              <span
                style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
                v-if="this.seriesColumm[0].data[0] === undefined"
                >此時間段無資料</span
              >
              <apexchart
                type="bar"
                height="300"
                :options="chartOptionsColumm"
                :series="seriesColumm"
              ></apexchart>
            </div>
          </div>
          <div class="col-12">
            <div class="columm-div" style="position: relative">
              <span class="fs-small" style="color: #71afb6"
                >購買時間點(小時)</span
              >
              <span
                style="position: absolute; top: 40%; left: 40%; color: #c4c4c4"
                v-if="this.seriesColumm2[0].data[0] === undefined"
                >此時間段無資料</span
              >
              <apexchart
                class="columm-div-scroll"
                type="bar"
                width="1000"
                height="280"
                :options="chartOptionsColumm2"
                :series="seriesColumm2"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "../components/Dropdown.vue";
import { allapi } from "../../public/static/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "CustomLabel",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    Dropdown,
    BtnInfoBox,
  },
  data() {
    return {
      notshow: true,
      memberShow6: false,
      memberShow5: false,
      memberShow4: false,
      memberShow3: false,
      memberShow2: false,
      memberShow: false,

      loading: false,
      websites: [],
      whichWeb: "",
      behaviorOnline: {},
      top5: {},
      buyTime: {},

      vue3Date: {
        start: "",
        end: "",
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      startDate: "2021-12-10",
      endDate: "2022-01-10",
      hasDataFirst: "",
      hasDataEnd: "",
      endYear: 2022,
      endMonth: 6,

      dateSelector: "全部",

      // pie
      seriesPie: [],
      chartOptionsPie: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#63A7C8", "#F9C357"],
        labels: ["線上", "線下"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      // line
      series3: [
        {
          name: "線上 - 2013",
          data: [],
        },
        {
          name: "線下 - 2013",
          data: [],
        },
      ],
      chartOptions3: {
        chart: {
          height: 320,
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        colors: ["#63A7C8", "#F9C357"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: "straight",
        },
        title: {
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "medium",
            color: "#71AFB6",
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          // row: {
          //   colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          //   opacity: 0.5,
          // },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {},
        },
        yaxis: {
          title: {
            text: "人數",
          },
        },
      },
      // bar
      seriesBar: [
        {
          name: "次數",
          data: [],
        },
      ],
      chartOptionsBar: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["桌子", "椅子", "電腦", "檯燈", "衣櫃", "沙發"],
          title: {
            text: "點擊次數",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      // bar 2
      seriesBar2: [
        {
          name: "次數",
          data: [],
        },
      ],
      chartOptionsBar2: {
        chart: {
          type: "bar",
          height: 310,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        colors: ["#71AFB6"],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["桌子", "椅子", "電腦", "檯燈", "衣櫃", "沙發"],
          title: {
            text: "點擊次數",
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
      },
      // columm chart
      seriesColumm: [
        {
          name: "消費次數",
          data: [],
        },
      ],
      chartOptionsColumm: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "星期一",
            "星期二",
            "星期三 ",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
        },
        yaxis: {
          title: {
            text: "消費次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      seriesColumm2: [
        {
          name: "消費次數",
          data: [],
        },
      ],
      chartOptionsColumm2: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "星期一",
            "星期二",
            "星期三 ",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
        },
        yaxis: {
          title: {
            text: "消費次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },

      barTop10Web1Purchase: {
        商品: [],
        商品類別: [],
        加入購物車商品: [],
        加入購物車類別: [],
        選擇金流: [],
        選擇運送方式: [],
      },

      barTop10Web2Purchase: {
        商品: [],
        商品類別: [],
        加入購物車商品: [],
        加入購物車類別: [],
        選擇金流: [],
        選擇運送方式: [],
      },

      selecteddropdown: [],
      dropdownObj: {},
      top10PurchaseSelector: "點擊商品",
      top10PurchaseSelectorIndex: "點擊商品",
      selectedColorBar: "",

      colorBarData: {},

      buyTimeWeek: {},
      buyTimeDay: {},
    };
  },

  beforeMount() {
    // this.getRangeWeek();
  },

  watch: {},

  created: async function () {
    if (this.notshow) {
      this.loading = true;
      await Promise.all([this.getTimeStr(), this.getTimeEnd()]);
      await this.getRecentMonth();

      // this.highlightVue3Date();
      // this.changeWeb();
      // this.loading = false;
    }
  },

  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },
    selectValueByDrop(selectValue, changingSelected, index) {
      this[changingSelected] = selectValue;
      this[changingSelected + "Index"] = index;

      if (
        this.barTop10Web1Purchase[this.whichWeb].加入購物車商品 === undefined
      ) {
        this.seriesBar[0].data = [];
        this.chartOptionsBar = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        this.seriesBar[0].data =
          this.barTop10Web1Purchase[this.whichWeb][selectValue].times;

        this.chartOptionsBar = {
          xaxis: {
            categories:
              this.barTop10Web1Purchase[this.whichWeb][selectValue].item,
          },
        };
      }
    },
    changeWeb(web) {
      if (web != undefined) {
        this.whichWeb = web;
      } else {
        this.whichWeb = this.websites[0];
      }
      this.seriesColumm = [
        {
          data: this.buyTimeWeek[this.whichWeb].星期.數量,
        },
      ];
      this.seriesColumm2 = [
        {
          data: this.buyTimeDay[this.whichWeb].早中晚.數量,
        },
      ];
      this.chartOptionsColumm2 = {
        xaxis: { categories: this.buyTimeDay[this.whichWeb].早中晚.時間 },
      };

      this.dropdownObj = {};
      const dropdowns = Object.keys(this.barTop10Web1Purchase[this.whichWeb]);
      const b = dropdowns.pop();
      dropdowns.unshift(b);
      const c = dropdowns.pop();
      dropdowns.unshift(c);
      for (let index = 0; index < dropdowns.length; index++) {
        this.dropdownObj[dropdowns[index]] = dropdowns[index];
      }
      this.selecteddropdown = this.dropdownObj;

      if (
        this.barTop10Web1Purchase[this.whichWeb].加入購物車商品 === undefined
      ) {
        this.seriesBar[0].data = [];
        this.chartOptionsBar = {
          xaxis: {
            categories: [],
          },
        };
      } else {
        this.seriesBar[0].data =
          this.barTop10Web1Purchase[this.whichWeb].點擊商品.times;

        this.chartOptionsBar = {
          xaxis: {
            categories: this.barTop10Web1Purchase[this.whichWeb].點擊商品.item,
          },
        };
      }

      // .xaxis.categories =
      //   this.barTop10Web1Purchase[this.whichWeb].加入購物車商品.item;
      if (this.colorBarData[this.whichWeb].view_count === 0) {
        console.log("沒漏斗");
      } else {
        setTimeout(() => {
          this.drawColorBar();
        }, 1000);
      }
    },

    drawColorBar() {
      let colorBar1 = document.getElementById("color-bar1");
      colorBar1.style.width =
        (this.colorBarData[this.whichWeb].view_count /
          this.colorBarData[this.whichWeb].view_count) *
          100 +
        "%";
      let colorBar2 = document.getElementById("color-bar2");
      colorBar2.style.width =
        (this.colorBarData[this.whichWeb].cart_count /
          this.colorBarData[this.whichWeb].view_count) *
          100 +
        "%";
      let colorBar3 = document.getElementById("color-bar3");
      colorBar3.style.width =
        (this.colorBarData[this.whichWeb].choose_count /
          this.colorBarData[this.whichWeb].view_count) *
          100 +
        "%";
      let colorBar4 = document.getElementById("color-bar4");
      colorBar4.style.width =
        (this.colorBarData[this.whichWeb].conversion_count /
          this.colorBarData[this.whichWeb].view_count) *
          100 +
        "%";
    },

    getBehaviorOnline: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOnlineRaterateGet(params)
        .then((res) => {
          console.log("behaviorOverallRateGet", res);
          this.behaviorOnline = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyTimeWeek: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOnlineWeekGet(params)
        .then((res) => {
          console.log("getBuyTimeWeek", res);
          this.buyTimeWeek = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyTimeDay: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOnlineDayGet(params)
        .then((res) => {
          console.log("getBuyTimeDay", res);
          this.buyTimeDay = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyTime: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOnlineTimeGet(params)
        .then((res) => {
          console.log("平均回購間隔", res);
          this.buyTime = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyFunnel: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOnlineBuyBehaGet(params)
        .then((res) => {
          console.log("購物行為漏斗", res);
          this.websites = Object.keys(res.data.Data);
          this.colorBarData = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyBar: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOnlineBuyJourGet(params)
        .then((res) => {
          console.log("Top10 購物旅程排行", res);
          this.barTop10Web1Purchase = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getTimeStr: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeSalesGet(params)
        .then((res) => {
          console.log("拿開始時間", res.data.Data);
          this.hasDataFirst = res.data.Data.start_date;
          this.startDate = res.data.Data.start_date;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getTimeEnd: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeOnlineGet(params)
        .then((res) => {
          console.log("拿結束時間", res.data.Data);
          this.hasDataEnd = res.data.Data.end_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTimeStr();
      await this.getTimeEnd();
      await Promise.all([
        this.getBehaviorOnline(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
        this.getBuyBar(),
        this.getBuyFunnel(),
      ]);

      this.highlightVue3Date();
      this.changeWeb();
      this.loading = false;
      this.dateSelector = "全部";
    },
    getRecentYear: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      if (strStart < this.hasDataFirst) {
        this.startDate = this.hasDataFirst;
      } else {
        this.startDate = strStart;
      }
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近一年";
      await Promise.all([
        this.getBehaviorOnline(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
        this.getBuyBar(),
        this.getBuyFunnel(),
      ]);

      this.highlightVue3Date();
      this.changeWeb();
      this.loading = false;
    },
    getRecentMonth: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近30天";
        this.getBehaviorOnline(),
        this.getBuyTime(),
      await Promise.all([
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
        this.getBuyBar(),
        this.getBuyFunnel(),
      ]);

      this.whichWeb = this.websites[0];
      this.highlightVue3Date();
      this.changeWeb();
      this.loading = false;
    },
    getPass7Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近7天";
      await Promise.all([
        this.getBehaviorOnline(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
        this.getBuyBar(),
        this.getBuyFunnel(),
      ]);

      this.highlightVue3Date();
      this.changeWeb();
      this.loading = false;
    },
    getPass1Days: async function () {
      this.loading = true;
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近1天";
      await Promise.all([
        this.getBehaviorOnline(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
        this.getBuyBar(),
        this.getBuyFunnel(),
      ]);
      this.highlightVue3Date();
      this.changeWeb();
      this.loading = false;
    },
    customDatePicker: async function () {
      this.loading = true;
      this.startDate = this.vue3Date.start.slice(0, 10);
      this.endDate = this.vue3Date.end.slice(0, 10);
      this.dateSelector = "自訂";
      await Promise.all([
        this.getBehaviorOnline(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
        this.getBuyBar(),
        this.getBuyFunnel(),
      ]);

      this.changeWeb();
      this.loading = false;
    },
  },
};
</script>
<style scoped>
/* CustomLabel */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  padding: 50px;
}

.title-span {
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}

.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  color: black;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}

.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}

.button-nofocus-CustomLabel {
  background-color: #eaf3f4;
  border: none;
  color: black;
  border-radius: 8px;
}

.button-focus-CustomLabel {
  background-color: #71afb6;
  color: white;
  border-radius: 8px;
}

.number-div {
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 145px;
}

.number-div2 {
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 70px;
}

.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 320px;
}

.line-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 320px;
}

.bar-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 387px;
}

.bar-div1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 387px;
}

.columm-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 387px;
}

.columm-div-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
}

.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}

.change-date-li:hover {
  background-color: #eaf3f4;
}

.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}

.selector-top10 {
  color: #71afb6;
  border-radius: 10px;
  padding: 5px;
  width: 30%;
  text-align: center;
  border-color: #c4c4c4;
}

.gap-custom {
  gap: 20px 25px;
}

.gap-custom2 {
  gap: 20px 8px;
}

.color-bar {
  height: 40px;
}
.child-popout-inCustomLabel-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -250px;
  text-align: left;
  top: -42px;
  width: 240px;
  z-index: 99;
}
.child-popout-inCustomLabel-second {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -310px;
  text-align: left;
  top: -42px;
  width: 300px;
  z-index: 99;
}
.child-popout-inCustomLabel-trd {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -131px;
  text-align: left;
  top: -95px;
  width: 300px;
  z-index: 99;
}
.container__arrow-inCustomLabel-trd {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: -8px;
  height: 0;
  left: 53.8%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(360deg);
  width: 0;
  z-index: 10;
}
.child-popout-inCustomLabel-fourth {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -310px;
  text-align: left;
  top: -42px;
  width: 300px;
  z-index: 99;
}
.child-popout-inCustomLabel-fifth {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: 65px;
  text-align: left;
  top: -32px;
  width: 220px;
  z-index: 99;
  white-space: normal;
}
.container__arrow-inCustomLabel-fifth {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 47%;
  height: 0;
  right: -25px;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(270deg);
  width: 0;
  z-index: 10;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
.child-popout-top {
  right: -95px;
}
</style>