<template>
  <!-- backdrop -->
  <div
    id="CycleTimeDetail-backdrop"
    class="modal-backdrop fade show"
    style="display: none"
  ></div>
  <!-- show CycleTimeDetail -->
  <div
    class="modal fade"
    id="CycleTimeDetail"
    tabindex="-1"
    aria-labelledby="alertModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      style="max-width: 570px; z-index: 5000"
    >
      <div class="modal-content" style="width: 570px">
        <div class="modal-header alert-modal-header d-flex justify-content-end">
          <img
            src="../assets/whiteX.svg"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </div>
        <div class="modal-body alert-modal-body text-start py-3 px-5">
          <div class="d-flex align-items-center mb-3" style="color: #71afb6">
            經過多少時間寄第一封
          </div>
          <div class="d-flex align-items-center mb-3">
            <div style="width: 20%">開始時間</div>
            <div style="width: 80%" class="d-flex justify-content-between">
              <div class="cycletime-setting-block" style="width: 49%">
                {{ this.cycleTime_time }}
              </div>
              <div class="cycletime-setting-block" style="width: 49%">
                {{ this.cycleTime_state }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div style="width: 20%">發送方式</div>
            <div class="cycletime-setting-block">一次性投放</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- main -->
  <div class="" style="padding: 0px 0px 0px 0">
    <div style="position: relative">
      <div class="modal-header alert-modal-header d-flex justify-content-end">
        <img
          src="../assets/whiteX.svg"
          alt="arrow"
          style="cursor: pointer"
          data-bs-dismiss="modal"
        />
      </div>
      <div
        style="inline-block"
        class="zoom-in-seescript d-flex align-items-center"
      >
        <button
          @click="zoomin"
          style="
            border-radius: 10px;
            border: none;
            width: 30px;
            height: 30px;
            font-size: 20px;
          "
          class="me-2"
        >
          +
        </button>
        <button
          @click="zoomout"
          style="
            border-radius: 10px;
            border: none;
            width: 30px;
            height: 30px;
            font-size: 24px;
          "
          class="me-2"
        >
          -
        </button>
        <button
          @click="zoomreset"
          style="border-radius: 10px; border: none; width: 30px; height: 30px"
          class="pb-1"
        >
          <img src="../assets/resetZoom.png" alt="" class="" />
        </button>
      </div>
      <div
        id="treeDiv"
        class="contain-right-top-seeScript2"
        style="padding-top: 20px; cursor: grab"
        :style="grabbing ? 'cursor: grabbing' : ''"
        @mousedown="this.grabbing = true"
        @mouseup="this.grabbing = false"
        v-dragscroll
      >
        <div
          id="zoom"
          class="text-center"
          style="width: 680px; margin: 0 auto"
          :style="[fat ? 'width: 1000px' : '', zoom]"
        >
          <div class="action-tag2" style="position: relative">
            <span
              style="position: absolute; top: -15px; right: -15px"
              @click="clearScript"
              v-if="this.eventScriptHasEventFromSche != '0'"
            >
              <img src="../assets/deleteScript.svg" alt="" />
            </span>
            <div v-if="this.emailChannel">
              <img src="../assets/sendmail.svg" class="mb-2" alt="" />
              <span style="position: relative; left: 2px; font-size: 14px">
                傳送Email
              </span>
            </div>
            <div v-else>
              <img
                src="../assets/sendsms.svg"
                style="position: relative; left: 2px"
                alt=""
              />
              <br />
              <span style="position: relative; font-size: 14px"> 傳送SMS </span>
            </div>
          </div>
          <div class="d-flex justify-content-center col-12">
            <img src="../assets/treeline.svg" alt="" />
          </div>
          <div class="tree-tag" style="color: #71afb6; position: relative">
            <div
              class="d-flex"
              style="
                color: #8a8a8a;
                position: absolute;
                left: 60px;
                bottom: -22px;
              "
            >
              {{ this.$props.delivery }}
            </div>
            <div
              class="template-tag"
              @click="
                this.showOutsideExp()
              "
            >
              {{ eventTitleFromSche }}
            </div>
          </div>

          <div class="d-flex justify-content-center col-12">
            <img src="../assets/treeline.svg" alt="" />
          </div>
          <!-- 樹狀圖 -->

          <div
            style="background-color: white"
            class="tree-div text-center"
            v-if="this.currentEvent != null"
          >
            <div class="blue-tag" @click="updateEventTimeInterval">
              <img src="../assets/hourglass.svg" alt="" style="width: 31px" />
              <div class="mt-2">
                {{ this.time_interval }}{{ this.time_state }}
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <img src="../assets/treeline.svg" alt="" />
            </div>
            <div
              class="blue-tag3"
              :style="
                this.currentEvent === '已開啟信件且點擊' ||
                this.currentEvent === '已開啟信件且轉換' ||
                this.currentEvent === '已點擊連結且轉換'
                  ? 'padding-top:4px'
                  : ''
              "
              @click="updateEventTimeInterval"
            >
              <img
                src="../assets/openmail.svg"
                style="width: 40px"
                alt=""
                v-if="this.currentEvent.includes('開啟信件')"
              />
              <img
                src="../assets/clicklink.svg"
                style="width: 40px"
                alt=""
                v-if="this.currentEvent.includes('點擊連結')"
              />
              <img
                src="../assets/change.svg"
                style="width: 40px"
                alt=""
                v-if="this.currentEvent === '轉換'"
              />
              <div
                style="
                  width: 100px;
                  position: relative;
                  left: -30px;
                  top: 3px;
                  color: white;
                "
                v-if="
                  this.currentEvent === '已開啟信件且點擊' ||
                  this.currentEvent === '已開啟信件且轉換' ||
                  this.currentEvent === '已點擊連結且轉換'
                "
              >
                {{ this.currentEvent.slice(0, -3) }}
                <br />
                <span style="font-size: 12px">{{
                  this.currentEvent.slice(-3)
                }}</span>
              </div>
              <div
                style="
                  width: 100px;
                  position: relative;
                  left: -30px;
                  top: 5px;
                  color: white;
                "
                v-else
              >
                {{ this.currentEvent }}
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <img src="../assets/treeline.svg" alt="" />
            </div>
            <div class="d-flex justify-content-center">
              <hr
                class="m-0"
                style="
                  border: none;
                  height: 0;
                  border-top: 2px solid #71afb6;
                  width: 50%;
                  opacity: 1;
                "
              />
            </div>

            <div class="d-flex">
              <!-- Yes -->

              <div
                class="col-6"
                style="background-color: white; height: 400px"
                @dragover="allowDrop"
                @drop.stop="dropTimeAtYes($event)"
              >
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div class="yes-tag">
                  <div
                    class="d-flex"
                    style="
                      color: #8a8a8a;
                      position: absolute;
                      left: 60px;
                      bottom: -22px;
                    "
                    v-if="this.apiTableData[this.ruleID] != undefined"
                  >
                    {{ this.apiTableData[this.ruleID].yes }}
                  </div>
                  是
                </div>
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div v-if="yes_time_after != ''">
                  <div
                    style="background-color: white; height: 400px"
                    @dragover="allowDrop"
                    @drop.stop="dropActionAtYes($event)"
                  >
                    <div
                      class="time-tag"
                      style="position: relative"
                      @click="
                        showCycleTimeDetail(
                          yes_time_after,
                          yes_time_after_state
                        )
                      "
                    >
                      <img
                        src="../assets/redalert.svg"
                        alt=""
                        style="position: absolute; top: -10px; right: -5px"
                        v-if="
                          this.ader_yes_triggerCron === '' &&
                          this.cycleTime_yes != '一次性'
                        "
                      />
                      <img
                        src="../assets/clock.svg"
                        alt=""
                        style="width: 40px"
                      />
                      <div class="mt-1">
                        <p class="m-0" style="font-size: 14px">
                          {{ yes_time_after }}
                          {{ yes_time_after_state }}
                        </p>
                        <p class="m-0" style="font-size: 12px">
                          {{ cycleTime_yes }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <img src="../assets/treeline.svg" alt="" />
                    </div>
                    <div
                      style="background-color: white; height: 400px"
                      @dragover="allowDrop"
                      @drop.stop="dropTemplateAtYes($event)"
                      v-if="yes_action != ''"
                    >
                      <div
                        class="action-tag2"
                        @click="updateYesAction"
                        v-if="this.yes_action === '傳送Email'"
                      >
                        <img src="../assets/sendmail.svg" class="mb-2" alt="" />
                        <span
                          style="position: relative; left: 2px; font-size: 14px"
                        >
                          {{ yes_action }}
                        </span>
                      </div>
                      <div class="action-tag3" @click="updateYesAction" v-else>
                        <img
                          src="../assets/sendsms.svg"
                          style="position: relative; left: 2px"
                          alt=""
                        />
                        <br />
                        <span style="position: relative; font-size: 14px">
                          {{ yes_action }}
                        </span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div
                        style="background-color: white; height: 400px"
                        @dragover="allowDrop"
                        @drop.stop="dropEventAtYes($event)"
                        v-if="yes_template != ''"
                      >
                        <div
                          class="tree-tag"
                          style="color: #71afb6; position: relative"
                          v-tooltip.bottom="{
                            value: yes_template,
                            class: 'tip-LabelManage',
                          }"
                          @click="
                            showexp(
                              'v_',
                              '_1',
                              this.yes_subject,
                              this.yes_action
                            )
                          "
                        >
                          <div
                            class="d-flex"
                            style="
                              color: #8a8a8a;
                              position: absolute;
                              left: 60px;
                              bottom: -22px;
                            "
                          >
                            {{ this.yes_delivery }}
                          </div>
                          <div
                            style="position: absolute; top: -10px; right: -5px"
                            v-if="
                              this.yes_subject === '' &&
                              this.yes_action === '傳送Email'
                            "
                          >
                            <img src="../assets/redalert.svg" alt="" />
                          </div>
                          <div class="template-tag">
                            {{ yes_template }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-center">
                          <img src="../assets/treeline.svg" alt="" />
                        </div>
                        <!-- UnderYes -->
                        <div
                          style="
                            background-color: white;
                            height: 400px;
                            width: 500px;
                          "
                          v-if="this.currentEventYes != null"
                        >
                          <div
                            class="blue-tag"
                            @click="updateYesEventTimeInterval"
                          >
                            <img
                              src="../assets/hourglass.svg"
                              alt=""
                              style="width: 31px"
                            />
                            <div class="mt-2">
                              {{ this.yes_time_interval
                              }}{{ this.yes_time_state }}
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            class="blue-tag3"
                            :style="
                              this.currentEvent === '已開啟信件且點擊' ||
                              this.currentEvent === '已開啟信件且轉換' ||
                              this.currentEvent === '已點擊連結且轉換'
                                ? 'padding-top:4px'
                                : ''
                            "
                            @click="updateYesEventTimeInterval"
                          >
                            <img
                              src="../assets/openmail.svg"
                              style="width: 40px"
                              alt=""
                              v-if="this.currentEventYes.includes('開啟信件')"
                            />
                            <img
                              src="../assets/clicklink.svg"
                              style="width: 40px"
                              alt=""
                              v-if="this.currentEventYes.includes('點擊連結')"
                            />
                            <img
                              src="../assets/change.svg"
                              style="width: 40px"
                              alt=""
                              v-if="this.currentEventYes === '轉換'"
                            />
                            <div
                              style="
                                width: 100px;
                                position: relative;
                                left: -30px;
                                top: 3px;
                                color: white;
                              "
                              v-if="
                                this.currentEventYes === '已開啟信件且點擊' ||
                                this.currentEventYes === '已開啟信件且轉換' ||
                                this.currentEventYes === '已點擊連結且轉換'
                              "
                            >
                              {{ this.currentEventYes.slice(0, -3) }}
                              <br />
                              <span style="font-size: 12px">{{
                                this.currentEvent.slice(-3)
                              }}</span>
                            </div>
                            <div
                              style="
                                width: 100px;
                                position: relative;
                                left: -30px;
                                top: 5px;
                                color: white;
                              "
                              v-else
                            >
                              {{ this.currentEventYes }}
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div class="d-flex justify-content-center">
                            <hr
                              class="m-0"
                              style="
                                border: none;
                                height: 0;
                                border-top: 2px solid #71afb6;
                                width: 50%;
                                opacity: 1;
                              "
                            />
                          </div>
                          <div class="d-flex">
                            <div
                              class="col-6"
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropTimeAtYesYes($event)"
                            >
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div class="yes-tag">
                                <div
                                  class="d-flex"
                                  style="
                                    color: #8a8a8a;
                                    position: absolute;
                                    left: 60px;
                                    bottom: -22px;
                                  "
                                  v-if="
                                    this.apiTableData[
                                      'v_' + this.ruleID.slice(-8) + '_1'
                                    ] != undefined
                                  "
                                >
                                  {{
                                    this.apiTableData[
                                      "v_" + this.ruleID.slice(-8) + "_1"
                                    ].yes
                                  }}
                                </div>
                                是
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div v-if="this.yesyes_time_after != ''">
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropActionAtYesYes($event)"
                                >
                                  <div
                                    class="time-tag"
                                    style="position: relative"
                                    @click="
                                      showCycleTimeDetail(
                                        yesyes_time_after,
                                        yesyes_time_after_state
                                      )
                                    "
                                  >
                                    <img
                                      src="../assets/redalert.svg"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: -10px;
                                        right: -5px;
                                      "
                                      v-if="
                                        this.ader_yesyes_triggerCron === '' &&
                                        this.cycleTime_yesyes != '一次性'
                                      "
                                    />
                                    <img
                                      src="../assets/clock.svg"
                                      alt=""
                                      style="width: 40px"
                                    />
                                    <div class="mt-1">
                                      <p class="m-0" style="font-size: 14px">
                                        {{ yesyes_time_after }}
                                        {{ yesyes_time_after_state }}
                                      </p>
                                      <p class="m-0" style="font-size: 12px">
                                        {{ cycleTime_yesyes }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTemplateAtYesYes($event)"
                                    v-if="this.yesyes_action != ''"
                                  >
                                    <div
                                      class="action-tag2"
                                      @click="updateYesYesAction"
                                      v-if="this.yesyes_action === '傳送Email'"
                                    >
                                      <img
                                        src="../assets/sendmail.svg"
                                        alt=""
                                        class="mb-2"
                                      />
                                      <span
                                        style="
                                          position: relative;
                                          left: 2px;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ yesyes_action }}
                                      </span>
                                    </div>
                                    <div
                                      class="action-tag3"
                                      @click="updateYesYesAction"
                                      v-else
                                    >
                                      <img
                                        src="../assets/sendsms.svg"
                                        style="position: relative; left: 2px"
                                        alt=""
                                      />
                                      <br />
                                      <span
                                        style="
                                          position: relative;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ yesyes_action }}
                                      </span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      style="background-color: white"
                                      v-if="this.yesyes_template != ''"
                                    >
                                      <div
                                        class="tree-tag"
                                        style="
                                          color: #71afb6;
                                          position: relative;
                                        "
                                        v-tooltip.bottom="{
                                          value: yesyes_template,
                                          class: 'tip-LabelManage',
                                        }"
                                        @click="
                                          showexp(
                                            'v_',
                                            '_1_1',
                                            this.yesyes_subject,
                                            this.yesyes_action
                                          )
                                        "
                                      >
                                        <div
                                          class="d-flex"
                                          style="
                                            color: #8a8a8a;
                                            position: absolute;
                                            left: 60px;
                                            bottom: -22px;
                                          "
                                        >
                                          {{ this.yesyes_delivery }}
                                        </div>
                                        <div
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.yesyes_subject === '' &&
                                            this.yesyes_action === '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="template-tag">
                                          {{ this.yesyes_template }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-6"
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropTimeAtYesNo($event)"
                            >
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div class="no-tag">
                                <div
                                  class="d-flex"
                                  style="
                                    color: #8a8a8a;
                                    position: absolute;
                                    left: 60px;
                                    bottom: -22px;
                                  "
                                  v-if="
                                    this.apiTableData[
                                      'v_' + this.ruleID.slice(-8) + '_1'
                                    ] != undefined
                                  "
                                >
                                  {{
                                    this.apiTableData[
                                      "v_" + this.ruleID.slice(-8) + "_1"
                                    ].no
                                  }}
                                </div>
                                否
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div v-if="this.yesno_time_after != ''">
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropActionAtYesNo($event)"
                                >
                                  <div
                                    class="time-tag"
                                    style="position: relative"
                                    @click="
                                      showCycleTimeDetail(
                                        yesno_time_after,
                                        yesno_time_after_state
                                      )
                                    "
                                  >
                                    <img
                                      src="../assets/redalert.svg"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: -10px;
                                        right: -5px;
                                      "
                                      v-if="
                                        this.ader_yesno_triggerCron === '' &&
                                        this.cycleTime_yesno != '一次性'
                                      "
                                    />
                                    <img
                                      src="../assets/clock.svg"
                                      alt=""
                                      style="width: 40px"
                                    />
                                    <div class="mt-1">
                                      <p class="m-0" style="font-size: 14px">
                                        {{ yesno_time_after }}
                                        {{ yesno_time_after_state }}
                                      </p>
                                      <p class="m-0" style="font-size: 12px">
                                        {{ cycleTime_yesno }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTemplateAtYesNo($event)"
                                    v-if="this.yesno_action != ''"
                                  >
                                    <div
                                      class="action-tag2"
                                      @click="updateYesNoAction"
                                      v-if="this.yesno_action === '傳送Email'"
                                    >
                                      <img
                                        src="../assets/sendmail.svg"
                                        alt=""
                                        class="mb-2"
                                      />
                                      <span
                                        style="
                                          position: relative;
                                          left: 2px;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ yesno_action }}
                                      </span>
                                    </div>
                                    <div
                                      class="action-tag3"
                                      @click="updateYesNoAction"
                                      v-else
                                    >
                                      <img
                                        src="../assets/sendsms.svg"
                                        style="position: relative; left: 2px"
                                        alt=""
                                      />
                                      <br />
                                      <span
                                        style="
                                          position: relative;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ yesno_action }}
                                      </span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      style="background-color: white"
                                      v-if="this.yesno_template != ''"
                                    >
                                      <div
                                        class="tree-tag"
                                        style="
                                          color: #71afb6;
                                          position: relative;
                                        "
                                        v-tooltip.bottom="{
                                          value: yesno_template,
                                          class: 'tip-LabelManage',
                                        }"
                                        @click="
                                          showexp(
                                            'v_',
                                            '_1_2',
                                            this.yesno_subject,
                                            this.yesno_action
                                          )
                                        "
                                      >
                                        <div
                                          class="d-flex"
                                          style="
                                            color: #8a8a8a;
                                            position: absolute;
                                            left: 60px;
                                            bottom: -22px;
                                          "
                                        >
                                          {{ this.yesno_delivery }}
                                        </div>
                                        <div
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.yesno_subject === '' &&
                                            this.yesno_action === '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="template-tag">
                                          {{ this.yesno_template }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- NO -->
              <div
                class="col-6"
                style="background-color: white; height: 400px"
                @dragover="allowDrop"
                @drop.stop="dropTimeAtNo($event)"
              >
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div class="no-tag">
                  <div
                    class="d-flex"
                    style="
                      color: #8a8a8a;
                      position: absolute;
                      left: 60px;
                      bottom: -22px;
                    "
                    v-if="this.apiTableData[this.ruleID] != undefined"
                  >
                    {{ this.apiTableData[this.ruleID].no }}
                  </div>
                  否
                </div>
                <div class="d-flex justify-content-center">
                  <img src="../assets/treeline.svg" alt="" />
                </div>
                <div v-if="no_time_after != ''">
                  <div
                    style="background-color: white; height: 400px"
                    @dragover="allowDrop"
                    @drop.stop="dropActionAtNo($event)"
                  >
                    <div
                      class="time-tag"
                      style="position: relative"
                      @click="
                        showCycleTimeDetail(no_time_after, no_time_after_state)
                      "
                    >
                      <img
                        src="../assets/redalert.svg"
                        alt=""
                        style="position: absolute; top: -10px; right: -5px"
                        v-if="
                          this.ader_no_triggerCron === '' &&
                          this.cycleTime_no != '一次性'
                        "
                      />
                      <img
                        src="../assets/clock.svg"
                        alt=""
                        style="width: 40px"
                      />
                      <div class="mt-1">
                        <p class="m-0" style="font-size: 14px">
                          {{ no_time_after }}
                          {{ no_time_after_state }}
                        </p>
                        <p class="m-0" style="font-size: 12px">
                          {{ cycleTime_no }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <img src="../assets/treeline.svg" alt="" />
                    </div>
                    <div
                      style="background-color: white; height: 400px"
                      @dragover="allowDrop"
                      @drop.stop="dropTemplateAtNo($event)"
                      v-if="no_action != ''"
                    >
                      <div
                        class="action-tag2"
                        @click="updateNoAction"
                        v-if="this.no_action === '傳送Email'"
                      >
                        <img src="../assets/sendmail.svg" alt="" class="mb-2" />
                        <span
                          style="position: relative; left: 2px; font-size: 14px"
                        >
                          {{ no_action }}
                        </span>
                      </div>
                      <div class="action-tag3" @click="updateNoAction" v-else>
                        <img
                          src="../assets/sendsms.svg"
                          style="position: relative; left: 2px"
                          alt=""
                        />
                        <br />
                        <span style="position: relative; font-size: 14px">
                          {{ no_action }}
                        </span>
                      </div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div
                        style="background-color: white; height: 400px"
                        @dragover="allowDrop"
                        @drop.stop="dropEventAtNo($event)"
                        v-if="no_template != ''"
                      >
                        <div
                          class="tree-tag"
                          style="color: #71afb6; position: relative"
                          v-tooltip.bottom="{
                            value: no_template,
                            class: 'tip-LabelManage',
                          }"
                          @click="
                            showexp('v_', '_2', this.no_subject, this.no_action)
                          "
                        >
                          <div
                            class="d-flex"
                            style="
                              color: #8a8a8a;
                              position: absolute;
                              left: 60px;
                              bottom: -22px;
                            "
                          >
                            {{ this.no_delivery }}
                          </div>
                          <div
                            style="position: absolute; top: -10px; right: -5px"
                            v-if="
                              this.no_subject === '' &&
                              this.no_action === '傳送Email'
                            "
                          >
                            <img src="../assets/redalert.svg" alt="" />
                          </div>
                          <div class="template-tag">
                            {{ no_template }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-center">
                          <img src="../assets/treeline.svg" alt="" />
                        </div>
                        <!-- UnderNo -->
                        <div
                          style="
                            background-color: white;
                            height: 400px;
                            width: 500px;
                          "
                          v-if="this.currentEventNo != null"
                        >
                          <div
                            class="blue-tag"
                            @click="updateNoEventTimeInterval"
                          >
                            <img
                              src="../assets/hourglass.svg"
                              alt=""
                              style="width: 31px"
                            />
                            <div class="mt-2">
                              {{ this.no_time_interval
                              }}{{ this.no_time_state }}
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            class="blue-tag3"
                            :style="
                              this.currentEvent === '已開啟信件且點擊' ||
                              this.currentEvent === '已開啟信件且轉換' ||
                              this.currentEvent === '已點擊連結且轉換'
                                ? 'padding-top:4px'
                                : ''
                            "
                            @click="updateNoEventTimeInterval"
                          >
                            <img
                              src="../assets/openmail.svg"
                              style="width: 40px"
                              alt=""
                              v-if="this.currentEventNo.includes('開啟信件')"
                            />
                            <img
                              src="../assets/clicklink.svg"
                              style="width: 40px"
                              alt=""
                              v-if="this.currentEventNo.includes('點擊連結')"
                            />
                            <img
                              src="../assets/change.svg"
                              style="width: 40px"
                              alt=""
                              v-if="this.currentEventNo === '轉換'"
                            />
                            <div
                              style="
                                width: 100px;
                                position: relative;
                                left: -30px;
                                top: 3px;
                                color: white;
                              "
                              v-if="
                                this.currentEventNo === '已開啟信件且點擊' ||
                                this.currentEventNo === '已開啟信件且轉換' ||
                                this.currentEventNo === '已點擊連結且轉換'
                              "
                            >
                              {{ this.currentEventNo.slice(0, -3) }}
                              <br />
                              <span style="font-size: 12px">{{
                                this.currentEventNo.slice(-3)
                              }}</span>
                            </div>
                            <div
                              style="
                                width: 100px;
                                position: relative;
                                left: -30px;
                                top: 5px;
                                color: white;
                              "
                              v-else
                            >
                              {{ this.currentEventNo }}
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div class="d-flex justify-content-center">
                            <hr
                              class="m-0"
                              style="
                                border: none;
                                height: 0;
                                border-top: 2px solid #71afb6;
                                width: 50%;
                                opacity: 1;
                              "
                            />
                          </div>
                          <div class="d-flex">
                            <div
                              class="col-6"
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropTimeAtNoYes($event)"
                            >
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div class="yes-tag">
                                <div
                                  class="d-flex"
                                  style="
                                    color: #8a8a8a;
                                    position: absolute;
                                    left: 60px;
                                    bottom: -22px;
                                  "
                                  v-if="
                                    this.apiTableData[
                                      'v_' + this.ruleID.slice(-8) + '_2'
                                    ] != undefined
                                  "
                                >
                                  {{
                                    this.apiTableData[
                                      "v_" + this.ruleID.slice(-8) + "_2"
                                    ].yes
                                  }}
                                </div>
                                是
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div v-if="this.noyes_time_after != ''">
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropActionAtNoYes($event)"
                                >
                                  <div
                                    class="time-tag"
                                    style="position: relative"
                                    @click="
                                      showCycleTimeDetail(
                                        noyes_time_after,
                                        noyes_time_after_state
                                      )
                                    "
                                  >
                                    <img
                                      src="../assets/redalert.svg"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: -10px;
                                        right: -5px;
                                      "
                                      v-if="
                                        this.ader_noyes_triggerCron === '' &&
                                        this.cycleTime_noyes != '一次性'
                                      "
                                    />
                                    <img
                                      src="../assets/clock.svg"
                                      alt=""
                                      style="width: 40px"
                                    />
                                    <div class="mt-1">
                                      <p class="m-0" style="font-size: 14px">
                                        {{ noyes_time_after }}
                                        {{ noyes_time_after_state }}
                                      </p>
                                      <p class="m-0" style="font-size: 12px">
                                        {{ cycleTime_noyes }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTemplateAtNoYes($event)"
                                    v-if="this.noyes_action != ''"
                                  >
                                    <div
                                      class="action-tag2"
                                      @click="updateNoYesAction"
                                      v-if="this.noyes_action === '傳送Email'"
                                    >
                                      <img
                                        src="../assets/sendmail.svg"
                                        alt=""
                                        class="mb-2"
                                      />
                                      <span
                                        style="
                                          position: relative;
                                          left: 2px;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ noyes_action }}
                                      </span>
                                    </div>
                                    <div
                                      class="action-tag3"
                                      @click="updateNoYesAction"
                                      v-else
                                    >
                                      <img
                                        src="../assets/sendsms.svg"
                                        style="position: relative; left: 2px"
                                        alt=""
                                      />
                                      <br />
                                      <span
                                        style="
                                          position: relative;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ noyes_action }}
                                      </span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      style="background-color: white"
                                      @dragover="allowDrop"
                                      v-if="this.noyes_template != ''"
                                    >
                                      <div
                                        class="tree-tag"
                                        style="
                                          color: #71afb6;
                                          position: relative;
                                        "
                                        v-tooltip.bottom="{
                                          value: noyes_template,
                                          class: 'tip-LabelManage',
                                        }"
                                        @click="
                                          showexp(
                                            'v_',
                                            '_2_1',
                                            this.noyes_subject,
                                            this.noyes_action
                                          )
                                        "
                                      >
                                        <div
                                          class="d-flex"
                                          style="
                                            color: #8a8a8a;
                                            position: absolute;
                                            left: 60px;
                                            bottom: -22px;
                                          "
                                        >
                                          {{ this.noyes_delivery }}
                                        </div>
                                        <div
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.noyes_subject === '' &&
                                            this.noyes_action === '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="template-tag">
                                          {{ this.noyes_template }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-6"
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropTimeAtNoNo($event)"
                            >
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div class="no-tag">
                                <div
                                  class="d-flex"
                                  style="
                                    color: #8a8a8a;
                                    position: absolute;
                                    left: 60px;
                                    bottom: -22px;
                                  "
                                  v-if="
                                    this.apiTableData[
                                      'v_' + this.ruleID.slice(-8) + '_2'
                                    ] != undefined
                                  "
                                >
                                  {{
                                    this.apiTableData[
                                      "v_" + this.ruleID.slice(-8) + "_2"
                                    ].no
                                  }}
                                </div>
                                否
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <div v-if="this.nono_time_after != ''">
                                <div
                                  style="background-color: white; height: 400px"
                                  @dragover="allowDrop"
                                  @drop.stop="dropActionAtNoNo($event)"
                                >
                                  <div
                                    class="time-tag"
                                    style="position: relative"
                                    @click="
                                      showCycleTimeDetail(
                                        nono_time_after,
                                        nono_time_after_state
                                      )
                                    "
                                  >
                                    <img
                                      src="../assets/redalert.svg"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: -10px;
                                        right: -5px;
                                      "
                                      v-if="
                                        this.ader_nono_triggerCron === '' &&
                                        this.cycleTime_nono != '一次性'
                                      "
                                    />
                                    <img
                                      src="../assets/clock.svg"
                                      alt=""
                                      style="width: 40px"
                                    />
                                    <div class="mt-1">
                                      <p class="m-0" style="font-size: 14px">
                                        {{ nono_time_after }}
                                        {{ nono_time_after_state }}
                                      </p>
                                      <p class="m-0" style="font-size: 12px">
                                        {{ cycleTime_nono }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-center">
                                    <img src="../assets/treeline.svg" alt="" />
                                  </div>
                                  <div
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTemplateAtNoNo($event)"
                                    v-if="this.nono_action != ''"
                                  >
                                    <div
                                      class="action-tag2"
                                      @click="updateNoNoAction"
                                      v-if="this.nono_action === '傳送Email'"
                                    >
                                      <img
                                        src="../assets/sendmail.svg"
                                        alt=""
                                        class="mb-2"
                                      />
                                      <span
                                        style="
                                          position: relative;
                                          left: 2px;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ nono_action }}
                                      </span>
                                    </div>
                                    <div
                                      class="action-tag3"
                                      @click="updateNoNoAction"
                                      v-else
                                    >
                                      <img
                                        src="../assets/sendsms.svg"
                                        style="position: relative; left: 2px"
                                        alt=""
                                      />
                                      <br />
                                      <span
                                        style="
                                          position: relative;
                                          font-size: 14px;
                                        "
                                      >
                                        {{ nono_action }}
                                      </span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      style="background-color: white"
                                      @dragover="allowDrop"
                                      v-if="this.nono_template != ''"
                                    >
                                      <div
                                        class="tree-tag"
                                        style="
                                          color: #71afb6;
                                          position: relative;
                                        "
                                        v-tooltip.bottom="{
                                          value: nono_template,
                                          class: 'tip-LabelManage',
                                        }"
                                        @click="
                                          showexp(
                                            'v_',
                                            '_2_2',
                                            this.nono_subject,
                                            this.nono_action
                                          )
                                        "
                                      >
                                        <div
                                          class="d-flex"
                                          style="
                                            color: #8a8a8a;
                                            position: absolute;
                                            left: 60px;
                                            bottom: -22px;
                                          "
                                        >
                                          {{ this.nono_delivery }}
                                        </div>
                                        <div
                                          style="
                                            position: absolute;
                                            top: -10px;
                                            right: -5px;
                                          "
                                          v-if="
                                            this.nono_subject === '' &&
                                            this.nono_action === '傳送Email'
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div class="template-tag">
                                          {{ this.nono_template }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi3 } from "../../public/static3/api/apigClient.js";
import { allapi2 } from "../../public/static2/api/apigClient.js";

export default {
  name: "SeeScript",
  components: {},
  props: ["scriptID2", "emailChannel", "addadd2", "delivery"],
  inheritAttrs: false,

  data() {
    return {
      unsubscribe_url:'',
      isShow: false,
      z: 1,
      zoom: "",
      testList: [],
      testList2: [],
      emailList: {},
      smsList: {},

      appenTime: "月",
      happenDaySelector: [],
      happenMonthSelector2: [],
      happenYearMonthSelector: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],

      happenDay_Selected: "",
      happenWeek_Selected: "",
      happenWeek2_Selected: "",
      happenMonth_Selected: "",
      happenMonth2_Selected: "",
      happenMonth3_Selected: "",
      happenMonth4_Selected: "",
      happenMonth5_Selected: "",
      happenYear_Selected: "",
      happenYear2_Selected: "",
      happenYear3_Selected: "",
      happenYear4_Selected: "",
      happenYear5_Selected: "",

      cycleTime_yes: "一次性",
      cycleTime_yesyes: "一次性",
      cycleTime_yesno: "一次性",
      cycleTime_no: "一次性",
      cycleTime_noyes: "一次性",
      cycleTime_nono: "一次性",

      ader_yes_triggerCron: "",
      ader_yesyes_triggerCron: "",
      ader_yesno_triggerCron: "",
      ader_no_triggerCron: "",
      ader_noyes_triggerCron: "",
      ader_nono_triggerCron: "",

      yes_delivery: "",
      yesyes_delivery: "",
      yesno_delivery: "",
      no_delivery: "",
      noyes_delivery: "",
      nono_delivery: "",

      yes_triggerCron: {},
      yesyes_triggerCron: {},
      yesno_triggerCron: {},
      no_triggerCron: {},
      noyes_triggerCron: {},
      nono_triggerCron: {},

      // Tab offcanvas data
      tabState: "event",
      updateWho: "",

      currentTrigger: null,
      triggerSelected: "",
      time_trigger: "",
      time_trigger_state: "",
      time_trigger_Selected: "",
      time_trigger_state_Selected: "",

      template_email: "",
      template_sms: "",

      eventSelected: "",
      currentEvent: null,
      currentEventYes: null,
      currentEventNo: null,
      time_interval_Selected: "",
      time_interval: "",
      yes_time_interval: "",
      no_time_interval: "",
      time_state_Selected: "",
      time_state: "",
      yes_time_state: "",
      no_time_state: "",

      time_after_Selected: "",
      yes_time_after: "",
      yesyes_time_after: "",
      yesno_time_after: "",
      no_time_after: "",
      noyes_time_after: "",
      nono_time_after: "",
      time_after_state_Selected: "",
      yes_time_after_state: "天後",
      yesyes_time_after_state: "天後",
      yesno_time_after_state: "天後",
      no_time_after_state: "天後",
      noyes_time_after_state: "天後",
      nono_time_after_state: "天後",

      action_Selected: "",
      yes_action: "",
      yesyes_action: "",
      yesno_action: "",
      no_action: "",
      noyes_action: "",
      nono_action: "",

      yes_template: "",
      yesyes_template: "",
      yesno_template: "",
      no_template: "",
      noyes_template: "",
      nono_template: "",

      // subject
      email_subject: "",
      sms_subject: "",
      yes_subject: "",
      yesyes_subject: "",
      yesno_subject: "",
      no_subject: "",
      noyes_subject: "",
      nono_subject: "",

      eventNameFromSche: "",
      eventTitleFromSche: "",
      eventScheduleIdFromSche: "ReMA_15e78a6b",
      eventScriptIdFromSche: null,
      eventScriptHasEventFromSche: "0",

      grabbing: false,
      fat: false,

      newHtml: "",
      newSubsec: "",

      apiTableData: {},
      ruleID: "",
      CycleTimeDetail: {},
      cycleTime_time: "",
      cycleTime_state: "",
    };
  },
  created() {
    this.getUnsubscribeUrl()
  },
  watch: {
    addadd2: function (val) {
      console.log(val);
      this.getScriptById();
      // if (this.emailChannel) {
      //   this.postEmailTable();
      // } else {
      //   this.postSmsTable();
      // }
      this.eventNameFromSche = sessionStorage.getItem("setResEventName");
      this.eventTitleFromSche = sessionStorage.getItem("setResEventTitle");
    },
    scriptID2: function (val) {
      console.log(val);
    },
    currentEventYes() {
      if (this.currentEventYes === null && this.currentEventNo === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventNo() {
      if (this.currentEventYes === null && this.currentEventNo === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    CycleTimeDetail: {
      handler: function () {
        console.log(this.CycleTimeDetail);
        if (this.CycleTimeDetail._isShown == false) {
          const backdrop = document.getElementById("CycleTimeDetail-backdrop");
          backdrop.style["display"] = "none";
        }
      },
      deep: true,
    },
  },
  methods: {
    // 拿取消訂閱的網址
     getUnsubscribeUrl() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      allapi2
        .templatev2UnsubscribeUrlGet(params)
        .then((res) => {
          console.log(res);
          this.unsubscribe_url = res.data.content;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("get連線有誤" + err);
        });
    },
    getScriptById: async function () {
      const token = sessionStorage.getItem("access_token");

      if (this.emailChannel) {
        const params = {
          Authorization: "Bearer " + token,
          script_id: this.$props.scriptID2,
        };
        await allapi2
          .scriptScriptIdGet(params)
          .then((res) => {
            this.ruleID = res.data.flows[0].ruleID;
            console.log("成功根據排成獲得劇本內容", res);
            console.log("this.ruleID", this.ruleID);
            res.data.flows.forEach((o) => {
              if (o.action === "寄信") {
                o.action = "傳送Email";
              }
              if (o.action === "寄簡訊") {
                o.action = "傳送SMS";
              }

              o.template_name = o.template;
            });
            this.testList2 = res.data.flows;
            this.loading = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
          });
      } else {
        const params = {
          Authorization: "Bearer " + token,
          id: this.$props.scriptID2,
        };
        await allapi2
          .smsScriptIdGet(params)
          .then((res) => {
            this.ruleID = res.data.flows[0].ruleID;
            console.log("成功根據排成獲得劇本內容", res);
            console.log("this.ruleID", this.ruleID);

            res.data.flows.forEach((o) => {
              if (o.action === "寄信") {
                o.action = "傳送Email";
              }
              if (o.action === "寄簡訊") {
                o.action = "傳送SMS";
              }
              o.template_name = o.template;
            });
            this.testList2 = res.data.flows;
            this.loading = false;
          })
          .catch((err) => {
            console.log("連線有誤");
            console.log(err);
          });
      }
      this.eventScheduleIdFromSche = this.testList2[0].ruleID;

      this.testList2 = this.testList2.map((o) => {
        return {
          action: o.action,
          event: o.event,
          is_yes: o.is_yes,
          ruleID: o.ruleID,
          subject: o.subject,
          template_name: o.template,
          time_after: o.time_after,
          time_interval: o.time_interval,
          vice_ruleID: o.vice_ruleID,
        };
      });

      this.testList2.map((d) => {
        if (d.vice_ruleID.length === 12 && d.is_yes === 1) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after = d.time_after / 24;
            this.yes_time_after_state = "天後";
          } else {
            this.yes_time_after = d.time_after;
            this.yes_time_after_state = "小時後";
          }
          this.yes_action = d.action;
          this.yes_template = d.template_name;
          this.yes_subject = d.subject;
        } else if (d.vice_ruleID.length === 12 && d.is_yes === 0) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after = d.time_after / 24;
            this.no_time_after_state = "天後";
          } else {
            this.no_time_after = d.time_after;
            this.no_time_after_state = "小時後";
          }
          this.no_action = d.action;
          this.no_template = d.template_name;
          this.no_subject = d.subject;
        } else if (d.vice_ruleID.includes("_1_1")) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after = d.time_after / 24;
            this.yesyes_time_after_state = "天後";
          } else {
            this.yesyes_time_after = d.time_after;
            this.yesyes_time_after_state = "小時後";
          }
          this.yesyes_action = d.action;
          this.yesyes_template = d.template_name;
          this.yesyes_subject = d.subject;
        } else if (d.vice_ruleID.includes("_1_2")) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after = d.time_after / 24;
            this.yesno_time_after_state = "天後";
          } else {
            this.yesno_time_after = d.time_after;
            this.yesno_time_after_state = "小時後";
          }
          this.yesno_action = d.action;
          this.yesno_template = d.template_name;
          this.yesno_subject = d.subject;
        } else if (d.vice_ruleID.includes("_2_1")) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after = d.time_after / 24;
            this.noyes_time_after_state = "天後";
          } else {
            this.noyes_time_after = d.time_after;
            this.noyes_time_after_state = "小時後";
          }
          this.noyes_action = d.action;
          this.noyes_template = d.template_name;
          this.noyes_subject = d.subject;
        } else if (d.vice_ruleID.includes("_2_2")) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after = d.time_after / 24;
            this.nono_time_after_state = "天後";
          } else {
            this.nono_time_after = d.time_after;
            this.nono_time_after_state = "小時後";
          }
          this.nono_action = d.action;
          this.nono_template = d.template_name;
          this.nono_subject = d.subject;
        }
      });
      this.editList = false;
      console.log("this.editList", this.editList);
    },
    showexp: async function (a, b, subject, action) {
      console.log(action);
      const key = a + this.eventScheduleIdFromSche.slice(-8) + b;
      if (action == "傳送Email") {
        await this.getTemplateByScriptkey(key);
        this.newHtml = this.newHtml
          .replace(
            "{{p1}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
          )
          .replace(
            "{{p2}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
          )
          .replace(
            "{{p3}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
          )
          .replace(
            "{{p4}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
          )
          .replace("{{tracking}}", "")
          .replace(/{{unsubscribe}}/g, this.unsubscribe_url)
        this.$emit("showUnlayer", this.newHtml, subject);
      } else {
        await this.getTemplateByScriptkey(key);
        console.log(this.newHtml)
        this.newHtml = this.newHtml
          .replace(
            "{id}",
            "uid000"
          )
        this.$emit("showSMSexp", this.newHtml);
      }
    },
    getTemplateByScriptkey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: key,
      };

      await allapi2
        .scriptTempScheduleIdGet(params)
        .then((res) => {
          console.log(res);
          this.newHtml = res.data.data;
          // if (this.emailChannel===false) {
          //   this.newSubsec = res.data.data.json;
          // }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },

    postEmailTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.scriptID2,
      };
      await allapi3
        .emailIndividualEventGet(params)
        .then((res) => {
          console.log("emailIndividualEventGet", res);
          this.apiTableData = res.data.Data;
          this.apiTableData.發送數.map((d) => {
            if (
              d.vice_ruleID.length === 12 &&
              d.vice_ruleID.slice(-2) === "_1"
            ) {
              this.yes_delivery = d.送達數量;
            } else if (
              d.vice_ruleID.length === 12 &&
              d.vice_ruleID.slice(-2) === "_2"
            ) {
              this.no_delivery = d.送達數量;
            } else if (d.vice_ruleID.includes("_1_1")) {
              this.yesyes_delivery = d.送達數量;
            } else if (d.vice_ruleID.includes("_1_2")) {
              this.yesno_delivery = d.送達數量;
            } else if (d.vice_ruleID.includes("_2_1")) {
              this.noyes_delivery = d.送達數量;
            } else if (d.vice_ruleID.includes("_2_2")) {
              this.nono_delivery = d.送達數量;
            }
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    postSmsTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.scriptID2,
      };
      await allapi3
        .smsScriptEventGet(params)
        .then((res) => {
          console.log("smsScriptEventGet", res);
          this.apiTableData = res.data.Data;
          this.apiTableData.發送數.map((d) => {
            if (d.ruleID.length === 12 && d.ruleID.slice(-2) === "_1") {
              this.yes_delivery = d.送達數量;
            } else if (d.ruleID.length === 12 && d.ruleID.slice(-2) === "_2") {
              this.no_delivery = d.送達數量;
            } else if (d.ruleID.includes("_1_1")) {
              this.yesyes_delivery = d.送達數量;
            } else if (d.ruleID.includes("_1_2")) {
              this.yesno_delivery = d.送達數量;
            } else if (d.ruleID.includes("_2_1")) {
              this.noyes_delivery = d.送達數量;
            } else if (d.ruleID.includes("_2_2")) {
              this.nono_delivery = d.送達數量;
            }
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    // 顯示時間設定詳情
    showCycleTimeDetail(time, state) {
      this.cycleTime_time = time;
      this.cycleTime_state = state;
      this.CycleTimeDetail = new bootstrap.Modal(
        document.getElementById("CycleTimeDetail"),
        {
          keyboard: false,
        }
      );
      this.CycleTimeDetail.show();
      const backdrop = document.getElementById("CycleTimeDetail-backdrop");
      backdrop.style["display"] = "block";
    },
    // 顯示外面的預覽
    showOutsideExp(){
      this.$emit("showOutsideExp",this.emailChannel);
      console.log(this.emailChannel)
    },

    showEmailDetail(a, b) {
      const key = a + this.eventScheduleIdFromSche.slice(-8) + b;
      this.$emit("showEmailDetail", key);
    },

    zoomout() {
      this.z = this.z - 0.1;
      if (this.z < 0.4) {
        this.z = 0.4;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomin() {
      this.z = this.z + 0.1;
      if (this.z > 1) {
        this.z = 1;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomreset() {
      this.z = 1;
      this.zoom = { transform: `scale(1)` };
    },
  },
};
</script>

<style>
/* SeeScript2 */
.child-popout-inSeeScript2 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 300px;
  right: -315px;
  top: -42px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}
.container__arrow {
  /* Invisible by default */
  opacity: 1;
  pointer-events: none;
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  left: -0.8%;
  position: absolute;
  transform: translate(-68%, 7px) rotate(90deg);
  height: 0;
  width: 0;
  z-index: 10;
}
.tab-div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
}
/* .form-control:focus {
  box-shadow: none;
  border: 1px solid #ff614d;
} */
.zoom-in-seescript {
  background-color: transparent;
  border-radius: 5px;
  font-size: 16px;
  top: 36px;
  left: 12px;
  padding: 5px 10px;
  position: absolute;
  z-index: 99;
}
.nowork {
  display: none;
}
.notwork {
  background-color: #eeeeee;
  border-radius: 5px;
  font-size: 16px;
  left: 20px;
  padding: 5px 10px;
  position: absolute;
  top: 80px;
  z-index: 99;
}
.work {
  background-color: #c6e0c9;
  border-radius: 5px;
  font-size: 16px;
  left: 20px;
  padding: 5px 10px;
  position: absolute;
  top: 80px;
  z-index: 99;
}

/* 一般樣式 */
.script-header {
  font-size: 24px;
  font-weight: bold;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.nav-tabs .nav-link {
  background-color: white;
  height: 40px;
  color: #575757;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.nav-tabs .nav-link.active {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.contain-left-all {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 645px;
  margin-bottom: 25px;
}
.moveTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
hr {
  border: 1px solid black;
}
.timeTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
.time-input {
  border: none;
  border-bottom: 1px solid black;
  background-color: #eaf3f4;
  width: 50px;
  outline: none;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.contain-right-top-seeScript2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  height: 721px;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}
.tree-div {
  width: 100%;
}
.tree-tag {
  border-radius: 10px;
  background-color: #eaf3f4;
  text-align: center;
  width: 90px;
  margin: 0 auto;
  cursor: pointer;
}
.template-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 10px;
  background-color: #eaf3f4;
  padding: 5px;
  text-align: center;
  width: 90px;
  margin: 0 auto;
  font-size: 18px;
}
.blue-tag {
  background-color: #96c5d7;
  color: white;
  border-radius: 10px;
  padding: 13px 19px 0 19px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.blue-tag2 {
  background-color: #96c5d7;
  border-radius: 15px;
  padding: 16px;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.blue-tag3 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding: 12px 25px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.mail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/openmail.svg");
}
.click-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clicklink.svg");
}
.change-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/change.svg");
}
.clock-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clock.svg");
}
.sendmail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendmail.svg");
}
.sendsms-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendsms.svg");
}
.yes-tag {
  position: relative;
  background-color: #8bc19b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
  font-size: 18px;
}
.no-tag {
  position: relative;
  background-color: #ff8b8b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
  font-size: 18px;
}
.time-tag {
  background-color: #fc9f66;
  border-radius: 10px;
  color: white;
  padding-top: 5px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag2 {
  position: relative;
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 13px 15px 10px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag3 {
  position: relative;
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 0px 20px 0px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.contain-right-bot {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 120px;
  margin-top: 30px;
}
.bot-left-left-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #ff998b, #ff614d);
  color: white;
}
.bot-left-btn {
  border-radius: 10px;
  border: none;
  background-color: #eaf3f4;
}
.bot-right-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.floating-button {
  position: fixed;
  bottom: 0;
  /* top: 50%; */
  left: 45.6%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  z-index: 10;
  display: flex;
  width: 8rem;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.floating-button2 {
  left: 50%;

  z-index: 10;
  display: flex;
  /* width: 8rem; */
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
  /* border-top-left-radius: 20px;
border-top-right-radius: 20px; */
}
.select-style {
  border-radius: 10px;
  text-align: center;
  height: 40px;
  border: 1px solid #c4c4c4;
}
.btn-basic {
  border: none;
  border-radius: 5px;
  width: 14rem;
  height: 40px;
}
.modal-btn-close {
  color: #fff;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border: none;
  width: 10vw;
}

.btn-save {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-group-div {
  background-color: #eaf3f4;
  width: 68%;
  border-radius: 10px;
}
.button-color2 {
  width: 50%;
  background-color: #eaf3f4;
  border-radius: 10px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -70px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.file-span {
  display: inline-block;
  background-color: #eaf3f4;
  line-height: 40px;
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clear-img-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.clear-file-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.add-template-button-color2 {
  border: 1px solid #71afb6;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  border-radius: 5px;
}
.add-template-button-color22 {
  border: 1px solid #71afb6;
  height: 39px;
  color: #71afb6;
  background-color: white;
  border-radius: 5px;
}
.delete-btn {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 93%;
}
.delete-btn2 {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 930px;
}
.group-div {
  position: absolute;
  right: 5px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.channel-btn {
  border: none;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.modal-backdrop {
  z-index: 999;
  background-color: rgb(155, 153, 153);
}
.highlight2 {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 10rem;
  padding: 30px;
  border-radius: 25px;
  width: 330px;
  word-break: break-all;
  /* overflow-wrap: anywhere; */
  white-space: normal;
}
.highlight-SmsTemp {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.highlight-SmsTemp-var {
  background-color: #71afb6;
  color: white;
}
/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35%;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 5px;
  left: 4px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  top: 5px;
  left: 5px;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #e0dada;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #71afb6;
}
.shodow-box-afterTriggerScript {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
}
.traggle-left {
  background-color: #a0c4c8;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
}
.traggle-green {
  border-color: transparent transparent transparent #62a074;
  position: relative;
  right: 0px;
  border-style: solid solid solid solid;
  border-width: 10px;
  /* 設定 width、height 可更好理解原理 */
  height: 0px;
  width: 0px;
}
.traggle-red {
  border-color: transparent transparent transparent #e56c6c;
  position: relative;
  right: 0px;
  border-style: solid solid solid solid;
  border-width: 10px;
  /* 設定 width、height 可更好理解原理 */
  height: 0px;
  width: 0px;
}
.traggle-template {
  position: relative;
  border-color: transparent transparent transparent #a0c4c8;
  right: 0px;
  border-style: solid solid solid solid;
  border-width: 10px;
  /* 設定 width、height 可更好理解原理 */
  height: 0px;
  width: 0px;
}
.cycletime-setting-block {
  width: 80%;
  border: 1px solid #c4c4c4;
  line-height: 40px;
  border-radius: 10px;
  color: #c4c4c4;
  text-align: center;
}
</style>