<template>
  <!-- loading -->
  <div class="full-loading-background" v-show="loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div>下載中...請稍後</div>
  </div>

  <!--main  -->
  <div
    class="modal fade"
    id="TouchListEmailDetailModal"
    tabindex="-1"
    aria-labelledby="alertModalLabel"
    aria-hidden="true"
    :style="`z-index:${zIndex}`"
  >
    <div class="modal-dialog" style="min-width: 1122px">
      <div class="modal-content">
        <div class="modal-header alert-modal-header d-flex justify-content-end">
          <img
            src="../assets/whiteX.svg"
            alt="arrow"
            class=""
            data-bs-dismiss="modal"
          />
        </div>
        <div
          class="modal-body alert-modal-body text-start"
          style="padding: 28px 32px"
        >
          <DataTable
            id="TouchListTable"
            :value="this.newTable"
            :paginator="true"
            :rows="6"
            paginatorPosition="top"
            :pageLinkSize="3"
            paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
            currentPageReportTemplate="目前 {currentPage} ，共 {totalPages} 頁"
            :style="{ textAlign: 'center' }"
            :first="this.nowpage"
          >
            <template #paginatorstart>
              <div>
                <div
                  class="d-flex align-items-center flex-wrap"
                  style="width: 500px"
                >
                  <span class="me-3" style="line-height: 40px"
                    >查看互動名單</span
                  >
                  <Dropdown
                    style="width: 300px"
                    :valueArr="this.dropDown_event"
                    :selectValue="eventSelected"
                    :changingSelected="'eventSelected'"
                    v-on:selectValueByDrop="selectValueByDrop"
                    v-if="!this.$props.hasAB.includes('http')"
                  />
                </div>
                <div
                  class="text-start mt-2 GrayC4 col-12"
                  v-if="this.$props.hasAB.includes('http')"
                >
                  連結URL: {{ this.$props.hasAB }}
                </div>
                <div
                  class="text-start mt-2 col-12"
                  style="height: 30px; width: 20px"
                  v-else
                ></div>
              </div>
            </template>
            <hr
              class="m-0"
              style="
                width: 100%;
                opacity: 1;
                height: 0;
                border: 0;
                border-bottom: 1px solid #c4c4c4;
                position: absolute;
                top: 90px;
              "
            />
            <Column field="name" header="會員名稱" :sortable="true"> </Column>
            <Column field="email" header="Email" :sortable="true"></Column>
            <Column field="phone" header="電話" :sortable="true"></Column>
            <Column field="time" header="日期時間" :sortable="true">
              <template #body="slotProps2">
                <div>
                  <div>
                    {{ slotProps2.data.time }}
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <div class="d-flex justify-content-end mt-4">
            <button
              class="btn-basic bg-SkyBlue Cyan me-3"
              style="width: 250px"
              @click="this.getcsv()"
            >
              <img
                src="../assets/green-download.svg"
                style="position: relative; top: -1px; margin-right: 15px"
                alt=""
              />
              名單匯出</button
            ><button
              class="btn-basic bg-gradientCyan White"
              style="width: 250px"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { allapi3 } from "../../public/static3/api/apigClient.js";

export default {
  name: "TouchListEmailDetail",
  components: {
    Dropdown,
    DataTable,
    Column,
  },
  props: {
    dropDown_event: Array,
    marketChannel: String,
    rule_id: String,
    zIndex: Number,
    hasAB: String,
    event_name: String,
  },
  data() {
    return {
      loading: false,
      eventSelected: "",
      newTable: [],
      nowpage: 0,

      template_a_Table: [],
      template_b_Table: [],
    };
  },
  created: async function () {
    console.log("this.$props.rule_id", this.$props.rule_id);
    this.eventSelected = this.dropDown_event[0];
    await this.getTouchListTable();
  },
  watch: {
    newTable() {
      console.log(this.newTable);
    },
    hasAB: async function () {
      console.log("this.$props.hasAB", this.$props.hasAB);
      this.newTable = [];
      console.log("this.newTable", this.newTable);
      await this.getTouchListTable();
    },
  },
  methods: {
    // 下拉選單模塊內的選取function
    selectValueByDrop: async function (selectValue, changingSelected) {
      console.log("this.$props.rule_id", this.$props.rule_id);
      this[changingSelected] = selectValue;
      await this.getTouchListTable();
    },
    // 拿 A模板 互動名單
    getTouchListTable: async function () {
      let event_type = "";
      if (this.$props.hasAB.includes("http")) {
        event_type = this.$props.hasAB;
      } else {
        if (this.eventSelected == "開信") {
          event_type = "view";
        } else if (this.eventSelected == "點擊") {
          event_type = "click";
        } else if (this.eventSelected == "送達") {
          event_type = "deliver";
        } else if (this.eventSelected == "退件") {
          event_type = "bounce";
        }
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.rule_id,
        type: event_type,
        id: 1000,
        action: this.$props.marketChannel,
      };

      await allapi3
        .interactGet(params)
        .then((res) => {
          console.log("互動名單a", res);
          res.data.Data.map((d) => (d.time = d.time.slice(0, -3)));
          this.newTable = res.data.Data;
          this.template_a_Table = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 拿 B模板 互動名單
    getTouchListTable_b: async function () {
      let event_type = "";
      if (this.$props.hasAB.includes("http")) {
        event_type = this.$props.hasAB;
      } else {
        if (this.eventSelected == "開信") {
          event_type = "view";
        } else if (this.eventSelected == "點擊") {
          event_type = "click";
        } else if (this.eventSelected == "送達") {
          event_type = "deliver";
        } else if (this.eventSelected == "退件") {
          event_type = "bounce";
        }
      }
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.rule_id + "_b",
        type: event_type,
        id: 1000,
        action: this.$props.marketChannel,
      };

      await allapi3
        .interactGet(params)
        .then((res) => {
          console.log("互動名單", res);
          res.data.Data.map((d) => (d.time = d.time.slice(0, -3)));
          this.newTable = res.data.Data;
          this.template_b_Table = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    // 合併 A 和 B模板 互動名單
    combineTable() {
      this.newTable = this.template_a_Table.concat(this.template_b_Table);
    },

    getcsv: async function () {
      this.loading = true;
      let event_type = "";
      if (this.eventSelected == "開信") {
        event_type = "view";
      } else {
        event_type = "click";
      }
      let hasAB = "";
      if (this.$props.hasAB == "有") {
        hasAB = "_ab";
      }

      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        ruleID: this.$props.rule_id + hasAB,
        type: event_type,
        action: this.$props.marketChannel,
        filename: `${this.$props.event_name}_${this.eventSelected}`,
      };
      await allapi3
        .interactExportGet(params)
        .then((res) => {
          console.log("getcsv", res);

          window.location.assign(res.data.Data);
          // console.log("this.json_data", this.json_data);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
  },
};
</script>

<style scoped>
:deep(#TouchListTable.p-datatable table) {
  border-collapse: collapse;
  border-radius: 15px;
  border-style: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 2px 0px;
}
:deep(.p-paginator) {
  align-items: flex-start;
}
.full-loading-background {
  background-color: hsla(0, 0%, 100%, 0.7);
  height: 100%;
  left: 0;
  padding: 400px 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9999;
}
</style>