<template>
  <!-- <div v-if="noMember" class="d-flex justify-content-center align-items-center">
    <span style="color: #c4c4c4"> 尚未有會員資料匯入 </span>
  </div> -->
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <!-- roadModal alert -->
    <div
      class="modal fade"
      id="roadModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>請選擇投放管道。</p>
            <div id="channel" class="d-flex flex-column align-items-center">
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'email' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'email'"
              >
                Email
              </button>
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'sms' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'sms'"
              >
                SMS
              </button>
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'line' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'line'"
              >
                Line
              </button>
              <button
                class="road-button-email my-2"
                :class="selectChannel === 'fb' ? 'road-button-email-focus' : ''"
                v-on:click="this.selectChannel = 'fb'"
              >
                FB Ads
              </button>

              <p class="road-button my-2">Google Ads</p>

              <p class="road-button my-2">域動clickforce</p>
              <div>
                <button
                  class="road-button-confirm mt-2"
                  data-bs-dismiss="modal"
                  @click="shareData()"
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 未選著類型 alert -->
    <div
      class="modal fade"
      id="alertModalforalert"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">尚未選擇名單</div>
          <div
            class="modal-footer alert-modal-footer"
            style="background-color: "
          >
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header">
      <span class="title-span fs-medium">
        價值分群
        <button
          class="btn btn-default button px-0 mx-0 pt-0 btn-info-pop"
          style="position: relative"
          @mouseover="RFMShow = true"
          @mouseleave="RFMShow = false"
        >
          <div
            class="parent-popout-inRFMpage"
            @mouseover="RFMShow = true"
            @mouseleave="RFMShow = false"
          >
            <div v-show="RFMShow" class="child-popout3-inRFMpage">
              採用演算法自動將顧客區分為6種客群，建議可配合不同群體進行廣告投放與行銷活動，適合想要快速分群的使用者。
              <br />
              R值為最近一次消費，F為近半年消費頻率，M為近半年消費總金額。
              <a
                class="aTag-inRFMpage"
                href="https://docs.google.com/spreadsheets/d/1TZjijoFktTBlD8m2nMXtCOEZD7QrQlzpLjnD5I0vLU4/edit?usp=sharing"
                target="_blank"
                :style="colorChange ? 'color:#395185' : 'color:white'"
                @mouseover="colorChange = true"
                @mouseleave="colorChange = false"
                >請點此連結查看詳細計算說明。</a
              >
              <div class="container__arrow"></div>
            </div>
          </div>
        </button>
      </span>
    </div>
    <div
      class="d-flex flex-wrap justify-content-between"
      style="margin-top: 30px"
    >
      <div
        class="col-4 mb-4"
        style="border-radius: 25px; width: 32%; cursor: pointer"
        :style="
          hoverOnCard === rfm.label || rfm.label === beClick
            ? `border:5px solid ${RFMcolor[index]}`
            : 'border:5px solid white'
        "
        v-for="(rfm, index) in RFMs"
        :key="rfm.label_id"
        :id="rfm"
        @click="shareRFM(rfm)"
      >
        <div
          class="card-container d-flex flex-column justify-content-between"
          @mouseenter="hoverOnCard = rfm.label"
          @mouseleave="hoverOnCard = ''"
        >
          <div class="px-2">
            <div class="d-flex align-items-center" style="position: relative">
              <div
                style="border-radius: 50%; z-index: 10"
                :style="`background-color:${RFMcolor2[index]}`"
              >
                <img
                  :src="RFMsImg[index]"
                  style="width: 60px; height: 60px"
                  class="logo"
                  alt="Girl in a jacket"
                />
              </div>
              <h3
                class="rfmpage-title m-0 text-center"
                :style="`background-color:${RFMcolor[index]}`"
              >
                {{ rfm.label }}
              </h3>
            </div>
            <div>
              <p class="rfmpage-sub" style="margin-top: 25px; cursor: pointer">
                {{ RFMsText[index] }}
              </p>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-center"
            style="position: relative"
            :id="rfm.label"
            :class="rfm.label"
          >
            <div class="col-12 mb-2">
              <img
                src="../assets/peopleNumbers.svg"
                style="width: 29px"
                class="numbers pb-1"
                alt="Girl in a jacket"
              />
              <span>{{ rfm.number.toLocaleString() }}</span>
            </div>
            <progress
              class="progress"
              :value="rfm.percent"
              max="100"
            ></progress>
            <span class="percent"> {{ rfm.percent }}% </span>
          </div>
        </div>
      </div>
    </div>
    <div class="shadow-box-RFM" style="padding: 26px 13.5px" v-if="!rfmLoading">
      <div class="d-flex" style="margin-bottom: 30px">
        <div
          style="margin: 0 12.5px; width: 24%; padding: 20px"
          class="d-flex flex-column justify-content-between shadow-box-RFM"
        >
          <p style="color: #71afb6">近半年平均消費頻率</p>
          <p class="d-flex justify-content-between">
            <span v-if="rfm.F === null"> -&nbsp;&nbsp;-&nbsp;&nbsp;</span>
            <span v-else>
              {{
                (
                  Math.round((+rfm.F + Number.EPSILON) * 10) / 10
                ).toLocaleString()
              }}</span
            >
            <span>(次)</span>
          </p>
        </div>
        <div
          style="margin: 0 12.5px; width: 24%; padding: 20px"
          class="d-flex flex-column justify-content-between shadow-box-RFM"
        >
          <p style="color: #71afb6">近半年平均消費金額</p>
          <p class="d-flex justify-content-between">
            <span v-if="rfm.M === null"> -&nbsp;&nbsp;-&nbsp;&nbsp;</span>
            <span v-else>
              {{
                (
                  Math.round((+rfm.M + Number.EPSILON) * 10) / 10
                ).toLocaleString()
              }}</span
            >
            <span>(NT)</span>
          </p>
        </div>
        <div
          style="margin: 0 12.5px; width: 24%; padding: 20px"
          class="d-flex flex-column justify-content-between shadow-box-RFM"
        >
          <p style="color: #71afb6">平均客單價</p>
          <p class="d-flex justify-content-between">
            <span v-if="rfm.客單價 === null"> -&nbsp;&nbsp;-&nbsp;&nbsp;</span>
            <span v-else>
              {{
                (
                  Math.round((+rfm.客單價 + Number.EPSILON) * 10) / 10
                ).toLocaleString()
              }}</span
            >
            <span>(NT)</span>
          </p>
        </div>
        <div
          style="margin: 0 12.5px; width: 24%; padding: 20px"
          class="d-flex flex-column justify-content-between shadow-box-RFM"
        >
          <p style="color: #71afb6">平均上次購買</p>
          <p class="d-flex justify-content-between">
            <span v-if="rfm.R === null"> -&nbsp;&nbsp;-&nbsp;&nbsp;</span>
            <span v-else>
              {{
                (
                  Math.round((+rfm.R + Number.EPSILON) * 10) / 10
                ).toLocaleString()
              }}</span
            >
            <span>(天前)</span>
          </p>
        </div>
      </div>
      <div class="shadow-box-RFM" style="padding: 22px 25px; margin: 0 12.5px">
        <p style="color: #71afb6">輪廓樣貌組合</p>
        <div id="barchart">
          <apexchart
            type="bar"
            height="320"
            :options="barchartOptions"
            :series="barseries"
          ></apexchart>
        </div>
      </div>
    </div>
    <div
      class="shadow-box-RFM d-flex justify-content-center align-items-center"
      style="padding: 26px 13.5px; height: 578px"
      v-else
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center pe-4 mt-4">
      <!-- <button class="btn btn-lg btn-download" @click="getcsv">
        匯出篩選名單
      </button> -->
      <button class="basic-btn" style="width: 18vw" @click="getcsv">
        <img
          src="../assets/green-download.svg"
          style="position: relative; top: -1px; margin-right: 15px"
          alt=""
        />匯出分群
      </button>
      <div class="d-flex align-items-center">
        <button
          class="
            basic-btn
            highlight-btn
            d-flex
            align-items-center
            justify-content-center
            me-3
          "
          style="width: 18vw"
          @click="getmemberinfo"
        >
          <img
            src="../assets/white-list.svg"
            style="position: relative; top: 1px; margin-right: 15px"
            alt=""
          />查看報表
        </button>
        <button
          class="
            btn btn-lg btn-primary
            button-color
            m-2
            d-flex
            align-items-center
            justify-content-center
          "
          style="width: 18vw; min-width: 18vw"
          role="button"
          id="linkbutton"
          @click="goSelectChannel"
        >
          <span> 選擇渠道 </span>
          <img src="../assets/white-arrow.svg" alt="" class="ms-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { allapi2 } from "../../public/static2/api/apigClient.js";

export default {
  name: "RFMpage",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      selectedLabel_name:'',
      selectedLabel_id: "",
      notshow: false,
      colorChange: false,
      rfmLoading: false,
      json_data: [],
      hoverOnCard: "",
      beClick: "",
      rfm: {
        F: null,
        M: null,
        R: null,
        客單價: null,
        number: 0,
      },
      RFMs: [],
      RFMcolor: [
        "#A190C3",
        "#4279A6",
        "#FF8B8B",
        "#7F6F6F",
        "#B6AC93",
        "#94B4A0",
      ],
      RFMcolor2: [
        "#E9E2F8",
        "#CBEAF1",
        "#FFEFEF",
        "#E6E1E7",
        "#EEEBDD",
        "#E3ECE7",
      ],
      RFMsImg: [
        require("../assets/RFMgroupPic0.svg"),
        require("../assets/RFMgroupPic1.svg"),
        require("../assets/RFMgroupPic2.svg"),
        require("../assets/RFMgroupPic3.svg"),
        require("../assets/RFMgroupPic4.svg"),
        require("../assets/RFMgroupPic5.svg"),
      ],
      RFMsText: [
        "最近30天內新註冊且有購買的會員。建議可以為顧客提供良好的消費體驗，給他/她最好的第一印象；例如首次購物金。",
        "近半年整體消費與頻率相對皆高的會員，為重點關注之客群。建議可以提供比其他會員更好的服務或者特別禮遇，讓對方感受到VIP貴賓的感覺；例如VIP顧客獨享禮。 ",
        "近半年整體消費頻率相對高，但消費力一般的會員。建議可以善用此群顧客的認同感，提供優惠來刺激消費，並同時引導他們口碑傳播；例如推薦好友加入就送優惠券。 ",
        "距離上次購買時間相對久，且曾經消費與頻率為高的會員。建議應瞭解該群顧客停止消費的原因與需求，後續可嘗試喚醒；例如檢視滿意度調查並提供對應服務與活動。",
        "距離上次購買已經很久，且消費與頻率都相對低的會員。建議為行銷資源後半段投入的對象。",
        "註冊後但沒有購買任何商品之會員。建議提升該顧客對於公司特點的了解程度，或者提供優惠券促使該會員進行首購；例如生日優惠購物金、入會禮獎勵。",
      ],

      number: "",
      loading: "",
      RFMShow: false,
      selectChannel: "",

      barseries: [
        {
          data: [0, 0],
        },
      ],
      barchartOptions: {},
      seletedApiChannelBar: {},
      seletedApiChannelBarRatio: {},
      colorBR: ["#63A7C8", "#FFA09D"],
    };
  },
  created: async function () {
    // http://localhost:8080 test

    if (sessionStorage.getItem("lv") === "test") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "laurel") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") === "dev") {
      this.notshow = false;
      sessionStorage.removeItem("lv");
    }
    this.loading = true;

    await Promise.all([this.getOverall(), this.get2()]);

    this.barchart();
    this.loading = false;
  },
  methods: {
    show() {
      // a=!a
      // console.log("a",a)
      this.RFMShow = !this.RFMShow;
      // this.ABShow = !this.ABShow
    },

    shareRFM: async function (rfm) {
      if (this.beClick === rfm.label) {
        this.beClick = "";
        this.selectedLabel_id = "";
        this.selectedLabel_name = "";
        await this.getOverall();
        this.barchart();
      } else {
        this.selectedLabel_name = rfm.label;
        this.selectedLabel_id = rfm.label_id;
        this.beClick = rfm.label;
        this.rfm = rfm;
        this.seletedApiChannelBar = rfm.age_gender.structure;
        this.seletedApiChannelBarRatio = rfm.age_gender.ratio.ratio;
        this.barchart();
      }
      this.number = rfm.number;
    },
    getOverall: async function () {
      this.rfmLoading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelOverallGet(params)
        .then((res) => {
          console.log("拿所有人輪廓", res);
          this.rfm = res.data.message.Data;
          this.seletedApiChannelBar = this.rfm.age_gender.structure;
          this.seletedApiChannelBarRatio = this.rfm.age_gender.ratio.ratio;
          this.rfmLoading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.rfmLoading = false;
          this.loading = false;
          // alert("連線有誤");
        });
    },
    get2: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .customlabelAilabelGet(params)
        .then((res) => {
          console.log("拿各分群的輪廓", res);
          if (res.data.message.Data[0] === undefined) {
            this.noMember = true;
            alert("目前無會員資料");
            return;
          }
          this.RFMs = res.data.message.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          // alert("連線有誤");
        });
    },
    // 下載分群CSV
    download() {
      const buildData = (data) => {
        return new Promise((resolve, reject) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(data[0]);
          arrayData.push(arrayTitle);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(data, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title] || "無";
              items.push(item);
            });
            arrayData.push(items);
          });

          resolve(arrayData);
        });
      };
      const selectNames = this.selectedLabel_name
      const downloadCSV = (data) => {
        let csvContent = "";
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(",") + "\n";
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName =
          "下載" +
          "[" +
          selectNames +
          "]" +
          "資料" +
          new Date().toISOString() +
          ".csv";

        // 建立一個 a，並點擊它
        let link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
        );
        link.setAttribute("download", fileName);
        link.click();
      };
      const data = this.json_data;
      buildData(data).then((data) => downloadCSV(data));
    },
    getcsv: async function () {
      if (this.selectedLabel_id != "") {
        const include_ids = [this.selectedLabel_id];
        const token = sessionStorage.getItem("access_token");
        const params = {
          Authorization: "Bearer " + token,
        };
        const body = {
          label_ids: include_ids,
        };
        await allapi2
          .multipleLabelExportPost(params, body)
          .then((res) => {
            console.log("getcsv", res);
            this.json_data = res.data.message.Data.map((o) => {
              return {
                會員ID: o.會員ID,
                姓名: o.姓名,
                性別: o.性別,
                年齡: o.年齡,
                生日: o.生日,
                手機: o.手機,
                信箱: o.信箱,
                地址1: o.地址1,
                地址2: o.地址2,
                地址3: o.地址3,
                加入日期: o.加入日期,
                會員註冊來源: o.會員註冊來源,
                "LINE ID": o["LINE ID"],
                婚姻狀態: o.婚姻狀態,
                會員等級: o.會員等級,
              };
            });
            console.log("this.json_data", this.json_data);
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            alert("連線有誤");
          });

        this.download();
      } else {
        alert("請先選擇分群");
      }
    },
    //  前往標籤報表資訊
    getmemberinfo() {
      const labelsID = [this.selectedLabel_id];
      sessionStorage.setItem("reportLabel", JSON.stringify(labelsID));

      if (labelsID != "") {
        /// 確認報表產生完成
        this.checkMultipleLabel().then((res) => {
          if (res == true) {
            this.$router.push({
              name: "TagReport",
            });
          } else {
            /// 跳出提示視窗
            const alertLabelNotReadyModal = new bootstrap.Modal(
              document.getElementById("alertLabelNotReadyModal"),
              {
                keyboard: false,
              }
            );
            alertLabelNotReadyModal.show();
          }
        });
      } else {
        alert("請先選擇分群");
      }
    },
    //  確認報表資訊產生完成
    checkMultipleLabel: async function () {
      const labelsID = [this.selectedLabel_id];

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        labelId: labelsID,
      };

      return await allapi2
        .multipleLabelCheckPost(params, body)
        .then((res) => {
          console.log("checkMultipleLabel", res);
          return res["data"]["Data"];
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤");
        });
    },

    barchart() {
      const vm = this;
      console.log("qsqs", this.seletedApiChannelBar);
      // if (this.seletedApiChannelBar.男性 === undefined) {
      //   this.barSeries = [
      //     {
      //       data: [],
      //     },
      //     {
      //       data: [],
      //     },
      //   ];
      //   this.barchartOptions = {
      //     labels: [],
      //   };
      //   console.log("123456", this.barSeries);
      // } else {
      (this.barseries = [
        {
          name: "男性",
          data: this.seletedApiChannelBar.男性.counts,
        },
        {
          name: "女性",
          data: this.seletedApiChannelBar.女性.counts,
        },
      ]),
        (this.barchartOptions = {
          chart: {
            type: "bar",
            height: "100%",
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
            },
          },
          colors: ["#63A7C8", "#FFA09D"],

          dataLabels: {
            enabled: false,
            // formatter: function (val, opts) {
            //   if (opts.seriesIndex === 0) {
            //     return (
            //       Math.round(
            //         (vm.seletedApiChannelBar.男性.ratio[opts.dataPointIndex] +
            //           Number.EPSILON) *
            //           10
            //       ) /
            //         10 +
            //       "%"
            //     );
            //   } else {
            //     return (
            //       Math.round(
            //         (vm.seletedApiChannelBar.女性.ratio[opts.dataPointIndex] +
            //           Number.EPSILON) *
            //           10
            //       ) /
            //         10 +
            //       "%"
            //     );
            //   }
            // },
            // offsetY: -20,
            // style: {
            //   fontSize: "12px",
            //   colors: ["#304758"],
            // },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["#63A7C8", "#FFA09D"],
          },
          xaxis: {
            title: {
              text: "年齡層分層",
              offsetY: 0,
              style: {
                fontSize: "12px",
                cssClass: "padding-for-apex",
              },
            },

            categories: this.seletedApiChannelBar.女性.age,
          },
          legend: {
            formatter: function (seriesName, opts) {
              return (
                "<div style='display:flex; align-items:center'>" +
                seriesName +
                ": " +
                `<div style='color:${
                  vm.colorBR[opts.seriesIndex]
                }; margin-top:3px'>` +
                vm.seletedApiChannelBarRatio[seriesName].toFixed(2) +
                "%" +
                "</div>" +
                "</div>"
              );
            },
          },
          yaxis: {
            title: {
              text: "人數",
            },
            labels: {
              style: {
                fontSize: "12px",
              },
              formatter: function (y) {
                return y.toLocaleString();
              },
            },
            max: function (max) {
              // console.log(10 ** (max.toFixed(0).toString().length - 2) * 5);
              // console.log(max / 10 ** (max.toString().length - 2));
              // console.log(
              //   Math.ceil(max / 10 ** (max.toString().length - 2)) *
              //     10 ** (max.toString().length - 2)
              // );
              // console.log(
              //   Math.ceil(max / 10 ** (max.toString().length - 1)) *
              //     10 ** (max.toString().length - 1) +
              //     10 ** (max.toString().length - 1)
              // );

              return (
                Math.ceil(max / 10 ** (max.toFixed(0).length - 2)) *
                  10 ** (max.toFixed(0).length - 2) +
                10 ** (max.toFixed(0).length - 2) * 3
              );
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                "<div style='width:200px;font-size:12px;font-family:sans-serif'>" +
                "<div style='background-color:#ECEFF1;padding:5px;border-bottom:1px solid #c4c4c4'>" +
                vm.seletedApiChannelBar[w.globals.seriesNames[seriesIndex]].age[
                  dataPointIndex
                ] +
                "</div>" +
                "<div style='padding:10px'>" +
                "<div style='margin-bottom:10px'>" +
                `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                w.globals.seriesNames[seriesIndex] +
                ": " +
                "<span style='font-weight:bold'>" +
                vm.seletedApiChannelBar[
                  w.globals.seriesNames[seriesIndex]
                ].counts[dataPointIndex].toLocaleString() +
                " (人)" +
                "</span>" +
                // vm.scatterData[dataPointIndex][1] +
                "</div>" +
                "<div style='margin:0'>" +
                `<div style='background-color:${w.globals.colors[seriesIndex]};border-radius:10px;width:12px;height:12px;vertical-align:middle;display:inline-block;margin-right:10px;margin-bottom:2px'></div>` +
                "占比" +
                ": " +
                "<span style='font-weight:bold'>" +
                vm.seletedApiChannelBar[
                  w.globals.seriesNames[seriesIndex]
                ].ratio[dataPointIndex].toFixed(1) +
                "%" +
                "</span>" +
                "</div>" +
                "</div>" +
                "</div>"
              );
            },
          },
          //   x: {
          //     show: true,
          //     formatter: function (val) {
          //       return val;
          //     },
          //   },
          //   y: {
          //     formatter: function (val) {
          //       return " $NT " + val.toLocaleString();
          //     },
          //   },
          // },
        });
      // }
    },

    goSelectChannel() {
      const roadModal = new bootstrap.Modal(
        document.getElementById("roadModal"),
        {
          keyboard: false,
        }
      );
      roadModal.show();
    },
    shareData() {
      if (this.selectChannel != "") {
        if (this.selectChannel === "email") {
          this.$router.push({
            name: "ProductTextTemplate",
          });
        } else if (this.selectChannel === "sms") {
          this.$router.push({
            name: "ProductSmsTemplate",
          });
        } else if (this.selectChannel === "line") {
          this.$router.push({
            name: "LineAdsPackage",
          });
        } else if (this.selectChannel === "fb") {
          this.$router.push({
            name: "FacebookAdsPackage",
          });
        }
      } else {
        alert("需選取投放管道");
        const roadModal = new bootstrap.Modal(
          document.getElementById("roadModal"),
          {
            keyboard: false,
          }
        );
        roadModal.show();
      }
    },
  },
};
</script>

<style scoped>
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  /* padding: 50px; */
  padding-top: 53px;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.loading-background-bottom-info-in-RFMpage {
  background-color: #fff;
  left: -2px;
  position: absolute;
  top: -2px;
  width: 102%;
  height: 200px;
  z-index: 99;
  border-radius: 10px;
}
.percent {
  position: absolute;
  top: 0px;
  right: 20px;
  /* font-weight:bold; */
  font-size: 18px;
}
.page-title {
  font-size: 28px;
}
.high-light-border {
  border: 5px solid #71afb6;
}
.header {
  font-size: 22px;
}
.leftImg {
  position: relative;
  top: -2px;
  cursor: pointer;
}
.numbers {
  width: 17px;
  margin-right: 10px;
}
.card-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 20px;
  padding: 20px;
  height: 280px;
}
.shadow-box-RFM {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
}
.rfmpage-title {
  position: absolute;
  left: 35px;
  color: white;
  width: 88%;
  font-size: 22px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
}
.progress {
  display: inline-block;
  margin: 0;
}
.progress::-webkit-progress-bar {
  color: #4279a6;
  width: 100%;
  border-radius: 5px;
}
.progress::-webkit-progress-value {
  color: #4279a6;
  border-radius: 5px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}
.新客客群 progress::-webkit-progress-bar {
  background-color: #e9e2f8;
}
.高價值客群 progress::-webkit-progress-bar {
  background-color: #cbeaf1;
}
.忠誠客群 progress::-webkit-progress-bar {
  background-color: #ffefef;
}
.沉睡客群 progress::-webkit-progress-bar {
  background-color: #e6e1e7;
}
.流失客群 progress::-webkit-progress-bar {
  background-color: #eeebdd;
}
.未購買客群 progress::-webkit-progress-bar {
  background-color: #e3ece7;
}
.新客客群 progress::-webkit-progress-value {
  background-color: #a190c3;
}
.高價值客群 progress::-webkit-progress-value {
  background-color: #4279a6;
}
.忠誠客群 progress::-webkit-progress-value {
  background-color: #ff8b8b;
}
.沉睡客群 progress::-webkit-progress-value {
  background-color: #7f6f6f;
}
.流失客群 progress::-webkit-progress-value {
  background-color: #b6ac93;
}
.未購買客群 progress::-webkit-progress-value {
  background-color: #94b4a0;
}
.more-info-button {
  border-color: transparent;
  border: none;
  background-image: linear-gradient(to bottom right, #71afb6, #b5dce0);
}
.card-expand {
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.button-color {
  background-image: linear-gradient(to bottom right, #71afb6, #b5dce0);
  color: #fff;
  border-color: transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.borderforcolor {
  color: #000;
  border: 2px solid #71afb6;
}
.modal-button {
  padding-left: 0;
}
.modal-backdrop {
  z-index: -1;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.rfmpage-sub {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 300;
}
.btn-download {
  background-color: #eaf3f4;
  color: #71afb6;
  font-size: 20px;
}
.button-color {
  /*background-color:#71AFB6;*/
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
  font-size: 20px;
}
.btn-lg {
  min-width: 31rem;
  font-size: 18px;
  border-radius: 10px;
}
.alert-modal-header {
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  padding: 0.5rem 1rem;
}
.alert-modal-body {
  text-align: center;
}
.road-button-email {
  height: 35px;
  padding: 5px;
  border: 1px solid #c4c4c4;
  background-color: white;
  border-radius: 8px;
  width: 265px;
}
.road-button-email-focus {
  padding: 5px;
  border: 1px solid #c4c4c4;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  color: white;
  border-radius: 8px;
  width: 265px;
}
.road-button {
  opacity: 0.3;
  padding: 5px;
  border: 1px solid #c4c4c4;
  background-color: white;
  border-radius: 8px;
  width: 265px;
}
.road-button-cancel {
  padding: 5px;
  border: none;
  background-color: #eaf3f4;
  border-radius: 8px;
  width: 125px;
}
.road-button-confirm {
  padding: 5px;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border-radius: 8px;
  color: white;
  width: 125px;
}
#roadModal .modal-content {
  width: 410px;
}
.child-popout3-inRFMpage {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 220px;
  right: -211px;
  top: -79px;
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  cursor: default;
}
.parent-popout-inRFMpage {
  position: absolute;
  width: 17px;
  height: 40px;
  top: -5px;
  left: 20px;
}
.child-popout3-inRFMpage a:hover {
  color: #4279a6;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
@media (max-width: 1200px) {
  .online-width {
    width: 22vw;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  .fs-small {
    font-size: 16px;
  }
  .fs-medium {
    font-size: 20px;
  }
}
</style>