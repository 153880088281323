<template>
  <div class="container pd-0">
    <div class="modal-header alert-modal-header d-flex justify-content-end">
      <img
        src="../assets/whiteX.svg"
        alt="arrow"
        style="cursor: pointer"
        data-bs-dismiss="modal"
      />
    </div>
    <!-- backdrop -->
    <div
      id="CycleTimeDetail2-backdrop"
      class="modal-backdrop fade show"
      style="display: none"
    ></div>
    <!-- show CycleTimeDetail -->
    <div
      class="modal fade"
      id="CycleTimeDetail2"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 570px; z-index: 5000"
      >
        <!-- ;margin-left:calc((100% - 360px) / 2) -->
        <div class="modal-content" style="width: 570px">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            v-if="
              this.cycleTimeState != '週期性' &&
              this.cycleTimeState != '一次性' &&
              this.cycleTimeState != ''
            "
          >
            <TimeSettingModalDay
              :cycleTime_setting="this.cycleTime_setting"
              v-if="this.cycleTime_setting.time_setting.happenTime === '日'"
            />
            <TimeSettingModalWeek
              :cycleTime_setting="this.cycleTime_setting"
              v-if="this.cycleTime_setting.time_setting.happenTime === '週'"
            />
            <TimeSettingModalMonth
              :cycleTime_setting="this.cycleTime_setting"
              v-if="this.cycleTime_setting.time_setting.happenTime === '月'"
            />
            <TimeSettingModalYear
              :cycleTime_setting="this.cycleTime_setting"
              v-if="this.cycleTime_setting.time_setting.happenTime === '年'"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start py-3 px-5"
            v-if="this.cycleTimeState === '週期性'"
          >
            <div class="d-flex align-items-center mb-3" style="color: #71afb6">
              經過多少時間寄第一封
            </div>
            <div class="d-flex align-items-center mb-3">
              <div style="width: 20%">開始時間</div>
              <div style="width: 80%" class="d-flex justify-content-between">
                <div
                  class="cycletime-setting-block"
                  style="width: 49%"
                  v-if="this.cycleTime_setting.time_after >= 24"
                >
                  {{ this.cycleTime_setting.time_after / 24 }}
                </div>
                <div style="width: 49%" class="cycletime-setting-block" v-else>
                  {{ this.cycleTime_setting.time_after }}
                </div>
                <div
                  class="cycletime-setting-block"
                  style="width: 49%"
                  v-if="this.cycleTime_setting.time_after == 0"
                >
                  立即
                </div>
                <div
                  class="cycletime-setting-block"
                  style="width: 49%"
                  v-else-if="
                    this.cycleTime_setting.time_after > 0 &&
                    this.cycleTime_setting.time_after < 25
                  "
                >
                  小時
                </div>
                <div style="width: 49%" class="cycletime-setting-block" v-else>
                  天後
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div style="width: 20%">發送方式</div>
              <div class="cycletime-setting-block">週期性投放</div>
            </div>
            <div class="d-flex align-items-center mb-3" style="color: #71afb6">
              寄完第一封後
            </div>
            <div class="d-flex flex-wrap mb-3">
              <div style="width: 20%; line-height: 40px">周期條件</div>
              <div class="cycletime-setting-block mb-3">
                {{ this.cycleTime_setting.time_setting.happenTime }}
              </div>
              <div style="width: 20%"></div>
              <div class="cycletime-setting-block mb-3" style="width: 60%">
                {{ this.happenByhappenTime() }}
              </div>
              <div style="width: 20%; line-height: 40px; text-align: right">
                投放一次
              </div>

              <div style="width: 20%"></div>
              <div class="cycletime-setting-block mb-3">
                {{ this.cycleTime_setting.time_setting.daytime }}
              </div>
              <div style="width: 20%"></div>
              <div style="width: 20%; line-height: 40px">結束次數</div>
              <div
                class="cycletime-setting-block"
                style="width: 50%"
                v-if="this.cycleTime_setting.endcount == -1"
              ></div>
              <div class="cycletime-setting-block" style="width: 50%" v-else>
                {{ this.cycleTime_setting.endcount }}
              </div>
              <div style="width: 10%; line-height: 40px; text-align: right">
                次
              </div>
            </div>
          </div>
          <div
            class="modal-body alert-modal-body text-start py-3 px-5"
            v-else-if="this.cycleTimeState === '一次性'"
          >
            <div class="d-flex align-items-center mb-3" style="color: #71afb6">
              經過多少時間寄第一封
            </div>
            <div class="d-flex align-items-center mb-3">
              <div style="width: 20%">開始時間</div>
              <div style="width: 80%" class="d-flex justify-content-between">
                <div
                  class="cycletime-setting-block"
                  style="width: 49%"
                  v-if="this.cycleTime_setting.time_after >= 24"
                >
                  {{ this.cycleTime_setting.time_after / 24 }}
                </div>
                <div class="cycletime-setting-block" style="width: 49%" v-else>
                  {{ this.cycleTime_setting.time_after }}
                </div>
                <div
                  class="cycletime-setting-block"
                  style="width: 49%"
                  v-if="this.cycleTime_setting.time_after == 0"
                >
                  立即
                </div>
                <div
                  class="cycletime-setting-block"
                  style="width: 49%"
                  v-else-if="
                    this.cycleTime_setting.time_after > 0 &&
                    this.cycleTime_setting.time_after < 25
                  "
                >
                  小時
                </div>
                <div class="cycletime-setting-block" style="width: 49%" v-else>
                  天後
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <div style="width: 20%">發送方式</div>
              <div class="cycletime-setting-block">一次性投放</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- tree -->
    <div class="" style="">
      <div
        style="inline-block"
        class="zoom-in-SeeTriggerScript d-flex align-items-center"
      >
        <button
          @click="zoomin"
          style="
            border-radius: 10px;
            border: none;
            width: 30px;
            height: 30px;
            font-size: 20px;
          "
          class="me-2"
        >
          +
        </button>
        <button
          @click="zoomout"
          style="
            border-radius: 10px;
            border: none;
            width: 30px;
            height: 30px;
            font-size: 24px;
          "
          class="me-2"
        >
          -
        </button>
        <button
          @click="zoomreset"
          style="border-radius: 10px; border: none; width: 30px; height: 30px"
          class="pb-1"
        >
          <img src="../assets/resetZoom.png" alt="" class="" />
        </button>
      </div>
      <div
        id="treeDiv"
        class="contain-right-top"
        style="padding-top: 30px; cursor: grab; height: auto"
        :style="grabbing ? 'cursor: grabbing' : ''"
        @dragover="allowDrop"
        @drop="dropTrigger($event)"
        @mousedown="this.grabbing = true"
        @mouseup="this.grabbing = false"
        v-dragscroll
      >
        <div
          class="text-center"
          style="width: 600px; margin: 0 auto"
          :style="[fat ? 'width: 1200px' : '', zoom]"
        >
          <!-- <div
                class="tree-tag"
                style="color: #71afb6; width: 230px; position: relative"
                @click="updateGroup"
              >
                <span
                  style="position: absolute; top: -15px; right: -15px"
                  @click="clearScript"
                  v-if="
                    this.eventScriptHasEventFromSche === '1' ||
                    this.eventScriptHasEventFromSche === '2'
                  "
                >
                  <img src="../assets/deleteScript.svg" alt="" />
                </span>
                {{ groupName.includeLabelNames.toString() }}
              </div> -->

          <!-- <div class="d-flex justify-content-center col-12">
                <img src="../assets/treeline.svg" alt="" />
              </div> -->
          <!-- 樹狀圖 -->
          <div
            v-if="this.currentTrigger != null"
            style="height: 350px"
            @dragover="allowDrop"
            @drop.stop="
              dropTrigger($event);
              dropTriggerTime($event);
            "
          >
            <div
              class="purple-tag"
              style="position: relative"
              :style="
                this.currentTrigger === '購買後促銷' &&
                this.triggerBuy.value != undefined
                  ? 'padding:5px 0px'
                  : ''
              "
              @click="updateTriggerEvent"
            >
              <!-- v-tooltip.top="{
                    value: template_email,
                    class: 'tip',
                  }" -->
              <span
                style="position: absolute; top: -15px; right: -15px"
                @click="clearScript"
                v-if="
                  this.eventScriptHasEventFromSche === '1' ||
                  this.eventScriptHasEventFromSche === '2'
                "
              >
                <img src="../assets/deleteScript.svg" alt="" />
              </span>
              <img
                src="../assets/signup.svg"
                style="width: 63px; position: relative; top: -10px; left: -1px"
                alt=""
                v-if="this.currentTrigger === '註冊'"
              />
              <img
                src="../assets/shopcar.svg"
                style="width: 54px"
                alt=""
                v-if="this.currentTrigger === '購物車未結'"
              />
              <img
                src="../assets/buy.svg"
                style="width: 45px"
                alt=""
                v-if="this.currentTrigger === '購買後促銷'"
              />
              <img
                src="../assets/Order-done.svg"
                style="width: 40px; position: relative; left: 1px"
                alt=""
                v-if="this.currentTrigger === '訂單確認'"
              />
              <img
                src="../assets/cycleTime.svg"
                style="width: 40px"
                alt=""
                v-if="this.currentTrigger === '定期投放'"
              />
              <div
                style="
                  color: white;
                  position: relative;
                  top: -18px;
                  font-size: 14px;
                  margin-top: 5px;
                "
                v-if="this.currentTrigger === '註冊'"
              >
                {{ this.currentTrigger }}
              </div>
              <div
                style="color: white; font-size: 14px; margin-top: 4px"
                v-else
              >
                {{ this.currentTrigger }}
              </div>
              <div
                class="triggerBuy-value"
                v-if="
                  this.currentTrigger === '購買後促銷' &&
                  this.triggerBuy.value != undefined
                "
              >
                {{ this.triggerBuy.value }}
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <img src="../assets/treeline.svg" alt="" />
            </div>
            <div
              style="position: relative"
              v-if="this.time_trigger_state != ''"
              @drop.stop="dropTriggerTime($event)"
            >
              <img
                style="position: absolute; top: -10px; right: calc(50% - 50px)"
                src="../assets/redalert.svg"
                alt=""
                v-if="
                  this.currentTrigger != '定期投放' &&
                  this.ader_triggerCron === '' &&
                  this.cycleTime_trigger === '週期性'
                "
              />
              <img
                style="position: absolute; top: -10px; right: calc(50% - 50px)"
                src="../assets/redalert.svg"
                alt=""
                v-if="
                  this.currentTrigger === '定期投放' &&
                  this.ader_triggerCron === ''
                "
              />
              <div
                class="time-tag2"
                @click="showCycleTimeDetail(this.cycleTime_trigger, '_0')"
                v-if="this.currentTrigger === '購物車未結'"
              >
                <img
                  src="../assets/hourglass.svg"
                  alt=""
                  style="width: 31.5px"
                />
                <div class="mt-1" style="color: white; font-size: 14px">
                  <p class="m-0" style="font-size: 14px">
                    {{ this.time_trigger }} {{ this.time_trigger_state }}
                  </p>
                  <p class="m-0" style="font-size: 12px">
                    {{ this.cycleTime_trigger }}
                  </p>
                </div>
              </div>
              <div
                class="time-tag2"
                style="padding-top: 10px"
                @click="showCycleTimeDetail(this.cycleTimeinCycle, '_0')"
                v-else-if="this.currentTrigger === '定期投放'"
              >
                <img src="../assets/cycleTime2.svg" alt="" />
                <div class="mt-1" style="color: white; font-size: 14px">
                  <p class="m-0" style="font-size: 14px">
                    {{ this.cycleTimeinCycle }}
                  </p>
                </div>
              </div>
              <div
                class="time-tag2"
                @click="showCycleTimeDetail(this.cycleTime_trigger, '_0')"
                v-else
              >
                <img src="../assets/clock.svg" alt="" style="width: 40px" />
                <div class="mt-1" v-if="this.time_trigger_state === '立即'">
                  <p class="m-0" style="font-size: 14px">
                    {{ this.time_trigger_state }}
                  </p>
                  <p class="m-0" style="font-size: 12px">
                    {{ this.cycleTime_trigger }}
                  </p>
                </div>
                <div class="mt-1" style="color: white" v-else>
                  <p class="m-0" style="font-size: 14px">
                    {{ this.time_trigger }} {{ this.time_trigger_state }}
                  </p>
                  <p class="m-0" style="font-size: 12px">
                    {{ this.cycleTime_trigger }}
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <img src="../assets/treeline.svg" alt="" />
              </div>
              <div class="d-flex justify-content-center">
                <hr
                  class="m-0"
                  style="
                    border: none;
                    height: 0;
                    border-top: 2px solid #71afb6;
                    width: 50%;
                    opacity: 1;
                  "
                />
              </div>
              <div class="d-flex">
                <div class="col-6" @dragover="allowDrop">
                  <!-- @drop.stop="dropTemplateAtEmail" -->
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div class="action-tag2">
                    <img src="../assets/sendmail.svg" class="mb-2" alt="" />
                    <span
                      style="position: relative; left: 2px; font-size: 14px"
                    >
                      傳送Email
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                </div>
                <div class="col-6" @dragover="allowDrop">
                  <!-- @drop.stop="dropTemplateAtSms" -->
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div class="action-tag3">
                    <img
                      src="../assets/sendsms.svg"
                      style="position: relative; left: 2px"
                      alt=""
                    />
                    <br />
                    <span style="position: relative; font-size: 14px">
                      傳送SMS
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="this.currentTrigger != null && this.time_trigger_state != ''"
          >
            <div
              class="col-6"
              style="height: 300px"
              @drop.stop="dropTemplateAtEmail"
            >
              <div style="position: relative; width: 105px; margin: 0 auto">
                <div
                  style="position: absolute; top: -13px; right: 1px"
                  v-if="this.email_subject === '' && this.template_email != ''"
                >
                  <img src="../assets/redalert.svg" alt="" />
                </div>
                <div
                  class="template-tag"
                  style="color: #71afb6"
                  v-tooltip.bottom="{
                    value: template_email,
                    class: 'tip-LabelManage',
                  }"
                  v-if="this.template_email != ''"
                  @click="showexp('ve_', '_0', true, this.email_subject)"
                >
                  {{ template_email }}
                </div>
              </div>
              <div
                class="d-flex justify-content-center"
                v-if="this.template_email != ''"
              >
                <img src="../assets/treeline.svg" alt="" />
              </div>
              <div
                class="col-12"
                style="height: 300px"
                @dragover="allowDrop"
                @drop.stop="dropEvent"
                v-if="this.template_email != ''"
              >
                <div
                  style="background-color: white"
                  class="tree-div text-center"
                  v-if="this.currentEvent != null"
                >
                  <div class="blue-tag" @click="updateEventTimeInterval">
                    <img
                      src="../assets/hourglass.svg"
                      alt=""
                      style="width: 31px"
                    />
                    <div class="mt-2">
                      {{ this.time_interval }}{{ this.time_state }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div
                    class="blue-tag3"
                    :style="
                      this.currentEvent === '開啟信件未點擊' ||
                      this.currentEvent === '開啟信件未轉換' ||
                      this.currentEvent === '點擊連結未轉換'
                        ? 'padding-top:4px'
                        : ''
                    "
                    @click="updateEventTimeInterval"
                  >
                    <img
                      src="../assets/openmail.svg"
                      style="width: 40px"
                      alt=""
                      v-if="this.currentEvent.includes('開啟信件')"
                    />
                    <img
                      src="../assets/clicklink.svg"
                      style="width: 40px"
                      alt=""
                      v-if="this.currentEvent.includes('點擊連結')"
                    />
                    <img
                      src="../assets/change.svg"
                      style="width: 40px"
                      alt=""
                      v-if="this.currentEvent === '轉換'"
                    />
                    <div
                      style="
                        width: 100px;
                        position: relative;
                        left: -30px;
                        top: 3px;
                        color: white;
                      "
                      v-if="
                        this.currentEvent === '開啟信件未點擊' ||
                        this.currentEvent === '開啟信件未轉換' ||
                        this.currentEvent === '點擊連結未轉換'
                      "
                    >
                      {{ this.currentEvent.slice(0, -3) }}
                      <br />
                      <span style="font-size: 12px">{{
                        this.currentEvent.slice(-3)
                      }}</span>
                    </div>
                    <div
                      style="
                        width: 100px;
                        position: relative;
                        left: -30px;
                        top: 5px;
                        color: white;
                      "
                      v-else
                    >
                      {{ this.currentEvent }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div class="d-flex justify-content-center">
                    <hr
                      class="m-0"
                      style="
                        border: none;
                        height: 0;
                        border-top: 2px solid #71afb6;
                        width: 50%;
                        opacity: 1;
                      "
                    />
                  </div>

                  <div class="d-flex">
                    <!-- Yes -->

                    <div
                      class="col-6"
                      style="background-color: white; height: 400px"
                      @dragover="allowDrop"
                      @drop.stop="dropTimeAtYes($event)"
                    >
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="yes-tag">是</div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div v-if="yes_time_after != ''">
                        <div
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropActionAtYes($event)"
                        >
                          <div
                            class="time-tag"
                            style="position: relative"
                            @click="
                              showCycleTimeDetail(
                                this.cycleTime_yes,
                                '_1',
                                've_'
                              )
                            "
                          >
                            <img
                              src="../assets/redalert.svg"
                              alt=""
                              style="position: absolute; top: -13px; right: 1px"
                              v-if="
                                this.ader_yes_triggerCron === '' &&
                                this.cycleTime_yes != '一次性'
                              "
                            />
                            <img
                              src="../assets/clock.svg"
                              alt=""
                              style="width: 40px"
                            />
                            <div class="mt-1">
                              <p class="m-0" style="font-size: 14px">
                                {{ yes_time_after }}
                                {{ yes_time_after_state }}
                              </p>
                              <p class="m-0" style="font-size: 12px">
                                {{ cycleTime_yes }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            style="background-color: white; height: 400px"
                            @dragover="allowDrop"
                            @drop.stop="dropTemplateAtYes($event)"
                            v-if="yes_action != ''"
                          >
                            <div class="action-tag2" @click="updateYesAction">
                              <img
                                src="../assets/sendmail.svg"
                                class="mb-2"
                                alt=""
                              />
                              <span
                                style="
                                  position: relative;
                                  left: 2px;
                                  font-size: 14px;
                                "
                              >
                                {{ yes_action }}
                              </span>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img src="../assets/treeline.svg" alt="" />
                            </div>
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropEventAtYes($event)"
                              v-if="yes_template != ''"
                            >
                              <div
                                style="
                                  position: relative;
                                  width: 105px;
                                  margin: 0 auto;
                                "
                              >
                                <div
                                  style="
                                    position: absolute;
                                    top: -13px;
                                    right: 1px;
                                    z-index: 99;
                                  "
                                  v-if="this.yes_subject === ''"
                                >
                                  <img src="../assets/redalert.svg" alt="" />
                                </div>
                                <div
                                  class="template-tag"
                                  style="color: #71afb6; position: relative"
                                  v-tooltip.bottom="{
                                    value: yes_template,
                                    class: 'tip-LabelManage',
                                  }"
                                  @click="
                                    showexp('ve_', '_1', true, this.yes_subject)
                                  "
                                >
                                  {{ yes_template }}
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <!-- UnderYes -->
                              <div
                                style="
                                  background-color: white;
                                  height: 400px;
                                  width: 300px;
                                "
                                v-if="this.currentEventYes != null"
                              >
                                <div
                                  class="blue-tag"
                                  @click="updateYesEventTimeInterval"
                                >
                                  <img
                                    src="../assets/hourglass.svg"
                                    style="width: 31px"
                                    alt=""
                                  />
                                  <div class="mt-2">
                                    {{ this.yes_time_interval
                                    }}{{ this.yes_time_state }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  class="blue-tag3"
                                  :style="
                                    this.currentEventYes === '開啟信件未點擊' ||
                                    this.currentEventYes === '開啟信件未轉換' ||
                                    this.currentEventYes === '點擊連結未轉換'
                                      ? 'padding-top:4px'
                                      : ''
                                  "
                                  @click="updateYesEventTimeInterval"
                                >
                                  <img
                                    src="../assets/openmail.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="
                                      this.currentEventYes.includes('開啟信件')
                                    "
                                  />
                                  <img
                                    src="../assets/clicklink.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="
                                      this.currentEventYes.includes('點擊連結')
                                    "
                                  />
                                  <img
                                    src="../assets/change.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="this.currentEventYes === '轉換'"
                                  />
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 3px;
                                      color: white;
                                    "
                                    v-if="
                                      this.currentEventYes ===
                                        '開啟信件未點擊' ||
                                      this.currentEventYes ===
                                        '開啟信件未轉換' ||
                                      this.currentEventYes === '點擊連結未轉換'
                                    "
                                  >
                                    {{ this.currentEventYes.slice(0, -3) }}
                                    <br />
                                    <span style="font-size: 12px">{{
                                      this.currentEventYes.slice(-3)
                                    }}</span>
                                  </div>
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 5px;
                                      color: white;
                                    "
                                    v-else
                                  >
                                    {{ this.currentEventYes }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div class="d-flex justify-content-center">
                                  <hr
                                    class="m-0"
                                    style="
                                      border: none;
                                      height: 0;
                                      border-top: 2px solid #71afb6;
                                      width: 50%;
                                      opacity: 1;
                                    "
                                  />
                                </div>
                                <div class="d-flex">
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtYesYes($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="yes-tag">是</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.yesyes_time_after != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtYesYes($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_yesyes,
                                              '_1_1',
                                              've_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_yesyes_triggerCron ===
                                                '' &&
                                              this.cycleTime_yesyes != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ yesyes_time_after }}
                                              {{ yesyes_time_after_state }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_yesyes }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtYesYes($event)
                                          "
                                          v-if="this.yesyes_action != ''"
                                        >
                                          <div
                                            class="action-tag2"
                                            @click="updateYesYesAction"
                                          >
                                            <img
                                              src="../assets/sendmail.svg"
                                              class="mb-2"
                                              alt=""
                                            />
                                            <span
                                              style="
                                                position: relative;
                                                left: 2px;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ yesyes_action }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            v-if="this.yesyes_template != ''"
                                          >
                                            <div
                                              style="
                                                position: relative;
                                                width: 105px;
                                                margin: 0 auto;
                                              "
                                            >
                                              <div
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 1px;
                                                  z-index: 99;
                                                "
                                                v-if="
                                                  this.yesyes_subject === ''
                                                "
                                              >
                                                <img
                                                  src="../assets/redalert.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                class="template-tag"
                                                style="color: #71afb6"
                                                v-tooltip.bottom="{
                                                  value: yesyes_template,
                                                  class: 'tip-LabelManage',
                                                }"
                                                @click="
                                                  showexp(
                                                    've_',
                                                    '_1_1',
                                                    true,
                                                    this.yesyes_subject
                                                  )
                                                "
                                              >
                                                {{ this.yesyes_template }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtYesNo($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="no-tag">否</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.yesno_time_after != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtYesNo($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_yesno,
                                              '_1_2',
                                              've_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_yesno_triggerCron ===
                                                '' &&
                                              this.cycleTime_yesno != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ yesno_time_after }}
                                              {{ yesno_time_after_state }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_yesno }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtYesNo($event)
                                          "
                                          v-if="this.yesno_action != ''"
                                        >
                                          <div
                                            class="action-tag2"
                                            @click="updateYesNoAction"
                                          >
                                            <img
                                              src="../assets/sendmail.svg"
                                              class="mb-2"
                                              alt=""
                                            />
                                            <span
                                              style="
                                                position: relative;
                                                left: 2px;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ yesno_action }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            v-if="this.yesno_template != ''"
                                          >
                                            <div
                                              style="
                                                position: relative;
                                                width: 105px;
                                                margin: 0 auto;
                                              "
                                            >
                                              <div
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 1px;
                                                  z-index: 99;
                                                "
                                                v-if="this.yesno_subject === ''"
                                              >
                                                <img
                                                  src="../assets/redalert.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                class="template-tag"
                                                style="color: #71afb6"
                                                v-tooltip.bottom="{
                                                  value: yesno_template,
                                                  class: 'tip-LabelManage',
                                                }"
                                                @click="
                                                  showexp(
                                                    've_',
                                                    '_1_2',
                                                    true,
                                                    this.yesno_subject
                                                  )
                                                "
                                              >
                                                {{ this.yesno_template }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- NO -->
                    <div
                      class="col-6"
                      style="background-color: white; height: 400px"
                      @dragover="allowDrop"
                      @drop.stop="dropTimeAtNo($event)"
                    >
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="no-tag">否</div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div v-if="no_time_after != ''">
                        <div
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropActionAtNo($event)"
                        >
                          <div
                            class="time-tag"
                            style="position: relative"
                            @click="
                              showCycleTimeDetail(
                                this.cycleTime_no,
                                '_2',
                                've_'
                              )
                            "
                          >
                            <img
                              src="../assets/redalert.svg"
                              alt=""
                              style="position: absolute; top: -13px; right: 1px"
                              v-if="
                                this.ader_no_triggerCron === '' &&
                                this.cycleTime_no != '一次性'
                              "
                            />
                            <img
                              src="../assets/clock.svg"
                              alt=""
                              style="width: 40px"
                            />
                            <div class="mt-1">
                              <p class="m-0" style="font-size: 14px">
                                {{ no_time_after }}
                                {{ no_time_after_state }}
                              </p>
                              <p class="m-0" style="font-size: 12px">
                                {{ cycleTime_no }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            style="background-color: white; height: 400px"
                            @dragover="allowDrop"
                            @drop.stop="dropTemplateAtNo($event)"
                            v-if="no_action != ''"
                          >
                            <div class="action-tag2" @click="updateNoAction">
                              <img
                                src="../assets/sendmail.svg"
                                class="mb-2"
                                alt=""
                              />
                              <span
                                style="
                                  position: relative;
                                  left: 2px;
                                  font-size: 14px;
                                "
                              >
                                {{ no_action }}
                              </span>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img src="../assets/treeline.svg" alt="" />
                            </div>
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropEventAtNo($event)"
                              v-if="no_template != ''"
                            >
                              <div
                                style="
                                  position: relative;
                                  width: 105px;
                                  margin: 0 auto;
                                "
                              >
                                <div
                                  style="
                                    position: absolute;
                                    top: -13px;
                                    right: 1px;
                                    z-index: 99;
                                  "
                                  v-if="this.no_subject === ''"
                                >
                                  <img src="../assets/redalert.svg" alt="" />
                                </div>
                                <div
                                  class="template-tag"
                                  style="color: #71afb6"
                                  v-tooltip.bottom="{
                                    value: no_template,
                                    class: 'tip-LabelManage',
                                  }"
                                  @click="
                                    showexp('ve_', '_2', true, this.no_subject)
                                  "
                                >
                                  {{ no_template }}
                                </div>
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <!-- UnderNo -->
                              <div
                                style="
                                  background-color: white;
                                  height: 400px;
                                  width: 300px;
                                "
                                v-if="this.currentEventNo != null"
                              >
                                <div
                                  class="blue-tag"
                                  @click="updateNoEventTimeInterval"
                                >
                                  <img
                                    src="../assets/hourglass.svg"
                                    style="width: 31px"
                                    alt=""
                                  />
                                  <div class="mt-2">
                                    {{ this.no_time_interval
                                    }}{{ this.no_time_state }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  class="blue-tag3"
                                  :style="
                                    this.currentEventNo === '開啟信件未點擊' ||
                                    this.currentEventNo === '開啟信件未轉換' ||
                                    this.currentEventNo === '點擊連結未轉換'
                                      ? 'padding-top:4px'
                                      : ''
                                  "
                                  @click="updateNoEventTimeInterval"
                                >
                                  <img
                                    src="../assets/openmail.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="
                                      this.currentEventNo.includes('開啟信件')
                                    "
                                  />
                                  <img
                                    src="../assets/clicklink.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="
                                      this.currentEventNo.includes('點擊連結')
                                    "
                                  />
                                  <img
                                    src="../assets/change.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="this.currentEventNo === '轉換'"
                                  />
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 3px;
                                      color: white;
                                    "
                                    v-if="
                                      this.currentEventNo ===
                                        '開啟信件未點擊' ||
                                      this.currentEventNo ===
                                        '開啟信件未轉換' ||
                                      this.currentEventNo === '點擊連結未轉換'
                                    "
                                  >
                                    {{ this.currentEventNo.slice(0, -3) }}
                                    <br />
                                    <span style="font-size: 12px">{{
                                      this.currentEventNo.slice(-3)
                                    }}</span>
                                  </div>
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 5px;
                                      color: white;
                                    "
                                    v-else
                                  >
                                    {{ this.currentEventNo }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div class="d-flex justify-content-center">
                                  <hr
                                    class="m-0"
                                    style="
                                      border: none;
                                      height: 0;
                                      border-top: 2px solid #71afb6;
                                      width: 50%;
                                      opacity: 1;
                                    "
                                  />
                                </div>
                                <div class="d-flex">
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtNoYes($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="yes-tag">是</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.noyes_time_after != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtNoYes($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_noyes,
                                              '_2_1',
                                              've_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_noyes_triggerCron ===
                                                '' &&
                                              this.cycleTime_noyes != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ noyes_time_after }}
                                              {{ noyes_time_after_state }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_noyes }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtNoYes($event)
                                          "
                                          v-if="this.noyes_action != ''"
                                        >
                                          <div
                                            class="action-tag2"
                                            @click="updateNoYesAction"
                                          >
                                            <img
                                              src="../assets/sendmail.svg"
                                              class="mb-2"
                                              alt=""
                                            />
                                            <span
                                              style="
                                                position: relative;
                                                left: 2px;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ noyes_action }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropEventAtNoYes($event)
                                            "
                                            v-if="this.noyes_template != ''"
                                          >
                                            <div
                                              style="
                                                position: relative;
                                                width: 105px;
                                                margin: 0 auto;
                                              "
                                            >
                                              <div
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 1px;
                                                  z-index: 99;
                                                "
                                                v-if="this.noyes_subject === ''"
                                              >
                                                <img
                                                  src="../assets/redalert.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                class="template-tag"
                                                style="color: #71afb6"
                                                v-tooltip.bottom="{
                                                  value: noyes_template,
                                                  class: 'tip-LabelManage',
                                                }"
                                                @click="
                                                  showexp(
                                                    've_',
                                                    '_2_1',
                                                    true,
                                                    this.noyes_subject
                                                  )
                                                "
                                              >
                                                {{ this.noyes_template }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtNoNo($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="no-tag">否</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.nono_time_after != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtNoNo($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_nono,
                                              '_2_2',
                                              've_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_nono_triggerCron ===
                                                '' &&
                                              this.cycleTime_nono != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ nono_time_after }}
                                              {{ nono_time_after_state }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_nono }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtNoNo($event)
                                          "
                                          v-if="this.nono_action != ''"
                                        >
                                          <div
                                            class="action-tag2"
                                            @click="updateNoNoAction"
                                          >
                                            <img
                                              src="../assets/sendmail.svg"
                                              class="mb-2"
                                              alt=""
                                            />
                                            <span
                                              style="
                                                position: relative;
                                                left: 2px;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ nono_action }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            @dragover="allowDrop"
                                            @drop.stop="dropEventAtNoNo($event)"
                                            v-if="this.nono_template != ''"
                                          >
                                            <div
                                              style="
                                                position: relative;
                                                width: 105px;
                                                margin: 0 auto;
                                              "
                                            >
                                              <div
                                                style="
                                                  position: absolute;
                                                  top: -13px;
                                                  right: 1px;
                                                  z-index: 99;
                                                "
                                                v-if="this.nono_subject === ''"
                                              >
                                                <img
                                                  src="../assets/redalert.svg"
                                                  alt=""
                                                />
                                              </div>
                                              <div
                                                class="template-tag"
                                                style="color: #71afb6"
                                                v-tooltip.bottom="{
                                                  value: nono_template,
                                                  class: 'titip-LabelManagep',
                                                }"
                                                @click="
                                                  showexp(
                                                    've_',
                                                    '_2_2',
                                                    true,
                                                    this.nono_subject
                                                  )
                                                "
                                              >
                                                {{ this.nono_template }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-6"
              style="height: 300px"
              @drop.stop="dropTemplateAtSms"
            >
              <div
                class="template-tag"
                style="color: #71afb6"
                v-tooltip.bottom="{
                  value: template_sms,
                  class: 'tip-LabelManage',
                }"
                v-if="this.template_sms != ''"
                @click="showexp('vs_', '_0', false, '')"
              >
                {{ template_sms }}
              </div>
              <div
                class="d-flex justify-content-center"
                v-if="this.template_sms != ''"
              >
                <img src="../assets/treeline.svg" alt="" />
              </div>
              <div
                class="col-12"
                style="height: 300px"
                @dragover="allowDrop"
                @drop.stop="dropEvent2"
                v-if="this.template_sms != ''"
              >
                <div
                  style="background-color: white"
                  class="tree-div text-center"
                  v-if="this.currentEvent2 != null"
                >
                  <div class="blue-tag" @click="updateEventTimeInterval2">
                    <img
                      src="../assets/hourglass.svg"
                      style="width: 31px"
                      alt=""
                    />
                    <div class="mt-2">
                      {{ this.time_interval2 }}{{ this.time_state2 }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div
                    class="blue-tag3"
                    :style="
                      this.currentEvent2 === '點擊連結未轉換'
                        ? 'padding-top:4px'
                        : ''
                    "
                    @click="updateEventTimeInterval2"
                  >
                    <img
                      src="../assets/clicklink.svg"
                      style="width: 40px"
                      alt=""
                      v-if="this.currentEvent2.includes('點擊連結')"
                    />
                    <img
                      src="../assets/change.svg"
                      style="width: 40px"
                      alt=""
                      v-if="this.currentEvent2 === '轉換'"
                    />
                    <div
                      style="
                        width: 100px;
                        position: relative;
                        left: -30px;
                        top: 3px;
                        color: white;
                      "
                      v-if="this.currentEvent2 === '點擊連結未轉換'"
                    >
                      {{ this.currentEvent2.slice(0, -3) }}
                      <br />
                      <span style="font-size: 12px">{{
                        this.currentEvent2.slice(-3)
                      }}</span>
                    </div>
                    <div
                      style="
                        width: 100px;
                        position: relative;
                        left: -30px;
                        top: 5px;
                        color: white;
                      "
                      v-else
                    >
                      {{ this.currentEvent2 }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <img src="../assets/treeline.svg" alt="" />
                  </div>
                  <div class="d-flex justify-content-center">
                    <hr
                      class="m-0"
                      style="
                        border: none;
                        height: 0;
                        border-top: 2px solid #71afb6;
                        width: 50%;
                        opacity: 1;
                      "
                    />
                  </div>

                  <div class="d-flex">
                    <!-- Yes -->

                    <div
                      class="col-6"
                      style="background-color: white; height: 400px"
                      @dragover="allowDrop"
                      @drop.stop="dropTimeAtYes2($event)"
                    >
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="yes-tag">是</div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div v-if="yes_time_after2 != ''">
                        <div
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropActionAtYes2($event)"
                        >
                          <div
                            class="time-tag"
                            style="position: relative"
                            @click="
                              showCycleTimeDetail(
                                this.cycleTime_yes2,
                                '_1',
                                'vs_'
                              )
                            "
                          >
                            <img
                              src="../assets/redalert.svg"
                              alt=""
                              style="position: absolute; top: -13px; right: 1px"
                              v-if="
                                this.ader_yes_triggerCron2 === '' &&
                                this.cycleTime_yes2 != '一次性'
                              "
                            />
                            <img
                              src="../assets/clock.svg"
                              alt=""
                              style="width: 40px"
                            />
                            <div class="mt-1">
                              <p class="m-0" style="font-size: 14px">
                                {{ yes_time_after2 }}
                                {{ yes_time_after_state2 }}
                              </p>
                              <p class="m-0" style="font-size: 12px">
                                {{ cycleTime_yes2 }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            style="background-color: white; height: 400px"
                            @dragover="allowDrop"
                            @drop.stop="dropTemplateAtYes2($event)"
                            v-if="yes_action2 != ''"
                          >
                            <div
                              class="action-tag3"
                              style="position: relative; left: 2px"
                              @click="updateYesAction2"
                            >
                              <img
                                src="../assets/sendsms.svg"
                                style="position: relative; left: 2px"
                                alt=""
                              />
                              <br />
                              <span style="position: relative; font-size: 14px">
                                {{ yes_action2 }}
                              </span>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img src="../assets/treeline.svg" alt="" />
                            </div>
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropEventAtYes2($event)"
                              v-if="yes_template2 != ''"
                            >
                              <div
                                class="template-tag"
                                style="color: #71afb6"
                                v-tooltip.bottom="{
                                  value: yes_template2,
                                  class: 'tip-LabelManage',
                                }"
                                @click="showexp('vs_', '_1', false, '')"
                              >
                                {{ yes_template2 }}
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <!-- UnderYes -->
                              <div
                                style="
                                  background-color: white;
                                  height: 400px;
                                  width: 300px;
                                "
                                v-if="this.currentEventYes2 != null"
                              >
                                <div
                                  class="blue-tag"
                                  @click="updateYesEventTimeInterval2"
                                >
                                  <img
                                    src="../assets/hourglass.svg"
                                    style="width: 31px"
                                    alt=""
                                  />
                                  <div class="mt-2">
                                    {{ this.yes_time_interval2
                                    }}{{ this.yes_time_state2 }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  class="blue-tag3"
                                  :style="
                                    this.currentEventYes2 === '點擊連結未轉換'
                                      ? 'padding-top:4px'
                                      : ''
                                  "
                                  @click="updateYesEventTimeInterval2"
                                >
                                  <img
                                    src="../assets/clicklink.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="
                                      this.currentEventYes2.includes('點擊連結')
                                    "
                                  />
                                  <img
                                    src="../assets/change.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="this.currentEventYes2 === '轉換'"
                                  />
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 3px;
                                      color: white;
                                    "
                                    v-if="
                                      this.currentEventYes2 === '點擊連結未轉換'
                                    "
                                  >
                                    {{ this.currentEventYes2.slice(0, -3) }}
                                    <br />
                                    <span style="font-size: 12px">{{
                                      this.currentEventYes2.slice(-3)
                                    }}</span>
                                  </div>
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 5px;
                                      color: white;
                                    "
                                    v-else
                                  >
                                    {{ this.currentEventYes2 }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div class="d-flex justify-content-center">
                                  <hr
                                    class="m-0"
                                    style="
                                      border: none;
                                      height: 0;
                                      border-top: 2px solid #71afb6;
                                      width: 50%;
                                      opacity: 1;
                                    "
                                  />
                                </div>
                                <div class="d-flex">
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtYesYes2($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="yes-tag">是</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.yesyes_time_after2 != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtYesYes2($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_yesyes2,
                                              '_1_1',
                                              'vs_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_yesyes_triggerCron2 ===
                                                '' &&
                                              this.cycleTime_yesyes2 != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ yesyes_time_after2 }}
                                              {{ yesyes_time_after_state2 }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_yesyes2 }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtYesYes2($event)
                                          "
                                          v-if="this.yesyes_action2 != ''"
                                        >
                                          <div
                                            class="action-tag3"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            @click="updateYesYesAction2"
                                          >
                                            <img
                                              src="../assets/sendsms.svg"
                                              style="
                                                position: relative;
                                                left: 2px;
                                              "
                                              alt=""
                                            />
                                            <br />
                                            <span
                                              style="
                                                position: relative;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ yesyes_action2 }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            v-if="this.yesyes_template2 != ''"
                                          >
                                            <div
                                              class="template-tag"
                                              style="color: #71afb6"
                                              v-tooltip.bottom="{
                                                value: yesyes_template2,
                                                class: 'tip-LabelManage',
                                              }"
                                              @click="
                                                showexp(
                                                  'vs_',
                                                  '_1_1',
                                                  false,
                                                  ''
                                                )
                                              "
                                            >
                                              {{ this.yesyes_template2 }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtYesNo2($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="no-tag">否</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.yesno_time_after2 != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtYesNo2($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_yesno2,
                                              '_1_2',
                                              'vs_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_yesno_triggerCron2 ===
                                                '' &&
                                              this.cycleTime_yesno2 != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ yesno_time_after2 }}
                                              {{ yesno_time_after_state2 }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_yesno2 }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtYesNo2($event)
                                          "
                                          v-if="this.yesno_action2 != ''"
                                        >
                                          <div
                                            class="action-tag3"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            @click="updateYesNoAction2"
                                          >
                                            <img
                                              src="../assets/sendsms.svg"
                                              style="
                                                position: relative;
                                                left: 2px;
                                              "
                                              alt=""
                                            />
                                            <br />
                                            <span
                                              style="
                                                position: relative;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ yesno_action2 }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            v-if="this.yesno_template2 != ''"
                                          >
                                            <div
                                              class="template-tag"
                                              style="color: #71afb6"
                                              v-tooltip.bottom="{
                                                value: yesno_template2,
                                                class: 'tip-LabelManage',
                                              }"
                                              @click="
                                                showexp(
                                                  'vs_',
                                                  '_1_2',
                                                  false,
                                                  ''
                                                )
                                              "
                                            >
                                              {{ this.yesno_template2 }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- NO -->
                    <div
                      class="col-6"
                      style="background-color: white; height: 400px"
                      @dragover="allowDrop"
                      @drop.stop="dropTimeAtNo2($event)"
                    >
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div class="no-tag">否</div>
                      <div class="d-flex justify-content-center">
                        <img src="../assets/treeline.svg" alt="" />
                      </div>
                      <div v-if="no_time_after2 != ''">
                        <div
                          style="background-color: white; height: 400px"
                          @dragover="allowDrop"
                          @drop.stop="dropActionAtNo2($event)"
                        >
                          <div
                            class="time-tag"
                            style="position: relative"
                            @click="
                              showCycleTimeDetail(
                                this.cycleTime_no2,
                                '_2',
                                'vs_'
                              )
                            "
                          >
                            <img
                              src="../assets/redalert.svg"
                              alt=""
                              style="position: absolute; top: -13px; right: 1px"
                              v-if="
                                this.ader_no_triggerCron2 === '' &&
                                this.cycleTime_no2 != '一次性'
                              "
                            />
                            <img
                              src="../assets/clock.svg"
                              alt=""
                              style="width: 40px"
                            />
                            <div class="mt-1">
                              <p class="m-0" style="font-size: 14px">
                                {{ no_time_after2 }}
                                {{ no_time_after_state2 }}
                              </p>
                              <p class="m-0" style="font-size: 12px">
                                {{ cycleTime_no2 }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <img src="../assets/treeline.svg" alt="" />
                          </div>
                          <div
                            style="background-color: white; height: 400px"
                            @dragover="allowDrop"
                            @drop.stop="dropTemplateAtNo2($event)"
                            v-if="no_action2 != ''"
                          >
                            <div class="action-tag2" @click="updateNoAction2">
                              <img
                                src="../assets/sendmail.svg"
                                class="mb-2"
                                alt=""
                              />
                              <span
                                style="
                                  position: relative;
                                  left: 2px;
                                  font-size: 14px;
                                "
                              >
                                {{ no_action2 }}
                              </span>
                            </div>
                            <div class="d-flex justify-content-center">
                              <img src="../assets/treeline.svg" alt="" />
                            </div>
                            <div
                              style="background-color: white; height: 400px"
                              @dragover="allowDrop"
                              @drop.stop="dropEventAtNo2($event)"
                              v-if="no_template2 != ''"
                            >
                              <div
                                class="template-tag"
                                style="color: #71afb6"
                                v-tooltip.bottom="{
                                  value: no_template2,
                                  class: 'tip-LabelManage',
                                }"
                                @click="showexp('vs_', '_2', false, '')"
                              >
                                {{ no_template2 }}
                              </div>
                              <div class="d-flex justify-content-center">
                                <img src="../assets/treeline.svg" alt="" />
                              </div>
                              <!-- UnderNo -->
                              <div
                                style="
                                  background-color: white;
                                  height: 400px;
                                  width: 300px;
                                "
                                v-if="this.currentEventNo2 != null"
                              >
                                <div
                                  class="blue-tag"
                                  @click="updateNoEventTimeInterval2"
                                >
                                  <img
                                    src="../assets/hourglass.svg"
                                    style="width: 31px"
                                    alt=""
                                  />
                                  <div class="mt-2">
                                    {{ this.no_time_interval2
                                    }}{{ this.no_time_state2 }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div
                                  class="blue-tag3"
                                  :style="
                                    this.currentEventNo2 === '點擊連結未轉換'
                                      ? 'padding-top:4px'
                                      : ''
                                  "
                                  @click="updateYesEventTimeInterval2"
                                >
                                  <img
                                    src="../assets/clicklink.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="
                                      this.currentEventNo2.includes('點擊連結')
                                    "
                                  />
                                  <img
                                    src="../assets/change.svg"
                                    style="width: 40px"
                                    alt=""
                                    v-if="this.currentEventNo2 === '轉換'"
                                  />
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 3px;
                                      color: white;
                                    "
                                    v-if="
                                      this.currentEventNo2 === '點擊連結未轉換'
                                    "
                                  >
                                    {{ this.currentEventNo2.slice(0, -3) }}
                                    <br />
                                    <span style="font-size: 12px">{{
                                      this.currentEventNo2.slice(-3)
                                    }}</span>
                                  </div>
                                  <div
                                    style="
                                      width: 100px;
                                      position: relative;
                                      left: -30px;
                                      top: 5px;
                                      color: white;
                                    "
                                    v-else
                                  >
                                    {{ this.currentEventNo2 }}
                                  </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <img src="../assets/treeline.svg" alt="" />
                                </div>
                                <div class="d-flex justify-content-center">
                                  <hr
                                    class="m-0"
                                    style="
                                      border: none;
                                      height: 0;
                                      border-top: 2px solid #71afb6;
                                      width: 50%;
                                      opacity: 1;
                                    "
                                  />
                                </div>
                                <div class="d-flex">
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtNoYes2($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="yes-tag">是</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.noyes_time_after2 != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtNoYes2($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_noyes2,
                                              '_2_1',
                                              'vs_'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_noyes_triggerCron2 ===
                                                '' &&
                                              this.cycleTime_noyes2 != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ noyes_time_after2 }}
                                              {{ noyes_time_after_state2 }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_noyes2 }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtNoYes2($event)
                                          "
                                          v-if="this.noyes_action2 != ''"
                                        >
                                          <div
                                            class="action-tag3"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            @click="updateNoYesAction2"
                                          >
                                            <img
                                              src="../assets/sendsms.svg"
                                              style="
                                                position: relative;
                                                left: 2px;
                                              "
                                              alt=""
                                            />
                                            <br />
                                            <span
                                              style="
                                                position: relative;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ noyes_action2 }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropEventAtNoYes2($event)
                                            "
                                            v-if="this.noyes_template2 != ''"
                                          >
                                            <div
                                              class="template-tag"
                                              style="color: #71afb6"
                                              v-tooltip.bottom="{
                                                value: noyes_template2,
                                                class: 'tip-LabelManage',
                                              }"
                                              @click="
                                                showexp(
                                                  'vs_',
                                                  '_2_1',
                                                  false,
                                                  ''
                                                )
                                              "
                                            >
                                              {{ this.noyes_template2 }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-6"
                                    style="
                                      background-color: white;
                                      height: 400px;
                                    "
                                    @dragover="allowDrop"
                                    @drop.stop="dropTimeAtNoNo2($event)"
                                  >
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="no-tag">否</div>
                                    <div class="d-flex justify-content-center">
                                      <img
                                        src="../assets/treeline.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div v-if="this.nono_time_after2 != ''">
                                      <div
                                        style="
                                          background-color: white;
                                          height: 400px;
                                        "
                                        @dragover="allowDrop"
                                        @drop.stop="dropActionAtNoNo2($event)"
                                      >
                                        <div
                                          style="position: relative"
                                          class="time-tag"
                                          @click="
                                            showCycleTimeDetail(
                                              this.cycleTime_nono2,
                                              '_2_2',
                                              'vs'
                                            )
                                          "
                                        >
                                          <img
                                            src="../assets/redalert.svg"
                                            alt=""
                                            style="
                                              position: absolute;
                                              top: -13px;
                                              right: 1px;
                                            "
                                            v-if="
                                              this.ader_nono_triggerCron2 ===
                                                '' &&
                                              this.cycleTime_nono2 != '一次性'
                                            "
                                          />
                                          <img
                                            src="../assets/clock.svg"
                                            alt=""
                                            style="width: 40px"
                                          />
                                          <div class="mt-1">
                                            <p
                                              class="m-0"
                                              style="font-size: 14px"
                                            >
                                              {{ nono_time_after2 }}
                                              {{ nono_time_after_state2 }}
                                            </p>
                                            <p
                                              class="m-0"
                                              style="font-size: 12px"
                                            >
                                              {{ cycleTime_nono2 }}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            src="../assets/treeline.svg"
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          style="
                                            background-color: white;
                                            height: 400px;
                                          "
                                          @dragover="allowDrop"
                                          @drop.stop="
                                            dropTemplateAtNoNo2($event)
                                          "
                                          v-if="this.nono_action2 != ''"
                                        >
                                          <div
                                            class="action-tag3"
                                            style="
                                              position: relative;
                                              left: 2px;
                                            "
                                            @click="updateNoNoAction2"
                                          >
                                            <img
                                              src="../assets/sendsms.svg"
                                              style="
                                                position: relative;
                                                left: 2px;
                                              "
                                              alt=""
                                            />
                                            <br />
                                            <span
                                              style="
                                                position: relative;
                                                font-size: 14px;
                                              "
                                            >
                                              {{ nono_action2 }}
                                            </span>
                                          </div>
                                          <div
                                            class="
                                              d-flex
                                              justify-content-center
                                            "
                                          >
                                            <img
                                              src="../assets/treeline.svg"
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style="background-color: white"
                                            @dragover="allowDrop"
                                            @drop.stop="
                                              dropEventAtNoNo2($event)
                                            "
                                            v-if="this.nono_template2 != ''"
                                          >
                                            <div
                                              class="template-tag"
                                              style="color: #71afb6"
                                              v-tooltip.bottom="{
                                                value: nono_template2,
                                                class: 'tip-LabelManage',
                                              }"
                                              @click="
                                                showexp(
                                                  'vs_',
                                                  '_2_2',
                                                  false,
                                                  ''
                                                )
                                              "
                                            >
                                              {{ this.nono_template2 }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi2 } from "../../public/static2/api/apigClient.js";
import TimeSettingModalDay from "../components/TriggerScriptPerformance/TimeSettingModalDay.vue";
import TimeSettingModalWeek from "../components/TriggerScriptPerformance/TimeSettingModalWeek.vue";
import TimeSettingModalMonth from "../components/TriggerScriptPerformance/TimeSettingModalMonth.vue";
import TimeSettingModalYear from "../components/TriggerScriptPerformance/TimeSettingModalYear.vue";
import { handleError } from "@vue/runtime-core";

export default {
  name: "SeeTriggerScript",
  props: ["scriptID", "groupName", "addadd"],
  components: {
    TimeSettingModalDay,
    TimeSettingModalWeek,
    TimeSettingModalMonth,
    TimeSettingModalYear,
  },
  data() {
    return {
      cycleTimeState: "",
      cycleTime_setting: {},

      forceRerender: 0,
      tree: [],
      triggerApiData: {},

      scriptHasStart: false,
      loading_copy: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },

      errorDrop: "",
      scriptTipShow: false,
      triggerBuy: {},
      cycleDayStart: null,
      scriptDayStart: "",
      scriptDayEnd: "",
      scriptDayStart_sure: "",
      scriptDayEnd_sure: "",
      scriptDayState: "",
      scriptDayState_sure: "",

      event_moreoption: false,
      event_moreoption_Selected: "",
      tagValue: [],
      tagSelected: "-",
      tagValueSelected: "-",

      triggerCron: {},
      cycleCron: "",

      exp_cycleTime_first: "",
      exp_cycleTime_second: "",
      exp_cycleTime_first_ader: "",
      exp_cycleTime_second_ader: "",

      ader_triggerCron: "",
      ader_yes_triggerCron: "",
      ader_yesyes_triggerCron: "",
      ader_yesno_triggerCron: "",
      ader_no_triggerCron: "",
      ader_noyes_triggerCron: "",
      ader_nono_triggerCron: "",
      ader_yes_triggerCron2: "",
      ader_yesyes_triggerCron2: "",
      ader_yesno_triggerCron2: "",
      ader_no_triggerCron2: "",
      ader_noyes_triggerCron2: "",
      ader_nono_triggerCron2: "",

      yes_triggerCron: {},
      yesyes_triggerCron: {},
      yesno_triggerCron: {},
      no_triggerCron: {},
      noyes_triggerCron: {},
      nono_triggerCron: {},
      yes_triggerCron2: {},
      yesyes_triggerCron2: {},
      yesno_triggerCron2: {},
      no_triggerCron2: {},
      noyes_triggerCron2: {},
      nono_triggerCron2: {},

      endCount2: "",
      time_every: 0,
      ader_daytime: "",
      ader_endCount: "",
      aderTestCron: "",

      yearBasedWith: "日期",
      picked: "一次性",
      picked2: "",
      picked3: "一次性",
      picked4: "定期投放",
      happenMonth_moreoption: false,
      happenTime: "月",
      happenDaySelector: [],
      happenYearSelector: [],
      happenMonthSelector: [],
      happenMonthSelector2: [],
      happenYearMonthSelector: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],

      happenDay_Selected: "",
      happenWeek_Selected: "",
      happenWeek2_Selected: "",
      happenMonth_Selected: "",
      happenMonth2_Selected: "",
      happenMonth3_Selected: "",
      happenMonth4_Selected: "",
      happenMonth5_Selected: "",
      happenYear_Selected: "",
      happenYear2_Selected: "",
      happenYear3_Selected: "",
      happenYear4_Selected: "",
      happenYear5_Selected: "",

      cycleTime_trigger: "",
      cycleTimeinCycle: "",
      cycleTime_yes: "一次性",
      cycleTime_yesyes: "一次性",
      cycleTime_yesno: "一次性",
      cycleTime_no: "一次性",
      cycleTime_noyes: "一次性",
      cycleTime_nono: "一次性",
      cycleTime_yes2: "一次性",
      cycleTime_yesyes2: "一次性",
      cycleTime_yesno2: "一次性",
      cycleTime_no2: "一次性",
      cycleTime_noyes2: "一次性",
      cycleTime_nono2: "一次性",

      endtime: "",
      z: 1,
      zoom: "",
      resSubsec: "",
      selectedLabels: [],
      // selectCustomGroup: "價值分群",

      from: "",
      group: "",
      name: "",
      token: "",
      key: "",
      emailChannel: true,
      random_id: "",

      page: 1,
      perPage: 4,
      nowPage: 0,
      records: [],

      selectedgroupID: "",
      searchInput: "",
      script_title: "",
      selectGroup: "",
      selectEmail: "",
      selectSms: "",
      cantSignup: false,
      // grouptodos: [],
      // RFMtodos: [],
      // customtodos: [],
      // channeltodos: [],

      grabbing: false,
      fat: false,
      notshow: false,
      loading: false,

      testList: [],
      testList2: [],
      editList: false,

      yesindex: null,
      yesyesindex: null,
      yesnoindex: null,
      noindex: null,
      noyesindex: null,
      nonoindex: null,
      // for 2nd testlist
      yesindex2: null,
      yesyesindex2: null,
      yesnoindex2: null,
      noindex2: null,
      noyesindex2: null,
      nonoindex2: null,

      // data for template
      selectedID: "",
      i: 1,

      titleError2: false,
      titleErrMsg2: "",
      titleError: false,
      titleErrMsg: "",
      subError: false,
      subErrMsg: "",
      textError: false,
      textErrMsg: "",
      noBanner: false,

      newTodoTitle: "",
      smsTitle: "",
      newSub: "",
      newSubsec: "",

      fullurl: "",
      shortUrl: "",

      searchQuery: "",
      todos: [],
      emailtodos: [],
      smstodos: [],
      searchTodos: [],
      schedules: [],
      childtodo: [],
      editinput: false,
      beClick: true,

      isShow: false,
      isShow2: false,
      isShow3: false,
      isShowMoney: false,

      // Tab offcanvas data
      tabState: "event",
      updateWho: "",

      currentTrigger: null,
      triggerSelected: "",
      time_trigger: "",
      time_trigger_state: "",
      time_trigger_Selected: "",
      time_trigger_state_Selected: "",

      template_email: "",
      template_sms: "",

      eventSelected: "",
      currentEvent: null,
      currentEventYes: null,
      currentEventNo: null,
      time_interval_Selected: "",
      time_interval: "",
      yes_time_interval: "",
      no_time_interval: "",
      time_state_Selected: "",
      time_state: "",
      yes_time_state: "",
      no_time_state: "",

      time_after_Selected: "",
      yes_time_after: "",
      yesyes_time_after: "",
      yesno_time_after: "",
      no_time_after: "",
      noyes_time_after: "",
      nono_time_after: "",
      time_after_state_Selected: "",
      yes_time_after_state: "天後",
      yesyes_time_after_state: "天後",
      yesno_time_after_state: "天後",
      no_time_after_state: "天後",
      noyes_time_after_state: "天後",
      nono_time_after_state: "天後",

      action_Selected: "",
      yes_action: "",
      yesyes_action: "",
      yesno_action: "",
      no_action: "",
      noyes_action: "",
      nono_action: "",

      yes_template: "",
      yesyes_template: "",
      yesno_template: "",
      no_template: "",
      noyes_template: "",
      nono_template: "",

      // for 2nd testlist

      currentEvent2: null,
      currentEventYes2: null,
      currentEventNo2: null,
      time_interval2: "",
      yes_time_interval2: "",
      no_time_interval2: "",
      time_state2: "",
      yes_time_state2: "",
      no_time_state2: "",

      yes_time_after2: "",
      yesyes_time_after2: "",
      yesno_time_after2: "",
      no_time_after2: "",
      noyes_time_after2: "",
      nono_time_after2: "",
      yes_time_after_state2: "天後",
      yesyes_time_after_state2: "天後",
      yesno_time_after_state2: "天後",
      no_time_after_state2: "天後",
      noyes_time_after_state2: "天後",
      nono_time_after_state2: "天後",

      yes_action2: "",
      yesyes_action2: "",
      yesno_action2: "",
      no_action2: "",
      noyes_action2: "",
      nono_action2: "",

      yes_template2: "",
      yesyes_template2: "",
      yesno_template2: "",
      no_template2: "",
      noyes_template2: "",
      nono_template2: "",

      // subject
      email_subject: "",
      sms_subject: "",
      yes_subject: "",
      yesyes_subject: "",
      yesno_subject: "",
      no_subject: "",
      noyes_subject: "",
      nono_subject: "",
      yes_subject2: null,
      yesyes_subject2: null,
      yesno_subject2: null,
      no_subject2: null,
      noyes_subject2: null,
      nono_subject2: null,
      // 8081 url
      url8081: "",
      unlayerJson: "",
      eventName: "",
      eventNameError: "",
      eventNameErrMsg: "",
      newTempName: "",
      newTempNameError: "",
      newTempNameErrMsg: "",
      todojson: "",
      todohtml: "",
      html: "",
      newHtml: "",
      vice_id: "",
      Parr: [],
      fullurlArr: [],
      shortUrlArr: [],

      // sms template
      expSubsec: "",
      newText: "",
      urlok: true,
      smsModal: "",
      editScriptSms: false,
      offCanvasClick: "",
      unlayerModal: "",
      alertModal3: "",

      //  從sessionStorage裏的活動名稱等資料data
      eventNameFromSche: "",
      eventTitleFromSche: "",
      eventScheduleIdFromSche: "ReMA_15e78a6b",
      eventScriptIdFromSche: null,
      eventScriptHasEventFromSche: "0",

      // start event
      emailList: {},
      smsList: {},
      finalList: [],

      varName: "{name}",
      varLastName: "{lastname}",
      varFirstName: "{firstname}",
      triggerType: "顧客姓名",
      autoShow: false,

      origingrouptodos: {},
      countText: "",

      scriptTimeModal: {},
      goingToRunScript2: false,
      CycleTimeDetail: {},
    };
  },
  created() {},
  watch: {
    addadd: function (val) {
      console.log(val);
      this.ruleID = this.scriptID;
      this.getScriptById();
    },
    scriptID: function (val) {
      console.log(val);
      // this.getScriptById();
    },
    currentEventYes() {
      if (this.currentEventYes === null && this.currentEventNo === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventNo() {
      if (this.currentEventYes === null && this.currentEventNo === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventYes2() {
      if (this.currentEventYes2 === null && this.currentEventNo2 === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    currentEventNo2() {
      if (this.currentEventYes2 === null && this.currentEventNo2 === null) {
        this.fat = false;
      } else {
        this.fat = true;
      }
    },
    CycleTimeDetail: {
      handler: function () {
        console.log(this.CycleTimeDetail);
        if (this.CycleTimeDetail._isShown == false) {
          const backdrop = document.getElementById("CycleTimeDetail2-backdrop");
          backdrop.style["display"] = "none";
        }
      },
      deep: true,
    },
  },
  methods: {
    happenByhappenTime() {
      if (this.cycleTime_setting.time_setting.happenTime === "月") {
        return this.cycleTime_setting.time_setting.happenMonth;
      } else if (this.cycleTime_setting.time_setting.happenTime === "日") {
        return "每" + this.cycleTime_setting.time_setting.happenDay + "天";
      } else if (this.cycleTime_setting.time_setting.happenTime === "年") {
        return this.cycleTime_setting.time_setting.happenYear;
      } else if (this.cycleTime_setting.time_setting.happenTime === "週") {
        return this.cycleTime_setting.time_setting.happenWeek;
      }
    },

    getScriptById: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        script_id: this.$props.scriptID,
      };

      await allapi2
        .triggerScriptScriptIdGet_1(params)
        .then((res) => {
          console.log("成功根據排成獲得劇本內容", res);
          this.triggerBuy={
            tag:res.data.message.tag,
            value:res.data.message.value
          }
          this.tree = res.data.message.flows;
          // this.drawTree();
          this.eventScheduleIdFromSche = res.data.message.script_id;
          if (res.data.message.start) {
            this.scriptHasStart = true;
          }

          this.scriptDayStart_sure = res.data.message.start_time;
          this.scriptDayStart = res.data.message.start_time;

          if (res.data.message.end_time != "永久") {
            this.scriptDayEnd_sure = res.data.message.end_time;
            this.scriptDayEnd = res.data.message.end_time;
          } else {
            this.scriptDayEnd_sure = "";
            this.scriptDayEnd = "";
            this.scriptDayState_sure = "永久";
            this.scriptDayState = "永久";
          }

          res.data.message.flows.forEach((o) => {
            if (o.action === "寄信") {
              o.action = "傳送Email";
            }
            if (o.action === "寄簡訊") {
              o.action = "傳送SMS";
            }
            if (o.event === "購買行銷") {
              o.event = "購買後促銷";
            }
            if (o.event === "購買") {
              o.event = "訂單確認";
            }
            o.template_name = o.template;
            delete o.template;
          });

          this.testList2 = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) != "0" && d.vice_ruleID.charAt(1) === "e"
            );
          });
          this.testList = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) != "0" && d.vice_ruleID.charAt(1) === "s"
            );
          });
          this.emailList = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) === "0" &&
              d.vice_ruleID.charAt(1) === "e"
            );
          })[0];
          this.smsList = res.data.message.flows.filter((d) => {
            return (
              d.vice_ruleID.charAt(12) === "0" &&
              d.vice_ruleID.charAt(1) === "s"
            );
          })[0];
          this.loading = false;
          console.log("this.testList2", this.testList2);
          console.log("this.testList", this.testList);
          console.log("this.emailList", this.emailList);
          console.log("this.smsList", this.smsList);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("資料連線有誤" + err + "將返回劇本一覽表");
          this.$router.push({
            name: "ScriptTable",
          });
        });

      // this.testList2.forEach((o) => {
      //   return (o.template_name = o.template);
      // });
      // this.testList.forEach((o) => {
      //   return (o.template_name = o.template);
      // });
      // if (this.emailList != undefined) {
      //   this.emailList.template_name = this.emailList.template;
      //   delete this.emailList.template;
      // }
      // if (this.smsList != undefined) {
      //   this.smsList.template_name = this.smsList.template;
      //   delete this.smsList.template;
      // }
      console.log("this.testList", this.testList);

      this.testList2.map((d) => {
        if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 1 &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after = d.time_after / 24;
            this.yes_time_after_state = "天後";
          } else {
            this.yes_time_after = d.time_after;
            this.yes_time_after_state = "小時後";
          }
          this.yes_action = d.action;
          this.yes_subject = d.subject;
          this.yes_template = d.template_name;
          this.yes_triggerCron = d.time_setting;
          this.cycleTime_yes = d.time_setting.picked;
          this.ader_yes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 0 &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after = d.time_after / 24;
            this.no_time_after_state = "天後";
          } else {
            this.no_time_after = d.time_after;
            this.no_time_after_state = "小時後";
          }
          this.no_action = d.action;
          this.no_subject = d.subject;
          this.no_template = d.template_name;
          this.no_triggerCron = d.time_setting;
          this.cycleTime_no = d.time_setting.picked;
          this.ader_no_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_1_1") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after = d.time_after / 24;
            this.yesyes_time_after_state = "天後";
          } else {
            this.yesyes_time_after = d.time_after;
            this.yesyes_time_after_state = "小時後";
          }
          this.yesyes_action = d.action;
          this.yesyes_subject = d.subject;
          this.yesyes_template = d.template_name;
          this.yesyes_triggerCron = d.time_setting;
          this.cycleTime_yesyes = d.time_setting.picked;
          this.ader_yesyes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_1_2") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after = d.time_after / 24;
            this.yesno_time_after_state = "天後";
          } else {
            this.yesno_time_after = d.time_after;
            this.yesno_time_after_state = "小時後";
          }
          this.yesno_action = d.action;
          this.yesno_subject = d.subject;
          this.yesno_template = d.template_name;
          this.yesno_triggerCron = d.time_setting;
          this.cycleTime_yesno = d.time_setting.picked;
          this.ader_yesno_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_2_1") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after = d.time_after / 24;
            this.noyes_time_after_state = "天後";
          } else {
            this.noyes_time_after = d.time_after;
            this.noyes_time_after_state = "小時後";
          }
          this.noyes_action = d.action;
          this.noyes_subject = d.subject;
          this.noyes_template = d.template_name;
          this.noyes_triggerCron = d.time_setting;
          this.cycleTime_noyes = d.time_setting.picked;
          this.ader_noyes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_2_2") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after = d.time_after / 24;
            this.nono_time_after_state = "天後";
          } else {
            this.nono_time_after = d.time_after;
            this.nono_time_after_state = "小時後";
          }
          this.nono_action = d.action;
          this.nono_template = d.template_name;
          this.nono_subject = d.subject;
          this.nono_triggerCron = d.time_setting;
          this.cycleTime_nono = d.time_setting.picked;
          this.ader_nono_triggerCron = "ok";
        }
      });
      this.testList.map((d) => {
        if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 1 &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEvent2 = d.event;
          if (d.time_interval > 23) {
            this.time_interval2 = d.time_interval / 24;
            this.time_state2 = "天內";
          } else {
            this.time_interval2 = d.time_interval;
            this.time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after2 = d.time_after / 24;
            this.yes_time_after_state2 = "天後";
          } else {
            this.yes_time_after2 = d.time_after;
            this.yes_time_after_state2 = "小時後";
          }
          this.yes_action2 = d.action;
          this.yes_template2 = d.template_name;
          this.yes_triggerCron2 = d.time_setting;
          this.cycleTime_yes2 = d.time_setting.picked;
          this.ader_yes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.length === 13 &&
          d.is_yes === 0 &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEvent2 = d.event;
          if (d.time_interval > 23) {
            this.time_interval2 = d.time_interval / 24;
            this.time_state2 = "天內";
          } else {
            this.time_interval2 = d.time_interval;
            this.time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after2 = d.time_after / 24;
            this.no_time_after_state2 = "天後";
          } else {
            this.no_time_after2 = d.time_after;
            this.no_time_after_state2 = "小時後";
          }
          this.no_action2 = d.action;
          this.no_template2 = d.template_name;
          this.no_triggerCron2 = d.time_setting;
          this.cycleTime_no2 = d.time_setting.picked;
          this.ader_no_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_1_1") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventYes2 = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval2 = d.time_interval / 24;
            this.yes_time_state2 = "天內";
          } else {
            this.yes_time_interval2 = d.time_interval;
            this.yes_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after2 = d.time_after / 24;
            this.yesyes_time_after_state2 = "天後";
          } else {
            this.yesyes_time_after2 = d.time_after;
            this.yesyes_time_after_state2 = "小時後";
          }
          this.yesyes_action2 = d.action;
          this.yesyes_template2 = d.template_name;
          this.yesyes_triggerCron2 = d.time_setting;
          this.cycleTime_yesyes2 = d.time_setting.picked;
          this.ader_yesyes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_1_2") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventYes2 = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval2 = d.time_interval / 24;
            this.yes_time_state2 = "天內";
          } else {
            this.yes_time_interval2 = d.time_interval;
            this.yes_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after2 = d.time_after / 24;
            this.yesno_time_after_state2 = "天後";
          } else {
            this.yesno_time_after2 = d.time_after;
            this.yesno_time_after_state2 = "小時後";
          }
          this.yesno_action2 = d.action;
          this.yesno_template2 = d.template_name;
          this.yesno_triggerCron2 = d.time_setting;
          this.cycleTime_yesno2 = d.time_setting.picked;
          this.ader_yesno_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_2_1") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventNo2 = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval2 = d.time_interval / 24;
            this.no_time_state2 = "天內";
          } else {
            this.no_time_interval2 = d.time_interval;
            this.no_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after2 = d.time_after / 24;
            this.noyes_time_after_state2 = "天後";
          } else {
            this.noyes_time_after2 = d.time_after;
            this.noyes_time_after_state2 = "小時後";
          }
          this.noyes_action2 = d.action;
          this.noyes_template2 = d.template_name;
          this.noyes_triggerCron2 = d.time_setting;
          this.cycleTime_noyes2 = d.time_setting.picked;
          this.ader_noyes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_2_2") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventNo2 = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval2 = d.time_interval / 24;
            this.no_time_state2 = "天內";
          } else {
            this.no_time_interval2 = d.time_interval;
            this.no_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after2 = d.time_after / 24;
            this.nono_time_after_state2 = "天後";
          } else {
            this.nono_time_after2 = d.time_after;
            this.nono_time_after_state2 = "小時後";
          }
          this.nono_action2 = d.action;
          this.nono_template2 = d.template_name;
          this.nono_triggerCron2 = d.time_setting;
          this.cycleTime_nono2 = d.time_setting.picked;
          this.ader_nono_triggerCron2 = "ok";
        }
      });
      if (this.emailList != undefined) {
        this.currentTrigger = this.emailList.event;
        if (this.emailList.time_after > 23) {
          this.time_trigger = this.emailList.time_after / 24;
          this.time_trigger_state = "天後";
        } else if (this.emailList.time_after === 0) {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else {
          this.time_trigger = this.emailList.time_after;
          this.time_trigger_state = "小時後";
        }
        if (this.emailList.event === "購物車未結") {
          this.time_trigger = this.emailList.time_after / 24;
          this.time_trigger_state = "天內";
        }
        this.email_subject = this.emailList.subject;
        this.template_email = this.emailList.template_name;
        this.triggerCron = this.emailList.time_setting;
        this.ader_triggerCron = "ok";
        if (this.emailList.event != "定期投放") {
          this.cycleTime_trigger = this.emailList.time_setting.picked;
        } else {
          this.cycleTimeinCycle = this.emailList.time_setting.happenTime;
          this.cycleDayStart = this.emailList.time_setting.cycleDayStart;
        }
      }

      if (this.smsList != undefined) {
        this.currentTrigger = this.smsList.event;

        if (this.smsList.time_after > 23) {
          this.time_trigger = this.smsList.time_after / 24;
          this.time_trigger_state = "天後";
        } else if (this.smsList.time_after === 0) {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else {
          this.time_trigger = this.smsList.time_after;
          this.time_trigger_state = "小時後";
        }
        if (this.smsList.event === "購物車未結") {
          this.time_trigger = this.smsList.time_after / 24;
          this.time_trigger_state = "天內";
        }
        this.sms_subject = this.smsList.subject;
        this.template_sms = this.smsList.template_name;
        this.triggerCron = this.smsList.time_setting;
        this.ader_triggerCron = "ok";
        if (this.smsList.event != "定期投放") {
          this.cycleTime_trigger = this.smsList.time_setting.picked;
        } else {
          this.cycleTimeinCycle = this.smsList.time_setting.happenTime;
          this.cycleDayStart = this.smsList.time_setting.cycleDayStart;
        }
      }
      this.editList = false;
      console.log("this.editList", this.editList);
    },
    drawTree() {
      console.log("根據劇本內容重新畫劇本");
      this.tree.forEach((o) => {
        if (o.action === "寄信") {
          o.action = "傳送Email";
        }
        if (o.action === "寄簡訊") {
          o.action = "傳送SMS";
        }
        if (o.event === "購買行銷") {
          o.event = "購買後促銷";
        }
        if (o.event === "購買") {
          o.event = "訂單確認";
        }
      });

      this.testList2 = this.tree.filter((d) => {
        return (
          d.vice_ruleID.charAt(12) != "0" && d.vice_ruleID.charAt(1) === "e"
        );
      });
      this.testList = this.tree.filter((d) => {
        return (
          d.vice_ruleID.charAt(12) != "0" && d.vice_ruleID.charAt(1) === "s"
        );
      });
      this.emailList = this.tree.filter((d) => {
        return (
          d.vice_ruleID.charAt(12) === "0" && d.vice_ruleID.charAt(1) === "e"
        );
      })[0];
      this.smsList = this.tree.filter((d) => {
        return (
          d.vice_ruleID.charAt(12) === "0" && d.vice_ruleID.charAt(1) === "s"
        );
      })[0];
      this.loading = false;
      console.log("this.testList2", this.testList2);
      console.log("this.testList", this.testList);
      console.log("this.emailList", this.emailList);
      console.log("this.smsList", this.smsList);

      this.testList2.forEach((o) => {
        return (o.template_name = o.template);
      });
      this.testList.forEach((o) => {
        return (o.template_name = o.template);
      });
      if (this.emailList != undefined) {
        this.emailList.template_name = this.emailList.template;
        delete this.emailList.template;
      }
      if (this.smsList != undefined) {
        this.smsList.template_name = this.smsList.template;
        delete this.smsList.template;
      }
      console.log("this.testList", this.testList);

      this.testList2.map((d) => {
        if (
          d.vice_ruleID.slice(0, -6).length === 13 &&
          d.is_yes === 1 &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after = d.time_after / 24;
            this.yes_time_after_state = "天後";
          } else {
            this.yes_time_after = d.time_after;
            this.yes_time_after_state = "小時後";
          }
          this.yes_action = d.action;
          this.yes_subject = d.subject;
          this.yes_template = d.template_name;
          this.yes_triggerCron = d.time_setting;
          this.cycleTime_yes = d.time_setting.picked;
          this.ader_yes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.slice(0, -6).length === 13 &&
          d.is_yes === 0 &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEvent = d.event;
          if (d.time_interval > 23) {
            this.time_interval = d.time_interval / 24;
            this.time_state = "天內";
          } else {
            this.time_interval = d.time_interval;
            this.time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after = d.time_after / 24;
            this.no_time_after_state = "天後";
          } else {
            this.no_time_after = d.time_after;
            this.no_time_after_state = "小時後";
          }
          this.no_action = d.action;
          this.no_subject = d.subject;
          this.no_template = d.template_name;
          this.no_triggerCron = d.time_setting;
          this.cycleTime_no = d.time_setting.picked;
          this.ader_no_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_1_1") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after = d.time_after / 24;
            this.yesyes_time_after_state = "天後";
          } else {
            this.yesyes_time_after = d.time_after;
            this.yesyes_time_after_state = "小時後";
          }
          this.yesyes_action = d.action;
          this.yesyes_subject = d.subject;
          this.yesyes_template = d.template_name;
          this.yesyes_triggerCron = d.time_setting;
          this.cycleTime_yesyes = d.time_setting.picked;
          this.ader_yesyes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_1_2") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventYes = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval = d.time_interval / 24;
            this.yes_time_state = "天內";
          } else {
            this.yes_time_interval = d.time_interval;
            this.yes_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after = d.time_after / 24;
            this.yesno_time_after_state = "天後";
          } else {
            this.yesno_time_after = d.time_after;
            this.yesno_time_after_state = "小時後";
          }
          this.yesno_action = d.action;
          this.yesno_subject = d.subject;
          this.yesno_template = d.template_name;
          this.yesno_triggerCron = d.time_setting;
          this.cycleTime_yesno = d.time_setting.picked;
          this.ader_yesno_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_2_1") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after = d.time_after / 24;
            this.noyes_time_after_state = "天後";
          } else {
            this.noyes_time_after = d.time_after;
            this.noyes_time_after_state = "小時後";
          }
          this.noyes_action = d.action;
          this.noyes_subject = d.subject;
          this.noyes_template = d.template_name;
          this.noyes_triggerCron = d.time_setting;
          this.cycleTime_noyes = d.time_setting.picked;
          this.ader_noyes_triggerCron = "ok";
        } else if (
          d.vice_ruleID.includes("_2_2") &&
          d.vice_ruleID.charAt(1) === "e"
        ) {
          this.currentEventNo = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval = d.time_interval / 24;
            this.no_time_state = "天內";
          } else {
            this.no_time_interval = d.time_interval;
            this.no_time_state = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after = d.time_after / 24;
            this.nono_time_after_state = "天後";
          } else {
            this.nono_time_after = d.time_after;
            this.nono_time_after_state = "小時後";
          }
          this.nono_action = d.action;
          this.nono_template = d.template_name;
          this.nono_subject = d.subject;
          this.nono_triggerCron = d.time_setting;
          this.cycleTime_nono = d.time_setting.picked;
          this.ader_nono_triggerCron = "ok";
        }
      });
      this.testList.map((d) => {
        if (
          d.vice_ruleID.slice(0, -6).length === 13 &&
          d.is_yes === 1 &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEvent2 = d.event;
          if (d.time_interval > 23) {
            this.time_interval2 = d.time_interval / 24;
            this.time_state2 = "天內";
          } else {
            this.time_interval2 = d.time_interval;
            this.time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yes_time_after2 = d.time_after / 24;
            this.yes_time_after_state2 = "天後";
          } else {
            this.yes_time_after2 = d.time_after;
            this.yes_time_after_state2 = "小時後";
          }
          this.yes_action2 = d.action;
          this.yes_template2 = d.template_name;
          this.yes_triggerCron2 = d.time_setting;
          this.cycleTime_yes2 = d.time_setting.picked;
          this.ader_yes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.slice(0, -6).length === 13 &&
          d.is_yes === 0 &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEvent2 = d.event;
          if (d.time_interval > 23) {
            this.time_interval2 = d.time_interval / 24;
            this.time_state2 = "天內";
          } else {
            this.time_interval2 = d.time_interval;
            this.time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.no_time_after2 = d.time_after / 24;
            this.no_time_after_state2 = "天後";
          } else {
            this.no_time_after2 = d.time_after;
            this.no_time_after_state2 = "小時後";
          }
          this.no_action2 = d.action;
          this.no_template2 = d.template_name;
          this.no_triggerCron2 = d.time_setting;
          this.cycleTime_no2 = d.time_setting.picked;
          this.ader_no_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_1_1") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventYes2 = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval2 = d.time_interval / 24;
            this.yes_time_state2 = "天內";
          } else {
            this.yes_time_interval2 = d.time_interval;
            this.yes_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yesyes_time_after2 = d.time_after / 24;
            this.yesyes_time_after_state2 = "天後";
          } else {
            this.yesyes_time_after2 = d.time_after;
            this.yesyes_time_after_state2 = "小時後";
          }
          this.yesyes_action2 = d.action;
          this.yesyes_template2 = d.template_name;
          this.yesyes_triggerCron2 = d.time_setting;
          this.cycleTime_yesyes2 = d.time_setting.picked;
          this.ader_yesyes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_1_2") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventYes2 = d.event;
          if (d.time_interval > 23) {
            this.yes_time_interval2 = d.time_interval / 24;
            this.yes_time_state2 = "天內";
          } else {
            this.yes_time_interval2 = d.time_interval;
            this.yes_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.yesno_time_after2 = d.time_after / 24;
            this.yesno_time_after_state2 = "天後";
          } else {
            this.yesno_time_after2 = d.time_after;
            this.yesno_time_after_state2 = "小時後";
          }
          this.yesno_action2 = d.action;
          this.yesno_template2 = d.template_name;
          this.yesno_triggerCron2 = d.time_setting;
          this.cycleTime_yesno2 = d.time_setting.picked;
          this.ader_yesno_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_2_1") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventNo2 = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval2 = d.time_interval / 24;
            this.no_time_state2 = "天內";
          } else {
            this.no_time_interval2 = d.time_interval;
            this.no_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.noyes_time_after2 = d.time_after / 24;
            this.noyes_time_after_state2 = "天後";
          } else {
            this.noyes_time_after2 = d.time_after;
            this.noyes_time_after_state2 = "小時後";
          }
          this.noyes_action2 = d.action;
          this.noyes_template2 = d.template_name;
          this.noyes_triggerCron2 = d.time_setting;
          this.cycleTime_noyes2 = d.time_setting.picked;
          this.ader_noyes_triggerCron2 = "ok";
        } else if (
          d.vice_ruleID.includes("_2_2") &&
          d.vice_ruleID.charAt(1) === "s"
        ) {
          this.currentEventNo2 = d.event;
          if (d.time_interval > 23) {
            this.no_time_interval2 = d.time_interval / 24;
            this.no_time_state2 = "天內";
          } else {
            this.no_time_interval2 = d.time_interval;
            this.no_time_state2 = "小時內";
          }
          if (d.time_after > 23) {
            this.nono_time_after2 = d.time_after / 24;
            this.nono_time_after_state2 = "天後";
          } else {
            this.nono_time_after2 = d.time_after;
            this.nono_time_after_state2 = "小時後";
          }
          this.nono_action2 = d.action;
          this.nono_template2 = d.template_name;
          this.nono_triggerCron2 = d.time_setting;
          this.cycleTime_nono2 = d.time_setting.picked;
          this.ader_nono_triggerCron2 = "ok";
        }
      });
      if (this.emailList != undefined) {
        this.currentTrigger = this.emailList.event;
        if (this.emailList.time_after > 23) {
          this.time_trigger = this.emailList.time_after / 24;
          this.time_trigger_state = "天後";
        } else if (this.emailList.time_after === 0) {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else {
          this.time_trigger = this.emailList.time_after;
          this.time_trigger_state = "小時後";
        }
        if (this.emailList.event === "購物車未結") {
          this.time_trigger = this.emailList.time_after / 24;
          this.time_trigger_state = "天內";
        }
        this.email_subject = this.emailList.subject;
        this.template_email = this.emailList.template_name;
        this.triggerCron = this.emailList.time_setting;
        this.ader_triggerCron = "ok";
        if (this.emailList.event != "定期投放") {
          this.cycleTime_trigger = this.emailList.time_setting.picked;
        } else {
          this.cycleTimeinCycle = this.emailList.time_setting.happenTime;
          this.cycleDayStart = this.emailList.time_setting.cycleDayStart;
        }
      }

      if (this.smsList != undefined) {
        this.currentTrigger = this.smsList.event;

        if (this.smsList.time_after > 23) {
          this.time_trigger = this.smsList.time_after / 24;
          this.time_trigger_state = "天後";
        } else if (this.smsList.time_after === 0) {
          this.time_trigger = 0;
          this.time_trigger_state = "立即";
        } else {
          this.time_trigger = this.smsList.time_after;
          this.time_trigger_state = "小時後";
        }
        if (this.smsList.event === "購物車未結") {
          this.time_trigger = this.smsList.time_after / 24;
          this.time_trigger_state = "天內";
        }
        this.sms_subject = this.smsList.subject;
        this.template_sms = this.smsList.template_name;
        this.triggerCron = this.smsList.time_setting;
        this.ader_triggerCron = "ok";
        if (this.smsList.event != "定期投放") {
          this.cycleTime_trigger = this.smsList.time_setting.picked;
        } else {
          this.cycleTimeinCycle = this.smsList.time_setting.happenTime;
          this.cycleDayStart = this.smsList.time_setting.cycleDayStart;
        }
      }
      this.editList = false;
      console.log("this.editList", this.editList);
    },
    showexp: async function (a, b, boolean, sub) {
      const key = a + this.eventScheduleIdFromSche.slice(-8) + b;
      if (boolean) {
        await this.getTemplateByScriptkey(key);
        this.newHtml = this.newHtml
          .replace(
            "{{p1}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/7b9bcf67.png"
          )
          .replace(
            "{{p2}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/ba89379c.png"
          )
          .replace(
            "{{p3}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/4ca61534.png"
          )
          .replace(
            "{{p4}}",
            "https://rema-image-resize.s3.ap-northeast-1.amazonaws.com/fircdp-dev/images/332f8c30.png"
          )
          .replace("{{tracking}}", "");
        this.$emit("showUnlayer", this.newHtml, sub);
      } else {
        await this.getTemplateByScriptkey(key);

        this.$emit("showSMSexp", this.newHtml);
      }
    },
    showCycleTimeDetail(cycleTimeState, a, b) {
      console.log("this.tree", this.tree);
      console.log("a, b", a, b);
      this.cycleTimeState = cycleTimeState;
      this.cycleTime_setting = this.tree.find((d) => {
        if (a === "_0") {
          return d.is_yes == null;
        } else if (a.length > 2) {
          return d.vice_ruleID.includes(a) && d.vice_ruleID.includes(b);
        } else if (a === "_1") {
          return (
            d.is_yes == 1 &&
            d.vice_ruleID.includes(b) &&
            d.vice_ruleID.length == 13
          );
        } else if (a === "_2") {
          return (
            d.is_yes == 0 &&
            d.vice_ruleID.includes(b) &&
            d.vice_ruleID.length == 13
          );
        }
      });
        console.log("this.cycleTime_setting", this.cycleTime_setting);
        this.CycleTimeDetail = new bootstrap.Modal(
          document.getElementById("CycleTimeDetail2"),
          {
            keyboard: false,
          }
        );
        this.CycleTimeDetail.show();
        const backdrop = document.getElementById("CycleTimeDetail2-backdrop");
        backdrop.style["display"] = "block";
    },
    getTemplateByScriptkey: async function (key) {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        id: key,
      };

      await allapi2
        .scriptTempScheduleIdGet(params)
        .then((res) => {
          console.log(res);
          this.newHtml = res.data.data;
          // if (this.emailChannel===false) {
          //   this.newSubsec = res.data.data.json;
          // }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
    zoomout() {
      this.z = this.z - 0.1;
      if (this.z < 0.4) {
        this.z = 0.4;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomin() {
      this.z = this.z + 0.1;
      if (this.z > 1) {
        this.z = 1;
      }
      this.zoom = { transform: `scale(${this.z})` };
    },
    zoomreset() {
      this.z = 1;
      this.zoom = { transform: `scale(1)` };
    },
  },
};
</script>

<style scoped>
.p-tooltip.p-tooltip-top {
  padding: 5px 10px;
}
.tip {
  background-color: #ababab;
  border: 1px solid #ababab;
  border-radius: 10px;
  color: white;
}
.tip:after {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 100%;
  width: 0;
}
.tip:after,
.tip:before {
  opacity: 1;
  pointer-events: none;
  border: 6px solid transparent;
  border-top-color: #ababab;
  bottom: -35%;
  left: 50%;
  position: absolute;
  height: 0;
  width: 0;
  z-index: 5000;
}

/* AfterTriggerScript */
.tab-div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #c4c4c4;
}
.script-tip {
  position: absolute;
  font-size: 16px;
  top: 80px;
  right: 20px;
  z-index: 99;
}
.zoom-in-SeeTriggerScript {
  position: absolute;
  font-size: 16px;
  top: 36px;
  left: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: transparent;
  z-index: 99;
}
.nowork {
  display: none;
}
.notwork {
  position: absolute;
  font-size: 16px;
  top: 80px;
  left: 20px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #eeeeee;
  z-index: 99;
}
.work {
  position: absolute;
  font-size: 16px;
  top: 80px;
  left: 20px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #c6e0c9;
  z-index: 99;
}
/* 拖曳物件樣式 */
/* .bedrag {
  min-height: 100px;
  width: 200px;
  margin: 20px auto;
  background: #eee;
} */
/* .bedrag p {
  min-height: 2em;
  margin-top: 10px;
  background: #abcded;
} */
/* .drog-move {
  transition: transform 1s;
} */

/* 一般樣式 */
.select-label-bottom-span {
  width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.triggerBuy-value {
  position: relative;
  top: -4px;
  font-size: 12px;
  width: 60px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.script-header {
  font-size: 24px;
  font-weight: bold;
}
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.nav-tabs .nav-link {
  background-color: white;
  height: 40px;
  color: #575757;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.nav-tabs .nav-link.active {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
  border: none;
  padding: 0.8vw 1.7vw;
  border-radius: 10px 10px 0 0;
}
.shodow-box-afterTriggerScript {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
}
.contain-left-trigger-script {
  height: 582px;
}
.contain-left-all {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 622px;
  margin-bottom: 25px;
}
.moveTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
.timeTag {
  width: 18vw;
  background-color: #eaf3f4;
  margin: 25px auto;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
}
.time-input {
  border: none;
  border-bottom: 1px solid black;
  background-color: #eaf3f4;
  width: 50px;
  outline: none;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.contain-right-top {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px;
  border-radius: 10px;
  height: 736px;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}
.tree-div {
  width: 100%;
}
.tree-tag {
  border-radius: 10px;
  background-color: #eaf3f4;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
}
.template-tag {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 10px;
  background-color: #eaf3f4;
  padding: 5px;
  text-align: center;
  width: 90px;
  margin: 0 auto;
  font-size: 18px;
}
.blue-tag {
  background-color: #96c5d7;
  color: white;
  border-radius: 10px;
  padding: 13px 19px 0 19px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.blue-tag2 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding: 16px;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.blue-tag3 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding: 12px 25px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
  font-size: 14px;
}
.blue-tag4 {
  background-color: #96c5d7;
  border-radius: 10px;
  padding-top: 10px;
  margin: 0 auto;
  width: 120px;
  height: 105px;
  cursor: pointer;
}
.purple-tag {
  background-color: #baabe7;
  color: white;
  border-radius: 10px;
  padding: 13px 0px 3px 0px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.purple-tag2 {
  background-color: #baabe7;
  border-radius: 10px;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.signup-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/signup.svg");
}
.shopcar-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/shopcar.svg");
}
.Order-done {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/Order-done.svg");
}
.buy-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/buy.svg");
}
.cycleTime-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/cycleTime.svg");
}
.mail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/openmail.svg");
}
.click-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clicklink.svg");
}
.change-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/change.svg");
}
.hourglass-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/hourglass.svg");
}
.clock-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/clock.svg");
}
.cycleTime2-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/cycleTime2.svg");
}
.sendmail-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendmail.svg");
}
.sendsms-image {
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: url("../assets/sendsms.svg");
}
.yes-tag {
  background-color: #8bc19b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
  font-size: 18px;
}
.no-tag {
  background-color: #ff8b8b;
  border-radius: 10px;
  color: white;
  padding: 7px;
  margin: 0 auto;
  width: 90px;
  height: 40px;
  font-size: 18px;
}
.time-tag {
  background-color: #fc9f66;
  border-radius: 10px;
  color: white;
  padding-top: 5px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.time-tag2 {
  background-color: #fc9f66;
  border-radius: 10px;
  color: white;
  padding-top: 5px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag2 {
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 13px 15px 10px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.action-tag3 {
  background-color: #f9c357;
  border-radius: 10px;
  color: white;
  padding: 15px 0px 20px 0px;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  cursor: pointer;
}
.bot-left-left-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #ff998b, #ff614d);
  color: white;
}
.bot-left-btn {
  border-radius: 10px;
  border: none;
  background-color: #eaf3f4;
}
.bot-right-btn {
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white;
}
.floating-button {
  position: fixed;
  bottom: 0;
  /* top: 50%; */
  left: 45.6%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  z-index: 10;
  display: flex;
  width: 8rem;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.floating-button2 {
  left: 50%;
  z-index: 10;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #71afb6;
  border: none;
}
.select-style {
  border-radius: 10px;
  text-align: center;
  height: 40px;
  border: 1px solid #c4c4c4;
}
.btn-basic {
  border: none;
  border-radius: 10px;
  width: 14rem;
  height: 40px;
}
.modal-btn-close {
  color: #fff;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border: none;
  width: 10vw;
}

.btn-save {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-color {
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
}
.button-group-div {
  background-color: #eaf3f4;
  width: 68%;
  border-radius: 10px;
}
.button-color2 {
  width: 50%;
  background-color: #eaf3f4;
  border-radius: 10px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -70px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
.file-span {
  display: inline-block;
  background-color: #eaf3f4;
  line-height: 40px;
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clear-img-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.clear-file-btn {
  border: none;
  background-color: #eaf3f4;
  width: 30px;
  height: 20px;
}
.delete-btn {
  position: absolute;
  cursor: pointer;
  top: 20px;
  left: 630px;
}
.delete-btn2 {
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 1500px;
}
.group-div {
  position: absolute;
  right: 5px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.trigger-channel-btn {
  border: none;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  background-color: #eaf3f4;
}
.todo-span {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.basic-btn {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: #eaf3f4;
  color: #71afb6;
}
.highlight {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: white !important;
}
.contain-bottom {
  border: 1px solid black;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  height: 210px;
}
.search-input {
  border-radius: 5px;
  background-color: #eaf3f4;
  border: none;
  text-indent: 30px;
  width: 13rem;
  height: 37px;
}
.searchImg {
  position: relative;
  top: -2px;
  left: 24px;
}
.sms-bubble {
  position: relative;
  background-color: #e6e6e6;
  margin-top: 30px;
  margin-bottom: 10rem;
  padding: 30px;
  border-radius: 25px;
  width: 330px;
  word-break: break-all;
  /* overflow-wrap: anywhere; */
  white-space: pre-wrap;
}
:deep(.dp-custom-input-triggerscript) {
  text-align: center;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 6px 12px;
}
/* Customize the label (the container) */
.radio-container {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 35%;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 5px;
  left: 4px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  top: 5px;
  left: 0px;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #e0dada;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #71afb6;
}
.child-popout-inAfterTriggerScript-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: 40px;
  text-align: left;
  top: -575px;
  width: 152px;
  z-index: 99;
}
.container__arrow--inAfterTriggerScript-first {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: 50%;
  height: 0;
  left: 104.2%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(270deg);
  width: 0;
  z-index: 10;
}
.cycletime-setting-block {
  width: 80%;
  border: 1px solid #c4c4c4;
  line-height: 40px;
  border-radius: 10px;
  color: #c4c4c4;
  text-align: center;
}
@media (min-width: 1500px) {
  .zoom {
    position: absolute;
    font-size: 16px;
    top: 875px;
    left: 88%;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    z-index: 99;
  }
  .nowork {
    position: absolute;
    font-size: 16px;
    top: 80px;
    left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    z-index: 99;
  }
  .notwork {
    position: absolute;
    font-size: 16px;
    top: 80px;
    left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #eeeeee;
    z-index: 99;
  }
  .work {
    position: absolute;
    font-size: 16px;
    top: 80px;
    left: 20px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #c6e0c9;
    z-index: 99;
  }
}
</style>