<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div class="container home" v-else>
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- show excludeModal -->
    <div
      class="modal fade"
      id="excludeModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header alert-modal-header d-flex justify-content-end"
          >
            <img
              src="../assets/whiteX.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div
            class="modal-body alert-modal-body text-start"
            style="padding: 25px 28px"
          >
            <p>
              <img src="../assets/greenAirplane.svg" alt="" class="me-3" />
              發送名單:
            </p>
            <p class="mb-5" style="margin-left: 36px; word-break: break-all">
              {{
                this.label_names.includeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
            <p>
              <img
                src="../assets/redAirplane.svg"
                alt=""
                class="me-3"
              />排除名單:
            </p>
            <p style="margin-left: 36px">
              {{
                this.label_names.excludeLabelNames
                  .toString()
                  .replace(/,/g, "、")
              }}
            </p>
          </div>
          <!-- <div class="modal-footer alert-modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary modal-btn-close"
                    data-bs-dismiss="modal"
                  >
                    確認
                  </button>
                </div> -->
        </div>
      </div>
    </div>
    <!-- main -->

    <div class="col-12">
      <span class="title-span fs-medium mb-5">投放活動紀錄一覽表 (SMS)</span>
      <DataTable
        :value="searchTable"
        class="header-table mt-3"
        showGridlines
        :paginator="true"
        :rows="10"
        paginatorPosition="top"
        :pageLinkSize="3"
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="目前 {currentPage} ，共 {totalPages} 頁"
        :style="{ textAlign: 'center' }"
      >
        <template #paginatorstart>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-6" style="position: relative">
              <img
                style="position: absolute; top: 8px; left: 10px"
                src="../assets/search-black.png"
                alt="searchImg"
              />
              <input
                style="
                  padding-left: 35px;
                  width: 100%;
                  height: 35px;
                  border-radius: 10px;
                  border: 1px solid #575757;
                "
                v-model="searchInput"
                placeholder="搜尋名稱"
              />
            </div>
            <div class="search-container col-5" ref="target">
              <div
                class="d-flex justify-content-between align-items-center"
                style="
                  width: 350px;
                  border: 1px solid #c4c4c4;
                  border-radius: 10px;
                  padding: 0 14px;
                  height: 35px;
                "
                @click="autoShow = !autoShow"
              >
                {{ triggerType }}
                <div
                  class="d-flex align-items-center"
                  style="width: 50px"
                  :class="
                    this.filterCount === 0
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  "
                >
                  <div
                    style="
                      position: relative;
                      background-color: #71afb6;
                      border-radius: 10px;
                      width: 20px;
                      height: 20px;
                    "
                    v-if="this.filterCount != 0"
                  >
                    <span
                      style="
                        color: white;
                        font-size: 14px;
                        position: absolute;
                        top: 0px;
                        left: 6.5px;
                      "
                    >
                      {{ filterCount }}
                    </span>
                  </div>
                  <img
                    style="width: 10px; height: 8px"
                    src="../assets/selection-arrow.png"
                    alt="searchImg"
                  />
                </div>
              </div>
              <div
                class="auto-complicate-div text-start"
                style="width: 350px"
                v-show="autoShow"
                @mouseenter="autoShow = true"
              >
                <p class="m-0 p-1 ps-3 my-2">自動回應</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div class="d-flex flex-wrap">
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="無"
                      id="無"
                      value="無"
                      v-model="checkedNames2"
                    />
                    <label for="無" style="width: 83%">無</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="執行中"
                      id="執行中"
                      value="執行中"
                      v-model="checkedNames2"
                    />
                    <label for="執行中" style="width: 83%">執行中</label>
                  </p>
                  <p class="m-0 p-1 ps-3 auto-complicate-p col-6">
                    <input
                      class="me-2"
                      style="position: relative; top: 1px"
                      type="checkbox"
                      name="已完成"
                      id="已完成"
                      value="已完成"
                      v-model="checkedNames2"
                    />
                    <label for="已完成" style="width: 83%">已完成</label>
                  </p>
                </div>
                <p class="m-0 p-1 ps-3 my-2">活動時間</p>
                <hr
                  class="m-0"
                  style="
                    width: 100%;
                    opacity: 1;
                    height: 0;
                    border: 0;
                    border-bottom: 1px solid #c4c4c4;
                  "
                />
                <div
                  class="
                    m-0
                    p-2
                    ps-3
                    auto-complicate-p
                    d-flex
                    align-items-center
                  "
                >
                  <input
                    class="me-2"
                    style="position: relative; top: 1px"
                    type="checkbox"
                    name="dateRange"
                    id="dateRange"
                    :value="0"
                    v-model="checkedNames3"
                  />
                  <div style="width: 90%; position: relative">
                    <DatePicker v-model="filterDateRange" is-range>
                      <!-- @dayclick="updateRangeday(index)" -->
                      <template v-slot="{ togglePopover }">
                        <input
                          id="dropdown-moreoption-rangeinput"
                          class="ps-5"
                          style="
                            cursor: default;
                            width: 100%;
                            height: 40px;
                            border-radius: 10px;
                            border: 1px solid #c4c4c4;
                          "
                          placeholder="選擇日期"
                          :value="time_range"
                          @click="togglePopover()"
                          readonly
                        />
                        <img
                          src="../assets/date.svg"
                          alt=""
                          style="position: absolute; left: 10px; top: 10px"
                        />
                        <img
                          src="../assets/selection-arrow.png"
                          alt="searchImg"
                          style="position: absolute; right: 10px; top: 16px"
                        />
                      </template>
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <Column field="活動名稱" header="活動名稱" :sortable="true">
          <template #body="slotProps2">
            <div style="padding: 5px 0">
              <div class="too-over-div" v-tooltip.bottom="slotProps2.data.活動名稱?.length > 6 ? { value: slotProps2.data.活動名稱, class: 'tip-LabelManage'} : { value: '', class: ''}">
                {{ slotProps2.data.活動名稱 }}
              </div>
              <div style="color: #c4c4c4; font-size: 12px">
                {{ slotProps2.data.活動時間 }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="模板名稱" header="模板名稱" :sortable="true">
          <template #body="slotProps2">
            <div style="padding: 5px 0">
              <div class="too-over-div" v-tooltip.bottom="slotProps2.data.模板名稱?.length > 6 ? { value: slotProps2.data.模板名稱, class: 'tip-LabelManage'} : { value: '', class: ''}">
                {{ slotProps2.data.模板名稱 }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="客群名稱" header="選擇客群" :sortable="true"
          ><template #body="slotProps2">
            <div v-tooltip.bottom="slotProps2.data.客群名稱.includeLabelNames.toString()?.length > 6 ? { value: slotProps2.data.客群名稱.includeLabelNames.toString().replace(/,/g, '、'), class: 'tip-LabelManage'} : { value: '', class: ''}">
              <p class="m-0 too-over-div">
                {{
                  slotProps2.data.客群名稱.includeLabelNames
                    .toString()
                    .replace(/,/g, "、")
                }}
              </p>
              <p
                class="m-0"
                style="
                  font-size: 14px;
                  font-weight: 100;
                  color: #c4c4c4;
                  text-decoration: underline;
                  cursor: pointer;
                "
                @click="openExcludeModal(slotProps2.data.客群名稱)"
                v-if="slotProps2.data.客群名稱.excludeLabelNames[0] != undefined"
              >
                瀏覽完整名單
              </p>
            </div>
          </template>
        </Column>
        <Column field="發送日期" header="投放時間" :sortable="true">
          <template #body="slotProps2">
            <div>
              {{
                slotProps2.data.發送日期.slice(
                  0,
                  slotProps2.data.發送日期.length - 9
                )
              }}
            </div>
            <div style="color: #c4c4c4; font-size: 12px">
              {{ slotProps2.data.發送日期.slice(-8).slice(0, 5) }}
            </div>
          </template>
        </Column>
        <Column field="傳送數量" header="傳送數量" :sortable="true">
          <template #body="slotProps2">
            <div v-if="slotProps2.data.傳送數量 === 0">傳送中</div>
            <div v-else>
              {{ slotProps2.data.傳送數量.toLocaleString() }}
            </div>
          </template>
        </Column>
        <Column field="點擊率" header="點擊率" :sortable="true"></Column>
        <!-- <Column
          field="has_event"
          header="劇本狀態"
          :sortable="true"
          v-if="this.checkedNames2[0] != undefined"
        >
          <template #body="slotProps2">
            <div>
              <span class="ms-2" v-if="slotProps2.data.has_event === 3">
                <img src="../assets/blackBigSpot.png" alt="" class="mb-1" />
                已完成
              </span>
              <span class="ms-2" v-else-if="slotProps2.data.has_event === 0">
                無劇本
              </span>
            </div>
          </template></Column
        > -->
        <Column field="數據查看" header="數據查看">
          <template #body="slotProps">
            <a
              type="button"
              label="Connect"
              class="p-button-raised p-button-secondary more-info"
              @click="goResult(slotProps.data)"
              v-if="slotProps.data.傳送數量 != 0"
            >
              more info.
            </a>
            <a
              type="button"
              label="Connect"
              style="color: #c4c4c4; cursor: default"
              class="p-button-raised p-button-secondary more-info"
              v-else
            >
              more info.
            </a>
          </template>
        </Column>
      </DataTable>
      <div
        class="text-center mt-4"
        style="color: #c4c4c4"
        v-if="this.searchTable[0] === undefined"
      >
        目前無投放紀錄
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import moment from "moment";

export default {
  name: "SmsMarketingTable",
  components: {
    DataTable,
    Column,
  },
  setup() {
    const autoShow = ref(false);
    const target = ref(null);

    onClickOutside(target, () => (autoShow.value = false));

    return { target, autoShow };
  },
  data() {
    return {
      searchInput: "",
      checkedNames2: [],
      checkedNames3: [],
      triggerType: "篩選",
      filterDateRange: {},
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      time_range: "",

      notshow: false,
      loading: true,
      // primevue table data exp
      userID: "",
      event: "",
      loopData: {
        label: "",
      },

      table: [
        {
          brand: "Volkswagen",
          year: 2018,
          model: "Golf",
          housepower: "340",
          aaa: "a",
        },
        {
          brand: "Audi",
          year: 2020,
          model: "RS7",
          housepower: "620",
          aaa: "a",
        },
        {
          brand: "Nissan",
          year: 2017,
          model: "GTR",
          housepower: "560",
          aaa: "a",
        },
      ],
      events: [],
      searchTable: [],
      label_names: {
        includeLabelNames: [],
        excludeLabelNames: [],
      },
    };
  },
  watch: {
    searchInput(val) {
      this.searchTable = this.table.filter((d) => {
        return d.模板名稱.includes(val) || d.活動名稱.includes(val);
      });
    },
    checkedNames2(val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTable = this.table;
        return;
      }
      this.searchTable = this.table.filter((d) => {
        return (
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    checkedNames3(val) {
      if (
        this.checkedNames2[0] === undefined &&
        this.checkedNames3[0] === undefined
      ) {
        this.searchTable = this.table;
        return;
      }
      this.searchTable = this.table.filter((d) => {
        return (
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
      });
    },
    filterDateRange() {
      this.updateRange();
      if (this.checkedNames3[0] != undefined) {
        this.searchTable = this.table.filter((d) => {
         return (
         
          (this.checkedNames2[0] === d.script_state ||
            this.checkedNames2[1] === d.script_state ||
            this.checkedNames2[2] === d.script_state ||
            this.checkedNames2[0] === undefined) &&
          ((this.time_range.slice(0, 10) <= d.活動時間.substr(13, 10) &&
            this.time_range.slice(0, 10) >= d.活動時間.slice(0, 10)) ||
            (this.time_range.substr(11, 10) >= d.活動時間.slice(0, 10) &&
              this.time_range.substr(11, 10) <= d.活動時間.substr(13, 10)) ||
            this.checkedNames3[0] === undefined)
        );
        });
      }
    },
  },
  computed: {
    filterCount() {
      console.log(this.checkedNames2.length, this.checkedNames3.length);
      return this.checkedNames2.length + this.checkedNames3.length;
    },
  },
  created: async function () {
    // http://localhost:8080 test
    
    await this.getCalenEvents();
    this.getTable();

    this.loading = false;
  },
  methods: {
    updateRange() {
      console.log(this.filterDateRange);
      console.log(this.filterDateRange.start.toISOString());
      this.time_range =
        this.filterDateRange.start.toISOString().slice(0, 10) +
        "~" +
        this.filterDateRange.end.toISOString().slice(0, 10);
    },
    test() {
      console.log(this.events);
    },
    openExcludeModal(labelNames) {
      this.label_names = labelNames;
      const excludeModal = new bootstrap.Modal(
        document.getElementById("excludeModal"),
        {
          keyboard: false,
        }
      );
      excludeModal.show();
    },
    goResult(data) {
      console.log(data);
      sessionStorage.setItem("has_script", data.has_event);
      sessionStorage.setItem("script_id", data.script_id);
      this.$router.push({
        name: "SmsResultPerformance",
      });
    },

    getTable: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi3
        .smsTableGet(params)
        .then((res) => {
          console.log("smstable", res);
          const arrayData = res.data;
          arrayData.forEach((d)=>{
             d.點擊率=d.點擊率+'%'
          })
          arrayData.forEach((d) => {
            d.發送日期 = new Date(
              moment(d.發送日期).add(-8, "h")
            ).toLocaleString("zh-cn");
            if(d.has_event==2){
              d.script_state='執行中'
            }else if(d.has_event==3){
              d.script_state='已完成'
            }else if(d.has_event==0){
              d.script_state='無'
            }
          });
          arrayData.forEach((e) => {
            const d = this.events.find((d) => d.id === e.活動名稱);
            if (d != undefined) {
              e.活動名稱 = d.title;
              e.活動時間 = `${d.start} - ${d.end}`;
            }
          });
          arrayData.sort((a, b) => {
            if (new Date(b.發送日期) > new Date(a.發送日期)) {
              return 1;
            } else {
              return -1;
            }
          });
          console.log("change number", arrayData);
          this.table = res.data;
          this.searchTable = arrayData;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    getCalenEvents: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      await allapi2
        .activityCalenderGet(params)
        .then((res) => {
          console.log(res);
          res.data.Data.map(
            (d) => (d.start = new Date(d.start).toISOString().slice(0, 10))
          );
          res.data.Data.map(
            (d) => (d.end = new Date(d.end).toISOString().slice(0, 10))
          );
          this.events = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  background-color: #fff;
  /* -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.7)); */
  filter: drop-shadow(0.16rem 0.16rem 0.16rem rgba(84, 84, 84, 0.241));
}

.p-column-header-content {
  justify-content: center;
}

/* .p-datatable-tbody {
  text-align: center;
} */
.p-datatable-wrapper {
  overflow-x: auto;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  padding: 0 10px 0 20px !important;
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  padding: 0 10px 0 20px !important;
}

.p-datatable .p-datatable-tbody > tr:last-child > td {
  border-bottom: none !important;
}

.p-datatable .p-datatable-tbody > tr > td:first-child {
  padding: 0px !important;
}
:deep(.p-datatable .p-datatable-tbody > tr > td:nth-child(-n + 4)) {
  text-align: left;
}
:deep(.p-datatable
    .p-datatable-thead
    > tr
    > th:nth-child(-n + 4)
    .p-column-header-content) {
  justify-content: flex-start;
}

.hasevent {
  background-color: #eaf3f4;
  text-align: center;
  height: 58px;
  line-height: 58px;
}

.noevent {
  background-color: #ffedc9;
  text-align: center;
  height: 58px;
  line-height: 58px;
}

.fs-small {
  font-size: 18px;
}

.fs-medium {
  font-size: 22px;
}

.home {
  /* padding: 50px; */
}

.more-info {
  color: #71afb6;
  text-decoration: none;
}
.too-over-div {
  margin: 0 auto 0 0;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>
