<template>
  <div v-if="notshow==0" class="d-flex justify-content-center align-items-center" style="height:100%">
    <span style="color:#c4c4c4"> 該功能為正式付費使用 </span>
  </div>
  <div class="container home" style="padding-top: 53px;" v-else>
    <div class="header fs-medium ">
    
       <span class="title-span" style="padding-left:1px ;">渠道行銷分群       <button
        class="btn btn-default button px-0 mx-0 pt-0"
        @click="customPop()"
      >
        <span>
          &nbsp;
          <img
            src="../assets/question-img.svg"
            style="width: 1.5rem"
            @mouseover="CustomShow = true"
            @mouseout="CustomShow = false"
        /></span>
        <div class="parent-popout">
          <div v-show="CustomShow" class="child-popout-channelmarketing">
            此模組提供您選擇不同的行銷渠道狀況下，可針對顧客的觸發動作來設定篩選條件。這類標籤的名單代表與品牌已有相對應的互動，可以此名單來做到顧客再行銷，引起導購或回購。
            <div class="container__arrow"></div>
          </div>
        </div>
      </button></span> 

    </div>

    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- add success alert -->
    <div
      class="modal fade"
      id="successModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">添加成功!</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              style="color: #fff"
            >
              確定
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- delete alert -->
    <div
      class="modal fade"
      id="alertModalDelete"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            刪除後即無法返回，是否確認刪除
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              style="background-image: #eaf3f4; width: 10vw"
              data-bs-dismiss="modal"
            >
              否
            </button>
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              style="color: white"
              @click="deleteTodo()"
              data-bs-dismiss="modal"
            >
              是
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- save success alert -->
    <div
      class="modal fade"
      id="saveModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">儲存成功!</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              style="color: #fff"
            >
              確定
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- haven select table row  failed alert -->
    <div
      class="modal fade"
      id="unselectTableModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">未選擇活動</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
              style="color: #fff"
            >
              確定
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- alert尚未選擇名單Modal -->
    <div
      class="modal fade"
      id="alertModal3"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">尚未選擇名單</div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- alert修改但沒儲存或添加新模板Modal -->
    <div
      class="modal fade"
      id="alertModal2"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            你有修改但沒儲存或添加新模板
          </div>
          <div class="modal-footer alert-modal-footer">
            <button
              type="button"
              class="btn btn-secondary modal-btn-close"
              data-bs-dismiss="modal"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- channel alert -->
    <div
      class="modal fade"
      id="roadModal"
      tabindex="-1"
      aria-labelledby="alertModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content">
          <div class="modal-header alert-modal-header">
            <img
              src="../assets/arrow-white.svg"
              alt="arrow"
              class=""
              data-bs-dismiss="modal"
            />
          </div>
          <div class="modal-body alert-modal-body">
            <p>請選擇投放管道。</p>
            <div>
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'email' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'email'"
              >
                Email
              </button>
              <button
                class="road-button-email my-2"
                :class="
                  selectChannel === 'sms' ? 'road-button-email-focus' : ''
                "
                v-on:click="this.selectChannel = 'sms'"
              >
                SMS
              </button>

              <button class="road-button my-2">Google Ads</button>

              <button class="road-button my-2">域動clickforce</button>
              <div>
                <button
                  data-bs-dismiss="modal"
                  class="road-button-confirm mt-2"
                  @click="
                    shareData(
                      selectValueById.label_id,
                      selectValueById.label_name
                    )
                  "
                  style="color: #fff"
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid p-0">
      <div class="container-item">
        <FavoriteList
          v-bind:todosAdv="todosAdv"
          @toggle-value="selectValue"
          @clearall="clearall"
          v-bind:selectedID="selectedID"
          v-on:remove-todo="removeTodo"
          v-on:copy-todo="copytodo"
          v-on:search="search"
        />
      </div>
    </div>
    <div class="d-flex justify-content-start mt-4">
      <div class="title-button-div fs-small">
        <button
          class="button-nofocus"
          :class="emailTab === true ? 'button-focus' : ''"
          @click="clickEmail"
        >
          Email
        </button>
        <button
          class="button-nofocus"
          :class="smsTab === true ? 'button-focus' : ''"
          @click="clickSMS"
        >
          SMS
        </button>
        <button
          class="button-nofocus"
          style="pointer-events: none; opacity: 0.3"
          :class="lineTab === true ? 'button-focus' : ''"
          @click="clickLine"
        >
          Line
        </button>
        <button
          class="button-nofocus"
          :class="fbTab === true ? 'button-focus' : ''"
          @click="clickFB"
        >
          FB
        </button>
      </div>
    </div>

    <!-- emaill tab -->
    <div class="tab-container">
      <p style="color: #71afb6">名單內容</p>
      <div class="d-flex mt-4">
        <span class="section-title" style="width: 120px">名單名稱</span>
        <input
          ref="titleInput"
          v-bind:class="{ 'is-invalid': titleError }"
          class="form-control"
          style="text-align: left"
          v-model="newTodoTitle"
          @change="inputChange"
        /><br />
      </div>
      <div class="d-flex mt-3" v-if="this.radioTab2 === true">
        <span class="section-title" style="width: 120px"></span>
        <!-- <span style="color: #71afb6" v-if="
          this.selectValueById.start_time != null ||
          this.selectValueById.end_time != null
        ">{{ this.selectValueById.start_time }}--{{
              this.selectValueById.end_time
          }}
          </span>
        <span style="color: #71afb6" v-else-if="
      
          this.startDateSelected === 'all' ||
          this.selectValueById.start_time === 'all' ||
          (this.selectValueById.start_time === 'all' &&
            this.selectValueById.start_time != null)
        ">全部</span> -->
        <span style="color: #71afb6"
          >{{ this.startDateSelected }}--{{ this.endDateSelected }}</span
        >
        <!-- <span style="color: #71afb6">{{ this.startDateSelected }}--{{ this.endDateSelected }}</span> -->
      </div>

      <hr class="mt-4" style="margin-left: -30px; margin-right: -30px" />

      <p style="color: #fc9f66">選取後人數</p>
      <div class="d-flex align-items-center mt-4 justify-content-between">
        <div class="d-flex align-items-center">
          <img src="../assets/peopleOrange.svg" style="z-index: 10" />
          <span class="progress-span"
            >{{ numbers }}人({{ Number(percentBars).toFixed(2) }}%)</span
          >
          <progress
            class="top-progress"
            :value="percentBars"
            max="100"
          ></progress>
        </div>
        <span style="font-size: 20px"
          >會員總數&nbsp;&nbsp;{{ totalMember }}&nbsp;&nbsp;人</span
        >
      </div>
      <hr class="mt-4" style="margin-left: -30px; margin-right: -30px" />
      <p style="color: #71afb6">名單設定</p>

      <span class="me-3">篩選維度</span>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio1"
          value="option1"
          @click="radioSelected1"
          :checked="this.radioTab1 === true"
        />
        <!--      this.emailTab === true ||
            this.smsTab === true ||
            this.lineTab === true ||
            this.fbTab === true -->
        <label class="form-check-label" for="inlineRadio1"
          >依智能篩選
          <button
            class="btn btn-default button px-0 mx-0 pt-0"
            @click="customPop2()"
          >
            <span>
              &nbsp;
              <img
                src="../assets/question-img.svg"
                style="width: 1.5rem"
                @mouseover="CustomShow2 = true"
                @mouseout="CustomShow2 = false"
            /></span>
            <div class="parent-popout">
              <div v-show="CustomShow2" class="child-popout-channelmarketing">
                1. 整體名單為您會員名單中具備Email可投放的名單<br />
                2. 高回應為點擊次數高的會員<br />
                3. 高貢獻為在此渠道下有購買並且較高金額的會員<br />
                4. 高轉換為購買次數高的會員<br />
                <div class="container__arrow"></div>
              </div>
            </div></button
        ></label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio2"
          value="option2"
          @click="radioSelected2"
          :checked="this.radioTab2 === true"
        />
        <label class="form-check-label" for="inlineRadio2">依時間篩選</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio3"
          value="option3"
          @click="radioSelected3"
          :checked="this.radioTab3 === true"
        />
        <label class="form-check-label" for="inlineRadio3">依活動篩選</label>
      </div>

      <!-- 依智能篩選 tab -->
      <div class="mt-4" v-show="radioTab1">
        <div class="d-flex">
          <span v-if="this.emailTab === true">Email名單</span>
          <span v-else-if="this.smsTab === true">SMS名單</span>
          <span v-else-if="this.lineTab === true">Line UID名單</span>
          <span v-else-if="this.fbTab === true">FB連結名單</span>
          <span v-else>pikachu</span>
          <div class="title-button-div fs-small ms-4" style="width: 28vw">
            <button
              class="button-nofocus"
              :class="smartAll === true ? 'button-focus' : ''"
              style="width: 7vw"
              @click="actionOption1()"
            >
              整體名單
            </button>
            <button
              class="button-nofocus"
              :class="smartResponse === true ? 'button-focus' : ''"
              style="width: 7vw"
              @click="actionOption2()"
            >
              高回應
            </button>

            <button
              class="button-nofocus"
              :class="smartContribution === true ? 'button-focus' : ''"
              style="width: 7vw"
              @click="actionOption3()"
            >
              高貢獻
            </button>
            <button
              class="button-nofocus"
              :class="smartConversion === true ? 'button-focus' : ''"
              style="width: 7vw"
              @click="actionOption4()"
            >
              高轉換
            </button>
          </div>
        </div>
      </div>

      <!-- 依時間篩選 tab -->
      <div class="mt-4" v-show="radioTab2">
        <div class="d-flex justify-content-evenly time-button-container">
          <!-- <button  class="button-nofocus2" style=" visibility: hidden;">
               
          </button> -->
          <button
            class="button-nofocus2"
            :class="selectDay === '8day' ? 'button-focus' : ''"
            @click="daySwitch(8, 'days', '8day')"
          >
            過去7天
          </button>
          <button
            class="button-nofocus2"
            :class="selectDay === '14day' ? 'button-focus' : ''"
            @click="daySwitch(15, 'days', '14day')"
          >
            過去14天
          </button>
          <button
            class="button-nofocus2"
            :class="selectDay === '30day' ? 'button-focus' : ''"
            @click="daySwitch(31, 'days', '30day')"
          >
            過去30天
          </button>
          <button
            class="button-nofocus2"
            :class="selectDay === '1season' ? 'button-focus' : ''"
            @click="daySwitch(3, 'months', '1season')"
          >
            過去一季
          </button>
          <button
            class="button-nofocus2"
            :class="selectDay === '1year' ? 'button-focus' : ''"
            @click="daySwitch(1, 'years', '1year')"
          >
            過去一年
          </button>
          <button
            class="button-nofocus2"
            :class="allDaysActive === true ? 'button-focus' : ''"
            @click="allDays()"
          >
            全部
          </button>
        </div>

        <div class="mt-4 mb-4 d-flex">
          <p class="me-3">受眾類型</p>
          <div
            class="dropdown d-flex justify-content-evenly"
            v-if="this.emailTab === true"
          >
            <button
              class="
                btn
                dropdown-toggle dropdown-toggle-channel
                d-flex
                justify-content-between
                align-items-center
              "
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span> </span>
              {{ smartText }}
            </button>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('open', '開信再行銷')"
                >
                  開信再行銷
                </p>
              </li>
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('click', '點擊再行銷')"
                >
                  點擊再行銷
                </p>
              </li>
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('buy', '轉換再行銷')"
                >
                  轉換再行銷
                </p>
              </li>
            </ul>
          </div>
          <div class="dropdown d-flex justify-content-evenly" v-else>
            <button
              class="btn dropdown-toggle dropdown-toggle-channel"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ smartText }}
            </button>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('click', '點擊再行銷')"
                >
                  點擊再行銷
                </p>
              </li>
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('buy', '轉換再行銷')"
                >
                  轉換再行銷
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 依活動篩選 tab -->
      <div class="mt-4" v-show="radioTab3">
        <div class="primetable-div">
          <DataTable
            :value="selectedTable"
            v-model:selection="selectedProduct3"
            v-model:filters="filters1"
            :globalFilterFields="['script_name', 'label_name']"
            class="header-table mt-3"
            showGridlines
            editMode="cell"
            :resizableColumns="true"
            columnResizeMode="expand"
            responsiveLayout="scroll"
            :paginator="true"
            :rows="10"
            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
            paginatorPosition="top"
            breakpoint="960px"
            @rowSelect="onRowSelect"
          >
            <template #header>
              <div class="d-flex justify-content-start text-end me-5">
                <span class="p-input-icon-left">
                  <img
                    class="searchImgChannel"
                    src="../assets/search.svg"
                    alt="searchImg"
                  />

                  <input
                    type="text"
                    v-model="filters1['global'].value"
                    placeholder=""
                    class="custom-filter-bar"
                  />
                </span>
              </div>
            </template>
            <Column selectionMode="single" headerStyle="width: 3em"></Column>

            <Column
              field="script_name"
              header="活動名稱"
              :sortable="true"
              draggable="true"
            >
              <template #body="slotProps2">
                <div :class="stockClass(slotProps2.data)">
                  <span class="ms-2">
                    {{ slotProps2.data.script_name }}
                  </span>
                </div>
              </template>
              <!-- <template #filter="{filterModel}">
                        <input type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                    </template> -->
            </Column>
            <Column
              field="label_name"
              header="客群名稱"
              :sortable="true"
              v-if="this.fbTab === false"
            >
              <template #body="slotProps2">
                <div :class="stockClass(slotProps2.data)">
                  <span class="ms-2">
                    {{ slotProps2.data.label_name.toString() }}
                  </span>
                </div>
              </template>
            </Column>
            <Column
              field="clicks"
              header="點擊"
              :sortable="true"
              v-if="this.fbTab === true"
            ></Column>

            <Column field="time" header="發送時間" :sortable="true"></Column>
          </DataTable>
        </div>

        <div class="mt-4 mb-4 d-flex">
          <p class="me-3">受眾類型</p>
          <div
            class="dropdown d-flex justify-content-evenly"
            v-if="this.emailTab === true"
          >
            <button
              class="btn dropdown-toggle dropdown-toggle-channel"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ smartText }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('open', ' 開信再行銷')"
                >
                  開信再行銷
                </p>
              </li>
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('click', '點擊再行銷')"
                >
                  點擊再行銷
                </p>
              </li>
              <li>
                <p
                  class="dropdown-item"
                  style="color: #71afb6"
                  @click="smartSwitch('buy', '轉換再行銷')"
                >
                  轉換再行銷
                </p>
              </li>
            </ul>
          </div>
          <div class="dropdown d-flex justify-content-evenly" v-else>
            <button
              class="btn dropdown-toggle dropdown-toggle-channel"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ smartText }}
            </button>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <p
                  class="dropdown-item"
                  href="#"
                  style="color: #71afb6"
                  @click="smartSwitch('click', '點擊再行銷')"
                >
                  點擊再行銷
                </p>
              </li>
              <li>
                <p
                  class="dropdown-item"
                  href="#"
                  style="color: #71afb6"
                  @click="smartSwitch('buy', '轉換再行銷')"
                >
                  轉換再行銷
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- add template btn -->
    </div>

    <div class="container-fluid d-flex justify-content-around pe-4 mt-5">
      <div class="d-flex justify-content-around">
        <div
          class="col-6 d-flex justify-content-center"
          v-if="selectedID != ''"
        >
          <button
            type="submit"
            class="btn btn-lg add-template-button-color fs-large"
            style="padding: 8px 0px"
            @click="updateNewTodo"
          >
            <img
              src="../assets/save.svg"
              alt="save"
              style="position: relative; top: -2px"
            />
            &nbsp;&nbsp;儲存
          </button>
        </div>
        <div class="col-6 d-flex justify-content-center" v-else>
          <button
            type="button"
            class="
              btn-lg
              add-template-button-color
              button-color2
              fs-large
              d-flex
              justify-content-center
              align-items-center
            "
            style="padding: 8px 0px; border: none"
            @click="addNewTodo"
          >
            <img
              src="../assets/blackPlus.svg"
              alt="save"
            />
            <span class="ms-2"> 建立 </span>
          </button>
        </div>
      </div>
      <button
        class="btn btn-lg btn-primary button-color d-flex justify-content-center align-items-center"
        role="button"
        id="linkbutton"
        style="padding: 10px; height: 100%"
        @click="shareData(selectValueById.label_id, selectValueById.label_name)"
      >
        <span>
       行銷自動化
       </span>
       <img src="../assets/white-arrow.svg" alt="" class="ms-2"/>
      </button>
    </div>
  </div>
</template>

<script>
import FavoriteList from "../components/ChannelTemplate/FavoriteList.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Toast from "primevue/toast";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
// import Pagination from "v-pagination-3";
export default {
  name: "ChannelMarketing",
  components: {
    FavoriteList,
    DataTable,
    Column,
    Toast,
  },

  data() {
    return {
      notshow: true,
      // 名單data
      todosAdv: [],
      searchTodos: [],
      newTodoTitle: "",
      newTodoTitle2: "",

      // 切換用的selection
      selectedID: "",
      selectedTable: [],

      smartSelected: "all",
      smartSelected2: "buy",
      smartText: "選擇類型",

      channelSelected: "Email",
      startDateSelected: "all",
      endDateSelected: "all",
      IDselected: "",

      //rule_id 不等於K0000003真正的id是給來run rowselect的參數
      rowCondition: "",

      // 拿highlight名單的值
      selectValueById: "",

      childtodo: {},

      // table資料
      tableData: "",
      tableDataSMS: "",
      tableDataLine: "",
      tableDataFB: "",

      // 進度條data
      percentBars: 0,
      numbers: 0,

      totalMember: 0,

      loading: false,
      // 較驗設定
      isTag: "",
      titleError: false,
      titleErrMsg: "",
      ageError: false,
      ageErrMsg: "",
      CPTError: false,
      numberErrMsg: "",
      moneyError: false,
      consumeError: false,
      lastError: false,
      editinput: false,

      startDate: "2021-12-01",
      endDate: "2022-03-15",

      // tab設定
      emailTab: true,
      smsTab: false,
      lineTab: false,
      fbTab: false,

      radioTab1: true,
      radioTab2: false,
      radioTab3: false,

      // 依智能篩選為boolean
      smartAll: true,
      smartResponse: false,
      smartContribution: false,
      smartConversion: false,

      // 時間boolean
      selectDay: "",
      allDaysActive: true,
      CustomShow: false,
      CustomShow2: false,

      // table selected功能參數
      selectedProduct3: null,

      // table filter功能參數
      global: "",
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      //  CSVDATA
      json_data: [],

      selectChannel: "",
      i: 1,
      searchIconHide: true,

      clearingInputData: false,
    };
  },

  watch: {
    memberAmount: function () {
      this.percent = (this.memberAmount / this.members) * 100;
    },
    radioTab1: function () {
      this.getMemberData();
    },
    radioTab2: function () {
      this.getMemberData();
    },
    radioTab3: function () {
      this.getMemberData();
    },
    smartAll: function () {
      this.getMemberData();
    },
    smartResponse: function () {
      this.getMemberData();
    },
    smartContribution: function () {
      this.getMemberData();
    },
    smartConversion: function () {
      this.getMemberData();
    },
    emailTab: function () {
      this.getMemberData();
    },
    smsTab: function () {
      this.getMemberData();
    },
    lineTab: function () {
      this.getMemberData();
    },
    fbTab: function () {
      this.getMemberData();
    },
    selectedProduct3() {
      console.log("w33r", this.selectedProduct3);
    },
  },

  created: async function () {
    if (sessionStorage.getItem("lv") == "0") {
      this.notshow = 0;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "1") {
      this.notshow = 1;
      sessionStorage.removeItem("lv");
    } else if (sessionStorage.getItem("lv") == "2") {
      this.notshow = 2;
      sessionStorage.removeItem("lv");
    } 
    await Promise.all([
      this.get(),
      this.getTableEmail(),
      this.getTableSMS(),
      this.getTableLine(),
      this.getTableFB(),
      this.getMemberData(),
    ]);
    (this.selectedTable = this.tableData),
      console.log("intialtable", this.selectedTable);
    this.radioSelected1();
    this.initFilters1();
    console.log("w33r", this.selectedProduct3);
  },
  methods: {
    // 建立名單
    post: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};
      if (this.radioTab1 === true) {
        body = {
          label_name: this.newTodoTitle,
          audience_type: this.channelSelected,
          audience_action: this.smartSelected,
          filter: "smart",
          // time_range:null
        };
      } else if (this.radioTab2 === true) {
        body = {
          label_name: this.newTodoTitle,
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "time",
          start_time: this.startDateSelected,
          end_time: this.endDateSelected,
          time_range: this.selectDay,
        };
      } else {
        body = {
          label_name: this.newTodoTitle,
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "rule",
          rule_id: this.IDselected,
        };
      }

      await allapi2
        .remarketinglabelCreatePost(params, body)
        .then((res) => {
          console.log(res);
          const successModal = new bootstrap.Modal(
            document.getElementById("successModal"),
            {
              keyboard: false,
            }
          );
          successModal.show();
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("post連線有誤");
        });
      await this.get();
      await this.selectValue(this.todosAdv[0]);
      this.loading = false;
      this.selectedProduct3 = null;
    },

    // 分群名單質料
    get: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .remarketinglabelGet(params)
        .then((res) => {
          console.log(res);
          this.todosAdv = res.data.Data;
          this.searchTodos = res.data.Data;
          // this.todosAdv.sort((a, b) => {
          //   return b.modified_time - a.modified_time;
          // });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    patch: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        label_id: this.selectedID,
      };
      let body = {};
      if (this.radioTab1 === true) {
        body = {
          label_name: this.newTodoTitle,
          audience_type: this.channelSelected,
          audience_action: this.smartSelected,
          filter: "smart",
        };
      } else if (this.radioTab2 === true) {
        body = {
          label_name: this.newTodoTitle,
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "time",
          start_time: this.startDateSelected,
          end_time: this.endDateSelected,
          time_range: this.selectDay,
        };
      } else {
        body = {
          label_name: this.newTodoTitle,
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "rule",
          rule_id: this.IDselected,
        };
      }

      await allapi2
        .remarketinglabelLabelIdPatch(params, body)
        .then((res) => {
          console.log(res);
          this.loading = false;
          const saveModal = new bootstrap.Modal(
            document.getElementById("saveModal"),
            {
              keyboard: false,
            }
          );
          saveModal.show();
          //  $("#saveModal").modal("show");
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("patch連線有誤");
        });
      await this.get();
      console.log("test patch", this.todosAdv);
      await this.getSelectValue();
      await this.selectValue(this.todosAdv[0]);
      this.selectedProduct3 = null;
    },

    // get進度條與人數等等資料
    getMemberData: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      let body = {};
      if (this.radioTab1 === true) {
        body = {
          audience_type: this.channelSelected,
          audience_action: this.smartSelected,
          filter: "smart",
        };
      } else if (this.radioTab2 === true) {
        body = {
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "time",
          start_time: this.startDateSelected,
          end_time: this.endDateSelected,
        };
      } else {
        body = {
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "rule",
          rule_id: this.IDselected,
        };
      }

      await allapi2.remarketinglabelPost(params, body).then((res) => {
        console.log("有點", res);
        this.totalMember = res.data.Data.total_member;
        this.percentBars = res.data.Data.percentage;
        this.numbers = res.data.Data.members;
      });
    },

    // 活動圖表資料
    getTableEmail: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .remarketinglabelEmailGet(params)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.Data;
          this.selectedTable = this.tableData;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getTableSMS: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .remarketinglabelSmsGet(params)
        .then((res) => {
          console.log("smstable", res);
          this.tableDataSMS = res.data.Data;

          // console.log("change number", arrayData);
          // this.table = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getTableLine: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi2
        .remarketinglabelLineGet(params)
        .then((res) => {
          console.log("linetable", res);
          this.tableDataLine = res.data.Data;

          // console.log("change number", arrayData);
          // this.table = res.data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },

    getTableFB: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        // start_time: this.startDate,
        // end_time: this.endDate,
      };

      await allapi2
        .remarketinglabelFacebookGet(params)
        .then((res) => {
          console.log("fbtable", res);
          this.tableDataFB = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤" + err);
        });
    },

    //  模板操作功能
    addNewTodo: async function () {
      if (
        this.searchTodos.findIndex((d) => d.label === this.newTodoTitle) != "-1"
      ) {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "您要使用的名單名稱已有其他分群名單使用";
      } else if (this.newTodoTitle === null || this.newTodoTitle === "") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "必須輸入名單名稱";
      } else if (
        (this.radioTab1 === false &&
          this.radioTab2 === false &&
          this.radioTab3 === true &&
          this.selectedProduct3 === null) ||
        this.selectedProduct3 === ""
      ) {
        const unselectTableModal = new bootstrap.Modal(
          document.getElementById("unselectTableModal"),
          {
            keyboard: false,
          }
        );
        unselectTableModal.show();
      } else {
        this.loading = true;
        await this.post();
        this.editinput = false;
      }
    },

    // 複雜功能
    copytodo: async function (todo) {
      this.loading = true;
      //  this.selectedID = todo.labelId;
      //   console.log("id", this.selectedID);
      //   await this.getSelectValue();
      console.log("copytodo", todo);

      await this.selectValue(todo);

      this.selectedID = todo;
      while (
        this.searchTodos.findIndex(
          (d) => d.label === todo.label + "(" + this.i + ")"
        ) != "-1"
      ) {
        this.i++;
      }
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};
      if (this.radioTab1 === true) {
        body = {
          label_name: todo.label + "(" + this.i + ")",
          audience_type: this.channelSelected,
          audience_action: this.smartSelected,
          filter: "smart",
          // time_range:null
        };
      } else if (this.radioTab2 === true) {
        body = {
          label_name: todo.label + "(" + this.i + ")",
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "time",
          start_time: this.startDateSelected,
          end_time: this.endDateSelected,
          time_range: this.selectDay,
        };
      } else {
        body = {
          label_name: todo.label + "(" + this.i + ")",
          audience_type: this.channelSelected,
          audience_action: this.smartSelected2,
          filter: "rule",
          rule_id: this.IDselected,
        };
      }

      // const json = JSON.parse(todo.behavior);
      // const behaviorsKey = Object.keys(this.behaviors);
      // for (const item in behaviorsKey) {
      //   console.log(behaviorsKey[item]);
      //   body.behavior[behaviorsKey[item]] = json[behaviorsKey[item]];
      // }

      await allapi2
        .remarketinglabelCreatePost(params, body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("post連線有誤");
        });
      await this.get();
      await this.getSelectValue();
      await this.selectValue(this.todosAdv[0]);
      this.loading = false;
    },

    search(val) {
      const searched = this.searchTodos.filter((d) => d.label.includes(val));
      this.todosAdv = searched;
    },

    removeTodo(todo) {
      // $("#alertModalDelete").modal("show");
      const alertModalDelete = new bootstrap.Modal(
        document.getElementById("alertModalDelete"),
        {
          keyboard: false,
        }
      );
      alertModalDelete.show();
      this.childtodo = todo;
    },

    deleteTodo: async function () {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        label_id: this.selectedID,
      };

      await allapi2
        .remarketinglabelLabelIdDelete(params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("delete連線有誤");
        });
      await this.get();
      this.selectValue(this.todosAdv[0]);
      this.loading = false;
    },
    updateNewTodo: async function () {
      if (
        this.searchTodos.findIndex(
          (d) => d.label === this.newTodoTitle && d.labelId != this.selectedID
        ) != "-1"
      ) {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "您要使用的名單名稱已有其他分群名單使用";
      } else if (this.newTodoTitle === null || this.newTodoTitle === "") {
        this.$refs.titleInput.focus();
        this.titleError = true;
        this.titleErrMsg = "必須輸入名單名稱";
      } else if (
        (this.radioTab1 === false &&
          this.radioTab2 === false &&
          this.radioTab3 === true &&
          this.selectedProduct3 === null) ||
        this.selectedProduct3 === ""
      ) {
        const unselectTableModal = new bootstrap.Modal(
          document.getElementById("unselectTableModal"),
          {
            keyboard: false,
          }
        );
        unselectTableModal.show();
      } else {
        this.loading = true;
        await this.patch();
        this.editinput = false;
        window, scrollTo(0, 0);
      }
    },

    // highlight點選的
    selectValue: async function (todo) {
      console.log("selectvaLUE", todo);
      if (this.selectedID != todo.labelId) {
        this.selectedID = todo.labelId;
        console.log("id", this.selectedID);
        await this.getSelectValue();

        // 選取後人數變化
        this.percentBars = this.selectValueById.percentage;
        this.numbers = this.selectValueById.members;
        this.totalMember = this.selectValueById.total_member;
        this.newTodoTitle = this.selectValueById.label_name;

        let needTable = [];

        // 名單設定變化audience_type
        if (this.selectValueById.audience_type === "Email") {
          needTable = this.tableData;
          this.emailTab = true;
          this.smsTab = false;
          this.lineTab = false;
          this.fbTab = false;
          this.selectedTable = this.tableData;
        } else if (this.selectValueById.audience_type === "SMS") {
          needTable = this.tableDataSMS;
          this.emailTab = false;
          this.smsTab = true;
          this.lineTab = false;
          this.fbTab = false;
          this.selectedTable = this.tableDataSMS;
        } else if (this.selectValueById.audience_type === "Facebook") {
          needTable = this.tableDataFB;
          this.emailTab = false;
          this.smsTab = false;
          this.lineTab = false;
          this.fbTab = true;
          this.selectedTable = this.tableDataFB;
        } else if (this.selectValueById.audience_type === "Line") {
          needTable = this.tableDataLine;
          this.emailTab = false;
          this.smsTab = false;
          this.lineTab = true;
          this.fbTab = false;
          this.selectedTable = this.tableDataLine;
        }
        // 名單設定變化filter
        if (this.selectValueById.filter === "smart") {
          this.radioTab1 = true;
          this.radioTab2 = false;
          this.radioTab3 = false;
        } else if (this.selectValueById.filter === "time") {
          this.radioTab1 = false;
          this.radioTab2 = true;
          this.radioTab3 = false;
        } else if (this.selectValueById.filter === "rule") {
          console.log("needtable", needTable);
          const findRuleId = needTable.find((d) => {
            return d.rule_id === this.selectValueById.rule_id;
          });
          this.selectedProduct3 = findRuleId;
          this.radioTab1 = false;
          this.radioTab2 = false;
          this.radioTab3 = true;
        }

        // 名單設定變化audience_action
        if (this.selectValueById.audience_action === "contribution") {
          this.smartContribution = true;
          this.smartAll = false;
          this.smartResponse = false;
          this.smartConversion = false;
        } else if (this.selectValueById.audience_action === "all") {
          this.smartContribution = false;
          this.smartAll = true;
          this.smartResponse = false;
          this.smartConversion = false;
        } else if (this.selectValueById.audience_action === "response") {
          this.smartContribution = false;
          this.smartAll = false;
          this.smartResponse = true;
          this.smartConversion = false;
        } else if (this.selectValueById.audience_action === "conversion") {
          this.smartContribution = false;
          this.smartAll = false;
          this.smartResponse = false;
          this.smartConversion = true;
        }
        // 名單設定變化"過去幾天""
        if (this.selectValueById.time_range != null) {
          this.allDaysActive = false;
          this.selectDay = this.selectValueById.time_range;
          this.startDateSelected = this.selectValueById.start_time;
          this.endDateSelected = this.selectValueById.end_time;
        } else {
          this.allDaysActive = true;
          this.selectDay = "";
        }

        // 受眾類型設定變化
        if (this.selectValueById.audience_action === "click") {
          this.smartText = "點擊再行銷";
        } else if (this.selectValueById.audience_action === "open") {
          this.smartText = "開信再行銷";
        } else if (this.selectValueById.audience_action === "buy") {
          this.smartText = "轉換再行銷";
        }

        //  依活動設定變化
        if (
          this.selectValueById.rule_id === null ||
          this.selectValueById.rule_id === "time"
        ) {
          this.rowCondition = "";
          console.log("不用拿rule_id", this.rowCondition);
        } else {
          this.rowCondition = "yes";
          this.IDselected = this.selectValueById.rule_id;
          console.log("測試rule_id", this.IDselected);
          this.onRowSelect();
        }

        //  取消highlight點選回到預設
      }
      // else if (this.selectedID === todo.labelId) {
      //   this.selectedID = "";
      //   // this.percentBars =
      //   this.newTodoTitle = "";
      //   this.emailTab = true;
      //   (this.smsTab = false),
      //     (this.lineTab = false),
      //     (this.fbTab = false),
      //     (this.radioTab1 = true);
      //   (this.radioTab2 = false),
      //     (this.radioTab3 = false),
      //     this.getMemberData();
      // }
    },

    getSelectValue: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        label_id: this.selectedID,
      };
      // const body = {

      // };

      await allapi2
        .remarketinglabelLabelIdGet(params)
        .then((res) => {
          console.log("get-data-by-label-id", res);
          this.selectValueById = res.data.Data;
          console.log("selevtVHighlight", this.selectValueById);
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("post連線有誤");
        });
    },

    download() {
      const buildData = (data) => {
        return new Promise((resolve, reject) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(data[0]);
          arrayData.push(arrayTitle);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(data, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title] || "無";
              items.push(item);
            });
            arrayData.push(items);
          });

          resolve(arrayData);
        });
      };
      const downloadCSV = (data) => {
        let csvContent = "";
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(",") + "\n";
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName =
          "下載" +
          this.newTodoTitle +
          "資料" +
          new Date().toISOString() +
          ".csv";

        // 建立一個 a，並點擊它
        let link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
        );
        link.setAttribute("download", fileName);
        link.click();
      };
      const data = this.json_data;
      buildData(data).then((data) => downloadCSV(data));
    },

    getcsv: async function () {
      if (this.editinput) {
        const alertModal2 = new bootstrap.Modal(
          document.getElementById("alertModal2"),
          {
            keyboard: false,
          }
        );
        alertModal2.show();
        // $("#alertModal2").modal("show");
        this.$refs.titleInput.focus();
      } else if (this.selectedID === "") {
        const alertModal3 = new bootstrap.Modal(
          document.getElementById("alertModal3"),
          {
            keyboard: false,
          }
        );
        alertModal3.show();
      } else {
        if (this.selectedID != "") {
          const token = sessionStorage.getItem("access_token");
          const params = {
            label_id: this.selectedID,
            Authorization: "Bearer " + token,
          };
          await allapi2
            .remarketinglabelExportLabelIdGet(params)
            .then((res) => {
              console.log("getcsv", res.data.Data);
              this.json_data = res.data.Data;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              alert("連線有誤");
            });

          this.download();
        }
      }
    },

    getRemaAct() {
      if (this.selectedID === "") {
        const alertModal3 = new bootstrap.Modal(
          document.getElementById("alertModal3"),
          {
            keyboard: false,
          }
        );
        alertModal3.show();
      } else {
        const roadModal = new bootstrap.Modal(
          document.getElementById("roadModal"),
          {
            keyboard: false,
          }
        );
        roadModal.show();
        // data-bs-target="#roadModal"
      }
    },

    shareData(labelId, label) {
      if (this.numbers != this.totalmembers) {
        this.label_number = this.numbers;
      } else {
        this.label_number = this.memberAmount;
      }

      console.log(labelId, label, this.label_number);

      if (this.newTodoTitle === "") {
        const alertModal2 = new bootstrap.Modal(
          document.getElementById("alertModal2"),
          {
            keyboard: false,
          }
        );
        alertModal2.show();
        // $("#alertModal2").modal("show");
        this.$refs.titleInput.focus();
      } else {
        if (
          this.emailTab != false ||
          this.fbTab != false ||
          this.smsTab != false ||
          this.lineTab != false
        ) {
          if (this.emailTab === true) {
            this.$router.push({
              name: "Productarchive",
            });
            sessionStorage.setItem("getlabelId", labelId);
            sessionStorage.setItem("getlabelTitle", label);
            sessionStorage.setItem("getlabelNumber", this.numbers);
            // this.$store.dispatch("getlabelId", labelId);
            // this.$store.dispatch("getlabelTitle", label);
            // this.$store.dispatch("getlabelNumber", this.label_number);
          } else if (this.smsTab === true) {
            this.$router.push({
              name: "ProductSmsTemplate",
            });
            sessionStorage.setItem("getlabelId", labelId);
            sessionStorage.setItem("getlabelTitle", label);
            sessionStorage.setItem("getlabelNumber", this.numbers);
            // this.$store.dispatch("getlabelId", labelId);
            // this.$store.dispatch("getlabelTitle", label);
            // this.$store.dispatch("getlabelNumber", this.label_number);
          }
        } else {
          alert("管道尚未開放");
        }
      }
    },

    // 管道切換tab
    clickEmail() {
      this.emailTab = true;
      this.smsTab = false;
      this.lineTab = false;
      this.fbTab = false;
      this.selectedTable = this.tableData;
      this.channelSelected = "Email";

      console.log("emaitable", this.selectedTable);
    },

    clickSMS() {
      this.emailTab = false;
      this.smsTab = true;
      this.lineTab = false;
      this.fbTab = false;
      this.selectedTable = this.tableDataSMS;

      this.channelSelected = "SMS";

      console.log("smstable", this.selectedTable);
    },
    clickLine() {
      this.emailTab = false;
      this.smsTab = false;
      this.lineTab = true;
      this.fbTab = false;
      this.selectedTable = this.tableDataLine;
      this.channelSelected = "Line";
      console.log("linetable", this.selectedTable);
    },

    clickFB() {
      this.emailTab = false;
      this.smsTab = false;
      this.lineTab = false;
      this.fbTab = true;
      this.selectedTable = this.tableDataFB;

      this.channelSelected = "Facebook";
      console.log("fbtable", this.selectedTable);
    },

    // radio button切換
    radioSelected1() {
      this.radioTab1 = true;
      this.radioTab2 = false;
      this.radioTab3 = false;
    },
    radioSelected2() {
      this.radioTab1 = false;
      this.radioTab2 = true;
      this.radioTab3 = false;
    },
    radioSelected3() {
      this.radioTab1 = false;
      this.radioTab2 = false;
      this.radioTab3 = true;
      // this.IDselected = ["K0000003"];
      this.getMemberData();
    },

    // 3高切換tab
    actionOption1() {
      this.smartAll = true;
      this.smartResponse = false;
      this.smartContribution = false;
      this.smartConversion = false;
      this.smartSelected = "all";
    },
    actionOption2() {
      this.smartAll = false;
      this.smartResponse = true;
      this.smartContribution = false;
      this.smartConversion = false;
      this.smartSelected = "response";
    },
    actionOption3() {
      this.smartAll = false;
      this.smartResponse = false;
      this.smartContribution = true;
      this.smartConversion = false;
      this.smartSelected = "contribution";
    },
    actionOption4() {
      this.smartAll = false;
      this.smartResponse = false;
      this.smartContribution = false;
      this.smartConversion = true;
      this.smartSelected = "conversion";
    },

    // dropdown切換function
    smartSwitch(a, b) {
      this.smartSelected2 = a;
      this.smartText = b;
      console.log("options-action", a);
      this.getMemberData();
    },

    // 時間切換function
    daySwitch(a, b, c) {
      this.selectDay = c;
      this.allDaysActive = false;

      const start = moment().subtract(a, b);
      const end = moment().subtract(1, "days");

      const strStart = start._d.toISOString().slice(0, 10);
      const endStart = end._d.toISOString().slice(0, 10);
      console.log("start", strStart);
      console.log("end", endStart);
      this.startDateSelected = strStart;
      this.endDateSelected = endStart;
      // this.newTodoTitle2  = this.newTodoTitle.concat("--" +  this.startDateSelected + "-" +  this.endDateSelected);
      // this.newTodoTitle = this.newTodoTitle2
      // console.log("length", this.newTodoTitle.slice(2))

      // console.log("wd", this.newTodoTitle.concat("--" + this.startDateSelected + "-" + this.endDateSelected))
      this.getMemberData();
    },

    allDays() {
      this.allDaysActive = true;
      this.selectDay = "";
      this.startDateSelected = "all";
      this.endDateSelected = "all";
      this.getMemberData();
    },

    onRowSelect(event) {
      if (this.rowCondition === "") {
        if (this.emailTab === true) {
          this.IDselected = event.data.rule_id;
          this.getMemberData();
          console.log("event.data.ruleID", this.IDselected);
        } else if (this.smsTab === true) {
          this.IDselected = event.data.rule_id;
          this.getMemberData();
          console.log("event.data.ruleID", this.IDselected);
        } else if (this.lineTab === true) {
          this.IDselected = event.data.rule_id;
          console.log("event.data.ruleID", this.IDselected);
          this.getMemberData();
        } else if (this.fbTab === true) {
          this.IDselected = event.data.rule_id;
          console.log("event.data.ruleID", this.IDselected);
          this.getMemberData();
        } else {
          console.log("hello-pikachu");
        }
      } else if (this.rowCondition !== "") {
        this.selectedProduct3;
        this.IDselected = this.selectValueById.rule_id;
        console.log("onrowselct", this.IDselected);
        this.rowCondition = "";
        this.getMemberData();
      }

      // this.$toast.add({severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.ruleID, life: 3000});
    },
    onRowUnselect(event) {
      // this.$toast.add({severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.ruleID, life: 3000});
    },

    stockClass(data) {
      return [
        {
          noevent: data.事件 === 2,
          hasevent: data.事件 === 0,
        },
      ];
    },
    stockClass2(data) {
      return [
        {
          "table-radio-button": data,
        },
      ];
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    inputChange() {
      this.editinput = true;
    },
    hideSearchIcon() {
      this.searchIconHide = !this.searchIconHide;
    },
    customPop() {
      this.CustomShow = !this.CustomShow;
    },
    customPop2() {
      this.CustomShow2 = !this.CustomShow2;
    },
    clearall() {
      this.clearInputs();
      this.selectedID = "";
    },
    clearInputs() {
      this.newTodoTitle = "";
      this.newSubsec = "";

      this.radioTab1 = true;
      this.radioTab2 = false;
      this.radioTab3 = false;
      this.selectedID = "";
      this.titleError = false;
      this.textError = false;
      this.selectDay = "all";
      this.emailTab = true;
      this.smsTab = false;
      this.lineTab = false;
      this.fbTab = false;
      this.clearingInputData = true;
      this.startDateSelected = "";
      this.endDateSelected = "";
      this.selectedProduct3 = null;
    },
  },
};
</script>

<style>
/* ChannelMarketing */
.p-checkbox {
  width: 14px;

  height: 14px;
  margin-bottom: 17px;
}

.p-checkbox:first-child {
  margin-bottom: 0px;
}

.container-item {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  margin-top: 2rem;
  /* padding: 15px 20px 24px; */
}

.tab-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  margin-top: 2rem;
  padding: 15px 30px 24px;
}

.title-button-div {
  background-color: #eaf3f4;
  border-radius: 8px;
  height: 40px;
}

.button-nofocus {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 5.7vw;
  height: 40px;
}

.button-nofocus2 {
  background-color: #eaf3f4;
  border: none;
  border-radius: 8px;
  width: 9.7vw;
  height: 40px;
}

.button-focus {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  color: white;
  border-radius: 8px;
  height: 40px;
  /* transition:   1s  ; */
}

.btn-lg {
  min-width: 80%;
  border-radius: 10px;
}

.add-template-button-color {
  background-color: #eaf3f4;
}

.progress-span {
  position: absolute;
  left: 45%;
  z-index: 30;
}

.top-progress {
  position: relative;
  background-color: #fff6f0;
  left: -4px;
  border-radius: 10px;
  width: 49vw;
  height: 40px;
}

.progress {
  border-radius: 10px;
  width: 100%;
  /* height: 2rem; */
  margin-left: -5%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

progress::-webkit-progress-bar {
  background-color: #fff6f0;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #fc9f66;
  border-radius: 0 10px 10px 0;
}

.form-check-input:checked {
  background-color: #71afb6;
  border-color: #71afb6;
}

.time-button-container {
  margin-left: 3.5rem;
}

.dropdown-toggle-channel {
  color: #71afb6;
  border-radius: 10px;
  width: 30vw;
  border-color: #c4c4c4;
}

.primetable-div {
  margin-left: 5.5rem;
  width: 90%;
}

.hasevent {
  background-color: #eaf3f4;
  text-align: center;
  height: 58px;
  line-height: 58px;
}

.noevent {
  background-color: #ffedc9;
  text-align: center;
  height: 58px;
  line-height: 58px;
}

.road-button {
  opacity: 0.3;
  padding: 5px;
  border: 1px solid #c4c4c4;
  background-color: white;
  border-radius: 8px;
  width: 265px;
}

.road-button-cancel {
  padding: 5px;
  border: none;
  background-color: #eaf3f4;
  border-radius: 8px;
  width: 125px;
}

.road-button-confirm {
  padding: 5px;
  border: none;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  border-radius: 8px;
  color: white;
  width: 125px;
}

.road-button-email {
  height: 35px;
  padding: 5px;
  border: 1px solid #c4c4c4;
  background-color: white;
  border-radius: 8px;
  width: 265px;
}

.road-button-email-focus {
  padding: 5px;
  border: 1px solid #c4c4c4;
  background-image: linear-gradient(to bottom right, #b5dbe0, #71aeb5);
  color: white;
  border-radius: 8px;
  width: 265px;
}

.p-radiobutton {
  width: 16px;
  height: 16px;
  margin-bottom: 1rem;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  margin-bottom: 1rem;
  background: #71afb6 !important;
  background-color: #71afb6 !important;
  border-color: #71afb6 !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  color: #00a2ed !important;
}

.p-datatable .p-datatable-header {
  background: #fff !important;
  border: none !important;
}

.p-paginator {
  background-color: transparent !important;
  border: none !important;
  /* justify-content: end !important; */
}

.p-paginator-prev {
  border: 1px solid #d4d4d8;
  color: #71717a;
  min-width: 3rem;
  background-color: #eaf3f4 !important;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.p-paginator-next {
  border: 1px solid #d4d4d8;
  color: #71717a;
  min-width: 3rem;
  background-color: #eaf3f4 !important;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.p-paginator-pages .p-paginator-page {
  border: 1px solid #d4d4d8;
  color: #71717a;
  background-color: #eaf3f4 !important;
  min-width: 3rem;
  height: 3rem;
  margin: 0 0 0 -1px;
  transition: none;
  border-radius: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border-color: #71afb6 !important;
  background-color: #71afb6 !important;
  color: #fff !important;
  margin-right: 1px;
}

/* .p-datatable-wrapper {
  margin-top: 30px;
} */

.p-checkbox {
  border: #575757 solid 1px;
  border-radius: 5px;
  width: 21px;
  height: 21px;
}

/* tr{
  filter: drop-shadow(0.16rem 0.16rem 0.16rem rgba(84, 84, 84, 0.241));
} */
/* .p-datatable-thead > tr {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
} */

.custom-filter-bar {
  top: 2.6rem;
  left: 0rem;
  position: relative;
  background-color: #eaf3f4;
  border: none;
  border-radius: 20px;
  width: 25vw;
  height: 3rem;
  text-indent: 30px;
}

.searchImgChannel {
  position: relative;
  top: 2.5rem;
  left: 30px;
  z-index: 6;
}

.btn-download {
  background-color: #eaf3f4;
  color: #71afb6;
  font-size: 20px;
}

.btn-lg {
  min-width: 31rem;
  font-size: 20px;
  border-radius: 10px;
}

.button-color {
  /* background-color:#EAF3F4; */
  border: none;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
  color: #fff;
  height: 40px;
}

.p-radiobutton {
  border: 1px solid #575757;

  border-radius: 10px;
}

.p-radiobutton-focused {
  background-color: #71afb6;
}

.child-popout-channelmarketing {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  width: 30rem;
  right: -500px;
  top: -70px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
/* .p-radiobutton-box .p-component .p-highlight[aria-checked="true"]{
  background-color: #71afb6;
  color: #71afb6;
} */
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 14px;
  height: 14px;
  /* transition-duration: 0.2s; */
  background-color: #71afb6;
  margin-top: 13px;
}
</style>
