<template>
  <div class="container home">
    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- main -->
    <div class="header d-flex justify-content-between">
      <div class="d-flex">
        <span class="title-span fs-medium" style="padding-left: 10px"
          >線下購物行為</span
        >
      </div>
      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span class="d-flex align-items-center">
            <img
              class="me-2"
              src="../assets/black-calendar.svg"
              alt="calendar"
            />
            <span>{{ dateSelector }}</span>
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="
            position: absolute;
            right: 5px;
            color: #c4c4c4;
            font-size: 14px;
          "
        >
          {{ endDate }} 為最近一筆資料
        </p>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              :from-page="{ month: this.endMonth, year: this.endYear }"
              @dayclick="onDayClick"
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              is-expanded
              :min-date="hasDataFirst"
              :max-date="hasDataEnd"
            />
            <p class="mt-3 mb-0" v-if="vue3Date.start != ''">
              {{ vue3Date.start }}~{{ vue3Date.end }}
            </p>

            <button
              class="btn confrim-btn mt-3"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="padding: 0px">
      <div class="d-flex justify-content-between">
        <div
          class="d-flex justify-content-between flex-wrap"
          style="width: 49%"
        >
          <div style="width: 49%">
            <div class="number-div">
              <p class="fs-small" style="color: #71afb6;height:35.36px">
                <span>線下會員數</span>
              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p
                  class="fs-medium"
                  v-if="behaviorOffline.會員總數 === undefined"
                >
                  - -
                </p>
                <p v-else>{{ behaviorOffline.會員總數 }}</p>
                <p>(人)</p>
              </div>
            </div>
          </div>
          <div style="width: 49%">
            <div class="number-div">
              <p class="fs-small" style="color: #71afb6;height:35.36px">
                <span>線下新註冊會員</span>
              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p
                  class="fs-medium"
                  v-if="behaviorOffline.新註冊會員 === undefined"
                >
                  - -
                </p>
                <p v-else>{{ behaviorOffline.新註冊會員 }}</p>
                <p>(人)</p>
              </div>
            </div>
          </div>

          <div style="width: 49%">
            <div class="number-div" style="margin-top: 25px">
              <p class="fs-small" style="color: #71afb6">
                <span class="me-2">線下會員首購率</span>
                <BtnInfoBox content="針對所選時段，首次線下註冊會員是否有進行購買之比率。" direction="right"></BtnInfoBox>
                
              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p
                  class="fs-medium"
                  v-if="behaviorOffline.首購率 === undefined"
                >
                  - -
                </p>
                <p v-else>{{ Number(behaviorOffline.首購率).toFixed(2) }}</p>
                <p>(%)</p>
              </div>
            </div>
          </div>
          <div style="width: 49%">
            <div class="number-div" style="margin-top: 25px">
              <p class="fs-small" style="color: #71afb6">
                <span class="me-2">線下會員回購率</span>
                <BtnInfoBox content="針對所選時段的同比，線下會員是否有再次購買之比率。" direction="right"></BtnInfoBox>

              </p>
              <div
                class="d-flex justify-content-between fs-medium money-number"
              >
                <p
                  class="fs-medium"
                  v-if="this.behaviorOffline.首購率 === undefined"
                >
                  - -
                </p>
                <p v-else>
                  {{ Number(this.behaviorOffline2.回購率).toFixed(2) }}
                </p>
                <p>(%)</p>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 0; width: 49%">
          <div class="pie-div" style="position: relative">
            <span class="fs-small" style="color: #71afb6">新客與回購比例</span>
            <span
              class="text-center"
              style="position: absolute; top: 30%; left: 30%; color: #c4c4c4"
              v-if="this.seriesPie.every((d) => d === 0)"
            >
              <img src="../assets/missData.svg" alt="" style="width: 200px" />
              <br />
              此時間段無資料</span
            >
            <apexchart
              type="donut"
              height="300"
              :options="chartOptionsPie"
              :series="seriesPie"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="container" style="padding: 0px">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap" style="width: 49%">
            <div class="col-12">
              <div class="pie-div" style="position: relative">
                <span class="fs-small" style="color: #71afb6">線下營業額</span>
                <span
                  class="text-center"
                  style="
                    position: absolute;
                    top: 30%;
                    left: 30%;
                    color: #c4c4c4;
                  "
                  v-if="this.seriesPie2.every((d) => d === 0)"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <br />
                  此時間段無資料</span
                >
                <apexchart
                  type="donut"
                  height="300"
                  :options="chartOptionsPie2"
                  :series="seriesPie2"
                ></apexchart>
              </div>
            </div>
            <div class="col-12">
              <div class="pie-div" style="position: relative">
                <span class="fs-small" style="color: #71afb6">線下訂單數</span>
                <span
                  class="text-center"
                  style="
                    position: absolute;
                    top: 30%;
                    left: 30%;
                    color: #c4c4c4;
                  "
                  v-if="this.seriesPie3.every((d) => d === 0)"
                >
                  <img
                    src="../assets/missData.svg"
                    alt=""
                    style="width: 200px"
                  />
                  <br />
                  此時間段無資料</span
                >
                <apexchart
                  type="donut"
                  height="300"
                  :options="chartOptionsPie3"
                  :series="seriesPie3"
                ></apexchart>
              </div>
            </div>
          </div>
          <div style="width: 49%">
            <div class="col-12">
              <div
                class="
                  number-div2
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <span class="fs-small" style="color: #71afb6"
                  >平均購買間隔時長</span
                >
                <span class="fs-medium" v-if="barData.平均購買間隔時長 === null"
                  >- - (天)</span
                >
                <span class="fs-medium" v-else
                  >{{
                    Number(barData.平均購買間隔時長).toFixed(2)
                  }}&nbsp;(天)</span
                >
              </div>
            </div>

            <div class="col-12">
              <div class="columm-div" style="position: relative; height: 265px">
                <span class="fs-small" style="color: #71afb6"
                  >購買時間點(星期)</span
                >
                <span
                  style="
                    position: absolute;
                    top: 45%;
                    left: 40%;
                    color: #c4c4c4;
                  "
                  v-if="this.seriesColumm[0].data.every((d) => d === 0)"
                  >此時間段無資料</span
                >
                <apexchart
                  type="bar"
                  height="200"
                  :options="chartOptionsColumm"
                  :series="seriesColumm"
                ></apexchart>
              </div>
            </div>
            <div class="col-12">
              <div class="columm-div" style="position: relative; height: 265px">
                <span class="fs-small" style="color: #71afb6"
                  >購買時間點(小時)</span
                >
                <span
                  style="
                    position: absolute;
                    top: 45%;
                    left: 40%;
                    color: #c4c4c4;
                  "
                  v-if="this.seriesColumm2[0].data.every((d) => d === 0)"
                  >此時間段無資料</span
                >
                <apexchart
                  class="columm-div-scroll"
                  type="bar"
                  width="1000"
                  height="200"
                  :options="chartOptionsColumm2"
                  :series="seriesColumm2"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allapi } from "../../public/static/api/apigClient.js";
import moment from "moment";
import { Calendar, DatePicker } from "v-calendar";
import VueApexCharts from "vue3-apexcharts";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "OfflineBehavior",
  components: {
    apexchart: VueApexCharts,
    Calendar,
    DatePicker,
    BtnInfoBox,
  },
  data() {
    return {
      loading: false,
      memberShow: false,
      memberShow2: false,
      behaviorOffline: {},
      behaviorOffline2: {},
      barData: {},

      vue3Date: {
        start: "",
        end: "",
        //  start: new Date("2021-12-06").toISOString().slice(0, 10),
        // end: new Date("2021-12-10").toISOString().slice(0, 10),
        // start: new Date(2021, 0, 1),
        // end: new Date(2021, 0, 11),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        // timeAdjust: "12:00:00",
      },
      startDate: "2021-11-21",
      endDate: "2021-12-12",
      hasDataFirst: "",
      hasDataEnd: "",
      endYear: 2022,
      endMonth: 6,

      dateSelector: "全部",

      comebackBuy: "",
      // piedata
      seriesPie: [],
      chartOptionsPie: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#63A7C8", "#F9C357"],
        labels: ["線上", "線下"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      // piedata 2
      seriesPie2: [],
      chartOptionsPie2: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#63A7C8", "#F9C357"],
        labels: ["線上", "線下"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      // piedata 3
      seriesPie3: [],
      chartOptionsPie3: {
        chart: {
          height: 330,
          type: "donut",
        },
        colors: ["#63A7C8", "#F9C357"],
        labels: ["線上", "線下"],
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      // columm chart
      seriesColumm: [
        {
          name: "消費金額",
          data: [],
        },
      ],
      chartOptionsColumm: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "星期一",
            "星期二",
            "星期三 ",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
        },
        yaxis: {
          title: {
            text: "消費次數",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      // columm chart 2
      seriesColumm2: [
        {
          name: "消費金額",
          data: [],
        },
      ],
      chartOptionsColumm2: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        colors: ["#71AFB6"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "星期一",
            "星期二",
            "星期三 ",
            "星期四",
            "星期五",
            "星期六",
            "星期日",
          ],
        },
        yaxis: {
          title: {
            text: "消費金額",
          },
          labels: {
            style: {
              fontSize: "12px",
            },
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },

  created: async function () {
    this.loading = true;
    await this.getTime();
    await this.getRecentMonth();

    this.highlightVue3Date();
    this.loading = false;
  },
  methods: {
    onDayClick(day) {
      this.endYear = day.year;
      this.endMonth = day.month;
    },
    highlightVue3Date: async function () {
      this.endMonth = Number(this.endDate.slice(5, 7));
      this.endYear = Number(this.endDate.slice(0, 4));

      this.vue3Date = {
        start: new Date(this.startDate).toISOString().slice(0, 10),
        end: new Date(this.endDate).toISOString().slice(0, 10),
      };
    },

    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeMemberGet(params)
        .then((res) => {
          console.log(res.data.Data.end_date);

          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getcomebackBuy: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallAllTimeGet(params)
        .then((res) => {
          console.log("回購間隔", res);
          this.comebackBuy = res.data.Data.整體;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorOffline: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallRateGet(params)
        .then((res) => {
          console.log("BehaviorOffline2OverallRate1", res);
          if (res.data.Data.線下 === undefined) {
            console.log("無線下資料");
          } else {
            res.data.Data.線下.會員總數 =
              res.data.Data.線下.會員總數.toLocaleString();
            this.behaviorOffline = res.data.Data.線下;
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorOffline2: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOverallRate2Get(params)
        .then((res) => {
          console.log("BehaviorOffline2OverallRate2", res);

          this.behaviorOffline2 = res.data.Data.線下;

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorOfflinePie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOfflinePieGet(params)
        .then((res) => {
          console.log(res.data.Data);
          this.seriesPie = Object.values(res.data.Data.線下.人數).map((d) => {
            return parseInt(d);
          });
          this.chartOptionsPie = {
            labels: Object.values(res.data.Data.線下.標題),
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBehaviorOfflineTwoPie: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOfflineMemberGet(params)
        .then((res) => {
          console.log(res.data.Data);
          this.seriesPie2 = res.data.Data.營業額佔比;
          this.chartOptionsPie2 = {
            labels: res.data.Data.類別,
          };
          this.seriesPie3 = res.data.Data.訂單數佔比;
          this.chartOptionsPie3 = {
            labels: res.data.Data.類別,
          };

          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    // getBehaviorOfflineTwoBar: async function () {
    //   const body = {
    //     start_date: this.startDate,
    //     end_date: this.endDate,
    //   };
    //   await allapi
    //     .behaviorOfflineTimePost(null, body)
    //     .then((res) => {
    //       console.log(res.data.Data);
    //       this.barData = res.data.Data;
    //       this.seriesColumm = [
    //         {
    //           data: res.data.Data.星期.數量,
    //         },
    //       ];
    //       this.seriesColumm2 = [
    //         {
    //           data: res.data.Data.早中晚.數量,
    //         },
    //       ];
    //       this.chartOptionsColumm2 = {
    //         xaxis: { categories: res.data.Data.早中晚.時間 },
    //       };

    //       // this.loading = false;
    //     })
    //     .catch((err) => {
    //       console.log("連線有誤");
    //       console.log(err);
    //       this.loading = false;
    //       alert("連線有誤");
    //     });
    // },
    getBuyTimeWeek: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOfflineWeekGet(params)
        .then((res) => {
          this.seriesColumm = [
            {
              data: res.data.Data.星期.數量,
            },
          ];
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyTimeDay: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOfflineDayGet(params)
        .then((res) => {
          console.log("OfflineDay", res);

          this.seriesColumm2 = [
            {
              data: res.data.Data.早中晚.數量,
            },
          ];
          this.chartOptionsColumm2 = {
            xaxis: { categories: res.data.Data.早中晚.時間 },
          };
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getBuyTime: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      await allapi
        .behaviorOfflineTimeGet(params)
        .then((res) => {
          console.log("behaviorOfflineTimeGet", res);

          this.barData = res.data.Data;
          // this.loading = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });
    },
    getRecentAll: async function () {
      this.loading = true;
      await this.getTime();
      this.dateSelector = "全部";
      await Promise.all([
        this.getcomebackBuy(),
        this.getBehaviorOffline(),
        this.getBehaviorOffline2(),
        this.getBehaviorOfflinePie(),
        this.getBehaviorOfflineTwoPie(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    getRecentYear: async function () {
      const start = moment(this.hasDataEnd).add(-1, "years");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      console.log(strStart);
      this.dateSelector = "最近一年";
      await Promise.all([
        this.getcomebackBuy(),
        this.getBehaviorOffline(),
        this.getBehaviorOffline2(),
        this.getBehaviorOfflinePie(),
        this.getBehaviorOfflineTwoPie(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    getRecentMonth: async function () {
      const start = moment(this.hasDataEnd).add(-1, "months");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近30天";
      await Promise.all([
        this.getcomebackBuy(),
        this.getBehaviorOffline(),
        this.getBehaviorOffline2(),
        this.getBehaviorOfflinePie(),
        this.getBehaviorOfflineTwoPie(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    getPass7Days: async function () {
      const start = moment(this.hasDataEnd).add(-7, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近7天";
      await Promise.all([
        this.getcomebackBuy(),
        this.getBehaviorOffline(),
        this.getBehaviorOffline2(),
        this.getBehaviorOfflinePie(),
        this.getBehaviorOfflineTwoPie(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    getPass1Days: async function () {
      const start = moment(this.hasDataEnd).add(-1, "days");
      const strStart = moment(start._d).add(1, "days").format().slice(0, 10);
      console.log(strStart);
      this.startDate = strStart;
      this.endDate = this.hasDataEnd;
      this.dateSelector = "最近1天";
      await Promise.all([
        this.getcomebackBuy(),
        this.getBehaviorOffline(),
        this.getBehaviorOffline2(),
        this.getBehaviorOfflinePie(),
        this.getBehaviorOfflineTwoPie(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
      ]);
      this.highlightVue3Date();
      this.loading = false;
    },
    customDatePicker: async function () {
      this.startDate = this.vue3Date.start.slice(0, 10);
      this.endDate = this.vue3Date.end.slice(0, 10);
      this.dateSelector = "自訂";
      await Promise.all([
        this.getcomebackBuy(),
        this.getBehaviorOffline(),
        this.getBehaviorOffline2(),
        this.getBehaviorOfflinePie(),
        this.getBehaviorOfflineTwoPie(),
        this.getBuyTime(),
        this.getBuyTimeWeek(),
        this.getBuyTimeDay(),
      ]);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
/* OfflineBehavior */
.loading-background {
  position: fixed;
  left: 16.6%;
  top: 0;
  width: 83.4%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  text-align: center;
  padding: 400px 0;
  height: 100%;
}
.fs-small {
  font-size: 18px;
}
.fs-medium {
  font-size: 22px;
}
.home {
  padding-left: 40px;
  padding-top: 50px;
}
.title-span {
  font-size: 22px;
  font-weight: bold;
  margin-right: 40px;
  align-self: center;
}
.number-div {
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 145px;
}
.pie-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  margin-right: 0px;
  height: 315px;
}
.number-div2 {
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 70px;
}
.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}
.columm-div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 270px;
}
.columm-div-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
}
.dropdown-menu {
  border-top: none;
  width: 20.8vw;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.change-date-button {
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 100%;
  text-align: left;
}
.change-date-li:hover {
  background-color: #eaf3f4;
}
.confrim-btn {
  border: none;
  background-color: #71afb6;
  color: #fff;
}
.child-popout-inOfflineBehavior-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -260px;
  text-align: left;
  top: -42px;
  width: 250px;
  z-index: 99;
}
.child-popout-inOfflineBehavior-second {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -260px;
  text-align: left;
  top: -42px;
  width: 250px;
  z-index: 99;
}
@media (min-width: 1660px) {
  .container {
    max-width: 1560px;
  }
}
</style>
