<template>
  <div
    v-if="notshow"
    class="d-flex justify-content-center align-items-center"
    style="height: 100%"
  >
    <span style="color: #c4c4c4"> 該功能暫未開放 </span>
  </div>
  <div v-else>
    <Teleport to="body">
      <div v-if="openTeleport" class="modal-bg">
        <div class="modal-teleport">
          <div class="modal-top">
            <img src="../assets/arrow-white.svg" alt="arrow" class="" />
          </div>
          <div
            class="
              modal-inner
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <p class="text-center teleport-text">移除成功!</p>
            <button
              @click="openTeleport = false"
              class="up-download-button2"
              style="
                background-image: linear-gradient(270deg, #71afb6, #b5dce0);
              "
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </Teleport>

    <Teleport to="body">
      <div v-if="unselectOpenTeleport" class="modal-bg">
        <div class="modal-teleport">
          <div class="modal-top">
            <img src="../assets/arrow-white.svg" alt="arrow" class="" />
          </div>
          <div
            class="
              modal-inner
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <p class="text-center teleport-text">尚未選擇移除名單</p>
            <button
              @click="unselectOpenTeleport = false"
              class="up-download-button2"
              style="
                background-image: linear-gradient(270deg, #71afb6, #b5dce0);
              "
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </Teleport>

    <Teleport to="body">
      <div v-if="recoverTeleport" class="modal-bg">
        <div class="modal-teleport">
          <div class="modal-top">
            <img src="../assets/arrow-white.svg" alt="arrow" class="" />
          </div>
          <div
            class="
              modal-inner
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <p class="text-center teleport-text">恢復成功!</p>
            <button
              @click="recoverTeleport = false"
              class="up-download-button2"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </Teleport>

    <Teleport to="body">
      <div v-if="unselectRecoverTeleport" class="modal-bg">
        <div class="modal-teleport">
          <div class="modal-top">
            <img src="../assets/arrow-white.svg" alt="arrow" class="" />
          </div>
          <div
            class="
              modal-inner
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
          >
            <p class="text-center teleport-text">尚未選擇恢復名單</p>
            <button
              @click="unselectRecoverTeleport = false"
              class="up-download-button2"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </Teleport>

    <div class="container home" style="padding-left: 40px; padding-top: 53px">
      <div class="loading-background" v-show="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="title-span fs-medium" style="padding-left: 2px">
        行銷名單管理
      </div>

      <!-- <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div> -->

      <div class="container-fluid p-0">
        <router-link to="/MemberLimit" style="text-decoration: none">
          <div class="tab-container upper-section-href">
            <div class="mt-1 d-flex justify-content-between align-items-center">
              <span class="col-1">
                <span style="color: #71afb6; white-space: nowrap"
                  >行銷溝通上限</span
                >
              </span>
              <span class="col-8" style="color: #575757">
                此功能可根據時段設定單一會員的溝通上限，避免寄出過多的推播訊息造成反感。
              </span>
              <span class="col-1 d-flex justify-content-end">
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 7L1.78017e-07 13.9282L1.27811e-08 0.071797L12 7Z"
                    fill="#71AFB6"
                  />
                </svg>
              </span>
            </div>
          </div>
        </router-link>

        <div class="tab-container-2">
          <div class="d-flex align-items-center inner-padding">
            <div class="col-2">
              <label for="namelist" class="label-font">名單狀態</label>
            </div>
            <div class="col-3" style="position: relative">
              <div
                class="
                  selector
                  d-flex
                  justify-content-between
                  align-items-center
                  ps-3
                  pe-3
                "
                @click="typeDropdown = !typeDropdown"
              >
                <span class="col-9 d-flex">
                  {{ this.typeDropdownSelect }}
                </span>
                <span class="col-3 d-flex justify-content-end">
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z"
                      fill="#71AFB6"
                    />
                  </svg>
                </span>
              </div>
              <div class="dropdown-contain" v-if="this.typeDropdown === true">
                <div ref="typeDropdownRef">
                  <p
                    class="dropdown-value m-0"
                    @click="typeDropdownFunction('全部會員')"
                  >
                    全部會員
                  </p>
                  <p
                    class="dropdown-value m-0"
                    @click="typeDropdownFunction('Email')"
                  >
                    Email
                  </p>
                  <p
                    class="dropdown-value m-0"
                    @click="typeDropdownFunction('SMS')"
                  >
                    SMS
                  </p>
                </div>
              </div>
              <!-- <select class="selector" v-model="selectedType">
              <option value="all">全部會員</option>
               <option value="Email">Email</option>
              <option value="SMS">SMS</option>
            
            </select> -->
            </div>
            <div class="col-3" style="position: relative">
              <div
                class="
                  selector
                  d-flex
                  justify-content-between
                  align-items-center
                  ps-3
                  pe-3
                "
                @click="this.actionDropdown = !this.actionDropdown"
              >
                <span class="col-9 d-flex">
                  {{ this.actionDropdownSelect }}
                </span>
                <span class="col-3 d-flex justify-content-end">
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z"
                      fill="#71AFB6"
                    />
                  </svg>
                </span>
              </div>
              <div class="dropdown-contain" v-if="this.actionDropdown === true">
                <div ref="actionDropdownRef">
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'all'"
                    @click="actionDropdownFunction('all')"
                  >
                    --
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('正常可寄送名單')"
                  >
                    正常可寄送名單<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.Email.正常可寄送名單.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'SMS'"
                    @click="actionDropdownFunction('正常可寄送名單')"
                  >
                    正常可寄送名單<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.SMS.正常可寄送名單.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('永久退信')"
                  >
                    永久退信<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.Email.永久退信.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('暫時退信')"
                  >
                    暫時退信<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.Email.暫時退信.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('取消訂閱')"
                  >
                    取消訂閱<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.Email.取消訂閱.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('抱怨')"
                  >
                    抱怨<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.Email.抱怨.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('未開啟90天')"
                  >
                    未開啟90天
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('未開啟半年')"
                  >
                    未開啟半年
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('未開啟一年')"
                  >
                    未開啟一年
                  </p>

                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'SMS'"
                    @click="actionDropdownFunction('未點擊90天')"
                  >
                    未點擊90天
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'SMS'"
                    @click="actionDropdownFunction('未點擊半年')"
                  >
                    未點擊半年
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'SMS'"
                    @click="actionDropdownFunction('未點擊一年')"
                  >
                    未點擊一年
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'Email'"
                    @click="actionDropdownFunction('已移除名單')"
                  >
                    已移除名單<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.Email.已移除名單.toLocaleString()
                      }})</span
                    >
                  </p>
                  <p
                    class="dropdown-value m-0"
                    v-if="this.selectedType === 'SMS'"
                    @click="actionDropdownFunction('已移除名單')"
                  >
                    已移除名單<span style="color: #575757"
                      >&nbsp;({{
                        this.dropdownNumber.SMS.已移除名單.toLocaleString()
                      }})</span
                    >
                  </p>
                </div>
              </div>

              <!-- <select class="selector" v-model="selectedAction">
     
                <option value="all" v-if="this.selectedType === 'all' ">
               --
              </option>
              <option value="bounce" v-if="this.selectedType === 'Email' ">
                永久退信
              </option>
              <option value="soft_bounce" v-if="this.selectedType === 'Email' ">
                暫時退信
              </option>
              <option value="unsubscribe" v-if="this.selectedType === 'Email' ">
                取消訂閱
              </option>
              <option value="complaint" v-if="this.selectedType === 'Email' ">
                抱怨
              </option>
              <option value="open&90" v-if="this.selectedType === 'Email' ">
                未開啟90天
              </option>
              <option value="open&180" v-if="this.selectedType === 'Email' ">
                未開啟半年
              </option>
              <option value="open&365" v-if="this.selectedType === 'Email' ">
                未開啟一年
              </option>

              <option value="normal" v-if="this.selectedType === 'SMS' ">
                正常可寄送名單
              </option>

              <option value="click&90" v-if="this.selectedType === 'SMS' ">
                未點擊90天
              </option>
              <option value="click&180" v-if="this.selectedType === 'SMS' ">
                未點擊半年
              </option>
              <option value="click&365" v-if="this.selectedType === 'SMS' ">
                未點擊一年
              </option>

              <option value="delete" v-if="this.selectedType === 'Email'">
                已移除名單
              </option> 
              <option value="delete" v-if="this.selectedType === 'SMS' ">
                已移除名單
              </option>
            </select> -->
            </div>
            <div class="col-4">
              <div class="d-flex justify-content-between">
                <button
                  class="check-button"
                  @click="getInvalidTableButton()"
                  v-if="
                    this.selectedAction === 'delete' ||
                    this.selectedAction === 'bounce' ||
                    this.selectedAction === 'soft_bounce' ||
                    this.selectedAction === 'unsubscribe' ||
                    this.selectedAction === 'complaint' ||
                    this.selectedAction === 'normal' ||
                    this.selectedAction === 'all'
                  "
                >
                  查看名單
                  <!-- 查看名單2 -->
                </button>
                <button class="check-button" @click="getTableButton()" v-else>
                  查看名單
                </button>
                <button
                  class="check-button"
                  style="padding-left: 10px"
                  @click="postmemberStatusExportPost()"
                >
                  <!-- <img src="../assets/upload-white.svg" alt="" /> -->
                  匯出篩選名單
                </button>
              </div>
            </div>
          </div>
          <hr />

          <div class="inner-padding">
            <div style="position: relative">
              <div class="absolute-class"></div>
            </div>
            <div></div>

            <div style="position: relative" v-if="this.refreshTable !== true">
              <button
                @click="test()"
                class="abs-test-button"
                :class="
                  this.buttonSelectedClass === true
                    ? 'abs-test-button2'
                    : 'abs-test-button'
                "
              ></button>

              <DataTable
                :value="searchTable"
                v-model:selection="selectedProducts3"
                dataKey="member_id"
                :paginator="true"
                :rows="10"
                paginatorTemplate=" CurrentPageReport  PrevPageLink PageLinks NextPageLink"
                :pageLinkSize="3"
                paginatorPosition="top"
                :currentPageReportTemplate="`目前 {currentPage} ， 共 ${Math.ceil(
                  this.total / 10
                )}頁`"
                :style="{ textAlign: 'center' }"
                @page="onPage($event)"
              >
                <template #paginatorstart>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div
                      class="col-3"
                      style="text-align: start; white-space: nowrap"
                    >
                      <span style="color: #71afb6">人數 &nbsp; &nbsp;</span
                      ><span style="white-space: nowrap"
                        >{{ total.toLocaleString() }} 人</span
                      >
                    </div>
                    <div
                      class="d-flex justify-content-center"
                      style="width: 70%"
                    >
                      <div
                        class="col-5"
                        :style="
                          this.selectedAction === 'bounce' ||
                          this.selectedAction === 'soft_bounce' ||
                          this.selectedAction === 'unsubscribe' ||
                          this.selectedAction === 'complaint' ||
                          this.selectedAction === 'open&90' ||
                          this.selectedAction === 'open&180' ||
                          this.selectedAction === 'open&365' ||
                          this.selectedAction === 'click&90' ||
                          this.selectedAction === 'click&180' ||
                          this.selectedAction === 'click&365' ||
                          this.selectedAction === 'normal'
                            ? 'cursor:not-allowed'
                            : ''
                        "
                      >
                        <button
                          class="
                            btn
                            up-download-button
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                          style="
                            background-color: #eaf3f4;
                            color: #71afb6;
                            width: 90%;
                          "
                          @click="clickRecover()"
                          :class="
                            this.selectedAction === 'bounce' ||
                            this.selectedAction === 'soft_bounce' ||
                            this.selectedAction === 'unsubscribe' ||
                            this.selectedAction === 'complaint' ||
                            this.selectedAction === 'open&90' ||
                            this.selectedAction === 'open&180' ||
                            this.selectedAction === 'open&365' ||
                            this.selectedAction === 'click&90' ||
                            this.selectedAction === 'click&180' ||
                            this.selectedAction === 'click&365' ||
                            this.selectedAction === 'normal'
                              ? 'disabled'
                              : ''
                          "
                          :aria-disabled="
                            this.selectedAction === 'bounce' ||
                            this.selectedAction === 'soft_bounce' ||
                            this.selectedAction === 'unsubscribe' ||
                            this.selectedAction === 'complaint' ||
                            this.selectedAction === 'open&90' ||
                            this.selectedAction === 'open&180' ||
                            this.selectedAction === 'open&365' ||
                            this.selectedAction === 'click&90' ||
                            this.selectedAction === 'click&180' ||
                            this.selectedAction === 'click&365' ||
                            this.selectedAction === 'normal'
                              ? 'true'
                              : 'false'
                          "
                        >
                          <img src="../assets/recover-icon.svg" class="me-2" />

                          <span>恢復</span>
                        </button>
                      </div>
                      <div
                        class="col-5 d-flex"
                        :style="
                          this.selectedProducts3 === null ||
                          this.selectedProducts3 === [] ||
                          this.selectedAction === 'bounce' ||
                          this.selectedAction === 'soft_bounce' ||
                          this.selectedAction === 'unsubscribe' ||
                          this.selectedAction === 'complaint' ||
                          this.selectedAction === 'delete'
                            ? 'cursor:not-allowed'
                            : ''
                        "
                      >
                        <button
                          class="
                            btn
                            up-download-button
                            d-flex
                            align-items-center
                            justify-content-center
                            remove-delete
                            check-button22
                          "
                          style="
                            background-image: linear-gradient(
                              270deg,
                              #71afb6 0%,
                              #b5dce0 100%
                            );
                            width: 90%;
                            color: white;
                          "
                          @click="clickDelete()"
                          :class="
                            this.selectedAction === 'bounce' ||
                            this.selectedAction === 'soft_bounce' ||
                            this.selectedAction === 'unsubscribe' ||
                            this.selectedAction === 'complaint' ||
                            this.selectedAction === 'delete'
                              ? 'disabled'
                              : ''
                          "
                          :aria-disabled="
                            this.selectedAction === 'bounce' ||
                            this.selectedAction === 'soft_bounce' ||
                            this.selectedAction === 'unsubscribe' ||
                            this.selectedAction === 'complaint' ||
                            this.selectedAction === 'delete'
                              ? 'true'
                              : 'false'
                          "
                        >
                          <img src="../assets/remove-icon.svg" class="me-2" />
                          <span>移除</span>
                        </button>
                        <button
                          class="btn btn-default button px-0 mx-0 pt-0"
                        ></button>
                      </div>
                      <div class="d-flex align-items-center">
                        <BtnInfoBox content="此移除功能僅會將該名單進行限制投放，並不會將會員資料進行刪除，若選擇全部會員也是將目前有的溝通渠道做限制。" direction="right"></BtnInfoBox>

                      </div>
                      
                    </div>
                    <div class="col-3" style="position: relative">
                      <input
                        v-model="searchInput"
                        class="search-member"
                        placeholder="ID.姓名.電子郵件.電話"
                        v-on:keyup.enter="memberStatusSearchPostOnChange"
                      />
                      <img
                        style="position: absolute; top: 10px; left: 10px"
                        src="../assets/search-black.png"
                        alt="searchImg"
                      />
                      <img
                        style="position: absolute; top: 17px; right: -105px"
                        :style="this.total === 0 ? 'right:-105px' : ''"
                        src="../assets/blue-inputX.svg"
                        @click="this.searchInput = ''"
                        alt="searchImg"
                      />
                      <!-- :style="this.total === '' ? 'right:-95px' : '' "  -->
                    </div>
                  </div>
                  <div
                    class="mt-3"
                    style="text-align: start"
                    v-if="this.buttonSelectedClass === true"
                  >
                    <span v-if="this.confirmAllSelected === false"
                      >已選取這個頁面上 10 個名單。<span
                        style="color: #71afb6; cursor: pointer"
                        @click="this.confirmAllSelected = true"
                        >選取全部 {{ total.toLocaleString() }} 個名單</span
                      ></span
                    >
                    <span v-else-if="this.confirmAllSelected === true"
                      >已選取全部 {{ total.toLocaleString() }} 個名單。<span
                        style="color: #71afb6; cursor: pointer"
                        @click="this.confirmAllSelected = false"
                      >
                        取消選取
                      </span></span
                    >
                  </div>
                </template>

                <Column
                  selectionMode="multiple"
                  headerStyle="width: 3em"
                ></Column>
                <Column field="name" header="會員名稱" :sortable="false">
                </Column>
                <Column field="member" header="Member ID" :sortable="false">
                </Column>
                <Column field="email" header="Email" :sortable="false">
                </Column>
                <Column field="phone" header="電話" :sortable="false"> </Column>
              </DataTable>
            </div>
          </div>
        </div>

        <div class="tab-container d-flex align-items-center">
          <div class="col-2">
            <label for="namelist" class="label-font">匯入名單</label>
          </div>
          <div class="col-10 d-flex justify-content-between">
            <div class="col-48">
              <button
                class="btn up-download-button-top"
                style="background-color: #eaf3f4; color: #71afb6"
                @click="goUpload()"
              >
                上傳功能
              </button>
            </div>
            <div class="col-48">
              <button
                class="btn up-download-button-top"
                style="
                  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
                "
                @click="goDownload()"
              >
                範例下載
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <button @click="onPageChange()">te4st</button> -->
</template>


<script>
import { ref, watch, onBeforeMount } from "vue";
import { allapi2 } from "../../public/static2/api/apigClient.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { onClickOutside } from "@vueuse/core";
// import { async } from "q";
import BtnInfoBox from '../components/BtnInfoBox.vue'

export default {
  name: "MemberUploadDel",
  components: {
    DataTable,
    Column,
    BtnInfoBox,
  },

  setup() {
    const typeDropdownRef = ref(null);
    const typeDropdown = ref(false);

    const actionDropdownRef = ref(null);
    const actionDropdown = ref(false);

    onClickOutside(typeDropdownRef, () => {
      typeDropdown.value = false;
    });

    onClickOutside(actionDropdownRef, () => {
      actionDropdown.value = false;
    });

    return {
      typeDropdownRef,
      typeDropdown,
      actionDropdownRef,
      actionDropdown,
    };
  },

  data() {
    return {
      MemberUploadDelRemove: false,
      loading: false,
      notshow: false,
      selectedType: "Email",

      selectedAction: "normal",
      selectedProducts3: null,

      selectedDelORRec: "",
      recoverORdelete: "",
      dropdownNumber: 0,

      actionAPi: "",
      invalidApi: "",

      changeApi: "",
      selectedTableApi: "",
      searchTable: [],
      searchInput: "",

      memberIdForChange: [],
      total: "",

      selectedTotal: "",
      openTeleport: false,
      unselectOpenTeleport: false,

      recoverTeleport: false,
      unselectRecoverTeleport: false,

      customSelecteAll: [],

      buttonSelectedClass: false,

      typeDropdownSelect: "Email",

      actionDropdownSelect: "正常可寄送名單",

      json_data: [],
      memberIdForExport: [],

      paginatorElement: "",
      timer: null,
      timerID: 0,
      refreshTable: false,
      confirmAllSelected: false,
      findPage: 0,
    };
  },

  watch: {
    selectedType() {
      console.log("typechoose", this.selectedType);

      if (this.selectedType === "SMS") {
        this.actionDropdownSelect = "正常可寄送名單";
        // this.selectedAction = "click&90";
      } else if (this.selectedType === "Email") {
        this.actionDropdownSelect = "正常可寄送名單";
        // this.selectedAction = "normal";
      } else if (this.selectedType === "all") {
        this.actionDropdownSelect = "--";
        // this.selectedAction = "all";
      }
      // this.memberTableApi()
    },
    currentPage() {
      console.log("cb", this.currentPage);
    },

    selectedAction() {
      console.log("actionchoose", this.selectedAction);
      // this.buttonSelectedClass = false
      //  this.memberTableApi()
    },
    searchInput(val) {
      if (val === "") {
        this.memberStatusPostApi();
      }

      // this.searchTable = this.selectedTableApi.filter((d) => {
      //   return (
      //     d.member_id.includes(val) ||
      //     d.name.includes(val) ||
      //     d.phone.includes(val) ||
      //     d.email.includes(val)
      //   );
      // });
    },

    // selectedProducts3(){
    //   console.log("checkobox", this.selectedProducts3)

    // }
    searchTable() {
      console.log("searc", this.searchTable);
    },
  },

  mounted() {
    this.loading = true;

    this.memberStatusPostApi();
    this.loading = false;
    this.getDropdownNumber();
    // this.timer = setInterval(() =>{
    //      this.timerID = this.timerID + 1000
    //     this.memberStatusPostApi()
    // },10000)
    // setTimeout(() =>{

    // },300)
    // this.memberTableApi()
  },
  beforeUnmount() {
    // clearInterval(this.timer)
    // this.timerID = this.timerID + 1000
  },

  methods: {
    test: async function () {
      this.loading = true;
      this.buttonSelectedClass = true;
      setTimeout(() => {
        this.test2();
      }, 100);
    },
    test2() {
      if (this.selectedProducts3 === null || this.selectedProducts3 === []) {
        this.customSelecteAll = this.searchTable.slice(
          10 * this.findPage,
          10 * (this.findPage + 1)
        );
        this.selectedProducts3 = this.customSelecteAll;
        this.loading = false;
      } else {
        this.selectedProducts3 = null;
        this.confirmAllSelected = false;
        this.buttonSelectedClass = false;
        this.loading = false;
      }
    },
    onPage(e) {
      console.log("teeee", e);
      if (e.page === 99) {
        this.timerID = this.timerID + 1000;
        this.refreshTable = true;
        this.memberStatusPostApi();
      }
      this.findPage = e.page;
    },

    getTableButton() {
      this.memberTableApi();
    },
    getInvalidTableButton() {
      this.memberStatusPostApi();
      // this.deleteInvalidApi();
    },
    goDownload() {
      window.location.href =
        "https://docs.google.com/spreadsheets/d/1Q0mTg1ejeY4I_sic2gfxnTj3G3tHLBSUhjyqJZv2cAM/edit#gid=0";
    },
    goUpload() {
      const from = "cdp";
      const token = sessionStorage.getItem("access_token");
      const idToken = sessionStorage.getItem("id_token");
      if (window.location.origin === "http://localhost:8080") {
        window.location.href = `http://localhost:8081/?from=${from}&token=${token}&idtoken=${idToken}`;
      } else if (
        window.location.origin === "https://firstcdp-test.retailing-data.net" ||
        window.location.origin === "https://firstcdp-dev.retailing-data.net" ||
        window.location.origin === "https://firstcdp.retailing-data.net"
      ) {
        window.location.href = `https://firstcdp-dataupload.retailing-data.net/?from=${from}&token=${token}&idtoken=${idToken}`;
      }
    },

    typeDropdownFunction(event) {
      this.typeDropdownSelect = event;
      this.typeDropdown = false;
      if (event === "全部會員") {
        this.selectedType = "all";
      } else if (event === "Email") {
        this.selectedType = "Email";
      } else if (event === "SMS") {
        this.selectedType = "SMS";
      }
      console.log("word", this.typeDropdownSelect);
      console.log("value", this.selectedType);
    },

    actionDropdownFunction(event) {
      this.actionDropdown = false;
      this.actionDropdownSelect = event;
      if (event === "all") {
        this.selectedAction = "all";
      } else if (event === "永久退信") {
        this.selectedAction = "bounce";
      } else if (event === "暫時退信") {
        this.selectedAction = "soft_bounce";
      } else if (event === "取消訂閱") {
        this.selectedAction = "unsubscribe";
      } else if (event === "抱怨") {
        this.selectedAction = "complaint";
      } else if (event === "未開啟90天") {
        this.selectedAction = "open&90";
      } else if (event === "未開啟半年") {
        this.selectedAction = "open&180";
      } else if (event === "未開啟一年") {
        this.selectedAction = "open&365";
      } else if (event === "正常可寄送名單") {
        this.selectedAction = "normal";
      } else if (event === "未點擊90天") {
        this.selectedAction = "click&90";
      } else if (event === "未點擊半年") {
        this.selectedAction = "click&180";
      } else if (event === "未點擊一年") {
        this.selectedAction = "click&365";
      } else if (event === "已移除名單") {
        this.selectedAction = "delete";
      }
    },

    clickDelete: async function () {
      this.recoverORdelete = "delete";
      if (
        this.selectedProducts3 === null ||
        this.selectedProducts3.length === 0
      ) {
        this.unselectOpenTeleport = true;
      } else {
        this.selectedDelORRec = this.selectedProducts3.map((d) => {
          return d.member_id;
        });
        console.log("newArr", this.selectedDelORRec);
        await this.changeMemberApi();

        if (
          this.selectedAction === "delete" ||
          this.selectedAction === "bounce" ||
          this.selectedAction === "soft_bounce" ||
          this.selectedAction === "unsubscribe" ||
          this.selectedAction === "complaint" ||
          this.selectedAction === "normal" ||
          this.selectedAction === "all"
        ) {
          await this.getInvalidTableButton();
        } else {
          await this.getTableButton();
          this.getDropdownNumber();
        }

        // await this.memberTableApi();
        // await this.changeMemberApi();

        this.selectedProducts3 = null;
      }
    },

    clickRecover: async function () {
      this.recoverORdelete = "normal";

      if (
        this.selectedProducts3 === null ||
        this.selectedProducts3.length === 0
      ) {
        this.unselectRecoverTeleport = true;
      } else {
        this.selectedDelORRec = this.selectedProducts3.map((d) => {
          return d.member_id;
        });
        await this.changeMemberApi();
        // await this.deleteInvalidApi();

        if (
          this.selectedAction === "delete" ||
          this.selectedAction === "bounce" ||
          this.selectedAction === "soft_bounce" ||
          this.selectedAction === "unsubscribe" ||
          this.selectedAction === "complaint" ||
          this.selectedAction === "normal" ||
          this.selectedAction === "all"
        ) {
          await this.getInvalidTableButton();
        } else {
          await this.getTableButton();
          this.getDropdownNumber();
        }

        this.selectedProducts3 = null;
      }
    },

    getDropdownNumber() {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };

      const body = {
        member_type: "pre-numbers",
        action: "all",
      };
      allapi2
        .memberStatusPost(params, body)
        .then((res) => {
          console.log("dropdownnumber", res);
          this.dropdownNumber = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    download() {
      const buildData = (data) => {
        return new Promise((resolve, reject) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(data[0]);
          arrayData.push(arrayTitle);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(data, (d) => {
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title] || "無";
              items.push(item);
            });
            arrayData.push(items);
          });

          resolve(arrayData);
        });
      };
      const downloadCSV = (data) => {
        let csvContent = "";
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(",") + "\n";
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName =
          "下載" +
          this.selectedNameList +
          "資料" +
          new Date().toISOString() +
          ".csv";

        // 建立一個 a，並點擊它
        let link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
        );
        link.setAttribute("download", fileName);
        link.click();
      };
      const data = this.json_data;
      buildData(data).then((data) => downloadCSV(data));
    },
    getcsv: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      const body = {
        member_list: this.memberIdForExport,
      };
      await allapi2
        .memberStatusExportPost(params, body)
        .then((res) => {
          console.log("getcsv", res.data.Data);
          this.json_data = res.data.Data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          alert("連線有誤");
        });

      this.download();
      // }
    },

    memberTableApi: async function () {
      if (
        this.recoverORdelete === "delete" ||
        this.recoverORdelete === "normal"
      ) {
        this.loading = false;
      } else {
        this.loading = true;
      }

      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      const body = {
        type: this.selectedType,
        action: this.selectedAction,
      };
      await allapi2
        .memberStatusOpenClickPost(params, body)
        .then((res) => {
          console.log("staus", res);

          this.actionAPi = res.data.Data.members;
          this.total = res.data.Data.total;
          this.selectedTableApi = this.actionAPi;
          this.searchTable = this.selectedTableApi;
          this.buttonSelectedClass = false;
          this.selectedProducts3 = null;
          this.loading = false;
          this.recoverORdelete = "";

          this.searchTable.forEach((id) => {
            this.memberIdForExport.push(id.member_id);
          });
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    memberStatusPostApi: async function () {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      let body = {};

      if (this.selectedType === "Email" || this.selectedType === "SMS") {
        body = {
          member_type: this.selectedType,
          action: this.selectedAction,
          id: this.timerID,
        };
      } else if (this.selectedType === "normal") {
        body = {
          member_type: this.selectedType,
          action: this.selectedAction,
          id: this.timerID,
        };
      } else {
        body = {
          member_type: "all",
          action: "all",
          id: this.timerID,
        };
      }

      await allapi2
        .memberStatusPost(params, body)
        .then((res) => {
          console.log("memberStatusPost", res);
          this.selectedTableApi = res.data.Data.members;
          this.searchTable = this.selectedTableApi;
          this.total = res.data.Data.total;
          this.buttonSelectedClass = false;
          this.selectedProducts3 = null;
          this.loading = false;
          this.refreshTable = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },

    deleteInvalidApi: async function () {
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      const body = {
        member_type: this.selectedAction,
        action: "delete",
      };
      await allapi2
        .memberStatusPost(params, body)
        .then((res) => {
          console.log("invalid_api", res);
          this.invalidApi = res.data.Data.members;
          this.total = res.data.Data.total;
          this.selectedTableApi = this.invalidApi;
          this.searchTable = this.selectedTableApi;
          this.buttonSelectedClass = false;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          // this.loading = false;
        });
    },

    changeMemberApi: async function () {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };
      let body = {};

      if (this.confirmAllSelected === false) {
        body = {
          member_list: this.selectedDelORRec,
          status_type: this.selectedType,
          status: this.recoverORdelete,
        };
      } else if (
        this.confirmAllSelected === true ||
        this.selectedAction === "all" ||
        this.selectedAction === "bounce" ||
        this.selectedAction === "soft_bounce" ||
        this.selectedAction === "unsubscribe" ||
        this.selectedAction === "complaint" ||
        this.selectedAction === "delete" ||
        this.selectedAction === "normal"
      ) {
        body = {
          member_list: "all",
          member_type: this.selectedType,
          action: this.selectedAction,
          status: this.recoverORdelete,
          status_type: this.selectedType,
        };
      } else if (
        this.confirmAllSelected === true ||
        this.selectedAction === "click&90" ||
        this.selectedAction === "click&180" ||
        this.selectedAction === "click&365"
      ) {
        body = {
          member_list: "all",
          type: this.selectedType,
          action: this.selectedAction,
          status: this.recoverORdelete,
          status_type: this.selectedType,
        };
      }

      await allapi2
        .memberStatusChangePost(params, body)
        .then((res) => {
          console.log("change", res);
          this.loading = false;
          if (this.recoverORdelete === "delete") {
            this.openTeleport = true;
          } else if (this.recoverORdelete === "normal") {
            this.recoverTeleport = true;
          }
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
      this.getDropdownNumber();
    },
    memberStatusSearchPostOnChange: async function () {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      let body = {};

      if (
        this.selectedAction === "click&90" ||
        this.selectedAction === "click&180" ||
        this.selectedAction === "click&365"
      ) {
        body = {
          type: this.selectedType,
          action: this.selectedAction,
          id: this.timerID,
          search: this.searchInput,
        };
      } else {
        body = {
          member_type: this.selectedType,
          action: this.selectedAction,
          id: this.timerID,
          search: this.searchInput,
        };
      }
      // else {
      //   body = {
      //     member_type: "all",
      //     action: "all",
      //      id:this.timerID,
      //        search:this.searchInput
      //   };
      //}

      await allapi2
        .memberStatusSearchPost(params, body)
        .then((res) => {
          console.log("search api", res);
          this.loading = false;
          this.total = res.data.Count;
          this.searchTable = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    postmemberStatusExportPost: async function () {
      this.loading = true;
      const token = sessionStorage.getItem("access_token");

      const params = {
        Authorization: "Bearer " + token,
      };

      let body = {};

      if (
        this.selectedAction === "click&90" ||
        this.selectedAction === "click&180" ||
        this.selectedAction === "click&365"
      ) {
        body = {
          type: this.selectedType,
          action: this.selectedAction,
          search: this.searchInput,
        };
      } else {
        body = {
          member_type: this.selectedType,
          action: this.selectedAction,
          search: this.searchInput,
        };
      }
      await allapi2
        .memberStatusExportPost(params, body)
        .then((res) => {
          console.log("export api", res);
          this.loading = false;
          window.location.href = res.data.Data;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
/* memberUploadDel */
:deep(.p-paginator-current) {
  margin-top: 10px;
}
.p-paginator {
  align-items: flex-start;
}

.label-font {
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #71afb6;
}

.upper-section-href {
  cursor: pointer;
}

.search-member {
  width: 16rem;
  /* width: auto; */
  height: auto;
  border: 0.5px solid #575757;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-indent: 30px;
}

.upper-section-href:hover {
  background-color: #eaf3f4;
}

.up-download-button {
  color: #fff;

  border: none;
  border-radius: 10px;
  padding: 8px;
  width: 80%;
}

.up-download-button2 {
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);

  color: #fff;

  border: none;
  border-radius: 10px;
  padding: 8px;
  width: 10rem;
  font-size: 18px;
  font-weight: 600;
}

.up-download-button-top {
  color: #fff;

  border: none;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
}

.selector {
  color: #71afb6;
  border-radius: 10px;
  padding: 5px;
  width: 90%;
  text-align: center;
  border-color: #c4c4c4;
  border: 0.5px solid #575757;
  cursor: pointer;
  user-select: none;
  /* text-align-last: center; */
}

.dropdown-contain {
  border: 1px solid transparent;
  border-radius: 15px;
  width: 91%;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  margin-top: 5px;
  /* top:10px; */
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
}

.dropdown-value {
  cursor: pointer;
  user-select: none;
  padding: 0.7rem /* 8px */;

  color: #575757;
}

.dropdown-value:hover {
  background-color: #eaf3f4;
  color: #71afb6;
}

.dropdown-value:first-child:hover {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #eaf3f4;
  color: #71afb6;
}

.dropdown-value:last-child:hover {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #eaf3f4;
  color: #71afb6;
}

.check-button {
  color: #fff;
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
  border: none;
  border-radius: 10px;
  padding: 10px;
  width: 47%;
}

.check-button22 {
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
}

.tab-container-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  margin-top: 2rem;
  padding: 15px 0px 15px;
}

.inner-padding {
  padding: 10px 30px 10px;
}

.absolute-class {
  position: absolute;
  top: -3px;
  width: 70%;
  z-index: 1;
}

.col-48 {
  width: 48%;
}

.btn.disabled {
  pointer-events: none;
  opacity: 0.35;
}

.modal-teleport {
  background-color: #fff;
  position: fixed;
  z-index: 7;
  top: 30%;
  left: 50%;
  width: 410px;
  height: 166px;
  margin-left: -150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.modal-inner {
  padding: 1.5rem;
}

.modal-inner:after {
  background-color: red;
}

.modal-bg:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 6;
  height: 140%;
}

.modal-top {
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  padding-left: 1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
}

.teleport-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.pi-check:before {
  /* content: "\e909"; */
  margin-top: 4px;
  color: #575757;
  background-color: #575757;
  border-radius: 3.5px;
}

.p-checkbox {
  width: 20px;

  height: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}

thead > tr > th > div > .p-checkbox {
  display: none;
}

.abs-test-button {
  position: absolute;
  top: 90px;
  left: 15.5px;
  z-index: 6;
  width: 23px;
  height: 23px;
  border-radius: 7px;
  border: 0.5px solid;
  border-color: #575757;
  background-color: #fff;
}

.abs-test-button2 {
  position: absolute;
  top: 130px;
  left: 15.5px;
  z-index: 6;

  width: 23px;
  height: 23px;
  border-radius: 7px;
  border: 0.5px solid;
  border-color: #575757;

  background-color: #575757;
}

.btn-download-MemberUpload-Del {
  background-image: linear-gradient(270deg, #71afb6, #b5dce0);
  border: none;
  color: #fff;
  font-size: 18px;
}
.child-popout-inMemberUploadDel-first {
  font-size: 14px;
  background-color: #63a7c8;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -410px;
  text-align: left;
  top: -28px;
  width: 400px;
  z-index: 99;
}
</style>