<template>
  <div id="nav" class="d-flex flex-column" style="height: 100vh">
    <Header class="col-12" v-if="this.isLogin == 'ImLogin'" />
    <div class="col-12 d-flex flex-fill">
      <LeftNav id="leftNav" class="col-2" v-if="this.isLogin == 'ImLogin'" />
      <div class="col-10">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "../src/components/LeftNav.vue";
import Header from "../src/components/Header.vue";
import { allapi2 } from "../public/static2/api/apigClient.js";

export default {
  name: "App",
  components: {
    LeftNav,
    Header,
  },
  data() {
    return {
      isLogin: "",
    };
  },
  watch: {
    $route: function () {
      this.isLogin = sessionStorage.getItem("token");
      console.log("this.isLogin", this.isLogin);
    },
  },
  created() {
    this.isLogin = sessionStorage.getItem("token");
    console.log("this.isLogin", this.isLogin);
  },
  methods: {},
};
</script>

<style>
#nav ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

#nav ::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 10px;
}

#nav ::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
#nav ::-webkit-scrollbar-thumb:hover {
  background: rgb(126, 125, 125);
}
/* .col-2 {
  width: 15%;
}
.col-10 {
  width: 80%;
} */
.tip-AfterTriggerScript {
  background-color: #888888;
  border: 1px solid #888888;
  border-radius: 5px;
  color: white;
  width: 150px;
  line-height: 25px;
  height: 25px;
  font-size: 12px;
  padding: 0px 4px !important ;
}
.tip-AfterTriggerScript:after {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 100%;
  width: 0;
}
.tip-AfterTriggerScriptp:after,
.tip-AfterTriggerScript:before {
  opacity: 1;
  pointer-events: none;
  border: 6px solid transparent;
  border-top-color: #888888;
  bottom: -35%;
  left: 50%;
  position: absolute;
  height: 0;
  width: 0;
  z-index: 5000;
}
.tip-LabelManage {
  background-color: rgba(87, 87, 87, 0.7);
  border: 1px solid #888888;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  padding: 2px 7px !important ;
  max-width: 360px;
  white-space: pre;
}
.tip-LabelManage-status_message {
  padding: 7px 7px !important ;
  min-width: 120px;
}
/* 縮寫超出的文字 */
.word-overflow {
  display: inline-block;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 陰影div框 */
.shadow-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

/* menu for dropdown in LabelManage*/
.menu-style-LabelManage {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 15px;
  color: #575757;
  font-family: "Noto Sans TC", sans-serif;
  font-size: 18px;
  cursor: default;
}
.p-menu-list {
  padding: 8px 16px !important;
  border-radius: 15px;
}
.p-menu-list:hover {
  background-color: #eaf3f4;
}
.White {
  color: white;
}

.Gray34 {
  color: #575757;
}

.Gray54 {
  color: #8A8A8A;
}

.GrayC4 {
  color: #c4c4c4;
}

.Red {
  color: #FB0A0A;
}

.Cyan {
  color: #71AFB6;
}

.bg-SkyBlue {
  background-color: #eaf3f4;
}

.bg-Cyan {
  background-color: #71AFB6;
}

.bg-gradientCyan {
  background-image: linear-gradient(to bottom right, #b5dce0, #71afb6);
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}

.pd-0 {
  padding: 0;
}

/* 右上的時間選擇框 */
.datepicker {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  width: 20.8vw;
  height: 40px;
}

.info-pop {
	position: relative;
	display: contents;
}

.btn-info-pop {
  background-image: url('./assets/question-img.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
  /* opacity: 0.8; */
}
.btn-info-pop:hover {
  background-image: url('./assets/question-img-hover.png');
  /* opacity: 1; */
}
.btn-info-pop:focus {
  box-shadow: none !important;;
}
.child-popout-right {
  background-color: #63a7c8;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  color: #fff;
  padding:8px 10px 10px 10px;
  position: absolute;
  right: -222px;
  text-align: left;
  top: var(--l);
  max-width: 220px;
  z-index: 99;
  line-height: 1;
}
.child-popout-top {
  background-color: #63a7c8;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 35%);
  color: #fff;
  padding: 10px;
  position: absolute;
  right: -90px;
  text-align: left;
  /* top: -75px; */
  top: var(--l2);
  width: 220px;
  z-index: 99;
  line-height: 1;
}
.container__arrow-inCustomLabel-trd {
  border: 8px solid transparent;
  border-top-color: #63a7c8;
  bottom: -8px;
  height: 0;
  left: 53.8%;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-68%, 7px) rotate(360deg);
  width: 0;
  z-index: 10;
}
</style>
