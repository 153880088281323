<template>
  <div class="modal-body alert-modal-body text-start py-3 px-5">
    <div class="d-flex align-items-center mb-3">
      <div style="width: 20%; line-height: 40px">開始時間</div>
      <div class="cycletime-setting-block">
        {{ this.$props.cycleTime_setting.time_start }}
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div style="width: 20%; line-height: 40px">發送方式</div>
      <div class="cycletime-setting-block">定期投放</div>
    </div>
    <div class="d-flex flex-wrap align-items-center">
      <div style="width: 20%; line-height: 40px">周期條件</div>
      <div class="cycletime-setting-block">年</div>
      <div style="width: 20%"></div>
      <div
      class="d-flex mt-3 mb-3" style="width:80%"
        v-if="this.$props.cycleTime_setting.time_setting.yearBasedWith === '日期'"
      >
        <div style="width: 20%; line-height: 40px">投放於</div>
        <div style="width: 39%;margin-right:2%" class="cycletime-setting-block">
          {{ this.$props.cycleTime_setting.time_setting.happenYear }}
        </div>
        <div style="width: 39%" class="cycletime-setting-block">
          {{ this.$props.cycleTime_setting.time_setting.happenYear2 }}
        </div>
      </div>
      <div
      class="d-flex my-3" style="width:80%"
        v-else-if="this.$props.cycleTime_setting.time_setting.yearBasedWith === '天'"
      >
        <div style="width: 20%; line-height: 40px">投放於</div>
        <div style="width: 25%;margin-right:2.5%" class="cycletime-setting-block">
          {{ this.$props.cycleTime_setting.time_setting.happenYear3 }}
        </div>
        <div style="width: 25%;margin-right:2.5%" class="cycletime-setting-block">
          {{ this.$props.cycleTime_setting.time_setting.happenYear4 }}
        </div>
        <div style="width: 25%" class="cycletime-setting-block">
          {{ this.$props.cycleTime_setting.time_setting.happenYear5 }}
        </div>
      </div>
      <div style="width: 20%"></div>
      <div class="cycletime-setting-block">
        {{ this.$props.cycleTime_setting.time_setting.daytime }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSettingModalYear",
  components: {},
  props: {
    cycleTime_setting: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.cycletime-setting-block {
  width: 80%;
  border: 1px solid #c4c4c4;
  line-height: 40px;
  border-radius: 10px;
  color: #c4c4c4;
  text-align: center;
}
</style>