<template>
  <div v-if="notshow" class="p-5 m-5">測試帳號尚未開放</div>
  <div class="container home" v-else>
    <!-- loading screen -->

    <div class="loading-background" v-show="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- datepicker modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <DatePicker
              v-model="vue3Date"
              is-range
              :model-config="modelConfig"
              :max-date="hasDataEnd"
              :min-date="hasDataFirst"
            />
            <p>{{ vue3Date.start }}~{{ vue3Date.end }}</p>
            <button
              class="btn confrim-btn"
              @click="customDatePicker"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              選取
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <span class="title-span fs-medium">
        <img
          src="../assets/back-arrow.png"
          @click="$router.go(-1)"
          style="cursor: pointer"
        />
        &nbsp; &nbsp; 廣告(FB Ads)</span
      >
      <!-- <div class="search-container">
        <div>
          <img class="searchImg" src="../assets/search.svg" alt="searchImg" />
          <input
            class="search-input"
            placeholder="搜尋廣告組合或廣告名稱名稱"
            list="films"
            v-model="searchinput"
            @focus="this.autoShow = true"
            @blur="blursearch"
          />
        </div>
        <div class="auto-complicate-div" v-show="autoShow">
          <p class="m-0 p-1 ps-3">搜尋建議</p>
          <hr class="m-0" />
          <p class="m-0 p-1 ps-3 auto-complicate-p" @click="search('all')">
            從全部中查詢{{ searchinput }}
          </p>
          <p class="m-0 p-1 ps-3 auto-complicate-p" @click="search('廣告組合')">
            從廣告組合中查詢{{ searchinput }}
          </p>
          <p class="m-0 p-1 ps-3 auto-complicate-p" @click="search('廣告名稱')">
            從廣告名稱中查詢{{ searchinput }}
          </p>
        </div>
      </div>
      <div class="dropdown">
        <a
          class="
            btn
            dropdown-toggle
            datepicker
            d-flex
            justify-content-between
            align-items-center
          "
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="font-size: 14px"
        >
          <span>
            {{ dateSelector }}
          </span>
          <span> {{ startDate }}~{{ endDate }} </span>
        </a>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass1Days">
              最近1天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getPass7Days">
              最近7天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentMonth">
              最近30天
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentYear">
              最近一年
            </button>
          </li>
          <li class="change-date-li">
            <button
              type="button"
              class="change-date-button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              自訂
            </button>
          </li>
          <li class="change-date-li">
            <button class="change-date-button" @click="getRecentAll">
              全部
            </button>
          </li>
        </ul>
        <p
          class="ms-2"
          style="position: absolute; color: #c4c4c4; font-size: 14px"
        >
          測試帳號以2022-1-15為最後一筆資料
        </p>
      </div> -->
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="view">
          <div class="wrapper">
            <table class="table" id="table">
              <thead>
                <th
                  v-for="header in headers"
                  :key="header"
                  scope="col"
                  draggable="true"
                  @dragover="allowDrop"
                  @dragstart="dragStart($event)"
                  @drop="dropEvent($event)"
                >
                  <span
                    :style="header === this.currentKey ? 'color:#4197B9' : ''"
                  >
                    {{ header }}
                  </span>
                  <img
                    class="sort-img"
                    style="cursor: pointer"
                    src="../assets/green-up-arrow.png"
                    alt=""
                    @click="sortingButton($event)"
                    v-if="header === this.currentKey && this.issort === false"
                  />
                  <img
                    class="sort-img"
                    style="cursor: pointer"
                    src="../assets/green-down-arrow.png"
                    alt=""
                    @click="sortingButton($event)"
                    v-if="header === this.currentKey && this.issort === true"
                  />
                  <img
                    class="sort-img"
                    style="cursor: pointer"
                    src="../assets/sort-black.png"
                    alt=""
                    @click="sortingButton($event)"
                    v-if="header != this.currentKey"
                  />
                </th>
              </thead>
              <tbody>
                <tr v-for="(item, index1) in searchlist" :key="index1">
                  <td v-for="(header, index) in headers" :key="index">
                    <button
                      class="btn-basic"
                      v-if="index === 1 && item[header] != '-'"
                      @click="changeToAdvList($event)"
                    >
                      <a
                        href="javascript:;"
                        style="color: #71afb6; text-decoration: none"
                        >{{ item[header] }}</a
                      >
                    </button>
                    <span v-else-if="index === 1 && item[header] === '-'">{{
                      item[header]
                    }}</span>
                    <div
                      class="d-flex flex-column cost-div align-items-end"
                      style="width: 150px"
                      v-else-if="header === '成果與成果次數'"
                    >
                      <div v-if="item[item.目標] != undefined">
                        {{ item[item.目標] }}
                      </div>
                      <div
                        v-else-if="
                          item[item.目標] === undefined &&
                          item.成果與成果次數[item.成果名稱] != undefined
                        "
                      >
                        {{ item.成果與成果次數[item.成果名稱] }}
                      </div>
                      <div v-else>{{ item.觸及人數 }}</div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                        style="width: 150px"
                      >
                        <img
                          class="ms-3"
                          src="../assets/show-result.png"
                          alt=""
                          @mouseover="(item.觸及人數 != '-' && item[header] != {}) ? show(index1) : ''"
                          @mouseout="this.rowindex = ''"
                        />
                        <div class="parent-popout font-14">
                          <div
                            v-show="this.rowindex === index1"
                            class="child-popout8"
                          >
                            <div>
                              <div
                                class="d-flex flex-column align-items-end"
                                v-for="(val, index) in Object.values(
                                  item[header]
                                )"
                                :key="index"
                              >
                                {{ val }}
                                <div style="font-size: 14px">
                                  {{ Object.keys(item[header])[index] }}
                                </div>
                              </div>
                            </div>
                            <div class="container__arrow"></div>
                          </div>
                        </div>
                        <div
                          style="font-size: 14px; color: gray"
                          v-if="item[item.目標] != undefined"
                        >
                          {{ item.目標 }}
                        </div>
                        <div
                          style="font-size: 14px; color: gray"
                          v-else-if="
                            item[item.目標] === undefined &&
                            item.成果與成果次數[item.成果名稱] != undefined
                          "
                        >
                          {{ item.成果名稱 }}
                        </div>
                        <div style="font-size: 14px; color: gray" v-else>
                          觸及人數
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column cost-div align-items-end"
                      v-else-if="header === '每次成果成本'"
                    >
                      <div>NT${{ item[header] }}</div>
                      <div
                        style="font-size: 14px; color: gray"
                        v-if="item[item.目標] != undefined"
                      >
                        {{ item.目標 }}
                      </div>
                      <div
                        style="font-size: 14px; color: gray"
                        v-else-if="
                          item[item.目標] === undefined &&
                          item.成果與成果次數[item.成果名稱] != undefined
                        "
                      >
                        {{ item.成果名稱 }}
                      </div>
                      <div style="font-size: 14px; color: gray" v-else>
                        觸及人數
                      </div>
                    </div>
                    <div
                      class="d-flex flex-column cost-div"
                      v-else-if="header === '排程'"
                    >
                      <div v-if="item[header] === '-'">時間同上</div>
                      <div v-else>
                        <div style="height: 20px">
                          {{ item[header].substr(0, 10) }}
                        </div>
                        <div style="height: 10px">
                          <div
                            style="
                              transform: rotate(90deg);
                              position: relative;
                              top: -3px;
                            "
                          >
                            ~
                          </div>
                        </div>
                        <div class="mb-1" style="height: 20px">
                          {{ item[header].substr(20, 10) }}
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item[header] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <rawDisplayer class="col-2" :value="list" title="List" /> -->

      <!-- <rawDisplayer class="col-2" :value="headers" title="Headers" /> -->
    </div>
  </div>
</template>

<script>
import { Calendar, DatePicker } from "v-calendar";
import { allapi } from "../../public/static/api/apigClient.js";
import { allapi3 } from "../../public/static3/api/apigClient.js";
export default {
  name: "FbadsTable",
  display: "Table Column",
  order: 9,
  components: {
    Calendar,
    DatePicker,
  },
  data() {
    return {
      loading:false,
      isShow: false,
      notshow: false,

      dateSelector: "",
      startDate: "2021-12-01",
      endDate: "2022-03-19",
      hasDataFirst: "",
      hasDataEnd: "",

      searchinput: "",
      autoShow: false,

      vue3Date: {
        start: new Date("2022-01-06").toISOString().slice(0, 10),
        end: new Date("2022-01-10").toISOString().slice(0, 10),
      },

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },

      headers: [
        "廣告組合",
        "廣告名稱",
        "狀態",
        "觸及人數",
        "曝光次數",
        "頻率",
        "成果與成果次數",
        "每次成果成本",
        "排程",
        "點擊",
        "CTR(全部)",
        "CPC",
        "CPM",
        "預算",
        "花費",
      ],
      list: [],
      searchlist: [
        // {
        //   CPC: 0.1,
        //   CPM: 1.2,
        //   CTR連結點擊率: 0.2,
        //   廣告名稱: "電器買五送一加碼大優惠",
        //   成果與成果次數: { ssss: 1230 },
        //   每次成果成本: "0.88",
        //   排程: "Sun, 13 Feb 2022 00:00:00 GMT",
        //   曝光次數: 897,
        //   廣告組合: "黑五特賣會會會會",
        //   狀態: "進行中",
        //   花費: 875,
        //   觸及人數: 230,
        //   預算: 23,
        //   頻率: 1.52,
        //   點擊: 54,
        // },
      ],

      issort: false,
      currentKey: "",
      rowindex: "",

      dragging: false,
      advname: "",
    };
  },
  watch: {
    searchinput() {
      if (this.searchinput === "") {
        this.searchlist = this.list;
        this.autoShow = false;
        setTimeout(() => {
          this.MergeCell();
        }, 200);
      } else {
        this.autoShow = true;
      }
    },
  },
  created: async function () {
    this.loading=true
    this.advname = sessionStorage.getItem("advname");
    await this.getTime();
    await this.getTableApi();
    await this.test1();
    this.MergeCell();
    this.loading=false
  },
  methods: {
    show(index1) {
      this.rowindex = index1;
    },
    search(key) {
      if (key === "all") {
        this.searchlist = this.searchlist.filter((d) => {
          return (
            d.廣告組合.includes(this.searchinput) ||
            d.廣告名稱.includes(this.searchinput)
          );
        });
      } else {
        this.searchlist = this.searchlist.filter((d) => {
          return d[key].includes(this.searchinput);
        });
      }
      const table = document.getElementById("table");
      console.log(table.col);
      for (var i = 0; i < table.rows.length - 1; i++) {
        table.rows[i + 1].cells[0].style.color = "black";
      }
      // table.col[0].cells[0].style.color = "black";

      this.autoShow = false;
    },
    getTime: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
      };
      await allapi
        .timeSalesGet(params)
        .then((res) => {
          console.log(res);
          this.hasDataFirst = res.data.Data.start_date;
          this.hasDataEnd = res.data.Data.end_date;
          this.startDate = res.data.Data.start_date;
          this.endDate = res.data.Data.end_date;
        })
        .catch((err) => {
          alert("連線有誤");
          console.log(err);
          this.loading = false;
        });
    },
    blursearch() {
      setTimeout(() => {
        this.autoShow = false;
      }, 200);
    },
    sortingButton(e) {
      this.issort = !this.issort;
      const key = e.target.parentNode.innerText.trim();
      if (this.currentKey != key) {
        this.issort = true;
      }
      this.currentKey = key;

      if (this.issort) {
        this.test1();
        this.test7(key);
        this.test2(key);
        this.test3();
      } else {
        this.test1();
        this.test8(key);
        this.test5(key);
        this.test6();
      }
    },
    test1: async function () {
      function compare(a, b) {
        const value1 = a.廣告組合;
        const value2 = b.廣告組合;
        if (value1 === value2) {
          return a.廣告名稱.length - b.廣告名稱.length;
        }

        if (b.廣告組合 > a.廣告組合) {
          return 1;
        }
        if (b.廣告組合 < a.廣告組合) {
          return -1;
        }
      }
      await this.searchlist.sort(compare);
    },
    test7(key) {
      function compare(a, b) {
        const value1 = a.廣告組合;
        const value2 = b.廣告組合;
        if (value1 === value2 && a.廣告名稱 != "-" && b.廣告名稱 != "-") {
          return b[key] - a[key];
        }
      }
      this.searchlist.sort(compare);
    },
    test2(key) {
      let arr = this.searchlist;
      function compare2(a, b) {
        const value1 = a.廣告組合;
        const value2 = b.廣告組合;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告名稱 === "-" && d.廣告組合 === a.廣告組合;
          });

          a.rank = data[key];
          b.rank = data[key];
        }
      }

      this.searchlist.sort(compare2);
    },
    test3() {
      function compare(a, b) {
        return b.rank - a.rank;
      }
      this.searchlist.sort(compare);
    },

    test8(key) {
      function compare(a, b) {
        const value1 = a.廣告組合;
        const value2 = b.廣告組合;
        if (value1 === value2 && a.廣告名稱 != "-" && b.廣告名稱 != "-") {
          return a[key] - b[key];
        }
      }
      this.searchlist.sort(compare);
    },
    test5(key) {
      let arr = this.searchlist;
      function compare2(a, b) {
        const value1 = a.廣告組合;
        const value2 = b.廣告組合;
        if (value1 === value2) {
          const data = arr.find((d) => {
            return d.廣告名稱 === "-" && d.廣告組合 === a.廣告組合;
          });

          a.rank = data[key];
          b.rank = data[key];
        }
      }

      this.searchlist.sort(compare2);
    },
    test6() {
      function compare(a, b) {
        return a.rank - b.rank;
      }
      this.searchlist.sort(compare);
    },

    allowDrop(e) {
      e.preventDefault();
    },
    dragStart(e) {
      let tar = e.target.innerText;
      console.log(e);
      console.log(tar);
      e.dataTransfer.setData("Text", tar);
    },

    dropEvent(e) {
      this.allowDrop(e);
      const fixDragText = e.dataTransfer.getData("Text").trim();
      const dragText = fixDragText;

      const newheaders = this.headers.slice(2, 20);

      const haveheader = newheaders.some((d) => {
        return d === dragText;
      });
      const not12 = newheaders.some((d) => {
        return d === e.target.innerText.trim();
      });

      if (haveheader && not12) {
        const dropindex = this.headers.findIndex((d) => {
          return d === e.target.innerText.trim();
        });
        const dragindex = this.headers.findIndex((d) => {
          return d === dragText;
        });

        this.headers[dropindex] = dragText;
        this.headers[dragindex] = e.target.innerText.trim();
      }
    },
    MergeCell() {
      const table = document.getElementById("table");
      console.log("table", table);
      for (var i = 0; i < table.rows.length - 1; i++) {
        if (
          table.rows[i].cells[0].innerHTML ==
          table.rows[i + 1].cells[0].innerHTML
        ) {
          table.rows[i + 1].cells[0].style.color = "white";
        }
      }
      console.log("table-col", table.rows[0].cells);
    },
    getTableApi: async function () {
      const token = sessionStorage.getItem("access_token");
      const params = {
        Authorization: "Bearer " + token,
        start_time: this.startDate,
        end_time: this.endDate,
      };

      await allapi3
        .facebookAdsetGet(params)
        .then((res) => {
          console.log("fbtable", res);
          this.advlist = res.data.Data;
          const adv = this.advlist.filter((d) => {
            return d.廣告組合 === this.advname;
          });
          this.list = adv;
          console.log("list", this.list);
          this.searchlist = this.list;
        })
        .catch((err) => {
          console.log("連線有誤");
          console.log(err);
          alert("連線有誤" + err);
        });
    },
    changeToAdvList(e) {
      this.$router.push({
        name: "FbadsTableByAdv",
        params: { id: e.target.innerText },
      });
      console.log(e.target.innerText);
    },
  },
};
</script>
<style scoped>
/* FbadsTableByAdv */
.buttons {
  margin-top: 35px;
}
.view {
  padding: 15px;
  margin: auto;
  width: 100%;
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.wrapper {
  position: relative;
  overflow: auto;
  /* border: 1px solid black; */
  white-space: nowrap;
  /* box-shadow: 10px 0 5px -2px #888; */
}

td:nth-child(1),
th:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  min-width: 150px;
  max-width: 150px;
  background-color: #fff;
  filter: drop-shadow(2px 0px 0px rgb(235, 230, 230));
  z-index: 9;
}

td:nth-child(2),
th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  left: 150px;
  border-right: none;
  /* filter: drop-shadow(2px 0px 1px rgba(124, 124, 124, 0.446)); */
  filter: drop-shadow(3px 0px 1px rgba(202, 201, 201, 0.446));
  /* white-space: normal; */
  z-index: 9;
}
table {
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}
th {
  border-bottom: 2px solid rgb(235, 230, 230);
  padding: 5px 15px;
}
th,
td {
  border-right: 2px solid rgb(235, 230, 230);
}
.upload-button {
  background: linear-gradient(270deg, #71afb6 0%, #b5dce0 100%);
  border: none;
}
.btn-lg {
  min-width: 34.5vw;
  font-size: 20px;
  border-radius: 10px;
}
.search-container {
  position: relative;
  right: 0;
  /* height:50px ; */
}
.search-input {
  background-color: #eaf3f4;
  border: none;
  border-radius: 10px;
  text-indent: 50px;
  height: 35px;
  width: 350px;
}
.searchImg {
  position: absolute;
  top: 8px;
  left: 15px;
}
.auto-complicate-div {
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  background-color: white;
  z-index: 99;
}
.auto-complicate-p:hover {
  background-color: #eaf3f4;
}
.btn-basic {
  border: none;
  background-color: white;
}
.sort-img {
  padding: 0;
  position: relative;
  top: -2px;
  right: 0px;
  margin-left: 10px;
}
.cost-div {
  width: 130px;
}
.child-popout8 {
  background-color: #63a7c8;
  z-index: 99;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: -30px;
  left: -20px;
  width: 120px;
  color: #fff;
  text-align: left;
  padding: 10px;
}
</style>
